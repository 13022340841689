import { Select } from '@rebass/forms';
import Iget from 'models/Iget';
import { Multiselect } from 'multiselect-react-dropdown';
import React, { ReactElement, useEffect, useState } from 'react';
import Collapsible from 'react-collapsible';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Box, Flex } from 'rebass/styled-components';
import { FormLabel, Stack, Toggle } from 'ui/components';
import * as Icon from 'ui/icons';
import { FormGroup, FormInput, PricingTable } from 'ui/patterns';
import urls from 'utils/create.url';
import helper from 'utils/helper.functions';
import hooks from 'utils/requestCtx/reactQueryHooks';
import { AccordionPanel, StyledLineAccordion, VenueDetails, DropDownMultiSelect } from './styles';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css';

function LineAccordion(props): ReactElement {
	const [error] = useState(props.error);
	const regexHTMLDecode = /(<([^>]+)>)/gi;
	const [startDate] = useState(new Date());
	const [multiSelectedCat, setMultiSelectedCat] = useState([] as any);
	const [productDetails, setProductDetails] = useState(props.productDetails);
	const [categoryLevel, setCategoryLevel] = useState([] as any);
	const [itemChecked, setItemChecked] = useState([] as any);
	const [itemExpanded, setItemExpanded] = useState([] as any);

	{
		/* https://www.npmjs.com/package/multiselect-react-dropdown */
	}

	const attributeKey = 'attributeList';
	const url = 'products/attribute-sets/sets/list?searchCriteria%5BcurrentPage%5D=1';
	const obj: Partial<Iget> = {
		params: {},
		url: url,
		enabled: true,
		key: attributeKey,
		resultFilteringRequired: true,
	};
	var attributeList = hooks.useQuery(obj);
	//taxClass type
	const url1 = 'products/attributes/tax_class_id';
	const taxObj: Partial<Iget> = {
		params: {},
		url: url1,
		enabled: true,
		key: 'taxClass',
		resultFilteringRequired: false,
	};
	var taxClassList = hooks.useQuery(taxObj);

	const uomKey = 'uom';
	const uomUrl = 'products/attributes/uom/options';
	const obj2: Partial<Iget> = {
		params: {},
		url: uomUrl,
		enabled: true,
		key: uomKey,
		resultFilteringRequired: false,
	};
	var uomList = hooks.useQuery(obj2);
	// product status
	const pdtStatusKey = 'pdtStatus';
	const pdtStatusUrl = 'products/attributes/quantity_and_stock_status/options';
	const obj3: Partial<Iget> = {
		params: {},
		url: pdtStatusUrl,
		enabled: true,
		key: pdtStatusKey,
		resultFilteringRequired: false,
	};
	var pdtStatusList = hooks.useQuery(obj3);
	const iterateValue = (name) => {
		var item =
			productDetails && productDetails.custom_attributes.find((x) => x.attribute_code == name);
		if (item && item != undefined) {
			if (name == 'special_from_date' || name == 'special_to_date') {
				return helper.formatDate(item.value);
			} else {
				return item.value.replace(regexHTMLDecode, '');
			}
		}
	};
	useEffect(() => {
		if (props.productDetails) {
			setProductDetails(props.productDetails);
		}
	}, [props.productDetails]);
	const validateEnteredText = (e) => {
		if (e.charCode === 45 || e.charCode === 43 || e.charCode === 46) {
			e.preventDefault();
			return false;
		} else {
			return true;
		}
	};

	const onAction = (node, action) => { };
	return (
		<StyledLineAccordion>
			<AccordionPanel>
				<Collapsible open trigger="PRODUCT DETAILS">
					<VenueDetails>
						<Flex as="section">
							<Box>
								<FormInput
									label="Product Name"
									value={props.productDetails.name}
									required={true}
									validationMessage={props.error && props.error.productNameError}
									validation={props.error && props.error.productNameError ? 'error' : undefined}
									name="prdtName"
									onTextChange={(e) => props.onChangeValue && props.onChangeValue(e, 'name')}
									maxLength={200}
									type="text"
								/>
							</Box>
							<Box>
								<Stack categorySelect gap={0}>
									<div>
										<label>
											Attribute Set <sup>*</sup>
										</label>
										<Select
											id="attributeSet"
											name="attributeSet"
											disabled={props.action == 'Save'}
											value={props.productDetails.attribute_set_id}
											onChange={(e) => props.onChangeValue && props.onChangeValue(e, 'attribute')}
										>
											<option>-Select-</option>
											{attributeList.data &&
												attributeList.data.map((option, index) => (
													<option value={option.attribute_set_id} key={index}>
														{option.attribute_set_name}
													</option>
												))}
										</Select>
										<Icon.downArrow />
									</div>
									<span style={{ fontSize: 12, color: 'red' }}>{error.attributeError}</span>
								</Stack>
							</Box>
						</Flex>
						<Box my="25px">
							<FormLabel disabled={false}>
								Product Category <sup>*</sup>
							</FormLabel>
							<DropDownMultiSelect>
								<DropdownTreeSelect
									data={props.categoryLevel}
									onChange={props.onChangeCategory}
									onAction={onAction}
									onNodeToggle={props.onNodeToggle}
									keepTreeOnSearch
									clearSearchOnChange
									mode='hierarchical'
									keepChildrenOnSearch />
							</DropDownMultiSelect>
						</Box>
						<Box my="25px">
							<FormInput
								label="Description"
								value={iterateValue('description')}
								name="description"
								id="desc"
								onTextChange={(e) => props.onChangeValue && props.onChangeValue(e, 'description')}
								maxLength={1000}
								type="text"
							/>
						</Box>
						<Flex as="section">
							<Box>
								<FormInput
									label="Supplier SKU"
									value={iterateValue('supplier_sku')}
									required={true}
									validationMessage={props.error && props.error.skuError}
									validation={props.error && props.error.skuError ? 'error' : undefined}
									name="supplier_sku"
									onTextChange={(e) =>
										props.onChangeValue && props.onChangeValue(e, 'supplier_sku')
									}
									maxLength={20}
									type="text"
								/>
							</Box>
							<Box>
								<FormInput
									label="Brand Name"
									value={iterateValue('brand')}
									name="brand"
									onTextChange={(e) => props.onChangeValue && props.onChangeValue(e, 'brand')}
									maxLength={20}
									type="text"
								/>
							</Box>
							<Box>
								<FormInput
									label="UPC"
									validationMessage={props.error && props.error.upcError}
									validation={props.error && props.error.upcError ? 'error' : undefined}
									value={iterateValue('upc')}
									name="upc"
									onTextChange={(e) => props.onChangeValue && props.onChangeValue(e, 'upc')}
									maxLength={12}
									onKeyPress={validateEnteredText}
									type="number"
								/>
							</Box>
							<Box className="statusSelect">
								<label>Status</label>
								<Toggle
									onChange={(e) => {
										props.toggleBtnChange && props.toggleBtnChange(e);
									}}
									checked={props.productDetails && props.statusToggle}
								/>
							</Box>
						</Flex>
					</VenueDetails>
				</Collapsible>

				<Collapsible open trigger="QUANTITY & PRICING">
					<PricingTable
						productDetails={props.productDetails}
						addTierPricing={(item) => props.addTierPricing(item)}
						deleteTierPricing={(index) => {
							props.deleteTierPricing && props.deleteTierPricing(index);
						}}
					></PricingTable>
					<VenueDetails>
						<Flex as="section">
							<Box>
								<FormInput
									label="Price per unit ($)"
									value={props.productDetails.price}
									required={true}
									name="price"
									onTextChange={(e) => props.onChangeValue && props.onChangeValue(e, 'price')}
									maxLength={20}
									type="number"
								/>
							</Box>
							<Box>
								<FormInput
									label="Inventory "
									value={props.productDetails.extension_attributes.stock_item.qty}
									name="qty"
									onTextChange={(e) => props.onChangeValue && props.onChangeValue(e, 'qty')}
									maxLength={20}
									type="number"
								/>
							</Box>
							<Box>
								<Stack categorySelect gap={0}>
									<div>
										<label>Tax Class</label>
										<Select
											id="taxClass"
											name="taxClass"
											value={iterateValue('tax_class_id')}
											onChange={(e) =>
												props.onChangeValue && props.onChangeValue(e, 'tax_class_id')
											}
										>
											{taxClassList.data &&
												taxClassList.data.options
													.filter((x) => x.value != '')
													.map((option, index) => (
														<option value={option.value} key={index}>
															{option.label}
														</option>
													))}
										</Select>
										<Icon.downArrow />
									</div>
								</Stack>
							</Box>
							<Box>
								<Stack categorySelect gap={0}>
									<div>
										<label>Stock Status</label>
										<Select
											id="productStatus"
											name="productStatus"
											value={
												props.productDetails.extension_attributes.stock_item.is_in_stock == true
													? 1
													: 0
											}
											onChange={(e) => props.onChangeValue && props.onChangeValue(e, 'pdtStatus')}
										>
											{pdtStatusList.data &&
												pdtStatusList.data
													.filter((x) => x.value != '')
													.map((option, index) => (
														<option value={option.value} key={index}>
															{option.label}
														</option>
													))}
										</Select>
										<Icon.downArrow />
									</div>
								</Stack>
							</Box>

							<Box>
								<FormInput
									label="Special Price"
									value={iterateValue('special_price')}
									name="spclPrice"
									onTextChange={(e) =>
										props.onChangeValue && props.onChangeValue(e, 'special_price')
									}
									maxLength={20}
									type="number"
								/>
								<span style={{ fontSize: 12, color: 'red' }}>{error.spclPriceError}</span>
							</Box>
							<Box>
								<FormGroup datePicker>
									<FormLabel>Date From</FormLabel>
									<DatePicker
										value={iterateValue('special_from_date')}
										selected={startDate}
										minDate={new Date}
										onChange={(e) =>
											props.onChangeValue && props.onChangeValue(e, 'special_from_date')
										}
										dateFormat={helper.returnDateFormat()}
									/>
									<Icon.DateIconInput />
								</FormGroup>
								<span style={{ fontSize: 12, color: 'red' }}>{error.fromDateError}</span>
							</Box>
							<Box>
								<FormGroup datePicker>
									<FormLabel>Date To</FormLabel>
									<DatePicker
										value={iterateValue('special_to_date')}
										selected={startDate}
										minDate={new Date}
										onChange={(e) =>
											props.onChangeValue && props.onChangeValue(e, 'special_to_date')
										}
										dateFormat={helper.returnDateFormat()}
									/>
									<Icon.DateIconInput />
								</FormGroup>
							</Box>
							<Box>
								<FormInput
									label="Threshold Value"
									value={props.productDetails.extension_attributes.stock_item.min_qty}
									name="thresholdValue"
									id="thresholdValue"
									onTextChange={(e) =>
										props.onChangeValue && props.onChangeValue(e, 'thresholdValue')
									}
									maxLength={20}
									type="number"
								/>
							</Box>
							<Box>
								<FormInput
									label="Min Order Quantity"
									value={props.productDetails.extension_attributes.stock_item.min_sale_qty}
									validationMessage={props.error && props.error.minSaleError}
									name="minOrderQty"
									onTextChange={(e) => props.onChangeValue && props.onChangeValue(e, 'minOrder')}
									maxLength={20}
									type="number"
								/>
							</Box>
							<Box>
								<Stack categorySelect gap={0}>
									<div>
										<label>Unit Of Measurement</label>
										<Select
											id="Unit Of Measurement"
											name="Unit Of Measurement"
											value={iterateValue('uom')}
											onChange={(e) => props.onChangeValue && props.onChangeValue(e, 'uom')}
										>
											<option>-Select-</option>
											{uomList.data &&
												uomList.data
													.filter((x) => x.value != '')
													.map((option, index) => (
														<option value={option.value} key={index}>
															{option.label}
														</option>
													))}
										</Select>
										<Icon.downArrow />
									</div>
								</Stack>
							</Box>
							<Box>
								<FormInput
									label="Max Order Quantity"
									value={props.productDetails.extension_attributes.stock_item.max_sale_qty}
									name="maxOrderQty"
									validationMessage={error.maxSaleError}
									onTextChange={(e) => props.onChangeValue && props.onChangeValue(e, 'maxOrder')}
									maxLength={20}
									type="number"
								/>
							</Box>
						</Flex>
					</VenueDetails>
				</Collapsible>
			</AccordionPanel>
		</StyledLineAccordion>
	);
}
export { LineAccordion };
