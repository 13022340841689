import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import logo from 'ui/images/logo_exp.png';
import helperFunctions from 'utils/helper.functions';
import moment from 'moment';
import { OrderStatuses } from 'models/enum-constants/common.constants';

const MainContent = styled.div`
	position:fixed;
	top: 0;
	text-align: center;
	width: 1440px !important;
	/* CLIENT-SPECIFIC STYLES */
	body,
	table,
	td,
	a {
		-webkit-text-size-adjust: 100%;
		-ms-text-size-adjust: 100%;
	} /* Prevent WebKit and Windows mobile changing default text sizes */
	table,
	td {
		mso-table-lspace: 0;
		mso-table-rspace: 0;
	} /* Remove spacing between tables in Outlook 2007 and up */
	img {
		-ms-interpolation-mode: bicubic;
	} /* Allow smoother rendering of resized image in Internet Explorer */

	/* RESET STYLES */
	img {
		border: 0;
		height: auto;
		line-height: 100%;
		outline: none;
		text-decoration: none;
	}
	table {
		border-collapse: collapse !important;
	}
	body {
		height: 100% !important;
		margin: 0 !important;
		padding: 0 !important;
		width: 100% !important;
		background: #fff !important;
	}
	/* iOS BLUE LINKS */
	a[x-apple-data-detectors] {
		color: inherit !important;
		text-decoration: none !important;
		font-size: inherit !important;
		font-family: inherit !important;
		font-weight: inherit !important;
		line-height: inherit !important;
	}

	/* MOBILE STYLES */

	/* ANDROID CENTER FIX */
	div[style*='margin: 16px 0;'] {
		margin: 0 !important;
	}

	/* Outlook 2016 Height Fix */
	table,
	tr,
	td {
		border-collapse: collapse;
	}
	tr {
		font-size: 0;
		line-height: 0;
		border-collapse: collapse;
	}
`;
function Order({ inputData }): ReactElement {
	const gridColumnSize = 20;
	const inData = JSON.parse(JSON.stringify(inputData));
	const copyData = JSON.parse(JSON.stringify(inputData));
	let reason = 'NIL';
	if (
		inData.status == OrderStatuses.Rejected &&
		inData.status_histories &&
		inData.status_histories.length > 0
	) {
		reason = inData.status_histories[0].comment?.split(':')[0] ? inData.status_histories[0].comment?.split(':')[0] : reason;
	}
	const numbers = Math.ceil(inData.items.length / gridColumnSize);
	const datas: any[] = [];
	for (let i = 0; i < numbers; i++) {
		const splitArray = inData.items.slice(i * gridColumnSize, i * gridColumnSize + gridColumnSize);
		copyData.items = splitArray;
		datas.push(JSON.parse(JSON.stringify(copyData)));
	}
	return datas ? (
		<>
			{datas.map((data, i) => (
				<MainContent
					id={'printDocument' + (i + 1)}
					// style={{
					// 	paddingTop: '200px'
					// }}
				>
					<table
						cellPadding={0}
						cellSpacing={0}
						width="100%"
						className="m-col-width"
						style={{
							margin: '0 auto',
							padding: '0 !important',
							width: '100%',
							maxWidth: '100%',
							background: '#ffffff !important',
							border: '0',
							borderCollapse: 'collapse',
							borderSpacing: 0,
							whiteSpace: 'nowrap',
						}}
					>
						<tbody>
							{/* Header */}
							<tr>
								<td
									align="center"
									valign="top"
									width="100%"
									className="m-col-width paddingshrink"
									style={{
										padding: 60,
										width: '800',
										maxWidth: '100%',
										margin: 0,
										lineHeight: 0,
										whiteSpace: 'normal',
										background: '#FFFFFF',
									}}
									colSpan={2}
								>
									<table
										cellPadding={0}
										cellSpacing={0}
										className="m-col-width"
										width="100%"
										style={{
											margin: 0,
											width: '800',
											maxWidth: '800',
											border: '0',
											whiteSpace: 'normal',
											borderCollapse: 'collapse',
											borderSpacing: 0,
										}}
									>
										<tbody>
											{/* <tr>
										<td
											align="left"
											colSpan={1}
											width="100%"
											style={{
												width: '100%',
												maxWidth: '100%',
												lineHeight: '0',
												whiteSpace: 'normal',
												height: '60px',
												background: '#FFFFFF',
											}}
										>
											<img
												src={logo}
												alt="experion"
												style={{ width: 100 }}
											/>
										</td>
									</tr> */}
											<tr>
												<td
													align="center"
													colSpan={1}
													width="100%"
													style={{
														width: '100%',
														maxWidth: '100%',
														lineHeight: '0px',
														whiteSpace: 'normal',
														height: '60px',
														background: '#FFFFFF',
													}}
												>
													<p
														className="m-heading"
														style={{
															fontFamily: 'Open Sans,sans-serif',
															fontStyle: 'normal',
															fontWeight: 'bold',
															fontSize: '29px',
															lineHeight: '63px',
															color: '#1A1919',
														}}
													>
														Order Summary
													</p>
												</td>
											</tr>
											<tr>
												<td
													align="left"
													colSpan={1}
													width="100%"
													style={{
														width: '100%',
														maxWidth: '100%',
														lineHeight: 0,
														whiteSpace: 'normal',
														height: '60px',
														background: '#FFFFFF',
													}}
												>
													<p
														className="m-created"
														style={{
															fontFamily: 'Open Sans,sans-serif',
															fontStyle: 'normal',
															fontWeight: 'normal',
															fontSize: '15px',
															color: '#1A1919',
															opacity: '0.8',
															paddingBottom: '10px',
														}}
													>
														Created By
													</p>
													<p
														className="m-venue"
														style={{
															fontFamily: 'Open Sans,sans-serif',
															color: '#1A1919',
															fontStyle: 'normal',
															fontWeight: 'bold',
															fontSize: '27px',
															lineHeight: 'normal',
															display: 'inline',
														}}
													>
														{data?.customer_firstname} {data?.customer_lastname}
													</p>
												</td>
											</tr>
											{/*  contact details and summary*/}
											<tr>
												<td
													align="left"
													colSpan={1}
													width="100%"
													style={{
														width: '100%',
														maxWidth: '100%',
														lineHeight: 'normal',
														whiteSpace: 'normal',
														height: '60px',
														background: '#FFFFFF',
													}}
												>
													<table
														cellPadding={0}
														cellSpacing={0}
														width="100%"
														className="m-col-width"
														style={{
															margin: 0,
															width: '100%',
															maxWidth: '100%',
															border: '0',
															whiteSpace: 'normal',
															borderCollapse: 'collapse',
															borderSpacing: 0,
														}}
													>
														<tbody>
															<tr>
																<td style={{ verticalAlign: 'top' }}>
																	<table
																		cellPadding={0}
																		cellSpacing={0}
																		width="400px"
																		className="m-col-width"
																		style={{
																			margin: '0 15px 0 0',
																			width: '400px',
																			maxWidth: '400px',
																			border: '0',
																			whiteSpace: 'normal',
																			borderCollapse: 'collapse',
																			borderSpacing: 0,
																		}}
																	>
																		<tbody>
																			{/* <tr>
																		<td colSpan={3} style={{ height: 35 }}>
																			<p />
																		</td>
																	</tr> */}
																			<tr>
																				<td
																					align="left"
																					colSpan={1}
																					className="m-col-width"
																					style={{
																						padding: 0,
																						verticalAlign: 'baseline',
																						width: '25%',
																						maxWidth: '25%',
																						lineHeight: 'normal',
																						whiteSpace: 'normal',
																						background: '#FFFFFF',
																					}}
																				>
																					<p
																						style={{
																							fontSize: '16px',
																							opacity: '0.8',
																							fontFamily: 'Open Sans,sans-serif',
																							color: '#1A1919',
																							fontStyle: 'normal',
																							fontWeight: 'normal',
																							margin: '7.5px 0',
																						}}
																					>
																						Address
																					</p>
																				</td>
																				<td
																					align="left"
																					colSpan={1}
																					width="100%"
																					className="m-col-width"
																					style={{
																						padding: 0,
																						verticalAlign: 'baseline',
																						width: '26px',
																						maxWidth: '26px',
																						lineHeight: 'normal',
																						whiteSpace: 'normal',
																						background: '#FFFFFF',
																					}}
																				>
																					<p
																						style={{
																							fontSize: '16px',
																							fontFamily: 'Open Sans,sans-serif',
																							color: '#1A1919',
																							fontStyle: 'normal',
																							fontWeight: 'normal',
																							margin: '0 12px',
																						}}
																					>
																						:
																					</p>
																				</td>
																				<td
																					align="left"
																					colSpan={1}
																					width="100%"
																					className="m-col-width"
																					style={{
																						padding: 0,
																						verticalAlign: 'baseline',
																						width: '100%',
																						maxWidth: '100%',
																						lineHeight: 'normal',
																						whiteSpace: 'normal',
																						background: '#FFFFFF',
																					}}
																				>
																					<p
																						style={{
																							fontSize: '16px',
																							fontFamily: 'Open Sans,sans-serif',
																							color: '#1A1919',
																							fontStyle: 'normal',
																							fontWeight: 600,
																							margin: '7.5px 0',
																						}}
																					>
																						{/* first place and area */}
																						{data?.billing_address?.street[0]}
																						{/* pincode and country */}
																						{data?.billing_address?.region}{' '}
																						{data?.billing_address?.postcode},{' '}
																						{data?.billing_address?.city}
																					</p>
																				</td>
																			</tr>
																			<tr>
																				<td
																					align="left"
																					colSpan={1}
																					width={290}
																					className="m-col-width"
																					style={{
																						padding: 0,
																						verticalAlign: 'baseline',
																						width: '100px',
																						maxWidth: '100px',
																						lineHeight: 'normal',
																						whiteSpace: 'normal',
																						background: '#FFFFFF',
																					}}
																				>
																					<p
																						style={{
																							fontSize: '16px',
																							opacity: '0.8',
																							fontFamily: 'Open Sans,sans-serif',
																							color: '#1A1919',
																							fontStyle: 'normal',
																							fontWeight: 'normal',
																							margin: '7.5px 0',
																						}}
																					>
																						Phone
																					</p>
																				</td>
																				<td
																					align="left"
																					colSpan={1}
																					width="100%"
																					className="m-col-width"
																					style={{
																						padding: 0,
																						verticalAlign: 'baseline',
																						width: '26px',
																						maxWidth: '26px',
																						lineHeight: 'normal',
																						whiteSpace: 'normal',
																						background: '#FFFFFF',
																					}}
																				>
																					<p
																						style={{
																							fontSize: '16px',
																							fontFamily: 'Open Sans,sans-serif',
																							color: '#1A1919',
																							fontStyle: 'normal',
																							fontWeight: 'normal',
																							margin: '0 12px',
																						}}
																					>
																						:
																					</p>
																				</td>
																				<td
																					align="left"
																					colSpan={1}
																					width="100%"
																					className="m-col-width"
																					style={{
																						padding: 0,
																						verticalAlign: 'baseline',
																						width: '100px',
																						maxWidth: '100px',
																						lineHeight: 'normal',
																						whiteSpace: 'normal',
																						background: '#FFFFFF',
																					}}
																				>
																					<p
																						style={{
																							fontSize: '16px',
																							fontFamily: 'Open Sans,sans-serif',
																							color: '#1A1919',
																							fontStyle: 'normal',
																							fontWeight: 600,
																							margin: '7.5px 0',
																						}}
																					>
																						{data?.billing_address?.telephone}
																					</p>
																				</td>
																			</tr>
																			<tr>
																				<td
																					align="left"
																					colSpan={1}
																					width={290}
																					className="m-col-width"
																					style={{
																						verticalAlign: 'baseline',
																						width: '100px',
																						maxWidth: '100px',
																						lineHeight: 'normal',
																						whiteSpace: 'normal',
																						background: '#FFFFFF',
																					}}
																				>
																					<p
																						style={{
																							fontSize: '16px',
																							opacity: '0.8',
																							fontFamily: 'Open Sans,sans-serif',
																							color: '#1A1919',
																							fontStyle: 'normal',
																							fontWeight: 'normal',
																						}}
																					>
																						Email
																					</p>
																				</td>
																				<td
																					align="left"
																					colSpan={1}
																					width="100%"
																					style={{
																						verticalAlign: 'baseline',
																						width: '26px',
																						maxWidth: '26px',
																						lineHeight: 'normal',
																						whiteSpace: 'normal',
																						background: '#FFFFFF',
																					}}
																				>
																					<p
																						style={{
																							fontSize: '16px',
																							fontFamily: 'Open Sans,sans-serif',
																							color: '#1A1919',
																							fontStyle: 'normal',
																							fontWeight: 'normal',
																							margin: '0 12px',
																						}}
																					>
																						:
																					</p>
																				</td>
																				<td
																					align="left"
																					colSpan={1}
																					width="100%"
																					style={{
																						verticalAlign: 'baseline',
																						width: '100%',
																						maxWidth: '100%',
																						lineHeight: 'normal',
																						whiteSpace: 'normal',
																						background: '#FFFFFF',
																					}}
																				>
																					<p
																						style={{
																							fontSize: '16px',
																							fontFamily: 'Open Sans,sans-serif',
																							color: '#1A1919',
																							fontStyle: 'normal',
																							fontWeight: 600,
																						}}
																					>
																						{data?.customer_email}
																					</p>
																				</td>
																			</tr>
																			{/* <tr>
																		<td
																			align="left"
																			colSpan={1}
																			width="100%"
																			className="m-col-width"
																			style={{
																				padding: 0,
																				verticalAlign: 'baseline',
																				width: 100,
																				maxWidth: 100,
																				lineHeight: 'normal',
																				whiteSpace: 'normal',
																				background: '#FFFFFF',
																			}}
																		>
																			<p
																				style={{
																					fontSize: '16px',
																					opacity: '0.8',
																					fontFamily: 'Open Sans,sans-serif',
																					color: '#1A1919',
																					fontStyle: 'normal',
																					fontWeight: 'normal',
																					margin: '7.5px 0',
																				}}
																			>
																				Website
																			</p>
																		</td>
																		<td
																			align="left"
																			colSpan={1}
																			width="100%"
																			className="m-col-width"
																			style={{
																				padding: 0,
																				verticalAlign: 'baseline',
																				width: 26,
																				maxWidth: 26,
																				lineHeight: 'normal',
																				whiteSpace: 'normal',
																				background: '#FFFFFF',
																			}}
																		>
																			<p
																				style={{
																					fontSize: '16px',
																					fontFamily: 'Open Sans,sans-serif',
																					color: '#1A1919',
																					fontStyle: 'normal',
																					fontWeight: 'normal',
																					margin: '0 12px',
																				}}
																			>
																				:
																			</p>
																		</td>
																		<td
																			align="left"
																			colSpan={1}
																			width="100%"
																			className="m-col-width"
																			style={{
																				padding: 0,
																				verticalAlign: 'baseline',
																				width: '100%',
																				maxWidth: '100%',
																				lineHeight: 'normal',
																				whiteSpace: 'normal',
																				background: '#FFFFFF',
																			}}
																		>
																			<p
																				style={{
																					fontSize: '16px',
																					fontFamily: 'Open Sans,sans-serif',
																					color: '#1A1919',
																					fontStyle: 'normal',
																					fontWeight: 600,
																					margin: '7.5px 0',
																				}}
																			>
																				www.jamesco.com
																			</p>
																		</td>
																	</tr> */}
																		</tbody>
																	</table>
																</td>
																<td style={{ verticalAlign: 'top' }}>
																	<table
																		cellPadding={0}
																		cellSpacing={0}
																		width="400px"
																		className="m-col-width"
																		style={{
																			margin: 0,
																			width: '400px',
																			maxWidth: '400px',
																			border: '0',
																			whiteSpace: 'normal',
																			borderCollapse: 'collapse',
																			borderSpacing: 0,
																		}}
																	>
																		<tbody>
																			<tr>
																				<td
																					align="left"
																					colSpan={1}
																					className="m-col-width"
																					width="150px"
																					style={{
																						padding: 0,
																						verticalAlign: 'baseline',
																						width: '150px',
																						maxWidth: '150px',
																						minWidth: '150px',
																						lineHeight: 'normal',
																						whiteSpace: 'normal',
																						background: '#FFFFFF',
																					}}
																				>
																					<p
																						style={{
																							fontSize: '16px',
																							opacity: '0.8',
																							fontFamily: 'Open Sans,sans-serif',
																							color: '#1A1919',
																							fontStyle: 'normal',
																							fontWeight: 'normal',
																							margin: '7.5px 0',
																						}}
																					>
																						Order ID
																					</p>
																				</td>
																				<td
																					align="left"
																					colSpan={1}
																					width="100%"
																					style={{
																						padding: 0,
																						verticalAlign: 'baseline',
																						width: 26,
																						maxWidth: 26,
																						lineHeight: 'normal',
																						whiteSpace: 'normal',
																						background: '#FFFFFF',
																					}}
																				>
																					<p
																						style={{
																							fontSize: '16px',
																							fontFamily: 'Open Sans,sans-serif',
																							color: '#1A1919',
																							fontStyle: 'normal',
																							fontWeight: 'normal',
																							margin: '0 12px',
																						}}
																					>
																						:
																					</p>
																				</td>
																				<td
																					align="left"
																					colSpan={1}
																					width="100%"
																					style={{
																						padding: 0,
																						verticalAlign: 'baseline',
																						width: '100%',
																						maxWidth: '100%',
																						lineHeight: 'normal',
																						whiteSpace: 'normal',
																						background: '#FFFFFF',
																					}}
																				>
																					<p
																						style={{
																							fontSize: '16px',
																							fontFamily: 'Open Sans,sans-serif',
																							color: '#1A1919',
																							fontStyle: 'normal',
																							fontWeight: 600,
																							margin: '7.5px 0',
																						}}
																					>
																						{
																							data?.extension_attributes
																								?.supplier_order_increment_id
																						}
																					</p>
																				</td>
																			</tr>
																			<tr>
																				<td
																					align="left"
																					colSpan={1}
																					className="m-col-width"
																					width="150px"
																					style={{
																						padding: 0,
																						verticalAlign: 'baseline',
																						width: '150px',
																						maxWidth: '150px',
																						minWidth: '150px',
																						lineHeight: 'normal',
																						whiteSpace: 'normal',
																						background: '#FFFFFF',
																					}}
																				>
																					<p
																						style={{
																							fontSize: '16px',
																							opacity: '0.8',
																							fontFamily: 'Open Sans,sans-serif',
																							color: '#1A1919',
																							fontStyle: 'normal',
																							fontWeight: 'normal',
																							margin: '7.5px 0',
																						}}
																					>
																						Ordered Date
																					</p>
																				</td>
																				<td
																					align="left"
																					colSpan={1}
																					width="100%"
																					style={{
																						padding: 0,
																						verticalAlign: 'baseline',
																						width: 26,
																						maxWidth: 26,
																						lineHeight: 'normal',
																						whiteSpace: 'normal',
																						background: '#FFFFFF',
																					}}
																				>
																					<p
																						style={{
																							fontSize: '16px',
																							fontFamily: 'Open Sans,sans-serif',
																							color: '#1A1919',
																							fontStyle: 'normal',
																							fontWeight: 'normal',
																							margin: '0 12px',
																						}}
																					>
																						:
																					</p>
																				</td>
																				<td
																					align="left"
																					colSpan={1}
																					width="100%"
																					style={{
																						padding: 0,
																						verticalAlign: 'baseline',
																						width: '100%',
																						maxWidth: '100%',
																						lineHeight: 'normal',
																						whiteSpace: 'normal',
																						background: '#FFFFFF',
																					}}
																				>
																					<p
																						style={{
																							fontSize: '16px',
																							fontFamily: 'Open Sans,sans-serif',
																							color: '#1A1919',
																							fontStyle: 'normal',
																							fontWeight: 600,
																							margin: '7.5px 0',
																						}}
																					>
																						{moment(data?.created_at)
																							.format('DD MMM YYYY')
																							.toString()}
																					</p>
																				</td>
																			</tr>
																			<tr>
																				<td
																					align="left"
																					colSpan={1}
																					className="m-col-width"
																					width="150px"
																					style={{
																						padding: 0,
																						verticalAlign: 'baseline',
																						width: '150px',
																						maxWidth: '150px',
																						minWidth: '150px',
																						lineHeight: 'normal',
																						whiteSpace: 'normal',
																						background: '#FFFFFF',
																					}}
																				>
																					<p
																						style={{
																							fontSize: '16px',
																							opacity: '0.8',
																							fontFamily: 'Open Sans,sans-serif',
																							color: '#1A1919',
																							fontStyle: 'normal',
																							fontWeight: 'normal',
																							margin: '7.5px 0',
																						}}
																					>
																						Downloaded Date
																					</p>
																				</td>
																				<td
																					align="left"
																					colSpan={1}
																					width="100%"
																					style={{
																						padding: 0,
																						verticalAlign: 'baseline',
																						width: 26,
																						maxWidth: 26,
																						lineHeight: 'normal',
																						whiteSpace: 'normal',
																						background: '#FFFFFF',
																					}}
																				>
																					<p
																						style={{
																							fontSize: '16px',
																							fontFamily: 'Open Sans,sans-serif',
																							color: '#1A1919',
																							fontStyle: 'normal',
																							fontWeight: 'normal',
																							margin: '0 12px',
																						}}
																					>
																						:
																					</p>
																				</td>
																				<td
																					align="left"
																					colSpan={1}
																					width="100%"
																					style={{
																						padding: 0,
																						verticalAlign: 'baseline',
																						width: '100%',
																						maxWidth: '100%',
																						lineHeight: 'normal',
																						whiteSpace: 'normal',
																						background: '#FFFFFF',
																					}}
																				>
																					<p
																						style={{
																							fontSize: '16px',
																							fontFamily: 'Open Sans,sans-serif',
																							color: '#1A1919',
																							fontStyle: 'normal',
																							fontWeight: 600,
																							margin: '7.5px 0',
																						}}
																					>
																						{moment().format('DD MMM YYYY').toString()}
																					</p>
																				</td>
																			</tr>
																			<tr>
																				<td
																					align="left"
																					colSpan={1}
																					className="m-col-width"
																					width="150px"
																					style={{
																						padding: 0,
																						verticalAlign: 'baseline',
																						width: '150px',
																						maxWidth: '150px',
																						minWidth: '150px',
																						lineHeight: 'normal',
																						whiteSpace: 'normal',
																						background: '#FFFFFF',
																					}}
																				>
																					<p
																						style={{
																							fontSize: '16px',
																							opacity: '0.8',
																							fontFamily: 'Open Sans,sans-serif',
																							color: '#1A1919',
																							fontStyle: 'normal',
																							fontWeight: 'normal',
																							margin: '7.5px 0',
																						}}
																					>
																						Seller
																					</p>
																				</td>
																				<td
																					align="left"
																					colSpan={1}
																					width="100%"
																					style={{
																						padding: 0,
																						verticalAlign: 'baseline',
																						width: 26,
																						maxWidth: 26,
																						lineHeight: 'normal',
																						whiteSpace: 'normal',
																						background: '#FFFFFF',
																					}}
																				>
																					<p
																						style={{
																							fontSize: '16px',
																							fontFamily: 'Open Sans,sans-serif',
																							color: '#1A1919',
																							fontStyle: 'normal',
																							fontWeight: 'normal',
																							margin: '0 12px',
																						}}
																					>
																						:
																					</p>
																				</td>
																				<td
																					align="left"
																					colSpan={1}
																					width="100%"
																					style={{
																						padding: 0,
																						verticalAlign: 'baseline',
																						width: '100%',
																						maxWidth: '100%',
																						lineHeight: 'normal',
																						whiteSpace: 'normal',
																						background: '#FFFFFF',
																					}}
																				>
																					<p
																						style={{
																							fontSize: '16px',
																							fontFamily: 'Open Sans,sans-serif',
																							color: '#1A1919',
																							fontStyle: 'normal',
																							fontWeight: 600,
																							margin: '7.5px 0',
																						}}
																					>
																						{data?.extension_attributes?.supplier_name}
																					</p>
																				</td>
																			</tr>
																		</tbody>
																	</table>
																</td>
															</tr>
														</tbody>
													</table>

													<table
														cellPadding={0}
														cellSpacing={0}
														width="100%"
														className="m-col-width"
														style={{
															margin: 0,
															width: '100%',
															maxWidth: '100%',
															border: '0',
															whiteSpace: 'normal',
															borderCollapse: 'collapse',
															borderSpacing: 0,
														}}
													>
														<tbody>
															<tr>
																<td style={{ verticalAlign: 'top' }}>
																	<table
																		cellPadding={0}
																		cellSpacing={0}
																		width="100%"
																		className="m-col-width"
																		style={{
																			margin: 0,
																			width: '100%',
																			maxWidth: '100%',
																			border: '0',
																			whiteSpace: 'normal',
																			borderCollapse: 'collapse',
																			borderSpacing: 0,
																		}}
																	>
																		<tbody>
																			<tr>
																				<td
																					align="left"
																					colSpan={1}
																					width={290}
																					className="m-col-width"
																					style={{
																						padding: 0,
																						verticalAlign: 'baseline',
																						width: '230px',
																						maxWidth: '230px',
																						minWidth: '230px',
																						lineHeight: 'normal',
																						whiteSpace: 'normal',
																						background: '#FFFFFF',
																					}}
																				>
																					<p
																						style={{
																							fontSize: '16px',
																							opacity: '0.8',
																							fontFamily: 'Open Sans,sans-serif',
																							color: '#1A1919',
																							fontStyle: 'normal',
																							fontWeight: 'normal',
																							margin: '7.5px 0',
																							whiteSpace: 'nowrap',
																						}}
																					>
																						Order Status from Seller
																					</p>
																				</td>
																				<td
																					align="left"
																					colSpan={1}
																					width="100%"
																					style={{
																						padding: 0,
																						verticalAlign: 'baseline',
																						width: '26px',
																						maxWidth: '26px',
																						lineHeight: 'normal',
																						whiteSpace: 'normal',
																						background: '#FFFFFF',
																					}}
																				>
																					<p
																						style={{
																							fontSize: '16px',
																							fontFamily: 'Open Sans,sans-serif',
																							color: '#1A1919',
																							fontStyle: 'normal',
																							fontWeight: 'normal',
																							margin: '0 12px',
																						}}
																					>
																						:
																					</p>
																				</td>
																				<td
																					align="left"
																					colSpan={1}
																					width="100%"
																					style={{
																						padding: 0,
																						verticalAlign: 'baseline',
																						width: '100%',
																						maxWidth: '100%',
																						lineHeight: 'normal',
																						whiteSpace: 'normal',
																						background: '#FFFFFF',
																					}}
																				>
																					<p
																						style={{
																							fontSize: '16px',
																							fontFamily: 'Open Sans,sans-serif',
																							color: '#1A1919',
																							fontStyle: 'normal',
																							fontWeight: 600,
																							margin: '7.5px 0',
																						}}
																					>
																						{`${helperFunctions.toPascalCase(
																							data.status?.replace('_', ' ')
																						)}`}
																					</p>
																				</td>
																			</tr>
																			<tr>
																				<td
																					align="left"
																					colSpan={1}
																					width={290}
																					className="m-col-width"
																					style={{
																						padding: 0,
																						verticalAlign: 'baseline',
																						width: '230px',
																						maxWidth: '230px',
																						minWidth: '230px',
																						lineHeight: 'normal',
																						whiteSpace: 'normal',
																						background: '#FFFFFF',
																					}}
																				>
																					<p
																						style={{
																							fontSize: '16px',
																							opacity: '0.8',
																							fontFamily: 'Open Sans,sans-serif',
																							color: '#1A1919',
																							margin: '7.5px 0',
																							fontStyle: 'normal',
																							fontWeight: 'normal',
																							whiteSpace: 'nowrap',
																						}}
																					>
																						Order Comment from Seller
																					</p>
																				</td>
																				<td
																					align="left"
																					colSpan={1}
																					width="100%"
																					style={{
																						padding: 0,
																						verticalAlign: 'baseline',
																						width: '26px',
																						maxWidth: '26px',
																						lineHeight: 'normal',
																						whiteSpace: 'normal',
																						background: '#FFFFFF',
																					}}
																				>
																					<p
																						style={{
																							fontSize: '16px',
																							fontFamily: 'Open Sans,sans-serif',
																							color: '#1A1919',
																							fontStyle: 'normal',
																							fontWeight: 'normal',
																							margin: '0 12px',
																						}}
																					>
																						:
																					</p>
																				</td>
																				<td
																					align="left"
																					colSpan={1}
																					width="100%"
																					style={{
																						padding: 0,
																						verticalAlign: 'baseline',
																						width: '100%',
																						maxWidth: '100%',
																						lineHeight: 'normal',
																						whiteSpace: 'normal',
																						background: '#FFFFFF',
																					}}
																				>
																					<p
																						style={{
																							fontSize: '16px',
																							fontFamily: 'Open Sans,sans-serif',
																							color: '#1A1919',
																							fontStyle: 'normal',
																							margin: '7.5px 0',
																							fontWeight: 600,
																						}}
																					>
																						{reason}
																					</p>
																				</td>
																			</tr>
																		</tbody>
																	</table>
																</td>
															</tr>
														</tbody>
													</table>
												</td>
											</tr>
											{/*  summary*/}
											<tr>
												<td
													align="left"
													colSpan={1}
													width="100%"
													style={{
														width: '100%',
														maxWidth: '100%',
														lineHeight: 'normal',
														whiteSpace: 'normal',
														paddingTop: '25px',
														background: '#FFFFFF',
													}}
												>
													<table
														cellPadding={0}
														cellSpacing={0}
														width="100%"
														style={{
															margin: 0,
															width: '100%',
															maxWidth: '100%',
															border: '0',
															whiteSpace: 'normal',
															borderCollapse: 'collapse',
															borderSpacing: 0,
														}}
													>
														<tbody>
															<tr>
																<td>
																	<table
																		cellPadding={0}
																		cellSpacing={0}
																		width="100%"
																		style={{
																			margin: 0,
																			width: '100%',
																			maxWidth: '100%',
																			border: '0',
																			whiteSpace: 'normal',
																			borderCollapse: 'collapse',
																			borderSpacing: 0,
																		}}
																	>
																		<tbody>
																			<tr>
																				<td
																					align="left"
																					colSpan={1}
																					width={70}
																					className="m-col-width"
																					style={{
																						padding: 0,
																						verticalAlign: 'baseline',
																						width: '220px',
																						maxWidth: '220px',
																						lineHeight: 'normal',
																						whiteSpace: 'normal',
																						background: '#64A2F5',
																					}}
																				>
																					<p
																						style={{
																							fontSize: '16px',
																							fontFamily: 'Open Sans,sans-serif',
																							color: '#fff',
																							fontStyle: 'normal',
																							fontWeight: 700,
																							padding: '15px',
																							textTransform: 'uppercase',
																						}}
																					>
																						SKU:
																					</p>
																				</td>
																				<td
																					align="left"
																					colSpan={1}
																					width={240}
																					className="m-col-width"
																					style={{
																						padding: 0,
																						verticalAlign: 'baseline',
																						width: '220px',
																						maxWidth: '220px',
																						lineHeight: 'normal',
																						whiteSpace: 'normal',
																						background: '#64A2F5',
																					}}
																				>
																					<p
																						style={{
																							fontSize: '16px',
																							fontFamily: 'Open Sans,sans-serif',
																							color: '#fff',
																							fontStyle: 'normal',
																							fontWeight: 700,
																							padding: '15px',
																							textTransform: 'uppercase',
																						}}
																					>
																						Product Name
																					</p>
																				</td>
																				<td
																					align="left"
																					colSpan={1}
																					width="100%"
																					className="m-col-width"
																					style={{
																						padding: 0,
																						verticalAlign: 'baseline',
																						width: 100,
																						maxWidth: 100,
																						lineHeight: 'normal',
																						whiteSpace: 'normal',
																						background: '#64A2F5',
																					}}
																				>
																					<p
																						style={{
																							fontSize: '16px',
																							fontFamily: 'Open Sans,sans-serif',
																							color: '#fff',
																							fontStyle: 'normal',
																							fontWeight: 700,
																							padding: '15px',
																							textTransform: 'uppercase',
																							textAlign: 'right',
																						}}
																					>
																						Price
																					</p>
																				</td>
																				<td
																					align="left"
																					colSpan={1}
																					width="100%"
																					className="m-col-width"
																					style={{
																						padding: 0,
																						verticalAlign: 'baseline',
																						width: 100,
																						maxWidth: 100,
																						lineHeight: 'normal',
																						whiteSpace: 'normal',
																						background: '#64A2F5',
																					}}
																				>
																					<p
																						style={{
																							fontSize: '16px',
																							fontFamily: 'Open Sans,sans-serif',
																							color: '#fff',
																							fontStyle: 'normal',
																							fontWeight: 700,
																							padding: '15px',
																							textTransform: 'uppercase',
																							textAlign: 'right',
																						}}
																					>
																						Quantity
																					</p>
																				</td>
																				<td
																					align="left"
																					colSpan={1}
																					width="100%"
																					className="m-col-width"
																					style={{
																						padding: 0,
																						verticalAlign: 'baseline',
																						width: 100,
																						maxWidth: 100,
																						lineHeight: 'normal',
																						whiteSpace: 'normal',
																						background: '#64A2F5',
																					}}
																				>
																					<p
																						style={{
																							fontSize: '16px',
																							fontFamily: 'Open Sans,sans-serif',
																							color: '#fff',
																							fontStyle: 'normal',
																							fontWeight: 700,
																							padding: '15px',
																							textTransform: 'uppercase',
																							textAlign: 'right',
																						}}
																					>
																						Total Cost
																					</p>
																				</td>
																			</tr>
																			{data?.items
																				? data.items.map((item) => (
																						<tr>
																							<td
																								align="left"
																								colSpan={1}
																								width={240}
																								className="m-col-width"
																								style={{
																									padding: 0,
																									verticalAlign: 'baseline',
																									width: '220px',
																									maxWidth: '220px',
																									lineHeight: 'normal',
																									whiteSpace: 'normal',
																									background: '#fff',
																									borderBottom: '1px solid #BDBDBD',
																								}}
																							>
																								<p
																									style={{
																										fontSize: '16px',
																										fontFamily: 'Rubik,sans-serif',
																										color: '#1E1919',
																										fontStyle: 'normal',
																										fontWeight: 400,
																										padding: '15px',
																										textTransform: 'uppercase',
																									}}
																								>
																									{item.sku}
																								</p>
																							</td>
																							<td
																								align="left"
																								colSpan={1}
																								width={240}
																								className="m-col-width"
																								style={{
																									padding: 0,
																									verticalAlign: 'baseline',
																									width: '220px',
																									maxWidth: '220px',
																									lineHeight: 'normal',
																									whiteSpace: 'normal',
																									background: '#fff',
																									borderBottom: '1px solid #BDBDBD',
																								}}
																							>
																								<p
																									style={{
																										fontSize: '16px',
																										fontFamily: 'Rubik,sans-serif',
																										color: '#1E1919',
																										fontStyle: 'normal',
																										fontWeight: 400,
																										padding: '15px',
																										textTransform: 'uppercase',
																									}}
																								>
																									{item.name}
																								</p>
																							</td>
																							<td
																								align="left"
																								colSpan={1}
																								width="100%"
																								className="m-col-width"
																								style={{
																									padding: 0,
																									verticalAlign: 'baseline',
																									width: 100,
																									maxWidth: 100,
																									lineHeight: 'normal',
																									whiteSpace: 'normal',
																									background: '#fff',
																									borderBottom: '1px solid #BDBDBD',
																								}}
																							>
																								<p
																									style={{
																										fontSize: '16px',
																										fontFamily: 'Rubik,sans-serif',
																										color: '#1E1919',
																										fontStyle: 'normal',
																										fontWeight: 400,
																										padding: '15px',
																										textTransform: 'uppercase',
																										textAlign: 'right',
																									}}
																								>
																									{helperFunctions.formatAmount(item.price)}
																								</p>
																							</td>
																							<td
																								align="left"
																								colSpan={1}
																								width="100%"
																								className="m-col-width"
																								style={{
																									padding: 0,
																									verticalAlign: 'baseline',
																									width: 100,
																									maxWidth: 100,
																									lineHeight: 'normal',
																									whiteSpace: 'normal',
																									background: '#fff',
																									borderBottom: '1px solid #BDBDBD',
																								}}
																							>
																								<p
																									style={{
																										fontSize: '16px',
																										fontFamily: 'Rubik,sans-serif',
																										color: '#1E1919',
																										fontStyle: 'normal',
																										fontWeight: 400,
																										padding: '15px',
																										textTransform: 'uppercase',
																										textAlign: 'right',
																									}}
																								>
																									{item.qty_ordered}
																									{/* item.qty_canceled +
																								item.qty_invoiced +
																								item.qty_refunded +
																								item.qty_shipped */}
																								</p>
																							</td>
																							<td
																								align="left"
																								colSpan={1}
																								width="100%"
																								className="m-col-width"
																								style={{
																									padding: 0,
																									verticalAlign: 'baseline',
																									width: 100,
																									maxWidth: 100,
																									lineHeight: 'normal',
																									whiteSpace: 'normal',
																									background: '#fff',
																									borderBottom: '1px solid #BDBDBD',
																								}}
																							>
																								<p
																									style={{
																										fontSize: '16px',
																										fontFamily: 'Rubik,sans-serif',
																										color: '#1E1919',
																										fontStyle: 'normal',
																										fontWeight: 400,
																										padding: '15px',
																										textTransform: 'uppercase',
																										textAlign: 'right',
																									}}
																								>
																									{helperFunctions.formatAmount(item.row_total)}
																								</p>
																							</td>
																						</tr>
																				  ))
																				: null}
																		</tbody>
																	</table>
																</td>
															</tr>
														</tbody>
													</table>
												</td>
											</tr>
											{/*   total*/}
											<tr>
												<td
													align="left"
													colSpan={1}
													width="100%"
													style={{
														width: '100%',
														maxWidth: '100%',
														lineHeight: 'normal',
														whiteSpace: 'normal',
														background: '#FFFFFF',
													}}
												>
													<table
														cellPadding={0}
														cellSpacing={0}
														width="100%"
														style={{
															margin: 0,
															width: '100%',
															maxWidth: '100%',
															border: '0',
															whiteSpace: 'normal',
															borderCollapse: 'collapse',
															borderSpacing: 0,
														}}
													>
														<tbody>
															<tr>
																<td>
																	<table
																		cellPadding={0}
																		cellSpacing={0}
																		width="100%"
																		style={{
																			margin: 0,
																			width: '100%',
																			maxWidth: '100%',
																			border: '0',
																			whiteSpace: 'normal',
																			borderCollapse: 'collapse',
																			borderSpacing: 0,
																		}}
																	>
																		<tbody>
																			{numbers === (i+1) ? (
																				<>
																					<tr>
																						<td
																							align="left"
																							colSpan={1}
																							width={70}
																							className="m-col-width"
																							style={{
																								padding: 0,
																								verticalAlign: 'baseline',
																								width: 100,
																								maxWidth: 100,
																								lineHeight: 'normal',
																								whiteSpace: 'normal',
																								background: '#fff',
																							}}
																						>
																							<p
																								style={{
																									fontSize: '16px',
																									fontFamily: 'Rubik,sans-serif',
																									color: '#1E1919',
																									fontStyle: 'normal',
																									fontWeight: 400,
																									padding: '15px',
																									textTransform: 'uppercase',
																									margin: '7.5px 0',
																								}}
																							/>
																						</td>
																						<td
																							align="left"
																							colSpan={1}
																							width={240}
																							className="m-col-width"
																							style={{
																								padding: 0,
																								verticalAlign: 'baseline',
																								width: 100,
																								maxWidth: 100,
																								lineHeight: 'normal',
																								whiteSpace: 'normal',
																								background: '#fff',
																							}}
																						>
																							<p
																								style={{
																									fontSize: '16px',
																									fontFamily: 'Rubik,sans-serif',
																									color: '#1E1919',
																									fontStyle: 'normal',
																									fontWeight: 400,
																									padding: '15px',
																									textTransform: 'uppercase',
																									margin: '7.5px 0',
																								}}
																							/>
																						</td>
																						<td
																							align="right"
																							colSpan={2}
																							width="100%"
																							className="m-col-width"
																							style={{
																								padding: 0,
																								verticalAlign: 'baseline',
																								width: 100,
																								maxWidth: 100,
																								lineHeight: 'normal',
																								whiteSpace: 'normal',
																								background: '#fff',
																							}}
																						>
																							<p
																								style={{
																									fontSize: '16px',
																									fontFamily: 'Rubik,sans-serif',
																									color: '#1E1919',
																									fontStyle: 'normal',
																									fontWeight: 400,
																									padding: '0 15px',
																									textTransform: 'uppercase',
																									margin: '7.5px 0',
																								}}
																							>
																								Order Sub-Total
																							</p>
																						</td>
																						<td
																							align="left"
																							colSpan={1}
																							width={'250px'}
																							className="m-col-width"
																							style={{
																								padding: 0,
																								verticalAlign: 'baseline',
																								width: '250px',
																								maxWidth: '250px',
																								lineHeight: 'normal',
																								whiteSpace: 'normal',
																								background: '#fff',
																							}}
																						>
																							<p
																								style={{
																									textAlign:'left',
																									fontSize: '16px',
																									fontFamily: 'Rubik,sans-serif',
																									color: '#1E1919',
																									fontStyle: 'normal',
																									fontWeight: 400,
																									padding: '0 15px',
																									textTransform: 'uppercase',
																									margin: '7.5px 0',
																								}}
																							>
																								{helperFunctions.formatAmount(data?.subtotal)}
																							</p>
																						</td>
																					</tr>

																					<tr>
																						<td
																							align="left"
																							colSpan={1}
																							width={70}
																							className="m-col-width"
																							style={{
																								padding: 0,
																								verticalAlign: 'baseline',
																								width: '100px',
																								maxWidth: '100px',
																								lineHeight: 'normal',
																								whiteSpace: 'normal',
																								background: '#fff',
																							}}
																						>
																							<p
																								style={{
																									fontSize: '16px',
																									fontFamily: 'Rubik,sans-serif',
																									color: '#1E1919',
																									fontStyle: 'normal',
																									fontWeight: 400,
																									padding: '0 15px',
																									textTransform: 'uppercase',
																									margin: '7.5px 0',
																								}}
																							/>
																						</td>
																						<td
																							align="left"
																							colSpan={1}
																							width={240}
																							className="m-col-width"
																							style={{
																								padding: 0,
																								verticalAlign: 'baseline',
																								width: '100px',
																								maxWidth: '100px',
																								lineHeight: 'normal',
																								whiteSpace: 'normal',
																								background: '#fff',
																							}}
																						>
																							<p
																								style={{
																									fontSize: '16px',
																									fontFamily: 'Rubik,sans-serif',
																									color: '#1E1919',
																									fontStyle: 'normal',
																									fontWeight: 400,
																									padding: '0 15px',
																									textTransform: 'uppercase',
																									margin: '7.5px 0',
																								}}
																							/>
																						</td>
																						<td
																							align="right"
																							colSpan={2}
																							width="100%"
																							className="m-col-width"
																							style={{
																								verticalAlign: 'baseline',
																								paddingBottom: '10px',
																								width: '100px',
																								maxWidth: '100px',
																								lineHeight: 'normal',
																								whiteSpace: 'normal',
																								background: '#fff',
																							}}
																						>
																							<p
																								style={{
																									fontSize: '16px',
																									fontFamily: 'Rubik,sans-serif',
																									color: '#1E1919',
																									fontStyle: 'normal',
																									fontWeight: 400,
																									padding: '0 15px',
																									textTransform: 'uppercase',
																									margin: '7.5px 0',
																								}}
																							>
																								Tax
																							</p>
																						</td>
																						<td
																							align="left"
																							colSpan={1}
																							width={'250px'}
																							className="m-col-width"
																							style={{
																								padding: 0,
																								verticalAlign: 'baseline',
																								width: '250px',
																								maxWidth: '250px',
																								lineHeight: 'normal',
																								whiteSpace: 'normal',
																								background: '#fff',
																							}}
																						>
																							<p
																								style={{
																									textAlign:'left',
																									fontSize: '16px',
																									fontFamily: 'Rubik,sans-serif',
																									color: '#1E1919',
																									fontStyle: 'normal',
																									fontWeight: 400,
																									padding: '0 15px',
																									textTransform: 'uppercase',
																									margin: '7.5px 0',
																								}}
																							>
																								{helperFunctions.formatAmount(data?.tax_amount)}
																							</p>
																						</td>
																					</tr>

																					<br />
																					<br />
																					{/* <tr>
																		<td
																			align="left"
																			colSpan={1}
																			width={70}
																			className="m-col-width"
																			style={{
																				padding: 0,
																				verticalAlign: 'baseline',
																				width: '100px',
																				maxWidth: '100px',
																				lineHeight: 'normal',
																				whiteSpace: 'normal',
																				background: '#fff',
																			}}
																		>
																			<p
																				style={{
																					fontSize: '16px',
																					fontFamily: 'Rubik,sans-serif',
																					color: '#1E1919',
																					fontStyle: 'normal',
																					fontWeight: 400,
																					padding: '0 15px',
																					textTransform: 'uppercase',
																					margin: '7.5px 0',
																				}}
																			/>
																		</td>
																		<td
																			align="left"
																			colSpan={1}
																			width={240}
																			className="m-col-width"
																			style={{
																				padding: 0,
																				verticalAlign: 'baseline',
																				width: '100px',
																				maxWidth: '100px',
																				lineHeight: 'normal',
																				whiteSpace: 'normal',
																				background: '#fff',
																			}}
																		>
																			<p
																				style={{
																					fontSize: '16px',
																					fontFamily: 'Rubik,sans-serif',
																					color: '#1E1919',
																					fontStyle: 'normal',
																					fontWeight: 400,
																					padding: '0 15px',
																					textTransform: 'uppercase',
																					margin: '7.5px 0',
																				}}
																			/>
																		</td>
																		<td
																			align="right"
																			colSpan={2}
																			width="100%"
																			className="m-col-width"
																			style={{
																				padding: 0,
																				verticalAlign: 'baseline',
																				width: '100px',
																				maxWidth: '100px',
																				lineHeight: 'normal',
																				whiteSpace: 'normal',
																				background: '#fff',
																			}}
																		>
																			<p
																				style={{
																					fontSize: '16px',
																					fontFamily: 'Rubik,sans-serif',
																					color: '#1E1919',
																					fontStyle: 'normal',
																					fontWeight: 400,
																					padding: '0 15px',
																					textTransform: 'uppercase',
																					margin: '7.5px 0',
																				}}
																			>
																				Delivery Cost
																			</p>
																		</td>
																		<td
																			align="left"
																			colSpan={1}
																			width={'140px'}
																			className="m-col-width"
																			style={{
																				padding: 0,
																				verticalAlign: 'baseline',
																				width: '140px',
																				maxWidth: '140px',
																				lineHeight: 'normal',
																				whiteSpace: 'normal',
																				background: '#fff',
																			}}
																		>
																			<p
																				style={{
																					fontSize: '16px',
																					fontFamily: 'Rubik,sans-serif',
																					color: '#1E1919',
																					fontStyle: 'normal',
																					fontWeight: 400,
																					padding: '0 15px',
																					textTransform: 'uppercase',
																					margin: '7.5px 0',
																				}}
																			>
																				$8
																			</p>
																		</td>
																	</tr> */}
																					{/* <tr>
																		<td
																			align="left"
																			colSpan={1}
																			width="100%"
																			className="m-col-width"
																			style={{
																				padding: 0,
																				verticalAlign: 'baseline',
																				width: '100px',
																				maxWidth: '100px',
																				lineHeight: 'normal',
																				whiteSpace: 'normal',
																				background: '#fff',
																			}}
																		>
																			<p
																				style={{
																					fontSize: '16px',
																					fontFamily: 'Rubik,sans-serif',
																					color: '#1E1919',
																					fontStyle: 'normal',
																					fontWeight: 400,
																					padding: '15px',
																					textTransform: 'uppercase',
																					margin: '7.5px 0',
																				}}
																			>
																				<br />
																			</p>
																		</td>
																	</tr> */}
																					<tr>
																						<td
																							align="left"
																							colSpan={1}
																							width="100%"
																							className="m-col-width"
																							style={{
																								padding: 0,
																								verticalAlign: 'middle',
																								width: '100px',
																								maxWidth: '100px',
																								lineHeight: 'normal',
																								whiteSpace: 'normal',
																								background: '#f5f5f5',
																								height: '80px',
																							}}
																						>
																							<p
																								style={{
																									fontSize: '16px',
																									fontFamily: 'Rubik,sans-serif',
																									color: '#1E1919',
																									fontStyle: 'normal',
																									fontWeight: 400,
																									padding: '15px',
																									textTransform: 'uppercase',
																									margin: '7.5px 0',
																								}}
																							/>
																						</td>
																						<td
																							align="right"
																							colSpan={3}
																							width="100%"
																							className="m-col-width"
																							style={{
																								padding: 0,
																								verticalAlign: 'middle',
																								width: '100px',
																								maxWidth: '100px',
																								lineHeight: 'normal',
																								whiteSpace: 'normal',
																								background: '#f5f5f5',
																								height: '80px',
																							}}
																						>
																							<p
																								style={{
																									fontSize: '21px',
																									fontFamily: 'Rubik,sans-serif',
																									color: '#333333',
																									fontStyle: 'normal',
																									fontWeight: 'normal',
																									padding: '15px',
																									textTransform: 'uppercase',
																									margin: 0,
																								}}
																							>
																								Total amount to be paid
																							</p>
																						</td>
																						<td
																							align="left"
																							colSpan={1}
																							width={'250px'}
																							className="m-col-width"
																							style={{
																								padding: 0,
																								verticalAlign: 'middle',
																								width: '250px',
																								maxWidth: '250px',
																								lineHeight: 'normal',
																								whiteSpace: 'normal',
																								background: '#f5f5f5',
																								height: '80px',
																							}}
																						>
																							<p
																								style={{
																									textAlign:'left',
																									fontSize: '29px',
																									fontFamily: 'Rubik,sans-serif',
																									color: '#64A2F5',
																									wordBreak: 'break-all',
																									fontStyle: 'normal',
																									fontWeight: 700,
																									padding: '15px',
																									textTransform: 'uppercase',
																									margin: 0,
																								}}
																							>
																								{helperFunctions.formatAmount(data?.total_due)}
																							</p>
																						</td>
																					</tr>
																				</>
																			) : null}
																			<tr>
																				<td
																					colSpan={5}
																					align="center"
																					className="m-col-width"
																					width="100%"
																					style={{
																						padding: 0,
																						verticalAlign: 'middle',
																						width: '100px',
																						maxWidth: '100px',
																						lineHeight: 'normal',
																						whiteSpace: 'normal',
																						height: '80px',
																					}}
																				>
																					<p
																						style={{
																							fontSize: '16px',
																							fontFamily: 'Open Sans,sans-serif',
																							fontStyle: 'normal',
																							fontWeight: 700,
																							padding: '15px',
																							textTransform: 'capitalize',
																							margin: '7.5px 0',
																						}}
																					>
																						<br />
																						Thank you for doing business with us.
																					</p>
																				</td>
																			</tr>
																			<tr>
																				<td
																					colSpan={5}
																					align="center"
																					width="100%"
																					className="m-col-width"
																					style={{
																						padding: 0,
																						verticalAlign: 'middle',
																						width: '100px',
																						maxWidth: '100px',
																						lineHeight: 'normal',
																						whiteSpace: 'normal',
																						height: '80px',
																					}}
																				>
																					<p
																						style={{
																							fontSize: '16px',
																							fontFamily: 'Open sans,sans-serif,sans-serif',
																							color: '#434242',
																							fontStyle: 'normal',
																							fontWeight: 400,
																							padding: '15px',
																							textTransform: 'capitalize',
																							margin: '7.5px 0',
																						}}
																					>
																						<span
																							style={{
																								marginBottom: '16px',
																								display: 'block',
																							}}
																						>
																							Powered by
																							<br />
																						</span>
																						<img src={logo} alt="experion" style={{ width: '100px' }} />
																					</p>
																				</td>
																			</tr>
																		</tbody>
																	</table>
																</td>
															</tr>
														</tbody>
													</table>
												</td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr>
						</tbody>
					</table>
				</MainContent>
			))}
		</>
	) : (
		<></>
	);
}

export default Order;
