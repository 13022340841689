import React, { ReactElement, ReactNode } from 'react';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';
import { Button } from 'ui/components';
import * as Icon from 'ui/icons';

const StyledDropdownButton = styled.summary``;

type DropdownButtonProps = {
	children?: string | ReactNode;
	role?: string;
	onClick?: () => void;
	tabIndex?: number;
	hasNotification?: boolean;
};

function DropdownButton({
	children,
	role,
	onClick,
	tabIndex,
	hasNotification = false,
}: DropdownButtonProps): ReactElement {
	return (
		<StyledDropdownButton tabIndex={tabIndex} as="summary" onClick={onClick} role={role}>
			<Tooltip arrow title="Notification" animation="perspective" position="bottom">
				<Button iconBefore={<Icon.notificationIcon title="cartIcon" />} variant="cartButton">
					<Box
						className={hasNotification ? 'badge-count' : ''}
						color="#fff"
						ml="0 !important"
						py="3px"
						height="22px"
					></Box>
				</Button>
			</Tooltip>
		</StyledDropdownButton>
	);
}

export default DropdownButton;
