import styled from 'styled-components';

const PendingOrderHolder = styled.div`
	table {
		width: 100%;
	}
	@media (max-width: 1300px) {
		overflow-x: auto;
		table {
			width: 142vw;
		}
	}
	@media (max-width: 960px) {
		table {
			width: 154vw;
		}
	}
	@media (max-width: 768px) {
		table {
			width: 100%;
		}
	}
	table {
		thead,
		tbody {
			tr {
				display: flex;
				align-items: center;
				td,
				th {
					text-align: left;
					width: 100%;
					padding: 0;
					background: ${({ theme }) => theme.colors.bg.white};
					white-space: nowrap;
					text-overflow: ellipsis;
					margin-right: auto;
					h1 {
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
						display: flex;
						align-items: center;
						max-width: 300px;
						span {
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
						}
						/* &:hover {
							color: ${({ theme }) => theme.baseColors.primary};
						} */
						img {
							margin-right: 23px;
							flex: 0 0 55px;
						}
					}
					&:nth-child(1) {
						flex: 0 0 50px;
						max-width: 50px;
						margin-right: 0;
						@media screen and (max-width: 767px) {
							flex: 0 0 100% !important;
							max-width: 100% !important;
							margin-bottom: 15px;
						}
						input {
							&:focus {
								~ svg {
									& + svg {
										background: none !important;
									}
								}
								svg {
									background: none !important;
								}
							}
						}
						svg {
							color: ${({ theme }) => theme.baseColors.primary};
							background-color: transparent;
							& + svg {
								color: ${({ theme }) => theme.colors.border.grayMedium02};
							}
						}
					}
					&:nth-child(2) {
						flex: 0 0 350px;
						max-width: 350px;
						@media screen and (max-width: 767px) {
							flex: 0 0 100% !important;
							max-width: 100% !important;
							margin-bottom: 15px;
						}
					}
					&.status {
						flex: 0 0 100px;
						max-width: 100px;
						text-align: center;
						margin-right: 0;
						@media screen and (max-width: 767px) {
							label {
								margin-top: 8px;
							}
						}
					}
					&.action {
						flex: 0 0 150px;
						max-width: 150px;
						text-align: center;
						margin-right: 0;
						div {
							display: flex;
							align-items: center;
							justify-content: center;
							@media screen and (max-width: 767px) {
								justify-content: start;
								button {
									margin-left: 0;
									margin-right: 10px;
								}
							}
						}
						button {
							width: 34px;
							height: 34px;
							border-radius: 8px;
							padding: 0;
							margin-left: 10px;
							svg {
								width: 15px;
								height: 17px;
							}
						}
					}
				}
			}
		}
		thead {
			@media (max-width: 768px) {
				display: none;
			}
			tr {
				border: 1px solid transparent;
				box-sizing: border-box;
				border-radius: 8px;
				padding: 30px 16px;
				th {
					font-size: 14px;
					line-height: 18px;
					font-family: 'OpenSansBold';
					text-transform: uppercase;
					&.statusFilter {
						position: relative;
						display: flex;
						align-items: center;
						justify-content: center;
						div {
							position: absolute;
							right: 0;
							button {
								margin-left: 0;
							}
						}
					}
				}
			}
		}
		tbody {
			display: block;
			    min-height: max-content;
			margin-bottom: 50px;
			@media screen and (max-width: 768px) {
				max-height: 100%;
				min-height: auto;
			}
			tr {
				border: 1px solid ${({ theme }) => theme.colors.border.userListTD};
				box-sizing: border-box;
				box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07);
				border-radius: 8px;
				padding: 14px 16px;
				margin-bottom: 15px;
				position: relative;
				@media screen and (max-width: 768px) {
					flex-wrap: wrap;
				}
				&.row-highlighted {
					background: rgba(125, 55, 111, 0.1);
					border: 1px solid rgba(125, 55, 111, 0.2);
					td {
						background: transparent !important;
					}
				}
				&.row-delayed {
					background: ${({ theme }) => theme.colors.bg.white};
					border: 1px solid ${({ theme }) => theme.colors.text.error01};
					background: url(${require('../../images/warning_outline.png')});
					background-repeat: no-repeat;
					background-position: right 8px center;
					td {
						background: transparent !important;
					}
				}
				td {
					@media screen and (max-width: 768px) {
						position: relative !important;
						padding-left: 0 !important;
						text-align: left !important;
						white-space: pre-wrap !important;
						overflow-wrap: break-word !important;
						display: flex !important;
						flex-direction: column !important;
						width: 50% !important;
						flex: 0 0 50% !important;
						max-width: 50% !important;
						margin-bottom: 15px;

						&::before {
							content: attr(data-label);
							position: relative;
							display: block;
							font-family: 'OpenSansBold';
							text-transform: uppercase;
							width: 100%;
							left: 0;
							font-size: 14px;
							line-height: 16px;
							white-space: pre-wrap;
							overflow-wrap: break-word;
							text-align: left !important;
							margin-bottom: 4px;
						}
					}
					@media screen and (max-width: 480px) {
						width: 100% !important;
						flex: 0 0 100% !important;
						max-width: 100% !important;
						&.status,
						&.action {
							flex: 0 0 50% !important;
							max-width: 50% !important;
							border-top: 1px solid ${({ theme }) => theme.colors.border.userListTD};
							padding-top: 15px;
							margin-bottom: 0 !important;
							min-height: 70px;
						}
						&:nth-child(1) {
							position: absolute !important;
							top: 7px;
							right: 18px;
							width: 20px !important;
							z-index: 1;
							margin-bottom: 0 !important;
						}
					}
					slot {
						max-width: 110px;
						flex: 0 0 110px;
						font-size: 13px;
						line-height: 17px;
						display: flex;
						align-items: center;
						justify-content: center;
						margin: 0 auto;
						@media screen and (max-width: 767px) {
							flex: 0;
							margin: 0 auto 10px 0;
							width: 100px;
							min-height: 30px;
						}
					}
				}
			}
		}
	}
`;
const PageSearchHolder = styled.div`
	width: 100%;
	padding-top: 20px;
	padding-bottom: 25px;
	display: flex;
	justify-content: space-between;
	section {
		width: 650px;
		@media screen and (max-width: 767px) {
			width: auto;
			flex: 1;
		}
	}
	small {
		button {
			@media screen and (max-width: 960px) {
				width: 60px;
				flex: 0 0 60px;
				max-width: 60px;
				margin-left: 20px;
				height: 50px;
			}

			div {
				width: auto;
				@media screen and (max-width: 960px) {
					display: none;
				}
			}
			@media screen and (max-width: 960px) {
				svg {
					margin-right: 0;
				}
			}
		}
		& + div {
			display: none;
			@media screen and (max-width: 767px) {
				display: Flex;
				margin-left: 20px;
				width: 60px;

				.pr-icon {
					width: 50px;
					height: 50px;
					background: ${({ theme }) => theme.baseColors.primary};
					border-radius: 6px;
					display: flex;
					align-items: center;
					justify-content: center;
					svg {
						path {
							fill: ${({ theme }) => theme.baseColors.white};
						}
					}
				}
				button {
					padding: 0;
					& + div {
						margin-top: 70px !important;
						right: 8px !important;
					}
				}
			}
		}
	}
	@media (max-width: 1300px) {
		width: 142vw;
	}
	@media (max-width: 960px) {
		width: 154vw;
	}

	@media screen and (max-width: 767px) {
		width: 100%;
	}
`;
const FilterHolder = styled.div`
	display: flex;
`;
export { PendingOrderHolder, PageSearchHolder, FilterHolder };
