import styled from 'styled-components';
const PageSearchHolder = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	margin: 10px 0 10px 40px;
	@media (max-width: 1440px) {
		margin-left: 20px;
	}
	@media (max-width: 960px) {
		margin: 0 15px !important;
		max-width: 100%;
		width: auto !important;
	}
	& > div {
		flex: 0 0 130px;
		position: relative;
		svg {
			position: absolute;
			right: 0;
		}
		@media (max-width: 1440px) {
			flex: 0 0 110px;
		}
		@media (max-width: 960px) {
			flex: 0 0 115px;
		}
	}
	.PageSearchCategory {
		width: 100%;
		width: 100%;
		height: 40px;
		font-size: 14px;
		border-radius: 6px 0 0 6px;
		border: 1px solid ${({ theme }) => theme.colors.bg.lightYellow05};
		border-right: 0 !important;
		margin: 0 0 0 0;
		background: ${({ theme }) => theme.colors.bg.grayLight};
		color: ${({ theme }) => theme.colors.text.black03};
		&:focus {
			outline: none;
			box-shadow: none;
		}
	}
	.PageSearchLocation {
		position: absolute;
		top: 95%;
		height: 10px;
	}
	.PageSearchContainer {
		position: relative;
		height: auto;
		z-index: 999;
		padding: 0 15px;
		background: ${({ theme }) => theme.colors.bg.white};
		cursor: pointer;
	}
	.PageLocationSearch {
		position: relative;
		z-index: 999;
		left: 10px;
		cursor: pointer;
	}
	.PageSearchOptions {
		background-color: ${({ theme }) => theme.colors.bg.white};
		padding: 5px 0;
	}
	input + span {
		position: absolute;
		right: 14px;
		top: 12px;
	}
	svg {
		path {
			fill: ${({ theme }) => theme.colors.text.tabHeading}!important;
		}
	}
	input[type='search']::-ms-clear {
		display: none;
		width: 0;
		height: 0;
	}
	input[type='search']::-ms-reveal {
		display: none;
		width: 0;
		height: 0;
	}
	input[type='search']::-webkit-search-decoration,
	input[type='search']::-webkit-search-cancel-button,
	input[type='search']::-webkit-search-results-button,
	input[type='search']::-webkit-search-results-decoration {
		display: none;
	}
`;

const PageSearch = styled.input`
	background: ${({ theme }) => theme.colors.bg.white};
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	width: 100%;
	height: 40px;
	border-radius: 0 6px 6px 0;
	border: 1px solid ${({ theme }) => theme.colors.bg.lightYellow05};
	outline: none;
	box-shadow: none;
	padding: 0 40px 0 16px;
	font-family: 'OpenSansMedium';
	font-size: 16px;
	&::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: ${({ theme }) => theme.baseColors.baseYellow}!important;
		font-size: 14px;
	}
	&::-moz-placeholder {
		/* Firefox 19+ */
		color: ${({ theme }) => theme.baseColors.baseYellow}!important;
		font-size: 14px;
	}
	&:-ms-input-placeholder {
		/* IE 10+ */
		color: ${({ theme }) => theme.baseColors.baseYellow}!important;
		font-size: 14px;
	}
	&:-moz-placeholder {
		/* Firefox 18- */
		color: ${({ theme }) => theme.baseColors.baseYellow}!important;
		font-size: 14px;
	}
	& + input {
		position: absolute;
		right: 0;
		opacity: 0;
	}
`;

export { PageSearch, PageSearchHolder };
