import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { StyledFormInput } from '../textInput';

type TextareaProps = {
	value?: string;
	onTextChange?: (text: string) => void;
	placeholder?: string;
	id?: string;
	disabled?: boolean;
	readonly?: boolean;
	validation?: string;
	rows?: number;
	cols?: number;
	register?: ({ required }: { required?: boolean }) => RefReturn;
	required?: boolean;
	maxLength?:number;
};

type RefReturn =
	| string
	| ((instance: HTMLInputElement | null) => void)
	| React.RefObject<HTMLInputElement>
	| null
	| undefined;

export const StyledTextarea = styled(StyledFormInput)`
	height: auto;
`;
const Textarea = ({
	placeholder,
	value,
	id,
	disabled,
	validation,
	rows,
	cols,
	register,
	required,
	readonly,
	onTextChange,
	maxLength,
}: TextareaProps): ReactElement => (
	<StyledTextarea
		as="textarea"
		placeholder={placeholder}
		value={value}
		id={id}
		disabled={disabled}
		validation={validation}
		rows={rows}
		cols={cols}
		// ref={register && register({ required })}
		name={id}
		onChange={e => onTextChange && onTextChange(e.currentTarget.value)}
		readonly={readonly}
		maxLength = {maxLength}
	/>
);

export { Textarea };
