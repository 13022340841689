import React, { ReactElement, ReactNode } from 'react';
import { Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { Button } from 'ui/components';
import * as Icon from 'ui/icons';


const StyledDropdownButton = styled.summary`
	button {
		font-weight: normal !important;
		text-transform: capitalize;
	}
	span {
		color: rgba(36, 43, 36, 1);
		display: flex;
		align-items: center;
		label {
			margin-right: 6px;
			font-size: 16px;
			cursor: pointer;

		}
		svg {
			height: auto;
			width: 8px;
			margin-top: 3px;
		}
	}
`;

type DropdownButtonProps = {
	children?: string | ReactNode;
	role?: string;
	onClick?: () => void;
	tabIndex?: number;
};

function DropdownButton({ children, role, onClick, tabIndex }: DropdownButtonProps): ReactElement {
	return (
		<>
			<StyledDropdownButton tabIndex={tabIndex} as="summary" onClick={onClick} role={role}>
				{/* <Button variant="cartButton">
					<Text as="label">Quick Access</Text>
					<Icon.Options width="2em" />
				</Button> */}
			</StyledDropdownButton>
		</>
	);
}

export default DropdownButton;
