import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { Sidebar, FormGroup, FormInput } from 'ui/patterns';
import { Text, Box } from 'rebass/styled-components';
import { Checkbox, Label, Select } from '@rebass/forms';
import { BreadcrumbNav, Footer, Header, Stack, Button, Loader } from 'ui/components';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { TableList, Grid, FormLabel } from 'ui/components';
import {
	UserListHolder,
	MainContent,
	BreadcrumbHolder,
	FilterHolder,
	ReportNameBlock,
} from './styles';
import * as Icon from 'ui/icons';
import icons from 'ui/icons';
import queryString from 'query-string';
import helperFunctions from 'utils/helper.functions';
import {
	Column,
	useExpanded,
	useFilters,
	useGlobalFilter,
	useAsyncDebounce,
	useSortBy,
	useTable,
} from 'react-table';
import hooks from 'utils/requestCtx/reactQueryHooks';
import DatePicker from 'react-multi-date-picker';
import Ipost from 'models/Ipost';
import constants from 'utils/constants';
import 'simplebar/dist/simplebar.min.css';
import utilityFunctions from 'utils/utility.functions';
import SimpleBar from 'simplebar-react';
import moment from 'moment';
import { UserBody } from 'admin/dashboardVenue/styles';
import { useHistory, useLocation } from 'react-router-dom';
import notify from 'utils/toast.service';
import { dcUserPermissionList, permissionGroup } from 'models/enum-constants/common.constants';
import { Tooltip } from 'react-tippy';
import { math } from 'polished';

function ProductSalesView(): ReactElement {
	const history = useHistory();
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const [List, updateList] = useState<any>([]);
	const [type, setType] = useState('');
	const [dateRange, setDateRange] = useState('');
	const [groupByArray, setGroupByArray] = useState([] as any);
	const [mutatePost] = hooks.useMutation('reportGeneration');
	const CUSTOMER_TOKEN = localStorage.getItem('customerToken'); //token changed
	const supplierId = parseInt(localStorage.getItem('supplierId')!);
	const dcId = parseInt(localStorage.getItem('selectedLocation')!);
	const userId = parseInt(localStorage.getItem('userId')!);
	const [reportName, setReportName] = useState('Report');
	const location = useLocation();
	let parsed = queryString.parse(location.search);
	const [isReportGenerated, setIsReportGenerated] = useState(false);
	const [startDate, setStartDate] = useState<any>(new Date());
	const [endDate, setEndDate] = useState<any>(new Date());
	const [reportData, setReportData] = useState({
		reportType: '',
		dateRange: '',
		groupBy: '',
		fromDate: new Date().toString(),
		ToDate: new Date().toString(),
	});
	const dateFormat = 'DD/MM/YYYY';
	const [groupBy, setGroupBy] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [Columns, setColumns] = useState([] as any);
	const [isNewReport, setIsNewReport] = useState(true);

	const breadcrumbNav = [
		{ id: '01', label: 'Dashboard', route: 'seller-dashboard', active: false },
		{ id: '02', label: reportName, active: true },
	];

	let productViewsColumns = React.useMemo(
		() => [
			{
				Header: groupBy,
				accessor: 'period'
			},
			{
				Header: 'Product',
				accessor: 'product_name', // accessor is the "key" in the data
			},
			{
				Header: 'SKU',
				accessor: 'sku',
			},
			{
				Header: 'Views',
				accessor: 'views_num',
				Cell: (props) => <div style={{ textAlign: 'right' }}> {props.value} </div>,
			},
		],
		[groupBy]
	);
	let salesColumns = React.useMemo(
		() => [
			{
				Header: groupBy,
				accessor: 'period'
			},
			{
				Header: 'Orders',
				accessor: 'orders', // accessor is the "key" in the data
				Cell: (props) => <div style={{ textAlign: 'right' }}> {props.value} </div>,
			},
			{
				Header: 'Items Sold',
				accessor: 'sales items',
				Cell: (props) => <div style={{ textAlign: 'right' }}> {props.value} </div>,
			},
			{
				Header: 'Revenue',
				accessor: 'revenue',
				Cell: (props) => <div style={{ textAlign: 'right' }}> {props.value} </div>,
			},
			{
				Header: 'Tax',
				accessor: 'tax',
				Cell: (props) => <div style={{ textAlign: 'right' }}> {props.value} </div>,
			},
		],
		[groupBy]
	);
	const productSalesColumns = React.useMemo(
		() => [
			{
				Header: groupBy,
				accessor: 'period'
			},
			{
				Header: 'Product',
				accessor: 'order_items_name',
			},
			{
				Header: 'SKU',
				accessor: 'order_items_sku',
				Cell: (props) => <div style={{ textAlign: 'right' }}> {props.value} </div>,
			},
			{
				Header: 'Units Sold',
				accessor: 'ordered_qty',
				Cell: (props) => <div style={{ textAlign: 'right' }}> {props.value} </div>,
			},
		],
		[groupBy]
	);
	const parseQueryString = function () {
		if (parsed) {
			if (parsed.type) {
				setIsNewReport(false);
				setType(parsed.type);
			}
			if (parsed.range) setDateRange(parsed.range);
			if (parsed.period) setGroupBy(parsed.period);
			if (parsed.range == 'Custom' && parsed.from) {
				let d = moment(parsed.from, 'MM/DD/YYYY');
				let date = d.toDate();
				setStartDate(date);
			}
			if (parsed.range == 'Custom' && parsed.to) {
				let dt = moment(parsed.to, 'MM/DD/YYYY');
				let ddate = dt.toDate();
				setEndDate(ddate);
			}
			if (parsed.type && (!parsed.period || !parsed.from || !parsed.to)) {
				history.push('admin/report');
			}
		}
	};
	useEffect(() => {
		parseQueryString();
	}, [location]);
	useEffect(() => {
		if (parsed.type && type && parsed.from && parsed.to && groupBy && !isNewReport) {
			generateReport();
		}
	}, [type, groupBy]);

	const defaultColumn = React.useMemo(
		() => ({
			Filter: DefaultColumnFilter,
		}),
		[]
	);
	function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
		const count = preFilteredRows.length;

		return (
			<input
				value={filterValue || ''}
				onChange={(e) => {
					setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
				}}
				placeholder={`Search ${count} records...`}
			/>
		);
	}

	const data = useMemo(() => List, [List]);
	// const columns = useMemo(() => salesColumns, [salesColumns]);
	const columns = Columns;
	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
		// @ts-ignore
		{ columns, data, defaultColumn },
		useFilters,
		useGlobalFilter,
		useSortBy,
		useExpanded
	);
	let typesArr;
	if (utilityFunctions.isGranted(dcUserPermissionList.ReportManagement)) {
		typesArr = [
			{ name: 'Sales', value: 'sales' },
			{ name: 'Product Views', value: 'view' },
			{ name: 'Product Sales', value: 'sold' },
		];
	} else if (utilityFunctions.isGranted(dcUserPermissionList.SalesReport)) {
		typesArr = [{ name: 'Sales', value: 'sales' }];
	} else if (utilityFunctions.isGranted(dcUserPermissionList.ProductSoldReport)) {
		typesArr = [{ name: 'Product Sales', value: 'sold' }];
	} else if (utilityFunctions.isGranted(dcUserPermissionList.ViewsReport)) {
		typesArr = [{ name: 'Product Views', value: 'view' }];
	}

	const dateRangeArr = [
		{ name: 'Today', value: 'today' },
		{ name: 'Yesterday', value: 'yesterday' },
		{ name: 'Last 7 Days', value: 'last-7d' },
		{ name: 'Last 30 Days', value: 'last-30d' },
		{ name: 'Previous Month', value: 'prev-mon' },
		{ name: 'Previous Year', value: 'prev-year' },
		{ name: 'Week to Date', value: 'wk-date' },
		{ name: 'Month to Date', value: 'mon-date' },
		{ name: 'Year to Date', value: 'yr-date' },
		{ name: 'Custom', value: 'custom' },
	];

	const setReportType = (e) => {
		setType(e.target.value);
	};

	const setReportDateRange = (e) => {
		setDateRange(e.target.value);
		if (e.target.value !== 'Custom') {
			setStartDate(new Date());
			setEndDate(new Date());
		}
	};

	const setReportGroupBy = (e) => {
		setGroupBy(e.target.value);
	};

	useEffect(() => {
		setGroupByOptions();
	}, [dateRange]);

	useEffect(() => {
		if (parsed.range && dateRange && groupByArray?.length > 0) {
			setGroupBy(parsed.period);
		}
	}, [groupByArray]);

	const setGroupByOptions = function () {
		let date = dateRange;
		let groupByArr = [] as any;
		if (date === '' || undefined) {
			// groupByArr.push({ name: 'Select',  value: ''});
		}
		if (
			date === 'Today' ||
			date === 'Yesterday' ||
			date === 'Last 7 Days' ||
			date === 'Last 30 Days' ||
			date === 'Previous Month'
		) {
			groupByArr = [{ name: 'Day', value: 'day' }];
		}
		if (date === 'Previous Year') {
			groupByArr = [
				{ name: 'Day', value: 'day' },
				{ name: 'Month', value: 'month' },
			];
		}
		if (date === 'Week to Date' || date === 'Month to Date') {
			groupByArr = [{ name: 'Day', value: 'day' }];
		}
		if (date === 'Year to Date') {
			groupByArr = [
				{ name: 'Day', value: 'day' },
				{ name: 'Month', value: 'month' },
			];
		}
		if (date === 'Custom') {
			groupByArr = [
				{ name: 'Day', value: 'day' },
				{ name: 'Month', value: 'month' },
				{ name: 'Year', value: 'year' },
			];
		}
		setGroupByArray(groupByArr);
	};

	//generate report
	async function generateReport() {
		setIsReportGenerated(true);
		setIsLoading(true);
		let fromDt;
		let toDt;
		let payload;
		if (parsed.type && !isNewReport) {
			payload = {
				from: parsed.from,
				to: parsed.to,
				type: parsed.type,
				supplierId: supplierId,
				dcId: dcId,
				dcuserId: userId,
				period: parsed.period,
			};
		} else {
			var sdate = JSON.parse(JSON.stringify(startDate.toString()));
			var edate = JSON.parse(JSON.stringify(endDate.toString()));
			sdate = moment(sdate, 'DD/MM/YYYY');
			edate = moment(edate, 'DD/MM/YYYY');
			fromDt =
				dateRange == 'Custom'
					? sdate._isValid
						? moment(sdate).format('MM/DD/YYYY')
						: moment().format('MM/DD/YYYY')
					: utilityFunctions.getFromToDates(dateRange)?.from;
			toDt =
				dateRange == 'Custom'
					? edate._isValid
						? moment(edate).format('MM/DD/YYYY')
						: moment().format('MM/DD/YYYY')
					: utilityFunctions.getFromToDates(dateRange)?.to;
			payload = {
				from: fromDt,
				to: toDt,
				type: type,
				supplierId: supplierId,
				dcId: dcId,
				dcuserId: userId,
				period: groupBy,
			};
		}

		const obj: Partial<Ipost> = {
			data: payload,
			params: {},
			headers: { Authorization: CUSTOMER_TOKEN },
			url: constants.generateReport(),
			message: ' ',
		};
		try {
			await mutatePost(obj).then((data) => {
				if (type == 'sales') {
					setColumns(salesColumns);
					setReportName('Sales Report');
				} else if (type == 'view') {
					setColumns(productViewsColumns);
					setReportName('Product Views Report');
				} else if (type == 'sold') {
					setColumns(productSalesColumns);
					setReportName('Product Sales Report');
				}
				setIsLoading(false);
				if (data !== undefined) {
					let letData = [] as any;
					data.map((x) => {
						const period = groupBy == 'day' ? moment(x.period).format('DD/MM/YYYY'):  x.period;
						x.items.map((y) => {
							if (y.tax) y.tax = helperFunctions.formatAmount(y.tax);
							if (y.revenue) y.revenue = helperFunctions.formatAmount(y.revenue);
							if (y.ordered_qty) y.ordered_qty = parseFloat(y.ordered_qty).toFixed(0);
							Object.assign(y, { period: period });
							letData.push(y);
						});
					});
					updateList(letData);
					letData.length > 0
						? notify('Report generated successfully').success()
						: notify('No report has been generated').success();
					setIsNewReport(true);
				}
			});
		} catch (e) {
			setIsLoading(false);
		}
	}
	const clearReport = () => {
		setType('');
		setDateRange('');
		setGroupBy('');
		reportData.ToDate = new Date().toString();
		reportData.fromDate = new Date().toString();
		setReportData(reportData);
	};
	const downloadCSV = async () => {
		var sdate = JSON.parse(JSON.stringify(startDate.toString()));
		var edate = JSON.parse(JSON.stringify(endDate.toString()));
		sdate = moment(sdate, 'DD/MM/YYYY');
		edate = moment(edate, 'DD/MM/YYYY');
		let fromDt =
			dateRange == 'Custom'
				? sdate._isValid
					? moment(sdate).format('MM/DD/YYYY')
					: moment().format('MM/DD/YYYY')
				: utilityFunctions.getFromToDates(dateRange)?.from;
		let toDt =
			dateRange == 'Custom'
				? edate._isValid
					? moment(edate).format('MM/DD/YYYY')
					: moment().format('MM/DD/YYYY')
				: utilityFunctions.getFromToDates(dateRange)?.to;
		window.location.href =
			constants.generateCsv() +
			`from=${fromDt}&&to=${toDt}&&supplierId=${supplierId}&&dcId=${dcId}&&dcUserId=${userId}&&period=${groupBy}&&type=${type}`;
	};
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	};
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={() => onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={() => onclickHambrgBtn()}></Sidebar>
			{isLoading == true ? <Loader></Loader> : null}
			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbHolder>
					<BreadcrumbNav Heading="Reports" breadcrumbList={breadcrumbNav} />
				</BreadcrumbHolder>
				<FilterHolder>
					<Box as="form">
						<Grid gap={0}>
							<Stack categorySelect gap={0}>
								<div>
									<label>Report Type</label>
									<Select id="typeReport" name="country" value={type} onChange={setReportType}>
										<option>-Select-</option>
										{typesArr
											? typesArr.map((item, index) => {
												return (
													<option value={item.value} key={index}>
														{item.name} Report
													</option>
												);
											})
											: null}
									</Select>
									<Icon.downArrow />
								</div>
							</Stack>
							<Stack categorySelect gap={0}>
								<div>
									<label>Date Range</label>
									<Select
										id="dateRange"
										name="country"
										value={dateRange}
										onChange={setReportDateRange}
									>
										<option>-Select-</option>
										{dateRangeArr
											? dateRangeArr.map((item, index) => {
												return (
													<option value={item.name} key={index}>
														{item.name}
													</option>
												);
											})
											: null}
									</Select>
									<Icon.downArrow />
								</div>
							</Stack>
							{dateRange == 'Custom' && (
								<Box as="section">
									<FormGroup datePicker>
										<FormLabel>From</FormLabel>
										<DatePicker
											format={dateFormat}
											maxDate={endDate}
											value={startDate}
											onChange={(dateObject) => {
												setStartDate(dateObject);
											}}
										/>
										<Icon.DateIconInput />
									</FormGroup>
									<FormGroup datePicker>
										<FormLabel>To</FormLabel>
										<DatePicker
											format={dateFormat}
											minDate={startDate}
											value={endDate}
											onChange={(dateObject) => {
												setEndDate(dateObject);
											}}
										/>
										<Icon.DateIconInput />
									</FormGroup>
								</Box>
							)}
							<Stack categorySelect gap={0}>
								<div>
									<label>Group By</label>
									<Select id="groupBy" name="country" value={groupBy} onChange={setReportGroupBy}>
										<option>-Select-</option>
										{groupByArray
											? groupByArray.map((item, index) => {
												return (
													<option value={item.value} key={index}>
														{item.name}
													</option>
												);
											})
											: null}
									</Select>
									<Icon.downArrow />
								</div>
							</Stack>

							<Stack
								className="btnBlock"
								direction="x"
								gap={3}
								justifyContent={['center', 'flex-end']}
							>
								<Button flex={[1, 1, 'initial']} variant="secondary" onClick={clearReport}>
									Clear
								</Button>
								<Button
									flex={[1, 1, 'initial']}
									disabled={!groupBy || !dateRange || !type}
									onClick={generateReport}
								>
									Generate Report
								</Button>
							</Stack>
						</Grid>
					</Box>
				</FilterHolder>
				{List && isReportGenerated && (
					<UserListHolder>
						<ReportNameBlock>
							<Text
								as="h1"
								fontSize="20px"
								lineHeight="26px"
								color="text.black"
								fontFamily="OpenSansBold"
							>
								{reportName}
							</Text>
							{List.length > 0 && (
								<Button
									flex={[1, 1, 'initial']}
									onClick={downloadCSV}
									variant="secondary"
									className="downloadButton"
								>
									<icons.downloadIcon />
									<div>Download CSV</div>
								</Button>
							)}
						</ReportNameBlock>

						<TableList>
							<table {...getTableProps()}>
								<thead>
									{
										// Loop over the header rows
										headerGroups.map((headerGroup: any) => (
											// Apply the header row props
											<tr {...headerGroup.getHeaderGroupProps()}>
												{
													// Loop over the headers in each row
													headerGroup.headers.map((column: any) => (
														// Apply the header cell props
														<th>
															{
																// Render the header
																column.render('Header')
															}
															{/* Add a sort direction indicator */}
															<span {...column.getHeaderProps(column.getSortByToggleProps())}>
																{column.isSorted ? (
																	column.isSortedDesc ? (
																		<icons.tableSortDownIcon />
																	) : (
																		<icons.tableSortUpIcon />
																	)
																) : (
																	<icons.tableSortIcon />
																)}
															</span>
															<div>{column.canFilter ? column.render('Filter') : null}</div>
														</th>
													))
												}
											</tr>
										))
									}
								</thead>

								{/* Apply the table body props */}
								<tbody {...getTableBodyProps()}>
									{List.length > 0 ? (
										<SimpleBar>
											{
												// Loop over the table rows
												rows.map((row: any) => {
													// Prepare the row for display
													prepareRow(row);
													return (
														// Apply the row props
														<tr {...row.getRowProps()}>
															{
																// Loop over the rows cells
																row.cells.map((cell: any) => {
																	// Apply the cell props
																	// const isData =
																	// 	cell.value !== 'toggle' && cell.value !== 'action' ? true : false;
																	// const isToggle = cell.value === 'toggle' ? true : false;
																	let tooltipText;
																	if (cell?.column?.Header === 'Product' && cell?.column?.id === 'product_name' && cell?.value &&
																		cell?.value?.length > 35) {
																		tooltipText = cell.value;
																	}
																	else if (cell?.column?.Header === 'Product' && cell?.column?.id === 'order_items_name' && cell?.value &&
																		cell?.value?.length > 35) {
																		tooltipText = cell.value;
																	}

																	return (
																		<td {...cell.getCellProps()} data-label={cell.render('Header')} key={Math.random()}>
																			{
																				<Text
																					as="h1"
																					fontSize={15}
																					fontFamily="OpenSansMedium"
																					color="text.black"
																				>
																					<Box>{cell.render('Cell')}</Box>
																					{tooltipText && tooltipText?.length > 0 &&
																						<Tooltip
																							arrow
																							title={tooltipText}
																							animation="perspective"
																							position="top"
																							trigger="mouseenter"
																						>
																							<Icon.InfoIcon />
																						</Tooltip>}
																				</Text>
																			}
																		</td>
																	);
																})
															}
														</tr>
													);
												})
											}
										</SimpleBar>
									) : (
										<UserBody>
											<Text fontSize={18} fontWeight="bold" color="#5C5E57" textAlign="center">
												No Records Found
											</Text>
										</UserBody>
									)}
								</tbody>
							</table>
						</TableList>
					</UserListHolder>
				)}
			</MainContent>
			<Footer></Footer>
		</>
	);
}
export default ProductSalesView;
