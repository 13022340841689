import React, { ReactElement, useEffect, useState } from 'react';
import { Text, Box } from 'rebass/styled-components';
import { FormInput } from 'ui/patterns';
import { Stack, Button, Loader } from 'ui/components';
import styled from 'styled-components';
import Ipost from 'models/Ipost';
import hooks from 'utils/requestCtx/reactQueryHooks';
import Iget from 'models/Iget';
import api from 'utils/requestCtx/api.service';
import urls from 'utils/create.url';
import notify from 'utils/toast.service';
import { loadavg } from 'os';
const NewFavorites = styled.div`
	display: flex;
	height: auto;
	position: relative;
	flex-direction: column;
	background: ${({ theme }) => theme.colors.bg.white};
	padding: 0 10px 12px;
	@media (max-width: 960px) {
		padding: 0;
	}
	h1 {
		margin: 0 0 30px 0;
		width: 100%;
		font-weight: normal;
		text-transform: uppercase;
	}
	label {
		font-size: 16px;
		line-height: 21px;
		font-family: 'OpenSansSemiBold';
		color: ${({ theme }) => theme.colors.text.SubHeading};
		margin-bottom: 8px;
	}
	button {
		padding: 0;
		min-width: 100px;
		min-height: 50px;
		text-transform: capitalize;
		font-size: 18px;
		margin: 0 0 0 20px;
		font-family: 'OpenSansBold';
	}
	.app-c-form-group {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
	}
	.app-c-select-dropdown {
		position: relative;
		svg {
			position: absolute;
			top: 20px;
			right: 14px;
		}
	}
	.footerButtonHolder {
		@media (max-width: 768px) {
			margin: 0 0 20px 0;
			column-gap: 20px;
			button {
				flex: 0;
				margin: 0;
			}
		}
		@media (max-width: 480px) {
			button {
				flex: 1;
			}
		}
	}
`;

type Option = {
	cancel: () => void;
};
export default function NewFavoritesModal({
	showModal,
	setShowModal,
	updateList,
	favId = 0,
	favName = '',
	orderId = 0,
}): ReactElement {
	const [newFavorites, setNewFavorites] = useState('');
	const [disableCreate, setDisableCreate] = useState(true);
	var key = 'addFavKey';
	const [mutatePost, mutationStates] = hooks.useMutation(key);

	useEffect(() => {
		if (favName) {
			setNewFavorites(favName);
		}
	}, []);

	const handleOnChange = (e: any) => {
		if (e.target.value.length > 0) {
			setDisableCreate(false);
			setNewFavorites(e.target.value);
		} else {
			setDisableCreate(true);
			setNewFavorites(e.target.value);
		}
	};

	const createFavorite = async (orderId) => {
		let favItems = [] as any;
		let userID = localStorage.getItem('userId')!;
		let isExists = false;
		await api
			.get({ url: `/experion/productmanagement/favorite/${newFavorites}/${userID}` })
			.then(function (data) {
				if (typeof data != 'undefined' && data == true) isExists = true;
			});
		if (!isExists) {
			if (favId != 0) {
				api
					.put({
						url: encodeURI(
							`experion/productmanagement/favorite/updatefavoritename/${favId}/${newFavorites}`
						),
						message: 'Favorite name updated successfully',
					})
					.then(function () {
						updateList(newFavorites);
						setShowModal(!showModal);
					});
			} else {
				api
					.get({
						url: urls.GetSearchUrl({
							url: 'orders?',
							fields: [
								{
									field_name: 'group_order_id',
									field_value: orderId,
								},
							],
						}),
					})
					.then(function (data) {
						var obj = { product_id: '', qty: null, is_active: false, is_delete: false };
						if (data?.items && data.items?.length > 0) {
							data.items.forEach(data => {
								data?.items.forEach((data) => {
									obj = { product_id: data.product_id, qty: data.qty_ordered, is_active: true, is_delete: false };
									favItems.push(obj);
								});
							});
							var venueUserID = parseInt(userID);
							var v_id: any;
							v_id = localStorage.getItem('selectedVenue');
							var venueId = parseInt(v_id);
							let markFavJson = {
								favoriteOrder: {
									venue_user_id: venueUserID,
									favorite_name: newFavorites,
									venue_id: venueId,
									favorite_items: favItems,
								},
							};

							const obj1: Partial<Ipost> = {
								data: markFavJson,
								params: {},
								url: '/experion/productmanagement/favoriteorder/create',
								message:
									favId == 0
										? 'Order has been added as favorite'
										: 'Favorite name updated successfully',
							};
							try {
								mutatePost(obj1).then((data) => {
									if (data !== undefined) {
										setShowModal(!showModal);
										// setInProgress(false);
									}
								});
							} catch (e) {}
						} else {
							notify('No products found').error();
							setShowModal(!showModal);
						}
					});
			}
		} else {
			notify('Favorite name exists').error();
		}
	};

	return (
		<NewFavorites>
			{/* {inProgress ? <Loader></Loader> : ''} */}
			<Text as="h1" color="text.textHeading" fontSize={18} fontFamily="OpenSansSemiBold">
				{favId == 0 ? 'New Favorite' : 'Update Favorite'}
			</Text>
			<Box mb={45}>
				<Stack categorySelect gap={0} mt={0}>
					<FormInput
						value={newFavorites}
						autoComplete="off"
						name="favoriteName"
						onChange={handleOnChange}
						maxLength={50}
						type="text"
						label={favId != 0 ? 'Enter title' : 'Create New Favorite'}
					/>
				</Stack>
			</Box>

			<Stack direction="x" gap={3} justifyContent="center" className="footerButtonHolder">
				<Button
					flex={[1, 1, 'initial']}
					variant="secondary"
					onClick={() => {
						setShowModal(!showModal);
					}}
				>
					Cancel
				</Button>
				<Button
					onClick={() => {
						createFavorite(orderId);
					}}
					disabled={disableCreate}
					flex={[1, 1, 'initial']}
				>
					{favId == 0 ? 'Create' : 'Update'}
				</Button>
			</Stack>
		</NewFavorites>
	);
	// }
	// else
	// {
	// 	return <Loader></Loader>
	// }

	// }
}
