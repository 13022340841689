// base URL will need to change for production applications
import constants, {PAYPAL_CLIENT_ID, PAYPAL_SECRET} from "./constants";

// call this function to create your client token
async function generateClientToken() {
  const accessToken = await generateAccessToken();
  const response = await fetch(`${constants.getPaypalUrl()}/v1/identity/generate-token`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Accept-Language": "en_US",
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  return data.client_token;
}

// access token is used to authenticate all REST API requests
async function generateAccessToken() {
  const auth = Buffer.from(PAYPAL_CLIENT_ID + ":" + PAYPAL_SECRET).toString("base64");
  const response = await fetch(`${constants.getPaypalUrl()}/v1/oauth2/token`, {
    method: "post",
    body: "grant_type=client_credentials",
    headers: {
      Authorization: `Basic ${auth}`,
    },
  });
  const data = await response.json();
  return data.access_token;
}

async function generatePaymentToken(customer_id) {
  const url = `https://api-m.sandbox.paypal.com/v2/vault/payment-tokens?customer_id=${customer_id}`;
  const accessToken = await generateAccessToken();
  const response = await fetch(url , {
    method: "post",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  return data.access_token;
}

export { generateAccessToken, generateClientToken, generatePaymentToken };