import styled from 'styled-components';
import { flexbox, FlexboxProps, layout, LayoutProps, space, SpaceProps } from 'styled-system';

type ContainerProps = FlexboxProps & SpaceProps & LayoutProps;

const Container = styled.div<ContainerProps>`
	${flexbox};
	${space};
	${layout};
	box-sizing: content-box;
	margin-left: auto;
	margin-right: auto;
	@media (max-width: 768px) {
		padding: 0 20px;
	}
`;

export { Container };
