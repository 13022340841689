import styled from 'styled-components';
const StyledProductMainDetails = styled.div`
	width: 100%;
	padding: 36px 0 0 0;
	margin: 40px 0 0 0;
	float: left;
	border-top: 1px solid #dadada;
	@media (max-width: 860px) {
		width: calc(100% + 30px);
		padding: 30px 15px;
		margin: 30px 0 0 -15px;
	}
`;
const ViewAllHeadingBlock = styled.div`
	h1 {
		font-family: 'OpenSansBold';
		text-transform: uppercase;
		color: ${({ theme }) => theme.colors.text.black};
		line-height: normal;
	}
`;
const ProductMainDetailsHolder = styled.div`
	margin-top: 20px;
	margin-bottom: 30px;
	display: flex;
	@media (max-width: 860px) {
		flex-direction: column;
	}
	.product-title {
		margin-right: 65px;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 23px;
		color: #1a1919;
	}
	.more-products {
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 23px;
		color: #7d376f;
		cursor: pointer;
	}
	@media (max-width: 860px) {
		div {
			max-height: max-content;
			flex-direction: column;
			align-items: flex-start;
		}
	}
	article {
		margin: 0;
		padding: 0;
		border-bottom: none;
		@media (max-width: 860px) {
			margin: 10px 0;
		}
		& + article {
			margin-left: 35px;
			@media (max-width: 860px) {
				margin-left: 0;
			}
		}
		div {
			display: flex;
			align-items: center;
			margin-bottom: 18px;
			&:last-child {
				margin-bottom: 0;
			}
			h2 {
				font-weight: normal;
				margin: 0;
				flex: 0 0 170px;
				margin-right: 24px;
				font-family: 'OpenSansMedium';
				position: relative;
				@media (max-width: 480px) {
					flex: 0 0 150px;
					margin-right: 20px;
				}
				&:after {
					content: ':';
					position: absolute;
					top: 0;
					right: 0;
				}
			}
			h3 {
				margin: 0;
				font-family: 'OpenSansBold';
			}
			@media (max-width: 860px) {
				display: flex;
				h3 {
					margin-top: 0;
				}
				h2,
				h3 {
					font-size: 17px;
				}
			}
		}
	}
`;
export { StyledProductMainDetails, ViewAllHeadingBlock, ProductMainDetailsHolder };
