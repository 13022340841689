import React, { ReactElement, ReactNode, useCallback, useEffect } from 'react';
import { animated, useSpring, useTransition } from 'react-spring';
import { Button } from 'ui/components';
import * as Icon from 'ui/icons';
import { ModalBackdrop, ModalDialog, ModalWrapper } from './styles';

type ModalProps = {
	width?: string;
	showModal: boolean;
	setShowModal: any;
	children: ReactNode;
	cancelFilter: any;
	backdrop?: string;
};

function Modal(prop: Partial<ModalProps>): ReactElement {
	const modalDialogTransition = useSpring({
		config: {
			duration: 200,
		},
		opacity: prop.showModal ? 1 : 0,
		transform: prop.showModal ? `translate3d(0, 0, 0)` : `translate3d(0, -50%, 0)`,
	});

	const modalTransitions = useTransition(prop.showModal, null, {
		config: {
			duration: 150,
		},
		from: {
			zIndex: 999999,
			opacity: 1,
			position: 'fixed',
			width: '100%',
			height: '100%',
			pointerEvents: 'none',
		},
		enter: {
			zIndex: 999999,
			opacity: 1,
			position: 'fixed',
			width: '100%',
			height: '100%',
			pointerEvents: 'initial',
		},
		leave: {
			zIndex: 999999,
			opacity: 0,
			position: 'fixed',
			width: '100%',
			height: '100%',
			pointerEvents: 'none',
		},
	});

	const handleEscape = useCallback(
		(e) => {
			if (e.code === 'Escape' && prop.showModal) {
				prop.cancelFilter && prop.cancelFilter();
				prop.setShowModal(false);
			}
		},
		[prop.showModal, prop.setShowModal]
	);

	useEffect(() => {
		document.addEventListener('keydown', handleEscape);
		if (prop.showModal) {
			document.body.style.overflow = 'hidden';
			// document.body.style.paddingRight = '20px';
		}

		return () => {
			document.removeEventListener('keydown', handleEscape);
			document.body.style.overflow = 'initial';
			// document.body.style.paddingRight = 'initial';
		};
	}, [prop.showModal, handleEscape]);

	return (
		<>
			{modalTransitions.map(
				({ item, key, props }) =>
					item && (
						<animated.div key={key} style={props} tabIndex={-1}>
							<ModalWrapper width={prop.width} tabIndex={-1}>
								<animated.div style={modalDialogTransition} className="animated-div" tabIndex={-1}>
									<ModalDialog width={prop.width} tabIndex={-1}>
										{prop.backdrop != 'static' && (
											<Button
												iconBefore={<Icon.ModalClose />}
												variant="modalClose"
												onClick={() => {
													prop.cancelFilter && prop.cancelFilter();
													prop.setShowModal(false);
												}}
												className="dg-modal-close"
											></Button>
										)}
										{prop.children}
									</ModalDialog>
								</animated.div>
								<ModalBackdrop
									open={prop.showModal}
									tabIndex={-1}
									onClick={() => {
										prop.cancelFilter && prop.cancelFilter();
										prop.backdrop == 'static' ? prop.setShowModal(true) : prop.setShowModal(false);
									}}
								></ModalBackdrop>
							</ModalWrapper>
						</animated.div>
					)
			)}
		</>
	);
}

export { Modal };
