import styled, { css } from 'styled-components';
import { flexbox, FlexboxProps, layout, LayoutProps, space, SpaceProps } from 'styled-system';
import { getHSL } from '../../utils/getHSL';

type ssProps = SpaceProps & LayoutProps & FlexboxProps;

type FormGroupProps = ssProps & {
	className?: string;
	style?: React.CSSProperties;
	datePicker?: boolean;
	datepickerSignUp?: boolean;
};

const FormGroup = styled.div<FormGroupProps>`
	${space}
	${layout}
	${flexbox}

	${({ datePicker }) =>
		datePicker &&
		css`
			position: relative;
			.pr-icon {
				position: absolute;
				right: 13px;
				top:41px;
			}
			.react-datepicker-wrapper {
				display: block;
			}
			.react-datepicker__input-container > input {
				--px: 0.75em;
				--py: 0.75em;
				--a: 80%;
				--h: ${({ theme }) => getHSL('h', theme.colors.border.default)};
				--s: ${({ theme }) => getHSL('s', theme.colors.border.default)};
				--l: ${({ theme }) => getHSL('l', theme.colors.border.default)};
				--input-bg: #fff;
				display: block;
				width: 100%;
				height: calc(calc(var(--py) * 2) + calc(var(--px) * 2) + 2px);
				padding: var(--py) var(--px);
				font-family: 'OpenSansSemiBold';
				font-size: 1rem;
				font-weight: 400;
				line-height: 1.5;
				color: ${({ theme }) => theme.colors.text.body};
				background-color: var(--input-bg);
				background-clip: padding-box;
				border: 1px solid ${({ theme }) => theme.colors.border.default};
				border-radius: ${({ theme }) => theme.radii.softer};
				transition: all 200ms ease-in-out;
				-webkit-appearance: none;
				appearance: none;

				&:focus {
					background-color: #fff;
					border-color: ${({ theme }) => theme.colors.primary};
					outline: 0;
					box-shadow: 0 0 0 0.2rem hsla(var(--h), var(--s), var(--l), 30%);
				}
			}
		`}
	${({ datepickerSignUp }) =>
		datepickerSignUp &&
		css`
			--color__accent: var(--primary-color);
			--input__border: ${({ theme }) => theme.colors.border.yellowDark};
			--input__border--hover: ${({ theme }) => theme.colors.border.yellowDark};
			--label__color: ${({ theme }) => theme.colors.text.body};
			--input__label__focus: ${({ theme }) => theme.colors.bg.lightYellow};
			--input__border__focus: ${({ theme }) => theme.colors.bg.darkYellow};
			--input__background: ${({ theme }) => theme.colors.bg.white};
			.react-datepicker-wrapper {
				display: block;
			}
			span {
				z-index: 99;
			}
			.react-datepicker__input-container > input {
				padding: 10px 22px 10px;
				font-size: inherit;
				border: 0;
				border: 1px solid var(--input__border);
				transition: border-color 0.2s ease;
				caret-color: var(--color__accent);
				display: block;
				width: 100%;
				background: var(--input__background);
				color: var(--label__color);
				height: 73px;
				border-radius: 9px;
				padding-right: 60px;
				font-family: 'OpenSansSemiBold';
				font-size: 1rem;

				&:focus {
					outline: none;
					border-color: var(--input__border__focus);
				}
				&::-webkit-input-placeholder {
					/* Chrome/Opera/Safari */
					color: ${({ theme }) => theme.colors.text.body};
					font-family: 'OpenSansRegular';
				}
				&::-moz-placeholder {
					/* Firefox 19+ */
					color: ${({ theme }) => theme.colors.text.body};
					font-family: 'OpenSansRegular';
				}
				&:-ms-input-placeholder {
					/* IE 10+ */
					color: ${({ theme }) => theme.colors.text.body};
					font-family: 'OpenSansRegular';
				}
				&:-moz-placeholder {
					/* Firefox 18- */
					color: ${({ theme }) => theme.colors.text.body};
					font-family: 'OpenSansRegular';
				}
			}
		`}
`;

export { FormGroup };
