import Iget from 'models/Iget';
import Ipost from 'models/Ipost';
import React, { ReactElement, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Box, Flex, Text } from 'rebass/styled-components';
import {
	BreadcrumbNav,
	Button,
	Footer,
	FormLabel,
	Header,
	Loader,
	Stack,
	Toggle,
} from 'ui/components';
import { DeliverySlot, FormGroup, Modal, OrderProcessingTime, Sidebar } from 'ui/patterns';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import ChangePasswordModal from './changePassword';
import { AddUserHolder, FooterBtnBlock, MainContent } from './styles';
import * as Icon from 'ui/icons';
import DatePicker from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import moment from 'moment';
import urls from 'utils/create.url';

function SupplerCompanySettings(): ReactElement {
	var supplierId = localStorage.getItem('supplierId');
	const apiKey = 'supplierSettings';
	const [disableBtnSave, setDisableBtnSave] = useState(true);
	const [modalText, setmodalText] = useState('');
	const [showPopup, setShowModal] = useState(false);
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const [action, setAction] = useState('');
	const [selectedSkipDates, setSelectedSkipDates] = useState<any>([]);
	const [minDate, setMinDate] = useState<any>(new Date());
	const [deliverySlotId, setDeliverySlotId] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [skipDates, setSkipDates] = useState([]);
	const listOfOrderProcessingTimeInitial = [
		{
			id: '01',
			Day: 'Sunday',
			active: false,
			processing_time: '',
			field_value: 'SUN'
		},
		{
			id: '02',
			Day: 'Monday',
			active: false,
			processing_time: '',
			field_value: 'MON'
		},
		{
			id: '03',
			Day: 'Tuesday',
			active: false,
			processing_time: '',
			field_value: 'TUE'
		},
		{
			id: '04',
			Day: 'Wednesday',
			active: false,
			processing_time: '',
			field_value: 'WED'
		},
		{
			id: '05',
			Day: 'Thursday',
			active: false,
			processing_time: '',
			field_value: 'THU'
		},
		{
			id: '06',
			Day: 'Friday',
			active: false,
			processing_time: '',
			field_value: 'FRI'
		},
		{
			id: '07',
			Day: 'Saturday',
			active: false,
			processing_time: '',
			field_value: 'SAT'
		},
	];
	const [listOfOrderProcessingTime, setListOfOrderProcessingTime] = useState(listOfOrderProcessingTimeInitial);
	const [supplierDetails, updateSupplierDetails] = useState({
		supplycompany_id: supplierId,
		return_time_period: '',
		password: '',
	});
	const [deliveryCost, setDeliveryCost] = useState(false);

	const [error, setError] = useState({
		return_time_periodError: '',
		passwordError: '',
	});

	const listOfDeliverySlotManagementInitial = [
		{
			id: '01',
			Day: 'Sunday',
			active: false,
			field_value: 'SUN'
		},
		{
			id: '02',
			Day: 'Monday',
			active: false,
			field_value: 'MON'
		},
		{
			id: '03',
			Day: 'Tuesday',
			active: false,
			field_value: 'TUE'
		},
		{
			id: '04',
			Day: 'Wednesday',
			active: false,
			field_value: 'WED'
		},
		{
			id: '05',
			Day: 'Thursday',
			active: false,
			field_value: 'THU'
		},
		{
			id: '06',
			Day: 'Friday',
			active: false,
			field_value: 'FRI'
		},
		{
			id: '07',
			Day: 'Saturday',
			active: false,
			field_value: 'SAT'
		},
	];
	const [listOfDeliverySlotManagement, setListOfDeliverySlotManagement] = useState(listOfDeliverySlotManagementInitial);
	const [time, setTimeValue] = useState(new Date());
	const breadcrumbNav = [
		{ id: '01', label: 'Dashboard', route: 'seller-dashboard', active: false },
		{ id: '03', label: 'Seller Settings', active: true },
	];
	const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
	const toggleChangePasswordModal = () => {
		setShowChangePasswordModal(!showChangePasswordModal);
	};

	useEffect(() => {
		// if(is_password_set == "0"){
		// 	setShowChangePasswordModal(true);
		// }
	}, []);

	const onChangeValue = (val, name) => {
		const item = supplierDetails;
		switch (name) {
			case 'return_time_period': {
				if (!val) {
					error.return_time_periodError = 'Return Time Period cannot be blank';
				} else {
					error.return_time_periodError = '';
				}
				setError(error);
				item.return_time_period = val;
				break;
			}
			case 'password': {
				if (!val) {
					//error.passwordError = 'Password cannot be blank';
				} else {
					error.passwordError = '';
				}
				setError(error);
				item.password = val;
				break;
			}
			default:
				break;
		}

		updateSupplierDetails({
			...item,
		});
		if (
			supplierDetails &&
			supplierDetails.return_time_period &&
			error &&
			!error.return_time_periodError
		) {
			setDisableBtnSave(false);
		} else {
			setDisableBtnSave(true);
		}
	};

	const params = {
		pages: '1',
	};

	const dataObj: Partial<Iget> = {
		params: params,
		url: `experion/supplycompanyaccount/getsupplycompany/${supplierId}`,
		enabled: true,
		key: apiKey,
	};

	var qryData = hooks.useQuery(dataObj);

	useEffect(() => {
		qryData.data && updateSupplierDetails(qryData.data);
		qryData && qryData.data && setDeliveryCost(qryData.data.delivery_cost);
	}, [qryData.data]);

	const deliverySlotURL = urls.GetSearchUrl({
		url: `experion/deliverySlot/search?`,
		fields: [{
			field_name: 'company_id',
			field_value: localStorage.getItem("supplierId")!
		}]
	});
	const deliverySlotObj: Partial<Iget> = {
		params: {},
		url: deliverySlotURL,
		enabled: true,
		key: 'deliverySlotSearch',
	};
	var deliverySlotQry = hooks.useQuery(deliverySlotObj);
	useEffect(() => {
		if (deliverySlotQry.data && deliverySlotQry.data.items && deliverySlotQry.data.items.length > 0) {
			const data = deliverySlotQry.data.items[0];
			setDeliverySlotId(data.id);
			setSelectedSkipDates(data.skip_dates);
			setSkipDates(data.skip_dates);
			const slotData = listOfDeliverySlotManagementInitial;
			data.slots && data.slots.length > 0 && data.slots.forEach(item => {
				const objIndex = slotData.findIndex((obj => obj.field_value == item));
				slotData[objIndex].active = true;
			});
			setListOfDeliverySlotManagement(slotData);
		} else {
			setDeliverySlotId(null);
			setSelectedSkipDates([]);
			setSkipDates([]);
			const slotData = listOfDeliverySlotManagementInitial;
			slotData.forEach(item => {
				if (item.field_value === 'SUN') { item.active = false; } else { item.active = true; }
			});
			setListOfDeliverySlotManagement(slotData);
		}
	}, [deliverySlotQry.data && deliverySlotQry.data.items]);

	const processingTimeURL = urls.GetSearchUrl({
		url: `experion/orderProcessingTime/search?`,
		fields: [{
			field_name: 'company_id',
			field_value: localStorage.getItem("supplierId")!
		}]
	});
	const orderProcessingObj: Partial<Iget> = {
		params: {},
		url: processingTimeURL,
		enabled: true,
		key: 'processingTimeSearch',
	};
	var processingTimeQry = hooks.useQuery(orderProcessingObj);

	useEffect(() => {
		let date = moment(new Date()).format('DD/MM/YYYY');
		if (processingTimeQry.data && processingTimeQry.data?.items?.length > 0
			&& processingTimeQry.data?.items[0].processing_days?.length > 0 ) {
			processingTimeQry.data?.items[0].processing_days.map((data) => {
				listOfOrderProcessingTime.map((innerData) => {
					if (data.day === innerData.field_value){
						innerData.processing_time = (date + ' ' + data.time);
						innerData.active = true;
					}
				});
			});
			setListOfOrderProcessingTime(JSON.parse(JSON.stringify(listOfOrderProcessingTime)));
		}
	}, [processingTimeQry.data])

	const resetData = () => {
		// qryData.refetch().then(function (data) {
		// 	if (data) {
		// 		setDisableBtnSave(true);
		// 		updateSupplierDetails(data.data);
		// 	}
		// });
		window.location.href = window.location.href.replace('#!', '');
	};

	const submitData = () => {
		showConfirmationModal('save', 'Save the changes?');
	};

	function showConfirmationModal(action, text) {
		setAction(action);
		setmodalText(text);
		setShowModal(!showPopup);
	}

	async function onSubmit() {
		if (action === 'save') {
			saveData();
		} else if (action === 'clear') {
			clearDetails('clear');
		}
		setShowModal(!showPopup);
	}

	async function closePopup() {
		await setShowModal(!showPopup);
	}

	async function saveData() {
		if (supplierDetails) {
			var payload = {
				// "supplycompany_id": supplierId,
				return_time_period:
					supplierDetails.return_time_period && parseInt(supplierDetails.return_time_period + ''),
			};
			const obj: Partial<Ipost> = {
				data: { supplyCompany: payload },
				params: {},
				url: `experion/supplycompanyaccount/updatesupplycompany/${supplierId}`,
				message: 'Return Time Period has been updated successfully',
			};
			try {
				api.put(obj).then(() => {
					// if (data !== undefined) {
					// 	history.push('/home/dashboard');
					// }
				});
			} catch (e) { }
			setShowModal(!showPopup);
		}
	}

	const clearDetails = (param) => {
		if (param === 'clear') {
			updateSupplierDetails({
				supplycompany_id: supplierId,
				return_time_period: '0',
				password: '',
			});
			setError({
				return_time_periodError: '',
				passwordError: '',
			});
		}
	};
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	};

	function updateDeliverySlots(data) {
		setListOfDeliverySlotManagement(JSON.parse(JSON.stringify(data)));
	}

	const selectSkipDates = (e) => {
		let skipDateArray = [] as any;
		let startObj: any;
		e.forEach((obj) => {
			startObj = new Date(obj);
			startObj = moment(startObj).format('DD-MM-YYYY');
			skipDateArray.push(startObj);
		})
		setSkipDates(skipDateArray);
	};

	const updateCreateDeliverySlot = () => {
		setIsLoading(true);
		let data = JSON.parse(JSON.stringify(listOfDeliverySlotManagement));
		data = data.filter(x => x.active);
		const slots = data.map(x => x.field_value);
		let deliverySlotData = {
			company_id: localStorage.getItem("supplierId")!,
			slots: slots,
			skip_dates: skipDates,
		};
		if(deliverySlotId) deliverySlotData = Object.assign(deliverySlotData,{id: deliverySlotId});
		let apiData = {
			deliverySlot: deliverySlotData
		}
		deliverySlotId ? updateDeliverySlot(apiData) : createDeliverySlot(apiData);
	}

	async function createDeliverySlot(apiData) {
		const obj: Partial<Ipost> = {
			data: apiData,
			params: {},
			url: `experion/deliverySlot/create`,
			message: `Changes saved successfully`,
		};
		try {
			await api.post(obj).then(function (data) {
				setDeliverySlotId(data.id);
				setIsLoading(false);
			});
		} catch (e) {
			setIsLoading(false);
		}
	};

	async function updateDeliverySlot(apiData) {
		const obj: Partial<Ipost> = {
			data: apiData,
			params: {},
			url: `experion/deliverySlot/update/${apiData.deliverySlot.id}`,
			message: `Changes saved successfully`,
		};
		try {
			await api.put(obj).then(function (data) {
				setIsLoading(false);
			});
		} catch (e) {
			setIsLoading(false);
		}
	};

	const resetDeliverySlot = () => {
		setSelectedSkipDates([]);
		setSkipDates([]);
		setListOfDeliverySlotManagement(listOfDeliverySlotManagementInitial);
	};

	const updateOrderProcessingTime = (item) => {
		setListOfOrderProcessingTime(JSON.parse(JSON.stringify(item)));
	}
	const resetProcessingTime = () => {
		setListOfOrderProcessingTime(JSON.parse(JSON.stringify(listOfOrderProcessingTimeInitial)));
	}
	const saveOrderProcessingTime = () => {
		setIsLoading(true);
		let selectedTime = listOfOrderProcessingTime.filter(x => x.active === true) as any;
		let processingDays = [] as any;
		if (selectedTime && selectedTime.length > 0) {
			selectedTime.map((dat) => {
				let itemDetails = {
					day: '',
					time: ''
				}
				itemDetails.day = dat.field_value;
				if (dat.processing_time === '' || !dat.processing_time) {
					itemDetails.time = "10:00"
				}
				else
					itemDetails.time = moment(dat.processing_time,'DD/MM/YYYY HH:mm').format('HH:mm');
				processingDays.push(itemDetails);
			});
		}
		const payload = {
			orderProcessingTime: {
				company_id: localStorage.getItem('supplierId'),
				processing_days: processingDays,
				is_active: 1
			}
		}
		const obj: Partial<Ipost> = {
			data: payload,
			params: {},
			url: `experion/orderProcessingTime/create`,
			message: `Changes saved successfully`,
		};
		try {
			api.post(obj).then(function (data) {
				setIsLoading(false);
			});
			
		} catch (e) {
			setIsLoading(false);
		}
	}
	const toggleBtnChange = async (e) => {
		setIsLoading(true);
		const checkedProp = e.currentTarget.checked;
		const message = checkedProp ? 'Charges Applied For Delivery' : 'Charges Not Applied For Delivery';
		const obj: Partial<Ipost> = {
			data: {},
			params: {},
			url: `experion/supplycompanyaccount/updatedeliverycost/${supplierId}/${checkedProp}`,
			message: message
		};
		try {
			await api.put(obj).then(function (data) {
				setDeliveryCost(checkedProp);
				setIsLoading(false);
			});
		}
		 catch (e) {
			setIsLoading(false);
		}
	};

	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={() => onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={() => onclickHambrgBtn()}></Sidebar>
			{(supplierDetails && supplierDetails.supplycompany_id == '') || isLoading || (processingTimeQry.isFetching  || deliverySlotQry.isFetching) ? <Loader></Loader> : null}
			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbNav Heading="Seller Settings" breadcrumbList={breadcrumbNav} />
				{/* Add User Details */}
				<AddUserHolder>
					{/* Old Content */}
					{/* <Box as="section">
						<Box as="form">
							<Grid gap={0}>
								<FormInput label="Currency" />
								<Stack UOMSelect gap={0}>
									<div>
										<Label>UOM</Label>
										<Select id="country" name="country" defaultValue="Last 30 days">
											<option>-Select Venue-</option>
										</Select>
										<Icon.downArrow />
									</div>
								</Stack>
								<FormInput label="Return Time Period (Days)" 
									autoComplete="off" validationMessage={error.return_time_periodError}
									validation={error.return_time_periodError ? 'error' : undefined}
									value={supplierDetails && supplierDetails.return_time_period}
									required={true}
									onTextChange={(e) => onChangeValue(e, 'return_time_period')}
									maxLength={100}
									type="number" />
								<FormInput label="Password" disabled={true} value="********" />
							</Grid>
							<Button onClick={toggleChangePasswordModal}>Change password</Button>
						</Box>
					</Box> */}
					<Box as="section">
						<Text as="h1" fontSize={18} fontFamily="OpenSansBold" color="text.SubHeading">
							Delivery Cost Configuration
						</Text>
						<Flex className="doYouDelivery" mt={5}>
							<Text as="h2" fontSize={14} fontFamily="OpenSansSemiBold">
								Do you charge for Delivery?
							</Text>
							<Toggle onChange={(e) => toggleBtnChange(e)}  checked={deliveryCost}/>
						</Flex>
					</Box>
					<Box as="section">
						<Text as="h1" mb={5} fontSize={18} fontFamily="OpenSansBold" color="text.SubHeading">
							Order Processing Time
						</Text>
						<OrderProcessingTime processingTimeList={listOfOrderProcessingTime} updateOrderProcessingTime={(data) => updateOrderProcessingTime(data)} value={moment(time)}></OrderProcessingTime>

						<FooterBtnBlock>
							<Stack direction="x" gap={3} justifyContent={['center', 'flex-end']}>
								<Button flex={[1, 1, 'initial']} variant="secondary" onClick={() => resetProcessingTime()}>
									Reset
								</Button>
								<Button flex={[1, 1, 'initial']} onClick={saveOrderProcessingTime}>Save</Button>
							</Stack>
						</FooterBtnBlock>
					</Box>
					<Box as="section">
						<Text as="h1" mb={5} fontSize={18} fontFamily="OpenSansBold" color="text.SubHeading">
							Delivery Slot Management
						</Text>
						<DeliverySlot deliverySlotList={listOfDeliverySlotManagement} updateDeliverySlots={(data) => updateDeliverySlots(data)}></DeliverySlot>
						<Box className="skipDatesBlock">
							<FormGroup datePicker className="skipDatePicker">
								<FormLabel>Skip Dates </FormLabel>
								{/* <DatePicker value={skipDates} multiple plugins={[<DatePanel />]} onChange={(dateObject) => { updateSkipDatesFn(dateObject); }} /> */}
								<DatePicker format='DD-MM-YYYY' minDate={minDate} placeholder='Choose skip dates' value={selectedSkipDates ? selectedSkipDates : ''} onChange={(e) => selectSkipDates(e)} multiple plugins={[<DatePanel />]} /> <Icon.DateIconInput />
							</FormGroup>
							{skipDates && skipDates.length > 0 && <Text as="h2" fontSize={14} fontFamily="OpenSansSemiBold">
								{skipDates.length} {skipDates.length > 1 ? 'Dates' : 'Date'} Selected
							</Text>}
						</Box>
						<FooterBtnBlock>
							<Stack direction="x" gap={3} justifyContent={['center', 'flex-end']}>
								<Button flex={[1, 1, 'initial']} variant="secondary" onClick={() => resetDeliverySlot()}>
									Reset
								</Button>
								<Button flex={[1, 1, 'initial']} disabled={listOfDeliverySlotManagement && listOfDeliverySlotManagement.filter(x => x.active).length === 0} onClick={() => updateCreateDeliverySlot()}>Save</Button>
							</Stack>
						</FooterBtnBlock>
					</Box>

					{/* Footer Btn Block */}
				</AddUserHolder>
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
			<SweetAlert
				show={showPopup}
				confirmBtnText="Confirm"
				onConfirm={onSubmit}
				showCancel={true}
				onCancel={closePopup}
				title={'Confirmation Box'}
			>
				{() => <form>{modalText}</form>}
			</SweetAlert>
			<Modal
				width="450px"
				showModal={showChangePasswordModal}
				setShowModal={setShowChangePasswordModal}
			>
				<ChangePasswordModal
					cancel={() => {
						toggleChangePasswordModal();
					}}
				/>
			</Modal>
		</>
	);
}

export default SupplerCompanySettings;
