import styled, { css } from 'styled-components';
import {
	color,
	ColorProps,
	flexbox,
	FlexboxProps,
	FlexProps,
	layout,
	LayoutProps,
	space,
	SpaceProps,
	textAlign,
} from 'styled-system';
import { StyledFormInput } from 'ui/components';

type CardProps = ColorProps &
	LayoutProps &
	SpaceProps &
	FlexProps &
	FlexboxProps & {
		userPoints?: boolean;
		userRewards?: boolean;
		favoriteFood?: boolean;
		favoriteVenues?: boolean;
		earningVenues?: boolean;
		venuesBanner?: boolean;
		venuesDetails?: boolean;
		signUpIntro?: boolean;
		profileCard?: boolean;
		purchaseHistory?: boolean;
		purchaseHistoryPointBlock?: boolean;
		merchantCustomerCard?: boolean;
		merchantCustomerCardActive?: boolean;
		cardSelector?: boolean;
		contentCard?: boolean;
		radius?: string;
		newCardIntro?: boolean;
	};
const Card = styled.div<CardProps>`
	${layout}
	${space}
	${color}
	${textAlign}
	${flexbox}
	border-radius: ${({ radius }) => radius || '16px'};
	border: solid 1px ${({ theme }) => theme.colors.border.default};
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
	${({ userPoints }) =>
		userPoints &&
		css`
			background: ${({ theme }) => theme.baseColors.baseYellow};
			padding: 18px;
			display: flex;
			justify-content: space-between;
			border-radius: 8px;
			width: 274px;
			margin-left: 16px;
			transition: box-shadow 100ms ease-in, transform 100ms ease-in;
			cursor: pointer;
			border: none;

			&:hover,
			&:focus {
				box-shadow: ${({ theme }) => theme.shadows.large};
				transform: translateY(-2px);
			}

			&:active {
				transform: translateY(2px);
			}

			&:first-child {
				margin-left: 0;
			}
			small {
				line-height: 18px;
				display: flex;
				align-items: center;
			}
			h1 {
				line-height: 31px;
			}
			svg {
				height: 68px;
				width: 68px;
				float: right;
			}
			@media (max-width: 1360px) {
				width: 100%;
				margin-top: 18px;
			}
			@media (max-width: 1152px) {
				width: 100%;
				margin-top: 18px;
				@media (max-width: 1152px) {
					width: calc(100% / 2 - 5px);
					margin-top: 10px;
					margin-left: 0;
				}
			}
			@media (max-width: 768px) {
				margin-left: 0;
				margin-top: 1px;
				border-radius: 0;
				width: 100%;
				align-items: center;
				&:first-child {
					margin-top: 14px;
					border-radius: 8px 8px 0 0;
				}
				&:last-child {
					border-radius: 0 0 8px 8px;
				}
			}
		`}
	${({ userRewards }) =>
		userRewards &&
		css`
			background: ${({ theme }) => theme.baseColors.white};
			padding: 18px;
			display: flex;
			justify-content: space-between;
			border-radius: 8px;
			width: 100%;
			align-items: Center;
			margin-left: 28px;
			border: none;
			&:first-child {
				margin-left: 0;
			}
			h1 {
				margin: 0px;
				padding: 0px;
				line-height: 34px;
			}
			small {
				line-height: 19px;
				font-family: 'OpenSansMedium';
			}
			svg {
				height: auto;
				width: auto;
				float: right;
			}
			@media (max-width: 1366px) {
				width: calc(100% / 3 - 8px);
				margin-top: 10px;
				margin-left: 4px;
				margin-right: 4px;
				&:first-child {
					margin-left: 4px;
				}
			}
			@media (max-width: 1152px) {
				width: calc(100% / 2 - 8px);
			}
			@media (max-width: 768px) {
				width: 100%;
				margin: 0 !important;
				border-radius: 0;
				border-bottom: 1px solid ${({ theme }) => theme.colors.border.greyBorder};
				&:first-child {
					margin-top: 0;
					margin-left: 0;
					border-radius: 8px 8px 0 0;
				}
				&:last-child {
					border-radius: 0 0 8px 8px;
					border-bottom: 0;
				}
			}
		`}
	${({ favoriteFood }) =>
		favoriteFood &&
		css`
			background: ${({ theme }) => theme.colors.bg.lightYellow};
			padding: 18px;
			display: flex;
			flex-direction: column;
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
			border-radius: 16px;
			width: 100%;
			align-items: Center;
			margin-left: 13px;
			border: none;
			&:first-child {
				margin-left: 0;
			}
			img {
				margin-top: -76px;
				width: 116px;
				height: 116px;
				border-radius: 50%;
				+ div img {
					margin: 0 auto;
					width: auto;
					height: 48px;
					border-radius: 0;
				}
				+ div {
					p {
						margin: 16px 0 20px 0;
						text-align: center;
						word-break: break-all;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						overflow: hidden;
					}
					@media (max-width: 768px) {
						display: flex;
						width: 100%;
						justify-content: space-between;
						align-items: center;
						padding: 23px 6px 18px 0;
						p {
							margin: 0;
							text-align: left;
							padding-right: 20px;
						}
						img {
							max-width: 48px;
							margin: 0;
						}
					}
				}
			}

			@media (max-width: 1152px) {
				width: 100%;
				margin-top: 18px;
			}
			@media (max-width: 768px) {
				padding: 0 14px;
				img {
					flex: 0 0 110px;
					width: 110px;
					height: 110px;
					max-width: 110px;
				}
			}
		`}
	${({ favoriteVenues }) =>
		favoriteVenues &&
		css`
			background: ${({ theme }) => theme.colors.bg.white};
			border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
			display: flex;
			padding: 20px;
			flex-direction: column;
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
			border-radius: 16px;
			width: 100%;
			align-items: Center;
			margin-left: 13px;
			text-align: center;
			&:first-child {
				margin-left: 0;
			}
			img {
				margin-top: 0;
				height: 68px;
			}
			h1 {
				margin-top: 12px;
				margin-bottom: 0;
				line-height: 31px;
			}
			p {
				margin: 4px 0 0 0;
				text-align: center;
			}
			@media (max-width: 1152px) {
				width: 100%;
				margin-top: 20px;
			}
		`}
	${({ earningVenues }) =>
		earningVenues &&
		css`
			background: ${({ theme }) => theme.colors.bg.white};
			border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
			padding: 0;
			display: flex;
			flex-direction: column;
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
			border-radius: 16px;
			width: 100%;
			align-items: Center;
			margin-left: 13px;
			text-align: center;
			overflow: hidden;
			border: none;
			&:first-child {
				margin-left: 0;
			}

			@media (max-width: 1152px) {
				width: 100%;
				margin-top: 18px;
			}
		`}
	${({ signUpIntro }) =>
		signUpIntro &&
		css`
			background: ${({ theme }) => theme.colors.border.yellowDark};
			border: 2px dashed ${({ theme }) => theme.baseColors.baseYellow};
			padding: 24px;
			display: flex;
			border-radius: 16px;
			width: 450px;
			margin: 40px 0;
			svg {
				float: left;
			}
			div {
				width: 232px;
				display: flex;
				margin-left: 32px;
				div {
					display: contents;
					font-size: 16px;
				}
			}

			@media (max-width: 640px) {
				width: 100%;
				flex-direction: column;
				text-align: center;
				svg {
					margin: auto;
					float: none;
				}
				div {
					margin-left: 0;
					width: 100%;
					justify-content: center;
				}
			}
		`}
		${({ newCardIntro }) =>
		newCardIntro &&
		css`
			background: ${({ theme }) => theme.colors.border.yellowDark};
			border: 1px dashed ${({ theme }) => theme.baseColors.baseYellow};
			padding: 12px;
			display: flex;
			border-radius: 8px;
			width: 100%;
			margin: 42px 0 25px;
			text-align: center;
			box-shadow: none;
			div {
				width: 100%;
			}

			@media (max-width: 640px) {
				width: 100%;
				flex-direction: column;
				text-align: center;
				svg {
					margin: auto;
					float: none;
				}
				div {
					margin-left: 0;
					width: 100%;
					justify-content: center;
				}
			}
		`}
		${({ profileCard }) =>
		profileCard &&
		css`
			background: ${({ theme }) => theme.colors.bg.blackCard};
			padding: 11px 14px 8px 0px;
			display: flex;
			border-radius: 8px;
			width: 100%;
			min-height: 50px;
			margin-top: 6px;
			min-width: 225px;
			max-width: 225px;
			border: none;
			@media (max-width: 640px) {
				position: absolute;
				left: 0;
				bottom: 0;
				max-width: 100%;
			}
			h1 {
				align-items: center;
				line-height: normal;
				span {
					margin-right: 4px;
					float: left;
					svg {
						float: left;
					}
				}
				small {
					background: ${({ theme }) => theme.colors.bg.white};
					color: ${({ theme }) => theme.colors.text.black};
					border-radius: 3px;
					font-family: 'OpenSansBold';
					line-height: normal;
					font-size: 10px;
					line-height: 12px;
					text-transform: uppercase;
					padding: 1px 5px 1px;
					margin-left: 6px;
					margin-top: 3px;
				}
			}
			button {
				padding: 0;
				background: transparent;
				border: none;
			}
			.selectFiled {
				width: 100%;
				margin: 0;
				div {
					width: 100%;
					select {
						padding: 8px 0 0 14px;
						border: none;
						outline: none;
						color: ${({ theme }) => theme.colors.text.white};
						font-family: 'OpenSansBold';
						font-size: 13px;
						position: relative;
						z-index: 1;
						option {
							color: ${({ theme }) => theme.colors.text.black};
							padding-left: 10px;
						}
						+ svg {
							display: none;
						}
					}
				}

				span {
					margin-left: -12px;
					float: right;
					height: 8px;
					margin-top: 14px;
					svg {
						float: right;
						path {
							fill: ${({ theme }) => theme.colors.bg.white};
						}
					}
				}
			}
		`}

		${({ cardSelector }) =>
		cardSelector &&
		css`
			@media (max-width: 640px) {
				position: relative;
				left: initial;
				bottom: initial;
				max-width: 95%;
				margin-left: auto;
				margin-right: auto;
			}
		`}

		${({ merchantCustomerCard }) =>
		merchantCustomerCard &&
		css`
			cursor: pointer;
			background: rgba(135, 137, 99, 1);
			background: -moz-linear-gradient(
				25deg,
				rgba(135, 137, 99, 1) 0%,
				rgba(141, 142, 106, 1) 49%,
				rgba(135, 137, 103, 1) 50%,
				rgba(164, 165, 136, 1) 100%
			);
			background: -webkit-gradient(
				left bottom,
				right top,
				color-stop(0%, rgba(135, 137, 99, 1)),
				color-stop(49%, rgba(141, 142, 106, 1)),
				color-stop(50%, rgba(135, 137, 103, 1)),
				color-stop(100%, rgba(164, 165, 136, 1))
			);
			background: -webkit-linear-gradient(
				25deg,
				rgba(135, 137, 99, 1) 0%,
				rgba(141, 142, 106, 1) 49%,
				rgba(135, 137, 103, 1) 50%,
				rgba(164, 165, 136, 1) 100%
			);
			background: -o-linear-gradient(
				25deg,
				rgba(135, 137, 99, 1) 0%,
				rgba(141, 142, 106, 1) 49%,
				rgba(135, 137, 103, 1) 50%,
				rgba(164, 165, 136, 1) 100%
			);
			background: -ms-linear-gradient(
				25deg,
				rgba(135, 137, 99, 1) 0%,
				rgba(141, 142, 106, 1) 49%,
				rgba(135, 137, 103, 1) 50%,
				rgba(164, 165, 136, 1) 100%
			);
			background: linear-gradient(
				25deg,
				rgba(135, 137, 99, 1) 0%,
				rgba(141, 142, 106, 1) 49%,
				rgba(135, 137, 103, 1) 50%,
				rgba(164, 165, 136, 1) 100%
			);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#878963', endColorstr='#a4a588', GradientType=1 );
			padding: 20px;
			display: flex;
			border-radius: 12px;
			width: 100%;
			margin-left: 16px;
			min-height: 134px;
			position: relative;
			border: none;
			transition: box-shadow 100ms ease-in, transform 100ms ease-in;
			transition: ease-in all 200ms;
			align-items: center;
			justify-content: space-between;
			&:first-child {
				margin-left: 0;
			}
			&:hover,
			&:focus {
				box-shadow: ${({ theme }) => theme.shadows.large};
				transform: translateY(-2px);
			}
			&:hover {
				background: rgba(236, 178, 54, 1);
				background: -moz-linear-gradient(
					25deg,
					rgba(236, 178, 54, 1) 0%,
					rgba(236, 177, 54, 1) 50%,
					rgba(238, 187, 77, 1) 50%,
					rgba(247, 223, 172, 1) 100%
				);
				background: -webkit-gradient(
					left bottom,
					right top,
					color-stop(0%, rgba(236, 178, 54, 1)),
					color-stop(50%, rgba(236, 177, 54, 1)),
					color-stop(50%, rgba(238, 187, 77, 1)),
					color-stop(100%, rgba(247, 223, 172, 1))
				);
				background: -webkit-linear-gradient(
					25deg,
					rgba(236, 178, 54, 1) 0%,
					rgba(236, 177, 54, 1) 50%,
					rgba(238, 187, 77, 1) 50%,
					rgba(247, 223, 172, 1) 100%
				);
				background: -o-linear-gradient(
					25deg,
					rgba(236, 178, 54, 1) 0%,
					rgba(236, 177, 54, 1) 50%,
					rgba(238, 187, 77, 1) 50%,
					rgba(247, 223, 172, 1) 100%
				);
				background: -ms-linear-gradient(
					25deg,
					rgba(236, 178, 54, 1) 0%,
					rgba(236, 177, 54, 1) 50%,
					rgba(238, 187, 77, 1) 50%,
					rgba(247, 223, 172, 1) 100%
				);
				background: linear-gradient(
					25deg,
					rgba(236, 178, 54, 1) 0%,
					rgba(236, 177, 54, 1) 50%,
					rgba(238, 187, 77, 1) 50%,
					rgba(247, 223, 172, 1) 100%
				);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ecb236', endColorstr='#f7dfac', GradientType=1 );
			}
			h1 {
				text-transform: uppercase;
				line-height: 26px;
			}
			h2 {
				font-size: 30px;
				line-height: 39px;
				margin-top: 8px;
				span {
					font-size: 19px;
				}
			}
		`}
		${({ merchantCustomerCardActive }) =>
		merchantCustomerCardActive &&
		css`
			background: rgba(236, 178, 54, 1);
			background: -moz-linear-gradient(
				25deg,
				rgba(236, 178, 54, 1) 0%,
				rgba(236, 177, 54, 1) 50%,
				rgba(238, 187, 77, 1) 50%,
				rgba(247, 223, 172, 1) 100%
			);
			background: -webkit-gradient(
				left bottom,
				right top,
				color-stop(0%, rgba(236, 178, 54, 1)),
				color-stop(50%, rgba(236, 177, 54, 1)),
				color-stop(50%, rgba(238, 187, 77, 1)),
				color-stop(100%, rgba(247, 223, 172, 1))
			);
			background: -webkit-linear-gradient(
				25deg,
				rgba(236, 178, 54, 1) 0%,
				rgba(236, 177, 54, 1) 50%,
				rgba(238, 187, 77, 1) 50%,
				rgba(247, 223, 172, 1) 100%
			);
			background: -o-linear-gradient(
				25deg,
				rgba(236, 178, 54, 1) 0%,
				rgba(236, 177, 54, 1) 50%,
				rgba(238, 187, 77, 1) 50%,
				rgba(247, 223, 172, 1) 100%
			);
			background: -ms-linear-gradient(
				25deg,
				rgba(236, 178, 54, 1) 0%,
				rgba(236, 177, 54, 1) 50%,
				rgba(238, 187, 77, 1) 50%,
				rgba(247, 223, 172, 1) 100%
			);
			background: linear-gradient(
				25deg,
				rgba(236, 178, 54, 1) 0%,
				rgba(236, 177, 54, 1) 50%,
				rgba(238, 187, 77, 1) 50%,
				rgba(247, 223, 172, 1) 100%
			);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ecb236', endColorstr='#f7dfac', GradientType=1 );
		`}

		${({ contentCard }) =>
		contentCard &&
		css`
			border-radius: 8px;
			border-width: 1px;
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
			padding: 15px;

			@media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
				border-radius: 16px;
				padding: 25px;
			}
		`}
`;

const CardHeader = styled.div<CardProps>`
	${layout}
	${space}
  ${color}

  --bg: var(--color-neutral-contrast-03);

	background: var(--bg);
	border-top-left-radius: ${({ theme }) => theme.radii.soft};
	border-top-right-radius: ${({ theme }) => theme.radii.soft};
	border-bottom: solid 1px ${({ theme }) => theme.colors.border.default};

	&&& ${StyledFormInput} {
		--input-bg: var(--color-neutral-contrast-00);

		html[data-color-mode='dark'] & {
			--input-bg: var(--color-neutral-contrast-02);
		}
	}
	${({ venuesBanner }) =>
		venuesBanner &&
		css`
			height: 144px;
			width: 100%;
		`}
`;

const CardBody = styled.div<CardProps>`
	${layout}
	${space}
  ${color}
  ${({ venuesDetails }) =>
		venuesDetails &&
		css`
			background: ${({ theme }) => theme.colors.bg.lightYellow};
			padding: 0 22px 22px;
			display: flex;
			flex-direction: column;
			width: 100%;
			align-items: Center;
			text-align: center;
			&:first-child {
				margin-left: 0;
			}
			img {
				background: ${({ theme }) => theme.colors.bg.white};
				border: 8px solid ${({ theme }) => theme.colors.border.white};
				box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
				border-radius: 50%;
				width: 116px;
				height: 116px;
				margin-top: -58px;
			}
			h1 {
				margin-top: 10px;
				margin-bottom: 0;
				line-height: 29px;
			}
			p {
				margin: 4px 0 31px 0;
				text-align: center;
			}
			h2 {
				margin-left: 5px;
			}
		`}
`;

export { Card, CardHeader, CardBody };
