import styled from 'styled-components';
import { getHSL } from '../../ui/utils/getHSL';

const MainContent = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
	padding: 0 50px;
	@media (max-width: 960px) {
		padding: 0 15px;
	}
`;
const AddUserHolder = styled.div`
	width: 100%;
	background: ${({ theme }) => theme.colors.bg.white};
	position: relative;
	margin: 0 auto;
	border: 1px solid ${({ theme }) => theme.colors.border.userListTD};
	box-sizing: border-box;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07);
	border-radius: 8px;
	margin-bottom: 50px;
`;
const FooterBtnBlock = styled.div`
	padding: 0 50px 58px;

	div {
		width: 875px;
		@media (max-width: 1200px) {
			width: 100%;
		}
	}
	button {
		min-width: 100px;
		text-transform: capitalize;
		padding: 0;
		height: 48px;
		flex: 0 0 100px;
	}
	@media (max-width: 767px) {
		button {
			flex: 0 0 100px;
		}
	}
	@media (max-width: 640px) {
		padding: 11px 14px 28px;
		column-gap: 10px;
		button {
			min-width: auto;
			flex: 1;
		}
	}
`;
const ProfileDetails = styled.div`
	margin: 50px 60px 50px;
	padding-bottom: 50px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.border.grayMedium03};
	@media (max-width: 640px) {
		margin: 15px;
		padding-bottom: 30px;
	}
	h1 {
		text-transform: uppercase;
		margin-bottom: 25px;
		@media (max-width: 640px) {
			margin-bottom: 15px;
		}
	}
	label {
		font-size: 16px;
		line-height: 21px;
		font-family: 'OpenSansSemiBold';
		color: ${({ theme }) => theme.colors.text.SubHeading};
		margin-bottom: 8px;
	}
	form {
		width: 865px;
		@media (max-width: 1100px) {
			width: 100%;
		}
		.Grid {
			grid-template-columns: repeat(2, 1fr) !important;
			column-gap: 25px;
			row-gap: 25px;
			@media (max-width: 640px) {
				grid-template-columns: repeat(1, 1fr) !important;
			}
		}
		textarea {
			height: 116px;
			margin-bottom: 20px;
		}
		.amount {
			position: relative;
			p {
				position: absolute;
				top: 42px;
				left: 20px;
				color: ${({ theme }) => theme.colors.text.body}!important;
				font-family: 'OpenSansSemiBold' !important;
				font-size: 18px !important;
			}
			input {
				padding-left: 34px;
			}
		}
		.status {
			position: relative;
			div {
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				top: 24px;
				@media (max-width: 640px) {
					position: relative;
					top: 0;
				}
				label {
					height: 26px;
					display: flex;
					align-items: center;
				}
				label + label {
					margin: 0 0 0 9px !important;
				}
			}
		}
	}
`;
const AssignVenueHolder = styled.div`
	padding: 0 50px 50px;
	width: 100%;
	@media (max-width: 640px) {
		padding: 15px;
	}
	label {
		font-size: 16px;
		line-height: 21px;
		font-family: 'OpenSansSemiBold';
		color: ${({ theme }) => theme.colors.text.SubHeading};
	}
	h1 {
		text-transform: uppercase;
		margin-bottom: 22px;
	}
	form {
		display: flex;
		flex-direction: column;
		width: 875px;

		@media (max-width: 1200px) {
			width: 100%;
		}
		@media (max-width: 640px) {
			flex-direction: column;
		}
		.Grid {
			grid-template-columns: repeat(2, 1fr) !important;
			column-gap: 25px;
			row-gap: 25px;
			@media (max-width: 1200px) {
				flex-grow: 1;
			}
			@media (max-width: 640px) {
				width: 100%;
			}
			div {
				grid-column: auto / span 1 !important;
				margin: 0 !important;
			}
			@media (max-width: 640px) {
				grid-template-columns: repeat(1, 1fr) !important;
			}
		}
		button {
			width: 100px;
			height: 50px;
			margin-left: 22px;
			@media (max-width: 640px) {
				width: 100%;
				margin: 20px 0 0 0 !important;
			}
		}
	}
`;
const AutocompleteHolder = styled.div`
	padding: 0;
	width: 100%;
	div {
		width: 100%;
		position: relative;
	}
	.icon-container {
		position: relative;
		top: -37px;
	}
	.loader {
		position: relative;
		float: right;
		right: 1%;
		height: 20px;
		width: 20px;
		display: inline-block;
		animation: around 5.4s infinite;
	}

	@keyframes around {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	.loader::after,
	.loader::before {
		content: '';
		background: white;
		position: absolute;
		display: inline-block;
		width: 100%;
		height: 100%;
		border-width: 2px;
		border-color: #333 #333 transparent transparent;
		border-style: solid;
		border-radius: 20px;
		box-sizing: border-box;
		top: 0;
		left: 0;
		animation: around 0.7s ease-in-out infinite;
	}

	.loader::after {
		animation: around 0.7s ease-in-out 0.1s infinite;
		background: transparent;
	}
	input {
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.5;
		height: 50px;
		width: 100%;
		--px: 0.75em;
		--py: 0.75em;
		--a: 80%;
		--h: ${({ theme }) => getHSL('h', theme.colors.border.default)};
		--s: ${({ theme }) => getHSL('s', theme.colors.border.default)};
		--l: ${({ theme }) => getHSL('l', theme.colors.border.default)};
		--input-bg: #fff;
		display: block;
		width: 100%;
		height: calc(calc(var(--py) * 2) + calc(var(--px) * 2) + 2px);
		padding: var(--py) var(--px);
		font-family: 'OpenSansSemiBold';
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.5;
		color: ${({ theme }) => theme.colors.text.body};
		background-color: var(--input-bg);
		background-clip: padding-box;
		border: 1px solid ${({ theme }) => theme.colors.border.default};
		border-radius: ${({ theme }) => theme.radii.softer};
		transition: all 200ms ease-in-out;
		-webkit-appearance: none;
		appearance: none;

		&:focus {
			background-color: #fff;
			border-color: ${({ theme }) => theme.colors.primary};
			outline: 0;
			box-shadow: 0 0 0 0.2rem hsla(var(--h), var(--s), var(--l), 30%);
		}
		& + div {
			position: absolute !important;
			width: 100% !important;
			top: 50px !important;
			left: 0 !important;
			max-height: 250px !important;
			z-index: 9;
			box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.39) !important;

			div {
				padding: 10px;
				border-bottom: 1px solid rgba(189, 188, 185, 0.5);
				font-family: 'OpenSansSemiBold';
				font-size: 17px;
				cursor: pointer;
				color: ${({ theme }) => theme.colors.text.black07};
				&:hover,
				&:focus,
				&:active {
					background: ${({ theme }) => theme.colors.primary} !important;
					color: ${({ theme }) => theme.colors.text.white};
				}
			}
		}
	}
`;
export {
	MainContent,
	FooterBtnBlock,
	AddUserHolder,
	ProfileDetails,
	AssignVenueHolder,
	AutocompleteHolder,
};
