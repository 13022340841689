import { dcUserPermissionList, userTypes } from 'models/enum-constants/common.constants';
import Iget from 'models/Iget';
import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link, Text } from 'rebass/styled-components';
import urls from 'utils/create.url';
import helperFunctions from 'utils/helper.functions';
import pageConstants from 'utils/pageConstants';
import hooks from 'utils/requestCtx/reactQueryHooks';
import utilityFunctions from 'utils/utility.functions';
import { Nav, TreeMenuHolder } from './styles';
import { Button, Classes, Menu, MenuItem, Position } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import * as Icon from 'ui/icons';
import TreeMenu, { defaultChildren, ItemComponent } from 'react-simple-tree-menu';
import 'react-simple-tree-menu/dist/main.css';

function HeaderMenu(props): ReactElement {
	const [showOptionsCat, setShowOptionsCat] = useState(false);
	const history = useHistory();
	const [catList, setCatList] = useState([] as any);
	var date = new Date();
	date.setDate(date.getDate() - 30);
	const selectedDate = date.toISOString().split('T')[0];
	const url1 = urls.GetSearchUrl({ url: 'categories/list?', pageSize: 1000 });
	const userKey1 = 'catKey';
	const [supplierList, setSupplierList] = useState([] as any);
	const [categoryList, setCategoryList] = useState([] as any);

	const categoryObj: Partial<Iget> = {
		params: {},
		url: url1,
		enabled: true,
		key: userKey1,
		resultFilteringRequired: true,
		caching: true,
	};
	var catQry = hooks.useQuery(categoryObj);
	const enabled =
		localStorage.getItem('selectedVenue') !== null &&
		localStorage.getItem('selectedVenue') !== 'undefined' &&
		props.userType == userTypes.HospitalityGroup;
	const suppObj: Partial<Iget> = {
		params: {},
		url: '/experionproductmanagement/getsuppliers/' + localStorage.getItem('selectedVenue')!,
		enabled: enabled,
		key: 'suppKey',
		resultFilteringRequired: false,
		caching: false,
	};
	var supQry = hooks.useQuery(suppObj);

	useEffect(() => {
		if (supQry.data !== undefined) {
			setSupplierList(supQry.data);
		}
	}, [supQry.data]);

	useEffect(() => {

		let list = [] as any;
		catQry.data &&
			catQry.data.filter((x) => x.include_in_menu == true).length > 0 &&
			catQry.data
				.filter((x) => x.include_in_menu == true)
				.map((item) => {

					// if (item.children !== '' && item.id !== 1) {
					list.push({
						id: item.id,
						name: item.name,
						parent_id: item.parent_id,
						include_in_menu: item.include_in_menu,
					});
					// }
				});
		setCatList(list);
		const data = dropDown(catQry.data);
		setCategoryList({ data });
	}, [catQry]);

	const onSelectCategory = (id) => {
		if (id) {
			history.push({
				pathname: '/home/product-list',
				search: '?search=&type=Products' + '&cat=' + id,
			});
			props.queryString && props.queryString('?search=' + '&cat=' + id);
		}
		setCategoryList(JSON.parse(JSON.stringify(categoryList)));
		setShowOptionsCat(!showOptionsCat);
	};

	const dropDown = (data) => {

		var catItems = [];
		if (data && data.length > 0) {
			let categoryItems = [] as any;
			data.map((innerData) => {
				let item = {
					url: 0,
					key: '',
					parent_id: 0,
					label: '',
				};
				item.url = innerData.id;
				item.key = innerData.name;
				item.parent_id = innerData.parent_id;
				item.label = innerData.name;
				categoryItems.push(item);
			});
			var o = {};
			categoryItems &&
				categoryItems.length > 0 &&
				categoryItems.forEach(function (a) {
					// a.nodes = o[a.url] && o[a.url].nodes;
					a.nodes = o[a.url] && o[a.url].nodes;
					o[a.url] = a;
					if (a.parent_id === 1 && a.url === 21) {
						a.label = 'Category';
						a.key = 'Category';
						catItems = a;
					} else {
						o[a.parent_id] = o[a.parent_id] || {};
						o[a.parent_id].nodes = o[a.parent_id].nodes || [];
						o[a.parent_id].nodes.push(a);
					}
				});
			return catItems;
		} else {
			return [];
		}
	};

	return (
		<Nav className={props.className}>
			{props.userType == userTypes.HospitalityGroup && (
				<>
					<Popover2
						captureDismiss={true}
						className="categoryMenu"
						popoverClassName={Classes.POPOVER_DISMISS}
						autoFocus={false}
						position={Position.RIGHT_TOP}
						content={
							<Menu key="category">
								{catList &&
									catList
										?.filter((x) => x.parent_id == 1 && x.id == 21)
										.map((l0) => {
											let level1 = catList?.filter((y) => y.parent_id == l0.id);
											// return level1.length == 0 ? <MenuItem onClick={()=>{onSelectCategory(l0.id)}}>{l0.name}</MenuItem>:
											//   <SubMenu label = {`${l0.name}`}>
											return level1.map((l1) => {
												let level2 = catList?.filter((p) => p.parent_id == l1.id);
												return level2.length == 0 ? (
													<MenuItem
														key={helperFunctions.getRandomInt(pageConstants.maxValue)}
														onClick={() => {
															onSelectCategory(l1.id);
														}}
														text={l1.name}
													/>
												) : (
													<MenuItem
														text={l1.name}
														onClick={() => {
															onSelectCategory(l1.id);
														}}
														key={helperFunctions.getRandomInt(pageConstants.maxValue)}
													>
														{level2.map((l2) => {
															let level3 = catList?.filter((p) => p.parent_id == l2.id);
															return level3.length == 0 ? (
																<MenuItem
																	key={helperFunctions.getRandomInt(pageConstants.maxValue)}
																	onClick={() => {
																		onSelectCategory(l2.id);
																	}}
																	text={l2.name}
																/>
															) : (
																<MenuItem
																	text={l2.name}
																	key={helperFunctions.getRandomInt(pageConstants.maxValue)}
																	onClick={() => {
																		onSelectCategory(l2.id);
																	}}
																>
																	{level3.map((l3) => {
																		let level4 = catList?.filter((p) => p.parent_id == l3.id);
																		return level4.length == 0 ? (
																			<MenuItem
																				key={helperFunctions.getRandomInt(pageConstants.maxValue)}
																				onClick={() => {
																					onSelectCategory(l3.id);
																				}}
																				text={l3.name}
																			/>
																		) : (
																			<MenuItem
																				text={l3.name}
																				key={helperFunctions.getRandomInt(pageConstants.maxValue)}
																				onClick={() => {
																					onSelectCategory(l3.id);
																				}}
																			>
																				{level4.map((l4) => {
																					let level5 = catList?.filter((p) => p.parent_id == l4.id);
																					return level5.length == 0 ? (
																						<MenuItem
																							key={helperFunctions.getRandomInt(
																								pageConstants.maxValue
																							)}
																							onClick={() => {
																								onSelectCategory(l4.id);
																							}}
																							text={l4.name}
																						/>
																					) : (
																						<MenuItem
																							text={l4.name}
																							key={helperFunctions.getRandomInt(
																								pageConstants.maxValue
																							)}
																							onClick={() => {
																								onSelectCategory(l4.id);
																							}}
																						>
																							{level5.map((l5) => {
																								let level6 = catList?.filter(
																									(p) => p.parent_id == l5.id
																								);
																								return level6.length == 0 ? (
																									<MenuItem
																										key={helperFunctions.getRandomInt(
																											pageConstants.maxValue
																										)}
																										onClick={() => {
																											onSelectCategory(l5.id);
																										}}
																										text={l5.name}
																									/>
																								) : (
																									<MenuItem
																										text={l5.name}
																										key={helperFunctions.getRandomInt(
																											pageConstants.maxValue
																										)}
																										onClick={() => {
																											onSelectCategory(l5.id);
																										}}
																									>
																										{level6.map(() => {
																											// let level7 = catList?.filter(
																											// 	(p) => p.parent_id == l6.id
																											// );
																										})}
																									</MenuItem>
																								);
																							})}
																						</MenuItem>
																					);
																				})}
																			</MenuItem>
																		);
																	})}
																</MenuItem>
															);
														})}
													</MenuItem>
												);
											});
										})}
							</Menu>
						}
					>
						<Button text="Category">
							<Icon.Options fill="#fff" />
						</Button>
					</Popover2>
					<TreeMenuHolder>
						{categoryList && <TreeMenu
							data={categoryList}
							debounceTime={125}
							disableKeyboard={false}
							hasSearch={false}
							initialOpenNodes={[]}
							resetOpenNodesOnDataUpdate={true}
							onClickItem={({ key, label, ...props }) => {

								if (props.url !== 1 && props.url !== 21) {
									onSelectCategory(props.url);
								}
							}}
						>
							{({ search, items, resetOpenNodes }) => (
								<ul>
									{items.map(({ key, ...props }) => (
										<div className="menuItem" key={key}>
											<ItemComponent key={key} {...props} />
										</div>
									))}
								</ul>
							)
							}
						</TreeMenu>}
					</TreeMenuHolder>
					{/* <AppNestedMenu className="suppliersMenu"> */}
					{/* <Menu
							menuButton={
								<MenuButton>
									Suppliers <Icon.Options fill="#fff" />
								</MenuButton>
							}
						>
							{supplierList && supplierList.length !== 0
								? supplierList.map((supObject) => (
										<MenuItem
											key={supObject.supplier_attribute_id}
											onClick={() => {
												history.push(
													'/home/product-list?search=&type=Suppliers&supplierCompanyName=' +
														`${supObject.supplier_attribute_id}`
												);
											}}
										>
											{supObject.supplier_name}
										</MenuItem>
								))
							: 'No data'}
					</Menu> */}
					<Popover2
						captureDismiss={true}
						className="suppliersMenu"
						popoverClassName={Classes.POPOVER_DISMISS}
						position={Position.RIGHT_TOP}
						autoFocus={false}
						content={
							<Menu>
								{supplierList && supplierList.length !== 0
									? supplierList.map((supObject) => (
										<MenuItem
											key={supObject.supplier_attribute_id}
											onClick={() => {
												history.push(
													'/home/product-list?search=&type=Suppliers&supplierCompanyName=' +
													`${supObject.supplier_attribute_id}`
												);
												props.closeHumburgur();
											}}
											text={supObject.supplier_name}
										/>
									))
									: 'No data'}
							</Menu>
						}
					>
						<Button text="Sellers">
							<Icon.Options fill="#fff" />
							<div className="supplierIcon">
								<span className="down">+</span>
								<span className="up">-</span>
							</div>
						</Button>
					</Popover2>
					{/* </AppNestedMenu> */}
					{/* <Link
						variant="nav"
						key={helperFunctions.getRandomInt(pageConstants.maxValue)}
						onClick={() => {
							history.push(`/home/product-list?search=&type=Products&specialProductsView=true`);
							props.closeHumburgur();
						}
						}
					>
						<Text as="label" style={{ cursor: 'pointer' }}>
							Sales
						</Text>
					</Link> */}
					<Link
						variant="nav"
						key={helperFunctions.getRandomInt(pageConstants.maxValue)}
						onClick={() => {
							history.push(`/home/product-list?search=&type=Products&newArrivalDate=${selectedDate}`);
							props.closeHumburgur();
						}
						}
					>
						{/* <Text as="label" style={{ cursor: 'pointer' }}>
							New Arrivals
						</Text> */}
					</Link>
				</>
			)}
			{props.userType == userTypes.Supplier ? (
				<>
					{/* {(utilityFunctions.isGranted(dcUserPermissionList.CreditStatusView) ||
						utilityFunctions.isGranted(dcUserPermissionList.CreditStatusUpdate)) && (
							<Link
								className="SupplierMenu"
								variant="nav"
								key={helperFunctions.getRandomInt(pageConstants.maxValue)}
								href="/admin/storeCreditStatus"
							>
								Store Credit Status
							</Link>
						)} */}
					{(utilityFunctions.isGranted(dcUserPermissionList.OrderManagementView) ||
						utilityFunctions.isGranted(dcUserPermissionList.OrderManagementUpdate)) && (
							<Link className="SupplierMenu" variant="nav" href="/admin/OrderList">
								Order Management
							</Link>
						)}
					{props.userAccessType == userTypes.supplierAdmin && (
						<Link className="SupplierMenu" variant="nav" href="/admin/DcList">
							DC Management
						</Link>
					)}
					{props.userAccessType == userTypes.supplierAdmin && (
						<Link className="SupplierMenu" variant="nav" href="/admin/attributeManagement">
							Attribute Management
						</Link>
					)}
				</>
			) : (
				''
			)}
		</Nav>
	);
}

export { HeaderMenu };
