import { Checkbox, Label } from '@rebass/forms';
import { UserBody } from 'admin/dashboardVenue/styles';
import React, { ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { Image, Text } from 'rebass/styled-components';
import { Button, SearchAutoSuggestion, TableList, Toggle } from 'ui/components';
import icons from 'ui/icons';
import { PageSearchHolder, UserListHolder } from './styles';
import no_image from 'ui/images/no_image.png';

function ProductList(props): ReactElement {
	const [showHideAutoSuggest] = useState(true);

	return (
		<UserListHolder>
			<PageSearchHolder>
				<SearchAutoSuggestion
					show={showHideAutoSuggest}
					setFilteredData={(val) => props.setFilteredData(val)}
					id={props.id}
					addSubstitutionProducts={(id) => props.addSubstitutionProducts(id)}
					subProductList={props.subProductList}
				></SearchAutoSuggestion>
				<Button
					disabled={!props.enableSubProductButton}
					onClick={props.deleteAllSubProducts}
					flex={[1, 1, 'initial']}
					variant="redOutline"
				>
					<icons.DeleteIcon />
					<div>Delete Selected</div>
				</Button>
			</PageSearchHolder>
			<TableList>
				<table>
					<thead>
						<tr>
							<th className="checked">
								{/* <Label>
									<Checkbox id="remember" name="remember" />
								</Label> */}
							</th>
							<th>Product Name</th>
							<th>Product Type</th>
							{/* <th>Attribute Set</th> */}
							<th>SKU</th>
							<th>Price</th>
							<th className="status">Status</th>
							<th className="action">Action</th>
						</tr>
					</thead>

					<tbody>
						{props.subProductsList && props.subProductsList.length > 0 ? (
							props.subProductsList.map(
								(
									{ id, images, name, price, sku, supplier_sku, status, type_id, isChecked },
									index
								) => (
									<tr key={index}>
										<td className="checked">
											<Label>
												<Checkbox
													id="remember"
													name="remember"
													onChange={(event) =>
														props.selectProductCheckBox && props.selectProductCheckBox(sku, event)
													}
													checked={isChecked}
												/>
											</Label>
										</td>
										<td data-label="Product Name" className="d-flex">
											<div className="img-block">
												<Image
													src={images == ''
														? no_image
														: images}
													sx={{
														width: 100,
														height: 100,
														border: 0,
													}}
												/>
											</div>
											<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
												<span>{name}</span>
											</Text>
										</td>
										<td data-label="Product Type">
											<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
												{type_id}
											</Text>
										</td>
										{/* <td data-label="Attribute Set">
											<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
												100
											</Text>
										</td> */}
										<td data-label="SKU">
											<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
												{supplier_sku}
											</Text>
										</td>
										<td data-label="Price">
											<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
												{price}
											</Text>
										</td>
										<td className="status" data-label="Status">
											<Toggle disabled={true} checked={status == 2 ? false : true} />
										</td>
										<td data-label="Action" className="action">
											<Text>
												<Button
													variant="deleteIcon"
													onClick={() => props.deleteSubstitutionPdt(sku)}
												>
													<icons.DeleteIcon />
												</Button>
											</Text>
										</td>
									</tr>
								)
							)
						) : (
							<tr>
								<UserBody>
									<Text fontSize={18} fontWeight="bold" color="#5C5E57" textAlign="center">
										No substitution products found
									</Text>
								</UserBody>
							</tr>
						)}
					</tbody>
				</table>
			</TableList>
		</UserListHolder>
	);
}

export { ProductList };
