import IapiService from 'models/IapiService';
import Iget from 'models/Iget';
import IgqlPost from 'models/IgqlPost';
import Ipost from 'models/Ipost';
import http from 'utils/http.service';
import config from 'utils/requestCtx/request.config';
import notify from 'utils/toast.service';
const api: IapiService = {
	//call to api goes here - GET request
	get: (request) => {
		return http
			.GET(config.getConfig(request.params, request.url, request.headers))
			.then(function (data) {
				//returning result
				return result(data, request.resultFilteringRequired);
			})
			.catch(function (err) {
				handleError(err);
			});
	},
	getEx: (request) => {
		return http
			.GET(config.getConfig(request.params, request.url, request.headers))
			.then(function (data) {
				//returning result
				return result(data, request.resultFilteringRequired);
			})
			.catch(function (err) {
				if (err.response.status != 404)
					handleError(err);
			});
	},
	post: (datas: Partial<Ipost>) => {
		return http
			.POST(config.postConfig(datas.data, datas.params, datas.url, datas.headers))
			.then(function (data) {
				//returning result
				if (datas.message !== ' ') {
					notify(datas.message ? datas.message : 'data posted successfully').success();
				}
				return result(data, datas.resultFilterRequired);
			})
			.catch(function (err) {
				handleError(err);
			});
	},
	bulkPost: (datas: Partial<Ipost>) => {
		return http
			.BULKPOST(config.postConfig(datas.data, datas.params, datas.url, datas.headers))
			.then(function (data) {
				//returning result
				if (datas.message !== ' ') {
					notify(datas.message ? datas.message : 'data posted successfully').success();
				}
				return result(data, datas.resultFilterRequired);
			})
			.catch(function (err) {
				handleError(err);
			});
	},
	gqlPost: (datas: Partial<IgqlPost>) => {
		return http
			.GRAPHQLPOST({ query: datas.query })
			.then(function (data) {
				//returning result
				return result(data, datas.resultFilterRequired);
			})
			.catch(function (err) {
				handleError(err);
			});
	},
	put: (datas: Partial<Ipost>) => {
		return http
			.PUT(config.postConfig(datas.data, datas.params, datas.url, datas.headers))
			.then(function (data) {
				//returning result
				if (datas.message !== ' ') {
					notify(datas.message ? datas.message : 'data updated successfully').success();
				}
				return result(data, datas.resultFilterRequired);
			})
			.catch(function (err) {
				handleError(err);
			});
	},
	delete: (datas: Partial<Ipost>) => {
		return http
			.DELETE(config.postConfig(datas.data, datas.params, datas.url, datas.headers))
			.then(function (data) {
				//returning result
				if (datas.message != ' ')
					notify(datas.message ? datas.message : 'data deleted successfully').success();
				return result(data, datas.resultFilterRequired);
			})
			.catch(function (err) {
				handleError(err);
			});
	},

};

function handleError(error) {
	parseErrorMessage(error);
}

function parseErrorMessage(err) {
	try {
		if (err.request.status === 400) {
			if (
				err.response &&
				err.response.data &&
				err.response.data.parameters &&
				err.response.data.parameters.length > 0
			) {
				notify(err.response.data?.parameters[0]).error();
			}
			else if (
				err.response &&
				err.response.data &&
				err.response.data.message
			) {
				notify(err.response.data?.message).error();
			}
			else if (err.message) {
				notify(err.message).error();
			} else if (err.response) {
			} else if (err.request) {
				var message = JSON.parse(err.request.response).message;
				if (message) notify(message).error();
			}
		}
		else if (err.request.status === 404) {
			//notify('Route not found').error(); //HACK need to implement AuthGuard
		}
		else if (err.request.status === 401) {
			if (
				err.response &&
				err.response.data &&
				err.response.data.message &&
				err.response.data.message === 'Incorrect username or password.Please wait and try again later.'
			) {
				notify(err.response.data?.message).error();
			} else {
				notify('Unauthorized access').error();
			}
		}
		else {
			console.log(err.request);
		}
	} catch (ex) {
		if (
			err.response &&
			err.response.data &&
			err.response.data.parameters &&
			err.response.data.parameters.length > 0
		) {
			notify(err.response.data?.parameters[0]).error();
		} else if (err.message) {
			notify(err.message).error();
		}
	}
}

function result(data, resultFilterRequired) {
	if (data.data != undefined) {
		const res = getData(data.data);
		return typeof res != 'undefined' && resultFilterRequired ? res : data.data;
	}
}

function getData(obj) {
	for (const key in obj) {
		if (Array.isArray(obj[key]) || typeof obj[key] == 'object') return obj[key];
	}
}

export default api;
