import React, { ReactElement } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { FlexboxProps, LayoutProps, SpaceProps } from 'styled-system';
import { Stack } from 'ui/components';
import { ProductMainDetailsHolder, StyledProductMainDetails, ViewAllHeadingBlock } from './styles';

type styledSystemProps = SpaceProps & LayoutProps & FlexboxProps;

type StyledProductMainDetailsProps = styledSystemProps & {
	totalAmount?: string;
	productDetails?: any;
	supplierInfo?: any;
	type?: string;
};

function ProductMainDetails({ ...styledSystemProps }: StyledProductMainDetailsProps): ReactElement {
	let Mov = styledSystemProps.supplierInfo?.MOV;
	var supplierSku =
		styledSystemProps.productDetails &&
		styledSystemProps.productDetails.custom_attributes.find(
			(x) => x.attribute_code == 'supplier_sku'
		);
		if (styledSystemProps.productDetails?.extension_attributes?.supplier_details.length > 0) {
			Mov = JSON.parse(styledSystemProps.productDetails?.extension_attributes?.supplier_details[0])?.MOV;
		}

	return (
		<StyledProductMainDetails {...styledSystemProps}>
			<ViewAllHeadingBlock>
				<Text as="h1" fontSize="18px">
					Details
				</Text>
			</ViewAllHeadingBlock>
			<ProductMainDetailsHolder>
				<Box>
					<Stack direction="y" gap={2}>
						<Flex as="article" bg="white" mb={0} p={[3, 4]} sx={{ alignItems: 'center' }}>
							{styledSystemProps?.type != 'supplierInfo' && (
								<Box flex={2}>
									<Text
										as="h2"
										fontFamily="OpenSansMedium"
										fontSize={16}
										lineHeight="23px"
										color="text.black03"
										className="truncate"
									>
										SKU
									</Text>
									<Text as="h3" fontSize="18px" color="text.black01" className="truncate">
										{styledSystemProps.productDetails?.sku}
									</Text>
								</Box>
							)}
							{styledSystemProps?.type != 'supplierInfo' && (
								<Box flex={2}>
									<Text
										as="h2"
										fontFamily="OpenSansMedium"
										fontSize={16}
										lineHeight="23px"
										color="text.black03"
										className="truncate"
									>
										SUPPLIER SKU
									</Text>
									<Text as="h3" fontSize="18px" color="text.black01" className="truncate">
										{supplierSku?.value}
									</Text>
								</Box>
							)}
						</Flex>
						{styledSystemProps?.type != 'supplierInfo' && (
							<div>
								<Flex as="article" bg="white" p={[3, 4]} sx={{ alignItems: 'center' }}>
									<Box flex={1}>
										<Text
											as="h2"
											fontFamily="OpenSansMedium"
											fontSize={16}
											lineHeight="23px"
											color="text.black03"
											className="truncate"
										>
											Product Category
										</Text>
										<Text as="h3" fontSize="18px" color="text.black01">
											{styledSystemProps.productDetails?.extension_attributes.productcatagory_name
												.length > 1
												? styledSystemProps.productDetails?.extension_attributes.productcatagory_name.map(
													(item) => item + ', '
												)
												: styledSystemProps.productDetails?.extension_attributes.productcatagory_name.map(
													(item) => item
												)}
										</Text>
									</Box>
								</Flex>
							</div>
						)}
						<Flex as="article" bg="white" p={[3, 4]} sx={{ alignItems: 'center' }}>
							<Box flex={2}>
								<Text
									as="h2"
									fontFamily="OpenSansMedium"
									fontSize={16}
									lineHeight="23px"
									color="text.black03"
									className="truncate"
								>
									MOV
										</Text>
								<Text as="h3" fontSize="18px" color="text.black01" className="truncate">
									{Mov}
								</Text>
							</Box>
						</Flex>
					</Stack>
				</Box>
			</ProductMainDetailsHolder>
		</StyledProductMainDetails>
	);
}

export { ProductMainDetails };
