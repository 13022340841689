import React, { ReactElement } from 'react';
import { Box, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { FlexboxProps, LayoutProps, SpaceProps } from 'styled-system';
const StyledProductDetails = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	padding: 32px 0 10px 0;
	justify-content: space-between;
	div {
		display: flex;
		h1 {
			text-transform: uppercase;
		}
		h2,
		h3 {
			text-transform: uppercase;
			margin-left: 80px;
			strong {
				font-size: 18px;
				margin-left: 10px;
			}
		}
	}
`;
type styledSystemProps = SpaceProps & LayoutProps & FlexboxProps;
type ProductDetailsProps = styledSystemProps & {
	totalItems?: string;
	orderTotal?: string;
};

function ProductDetails({
	totalItems,
	orderTotal,
	...styledSystemProps
}: ProductDetailsProps): ReactElement {
	return (
		<StyledProductDetails {...styledSystemProps}>
			<Box>
				<Text as="h1" fontSize={18} fontFamily="OpenSansBold" color="text.black">
					Product details
				</Text>
			</Box>
			<Box>
				{/* <Text as="h2" fontSize={14} color="text.black">
					Total Items<strong>{totalItems}</strong>
				</Text>
				<Text as="h3" fontSize={14} color="text.black">
					Order Total<strong>{orderTotal}</strong>
				</Text> */}
			</Box>
		</StyledProductDetails>
	);
}

export { ProductDetails };
