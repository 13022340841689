import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Text, Box, Flex } from 'rebass/styled-components';
import 'react-tippy/dist/tippy.css';
import { Search, Button, SortBy, Loader } from 'ui/components';
import styled from 'styled-components';
import icons, * as Icon from 'ui/icons';
import { Modal, RecentOrderFilter } from 'ui/patterns';
import NewFavoritesModal from '../recentOrderDetailsEditView/newFavorites';
import Skeleton from 'react-loading-skeleton';
import { UserBody } from 'admin/dashboardVenue/styles';
import { Tooltip } from 'react-tippy';
import utilityFunctions from 'utils/utility.functions';
import hooks from 'utils/requestCtx/reactQueryHooks';
import Iget from 'models/Iget';
import urlObj from 'models/url.obj';
import AppContext from 'utils/appContext';
import urls from 'utils/create.url';
import { OrderStatuses, venueUserPermissionList } from 'models/enum-constants/common.constants';
import { useHistory } from 'react-router-dom';
import helperFunctions from 'utils/helper.functions';
import pageConstants from 'utils/pageConstants';
import api from 'utils/requestCtx/api.service';
import constants from 'utils/constants';
import { UseGlobalState } from 'models/globalStateAccess';
import http from 'utils/http.service';
import SweetAlert from 'react-bootstrap-sweetalert/dist/components/SweetAlert';

const StyledOrderListView = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	column-gap: 20px;
	row-gap: 20px;
	article {
		flex-direction: row;
		background: ${({ theme }) => theme.colors.bg.white};
		border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
		box-sizing: border-box;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 16px;
		overflow: hidden;
		transition: box-shadow 100ms ease-in, transform 100ms ease-in;
		align-items: flex-start;
		cursor: pointer;
		flex-direction: column;
		&:hover {
			transform: translateY(-5px);
			box-shadow: 0 20px 20px #eee;
			border: 1px solid ${({ theme }) => theme.baseColors.baseYellowLight};
		}
		span {
			max-width: 120px;
			flex: 0 0 120px;
			height: 100%;
			min-height: 130px;
			background-repeat: no-repeat;
			background-size: cover;
			@media (max-width: 768px) {
				max-width: 126px;
				flex: 0 0 126px;
			}
			@media (max-width: 480px) {
				max-width: 100px;
				flex: 0 0 100px;
			}
		}
		section {
			display: grid !important;
			grid-template-columns: repeat(7, 1fr) !important;
			height: 100%;
			padding: 20px 25px;
			align-items: center;
			column-gap: 10px;
			div {
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				display: block;
			}
			@media (max-width: 1300px) {
				grid-template-columns: repeat(4, 1fr) !important;
				row-gap: 20px;
			}
			@media (max-width: 768px) {
				row-gap: 15px;
				grid-template-columns: repeat(3, 1fr) !important;
			}
			@media (max-width: 480px) {
				row-gap: 15px;
				grid-template-columns: repeat(2, 1fr) !important;
				div {
					width: 100%;
				}
				.createdBy {
					grid-area: auto / 3 / auto / 1;
				}
			}
			@media (max-width: 340px) {
				grid-template-columns: repeat(1, 1fr) !important;
			}
			.totalProduct {
				div {
					text-align: right;
					width: 100px;
					max-width: 100px;
					flex: 0 0 100px;
				}
			}
			object {
				label {
					display: flex;
					div {
						margin-left: 5px;
						svg {
							width: 16px;
							height: 16px;
							margin-top: 5px;
						}
					}
				}
			}
		}
		.btn-block {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			width: 276px;
			margin: 0;
			padding: 20px 15px 20px 25px;
			@media (max-width: 1300px) {
				padding-left: 0;
				justify-content: flex-start;
				grid-column: 3 / 1;
				grid-row: auto / auto;
			}
			span {
				height: auto;
				flex: 0 0 auto;
				max-width: auto;
				min-width: auto;
			}
			.Favorites {
				width: 50px;
				flex: 0 0 50px;
			}
			button {
				height: 50px;
				margin: 0 10px 0 0;
				@media (max-width: 860px) {
					margin-left: 0;
					margin-right: 10px;
				}
				span {
					height: auto;
					min-height: auto;
				}
				svg {
					width: 20px;
					height: 20px;
					path {
						fill: ${({ theme }) => theme.baseColors.primary};
					}
				}
				&:hover,
				&:focus {
					svg {
						width: 20px;
						height: 20px;
						path {
							fill: ${({ theme }) => theme.baseColors.white};
						}
					}
				}
				+ button {
					display: flex;
					align-items: center;
					text-transform: capitalize;
					white-space: nowrap;
					padding: 0 1.5em !important;
					width: auto;

					@media (max-width: 480px) {
						font-size: 13px;
						padding: 0 10px !important;

						white-space: nowrap;
					}
					span {
						max-width: 100% !important;
						min-height: auto;
					}
					+ div button {
						width: auto;
						padding: 0 1.5em !important;
						@media (max-width: 640px) {
							padding: 0 10px !important;
							font-size: 13px !important;
						}
					}
				}
			}

			icon {
				display: inline-block;
			}
			div {
				word-break: break-word;
			}
		}
		.totalProducts {
			max-width: 110px;
			text-align: right;
			white-space: nowrap;
		}
	}
`;
const PageSearchHolder = styled.div`
	width: 100%;
	padding-top: 15px;
	padding-bottom: 10px;
	display: flex;
	justify-content: space-between;
	div {
		width: 650px;
		@media screen and (max-width: 1300px) {
			width: auto;
			flex: 1;
			margin-right: 20px;
		}
		@media screen and (max-width: 860px) {
			margin-right: 0;
		}

		+ div {
			width: 254px;
			margin-right: 0;
			@media screen and (max-width: 860px) {
				width: 100%;
				margin-top: 10px;
			}
		}
	}
	section {
		display: flex;
		div {
			width: 254px;
			+ div {
				margin-left: 20px;
			}
			@media screen and (max-width: 860px) {
				width: 100%;
				margin-top: 10px;
				div {
					margin-top: 0;
				}
			}
		}
	}
	@media screen and (max-width: 860px) {
		flex-direction: column;
	}
	@media screen and (max-width: 768px) {
		width: 100%;
	}
`;

function AllOrdersTab(): ReactElement {
	let history = useHistory();
	const [cartId, setCartId] = useState('');
	const [showNewFavoritesModal, setShowNewFavoritesModal] = useState(false);
	const toggleNewFavoritesModal = (e, id, qty) => {
		e.stopPropagation();
		setObj({ product_id: id, qty: qty });
		setShowNewFavoritesModal(!showNewFavoritesModal);
	};
	const [postObj, setObj] = useState({} as any);
	const [state, dispatch] = UseGlobalState();
	const [searchText, setSearchText] = useState('');
	const [filterOption, setFilterOption] = useState('');
	const [orderList, setOrderList] = useState([] as any);
	const [venueUserId] = useState<any>(localStorage.getItem('userId'));
	const [venueId, setVenueId] = useState<any>(localStorage.getItem('selectedVenue'));
	const ctx = useContext(AppContext).productSearchObj;
	const [url, setSearchUrl] = useState('');
	const [inProgress, setInProgress] = useState(false);
	const [showPopup, setShowModal] = useState(false);
	const [orderId, setOrderId] = useState('');
	const [modalText, setmodalText] = useState('');

	function updateList() { }
	let urlObj: Partial<urlObj> = {
		url: 'experion/order/search?',
		pageSize: 2000,
		filterDeleted: true,
		ctx: ctx,
		fields: [
			{ field_name: 'venue_id', field_value: localStorage.getItem('selectedVenue')! },
			{
				field_name: 'venue_user_id',
				field_value: localStorage.getItem('userId')!,
				field_conditionType: 'neq',
			},
			{ field_name: 'for_approval', field_value: '0,2', field_conditionType: 'in' },
		],
	};

	const getKey = () => {
		return `all_orders_${ctx.SortField}${ctx.SortDirection}`;
	};

	const obj: Partial<Iget> = {
		url: urls.GetSearchUrl(urlObj),
		enabled: true,
		refetchOnWindowFocus: false,
		resultFilteringRequired: true,
		key: getKey(),
	};

	const allOrdersListQry = hooks.useQuery(obj);

	//api to get total amount
	const url1 = `/carts/${cartId}/totals`;
	const obj6: Partial<Iget> = {
		url: url1,
		enabled: false, //manual fetch
		key: 'carttotalKey1',
		resultFilteringRequired: false,
	};
	var qryTotal = hooks.useQuery(obj6);

	const setFilteredData = (val) => {
		setSearchText(val);
	};

	const setFilterOnStatus = (val) => {
		setFilterOption(val);
	};

	useEffect(() => {
		filterOrderData();
	}, [searchText, filterOption]);

	const filterOrderData = () => {
		if (allOrdersListQry.data) {
			if (
				(searchText && searchText.length > 0) ||
				(filterOption && filterOption.length > 0 && filterOption !== 'All')
			) {
				var list = allOrdersListQry.data;
				if (searchText && searchText.length > 0) {
					list = list.filter(
						(v) => {
							let statusList = getStatusList(v.status);
							let statusName = [] as any;
							statusList && statusList.map((m) => {
								if (m.status == OrderStatuses.Rejected) {
									m.status = 'Rejected';
								} else {
									m.status = m.status?.replace('_', ' ');
								}
								statusName.push(m.status);
							});
							let status = statusName?.join(',');
							return (
								String('#' + v.order_group_increment_id)
									.toLowerCase()
									.includes(searchText.toLowerCase()) ||
								String('$' + parseFloat(v.cart_total).toFixed(2))
									.toLowerCase()
									.includes(searchText.toLowerCase()) ||
								String(v.no_of_items).toLowerCase().includes(searchText.toLowerCase()) ||
								status?.toLowerCase().includes(searchText.toLowerCase()) ||
								v.name?.toLowerCase().includes(searchText.toLowerCase()) ||
								String(v.created_by).toLowerCase().includes(searchText.toLowerCase()) ||
								helperFunctions.formatDate(v.created_at).toLowerCase().includes(searchText.toLowerCase()))
						}
					);
				}
				if (filterOption && filterOption.length > 0 && filterOption !== 'All') {
					list = list.filter((v) => {
						let statusList = getStatusList(v.status);
						let orderStatus = utilityFunctions.getOrderStatus(statusList.map((x) => x.status));
						return orderStatus === filterOption;
					});
				}
				setOrderList(list);
			} else {
				setOrderList(allOrdersListQry.data);
			}
		}
	};

	useEffect(() => {
		setOrderList(allOrdersListQry.data);
		filterOrderData();
	}, [allOrdersListQry.data]);

	function getStatusList(status) {
		let data = [] as any;
		if (status.length > 0) {
			if (status.length > 1) {
				let items = JSON.parse(JSON.stringify(status));
				items.map((v, i) => {
					data.push(JSON.parse(v));
				});
			} else {
				data.push(JSON.parse(status));
			}
		}
		return data;
	}
	const getSortData = (val) => {
		let obj = { field: '', direction: '' };
		switch (val) {
			case 'Low - High Price':
				obj.field = 'cart_total';
				obj.direction = 'asc';
				break;
			case 'High - Low Price':
				obj.field = 'cart_total';
				obj.direction = 'desc';
				break;
			default:
				break;
		}
		return obj;
	};

	const getOrderAndMoveTocart = async (orderId) => {
		setInProgress(true);
		let id: any;
		let CUSTOMER_TOKEN = localStorage.getItem('customerToken');
		id = await utilityFunctions.getCartId(CUSTOMER_TOKEN);
		if (id !== undefined) {
			setCartId(id);
			http
				.GET({
					url: urls.GetSearchUrl({
						url: 'orders?',
						fields: [
							{
								field_name: 'group_order_id',
								field_value: orderId,
							},
						],
					}),
				})
				.then(function (d) {
					if (d.data?.items?.length > 0) {
						const datas = d.data?.items;
						moveToCart(orderId, id, datas);
					} else {
						setInProgress(false);
					}
				})
				.catch((e) => {
					setInProgress(false);
				});
		} else {
			setCartId('');
			setInProgress(false);
		}
	};

	const moveToCart = (orderId, cart_id, productsData) => {
		// products
		var obj = { product_id: '', qty: null };
		const item = [] as any;
		productsData.forEach((data) => {
			data.items.forEach((data) => {
				obj = { product_id: data.product_id, qty: data.qty_ordered };
				item.push(obj);
			});
		});
		let addToCartJson = {
			products: {
				orderGroupId: parseInt(orderId),
				quote_id: parseInt(cart_id),
				items: item,
				venue_user_id: parseInt(venueUserId),
				venue_id: parseInt(venueId),
			},
		};
		api
			.post({
				data: addToCartJson,
				url: constants.multiCartURL(),
				key: 'allOrderCartAdd',
				message: 'Successfully moved to cart',
			})
			.then(function (data) {
				fetchTotal();
			})
			.catch((e) => {
				setInProgress(false);
			});
	};

	const fetchTotal = () => {
		qryTotal
			.refetch()
			.then((data) => {
				if (data) {
					updateCartTotal(data);
				}
				setInProgress(false);
			})
			.catch((e) => {
				setInProgress(false);
			});
	};

	function updateCartTotal(data) {
		if (data) {
			let datas = data.items?.filter((x) => x.is_deleted != true);
			if (datas?.length > 0) {
				dispatch({ cartTotal: helperFunctions.formatAmount(data.grand_total) });
				dispatch({ cartItemCount: datas?.length });
				// setTotalCount(data.subtotal);
			} else {
				dispatch({ cartItemCount: '0' });
				dispatch({ cartTotal: '' });
				// setTotalCount(0);
			}
		} else {
			dispatch({ cartItemCount: '0' });
			dispatch({ cartTotal: '' });
			// setTotalCount(0);
		}
	}

	const handleSort = (val) => {
		let sortData = getSortData(val);
		ctx.SortField = sortData.field;
		ctx.SortDirection = sortData.direction;
		setSearchUrl(urls.GetSearchUrl(urlObj));
	};

	function confirm(e, orderId) {
		e.stopPropagation();
		showConfirmationModal('Move to cart ?', orderId);
	}
	function showConfirmationModal(text, id) {
		setOrderId(id);
		setmodalText(text);
		setShowModal(!showPopup);
	}
	async function closePopup() {
		await setShowModal(!showPopup);
	}
	function onSubmit() {
		getOrderAndMoveTocart(orderId);
		setShowModal(!showPopup);
	}
	return (
		<StyledOrderListView>
			{inProgress ? <Loader></Loader> : null}
			<PageSearchHolder>
				<Search onChange={(val) => setFilteredData(val)}></Search>
				<Box as="section">
					<RecentOrderFilter onSort={setFilterOnStatus}></RecentOrderFilter>
					<SortBy onSort={handleSort} removeNameSort={true}></SortBy>
				</Box>
			</PageSearchHolder>
			{!allOrdersListQry.isFetching && orderList?.length > 0 ? (
				orderList.map(
					(
						{
							order_group_id,
							order_group_increment_id,
							no_of_items,
							cart_total,
							status,
							created_at,
							name,
						},
						i
					) => {
						let statusList = getStatusList(status);
						let orderStatus = utilityFunctions.getOrderStatus(statusList.map((x) => x.status));
						// let diff = moment.duration(moment(new Date()).diff(moment(created_at)));
						// let diffHours = diff.hours() +(diff.days() * 24);
						let eligibleForCancel = true;
						eligibleForCancel =
							[
								OrderStatuses.Cancelled,
								OrderStatuses.Closed,
								OrderStatuses.Rejected,
								OrderStatuses.Disputed,
								OrderStatuses.Confirmed,
								OrderStatuses.Delivered,
							].includes(orderStatus) || statusList?.length == 0
								? false
								: true;
						return (
							<Flex
								onClick={() => history.push(`/store/allOrderDetails?id=${order_group_id}`)}
								as="article"
								bg="white"
								sx={{ alignItems: 'center' }}
								key={i}
							// className="disabled"
							>
								<Flex as="section" flexWrap="wrap" width="100%" maxWidth={['100%']}>
									<Box>
										<Text fontSize={16} fontWeight="normal" color="#242B24">
											Order ID
										</Text>
										<Text fontSize={18} fontWeight="bold" color="#242B24">
											{'#' + order_group_increment_id}
										</Text>
									</Box>
									<Box className="createdBy">
										<Text fontSize={16} fontWeight="normal" color="#242B24">
											Created By
										</Text>
										<Text fontSize={18} fontWeight="bold" color="#242B24">
											{name}
										</Text>
									</Box>
									<Box className="totalProducts">
										<Text fontSize={16} fontWeight="normal" color="#242B24">
											Total Products
										</Text>
										<Text fontSize={18} fontWeight="bold" color="#242B24">
											{no_of_items}
										</Text>
									</Box>
									<Box>
										<Text fontSize={16} fontWeight="normal" color="#242B24">
											Created On
										</Text>
										<Text fontSize={18} fontWeight="bold" color="#242B24">
											{helperFunctions.formatDate(created_at)}
										</Text>
									</Box>
									<Box
										as="object"
										onClick={(e) => {
											e.stopPropagation();
										}}
									>
										<Text as="label" fontSize={16} fontWeight="normal" color="#242B24">
											Status
											<Tooltip
												arrow
												animation="perspective"
												position="right"
												html={
													<div className="tooltip-body tooltip-content statusTooltip">
														{statusList &&
															statusList.map((v) => {
																if (v.enable_cancel_button == false) eligibleForCancel = false;
																return (
																	<span key={helperFunctions.getRandomInt(pageConstants.maxValue)}>
																		<Text
																			as="label"
																			fontSize="17px"
																			fontFamily="OpenSansRegular"
																			color="text.black03"
																		>
																			{`${v.supplier_name} :`}
																			<strong>
																				{helperFunctions.toPascalCase(
																					v.status == 'order_rejected'
																						? 'Rejected'
																						: v.status?.replace('_', ' ')
																				)}
																			</strong>
																		</Text>
																	</span>
																);
															})}
													</div>
												}
											>
												{status?.length > 0 && <icons.InfoIconRound />}
											</Tooltip>
										</Text>
										<Text fontSize={18} fontWeight="bold" color="#242B24">
											{helperFunctions.toPascalCase(
												orderStatus == 'order_rejected'
													? 'Rejected'
													: orderStatus?.replace('_', ' ')
											)}
										</Text>
									</Box>
									<Box>
										<Text fontSize={16} fontWeight="normal" color="#242B24">
											Amount
										</Text>
										<Text fontSize={18} fontWeight="bold" color="#242B24">
											{helperFunctions.formatAmount(cart_total)}
										</Text>
									</Box>
									<Box
										as="slot"
										onClick={(e) => {
											e.stopPropagation();
										}}
									>
										<div className="btn-block">
											{utilityFunctions.isGranted(venueUserPermissionList.FavoriteOrderAdd) && (
												<Button
													variant="secondary"
													className="Favorites"
													onClick={(e) => {
														toggleNewFavoritesModal(e, order_group_id, no_of_items);
													}}
												>
													<Icon.FavoritesIcon title="FavoritesIcon" />
												</Button>
											)}
											{utilityFunctions.isGranted(venueUserPermissionList.ManageProductsinCart) && (
												<Button onClick={(e) => confirm(e, order_group_id)}>
													<Text as="h1" fontFamily="OpenSansBold" fontSize={18} color="text.white">
														Move To Cart
													</Text>
												</Button>
											)}
										</div>
									</Box>
								</Flex>
							</Flex>
						);
					}
				)
			) : !allOrdersListQry.isFetching ? (
				<UserBody>
					{/* <Icon.searchIcon title="No Data Found" /> */}
					<Text fontSize={18} fontWeight="bold" color="#5C5E57" textAlign="center">
						No Orders Found
					</Text>
				</UserBody>
			) : (
				new Array(2).fill(null).map((_, i) => {
					return <Skeleton key={i} width="1200px" height="50px" />;
				})
			)}
			<Modal
				width="500px"
				showModal={showNewFavoritesModal}
				setShowModal={setShowNewFavoritesModal}
			>
				<NewFavoritesModal
					updateList={updateList}
					showModal={showNewFavoritesModal}
					setShowModal={setShowNewFavoritesModal}
					orderId={postObj.product_id}
				/>
			</Modal>
			<SweetAlert
				show={showPopup}
				confirmBtnText="Confirm"
				onConfirm={onSubmit}
				showCancel={true}
				onCancel={closePopup}
				title={'Confirmation Box'}
			>
				{() => (
					<form>
						<hr />
						{modalText}
						<hr />
					</form>
				)}
			</SweetAlert>
		</StyledOrderListView>
	);
}

export { AllOrdersTab };
