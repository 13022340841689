import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import { Box, Flex, Text } from 'rebass/styled-components';
import no_image from 'ui/images/no_image.png';
import { StyledProductSubstitute, ViewAllHeadingBlock } from './styles';

function ProductSubstitute(props): ReactElement {
	const history = useHistory();

	if (props?.substitution?.length > 0) {
		props.substitution.forEach((data) => {
			if (data.extension_attributes?.is_in_stock === false) {
				data['className'] = 'row-highlighted';
			} else {
				data['className'] = '';
			}
		});
	}
	const settings = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 1224,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					initialSlide: 1,
					infinite: false,
					dots: false,
				},
			},
			{
				breakpoint: 860,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 1,
					infinite: false,
					dots: false,
				},
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 1,
					infinite: false,
					dots: true,
				},
			},
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					initialSlide: 1,
					infinite: false,
					dots: true,
				},
			},
		],
	};

	const navigateToProdDetails = (linkedSku) => {
		history.push({
			pathname: '/home/product-details',
			search: '?id=' + linkedSku,
		});
		window.location.reload();
	};
	return (
		<StyledProductSubstitute>
			{props && props.substitution && props.substitution.length > 0 && (
				<ViewAllHeadingBlock>
					<Text as="h1" fontSize="18px">
						Substitute Products
					</Text>
					{/* <Link variant="nav" href="">
						View All
					</Link> */}
				</ViewAllHeadingBlock>
			)}

			<Slider {...settings}>
				{props &&
					props.substitution &&
					props.substitution.map((item) => (
						<Flex
							className={item.className}
							as="article"
							bg="white"
							sx={{ alignItems: 'center' }}
							key={Math.random()}
							onClick={() => {
								navigateToProdDetails(item.linked_product_sku);
							}}
						>
							<Flex alignItems="center" as="span" maxWidth={['100%']} sx={{ overflow: 'hidden' }}>
								<Box
									style={{
										backgroundImage:
											'url(' +
											(item.extension_attributes?.image
												? item.extension_attributes?.image
												: no_image) +
											')',
									}}
								></Box>
							</Flex>
							<Flex alignItems="center" as="section" maxWidth={['100%']}>
								<Box flex={1}>
									<Text
										as="h1"
										fontFamily="OpenSansBold"
										fontSize="20px"
										lineHeight="26px"
										color="text.black"
										className="truncate"
									>
										{item.extension_attributes.name}
									</Text>
									{/* <Text
											as="h2"
											fontSize="17px"
											lineHeight="22px"
											color="text.black"
											className="truncate"
										>
											{item.brand}
										</Text> */}
								</Box>
								<Box>
									<Text as="h4" fontSize="14px" color="text.black05" className="truncate">
										<strong>${parseFloat(item.extension_attributes?.unit_cost).toFixed(2)}</strong>{' '}
										{item.extension_attributes?.uom?.length > 0
											? item.extension_attributes?.uom
											: 'per Pack'}
									</Text>
								</Box>
							</Flex>
						</Flex>
					))}
			</Slider>
		</StyledProductSubstitute>
	);
}

export { ProductSubstitute };
