import styled from 'styled-components';

const MainContent = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
	padding: 0 50px;
	@media (max-width: 960px) {
		padding: 0 15px;
	}
	section {
		&.section {
			row-gap: 20px;
			column-gap: 20px;
			display: grid;
			grid-template-columns: repeat(2, 1fr);

			@media (max-width: 860px) {
				grid-template-columns: repeat(1, 1fr);
				.reportBlock {
					grid-column: 1 / 1 !important;
				}
			}
		}

		article {
			width: 100%;
			background: ${({ theme }) => theme.colors.bg.white};
			border: 1px solid ${({ theme }) => theme.colors.border.grayMedium03};
			box-sizing: border-box;
			box-shadow: 0 1px 15px 1px rgb(62 57 107 / 7%);
			border-radius: 16px;
			padding: 0;
			h2 {
				font-family: 'OpenSansSemiBold';
				color: ${({ theme }) => theme.colors.text.SubHeading};
				font-size: 1.12rem;
				padding: 25px 25px 10px;
				cursor: pointer;
				text-decoration: underline;
				display: inline-flex;
				&:hover {
					color: ${({ theme }) => theme.baseColors.primary};
				}
				+ div {
					margin-top: 0;
					padding: 0 25px 10px;
				}
			}
			.tableList {
				padding: 0 !important;
				table {
					width: 100%;
					thead {
						tr {
							padding: 10px 16px 10px;
						}
					}
					tbody {
						tr {
							border: none;
							border-top: 1px solid ${({ theme }) => theme.colors.border.grayMedium03};
							box-shadow: none;
							border-radius: 0px;
							margin-bottom: 0;
							padding: 10px 16px;
						}
					}
					tbody {
						margin-bottom: 0;
					}

					th,
					td {
						width: auto;
						max-width: 100%;
						flex: 1;
						margin-bottom: 0;
						&:nth-child(2) {
							text-align: center;
							width: 130px;
							max-width: 130px;
							flex: 0 0 130px;
							@media screen and (max-width: 480px) {
								margin-top: 10px;
							}
						}
						@media screen and (max-width: 480px) {
							width: 100%!important;
							max-width: 100%!important;
							flex: 0 0 100%!important;
						}
					}
				}
			}
		}
	}
`;
const Dashboard = styled.div`
	background: #f9f9f9;
	padding: 20px;
	border-radius: 15px;
	margin-bottom: 30px;
	.reportBlock {
		display: flex;
		flex-wrap: wrap;
		column-gap: 20px;
		select {
			background: ${({ theme }) => theme.baseColors.white};
		}
		h1 {
			text-align: left;
			font-family: 'OpenSansSemiBold';
			color: ${({ theme }) => theme.colors.text.SubHeading};
			font-size: 20px;
			margin: 28px 0 10px 0;
			width: 100%;
		}
		section {
			display: flex;
			column-gap: 20px;
			@media screen and (max-width: 640px) {
				flex-direction: column;
				width: 100%;
				div {
					margin-top: 10px;
					div {
						margin-top: 0;
					}
				}
			}
		}
	}
`;
const ContentHolder = styled.div`
	height: 100%;
	padding: 0 50px;
	padding-bottom: 30px;
	width: 100%;

	@media (max-width: 768px) {
		padding: 0 15px;
	}
`;

const CardsAndNotificationHolder = styled.div`
	width: 100%;
	display: flex;
	@media (max-width: 860px) {
		flex-direction: column;
	}
	& > div {
		grid-template-columns: repeat(3, 1fr);
		@media (max-width: 1780px) {
			grid-template-columns: repeat(2, 1fr);
		}
		@media (max-width: 1280px) {
			grid-template-columns: repeat(1, 1fr);
			row-gap: 0;
		}
	}
`;
const TabContentHolder = styled.div`
	width: 100%;
	display: flex;
	margin-top: 0;
`;
const BreadcrumbHolder = styled.div`
	button {
		display: none;
	}
`;
export {
	MainContent,
	ContentHolder,
	CardsAndNotificationHolder,
	TabContentHolder,
	BreadcrumbHolder,
	Dashboard,
};
