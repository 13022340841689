import styled, { css } from 'styled-components';

type FormLabelProps = {
	required?: boolean;
	htmlFor?: string;
	disabled?: boolean;
};

const FormLabel = styled.label<FormLabelProps>`
	display: inline-block;
	margin-bottom: ${({ theme }) => theme.space[1]};
	color: ${({ theme }) => theme.colors.text.textLabel};
	font-family: 'OpenSansSemiBold';
	line-height: 18px;
	font-size: 14px;

	${({ required }) =>
		required &&
		css`
			&::after {
				content: '*';
				display: inline-block;
				margin-left: ${({ theme }) => theme.space[1]};
				color: red;
				font-family: 'OpenSansBold';
				font-size: 16px !important;
			}
		`}
`;

export { FormLabel };
