import { OrderStatuses } from 'models/enum-constants/common.constants';
import React, { ReactElement } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';
import { Box, Flex, Image, Text } from 'rebass/styled-components';
import styled, { css } from 'styled-components';
import helperFunctions from 'utils/helper.functions';
import pageConstants from 'utils/pageConstants';
import utilityFunctions from 'utils/utility.functions';
import nodataImage from '../../images/Nodata.svg';

const StyledRecentOrders = styled.div`
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	column-gap: 20px;
	row-gap: 20px;
	@media (max-width: 1366px) {
		grid-template-columns: repeat(4, 1fr) !important;
		column-gap: 20px;
	}
	@media (max-width: 1152px) {
		grid-template-columns: repeat(3, 1fr) !important;
		column-gap: 20px;
	}
	@media (max-width: 860px) {
		grid-template-columns: repeat(2, 1fr) !important;
		column-gap: 15px;
	}
	@media (max-width: 640px) {
		grid-template-columns: repeat(1, 1fr) !important;
		column-gap: 15px;
	}
`;

const RecentOrderBlock = styled.div<RecentOrderBlockProps>`
	flex-direction: column;
	background: ${({ theme }) => theme.colors.bg.white};
	border: 1px solid ${({ theme }) => theme.newColors.gray};
	box-sizing: border-box;
	border-radius: 16px;
	overflow: hidden;
	transition: box-shadow 100ms ease-in, transform 100ms ease-in;
	transition: all 0.25s ease-in-out;
	cursor: pointer;
	&:hover {
		transform: translateY(-5px);
		box-shadow: 0 20px 20px #eee;
	}
	span {
		width: 100%;
		height: 200px;
		background-repeat: no-repeat;
		background-size: cover;
	}
	section {
		width: 100%;
		display: flex;
		align-items: center;
		padding: 15px;
		h1,
		h2,
		h3,
		h4 {
			font-weight: normal;
		}
		h1 {
			margin-bottom: 5px;
			strong {
				font-family: 'OpenSansBold';
				margin-left: 5px;
			}
		}
		h2 {
			font-family: 'OpenSansRegular';
			span {
				margin-right: 5px;
			}
		}
		h3 {
			margin-top: 30px;
		}
		h5 {
			margin-top: 27px;
			strong {
				font-family: 'OpenSansBold';
				margin-left: 5px;
			}
		}
	}
	${({ active }) =>
		!active &&
		css`
			& ${Processing} {
				background: #e4b23a;
				border: 1px solid #e4b23a;
				&:before {
					content: 'Processing';
				}
			}
			& ${Delivered} {
				background: #7fa495;
				border: 1px solid #7fa495;
				&:before {
					content: 'Delivered';
				}
			}
			& ${Cancelled} {
				background: ${({ theme }) => theme.baseColors.red};
				border: 1px solid ${({ theme }) => theme.baseColors.red};
				&:before {
					content: 'Cancelled';
				}
			}
			& ${Rejected} {
				background: ${({ theme }) => theme.baseColors.red};
				border: 1px solid ${({ theme }) => theme.baseColors.red};
				&:before {
					content: 'Rejected';
				}
			}
			& ${Confirmed} {
				background: #7fa495;
				border: 1px solid #7fa495;
				&:before {
					content: 'Confirmed';
				}
			}
			& ${Pending} {
				background: #e4b23a;
				border: 1px solid #e4b23a;
				&:before {
					content: 'Pending';
				}
			}
			& ${Disputed} {
				background: #e4b23a;
				border: 1px solid #e4b23a;
				&:before {
					content: 'Disputed';
				}
			}
			& ${Shipped} {
				background: ${({ theme }) => theme.baseColors.purple};
				border: 1px solid ${({ theme }) => theme.baseColors.purple};
				&:before {
					content: 'Shipped';
				}
			}
		`}
`;
const Processing = styled.div`
	width: 87px;
	height: 30px;
	background: #7fa495;
	border: 1px solid #7fa495;
	box-sizing: border-box;
	border-radius: 15px;
	display: flex;
	align-items: center;
	justify-content: center;

	&:before {
		content: 'Delivered';
		font-size: 13px;
		line-height: 17px;
		font-family: 'OpenSansSemiBold';
		color: #ffffff;
	}
`;
const Delivered = styled.div`
	width: 87px;
	height: 30px;
	background: #7fa495;
	border: 1px solid #7fa495;
	box-sizing: border-box;
	border-radius: 15px;
	display: flex;
	align-items: center;
	justify-content: center;

	&:before {
		content: 'Delivered';
		font-size: 13px;
		line-height: 17px;
		font-family: 'OpenSansSemiBold';
		color: #ffffff;
	}
`;
const Cancelled = styled.div`
	width: 87px;
	height: 30px;
	background: #7fa495;
	border: 1px solid #7fa495;
	box-sizing: border-box;
	border-radius: 15px;
	display: flex;
	align-items: center;
	justify-content: center;

	&:before {
		content: 'Delivered';
		font-size: 13px;
		line-height: 17px;
		font-family: 'OpenSansSemiBold';
		color: #ffffff;
	}
`;
const Rejected = styled.div`
	width: 87px;
	height: 30px;
	background: #7fa495;
	border: 1px solid #7fa495;
	box-sizing: border-box;
	border-radius: 15px;
	display: flex;
	align-items: center;
	justify-content: center;

	&:before {
		content: 'Delivered';
		font-size: 13px;
		line-height: 17px;
		font-family: 'OpenSansSemiBold';
		color: #ffffff;
	}
`;
const Confirmed = styled.div`
	width: 87px;
	height: 30px;
	background: #7fa495;
	border: 1px solid #7fa495;
	box-sizing: border-box;
	border-radius: 15px;
	display: flex;
	align-items: center;
	justify-content: center;

	&:before {
		content: 'Delivered';
		font-size: 13px;
		line-height: 17px;
		font-family: 'OpenSansSemiBold';
		color: #ffffff;
	}
`;
const Pending = styled.div`
	width: 87px;
	height: 30px;
	background: #7fa495;
	border: 1px solid #7fa495;
	box-sizing: border-box;
	border-radius: 15px;
	display: flex;
	align-items: center;
	justify-content: center;

	&:before {
		content: 'Delivered';
		font-size: 13px;
		line-height: 17px;
		font-family: 'OpenSansSemiBold';
		color: #ffffff;
	}
`;
const Disputed = styled.div`
	width: 87px;
	height: 30px;
	background: #7fa495;
	border: 1px solid #7fa495;
	box-sizing: border-box;
	border-radius: 15px;
	display: flex;
	align-items: center;
	justify-content: center;

	&:before {
		content: 'Delivered';
		font-size: 13px;
		line-height: 17px;
		font-family: 'OpenSansSemiBold';
		color: #ffffff;
	}
`;
const Shipped = styled.div`
	width: 87px;
	height: 30px;
	background: #7fa495;
	border: 1px solid #7fa495;
	box-sizing: border-box;
	border-radius: 15px;
	display: flex;
	align-items: center;
	justify-content: center;

	&:before {
		content: 'Delivered';
		font-size: 13px;
		line-height: 17px;
		font-family: 'OpenSansSemiBold';
		color: #ffffff;
	}
`;
const FooterBlock = styled.div`
	width: 100%;
	height: 60px;
	padding: 0 15px;
	background: ${({ theme }) => theme.newColors.lightgray};
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const NodataFound = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 250px;
	grid-column: 6 / 1;
	img {
		margin: 0;
		height:120px;
	}
	h1 {
		text-align: center;
		margin-top: 15px;
	}
`;
type RecentOrderBlockProps = {
	active?: boolean;
	status?: string;
};

function RecentOrders({ orderList }): ReactElement {
	function getStatusList(status) {
		let data = [] as any;
		if (status && status.length == 1) {
			data.push(JSON.parse(status));
		} else if (status && status.length > 1) {
			let items = JSON.parse(JSON.stringify(status));
			items.map((v) => {
				data.push(JSON.parse(v));
			});
		}
		return data;
	}
	let history = useHistory();
	return (
		<StyledRecentOrders>
			{orderList && orderList.length > 0 ? (
				orderList.map(
					(
						{
							order_group_id,
							order_group_increment_id,
							no_of_items,
							cart_total,
							status,
							created_at,
						}) => {
						let statusList = getStatusList(status);
						let orderStatus = utilityFunctions.getOrderStatus(statusList.map((x) => x.status));
						return (
							<RecentOrderBlock key={helperFunctions.getRandomInt(pageConstants.maxValue)}>
								<Flex
									onClick={() => history.push(`/store/myOrderDetails?id=${order_group_id}`)}
									alignItems="center"
									as="section"
									maxWidth={['100%']}
								>
									<Box flex={1}>
										<Text
											as="h1"
											fontFamily="OpenSansRegular"
											fontSize="18px"
											lineHeight="26px"
											color="text.black"
											className="truncate"
										>
											Order ID<strong>{'#'+order_group_increment_id}</strong>
										</Text>
										<Text
											as="h2"
											fontSize="17px"
											lineHeight="22px"
											color="text.black"
											className="truncate"
										>
											<span>{helperFunctions.formatDate(created_at)}</span>
										</Text>
										<Text
											as="h5"
											fontSize="18px"
											fontFamily="OpenSansRegular"
											color="text.black"
											className="truncate"
										>
											Qty <strong>{no_of_items}</strong>
										</Text>
									</Box>
								</Flex>
								<FooterBlock>
									<Text
										as="h6"
										fontSize="20px"
										fontFamily="OpenSansBold"
										color="text.black"
										className="truncate"
									>
										<strong>{helperFunctions.formatAmount(cart_total)}</strong>
									</Text>
									{orderStatus == OrderStatuses.Cancelled && <Cancelled></Cancelled>}
									{orderStatus == OrderStatuses.Rejected && <Rejected></Rejected>}
									{orderStatus == OrderStatuses.Delivered && <Delivered></Delivered>}
									{orderStatus == OrderStatuses.Confirmed && <Confirmed></Confirmed>}
									{orderStatus == OrderStatuses.Processing && <Delivered></Delivered>}
									{orderStatus == OrderStatuses.Pending && <Pending></Pending>}
									{orderStatus == OrderStatuses.Disputed && <Disputed></Disputed>}
									{orderStatus == OrderStatuses.Shipped && <Shipped></Shipped>}
								</FooterBlock>
							</RecentOrderBlock>
						);
					}
				)
			) : orderList && orderList.length == 0 ? (
				<NodataFound>
					<Box>
						<Image
							style={{ margin: 'auto' }}
							src={nodataImage}
							sx={{
								width: 130,
								height: 130,
							}}
						/>
						<Text as="h1" fontFamily="OpenSansBold" fontSize={18} color="text.black">
							No Orders Found
						</Text>
					</Box>
				</NodataFound>
			) : (
				new Array(5).fill(null).map((_, i) => {
					return (
						<Skeleton
							key={helperFunctions.getRandomInt(pageConstants.maxValue)}
							width="200px"
							height="200px"
						/>
					);
				})
			)}
		</StyledRecentOrders>
	);
}

export { RecentOrders };
