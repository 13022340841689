import React, { ReactElement } from 'react';
import styled from 'styled-components';
import * as Icon from 'ui/icons';

const ProfileImageWrapper = styled.div`
	--wrap-dimension: 140px;
	position: relative;
	width: var(--wrap-dimension);
	height: var(--wrap-dimension);

	@media (max-width: 52em) {
		--wrap-dimension: 160px;
	}

	& > .profile-image-upload {
		--upload-dimension: 40px;
		position: absolute;
		bottom: 5px;
		right: 5px;
		width: var(--upload-dimension);
		height: var(--upload-dimension);
		border-radius: 50%;
		border: solid 3px #fff;
		background: ${({ theme }) => theme.colors.primary};
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;

		@media (max-width: 52em) {
			--upload-dimension: 60px;
			bottom: -5px;
			right: -5px;
		}

		svg {
			width: 16px;
			fill: #fff;

			@media (max-width: 52em) {
				width: 24px;
			}
		}
	}

	& > .profile-image-upload input[type='file']::-webkit-file-upload-button {
		visibility: hidden;
	}

	& > .profile-image-upload input[type='file'] {
		--upload-dimension: 40px;
		display: inline-block;
		outline: none;
		white-space: nowrap;
		-webkit-user-select: none;
		cursor: pointer;
		width: var(--upload-dimension);
		height: var(--upload-dimension);
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;

		@media (max-width: 52em) {
			--upload-dimension: 60px;
		}
	}
`;

const StyledProfileImage = styled.div`
	--wrap-dimension: 140px;
	width: var(--wrap-dimension);
	height: var(--wrap-dimension);
	border: solid 6px #fff;
	border-radius: 50%;
	box-shadow: ${({ theme }) => theme.shadows.small};
	overflow: hidden;

	@media (max-width: 52em) {
		--wrap-dimension: 160px;
	}

	& > img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`;

type ProfileImageProps = {
	src?: string;
	editImage?: boolean;
};
function ProfileImage({ src, editImage }: ProfileImageProps): ReactElement {
	return (
		<ProfileImageWrapper>
			<StyledProfileImage>
				<img src={src ? src : require('ui/images/avatar.svg')} alt="Profile" />
			</StyledProfileImage>
			<div className="profile-image-upload">
				{editImage ? (
					<Icon.Edit width="16px" title="Change image" />
				) : (
					<Icon.Camera width="16px" title="Upload image" />
				)}
				<input type="file" />
			</div>
		</ProfileImageWrapper>
	);
}

export { ProfileImageWrapper, ProfileImage };
