import React, { ReactElement } from 'react';
import styled from 'styled-components';

type AvatarProps = {
	src: string;
	className?: string;
};

const StyledAvatar = styled.img`
	display: inline-block;
	overflow: hidden;
	line-height: 1;
	vertical-align: middle;
	&.pr-header-avatar {
		flex: 0 0 50px;
		height: 50px;
		border-radius: 50px;
		overflow: hidden;
		@media (max-width: 767px) {
			flex: 0 0 40px;
			height: 40px;
		}
	}
`;

function Avatar({ src, className }: AvatarProps): ReactElement {
	return <StyledAvatar src={src} className={className} />;
}

export { Avatar };
