import { condition } from 'models/enum-constants/common.constants';
import Iget from 'models/Iget';
import Ipost from 'models/Ipost';
import queryString from 'query-string';
import React, { ReactElement, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Text } from 'rebass/styled-components';
import {
	AccordionVenue, BreadcrumbNav,


	Button, Footer, Header,






	Loader, TableList, Toggle
} from 'ui/components';
import icons from 'ui/icons';
import { Modal, Sidebar } from 'ui/patterns';
import urls from 'utils/create.url';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import AssignUsersModal from './assignUsers';
import {
	AccordionBlock,



	BreadcrumbWrapper, MainContent,





	RightHolder, UserBlock,

	UserBody, UserHeader
} from './styles';



function DashboardVenue(): ReactElement {
	const history = useHistory();
	const [Name, setName] = useState('');
	const [venueId, setVenueId] = useState(0);
	const [showPopup, setShowModal] = useState(false);
	const [checked, setChecked] = useState(false);
	const [modalText, setmodalText] = useState('');
	const [action, setAction] = useState('');
	const [venueUserList, setVenueUserList] = useState([] as any);
	const [userId, setUserId] = useState(0);
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);	
	//let venueId = 0;
	const breadcrumbNav = [
		{ id: '01', label: 'Home', route: "/home/dashboard", active: false },
		{ id: '02', label: 'Dashboard', active: false },
		{ id: '03', label: Name, active: true },
	];
	const location = useLocation();
	const parsed = queryString.parse(location.search);
	const params = {
		pages: '1',
	};
	const [values, setValues] = useState({
		id: 0,
		name: '',
		hosp_id: 0,
		venue_email: '',
		description: '',
		address: '',
		zip_code: '',
		phone: 0,
		latitude: 0,
		longitude: 0,
		created_by: '',
		updated_by: '',
		is_active: true,
		is_delete: true,
		contact_name: '',
		city: '',
		state: '',
		country: '',
		number_of_parked_orders: 0,
		contact_person_phone: 0,
	});
	const parseQueryString = function () {
		if (parsed.id) {
			venueEditDetails.refetch().then(function (data) {
				setValues(data);
				setVenueName(data.name, data.id);
			});
		}
	};
	useEffect(() => {
		parseQueryString();
	}, [location, parseQueryString]);

	const editVenueKey = 'editVenue';
	const EditVenueUrl = `experion/venue/get/${parsed.id}`;
	const item: Partial<Iget> = {
		params: params,
		url: EditVenueUrl,
		enabled: false,
		key: editVenueKey,
	};
	const venueEditDetails = hooks.useQuery(item);

	const [showAssignUsersModal, setShowAssignUsersModal] = useState(false);
	// const toggleAssignUsersModal = () => {
	// 	setShowAssignUsersModal(!showAssignUsersModal);
	// };
	const [showAddExistingUsersModal, setShowAddExistingUsersModal] = useState(false);
	const toggleAddExistingUsersModal = () => {
		setShowAddExistingUsersModal(!showAddExistingUsersModal);
	};
	const setVenueName = (venueName: string, id: number) => {
		setName(venueName);
		setVenueId(id);
	};
	const venueUserUrl = urls.GetSearchUrl({
		url: 'experion/venueuser/search?',
		pageSize: 10,
		fields : [{field_name: 'hg_id',
		field_value: localStorage.getItem("hospitalityId")!},
		{field_name: 'magento_user_id',
			field_value: localStorage.getItem("hgAdminId")!,
			field_conditionType : condition.neq}
	],
		filterDeleted: true,
	});
	const venueUserObj: Partial<Iget> = {
		params: {},
		url: venueUserUrl,
		enabled: true,
		key: "userListKey",
		resultFilteringRequired: true,
	};
	const venueUserQry = hooks.useQuery(venueUserObj);
	useEffect(() => {
		let userList = [] as any;
		if (venueUserQry.data) {
			venueUserQry.data.map((d) => {
				if (d.venue_user_role_mapping && d.venue_user_role_mapping.length > 0) {
					d.venue_user_role_mapping.map(item => {
						if (item.venue_id == parsed.id) {
							userList.push(d);
						}
					})
				}
			})

			setVenueUserList([...userList])
		}
		
	}, [venueUserQry.data])
	const navigateToEdit = () => {
		history.push({
			pathname: 'edit-store',
			search: '?id=' + venueId,
		});
	};
	function confirm(action, id, e = null as any) {
		let checked = false;
		if (e != null) {
			checked = e.currentTarget.checked;
			values.is_active = checked;
			setValues(values);
		}
		switch (action) {
			case 'del':
				showConfirmationModal(action, 'Delete store?', checked);
				break;
			case 'bulkdel':
				showConfirmationModal(action, 'Delete store(s)?', checked);
				break;
			case 'upd':
				showConfirmationModal(action, `${checked == true ? 'A' : 'Dea'}ctivate store?`, checked);
				break;
			case 'deleteUser':
				setUserId(id);
				showConfirmationModal(action, 'Delete user?', checked);
				break;
			default:
				break;
		}
	}
	function showConfirmationModal(action, text, checked) {
		if (action == 'upd') setChecked(checked);
		setAction(action);
		setmodalText(text);
		setShowModal(!showPopup);
	}
	function onSubmit() {
		if (action == 'del' || action == 'bulkdel') deleteVenue();
		else if (action == 'upd') changeStatus();
		else if (action == 'deleteUser') deleteUser();
	}
	async function closePopup() {
		if (action == 'upd') {
			values.is_active = !checked;
			setValues(values);
			setChecked(!checked);
		}
		await setShowModal(!showPopup);
	}
	const obj1: Partial<Ipost> = {
		params: {},
		url: '',
	};
	const addExistingUsers = (item) => {
		if (item == 'existing') {
			setShowAddExistingUsersModal(!showAddExistingUsersModal);
			history.push({
				pathname: '/store/addExistingUser',
				search: '?id=' + parsed.id,
			});
		}
		else {
			setShowAddExistingUsersModal(!showAddExistingUsersModal);
			history.push({
				pathname: '/store/addNewStoreUser',
				search: '?id=' + parsed.id,
			});
		}
	}
	function deleteVenue() {
		obj1.url = `experion/venue/delete`;
		obj1.data = { Id: [venueId] };
		obj1.message = 'Store deleted successfully';
		api.post(obj1).then(function (d) {
			history.push('user-dashboard');
		});
		setShowModal(!showPopup);
	}
	function changeStatus() {
		obj1.url = `experion/venue/updatestatus/${venueId}/${checked}`;
		obj1.message = 'Store status updated successfully';
		api.put(obj1);
		setShowModal(!showPopup);
	}
	function deleteUser() {
		obj1.url = `experion/venueuser/delete/${userId}`;
		obj1.data = { Id: [userId] };
		obj1.message = 'User deleted successfully';
		api.put(obj1).then(() => {
			setShowModal(!showPopup);
		})

	}
	const editUser = (id) => {
		history.push({
			pathname: 'user',
			search: '?id=' + id,
		});
	}
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	}
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={()=>onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={()=>onclickHambrgBtn()}></Sidebar>
			{(values && values.name === '') ? <Loader></Loader> : null}
			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbWrapper>
					<BreadcrumbNav Heading="Store Details" breadcrumbList={breadcrumbNav} />
					<RightHolder>
						<Toggle
							disabled={!venueId ? true : false}
							checked={values && values.is_active}
							onChange={(e) => confirm('upd', venueId, e)}
						/>
						<Button
							variant="deleteIcon"
							disabled={!venueId ? true : false}
							onClick={() => confirm('del', venueId)}
						>
							<icons.DeleteIcon />
						</Button>
						<Button onClick={navigateToEdit} variant="editIcon">
							<icons.textEditIcon />
						</Button>
					</RightHolder>
				</BreadcrumbWrapper>
				<AccordionBlock>
					<AccordionVenue setData={values} />
					<UserBlock>
						<UserHeader>
							<Text fontSize={18} fontWeight="bold" color="black" verticalAlign="middle">
								User
							</Text>
							{/* <Button>
								<Text as="h1" fontFamily="OpenSansBold" fontSize={18} color="text.white">
									Add
								</Text>
							</Button> */}
							<Button onClick={toggleAddExistingUsersModal}>
								<Text as="h1" fontFamily="OpenSansBold" fontSize={18} color="text.white">
									Add
								</Text>
							</Button>
						</UserHeader>
						{/* <UserBody>
							<Button>
								<Icon.noUser title="User Icon" />
							</Button>
							<Text fontSize={18} fontWeight="bold" color="#5C5E57" textAlign="center">
								No Users Added Yet
							</Text>
						</UserBody> */}

						<TableList>
							<table>
								<thead>
									<tr>
										<th>Employee Name</th>
										<th>Email</th>
										<th>Phone</th>
										<th>Role</th>
										<th className="action">Action</th>
									</tr>
								</thead>

								<tbody>
									{venueUserList && venueUserList.length > 0 ? (
										(venueUserList).map(
											(
												{ id, first_name, last_name, phone, venue_user_role_mapping, venue_user_email }
												
											) => (
												<tr key={id}>
													<td data-label="Employee Name">
														<Text as="h1" fontSize={15} fontFamily="OpenSansBlack" color="text.black">
															<span>{first_name}{' '}{last_name}</span>
														</Text>
													</td>
													<td data-label="Email">
														<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
															{venue_user_email}
														</Text>
													</td>
													<td data-label="Phone Numbe">
														<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
															{phone}
														</Text>
													</td>
													<td data-label="Role">
														<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
															{venue_user_role_mapping && venue_user_role_mapping.length > 0 ? venue_user_role_mapping[0]?.role_name : ''}

														</Text>
													</td>
													<td className="action" data-label="Action">
														<Text>
															{/* <Button variant="deleteIcon"
																disabled={!id ? true : false}
																onClick={() => confirm('deleteUser', id)}>
																<icons.DeleteIcon />
															</Button> */}
															<Button variant="editIcon"
																onClick={() => editUser(id)}>
																<icons.textEditIcon />
															</Button>
														</Text>
													</td>
												</tr>
											)
										)
									) : (
										<tr>
											<Box width={1}>
												<UserBody>
													<Text fontSize={18} fontWeight="bold" color="#5C5E57" textAlign="center">
														No Users Found
													</Text>
												</UserBody>
											</Box>
										</tr>
									)}
								</tbody>
							</table>
						</TableList>
					</UserBlock>
				</AccordionBlock>
				<SweetAlert
					show={showPopup}
					confirmBtnText="Confirm"
					onConfirm={onSubmit}
					showCancel={true}
					onCancel={closePopup}
					title={'Confirmation Box'}
				//  dependencies={[this.state.firstName, this.state.lastName]}
				>
					{() => <form>{modalText}</form>}
				</SweetAlert>
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
			{/* <Modal width="450px" showModal={showAssignUsersModal} setShowModal={setShowAssignUsersModal}>
				<AssignUsersModal cancel={() => setShowModal(false)} />
			</Modal> */}
			<Modal
				width="450px"
				showModal={showAddExistingUsersModal}
				setShowModal={setShowAddExistingUsersModal}
			>
				<AssignUsersModal addExistingUsers={addExistingUsers} cancel={() => setShowModal(false)} />
			</Modal>
		</>
	);
}

export default DashboardVenue;
