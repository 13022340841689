import { userTypes } from 'models/enum-constants/common.constants';
import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Link } from 'rebass/styled-components';
import logo from 'ui/images/logo_exp.png';
import 'ui/styles/home.css';

function Home(): ReactElement {
	const history = useHistory();
	const { t, i18n } = useTranslation();
	const userType = [{
		key: userTypes.venueUser,
		name: "Venue User"
	},
	{
		key: userTypes.venueAdmin,
		name: "Venue Admin"
	},
	{
		key: userTypes.supplierUser,
		name: "Supplier User"   
	},
	{
		key: userTypes.supplierAdmin, 
		name: "Supplier Admin"
	},
	];
	const countries = [{
		key: "en",
		name: "English"
	},
	{
		key: "jap",
		name: "Japanese"
	},
	{
		key: "hin",
		name: "Hindi"   
	},
	{
		key: "ger",
		name: "German"
	},
	{
		key: "fre",
		name: "French"
	},];

	const handleChange = event => {
		console.log("selected val is ", event.target.value);
		let newlang = event.target.value;
		// this.setState(prevState => ({ value: newlang }));
		console.log("state value is", newlang);
		i18n.changeLanguage(newlang);
	};

	const handleUserChange = event => {
		let userTypeSet = event.target.value;
		console.log("state value is", userTypeSet);
		localStorage.setItem('userType', userTypeSet);
	};

	useEffect(() => {
		history.push('/login');
	}, [])

	return (
		<div className="App">
			{/* Localization */}
			{/* <div>
				<Select width="300px"
					id='userType'
					name='userType'
					onChange={handleUserChange} 
					defaultValue='User Type'>
					{Object.entries(userType).map(([ key, country ]) => (
					<option 
						value={country.key}>
						{country.name}
					</option>
					))}
				</Select>
			</div>
			<div>
			<Label htmlFor='language'>Selected Language: {t("Introduction")}</Label>
			<Select width="300px"
				id='language'
				name='language'
				onChange={handleChange} 
				defaultValue='English'>
				{Object.entries(countries).map(([ key, country ]) => (
				<option 
					key={key}>
					{country.key}
				</option>
				))}
			</Select>
			{t("is an internationalization-framework which offers a complete solution to localize your product from web to mobile and desktop")}
			</div>	 */}
			
			<header className="App-header">
				<img src={logo} className="App-logo" alt="logo" />
				<div>
				<Link variant="nav" onClick={() => history.push('/login')} href="" fontSize="14px">
						Login
				</Link>				
				</div>
			</header>
		</div>
	);
}

export default Home;
