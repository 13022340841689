import styled from 'styled-components';

const MainContent = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
	padding: 0 50px;
	@media (max-width: 960px) {
		padding: 0 15px;
	}
`;
const ContentHolder = styled.div`
	height: 100%;
	padding: 0 50px;
	padding-bottom: 30px;
	width: 100%;

	@media (max-width: 768px) {
		padding: 0 15px;
	}
`;
const CardsAndNotificationHolder = styled.div`
	width: 100%;
	display: flex;
	@media (max-width: 860px) {
		flex-direction: column;
	}
	& > div {
		grid-template-columns: repeat(3, 1fr);
		@media (max-width: 1780px) {
			grid-template-columns: repeat(2, 1fr);
		}
		@media (max-width: 1280px) {
			grid-template-columns: repeat(1, 1fr);
			row-gap: 0;
		}
	}
`;
const TabContentHolder = styled.div`
	width: 100%;
	display: flex;
	margin-bottom: 50px;
	
`;
const BreadcrumbHolder = styled.div``;
const ParkedOrderTab = styled.div`
	margin-bottom: 15px;
	button {
		display: none;
	}
`;
const RecentOrderTab = styled.div`
	margin-bottom: 15px;
`;
const FavouriteOrderTab = styled.div`
	margin-bottom: 15px;
`;
export {
	MainContent,
	ContentHolder,
	CardsAndNotificationHolder,
	TabContentHolder,
	BreadcrumbHolder,
	ParkedOrderTab,
	RecentOrderTab,
	FavouriteOrderTab,
};
