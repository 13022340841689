import { lighten } from 'polished';
import styled, { css } from 'styled-components';

type StyledFormFeedbackProps = {
	validation?: 'error' | 'success' | 'warning' | undefined;
	disabled?: boolean;
};

const StyledFormFeedback = styled.small<StyledFormFeedbackProps>`
	display: block;
	margin-top: ${({ theme }) => theme.space[1]};
	color: ${({ theme }) => theme.colors.text.muted};
	line-height: 1;

	${({ validation }) =>
		validation === 'error' &&
		css`
			color: ${({ theme }) => theme.colors.text.error};
		`}
	${({ validation }) =>
		validation === 'success' &&
		css`
			color: ${({ theme }) => theme.colors.text.success};
		`}
  ${({ validation }) =>
		validation === 'warning' &&
		css`
			color: ${({ theme }) => theme.colors.text.warning};
		`}

  ${({ disabled }) =>
		disabled &&
		css`
			color: ${({ theme }) => lighten(0.5, theme.color.text.muted)};
		`}
`;

export { StyledFormFeedback };
