import styled from 'styled-components';

const UserListHolder = styled.div`
	table {
		width: 100%!important;
		margin-top: 20px;
		tbody {
			min-height: auto !important;
		}
	}
`;
export { UserListHolder };
