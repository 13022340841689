import { Checkbox, Label, Textarea } from '@rebass/forms';
import React, { ReactElement, useEffect, useState } from 'react';
import { Box, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { Button, Stack } from 'ui/components';

const AddToFavorites = styled.div`
	display: flex;
	height: auto;
	position: relative;
	flex-direction: column;
	background: ${({ theme }) => theme.colors.bg.white};
	padding: 0 10px 12px;
	@media (max-width: 960px) {
		padding: 0;
	}
	h1 {
		margin: 0 0 0 0;
		width: 100%;
		font-weight: normal;
		text-transform: uppercase;
	}
	h2 {
		margin: 0 0 30px 0;
	}
	section {
		display: flex;
		flex-wrap: wrap;
		label {
			font-size: 16px;
			line-height: 21px;
			font-family: 'OpenSansSemiBold';
			color: ${({ theme }) => theme.colors.text.SubHeading};
			margin-bottom: 20px;
			width: auto;
			margin-right: 50px;
			display: flex;
			white-space: nowrap;
			align-items: center;
			input {
				outline: none;
				box-shadow: none;
				&:focus {
					~ svg {
						& + svg {
							background: none !important;
						}
					}
					svg {
						background: none !important;
					}
				}
			}
			svg {
				color: ${({ theme }) => theme.baseColors.primary};
				background-color: transparent !important;
				& + svg {
					color: ${({ theme }) => theme.colors.border.grayMedium02};
				}
			}
			& + label {
				margin-right: 0;
			}
		}
	}

	textarea {
		height: 132px;
		border: 1px solid ${({ theme }) => theme.colors.border.grayMedium02};
		border-radius: 8px;
		margin-bottom: 32px;
		outline: none;
		color: ${({ theme }) => theme.colors.text.black};
	}
	button {
		padding: 0;
		min-width: 100px;
		min-height: 50px;
		text-transform: capitalize;
		font-size: 18px;
		margin: 0 0 0 20px;
		font-family: 'OpenSansBold';
	}
`;

// type Option = {
// 	cancel: () => void;
// };
export default function ReasonForRejectionModal(prop): ReactElement {
	const [reasonForRejection, setReasonForRejection] = useState('');

	useEffect(() => {
		if (prop.value && prop.value.reason)
			setReasonForRejection(prop.value.reason)
	}, [prop.value && prop.value.reason]);
	const handleOnChange = (e: any) => {
		setReasonForRejection(e.target.value);
	};
	const onChangeReason = (comment, e) => {
		prop.setReasonForRejection && prop.setReasonForRejection(comment, e)
	}
	return (
		<AddToFavorites>
			<Text as="h1" color="text.black" fontSize={18} fontFamily="OpenSansSemiBold">
				Reason for rejection
			</Text>
			<Text as="h2" color="text.SubHeading" fontSize={16}>
				Select reason for rejection of the order
			</Text>
			<Box as="section">
				<Label>
					<Checkbox checked={prop.firstCheckBox} disabled={prop.disableReason} id="productCheck" name="remember" onChange={(e) => onChangeReason('product', e)} />
					Product unavailable
				</Label>
				<Label>
					<Checkbox checked={prop.secondCheckBox} disabled={prop.disableReason} id="deliveryCheck" name="remember" onChange={(e) => onChangeReason('delivery', e)} />
					Delivery unavailable
				</Label>
			</Box>
			<Box>
				<Textarea value={reasonForRejection} disabled={prop.disableReason} onChange={handleOnChange} id="comment" name="comment" placeholder="Add additional comments" />
			</Box>

			<Stack direction="x" gap={3} justifyContent="flex-end">
				<Button onClick={() => prop.cancel && prop.cancel()} flex={[1, 1, 'initial']} variant="secondary">
					Cancel
				</Button>
				{!prop.disableReason ?
					<Button onClick={() => { prop.onSubmitReason && prop.onSubmitReason(reasonForRejection, 'order_rejected') }} flex={[1, 1, 'initial']}>Submit</Button> : null}
			</Stack>
		</AddToFavorites>
	);
}
