import { Column } from 'react-table';
import styled from 'styled-components';
const StyledParkedOrders = styled.div`
	width: 100%;
	padding: 14px 0 0 0;
`;
const TotalAmountBlock = styled.div`
	background: ${({ theme }) => theme.newColors.lightgray};
	min-height: 50px;
	width: calc(100% - 32px) !important;
	border-radius: 12px;
	margin: 0 16px 26px !important;
	display: flex !important;
	align-items: center;
	justify-content: space-between;
	padding: 17px;
	h6,
	p {
		color: ${({ theme }) => theme.colors.text.black02};
	}
	h6 {
		font-weight: normal;
		margin-bottom: 5px;
	}
	button {
		text-transform: capitalize !important;
		padding: 15px !important;
		font-size: 17px !important;
		font-family: 'OpenSansBold' !important;
		border-radius: 8px !important;
		span {
			display: flex;
			svg {
				margin-left: 17px;
				width: auto;
				height: auto;
			}
		}
	}
`;
const ViewAllHeadingBlock = styled.div`
	display: flex !important;
	justify-content: space-between;
	padding: 0 16px;
	color: ${({ theme }) => theme.colors.text.black01};
	h1,
	a {
		font-weight: normal;
		font-family: 'OpenSansMedium';
	}
	a {
		color: ${({ theme }) => theme.colors.text.black02};
	}
	@media (max-width: 480px) {
		flex-direction: Column;
	}
`;
const ParkedOrdersBlock = styled.div`
	padding: 25px 16px 0;
	div {
		@media (max-width: 960px) {
			max-height: 250px !important;
		}
		@media (max-width: 480px) {
			max-height: 200px !important;
		}
	}
	article {
		margin: 0 0 22px;
		padding: 0 0 22px;
		position: relative;
		&::after {
			content: '';
			width: 100%;
			height: 1px;
			float: left;
			background: ${({ theme }) => theme.colors.border.grayMedium};
			position: absolute;
			bottom: 0;
		}
		&:last-child {
			margin: 0;
			padding: 0;
			&:after {
				display: none;
			}
		}
	}
	h3 {
		font-weight: normal;
		font-family: 'OpenSansMedium';
		line-height: 21px;
		strong {
			font-family: 'OpenSansBlack';
		}
	}
	h4 {
		margin-top: 13px;
		font-family: 'OpenSansBlack';
	}
`;
const DeleteParkOrder = styled.div`
	padding: 0;
	margin-top: 20px;
	border-radius: 0 0 12px 12px;
	overflow: hidden;
	button {
		background: transparent !important;
		width: 100%;
		justify-content: center;
		border: none;
		border-top: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
		border-radius: 0;
		padding: 20px 0;
		height: 65px;
		span {
			color: ${({ theme }) => theme.colors.text.black03};
			display: flex;
			align-items: center;
			text-transform: capitalize;
		}
		svg {
			margin-top: -1px;
			width:15px;
			height:auto;
		}
		.pr-icon {
			margin-right: 10px;
		}
		&:hover {
			background: ${({ theme }) => theme.colors.bg.error}!important;
			span {
				color: ${({ theme }) => theme.colors.text.white}!important;
				svg {
					path {
						fill: ${({ theme }) => theme.colors.text.white}!important;
					}
				}
			}
		}
	}
`;
export {
	StyledParkedOrders,
	TotalAmountBlock,
	ViewAllHeadingBlock,
	ParkedOrdersBlock,
	DeleteParkOrder,
};
