import styled from 'styled-components';
const StyledSortInfo = styled.div`
	border-bottom: 1px solid ${({ theme }) => theme.colors.border.grayLightBorder01};
	padding: 0 30px 16px;
	@media (max-width: 960px) {
		padding: 0 15px 16px;
	}
	h1 {
		font-size: 18px;
		line-height: 23px;
		font-family: 'OpenSansMedium';
		font-weight: normal;
		color: ${({ theme }) => theme.colors.bg.lightYellow04};
		margin: 23px 0 19px;
		strong {
			color: ${({ theme }) => theme.colors.text.black};
		}
	}
`;
const TagsHolder = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
`;
export { StyledSortInfo, TagsHolder };
