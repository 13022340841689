import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';
import icons from 'ui/icons';
import { Dropdown } from 'ui/patterns';
const StyledStatusFilter = styled.div`
	position: relative;
	.dropdownMenu {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		.ScrollbarsCustom-Content {
			display: flex;
			flex-direction: column;
		}
		button {
			position: absolute;
			top: 0;
			left: 0;
			width: 100% !important;
			height: 100%;
			border: none;
			background: transparent;
			outline: none;
			cursor: pointer;
			& + div {
				margin-top: 50px;
				border-radius: 12px;
				left: auto;
				right: -30px !important;
				min-width: 150px;
				z-index: 1;
				padding: 0;
				overflow: visible;
				box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.39);
				height: 150px;
				@media (max-width: 960px) {
					right: -10px;
				}
				@media (max-width: 767px) {
					right: 2px !important;
				}
				button {
					position: relative;
					min-height: auto;
					padding: 10px 10px !important;
					margin: 0 !important;
					border-radius: 0 !important;
					&::before {
						left: 0;
						right: 0;
					}
					&:hover {
						background-color: #f6f4ee;
						text-decoration: none;
					}
				}
				&::before {
					content: '';
					width: 47px;
					height: 16px;
					position: absolute;
					top: -16px;
					left: auto;
					right: 14px;
					background: url(${require('../../images/dropdownTopArrow.png')});
				}
			}
		}
	}
`;
function StatusFilter(): ReactElement {
	const categoriesOptions = [
		{
			id: 1,
			name: 'Processing',
		},
		{
			id: 2,
			name: 'Delayed ',
		},
		{
			id: 3,
			name: 'Confirmed',
		},
		{
			id: 4,
			name: 'Out for delivery',
		},
	];
	const [showOptions, setShowOptions] = useState(false);
	const optionsTrigger = () => (
		<button className="btn-cardoptions" onClick={() => setShowOptions(!showOptions)}></button>
	);
	return (
		<StyledStatusFilter>
			<icons.FilterIcon />
			<Dropdown
				trigger={optionsTrigger()}
				options={categoriesOptions}
				showOptions={showOptions}
				setShowOptions={setShowOptions}
				top="0"
				place="covered-right"
			/>
		</StyledStatusFilter>
	);
}

export { StatusFilter };
