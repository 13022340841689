import styled from 'styled-components';

const MainContent = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
	padding: 0 50px;
	@media (max-width: 960px) {
		padding: 0 15px;
	}
	table {
		@media (max-width: 1300px) {
			width: 100%;
		}
		thead,
		tbody {
			tr {
				td,
				th {
					width: auto;
					border: none !important;
					padding-top: 0 !important;
					div {
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
					}
					h1 {
						width: auto;
					}
					&:nth-child(1) {
						flex: 0 0 300px;
						max-width: 300px;
						@media (max-width: 960px) {
							flex: 0 0 250px;
							max-width: 250px;
						}
						@media screen and (max-width: 767px) {
							flex: 0 0 100% !important;
							max-width: 100% !important;
							margin-bottom: 15px;
							position: relative !important;
							right: auto !important;
							margin-bottom: 15px !important;
							top: auto;
						}
					}
					&:nth-child(2) {
						flex: 0 0 50%;
						max-width: 50%;
						@media (max-width: 960px) {
							flex: 0 0 40%;
							max-width: 40%;
						}
						@media (max-width: 770px) {
							flex: 0 0 35%;
							max-width: 35%;
						}
						h1 {
							white-space: normal;
							max-width: 100%;
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
						}
						@media screen and (max-width: 767px) {
							flex: 0 0 100% !important;
							max-width: 100% !important;
							margin-bottom: 15px;
						}
					}
				}
			}
		}
	}
`;
const ContentHolder = styled.div`
	height: 100%;
	padding: 0 50px;
	padding-bottom: 30px;
	width: 100%;

	@media (max-width: 768px) {
		padding: 0 15px;
	}
`;
const BreadcrumbHolder = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	@media (max-width: 360px) {
		align-items: end;
		& > div {
			padding-bottom: 15px;
		}
	}
`;
const ButtonWrapper = styled.div`
	display: flex;
	@media (max-width: 480px) {
		padding-bottom: 0 !important;
	}
	button {
		display: flex !important;
		font-size: 18px;
		text-transform: capitalize;
		height: 50px;
		padding-top: 0;
		padding-bottom: 0;
		min-width: 190px;
		&:hover {
			svg {
				path {
					fill: ${({ theme }) => theme.colors.text.white};
				}
			}
		}
		span {
			display: flex;
			align-items: center;
		}
		+ button {
			margin-left: 15px;
			border: 1px solid ${({ theme }) => theme.baseColors.primary};
			div + span {
				margin-right: 0 !important;
				margin-left: 15px !important;
			}
		}
		.pr-icon {
			margin-right: 15px;
			margin-left: 0;
		}
	}
`;

const PageSearchHolderBlock = styled.div`
	div {
		width: 100% !important;
	}
`;

export { MainContent, ContentHolder, BreadcrumbHolder, ButtonWrapper, PageSearchHolderBlock };
