import { userTypes } from 'models/enum-constants/common.constants';
import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { Text } from 'rebass/styled-components';
import { Button, Stack } from 'ui/components';
import {
	AddToFavorites,
	AddToFavoritesContainer,
} from '../../ui/patterns/productGridView/addToFavorites/styles';

export default function RedirectUser(props): ReactElement {
	var userType = localStorage.getItem('userType');
	let history = useHistory();

	const RedirectDashboard = () => {
		if (userType == userTypes.venueUser || userType == userTypes.venueAdmin) {
			history.push('/admin/add-new-store');
		} else {
			history.push('/admin/addDCList');
		}
	};

	return (
		<AddToFavorites>
			<Text as="h1" color="text.textHeading" fontSize={22} fontFamily="OpenSansBold">
				{userType == userTypes.venueUser || userType == userTypes.venueAdmin
					? 'Welcome to Experion B2B MarketPlace!'
					: ''}
			</Text>
			<AddToFavoritesContainer>
				<Text as="h1" color="text.textHeading" fontSize={22} fontFamily="OpenSansBold">
					{userType == userTypes.venueUser || userType == userTypes.venueAdmin
						? 'No Venues exist in your Marketplace customer!'
						: 'No Distribution Centers exist in your Seller Group!'}
				</Text>
				<Text as="p" color="text.black06" fontFamily="OpenSansMedium" fontSize={[16]}>
					{userType == userTypes.venueUser || userType == userTypes.venueAdmin
						? 'Click the below button to create a new venue.'
						: 'Click the below button to create a new distribution centers.'}
				</Text>
			</AddToFavoritesContainer>
			<Stack direction="x" gap={4} justifyContent="center" my={5}>
				<Button onClick={() => RedirectDashboard()} flex={[1, 1, 'initial']}>
					Create!
				</Button>
			</Stack>
		</AddToFavorites>
	);
}
