import styled from 'styled-components';

const UserListHolder = styled.div`
	table {
		width: 100%;
		@media (max-width: 1300px) {
			width: 100% !important;
			margin-bottom: 30px;
		}
		thead,
		tbody {
			tr {
				display: flex;
				align-items: center;
				td,
				th {
					&:nth-child(2),
					&:nth-child(3) {
						width: 150px;
						max-width: 150px;
						flex: 0 0 150px;
						@media (max-width: 1440px) {
							width: 200px;
							max-width: 200px;
							flex: 0 0 200px;
						}
					}
					&:nth-child(6) {
						width: 175px !important;
						max-width: 175px !important;
						flex: 0 0 175px !important;
						text-align: right !important;
						@media (max-width: 768px) {
							width: 33.3333% !important;
							flex: 0 0 33.3333% !important;
							max-width: 33.3333% !important;
							padding-right: 84px;
						}
						@media (max-width: 480px) {
							padding-right: 37px;
							width: 50% !important;
							max-width: 50% !important;
							flex: 0 0 50% !important;
						}
						@media (max-width: 340px) {
							width: 100% !important;
							max-width: 100% !important;
							flex: 0 0 100% !important;
							padding-right: 102px;
						}
					}
					&:nth-child(7) {
						width: 180px;
						max-width: 180px;
						flex: 0 0 180px;
					}
					h1 {
						strong {
							cursor: pointer;
							&:hover {
								color: ${({ theme }) => theme.baseColors.primary};
							}
						}
					}
				}
			}
		}
		tbody{
			margin-bottom: 0!important;
		}
	}
	@media (max-width: 768px) {
		table {
			width: 100%;
		}
	}
`;
const PageSearchHolder = styled.div`
	width: 100%;
	padding-top: 20px;
	padding-bottom: 25px;
	display: flex;
	justify-content: space-between;
	div {
		width: 650px;
		@media screen and (max-width: 860px) {
			width: 100%;
		}
		& + div {
			width: auto;
			@media screen and (max-width: 860px) {
				margin-top: 20px;
				button {
					margin-left: 0;
					margin-right: 20px;
					width: 50px;
					height: 50px;
					flex: 0 0 50px;
					max-width: 50px;
				}
			}
		}
	}
	@media screen and (max-width: 860px) {
		flex-direction: column;
	}
	button {
		& + button {
			margin-left: 15px;
		}
		span {
			display: flex;
			align-items: center;
			svg {
				margin-right: 12px;
				@media screen and (max-width: 1152px) {
					margin-right: 0;
				}
			}
		}
		@media screen and (max-width: 960px) {
			width: 60px;
			flex: 0 0 60px;
			max-width: 60px;
			margin-left: 20px;
		}

		div {
			width: auto;
			text-transform: capitalize !important;
			@media screen and (max-width: 1152px) {
				display: none;
			}
		}
		@media screen and (max-width: 1152px) {
			svg {
				margin-right: 0;
			}
		}
	}
	@media screen and (max-width: 767px) {
		width: 100%;
	}
`;
export { UserListHolder, PageSearchHolder };
