import { Label, Select } from '@rebass/forms';
import Iget from 'models/Iget';
import IgetEx from 'models/IgetEx';
import Ipost from 'models/Ipost';
import queryString from 'query-string';
import React, { ReactElement, useEffect, useState } from 'react';
import Autocomplete from 'react-autocomplete';
import SweetAlert from 'react-bootstrap-sweetalert';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Flex, Text } from 'rebass/styled-components';
import { BreadcrumbNav, Checkbox, Button, Footer, Header, Loader, Stack, Toggle, Grid, FormLabel } from 'ui/components';
import * as Icon from 'ui/icons';
import { FormGroup, FormInput, Sidebar } from 'ui/patterns';
import helperFunctions from 'utils/helper.functions';
import httpService from 'utils/http.service';
import pageConstants from 'utils/pageConstants';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import constants from '../../utils/constants';
import { AddUserHolder, AutocompleteHolder, FooterBtnBlock, MainContent, ProfileDetails } from './styles';
import moment from 'moment';
import utilityFunctions from 'utils/utility.functions';
import { dcUserPermissionList } from 'models/enum-constants/common.constants';
import notify from 'utils/toast.service';



function AddDCList(): ReactElement {
	const params = {
		pages: '1',
	};
	const [statusChecked, setStatusChecked] = useState(true);
	let userId = localStorage.getItem('userId');
	const supplierId = localStorage.getItem('supplierId');
	const storeId = localStorage.getItem('storeId');
	const editDcKey = 'editDc';
	const location = useLocation();
	const parsed = queryString.parse(location.search);
	const [modalText, setmodalText] = useState('');
	const [showPopup, setShowModal] = useState(false);
	const [action, setAction] = useState('');
	const EditDcUrl = `experion/supplycompanyaccount/getdc/${parsed.id}`;
	const [startDate, setStartDate] = useState(new Date());
	const [initialDateFrom, setInitialDateFrom] = useState(new Date());
	const [initialDateTo, setInitialDateTo] = useState(new Date());
	const [vacationCheckbox, setVacationDetails] = useState(false);
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);

	const item: Partial<Iget> = {
		params: params,
		url: EditDcUrl,
		enabled: false,
		key: editDcKey,
	};
	const dcEditDetails = hooks.useQuery(item);
	var [dcUniqueName, checkDcUniqueName] = useState<any>();
	let DCNameUrl = `experion/supplycompanyaccount/DCNameExists/${dcUniqueName}/${supplierId}`;
	const history = useHistory();
	const [editParam, setEditParam] = useState(0);
	const [disableBtnSave, setDisableBtnSave] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const parseQueryString = function () {
		if (parsed.id) {
			setIsLoading(true);
			let id = parseInt(parsed.id);
			setDisableBtnSave(false);
			setEditParam(id);
			dcEditDetails.refetch().then(function (data) {
				if (data) {
					if (data.vacation.from) {
						setInitialDateFrom(new Date(data.vacation?.from));
						setInitialDateTo(new Date(data.vacation?.to));
						data.vacation.from = moment(data.vacation.from).format('DD/MM/YYYY');
						data.vacation.to = moment(data.vacation.to).format('DD/MM/YYYY');
						setVacationDetails(true);

					}
					setValues(data);
					setStatusChecked(data.is_active);
					setAutoAddress(data.address);
					setIsLoading(false);
				}
			}).finally(() => {
				setIsLoading(false);
			})
		}
	};
	useEffect(() => {
		parseQueryString();
	}, [location]);
	const breadcrumbNav = [
		{ id: '01', label: 'Dashboard', route: 'seller-dashboard', active: false },
		{ id: '02', label: 'DC Management', route: 'DCList', active: false },
		{ id: '03', label: editParam ? 'Edit DC' : 'Add New DC', active: true },
	];

	const key = 'dcAdd';
	const [mutatePost] = hooks.useMutation(key);
	const [values, setValues] = useState({
		dc_id: editParam ? editParam : 0,
		name: '',
		supply_id: parseInt(supplierId ? supplierId : '0'),
		email: '',
		description: '',
		address: '',
		zip_code: '',
		phone: "",
		latitude: 0,
		longitude: 0,
		is_active: true,
		store_id: parseInt(storeId ? storeId : '0'),
		is_delete: false,
		contact_name: '',
		city: '',
		state: '',
		country: 'select',
		contact_person_phone: "",
		delivery_location: '',
		vacation: {
			from: new Date().toString(),
			to: new Date().toString(),
			comment: "",
			status: "",
			is_delete: "",
			dc_id: editParam ? editParam : 0
		},
		dc_user_id: parseInt(userId ? userId : '')
	});

	const [error, setError] = useState({
		dcNameError: '',
		emailAddressError: '',
		phoneNumberError: '',
		deliveryLocationError: '',
		descriptionError: '',
		contactNameError: '',
		contactPhoneError: '',
		addressFieldError: '',
		zipCodeError: '',
		cityError: '',
		stateError: '',
		countryError: '',
		dateFromError: '',
		dateToError: ''
	});


	const validateDcName = async (name) => {
		if (parsed.id) {
			DCNameUrl = `experion/supplycompanyaccount/DCNameExists/${name}/${supplierId}?dcId=${parsed.id}`;
		} else DCNameUrl = `experion/supplycompanyaccount/DCNameExists/${name}/${supplierId}`;
		let isDuplicate = false;
		await httpService.GET({ url: DCNameUrl }).then((data) => {
			if (data.data) {
				isDuplicate = true;
				setError({ ...error, dcNameError: 'DC Name already exists' });
			} else {
				setError({ ...error, dcNameError: '' });
			}
		});
		return isDuplicate;
	};
	const onChangeValue = (val, name) => {
		const item = values;
		switch (name) {
			case 'dcName': {
				if (!val) {
					error.dcNameError = 'DC Name cannot be blank';
				} else {
					error.dcNameError = '';
				}
				setError(error);
				item.name = val;
				checkDcUniqueName(val);
				break;
			}
			case 'email': {
				if (!val) {
					setError({ ...error, emailAddressError: 'Email Address cannot be blank' });
				} else {
					const validEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(val);
					if (validEmail) {
						error.emailAddressError = '';
					} else {
						error.emailAddressError = 'Please enter Valid Email Address';
					}
				}
				setError(error);
				item.email = val;
				break;
			}
			case 'phone': {
				if (!val) {
					error.phoneNumberError = 'Phone cannot be blank';
				} else {
					const regex = pageConstants.phoneRegex;
					if (!regex.test(val)) {
						error.phoneNumberError = 'Invalid Phone';
					} else {
						error.phoneNumberError = '';
					}
				}
				setError(error);
				item.phone = val;
				break;
			}
			case 'deliveryLoc':
				if (!val) {
					error.deliveryLocationError = 'Delivery Location cannot be blank';
				} else {
					error.deliveryLocationError = '';
				}
				setError(error);
				item.delivery_location = val;
				break;
			case 'description':
				if (!val) {
					error.descriptionError = 'Description cannot be blank';
				} else {
					error.descriptionError = '';
				}
				setError(error);
				item.description = val;
				break;
			case 'contactName':
				if (!val) {
					error.contactNameError = 'Contact Name cannot be blank';
				} else {
					error.contactNameError = '';
				}
				setError(error);
				item.contact_name = val;
				break;
			case 'contactPhone':
				if (!val) {
					error.contactPhoneError = `Contact Person's Phone cannot be blank`;
				} else {
					const regex = pageConstants.phoneRegex;
					if (!regex.test(val)) {
						error.contactPhoneError = 'Invalid Phone';
					} else {
						error.contactPhoneError = '';
					}
				}
				setError(error);
				item.contact_person_phone = val;
				break;
			case 'address':
				if (!val) {
					error.addressFieldError = 'Address cannot be blank';
				} else {
					error.addressFieldError = '';
				}
				setError(error);
				item.address = val;
				break;
			case 'zipCode':
				if (!val) {
					error.zipCodeError = 'Zip Code cannot be blank';
				} else {
					error.zipCodeError = '';
				}
				setError(error);
				item.zip_code = val;
				break;
			case 'city':
				if (!val) {
					error.cityError = 'City cannot be blank';
				} else {
					error.cityError = '';
				}
				setError(error);
				item.city = val;
				break;
			case 'state':
				if (!val) {
					error.stateError = 'State cannot be blank';
				} else {
					error.stateError = '';
				}
				setError(error);
				item.state = val;
				break;
			case 'country':
				if (val.target.value == '-Select-') {
					error.countryError = 'Country cannot be blank';
				} else {
					error.countryError = '';
				}
				setError(error);
				item.country = val.target.value;
				break;
			case 'vacationDetails':
				const checkedProp = val.currentTarget.checked;
				setVacationDetails(checkedProp);
				break;
			case 'comment':
				item.vacation.comment = val;
				break;
			case 'date_from':
				setInitialDateFrom(val);
				if (val != '') {
					let date = val !== '' ? moment(val).format('DD/MM/YYYY') : '';
					var isafter = moment(new Date).isAfter(val);
					if (isafter) {
						let now = moment().format('DD/MM/YYYY');
						if (date == now) {
							error.dateFromError = ''
						}
						else
							error.dateFromError = `Date From should not be less than Today's date`;
					}
					else {
						error.dateFromError = '';
					}
					var validateDateTo = new Date(val) > new Date(initialDateTo)
					if (validateDateTo) {
						error.dateToError = `Date To should be greater than Date From`;
					}
					else {
						error.dateToError = '';
					}
					item.vacation.from = date;
				}
				else {
					item.vacation.from = val;
					error.dateFromError = 'Date From cannot be blank';
				}
				setError(error);
				break;
			case 'date_to':
				setInitialDateTo(val);
				if (val != '') {
					//let dateFrom = values.vacation.from !== '' ? moment(values.vacation.from).format('DD/MM/YYYY') : '';
					let dateTo = val !== '' ? moment(val).format('DD/MM/YYYY') : '';
					let date1 = new Date(val);
					let date2 = new Date(initialDateFrom);
					var validateDate = date2 > date1;
					if (validateDate) {
						error.dateToError = `Date To should be greater than Date From`;
					}
					else {
						error.dateToError = '';
					}
					item.vacation.to = dateTo;
				}
				else {
					item.vacation.to = val;
					error.dateToError = 'Date To cannot be blank';
				}
				setError(error);
				break;
			default:
				break;
		}
		setValues({
			...item,
		});
		if (
			values &&
			values.name &&
			values.email &&
			values.phone &&
			values.delivery_location &&
			values.description &&
			values.contact_name &&
			values.contact_person_phone &&
			values.address &&
			values.zip_code &&
			values.city &&
			values.state &&
			values.country != '-Select-' &&
			error &&
			!error.dcNameError &&
			!error.emailAddressError &&
			!error.phoneNumberError &&
			!error.deliveryLocationError &&
			!error.descriptionError &&
			!error.contactNameError &&
			!error.contactPhoneError &&
			!error.addressFieldError &&
			!error.cityError &&
			!error.stateError &&
			!error.countryError
		) {
			setDisableBtnSave(false);
		} else {
			setDisableBtnSave(true);
		}
	};

	const userInfo = 'addDc';
	const url = 'directory/countries';
	const obj: Partial<Iget> = {
		params: params,
		url: url,
		enabled: true,
		key: userInfo,
	};
	const country = hooks.useQuery(obj);
	const countries = country.data;
	const submitData = async (action) => {
		switch (action) {
			case 'save':
				setIsLoading(true);
				let isDuplicate = await validateDcName(values.name);
				setIsLoading(false);
				if (isDuplicate) {
					window.scroll({ top: 0, left: 0, behavior: 'smooth' });
					return false;
				}
				else {
					if (editParam)
						showConfirmationModal(action, 'Save the changes?');
					else
						saveData();
				}
				break;
			case 'clear':
				if (
					values.name != '' ||
					values.email != '' ||
					values.phone !== "" ||
					values.delivery_location != '' ||
					values.description != '' ||
					values.contact_name != '' ||
					values.contact_person_phone !== "" ||
					values.address != '' ||
					values.zip_code != '' ||
					values.city != '' ||
					values.state != '' ||
					values.country != ('-Select-' && 'select') ||
					values.vacation.comment != ''
				) {
					showConfirmationModal(action, 'Discard the changes?');
				}
				break;
			default:
				break;
		}
	};
	async function closePopup() {
		await setShowModal(!showPopup);
	}
	function showConfirmationModal(action, text) {
		if (!editParam && action != 'clear') {
			saveData();
		} else {
			setAction(action);
			setmodalText(text);
			setShowModal(!showPopup);
		}
	}
	async function onSubmit() {
		if (action === 'save') {
			saveData();
		} else if (action === 'clear') {
			clearDcDetails('clear');
		}
		else if (action == 'vacation') {
			if (vacationCheckbox == true) {
				values.vacation = [] as any;
				setInitialDateTo(new Date());
				setInitialDateFrom(new Date());
				error.dateFromError = ''
				error.dateToError = ''
				setError(error);
			}
			setVacationDetails(!vacationCheckbox);
		}
		setShowModal(!showPopup);
	}
	async function saveData() {
		if (values && !editParam) {
			var payload = {
				name: values.name.trim(),
				supply_id: values.supply_id,
				email: values.email,
				description: values.description,
				address: values.address,
				zip_code: values.zip_code,
				phone: helperFunctions.formatPhone(values.phone),
				latitude: values.latitude,
				longitude: values.longitude,
				is_active: values.is_active,
				store_id: values.store_id,
				is_delete: values.is_delete,
				contact_name: values.contact_name,
				city: values.city,
				state: values.state,
				country: values.country,
				contact_person_phone: helperFunctions.formatPhone(values.contact_person_phone),
				delivery_location: values.delivery_location,
			};
			const obj: Partial<Ipost> = {
				data: { dC: payload },
				params: {},
				url: 'experion/supplycompanyaccount/dc/create',
				message: 'DC created successfully',
			};
			try {
				await mutatePost(obj).then((data) => {
					if (data !== undefined) {
						history.push('DcList');
					}
				});
			} catch (e) { }

		} else if (values && editParam) {

			if (vacationCheckbox && (new Date(initialDateTo).getTime() === new Date(initialDateFrom).getTime())) {
				notify("Date From and Date To cannot be same").error();
			}
			else {
				values.dc_user_id = parseInt(userId ? userId : '');
				var updatePayload = values;
				if (vacationCheckbox) {
					if (!values.vacation.from) {
						updatePayload.vacation.from = moment(new Date()).format('YYYY-MM-DD');
					}
					else if (values.vacation.from) {
						updatePayload.vacation.from = moment(initialDateFrom).format('YYYY-MM-DD')
					}
					if (!values.vacation.to) {
						updatePayload.vacation.to = moment(new Date()).format('YYYY-MM-DD');
					}
					else if (values.vacation.to) {
						updatePayload.vacation.to = moment(initialDateTo).format('YYYY-MM-DD')
					}

				}
				updatePayload.phone = helperFunctions.formatPhone(updatePayload.phone);
				updatePayload.contact_name = helperFunctions.formatPhone(updatePayload.contact_name);
				updatePayload.name = updatePayload.name.trim();
				const obj: Partial<Ipost> = {
					data: { dC: updatePayload },
					params: {},
					url: `experion/supplycompanyaccount/updatedc/${editParam}`,
					message: 'DC updated successfully',
				};
				try {
					api.put(obj).then((data) => {
						if (data !== undefined) {
							history.push('DcList');
						}
						setShowModal(!showPopup);
					});
				} catch (e) {
					setShowModal(!showPopup);
				}
			}

		}
	}

	//Auto complete for Address Begins

	const [autoAddress, setAutoAddress] = useState('');
	const [autoAddress404, setAutoAddress404] = useState(false);
	var [autoAddressList, setAutoAddressList] = useState<any>([]);
	const addInfo = 'autoAddress';
	const addDetails = 'autoDetails';
	const acHeaders = {
		'x-api-key': constants.placesAPIKey(),
		'x-client-id': constants.placesClientId(),
	};
	const autoparams = {
		count: '10',
		country: pageConstants.defaultCountry,
	};
	const addobj: Partial<IgetEx> = {
		headers: acHeaders,
		url: constants.placesAPIURL() + `key=`,
		key: addInfo,
		params: autoparams,
		enabled: false,
	};
	const addQry = hooks.useQuery(addobj);

	const addRobj: Partial<IgetEx> = {
		headers: acHeaders,
		url: constants.placesAPIURL() + `id=`,
		key: addDetails,
		params: autoparams,
		enabled: false,
	};

	useEffect(() => {
		addQry.data && setAutoAddressList([...addQry.data]);
	}, [addQry.data]);

	useEffect(() => {
		setAutoAddress404(false);
		if (values.address != autoAddress) {
			setValues({
				dc_id: values.dc_id,
				name: values.name,
				supply_id: values.supply_id,
				email: values.email,
				description: values.description,
				address: values.address,
				zip_code: '',
				phone: values.phone,
				latitude: 0,
				longitude: 0,
				is_active: values.is_active,
				store_id: values.store_id,
				is_delete: values.is_delete,
				contact_name: values.contact_name,
				city: '',
				state: '',
				country: '',
				contact_person_phone: values.contact_person_phone,
				delivery_location: values.delivery_location,
				vacation: {
					from: values.vacation.from,
					to: values.vacation.to,
					comment: values.vacation.comment,
					status: values.vacation?.status,
					is_delete: values.vacation.is_delete,
					dc_id: values.dc_id
				},
				dc_user_id: values.dc_user_id
			});
		}
		autoAddressList = [];
		setAutoAddressList(autoAddressList);
		getAddressSuggestion(autoAddress);
	}, [autoAddress]);

	const addressOnSelect = function (item?) {
		let addressId = 0;
		if (autoAddressList.length > 0 && autoAddress.length > 4) {
			autoAddressList &&
				autoAddressList
					.filter((x) => x.FullAddress == item)
					.map((option) => (addressId = option.Id));
			values.address = item
			setValues(values);
			if (addressId != 0) {
				addRobj.url = constants.placesAPIURL() + `id=${addressId}`;
				api.getEx(addRobj).then(function (res) {
					setValues({
						dc_id: values.dc_id,
						name: values.name,
						supply_id: values.supply_id,
						email: values.email,
						description: values.description,
						address: values.address,
						zip_code: res.Postcode,
						phone: values.phone,
						latitude: res.Latitude,
						longitude: res.Longitude,
						is_active: values.is_active,
						store_id: values.store_id,
						is_delete: values.is_delete,
						contact_name: values.contact_name,
						city: res.City,
						state: res.Suburb,
						country: res.Country,
						contact_person_phone: values.contact_person_phone,
						delivery_location: values.delivery_location,
						vacation: {
							from: values.vacation.from,
							to: values.vacation.to,
							comment: values.vacation.comment,
							status: values.vacation?.status,
							is_delete: values.vacation.is_delete,
							dc_id: values.dc_id
						},
						dc_user_id: values.dc_user_id
					});
				});
			}
		}
	};

	// const addressSuggestion = function (item?) {
	// 	// return [{ label: 'apple' },{ label: 'banana' },{ label: 'pear' }];
	// };

	const getAddressSuggestion = async function (value?) {
		if (autoAddress != '' && autoAddress.length > 4) {
			addobj.url = constants.placesAPIURL() + `key=${autoAddress}`;
			await api.getEx(addobj).then(function (res) {
				if (res == undefined) {
					setAutoAddress404(true);
				} else {
					setAutoAddress404(false);
				}
				res && setAutoAddressList([...res]);
			});
		} else {
			setAutoAddressList([]);
		}
	};

	//Auto complete for Address Ends

	const toggleBtnChange = (e) => {
		const checkedProp = e.currentTarget.checked;
		setStatusChecked(checkedProp);
		values.is_active = checkedProp;
		setValues(values);
	};
	const clearDcDetails = (param) => {
		if (param === 'clear') {
			setAutoAddress("");
			setValues({
				dc_id: 0,
				name: '',
				supply_id: 6,
				email: '',
				description: '',
				address: '',
				zip_code: '',
				phone: "",
				latitude: 0,
				longitude: 0,
				is_active: true,
				store_id: 1,
				is_delete: false,
				contact_name: '',
				city: '',
				state: '',
				country: 'select',
				contact_person_phone: "",
				delivery_location: '',
				vacation: {
					from: "",
					to: "",
					comment: "",
					status: "",
					is_delete: "",
					dc_id: editParam ? editParam : 0
				},
				dc_user_id: parseInt(userId ? userId : ''),
			});
			setError({
				dcNameError: '',
				emailAddressError: '',
				phoneNumberError: '',
				deliveryLocationError: '',
				descriptionError: '',
				contactNameError: '',
				contactPhoneError: '',
				addressFieldError: '',
				zipCodeError: '',
				cityError: '',
				stateError: '',
				countryError: '',
				dateFromError: '',
				dateToError: ''
			});
			setStatusChecked(true);
		} else {
			history.push('DcList');
		}
	};
	const onChangeVacationDetails = (e, action) => {
		//setVacationDetails(e.target.value);
		showConfirmationModal(action, 'Are you sure want to change the vacation status of the DC ?');
	}
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	}
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={() => onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar}
				toggleSideBar={() => onclickHambrgBtn()}></Sidebar>
			{isLoading ? <Loader></Loader> : null}
			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbNav
					Heading={editParam ? 'Edit DC' : 'Add New  DC'}
					breadcrumbList={breadcrumbNav}
				/>
				{/* Add User Details */}
				<AddUserHolder>
					<ProfileDetails>
						<Box as="form">
							<Grid gap={0}>
								<FormInput
									validationMessage={error.dcNameError}
									validation={error.dcNameError ? 'error' : undefined}
									value={values && values.name}
									required={true}
									name="dcName"
									onTextChange={(e) => onChangeValue(e, 'dcName')}
									maxLength={20}
									type="text"
									label="DC Name"
									onBlur={(e) => { validateDcName(e.target.value) }}
								/>
								<FormInput
									label="Description"
									validationMessage={error.descriptionError}
									validation={error.descriptionError ? 'error' : undefined}
									value={values && values.description}
									required={true}
									name="description"
									onTextChange={(e) => onChangeValue(e, 'description')}
									maxLength={100}
									type="text"
								/>

								<FormInput
									label="Delivery Locations"
									validationMessage={error.deliveryLocationError}
									validation={error.deliveryLocationError ? 'error' : undefined}
									value={values && values.delivery_location}
									placeholder="Enter Delivery Location Zipcode"
									required={true}
									name="deliveryLoc"
									onTextChange={(e) => onChangeValue(e, 'deliveryLoc')}
									maxLength={500}
									type="text"
								/>

								<div>
									<label>
										Address <sup style={{ fontSize: 16, color: 'red' }}>*</sup>
									</label>
									<AutocompleteHolder>
										<Autocomplete
											name="address"
											getItemValue={(item) => item.FullAddress}
											items={autoAddressList}
											renderItem={(item, isHighlighted) => (
												<div
													key={item.Id}
													style={{ background: isHighlighted ? 'lightgray' : 'white' }}
												>
													{item.FullAddress}
												</div>
											)}
											value={autoAddress}
											onChange={(e) => {
												setAutoAddress(e.target.value); onChangeValue(e.target.value, 'address');
											}}
											onSelect={(val) => {
												setAutoAddress(val);
												addressOnSelect(val);
											}}
										/>
										{
											autoAddressList.length == 0 && autoAddress != "" && autoAddress.length > 4 && !autoAddress404 ? <div className="icon-container"><i className="loader"></i></div> : ""
										}
									</AutocompleteHolder>
								</div>

								{/* <FormInput
									label="Address"
									validationMessage={error.addressFieldError}
									validation={error.addressFieldError ? 'error' : undefined}
									value={values && values.address}
									required={true}
									name="address"
									onTextChange={(e) => onChangeValue(e, 'address')}
									maxLength={200}
									type="text"
								/> */}
								<FormInput
									label="Email"
									validationMessage={error.emailAddressError}
									validation={error.emailAddressError ? 'error' : undefined}
									value={values && values.email}
									required={true}
									name="email"
									onTextChange={(e) => onChangeValue(e, 'email')}
									maxLength={50}
									type="text"
								/>
								<FormInput
									label="Phone"
									validationMessage={error.phoneNumberError}
									validation={error.phoneNumberError ? 'error' : undefined}
									value={values && (values.phone ? values.phone.toString() : '')}
									required={true}
									name="phone"
									onTextChange={(e) => onChangeValue(e, 'phone')}
									maxLength={12}
									type="tel"
								/>
								<FormInput
									label="Contact Name"
									validationMessage={error.contactNameError}
									validation={error.contactNameError ? 'error' : undefined}
									value={values && values.contact_name}
									required={true}
									name="contactName"
									onTextChange={(e) => onChangeValue(e, 'contactName')}
									maxLength={20}
									type="text"
								/>
								<FormInput
									label="Contact Person’s Phone"
									validationMessage={error.contactPhoneError}
									validation={error.contactPhoneError ? 'error' : undefined}
									value={
										values &&
										(values.contact_person_phone ? values.contact_person_phone.toString() : '')
									}
									required={true}
									name="contactPhone"
									onTextChange={(e) => onChangeValue(e, 'contactPhone')}
									maxLength={12}
									type="tel"
								/>
								<FormInput
									label="City"
									validationMessage={error.cityError}
									validation={error.cityError ? 'error' : undefined}
									value={values && values.city}
									required={true}
									name="city"
									onTextChange={(e) => onChangeValue(e, 'city')}
									maxLength={20}
									type="text"
								/>
								<FormInput
									label="State"
									validationMessage={error.stateError}
									validation={error.stateError ? 'error' : undefined}
									value={values && values.state}
									required={true}
									name="state"
									onTextChange={(e) => onChangeValue(e, 'state')}
									maxLength={50}
									type="text"
								/>
								<FormInput
									label="Zipcode"
									validationMessage={error.zipCodeError}
									validation={error.zipCodeError ? 'error' : undefined}
									value={values && values.zip_code}
									required={true}
									name="zipCode"
									onTextChange={(e) => onChangeValue(e, 'zipCode')}
									maxLength={10}
									type="text"
								/>
								<Stack
									className="categorySelect"
									categorySelect
									validation={error.countryError ? 'error' : undefined}
									gap={0}
								>
									<div>
										<label>
											Country <sup>*</sup>
										</label>
										<Select
											id="countryselected"
											name="countryselected"
											onChange={(e) => onChangeValue(e, 'country')}
											value={values && values.country}
										>
											<option>-Select-</option>
											{countries &&
												countries
													.filter((x) => x.id != "AN")
													.map((option, index) => (
														<option value={option.full_name_english} key={index}>
															{option.full_name_english}
														</option>
													))}
										</Select>
										<Icon.downArrow />
									</div>
									<span style={{ fontSize: 12, color: 'red' }}>{error.countryError}</span>
								</Stack>
							</Grid>
							{/* <Box as="section">
								<label>Status</label>
								<Toggle onChange={(e) => toggleBtnChange(e)} checked={values && statusChecked} />
							</Box> */}

							<Grid gap={0} className="inline-checkbox">
								<Flex>
									<Flex>
										<Text as="h1" fontSize={14} fontFamily="OpenSansBold">
											DC Active Status
										</Text>
									</Flex>
									<Flex>
										<Toggle onChange={(e) => toggleBtnChange(e)} checked={values && statusChecked} />
									</Flex>
								</Flex>
							</Grid>
							{(editParam && utilityFunctions.isGranted(dcUserPermissionList.Vacation)) ?
								<div>
									<Grid gap={0} className="vacation-details">
										<Flex>
											<Flex>
												<Text as="h1" fontSize={18} fontFamily="OpenSansBold">
													Vacation Details
													<Text as="span" className="requiredText">
														*Applicable from 00:00 hr (Midnight) to 00:00 hr (Midnight)
													</Text>
												</Text>
											</Flex>
										</Flex>
									</Grid>
									<Grid gap={0}>
										<Flex>
											<Flex>
												<Label>
													<Checkbox checked={vacationCheckbox} name="remember" handleChange={(e) => onChangeVacationDetails(e, 'vacation')} />
													Vacation Status
												</Label>
											</Flex>
										</Flex>
									</Grid>
									{vacationCheckbox == true ?
										<Grid gap={0} >
											<Flex className="vacationDetailsDate">
												<Box className="datepicker-c">
													<FormGroup datePicker>
														<FormLabel>Date From</FormLabel>
														<DatePicker
															value={values.vacation.from}
															selected={startDate}
															// onChange={(date: any) => setStartDate(date)}
															onChange={(e) =>
																onChangeValue(e, 'date_from')
															}
															dateFormat={helperFunctions.returnDateFormat()}
														/>
														<Icon.DateIconInput />
													</FormGroup>
													<span style={{ fontSize: 12, color: 'red' }}>{error.dateFromError}</span>
												</Box>
												<Box className="datepicker-c">
													<FormGroup datePicker>
														<FormLabel>Date To</FormLabel>
														<DatePicker
															value={values.vacation.to}
															selected={startDate}
															onChange={(e) =>
																onChangeValue(e, 'date_to')
															}
															dateFormat={helperFunctions.returnDateFormat()}
														/>
														<Icon.DateIconInput />
													</FormGroup>
													<span style={{ fontSize: 12, color: 'red' }}>{error.dateToError}</span>
												</Box>
											</Flex>
											<Flex>
												<Flex className="full-width">
													<FormInput label="Message for Customers"
														value={values.vacation.comment}
														onTextChange={(e) => onChangeValue(e, 'comment')}
														maxLength={250}
														type="text" />
												</Flex>
											</Flex>
										</Grid>
										: null
									}
								</div>
								: null}

						</Box>
					</ProfileDetails>

					{/* Footer Btn Block */}
					<FooterBtnBlock>
						<Stack direction="x" gap={3} justifyContent={['center', 'flex-end']}>
							{editParam ? null : (
								<Button
									onClick={() => submitData('clear')}
									flex={[1, 1, 'initial']}
									variant="secondary"
								>
									Clear
								</Button>
							)}
							<Button
								onClick={() => clearDcDetails('cancel')}
								flex={[1, 1, 'initial']}
								variant="secondary"
							>
								Cancel
							</Button>
							<Button
								disabled={disableBtnSave ||
									error.dcNameError != '' ||
									error.emailAddressError != '' ||
									error.phoneNumberError != '' ||
									error.deliveryLocationError != '' ||
									error.descriptionError != '' ||
									error.contactNameError != '' ||
									error.contactPhoneError != '' ||
									error.addressFieldError != '' ||
									error.cityError != '' ||
									error.stateError != '' ||
									error.countryError != '' ||
									error.dateFromError != '' ||
									error.dateToError != ''}
								onClick={() => submitData('save')}
								flex={[1, 1, 'initial']}
							>
								{editParam ? 'Save' : 'Add'}
							</Button>
						</Stack>
					</FooterBtnBlock>
				</AddUserHolder>
				<SweetAlert
					show={showPopup}
					confirmBtnText="Confirm"
					onConfirm={onSubmit}
					showCancel={true}
					onCancel={closePopup}
					title={'Confirmation Box'}
				>
					{() => <form>{modalText}</form>}
				</SweetAlert>
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default AddDCList;
