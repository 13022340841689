import styled from 'styled-components';

const MainContent = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
	padding: 0 50px;
	@media (max-width: 960px) {
		padding: 0 15px;
	}
`;
const AddUserHolder = styled.div`
	width: 100%;
	background: ${({ theme }) => theme.colors.bg.white};
	position: relative;
	margin: 0 auto;
	border: 1px solid ${({ theme }) => theme.colors.border.userListTD};
	box-sizing: border-box;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07);
	border-radius: 8px;
	margin-bottom: 50px;
`;
const FooterBtnBlock = styled.div`
	padding: 0 50px 58px;
	@media (max-width: 640px) {
		padding: 11px 50px 28px;
	}
	div {
		width: 875px;
		@media (max-width: 1200px) {
			width: 100%;
		}
	}
	button {
		min-width: 100px;
		text-transform: capitalize;
		padding: 0 15px;
		height: 48px;
	}
	@media (max-width: 767px) {
		padding: 0 20px 58px;

		button {
			flex: 0 0 auto;
		}
	}
`;
const ProfileDetails = styled.div`
	margin: 50px 60px 50px;
	padding-bottom: 50px;
	@media (max-width: 640px) {
		margin: 15px;
		padding-bottom: 30px;
	}
	h1 {
		text-transform: uppercase;
		margin-bottom: 25px;
		@media (max-width: 640px) {
			margin-bottom: 15px;
		}
	}
	label {
		font-size: 16px;
		line-height: 21px;
		font-family: 'OpenSansSemiBold';
		color: ${({ theme }) => theme.colors.text.SubHeading};
		margin-bottom: 8px;
	}
	form {
		width: 865px;
		@media (max-width: 1100px) {
			width: 100%;
		}
		& > div {
			grid-template-columns: repeat(2, 1fr) !important;
			column-gap: 25px;
			row-gap: 25px;
			@media (max-width: 1200px) {
				flex-grow: 1;
			}
			@media (max-width: 640px) {
				width: 100%;
				grid-template-columns: repeat(1, 1fr) !important;
			}
			div {
				grid-column: auto / span 1 !important;
				margin: 0 !important;
				grid-template-columns: repeat(1, 1fr) !important;
			}
		}
		textarea {
			height: 116px;
			margin-bottom: 20px;
		}
	}
`;
const BreadcrumbHolder = styled.div`
	// button {
	// 	display: none;
	// }
`;
export { MainContent, FooterBtnBlock, AddUserHolder, ProfileDetails, BreadcrumbHolder };
