const pageConstants = {
	dateFormat: 'DD MMMM YYYY',
	defaultDateFormat : 'YYYY-MM-DD',
	systemDateFormat: 'DD/MM/YYYY',
	defaultTime:'hh:mm:ss A',
	defaultLang: 'en',
	defaultCountry: 'NZ',
	supplyGroup: 'supply',
	categoryGroup: 'category',
	subCategoryGroup: 'subCategory',
	allergensGroup : 'allergens',
	maxValue : 100000,
	dateTimeFormat:'YYYY-MM-DD hh:mm:ss A',
	phoneRegex: /^(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})$/,
};
export default pageConstants;
