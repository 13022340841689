import React, { ReactElement, useEffect, useState } from 'react';
import 'react-tippy/dist/tippy.css';
import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { pageNavigators, pages } from 'models/enum-constants/common.constants';
import { Pagination, Modal, bindPagination } from 'ui/patterns';
import { default as helper, default as helperFunctions } from 'utils/helper.functions';
import ReviewVenue from 'venue/review';
import { useHistory } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
const StyledFavoriteOrdersDetailsListView = styled.div`
	.grid-data {
		.grid-header {
			align-items: center;
			> div {
				padding-left: 20px;
				font-weight: bold;
				font-size: 14px;
				line-height: 18px;
				text-transform: uppercase;
				color: #242b24;
				min-height: 50px;
				flex: 0 1 100%;
				align-items: center;
				display: flex;
			}
		}
		.grid-body {
			background: #ffffff;
			border: 1px solid #edebe6;
			box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07);
			border-radius: 8px;
			transition: box-shadow 100ms ease-in, transform 100ms ease-in;
			.venue-val {
				cursor: pointer;
				max-width: 40vw;
			}
			// &:hover{
			// 	transform: translateY(-5px);
			// 	box-shadow: 0 20px 20px #eee;
			// 	border: 1px solid rgba(135,137,99,1);
			// }
			& + .grid-body {
				margin-top: 16px;
			}
			&.no-data {
				// &:hover{
				//     transform: translateY(0);
				//     border: 0;
				// }
				.title {
					justify-content: center;
					min-height: 100px;
					align-items: center;
				}
			}
		}
		.list {
			width: 100%;
			display: flex;
			min-height: 70px;
			align-items: center;
			> div {
				padding-left: 20px;
				flex: 0 1 100%;
				display: flex;
				&.title {
					font-weight: bold;
					font-size: 15px;
					line-height: 20px;
				}
			}
		}
		.overallRatings {
			div {
				/* padding-left: 110px; */
			}
		}
		.ratingCount {
			div {
				padding-left: 35px;
			}
		}
	}
`;

function DataGrid({ gridData, gridDataHead, venueName = '' }): ReactElement {
	const itemsPerPage = 10;
	const showingPageCount = 5;
	const history = useHistory();
	const [page, setPage] = React.useState(1);
	const [gridDataShown, setGridDataShown] = React.useState(gridData);
	const count = gridData && gridData && (gridData.length ?? 0);
	var [pageList, setPageList] = React.useState([...Array(showingPageCount)].map((_, i) => i + 1));
	const totalPages = Math.ceil((gridData && gridData && gridData.length) / itemsPerPage);
	const [showSetScheduleModal, setShowSetScheduleModal] = useState(false);
	const [modalData, setModalData] = useState(null);
	const toggleSetScheduleModal = (item) => {
		setShowSetScheduleModal(!showSetScheduleModal);
		const venueData = Object.assign({}, item);
		venueData['venue_name'] = venueName;
		setModalData(venueData);
	};
	const handlePagination = (action) => {
		if (action == pageNavigators.prev) setPageData(page - 1);
		else if (action == pageNavigators.next) setPageData(page + 1);
		if (action == pageNavigators.fastPrev) setPageData(1);
		//pageList[0] - showingPageCount
		else if (action == pageNavigators.fastNext) setPageData(totalPages); //pageList.slice(-1).pop()! + 1
	};

	const setPageData = (page) => {
		if (page <= totalPages && page > 0) {
			setPage(() => page);
			setPageList(getPages(page).pages);
		}
	};
	const bindPages = () => {
		return bindPagination(getPages(page, false).pages, page, setPage);
	};
	function getPages(currentPage: number = 1, repagination: boolean = true) {
		let pageCount = totalPages;

		if (repagination) {
			// will only enter this block for prev & next
			//for checking if in last section of pagination
			let isLastSection = pageCount - currentPage < showingPageCount ? true : false;
			if (!pageList.includes(currentPage))
				//if current pagination list doesnt contains current page,
				// then the list will get updated with new values.ie pagination numbers will change
				setPageArray(currentPage, pageCount, isLastSection);
		} else if (pageCount < showingPageCount)
			// if total page count less than numberOfPages, then page count
			//will be max page
			pageList = Array.from(Array(pageCount + 1 - 1).keys()).map((i) => 1 + i);
		// return object with all pager properties required by the view
		return {
			pages: pageList,
		};
	}
	const setPageArray = (currentPage, pageCount, isLastSection = false) => {
		//if page is greatr than list items, then new start will be last list item + 1
		let val =
			currentPage > pageList[0] ? pageList.slice(-1).pop()! : pageList[0] - (showingPageCount + 1);
		let startPage = val + 1;
		// if last section, then end page will be pagecount
		let endPage = isLastSection && pageList[0] < currentPage ? pageCount : val + showingPageCount;
		// pagelist updating with new values
		pageList = Array.from(Array(endPage + 1 - startPage).keys()).map((i) => startPage + i);
	};
	const navigateToEdit = (id, name) => {
		history.push({
			pathname: 'seller-rating-by-store',
			search: '?venue_id=' + id + '&venue=' + name,
		});
	};
	useEffect(() => {
		setGridDataShown(gridData.slice((page - 1) * itemsPerPage, page * itemsPerPage));
	}, [page, gridData]);
	return (
		<StyledFavoriteOrdersDetailsListView>
			<SimpleBar>
				<Box as="article" className="grid-data">
					<Flex className="grid-header">
						{gridDataHead
							? gridDataHead.map((item, i) => {
									return (
										<Box className="gridHeader" key={i}>
											<Text>{item.label}</Text>
										</Box>
									);
							  })
							: null}
					</Flex>
				</Box>
				<Box as="article" className="grid-data">
					{gridDataShown
						? gridDataShown.map((item, key) => {
								return (
									<Flex key={key} className="grid-body">
										<Box className="list">
											{item.venue_name ? (
												<Text className="title">
													<Text
														as="div"
														className="truncate venue-val gridBodyItem"
														onClick={() => navigateToEdit(item.venue_id, item.venue_name)}
													>
														{item.venue_name}
													</Text>
												</Text>
											) : null}
											{item.venue_user_name ? (
												<Text className="gridBodyItem">{item.venue_user_name}</Text>
											) : null}
											{item.overall_ratings ? (
												<Text className="gridBodyItem overallRatings">
													<div>{item.overall_ratings}</div>
												</Text>
											) : null}
											{item.rating ? (
												<Text className="gridBodyItem ratingCount">
													<div>{item.rating}</div>
												</Text>
											) : null}
											{item.review ? (
												<Text className="title gridBodyItem">
													<Text
														as="div"
														className="truncate venue-val "
														onClick={() => toggleSetScheduleModal(item)}
													>
														{item.review}
													</Text>
												</Text>
											) : null}
											{item.created_at ? (
												<Text className="gridBodyItem">{helper.formatDate(item.created_at)}</Text>
											) : null}
										</Box>
									</Flex>
								);
						  })
						: null}
					{gridData && gridData == 0 && (
						<Flex className="grid-body no-data">
							<Box className="list">
								<Text className="title">No data found</Text>
							</Box>
						</Flex>
					)}
				</Box>
			</SimpleBar>
			{gridData && gridData.length > 0 && (
				<Flex>
					{/* commenting this due to some error in code , uncomment for pagination option */}
					<Pagination
						handlePagination={handlePagination}
						bindPagination={bindPages}
						page={page}
						total={gridData && count}
						itemsPerPage={itemsPerPage}
					/>
				</Flex>
			)}
			<Modal width="600px" showModal={showSetScheduleModal} setShowModal={setShowSetScheduleModal}>
				<ReviewVenue data={modalData}></ReviewVenue>
			</Modal>
		</StyledFavoriteOrdersDetailsListView>
	);
}

export { DataGrid };
