import React, { ReactElement, useEffect, useState } from 'react';
import { SpaceProps, LayoutProps, FlexboxProps } from 'styled-system';
import {
	StyledSuppliersReview,
	ViewAllHeadingBlock,
	SuppliersReviewDetails,
	RatingBox,
	RatingBoxBad,
	AddCommentsHolder,
	ViewAllBlock,
} from './styles';
import { Text, Box, Flex, Link } from 'rebass/styled-components';
import { Stack, Button } from 'ui/components';
import { Input } from '@rebass/forms';
import * as Icon from 'ui/icons';
import hooks from 'utils/requestCtx/reactQueryHooks';
import notify from 'utils/toast.service';
import Iget from 'models/Iget';
import moment from 'moment';
import pageConstants from 'utils/pageConstants';
import Ipost from 'models/Ipost';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import helperFunctions from 'utils/helper.functions';
import api from 'utils/requestCtx/api.service';
import SweetAlert from 'react-bootstrap-sweetalert';
import constants from 'utils/constants';

type styledSystemProps = SpaceProps & LayoutProps & FlexboxProps;
type StyledSuppliersReviewProps = styledSystemProps & {
	totalAmount?: string;
};

function SupplierListReview(props): ReactElement {
	const [query, setQuery] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [suppReview, setSuppliersReview] = useState<any>([]);
	const [currentUserReview, setUserReview] = useState<any>([]);

	const location = useLocation();

	const parsed = queryString.parse(location.search);
	const userId = parseInt(localStorage.getItem('userId')!);
	const key = 'postQuery';
	const [mutatePost] = hooks.useMutation(key);
	const history = useHistory();
	const queryText = (e) => {
		setQuery(e.target.value);
	};
	const [action, setAction] = useState('');
	const [modalText, setmodalText] = useState('');
	const [showPopup, setShowModal] = useState(false);
	const CUSTOMER_TOKEN = localStorage.getItem('customerToken');
	const [visibleSupplyItem, setVisibleSupplyItem] = useState(3);
	const [disableViewAll, setDisableViewAll] = useState(false);
	const url = constants.getAllSupplierReview() + parsed.id;
	const obj: Partial<Iget> = {
		params: {},
		url: url,
		enabled: true,
		key: 'suppReviewKey',
		resultFilteringRequired: false,
		headers: { Authorization: CUSTOMER_TOKEN },
	};
	var qry = hooks.useQuery(obj);

	useEffect(() => {
		if (qry.data !== undefined) {
			let filteredUserData = [] as any;
			let filteredData = [] as any;
			props.getAllReviewDetails(qry.data[0]);
			if (qry.data[0].available === true) {
				if (qry.data[0].reviews.length > 1) {
					for (let i = 1; i <= qry.data[0].reviews.length - 1; i++) {
						filteredData.push(qry.data[0].reviews[i]); //review of other user
						setSuppliersReview(filteredData);
					}
					filteredUserData.push(qry.data[0].reviews[0]); //review of current user
					setUserReview(filteredUserData);
				} else {
					setUserReview(qry.data[0].reviews);
				}
			} else {
				// if there is no current user review
				setSuppliersReview(qry.data[0].reviews);
			}
		}
	}, [qry.data]);

	async function submitQuery() {
		if (query != '' && userId) {
			setIsLoading(true);
			const payload = {
				user_id: userId,
				message: query,
				supplier_id: props?.supplierId,
			};
			const obj: Partial<Ipost> = {
				data: { supplierDetails: payload },
				params: {},
				url: `experion/venueuser/viewsupplierdetails`,
				message: 'Query submitted successfully',
			};
			try {
				await mutatePost(obj).then((data) => {
					if (data != undefined) {
						setQuery(JSON.parse(JSON.stringify('')));
					}
					setIsLoading(false);
				});
			} catch (e) {
				setIsLoading(false);
			}
		} else {
			notify('please type any query').error();
		}
	}

	const editReview = (reviewId) => {
		history.push({
			pathname: '/admin/sellers-review',
			search: '?id=' + props?.supplierId + '&reviewId=' + reviewId,
		});
	};

	const deleteReview = (reviewId) => {
		const obj1: Partial<Ipost> = {
			data: {},
			params: {},
			url: constants.deleteSupplierReview() + `${reviewId}`,
			message: 'Review deleted successfully',
			headers: { Authorization: CUSTOMER_TOKEN },
		};
		api.delete(obj1).then(function (resp) {
			if (resp == true) {
				setUserReview([]);
				qry.refetch().then(() => { });
				props.fetchData && props.fetchData();
				closePopup();
			}
		});
	};
	function showConfirmationModal(text, action) {
		setAction(action);
		setmodalText(text);
		setShowModal(!showPopup);
	}

	const closePopup = () => {
		setShowModal(!showPopup);
	};

	const viewAllReviews = () => {
		setDisableViewAll(true);
		setVisibleSupplyItem(suppReview.length);
		// setSuppliersReview(suppReview);
	};
	return (
		<StyledSuppliersReview>
			{currentUserReview.length > 0 ? (
				<ViewAllHeadingBlock>
					<Text as="h1" fontSize="18px">
						Your Reviews
					</Text>
				</ViewAllHeadingBlock>
			) : (
				''
			)}
			<SuppliersReviewDetails>
				<Box>
					{/* {suppReview && suppReview[0]?.available === false ? */}
					{currentUserReview &&
						currentUserReview.length > 0 &&
						currentUserReview.map((item1, index) => (
							<Stack direction="y" gap={3} key={index}>
								<Flex
									as="article"
									bg="white"
									p={[3, 4]}
									sx={{ alignItems: 'center' }}
									key={helperFunctions.getRandomInt(pageConstants.maxValue)}
								>
									<Box as="hgroup">
										<Box display="flex" sx={{ alignItems: 'flexstart' }}>
											{item1.rating <= 2 ? (
												<RatingBoxBad>{item1.rating && item1.rating % 1 == .5 ? parseFloat(item1.rating).toFixed(1) : parseInt(item1.rating)}/5</RatingBoxBad>
											) : (
												<RatingBox>{item1.rating && item1.rating % 1 == .5 ? parseFloat(item1.rating).toFixed(1) : parseInt(item1.rating)}/5</RatingBox>
											)}
											<Text
												as="h2"
												fontFamily="OpenSansBold"
												fontSize={18}
												lineHeight="23px"
												color="text.black"
											>
												{item1.title}
											</Text>
										</Box>
										<Box className="btnHolder">
											<Button
												className="action-btn"
												onClick={() => {
													editReview(item1.id);
												}}
											>
												<Icon.textEditIcon />
											</Button>
											<Button
												className="action-btn"
												onClick={() => {
													showConfirmationModal('Delete Review?', 'deleteRole');
												}}
											>
												<Icon.DeleteIcon />
											</Button>
										</Box>
									</Box>
									<Text
										as="h3"
										fontFamily="OpenSansRegular"
										fontSize={18}
										lineHeight="23px"
										color="text.black03"
									>
										{item1.review}
									</Text>
									<Box as="hgroup">
										<Box>
											<Text
												as="h4"
												fontFamily="OpenSansBold"
												fontSize={18}
												lineHeight="18px"
												color="text.black03"
											>
												{item1.venue_user_name}
												{item1.verified_customer === '1' ? <strong>(Verified)</strong> : ''}
											</Text>
											<Text
												as="h5"
												fontFamily="OpenSansSemiBold"
												fontSize={18}
												lineHeight="18px"
												color="text.black03"
											>
												<strong>{helperFunctions.formatDate(item1.updated_at)}</strong>
											</Text>
										</Box>
										{/* <Box>
											<Button>
												<Icon.likeIcon />
												<Text
													as="h6"
													fontFamily="OpenSansMedium"
													fontSize={17}
													color="text.black03"
												>
													{likeCount}
												</Text>
											</Button>
											<Button>
												<Icon.unLikeIcon />
												<Text
													as="h6"
													fontFamily="OpenSansMedium"
													fontSize={17}
													color="text.black03"
												>
													{unLikeCount}
												</Text>
											</Button>
										</Box> */}
									</Box>
								</Flex>
								<SweetAlert
									show={showPopup}
									confirmBtnText="Confirm"
									onConfirm={() => deleteReview(item1.id)}
									showCancel={true}
									onCancel={closePopup}
									title={'Confirmation Box'}
								//  dependencies={[this.state.firstName, this.state.lastName]}
								>
									{() => <form>{modalText}</form>}
								</SweetAlert>
							</Stack>
						))}
					{/* : ''} */}
					<Flex className="reviews-rating">
						<ViewAllHeadingBlock>
							<Text as="h1" fontSize="18px">
								Ratings & Reviews
							</Text>
						</ViewAllHeadingBlock>
						{suppReview && suppReview.length > 0
							? suppReview.slice(0, visibleSupplyItem).map((item2, index) => (
								<Stack direction="y" gap={3} key={index}>
									<Flex
										as="article"
										bg="white"
										p={[3, 4]}
										sx={{ alignItems: 'center' }}
										key={helperFunctions.getRandomInt(pageConstants.maxValue)}
									>
										<Box flex={1} display="flex" sx={{ alignItems: 'flex-start' }}>
											{item2.rating <= 2 ? (
												<RatingBoxBad>{item2.rating && item2.rating % 1 == .5 ? parseFloat(item2.rating).toFixed(1) : parseInt(item2.rating)}/5</RatingBoxBad>
											) : (
												<RatingBox>{item2.rating && item2.rating % 1 == .5 ? parseFloat(item2.rating).toFixed(1) : parseInt(item2.rating)}/5</RatingBox>
											)}
											<Text
												as="h2"
												fontFamily="OpenSansBold"
												fontSize={18}
												lineHeight="23px"
												color="text.black"
											>
												{item2.title}
											</Text>
										</Box>
										<Text
											as="h3"
											fontFamily="OpenSansRegular"
											fontSize={18}
											lineHeight="23px"
											color="text.black03"
										>
											{item2.review}
										</Text>
										<Box as="hgroup">
											<Box>
												<Text
													as="h4"
													fontFamily="OpenSansBold"
													fontSize={18}
													lineHeight="18px"
													color="text.black03"
												>
													{item2.venue_user_name}
													{item2.verified_customer === '1' ? <strong>(Verified)</strong> : ''}
												</Text>
												<Text
													as="h5"
													fontFamily="OpenSansSemiBold"
													fontSize={18}
													lineHeight="18px"
													color="text.black03"
												>
													<strong>{helperFunctions.formatDate(item2.updated_at)}</strong>
												</Text>
											</Box>
											{/* <Box>
											<Button>
												<Icon.likeIcon />
												<Text
													as="h6"
													fontFamily="OpenSansMedium"
													fontSize={17}
													color="text.black03"
												>
													{likeCount}
												</Text>
											</Button>
											<Button>
												<Icon.unLikeIcon />
												<Text
													as="h6"
													fontFamily="OpenSansMedium"
													fontSize={17}
													color="text.black03"
												>
													{unLikeCount}
												</Text>
											</Button>
										</Box> */}
										</Box>
									</Flex>
								</Stack>
							))
							: 'No reviews'}
					</Flex>
				</Box>
			</SuppliersReviewDetails>
			{suppReview && suppReview.length > 3 && disableViewAll === false ? (
				<ViewAllBlock>
					<Link variant="nav" onClick={viewAllReviews} className="view-all">
						View More
					</Link>
				</ViewAllBlock>
			) : (
				''
			)}
			<AddCommentsHolder>
				<Input id="query" onChange={(e) => queryText(e)} value={query} name="email" placeholder="Type here" />
				<Button onClick={submitQuery}>Contact Supplier</Button>
			</AddCommentsHolder>
		</StyledSuppliersReview>
	);
}

export { SupplierListReview };
