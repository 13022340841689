const ValidationJson = {
	header: ['sku', 'qty'],
	MandatoryFields: ['sku', 'qty'],
	MaxLengthFields: ['sku', 'qty'],

	// DropDownItems : ['Product_Category'],
	ValidText: [],
	NumberOnly: ['Price', 'Inventory'],
};

export default ValidationJson;
