import React, { ReactElement, useEffect, useState } from 'react';

import {
	Header,
	Footer,
	BreadcrumbNav,
	Grid,
	Button,
	Stack,
	Toggle,
	Checkbox,
	FormLabel,
	Loader,
} from 'ui/components';
import { Sidebar, FormInput, FormGroup } from 'ui/patterns';
import { Box, Flex, Text } from 'rebass/styled-components';
import SweetAlert from 'react-bootstrap-sweetalert';
import helper from 'utils/helper.functions';

import { MainContent, FooterBtnBlock, AddUserHolder, ProfileDetails } from './styles';
import { Label } from '@rebass/forms';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as Icon from 'ui/icons';
import moment from 'moment';
import Ipost from 'models/Ipost';
import api from 'utils/requestCtx/api.service';
import { useHistory } from 'react-router';
import Iget from 'models/Iget';
import hooks from 'utils/requestCtx/reactQueryHooks';
import notify from 'utils/toast.service';

function DcConfig(): ReactElement {
	const breadcrumbNav = [
		{ id: '01', label: 'Dashboard ', route: 'seller-dashboard', active: false },
		{ id: '02', label: 'DC Configuration', active: true },
	];
	const [startDate, setStartDate] = useState(new Date());
	const OnlyHeading = 'DC Configuration';
	let selectedDc = localStorage.getItem('selectedLocation');
	const [modalText, setmodalText] = useState('');
	const [vacationCheckbox, setVacationDetails] = useState(false);
	const [showPopup, setShowModal] = useState(false);
	const [initialDateFrom, setInitialDateFrom] = useState(new Date());
	const [initialDateTo, setInitialDateTo] = useState(new Date());
	let userId = localStorage.getItem('userId');
	const [isLoading, setIsLoading] = useState(false);
	const [action, setAction] = useState('')
	const history = useHistory();
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);	
	async function closePopup() {
		await setShowModal(!showPopup);
	}
	useEffect(() => {
		if (selectedDc != '') {
			getDcDetails()
		}
	}, []);
	const item: Partial<Iget> = {
		params: {},
		url: `experion/supplycompanyaccount/getdc/${selectedDc}`,
		enabled: false,
		key: 'dcEditDetails',
	};
	const dcEditDetails = hooks.useQuery(item);
	const getDcDetails = () => {
		setIsLoading(true);
		dcEditDetails.refetch().then(function (data) {
			if (data) {
				if (data.vacation.from) {
					setInitialDateFrom(new Date(data.vacation?.from));
					setInitialDateTo(new Date(data.vacation?.to));
					data.vacation.from = moment(data.vacation.from).format('DD/MM/YYYY');
					data.vacation.to = moment(data.vacation.to).format('DD/MM/YYYY');
					setVacationDetails(true);
				}
				setValues(data);
				setIsLoading(false);
			}
		}).finally(() => {
			setIsLoading(false);
		})
	}
	const onChangeVacationDetails = (item) => {
		if (item == 'cancel') {
			showConfirmationModal('Are you sure you want to cancel ?', item);
		}
		else
			showConfirmationModal('Are you sure want to change the vacation status of the DC ?', item);
	}
	function showConfirmationModal(text, item) {
		setAction(item);
		setmodalText(text);
		setShowModal(!showPopup);
	}
	const [error, setError] = useState({
		dateFromError: '',
		dateToError: ''
	});
	const [values, setValues] = useState({
		vacation: {
			from: new Date().toString(),
			to: new Date().toString(),
			comment: "",
			status: "",
			is_delete: "",
			dc_id: parseInt(selectedDc ? selectedDc : '')
		},
		dc_user_id: parseInt(userId ? userId : '')
	});
	const onSubmit = () => {
		if (action == 'cancel') {
			history.push('seller-dashboard');
		} else {
			if (vacationCheckbox == true) {
				values.vacation = [] as any;
				setInitialDateTo(new Date());
				setInitialDateFrom(new Date());
				error.dateFromError=''
				error.dateToError= ''
				setError(error);
			}
			setVacationDetails(!vacationCheckbox);
		}
		setShowModal(!showPopup);
	}
	const onChangeValue = (val, name) => {
		const item = values;
		switch (name) {
			case 'comment':
				item.vacation.comment = val;
				break;
			case 'date_from':
				setInitialDateFrom(val);
				if (val != '') {
					let date = val !== '' ? moment(val).format('DD/MM/YYYY') : '';
					var isafter = moment(new Date).isAfter(val);
					if (isafter) {
						let now = moment().format('DD/MM/YYYY');
						if (date == now) {
							error.dateFromError = ''
						}
						else
							error.dateFromError = `Date From should not be less than Today's date`;
					}
					else {
						error.dateFromError = '';
					}
					var validateDateTo = new Date(val) > new Date(initialDateTo)
					if (validateDateTo) {
						error.dateToError = `Date To should be greater than Date From`;
					}
					else {
						error.dateToError = '';
					}
					item.vacation.from = date;
				}
				else {
					item.vacation.from = val;
					error.dateFromError = 'Date From cannot be blank';
				}
				setError(error);
				break;
			case 'date_to':
				setInitialDateTo(val);
				if (val != '') {
					let dateTo = val !== '' ? moment(val).format('DD/MM/YYYY') : '';
					let date1 = new Date(val);
					let date2 = new Date(initialDateFrom);
					var validateDate = date2 > date1;
					if (validateDate) {
						error.dateToError = `Date To should be greater than Date From`;
					}
					else {
						error.dateToError = '';
					}
					item.vacation.to = dateTo;
				}
				else {
					item.vacation.to = val;
					error.dateToError = 'Date To cannot be blank';
				}
				setError(error);
				break;
			default:
				break;
		}
		setValues({
			...item,
		});
	}
	async function submitData() {

		if (vacationCheckbox && (new Date(initialDateTo).getTime() === new Date(initialDateFrom).getTime())) {
			notify("Date From and Date To cannot be same").error();
		}
		else {
			values.dc_user_id = parseInt(userId ? userId : '');
			var updatePayload = values;
			if (vacationCheckbox) {
				if (!values.vacation.from) {
					updatePayload.vacation.from = moment(new Date()).format('YYYY-MM-DD');
				}
				else if (values.vacation.from) {
					updatePayload.vacation.from = moment(initialDateFrom).format('YYYY-MM-DD')
				}
				if (!values.vacation.to) {
					updatePayload.vacation.to = moment(new Date()).format('YYYY-MM-DD');
				}
				else if (values.vacation.to) {
					updatePayload.vacation.to = moment(initialDateTo).format('YYYY-MM-DD')
				}

			}
			const obj: Partial<Ipost> = {
				data: { dC: updatePayload },
				params: {},
				url: `experion/supplycompanyaccount/updatedc/${localStorage.getItem('selectedLocation')}`,
				message: 'Vacation details updated successfully',
			};
			try {
				api.put(obj).then((data) => {
					if (data !== undefined) {
						history.push('seller-dashboard');
					}
					setShowModal(!showPopup);
				});
			} catch (e) {
				setShowModal(!showPopup);
			}
		}
	}
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	}
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={()=>onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={()=>onclickHambrgBtn()}></Sidebar>
			{isLoading == true ? <Loader></Loader> : null}
			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbNav Heading="Back" breadcrumbList={breadcrumbNav} OnlyHeading={OnlyHeading} />
				{/* Add User Details */}
				<AddUserHolder>
					<ProfileDetails>
						<Box as="form">
							<Grid gap={0} className="vacation-details">
								<Flex>
									<Flex>
										<Text as="h1" fontSize={18} fontFamily="OpenSansBold">
											Vacation Details
											<Text as="span" className="requiredText">
												*Applicable from 00:00 hr (Midnight) to 00:00 hr (Midnight)
											</Text>
										</Text>
									</Flex>
								</Flex>
							</Grid>
							<Grid gap={0}>
								<Flex>
									<Flex>
										<Label>
											<Checkbox checked={vacationCheckbox} name="remember" handleChange={() => onChangeVacationDetails('check_box')} />
											Vacation Status
										</Label>
									</Flex>
								</Flex>
							</Grid>
							{vacationCheckbox == true ?
								<Grid gap={0}>
									<Flex>
										<Box className="datepicker-c">
											<FormGroup datePicker>
												<FormLabel>Date From</FormLabel>
												<DatePicker
													value={values.vacation?.from}
													selected={startDate}
													// onChange={(date: any) => setStartDate(date)}
													onChange={(e) =>
														onChangeValue(e, 'date_from')
													}
													dateFormat={helper.returnDateFormat()}
												/>
												<Icon.DateIconInput />
											</FormGroup>
											<span style={{ fontSize: 12, color: 'red' }}>{error.dateFromError}</span>
										</Box>
										<Box className="datepicker-c">
											<FormGroup datePicker>
												<FormLabel>Date To</FormLabel>
												<DatePicker
													value={values.vacation?.to}
													selected={startDate}
													onChange={(e) =>
														onChangeValue(e, 'date_to')
													}
													dateFormat={helper.returnDateFormat()}
												/>
												<Icon.DateIconInput />
											</FormGroup>
											<span style={{ fontSize: 12, color: 'red' }}>{error.dateToError}</span>
										</Box>
									</Flex>
									<Flex>
										<Flex className="full-width">
											<FormInput label="Message for Customers"
												value={values.vacation?.comment}
												onTextChange={(e) => onChangeValue(e, 'comment')}
												maxLength={250}
												type="text" />
										</Flex>
									</Flex>
								</Grid>
								: null
							}
						</Box>
					</ProfileDetails>

					{/* Footer Btn Block */}
					<FooterBtnBlock>
						<Stack direction="x" gap={3} justifyContent={['center', 'flex-end']}>
							<Button flex={[1, 1, 'initial']} onClick={() => onChangeVacationDetails('cancel')} variant="secondary">
								Cancel
							</Button>
						<Button
							disabled={error.dateFromError != '' ||
								error.dateToError != ''}
							onClick={() => submitData()}
							flex={[1, 1, 'initial']}>save</Button>
						</Stack>
					</FooterBtnBlock>
				</AddUserHolder>
			<SweetAlert
				show={showPopup}
				confirmBtnText="Confirm"
				onConfirm={onSubmit}
				showCancel={true}
				onCancel={closePopup}
				title={'Confirmation Box'}
			>
				{() => <form>{modalText}</form>}
			</SweetAlert>
		</MainContent>

			{/* Footer Session */ }
	<Footer></Footer>
		</>
	);
}

export default DcConfig;
