import styled from 'styled-components';
const StyledSuppliersReview = styled.div`
	float: left;
	width: 100%;
	margin-top: 40px;
	flex-direction: column;
	padding-top: 40px;
	border-top: 1px solid #dadada;
	.reviews-rating {
		border-top: 0;
		flex-direction: column;
	}
	@media (max-width: 860px) {
		margin-top: 30px;
		padding-top: 30px;
		width: calc(100% + 30px);
		padding: 30px 0 0;
		margin: 0 0 0 -15px;
		border: 0 !important;
	}
	.pr-button-label {
		display: flex;
	}
`;
const ViewAllBlock = styled.div`
	display: flex !important;
	justify-content: flex-end;
	padding: 0;
	color: ${({ theme }) => theme.colors.text.black};
	min-height: 30px;
	display: flex;
	align-items: center;
	.view-all {
		padding: 3px 10px;
	}
	h1,
	a {
		font-weight: normal;
		font-family: 'OpenSansMedium';
	}
	h1 {
		font-family: 'OpenSansBold';
		text-transform: uppercase;
	}
	a {
		color: ${({ theme }) => theme.baseColors.primary};
		text-decoration: none;
		transition: background 0.8s;
		transition: ease-in all 200ms;

		&:hover {
			background: ${({ theme }) => theme.baseColors.primary};
			padding: 3px 10px;
			color: ${({ theme }) => theme.baseColors.white};
			border-radius: 5px;
		}
	}
	@media (max-width: 860px) {
		padding: 0 15px;
	}
`;
const ViewAllHeadingBlock = styled.div`
	display: flex !important;
	justify-content: space-between;
	padding: 0;
	color: ${({ theme }) => theme.colors.text.black};
	min-height: 30px;
	display: flex;
	align-items: center;
	.view-all {
		padding: 3px 10px;
	}
	h1,
	a {
		font-weight: normal;
		font-family: 'OpenSansMedium';
	}
	h1 {
		font-family: 'OpenSansBold';
		text-transform: uppercase;
	}
	a {
		color: ${({ theme }) => theme.baseColors.primary};
		text-decoration: none;
		transition: background 0.8s;
		transition: ease-in all 200ms;

		&:hover {
			background: ${({ theme }) => theme.baseColors.primary};
			padding: 3px 10px;
			color: ${({ theme }) => theme.baseColors.white};
			border-radius: 5px;
		}
	}
	@media (max-width: 860px) {
		padding: 0 15px;
	}
`;
const SuppliersReviewDetails = styled.div`
	// padding: 30px 0 0;
	div {
		& + div {
			padding-top: 30px;
			@media (max-width: 860px) {
				padding-top: 0 !important;
			}
		}
	}
	@media (max-width: 860px) {
		padding: 0 0 0 0;

		/* div {
			max-height: max-content;
		} */
	}
	article {
		margin: 0 0 30px 0;
		padding: 0 0 30px 0;
		flex-direction: column;
		align-items: flex-start;
		&:last-child {
			margin-bottom: 0;
		}
		@media (max-width: 860px) {
			padding: 20px 15px 30px !important;
			margin-bottom: 0;
		}
		div {
			width: 100%;
			border: none;
		}
		h3 {
			margin-top: 8px;
		}
		hgroup {
			width: 100%;
			display: flex;
			justify-content: space-between;
			margin-top: 20px;
			@media (max-width: 640px) {
				flex-direction: column;
				justify-content: flex-start;
			}
			h4 {
				strong {
					color: ${({ theme }) => theme.colors.text.green1};
					margin-left: 5px;
					font-weight: normal;
					font-family: 'OpenSansMedium';
				}
			}
			h2 {
				word-break: break-word;
			}
			h5 {
				margin-top: 10px;
				font-weight: normal;
				strong {
					color: ${({ theme }) => theme.colors.text.black03};
					margin-left: 5px;
					font-weight: normal;
					font-family: 'OpenSansRegular';
					/* border-left: 1px solid ${({ theme }) => theme.colors.text.black03}; */
					margin-left: 0;
					padding-left: 0;
				}
			}
			.btnHolder {
				padding-top: 0 !important;
				width: auto !important;
				align-items: flex-start !important;
				@media (max-width: 640px) {
					margin-left: auto;
					margin-top: 10px;
					button {
						margin: 0 0 0 10px !important;
					}
				}
				button {
					background: transparent;
					padding: 3px 2px;
					border: none;
					display: flex;
					align-items: center;
					margin-left: 30px;
					outline: none;
					cursor: pointer;
					.pr-icon {
						svg {
							width: 20px !important;
							height: 20px !important;
						}
						path {
							fill: #1a1919;
						}
					}
					&.action-btn {
						border: 1px solid #7d376f;
						padding: 6px;
						& + .action-btn {
							margin-left: 18px;
						}
						.pr-icon {
							svg {
								width: 20px !important;
								height: 20px !important;
							}
							path {
								fill: #7d376f;
							}
						}
						&:hover,
						&:focus,
						&:active {
							background: ${({ theme }) => theme.baseColors.primary};
							svg {
								path {
									fill: #fff;
								}
							}
							h6 {
								color: #fff;
							}
						}
					}
					@media (max-width: 640px) {
						margin-left: 0;
						margin-right: 30px;
					}
					h6 {
						margin-left: 14px;
						line-height: 18px;
					}
					&:hover {
						svg {
							path {
								fill: #fff;
							}
						}
						h6 {
							color: #fff;
						}
					}
					&:active,
					&:focus {
						svg {
							path {
								fill: ${({ theme }) => theme.colors.text.green1};
							}
						}
						h6 {
							color: ${({ theme }) => theme.colors.text.green1};
						}
					}
					& + button {
						&:active,
						&:focus {
							svg {
								path {
									fill: ${({ theme }) => theme.colors.text.error};
								}
							}
							h6 {
								color: ${({ theme }) => theme.colors.text.error};
							}
						}
					}
				}
			}

			div + div {
				display: flex;
				align-items: flex-end;
				justify-content: flex-end;
				@media (max-width: 640px) {
					justify-content: flex-start;
					margin-top: 35px;
				}
			}
		}
	}
`;
const RatingBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 0 0 60px;
	max-width: 60px;
	height: 22px;
	background: linear-gradient(135deg, #fec163 0%, #dea513 100%);
	border-radius: 100px;
	font-size: 12px;
	line-height: 16px;
	font-family: 'OpenSansBold';
	color: ${({ theme }) => theme.baseColors.white};
	margin-right: 12px;
`;
const RatingBoxBad = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 0 0 60px;
	max-width: 60px;
	height: 22px;
	background: ${({ theme }) => theme.colors.text.error};
	border-radius: 100px;
	font-size: 12px;
	line-height: 16px;
	font-family: 'OpenSansBold';
	color: ${({ theme }) => theme.baseColors.white};
	margin-right: 12px;
`;
const AddCommentsHolder = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 0 0 100%;
	max-width: 100%;
	height: auto;
	background: #eee3d2;
	border-radius: 6px;
	font-size: 12px;
	line-height: 16px;
	font-family: 'OpenSansBold';
	color: ${({ theme }) => theme.baseColors.white};
	padding: 15px !important;
	padding-top: 15px !important;
	margin-top: 86px;

	input {
		height: 54px;
		border-radius: 6px;
		background: ${({ theme }) => theme.colors.bg.white};
		font-size: 17px;
		color: ${({ theme }) => theme.baseColors.black};
		font-family: 'OpenSansRegular';
		border: none;
		box-shadow: none;
		outline: none;
	}
	button {
		flex: 0 0 200px;
		max-width: 0 0 200px;
		cursor: pointer;
		height: 54px;
		max-height: 54px;
		margin-left: 15px;
		border-radius: 6px;
		font-size: 18px;
		line-height: 23px;
		font-family: 'OpenSansRegular';
	}
	@media (max-width: 767px) {
		flex-direction: column;
		margin-top: 43px;
		border-radius: 0;

		button {
			width: 100%;
			margin-left: 0;
			margin-top: 10px;
		}
	}
`;
export {
	StyledSuppliersReview,
	ViewAllHeadingBlock,
	SuppliersReviewDetails,
	RatingBox,
	RatingBoxBad,
	AddCommentsHolder,
	ViewAllBlock,
};
