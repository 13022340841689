import { UserBody } from 'admin/dashboardVenue/styles';
import Iget from 'models/Iget';
import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Text } from 'rebass/styled-components';
import { BreadcrumbNav, Button, Footer, Header, Loader, TableList } from 'ui/components';
import icons from 'ui/icons';
import { Modal, Sidebar } from 'ui/patterns';
import urls from 'utils/create.url';
import hooks from 'utils/requestCtx/reactQueryHooks';
import AddNewAttributeModal from './addNewAttribute';
import { BreadcrumbHolder, ButtonWrapper, MainContent } from './styles';
function UserRoleManagement(): ReactElement {
	let history = useHistory();
	const breadcrumbNav = [
		{ id: '01', label: 'Dashboard', route: 'seller-dashboard', active: false },
		{ id: '02', label: 'Attribute Management', active: true },
	];
	const [showAddNewAttributeModal, setShowAddNewAttributeModal] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const[attributeList,setAttributeList] = useState([] as any);
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const key = 'attributeList';
	const url =
		urls.GetSearchUrl({
			url: 'products/attributes?',
			fields: [],
			pageSize : 500,
			ctx: {avoidSort :true}
		});
	const obj: Partial<Iget> = {
		params: {},
		url: url,
		enabled: true,
		key: key,
		resultFilteringRequired: true,
	};

	var qry = hooks.useQuery(obj);
	useEffect(() => {
	if(qry.data){
		let data = [] as any;
		qry.data.map((x,i)=>{
			if(x.is_user_defined == true && (x.frontend_input == 'select' ||
			x.frontend_input == 'text') && (x.attribute_code != 'brand' &&
			x.attribute_code != 'allergens' && x.attribute_code != 'uom'))
			data.push(x);
		});
		setAttributeList([...data]);
	}
	}, [qry.data]);
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	}
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={()=>onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={()=>onclickHambrgBtn()}></Sidebar>

			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbHolder>
					<BreadcrumbNav Heading="Attribute Management" breadcrumbList={breadcrumbNav} />
					<ButtonWrapper>
						<Button flex={[1, 1, 'initial']} onClick={()=>{history.push('/admin/attribute')}}>
							<icons.PlusIcon />
							<div>Add new attribute</div>
						</Button>
					</ButtonWrapper>
				</BreadcrumbHolder>
				<TableList>
					<table>
						{!qry.isFetching && <thead>
							<tr>
								<th>Attribute Name</th>
								<th>Attribute Type</th>
								<th className="action">Action</th>
							</tr>
						</thead>}

						<tbody>
							{qry.isFetching && <Loader></Loader>}
						{attributeList && attributeList.length > 0 ?
								(attributeList.filter((x) => x.is_user_defined === true)).map(
									({
										default_frontend_label,
										attribute_code,
										frontend_input
									}, index) => (
							<tr key={index}>
								<td data-label="Attribute Name">
									<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
										{default_frontend_label}
									</Text>
								</td>
								<td data-label="Attribute Type">
									<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
									{frontend_input == 'text' ? 'Text' : 'Dropdown'}
									</Text>
								</td>
								<td className="action" data-label="Action">
									<Text>
										<Button variant="editIcon" 
										onClick={()=>{history.push(`/admin/attribute?code=${attribute_code}`)}}>
											<icons.textEditIcon />
										</Button>
									</Text>
								</td>
							</tr>
				)
				) :
				!qry.isFetching && (<UserBody>
					<Text fontSize={18} fontWeight="bold" color="#5C5E57" textAlign="center">
						No attributes Found
					</Text>
				</UserBody>)}
						</tbody>
					</table>
				</TableList>
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
			<Modal
				width="450px"
				showModal={showAddNewAttributeModal}
				setShowModal={setShowAddNewAttributeModal}
			>
				<AddNewAttributeModal cancel={() => setShowModal(false)} />
			</Modal>
		</>
	);
}

export default UserRoleManagement;
