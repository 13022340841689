import React, { ReactElement, useEffect, useState } from 'react';
import { Text, Box, Button, Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import { Rating } from 'ui/components';
import Countdown from 'react-countdown';
import helperFunctions from 'utils/helper.functions';
import pageConstants from 'utils/pageConstants';
import helper from 'utils/helper.functions';
import icons from 'ui/icons';
import { Tooltip } from 'react-tippy';
import Ipost from 'models/Ipost';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import Iget from 'models/Iget';

import 'react-tippy/dist/tippy.css';
import { userTypes } from 'models/enum-constants/common.constants';
import constants from 'utils/constants';
import { getHSL } from '../../../ui/utils/getHSL';
import moment from 'moment';

const StyledSupplierMainHeading = styled.div`
	width: 100%;
	@media (max-width: 860px) {
		display: flex;
		justify-content: center;
		div {
			display: flex;
			justify-content: center;
			flex-direction: column;
			div {
				flex-direction: row;
			}
		}
	}
	.addToWatchList {
		margin-left: 15px;
		display: flex;
		align-items: center;
		margin-top: -5px;
		.Favorites {
			border: solid 1px #b4b4b4;
			padding: 0 !important;
			width: 44px;
			max-width: 44px;
			flex: 0 0 44px;
			height: 44px;
			display: flex;
			justify-content: center;
			background: transparent;
			align-items: center;
			&.active {
				background: ${({ theme }) => theme.baseColors.primary};
				svg {
					path {
						fill: ${({ theme }) => theme.baseColors.white};
					}
				}
			}
		}
	}
	h1 {
		line-height: normal;
		margin-bottom: 12px;
		display: flex;
		align-items: center;
	}
	h2 {
		margin-top: 35px;
		width: 100%;
	}
	h3 {
		margin-top: 21px;
		width: 100%;
		br {
			display: none;
			+ strong {
				font-family: 'OpenSansBold';
				color: ${({ theme }) => theme.colors.text.black};
			}
		}
		strong {
			font-family: 'OpenSansBold';
			color: ${({ theme }) => theme.colors.text.error};
		}
	}
	@media (max-width: 860px) {
		text-align: center;
		h1 {
			font-size: 26px;
		}
		h2,
		h3,
		h4 {
			font-size: 17px;
		}
		h2 {
			strong {
				font-size: 17px;
				margin-top: 7px;
			}
		}
		h3 {
			margin-top: 21px;
		}
		h4 {
			margin-top: 15px;
			br {
				display: block;
			}
		}
	}
`;
const RatingHolder = styled.div`
	width: 100%;
	label {
		pointer-events: none;
	}
	@media (max-width: 860px) {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 10px 0 -10px;
		div {
			margin: 0 !important;
			div {
				width: 175px;
				justify-content: flex-start;
			}
		}
		h2 {
			width: auto;
		}
	}
	.review-count {
		display: inline;
		float: none;
		line-height: 22px;
		white-space: nowrap;
		@media (max-width: 860px) {
			margin: 0 0 0 10px;
		}
	}
	.rating {
		@media (max-width: 860px) {
			float: none;
		}
	}
`;

function SupplierListHeading(props): ReactElement {	
	const [status, updateStatus] = useState(false);
	let deliveryMonth: any;
	let deliveryDate = props?.processTime?.next_avaliable_time;
	if (deliveryDate && deliveryDate !== '') {
		deliveryMonth = helper.formatDate(moment(deliveryDate, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD hh:mm:ss"));
	}
	const getCountDown = (order_process_time) => {
		//count down timer functionality
		if (order_process_time && order_process_time !== undefined && order_process_time?.next_avaliable_time !== "") {
			const countDownDate = moment(order_process_time?.next_avaliable_time, "DD-MM-YYYY HH:mm:ss").valueOf();
			const now = moment(order_process_time?.current_time, "DD-MM-YYYY HH:mm:ss").valueOf();
			const distance = countDownDate - now;
			return distance;
		} else {
			return 0;
		}
	};
	const watchSupplier = async () => {
		const msg = status ? 'Supplier removed from watch list' : 'Supplier added to watch list';
		const obj: Partial<Ipost> = {
			data: {
				supplierWatch: {
					venue_user_id: localStorage.getItem('userId'),
					venue_id: localStorage.getItem('selectedVenue'),
					supplier_id: props.supplierId,
					status: !status,
				},
			},
			params: {},
			url: constants.supplierWatch(),
			message: msg,
			headers: { Authorization: localStorage.getItem('customerToken') },
		};
		try {
			await api.post(obj).then(function (data) {
				updateStatus(!status);
			});
		} catch (e) { }
	};
	let userId = localStorage.getItem('userId');
	let user_Type = localStorage.getItem('userType');
	let venue = localStorage.getItem('selectedVenue');
	let location = localStorage.getItem('selectedLocation');
	let loc =
		user_Type == userTypes.venueAdmin || user_Type == userTypes.venueUser ? venue : location;
	const obj: Partial<Iget> = {
		params: {},
		url: constants.supplierCheckWatch() + loc + '/' + userId + '/' + props.supplierId,
		enabled: false,
		resultFilteringRequired: true,
		headers: { Authorization: localStorage.getItem('customerToken') },
	};
	const qry = hooks.useQuery(obj);
	useEffect(() => {
		props.supplierId &&
			qry.refetch().then(function (data) {
				if (data) {
					updateStatus(data);
				}
			});
	}, [props.supplierId]);
	return (
		<StyledSupplierMainHeading>
			{/* {recentOrderList.map((obj) => ( */}
			<Box m="0" key={helperFunctions.getRandomInt(pageConstants.maxValue)}>
				<Flex>
					<Text
						as="h1"
						fontSize="36px"
						fontFamily="OpenSansBold"
						color="text.black"
						className="truncate"
					>
						{props?.supplierInfo?.name}
					</Text>
					<div className="addToWatchList">
						<Tooltip
							arrow
							title={`${status ? 'Remove From WatchList' : 'Add To WatchList'}`}
							animation="perspective"
							position="top"
							trigger="mouseenter"
						>
							{/* Add "active" class for button active state */}
							<Button
								variant="outline"
								className={`Favorites ${status ? 'active' : ''}`}
								onClick={watchSupplier}
							>
								{status ? <icons.watchListIconActive /> : <icons.watchListIcon />}
							</Button>
						</Tooltip>
					</div>
				</Flex>

				<RatingHolder>
					<Rating value={props?.currentUserReview?.overall_ratings}></Rating>
					<Text as="h2" fontSize="20px" fontFamily="OpenSansRegular" className="review-count">
						{props?.currentUserReview?.total_reviews} Reviews
					</Text>
				</RatingHolder>

				<Text as="h2" fontSize="20px" fontFamily="OpenSansRegular" color="text.black03">
					{props?.supplierInfo?.SupplierAddress}
				</Text>
				{deliveryMonth &&
					<Text as="h3" fontSize="20px" fontFamily="OpenSansRegular" color="text.black03">
						Order in{' '}
						<strong>
							<Countdown
								date={Date.now() + getCountDown(props?.processTime)}
							/>
						</strong>
						<br></br> to get it delivered on{' '}
						<strong>{deliveryMonth}</strong>
					</Text>}
			</Box>
			{/* ))} */}
		</StyledSupplierMainHeading>
	);
}

export { SupplierListHeading };
