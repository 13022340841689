
import React from 'react';
const defaultGlobalState = {
    cartTotal : '',
    cartItemCount : '0'
    };
const globalStateContext = React.createContext(defaultGlobalState);
const dispatchStateContext =  React.createContext<any>(undefined);
          const GlobalStateProvider = ({ children }) => {
            const [state, dispatch] = React.useReducer(
              (state, newValue) => ({ ...state, ...newValue }),
              defaultGlobalState
            );
            return (
              <globalStateContext.Provider value={state}>
                <dispatchStateContext.Provider value={dispatch}>
                  {children}
                </dispatchStateContext.Provider>
              </globalStateContext.Provider>
            );
          };
export {globalStateContext,dispatchStateContext,GlobalStateProvider}
