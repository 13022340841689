import { parseToHsl } from 'polished';

function getHSL(type: string, color: string): string | undefined {
	const { hue, saturation, lightness } = parseToHsl(color);
	const h = `${Math.round(hue)}`;
	const s = `${Math.round(saturation * 100)}%`;
	const l = `${Math.round(lightness * 100)}%`;
	if (type === 'h' || type === 'hue') return h;
	if (type === 's' || type === 'saturation') return s;
	if (type === 'l' || type === 'lightness') return l;
	if (type === 'hsl') return `${h}, ${s}, ${l}`;
}

export { getHSL };
