import { venueUserPermissionList } from 'models/enum-constants/common.constants';
import Iget from 'models/Iget';
import queryString from 'query-string';
import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useHistory, useLocation } from 'react-router-dom';
import { Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { BreadcrumbNav, Button, Footer, Header } from 'ui/components';
import icons from 'ui/icons';
import { FavouriteOrderDetailsEditView, Modal, Sidebar } from 'ui/patterns';
import { FavoriteOrdersDetailsListView } from 'ui/patterns/favoriteOrderDetailsListView';
import env from 'utils/constants';
import urls from 'utils/create.url';
import { default as helper, default as helperFunctions } from 'utils/helper.functions';
import http from 'utils/http.service';
import pageConstants from 'utils/pageConstants';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import utilityFunctions from 'utils/utility.functions';
import SetScheduleModal from './setSchedule';

const MainContent = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
	padding: 0 50px;
	@media (max-width: 960px) {
		padding: 0 15px;
	}
`;
const OrderDetailsBlock = styled.div`
	margin-bottom: 50px;
	h4 {
		margin: 40px 0 10px;
	}
	h5 {
		margin-bottom: 10px;
	}
	header {
		justify-content: flex-end;
		align-items: center;
		margin-top: 30px;
		h4 {
			margin-top: 0;
			margin-bottom: 0;
		}
		button {
			height: 50px;
			text-transform: capitalize;
			margin-left: 10px;
			span {
				display: flex;
				white-space: nowrap;
				align-items: center;
			}
			svg {
				margin-right: 10px;
				path {
					fill: ${({ theme }) => theme.baseColors.primary};
				}
			}
			&:hover,
			&:focus {
				svg {
					path {
						fill: ${({ theme }) => theme.baseColors.white};
					}
				}
			}
		}
	}
`;
function FavouriteOrderDetails(): ReactElement {
	const [showSetScheduleModal, setShowSetScheduleModal] = useState(false);
	const toggleSetScheduleModal = () => {
		setShowSetScheduleModal(!showSetScheduleModal);
	};
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const [showModal, setShowModal] = useState(false);
	let location = useLocation();
	const [product, setProduct] = useState([] as any);
	const [hasData, setHasData] = useState(false);
	const parsed = queryString.parse(location.search);
	const [productsBySuppliers, setSupplierGroup] = useState([] as any);
	const [isEmailNavigate, setIsEmailNavigate] = useState(false);
	const [venueUserId] = useState<any>(localStorage.getItem('userId'));
	const [cartId] = useState<any>(localStorage.getItem('cartId'));
	const [scheduledDetails, setScheduledDetails] = useState([]);
	const [count, setProductCount] = useState(0);
	const [productAlreadyScheduled, setProductAlreadyScheduled] = useState(false);

	const history = useHistory();
	const breadcrumbNav = [
		{ id: '01', route: '/home/dashboard', label: 'Home', active: false },
		{ id: '02', route: '/store/orders?tab=favourite-orders', label: 'Favourites', active: false },
		{ id: '03', label: getName(), active: true },
	];
	const url = urls.GetSearchUrl({
		url: `/experion/scheduleorder/search?`,
		pageSize: 2000,
		fields: [
			{
				field_name: 'fav_id',
				field_value: parsed.id,
			},
			{
				field_name: 'venue_user_id',
				field_value: venueUserId,
			},
		],
	});
	const obj: Partial<Iget> = {
		params: {},
		url: url,
		enabled: false, //manual fetch
		key: 'scheduleOrders',
	};
	var qry = hooks.useQuery(obj);

	function getName() {
		return product?.favorite_name;
	}

	useEffect(() => {
		if (parsed.venue_id !== undefined || parsed.scheduled_mail !== undefined) {
			setIsEmailNavigate(true);
		}
		if (parsed.id) {
			http.GET({ url: `experion/productmanagement/getfavoriteorder/${parsed.id}` }).then(function (d) {
				setProduct(d.data);
				if (d.data.is_schedule === true) {
					setProductAlreadyScheduled(true);
				} else {
					setProductAlreadyScheduled(false);
				}
				if (d.data?.favorite_items?.length > 0) {
					let datas = d.data?.favorite_items;
					setProductCount(datas.length);
					let groupBySupplierName = helper.GroupBy(datas, (x) => x.supplier_name);
					setSupplierGroup(groupBySupplierName);
				}
				setHasData(true);
			});
		}
	}, [location]);

	async function approveSchedule() {
		let dataObj = { product_id: null, qty: null };
		let pdtArray = [] as any;
		product?.favorite_items.forEach((item) => {
			dataObj.product_id = item.product_id;
			dataObj.qty = item.qty;
			pdtArray.push(dataObj);
		});
		let id: any;
		let CUSTOMER_TOKEN = localStorage.getItem('customerToken');
		id = await utilityFunctions.getCartId(CUSTOMER_TOKEN);
		const payload = {
			products: {
				quote_id: parseInt(id),
				items: pdtArray,
				venue_user_id: parseInt(parsed?.venue_user_id),
				user_id: parseInt(venueUserId),
				scheduled_id: parseInt(parsed?.scheduled_mail),
				time: parsed.time,
			},
		};
		api
			.post({
				data: payload,
				url: env.multiCartURL(),
				key: 'movedToCart',
				message: 'Available products will be moved to cart!',
			})
			.then(function (data) {
				if (data == true) {
					history.push('/store/orders?tab=favourite-orders');
				}
				setShowModal(!showModal);
			});
	}
	async function togglePopup() {
		await setShowSetScheduleModal(!showSetScheduleModal);
	}

	const editScheduledOrder = () => {
		qry.refetch().then(function (data) {
			if (data) {
				setScheduledDetails(data);
				toggleSetScheduleModal();
			}
		});
	};
	const rejectOrder = () => {
		api
			.post({
				url: '/scheduleorder/decline',
				data: {
					venueUserId: parsed?.venue_user_id,
					favId: parsed?.id,
					emailRowId: parsed?.scheduled_mail,
				},
				message: 'Order Rejected',
			})
			.then(function () {
				history.push('/store/orders?tab=favourite-orders');
			});
	};

	const refetchProducts = () => {
		if (parsed.venue_id !== undefined || parsed.scheduled_mail !== undefined) {
			setIsEmailNavigate(true);
		}
		if (parsed.id) {
			http.GET({ url: `experion/productmanagement/getfavoriteorder/${parsed.id}` }).then(function (d) {
				setProduct(d.data);
				if (d.data.is_schedule === true) {
					setProductAlreadyScheduled(true);
				} else {
					setProductAlreadyScheduled(false);
				}
				if (d.data?.favorite_items?.length > 0) {
					let datas = d.data?.favorite_items;
					setProductCount(datas.length);
					let groupBySupplierName = helper.GroupBy(datas, (x) => x.supplier_name);
					setSupplierGroup(groupBySupplierName);
				}
				setHasData(true);
			});
		}
	};
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	};
	return (
		<Fragment>
			{/* Main Header */}
			<Header toggleSideBar={() => onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={() => onclickHambrgBtn()}></Sidebar>

			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbNav Heading="Favourites" breadcrumbList={breadcrumbNav} />
				{(hasData && (
					<OrderDetailsBlock>
						<FavouriteOrderDetailsEditView
							setProduct={setProduct}
							product={product}
						></FavouriteOrderDetailsEditView>
						{utilityFunctions.isGranted(venueUserPermissionList.ScheduleOrderfromFavoriteOrder) &&
						!isEmailNavigate ? (
							<Flex as="header">
								<Button
									variant="secondary"
									onClick={
										product?.is_schedule === true ? editScheduledOrder : toggleSetScheduleModal
									}
								>
									<icons.calendar />
									<div>Set Schedule</div>
								</Button>
							</Flex>
						) : (
							<Flex as="header">
								<Button variant="primary" onClick={approveSchedule}>
									<icons.Tick />
									<div>Approve</div>
								</Button>
								<Button variant="redOutline" onClick={rejectOrder}>
									<icons.CloseIcon />
									<div>Reject</div>
								</Button>
							</Flex>
						)}
						{productsBySuppliers &&
							productsBySuppliers.map((data) => {
								return (
									<Fragment key={helperFunctions.getRandomInt(pageConstants.maxValue)}>
										<Text as="h5" mt={5} fontSize="20px" lineHeight="26px" color="text.black">
											{data.key} <strong>{data.value?.length}</strong>
										</Text>
										<FavoriteOrdersDetailsListView
											favoriteOrderDetailsListView={data.value}
										></FavoriteOrdersDetailsListView>
									</Fragment>
								);
							})}
					</OrderDetailsBlock>
				)) ||
					new Array(10).fill(null).map((_, i) => {
						return <Skeleton key={i} width="1400px" height="150px" />;
					}) ||
					new Array(10).fill(null).map((_, i) => {
						return <Skeleton key={i} width="1400px" height="50px" />;
					})}
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>

			<Modal width="500px" showModal={showSetScheduleModal} setShowModal={setShowSetScheduleModal}>
				<SetScheduleModal
					refetchProducts={refetchProducts}
					is_schedule={productAlreadyScheduled}
					scheduledDetails={scheduledDetails}
					togglePopup={togglePopup}
				/>
			</Modal>
		</Fragment>
	);
}

export default FavouriteOrderDetails;
