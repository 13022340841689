import { Select } from '@rebass/forms';
import Iget from 'models/Iget';
import IgetEx from 'models/IgetEx';
import Ipost from 'models/Ipost';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import Autocomplete from 'react-autocomplete';
import SweetAlert from 'react-bootstrap-sweetalert';
import DateTimePicker from 'react-multi-date-picker';
import { Box, Text } from 'rebass/styled-components';
import { BreadcrumbNav, Button, Footer, Grid, Header, Loader, Stack } from 'ui/components';
import * as Icon from 'ui/icons';
import { FormInput, FormTextarea, Sidebar } from 'ui/patterns';
import constants from 'utils/constants';
import helperFunctions from 'utils/helper.functions';
import pageConstants from 'utils/pageConstants';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import {
	AddUserHolder,

	AssignVenueHolder,
	AutocompleteHolder, FooterBtnBlock, MainContent,


	ProfileDetails
} from './styles';

function SupplierCompanyProfileManagement(): ReactElement {
	var supplierId = localStorage.getItem('supplierId');
	const apiKey = 'supplierDet';
	const [dateTimeValue, setDateTimeValue] = useState(new Date());
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const [disableBtnSave, setDisableBtnSave] = useState(true);
	const [modalText, setmodalText] = useState('');
	const [showPopup, setShowModal] = useState(false);
	const [action, setAction] = useState('');

	const [supplierDetails, updateSupplierDetails] = useState({
		supplycompany_id: supplierId,
		company_name: '',
		company_legal_name: '',
		company_email: '',
		vat_id: '',
		reseller_id: '',
		comment: '',
		street_address: '',
		zip_code: '',
		city: '',
		state: '',
		country: '',
		phone_no: '',
		website_url: '',
		mov: '',
		countdown_timer: ''
	});

	const [error, setError] = useState({
		company_nameError: '',
		company_legal_nameError: '',
		company_emailError: '',
		vat_idError: '',
		reseller_idError: '',
		commentError: '',
		street_addressError: '',
		zip_codeError: '',
		cityError: '',
		stateError: '',
		countryError: '',
		phone_noError: '',
		website_urlError: '',
		movError: '',
	});

	const breadcrumbNav = [
		{ id: '01', label: 'Dashboard', route: 'seller-dashboard', active: false },
		{ id: '02', label: 'Seller Profile Management', active: true },
	];

	const url = 'directory/countries';
	const obj: Partial<Iget> = {
		params: {
			pages: '1',
		},
		url: url,
		enabled: true,
		key: 'countryDD',
	};
	const country = hooks.useQuery(obj);
	const countries = country.data;

	const onChangeValue = (val, name) => {
		const item = supplierDetails;
		switch (name) {
			case 'company_legal_name': {
				if (!val) {
					//error.company_legal_nameError = 'Company Legal Name cannot be blank';
				} else {
					error.company_legal_nameError = '';
				}
				setError(error);
				item.company_legal_name = val;
				break;
			}
			case 'company_name': {
				if (!val) {
					error.company_nameError = 'Company Name cannot be blank';
				} else {
					error.company_nameError = '';
				}
				setError(error);
				item.company_name = val;
				break;
			}
			case 'company_email': {
				if (!val) {
					setError({ ...error, company_emailError: 'Email Address cannot be blank' });
				} else {
					const validEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(val);
					if (validEmail) {
						error.company_emailError = '';
					} else {
						error.company_emailError = 'Please enter Valid Email Address';
					}
				}
				setError(error);
				item.company_email = val;
				break;
			}
			case 'vat_id': {
				if (!val) {
					//error.vat_idError = 'VAT ID cannot be blank';
				} else {
					error.vat_idError = '';
				}
				setError(error);
				item.vat_id = val;
				break;
			}
			case 'reseller_id': {
				if (!val) {
					//error.reseller_idError = 'Reseller ID cannot be blank';
				} else {
					error.reseller_idError = '';
				}
				setError(error);
				item.reseller_id = val;
				break;
			}
			case 'mov': {
				if (!val) {
					error.movError = 'MOV cannot be blank';
				} else {
					error.movError = '';
				}
				setError(error);
				item.mov = val;
				break;
			}
			case 'comment': {
				if (!val) {
					//error.commentError = 'Reseller ID cannot be blank';
				} else {
					error.commentError = '';
				}
				setError(error);
				item.comment = val;
				break;
			}
			case 'street_address': {
				if (!val) {
					error.street_addressError = 'Street Address cannot be blank';
				} else {
					error.street_addressError = '';
				}
				setError(error);
				item.street_address = val;
				break;
			}
			case 'city': {
				if (!val) {
					error.cityError = 'City cannot be blank';
				} else {
					error.cityError = '';
				}
				setError(error);
				item.city = val;
				break;
			}
			case 'state': {
				if (!val) {
					error.stateError = 'State/Provice cannot be blank';
				} else {
					error.stateError = '';
				}
				setError(error);
				item.state = val;
				break;
			}
			case 'zip_code': {
				if (!val) {
					error.zip_codeError = 'Zipcode cannot be blank';
				} else {
					error.zip_codeError = '';
				}
				setError(error);
				item.zip_code = val;
				break;
			}
			case 'website_url': {
				if (!val) {
					error.website_urlError = 'Zipcode cannot be blank';
				} else {
					error.website_urlError = '';
				}
				setError(error);
				item.website_url = val;
				break;
			}
			case 'country': {
				if (val.target.value == '-Select-') {
					error.countryError = 'Country cannot be blank';
				} else {
					error.countryError = '';
				}
				setError(error);
				item.country = val.target.value;
				break;
			}
			case 'phone_no': {
				if (!val) {
					//error.phone_noError = 'Phone cannot be blank';
				} else {
					const regex = pageConstants.phoneRegex;
					if (!regex.test(val)) {
						error.phone_noError = 'Invalid Phone Number';
					} else {
						error.phone_noError = '';
					}
				}
				setError(error);
				item.phone_no = val; //parseInt(val);
				break;
			}
			case 'timer': {
				if (!val) {
				} else {
					//error.website_urlError = '';
				}
				setError(error);
				item.countdown_timer = val;
				break;
			}
			default:
				break;
		}

		updateSupplierDetails({
			...item,
		});
		if (
			supplierDetails &&
			supplierDetails.company_email &&
			supplierDetails.street_address &&
			supplierDetails.zip_code &&
			supplierDetails.city &&
			supplierDetails.state &&
			supplierDetails.country != '-Select-' &&
			error &&
			!error.company_legal_nameError &&
			!error.company_nameError &&
			!error.company_emailError &&
			!error.vat_idError &&
			!error.reseller_idError &&
			!error.commentError &&
			!error.street_addressError &&
			!error.zip_codeError &&
			!error.cityError &&
			!error.stateError &&
			!error.countryError &&
			!error.phone_noError &&
			!error.movError &&
			!error.website_urlError
		) {
			setDisableBtnSave(false);
		} else {
			setDisableBtnSave(true);
		}
	};

	const params = {
		pages: '1',
	};

	const dataObj: Partial<Iget> = {
		params: params,
		url: `experion/supplycompanyaccount/getsupplycompany/${supplierId}`,
		enabled: true,
		key: apiKey,
	};

	var qryData = hooks.useQuery(dataObj);

	useEffect(() => {
		if (qryData.data && qryData.data.countdown_timer) {
			setDateTimeValue(new Date(qryData.data.countdown_timer));

		}
		qryData.data && updateSupplierDetails(qryData.data);
		qryData.data && setAutoAddress(qryData.data.street_address);
		qryData.data && setDisableBtnSave(false);
	}, [qryData.data]);

	const resetData = () => {
		// qryData.refetch().then(function (data) {
		// 	if (data) {
		// 		updateSupplierDetails(data.data);
		// 	}
		// });
		window.location.href = window.location.href.replace("#!", "");
	};

	const submitData = (action) => {
		switch (action) {
			case 'save':
				showConfirmationModal(action, 'Save the changes?');
				break;
			case 'clear':
				if (
					supplierDetails.company_legal_name != '' ||
					supplierDetails.company_name != '' ||
					supplierDetails.company_email != '' ||
					supplierDetails.vat_id != '' ||
					supplierDetails.reseller_id != '' ||
					supplierDetails.comment != '' ||
					supplierDetails.street_address != '' ||
					supplierDetails.zip_code != '' ||
					supplierDetails.city != '' ||
					supplierDetails.state != '' ||
					supplierDetails.phone_no != '' ||
					supplierDetails.website_url != '' ||
					supplierDetails.mov != '' ||
					supplierDetails.countdown_timer != '' ||
					supplierDetails.country != ('-Select-' && 'select')

				) {
					showConfirmationModal(action, 'Discard the changes?');
				}
				break;
			default:
				break;
		}
	};

	function showConfirmationModal(action, text) {
		setAction(action);
		setmodalText(text);
		setShowModal(!showPopup);
	}

	async function onSubmit() {
		if (action === 'save') {
			saveData();
		} else if (action === 'clear') {
			clearDetails('clear');
		}
		setShowModal(!showPopup);
	}

	async function closePopup() {
		await setShowModal(!showPopup);
	}

	async function saveData() {
		if (supplierDetails) {
			var payload = {
				supplycompany_id: supplierId,
				company_name: supplierDetails.company_name,
				website_url: supplierDetails.website_url,
				company_email: supplierDetails.company_email,
				company_legal_name: supplierDetails.company_legal_name,
				vat_id: supplierDetails.vat_id,
				reseller_id: supplierDetails.reseller_id,
				comment: supplierDetails.comment,
				street_address: autoAddress, //supplierDetails.street_address,
				city: supplierDetails.city,
				state: supplierDetails.state,
				country: supplierDetails.country,
				zip_code: supplierDetails.zip_code,
				phone_no: helperFunctions.formatPhone(supplierDetails.phone_no),
				mov: supplierDetails.mov && parseInt(supplierDetails.mov + ''),
				countdown_timer: getDateTimeValue(supplierDetails.countdown_timer),
			};
			const obj: Partial<Ipost> = {
				data: { supplyCompany: payload },
				params: {},
				url: `experion/supplycompanyaccount/updatesupplycompany/${supplierId}`,
				message: 'Seller details updated successfully',
			};
			try {
				api.put(obj).then((data) => {
					if (data !== undefined) {
						setDateTimeValue(new Date(data.countdown_timer))
					}
					// if (data !== undefined) {
					// 	history.push('/home/dashboard');
					// }
				});
			} catch (e) { }
			setShowModal(!showPopup);
		}
	}
	const getDateTimeValue = (timer) => {
		var date = moment().format("YYYY-MM-DD");
		let time = dateTimeValue;
		var hours = time.getHours();
		var minutes = time.getMinutes();
		var seconds = time.getSeconds();
		var sHours = hours.toString();
		var sMinutes = minutes.toString();
		var sSeconds = seconds.toString();
		if (hours < 10) sHours = "0" + sHours;
		if (minutes < 10) sMinutes = "0" + sMinutes;
		if (seconds < 10) sSeconds = "0" + sSeconds;
		return (date + " " + sHours + ":" + sMinutes + ":" + sSeconds)
	}
	const clearDetails = (param) => {
		if (param === 'clear') {
			updateSupplierDetails({
				supplycompany_id: supplierId,
				company_legal_name: '',
				company_name: '',
				company_email: '',
				vat_id: '',
				reseller_id: '',
				comment: '',
				street_address: '',
				zip_code: '',
				city: '',
				state: '',
				country: 'select',
				phone_no: '',
				website_url: '',
				mov: '0',
				countdown_timer: ''
			});
			setError({
				company_legal_nameError: '',
				company_nameError: '',
				company_emailError: '',
				vat_idError: '',
				reseller_idError: '',
				commentError: '',
				street_addressError: '',
				zip_codeError: '',
				cityError: '',
				stateError: '',
				countryError: '',
				phone_noError: '',
				website_urlError: '',
				movError: '',
			});
		}
	};

	//Auto complete for Address Begins
	const [autoAddress, setAutoAddress] = useState('');
	const [autoAddress404, setAutoAddress404] = useState(false);
	var [autoAddressList, setAutoAddressList] = useState<any>([]);
	const addInfo = 'autoAddress';
	const addDetails = 'autoDetails';
	const acHeaders = {
		'x-api-key': constants.placesAPIKey(),
		'x-client-id': constants.placesClientId(),
	};
	const autoparams = {
		count: '10',
		country: pageConstants.defaultCountry,
	};
	const addobj: Partial<IgetEx> = {
		headers: acHeaders,
		url: constants.placesAPIURL() + `key=`,
		key: addInfo,
		params: autoparams,
		enabled: false,
	};
	const addQry = hooks.useQuery(addobj);

	const addRobj: Partial<IgetEx> = {
		headers: acHeaders,
		url: constants.placesAPIURL() + `id=`,
		key: addDetails,
		params: autoparams,
		enabled: false,
	};

	useEffect(() => {
		addQry.data && setAutoAddressList([...addQry.data]);
	}, [addQry.data]);

	useEffect(() => {
		setAutoAddress404(false);
		if (supplierDetails.street_address != autoAddress) {
			setDateTimeValue(new Date(supplierDetails.countdown_timer))
			updateSupplierDetails({
				supplycompany_id: supplierDetails.supplycompany_id,
				company_legal_name: supplierDetails.company_legal_name,
				company_name: supplierDetails.company_name,
				company_email: supplierDetails.company_email,
				vat_id: supplierDetails.vat_id,
				reseller_id: supplierDetails.reseller_id,
				comment: supplierDetails.comment,
				street_address: autoAddress,
				zip_code: '',
				city: '',
				state: '',
				country: '',
				phone_no: supplierDetails.phone_no,
				website_url: supplierDetails.website_url,
				mov: supplierDetails.mov,
				countdown_timer: supplierDetails.countdown_timer
			});
		}

		autoAddressList = [];
		setAutoAddressList(autoAddressList);
		getAddressSuggestion(autoAddress);
	}, [autoAddress]);

	const addressOnSelect = function (item?) {
		let addressId = 0;
		if (autoAddressList.length > 0 && autoAddress.length > 4) {
			autoAddressList &&
				autoAddressList
					.filter((x) => x.FullAddress == item)
					.map((option) => (addressId = option.Id));

			if (addressId != 0) {
				addRobj.url = constants.placesAPIURL() + `id=${addressId}`;
				api.getEx(addRobj).then(function (res) {
					setDisableBtnSave(false);
					updateSupplierDetails({
						supplycompany_id: supplierDetails.supplycompany_id,
						company_legal_name: supplierDetails.company_legal_name,
						company_name: supplierDetails.company_name,
						company_email: supplierDetails.company_email,
						vat_id: supplierDetails.vat_id,
						reseller_id: supplierDetails.reseller_id,
						comment: supplierDetails.comment,
						street_address: supplierDetails.street_address,
						zip_code: res.Postcode,
						city: res.City,
						state: res.Suburb,
						country: res.Country,
						phone_no: supplierDetails.phone_no,
						website_url: supplierDetails.website_url,
						mov: supplierDetails.mov,
						countdown_timer: supplierDetails.countdown_timer
					});
					setDateTimeValue(new Date(supplierDetails.countdown_timer));
				});
			}
		}
	};

	// const addressSuggestion = function (item?) {
	// 	// return [{ label: 'apple' },{ label: 'banana' },{ label: 'pear' }];
	// };

	const getAddressSuggestion = async function (value?) {
		if (autoAddress != '' && autoAddress.length > 4) {
			addobj.url = constants.placesAPIURL() + `key=${autoAddress}`;
			await api.getEx(addobj).then(function (res) {
				if (res == undefined) {
					setAutoAddress404(true);
				} else {
					setAutoAddress404(false);
				}
				res && setAutoAddressList([...res]);
			});
		} else {
			setAutoAddressList([]);
		}
	};
	const setProcessingTime = (timeObj) => {
		const time = new Date(timeObj);
		setDateTimeValue(time);
	}
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	}
	//Auto complete for Address Ends
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={() => onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={() => onclickHambrgBtn()}></Sidebar>
			{supplierDetails && supplierDetails.company_email == '' ? <Loader></Loader> : null}
			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbNav
					Heading="Seller Profile Management"
					breadcrumbList={breadcrumbNav}
				/>
				{/* Add User Details */}

				<AddUserHolder>
					<ProfileDetails>
						<Text as="h1" fontSize={18} fontFamily="OpenSansBold" color="text.SubHeading">
							Seller information
						</Text>
						<Box as="form">
							<Grid gap={25}>
								<FormInput
									autoComplete="off"
									label="Seller Legal Name"
									validationMessage={error.company_legal_nameError}
									validation={error.company_legal_nameError ? 'error' : undefined}
									value={supplierDetails && supplierDetails.company_legal_name}
									required={false}
									onTextChange={(e) => onChangeValue(e, 'company_legal_name')}
									maxLength={50}
									type="text"
								/>
								<FormInput
									label="Seller Email"
									autoComplete="off"
									validationMessage={error.company_emailError}
									validation={error.company_emailError ? 'error' : undefined}
									value={supplierDetails && supplierDetails.company_email}
									required={true}
									onTextChange={(e) => onChangeValue(e, 'company_email')}
									maxLength={100}
									type="text"
								/>
								<FormInput
									maxLength={12}
									label="Phone Number"
									disabled={true}
									autoComplete="off"
									validationMessage={error.phone_noError}
									validation={error.phone_noError ? 'error' : undefined}
									value={supplierDetails && supplierDetails.phone_no}
									required={false}
									onTextChange={(e) => onChangeValue(e, 'phone_no')}
									type="tel"
								/>
								<FormInput
									label="MOV"
									maxLength={20}
									autoComplete="off"
									validationMessage={error.movError}
									validation={error.movError ? 'error' : undefined}
									value={supplierDetails && supplierDetails.mov}
									required={false}
									onTextChange={(e) => onChangeValue(e, 'mov')}
									type="number"
								/>

								<div style={{ paddingBottom: '20px' }}>
									<label>
										Processing Time <sup style={{ fontSize: 16, color: 'red' }}>*</sup>
									</label>
									{/* <div>
										<DateTimePicker
											className="DateTimePicker"
											format="hh:mm:ss A"
											timePicker
											onlyTimePicker
											value={dateTimeValue}
											animation
											onChange={(dateObject) => {
												setProcessingTime(dateObject)
											}}
										/>
									</div> */}
									<div>
										<TimePicker
											showSecond={false}
											value={moment(dateTimeValue)}
											className="timePicker"
											onChange={setProcessingTime}
											format={'h:mm a'}
											use12Hours
											inputReadOnly
										/>
									</div>
								</div>
							</Grid>
						</Box>
					</ProfileDetails>
					<AssignVenueHolder>
						<Text as="h1" fontSize={18} fontFamily="OpenSansBold" color="text.SubHeading">
							Account Information
						</Text>
						<Box as="form">
							<Box as="form">
								<Grid gap={25}>
									<FormInput
										autoComplete="off"
										label="VAT/TAX ID"
										validationMessage={error.vat_idError}
										validation={error.vat_idError ? 'error' : undefined}
										value={supplierDetails && supplierDetails.vat_id}
										required={false}
										onTextChange={(e) => onChangeValue(e, 'vat_id')}
										maxLength={100}
										type="text"
									/>
									<FormInput
										label="Reseller ID"
										autoComplete="off"
										validationMessage={error.reseller_idError}
										validation={error.reseller_idError ? 'error' : undefined}
										value={supplierDetails && supplierDetails.reseller_id}
										required={false}
										onTextChange={(e) => onChangeValue(e, 'reseller_id')}
										maxLength={100}
										type="text"
									/>
								</Grid>
								<Box my={20}>
									<FormTextarea
										label="Description"
										validationMessage={error.commentError}
										validation={error.commentError ? 'error' : undefined}
										value={supplierDetails && supplierDetails.comment}
										required={false}
										onTextChange={(e) => onChangeValue(e, 'comment')}
										maxLength={500}
									/>
								</Box>
								<Box>
									<FormInput
										label="Website URL"
										autoComplete="off"
										validationMessage={error.website_urlError}
										validation={error.website_urlError ? 'error' : undefined}
										value={supplierDetails && supplierDetails.website_url}
										required={false}
										onTextChange={(e) => onChangeValue(e, 'website_url')}
										maxLength={100}
										type="text"
									/>
								</Box>
							</Box>
						</Box>
					</AssignVenueHolder>
					<AssignVenueHolder>
						<Text as="h1" fontSize={18} fontFamily="OpenSansBold" color="text.SubHeading">
							Legal address
						</Text>

						<Box as="form" mt={25}>
							<div style={{ paddingBottom: '20px' }}>
								<label>
									Address <sup style={{ fontSize: 16, color: 'red' }}>*</sup>
								</label>
								<AutocompleteHolder>
									<Autocomplete
										name="street_address"
										getItemValue={(item) => item.FullAddress}
										items={autoAddressList}
										renderItem={(item, isHighlighted) => (
											<div
												key={item.Id}
												style={{ background: isHighlighted ? 'lightgray' : 'white' }}
											>
												{item.FullAddress}
											</div>
										)}
										value={autoAddress}
										onChange={(e) => {
											setAutoAddress(e.target.value);
											onChangeValue(e.target.value, 'street_address');
										}}
										onSelect={(val) => {
											setAutoAddress(val);
											addressOnSelect(val);
										}}
									/>
									{autoAddressList.length == 0 &&
										autoAddress != '' &&
										autoAddress.length > 4 &&
										!autoAddress404 ? (
										<div className="icon-container">
											<i className="loader"></i>
										</div>
									) : (
										''
									)}
								</AutocompleteHolder>
							</div>

							{/* <Box mb={25}>
								<FormInput label="Street address"
								autoComplete="off" validationMessage={error.street_addressError}
								validation={error.street_addressError ? 'error' : undefined}
								value={supplierDetails && supplierDetails.street_address}
								required={true}
								onTextChange={(e) => onChangeValue(e, 'street_address')}
								maxLength={150}
								type="text"  />
							</Box> */}
							<Grid gap={4}>
								<Stack categorySelect gap={0}>
									<FormInput
										label="Zipcode"
										autoComplete="off"
										validationMessage={error.zip_codeError}
										validation={error.zip_codeError ? 'error' : undefined}
										value={supplierDetails && supplierDetails.zip_code}
										required={true}
										onTextChange={(e) => onChangeValue(e, 'zip_code')}
										maxLength={15}
										type="text"
									/>
								</Stack>
								<Stack categorySelect gap={0}>
									<FormInput
										label="City"
										autoComplete="off"
										validationMessage={error.cityError}
										validation={error.cityError ? 'error' : undefined}
										value={supplierDetails && supplierDetails.city}
										required={true}
										onTextChange={(e) => onChangeValue(e, 'city')}
										maxLength={50}
										type="text"
									/>
								</Stack>
								<Stack categorySelect gap={0}>
									<FormInput
										label="State/Province"
										autoComplete="off"
										validationMessage={error.stateError}
										validation={error.stateError ? 'error' : undefined}
										value={supplierDetails && supplierDetails.state}
										required={true}
										onTextChange={(e) => onChangeValue(e, 'state')}
										maxLength={100}
										type="text"
									/>
								</Stack>
								<Stack categorySelect gap={0}>
									<div>
										<label>
											Country<sup>*</sup>
										</label>
										<Select
											id="country"
											name="country"
											value={supplierDetails && supplierDetails.country}
											onChange={(e) => onChangeValue(e, 'country')}
										>
											<option>-Select-</option>
											{countries &&
												countries
													.filter((x) => x.id != "AN")
													.map((option, index) => (
														<option value={option.full_name_english} key={index}>
															{option.full_name_english}
														</option>
													))}
										</Select>
										<Icon.downArrow />
									</div>
								</Stack>
							</Grid>
						</Box>
					</AssignVenueHolder>

					{/* Footer Btn Block */}
					<FooterBtnBlock>
						<Stack direction="x" gap={3} justifyContent={['center', 'flex-end']}>
							<Button onClick={() => resetData()} flex={[1, 1, 'initial']} variant="secondary">
								Cancel
							</Button>
							<Button
								disabled={disableBtnSave}
								onClick={() => submitData('save')}
								flex={[1, 1, 'initial']}
							>
								Save
							</Button>
						</Stack>
					</FooterBtnBlock>
				</AddUserHolder>
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
			<SweetAlert
				show={showPopup}
				confirmBtnText="Confirm"
				onConfirm={onSubmit}
				showCancel={true}
				onCancel={closePopup}
				title={'Confirmation Box'}
			>
				{() => <form>{modalText}</form>}
			</SweetAlert>
		</>
	);
}

export default SupplierCompanyProfileManagement;
