import styled from 'styled-components';
import { flexbox, FlexboxProps, layout, LayoutProps, space, SpaceProps } from 'styled-system';

type ClusterProps = LayoutProps &
	FlexboxProps &
	SpaceProps & {
		gap: number;
		justifyContent?: string;
		align?: string;
	};

const Cluster = styled.div<ClusterProps>`
	${flexbox};
	${layout}
	${space}

	--space: ${({ theme, gap }) => theme.space[gap]};
	overflow: hidden;

	& > * {
		display: flex;
		flex-wrap: wrap;
		justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'flex-start')};
		align-items: ${({ align }) => (align ? align : 'center')};
		margin: calc(var(--space) / 2 * -1);
	}

	& > * > * {
		margin: calc(var(--space) / 2);
	}
`;

export { Cluster };
