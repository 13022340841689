import { venueUserPermissionList } from 'models/enum-constants/common.constants';
import Ipost from 'models/Ipost';
import React, { ReactElement, useState, useContext } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory } from 'react-router-dom';
import 'react-tippy/dist/tippy.css';
import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { Button, Loader } from 'ui/components';
import * as Icon from 'ui/icons';
import env from 'utils/constants';
import { default as helper, default as helperFunctions } from 'utils/helper.functions';
import pageConstants from 'utils/pageConstants';
import api from 'utils/requestCtx/api.service';
import utilityFunctions from 'utils/utility.functions';
import { Modal } from '../modal';
import NewFavoritesModal from '../recentOrderDetailsEditView/newFavorites';
import appContext from 'utils/appContext';
const StyledFavouriteOrderDetailsEditView = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	column-gap: 20px;
	row-gap: 20px;
	margin-bottom: 16px;
	.order-details__wrap {
		flex-direction: row;
		background: ${({ theme }) => theme.colors.bg.white};
		border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
		box-sizing: border-box;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 16px;
		overflow: hidden;
		transition: box-shadow 100ms ease-in, transform 100ms ease-in;
		align-items: flex-start;
		height: auto;
		display: flex;
		span {
			max-width: 117px;
			flex: 0 0 117px;
			height: 100%;
			background-repeat: no-repeat;
			background-size: contain;
			@media (max-width: 890px) {
				max-width: 126px;
				flex: 0 0 126px;
				background-position: center center;
			}
			@media (max-width: 360px) {
				max-width: 80px;
				flex: 0 0 80px;
			}
		}
		section {
			display: grid;
			grid-template-columns: repeat(7, 1fr) !important;
			align-items: center;
			height: 100%;
			padding: 25px;
			column-gap: 20px;
			column-gap: 10px;
			label {
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				display: block;
			}

			@media (max-width: 1750px) {
				padding: 20px;
				row-gap: 25px;
				grid-template-columns: repeat(6, 1fr) !important;
			}
			@media (max-width: 1552px) {
				grid-template-columns: repeat(4, 1fr) !important;
			}
			@media (max-width: 1366px) {
				grid-template-columns: repeat(3, 1fr) !important;
			}
			@media (max-width: 960px) {
				grid-template-columns: repeat(2, 1fr) !important;
			}
			@media (max-width: 640px) {
				grid-template-columns: repeat(2, 1fr) !important;
				align-items: flex-start;
			}
			@media (max-width: 340px) {
				grid-template-columns: repeat(1, 1fr) !important;
				align-items: flex-start;
			}

			h1 {
				span {
					max-width: fit-content;
					flex: 0 0 auto;
					height: auto;
					path {
						fill: ${({ theme }) => theme.baseColors.primary};
					}
				}
				button {
					padding: 0;
					background: transparent !important;
					cursor: pointer;
					margin: 0 0 0 10px;
					outline: none !important;
					box-shadow: none !important;
					border: none !important;
				}
			}
			.btn-block {
				display: flex;
				align-items: center;
				justify-content: flex-start;

				@media (max-width: 1300px) {
					justify-content: flex-start;
				}
				span {
					height: auto;
					flex: 0 0 auto;
					max-width: auto;
					min-width: auto;
					min-height: auto;
				}
				button {
					display: flex;
					align-items: center;
					margin-right: 15px;
					text-transform: capitalize;
					white-space: nowrap;
					height: 50px;
					@media (max-width: 480px) {
						font-size: 13px;
						padding-right: 10px;
						padding-left: 10px;
						white-space: nowrap;
					}
					span {
						max-width: 100% !important;
					}
				}
				icon {
					display: inline-block;
				}
				div {
					word-break: break-word;
				}
			}
			.totalProducts,
			.totalSuppliers {
				max-width: 125px;
				text-align: right;
				white-space: nowrap;
			}
			.totalProducts {
				max-width: 119px;
			}
			slot {
				display: flex;
				flex-direction: column;
				grid-row: auto;
				h1 {
					display: flex;
				}
				@media (max-width: 640px) {
					grid-area: auto / 3 / auto / 1;
				}
			}
			main {
				width: 250px;
				@media (max-width: 640px) {
					width: 100%;
				}
			}
		}
		&.selected {	
			background: ${({ theme }) => theme.newColors.lightgray};
		}
	}
`;
function FavouriteOrderDetailsEditView({ product, setProduct }): ReactElement {
	let ctx = useContext(appContext).cartObj;
	const [postObj, setObj] = useState({} as any);
	const [modalText, setmodalText] = useState('');
	const [showPopup, setShowModal] = useState(false);
	const [action, setAction] = useState('');
	const [favName, setFavName] = useState('');
	const [favId, setFavId] = useState(0);
	const [showNewFavoritesModal, setShowNewFavoritesModal] = useState(false);
	const [orderId, setOrderId] = useState('');
	const [inProgress, setInProgress] = useState(false);
	let history = useHistory();
	function confirm(e, action, id, qty = 0) {
		e.stopPropagation();
		setAction(action);
		switch (action) {
			case 'del':
				showConfirmationModal('Delete favorite?', id, qty);
				break;
			case 'placeOrder':
				showConfirmationModal('Move to cart ?', id, qty);
				break;
			default:
				break;
		}
	}
	function showConfirmationModal(text, id, qty) {
		setOrderId(id);
		setmodalText(text);
		setShowModal(!showPopup);
	}
	async function closePopup() {
		await setShowModal(!showPopup);
	}

	function onSubmit() {
		if (action == 'del') deleteOrder();
		else if (action == 'placeOrder') placeOrder();
	}

	function deleteOrder(hasMessage = true) {
		let obj1: Partial<Ipost> = {};
		obj1.message = !hasMessage ? ' ' : 'Order deleted successfully';
		obj1.url = `experion/productmanagement/deletefavoriteorder/${orderId}`;
		api.delete(obj1).then(function (d) {
			if (typeof d != 'undefined') {
				history.push('/store/orders?tab=favourite-orders');
			}
		});
		if (hasMessage) setShowModal(!showPopup);
	}

	function updateList(name) {
		product.favorite_name = `${getBaseName(product.favorite_name)}${name}`;
		setProduct(product);
	}

	function getBaseName(name) {
		if (name.includes('/')) {
			let n = name.lastIndexOf('/');
			return name.substring(0, n + 1);
		}
		return '';
	}

	function getFavName(name) {
		if (name.includes('/')) {
			let n = name.lastIndexOf('/');
			return name.substring(n + 1);
		}
		return name;
	}

	async function placeOrder() {
		setShowModal(!showPopup);
		setInProgress(true);
		let items = [] as any;
		product?.favorite_items?.forEach((v) => {
			items.push({ product_id: v.product_id, qty: v.qty });
		});
		setObj(items);
		let id: any;
		let CUSTOMER_TOKEN = localStorage.getItem('customerToken');
		id = await utilityFunctions.getCartId(CUSTOMER_TOKEN);
		const payload = {
			products: {
				quote_id: id,
				items: items,
			},
		};
		api
			.post({
				data: payload,
				url: env.multiCartURL(),
				key: 'placeOrder',
				message: 'Available products will be moved to cart!',
			})
			.then(function (data) {
				setInProgress(false);
				if (data == true) {
					ctx.stepperdCountUpdated = false;
					history.push('/store/cart');
				}
			});
	}

	function editFavoriteName(e, name, id) {
		e.stopPropagation();
		setFavName(getFavName(name));
		setFavId(id);
		setShowNewFavoritesModal(!showNewFavoritesModal);
	}

	let productCount = product?.favorite_items?.length;
	let Suppliers = [] as any;
	product?.favorite_items?.map((x) => {
		if (!Suppliers.includes(x.supplier_name)) Suppliers.push(x.supplier_name);
	});
	let supplierCount = Suppliers?.length;
	let SuppliersToDisplay = Suppliers.slice(0, 2).join(',').replace(/,\s*$/, '');
	return (
		<StyledFavouriteOrderDetailsEditView>
			<Flex
				className="order-details__wrap selected"
				bg="white"
				sx={{ alignItems: 'center' }}
				key={helperFunctions.getRandomInt(pageConstants.maxValue)}
			>
				{/* <Flex alignItems="center" as="span" maxWidth={['100%']} sx={{ overflow: 'hidden' }}></Flex> */}
				<Flex as="section" flexWrap="wrap" width="100%" maxWidth={['100%']}>
					<Box as="slot">
						<Text as="h1" mb={1} fontSize={18} color="text.black">
							Favourite List Name{' '}
							<Button
								variant="ghost"
								onClick={(e) => editFavoriteName(e, product.favorite_name, product.id)}
							>
								<Icon.textEditIcon />
							</Button>
						</Text>
						<Text as="label" fontSize={20} fontFamily="OpenSansBold" color="text.black">
							{product.favorite_name}
						</Text>
					</Box>
					<Box as="slot">
						<Text mb={1} fontSize={18} color="text.black">
							Suppliers
						</Text>
						<Text as="label" fontSize={20} fontFamily="OpenSansBold" color="text.black">
							{SuppliersToDisplay}
							{supplierCount > 2 && <strong> & {supplierCount - 2} more</strong>}
						</Text>
					</Box>
					<Box>
						<Text mb={1} fontSize={18} color="text.black">
							Date
						</Text>
						<Text as="label" fontSize={20} fontFamily="OpenSansBold" color="text.black">
							{helper.formatDate(product.created_at)}
						</Text>
					</Box>
					<Box>
						<Text mb={1} fontSize={18} color="text.black">
							Amount
						</Text>
						<Text as="label" fontSize={20} fontFamily="OpenSansBold" color="text.black">
							{helper.formatAmount(product.total_cost)}
						</Text>
					</Box>
					<Box className="totalProducts">
						<Text mb={1} fontSize={18} color="text.black">
							Total Products
						</Text>
						<Text as="label" fontSize={20} fontFamily="OpenSansBold" color="text.black">
							{productCount}
						</Text>
					</Box>
					<Box className="totalSuppliers">
						<Text mb={1} fontSize={18} color="text.black">
							Total Suppliers
						</Text>
						<Text as="label" fontSize={20} fontFamily="OpenSansBold" color="text.black">
							{supplierCount}
						</Text>
					</Box>
					<Box as="main">
						<div className="btn-block">
							{utilityFunctions.isGranted(venueUserPermissionList.ManageProductsinCart) && (
								<Button
									disabled={productCount == 0}
									onClick={(e) => confirm(e, 'placeOrder', product.id, productCount)}
								>
									Move To Cart
								</Button>
							)}
							{utilityFunctions.isGranted(venueUserPermissionList.FavoriteOrderDelete) && (
								<Button variant="ghost" onClick={(e) => confirm(e, 'del', product.id)}>
									<Icon.DeleteIcon />
								</Button>
							)}
						</div>
					</Box>
				</Flex>
			</Flex>
			<Modal
				width="500px"
				showModal={showNewFavoritesModal}
				setShowModal={setShowNewFavoritesModal}
			>
				<NewFavoritesModal
					updateList={updateList}
					showModal={showNewFavoritesModal}
					setShowModal={setShowNewFavoritesModal}
					favName={favName}
					favId={favId}
				/>
			</Modal>
			{inProgress && <Loader></Loader>}
			<SweetAlert
				show={showPopup}
				confirmBtnText="Confirm"
				onConfirm={onSubmit}
				showCancel={true}
				onCancel={closePopup}
				title={'Confirmation Box'}
			>
				{() => (
					<form>
						<hr />
						{modalText}
						<hr />
					</form>
				)}
			</SweetAlert>
		</StyledFavouriteOrderDetailsEditView>
	);
}

export { FavouriteOrderDetailsEditView };
