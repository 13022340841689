import React, { ReactElement, ReactNode, SyntheticEvent, useRef, useState } from 'react';
import { Box } from 'rebass/styled-components';
import 'simplebar/dist/simplebar.min.css';
import styled from 'styled-components';
import { ParkedOrders } from 'ui/patterns';
import useOnClickOutside from 'use-onclickoutside';
import DropdownButton from './DropdownButton';
import DropdownMenu from './DropdownMenu';

const StyledParkedOrderDropdown = styled.div`
	display: flex;
	align-items: center;
	margin: 0 30px 0 0;
	svg {
		width: 25px;
		height: 30px;
	}
	button {
		padding: 0;
		outline: none !important;
		box-shadow: none !important;
	}
	.badge-count {
		width: 10px;
		height: 10px !important;
		top: 4px;
		left: auto;
		border-width: 1px;
		right: 1px;
	}
	.menuContent {
		width: 400px !important;
		padding: 0 !important;
		display: flex !important;
		flex-direction: column !important;
		top: 60px !important;
		min-height: max-content !important;
		@media (max-width: 480px) {
			width: 330px !important;
		}
		@media (max-width: 340px) {
			width: 310px !important;
		}
		&:before {
			right: 140px;
			left: auto !important;
		}
		.simplebar-wrapper {
			max-height: calc(100vh - 200px);
		}
	}
`;
const ViewAllHeadingBlock = styled.div`
	display: flex !important;
	justify-content: space-between;
	color: ${({ theme }) => theme.colors.text.black04};
	padding: 0 10px 10px;
	align-items: center;
	min-height: 35px;
	h1,
	h2 {
		font-family: 'OpenSansBold';
		text-transform: uppercase;
		display: flex !important;
		align-items: center;
		position: relative;
		span {
			margin-right: 6px;
			svg {
				width: 25px;
				height: 22px;
			}
		}
	}
	h1,
	h2 a {
		font-weight: normal;
	}
	a {
		color: ${({ theme }) => theme.baseColors.baseYellow};
		text-decoration: none;
		font-family: 'OpenSansMedium';
		transition: ease-in all 200ms;

		&:hover {
			background: ${({ theme }) => theme.baseColors.baseYellow};
			padding: 3px 10px;
			color: ${({ theme }) => theme.baseColors.white};
			border-radius: 5px;
		}
	}
	h2 {
		width: 100%;
		margin-top: 20px;
		&:after,
		&:before {
			content: '';
			position: absolute;
			z-index: -1;
		}
		&:after {
			width: 125px;
			height: 10px;
			background: ${({ theme }) => theme.baseColors.white};
		}
		&:before {
			width: 100%;
			height: 1px;
			background: rgba(0, 0, 0, 0.15);
		}
	}
`;
export type ParkedOrderDropdownProps = {
	trigger?: string | ReactNode;
	menuList?: Array<item>;
	menuContent?: ReactNode;
	autoClose?: boolean;
	position?: 'right-top' | 'right' | 'left-top' | 'left';
	ref?: React.RefObject<HTMLDivElement>;
	onClick?: (e: any) => void;
};
type item = {
	id?: number;
	value: string;
	action?: () => void;
	icon?: ReactNode;
	groupEnd?: boolean;
};
function ParkedOrderDropdown({
	autoClose = true,
	position,
}: ParkedOrderDropdownProps): ReactElement {
	const [open, setOpen] = useState(false);
	const handleOnClickOutside = () => {
		setOpen(false);
	};

	const wrapperRef = useRef<HTMLDivElement>(null);
	useOnClickOutside(wrapperRef, handleOnClickOutside);
	const handleToggle = (e: SyntheticEvent<HTMLDivElement>) => {
		e.stopPropagation();
		setOpen(autoClose === true ? !open : true);
	};
	return (
		<StyledParkedOrderDropdown ref={wrapperRef} onClick={handleToggle}>
			<DropdownButton />
			{open && (
				<DropdownMenu position={position}>
					<Box className="menuContent">
						<ParkedOrders totalAmount="$0"></ParkedOrders>
					</Box>
				</DropdownMenu>
			)}
		</StyledParkedOrderDropdown>
	);
}

export { ParkedOrderDropdown };
