import { UserBody } from 'admin/dashboardVenue/styles';
import { venueUserPermissionList } from 'models/enum-constants/common.constants';
import Iget from 'models/Iget';
import Ipost from 'models/Ipost';
import urlObj from 'models/url.obj';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Flex, Text } from 'rebass/styled-components';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { Button, RadioButton, Stack } from 'ui/components';
import * as Icon from 'ui/icons';
import AppContext from 'utils/appContext';
import urls from 'utils/create.url';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import utilityFunctions from 'utils/utility.functions';
import { AddToFavorites, ParkedOrderLimitReached } from './styles';
export default function CustomerNotFound(props): ReactElement {
	const ctx = useContext(AppContext).productSearchObj;
	const [products, setProducts] = useState([] as any);
	var [enableButton, updateEnableButton] = useState<any>(false);
	const [addSelectedVenue, setAddSelectedVenue] = useState('');

	let urlObj : Partial<urlObj> = {
		url: 'experion-parkorder/parkorder/search?',
		pageSize: 2000,
		filterDeleted: true,
		ctx: ctx,
		fields : [{field_name : 'venue_id',
		field_value : localStorage.getItem('switchedVenue')!},
		{field_name : 'venue_user_id',
		field_value : localStorage.getItem('userId')!}],
	};
	

	const obj: Partial<Iget> = {
		url: urls.GetSearchUrl(urlObj),
		enabled: true,
		refetchOnWindowFocus: false,
		resultFilteringRequired: true,
		key: 'parked-orders'
	};
	const qryObj = hooks.useQuery(obj);

	useEffect(() => {
		// qryObj.data && qryObj.data.forEach((data) => {
		// 	setProducts(qryObj.data?.filter(x=>x.parkorder_id == x.parkorder_id));
		// }
		// )
		setProducts(qryObj.data);
	}, [qryObj.data]);


	// const handleOnChange = (e: any) => {
	// 	props.parentCallback(e.target.id);
    // };

    const handleSelectOnChange = (e: any) => {
		e.stopPropagation();
        setAddSelectedVenue(e.target.id);
		updateEnableButton(true);
		// props.parentCallback(e.target.id);
		// props.togglePopup();
	}

	const deleteParkedOrderById = () => {
		const obj: Partial<Ipost> = {
			params: {},
			url: 'experion-parkorder/parkorder/' + addSelectedVenue,
			message: 'Selected item has been deleted from Parked Orders',
		};
		try {
			api.delete(obj).then((data) => {
				if (data !== undefined) {
					props.parentCallback();
					props.togglePopup();
				}
			});
		} catch (e) { }
	}

	return (
		<AddToFavorites>
			<Text as="h1" color="text.textHeading" fontSize={22} fontFamily="OpenSansBold">
				Parked Order Limit Reached
			</Text>
			<Text as="p" color="text.black06" fontFamily="OpenSansMedium" fontSize={[16]}>
				You have reached the parked order limit. Please delete an existing parked order to
				continue.
			</Text>
			<ParkedOrderLimitReached>
				<SimpleBar className="scroll-block">
				{!qryObj.isFetching && products?.length > 0 ? (
				products.map(({ parkorder_id, name }) => (
					// <Checkbox checked={false} handleChange={(event) => selectCheckBox(parkorder_id, event)} key={helperFunctions.getRandomInt(pageConstants.maxValue)}>
					// 	<Text
					// 		as="h1"
					// 		fontSize="20px"
					// 		color="text.black04"
					// 		fontFamily="OpenSansRegular"
					// 		sx={{ width: '100%', top: '1px', position: 'relative' }}
					// 	>
					// 		{name}
					// 	</Text>
					// </Checkbox>

					<RadioButton id={parkorder_id} key={parkorder_id} name="paymentMethod" onChange={handleSelectOnChange} hasActiveText>
					<Flex alignItems="center" width="100%"  sx={{ transform: 'translateY(-3px)' }}>
						<Text as="h1" fontFamily="OpenSansSemiBold" flex="1" px={2}>
							{name}
						</Text>
						{(addSelectedVenue === parkorder_id.toString()) ? <Icon.Tick /> : ``}
					</Flex>
					</RadioButton>
				))): (!qryObj.isFetching && products?.length == 0) ? (
					<UserBody>
						{/* <Icon.searchIcon title="No Data Found" /> */}
						<Text fontSize={18} fontWeight="bold" color="#5C5E57" textAlign="center">
							No Orders Found
						</Text>
					</UserBody>
				) : (
					new Array(2).fill(null).map((_, i) => {
						return <Skeleton key={i} width="1200px" height="50px" />;
					})
				)}
				</SimpleBar>
			</ParkedOrderLimitReached>
			<Stack direction="x" gap={3} justifyContent="center" className="parkedOrderLimit">
				<Button onClick={() => props.togglePopup && props.togglePopup()} flex={[1, 1, 'initial']} variant="secondary">
					Cancel
				</Button>
				{(utilityFunctions.isGranted(venueUserPermissionList.ParkedOrdersDelete) ||
				utilityFunctions.isGranted(venueUserPermissionList.ParkedOrdersAdmin)) &&
				<Button disabled={!enableButton} onClick={() => deleteParkedOrderById()} flex={[1, 1, 'initial']}>Delete Selected</Button>}
			</Stack>
		</AddToFavorites>
	);
}
