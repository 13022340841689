import moment from 'moment';
import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import 'react-tippy/dist/tippy.css';
import { Box, Flex, Image, Text } from 'rebass/styled-components';
import styled, { css } from 'styled-components';
import helper from 'utils/helper.functions';
import nodataImage from '../../images/Nodata.svg';

const StyledCartListView = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 20px;
	row-gap: 20px;
	margin-bottom: 50px;
	@media (max-width: 640px) {
		grid-template-columns: repeat(1, 1fr);
	}
`;

const DeliveryConfirmationsListHolder = styled.div<DeliveryConfirmationsListHolderProps>`
	width: 100%;
	display: flex;
	flex-direction: row;
	background: ${({ theme }) => theme.colors.bg.white};
	border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
	box-sizing: border-box;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 16px;
	overflow: hidden;
	transition: box-shadow 100ms ease-in, transform 100ms ease-in;
	align-items: flex-start;
	cursor: pointer;
	&:hover {
		transform: translateY(-5px);
		box-shadow: 0 20px 20px #eee;
		border: 1px solid ${({ theme }) => theme.baseColors.primary};
	}
	@media (max-width: 768px) {
		flex-direction: column;
	}

	picture {
		max-width: 216px;
		flex: 0 0 216px;
		height: 100%;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		@media (max-width: 768px) {
			max-width: 100%;
			flex: 0 0 100%;
			height: 200px;
			min-height: 200px;
			width: 100%;
			max-height: 200px;
		}
	}
	section {
		height: 100%;
		padding: 25px;
		position: relative;
		display: flex;
		flex-direction: row;
		@media (max-width: 768px) {
			padding: 20px;
		}
		@media (max-width: 480px) {
			padding: 15px;
		}
	}
	slot {
		flex: 1;
		align-items: flex-start;
		flex-direction: column;
		justify-content: space-between;
		position: relative;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		row-gap: 20px;
		column-gap: 20px;
		@media (max-width: 1500px) {
			grid-template-columns: repeat(2, 1fr);
		}
		@media (max-width: 1200px) {
			grid-template-columns: repeat(1, 1fr);
			row-gap: 10px;
		}
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-weight: normal;
			color: ${({ theme }) => theme.colors.text.black};
			strong {
				float: left;
				width: 100%;
			}
		}
		h1 {
			margin-bottom: 0;
			strong {
				font-family: 'OpenSansBold';
				font-size: 20px;
				line-height: 26px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				display: block;
			}
		}
		h2 {
			width: 90px;
			text-align: right;
			strong {
				font-family: 'OpenSansBold';
				font-size: 17px;
				line-height: 22px;
				margin-left: 3px;
			}
		}
		h3 {
			strong {
				font-family: 'OpenSansBold';
				font-size: 17px;
				line-height: 22px;
				margin-left: 3px;
			}
		}
		h4 {
			margin-left: auto;
			@media (max-width: 480px) {
				margin-left: 0;
			}
			strong {
				font-family: 'OpenSansBold';
				font-size: 17px;
				line-height: 22px;
				margin-left: 3px;
			}
		}
		@media (max-width: 480px) {
			div {
				flex-direction: column;
			}
		}
	}
	${({ active }) =>
		!active &&
		css`
			cursor: default;
			&:hover {
				transform: none;
				background: ${({ theme }) => theme.colors.bg.white};
				border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
				box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
			}

			picture {
				opacity: 0.5;
				pointer-events: none;
			}
			h1,
			h2,
			h3,
			h4,
			h6 {
				opacity: 0.5;
				pointer-events: none;
			}
		`}
`;
const Badge = styled.div<BadgeProps>`
	position: absolute;
	right: 0;
	width: 88px;
	height: 30px;
	border-width: 1px;
	border-style: solid;
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 13px;
	line-height: 17px;
	font-family: 'OpenSansSemiBold';

	${({ active }) =>
		active &&
		css`
			background: #fdf9f0;
			border-color: #ecb136;
			color: #ecb136;
		`}
	${({ received }) =>
		received &&
		css`
			background: #fcfeef;
			border-color: #666f35;
			color: #666f35;
		`}
	${({ disputed }) =>
		disputed &&
		css`
			background: #ffeeee;
			border-color: #eb5757;
			color: #eb5757;
		`}
`;
const NodataFound = styled.div`
	width: 100%;
	display: flex;
	display: flex;
	align-items: center;
	justify-content: center;
	height: calc(100vh - 494px);
	grid-column: 3/1;
	img {
		margin: 0;
	}
	h1 {
		text-align: center;
		margin-top: 15px;
	}
`;
type DeliveryConfirmationsListHolderProps = {
	active?: boolean;
};
type BadgeProps = {
	active?: boolean;
	received?: boolean;
	disputed?: boolean;
};

function DeliveryConfirmationsListView({ deliveryConfirmationsList }): ReactElement {
	const history = useHistory();

	// const getParsedData = (status) => {
	// 	return JSON.parse(status).status;
	// };
	return (
		<StyledCartListView>
			{deliveryConfirmationsList.length > 0 ? (
				deliveryConfirmationsList.map((item) => {
					let deliverySlot = item?.extension_attributes?.delivery_slot
						? item?.extension_attributes?.delivery_slot
						: '';
					deliverySlot = deliverySlot.split(' ');
					let deliverySlotDay = null as any;
					let deliverySlotDate = null as any;
					if (deliverySlot.length === 2) {
						deliverySlotDate = moment(deliverySlot[1], 'DD-MM-YYYY');
						deliverySlotDate = helper.formatDate(deliverySlotDate.toDate());
						deliverySlotDay = deliverySlot[0];
					}
					return (
						<DeliveryConfirmationsListHolder
							key={Math.random()}
							active={true}
							onClick={() => history.push('/admin/deliveryConfirmationsInner?id=' + item.entity_id)}
						>
							{/* <Flex
							alignItems="center"
							as="picture"
							maxWidth={['100%']}
							sx={{ overflow: 'hidden' }}
							style={{
								backgroundImage: 'url(' + item.image + ')',
							}}
						></Flex> */}
							<Flex as="section" flexDirection="column" width="100%" maxWidth={['100%']}>
								<Box as="slot">
									<Text as="h1" fontSize={17} color="text.black">
										Order ID<strong> {item?.extension_attributes?.order_group_increment_id}</strong>
									</Text>
									<Text as="h1" fontSize={17} color="text.black">
										Supplier Name<strong> {item.extension_attributes.supplier_name}</strong>
									</Text>
									<Text as="h2" fontSize={16} color="text.black">
										No of items<strong> {item.total_item_count}</strong>
									</Text>
									<Flex width={1}>
										<Text as="h3" fontSize={16} color="text.black">
											Created :<strong> {helper.formatDate(item.created_at)}</strong>
										</Text>
										{/* <Text as="h4" fontSize={16} color="text.black">
										Delivered :<strong> {helper.formatDate(item.updated_at)}</strong>
									</Text> */}
									</Flex>
									{deliverySlot && deliverySlot.length === 2 && (
										<Flex width={1}>
											<Text as="h3" fontSize={16} color="text.black">
												Delivery Date :<strong> {`${deliverySlotDay} ${deliverySlotDate}`}</strong>
											</Text>
										</Flex>
									)}

									<Badge active>
										{item.status?.charAt(0).toUpperCase() + item.status?.slice(1)}
									</Badge>
									{/* <Badge received>Received</Badge>
									<Badge disputed>Disputed</Badge> */}
								</Box>
							</Flex>
						</DeliveryConfirmationsListHolder>
					);
				})
			) : (
				<NodataFound>
					<Box>
						<Image
							src={nodataImage}
							sx={{
								width: 130,
								height: 130,
							}}
						/>
						<Text as="h1" fontFamily="OpenSansBold" fontSize={18} color="text.black">
							No data
						</Text>
					</Box>
				</NodataFound>
			)}
		</StyledCartListView>
	);
}

export { DeliveryConfirmationsListView };
