import Iget from 'models/Iget';
import Ipost from 'models/Ipost';
import VenueRole from 'models/pageObjects/venue.roles';
import queryString from 'query-string';
import React, { ReactElement, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory, useLocation } from 'react-router-dom';
import { Text } from 'rebass/styled-components';
import {
	AccordionUserDetails, BreadcrumbNav,



	Button, Footer, Header,




	TableList, Toggle
} from 'ui/components';
import icons from 'ui/icons';
import { Modal, Sidebar } from 'ui/patterns';
import urls from 'utils/create.url';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import notify from 'utils/toast.service';
import AddExistingDCUsersModal from './assignUserRole';
import {
	AccordionBlock,


	BreadcrumbWrapper, MainContent,




	RightHolder, UserBlock,
	UserHeader
} from './styles';


function DCUserDetails(props): ReactElement {
	const breadcrumbNav = [
		{ id: '01', label: 'Dashboard', route: 'seller-dashboard', active: false },
		{ id: '02', label: 'User Management', route: 'userList', active: false },
		{ id: '03', label: 'User Details', active: true },
	];

	const [showAssignUserRoleModal, setShowAssignUserRoleModal] = useState(false);
	const toggleAssignUserRoleModal = () => {
		setShowAssignUserRoleModal(!showAssignUserRoleModal);
	};

	const [showPopup, setShowModal] = useState(false);
	const [dcList, setDcList] = useState([] as any);
	const [userDetails, setUserDetails] = useState<any>();
	const [venueRoles, setVenueRoles] = useState<Array<VenueRole>>([]) as any;
	const [roleList, setRoleList] = useState([] as any);
	// const [checked, setChecked] = useState(false)
	var [updateList, setUpdateList] = useState([] as any);
	const [modalText, setmodalText] = useState('');
	const [assignedUser, setAssignedUser] = useState<User>({ dcName: '', dcId: 0, userRole: '', role_id: 0 });
	const [action, setAction] = useState('');
	var [dcIndex, setDcIndex] = useState<any>();

	var history = useHistory()
	type User = { dcName: string; dcId: number; userRole: string; role_id: number };
	const location = useLocation();
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);	
	const pageSize = 500;
	var itemsList = [] as any;
	const obj1: Partial<Ipost> = {
		params: {},
		url: '',
	};

	//get all assigned user mapped roles
	const parsed = queryString.parse(location.search);
	const obj: Partial<Iget> = {
		params: {},
		url: '/experion/supplycompanyaccount/get/' + parsed.id,
		enabled: true,
		key: 'KeyUserDetails',
		resultFilteringRequired: false
	};
	var qry = hooks.useQuery(obj);

	//get all venues
	const dcUrl = urls.GetSearchUrl({
		url: 'experion/supplycompanyaccount/dc/search?',
		pageSize: pageSize,
		fields: [{
			field_name: 'supply_id',
			field_value: localStorage.getItem("supplierId")!
		}],
		filterDeleted: true,
		filterInactive: true
	});
	const venueObj: Partial<Iget> = {
		params: {},
		url: dcUrl,
		enabled: true,
		key: 'dc',
		resultFilteringRequired: false,
	};
	const venueQry = hooks.useQuery(venueObj);

	//get all roles
	const roleUrl = urls.GetSearchUrl({
		url: '/experion/supplycompanyaccount/dcUserrole/search?',
		pageSize: pageSize,
		fields: [{
			field_name: 'supply_id',
			field_value: localStorage.getItem("supplierId")!
		}]
	});
	const roleObj: Partial<Iget> = {
		params: {},
		url: roleUrl,
		enabled: true,
		key: 'role',
		resultFilteringRequired: true,
	};
	const roleQry = hooks.useQuery(roleObj);


	// map user role mapping to dropdown
	useEffect(() => {
		setUserDetails(qry.data)
		let list = [] as Array<any>;
		qry.data?.dc_userrole_mapping.map((v) => {
			if (v.is_delete != true && v.is_active == true) {
				list.push({
					dc: {
						key: v.dc_id,
						value: v.dc_name,
					},
					role: {
						key: v.role_id,
						value: v.role_name,
					},
				});
			}
		});
		setVenueRoles(list);

	}, [qry.data])

	useEffect(() => {
		const venues = [] as any;
		venueQry.data &&
			venueQry.data.items.map((item) => {
				if (
					(venueRoles && venueRoles.findIndex((x) => x.dc.key == item.dc_id) == -1) ||
					!venueRoles ||
					venueRoles.length == 0
				) {
					venues.push({ key: item.dc_id, value: item.name });
				}
			});
		setDcList(JSON.parse(JSON.stringify(venues)));
	}, [venueQry.data]);

	useEffect(() => {
		const roles = [] as any;
		roleQry.data &&
			roleQry.data.map((item) => {
				// let data = venueQry.data;
				if (
					(venueRoles && venueRoles.findIndex((x) => x.role.key == item.key) == -1) ||
					!venueRoles ||
					venueRoles.length == 0
				) {
					roles.push({ key: item.id, value: item.role_name });
				}
			});
		setRoleList(JSON.parse(JSON.stringify(roles)));
	}, [roleQry.data]);

	const saveUser = async () => {
		if (
			userDetails?.dc_userrole_mapping.findIndex(
				(x) => x.dc_id == assignedUser.dcId
			) != 0
		) {
			updateList = JSON.parse(JSON.stringify(userDetails));
			updateList.dc_userrole_mapping = [];
			userDetails.dc_userrole_mapping?.forEach((data) => {
				let items = { dc_id: null, role_id: null, dc_name: '', role_name: '' }
				items.dc_id = data.dc_id
				items.role_id = data.role_id
				items.dc_name = data.dc_name
				items.role_name = data.role_name
				updateList.dc_userrole_mapping.push(items)

			})
			updateList.dc_userrole_mapping.push({
				"dc_id": assignedUser.dcId,
				"role_id": assignedUser.role_id,
				"dc_name": assignedUser.dcName,
				"role_name": assignedUser.userRole
			})
			const obj: Partial<Ipost> = {
				data: { dcUser: updateList },
				params: {},
				url: '/experion/supplycompanyaccount/update/' + userDetails.id,
				message: `User role added successfully`,
			};
			try {
				await api.put(obj).then(function (data) {
					if (data) {
						dcList.splice(dcIndex, 1)
						setUserDetails(data)
						toggleAssignUserRoleModal()
					}
				});

			} catch (e) {
				// setIsLoading(false);
			}
		} else {
			notify('DC already exists in the list').info();
		}

	}
	const onSelectDropdwn = (event, name) => {
		const data = JSON.parse(JSON.stringify(assignedUser));
		switch (name) {
			case 'venueName': {
				if (data) {
					data.dcName = event.target.value;
					if (event.target.value !== '') {
						dcIndex = dcList.findIndex((x) => x.value == event.target.value)
						data.dcId = dcList[dcIndex].key;
						setDcIndex(dcIndex);
					}
				}
				break;
			}
			case 'userRole': {
				if (data) {
					data.userRole = event.target.value;
					if (event.target.value != '')
						data.role_id = roleList[roleList.findIndex((x) => x.value == event.target.value)].key;
				}
				break;
			}
		}
		setAssignedUser(JSON.parse(JSON.stringify(data)));
	}

	async function onDeleteVenueRole(selectedId) {
		updateList = JSON.parse(JSON.stringify(userDetails));

		updateList.dc_userrole_mapping.splice(
			updateList.dc_userrole_mapping.findIndex((x) => x.id == selectedId), 1)

		userDetails.dc_userrole_mapping?.forEach((data) => {
			let items = { dc_id: null, role_id: null, dc_name: '', role_name: '' }
			items.dc_id = data.dc_id
			items.role_id = data.role_id
			items.dc_name = data.dc_name
			items.role_name = data.role_name
			itemsList.push(items)

		})
		userDetails.dc_userrole_mapping = itemsList;
		const obj: Partial<Ipost> = {
			data: { dcUser: updateList },
			params: {},
			url: '/experion/supplycompanyaccount/update/' + userDetails.id,
			message: `User role deleted successfully`,
		};
		try {
			await api.put(obj).then(function (data) {
				if (data) {
					setShowModal(!showPopup);
					setUserDetails(data)
				}
			});
			// }
			// setIsLoading(false);
		} catch (e) {
			// setIsLoading(false);
		}
	};
	const closePopup = () => {
		setShowModal(!showPopup);
	}
	function showConfirmationModal(text, action) {
		setAction(action);
		setmodalText(text);
		setShowModal(!showPopup);
	}

	function onSubmit(selectedId) {
		if (action == 'deleteRole') onDeleteVenueRole(selectedId);
		else if (action == 'deleteUser') deleteUser();
	}

	function deleteUser() {
		obj1.url = `experion/supplycompanyaccount/dcuser/${parsed.id}`;
		obj1.data = { Id: [parsed.id] };
		obj1.message = 'User deleted successfully';
		api.delete(obj1).then(function (d) {
			// if (typeof d != 'undefined')
			// updateUserList((qry.data = qry.data.filter((x) => x.id !== parsed.id)));
		});
		setShowModal(!showPopup);
	}

	function changeStatus(checked) {
		obj1.url = `experion/supplycompanyaccount/activedeactive/${parsed.id}/${checked}`;
		obj1.message = 'DC user status updated successfully';
		api.put(obj1).then(data => {
			if (data === true) {
				qry.refetch().then(() => {
				});
			}
		});

	}

	const navigateToEdit = () => {
		history.push({
			pathname: 'edit-dc-user',
			search: '?id=' + parsed.id,
		});
	};

	const ontoggleChange = (event) => {
		changeStatus(event.target.checked)
	}
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	}
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={()=>onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={()=>onclickHambrgBtn()}></Sidebar>

			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbWrapper>
					<BreadcrumbNav Heading="User Details" breadcrumbList={breadcrumbNav} />
					<RightHolder>
						<Toggle onChange={ontoggleChange} />
						<Button variant="deleteIcon" onClick={() => showConfirmationModal('Delete user?', 'deleteUser')}>
							<icons.DeleteIcon />
						</Button>
						<Button variant="editIcon" onClick={navigateToEdit}>
							<icons.textEditIcon />
						</Button>
					</RightHolder>
				</BreadcrumbWrapper>
				<AccordionBlock>
					<AccordionUserDetails userDetails={userDetails} />
					<UserBlock>
						<UserHeader>
							<Text fontSize={18} fontWeight="bold" color="black" verticalAlign="middle">
								Users
							</Text>
							{/* <Button>
								<Text as="h1" fontFamily="OpenSansBold" fontSize={18} color="text.white">
									Add
								</Text>
							</Button> */}
							<Button onClick={toggleAssignUserRoleModal}>
								<Text as="h1" fontFamily="OpenSansBold" fontSize={18} color="text.white">
									Assign Roles
								</Text>
							</Button>
						</UserHeader>
						<TableList>
							<table>
								<thead>
									<tr>
										<th>DC</th>
										<th>Role</th>
										<th className="action">Action</th>
									</tr>
								</thead>

								<tbody>

									{userDetails && userDetails?.dc_userrole_mapping.map((data) => (
										<tr>
											<td data-label="Venue">
												<Text as="h1" fontSize={15} fontFamily="OpenSansBlack" color="text.black">
													<span>{data.dc_name}</span>
												</Text>
											</td>
											<td data-label="Role">
												<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
													{data.role_name}
												</Text>
											</td>
											<td className="action" data-label="Action">
												{userDetails?.dc_userrole_mapping?.length > 1 && <Text>
													<Button variant="deleteIcon" onClick={() => showConfirmationModal('Delete the venue-role?', 'deleteRole')}>
														<icons.DeleteIcon />
													</Button>
												</Text>}
												<SweetAlert
													show={showPopup}
													confirmBtnText="Confirm"
													onConfirm={() => onSubmit(data.id)}
													showCancel={true}
													onCancel={closePopup}
													title={'Confirmation Box'}
												//  dependencies={[this.state.firstName, this.state.lastName]}
												>
													{() => <form>{modalText}</form>}
												</SweetAlert>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</TableList>
					</UserBlock>
				</AccordionBlock>

			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
			<Modal
				width="450px"
				showModal={showAssignUserRoleModal}
				setShowModal={setShowAssignUserRoleModal}
			>
				<AddExistingDCUsersModal saveUserRole={saveUser} onSelectDropdwn={onSelectDropdwn} dcList={dcList} roleList={roleList} cancel={() => toggleAssignUserRoleModal()} />
			</Modal>
			{/* <Modal
				width="450px"
				showModal={showAssignUserRoleModal}
				setShowModal={setShowAssignUserRoleModal}
			>
				<AssignUserRoleModal cancel={() => setShowModal(false)} />
			</Modal> */}

		</>
	);
}

export default DCUserDetails;
