import React, { ReactElement, useEffect, useState } from 'react';
import { Header, Footer, Stack, Button, Breadcrumb, Loader } from 'ui/components';
import {
	Sidebar,
	FormInput,
	FormTextarea,
	SuppliersMainHeading,
	SupplierReviewRating,
} from 'ui/patterns';
import { Box, Flex } from 'rebass/styled-components';
import {
	MainContent,
	RightContentHolder,
	ContentHolder,
	FooterBtnBlock,
	BreadcrumbHolder,
} from './styles';
import Ipost from 'models/Ipost';
import hooks from 'utils/requestCtx/reactQueryHooks';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import Iget from 'models/Iget';
import api from 'utils/requestCtx/api.service';
import SweetAlert from 'react-bootstrap-sweetalert';
import constants from 'utils/constants';

function SupplierReview(): ReactElement {
	const breadcrumbNav = [
		{ id: '01', label: 'Home ', active: false },
		{ id: '02', label: 'Suppliers ', active: false },
		{ id: '03', label: ``, active: true },
	];
	const [supplierRating, setSupplierRating] = useState('');
	const [reviewTitle, setReviewTitle] = useState<any>('');
	const [reviewDesc, setReviewDesc] = useState<any>('');
	const [venueId] = useState<any>(localStorage.getItem('selectedVenue'));
	const [venueUserId] = useState<any>(localStorage.getItem('userId'));
	const location = useLocation();
	const parsed = queryString.parse(location.search);
	const key = 'reviewRating';
	const [mutatePost] = hooks.useMutation(key);
	const [action, setAction] = useState('');
	const [modalText, setmodalText] = useState('');
	const [showPopup, setShowModal] = useState(false);
	const [showCancelPopup, setShowCancelPopup] = useState(false);
	const [supplierDetails, updateSupplierDetails] = useState<any>();
	const [disableSubmitButton, setDisableSubmitButton] = useState(false);
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const [loading, setLoading] = useState(false);
	const history = useHistory();

	const getSupplierRating = (e) => {
		setSupplierRating(e.target.value);
	};
	const getReviewTitle = (e) => {
		setReviewTitle(e.target.value);
	};

	const obj: Partial<Iget> = {
		params: {},
		url: 'experion/supplierreview/get/' + parsed.reviewId,
		enabled: false,
		key: 'reviewKey',
		resultFilteringRequired: false,
	};
	// var qry = hooks.useQuery(obj);

	useEffect(() => {
		if (parsed.reviewId) {
			setLoading(true);
			api.get({ url: `experion/supplierreview/get/${parsed.reviewId}` }).then(function (data) {
				setReviewTitle(data.title);
				setReviewDesc(data.review);
				setSupplierRating(data.rating);
				setLoading(false);
			}).catch(function (data) {
				setLoading(false);
			});;
		}
	}, [location]);

	const params = {
		pages: '1',
	};

	const dataObj: Partial<Iget> = {
		params: params,
		url: `experion/supplycompanyaccount/getsupplycompany/${parsed.id}`,
		enabled: true,
		key: 'suppKey',
	};

	var qryData = hooks.useQuery(dataObj);

	useEffect(() => {
		qryData.data && updateSupplierDetails(qryData.data);
	}, [qryData.data]);

	async function submitRating() {
		const CUSTOMER_TOKEN = localStorage.getItem('customerToken');
		const payload = {
			venue_user_id: parseInt(venueUserId),
			venue_user_name: localStorage.getItem('userName'),
			venue_id: parseInt(venueId),
			supplier_id: parseInt(parsed.id),
			title: reviewTitle,
			review: reviewDesc,
			verified_customer: true,
			rating: parseFloat(supplierRating),
			is_delete: false,
			status: true,
		};
		const obj: Partial<Ipost> = {
			data: { ReviewRatings: payload },
			params: {},
			url: constants.createSupplierReview(),
			message: 'Review submitted successfully',
			headers: { Authorization: CUSTOMER_TOKEN },
		};
		try {
			await mutatePost(obj).then((data) => {
				if (data != undefined) {
					history.goBack();
				}
			});
		} catch (e) { }
	}
	async function editReview() {
		const CUSTOMER_TOKEN = localStorage.getItem('customerToken');
		const payload = {
			venue_user_id: parseInt(venueUserId),
			venue_id: parseInt(venueId),
			supplier_id: parseInt(parsed.id),
			title: reviewTitle,
			review: reviewDesc,
			verified_customer: true,
			rating: parseFloat(supplierRating),
			is_delete: false,
			status: true,
		};
		const obj4: Partial<Ipost> = {
			data: { ReviewRatings: payload },
			params: {},
			url: constants.editSupplierReview() + parsed.reviewId,
			message: 'Review submitted successfully',
			headers: { Authorization: CUSTOMER_TOKEN },
		};
		try {
			api.put(obj4).then((data) => {
				if (data != undefined) {
					history.goBack();
				}
			});
		} catch (e) { }
	}

	function showConfirmationModal(text, action) {
		setAction(action);
		setmodalText(text);
		setShowModal(!showPopup);
	}
	function showCancelConfirmationModal(text, action) {
		setAction(action);
		setmodalText(text);
		setShowCancelPopup(!showCancelPopup);
	}

	const closePopup = () => {
		setShowModal(!showPopup);
	};
	const closeCancelPopup = () => {
		setShowCancelPopup(!showCancelPopup);
	};

	const onSubmitClick = () => {
		if (parsed.reviewId) {
			showConfirmationModal('Are you sure you want to update the review?', 'editReview');
		} else {
			submitRating();
		}
	};
	const cancelChanges = () => {
		setShowCancelPopup(!showCancelPopup);
		history.goBack();
	};
	const clearChanges = () => {
		setReviewTitle('');
		setReviewDesc('');
		setSupplierRating('');
		setShowCancelPopup(!showCancelPopup);
	};
	const clearCancelChanges = () => {
		if (action === 'cancelChanges') {
			cancelChanges();
		} else if (action === 'clearChanges') {
			clearChanges();
		}
	};
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	}
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={() => onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={() => onclickHambrgBtn()}></Sidebar>
			{loading === true ? <Loader></Loader> : null}

			<MainContent>
				{/* Top Breadcrumb */}
				<BreadcrumbHolder>
					{parsed?.type == 'delivery' ?
						<Breadcrumb
							Heading="Rate & Review the supplier"
							HomePage="Home"
							previousPage="Dashboard"
							previousPageSecond="Delivery Confirmation Details"
							currentPage="Rate & Review the supplier"
							type={'supplier'}
							deliveryId={parsed?.deliveryId}
						></Breadcrumb> :
						<Breadcrumb
							Heading="Rate & Review the supplier"
							HomePage="Home"
							previousPage="Suppliers"
							previousPageSecond={supplierDetails && supplierDetails.company_name}
							currentPage="Rate & Review the supplier"
							type={'supplier'}
						></Breadcrumb>
					}
				</BreadcrumbHolder>
				<ContentHolder>
					{/* Right Product Detail Session */}
					<RightContentHolder>
						{/* Supplier Main Heading , needs to hide the - order in status in below component*/}
						<Box mt={5}>
							<SuppliersMainHeading supplierDetails={supplierDetails}></SuppliersMainHeading>
						</Box>
						<Box className="RatingSession">
							<SupplierReviewRating
								getSupplierRating={getSupplierRating}
								reviewId={parsed.reviewId}
								supplierRating={supplierRating ? supplierRating : ''}
							></SupplierReviewRating>
						</Box>

						<Box as="form">
							<Flex flexDirection="column" className="review-area">
								<FormInput
									label="Add a title"
									maxLength={150}
									required={true}
									value={reviewTitle}
									onChange={(value) => getReviewTitle(value)}
									placeholder="Enter here ....."
								/>
								<FormTextarea
									label="Your Review"
									name="description"
									required={true}
									placeholder="Enter here ....."
									maxLength={2000}
									onTextChange={(value) => setReviewDesc(value)}
									value={reviewDesc}
								/>
							</Flex>
							<Flex as="div" marginTop="20px" className="point-to-note">
								<Flex as="span" className="required">
									*
								</Flex>
								The user's name and review will be shown publicly to other users
							</Flex>
						</Box>

						{/* Footer Btn Block */}
						<FooterBtnBlock>
							<Stack direction="x" gap={3} justifyContent={['center', 'flex-end']}>
								<Button
									flex={[1, 1, 'initial']}
									variant="secondary"
									onClick={() =>
										showCancelConfirmationModal(
											'Are you sure you want to cancel ?',
											'cancelChanges'
										)
									}
								>
									Cancel
								</Button>
								{!parsed.reviewId ? (
									<Button
										flex={[1, 1, 'initial']}
										disabled={reviewTitle === '' && reviewDesc === '' && supplierRating === ''}
										variant="secondary"
										onClick={() =>
											showCancelConfirmationModal(
												'Are you sure you want to clear all changes ?',
												'clearChanges'
											)
										}
									>
										Clear
									</Button>
								) : (
									''
								)}
								<Button
									disabled={reviewTitle === '' || reviewDesc === '' || supplierRating === ''}
									flex={[1, 1, 'initial']}
									onClick={onSubmitClick}
								>
									Submit
								</Button>
							</Stack>
						</FooterBtnBlock>
					</RightContentHolder>
				</ContentHolder>
				<SweetAlert
					show={showPopup}
					confirmBtnText="Confirm"
					onConfirm={() => editReview()}
					showCancel={true}
					onCancel={closePopup}
					title={'Confirmation Box'}
				//  dependencies={[this.state.firstName, this.state.lastName]}
				>
					{() => <form>{modalText}</form>}
				</SweetAlert>
				<SweetAlert
					show={showCancelPopup}
					confirmBtnText="Confirm"
					onConfirm={() => clearCancelChanges()}
					showCancel={true}
					onCancel={closeCancelPopup}
					title={'Confirmation Box'}
				//  dependencies={[this.state.firstName, this.state.lastName]}
				>
					{() => <form>{modalText}</form>}
				</SweetAlert>
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default SupplierReview;
