import { userTypes } from 'models/enum-constants/common.constants';
import React, { ReactElement, useContext, useState } from 'react';
import { Flex, Text } from 'rebass/styled-components';
import SimpleBar from 'simplebar-react';
import { RadioButton } from 'ui/components';
import * as Icon from 'ui/icons';
import appContext from 'utils/appContext';
import utilityFunctions from 'utils/utility.functions';
import {
	AddToFavorites,
	AddToFavoritesContainer,
} from '../../ui/patterns/productGridView/addToFavorites/styles';

export default function VenueSelector(props): ReactElement {
	let ctx = useContext(appContext).userObj;
	var venueUserId = localStorage.getItem('userId');
	var userType = localStorage.getItem('userType');
	//var locationOptions: HVenueDetails[];
	var locationOptions = [] as any;
	locationOptions = props.qry;
	const [addSelectedVenue, setAddSelectedVenue] = useState('');

	const handleOnChange = async (e: any) => {
		if (venueUserId) {
			let id = e.target.id;
			setAddSelectedVenue(id);
			localStorage.setItem('selectedVenue', id);
			ctx.venueId = id;
			await utilityFunctions.setPermissions(id);
			props.parentCallback();
			props.togglePopup();
		}
	};

	// const saveSelectedVenue = () => {
	//     localStorage.setItem('selectedVenue', addSelectedVenue);
	//     props.parentCallback(addSelectedVenue);
	//     props.togglePopup();
	// }
	// const closePopup = () => {
	//     props.togglePopup();
	// }

	return (
		<AddToFavorites>
			<Text as="h1" color="text.textHeading" fontSize={22} fontFamily="OpenSansBold">
				{userType == userTypes.venueUser || userType == userTypes.venueAdmin
					? 'Choose Your Store'
					: 'Choose Your Location'}
			</Text>
			<AddToFavoritesContainer>
				<SimpleBar>
					{locationOptions &&
						userType == userTypes.venueUser &&
						locationOptions
							.filter((x) => x.is_delete != true && x.is_active == true)
							.map(({ venue_id, venue_name }) => (
								<RadioButton
									id={venue_id}
									key={venue_id}
									name="paymentMethod"
									onChange={handleOnChange}
									hasActiveText
								>
									<Flex
										alignItems="center"
										width="100%"
										pl={[1, 2]}
										sx={{ transform: 'translateY(-3px)' }}
									>
										<Text as="h1" fontFamily="OpenSansSemiBold" flex="1" px={2}>
											{venue_name}
										</Text>
										{addSelectedVenue === venue_id.toString() ? <Icon.Tick /> : ``}
									</Flex>
								</RadioButton>
							))}

					{locationOptions &&
						userType == userTypes.venueAdmin &&
						locationOptions
							.filter((x) => x.is_delete != true && x.is_active == true)
							.map(({ id, name }) => (
								<RadioButton
									id={id}
									key={id}
									name="paymentMethod"
									onChange={handleOnChange}
									hasActiveText
								>
									<Flex
										alignItems="center"
										width="100%"
										pl={[1, 2]}
										sx={{ transform: 'translateY(-3px)' }}
									>
										<Text as="h1" fontFamily="OpenSansSemiBold" flex="1" px={2}>
											{name}
										</Text>
										{addSelectedVenue === id.toString() ? <Icon.Tick /> : ``}
									</Flex>
								</RadioButton>
							))}
				</SimpleBar>
			</AddToFavoritesContainer>
		</AddToFavorites>
	);
}
