import { permissionGroup, venueUserPermissionList } from 'models/enum-constants/common.constants';
import queryString from 'query-string';
import React, { ReactElement, useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { BreadcrumbNav, Footer, Header, TabsView } from 'ui/components';
import {
	FavouriteOrderListView,
	OrderListView,
	RecentOrderListView,
	Sidebar,
	PendingOrdersListView,
	PendingOrders,
	AllOrdersTab,
} from 'ui/patterns';
import AppContext from 'utils/appContext';
import utilityFunctions from 'utils/utility.functions';
import {
	BreadcrumbHolder,
	FavouriteOrderTab,
	MainContent,
	ParkedOrderTab,
	RecentOrderTab,
	TabContentHolder,
} from './styles';

function Orders(): ReactElement {
	const ctx = useContext(AppContext).productSearchObj;
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const history = useHistory();
	const location = useLocation();
	const parsed = queryString.parse(location.search);
	function checkPermissions() {
		const permArray = [] as any;
		let i = 0;
		utilityFunctions.isAnyGranted(permissionGroup.ParkedOrder)
			? permArray.push(i++)
			: permArray.push(null);
		utilityFunctions.isAnyGranted(permissionGroup.RecentOrder)
			? permArray.push(i++)
			: permArray.push(null);
		utilityFunctions.isGranted(venueUserPermissionList.FavoriteOrderView)
			? permArray.push(i++)
			: permArray.push(null);
		utilityFunctions.isAnyGranted(permissionGroup.RecentOrder)
			? permArray.push(i++)
			: permArray.push(null);
		// All Orders permission
		utilityFunctions.isGranted(venueUserPermissionList.viewAllOrders)
			? permArray.push(i++)
			: permArray.push(null);
		return permArray;
	}
	const permissionArrayIndex = checkPermissions();
	let tabIndex = 0;
	if (parsed.tab == 'parked-orders' || parsed.tab == 'parked-orders#!') {
		tabIndex = permissionArrayIndex[0];
	} else if (parsed.tab == 'my-orders' || parsed.tab == 'my-orders#!') {
		tabIndex = permissionArrayIndex[1];
	} else if (parsed.tab == 'favourite-orders' || parsed.tab == 'favourite-orders#!') {
		tabIndex = permissionArrayIndex[2];
	} else if (parsed.tab == 'pending-orders' || parsed.tab == 'pending-orders#!') {
		tabIndex = permissionArrayIndex[3];
	} else if (parsed.tab == 'all-orders' || parsed.tab == 'all-orders#!') {
		tabIndex = permissionArrayIndex[4];
	}

	const setTabIndex = (index) => {
		ctx.SortField = '';
		ctx.SortDirection = '';
		switch (index) {
			case permissionArrayIndex[0]:
				history.push('/store/orders?tab=parked-orders');
				break;
			case permissionArrayIndex[1]:
				history.push('/store/orders?tab=my-orders');
				break;
			case permissionArrayIndex[2]:
				history.push('/store/orders?tab=favourite-orders');
				break;
			case permissionArrayIndex[3]:
				history.push('/store/orders?tab=pending-orders');
				break;
			case permissionArrayIndex[4]:
				history.push('/store/orders?tab=all-orders');
				break;
		}
	};

	const breadcrumbNav = [
		{ id: '01', label: 'Home', route: '/home/dashboard', active: false },
		{ id: '03', label: 'Order Management', active: true },
	];
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	};
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={() => onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={() => onclickHambrgBtn()}></Sidebar>

			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbHolder>
					<BreadcrumbNav Heading="Orders" breadcrumbList={breadcrumbNav} />
				</BreadcrumbHolder>
				<TabContentHolder>
					<TabsView className="tablistBlock">
						<Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
							<TabList>
								{utilityFunctions.isAnyGranted(permissionGroup.ParkedOrder) && (
									<Tab>Parked Orders</Tab>
								)}
								{utilityFunctions.isAnyGranted(permissionGroup.RecentOrder) && (
									<Tab>My Orders</Tab>
								)}
								{utilityFunctions.isGranted(venueUserPermissionList.FavoriteOrderView) && (
									<Tab>Favourite Orders</Tab>
								)}
								{utilityFunctions.isAnyGranted(permissionGroup.RecentOrder) && (
									<Tab>Pending Orders</Tab>
								)}
								{utilityFunctions.isGranted(venueUserPermissionList.viewAllOrders) && (
									<Tab>All Orders</Tab>
								)}
							</TabList>
							{utilityFunctions.isAnyGranted(permissionGroup.ParkedOrder) && (
								<TabPanel>
									<ParkedOrderTab>
										<OrderListView></OrderListView>
									</ParkedOrderTab>
								</TabPanel>
							)}

							{utilityFunctions.isAnyGranted(permissionGroup.RecentOrder) && (
								<TabPanel>
									<RecentOrderTab>
										<RecentOrderListView></RecentOrderListView>
									</RecentOrderTab>
								</TabPanel>
							)}
							{utilityFunctions.isGranted(venueUserPermissionList.FavoriteOrderView) && (
								<TabPanel>
									<FavouriteOrderTab>
										<FavouriteOrderListView></FavouriteOrderListView>
									</FavouriteOrderTab>
								</TabPanel>
							)}
							{utilityFunctions.isAnyGranted(permissionGroup.RecentOrder) && (
								<TabPanel>
									<PendingOrdersListView />
								</TabPanel>
							)}
							{/* All Orders permission */}
							{utilityFunctions.isGranted(venueUserPermissionList.viewAllOrders) && (
								<TabPanel>
									<AllOrdersTab />
								</TabPanel>
							)}
						</Tabs>
					</TabsView>
				</TabContentHolder>
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default Orders;
