import styled, { css } from 'styled-components';

type NavProps = {
	hide?: boolean;
	show?: boolean;
};
const AppNestedMenu = styled.div`
	.rc-menu-button {
		background: transparent;
		border: none;
		padding: 0;
		color: ${({ theme }) => theme.colors.text.white};
		font-size: 16px;
		line-height: 21px;
		text-decoration: none;
		display: flex;
		position: relative;
		min-height: 50px;
		padding-right: 10px;
		padding-left: 10px;
		cursor: pointer;
		font-family: 'OpenSansSemiBold';
		outline: none;
		align-items: center;
		justify-content: center;
		margin-right: -15px;
		@media (max-width: 1280px) {
			font-size: 14px;
			line-height: 16px;
			margin-left: 15px;
		}
		@media (max-width: 1152px) {
			font-size: 13px;
			margin-left: 10px;
		}

		@media (max-width: 960px) {
			line-height: 16px;
			margin-left: 0;
			padding-left: 15px;
			svg {
				height: 12px !important;
				width: 12px !important;
			}
		}
		@media (max-width: 480px) {
			font-size: 12px;
			line-height: 16px;
		}
		svg {
			margin-left: 15px;
			path {
				fill: ${({ theme }) => theme.colors.text.white};
			}
		}
	}
	.rc-menu {
		box-shadow: 0px 4px 24px rgb(0 0 0 / 39%);
		border-radius: 0 0 10px 10px;
		li {
			border-bottom: 1px solid rgba(125, 55, 111, 0.12);
			&:hover {
				background: ${({ theme }) => theme.baseColors.primary};
				color: ${({ theme }) => theme.colors.text.white};
			}
		}
	}
	.rc-menu__item--hover {
		background: ${({ theme }) => theme.baseColors.primary};
		&:after {
			color: ${({ theme }) => theme.colors.text.white}!important;
		}
	}
	.rc-menu__item--active {
		background: ${({ theme }) => theme.baseColors.primary};
		color: ${({ theme }) => theme.colors.text.white}!important;
	}
	.rc-menu__item {
		font-size: 14px;
		padding: 0.6rem 1.5rem;
		&:after {
			color: ${({ theme }) => theme.baseColors.primary};
		}
		&:hover {
			&:after {
				color: ${({ theme }) => theme.colors.text.white}!important;
			}
		}
		&:hover,
		&:focus,
		&:active {
			background: ${({ theme }) => theme.baseColors.primary};
		}
	}
`;

const Nav = styled.nav<NavProps>`
	display: flex;
	align-items: center;

	@media (max-width: 960px) {
		margin: 20px 0 !important;
		justify-content: space-between;
	}
	a {
		color: ${({ theme }) => theme.colors.text.white};
		font-size: 16px;
		line-height: 21px;
		text-decoration: none;
		margin-left: 50px;
		display: flex;
		align-items: center;
		position: relative;
		min-height: 50px;
		padding-right: 15px;
		padding-left: 10px;
		cursor: pointer;
		font-family: 'OpenSansSemiBold';
		&:hover {
			color: #ffffff;
		}
		& > div {
			top: 0;
			position: absolute;
			width: 100%;
		}
		button {
			background: transparent;
			border: none;
			width: 100%;
			min-height: 50px;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			position: absolute;
			outline: none;
			cursor: pointer;
			svg {
				path {
					fill: ${({ theme }) => theme.colors.text.white};
				}
			}
		}
		@media (max-width: 1280px) {
			margin-left: 15px;
		}
		@media (max-width: 1152px) {
			margin-left: 10px;
		}
		@media (max-width: 960px) {
			margin-left: 0 !important;
			float: left;
			width: 100%;
		}
		@media (max-width: 480px) {
			font-size: 12px;
			line-height: 16px;
			margin-left: 0;
		}
		&:first-child {
			margin-left: 0;
		}

		.dropdownMenu {
			button {
				& + div {
					margin-top: 52px;
					border-radius: 0 0 10px 10px;
					left: 0;
					right: 0;
					min-width: 150px;
					z-index: 1;
					button {
						position: relative;
						min-height: auto;
						padding: 10px 10px;
						margin: 0;
						&:focus {
							${({ theme }) => theme.baseColors.primary};
						}
						&::before {
							left: 0;
							right: 0;
						}
					}
				}
			}
		}
	}
	&.navbar-nav {
		@media (max-width: 960px) {
			display: none;
		}
	}
	${({ hide }) =>
		hide &&
		css`
			display: none;
		`}
	${({ show }) =>
		show &&
		css`
			display: block;
		`}
	.suppliersMenu,
	.categoryMenu {
		margin-left: 50px;
		margin-right: 20px;

		button {
			background: transparent !important;
			border: none !important;
			box-shadow: none !important;
			padding: 0;
			color: ${({ theme }) => theme.colors.text.white};
			font-size: 16px;
			line-height: 21px;
			text-decoration: none;
			display: flex;
			position: relative;
			min-height: 50px;
			padding-right: 10px;
			padding-left: 10px;
			cursor: pointer;
			font-family: 'OpenSansSemiBold';
			outline: none;
			align-items: center;
			justify-content: center;
			display: flex;
			border-radius: 6px;
			span {
				align-items: center;
				justify-content: center;
				display: flex;
				position: relative;
			}
			svg {
				position: absolute;
				right: -23px;
				top: -2px;
				width: 10px;
				height: 10px;
				path {
					fill: #fff;
				}
			}
		}
		@media (max-width: 1280px) {
			margin-left: 15px;
		}
		@media (max-width: 960px) {
			margin: 0 0 10px 0 !important;
			float: left;
			width: 100%;
			button {
				background: ${({ theme }) => theme.baseColors.primary}!important;
				margin: 0 !important;
				width: 100%;
				right: 0;
				top: 0;
				font-size: 14px !important;
				justify-content: space-between;
				.bp3-button-text {
					width: 100%;
					justify-content: space-between;
				}
				svg {
					position: absolute;
					right: 4px;
					top: -6px;
					width: 14px !important;
					height: 14px !important;
				}
			}
		}
	}
	.suppliersMenu {
		@media (max-width: 960px) {
			.pr-icon {
				display: none;
			}
		}
		.supplierIcon {
			display: none;
			@media (max-width: 960px) {
				display: block;
				font-size: 20px;
				span {
					width: 2rem;
					height: 2rem;
					text-align: center;
					line-height: 2rem;
					&.down {
					}
					&.up {
						display: none;
					}
				}
			}
		}
		&.bp3-active {
			span {
				.down {
					display: none;
				}
				.up {
					display: block !important;
				}
			}
		}
	}
	.categoryMenu {
		margin-left: 0;
		@media (max-width: 960px) {
			display: none;
		}
	}
`;
const HeaderSupplierMenu = styled.div`
	display: flex;
	grid-template-columns: repeat(4, 1fr) !important;
	text-align: center;
	align-items: center;
	justify-content: center;
	margin: auto;
	a {
		font-size: 16px !important;
		color: ${({ theme }) => theme.colors.text.white};
		font-family: 'OpenSansSemiBold';
		text-decoration: none;
		margin: 0 20px;
		@media (max-width: 767px) {
			font-size: 13px !important;
			margin: 0 10px;
		}

		text-transform: capitalize;
		&:hover {
			opacity: 0.8;
		}
	}
	.supplierIcon {
		display: none;
	}
`;

const TreeMenuHolder = styled.div`
	display: none;
	margin-bottom: 10px;
	ul {
		margin: 0;
		padding: 0;
		max-height: 400px;
		overflow: hidden;
		overflow-y: auto;
		border: 1px solid #efe8ed;
		border-radius: 6px;
		.menuItem {
			&:first-child {
				position: sticky;
				top: 0;
				z-index: 999999;
			}
		}
		li {
			display: inline-block;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.rstm-toggle-icon {
				order: 1;
				font-size: 20px;
			}
		}
		div {
			background: ${({ theme }) => theme.baseColors.primary}!important;
			color: ${({ theme }) => theme.baseColors.white}!important;

			border-radius: 6px;
			overflow: hidden;

			li {
				overflow: hidden;
				padding: 9px 10px !important;
				color: ${({ theme }) => theme.baseColors.white}!important;
				font-family: 'OpenSansSemiBold';
				border: none;
				font-size: 14px;
				&.rstm-tree-item--active {
					background: ${({ theme }) => theme.baseColors.primary}!important;
					border-radius: 6px !important;
				}
				&.rstm-tree-item--focused {
					box-shadow: none;
					border-radius: 6px !important;
				}
				&.rstm-tree-item-level2 {
					padding: 9px 10px 9px 30px !important;
				}
				&.rstm-tree-item-level3 {
					padding: 9px 10px 9px 50px !important;
				}
			}
			+ div {
				background: transparent !important;
				li {
					color: #182026 !important;
					font-size: 14px;
					border-bottom: 1px solid rgba(125, 55, 111, 0.12);
				}
				div {
					background: transparent !important;
					color: #182026 !important;

					li {
						color: #182026 !important;
						font-size: 14px;
					}
				}
			}
		}
	}
	@media (max-width: 960px) {
		display: block;
	}
`;
export { Nav, AppNestedMenu, HeaderSupplierMenu, TreeMenuHolder };
