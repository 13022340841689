import { OrderStatuses, venueUserPermissionList } from 'models/enum-constants/common.constants';
import React, { ReactElement, useState, useContext } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory } from 'react-router-dom';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { Button, Loader } from 'ui/components';
import icons, * as Icon from 'ui/icons';
import { Modal, OrderGrid } from 'ui/patterns';
import env from 'utils/constants';
import { default as helper, default as helperFunctions } from 'utils/helper.functions';
import pageConstants from 'utils/pageConstants';
import api from 'utils/requestCtx/api.service';
import utilityFunctions from 'utils/utility.functions';
import CancelOrderMessageModal from './cancelOrderMessage';
import NewFavoritesModal from './newFavorites';
import AppContext from 'utils/appContext';
import { UseGlobalState } from 'models/globalStateAccess';
import hooks from 'utils/requestCtx/reactQueryHooks';
import Iget from 'models/Iget';
import Ipost from 'models/Ipost';

const StyledRecentOrderDetailsEditView = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	column-gap: 20px;
	row-gap: 20px;
	margin-bottom: 16px;
	.order-details__wrap {
		flex-direction: row;
		background: ${({ theme }) => theme.colors.bg.white};
		border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
		box-sizing: border-box;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 16px;
		overflow: hidden;
		transition: box-shadow 100ms ease-in, transform 100ms ease-in;
		align-items: flex-start;
		height: 100%;
		display: flex;
		span {
			max-width: 117px;
			flex: 0 0 117px;
			height: 100%;
			background-repeat: no-repeat;
			background-size: contain;
			@media (max-width: 890px) {
				max-width: 126px;
				flex: 0 0 126px;
				background-position: center center;
			}
			@media (max-width: 360px) {
				max-width: 80px;
				flex: 0 0 80px;
			}
		}
		section {
			display: grid;
			grid-template-columns: repeat(1, 1fr) !important;
			align-items: center;
			height: 100%;
			padding: 25px;
			@media (max-width: 890px) {
				label {
					width: 100%;
					margin: 0;
					line-height: normal;
				}
			}

			@media (max-width: 480px) {
				padding: 15px;
			}
			.order-row {
				display: grid;
				grid-template-columns: repeat(6, 1fr);
				row-gap: 10px;
				column-gap: 10px;
				div {
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					display: block;
					width: 100%;
				}
				.order-col {
					flex: 0 1 300px;
					min-width: 300px;
					justify-self: auto;
				}
				@media (max-width: 1540px) {
					grid-template-columns: repeat(4, 1fr);
				}
				@media (max-width: 960px) {
					grid-template-columns: repeat(3, 1fr);
				}

				@media (max-width: 1268px) {
					.order-col {
						min-width: 100%;
					}
				}
				@media (max-width: 640px) {
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					.order-col {
						flex: 0 1 auto;
					}
				}
				@media (max-width: 340px) {
					grid-template-columns: repeat(1, 1fr);
				}
			}
			.order-amount-action {
				label {
					display: block;
				}
			}
			.btn-block {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				flex-wrap: wrap;
				width: 100%;
				margin-top: 20px;
				@media (max-width: 640px) {
					justify-content: flex-start;
					flex-wrap: wrap;
					margin-top: 20px !important;
					button {
						margin-top: 5px;
						flex: auto !important;
						span {
							white-space: nowrap;
						}
					}
					div {
						width: 100%;
						span {
							max-width: none !important;
						}
					}
				}
				span {
					height: auto;
					flex: 0 0 auto;
					max-width: auto;
					min-width: auto;
				}
				button {
					height: 50px;
					padding: 0 !important;
					width: 50px;
					flex: 0 0 50px;
					margin-left: 10px;
					margin-top: 5px;
					margin-bottom: 5px;
					@media (max-width: 890px) {
						margin-left: 0;
						margin-right: 10px;
					}
					span {
						height: auto;
						min-height: auto;
					}
					svg {
						width: 20px;
						height: 20px;
						path {
							fill: ${({ theme }) => theme.baseColors.primary};
						}
					}
					&:hover,
					&:focus {
						svg {
							width: 20px;
							height: 20px;
							path {
								fill: ${({ theme }) => theme.baseColors.white};
							}
						}
					}
					+ button {
						display: flex;
						align-items: center;
						text-transform: capitalize;
						white-space: nowrap;
						padding: 0 1.5em !important;
						width: auto;

						@media (max-width: 480px) {
							font-size: 13px;
							padding: 0 10px !important;

							white-space: nowrap;
						}
						span {
							max-width: 100% !important;
							min-height: auto;
						}
						+ div button {
							width: auto;
							padding: 0 1.5em !important;
						}
					}
				}

				icon {
					display: inline-block;
				}
				div {
					word-break: break-word;
				}
			}
			object {
				label {
					display: flex;
					div {
						margin: 4px 0 0 4px;
						cursor: pointer;
						width: auto !important ;
					}
					svg {
						width: 16px;
						height: 16px;
					}
				}
			}
			main {
				grid-column: 8 / -1;
				grid-row: 1 / 1;
				@media (max-width: 1600px) {
					grid-column: 7 / 1;
					grid-row: 2 / 2;
				}
				@media (max-width: 1152px) {
					grid-column: 6 / 2;
				}
				@media (max-width: 960px) {
					grid-row: auto;
					div {
						justify-self: start;
					}
				}
				@media (max-width: 767px) {
					grid-column: 3 / 1;
				}
				@media (max-width: 640px) {
					grid-column: 2 / 1;
				}
			}
			div + div {
				@media (max-width: 960px) {
					display: grid;
				}
			}
			.totalProducts {
				text-align: right !important;
				white-space: nowrap !important;
				flex: 0 0 125px !important;
				max-width: 125px !important;
				justify-content: flex-end !important;
				min-width: 125px !important;
				label {
					justify-content: flex-end !important;
				}
			}
		}
		&.selected {
			background: #f0e9da;
		}
	}
`;

function RecentOrderDetailsEditView({
	data,
	eligibleForCancel,
	cancelOrders,
	supplierCount,
}): ReactElement {
	const ctx = useContext(AppContext).cartObj;
	const history = useHistory();
	const [showNewFavoritesModal, setShowNewFavoritesModal] = useState(false);
	const [showCancelOrderMessageModal, setShowCancelOrderMessageModal] = useState(false);

	const toggleNewFavoritesModal = (id, qty) => {
		setObj({ product_id: id, qty: qty });
		setShowNewFavoritesModal(!showNewFavoritesModal);
	};
	const toggleCancelOrderMessageModal = () => {
		setShowCancelOrderMessageModal(!showCancelOrderMessageModal);
	};
	const [postObj, setObj] = useState({} as any);
	const [modalText, setmodalText] = useState('');
	const [showPopup, setShowModal] = useState(false);
	const [action, setAction] = useState('');
	const [message, setMessage] = useState('');
	const [showOrderGridModal, setShowOrderGridModal] = useState(false);
	const [productGridData, setProductGridData] = useState([]);
	const [disableBtnMove, setDisableBtnMove] = useState(false);
	const [productsToMoveArr, setProductsToMoveArr] = useState([] as any);
	const [venueUserId, setVenueUserId] = useState<any>(localStorage.getItem('userId'));
	const [venueId, setVenueId] = useState<any>(localStorage.getItem('selectedVenue'));
	const [state, dispatch] = UseGlobalState();
	const [isLoading, setIsLoading] = useState(false);

	function confirm(action, id, qty) {
		setAction(action);
		switch (action) {
			case 'favorite':
				showConfirmationModal('Delete order?', id, qty);
				break;
			case 'order':
				showConfirmationModal('Order again ?', id, qty);
				break;
			case 'cancelOrder':
				showConfirmationModal('Cancel order?', id, qty);
				break;
			default:
				break;
		}
	}
	function showConfirmationModal(text, id = 0, qty = 0) {
		if (id != 0) setObj({ product_id: id, qty: qty });
		setmodalText(text);
		setShowModal(!showPopup);
	}
	async function closePopup() {
		await setShowModal(!showPopup);
	}
	function onSubmit() {
		setShowModal(!showPopup);
		if (action == 'order') addItemsToCart();
		else if (action == 'cancelOrder') cancelOrder();
	}

	let cartId: any;
	cartId = localStorage.getItem('cartId');

	async function addItemsToCart() {
		setIsLoading(true);
		let id: any;
		const CUSTOMER_TOKEN = localStorage.getItem('customerToken');
		id = await utilityFunctions.getCartId(CUSTOMER_TOKEN);
		if (id !== undefined) {
			if (productsToMoveArr.length > 0) {
				const payload = {
					products: {
						quote_id: parseInt(id),
						items: productsToMoveArr,
						venue_user_id: parseInt(venueUserId),
						venue_id: parseInt(venueId),
						reorder: 1,
					},
				};
				const obj: Partial<Ipost> = {
					data: payload,
					key: 'recentToCart',
					url: env.multiCartURL(),
					message: 'Your cart has been updated!',
					headers: { Authorization: localStorage.getItem('customerToken') },
				};

				try {
					await api
						.post(obj)
						.then(function (data) {
							if (data !== undefined) {
								setIsLoading(false);
								fetchTotal();
							} else {
								setIsLoading(false);
							}
						})
						.catch(function (data) {
							setIsLoading(false);
						});
				} catch (e) {
					setIsLoading(false);
				}
			}
		} else {
			setIsLoading(false);
		}
	}

	//api to get total amount
	const url1 = '/carts/' + cartId + '/totals';

	const obj6: Partial<Iget> = {
		params: {},
		url: url1,
		enabled: false, //manual fetch
		key: 'carttotalKey1',
		resultFilteringRequired: false,
	};
	const qryTotal = hooks.useQuery(obj6);

	const fetchTotal = () => {
		qryTotal.refetch().then((data) => {
			if (data) {
				updateCartTotal(data);
			}
		});
	};

	function updateCartTotal(data) {
		if (data) {
			const datas = data.items?.filter((x) => x.is_deleted != true);
			if (datas?.length > 0) {
				dispatch({ cartTotal: helperFunctions.formatAmount(data.grand_total) });
				dispatch({ cartItemCount: datas?.length });
				// setTotalCount(data.subtotal);
			} else {
				dispatch({ cartItemCount: '0' });
				dispatch({ cartTotal: '' });
				// setTotalCount(0);
			}
		} else {
			dispatch({ cartItemCount: '0' });
			dispatch({ cartTotal: '' });
			// setTotalCount(0);
		}
	}

	function updateList() {}

	function cancelOrderPopup(message) {
		setMessage(message);
		setAction('cancelOrder');
		showConfirmationModal('Cancel the order?');
	}

	function cancelOrder() {
		cancelOrders(message);
		setShowModal(!showPopup);
	}

	function getStatusList(status) {
		const data = [] as any;
		if (status && status.length == 1) {
			data.push(JSON.parse(status));
		} else if (status && status.length > 1) {
			const items = JSON.parse(JSON.stringify(status));
			items.map((v) => {
				data.push(JSON.parse(v));
			});
		}
		return data;
	}
	const statusList = getStatusList(data.status);
	const orderStatus = utilityFunctions.getOrderStatus(statusList.map((x) => x.status));

	function criteriaCheck(id) {
		const payload = {
			reorder: {
				quote_id: localStorage.getItem('cartId'),
				venue_id: localStorage.getItem('selectedVenue'),
				group_order_id: id,
			},
		};
		api
			.post({
				data: payload,
				url: 'experion/grouporder/reorder/',
				key: 'recentToCart',
				message: ' ',
			})
			.then(function (data) {
				if (data != undefined) {
					const cart_status = data[0].cart_status;
					const productsToMoveArr = [] as any;

					const productData = data[0].order_details.map((x) =>
						utilityFunctions.OrderGridProductData(
							x.product_data,
							x.sensitive_data,
							x.non_sensitive_data
						)
					);

					const productDataArray = productData.map((x) =>
						utilityFunctions.productsAvailable(x.product_id, x.qty_ordered, x.sensWarning)
					);
					productDataArray.map((x) => {
						if (x && x.productsObj && Object.keys(x.productsObj).length) {
							productsToMoveArr.push(x.productsObj);
						}
					});

					setProductsToMoveArr(productsToMoveArr);
					if (productsToMoveArr.length > 0) {
						setDisableBtnMove(false);
					} else {
						setDisableBtnMove(true);
					}

					if (cart_status == true) {
						confirm('order', id, 0);
					} else if (cart_status == false) {
						setProductGridData(productData);
						setShowOrderGridModal(!showOrderGridModal);
					}
				}
			});
	}

	return (
		<>
			{isLoading ? <Loader></Loader> : null}
			<StyledRecentOrderDetailsEditView>
				<Flex
					className="order-details__wrap selected"
					bg="white"
					sx={{ alignItems: 'center', background: '#f5f5f5 !important' }}
				>
					<Flex as="section" flexWrap="wrap" width="100%" maxWidth={['100%']}>
						<Box>
							<Box className="order-row">
								<Box className="order-col">
									<Text mb={1} fontSize={18} color="text.black">
										Order ID
									</Text>
									<Text as="label" fontSize={20} fontFamily="OpenSansBold" color="text.black">
										{'#' + data.order_group_increment_id}
									</Text>
								</Box>

								<Box className="order-col">
									<Text mb={1} fontSize={18} color="text.black">
										Created
									</Text>
									<Text as="label" fontSize={20} fontFamily="OpenSansBold" color="text.black">
										{helper.formatDate(data.created_at)}
									</Text>
								</Box>
								<Box
									as="object"
									className="order-col"
									onClick={(e) => {
										e.stopPropagation();
									}}
								>
									<Text as="label" fontSize={16} fontWeight="normal" color="#242B24">
										Status
										<Tooltip
											arrow
											animation="perspective"
											position="right"
											html={
												<div className="tooltip-body tooltip-content statusTooltip">
													{statusList &&
														statusList.map((v) => {
															return (
																<span key={helperFunctions.getRandomInt(pageConstants.maxValue)}>
																	<Text
																		as="label"
																		fontSize="17px"
																		fontFamily="OpenSansRegular"
																		color="text.black03"
																	>
																		{`${v.supplier_name} :`}
																		<strong>
																			{helperFunctions.toPascalCase(
																				v.status == 'order_rejected'
																					? 'Rejected'
																					: v.status?.replace('_', ' ')
																			)}
																		</strong>
																	</Text>
																</span>
															);
														})}
												</div>
											}
										>
											{data.status?.length > 0 && <icons.InfoIconRound />}
										</Tooltip>
									</Text>
									<Text fontSize={18} fontWeight="bold" color="#242B24">
										{helperFunctions.toPascalCase(
											orderStatus == 'order_rejected' ? 'Rejected' : orderStatus?.replace('_', ' ')
										)}
									</Text>
								</Box>
								<Box as="object" className="order-col totalProducts">
									<Text mb={1} fontSize={18} color="text.black">
										Total Products
									</Text>
									<Text as="label" fontSize={20} fontFamily="OpenSansBold" color="text.black">
										{data.no_of_items}
									</Text>
								</Box>
								<Box className="order-col totalProducts" as="object">
									<Text mb={1} fontSize={18} color="text.black">
										Total Suppliers
									</Text>
									<Text as="label" fontSize={20} fontFamily="OpenSansBold" color="text.black">
										{supplierCount}
									</Text>
								</Box>
								<Box as="object" className="order-amount-action">
									<Text mb={1} fontSize={18} color="text.black">
										Amount
									</Text>
									<Text as="label" fontSize={20} fontFamily="OpenSansBold" color="text.black">
										{helper.formatAmount(data.cart_total)}
									</Text>
								</Box>
							</Box>
						</Box>
						<Box>
							<Box as="main">
								<div className="btn-block">
									{utilityFunctions.isGranted(venueUserPermissionList.FavoriteOrderAdd) && (
										<Button
											variant="secondary"
											className="Favorites"
											onClick={() => {
												toggleNewFavoritesModal(data.order_group_id, data.no_of_items);
											}}
										>
											<Icon.FavoritesIcon title="FavoritesIcon" />
										</Button>
									)}
									{utilityFunctions.isGranted(venueUserPermissionList.ManageProductsinCart) && (
										<Button onClick={() => criteriaCheck(data.order_group_id)}>Order Again</Button>
									)}
									<Tooltip
										arrow
										title={
											!eligibleForCancel
												? orderStatus == OrderStatuses.Pending
													? 'Cut off time exceeded'
													: 'Order has been processed'
												: ''
										}
										animation="perspective"
										position="top"
										trigger="mouseenter"
									>
										{(utilityFunctions.isGranted(venueUserPermissionList.RecentOrdersCancel) ||
											utilityFunctions.isGranted(venueUserPermissionList.RecentOrdersAdmin)) && (
											<Button
												title={
													!eligibleForCancel
														? orderStatus == OrderStatuses.Pending
															? 'Cut off time exceeded'
															: 'Order has been processed'
														: ''
												}
												disabled={!eligibleForCancel}
												onClick={toggleCancelOrderMessageModal}
												variant="secondary"
											>
												Cancel Order
											</Button>
										)}
									</Tooltip>
								</div>
							</Box>
						</Box>
					</Flex>
				</Flex>
				<Modal
					width="500px"
					showModal={showNewFavoritesModal}
					setShowModal={setShowNewFavoritesModal}
				>
					<NewFavoritesModal
						updateList={updateList}
						showModal={showNewFavoritesModal}
						setShowModal={setShowNewFavoritesModal}
						orderId={postObj.product_id}
					/>
				</Modal>
				<Modal
					width="500px"
					showModal={showCancelOrderMessageModal}
					setShowModal={setShowCancelOrderMessageModal}
				>
					<CancelOrderMessageModal
						showModal={showCancelOrderMessageModal}
						setShowModal={setShowCancelOrderMessageModal}
						cancelOrder={cancelOrderPopup}
					/>
				</Modal>
				<SweetAlert
					show={showPopup}
					confirmBtnText="Confirm"
					onConfirm={onSubmit}
					showCancel={true}
					onCancel={closePopup}
					title={'Confirmation Box'}
				>
					{() => (
						<form>
							<hr />
							{modalText}
							<hr />
						</form>
					)}
				</SweetAlert>
			</StyledRecentOrderDetailsEditView>
			<Modal width="800px" showModal={showOrderGridModal} setShowModal={setShowOrderGridModal}>
				<OrderGrid
					data={productGridData}
					setShowModal={setShowOrderGridModal}
					productsToMoveArr={productsToMoveArr}
					showModal={showOrderGridModal}
					disableBtnMove={disableBtnMove}
					fetchTotal={fetchTotal}
					utilityFunctions={utilityFunctions}
					setIsLoading={setIsLoading}
				/>
			</Modal>
		</>
	);
}

export { RecentOrderDetailsEditView };
