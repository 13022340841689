import styled from 'styled-components';

const MainContent = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
	padding: 0 50px 30px;
	@media (max-width: 960px) {
		padding: 0 15px 30px;
	}
	table {
		@media (max-width: 1300px) {
			width: 100%;
		}
		@media (max-width: 960px) {
			width: 111vw;
		}
		@media (max-width: 768px) {
			width: 100%;
		}
		thead,
		tbody {
			tr {
				td,
				th {
					&:nth-child(1) {
						flex: 0 0 250px;
						max-width: 250px;
						h1 {
							display: flex;
							align-items: center;
							font-family: 'OpenSansBold';
							img {
								border: 1px solid #faf4ea;
								object-fit: contain;
								background: rgba(255, 255, 255, 1);
							}
						}
						@media screen and (max-width: 768px) {
							flex: 0 0 66.66% !important;
							max-width: 66.66% !important;
							margin-bottom: 15px;
							img{
								margin-right: 10px;
							}
							h1 {
								max-width: 400px;
							}
						}
						@media screen and (max-width: 480px) {
							flex: 0 0 100% !important;
							max-width: 100% !important;
							h1 {
								max-width: 280px;
							}
						}
						@media screen and (max-width: 340px) {
							flex: 0 0 100% !important;
							max-width: 100% !important;
							h1 {
								max-width: 230px;
							}
						}
					}
				}
			}
		}
		tbody {
			margin-bottom: 0;
		}
	}
`;
const ContentHolder = styled.div`
	height: 100%;
	padding: 0 50px;
	padding-bottom: 30px;
	width: 100%;

	@media (max-width: 768px) {
		padding: 0 15px;
	}
`;
const CardsAndNotificationHolder = styled.div`
	width: 100%;
	display: flex;
	@media (max-width: 860px) {
		flex-direction: column;
	}
`;
const TabContentHolder = styled.div`
	width: 100%;
	display: flex;
	margin-top: 50px;
`;
const BreadcrumbHolder = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media (max-width: 640px) {
		flex-direction: column;
		align-items: end;
		& > div {
			padding-bottom: 15px;
		}
	}
	div {
		width: 100%;
		div {
			width: auto;
		}
	}
`;
const ButtonWrapper = styled.div`
	display: flex;
	width: 100%;
	justify-content: flex-end;
	@media (max-width: 480px) {
		padding-bottom: 0 !important;
	}
	.app-c-csv-uploaded {
		label {
			font-size: 12px;
			margin-bottom: 3px;
		}
		p {
			font-size: 16px;
		}
		.pr-icon {
			margin-right: 0;
			margin-left: 15px;
			svg {
				path {
					fill: ${({ theme }) => theme.colors.text.white};
				}
			}
		}
	}
	button {
		display: flex !important;
		font-size: 18px;
		text-transform: capitalize;
		height: 50px;
		padding-top: 0;
		padding-bottom: 0;
		margin-left: 15px;
		white-space: nowrap;
		flex: 0;
		@media (max-width: 1200px) {
			font-size: 14px;
			width: 40px;
			height: 40px;
			max-width: 40px;
			flex: 0 0 40px;
			padding: 0;
			align-items: center;
			justify-content: Center;
			div,
			label {
				display: none;
			}
			.pr-icon {
				margin-right: 0 !important;
			}
		}
		&:hover {
			svg {
				path {
					fill: ${({ theme }) => theme.colors.text.white};
				}
			}
		}
		span {
			display: flex;
			align-items: center;
		}
		+ button {
			margin-left: 15px;
		}
		.pr-icon {
			margin-right: 15px;
		}
	}
	.app-c-file-upload {
		position: relative;
		cursor: pointer;
		label {
			white-space: nowrap;
		}
		span {
			width: 100%;
			height: 100%;
		}
		input[type='file'] {
			position: absolute;
			left: 0;
			top: 0;
			opacity: 0;
			width: 100%;
			height: 100%;
		}
		.pr-button-label {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			div {
				display: flex;
			}
			h2 {
				width: calc(100% + 40px);
				font-size: 13px;
				font-family: 'OpenSansRegular';
				padding: 3px 0;
				margin: 0 -20px;
				max-width: 180px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
			@media (max-width: 1200px) {
				h2 {
					display: none;
				}
			}
		}
	}
`;
export {
	MainContent,
	ContentHolder,
	CardsAndNotificationHolder,
	TabContentHolder,
	BreadcrumbHolder,
	ButtonWrapper,
};
