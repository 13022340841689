import { Select } from '@rebass/forms';
import Countries from 'models/countryList';
import Iget from 'models/Iget';
import React, { ReactElement, useState } from 'react';
import { Box, Text } from 'rebass/styled-components';
import { BreadcrumbNav, Button, Footer, Grid, Header, Stack, Toggle } from 'ui/components';
import * as Icon from 'ui/icons';
import { FormInput, Sidebar } from 'ui/patterns';
import hooks from 'utils/requestCtx/reactQueryHooks';
import { AddUserHolder, FooterBtnBlock, MainContent, ProfileDetails } from './styles';



function VenueDetailsNewUsers(): ReactElement {
	const breadcrumbNav = [
		{ id: '01', label: 'Home', active: false },
		{ id: '02', label: 'Dashboard', active: false },
		{ id: '03', label: 'Store', active: false },
		{ id: '04', label: 'Store Details', active: false },
		{ id: '05', label: 'Add Users', active: true },
	];
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const params = {
		pages: '1',
	};
	const key = 'addUser';
	const wholeList: Countries = {
		countries: [],
	};
	const url = 'directory/countries';
	const obj: Partial<Iget> = {
		params: params,
		url: url,
		enabled: true,
		key: key,
	};
	const qry = hooks.useQuery(obj);
	wholeList.countries = qry.data;
	const [userDetails, setUserDetails] = useState({
		first_name: '',
		last_name: '',
		mob_number: '',
		email_address: '',
		state: '',
		is_active: false,
	});
	const [error, setError] = useState({
		first_name_Error: '',
		last_name_Error: '',
		mob_number_Error: '',
		email_address_Error: '',
		stateError: '',
	});

	const toggleBtnChange = (e) => {
		const checkedProp = e.currentTarget.checked;
		setUserDetails({ ...userDetails, is_active: checkedProp });
	};

	const onTextChange = (val, name) => {
		const item = userDetails;
		switch (name) {
			case 'firstName': {
				if (!val) {
					setError({ ...error, first_name_Error: 'First name cannot be blank' });
				} else {
					setError({ ...error, first_name_Error: '' });
				}
				item.first_name = val;
				break;
			}
			case 'lastName': {
				if (!val) {
					setError({ ...error, last_name_Error: 'Last name cannot be blank' });
				} else {
					setError({ ...error, last_name_Error: '' });
				}
				item.last_name = val;
				break;
			}
			case 'mobile': {
				if (!val) {
					setError({ ...error, mob_number_Error: 'Last name cannot be blank' });
				} else {
					setError({ ...error, mob_number_Error: '' });
				}
				item.mob_number = val;
				break;
			}
			case 'email': {
				if (!val) {
					setError({ ...error, email_address_Error: 'Email cannot be blank' });
				} else {
					const validEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(val);
					if (validEmail) {
						setError({ ...error, email_address_Error: '' });
					} else {
						setError({ ...error, email_address_Error: 'Please enter Valid Email' });
					}
				}
				item.email_address = val;
				break;
			}
			case 'state':
				if (!val) {
					setError({ ...error, stateError: 'state cannot be blank' });
				} else {
					setError({ ...error, stateError: '' });
				}
				item.state = val.target.value;
				break;
		}
	};
	const addNewUser = () => {
	}
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	}
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={()=>onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={()=>onclickHambrgBtn()}></Sidebar>

			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbNav Heading="Add Users" breadcrumbList={breadcrumbNav} />
				{/* Add User Details */}
				<AddUserHolder>
					<ProfileDetails>
						<Text as="h1" fontSize={18} fontFamily="OpenSansBold" color="text.SubHeading">
							Add Users
						</Text>
						<Box as="form">
							<Grid gap={0}>
							<FormInput
									label="First Name"
									required={true}
									validationMessage={error.first_name_Error}
									validation={error.first_name_Error ? 'error' : undefined}
									onTextChange={(e) => onTextChange(e, 'firstName')}
								/>
								<FormInput
									label="Last Name"
									required={true}
									validationMessage={error.last_name_Error}
									validation={error.last_name_Error ? 'error' : undefined}
									onTextChange={(e) => onTextChange(e, 'lastName')}
								/>
								<FormInput
									label="Phone"
									required={true}
									validationMessage={error.mob_number_Error}
									validation={error.mob_number_Error ? 'error' : undefined}
									onTextChange={(e) => onTextChange(e, 'mobile')}
								/>
								<FormInput
									label="Email Address"
									required={true}
									validationMessage={error.email_address_Error}
									validation={error.email_address_Error ? 'error' : undefined}
									onTextChange={(e) => onTextChange(e, 'email')}
								/>
								<Box>
									<Stack categorySelect gap={0}>
										<div>
											<label>State/ Province</label>
											<Select
												id="country"
												name="country"
												onChange={(e) => onTextChange(e, 'state')}
												defaultValue="Last 30 days"
											>
												<option>-Select-</option>
												{wholeList.countries &&
													wholeList.countries
													.filter((x) => x.id != "AN")
													.map((option, index) => (
														<option
															value={option.full_name_english}
															key={index}
														// selected={value === option.id}
														>
															{option.full_name_english}
														</option>
												))}
											</Select>
											<Icon.downArrow />
										</div>
									</Stack>
								</Box>
								<Box>
									<label>Status</label>
									<Toggle onChange={(e) => toggleBtnChange(e)} />
								</Box>
							</Grid>
						</Box>
					</ProfileDetails>
					{/* Footer Btn Block */}
					<FooterBtnBlock>
						<Stack direction="x" gap={3} justifyContent={['center', 'flex-end']}>
							<Button flex={[1, 1, 'initial']} variant="secondary">
								Cancel
							</Button>
							<Button onClick={addNewUser} flex={[1, 1, 'initial']}>Add</Button>
						</Stack>
					</FooterBtnBlock>
				</AddUserHolder>
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default VenueDetailsNewUsers;
