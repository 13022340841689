import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';

var styles = {
	lineChart: {
		float: 'left',
		width: '25%',
	},
};

function LineChart(props) {

	const [orderReportDataX, setOrderReportDataX] = useState([]);
	const [orderReportDataY, setOrderReportDataY] = useState([]);

	useEffect(() => {
		const reportDataX = [];
		const reportDataY = [];
		if (props.reportData && props.reportData.length > 0) {
			props.reportData.forEach((item) => {
				item.items.forEach((obj) => {
					let dateData = item.period;
					if (props?.period === 'day') {
						dateData = moment(dateData).format('MMM Do YYYY')
					}
					else if (props?.period === 'month') {
						dateData = moment(dateData, 'MM/YYYY');
						dateData = moment(dateData).format('MMM YYYY')
					}
					reportDataX.push(dateData);
					reportDataY.push(obj.orders);
				})
			})
			setOrderReportDataX((prevObj1) => ([...reportDataX]));
			setOrderReportDataY((prevObj1) => ([...reportDataY]));
		} else {
			setOrderReportDataX((prevObj1) => ([]));
			setOrderReportDataY((prevObj1) => ([]));
		}
	}, [props.reportData])

	var state = {
		series: [{
			name: 'Orders',
			data: orderReportDataY,
			color: '#7d376f'
		},],
		options: {
			chart: {
				height: 350,
				type: 'line',
				toolbar: {
					show: false
				},
				selection: {
					enabled: false
				},
				zoom: {
					enabled: false
				}
			},
			stroke: {
				width: 7,
				curve: 'smooth',
				colors: '#7d376f'
			},
			redrawOnWindowResize: true,
			xaxis: {
				type: 'category',
				tickAmount: 10,
				tickPlacement: 'on',
				labels: {
					hideOverlappingLabels: false,
					showDuplicates: false,
					show: props?.reportData?.length > 0,
				},
				tooltip: {
					enabled: false
				},
				categories: orderReportDataX
			},
			title: {
				// text: 'Social Media',
				align: 'left',
				style: {
					fontSize: '16px',
					color: '#666',
				},
			},
			fill: {
				type: 'gradient',
				gradient: {
					shade: 'dark',
					gradientToColors: ['#7d376f'],
					shadeIntensity: 1,
					type: 'horizontal',
					opacityFrom: 1,
					opacityTo: 1,
					stops: [0, 100, 100, 100],
				},
			},
			markers: {
				size: 4,
				colors: ['#ffffff'],
				strokeColors: '#7d376f',
				strokeWidth: 2,
				hover: {
					size: 7,
				},
			},
			yaxis: {
				showForNullSeries: false,
				min: 0,
				max: function (max) { return max + 10 },
			},
			noData: {
				text: "No Data",
				align: 'center',
				verticalAlign: 'middle',
				offsetX: 0,
				offsetY: 0,
				style: {
					color: '#41453f',
					fontSize: '1.12rem',
					fontFamily: 'OpenSansSemiBold'
				}
			}
		},
	};

	return (
		<div style={styles.pieChart}>
			<ReactApexChart options={state.options} series={state.series} type="line" height={350} />
		</div>
	);
}

export default LineChart;
