import { Select } from '@rebass/forms';
import React, { ReactElement, useState } from 'react';
import { Box, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { Button, Stack } from 'ui/components';
import * as Icon from 'ui/icons';


const AddToFavorites = styled.div`
	display: flex;
	height: auto;
	position: relative;
	flex-direction: column;
	background: ${({ theme }) => theme.colors.bg.white};
	padding: 0 10px 12px;
	@media (max-width: 960px) {
		padding: 0;
	}
	h1 {
		margin: 0 0 38px 0;
		width: 100%;
		font-weight: normal;
		text-transform: uppercase;
	}
	label {
		font-size: 16px;
		line-height: 21px;
		font-family: 'OpenSansSemiBold';
		color: ${({ theme }) => theme.colors.text.SubHeading};
		margin-bottom: 8px;
	}
	button {
		padding: 0;
		min-width: 100px;
		min-height: 50px;
		text-transform: capitalize;
		font-size: 18px;
		margin: 0 0 0 20px;
		font-family: 'OpenSansBold';
	}
	.app-c-form-group {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
	}
	.app-c-select-dropdown {
		position: relative;
		svg {
			position: absolute;
			top: 20px;
			right: 14px;
		}
	}
`;

type Option = {
	cancel: () => void;
};
export default function AddExistingUsersModal(props, { cancel }: Option): ReactElement {
	// const [addExistingUsers, setAddExistingUsers] = useState('');

	// const handleOnChange = (e: any) => {
	// 	setAddExistingUsers(e.target.id);
	// };
	return (
		<AddToFavorites>
			<Text as="h1" color="text.textHeading" fontSize={18} fontFamily="OpenSansSemiBold">
				Assign User Role
			</Text>
			<Box mb={25}>
				<Stack categorySelect gap={0} mt={0}>
					<div className="app-c-form-group">
						<label>Select Store</label>
						<div className="app-c-select-dropdown">

							<Select id="country" name="country" defaultValue="Last 30 days" onChange={(e) => props.onSelectDropdwn(e, 'venueName')}> 
								<option>-Select-</option>
								{props.venueList &&
									props.venueList.map((option, index) => (
										<option key={index}>{option.value}</option>
									))}
							</Select>
							<Icon.downArrow />
						</div>
					</div>
				</Stack>
			</Box>
			<Box mb={50}>
				<Stack categorySelect gap={0} mt={0}>
					<div className="app-c-form-group">
						<label>Select Role</label>
						<div className="app-c-select-dropdown">
							<Select id="country" name="country" defaultValue="Last 30 days" onChange={(e) => props.onSelectDropdwn(e, 'userRole')}>
								<option>-Select-</option>
								{props.roleList &&
									props.roleList.map((option, index) => (
										<option key={index}>{option.value}</option>
									))}
							</Select>
							<Icon.downArrow />
						</div>
					</div>
				</Stack>
			</Box>

			<Stack direction="x" gap={3} justifyContent="flex-end">
				<Button onClick={props.cancel} flex={[1, 1, 'initial']} variant="secondary">
					Cancel
				</Button>
				<Button onClick={()=>{props.saveUserRole()}} flex={[1, 1, 'initial']}>Add</Button>
			</Stack>
		</AddToFavorites>
	);
}
