import styled from 'styled-components';
const StyledHeader = styled.header`
	flex-shrink: 0;
	z-index: 1000;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	min-height: 100px;
	padding: 0 50px;
	@media (max-width: 960px) {
		padding: 0 15px 0 5px;
		min-height: 75px;
		margin-bottom: 15px;

		border-bottom: 1px solid ${({ theme }) => theme.colors.border.grayLightBorder};
	}
	img {
		flex: 0 0 86px;
		max-width: 86px;
	}
`;
const HeaderLeft = styled.div`
	display: flex;
	flex-grow: 1;
	Button {
		display: none;
		@media (max-width: 960px) {
			display: block !important;
			background: transparent !important;
			border: none !important;
			outline: none !important;
		}
	}
`;
const HeaderRight = styled.div`
	display: flex;
	flex-grow: 1;
	justify-content: flex-end;
	align-items: center;
	position: relative;
	.menuContent {
		position: absolute;
		background: #ffffff;
		box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.39);
		border-radius: 12px;
		min-height: 220px;
		width: 400px;
		right: 0;
		top: 53px;
		font-family: 'OpenSansBold';
		cursor: default;
		transition: all 0.25s ease-in-out;
		z-index: 1;
		&::before {
			content: '';
			width: 47px;
			height: 16px;
			position: absolute;
			top: -16px;
			left: 185px;
			background: url(${require('../../images/dropdownTopArrow.png')});
		}
	}
`;
const HeaderSearchWebView = styled.div`
	width: 640px;
	@media (max-width: 1440px) {
		width: 400px;
	}
	@media (max-width: 1152px) {
		width: 330px;
	}
	@media (max-width: 960px) {
		display: none;
	}
`;

const HeaderRightNav = styled.div`
	margin-right: 47px;
	@media (max-width: 1152px) {
		margin-right: 20px;
	}
	@media (max-width: 960px) {
		display: none;
	}
`;

const HeaderCartLocation = styled.div`
	width: 100%;
	height: 50px;
	background: ${({ theme }) => theme.baseColors.baseYellow};
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 50px;
	position: relative;
	.menu-btn {
		display: none;
	}
	@media (max-width: 960px) {
		padding: 0 15px;
		.location {
			display: block;
		}
		.menu-btn {
			display: flex;
			background: transparent !important;
			padding: 0;
			border: none;
			span {
				h1 {
					display: flex;
					text-transform: capitalize;
					align-items: center;
					span {
						margin-left: 15px;
						svg {
							path {
								fill: ${({ theme }) => theme.baseColors.white};
							}
						}
					}
				}
			}
		}
		.hiddenMenu {
			display: none !important;
		}
	}
`;

const HeaderMobileView = styled.div`
	display: flex;
	display: none;
	/* div {
		width: 100%;
		margin: 0;
	} */
	@media (max-width: 960px) {
		display: block;
		/* & * > * div {
			display: block;
		} */
		nav {
			display: none !important;
		}
		.active {
			display: block !important;
			background: ${({ theme }) => theme.baseColors.baseYellow};
			margin: 1px 0 0 0 !important;
			a {
				padding: 0 !important;
				font-size: 14px !important;
				align-items: flex-start;
				border-bottom: 1px solid ${({ theme }) => theme.colors.transparent.white70};
				label {
					position: absolute;
					top: 13px;
					left: 15px;
				}
				.pr-icon {
					margin-right: 15px;
				}
				.dropdownMenu {
					padding: 0 !important;
					position: relative;
					Button {
						margin: 0 !important;
						padding: 0 !important;
					}
					div {
						position: relative;
						border-radius: 0;
						margin-bottom: -2px;
						padding: 0;
						button {
							padding: 10px 15px !important;
						}
					}
				}
			}
		}
	}
`;
const StickyHeader = styled.div`
	position: sticky;
	z-index: 999;
	top: 0;
	background: ${({ theme }) => theme.baseColors.white};
`;
const HeaderSupplierMenu = styled.div`
	display: flex;
	grid-template-columns: repeat(4, 1fr) !important;
	text-align: center;
	align-items: center;
	justify-content: center;
	margin: auto;
	a {
		font-size: 16px !important;
		color: ${({ theme }) => theme.colors.text.white};
		font-family: 'OpenSansSemiBold';
		text-decoration: none;
		margin: 0 20px;
		@media (max-width: 767px) {
			font-size: 13px !important;
			margin: 0 10px;

		}

		text-transform: capitalize;
		&:hover {
			opacity: 0.8;
		}
	}
`;
export {
	StyledHeader,
	HeaderLeft,
	HeaderRight,
	HeaderMobileView,
	HeaderSearchWebView,
	HeaderRightNav,
	HeaderCartLocation,
	StickyHeader,
	HeaderSupplierMenu,
};
