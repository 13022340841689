import { Select } from '@rebass/forms';
import React, { ReactElement, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { FlexProps, LayoutProps, SpaceProps } from 'styled-system';
import { FormLabel, StyledFormFeedback } from 'ui/components';

type FormGroupProps = SpaceProps & LayoutProps & FlexProps;
export type OptionItem = {
	id: number;
	name: string;
};
const FormGroup = styled.div<FormGroupProps>`
	background: red;
`;
export type SelectProps = {
	name?: string;
	label: string;
	value?: number;
	onTextChange?: (text: string) => void;
	type?: 'email' | 'number' | 'password' | 'search' | 'text' | 'tel' | 'url';
	placeholder?: string;
	disabled?: boolean;
	validation?: 'error' | 'success' | 'warning';
	feedback?: string;
	required?: boolean;
	options?: Array<OptionItem>;
	register?: ({ required }: { required?: boolean }) => RefReturn;
	onChange?: (e: SyntheticEvent<HTMLSelectElement>) => void;
};

type item = string;

type RefReturn =
	| string
	| ((instance: HTMLSelectElement | null) => void)
	| React.RefObject<HTMLSelectElement>
	| null
	| undefined;

const FormSelect = ({ label, validation, feedback, required }: SelectProps): ReactElement => {
	const id = `toggle-${label ? label.replace(/ /g, '').toLowerCase() : null}`;
	return (
		<FormGroup>
			{label ? (
				<FormLabel htmlFor={id} required={required}>
					{label}
				</FormLabel>
			) : null}
			<Select id="country" name="country" defaultValue="United States">
				<option>asdsa</option>
			</Select>

			{feedback ? (
				<StyledFormFeedback validation={validation}>{feedback}</StyledFormFeedback>
			) : null}
		</FormGroup>
	);
};

export { FormSelect };
