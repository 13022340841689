import styled from 'styled-components';
const StyledBreadcrumb = styled.div`
	button {
		width: 22px;
		height: 22px;
		border-radius: 6px;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	h1 {
		text-transform: uppercase;
		line-height: normal;
	}
	a,
	p {
		text-decoration: none;
		text-decoration: none;
		line-height: 18px;
		position: relative;
		margin-left: 5px;
		padding-left: 11px;
		&:before {
			content: '/';
			position: absolute;
			font-size: 14px;
			left: 0;
		}
		&:hover {
			color: ${({ theme }) => theme.baseColors.baseYellow};
		}
	}
	p {
		text-decoration: none;
		text-decoration: none;
		line-height: 18px;
		position: relative;
		margin-left: 5px !important;
		padding-left: 11px !important;
		&:after {
			content: '/';
			position: absolute;
			font-size: 14px;
			left: 0;
			color: ${({ theme }) => theme.colors.text.blackLight}!important;
			font-family: 'OpenSansMedium';
		}
		&:hover {
			color: ${({ theme }) => theme.baseColors.baseYellow};
		}
	}
	a {
		color: ${({ theme }) => theme.colors.text.blackLight};
		font-family: 'OpenSansMedium';

		&:before {
			font-family: 'OpenSansMedium';
			right: 0;
		}
		&:first-child {
			padding-left: 0;
			margin-left: 0;
			&:before {
				display: none !important;
			}
		}
	}
	p {
		color: ${({ theme }) => theme.baseColors.baseYellow};
		font-family: 'OpenSansBold';
		&:before {
			color: ${({ theme }) => theme.colors.text.blackLight}!important;
		    font-family: 'OpenSansMedium';
			left: 0;
			display: none;
		}
	}
`;
export { StyledBreadcrumb };
