import FilterGroupItem from 'models/FilterModel';
import React, { ReactElement } from 'react';
import { Text } from 'rebass/styled-components';
import { FlexboxProps, LayoutProps, SpaceProps } from 'styled-system';
import helper from 'utils/helper.functions';
import { Tags } from '../../components/tags';
import { StyledSortInfo, TagsHolder } from './styles';

type styledSystemProps = SpaceProps & LayoutProps & FlexboxProps;
type SortInfoProps = styledSystemProps & {
	noOfResult?: string;
	noOfResultItem?: string;
	isFetching?: boolean;
	selectedFilters?: Array<FilterGroupItem>;
	removeItem?: (SupplyGroupItem) => void;
};

function SortInfo({
	noOfResult,
	noOfResultItem,
	selectedFilters,
	isFetching,
	removeItem,
	...styledSystemProps
}: SortInfoProps): ReactElement {
	return (
		<>
		{!isFetching && <StyledSortInfo {...styledSystemProps}>
			 <Text as="h1">
			 	Showing {noOfResult} results {noOfResultItem != '' ? 'for' : ''}{' '}
				<strong>{noOfResultItem != '' ? ' “' + helper.toPascalCase(noOfResultItem) + '”' : ''}</strong>
			</Text>
			<TagsHolder>
				{selectedFilters &&
					selectedFilters.map((item, index) => (
						<Tags key={index} tagContent={item.name} content={item} onPress={removeItem}></Tags>
					))}
			</TagsHolder>
		</StyledSortInfo>}
		</>
	);
}

export { SortInfo };
