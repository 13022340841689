import styled from 'styled-components';

const MainContent = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
`;
const ContentHolder = styled.div`
	height: 100%;
	padding: 0 50px;
	padding-bottom: 30px;
	width: 100%;
	@media (max-width: 768px) {
		padding: 0 15px;
	}
`;
const TileViewHolder = styled.div`
	width: 100%;
	display: flex;
	border-top: 1px solid ${({ theme }) => theme.colors.border.grayLightBorder01};
	min-height: 200px;
`;
const FilterBlock = styled.div`
	flex: 0 0 345px;
	border-right: 1px solid ${({ theme }) => theme.colors.border.grayLightBorder01};
	background: ${({ theme }) => theme.newColors.white};
	min-height: calc(100vh - 452px);
	@media (max-width: 960px) {
		display: none;
	}
`;
const TilesContentBlock = styled.div`
	flex: 1;
`;
const ProductGridViewBlock = styled.div`
	width: 100%;
	padding: 30px 50px 30px 30px;
	@media (max-width: 960px) {
		padding: 15px;
	}
`;
const PaginationHolder = styled.div`
	width: 100%;
	padding: 20px 0 50px;
	display: flex;
`;
const NodataFound = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 250px;
	grid-column: 6 / 1;
	img {
		margin: 0;
	}
	h1 {
		text-align: center;
		margin-top: 15px;
	}
`;
export {
	MainContent,
	ContentHolder,
	TileViewHolder,
	FilterBlock,
	TilesContentBlock,
	ProductGridViewBlock,
	PaginationHolder,
	NodataFound,
};
