import { permissionGroup, userTypes } from 'models/enum-constants/common.constants';
import Iget from 'models/Iget';
import urlObj from 'models/url.obj';
import moment from 'moment';
import RedirectUser from 'pages/auth/RedirectUser';
import VenueSelector from 'pages/auth/venueSelection';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link, Text } from 'rebass/styled-components';
import { Footer, Header } from 'ui/components';
import { Modal, RecentOrders, Sidebar, SpecialSaleProducts, TopSellingItems } from 'ui/patterns';
import urls from 'utils/create.url';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import utilityFunctions from 'utils/utility.functions';
import * as Icon from '../../ui/icons';
import { ContentStaging } from './contentStaging';
import { Box, Card, Button, Heading } from 'rebass';

import {
	ContentHolder,
	MainContent,
	RecentOfferListMobileView,
	RecentOrdersBlock,
	ViewAllHeadingBlock,
} from './styles';
import appContext from 'utils/appContext';
var token: any;

function Dashboard(): ReactElement {
	const history = useHistory();
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const [showCustomerNotFoundModal, setShowCustomerNotFoundModal] = useState(false);
	const [showVenueNotFoundModal, setShowVenueNotFoundModal] = useState(false);
	const [selectedLocation, setSelectedLocation] = useState('');
	const [selectedVenue, setSelectedVenue] = useState('');
	const [orderList, setOrderList] = useState([] as any);
	const [newArrivals, setNewArrivals] = useState(null as any);
	const [specialProducts, setSpecialProducts] = useState(null as any);
	const [venueId, updateVenueId] = useState<any>(localStorage.getItem('selectedVenue'));
	token = localStorage.getItem('customerToken') ?? 1;
	let ctx = useContext(appContext).userObj;
	let venueUserId = localStorage.getItem('userId');
	var userType = localStorage.getItem('userType');
	var date = new Date();
	date.setDate(date.getDate() - 30);
	const selectedDate = date.toISOString().split('T')[0];
	let IsVenueUser =
		localStorage.getItem('userType') == userTypes.venueUser ||
			localStorage.getItem('userType') == userTypes.venueAdmin
			? true
			: false;
	let urlObj: Partial<urlObj> = {
		url: 'experion/order/search?',
		fields: [
			{
				field_name: 'venue_id',
				field_value: venueId,
			},
			{
				field_name: 'venue_user_id',
				field_value: localStorage.getItem('userId')!,
			},
			{ field_name: 'for_approval', field_value: '0,2', field_conditionType: 'in' },
		],
		pageSize: 5,
		filterDeleted: true,
		page: 1,
	};

	const recentOrderEnabled = venueId && venueId !== '' && venueId !== 'undefined';
	const recentOrderObj: Partial<Iget> = {
		url: urls.GetSearchUrl(urlObj),
		enabled: recentOrderEnabled,
		refetchOnWindowFocus: false,
		resultFilteringRequired: true,
		key: 'recentOrder',
		caching: true,
	};
	const recentOrderListQry = hooks.useQuery(recentOrderObj);

	function apiURLSelector() {
		if (userType == userTypes.venueAdmin) {
			return venueAdminUrl;
		} else if (userType == userTypes.venueUser) {
			return venueUrl;
		} else {
			return '';
		}
	}

	const venueAdminUrl = urls.GetSearchUrl({
		url: 'experion/venue/search?',
		pageSize: 500,
		fields: [
			{
				field_name: 'hosp_id',
				field_value: localStorage.getItem('hospitalityId')!,
			},
		],
		filterDeleted: true,
		filterInactive: true,
	});
	const venueUrl = `experion/venueuser/get/${venueUserId}`;

	const userKey = 'VenueSelection';
	const obj: Partial<Iget> = {
		params: {},
		url: apiURLSelector(),
		enabled: true,
		key: userKey,
		resultFilteringRequired: true,
	};
	var qry = hooks.useQuery(obj);
	function getSpecialproducts() {
		let sorting = {
			SortDirection: '',
			SortField: '',
		};
		sorting.SortDirection = 'asc';
		sorting.SortField = 'special_price';
		let url = urls.GetSearchUrl({
			url: 'experionproductmanagement/search?',
			pageSize: 100,
			ctx: sorting,
			fields: [
				{
					field_name: 'venue_id',
					field_value: venueId,
					field_conditionType: 'eq',
				},
				{
					field_name: 'special_price',
					field_value: '0',
					field_conditionType: 'gt',
				},
				{
					field_name: 'special_from_date',
					field_value: String(moment(new Date()).format('YYYY-MM-DD')),
					field_conditionType: 'lteq',
				},
				{
					field_name: 'special_to_date',
					field_value: String(moment(new Date()).format('YYYY-MM-DD')),
					field_conditionType: 'gteq',
				},
			],
		});
		api
			.get({
				url: url,
			})
			.then((data) => {
				const specialPriceData = data?.items ? data?.items.slice(0, 5) : [];
				setSpecialProducts(specialPriceData);
			});
	}

	function getArrivals() {
		let Today = moment(new Date()).format('YYYY-MM-DD');
		let sorting = {
			SortDirection: '',
			SortField: '',
		};
		sorting.SortDirection = 'desc';
		sorting.SortField = 'created_at';
		let url = urls.GetSearchUrl({
			url: 'experionproductmanagement/search?',
			pageSize: 100,
			ctx: sorting,
			fields: [
				{
					field_name: 'status',
					field_value: '1',
					field_conditionType: 'eq',
				},
				{
					field_name: 'venue_id',
					field_value: venueId,
					field_conditionType: 'eq',
				},
			],
		});
		api
			.get({
				url: url,
			})
			.then((data) => {
				const arrivalsData = data?.items ? data?.items.slice(0, 5) : [];
				setNewArrivals(arrivalsData);
			});
	}
	useEffect(() => {
		if (venueId && venueId !== '' && venueId !== 'undefined') {
			getArrivals();
			getSpecialproducts();
		}
	}, []);

	useEffect(() => {
		if (venueId && venueId !== '' && venueId !== 'undefined') {
			getArrivals();
			getSpecialproducts();
			recentOrderListQry.refetch();
		}
	}, [venueId]);

	useEffect(() => {
		if (
			ctx.venueId !== venueId &&
			ctx.venueId !== null &&
			ctx.venueId &&
			ctx.venueId !== 'undefined'
		) {
			console.log(venueId);
			updateVenueId(ctx.venueId);
		}
	}, [ctx.venueId]);

	useEffect(() => {
		let selectedVenue = localStorage.getItem('selectedVenue');
		if (venueUserId && IsVenueUser) {
			if (selectedVenue === null) {
				let isMounted = true;
				togglePopup();
				return () => {
					isMounted = false;
				};
			} else {
				setSelectedVenue(selectedVenue);
			}
		}
	}, []);

	useEffect(() => {
		if (qry.data && qry.data.length == 1) {
			const setPermissions = async () => {
				await utilityFunctions
					.setPermissions(qry.data[0].venue_id)
					.then(function () {
						setVenue();
					})
					.catch(function () {
						setVenue();
					});
			};
			setPermissions();
		} else {
			let selectedVenue = localStorage.getItem('selectedVenue');
			if (qry.data && qry.data.length > 1 && (selectedVenue == null || selectedVenue == '')) {
				setShowVenueNotFoundModal(false);
				togglePopup();
			} else if (
				!qry.isLoading &&
				qry.data &&
				qry.data.length == 0 &&
				localStorage.getItem('userType') == userTypes.venueAdmin
			) {
				toggleRedirectPopup();
			} else if (qry.data && selectedVenue != null && selectedVenue != '') {
				setSelectedLocation(selectedVenue);
			}
		}
	}, [qry.data]);

	function setVenue() {
		if (qry.data[0].is_delete != true && qry.data[0].is_active == true) {
			if (userType == userTypes.venueAdmin) {
				if (venueUserId && localStorage.getItem('selectedVenue') != qry.data[0].id) {
					let id = qry.data[0].id;
					localStorage.setItem('selectedVenue', id);
					ctx.venueId = id;
					setSelectedLocation(id);
					reloadPage();
				}
			} else {
				if (venueUserId && localStorage.getItem('selectedVenue') != qry.data[0].venue_id) {
					let id = qry.data[0].venue_id;
					localStorage.setItem('selectedVenue', id);
					ctx.venueId = id;
					setSelectedLocation(id);
					reloadPage();
				}
			}
		}
	}

	useEffect(() => {
		setOrderList(recentOrderListQry.data);
	}, [recentOrderListQry.data]);

	const reloadPage = () => {
		window.location.href = window.location.href.replace('#!', '');
	};

	const callbackFunction = (childData) => {
		setSelectedLocation(childData);
		window.location.href = window.location.href.replace('#!', '');
	};

	async function togglePopup() {
		if (qry.data && qry.data.length > 1) {
			await setShowCustomerNotFoundModal(!showCustomerNotFoundModal);
		}
	}

	async function toggleRedirectPopup() {
		await setShowVenueNotFoundModal(!showVenueNotFoundModal);
	}
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	};
	return (
		<>
			<Modal
				backdrop="static"
				width="600px"
				showModal={showCustomerNotFoundModal}
				setShowModal={setShowCustomerNotFoundModal}
			>
				<VenueSelector qry={qry.data} parentCallback={callbackFunction} togglePopup={togglePopup} />
			</Modal>

			<Modal
				backdrop="static"
				width="600px"
				showModal={showVenueNotFoundModal}
				setShowModal={setShowVenueNotFoundModal}
			>
				<RedirectUser
					qry={qry.data}
					parentCallback={callbackFunction}
					togglePopup={toggleRedirectPopup}
				/>
			</Modal>
			{/* Main Header */}
			<Header
				toggleSideBar={() => onclickHambrgBtn()}
				locationSelected={venueUserId ? selectedVenue : selectedLocation}
			>
				{' '}
			</Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={() => onclickHambrgBtn()}></Sidebar>
			<MainContent>
				<ContentHolder>
					{/* {token !== null && utilityFunctions.isAnyGranted(permissionGroup.RecentOrder) ? (
						<ViewAllHeadingBlock>
							<Text as="h1" fontSize="18px">
								<Icon.HistoryIcon /> My Orders
							</Text>
							<Link variant="nav" href="\store\orders?tab=my-orders" fontSize="14px">
								{orderList && orderList.length > 0 && 'View All'}
							</Link>
						</ViewAllHeadingBlock>
					) : (
						''
					)} */}

					{/* Recent Order Session */}
					{/* {token !== null && utilityFunctions.isAnyGranted(permissionGroup.RecentOrder) ? (
						<RecentOrdersBlock>
							<RecentOrders orderList={orderList} />
						</RecentOrdersBlock>
					) : (
						''
					)}

					{token !== null && utilityFunctions.isAnyGranted(permissionGroup.RecentOrder) ? (
						<RecentOfferListMobileView>
							<RecentOrders orderList={orderList} />
						</RecentOfferListMobileView>
					) : (
						''
					)} */}

					{/* Today’s Offer Session */}
					{/* <ViewAllHeadingBlock>
						<Text as="h1" fontSize="18px">
							<Icon.offersIcon />
							Today’s Offer
						</Text>
						<Link variant="nav" href="" fontSize="14px">
							View All
						</Link>
					</ViewAllHeadingBlock>					
					<RecentOrdersBlock>
						<TodaysOffer></TodaysOffer>
					</RecentOrdersBlock> */}

					{/* <RecentOfferListMobileView>
						<TabsView>
							<Tabs>
								<TabList>
									<Tab>
										<Icon.HistoryIcon />
									</Tab>
									<Tab>
										<Icon.offersIcon />
									</Tab>
									<Tab>
										<Icon.sellingIcon />
									</Tab>
								</TabList>
								<TabPanel>
									<RecentOrders recentOrderList={listOfRecentOrders} />
								</TabPanel>
								<TabPanel>
									<TodaysOffer></TodaysOffer>
								</TabPanel>
								<TabPanel>
									<TopSellingItems topSellingItems={listTopSellingItems} />
								</TabPanel>
							</Tabs>
						</TabsView>
					</RecentOfferListMobileView> */}

					{/* Recent Order Session */}
					{/* <ViewAllHeadingBlock>
						<Text as="h1" fontSize="18px">
							<Icon.sellingIcon />
							Sales
						</Text>
						{specialProducts && specialProducts.length > 0 && (
							<Link
								variant="nav"
								href=""
								fontSize="14px"
								onClick={() =>
									history.push(`/home/product-list?search=&type=Products&specialProductsView=true`)
								}
							>
								View All
							</Link>
						)}
					</ViewAllHeadingBlock>
					<RecentOrdersBlock className="salesBLock">
						<SpecialSaleProducts specialSaleProducts={specialProducts} />
					</RecentOrdersBlock> */}
					<ViewAllHeadingBlock>
						<Text as="h1" fontSize="18px">
							<Icon.sellingIcon />
							New Arrivals
						</Text>
						{newArrivals && newArrivals.length > 0 && (
							<Link
								variant="nav"
								fontSize="14px"
								onClick={() =>
									history.push(`/home/product-list?search=&type=Products&newArrivalDate=${selectedDate}`)
								}
							>
								View All
							</Link>
						)}
					</ViewAllHeadingBlock>
					<RecentOrdersBlock className="salesBLock">
						<TopSellingItems topSellingItems={newArrivals} />
					</RecentOrdersBlock>
					<RecentOrdersBlock className="salesBLock">
						{/* <ContentStaging></ContentStaging> */}
					</RecentOrdersBlock>
				</ContentHolder>
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default Dashboard;
