import styled from 'styled-components';

const MainContent = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
`;
const ContentHolder = styled.div`
	height: 100%;
	padding: 0 50px;
	padding-bottom: 30px;
	width: 100%;
	@media (max-width: 960px) {
		padding: 0 15px 30px;
	}
`;
const ViewAllHeadingBlock = styled.div`
	display: flex !important;
	justify-content: space-between;
	color: ${({ theme }) => theme.colors.text.black04};
	margin: 40px 0 26px 0;
	align-items: center;
	min-height: 30px;
	h1 {
		font-family: 'OpenSansBold';
		text-transform: uppercase;
		display: flex !important;
		align-items: center;
		span {
			margin-right: 6px;
			svg {
				width: 25px;
				height: 22px;
			}
		}
	}
	h1,
	a {
		font-weight: normal;
	}
	a {
		color: ${({ theme }) => theme.baseColors.baseYellow};
		text-decoration: none;
		font-family: 'OpenSansMedium';
		transition: ease-in all 200ms;

		&:hover {
			background: ${({ theme }) => theme.baseColors.baseYellow};
			padding: 3px 10px;
			color: ${({ theme }) => theme.baseColors.white};
			border-radius: 5px;
		}
	}
	@media (max-width: 768px) {
		float: left;
		width: 100%;
	}
`;
const RecentOfferListMobileView = styled.div`
	width: 100%;
	float: left;
	display: none;
	@media (max-width: 768px) {
		display: block;
		margin: 0px 0 0 0;
	}
`;

const RecentOrdersBlock = styled.div`
	width: 100%;
	@media (max-width: 768px) {
		display: none;
	}
	&.salesBLock {
		@media (max-width: 768px) {
			display: flex !important;
			width: 100%;
			div{
				width: 100%;
			}
		}
	}
`;
const DealsHolder = styled.div`
	width: 100%;
	article {
		a {
			min-height: 220px;
			background-repeat: no-repeat;
			background-size: cover;
		}
	}
	.slick-slider .slick-list .slick-track .slick-slide:first-child {
		margin-left: 0;
	}
	.slick-slider {
		width: 100%;
		.slick-prev,
		.slick-next {
			z-index: 9;
			width: 24px;
			height: 24px;
			top: calc(50% - 4px);
		}
		.slick-prev,
		.slick-next {
			@media (max-width: 767px) {
				display: none !important;
			}
			&:before {
				content: '' !important;
				width: 24px;
				height: 24px;
				display: block;
				opacity: 1;
			}
		}
		.slick-prev {
			left: 15px;
			&:before {
				background: url(${require('../../ui/images/leftSlideArrow.svg')});
			}
		}
		.slick-next {
			right: 20px;
			&:before {
				background: url(${require('../../ui/images/rightSlideArrow.svg')});
			}
		}

		.slick-next.slick-disabled:before,
		.slick-prev.slick-disabled:before {
			opacity: 0.25;
		}

		.slick-list {
			@media (max-width: 767px) {
				overflow: hidden;
			}
			@media (max-width: 640px) {
				overflow: hidden;
			}
			.slick-track {
				display: flex;
				padding-right: 0;
				margin: 0 !important;
				.slick-slide {
					width: 100%;
					margin-left: 20px;
					@media (max-width: 640px) {
						margin-left: 0;
						padding: 0 3px;
					}
					&:first-child {
						margin-left: 0;
					}
				}
			}
		}
		.slick-dots {
			li {
				width: 8px;
				height: 8px;
				button {
					width: 8px;
					height: 8px;
					&::before {
						font-size: 8px;
						line-height: 8px;
						width: 8px;
						height: 8px;
						color: ${({ theme }) => theme.colors.border.greyBorderLogo};
						opacity: 1;
					}
				}
				&.slick-active {
					button {
						&::before {
							color: ${({ theme }) => theme.baseColors.baseYellow};
							z-index: 1;
						}
						&::after {
							content: '';
							position: absolute;
							width: 14px;
							height: 14px;
							background: ${({ theme }) => theme.colors.border.greyBorderLogo};
							left: -3px;
							top: -4px;
							border-radius: 50px;
						}
					}
				}
			}
			@media (max-width: 767px) {
				bottom: -20px;
				li {
					width: 8px;
					height: 8px;
					button {
						width: 8px;
						height: 8px;
						&::before {
							font-size: 8px;
							line-height: 8px;
							width: 8px;
							height: 8px;
							color: ${({ theme }) => theme.colors.border.greyBorderLogo};
							opacity: 1;
						}
					}
					&.slick-active {
						button {
							&::before {
								color: ${({ theme }) => theme.baseColors.baseYellow};
								z-index: 1;
							}
							&::after {
								content: '';
								position: absolute;
								width: 13px;
								height: 13px;
								background: ${({ theme }) => theme.colors.border.greyBorderLogo};
								left: -3px;
								top: -3px;
								border-radius: 50px;
							}
						}
					}
				}
			}
		}
	}
`;
export {
	MainContent,
	ViewAllHeadingBlock,
	ContentHolder,
	RecentOrdersBlock,
	RecentOfferListMobileView,
	DealsHolder,
};
