import { queryCache } from 'react-query';
// use if mutation in data required before posting data(for immediate ui response)
const mutation = {
	//call to api goes here - GET request
	mutate: (key, data) => {
			queryCache.cancelQueries(key);
			const previousData = queryCache.getQueryData(key);
			if (Array.isArray(previousData))
				queryCache.setQueryData(key, (prev) => [...(prev as any), data]);
				if(typeof previousData != 'undefined')
			return () => queryCache.setQueryData(key, previousData);
	},

	prevData: (key) => {
		return queryCache.getQueryData(key);
	},

	error: (key, error, values, rollback ) => {
		if (rollback) rollback();
	},
	settled: (key, data) => {
		//we can handle response here .. can store in cache if wanted.
		//if the response is same as that of post body, then  no need to set it again here in cache.
		//because 2 entries will come if do so.
		//queryCache.setQueryData(key, (prev) => [...(prev as any), data]);
	},
};

export default mutation;
