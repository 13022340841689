import styled from 'styled-components';
import {
	FlexboxProps,
	flexbox,
	SpaceProps,
	space,
	LayoutProps,
	layout,
	BoxShadowProps,
	boxShadow,
	ColorProps,
	color,
} from 'styled-system';

type ModalWrapperProps = {
	width?: string;
};

export const ModalWrapper = styled.div<ModalWrapperProps>`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	z-index: 1510;
	display: flex;

	.animated-div {
		margin: auto;
		max-width: ${({ width }) => (width ? width : '400px')};
		max-width: min(${({ width }) => (width ? width : '400px')}, 90%);
		outline: none !important;
		box-shadow: none !important;
		@media (max-width: 640px) {
			max-width: 90% !important;
		}
	}
`;
type ModalBackdropProps = {
	open?: boolean;
};

export const ModalBackdrop = styled.div<ModalBackdropProps>`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: -1;
`;

type ModalDialogProps = FlexboxProps &
	SpaceProps &
	LayoutProps &
	ColorProps &
	BoxShadowProps & {
		width?: string;
	};

export const ModalDialog = styled.div<ModalDialogProps>`
	${flexbox};
	${space};
	${layout};
	${boxShadow};
	${color};

	border-radius: 6px;
	position: relative;
	/* top: 10vh; */
	margin: 1.75rem auto;
	height: auto;
	max-width: ${({ width }) => (width ? width : '400px')};
	max-width: min(${({ width }) => (width ? width : '400px')}, 100%);
	background: ${({ theme }) => theme.colors.bg.white};
	z-index: 1530;
	overflow: hidden;
	padding: 26px 20px 10px;
	outline: none !important;
	& .dg-modal-close {
		z-index: 1;
		@media (max-width: 40.1em) {
			top: 10px;
			right: 10px;
		}
	}
	@media (max-width: 640px) {
		max-width: 100% !important;
	}
`;
