import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { flex, FlexProps, layout, LayoutProps, space, SpaceProps } from 'styled-system';

type StyledAvatarProps = SpaceProps & LayoutProps & FlexProps;

const StyledAvatar = styled.div<StyledAvatarProps>`
	${flex}
	${space}
	${layout}
	display: inline-block;
	overflow: hidden;
	line-height: 1;
	vertical-align: middle;
	border-radius: 50%;

	& > img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	/* &.pr-header-avatar {
		@media (max-width: 768px) {
			width: 58px;
		}
	} */
`;

type styledSystemProps = SpaceProps & LayoutProps & FlexProps;

type AvatarProps = SpaceProps &
	LayoutProps & {
		src: string;
		className?: string;
	};

function Avatar({ src, className, ...styledSystemProps }: AvatarProps): ReactElement {
	return (
		<StyledAvatar {...styledSystemProps} className={className}>
			<img src={src} alt="User's profile" />
		</StyledAvatar>
	);
}

export { Avatar };
