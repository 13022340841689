import styled from 'styled-components';

const MainContent = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
	padding: 0 50px;
	@media (max-width: 960px) {
		padding: 0 15px;
	}
`;
const ContentHolder = styled.div`
	height: 100%;
	padding: 0 50px;
	padding-bottom: 30px;
	width: 100%;

	@media (max-width: 768px) {
		padding: 0 15px 0;
	}
`;
const UserListHolder = styled.div`
	padding-bottom: 30px;

	table {
		width: 100%;
		thead,
		tbody {
			tr {
				display: flex;
				align-items: center;
				td,
				th {
					&:nth-child(2),
					&:nth-child(3) {
						width: 150px;
						max-width: 150px;
						flex: 0 0 150px;
						@media (max-width: 1440px) {
							width: 200px;
							max-width: 200px;
							flex: 0 0 200px;
						}
					}
					&:nth-child(6) {
						width: 175px !important;
						max-width: 175px !important;
						flex: 0 0 175px !important;
						text-align: left !important;
						@media (max-width: 768px) {
							width: 33.3333% !important;
							flex: 0 0 33.3333% !important;
							max-width: 33.3333% !important;
							padding-right: 84px;
						}
						@media (max-width: 480px) {
							padding-right: 37px;
							width: 50% !important;
							max-width: 50% !important;
							flex: 0 0 50% !important;
						}
						@media (max-width: 340px) {
							width: 100% !important;
							max-width: 100% !important;
							flex: 0 0 100% !important;
							padding-right: 102px;
						}
					}
					&:nth-child(7) {
						width: 175px !important;
						max-width: 175px !important;
						flex: 0 0 175px !important;
						text-align: right !important;
						@media (max-width: 768px) {
							width: 33.3333% !important;
							flex: 0 0 33.3333% !important;
							max-width: 33.3333% !important;
							padding-right: 84px;
						}
						@media (max-width: 480px) {
							padding-right: 37px;
							width: 50% !important;
							max-width: 50% !important;
							flex: 0 0 50% !important;
						}
						@media (max-width: 340px) {
							width: 100% !important;
							max-width: 100% !important;
							flex: 0 0 100% !important;
							padding-right: 102px;
						}
						label {
							justify-content: flex-end;
						}
					}
					h1 {
						strong {
							cursor: pointer;
							&:hover {
								color: ${({ theme }) => theme.baseColors.primary};
							}
						}
					}
					h1 {
						strong {
							cursor: pointer;
							&:hover {
								color: ${({ theme }) => theme.baseColors.primary};
							}
						}
					}
				}
			}
		}
		tbody {
			margin-bottom: 0px;
		}
	}
	@media (max-width: 768px) {
		table {
			width: 100%;
		}
	}
`;

const CardsAndNotificationHolder = styled.div`
	width: 100%;
	display: flex;
	@media (max-width: 860px) {
		flex-direction: column;
	}
`;
const TabContentHolder = styled.div`
	width: 100%;
	display: flex;
	margin-top: 50px;
`;
const BreadcrumbHolder = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media (max-width: 360px) {
		flex-direction: column;
		align-items: end;
		& > div {
			padding-bottom: 15px;
		}
	}
`;
const ButtonWrapper = styled.div`
	display: flex;
	@media (max-width: 480px) {
		padding-bottom: 0 !important;
	}
	button {
		display: flex !important;
		font-size: 18px;
		text-transform: capitalize;
		height: 50px;
		padding-top: 0;
		padding-bottom: 0;
		div {
			white-space: nowrap;
		}
	
		&:hover {
			svg {
				path {
					fill: ${({ theme }) => theme.colors.text.white};
				}
			}
		}
		span {
			display: flex;
			align-items: center;
		}
		+ button {
			margin-left: 15px;
			border: 1px solid ${({ theme }) => theme.baseColors.primary};
		}
		.pr-icon {
			margin-right: 15px;
		}
		@media (max-width: 767px) {
			font-size: 13px !important;
			.pr-icon {
				margin-right: 5px !important;
			}
		}
	}
`;
export {
	MainContent,
	ContentHolder,
	CardsAndNotificationHolder,
	TabContentHolder,
	BreadcrumbHolder,
	ButtonWrapper,
	UserListHolder,
};
