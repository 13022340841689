import Ipost from 'models/Ipost';
import moment from 'moment';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import Countdown from 'react-countdown';
import { useHistory } from 'react-router-dom';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import { Box, Flex, Text } from 'rebass/styled-components';
import styled, { css } from 'styled-components';
import { Button, Loader } from 'ui/components';
import icons from 'ui/icons';
import no_image from 'ui/images/no_image.png';
import { Stepper } from 'ui/patterns';
import appContext from 'utils/appContext';
import helperFunctions from 'utils/helper.functions';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import utilityFunctions from 'utils/utility.functions';

const StyledCartListView = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 20px;
	row-gap: 20px;

	@media (max-width: 1440px) {
		grid-template-columns: repeat(1, 1fr);
	}
`;

const CartListHolder = styled.div<CartListHolderProps>`
	width: 100%;
	display: flex;
	flex-direction: row;
	background: ${({ theme }) => theme.colors.bg.white};
	border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
	box-sizing: border-box;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 16px;
	overflow: hidden;
	transition: box-shadow 100ms ease-in, transform 100ms ease-in;
	align-items: flex-start;
	cursor: pointer;
	@media (max-width: 768px) {
		flex-direction: column;
	}
	.image-wrapper {
		position: relative;
	}
	.temp-unavailable {
		background: rgba(155, 6, 6, 0.73);
		border-radius: 10px;
		font-size: 14px;
		padding: 9px 14px;
		text-align: center;
		position: absolute;
		bottom: 9px;
		width: 95%;
		color: #fff;
		left: 5px;
		.date {
			font-weight: 800;
			font-size: 15px;
		}
	}
	picture {
		max-width: 216px;
		flex: 0 0 216px;
		height: 100%;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		border-right: 1px solid ${({ theme }) => theme.colors.bg.lightYellow05};
		background-color: #faf4ea;
		@media (max-width: 768px) {
			max-width: 100%;
			flex: 0 0 100%;
			height: 200px;
			min-height: 200px;
			width: 100%;
			max-height: 200px;
		}
	}
	section {
		height: 100%;
		padding: 25px;
		position: relative;
		@media (max-width: 768px) {
			padding: 20px;
			flex-direction: row;
			flex-wrap: wrap;
			align-items: flex-start;
			slot {
				width: 50%;
				+ slot + slot + slot {
					justify-content: flex-end;
				}
			}
		}
		@media (max-width: 640px) {
			padding: 20px;
			flex-direction: column;
			flex-wrap: wrap;
			align-items: flex-start;
			slot {
				width: 100%;
				+ slot + slot + slot {
					justify-content: flex-start;
				}
			}
		}
		@media (max-width: 480px) {
			padding: 15px;
		}
	}
	slot {
		width: 100%;
		display: flex;
		align-items: flex-start;
		flex-direction: row;
		justify-content: space-between;
		position: relative;
		div + div {
			flex: 0 0 120px;
			max-width: 120px;
			justify-content: flex-end;
			@media (max-width: 768px) {
				flex: auto;
				max-width: fit-content;
			}
		}
		main {
			margin-top: 10px;
			div {
				strong {
					margin-left: 5px;
				}
				& + div {
					flex: 20px;
					max-width: 20px;
					width: 20px;
					margin: 3px 0 0 10px;
				}
			}
			@media (max-width: 768px) {
				margin-bottom: 15px;
			}
		}
		@media (max-width: 768px) {
			align-items: end;
			div {
				+ div {
					display: flex;
					align-items: center;
				}
			}
		}
		h1,
		h2,
		h4,
		h5,
		h6 {
			font-weight: normal;
			color: ${({ theme }) => theme.colors.text.black};
		}
		h1 {
			margin-bottom: 3px;
			@media (max-width: 768px) {
				width: calc(100vw - 193px);
			}
			@media (max-width: 360px) {
				width: calc(100vw - 140px);
			}
		}
		h2 {
			@media (max-width: 768px) {
				margin-bottom: 4px;
			}
		}
		h5 {
			margin-left: 24px;
			strong {
				font-family: 'OpenSansBold';
			}
		}
		h6 {
			br {
				display: none;
			}
			strong {
				font-family: 'OpenSansBold';
				color: ${({ theme }) => theme.colors.text.error01};
			}
			br {
				& + strong {
					color: ${({ theme }) => theme.colors.text.black}!important;
				}
			}
			@media (max-width: 1550px) {
				font-size: 15px;
			}
			@media (max-width: 1440px) {
				font-size: 18px;
			}
		}
		p {
			align-items: flex-start;
			margin-top: 10px;
			strong {
				font-family: 'OpenSansBlack' !important;
				margin: 0 0 0 4px;
			}
			span {
				display: inline-flex;
				max-width: 20px;
				flex: 0 0 20px;
			}
		}

		+ slot {
			padding: 26px 0 0 0;
			justify-content: flex-start;
			align-items: center;
			@media (max-width: 768px) {
				padding: 0 0 20px 0;
				flex-wrap: wrap;
				row-gap: 10px;
				div {
					margin-right: 24px;
					div {
						margin-right: 0;
					}
				}
				h5 {
					margin-left: 0;
				}
			}

			+ slot {
				margin: 30px 0 0 0;
				padding: 0;
				@media (max-width: 768px) {
					margin: 0;
				}
				+ slot {
					padding: 0;
					div + div {
						flex: 20px;
						max-width: 20px;
						width: 20px;
						margin: 3px 0 0 10px;
						@media (max-width: 768px) {
							flex: auto;
							max-width: fit-content;
						}
					}

					@media (max-width: 768px) {
						margin: 20px 0;
					}
					+ slot {
						margin: 10px 0 0 0;
						padding: 0;
						div + div {
							flex: 0 0 20px;
							max-width: 20px;
							margin: 3px 0 0 10px;
							@media (max-width: 768px) {
								flex: auto;
								max-width: fit-content;
							}
						}

						+ div {
							flex: 0;
							max-width: 0;
						}
						@media (max-width: 768px) {
							margin: 0;
						}
						@media (max-width: 480px) {
							flex-direction: column;
							align-items: flex-start;
						}
					}
				}
			}
		}
		data {
			div {
				display: inline-block !important;
			}
		}
		h3 {
			opacity: 0;
		}
	}
	&.outOfStock {
		cursor: default;
		pointer-events: none;
		&:hover {
			transform: none;
			background: ${({ theme }) => theme.colors.bg.white};
			border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		}

		picture {
			opacity: 0.5;
			pointer-events: none;
		}
		h1,
		h2,
		h4,
		h5,
		h6,
		main {
			opacity: 0.5;
			pointer-events: none;
		}
		slot + slot {
			opacity: 0.5;
		}
		slot + slot + slot + slot {
			div {
				opacity: 0.5;
				+ div {
					opacity: 1;
				}
			}
		}
		slot + slot + slot + slot {
			opacity: 1;
		}
		h3 {
			opacity: 1 !important;
		}
	}
	${({ active }) =>
		!active &&
		css`
			cursor: default;
			&:hover {
				transform: none;
				background: ${({ theme }) => theme.colors.bg.white};
				border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
				box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
			}

			picture {
				opacity: 0.5;
				pointer-events: none;
			}
			h1,
			h2,
			h4,
			h5,
			h6 {
				opacity: 0.5;
				pointer-events: none;
			}
			slot + slot {
				opacity: 0.5;
			}
			slot + slot + slot + slot {
				div {
					opacity: 0.5;
					+ div {
						opacity: 1;
					}
				}
			}
			slot + slot + slot + slot {
				opacity: 1;
			}
			h3 {
				opacity: 1 !important;
			}
		`}
`;
const RemoveHolder = styled.div`
	display: flex;
	position: relative;
	left: 0;
	max-width: max-content !important;
	margin: 0 !important;
	flex: 0 0 auto !important;
	max-width: max-content !important;
	white-space: nowrap;
	cursor: pointer;
	button {
		padding: 0;
		border: none;
		color: ${({ theme }) => theme.colors.text.black};
		font-size: 16px;
		text-transform: capitalize;
		background: transparent !important;
		box-shadow: none !important;
		outline: none !important;
		&:hover {
			opacity: 0.5;
		}
		span {
			display: flex;
			align-items: center;
			span {
				margin-right: 15px;
			}
		}
	}
	@media (max-width: 480px) {
		position: relative;
		width: calc(100% - -30px) !important;
		display: flex;
		justify-content: center !important;
		align-items: center;
		flex: 0 0 100%;
		max-width: calc(100% - -30px) !important;
		padding: 14px 0 0;
		border-top: 1px solid #ddd;
		margin: 0 -15px -15px -15px !important;
	}
`;
// const NodataFound = styled.div`
// 	width: 100%;
// 	display: flex;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	height: calc(100vh - 494px);
// 	grid-column: 3/1;
// 	img {
// 		margin: 0;
// 	}
// 	h1 {
// 		text-align: center;
// 		margin-top: 15px;
// 	}
// `;

type CartListHolderProps = {
	active?: boolean;
};
// type CartListViewProps = {
// 	cartOrderList = [];
// };
// type list = {
// 	item_id?: string | number;
// 	image?: string;
// 	name?: string;
// 	brandType?: string;
// 	weight?: string;
// 	rate?: string;
// 	price?: string;
// 	retail?: string;
// 	NoOfComments?: string;
// 	deliveredTime?: string;
// 	deliveredDate?: string;
// 	movAmount?: string;
// 	maximumRetailPrice?: string;
// 	taxDetectable?: string;
// 	sellingPrice?: string;
// 	active?: boolean;
// 	min_order_qty: string;
// 	qty: string;
// };

function CartListView({
	disableCheckoutButton,
	callbackFn,
	updateTotalAmount,
	cartOrderList,
	supplierIndex = 0,
	getTotalSuppAmt,
	updateCartData,
	disableParkedOrder,
}): ReactElement {
	let ctx = useContext(appContext).cartObj;
	const key = 'cartGetKey';
	const [stepperCount, setStepperCount] = useState(new Array(cartOrderList?.length));
	const [stepperValue, setStepperValue] = useState(0);
	const [showPopup, setShowModal] = useState(false);
	const [mutatePost, mutationStates] = hooks.useMutation(key);
	const [validationData, SetValidationData] = useState(new Array(cartOrderList?.length));
	const [isCartUpdated, setIsCartUpdated] = useState(true);
	const [deleteItem, setDeleteItem] = useState<any>();
	const [isLoading, setIsLoading] = useState<any>(0);
	const [mov, setMov] = useState<any>();
	const [creditStatus, setCreditStatus] = useState(new Array(cartOrderList?.length));
	const [venueId] = useState<any>(localStorage.getItem('selectedVenue'));
	var history = useHistory();
	var cartId = localStorage.getItem('cartId');
	const [disableStepper, setDisableStepper] = useState(false);
	var sum = 0;
	useEffect(() => {
		disableCheckoutButton(false);
	}, []);
	useEffect(() => {
		if (ctx.stepperdCountUpdated != true) {
			let stepperCountList = new Array(cartOrderList?.length);
			// if (cartOrderList?.length !== 0) {
			// 	disableCheckoutButton(false);
			// } else {
			// 	disableCheckoutButton(true);
			// }
			let itemsProcessed = 0;
			cartOrderList?.forEach((data, index) => {
				if (data.extension_attributes?.credit_status.length === 0) {
					creditStatus[index] = 'go';
					setCreditStatus(creditStatus);
				} else {
					data.extension_attributes?.credit_status.forEach((obj) => {
						if (parseInt(venueId) === obj) {
							creditStatus[index] = 'stop';
							disableCheckoutButton(true);
							setCreditStatus(creditStatus);
						}
					});
				}
				stepperCount[index] = data.qty;
				stepperCountList[index] = data.qty;
				setStepperCount(stepperCount);
				setStepperValue(stepperValue + 1);

				itemsProcessed++;
				sum = sum + data.qty * data.price;

				if (itemsProcessed === cartOrderList.length) {
					if (sum < data.extension_attributes?.mov) {
						disableCheckoutButton(true);
					}
					// else {
					// 	disableCheckoutButton(false);
					// }
				}
			});
			cartOrderList?.forEach((dataObj, index) => {
				if (sum < dataObj.extension_attributes?.mov) {
					dataObj['isMovNotMet'] = true;
				} else {
					dataObj['isMovNotMet'] = false;
				}
			});
			if (cartOrderList?.length > 0) {
				let supplierId = cartOrderList[0].extension_attributes?.supplier_id;
				let obj = ctx.cartItemCount?.find((x: any) => x?.id == supplierId);
				if (typeof obj == 'undefined') {
					ctx.cartItemCount[supplierIndex] = { id: supplierId, value: stepperCountList };
				}
			}
			if (ctx.cartItemCount.length > 0 && typeof ctx.cartItemCount[0] != 'undefined') {
				let cartItemCount: any = ctx.cartItemCount.find(
					(x: any) => x.id == cartOrderList[0].extension_attributes?.supplier_id
				);
				if (typeof cartItemCount != 'undefined') {
					let values = cartItemCount.value;
					values?.forEach((el, i) => {
						stepperCount[i] = el;
						setStepperCount(stepperCount);
						setStepperValue(stepperValue + 1);

						if (
							parseInt(stepperCount[i]) <
							parseInt(cartOrderList[i]?.extension_attributes.min_order_qty) ||
							stepperCount[i] == '' ||
							typeof stepperCount[i] == 'undefined'
						) {
							validationData[i] =
								'Min quantity is ' + cartOrderList[i]?.extension_attributes.min_order_qty;
							SetValidationData(validationData);
							disableCheckoutButton(true);
						} else if (
							parseInt(stepperCount[i]) >
							parseInt(cartOrderList[i]?.extension_attributes.max_order_qty)
						) {
							validationData[i] =
								'Max quantity is ' + cartOrderList[i]?.extension_attributes.max_order_qty;
							SetValidationData(validationData);
							disableCheckoutButton(true);
						}
					});
				}
			}
		} else {
			if (cartOrderList?.length > 0) {
				if (ctx.cartItemCount.length > 0 && typeof ctx.cartItemCount[0] != 'undefined') {
					let cartItemCount: any = ctx.cartItemCount.find(
						(x: any) => x.id == cartOrderList[0].extension_attributes?.supplier_id
					);

					let itemsProcessed = 0;
					cartOrderList?.forEach((data, index) => {
						itemsProcessed++;
						sum = sum + data.qty * data.price;
						if (itemsProcessed === cartOrderList.length) {
							if (sum < data.extension_attributes?.mov) {
								disableCheckoutButton(true);
							}
							//  else {
							// 	disableCheckoutButton(false);
							// }
						}
					});
					cartOrderList?.forEach((dataObj, index) => {
						if (sum < dataObj.extension_attributes?.mov) {
							dataObj['isMovNotMet'] = true;
						} else {
							dataObj['isMovNotMet'] = false;
						}
					});
					if (typeof cartItemCount != 'undefined') {
						let values = cartItemCount.value;
						values?.forEach((el, i) => {
							stepperCount[i] = el;
							setStepperCount(stepperCount);
							setStepperValue(stepperValue + 1);

							if (
								parseInt(stepperCount[i]) <
								parseInt(cartOrderList[i]?.extension_attributes.min_order_qty) ||
								stepperCount[i] == '' ||
								typeof stepperCount[i] == 'undefined'
							) {
								validationData[i] =
									'Min quantity is ' + cartOrderList[i]?.extension_attributes.min_order_qty;
								SetValidationData(validationData);
								disableCheckoutButton(true);
							} else if (
								parseInt(stepperCount[i]) >
								parseInt(cartOrderList[i]?.extension_attributes.max_order_qty)
							) {
								validationData[i] =
									'Max quantity is ' + cartOrderList[i]?.extension_attributes.max_order_qty;
								SetValidationData(validationData);
								disableCheckoutButton(true);
							}
						});
					}
				}
			}
		}
	}, [cartOrderList]);

	const stopEvent = (event) => {
		event.stopPropagation();
		event.preventDefault();
	};

	async function handleChange(event, item, index) {
		event.stopPropagation();
		event.preventDefault();
		// setIsLoading(true);
		let eventValue = event.target.value;
		// let id: any;
		// let CUSTOMER_TOKEN = localStorage.getItem('customerToken');
		// id = await utilityFunctions.getCartId(CUSTOMER_TOKEN);
		// if (id !== undefined) {
		// setIsLoading(false);
		ctx.stepperdCountUpdated = true;
		let obj: any = ctx.cartItemCount?.find(
			(x: any) => x?.id == item?.extension_attributes?.supplier_id
		);
		if (eventValue === '' || eventValue === '0' || eventValue === undefined) {
			stepperCount[index] = eventValue;
			let count = eventValue;
			let obj: any = ctx.cartItemCount?.find(
				(x: any) => x?.id == item?.extension_attributes?.supplier_id
			);
			if (typeof obj != 'undefined') obj.value[index] = count;
			setStepperCount(stepperCount);
			setStepperValue(stepperValue - 1);
			setIsCartUpdated(false);
			validationData[index] = 'Min quantity is ' + item?.extension_attributes.min_order_qty;
			SetValidationData(validationData);
			disableCheckoutButton(true);
		} else {
			stepperCount[index] = eventValue;
			let count = eventValue;
			if (typeof obj != 'undefined') obj.value[index] = count;
			setStepperCount(stepperCount);
			setStepperValue(stepperValue - 1);
			if (
				parseInt(eventValue) >= parseInt(item?.extension_attributes.min_order_qty) &&
				parseInt(eventValue) <= parseInt(item?.extension_attributes.max_order_qty)
			) {
				disableCheckoutButton(false);
				disableParkedOrder(false);
				setIsCartUpdated(false);
				addItemsToCart(item.sku, stepperCount[index], item.item_id, event);
				validationData[index] = '';
				SetValidationData(validationData);
			} else if (parseInt(eventValue) < parseInt(item?.extension_attributes.min_order_qty)) {
				validationData[index] = 'Min quantity is ' + item?.extension_attributes.min_order_qty;
				SetValidationData(validationData);

				disableCheckoutButton(true);
			} else if (parseInt(eventValue) > parseInt(item?.extension_attributes.max_order_qty)) {
				validationData[index] = 'Max quantity is ' + item?.extension_attributes.max_order_qty;
				SetValidationData(validationData);

				disableCheckoutButton(true);
			}
		}
		// } else {
		// 	setIsLoading(false);
		// }
	}

	async function decreaseCount(item, index, event) {
		event.stopPropagation();
		event.preventDefault();
		// setIsLoading(true);
		// let id: any;
		// let CUSTOMER_TOKEN = localStorage.getItem('customerToken');
		// id = await utilityFunctions.getCartId(CUSTOMER_TOKEN);
		// if (id !== undefined) {
		// setIsLoading(false);
		ctx.stepperdCountUpdated = true;
		let obj: any = ctx.cartItemCount?.find(
			(x: any) => x?.id == item?.extension_attributes?.supplier_id
		);
		if (parseInt(stepperCount[index]) - 1 >= 0) {
			if (
				parseInt(stepperCount[index]) - 1 >= item?.extension_attributes.min_order_qty &&
				parseInt(stepperCount[index]) - 1 <= parseInt(item?.extension_attributes.max_order_qty)
			) {
				let count = (parseInt(stepperCount[index]) - 1).toString();
				stepperCount[index] = count;
				if (typeof obj != 'undefined') obj.value[index] = count;
				setIsCartUpdated(false);
				setStepperCount(stepperCount);
				setStepperValue(stepperValue - 1);
				addItemsToCart(item.sku, stepperCount[index], item.item_id, event);
				validationData[index] = '';
				SetValidationData(validationData);
				disableCheckoutButton(false);
				disableParkedOrder(false);
			} else if (
				parseInt(stepperCount[index]) - 1 <
				parseInt(item?.extension_attributes.min_order_qty)
			) {
				let count = (parseInt(stepperCount[index]) - 1).toString();
				stepperCount[index] = count;
				if (typeof obj != 'undefined') obj.value[index] = count;
				setStepperCount(stepperCount);
				setStepperValue(stepperValue - 1);
				//show validation
				validationData[index] = 'Min quantity is ' + item?.extension_attributes.min_order_qty;
				SetValidationData(validationData);
				disableCheckoutButton(true);
			} else if (
				parseInt(stepperCount[index]) - 1 >
				parseInt(item?.extension_attributes.max_order_qty)
			) {
				let count = (parseInt(stepperCount[index]) - 1).toString();
				stepperCount[index] = count;
				if (typeof obj != 'undefined') obj.value[index] = count;
				setStepperCount(stepperCount);
				setStepperValue(stepperValue - 1);
				//show validation
				validationData[index] = 'Max quantity is ' + item?.extension_attributes.max_order_qty;
				SetValidationData(validationData);
				disableCheckoutButton(true);
			}
		}
		// } else {
		// 	setIsLoading(false);
		// }
	}
	async function increaseCount(item, index, event) {
		// let id: any;
		event.stopPropagation();
		event.preventDefault();
		// setIsLoading(true);
		// let CUSTOMER_TOKEN = localStorage.getItem('customerToken');
		// id = await utilityFunctions.getCartId(CUSTOMER_TOKEN);
		// if (id !== undefined) {
		// 	setIsLoading(false);
		ctx.stepperdCountUpdated = true;
		let obj: any = ctx.cartItemCount?.find(
			(x: any) => x?.id == item?.extension_attributes?.supplier_id
		);
		if (parseInt(stepperCount[index]) + 1 >= 0) {
			if (
				parseInt(stepperCount[index]) + 1 >= item?.extension_attributes.min_order_qty &&
				parseInt(stepperCount[index]) + 1 <= parseInt(item?.extension_attributes.max_order_qty)
			) {
				setIsCartUpdated(false);
				let count = (parseInt(stepperCount[index]) + 1).toString();
				stepperCount[index] = count;
				if (typeof obj != 'undefined') obj.value[index] = count;
				setStepperCount(stepperCount);
				setStepperValue(stepperValue + 1);
				addItemsToCart(item.sku, stepperCount[index], item.item_id, event);
			} else if (
				parseInt(stepperCount[index]) + 1 <
				parseInt(item?.extension_attributes.min_order_qty)
			) {
				let count = (parseInt(stepperCount[index]) + 1).toString();
				stepperCount[index] = count;
				if (typeof obj != 'undefined') obj.value[index] = count;
				setStepperCount(stepperCount);
				setStepperValue(stepperValue + 1);
				// show validation
				validationData[index] = 'Min quantity is ' + item?.extension_attributes.min_order_qty;
				SetValidationData(validationData);
				disableCheckoutButton(true);
			} else if (
				parseInt(stepperCount[index]) + 1 >
				parseInt(item?.extension_attributes.max_order_qty)
			) {
				let count = (parseInt(stepperCount[index]) + 1).toString();
				stepperCount[index] = count;
				if (typeof obj != 'undefined') obj.value[index] = count;
				setStepperCount(stepperCount);
				setStepperValue(stepperValue + 1);
				// show validation
				validationData[index] = 'Max quantity is ' + item?.extension_attributes.max_order_qty;
				SetValidationData(validationData);
				disableCheckoutButton(true);
			}
		}
		// } else {
		// 	setIsLoading(false);
		// }
	}

	async function addItemsToCart(sku, count, item_id, event) {
		setIsLoading(true);
		setDisableStepper(true);
		// let id: any;
		// let CUSTOMER_TOKEN = localStorage.getItem('customerToken');
		// id = await utilityFunctions.getCartId(CUSTOMER_TOKEN);
		// if (id !== undefined) {
		let addToCartJson = {
			cartItem: {
				sku: sku,
				qty: count,
				quote_id: cartId,
			},
		};

		const obj4: Partial<Ipost> = {
			data: addToCartJson,
			params: {},
			url: '/carts/' + cartId + '/items/' + item_id,
			message: 'Your cart has been updated!',
		};
		try {
			await api
				.put(obj4)
				.then(function (data) {
					if (data !== undefined) {
						setIsCartUpdated(true);
						setIsLoading(false);
						updateCartData();
						setDisableStepper(false);
						cartOrderList.forEach((obj8, index) => {
							if (obj8.item_id === item_id) {
								obj8.qty = stepperCount[index];
								getTotalSuppAmt(obj8);
							}
						});
					} else {
						setIsLoading(true);
						setDisableStepper(false);
						callbackFn(cartOrderList);
					}
				})
				.catch(function (data) {
					setIsLoading(false);
				});
		} catch (e) {
			setIsLoading(false);
			setDisableStepper(false);
		}
	}

	const getCountDown = (order_process_time) => {
		//count down timer functionality cart
		if (order_process_time && order_process_time !== undefined && order_process_time?.next_avaliable_time !== "") {
			const countDownDate = moment(order_process_time?.next_avaliable_time, "DD-MM-YYYY HH:mm:ss").valueOf();
			const now = moment(order_process_time?.current_time, "DD-MM-YYYY HH:mm:ss").valueOf();
			const distance = countDownDate - now;
			return distance;
		} else {
			return 0;
		}
	};

	const removeItem = (event) => {
		event?.stopPropagation();
		let cartId: any;
		setShowModal(!showPopup);
		cartId = localStorage.getItem('cartId');
		const obj1: Partial<Ipost> = {
			params: {},
			url: '/carts/' + cartId + '/items/' + deleteItem.item_id,
			data: {},
			message: 'Item deleted Successfully',
		};

		api.delete(obj1).then(function (res) {
			if (res === true) {
				try {
					cartOrderList.splice(cartOrderList.indexOf(deleteItem), 1);
					callbackFn(cartOrderList);
					// updateTotalAmount();
					updateCartData();
				} catch (ex) { }
			}
		});
	};

	const confirmDelete = (event, item) => {
		event.stopPropagation();
		setDeleteItem(item);
		setShowModal(!showPopup);
	};
	const closePopup = () => {
		setShowModal(!showPopup);
	};
	const navigateToDetails = (item, event, index) => {
		event.stopPropagation();
		event.preventDefault();
		if (creditStatus[index] !== 'stop') history.push('/home/product-details?id=' + item.sku);
	};
	const getDateCheck = (date) => {
		let date1 = moment(date, 'DD-MM-YYYY').format('MM/DD/YYYY');
		let now = moment().format('MM/DD/YYYY');
		let checkValue = moment(date1).isBefore(now);
		return checkValue;
	};

	let onVacation = false;
	return (
		<StyledCartListView>
			{isLoading ? <Loader></Loader> : ''}
			{cartOrderList && cartOrderList.length > 0
				? cartOrderList.map((item, index) => {
					var deliveryMonth: any;
					let deliverydate = item.extension_attributes?.order_process_time?.next_avaliable_time;
					if (deliverydate) {
						deliveryMonth = helperFunctions.formatDate(moment(item.extension_attributes?.order_process_time?.next_avaliable_time, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD hh:mm:ss"));
					}
					return (
						<CartListHolder
							key={item.item_id}
							onClick={(event) => {
								navigateToDetails(item, event, index);
							}}
							active={item?.extension_attributes?.is_in_stock && creditStatus[index] !== 'stop'}
						// className={item?.extension_attributes?.is_in_stock == false ? 'outOfStock' : ''}
						>
							<Flex
								alignItems="center"
								as="picture"
								maxWidth={['100%']}
								className="image-wrapper"
								sx={{ overflow: 'hidden' }}
								style={{
									backgroundImage:
										'url(' +
										(item.extension_attributes.thumbnail_image
											? item.extension_attributes.thumbnail_image
											: no_image) +
										')',
								}}
							>
								{onVacation === true ? (
									<Flex as="div" className="temp-unavailable" flexDirection="column">
										<Box>Temporarily unavailable till</Box>
										<Box className="date"> 02 April 2021 </Box>
									</Flex>
								) : null}
							</Flex>
							<Flex as="section" flexDirection="column" width="100%" maxWidth={['100%']}>
								<Box as="slot">
									<Box>
										<Text
											as="h1"
											fontFamily="OpenSansBold"
											fontSize="20px"
											lineHeight="26px"
											color="text.black"
											className="truncate"
										>
											{item.name}
										</Text>
										<Text
											as="h2"
											fontSize="17px"
											fontFamily="OpenSansRegular"
											className="truncate"
											lineHeight="22px"
										>
											{item.brandType}
										</Text>

										<Text
											as="h2"
											fontSize="17px"
											fontFamily="OpenSansRegular"
											className="truncate"
											lineHeight="22px"
										>
											{item.extension_attributes.product_category_name[0]}
										</Text>
										<Flex as="main">
											<Box>
												<Text
													as="data"
													fontSize="17px"
													fontFamily="OpenSansRegular"
													color="text.black03"
												>
													MOV
													<strong> ${parseFloat(item.extension_attributes.mov).toFixed(2)}</strong>
												</Text>
											</Box>
											{getDateCheck(item.extension_attributes.stock_updated_date) ||
												item.isMovNotMet ? (
												<Tooltip
													arrow
													animation="perspective"
													position="right"
													html={
														<div className="tooltip-body tooltip-content">
															<span>
																<Text
																	as="label"
																	fontSize="17px"
																	fontFamily="OpenSansRegular"
																	color="text.black03"
																>
																	Last stock updated on
																	<strong>{item.extension_attributes.stock_updated_date}</strong>
																</Text>
															</span>
															{item.isMovNotMet ? (
																<span>
																	<Text
																		as="label"
																		fontSize="17px"
																		fontFamily="OpenSansRegular"
																		color="text.black03"
																	>
																		MOV not met
																	</Text>
																</span>
															) : (
																''
															)}
														</div>
													}
												>
													<icons.InfoIconRound />
												</Tooltip>
											) : (
												''
											)}
										</Flex>
									</Box>
									{!item?.extension_attributes?.is_in_stock && creditStatus[index] === 'go' ? (
										<Box>
											<Text
												as="h3"
												fontSize="16px"
												fontFamily="OpenSansBold"
												color="text.error"
												textAlign="right"
											>
												Out of Stock !
											</Text>
										</Box>
									) : (
										''
									)}
									{creditStatus[index] === 'stop' ? (
										<Box>
											<Text
												as="h3"
												fontSize="16px"
												fontFamily="OpenSansBold"
												color="text.error"
												textAlign="right"
											>
												Cannot place order from {item.extension_attributes.supplier_name}
											</Text>
										</Box>
									) : (
										''
									)}
								</Box>
								{/* <Box as="slot">
									<Text
										as="h4"
										fontSize="17px"
										fontFamily="OpenSansRegular"
										className="truncate"
										lineHeight="22px"
									>
										{item.retail}
									</Text>
								</Box> */}
								<Box as="slot">
									<Stepper
										stepperValue={stepperValue}
										active={item.extension_attributes.is_in_stock}
										creditStatus={creditStatus}
										stepperCount={stepperCount}
										index={index}
										decreaseCount={(event) => decreaseCount(item, index, event)}
										handleChange={(event) => handleChange(event, item, index)}
										increaseCount={(event) => increaseCount(item, index, event)}
										validationData={validationData}
										isCartUpdated={isCartUpdated}
										stopEvent={stopEvent}
										isStepperActive={disableStepper}
									/>
									<Text
										as="h5"
										fontSize="20px"
										fontFamily="OpenSansRegular"
										className="truncate"
										lineHeight="22px"
									>
										<strong> ${parseFloat(item.price).toFixed(2)} </strong>{' '}
										{item.extension_attributes?.uom_name?.length > 0
											? item.extension_attributes?.uom_name
											: 'per Pack'}
									</Text>
								</Box>
								{deliveryMonth &&
									<Box as="slot">
										<Box>
											<Text as="h6" fontSize="20px" fontFamily="OpenSansRegular" color="text.black03">
												Order in{' '}
												<strong>
													<Countdown
														date={
															Date.now() +
															getCountDown(item.extension_attributes?.order_process_time)
														}
													/>
												</strong>
												<br></br> to get it processed on <strong>{deliveryMonth}</strong>
											</Text>
										</Box>
									</Box>}
								<Box as="slot">
									<RemoveHolder>
										<Button onClick={(event) => confirmDelete(event, item)}>
											<icons.DeleteIcon />
											Remove Item
										</Button>
									</RemoveHolder>
								</Box>
							</Flex>
							<SweetAlert
								show={showPopup}
								confirmBtnText="Confirm"
								onConfirm={(event) => removeItem(event)}
								showCancel={true}
								onCancel={closePopup}
								title={'Confirmation Box'}
							//  dependencies={[this.state.firstName, this.state.lastName]}
							>
								{() => <form>{'Remove item?'}</form>}
							</SweetAlert>
						</CartListHolder>)
				})
				: ''}
		</StyledCartListView>
	);
}

export { CartListView };
