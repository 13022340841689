import React, { ReactElement } from 'react';
import { Text, Box, Flex } from 'rebass/styled-components';
import { Label, Checkbox } from '@rebass/forms';

import styled, { css } from 'styled-components';
import * as Icon from 'ui/icons';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
const StyledDeliverySlot = styled.div`
	width: 100%;
	column-gap: 20px;
	row-gap: 20px;
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	align-items: baseline;
	@media (max-width: 1400px) {
		grid-template-columns: repeat(6, 1fr);
	}
	@media (max-width: 1200px) {
		grid-template-columns: repeat(5, 1fr);
	}
	@media (max-width: 960px) {
		grid-template-columns: repeat(4, 1fr);
	}
	@media (max-width:767px) {
		grid-template-columns: repeat(3, 1fr);
	}
	@media (max-width:640px) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media (max-width:480px) {
		grid-template-columns: repeat(1, 1fr);
	}
`;
const OrderProcessingItems = styled.div<DeliverySlotBlockProps>`
	article {
		width: 100%;
		border: 1px solid ${({ theme }) => theme.colors.border.userListTD};
		border-radius: 5px;
		display: flex;
		justify-content: space-between;
		padding: 10px;
		flex-direction: column;
		label {
			font-size: 16px;
			line-height: 21px;
			font-family: 'OpenSansSemiBold';
			color: ${({ theme }) => theme.colors.text.SubHeading};
			display: flex;
			white-space: nowrap;
			align-items: center;
			width: 100%;
			span {
				width: 100%;
			}
			svg {
				width: 26px;
				height: 26px;
				margin-right: 0;
			}
			input {
				outline: none;
				box-shadow: none;
				&:focus {
					~ svg {
						& + svg {
							background: none !important;
						}
					}
					svg {
						background: none !important;
					}
				}
			}
			svg {
				color: ${({ theme }) => theme.baseColors.primary};
				background-color: transparent !important;
				& + svg {
					color: ${({ theme }) => theme.colors.border.grayMedium02};
				}
			}
			& + label {
				margin-right: 0;
			}
		}
		.inputHolder {
			position: relative;
			margin-top: 10px;
			display: none;
			z-index: 1;
			p {
				position: absolute;
				top: 8px;
				right: 5px;
				z-index: 1;
				svg {
					height: 20px;
				}
			}
			.rc-time-picker {
				width: 100%;
			}
			input {
				height: 40px;
				padding: 10px;
				font-family: 'OpenSansSemiBold';
				font-size: 1rem;
				font-weight: 400;
				line-height: 1.5;
				color: ${({ theme }) => theme.colors.text.body};
				outline: 0 !important;
				box-shadow: none !important;
				border: none !important;
			}
		}
	}
	${({ active }) =>
		!active &&
		css`
			article {
				background: ${({ theme }) => theme.baseColors.primary};
				span {
					color: ${({ theme }) => theme.baseColors.white};
				}
				label {
					svg {
						color: ${({ theme }) => theme.baseColors.white};
					}
				}
				.inputHolder {
					display: block;
				}
			}
		`}
`;

type DeliverySlotBlockProps = {
	active?: boolean;
};


function DeliverySlot({ deliverySlotList,updateDeliverySlots }): ReactElement {
	const selectCheckBox = (id,e) => {
		const objIndex = deliverySlotList.findIndex((obj => obj.id == id));
		deliverySlotList[objIndex].active = !deliverySlotList[objIndex].active;
		updateDeliverySlots(deliverySlotList);
	}
	return (
		<StyledDeliverySlot>
			{deliverySlotList
				? deliverySlotList.map((item) => (
						<OrderProcessingItems key={item.id} active={true}>
							<Box as="article">
								<Label>
									<span>{item.Day}</span>
									<Checkbox id="remember" name="remember" checked={item.active} onChange={(event) => selectCheckBox(item.id, event)}/>
								</Label>
							</Box>
						</OrderProcessingItems>
				  ))
				: null}
		</StyledDeliverySlot>
	);
}

export { DeliverySlot };
