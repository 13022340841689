import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import Countdown from 'react-countdown';
import { useHistory } from 'react-router';
import { Box, Button, Flex, Text } from 'rebass/styled-components';
import { RadioButton } from 'ui/components';
import helper from 'utils/helper.functions';
import { ItemQuantityHolder, StyledProductMainHeading } from './styles';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import icons from 'ui/icons';
import api from 'utils/requestCtx/api.service';
import Ipost from 'models/Ipost';
import constants from 'utils/constants';
import Iget from 'models/Iget';
import hooks from 'utils/requestCtx/reactQueryHooks';

function ProductMainHeading(props): ReactElement {
	const history = useHistory();
	const [showPopup, setShowModal] = useState(false);
	const [modalText] = useState('');
	const [addFavorites, setAddFavorites] = useState('');
	const [enableSplPrice, setEnableSplPrice] = useState(false);
	const [onVacation, setOnVacation] = useState(false);
	const [addedtoWatchList, setAddedtoWatchList] = useState(false);
	var deliveryMonth: any;
	const [venueUserId] = useState<any>(localStorage.getItem('userId'));
	const [venueId] = useState<any>(localStorage.getItem('selectedVenue'));
	const CUSTOMER_TOKEN = localStorage.getItem('customerToken');

	if (props.productDetails?.extension_attributes?.order_process_time?.next_avaliable_time !== undefined && props.productDetails?.extension_attributes?.order_process_time?.next_avaliable_time !== '') {
		deliveryMonth = helper.formatDate(
			moment(props.productDetails?.extension_attributes?.order_process_time?.next_avaliable_time, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD hh:mm:ss")
		);
	}
	const item: Partial<Iget> = {
		//get method for watch a pdt
		params: {},
		url:
			constants.checkWatchAProductAdded() +
			venueId +
			'/' +
			venueUserId +
			'/' +
			`${props?.productDetails?.sku}`,
		enabled: false, //called only when the props is ready
		key: 'watchAPdt',
		headers: { Authorization: CUSTOMER_TOKEN },
	};

	const qry = hooks.useQuery(item);

	useEffect(() => {
		if (qry.data !== undefined) {
			setAddedtoWatchList(qry.data);
		}
	}, [qry.data]);

	const getCountDown = (order_process_time) => {
		//count down timer functionality
		if (order_process_time && order_process_time !== undefined && order_process_time?.next_avaliable_time !== "") {
			const countDownDate = moment(order_process_time?.next_avaliable_time, "DD-MM-YYYY HH:mm:ss").valueOf();
			const now = moment(order_process_time?.current_time, "DD-MM-YYYY HH:mm:ss").valueOf();
			const distance = countDownDate - now;
			return distance;
		} else {
			return 0;
		}
	};

	useEffect(() => {
		if (props?.productDetails !== undefined) {
			qry.refetch().then(function (data) { });
			let date = moment().format('YYYY-MM-DD');
			let fromDate =
				props?.productDetails?.extension_attributes?.multi_seller_product_special_from_date !== ''
					? moment(
						props?.productDetails?.extension_attributes?.multi_seller_product_special_from_date
					).format('YYYY-MM-DD')
					: '';
			let toDate =
				props?.productDetails?.extension_attributes?.multi_seller_product_special_to_date !== ''
					? moment(
						props?.productDetails?.extension_attributes?.multi_seller_product_special_to_date
					).format('YYYY-MM-DD')
					: '';
			if (fromDate !== '' && toDate !== '') {
				if (
					moment(date).isSameOrAfter(fromDate, 'date') &&
					moment(date).isSameOrBefore(toDate, 'date')
				) {
					setEnableSplPrice(true);
				} else {
					setEnableSplPrice(false);
				}
			} else if (fromDate !== '' && toDate === '') {
				if (moment(date).isSameOrAfter(fromDate, 'date')) {
					setEnableSplPrice(true);
				} else {
					setEnableSplPrice(false);
				}
			} else {
				setEnableSplPrice(false);
			}
		}
	}, [props.productDetails]);

	const handleOnChange = (e: any, data) => {
		setAddFavorites(e.target.id);
		props.validationData('');
		props.updateStepperCount(data);
	};

	const showSupplierDetails = (data) => {
		history.push({
			pathname: '/suppliers/seller-details',
			search:
				'?sku=' +
				data?.sku +
				'&id=' +
				props?.productDetails?.extension_attributes?.supplier_id +
				'&cat=' +
				data?.extension_attributes?.productcatagory_name[0] +
				'&pdt=' +
				data?.name,
		});
	};

	async function addProductToWatchList() {
		//api call to add a pdt to watch list
		let addToCartJson = {
			productWatch: {
				venue_user_id: parseInt(venueUserId),
				venue_id: parseInt(venueId),
				sku: props.productDetails.sku,
				price: props.productDetails.price.toString(),
				is_in_stock: true,
				status: props.productDetails.status,
			},
		};

		const obj: Partial<Ipost> = {
			data: addToCartJson,
			params: {},
			headers: { Authorization: CUSTOMER_TOKEN },
			url: constants.createProductWatch(),
			message: 'Product added to watch list',
		};

		try {
			await api
				.post(obj)
				.then(function (data) {
					if (data !== undefined) {
						setAddedtoWatchList(true);
					} else {
					}
				})
				.catch(function (data) { });
		} catch (e) { }
	}
	async function deleteProductFromWatchList() {
		//api call to delete a pdt from watch list
		const obj: Partial<Ipost> = {
			data: {},
			params: {},
			headers: { Authorization: CUSTOMER_TOKEN },
			url:
				constants.removeWatchAProductAdded() +
				venueId +
				'/' +
				venueUserId +
				'/' +
				`${props?.productDetails?.sku}`,
			message: 'Product removed from watch list',
		};

		try {
			await api
				.delete(obj)
				.then(function (data) {
					if (data !== undefined) {
						if (data === true) {
							setAddedtoWatchList(false);
						}
						//
					} else {
					}
				})
				.catch(function (data) { });
		} catch (e) { }
	}

	function onSubmit() {
		setShowModal(!showPopup);
	}
	return (
		<StyledProductMainHeading>
			{props && props.productDetails ? (
				<Box m="0" key={props.productDetails?.id}>
					{/* <Button className={addedtoWatchList === false ? "addToWatchList" : "addToWatchList active"} onClick={addedtoWatchList === false ? addProductToWatchList : deleteProductFromWatchList}>
						<Tooltip
							arrow
							title="Add To WatchList"
							animation="perspective"
							position="top"
							trigger="mouseenter"
						>
							<icons.watchListIcon />
						</Tooltip>
					</Button> */}
					<Text as="h1" fontSize="36px" fontFamily="OpenSansBold" color="text.black">
						{props.productDetails.name}
						<div className="addToWatchList">
							<Tooltip
								arrow
								title={`${addedtoWatchList ? 'Remove From WatchList' : 'Add To WatchList'}`}
								animation="perspective"
								position="top"
								trigger="mouseenter"
							>
								{/* Add "active" class for button active state */}

								<Button
									variant="outline"
									className={`Favorites ${addedtoWatchList ? 'active' : ''}`}
									onClick={
										addedtoWatchList === false ? addProductToWatchList : deleteProductFromWatchList
									}
								>
									{addedtoWatchList === false ? (
										<icons.watchListIcon />
									) : (
										<icons.watchListIconActive />
									)}
								</Button>
							</Tooltip>
							{/* Active Tooltip */}
							{/* <Button onClick={addProductToWatchList}>
								<Tooltip
									arrow
									title="Add To WatchList"
									animation="perspective"
									position="top"
									trigger="mouseenter"
								>
									<icons.watchListIconActive />
								</Tooltip>
							</Button> */}
						</div>
					</Text>

					{enableSplPrice && parseFloat(
						props.productDetails?.extension_attributes?.multi_seller_product_special_price
					) > 0 ? (
						<Text as="h2" fontSize="24px" fontFamily="OpenSansRegular" color="text.black03">
							<strong>
								$
								{parseFloat(
									props.productDetails?.extension_attributes?.multi_seller_product_special_price
								).toFixed(2)}
							</strong>
							{props.productDetails?.extension_attributes?.uomname?.length > 0
								? props.productDetails?.extension_attributes?.uomname
								: 'per Pack'}
						</Text>
					) : (
						<Text as="h2" fontSize="24px" fontFamily="OpenSansRegular" color="text.black03">
							<strong>${parseFloat(props.productDetails.price).toFixed(2)}</strong>{' '}
							{props.productDetails?.extension_attributes?.uomname?.length > 0
								? props.productDetails?.extension_attributes?.uomname
								: 'per Pack'}
						</Text>
					)}
					{/*TODO: Remove if brand is required
					<Text as="h6" fontSize="20px" fontFamily="OpenSansRegular" color="text.black03">
						Brand: <strong>{props.productDetails.extension_attributes.brand_name}</strong>
					</Text> */}
					<Text as="h3" fontSize="20px" fontFamily="OpenSansRegular" color="text.black03">
						Seller:{' '}
						<strong onClick={() => showSupplierDetails(props.productDetails)}>
							{props.productDetails.extension_attributes.supplier_name}
						</strong>
					</Text>
					{deliveryMonth &&
						<Text as="h4" fontSize="20px" fontFamily="OpenSansRegular" color="text.black03">
							Order in{' '}
							<strong>
								<Countdown
									date={
										Date.now() +
										getCountDown(props.productDetails?.extension_attributes?.order_process_time)
									}
								/>
							</strong>
							<br></br> to get it processed on <strong>{deliveryMonth}</strong>
						</Text>
					}
					{/* Extra supplier details Start*/}
					{onVacation ? (
						<Text as="h4" fontSize="20px" fontFamily="OpenSansRegular" color="#EB5757">
							Delivery will be unavailable till <strong> 15 Apr 2021 </strong>as Supplier will be on
							Vacation
						</Text>
					) : null}
					{onVacation ? (
						<Box className="supplier-msg">
							<Text as="h4" fontSize="20px" fontFamily="OpenSansRegular" color="text.black03">
								<strong>Message from Supplier*</strong>
							</Text>
							<Text as="span" fontSize="18px" fontFamily="OpenSansRegular" color="text.black03">
								We are updating our product delivery services and its under maintenance. <br />
								We will resume our services by 02 April 2021. Thanks for your support.
							</Text>
						</Box>
					) : null}
					{/* Extra supplier details End*/}
					<ItemQuantityHolder>
						{props.productDetails?.tier_prices &&
							props.productDetails?.tier_prices.map((obj3, i) => {
								return (
									<RadioButton
										id={i + 1}
										key={i + 1}
										name="paymentMethod"
										onChange={(event) => handleOnChange(event, obj3.qty)}
										hasActiveBackground
									>
										<Flex alignItems="center" width="100%" pl={[1, 2]}>
											<Text
												as="h5"
												fontSize="16px"
												fontFamily="OpenSansMedium"
												color="text.blackLight"
											>
												{obj3.qty} Pieces
											</Text>
											{obj3.extension_attributes?.percentage_value ? (
												<Text as="h6" fontSize="20px" color="text.blackLight">
													<strong>${(obj3.qty * obj3.value).toFixed(2)} </strong>(
													{obj3.extension_attributes?.percentage_value}% Off)
												</Text>
											) : (
												<Text as="h6" fontSize="20px" color="text.blackLight">
													<strong>${parseFloat(obj3.value).toFixed(2)}</strong> per Piece
												</Text>
											)}
											{addFavorites === i + 1}
										</Flex>
									</RadioButton>
								);
							})}
					</ItemQuantityHolder>
				</Box>
			) : null}
			<SweetAlert
				show={showPopup}
				confirmBtnText="OK"
				onConfirm={onSubmit}
				showCancel={false}
				title={'Supplier Details'}
			//  dependencies={[this.state.firstName, this.state.lastName]}
			>
				{() => (
					<form>
						<hr />
						{modalText}
						<hr />
					</form>
				)}
			</SweetAlert>
		</StyledProductMainHeading>
	);
}

export { ProductMainHeading };
