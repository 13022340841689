import { userTypes } from 'models/enum-constants/common.constants';
import Ipost from 'models/Ipost';
import React, { ReactElement, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Box } from 'rebass/styled-components';
import { BreadcrumbNav, Button, Footer, Grid, Header, Stack } from 'ui/components';
import { FormTextarea, Sidebar } from 'ui/patterns';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import {
	AddUserHolder,

	BreadcrumbHolder, FooterBtnBlock, MainContent,


	ProfileDetails
} from './styles';



function AskHelp(): ReactElement {
	const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
	var userType = localStorage.getItem('userType');

	var userId = localStorage.getItem('userId');
	const [disableBtnSave, setDisableBtnSave] = useState(true);
	const [modalText, setmodalText] = useState('');
	const [showPopup, setShowModal] = useState(false);
	const [action, setAction] = useState('');

	const [userDetails, updateUserDetails] = useState({
		help_text: '',
	});

	const [error, setError] = useState({
		helpTextError: '',
	});
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const breadcrumbNav = [
		// { id: '01', label: 'Home',route: '/home/dashboard', active: false },
		{ id: '02', label: 'Ask For Help', active: true },
	];

	function userTypeSel() {
		if (userType == userTypes.supplierAdmin) {
			return "dc_user";
		} else if (userType == userTypes.supplierUser) {
			return "dc_user";
		} else if (userType == userTypes.venueAdmin) {
			return "venue_user";
		} else if (userType == userTypes.venueUser) {
			return "venue_user";
		} else {
			return "";
		}
	}

	const onChangeValue = (val, name) => {
		const item = userDetails;
		switch (name) {
			case 'help_text': {
				if (!val) {
					error.helpTextError = 'Field cannot be blank';
				} else {
					error.helpTextError = '';
				}
				setError(error);
				item.help_text = (val);
				break;
			}
			default:
				break;
		}
		updateUserDetails({
			...item,
		});
		if (
			userDetails &&
			userDetails.help_text &&
			// userDetails.phone &&
			error &&
			!error.helpTextError
		) {
			setDisableBtnSave(false);
		} else {
			setDisableBtnSave(true);
		}
	};

	const resetData = () => {
		clearDetails('clear');
	};

	const submitData = (action) => {
		switch (action) {
			case 'save':
				showConfirmationModal(action, 'Submit your query to ARIA?');
				break;
			case 'clear':
				if (
					userDetails.help_text != ''
				) {
					showConfirmationModal(action, 'Discard the changes?');
				}
				break;
			default:
				break;
		}
	};

	function showConfirmationModal(action, text) {
		setAction(action);
		setmodalText(text);
		setShowModal(!showPopup);
	}

	async function onSubmit() {
		if (action === 'save') {
			saveData();
		} else if (action === 'clear') {
			clearDetails('clear');
		}
		setShowModal(!showPopup);
	}

	async function closePopup() {
		await setShowModal(!showPopup);
	}

	async function saveData() {
		if (userDetails) {
			var payload = {
				"user_id": parseInt(userId + ""),
				"message": userDetails.help_text,
				"user": userTypeSel(),
				"supplier_id": 0
			};
			const obj: Partial<Ipost> = {
				params: {},
				data: { experionHelp: payload },
				url: "experion/Help/create",
				message: 'Your query has been send successfully',
			};
			try {
				api.post(obj).then((data) => {
					if (data !== undefined) {
						userDetails.help_text = '';
						updateUserDetails(JSON.parse(JSON.stringify(userDetails)));
					}
				});
			} catch (e) { }
			setShowModal(!showPopup);
		}
	}

	const clearDetails = (param) => {
		if (param === 'clear') {
			updateUserDetails({
				help_text: '',
			});
			setError({
				helpTextError: '',
			});
			setDisableBtnSave(true);
		}
	};
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	}
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={() => onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={() => onclickHambrgBtn()}></Sidebar>
			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbHolder>
					<BreadcrumbNav Heading="Ask For Help" breadcrumbList={breadcrumbNav} />
				</BreadcrumbHolder>
				{/* Add User Details */}

				<AddUserHolder>
					<ProfileDetails style={{ marginBottom: '0px' }}>
						{/* <Text as="h1" fontSize={18} fontFamily="OpenSansBold" color="text.SubHeading">
							Ask Help
						</Text> */}
						<Box as="form">
							<Grid gap={25}>

								<Box mt={25}>
									<FormTextarea label="Ask For Help" validationMessage={error.helpTextError}
										validation={error.helpTextError ? 'error' : undefined}
										value={userDetails && (userDetails.help_text ? userDetails.help_text : '')}
										placeholder="Enter your query..."
										required={false}
										onTextChange={(e) => onChangeValue(e, 'help_text')}
										maxLength={500} />
								</Box>

							</Grid>
						</Box>
					</ProfileDetails>

					{/* Footer Btn Block */}
					<FooterBtnBlock>
						<Stack direction="x" gap={3} justifyContent={['center', 'flex-end']}>
							<Button onClick={() => resetData()} flex={[1, 1, 'initial']} variant="secondary">
								Clear
							</Button>
							<Button
								disabled={disableBtnSave}
								onClick={() => submitData('save')}
								flex={[1, 1, 'initial']}
							>
								Send
							</Button>
						</Stack>
					</FooterBtnBlock>
				</AddUserHolder>
				<SweetAlert
					show={showPopup}
					confirmBtnText="Confirm"
					onConfirm={onSubmit}
					showCancel={true}
					onCancel={closePopup}
					title={'Confirmation Box'}
				>
					{() => <form>{modalText}</form>}
				</SweetAlert>
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default AskHelp;
