import { Select } from '@rebass/forms';
import React, { ReactElement } from 'react';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';
import { Stack } from 'ui/components';
import * as Icon from 'ui/icons';
import { FormInput } from 'ui/patterns';

const AddAttribute = styled.div`
	display: flex;
	height: auto;
	position: relative;
	flex-direction: column;
	background: ${({ theme }) => theme.colors.bg.white};
	padding: 20px 0 0 0;
	display: grid;
	grid-template-columns: repeat(4, 1fr) !important;
	row-gap: 20px;
	column-gap: 20px;
	@media (max-width: 1200px) {
		grid-template-columns: repeat(3, 1fr) !important;
	}
	@media (max-width: 767px) {
		grid-template-columns: repeat(2, 1fr) !important;
	}
	@media (max-width: 640px) {
		grid-template-columns: repeat(1, 1fr) !important;
	}
	div {
		grid-column: auto;
	}
	h1,
	p {
		margin: 0;
		width: 100%;
		font-weight: normal;
	}
	button {
		padding: 0;
		min-width: 100px;
		height: 48px;
		text-transform: capitalize;
		font-size: 18px;
	}
`;
function AddDynamicForm(props): ReactElement {
	return (
		<AddAttribute>
			{props.data &&
				props.data.length > 0 &&
				props.data?.map((attr, i) => {
					switch (attr.frontend_input) {
						case 'text':
							return (
								<Box key={attr.attribute_code}>
									<FormInput
										id={`${attr.attribute_code}_${attr.attribute_id}`}
										value={attr.value}
										label={attr.default_frontend_label}
										maxLength={20}
										onTextChange={(val) => props.onChangeValue(attr.attribute_code, val,'text')}
										type="text"
									/>
								</Box>
							);
						case 'select':
							return (
								<Stack categorySelect gap={0} key={i}>
									<div>
										<label>{attr.default_frontend_label}</label>
										<Select
											id={`${attr.attribute_code}_${attr.attribute_id}`}
											value={attr.default_value}
											onChange={(e) => {
												props.onChangeValue(attr.attribute_code, e.target.value,'select');
											}}
										>
													<option value = "">-Select-</option>
											{attr.options &&
												attr.options.map((option, index) => {
													if (option.value != '') {
														return (
															<option value={option.value} key={index}>
																{option.label}
															</option>
														);
													}
												})}
										</Select>
										<Icon.downArrow />
									</div>
								</Stack>
							);
					}
				})}
		</AddAttribute>
	);
}

export { AddDynamicForm };
