import { Input, Label } from '@rebass/forms';
import React, { ReactElement } from 'react';
import { Box, Heading } from 'rebass/styled-components';
import styled from 'styled-components';
import icons, * as Icon from 'ui/icons';
import BgImg from '../../images/PMI-01.png';

const LefContentHolder = styled.div`
	flex: 0 0 500px;
	max-width: 500px;
	margin-right: 142px;
	@media (max-width: 1360px) {
		flex: 0 0 400px;
		max-width: 400px;
		margin-right: 46px;
	}
	@media (max-width: 1152px) {
		flex: 0 0 350px;
		max-width: 350px;
		margin-right: 46px;
	}
	@media (max-width: 860px) {
		flex: 0 0 100%;
		max-width: 100%;
		margin-right: 0;
	}
`;
const ImageHolder = styled.div`
	row-gap: 25px;
	column-gap: 25px;
	display: grid;
	grid-template-columns: repeat(3, 1fr) !important;
	@media (max-width: 480px) {
		row-gap: 18px;
		column-gap: 18px;
	}
	div {
		width: 100%;
		border-radius: 16px;
		overflow: hidden;
		position: relative;
		img {
			width: 100%;
		}
	}
	form {
		width: 100%;
		height: 100%;
		position: relative;
		overflow: hidden;
		margin: 0;
		border: 1px dashed #000000;
		border-radius: 14px;
		flex-shrink: 0;
		@media (max-width: 860px) {
			width: 100%;
			height: auto;
		}
		.app-l-ad__upload {
			height: 100%;
			position: relative;
			cursor: pointer;
		}
		label {
			display: block;
			padding: 5px;
			margin: 0;
			height: 100%;
			position: relative;
			cursor: pointer;

			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			input {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: none;
				opacity: 0;
			}

			&:before {
				content: '';
				font-family: 'OpenSansBold';
				font-size: 12px;
				color: black;
			}
		}

		span {
			svg {
				width: 25px;
				height: 25px;
				path {
					fill: black;
				}
			}
		}
	}
`;
const PlayIconOverlay = styled.div`
	position: absolute !important;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	background: linear-gradient(0deg, rgba(34, 18, 31, 0.48), rgba(34, 18, 31, 0.48)), url(image.png);
`;
function AddVideoHolder(): ReactElement {
	return (
		<LefContentHolder>
			<Heading
				fontSize="18px"
				color="text.black"
				fontFamily="OpenSansBold"
				sx={{ textTransform: 'uppercase' }}
			>
				Videos
			</Heading>
			<ImageHolder>
				<Box as="form" p={0} fontSize={4} width={[1, 1, 1 / 2]}>
					<div className="app-l-ad__upload">
						<Label htmlFor="file">
							<Input id="file" name="file" type="file" placeholder="jane@example.com" />
							<icons.PlusIcon />
						</Label>
					</div>
				</Box>
				<Box>
					<img src={BgImg} alt="Images" style={{ maxWidth: '100%' }} />
					<PlayIconOverlay>
						<Icon.playIcon />
					</PlayIconOverlay>
				</Box>
				<Box>
					<img src={BgImg} alt="Images" style={{ maxWidth: '100%' }} />
				</Box>
				<Box>
					<img src={BgImg} alt="Images" style={{ maxWidth: '100%' }} />
				</Box>
				<Box>
					<img src={BgImg} alt="Images" style={{ maxWidth: '100%' }} />
				</Box>
				<Box>
					<img src={BgImg} alt="Images" style={{ maxWidth: '100%' }} />
				</Box>
			</ImageHolder>
		</LefContentHolder>
	);
}

export { AddVideoHolder };
