import { Input } from '@rebass/forms';
import React, { ReactElement, useEffect, useState } from 'react';
import { Flex, Text } from 'rebass/styled-components';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { Button, Loader, RadioButton } from 'ui/components';
import * as Icon from 'ui/icons';
import { AddToFavorites, AddToFavoritesContainer, CreateNewFavorites } from './styles';


export default function AddToFavoritesModal(props): ReactElement {
	const [addFavorites, setAddFavorites] = useState(0);
	const [disableSaveButton, setDisableSaveButton] = useState(true);

	const handleOnChange = (e: any) => {
		setDisableSaveButton(false);
		setAddFavorites(parseInt(e.target.id));
		props.saveProductToFav(parseInt(e.target.id));
	};

	const onNameChange = () => {
		setDisableSaveButton(false);
			
	}
	useEffect (()=>{
		if (props.isExist){
			setDisableSaveButton(true)
		}
	},[props.isExist])
	return (
		<AddToFavorites>
			<Text as="h1" color="text.textHeading" fontSize={22} fontFamily="OpenSansBold">
				Add to Favorites
			</Text>
			<Text as="p" color="text.black06" fontFamily="OpenSansMedium" fontSize={[16]}>
				Select a favorite list to add the product
			</Text>
			<CreateNewFavorites>
				<Input
					onBlur={props.validateNewCollection}
					id="email"
					name="email"
					type="email"
					placeholder="Create New Favourite Collection"
					onChange={onNameChange}
				/>
				<Button onClick={props.saveToCollection} disabled={disableSaveButton}>
					<Icon.PlusIcon />
				</Button>
			</CreateNewFavorites>
			{props.isExist ? (
				<Text as="p" fontSize="15px" fontFamily="OpenSansRegular" color="text.error">
					Favorite order exists
				</Text>
			) : (
				''
			)}
			<AddToFavoritesContainer>
				<SimpleBar>
					{props.favoriteList === undefined ? <Loader></Loader> : null}
					{props.favoriteList &&
						props.favoriteList.map((item) => (
							<RadioButton
								id={item.id}
								name="paymentMethod"
								onChange={handleOnChange}
								hasActiveText
							>
								<Flex
									alignItems="center"
									width="100%"
									pl={[1, 2]}
									sx={{ transform: 'translateY(-3px)' }}
								>
									<Text as="h1" fontFamily="OpenSansSemiBold" flex="1" px={2}>
										{item.favorite_name}
									</Text>
									{addFavorites === item.id ? <Icon.Tick /> : ``}
								</Flex>
							</RadioButton>
						))}
				</SimpleBar>
			</AddToFavoritesContainer>
		</AddToFavorites>
	);
}
