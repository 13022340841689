import React, { ReactElement } from 'react';
import { Box, Text } from 'rebass/styled-components';
import { FlexboxProps, LayoutProps, SpaceProps } from 'styled-system';
import { Button } from 'ui/components';
import * as Icon from 'ui/icons';
import { StyledCart } from './styles';

type styledSystemProps = SpaceProps & LayoutProps & FlexboxProps;
type CartProps = styledSystemProps & {
	amount?: string;
	count?: string;
};

function Cart({ count, amount, ...styledSystemProps }: CartProps): ReactElement {
	return (
		<StyledCart {...styledSystemProps}>
			<Button iconBefore={<Icon.cartIcon title="cartIcon" />} variant="cartButton">
				<Box className="badge-count" color="#fff" ml="0 !important" py="3px" height="22px">
					<Text className="count">{count}</Text>
				</Box>
				<Text className="amount">{amount}</Text>
			</Button>
		</StyledCart>
	);
}

export { Cart };
