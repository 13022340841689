import React, { ReactElement} from 'react';
import { StyledAccordionVenue, AccordionPanel, VenueDetails } from './styles';
import { Text, Flex, Box } from 'rebass/styled-components';
import Collapsible from 'react-collapsible';

function AccordionVenue(prop): ReactElement {
	return (
		<StyledAccordionVenue>
			<AccordionPanel>
				<Collapsible open={true} trigger="STORE INFORMATION">
					<VenueDetails>
						<Flex as="section" flexWrap="wrap" mx={-2} width="100%" maxWidth={['100%']}>
							<Box p={2} width={[1, 1 / 2, 1 / 3, 1 / 4, 1 / 5]} color="white" bg="none">
								<Text fontSize={16} fontWeight="normal" color="black">
									Store Name
								</Text>
								<Text fontSize={16} fontWeight="bold" color="black">
									{prop.setData && prop.setData.name}
								</Text>
							</Box>
							<Box p={2} width={[1, 1 / 2, 1 / 3, 1 / 4, 1 / 5]} color="white" bg="none">
								<Text fontSize={16} fontWeight="normal" color="black">
									Email
								</Text>
								<Text fontSize={16} fontWeight="bold" color="black">
									{prop.setData && prop.setData.venue_email}
								</Text>
							</Box>
							<Box p={2} width={[1, 1 / 2, 1 / 3, 1 / 4, 1 / 5]} color="white" bg="none">
								<Text fontSize={16} fontWeight="normal" color="black">
									Phone
								</Text>
								<Text fontSize={16} fontWeight="bold" color="black">
									{prop.setData && prop.setData.phone}
								</Text>
							</Box>
							<Box p={2} width={[1, 1 / 2, 1 / 3, 1 / 4, 1 / 5]} color="white" bg="none">
								<Text fontSize={16} fontWeight="normal" color="black">
									No of Parked Orders
								</Text>
								<Text fontSize={16} fontWeight="bold" color="black">
									{prop.setData && prop.setData.number_of_parked_orders}
								</Text>
							</Box>
							<Box p={2} width={[1, 1 / 2, 1 / 3, 1 / 4, 1 / 5]} color="white" bg="none">
								<Text fontSize={16} fontWeight="normal" color="black">
									Status
								</Text>
								<span className="status-button-label">
									{prop.setData && prop.setData.is_active === true
										? 'Active'
										: 'Inactive'}
								</span>
							</Box>
						</Flex>
					</VenueDetails>
				</Collapsible>
			</AccordionPanel>
			{/* <AccordionPanel>
				<Collapsible trigger="ACCOUNT INFORMATION">
					<VenueDetails>
						<Flex as="section" flexWrap="wrap" mx={-2} width="100%" maxWidth={['100%']}>
							<Box p={2} width={[1, 1 / 2, 1 / 3, 1 / 4, 1 / 5]} color="white" bg="none">
								<Text fontSize={16} fontWeight="normal" color="black">
									Venue Name
								</Text>
								<Text fontSize={16} fontWeight="bold" color="black">
									Burger Spot
								</Text>
							</Box>
							<Box p={2} width={[1, 1 / 2, 1 / 3, 1 / 4, 1 / 5]} color="white" bg="none">
								<Text fontSize={16} fontWeight="normal" color="black">
									Email
								</Text>
								<Text fontSize={16} fontWeight="bold" color="black">
									burgerspot@gmail.com
								</Text>
							</Box>
							<Box p={2} width={[1, 1 / 2, 1 / 3, 1 / 4, 1 / 5]} color="white" bg="none">
								<Text fontSize={16} fontWeight="normal" color="black">
									Phone
								</Text>
								<Text fontSize={16} fontWeight="bold" color="black">
									+6463567134
								</Text>
							</Box>
							<Box p={2} width={[1, 1 / 2, 1 / 3, 1 / 4, 1 / 5]} color="white" bg="none">
								<Text fontSize={16} fontWeight="normal" color="black">
									No of Parked Orders
								</Text>
								<Text fontSize={16} fontWeight="bold" color="black">
									10
								</Text>
							</Box>
							<Box p={2} width={[1, 1 / 2, 1 / 3, 1 / 4, 1 / 5]} color="white" bg="none">
								<Text fontSize={16} fontWeight="normal" color="black">
									Status
								</Text>
								<span className="status-button-label">Active</span>
							</Box>
						</Flex>
					</VenueDetails>
				</Collapsible>
			</AccordionPanel> */}
			<AccordionPanel>
				<Collapsible trigger="LEGAL ADDRESS">
					<VenueDetails>
						<Flex as="section" flexWrap="wrap" mx={-2} width="100%" maxWidth={['100%']}>
							<Box p={2} width={[1, 1 / 2, 1 / 3, 1 / 4, 1 / 5]} color="white" bg="none">
								<Text fontSize={16} fontWeight="normal" color="black">
									Address
								</Text>
								<Text fontSize={16} fontWeight="bold" color="black">
									{prop.setData && prop.setData.address}
								</Text>
							</Box>
							<Box p={2} width={[1, 1 / 2, 1 / 3, 1 / 4, 1 / 5]} color="white" bg="none">
								<Text fontSize={16} fontWeight="normal" color="black">
									Country
								</Text>
								<Text fontSize={16} fontWeight="bold" color="black">
									{prop.setData && prop.setData.country}
								</Text>
							</Box>
							<Box p={2} width={[1, 1 / 2, 1 / 3, 1 / 4, 1 / 5]} color="white" bg="none">
								<Text fontSize={16} fontWeight="normal" color="black">
									State
								</Text>
								<Text fontSize={16} fontWeight="bold" color="black">
									{prop.setData && prop.setData.state}
								</Text>
							</Box>
							<Box p={2} width={[1, 1 / 2, 1 / 3, 1 / 4, 1 / 5]} color="white" bg="none">
								<Text fontSize={16} fontWeight="normal" color="black">
									City
								</Text>
								<Text fontSize={16} fontWeight="bold" color="black">
									{prop.setData && prop.setData.city}
								</Text>
							</Box>
							<Box p={2} width={[1, 1 / 2, 1 / 3, 1 / 4, 1 / 5]} color="white" bg="none">
								<Text fontSize={16} fontWeight="normal" color="black">
									Zip Code
								</Text>
								<Text fontSize={16} fontWeight="bold" color="black">
									{prop.setData && prop.setData.zip_code}
								</Text>
							</Box>
						</Flex>
					</VenueDetails>
				</Collapsible>
			</AccordionPanel>
		</StyledAccordionVenue>
	);
}

export { AccordionVenue };
