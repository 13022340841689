import { Column } from 'react-table';
import styled from 'styled-components';
const StyledOtherSuppliers = styled.div`
	width: 100%;
	padding: 44px 0 0 0;
	@media (max-width: 860px) {
		padding: 30px 0 0 0;
	}
`;
const ViewAllHeadingBlock = styled.div`
	display: flex !important;
	justify-content: space-between;
	padding: 0;
	color: ${({ theme }) => theme.colors.text.black};
	min-height: 30px;
	display: flex;
	align-items: center;
	h1,
	a {
		font-weight: normal;
		font-family: 'OpenSansMedium';
	}
	h1 {
		font-family: 'OpenSansBold';
		text-transform: uppercase;
	}
	a {
		color: ${({ theme }) => theme.baseColors.baseYellow};
		text-decoration: none;
		transition: background 0.8s;
		transition: ease-in all 200ms;

		&:hover {
			background: ${({ theme }) => theme.baseColors.baseYellow};
			padding: 3px 10px;
			color: ${({ theme }) => theme.baseColors.white};
			border-radius: 5px;
		}
	}
`;
const OtherSuppliersDetails = styled.div`
	padding: 20px 0;
	/* height: calc(100vh - 350px); */
	@media (max-width: 860px) {
		padding: 20px 0 0;
		div {
			position: relative !important;
		}
	}

	.simplebar-wrapper {
		max-height: 400px;
		margin-bottom: 30px;
		.simplebar-mask {
			border: 1px solid ${({ theme }) => theme.colors.border.grayLightBorder01};
			border-radius: 12px;
		}
	}

	article {
		margin: 0;
		padding: 20px 30px;
		border-bottom: none;
		border-top: 1px solid ${({ theme }) => theme.colors.border.grayLightBorder01};
		cursor: pointer;
		&:hover {
			cursor: pointer;
			background: rgba(250, 244, 234, 1);
		}

		@media (max-width: 860px) {
			padding: 20px !important;
		}
		&:first-child {
			border: none !important;
		}
		picture {
			width: 60px;
			height: 60px;
			border-radius: 50px;
			margin-right: 20px;
			img {
				width: 60px;
				height: 60px;
				object-fit: contain;
			}
		}
		div {
			h3 {
				font-weight: normal;
				font-family: 'OpenSansMedium';
				line-height: 24px;
				margin-top: 6px;
				strong {
					font-family: 'OpenSansBlack';
				}
			}
			h4 {
				margin-top: 13px;
				font-family: 'OpenSansBlack';
			}
			@media (max-width: 860px) {
				display: flex;
				justify-content: space-between;
				flex-direction: Column;
				h3 {
					margin-top: 0;
				}
			}
		}
	}
`;
export { StyledOtherSuppliers, ViewAllHeadingBlock, OtherSuppliersDetails };
