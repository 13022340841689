import { condition, userTypes } from 'models/enum-constants/common.constants';
import Iget from 'models/Iget';
import React, { ReactElement, useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { BreadcrumbNav, Footer, Header, Loader, TabsView } from 'ui/components';
import { Sidebar, UsersList, VenueCardHolder, VenueList } from 'ui/patterns';
import urls from 'utils/create.url';
import hooks from 'utils/requestCtx/reactQueryHooks';
import {
	BreadcrumbHolder, CardsAndNotificationHolder, MainContent,

	TabContentHolder
} from './styles';



function UserDashboard(): ReactElement {
	let userType = localStorage.getItem('userType');
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const [venueCount, setVenueCount] = useState(0);
	const [userCount, setUserCount] = useState(0);
	const breadcrumbNav = [
		{ id: '01', label: 'Home', route: '/home/dashboard', active: false },
		{ id: '02', label: 'Dashboard', active: true },
	];
	
	//Getting count for users
	const userKey = 'userCountKey';
	const venueKey = 'venueCountKey';
	const urlUser = urls.GetSearchUrl({
		url: 'experion/venueuser/search?',
		pageSize: 2000,
		fields : [{field_name: 'hg_id',
		field_value: localStorage.getItem("hospitalityId")!},
		{field_name: 'magento_user_id',
			field_value: localStorage.getItem("hgAdminId")!,
			field_conditionType : condition.neq}
	],
		filterDeleted: true,
	});
	const objUser: Partial<Iget> = {
		params: {},
		url: urlUser,
		enabled: true,
		key: userKey,
		resultFilteringRequired: true,
	};
	const qryUserCount = hooks.useQuery(objUser);
	useEffect(() => {
		if(qryUserCount.data != undefined){
			calculateUserCount(qryUserCount.data?.length);
		}else{
			calculateUserCount(0);
		}
	}, [qryUserCount.data]);

	const urlVenue = 
	urls.GetSearchUrl({
		url: 'experion/venue/search?',
		pageSize: 2000,
		fields : [{field_name: 'hosp_id',
		field_value: localStorage.getItem("hospitalityId")!}],
		filterDeleted: true,
	});
	const objVenue: Partial<Iget> = {
		params: {},
		url: urlVenue,
		enabled: true,
		key: venueKey,
		resultFilteringRequired: true,
	};
	const qryVenueCount = hooks.useQuery(objVenue);
	useEffect(() => {
		if(qryVenueCount.data != undefined){
			calculateVenueCount(qryVenueCount.data?.length);
		}else{
			calculateVenueCount(0);
		}		
	}, [qryVenueCount.data]);
	const calculateVenueCount = (data) => {
		// if(data < 0){
		// 	setVenueCount(venueCount - data)
		// }else{
		// 	setVenueCount(data);
		// }
		qryVenueCount.refetch().then(function (data) {
			setVenueCount(data?.length);
		});
	};
	const calculateUserCount = (data) => {
		// if(data < 0){
		// 	setUserCount(userCount - data)
		// }else{
		// 	setUserCount(data);
		// }
		qryUserCount.refetch().then(function (data) {
			setUserCount(data?.length);
		});		
	};

	window.scroll({ top: 0, left: 0, behavior: 'smooth' });
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	}
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={()=>onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={()=>onclickHambrgBtn()}></Sidebar>
			{qryVenueCount == null ? <Loader></Loader> : null}
			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbHolder>
					<BreadcrumbNav Heading="Dashboard" breadcrumbList={breadcrumbNav} />
				</BreadcrumbHolder>
				<CardsAndNotificationHolder>
					<VenueCardHolder venueCount={venueCount} userCount={userCount}></VenueCardHolder>
					{/* <Notifications count="20"></Notifications> */}
				</CardsAndNotificationHolder>
				{
					userType == userTypes.venueAdmin && (
						<TabContentHolder>
							<TabsView>
								<Tabs>
									<TabList>
										<Tab>Store</Tab>
										<Tab>Users</Tab>
									</TabList>

									<TabPanel>
										<VenueList getVenueCount={calculateVenueCount}></VenueList>
									</TabPanel>
									<TabPanel>
										<UsersList getUserCount={calculateUserCount}></UsersList>
									</TabPanel>
								</Tabs>
							</TabsView>
						</TabContentHolder>
					)
				}
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default UserDashboard;
