import React, { ReactElement } from 'react';
import 'react-tippy/dist/tippy.css';
import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import helper from 'utils/helper.functions';
import no_image from 'ui/images/no_image.png';

const StyledOrdersDetailsListView = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	column-gap: 20px;
	row-gap: 20px;
	.order-details__wrap {
		flex-direction: row;
		background: ${({ theme }) => theme.colors.bg.white};
		border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
		box-sizing: border-box;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 16px;
		overflow: hidden;
		transition: box-shadow 100ms ease-in, transform 100ms ease-in;
		align-items: flex-start;
		height: 100%;
		@media (max-width: 640px) {
			flex-direction: column;
		}
		picture {
			max-width: 216px;
			flex: 0 0 216px;
			height: 100%;
			position: relative;
			border-right: 1px solid ${({ theme }) => theme.colors.bg.lightYellow05};
			background-color: #faf4ea;
			div {
				position: relative;
				width: calc(100% - 10px);
				height: calc(100% - 10px);
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center;
				margin: 5px;
			}
			@media (max-width: 768px) {
				max-width: 126px;
				flex: 0 0 126px;
				background-position: center center;
			}
			@media (max-width: 640px) {
				max-width: 100%;
				flex: 0 0 100%;
				height: 150px;
				min-height: 150px;
				width: 100%;
				max-height: 150px;
			}
		}
		section {
			display: Grid !important;
			align-items: center;
			height: 100%;
			grid-template-columns: repeat(3, 1fr) !important;
			row-gap: 20px;
			padding: 25px;
			column-gap: 10px;
			object {
				div {
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					display: block;
				}
			}
			@media (max-width: 768px) {
				padding: 20px;
			}
			@media (max-width: 640px) {
				grid-template-columns: repeat(1, 1fr) !important;
				row-gap: 10px;
				position: relative;
				height: auto;
				.qty {
					position: absolute;
					bottom: 13px;
					right: 20px;
					h3 {
						font-size: 26px;
					}
				}
			}
			.btn-block {
				display: flex;
				flex-direction: column;
				align-items: center;
				span {
					height: auto;
					flex: 0 0 auto;
					max-width: auto;
					min-width: auto;
				}

				div {
					word-break: break-word;
					span {
						svg {
							margin-left: 12px;
						}
						text {
							justify-content: center;
						}
					}
				}
				button {
					margin-top: 16px;
				}
			}
			object {
				grid-column: 3 / 1;
				grid-row: auto / auto;
				@media (max-width: 640px) {
					grid-column: auto;
					grid-row: auto;
				}
				strong {
					font-family: 'OpenSansBold';
					font-size: 17px;
				}
			}
			h1 {
				strong {
					font-family: 'OpenSansBold';
					margin-right: 5px;
				}
			}
			h2 {
				justify-content: center;
				display: flex;
				@media (max-width: 1440px) {
					justify-content: flex-end;
				}
				@media (max-width: 640px) {
					justify-content: flex-start;
				}
				strong {
					font-family: 'OpenSansBold';
					margin-left: 5px;
					font-size: 17px;
				}
			}
			h3 {
				justify-content: flex-end;
				display: flex;
				@media (max-width: 640px) {
					justify-content: flex-start;
				}
			}
			.stepperBlock {
				display: flex;
				align-items: center;
				white-space: nowrap;
			}
		}
		.quantityAndDelete {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
			button {
				margin: 0 100px;
				@media (max-width: 640px) {
					margin: 0 50px;
				}
			}
		}
	}
`;
const RemoveHolder = styled.div`
	display: flex;
	position: relative;
	left: 0;
	max-width: 100% !important;
	margin: 0 !important;
	flex: 1 !important;
	white-space: nowrap;
	cursor: pointer;
	justify-content: flex-end;
	@media (max-width: 1440px) {
		justify-content: flex-start;
	}
	button {
		padding: 0;
		border: none;
		color: ${({ theme }) => theme.colors.text.black};
		font-size: 16px;
		text-transform: capitalize;
		background: transparent !important;
		box-shadow: none !important;
		outline: none !important;
		&:hover {
			opacity: 0.5;
		}
		span {
			display: flex;
			align-items: center;
			span {
				margin-right: 15px;
			}
		}
	}
`;
function PendingOrdersDetailsListView({ pendingOrdersDetailsListView }): ReactElement {
	return (
		<StyledOrdersDetailsListView>
			{pendingOrdersDetailsListView
				? pendingOrdersDetailsListView.map((item, i) => {
						return (
							<Flex
								className="order-details__wrap selected"
								bg="white"
								sx={{ alignItems: 'center' }}
								key={i}
							>
								<Flex
									alignItems="center"
									as="picture"
									maxWidth={['100%']}
									sx={{ overflow: 'hidden' }}
								>
									<Box
										style={{
											backgroundImage:
												'url(' +
												(item.extension_attributes.image_details &&
												item.extension_attributes.image_details !== ''
													? item.extension_attributes.image_details
													: no_image) +
												')',
										}}
									></Box>
								</Flex>
								<Flex
									as="section"
									flexWrap="wrap"
									flexDirection="row"
									width="100%"
									maxWidth={['100%']}
								>
									<Box as="object">
										<Text fontSize={20} fontWeight="bold" color="text.black">
											{item.name}
										</Text>
										<Text fontSize={17} fontWeight="normal" color="text.black">
											{item.extension_attributes.product_catagory &&
												item.extension_attributes.product_catagory[0]}
										</Text>
									</Box>
									<Box className="qty">
										<Text as="h3" fontSize={30} fontFamily="OpenSansBlack" color="text.black">
											{helper.formatAmount(parseFloat(item.price) * parseInt(item.qty_ordered))}
										</Text>
									</Box>
									<Box>
										<Text as="h1" fontSize={17} color="text.black">
											<strong>{helper.formatAmount(item.price)}</strong>{' '}
											{item.extension_attributes?.uom_name?.length > 0
												? item.extension_attributes?.uom_name
												: 'per Pack'}
										</Text>
									</Box>
									<Box>
										<Text as="h2" fontSize={16} fontWeight="normal" color="text.black">
											No. of items <strong>{item.qty_ordered}</strong>
										</Text>
									</Box>
									{/* <Box>
										<Text fontSize={17} fontWeight="normal" color="text.black">
											Company Name Here
										</Text>
									</Box> */}
								</Flex>
							</Flex>
						);
				  })
				: null}
		</StyledOrdersDetailsListView>
	);
}

export { PendingOrdersDetailsListView };
