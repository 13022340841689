import styled from 'styled-components';
import { flexbox, FlexProps, layout, LayoutProps, space, SpaceProps } from 'styled-system';

type MainContentProps = FlexProps &
	LayoutProps &
	SpaceProps & {
		contentDirection: 'x' | 'y';
	};

const MainContent = styled.main<MainContentProps>`
	${flexbox}
	${layout}
	${space}
	flex: 1 0 auto;
	display: flex;
	padding: ${({ theme }) => theme.space[3]};
	flex-direction: ${({ contentDirection }) => {
		if (contentDirection === 'x') return 'row';
		if (contentDirection === 'y') return 'column';
	}};
`;

export { MainContent };
