import { Select } from '@rebass/forms';
import Iget from 'models/Iget';
import queryString from 'query-string';
import React, { ReactElement, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory, useLocation } from 'react-router-dom';
import { Box } from 'rebass/styled-components';
import { BreadcrumbNav, Button, Footer, Grid, Header, Loader, Stack } from 'ui/components';
import * as Icon from 'ui/icons';
import { AttributeEntryTypes, FormInput, Sidebar } from 'ui/patterns';
import urls from 'utils/create.url';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import notify from 'utils/toast.service';
import {
	AddUserHolder,
	AssignVenueHolder, FooterBtnBlock, MainContent
} from './styles';


function AddAttribute(): ReactElement {
	console.log = console.warn = console.error = () => {};
	let userId = localStorage.getItem('userId');
	const [actionType, setActionType] = useState('Add');
	const [action, setAction] = useState('');
	const [showPopup, setShowModal] = useState(false);
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const breadcrumbNav = [
		{ id: '01', label: 'Dashboard', route: '/admin/seller-dashboard', active: false },
		{ id: '02', label: 'Attribute Management', route: '/admin/attributeManagement', active: false },
		{ id: '03', label: actionType == 'Add'? 'Add Attribute' : 'Edit Attribute', active: true },
	];
	const [isLoading, setIsLoading] = useState(false);
	const [attributeList, setAttributeList] = useState([] as any);
	const [modalText, setmodalText] = useState('');
	const [attributeSetList, setAttributeSetList] = useState([] as any);
	const [data, setData] = useState({ name: '', type: 'text', attribute: '', attributeSet: 
	'',attribute_id : 0,attribute_code : ''});
	const [attributeName, setAttributeName] = useState(null);
	const [error, setError] = useState({
		nameError: '',
		attributeError: '',
		attributeSetError: '',
		attributeCodeError: ''
	});
	const url = urls.GetSearchUrl({
		url: 'products/attribute-sets/sets/list?',
		pageSize: 500,
		ctx: { avoidSort: true },
	});
	const obj: Partial<Iget> = {
		params: {},
		url: url,
		enabled: true,
		key: 'attributes',
		resultFilteringRequired: true,
	};
	const attributeListQry = hooks.useQuery(obj);
	function addAttributeSet() {
		if (!attributeList?.map(x=>x.label).includes(data.attribute)) {
			attributeList?.push({label : data.attribute, is_default : false});
			data.attribute = '';
			setData(JSON.parse(JSON.stringify(data)));
			setAttributeList(JSON.parse(JSON.stringify(attributeList)));
		} else {
			notify('Attribute entry already exists !!').error();
		}
	}

	function deleteAttribute(val) {
		setAttributeList(attributeList?.filter((x) => x.label != val));
	}
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	}
	function clearData() {
		data.attribute = '';
		data.name = '';
		data.attribute_code = '';
		data.type = 'text';
		data.attributeSet = '';
		setData(JSON.parse(JSON.stringify(data)));
		setAttributeList([]);
		setError({
			nameError: '',
			attributeError: '',
			attributeSetError: '',
			attributeCodeError: ''
		});
	}

	const handleChange = (val, name) => {
		const item = data;
		//const data = assignedUser;
		switch (name) {
			case 'name':
				if (!val) {
					setError({ ...error, nameError: 'Attribute Name cannot be blank' });
				} else {
					setError({ ...error, nameError: '' });
				}
				if(parseInt(val) < 10)
				{
					notify('Attribute name should start with a letter').error();
					return false;
				}
				item.name = val;
				break;
			case 'code':
				if (!val) {
					setError({ ...error, attributeCodeError: 'Attribute Code cannot be blank' });
				} else {
					setError({ ...error, attributeCodeError: '' });
				}
				if(parseInt(val) < 10)
				{
					notify('Attribute code should start with a letter').error();
					return false;
				}
				item.attribute_code = val.replace(/[^\w\s]/gi, "");
				break;
			case 'type':
				item.type = val;
				break;
			case 'attributeSet':
				if (val == '') {
					setError({ ...error, attributeSetError: 'Attribute set required' });
				} else {
					setError({ ...error, attributeSetError: '' });
				}
				item.attributeSet = val;
				break;

			case 'attribute':
				item.attribute = val;
				break;
			default:
				break;
		}
		setData({
			...item,
		});
	};

	function saveAttributes() {
		setIsLoading(true);
		let payload = constructPayload();
		if (actionType == 'Add') {
			try {
				api.post({
					url: 'products/attributes', data: payload, message:
						'Attribute added successfully'
				}).then(
					function (data) {
						if (data) {
							setIsLoading(false);
							history.push('/admin/attributeManagement');
						} else {
							setIsLoading(false);
						}
					}
				)
			} catch (e) { setIsLoading(false); }
		}
		else {
			if (attributeName && attributeName === data.name) {
				notify('Attribute name updated successfully').success();
				setIsLoading(false);
				history.push('/admin/attributeManagement');
			} else {
				try {
					api.put({
						url: `products/attributes/${data.attribute_code}`, data: payload,
						message: 'Attribute name updated successfully'
					}).then(
						function (data) {
							if (data) {
								setIsLoading(false);
								history.push('/admin/attributeManagement');
							} else {
								setIsLoading(false);
							}
						}
					);
				} catch (e) { setIsLoading(false); }
			}
		}
	}

	function constructPayload() {
		let options = [] as any;
		attributeList?.map((v) => {
			if(v.label != ''){
				options.push(
					{
						is_default: v.is_default,
						label: v.label,
					});
			}
		});
		return {
			attribute: {
				extension_attributes: {
					user_id: userId,
					...(actionType =='Add') && {attribute_set: data.attributeSet},
				}, 
				is_wysiwyg_enabled: false,
				is_html_allowed_on_front: true,
				used_for_sort_by: true,
				is_filterable: data.type == 'text' ? false : true,
				is_filterable_in_search: data.type == 'text' ? false : true,
				is_filterable_in_grid: data.type == 'text' ? false : true,
				is_used_in_grid: true,
				is_visible_in_grid: true,
				position: 0,
				apply_to: [],
				is_searchable: '1',
				is_visible_in_advanced_search: '1',
				is_comparable: '0',
				is_used_for_promo_rules: '0',
				is_visible_on_front: '1',
				used_in_product_listing: '1',
				is_visible: true,
				scope: 'global',
				...(actionType =='Add') && {attribute_code: data.attribute_code !== '' ? data.attribute_code.toLowerCase().replace(/\s/g, '')
				: data.name.toLowerCase().replace(/\s/g, '')},
				...(actionType =='Add') && {frontend_input: data.type},
				entity_type_id: '4',
				is_required: false,
				...(actionType =='Add') && {options: options},
				is_user_defined: true,
				default_frontend_label: data.name,
				...(actionType == 'Save') && {attribute_id: data.attribute_id},
				frontend_labels: [],
				backend_type: data.type == 'text' ? 'varchar' : 'int',
				...(actionType =='Add') && {source_model:
					data.type == 'select' ? 'Magento\\Eav\\Model\\Entity\\Attribute\\Source\\Table' : ''},
				...(actionType =='Add') && {default_value: ''},
				is_unique: '0',
				validation_rules: [],
			},
		};
	}

	function setDefault(val){
		attributeList?.map((data)=>{
			if(data.label == val)
			data.is_default = true;
			else
			data.is_default = false;
		})
		setAttributeList(JSON.parse(JSON.stringify(attributeList)));
	}

	let history = useHistory();
	const location = useLocation() as any;
	const parsed = queryString.parse(location.search);
	useEffect(() => {
		if (typeof location.search != 'undefined' && parsed.code) {
			setActionType('Save');
			setIsLoading(true);
			try {api.get({ url: `products/attributes/${parsed.code}` }).then(function (data) {
				setData({
					name: data.default_frontend_label,
					type: data.frontend_input,
					attribute_id : data.attribute_id,
					attribute_code :  data.attribute_code,
					attribute : '',
					attributeSet: data.extension_attributes?.attribute_set
				});
				setAttributeName(data.default_frontend_label);
				let defaultOption = data.options?.find(x=>x.value == data.default_value);
				if(defaultOption)
				defaultOption.is_default = true;
				setAttributeList(data.options?.filter(x=>x.value != ''));
				window.scroll({ top: 0, left: 0, behavior: 'smooth' });
				setIsLoading(false);
			})} catch (e) {setIsLoading(false);}
		}
	}, [location]);

	useEffect(() => {
		setAttributeSetList(attributeListQry.data);
	}, [attributeListQry.data]);

		//popup 

		function confirmPopup(action){
			switch(action){
				case 'cancel': showConfirmationModal(action,'Are you sure you want to cancel ?');
				break;
				case 'clear': showConfirmationModal(action,'Are you sure you want to clear all data ?');
				break;
				case 'Save' : showConfirmationModal(action,'Are you sure you want to update the name?'); 
				break;
				case 'Add': saveAttributes();
				break;
				default: break;	
			}
		}
		async function closePopup() {
			await setShowModal(!showPopup);
		}
		function showConfirmationModal(action, text) {
			setAction(action);
			setmodalText(text);
			 setShowModal(!showPopup);
		}
		async function onSubmit() {
			if (action === 'clear') {
				clearData();
			} else if (action === 'cancel') {
			history.push('/admin/attributeManagement');
			}
			else if(action == 'Save')
				saveAttributes();
			setShowModal(!showPopup);
		}

	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={()=>onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar}
				toggleSideBar={()=>onclickHambrgBtn()}></Sidebar>
			{isLoading ? <Loader></Loader> : null}
			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbNav Heading= {actionType == 'Add'? 'Add Attribute' : 'Edit Attribute'} breadcrumbList={breadcrumbNav} />
				{/* Add User Details */}

				<AddUserHolder>
					<AssignVenueHolder>
						<Box as="form" mt={25}>
							<Grid gap={4}>
								<FormInput
									label="Attribute Name"
									autoComplete="off"
									validationMessage={error.nameError}
									validation={error.nameError ? 'error' : undefined}
									required={true}
									value={data.name}
									onTextChange={(e) => handleChange(e, 'name')}
									maxLength={50}
									type="text"
								/>
									{actionType == 'Add' && <FormInput
									label="Attribute Code"
									autoComplete="off"
									validationMessage={error.attributeCodeError}
									validation={error.attributeCodeError ? 'error' : undefined}
									required={true}
									value={data.attribute_code}
									onTextChange={(e) => handleChange(e, 'code')}
									maxLength={50}
									type="text"
								/>}
								{actionType == 'Add' && <Stack categorySelect validation={error.attributeSetError ? 'error' : undefined} gap={0}>
									<div>
										<label>
											Attribute Set<sup>*</sup>
										</label>
										<Select
											value={data.attributeSet}
											onChange={(e) => handleChange(e.target.value, 'attributeSet')}
										>
											<option value = ''>-Select-</option>
											{attributeSetList &&
												attributeSetList?.map((option, i) => {
													return (
														<option value={option.attribute_set_id} key={i}>
															{option.attribute_set_name}
														</option>
													);
												})}
										</Select>
										<Icon.downArrow />
									</div>
								</Stack>}
								<Stack categorySelect 
								  gap={0}>
									<div>
										<label>
											Attribute Type<sup>*</sup>
										</label>
										<Select
												disabled = {actionType == 'Save'}
											value={data.type}
											onChange={(e) => handleChange(e.target.value, 'type')}
										>
											<option value='text'>Text</option>
											<option value ='select'>Dropdown</option>
										</Select>
										<Icon.downArrow />
									</div>
								</Stack>
							</Grid>
						</Box>
						{data.type == 'select' && (
							<AttributeEntryTypes
								attribute={data.attribute}
								addAttributeSet={addAttributeSet}
								handleChange={handleChange}
								attributeList={attributeList}
								deleteAttribute={deleteAttribute}
								setDefault = {setDefault}
								data={data}
								action = {actionType}
							/>
						)}
					</AssignVenueHolder>

					{/* Footer Btn Block */}
					<FooterBtnBlock>
						<Stack direction="x" gap={3} justifyContent={['center', 'flex-end']}>
							<Button
								flex={[1, 1, 'initial']}
								variant="secondary"
								onClick={() => confirmPopup('cancel')}
							>
								Cancel
							</Button>
							{actionType == 'Add' && (
								<Button
									flex={[1, 1, 'initial']}
									variant="secondary"
									onClick={() => confirmPopup('clear')}
								>
									Clear
								</Button>
							)}
							<Button
								flex={[1, 1, 'initial']}
								disabled={
									data.name == '' || data.attributeSet == '' ||
									 (data.type == 'select' && attributeList?.length == 0)}
								onClick={()=>confirmPopup(actionType)}
							>
								{actionType}
							</Button>
						</Stack>
					</FooterBtnBlock>
				</AddUserHolder>
				<SweetAlert
					show={showPopup}
					confirmBtnText="Confirm"
					onConfirm={onSubmit}
					showCancel={true}
					onCancel={closePopup}
					title={'Confirmation Box'}
				>
					{() => <form>{modalText}</form>}
				</SweetAlert>
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default AddAttribute;
