import Ipost from 'models/Ipost';
import React, { ReactElement, useState } from 'react';
import { Box, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { Button, Stack } from 'ui/components';
import { FormInput } from 'ui/patterns';
import hooks from 'utils/requestCtx/reactQueryHooks';

const NewParkedOrder = styled.div`
	display: flex;
	height: auto;
	position: relative;
	flex-direction: column;
	background: ${({ theme }) => theme.colors.bg.white};
	padding: 0 10px 12px;
	@media (max-width: 960px) {
		padding: 0;
	}
	h1 {
		margin: 0 0 30px 0;
		width: 100%;
		font-weight: normal;
		text-transform: uppercase;
	}
	label {
		font-size: 16px;
		line-height: 21px;
		font-family: 'OpenSansSemiBold';
		color: ${({ theme }) => theme.colors.text.SubHeading};
		margin-bottom: 8px;
	}
	button {
		padding: 0;
		min-width: 100px;
		min-height: 50px;
		text-transform: capitalize;
		font-size: 18px;
		margin: 0 0 0 20px;
		font-family: 'OpenSansBold';
	}
	.app-c-form-group {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
	}
	.app-c-select-dropdown {
		position: relative;
		svg {
			position: absolute;
			top: 20px;
			right: 14px;
		}
	}
`;

export default function NewParkedOrderModal(props): ReactElement {
	const [newParkedOrder, setNewParkedOrder] = useState('');
	const key = "parkedOrderCreate";
	const [mutatePost] = hooks.useMutation(key);

	const handleOnChange = (e: any) => {
		setNewParkedOrder(e.target.value);
	};

	const closePopup = () => {
        props.togglePopup();
    }

	const onMarkAsParked = () => {
		let cartId = localStorage.getItem('cartId');
		var venueUserId = localStorage.getItem('userId');
		var venueId = localStorage.getItem('selectedVenue');
		let payload = {
			"cartId": cartId,
			"venueUserId": venueUserId,
			"parkOrderName": newParkedOrder,
			"venueId": venueId
		  };

		const obj1: Partial<Ipost> = {
			data: payload,
			params: {},
			url: '/experion-parkorder/create',
			message:'Order has been saved as Parked Order'
		};
		try {
			mutatePost(obj1).then((data) => {
				if (data !== undefined) {
					closePopup();
				}
			});
		} catch (e) { }
	}

	return (
		<NewParkedOrder>
			<Text as="h1" color="text.textHeading" fontSize={18} fontFamily="OpenSansSemiBold">
				New Parked Order
			</Text>
			<Box mb={45}>
				<Stack categorySelect gap={0} mt={0}>
					<FormInput label="Enter Title for Parked Order" onChange={handleOnChange} />
				</Stack>
			</Box>

			<Stack direction="x" gap={3} justifyContent="center">
				<Button flex={[1, 1, 'initial']} variant="secondary" onClick={closePopup}>
					Cancel
				</Button>
				<Button flex={[1, 1, 'initial']} onClick={onMarkAsParked}>Create</Button>
			</Stack>
		</NewParkedOrder>
	);
}
