import { UserBody } from 'admin/dashboardVenue/styles';
import { userTypes } from 'models/enum-constants/common.constants';
import Iget from 'models/Iget';
import React, { ReactElement, ReactNode, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Box, Link, Text } from 'rebass/styled-components';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import styled from 'styled-components';
import * as Icon from 'ui/icons';
import { NotificationsFullView } from 'ui/patterns';
import useOnClickOutside from 'use-onclickoutside';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';

const StyledWatchListNotification = styled.div`
	display: flex;
	align-items: center;
	svg {
		width: 25px;
		height: 30px;
	}
	button {
		padding: 0;
		outline: none !important;
		box-shadow: none !important;
	}
	.badge-count {
		width: 10px;
		height: 10px !important;
		top: 4px;
		left: auto;
		border-width: 1px;
		right: 1px;
	}
	.simplebar-wrapper {
		max-height: calc(100vh - 250px);
	}
	.simplebar-track.simplebar-vertical {
		width: 8px !important;
	}
`;
const ViewAllHeadingBlock = styled.div`
	display: flex !important;
	justify-content: space-between;
	color: ${({ theme }) => theme.colors.text.black04};
	padding: 0 10px 10px;
	align-items: center;
	min-height: 35px;
	h1,
	h2 {
		font-family: 'OpenSansBold';
		text-transform: uppercase;
		display: flex !important;
		align-items: center;
		position: relative;
		span {
			margin-right: 6px;
			svg {
				width: 25px;
				height: 22px;
			}
		}
	}
	h1,
	h2 a {
		font-weight: normal;
	}
	a {
		color: ${({ theme }) => theme.baseColors.baseYellow};
		text-decoration: none;
		font-family: 'OpenSansMedium';
		transition: ease-in all 200ms;

		&:hover {
			background: ${({ theme }) => theme.baseColors.baseYellow};
			padding: 3px 10px;
			color: ${({ theme }) => theme.baseColors.white};
			border-radius: 5px;
		}
	}
	h2 {
		width: 100%;
		margin-top: 20px;
		&:after,
		&:before {
			content: '';
			position: absolute;
			z-index: -1;
		}
		&:after {
			width: 125px;
			height: 10px;
			background: ${({ theme }) => theme.baseColors.white};
		}
		&:before {
			width: 100%;
			height: 1px;
			background: rgba(0, 0, 0, 0.15);
		}
	}
`;
export type WatchListNotificationProps = {
	trigger?: string | ReactNode;
	menuList?: Array<item>;
	menuContent?: ReactNode;
	autoClose?: boolean;
	position?: 'right-top' | 'right' | 'left-top' | 'left';
	ref?: React.RefObject<HTMLDivElement>;
	onClick?: (e: any) => void;
	updateUnReadCount: any;
	notificationList?: any;
	updateNotificationList?: any;
};
type item = {
	id?: number;
	value: string;
	action?: () => void;
	icon?: ReactNode;
	groupEnd?: boolean;
};

function WatchListNotification({
	autoClose = true,
	position,
	updateUnReadCount,
	notificationList,
	updateNotificationList
}: WatchListNotificationProps): ReactElement {
	const notificationCount = 25;
	const [lenghtUpdater, setLengthUpdater] = useState(0);
	const [scrollEvent, setScrollEvent] = useState(Math.random());
	let user_Type = localStorage.getItem('userType');
	let venue = localStorage.getItem('selectedVenue');
	let location = localStorage.getItem('selectedLocation');
	let userId = localStorage.getItem('userId');
	let loc =
		user_Type == userTypes.venueAdmin || user_Type == userTypes.venueUser ? venue : location;
	const [notificationsFullViewList, setNotificationsFullViewList] = useState([] as any);
	const url =
		loc &&
			typeof userId != 'undefined' &&
			userId != '' &&
			typeof user_Type != 'undefined' &&
			user_Type != ''
			? `experion/notifications/${loc}/${getUserType()}/${userId}`
			: '';
	const key = 'WatchListNotification';
	const obj: Partial<Iget> = {
		params: {},
		url: url,
		enabled: false,
		key: key,
		resultFilteringRequired: false,
	};
	var WatchListNotificationQry = hooks.useQuery(obj);
	const handleScroll = useCallback(() => {
		const element = document.getElementsByClassName("simplebar-content-wrapper")[0] as any;
		if (Math.ceil(element?.scrollHeight - element?.scrollTop) !== element?.clientHeight)
			return;
		setScrollEvent(Math.random());
	}, [setScrollEvent]);
	useEffect(() => {
		const oldData = JSON.parse(JSON.stringify(notificationsFullViewList));
		if (oldData && oldData.length > 0) {
			let data = WatchListNotificationQry.data ? WatchListNotificationQry.data?.sort((a, b) => b.id - a.id) : notificationList?.sort((a, b) => b.id - a.id);
			if (data && (data.length > (notificationCount * lenghtUpdater))) {
				data = data.slice(notificationCount * lenghtUpdater, notificationCount * (lenghtUpdater + 1));
				setLengthUpdater(JSON.parse(JSON.stringify(lenghtUpdater)) + 1);
				setNotificationsFullViewList([...oldData, ...data]);
			}
		}
	}, [scrollEvent]);
	useEffect(() => {
		let data = WatchListNotificationQry.data ? WatchListNotificationQry.data?.sort((a, b) => b.id - a.id) : WatchListNotificationQry.data;
		if(data)updateNotificationList(data);
		if (data && data.length > 0) {
			data = data.slice(0, notificationCount);
			setLengthUpdater(1);
			setNotificationsFullViewList(data);
		} else {
			setLengthUpdater(1);
			setNotificationsFullViewList([]);
		}
	}, [WatchListNotificationQry.data]);
	useEffect(() => {
		const element = document.getElementsByClassName("simplebar-content-wrapper")[0] as any;
		if (element) element?.addEventListener('scroll', handleScroll);
		let data = notificationList ? notificationList?.sort((a, b) => b.id - a.id) : notificationList;
		if (data && data.length > 0) {
			data = data.slice(0, notificationCount);
			setLengthUpdater(1);
			setNotificationsFullViewList(data);
		}
		else {
			setLengthUpdater(1);
			setNotificationsFullViewList([]);
		}
	}, [notificationList]);
	const [open, setOpen] = useState(false);
	const handleOnClickOutside = () => {
		setOpen(false);
	};

	const wrapperRef = useRef<HTMLDivElement>(null);
	useOnClickOutside(wrapperRef, handleOnClickOutside);
	const handleToggle = (e: SyntheticEvent<HTMLDivElement>) => {
		e.stopPropagation();
		setOpen(autoClose === true ? !open : true);
	};

	function getUserType() {
		let userType = '';
		switch (user_Type) {
			case userTypes.venueAdmin:
				userType = 'hg_admin';
				break;
			case userTypes.venueUser:
				userType = 'venue_user';
				break;
			case userTypes.supplierAdmin:
				userType = 'supply_admin';
				break;
			case userTypes.supplierUser:
				userType = 'dc_user';
				break;
			default:
				break;
		}
		return userType;
	}

	function markAllAsRead(e) {
		e.preventDefault();
		let userType = getUserType();
		api
			.put({
				url: `experion/notifications/markAsReadAll/${loc}/${userType}/${localStorage.getItem(
					'userId'
				)!}`,
				message: 'All notifications marked as read',
			})
			.then(function (data) {
				if (data) {
					notificationsFullViewList?.map((x) => {
						x.mark_read = '1';
					});
					setNotificationsFullViewList(JSON.parse(JSON.stringify(notificationsFullViewList)));
					notificationList?.map((x) => {
						x.mark_read = '1';
					});
					updateNotificationList(notificationList);
				}
			});
	}

	function markAsRead(id) {
		api
			.put({
				url: `experion/notifications/markAsRead/${id}`,
				message: 'Notification marked as read',
				data: constructData(),
			})
			.then(function (data) {
				if (data) {
					let item = notificationsFullViewList.find((x) => x.id == id);
					if (item) {
						item!.mark_read = '1';
						setNotificationsFullViewList(JSON.parse(JSON.stringify(notificationsFullViewList)));
						let mainItem = notificationList.find((x) => x.id == id);
						mainItem!.mark_read = '1';
						updateNotificationList(notificationList);
					}
				}
			});
	}

	function constructData() {
		return {
			participants: {
				mark_read: '1',
			},
		};
	}

	function deleteNotification(id) {
		api
			.delete({
				url: `experion/notifications/delete/${id}`,
				message: 'Notification deleted successfully',
			})
			.then(function (data) {
				if (data) WatchListNotificationQry.refetch();
			});
	}

	useEffect(() => {
		let count = 0;
		if (notificationsFullViewList) {
			const data = WatchListNotificationQry.data ? WatchListNotificationQry.data : notificationList;
			count = data?.filter((x) => x.mark_read == '0')?.length
		}
		updateUnReadCount(count);
	}, [notificationsFullViewList]);

	return (
		<StyledWatchListNotification ref={wrapperRef} onClick={handleToggle}>
			<Box style={{ width: '100%' }}>
				{notificationsFullViewList &&
					notificationsFullViewList?.filter((x) => x.mark_read == '0')?.length > 0 && (
						<ViewAllHeadingBlock>
							<Text as="h1" fontSize="14px"></Text>
							<Link
								variant="nav"
								href=""
								onClick={(e) => {
									markAllAsRead(e);
								}}
								fontSize="12px"
							>
								Mark all as read
							</Link>
						</ViewAllHeadingBlock>
					)}
				<SimpleBar>
					{(!WatchListNotificationQry.isFetching && notificationsFullViewList?.length > 0 && (
						<NotificationsFullView
							notificationsFullView={notificationsFullViewList}
							markAsRead={markAsRead}
							deleteNotification={deleteNotification}
						/>
					)) ||
						(!WatchListNotificationQry.isFetching && notificationsFullViewList?.length == 0 ? (
							<UserBody>
								<Icon.searchIcon title="No Data Found" />
								<Text fontSize={18} fontWeight="bold" color="#5C5E57" textAlign="center">
									No notifications found
								</Text>
							</UserBody>
						) : (
							new Array(12).fill(null).map((_, i) => {
								return <Skeleton key={i} width="1200px" height="50px" />;
							})
						))}
				</SimpleBar>
			</Box>
		</StyledWatchListNotification>
	);
}

export { WatchListNotification };
