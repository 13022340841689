import { Select } from '@rebass/forms';
import Iget from 'models/Iget';
import Ipost from 'models/Ipost';
import VenueRole from 'models/pageObjects/venue.roles';
import queryString from 'query-string';
import React, { ReactElement, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Text } from 'rebass/styled-components';
import { BreadcrumbNav, Button, Footer, Grid, Header, Loader, Stack } from 'ui/components';
import * as Icon from 'ui/icons';
import { AssignVenueList, FormInput, Sidebar } from 'ui/patterns';
import urls from 'utils/create.url';
import helperFunctions from 'utils/helper.functions';
import http from 'utils/http.service';
import pageConstants from 'utils/pageConstants';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import notify from 'utils/toast.service';
import {
	AddUserHolder,

	AssignVenueHolder, FooterBtnBlock, MainContent,


	ProfileDetails
} from './styles';
function AddUsers(): ReactElement {
	const pageSize = 500;
	const location = useLocation() as any;
	const history = useHistory();
	const parsed = queryString.parse(location.search);
	const [showPopup, setShowModal] = useState(false);
	const [action, setAction] = useState('');
	const [modalText, setmodalText] = useState('');
	const venueUrl = urls.GetSearchUrl({
		url: 'experion/venue/search?',
		pageSize: pageSize,
		fields: [{
			field_name: 'hosp_id',
			field_value: localStorage.getItem("hospitalityId")!
		}],
		filterDeleted: true,
		filterInactive: true
	});
	const [venueRoleChange, setVenueRoleChange] = useState(false);
	const [userPhn, checkUserPhn] = useState<any>();
	const userPhnValidateUrl = `experion/venueuser/phonenumberexists/get/${userPhn}`;
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const roleUrl = urls.GetSearchUrl({
		url: 'experion/venueuserrole/search?',
		pageSize: pageSize, filterDeleted: true, filterInactive: true,
		fields: [{
			field_name: 'hg_admin_Id',
			field_value: localStorage.getItem("hospitalityId")!
		}]
	});
	const venueObj: Partial<Iget> = {
		params: {},
		url: venueUrl,
		enabled: true,
		key: 'venue',
		resultFilteringRequired: true,
	};

	const venueQry = hooks.useQuery(venueObj);

	const roleObj: Partial<Iget> = {
		params: {},
		url: roleUrl,
		enabled: true,
		key: 'role',
		resultFilteringRequired: true,
	};

	const roleQry = hooks.useQuery(roleObj);
	const [assignedUser, setAssignedUser] = useState<VenueRole>({
		venue: { key: 0, value: '' },
		role: { key: 0, value: '' },
	});
	const [venueRoles, setVenueRoles] = useState<Array<VenueRole>>([]) as any;
	const breadcrumbNav = [
		{ id: '01', label: 'Home', route: '/home/dashboard', active: false },
		{ id: '02', label: parsed.id ? 'Edit User' : 'Add User', active: true },
	];
	const [isLoading, setIsLoading] = useState(false);
	const [venueList, setVenueList] = useState([] as any);
	const [roleList, setRoleList] = useState([] as any);
	const [userSaveText, setUserSaveText] = useState('Add');
	const [error, setError] = useState({
		userFirstNameError: '',
		userLastNameError: '',
		userPhnError: '',
		userEmailError: '',
		venueNameError: '',
		userRoleError: '',
	});
	const [userDetails, setUserDetails] = useState({
		first_name: '',
		last_name: '',
		phone: '',
		venue_user_email: '',
		id: 0,
		venue_user_role_mapping: [],
		is_active: true,
		hg_id: localStorage.getItem("hospitalityId")
	});
	useEffect(() => {
		if (typeof location.search != 'undefined' && parsed.id) {
			setUserSaveText('Save');
			http.GET({ url: `experion/venueuser/get/${parsed.id}` }).then(function (d) {
				let data = d.data;
				setUserDetails({
					first_name: data.first_name,
					id: data.id,
					is_active: data.is_active,
					last_name: data.last_name,
					phone: helperFunctions.formatPhone(data.phone),
					venue_user_email: data.venue_user_email,
					venue_user_role_mapping: data.venue_user_role_mapping,
					hg_id: localStorage.getItem("hospitalityId")
				});
				let list = [] as Array<VenueRole>;
				data.venue_user_role_mapping.map((v, i) => {
					if (v.is_delete != true && v.is_active == true) {
						list.push({
							venue: {
								key: v.venue_id,
								value: v.venue_name,
							},
							role: {
								key: v.role_id,
								value: v.role_name,
							},
						});
					}
				});
				setVenueRoles(list);
				setVenueRoleChange(true);
			});
		}
		window.scroll({ top: 0, left: 0, behavior: 'smooth' });
	}, [location]);
	// const validationObj: Partial<Iget> = {
	// 	params: {},
	// 	url: userPhnValidateUrl,
	// 	enabled: false,
	// 	key: 'venuePhnValidation',
	// };
	// const qryValidatePhn = hooks.useQuery(validationObj);
	const validateUniquePhnNum = () => {
		if (error.userPhnError === '') {
			http.GET({ url: userPhnValidateUrl }).then(function (data) {
				if (data.data) {
					setError({ ...error, userPhnError: 'Phone already exists' });
				} else {
					//({ ...error, userPhnError: '' });
					handleChange(userPhn, 'phone');
				}
			});
		}
	}
	// useEffect(()=>{
	// 	if(venueRoleChange == true && venueRoles.length > 0 &&
	// 		venueQry.data && roleQry.data){
	// 	let venues = venueQry.data;
	// 	let roles = roleQry.data;
	// 	venueRoles.map((v:VenueRole,i)=>{
	// 		venues.splice(venues.findIndex(x=>x.name == v.venue.value),1);
	// 		roles.splice(roles.findIndex(x=>x.name == v.role.value),1);
	// 	})
	// 	setVenueList(venues);
	// 	setRoleList(roles);
	// 	}
	// },[venueRoleChange])

	useEffect(() => {
		const venues = [] as any;
		venueQry.data &&
			venueQry.data.map((item, i) => {
				if (
					(venueRoles && venueRoles.findIndex((x) => x.venue.key == item.id) == -1) ||
					!venueRoles ||
					venueRoles.length == 0
				) {
					venues.push({ key: item.id, value: item.name });
				}
			});
		setVenueList(JSON.parse(JSON.stringify(venues)));
	}, [venueQry.data]);

	useEffect(() => {
		const roles = [] as any;
		roleQry.data &&
			roleQry.data.map((item) => {
				// let data = venueQry.data;
				if (
					(venueRoles && venueRoles.findIndex((x) => x.role.key == item.key) == -1) ||
					!venueRoles ||
					venueRoles.length == 0
				) {
					roles.push({ key: item.id, value: item.role_name });
				}
			});
		setRoleList(JSON.parse(JSON.stringify(roles)));
	}, [roleQry.data]);

	const onDeleteVenueRole = (venue, role) => {
		venueRoles.splice(
			venueRoles.findIndex((x) => x.role.key == role.key && x.venue.key === venue.key),
			1
		);
		assignedUser.role = assignedUser.venue = { key: 0, value: '' };
		if (venueList.findIndex((x) => x.key == venue.key) == -1) {
			venueList.push(venue);
			setVenueList(venueList);
		}

		// if (roleList.findIndex((x) => x.value == role.value) == -1) {
		// 	roleList.push(role);
		// 	setRoleList(roleList);
		// }

		setVenueRoles(JSON.parse(JSON.stringify(venueRoles)));
	};

	const cancel = () => {
		history.push('user-dashboard');
	};
	const clear = () => {
		setUserDetails({
			first_name: '',
			last_name: '',
			phone: '',
			venue_user_email: '',
			id: 0,
			venue_user_role_mapping: [],
			is_active: true,
			hg_id: localStorage.getItem("hospitalityId")
		});
		setError({
			userEmailError: '',
			userFirstNameError: '',
			userLastNameError: "",
			userPhnError: '',
			userRoleError: '',
			venueNameError: ''
		});
		setVenueRoles([]);
		const venues = [] as any;
		venueQry.data &&
			venueQry.data.map((item) => {
				venues.push({ key: item.id, value: item.name });
			});
		setVenueList(JSON.parse(JSON.stringify(venues)));
		roleQry.data &&
			roleQry.data.map((item) => {
				roleList.push({ key: item.id, value: item.role_name });
			});
		setRoleList(JSON.parse(JSON.stringify(roleList)));
		window.scroll({ top: 0, left: 0, behavior: 'smooth' });
	};

	const onSelectDropdwn = (e, name) => {
		const data: VenueRole = JSON.parse(JSON.stringify(assignedUser));
		switch (name) {
			case 'venueName': {
				if (!e) {
					setError({ ...error, venueNameError: 'Venue name cannot be blank' });
				} else {
					setError({ ...error, venueNameError: '' });
				}
				if (data) {
					data.venue.value = e.target.value;
					if (e.target.value != '')
						data.venue.key = venueList[venueList.findIndex((x) => x.value.trim() == e.target.value.trim())].key;
				}
				break;
			}
			case 'userRole': {
				if (!e) {
					setError({ ...error, userRoleError: 'User role cannot be blank' });
				} else {
					setError({ ...error, userRoleError: '' });
				}
				if (data) {
					data.role.value = e.target.value;
					if (e.target.value != '')
						data.role.key = roleList[roleList.findIndex((x) => x.value.trim() == e.target.value.trim())].key;
				}
				break;
			}
		}
		setAssignedUser(JSON.parse(JSON.stringify(data)));
	};
	const handleChange = (val, name) => {
		const item = userDetails;
		//const data = assignedUser;
		switch (name) {
			case 'firstName': {
				if (!val) {
					setError({ ...error, userFirstNameError: 'First name cannot be blank' });
				} else {
					setError({ ...error, userFirstNameError: '' });
				}
				item.first_name = val;
				break;
			}
			case 'lastName': {
				if (!val) {
					setError({ ...error, userLastNameError: 'Last name cannot be blank' });
				} else {
					setError({ ...error, userLastNameError: '' });
				}
				item.last_name = val;
				break;
			}
			case 'phone': {
				if (!val) {
					setError({ ...error, userPhnError: 'Phone cannot be blank' });
				} else {
					const regex = pageConstants.phoneRegex;
					if (!regex.test(val)) {
						setError({ ...error, userPhnError: 'Invalid Phone' });
					} else {
						checkUserPhn(val)
						setError({ ...error, userPhnError: '' });
					}
				}
				item.phone = val;
				break;
			}
			case 'userEmail': {
				if (!val) {
					setError({ ...error, userEmailError: 'Email cannot be blank' });
				} else {
					const validEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(val);
					if (validEmail) {
						setError({ ...error, userEmailError: '' });
					} else {
						setError({ ...error, userEmailError: 'Please enter Valid Email' });
					}
				}
				item.venue_user_email = val;
				break;
			}
		}
		setUserDetails({
			...item,
		});
	};

	const assignUserRole = () => {
		if (
			venueRoles.findIndex(
				(x) => x.venue.key == assignedUser.venue.key && x.role.key == assignedUser.role.key
			) != 0
		) {
			const itemList = JSON.parse(JSON.stringify(venueRoles));
			const user = JSON.parse(JSON.stringify(assignedUser));
			if (user && itemList && itemList.map((x) => x.venue.key).indexOf(user.venue.key) == -1) {
				venueList.splice(
					venueList.findIndex((x) => x.key == user.venue.key),
					1
				);
				setVenueList(venueList);
				// roleList.splice(
				// 	roleList.findIndex((x) => x.value == user.role.value),
				// 	1
				// );
				// setRoleList(roleList);
				setVenueRoles((arr) => [...arr, user]);
				assignedUser.role = assignedUser.venue = { key: 0, value: '' };
			}
			else {
				notify('venue already in the list').info();
			}
		}
		else {
			notify('venue already in the list').info();
		}
	};


	const saveUser = async () => {
		setIsLoading(true);
		const list = [] as any;

		venueRoles.map((v) => {
			list.push({ venue_id: v.venue.key as never, role_id: v.role.key as never, is_active: true });
		});
		userDetails.venue_user_role_mapping = list;
		userDetails.phone = userDetails.phone.replace(/\+/g, '').replace(/ /g, '');
		userDetails.phone = helperFunctions.formatPhone(userDetails.phone);
		let user = {};
		const isSave = userSaveText == 'Add' ? true : false;
		if (isSave) {
			user = Object.keys(userDetails).reduce((object, key) => {
				if (key !== 'id') {
					object[key] = userDetails[key];
				}
				return object;
			}, {});
		} else user = userDetails;

		const obj: Partial<Ipost> = {
			data: { venueUser: user },
			params: {},
			url: isSave ? 'experion/venueuser/create' : `experion/venueuser/update/${userDetails.id}`,
			message: `User ${isSave ? 'added' : 'updated'} successfully`,
		};
		try {
			if (isSave) {
				await api.post(obj).then(function (data) {
					if (typeof data != 'undefined') history.goBack();
				});
			} else {
				await api.put(obj).then(function (data) {
					if (typeof data != 'undefined') history.goBack();
				});
			}
			setIsLoading(false);
		} catch (e) {
			setIsLoading(false);
		}
	};

	//popup 

	function confirmPopup(action) {
		switch (action) {
			case 'cancel': showConfirmationModal(action, 'Are you sure you want to cancel ?');
				break;
			case 'clear': showConfirmationModal(action, 'Are you sure you want to clear all data ?');
				break;
			case 'Save': showConfirmationModal(action, 'Are you sure you want to update the venue user details');
				break;
			case 'Add': saveUser();
				break;
			default: break;
		}
	}
	async function closePopup() {
		await setShowModal(!showPopup);
	}
	function showConfirmationModal(action, text) {
		setAction(action);
		setmodalText(text);
		setShowModal(!showPopup);
	}
	async function onSubmit() {
		if (action === 'clear') {
			clear();

		} else if (action === 'cancel') {
			cancel();
		}
		else if (action == 'Save') {
			saveUser();
		}
		setShowModal(!showPopup);

	}
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	}
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={() => onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={() => onclickHambrgBtn()}></Sidebar>
			{!(venueRoleChange) && parsed?.id && <Loader></Loader>}{' '}
			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbNav Heading={parsed.id ? 'Edit User' : 'Add User'} breadcrumbList={breadcrumbNav} />
				{/* Add User Details */}
				<AddUserHolder>
					<ProfileDetails>
						<Text as="h1" fontSize={18} fontFamily="OpenSansBold" color="text.SubHeading">
							basic details
						</Text>
						<Box as="form">
							<Grid gap={0}>
								<FormInput
									label="First Name"
									autoComplete="off"
									validationMessage={error.userFirstNameError}
									validation={error.userFirstNameError ? 'error' : undefined}
									required={true}
									value={userDetails.first_name}
									name="firstName"
									onTextChange={(e) => handleChange(e, 'firstName')}
									maxLength={50}
									type="text"
								/>
								<FormInput
									label="Last Name"
									autoComplete="off"
									validationMessage={error.userLastNameError}
									validation={error.userLastNameError ? 'error' : undefined}
									required={true}
									value={userDetails.last_name}
									name="lastName"
									onTextChange={(e) => handleChange(e, 'lastName')}
									maxLength={50}
									type="text"
								/>
								<FormInput
									label="Phone"
									disabled={parsed.id}
									autoComplete="off"
									validationMessage={error.userPhnError}
									validation={error.userPhnError ? 'error' : undefined}
									required={true}
									value={userDetails.phone}
									name="phone"
									onTextChange={(e) => handleChange(e, 'phone')}
									maxLength={12}
									type="tel"
									onBlur={validateUniquePhnNum}
								/>
								<FormInput
									label="Email Address"
									autoComplete="off"
									validationMessage={error.userEmailError}
									validation={error.userEmailError ? 'error' : undefined}
									required={true}
									value={userDetails.venue_user_email}
									name="userEmail"
									onTextChange={(e) => handleChange(e, 'userEmail')}
									maxLength={50}
									type="text"
								/>
							</Grid>
						</Box>
					</ProfileDetails>
					<AssignVenueHolder>
						<Text as="h1" fontSize={18} fontFamily="OpenSansBold" color="text.SubHeading">
							Assign Store & role{' '}
						</Text>
						<Box as="form">
							<Grid gap={4}>
								<Stack categorySelect gap={0}>
									<div>
										<Select
											value={assignedUser.venue.value}
											onChange={(e) => onSelectDropdwn(e, 'venueName')}
										>
											<option value="">-Select Store-</option>
											{venueList &&
												venueList.map((option, index) => (
													<option key={index}>{option.value}</option>
												))}
										</Select>
										<Icon.downArrow />
									</div>
									<span style={{ fontSize: 12, color: 'red' }}>{error.venueNameError}</span>
								</Stack>
								<Stack categorySelect gap={0}>
									<div>
										<Select
											value={assignedUser.role.value}
											onChange={(e) => onSelectDropdwn(e, 'userRole')}
										>
											<option value="">-Select Role-</option>
											{roleList &&
												roleList.map((option, index) => (
													<option key={index}>{option.value}</option>
												))}
										</Select>
										<Icon.downArrow />
									</div>
									<span style={{ fontSize: 12, color: 'red' }}>{error.userRoleError}</span>
								</Stack>
							</Grid>
							<Button
								flex={[1, 1, 'initial']}
								onClick={assignUserRole}
								disabled={!assignedUser.venue.value || !assignedUser.role.value}
							>
								Assign
							</Button>
						</Box>

						{/* Assign Value List */}
						<AssignVenueList
							deleteVenueRole={onDeleteVenueRole}
							venueRoles={venueRoles}
						></AssignVenueList>
					</AssignVenueHolder>

					{/* Footer Btn Block */}
					<FooterBtnBlock>
						<Stack direction="x" gap={3} justifyContent={['center', 'flex-end']}>
							{userSaveText == 'Add' && (
								<Button flex={[1, 1, 'initial']} variant="secondary" onClick={() => confirmPopup('clear')}>
									Clear
								</Button>
							)}
							<Button flex={[1, 1, 'initial']} variant="secondary" onClick={() => confirmPopup('cancel')}>
								Cancel
							</Button>
							<Button
								flex={[1, 1, 'initial']}
								onClick={() => confirmPopup(userSaveText)}
								disabled={
									userDetails.first_name == '' ||
									userDetails.last_name == '' ||
									userDetails.venue_user_email == '' ||
									userDetails.phone == '' ||
									error.userFirstNameError != '' ||
									error.userLastNameError != '' ||
									error.userPhnError != '' ||
									error.userEmailError != '' ||
									error.userRoleError != '' ||
									error.venueNameError != '' ||
									(venueRoles && venueRoles.length == 0) ||
									isLoading == true
								}
							>
								{userSaveText}
							</Button>
						</Stack>
					</FooterBtnBlock>
				</AddUserHolder>
				<SweetAlert
					show={showPopup}
					confirmBtnText="Confirm"
					onConfirm={onSubmit}
					showCancel={true}
					onCancel={closePopup}
					title={'Confirmation Box'}
				>
					{() => <form>{modalText}</form>}
				</SweetAlert>
			</MainContent>
			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default AddUsers;
