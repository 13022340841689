import styled from 'styled-components';
const StyledTags = styled.div`
	border: 1px solid ${({ theme }) => theme.colors.border.grayMedium04};
	border-radius: 6px;
	display: flex;
	margin-right: 14px;
	margin-bottom: 6px;
	@media (max-width: 960px) {
		margin-right: 10px;
	}
	button {
		background: transparent !important;
		border: none;
		padding: 0 6px;
		margin: 2px;
		border-radius: 4px;
		svg {
			path {
				fill: ${({ theme }) => theme.colors.text.black};
			}
		}
		&:hover,
		&:focus {
			background: ${({ theme }) => theme.colors.bg.error}!important;
			svg {
				path {
					fill: ${({ theme }) => theme.baseColors.white};
				}
			}
		}
	}
	h2 {
		text-transform: uppercase;
		font-size: 14px;
		line-height: 18px;
		font-family: 'OpenSansSemiBold';
		padding: 6px 11px 6px 10px;
		margin: 0;
		color: ${({ theme }) => theme.colors.border.grayMedium04};
	}
`;
export { StyledTags };
