import styled, { css } from 'styled-components';

type SidebarProps = {
	hide?: boolean;
	show?: boolean;
};

const StyledSidebar = styled.nav<SidebarProps>`
	background: ${({ theme }) => theme.colors.bg.white};
	margin: 0 0 0 0;
	height: 100%;
	float: left;
	width: 295px;
	flex: 0 0 295px;
	border-radius: 0;
	padding: 24px;
	transition: margin 0.4s ease 0s;
	position: fixed;
	z-index: 9999;
	overflow: hidden;
	display: none;
	@media (max-width: 960px) {
		display: block;
	}
	button,
	div {
		z-index: 1;
	}

	div {
		max-height: max-content !important;
		border: none !important;
		overflow: visible !important;
	}
	.simplebar-placeholder,
	.simplebar-track {
		display: none;
	}
	button {
		top: 24px;
		right: 24px;
		svg {
			height: 20px !important;
			width: 20px !important;
			float: right;
			path {
				fill: ${({ theme }) => theme.baseColors.baseYellow};
			}
		}
	}
	ul {
		li {
			margin-bottom: 16px !important;
			a {
				border: 1px solid ${({ theme }) => theme.colors.border.yellowDark};
			}
			&:last-child {
				margin-bottom: 0px !important;
			}
			span {
				margin-right: 15px;
				margin-top: -2px;
				float: left;
				svg {
					float: left;
				}
			}
		}
	}
	${({ hide }) =>
		hide &&
		css`
			margin-left: -295px;
		`}
	${({ show }) =>
		show &&
		css`
			margin-left: 0;
			&:before {
				content: '';
				background: rgba(0, 0, 0, 0.18);
				width: calc(100% - 295px);
				height: 100vh;
				position: fixed;
				top: 0;
				right: 0;
				z-index: 0;
			}
		`}
	.SupplierMenu {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding-left: 15px !important;
	}
	.rc-menu-button {
		top: 0 !important;
		right: auto !important;
		left: 0 !important;
		width: 100%;
		margin: 0 0 10px 0 !important;
		background: ${({ theme }) => theme.baseColors.baseYellow};
		justify-content: space-between;
	}
	.rc-menu-container {
		width: 100%;
		position: relative;
		z-index: 999;
		.rc-menu {
			width: 100%;
		}
		li {
			margin-bottom: 0 !important;
			ul {
				left: auto !important;
				right: 0 !important;
				width: auto !important;
			}
		}
	}
`;
const HeaderMobileView = styled.div`
	display: flex;
	nav {
		display: block !important;
		margin: 30px 0 0 0 !important;
		width: 100%;
		a {
			padding: 0 !important;
			font-size: 14px !important;
			align-items: flex-start;
			background: ${({ theme }) => theme.baseColors.baseYellow};

			border-bottom: 1px solid ${({ theme }) => theme.colors.transparent.white70};
			margin: 0 0 10px 0;
			border-radius: 6px;
			label {
				position: absolute;
				top: 16px;
				left: 15px;
			}
			.pr-icon {
				margin-right: 15px;
			}
			.dropdownMenu {
				padding: 0 !important;
				position: relative;
				Button {
					margin: 0 !important;
					padding: 0 !important;
				}
				button {
					position: relative;
					padding: 10px 10px;
					margin: 0;
					top: 0;
					right: 0;
					&::before {
						left: 0;
						right: 0;
					}
					svg {
						height: 10px !important;
						width: 10px !important;
						float: right;
						path {
							fill: ${({ theme }) => theme.colors.bg.lightYellow03};
						}
					}
				}
				div {
					position: relative;
					border-radius: 0;
					margin-bottom: -3px;
					margin-top: 0 !important;
					padding: 0;
					border: 1px solid ${({ theme }) => theme.baseColors.baseYellow};

					button {
						padding: 10px 15px !important;
					}
				}
			}
			.dropdownMenu {
				div {
					margin-top: 52px;
					border-radius: 0;
					left: 0;
					right: 0;
					min-width: 150px;
					z-index: 1;
				}
			}
		}
	}
`;
const AccordionPanel = styled.div`
	display: flex;
	flex-direction: column;
	padding: 25px;
	margin-bottom: 12px;
	border: 1px solid #bdbcb9;
	border-radius: 12px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

	.Collapsible__trigger {
		width: 100%;
		display: flex;
		font-weight: 600;
		&.is-closed,
		&.is-open {
			position: relative;
			&:after {
				content: '';
				box-sizing: border-box;
				height: 10px;
				width: 10px;
				border-style: solid;
				border-color: #000;
				right: 0;
				position: absolute;
				transition: all 0.5s ease;
				cursor: pointer;
			}
		}
		&.is-closed {
			&:after {
				border-width: 0px 2px 2px 0px;
				-webkit-transform: rotate(45deg);
				-ms-transform: rotate(45deg);
				transform: rotate(45deg);
				-webkit-transition: border-width 150ms ease-in-out;
				transition: border-width 150ms ease-in-out;
			}
		}
		&.is-open {
			&:after {
				border-width: 0px 2px 2px 0px;
				-webkit-transform: rotate(225deg);
				-ms-transform: rotate(225deg);
				transform: rotate(225deg);
				-webkit-transition: border-width 150ms ease-in-out;
				transition: border-width 150ms ease-in-out;
			}
		}
	}
`;
export { StyledSidebar, HeaderMobileView, AccordionPanel };
