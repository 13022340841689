import { Input } from '@rebass/forms';
import FilterGroupItem from 'models/FilterModel';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Box, Flex, Link, Text } from 'rebass/styled-components';
import { Button, Checkbox } from 'ui/components';
import * as Icon from 'ui/icons';
import { FormGroup } from 'ui/patterns';
import AppContext from 'utils/appContext';
import pageConstants from 'utils/pageConstants';
import {
	ButtonHolderMobileView,
	FilterCategory,
	FilterCategoryHeading,
	FilterCategoryHolder,
	FilterHeading,
	Price,
	PriceBtnHolder,
	PriceHolder,
	PriceWrapper,
	StyledFilter,
} from './styles';

function Filter(prop): ReactElement {
	const ctx = useContext(AppContext).productSearchObj;
	const [searchSupplier, setSearchSupplier] = useState('');
	const [searchCategory, setSearchCategory] = useState('');
	const [searchAllergens, setSearchAllergens] = useState('');
	const [visibleSupplyItem, setVisibleSupplyItem] = useState(4);
	const [visibleCategoryItem, setVisibleCategoryItem] = useState(4);
	const [visibleAllergenItem, setVisibleAllergenItem] = useState(4);
	const [showSupplyItem, setShowSupplyItem] = useState(true);
	const [showCategoryItem, setShowCategoryItem] = useState(true);
	const [showAllergenItem, setShowAllergenItem] = useState(true);
	const [isPriceFilterEnabled, setPriceApplyBtn] = useState(false);
	const [fromPrice, setfromPrice] = useState(ctx.PriceFrom);
	const [toPrice, setToPrice] = useState(ctx.PriceTo);
	const [priceReset, setPriceReset] = useState(false);
	let categoryList: FilterGroupItem[] = [];
	let supplierList: FilterGroupItem[] = [];
	let allergensList: FilterGroupItem[] = [];
	if (prop.layeredFilters?.data?.products?.aggregations?.length > 0) {
		let aggregations = prop.layeredFilters.data.products.aggregations;
		let list = [] as any;
		let categories = aggregations.find((x) => x.attribute_code == 'category_id');
		if (categories && categories.options) {
			categories.options.map((x) => {
				list.push({ id: x.value, name: x.label });
			});
		}
		categoryList = list;
		list = [];
		let suppliers = aggregations.find((x) => x.attribute_code == 'supply_company_name');
		if (suppliers && suppliers.options) {
			suppliers.options.map((x) => {
				if (x.value != 0) list.push({ id: x.value, name: x.label });
			});
		}
		supplierList = list;
		list = [];
		let allergens = aggregations.find((x) => x.attribute_code == 'allergens');
		if (allergens && allergens.options) {
			allergens.options.map((x) => {
				if (x.label != "0")
					list.push({ id: x.value, name: x.label });
			});
		}
		allergensList = list;
	}

	if (prop.selectedCategoryId && categoryList) {
		let count = 0;
		if (
			!prop.selectedCategoryGroup.includes(
				categoryList.filter((x) => x.id == prop.selectedCategoryId)
			)
		) {
			categoryList.map((item) => {
				count++;
				if (item.id == prop.selectedCategoryId) {
					if (count > 4 && showCategoryItem == true) {
						setShowCategoryItem(false);
						setVisibleCategoryItem(categoryList ? categoryList.length : 4);
					}
					prop.isCatSelected(item);
				}
			});
		}
	}

	const getFilterGroup = (group): Array<FilterGroupItem> => {
		let category: Array<FilterGroupItem> = [];
		let selectedGroup: Array<FilterGroupItem> = [];
		if (group == pageConstants.supplyGroup) {
			category = prop.selectedSupplyGroup ? prop.selectedSupplyGroup : [];
			selectedGroup = supplierList ? supplierList : [];
		} else if (group == pageConstants.categoryGroup) {
			category = prop.selectedCategoryGroup ? prop.selectedCategoryGroup : [];
			selectedGroup = categoryList ? categoryList : [];
		} else if (group == pageConstants.allergensGroup) {
			category = prop.selectedAllergensGroup ? prop.selectedAllergensGroup : [];
			selectedGroup = allergensList ? allergensList : [];
		}
		let newArray: Array<FilterGroupItem> = selectedGroup;

		if (category) {
			const namesOfSelectedItem: Array<string> = [];
			if (category.length > 0) {
				newArray.map((innerData) => {
					category.map((data) => {
						if (data.id == innerData.id) {
							innerData.tempDeleted = data.tempDeleted;
							namesOfSelectedItem.push(data.name);
						}
					});
				});
			}
			newArray &&
				newArray.length > 0 &&
				newArray.map((data) => {
					data.key = group;
					if (category.length == 0) {
						data.isChecked = false;
					} else {
						if (namesOfSelectedItem?.includes(data.name) && data.tempDeleted != true) {
							data.isChecked = true;
						} else {
							data.isChecked = false;
						}
					}
				});
		}
		return newArray;
	};
	const handleClearAll = () => {
		prop.clearAll && prop.clearAll();
		clearSearch();
	};
	const clearSearch = () => {
		setSearchSupplier('');
		setSearchCategory('');
		setSearchAllergens('');
		setfromPrice(0);
		setToPrice(0);
	};
	const showMoreItems = (group, data) => {
		if (group == pageConstants.supplyGroup && data.length > 0) {
			setShowSupplyItem(false);
			setVisibleSupplyItem(data.length);
		} else if (group == pageConstants.categoryGroup && data.length > 0) {
			setShowCategoryItem(false);
			setVisibleCategoryItem(data.length);
		} else if (group == pageConstants.allergensGroup && data.length > 0) {
			setShowAllergenItem(false);
			setVisibleAllergenItem(data.length);
		}
	};

	useEffect(()=>{
		if(priceReset && fromPrice === 0 && toPrice === 0) {
			setPriceReset(false);
			prop.onPriceChange && prop.onPriceChange();
		}
	},[fromPrice,toPrice]);

	return (
		<StyledFilter>
			{typeof prop.layeredFilters?.data?.products?.aggregations == 'undefined' ? (
				<Skeleton width="300px" height="30px">
					{' '}
				</Skeleton>
			) : (
				<FilterHeading>
					<Text as="h1">FIlters</Text>
					<Link as="a" onClick={handleClearAll} className="filter-clear">
						Clear All
					</Link>
				</FilterHeading>
			)}
			<FilterCategoryHolder>
				{supplierList && supplierList.length > 0 ? (
					<FilterCategory>
						{supplierList && supplierList.length > 0 ? (
							<FilterCategoryHeading>
								<Text as="h2">Sellers</Text>
								<Flex className="search-form">
									<Input
										className="search-field "
										id="email"
										name="email"
										autoComplete="off"
										type="text"
										placeholder="Search"
										value={searchSupplier}
										onChange={(event) => {
											setSearchSupplier(event.target.value);
										}}
									/>
									<Button className="search-submit">
										<Icon.searchIcon />
									</Button>
								</Flex>
							</FilterCategoryHeading>
						) : typeof prop.layeredFilters?.data?.products?.aggregations == 'undefined' ? (
							new Array(6).fill(null).map((_, i) => {
								return <Skeleton key={i} width="250px" height="25px" />;
							})
						) : null}
						<FormGroup display="flex" flexDirection="column" alignItems="center">
							{getFilterGroup(pageConstants.supplyGroup) &&
								getFilterGroup(pageConstants.supplyGroup)
									.filter((val) => {
										if (searchSupplier == '') {
											return val;
										} else if (val.name.toLowerCase().includes(searchSupplier.toLowerCase())) {
											return val;
										}
									})
									.slice(0, visibleSupplyItem)
									.map((item) => (
										<Checkbox
											label={item.name}
											key={item.id}
											checked={item.isChecked}
											handleChange={(e) => {
												prop.onChange && prop.onChange(item, e.currentTarget.checked);
											}}
										>
											<Text
												as="h3"
												fontSize="14px"
												color="text.black04"
												fontFamily="OpenSansSemiBold"
												sx={{ width: '100%', top: '1px', position: 'relative' }}
											>
												{item.name}
											</Text>
										</Checkbox>
									))}

							{showSupplyItem && !searchSupplier && supplierList && supplierList.length > 4 ? (
								<Link
									as="a"
									onClick={() =>
										showMoreItems(
											pageConstants.supplyGroup,
											supplierList && supplierList ? supplierList : []
										)
									}
								>
									{`+ ${supplierList.length - 4} more`}
								</Link>
							) : null}
						</FormGroup>
					</FilterCategory>
				) : null}
				{/* <FilterCategory>
				{ categoryList && categoryList.length > 0 ? (<FilterCategoryHeading>
						<Text as="h2">Category</Text>
						<Flex className="search-form">
							<Input
								className="search-field"
								id="email"
								name="email"
								autoComplete="off"
								type="text"
								placeholder="Search"
								value={searchCategory}
								onChange={(event) => {
									setSearchCategory(event.target.value);
								}}
							/>
							<Button className="search-submit">
								<Icon.searchIcon />
							</Button>
						</Flex>
					</FilterCategoryHeading>): 
				(typeof prop.layeredFilters?.data?.products?.aggregations == 'undefined'? (new Array(6).fill(null).map((_, i) => {
					return <Skeleton key={i} width="250px" height="25px" />;
				})):null)}
					<FormGroup display="flex" flexDirection="column" alignItems="center">
						{getFilterGroup(pageConstants.categoryGroup)
							?.filter((val) => {
								if (searchCategory == '') {
									return val;
								} else if (val.name.toLowerCase().includes(searchCategory.toLowerCase())) {
									return val;
								}
							})
							.slice(0, visibleCategoryItem)
							.map((item) => (
								<Checkbox
									label={item.name}
									key={item.id}
									checked={item.isChecked}
									handleChange={(e) => {
										prop.onChange && prop.onChange(item, e.currentTarget.checked);
									}}
								>
									<Text
										as="h3"
										fontSize="14px"
										color="text.black04"
										fontFamily="OpenSansSemiBold"
										sx={{ width: '100%', top: '1px', position: 'relative' }}
									>
										{item}
									</Text>
								</Checkbox>
							))}
						
						{showCategoryItem && !searchCategory && categoryList && categoryList.length > 4 ? (
							
							<Link
								as="a"
								onClick={() =>
									showMoreItems(pageConstants.categoryGroup, categoryList ? categoryList : [])
								}
							>
								{`+ ${categoryList.length - 4} more`}
							</Link>
						) : null}
					</FormGroup>
				</FilterCategory> */}
				{allergensList && allergensList.length > 0 ? (
					<FilterCategory>
						{allergensList && allergensList.length > 0 ? (
							<FilterCategoryHeading>
								<Text as="h2">Allergens</Text>
								<Flex className="search-form">
									<Input
										className="search-field"
										id="email"
										name="email"
										autoComplete="off"
										type="text"
										placeholder="Search"
										value={searchAllergens}
										onChange={(event) => {
											setSearchAllergens(event.target.value);
										}}
									/>
									<Button className="search-submit">
										<Icon.searchIcon />
									</Button>
								</Flex>
							</FilterCategoryHeading>
						) : typeof prop.layeredFilters?.data?.products?.aggregations == 'undefined' ? (
							new Array(6).fill(null).map((_, i) => {
								return <Skeleton key={i} width="250px" height="25px" />;
							})
						) : null}

						<FormGroup display="flex" flexDirection="column" alignItems="center">
							{getFilterGroup(pageConstants.allergensGroup)
								?.filter((val) => {
									if (searchAllergens == '') {
										return val;
									} else if (val.name.toLowerCase().includes(searchAllergens.toLowerCase())) {
										return val;
									}
								})
								.slice(0, visibleAllergenItem)
								.map((item) => (
									<Checkbox
										label={item.name}
										key={item.id}
										checked={item.isChecked}
										handleChange={(e) => {
											prop.onChange && prop.onChange(item, e.currentTarget.checked);
										}}
									>
										<Text
											as="h3"
											fontSize="14px"
											color="text.black04"
											fontFamily="OpenSansSemiBold"
											sx={{ width: '100%', top: '1px', position: 'relative' }}
										>
											{item}
										</Text>
									</Checkbox>
								))}

							{showAllergenItem && !searchAllergens && allergensList && allergensList.length > 4 ? (
								<Link
									as="a"
									onClick={() =>
										showMoreItems(pageConstants.allergensGroup, allergensList ? allergensList : [])
									}
								>
									{`+ ${allergensList.length - 4} more`}
								</Link>
							) : null}
						</FormGroup>
					</FilterCategory>
				) : (
					''
				)}
				<Price>
					<FilterCategoryHeading>
						<Text as="h2">Price</Text>
					</FilterCategoryHeading>
					<PriceWrapper>
						<PriceHolder>
							<Box>
								<Text
									as="small"
									fontSize={16}
									fontFamily="OpenSansSemiBold"
									color="bg.lightYellow04"
								>
									$
								</Text>
								<Input
									value={fromPrice > 0 ? fromPrice : ''}
									onChange={(e) => {
										ctx.PriceFrom = e.target.value ? parseFloat(e.target.value) : 0;
										setfromPrice(ctx.PriceFrom);
										if (ctx.PriceFrom > 0) setPriceApplyBtn(true);
										if (ctx.PriceTo <= 0) setPriceApplyBtn(false);
										if (ctx.PriceTo < ctx.PriceFrom) setPriceApplyBtn(false);
									}}
									autoComplete="off"
									type="text"
								/>
							</Box>
							<Text as="p" fontSize={14} fontFamily="OpenSansMedium" color="text.black04">
								To
							</Text>

							<Box>
								<Text
									as="small"
									fontSize={16}
									fontFamily="OpenSansSemiBold"
									color="bg.lightYellow04"
								>
									$
								</Text>
								<Input
									value={toPrice > 0 ? toPrice : ''}
									onChange={(e) => {
										ctx.PriceTo = e.target.value ? parseFloat(e.target.value) : 0;
										setToPrice(ctx.PriceTo);
										if (ctx.PriceTo > 0) setPriceApplyBtn(true);
										if (ctx.PriceFrom <= 0) setPriceApplyBtn(false);
										if (ctx.PriceTo < ctx.PriceFrom) setPriceApplyBtn(false);
									}}
									type="text"
									autoComplete="off"
								/>
							</Box>
						</PriceHolder>
						<PriceBtnHolder>
							<Button
								variant="primary"
								disabled={!isPriceFilterEnabled}
								onClick={() => {
									prop.onPriceChange && prop.onPriceChange();
									setPriceReset(true);
								}}
							>
								Apply
							</Button>
						</PriceBtnHolder>
					</PriceWrapper>
				</Price>
			</FilterCategoryHolder>
			<ButtonHolderMobileView>
				<Button
					variant="secondary"
					onClick={() => {
						prop.setShowModal();
						prop.cancelFilter && prop.cancelFilter();
					}}
				>
					Cancel
				</Button>
				<Button
					variant="primary"
					onClick={() => {
						prop.setShowModal();
						prop.applyFilter && prop.applyFilter();
					}}
				>
					Apply
				</Button>
			</ButtonHolderMobileView>
		</StyledFilter>
	);
}

export { Filter };
