import React, { CSSProperties, InputHTMLAttributes, ReactElement } from 'react';
import styled from 'styled-components';

const StyledFloatLabelInput = styled.div`
	--color__accent: var(--primary-color);
	--input__border: ${({ theme }) => theme.colors.border.yellowDark};
	--input__border--hover: ${({ theme }) => theme.colors.border.yellowDark};
	--label__color: ${({ theme }) => theme.colors.text.body};
	--input__label__focus: ${({ theme }) => theme.colors.bg.lightYellow};
	--input__border__focus: ${({ theme }) => theme.colors.bg.darkYellow};
	--input__background: ${({ theme }) => theme.colors.bg.white};

	.floating-input {
		padding: 10px 22px 10px;
		font-size: inherit;
		border: 0;
		border: 1px solid var(--input__border);
		transition: border-color 0.2s ease;
		caret-color: var(--color__accent);
		display: block;
		width: 100%;
		background: var(--input__background);
		color: var(--label__color);
		height: 73px;
		border-radius: 9px;
		padding-right: 60px;
		font-family: 'OpenSansSemiBold';
		font-size: 1rem;
		&:focus {
			outline: none;
			border-color: var(--input__border__focus);
		}
	}

	.floating:hover .floating-input {
		border-color: var(--input__border--hover);
	}

	.floating-input::placeholder {
		color: rgba(0, 0, 0, 0);
	}

	.floating-label {
		display: block;
		position: relative;
		max-height: 0;
		font-weight: 500;
		pointer-events: none;
		font-size: 14px;
		color: var(--label__color);
	}

	.floating-label::before {
		color: var(--label__color);
		content: attr(data-content);
		display: inline-block;
		/* filter: blur(0); */
		backface-visibility: hidden;
		transform-origin: left top;
		transition: transform 0.2s ease;
		left: 0;
		position: relative;
	}

	.floating-label::after {
		bottom: 1em;
		content: '';
		height: 0.1em;
		position: absolute;
		transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1),
			opacity 200ms cubic-bezier(0.4, 0, 0.2, 1), background-color 0.3s ease;
		opacity: 0;
		left: 0;
		top: 100%;
		margin-top: -0.1em;
		transform: scale3d(0, 1, 1);
		width: 100%;
		background-color: var(--color__accent);
	}

	.floating-input:focus + .floating-label::after {
		transform: scale3d(1, 1, 1);
		opacity: 1;
	}

	.floating-input:placeholder-shown + .floating-label::before {
		transform: translate3d(0, -2.2em, 0) scale3d(1, 1, 1);
		padding-left: 22px;
		bottom: 12px;
		margin-left: 0;
		background: transparent;
		font-weight: normal;
		font-size: 16.44px;
		color: var(--label__color);
	}

	.floating-label::before,
	.floating-input:focus + .floating-label::before {
		transform: translate3d(0, -3.12em, 0) scale3d(0.82, 0.82, 1);
		margin-left: 18px;
		padding-left: 0;
		padding: 0 5px;
		bottom: 25px;
		font-weight: 600;
		font-size: 14px;
	}

	.floating-input:focus + .floating-label::before {
		color: var(--color__accent);
		margin-left: 18px;
		padding-left: 0;
		padding: 0 5px;
		bottom: 25px;
		font-weight: 600;
		color: var(--input__border__focus);
		font-size: 14px;
	}
`;

interface FloatLabelInputProps extends InputHTMLAttributes<HTMLInputElement> {
	label: string;
	style?: CSSProperties;
}

function FloatLabelInput({ label, style, ...props }: FloatLabelInputProps): ReactElement {
	const fieldID = label.replace(/\s/g, '').toLowerCase();

	return (
		<StyledFloatLabelInput style={style}>
			<input id={fieldID} className="floating-input" name={label} placeholder={label} {...props} />
			<label htmlFor={fieldID} className="floating-label" data-content={label}>
				<span className="sr-only">{label}</span>
			</label>
		</StyledFloatLabelInput>
	);
}

export { FloatLabelInput };
