import styled, { css } from 'styled-components';
import { SpaceProps, space, LayoutProps, layout, PositionProps, position } from 'styled-system';

type DropdownMenuProps = SpaceProps & LayoutProps & PositionProps;

const DropdownMenu = styled.div<DropdownMenuProps>`
	${space}
	${layout}
	${position}
	position: relative;
`;

type DropdownItemGroupProps = {
	place?:
		| 'right-top'
		| 'right'
		| 'right-bottom'
		| 'left-top'
		| 'left'
		| 'left-bottom'
		| 'covered-left'
		| 'covered-right';
};

const DropdownItemGroup = styled.div<DropdownItemGroupProps>`
	position: absolute;
	background: rgb(255, 255, 255);
	border-radius: 12px;
	box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.39);
	box-sizing: border-box;
	overflow: auto;
	padding: 4px 0px;
	max-height: 317.5px;

	${({ place }) =>
		place === 'left' &&
		css`
			top: 100%;
			right: 100%;
		`}

	${({ place }) =>
		place === 'left-bottom' &&
		css`
			top: 100%;
			right: 0;
		`}

	${({ place }) =>
		place === 'left-top' &&
		css`
			top: 0;
			right: 100%;
			left: auto;
			margin-top: 0;
			margin-right: ${({ theme }) => theme.space[1]};
		`}

  ${({ place }) =>
		place === 'right' &&
		css`
			top: 100%;
			left: 0;
			right: auto;
		`}

  ${({ place }) =>
		place === 'right-top' &&
		css`
			top: 0;
			left: 100%;
			right: auto;
			margin-top: 0;
			margin-left: ${({ theme }) => theme.space[1]};
		`}

	${({ place }) =>
		place === 'covered-left' &&
		css`
			top: -2px;
			right: 0;
			left: auto;
			margin-top: 0;
		`}

	${({ place }) =>
		place === 'covered-right' &&
		css`
			top: -2px;
			left: 0;
			right: auto;
			margin-top: 0;
		`}

	& > * + *:before {
		content: '';
		position: absolute;
		top: 0;
		left: 10px;
		display: block;
		right: 10px;
		height: 1px;
		/* width: 100%; */
		background-color: #f6f4ee;
	}
`;

const DropdownItem = styled.button`
	position: relative;
	appearance: none;
	border: none;
	align-items: center;
	border-radius: 0px;
	box-sizing: border-box;
	cursor: pointer;
	display: flex;
	flex: 0 0 auto;
	background-color: ${({ theme }) => theme.colors.bg.white};
	color: ${({ theme }) => theme.colors.text.black01};

	text-decoration: none;
	padding: 12px 12px 11px;
	width: 100%;
	font-size: 14px;
	font-family: 'OpenSansMedium';
	transition: all 200ms ease-out;
	&:hover {
		background: ${({ theme }) => theme.baseColors.primary};
		color: ${({ theme }) => theme.colors.text.white};
		text-decoration: none;
	}

	&:focus,
	&:active {
		box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
		background: ${({ theme }) => theme.baseColors.primary};
		color: ${({ theme }) => theme.colors.text.white};
		outline: none;
	}

	& > .pr-icon {
		margin-right: ${({ theme }) => theme.space[2]};
	}
`;

const ItemLabel = styled.span`
	display: block;
	flex: 1 1 auto;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	line-height: 1.14286;
	padding-bottom: 1px;
	text-align: left;
`;

export { DropdownMenu, DropdownItemGroup, DropdownItem, ItemLabel };
