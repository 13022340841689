import React, { ReactElement, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { StyledCheckboxWrapper } from '../checkbox';

type RadioProps = {
	hasActiveBg?: boolean;
	hasActiveText?: boolean;
	hasActiveBackground?: boolean;
};

export const StyledRadioWrapper = styled(StyledCheckboxWrapper)<RadioProps>`
	--radio-marker-size: 12px;
	--checkbox-marker-color: #fff;
	--checkbox-radio-size: 22px;
	--checkbox-radio-gap: 5px;

	input[type='radio'] {
		/* &:checked + label::before {
			background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cg class='nc-pr-iconper' fill='%23ecb136'%3E%3Ccircle cx='8' cy='8' r='8' fill='%23ecb136'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
			background-size: var(--radio-marker-size);
			border-color: #ecb136;
		} */

		& + label::before {
			border-radius: 50%;
		}

		${({ hasActiveBg }) =>
			hasActiveBg &&
			css`
				&:checked + label {
					background-color: red;
					box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
					border: 1px solid #bdbcb9;
				}

				& + label {
					border: 1px solid transparent;
					padding: 1em 0.8em;
					border-radius: 6px;
					transition: background 0.2s;
					width: 100%;

					@media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
						padding: 1em 1.125em;
					}
				}

				& + label:hover {
					border: 1px solid #bdbcb9;
				}

				&:active + label,
				&:focus + label {
					background-color: red;
					box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
					border: 1px solid #bdbcb9;
				}
			`}
		${({ hasActiveText }) =>
			hasActiveText &&
			css`
				&:checked + label {
					h1 {
						font-family: 'OpenSansBold';
						color: ${({ theme }) => theme.baseColors.baseYellow};
					}
					svg {
						path {
							fill: ${({ theme }) => theme.baseColors.baseYellow};
						}
					}
				}
			`}
			${({ hasActiveBackground }) =>
			hasActiveBackground &&
			css`
				&:checked + label {
					background: ${({ theme }) => theme.colors.bg.menu};
				}
			`}
	}
`;

type RefReturn =
	| string
	| ((instance: HTMLInputElement | null) => void)
	| React.RefObject<HTMLInputElement>
	| null
	| undefined;

export type RadioButtonProps = {
	label?: string | any;
	value?: string;
	checked?: boolean;
	disabled?: boolean;
	name?: string;
	inline?: boolean;
	onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
	register?: () => RefReturn;
	children?: ReactNode;
	hasActiveBg?: boolean;
	hasActiveText?: boolean;
	hasActiveBackground?: boolean;
	id?: string;
	className ?: string
};
// { required }: { required?: boolean }

function RadioButton({
	label,
	value,
	checked,
	disabled,
	name,
	inline,
	onChange,
	register,
	children,
	id,
	hasActiveBg,
	hasActiveText,
	hasActiveBackground,
	className
}: RadioButtonProps): ReactElement {
	const randomid = `radio-${label ? label.replace(/ /g, '').toLowerCase() : null}`;
	return (
		<StyledRadioWrapper
			inline={inline}
			hasActiveText={hasActiveText}
			hasActiveBackground={hasActiveBackground}
		>
			<input
				className = {className}
				type="radio"
				id={id || randomid}
				// value={value}
				defaultChecked={checked}
				disabled={disabled}
				name={name}
				onClick={onChange}
				// onChange={onChange}
				// ref={register}
			/>
			<label htmlFor={id}>{children || label}</label>
		</StyledRadioWrapper>
	);
}

export { RadioButton };
