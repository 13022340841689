import Iget from 'models/Iget';
import React, { ReactElement, useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import constants from 'utils/constants';
import 'react-tippy/dist/tippy.css';
import icons from 'ui/icons';
import { useHistory } from 'react-router-dom';
import { Loader } from 'ui/components/Loader';
import httpService from 'utils/http.service';
import { Label, Radio } from '@rebass/forms';

const StyledCheckoutShippingOptions = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	column-gap: 20px;
	row-gap: 20px;
	margin-top: 15px;
	.order-details__wrap {
		flex-direction: column;
		background: ${({ theme }) => theme.colors.bg.white};
		border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
		box-sizing: border-box;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 16px;
		overflow: hidden;
		transition: box-shadow 100ms ease-in, transform 100ms ease-in;
		align-items: flex-start;
		height: auto;
		section {
			display: flex;
			flex-direction: column;
			padding: 25px;
			flex-wrap: nowrap;

			h1 {
				display: flex;
				margin-bottom: 18px;
				@media (max-width: 1224px) {
					flex-direction: column;
				}
				@media (max-width: 960px) {
					flex-direction: row;
				}
				@media (max-width: 480px) {
					flex-direction: column;
				}
				div {
					max-width: 100px;
					width: 100px;
					@media (max-width: 768px) {
						max-width: 120px;
						width: 120px;
					}
				}
				strong {
					font-family: 'OpenSansBold';
					font-size: 18px;
				}
			}
			h2 {
				float: left;
				width: 100%;
				br {
					display: none;
					+ strong {
						font-family: 'OpenSansBold';
						color: ${({ theme }) => theme.colors.text.black};
					}
				}
				strong {
					font-family: 'OpenSansBold';
					color: ${({ theme }) => theme.colors.text.error01};
				}
			}
			article {
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				white-space: nowrap;
				@media (max-width: 1152px) {
					flex-wrap: wrap;
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-start;
				}
				div {
					max-width: fit-content;
					width: auto;
					padding-right: 10px;
					@media (max-width: 768px) {
						max-width: 120px;
						width: 120px;
					}
				}
				h5 {
					width: calc(100% - 57%);
					word-break: break-all;
					white-space: normal;
					@media (max-width: 1152px) {
						width: 100%;
					}
				}
			}
		}
	}
`;
const DeliveryCostBlock = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media (max-width: 1152px) {
		flex-wrap: wrap;
		h3 {
			flex: 1;
		}
	}
	h3 {
		white-space: nowrap;
		display: flex;
		width: 100;
		justify-content: flex-end;
		height: 34px;
		align-items: center;
		flex: initial;
		svg {
			height: 18px;
			width: 28px;
			margin-left: 5px;
		}
	}
`;
function CheckoutShippingOptions(props): ReactElement {
	const history = useHistory();
	const cartId = localStorage.getItem('cartId');
	const [deliveryOptions, setDeliveryOptions] = useState<any>([]);
	const [selectedOption, setSelectedOption] = useState<any>();
	
	useEffect(() => {
		if(cartId == undefined) 
			history.push('/home/dashboard');

		//API endpoint to fetch shipping method for a particular cart
		const url = `${constants.publicURL()}rest/V1/carts/${cartId}/shipping-methods`; 

		httpService.GET({ url }).then(function (response) {
			if(response?.data) {
				setDeliveryOptions(response.data);
			}
			else {
				setDeliveryOptions([]);
			}
		}); 
	}, [])
	
	const changeShippingMethod = (event) => {
		props.setShippingMethod(deliveryOptions.filter(x => x.method_code == event.target.value));
		setSelectedOption(event.target.value);
	}

	return (
		<StyledCheckoutShippingOptions>
			<Flex
				className="order-details__wrap selected"
				bg="white"
				sx={{ alignItems: 'center' }}
				p={4}
			>
				<Text as="h5" fontSize={17} color="text.black" m={3}>
					<strong>Shipping Methods</strong>
				</Text>
				{
					deliveryOptions?.length > 0 ? 
						deliveryOptions.map((item) => {
							return (
								<Box 
									as="article"
									key={`${item.method_code}${Math.floor(Math.random() * 1000)}`}
									p={3}
								>
									<Label>
										<Radio
											name='shipping-method'
											id={item.method_code}
											value={item.method_code}
											onChange={changeShippingMethod}
											checked={selectedOption == item.method_code}
										/>
										{/* <input
											type='radio'
											name='shipping-method'
											id={item.method_code}
											value={item.method_code}
											onChange={changeShippingMethod}
											checked={selectedOption == item.method_code}
										/> */}
										{item.carrier_title} ( {item.price_incl_tax == 0 ? "FREE" : `+$${item.price_incl_tax}` } ) 
									</Label>
								</Box>
							)
						})
					: 
						<Box as="article">
							<Text as="h1" fontSize={17} color="text.black">
								<div>No options available </div>
							</Text>
						</Box>
				}
			</Flex>
		</StyledCheckoutShippingOptions>
	);
}

export { CheckoutShippingOptions };
