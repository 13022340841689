import React, { HTMLAttributes, ReactElement } from 'react';
import { FlexboxProps, LayoutProps, SpaceProps } from 'styled-system';
import { FormLabel, StyledFormInput } from '../../components';
import { FormGroup } from '../index';

type ssProps = SpaceProps & LayoutProps & FlexboxProps;

type RefReturn =
	| string
	| ((instance: HTMLInputElement | null) => void)
	| React.RefObject<HTMLInputElement>
	| null
	| undefined;
export type InputProps = ssProps &
	HTMLAttributes<HTMLInputElement> & {
		autoFocus?: boolean;
		name?: string;
		label?: string;
		value?: string;
		onTextChange?: (text: string) => void;
		type?: 'email' | 'number' | 'password' | 'search' | 'text' | 'tel' | 'url' | 'date';
		placeholder?: string;
		disabled?: boolean;
		readonly?: boolean;
		autoComplete?: string;
		validation?: 'error' | 'success' | 'warning';
		feedback?: string;
		required?: boolean;
		maxLength?: number;
		validationMessage?: string;
		autofocus?: boolean;
		max?: number;
		// validate?: { pattern?: { value?: string; message?: string } };
		style?: React.CSSProperties;
	};

const FormInput = ({
	name,
	label,
	value,
	onTextChange,
	type,
	placeholder,
	disabled,
	readonly,
	validation,
	feedback,
	autoComplete,
	required,
	maxLength,
	style,
	validationMessage,
	autofocus,
	max,
	autoFocus,
	...ssProps
}: InputProps): ReactElement => {
	const id = `toggle-${label ? label.replace(/ /g, '').toLowerCase() : null}`;
	return (
		<FormGroup style={style} {...ssProps}>
			{label ? (
				<FormLabel htmlFor={id} required={required} disabled={disabled}>
					{label}
				</FormLabel>
			) : null}
			<StyledFormInput
				maxLength={maxLength}
				name={name || label}
				type={type}
				placeholder={placeholder}
				value={value}
				id={id}
				disabled={disabled}
				onChange={(e: any) => onTextChange && onTextChange(e.currentTarget.value)}
				readonly={readonly}
				validation={validation}
				autoFocus={autofocus}
				max={max}
				autoComplete={autoComplete}
			/>
			<span style={{ fontSize: 12, color: 'red' }}>{validationMessage}</span>
		</FormGroup>
	);
};

export { FormInput };
