import styled from 'styled-components';

const MainContent = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
	padding: 0 50px;
	@media (max-width: 960px) {
		padding: 0 15px;
	}
`;
const DCListHolder = styled.div`
	width: 100%;
	float: left;
	min-height: calc(100vh - 444px);
	h1 {
		margin-bottom: 20px;
		strong {
			font-family: 'OpenSansBold';
		}
	}
`;

export { MainContent, DCListHolder };
