/* eslint-disable react-hooks/rules-of-hooks */
import { dcUserPermissionList, OrderStatuses } from 'models/enum-constants/common.constants';
import Ipost from 'models/Ipost';
import queryString from 'query-string';
import React, { ReactElement, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Flex, Image, Text } from 'rebass/styled-components';
import { BreadcrumbNav, Button, Footer, Header, Loader } from 'ui/components';
import icons from 'ui/icons';
import { Modal, OrderRequestInfo, ProductDetails, Sidebar } from 'ui/patterns';
import urls from 'utils/create.url';
import http from 'utils/http.service';
import api from 'utils/requestCtx/api.service';
import utilityFunctions from 'utils/utility.functions';
import ReasonForRejectionModal from './reasonForRejection';
import no_image from 'ui/images/no_image.png';
import {
	BreadcrumbHolder,
	ButtonWrapper,
	ButtonWrapperR,
	MainContent,
	ProductOrderList,
} from './styles';

function orderRequest(): ReactElement {
	var history = useHistory();
	const location = useLocation();
	const parsed = queryString.parse(location.search);
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const [rejectionReason, setRejectionReason] = useState({
		reason: '',
	});
	const [orderedList, setOrderedList] = useState({} as any);
	const breadcrumbNav = [
		{ id: '01', label: 'Dashboard', route: '/admin/seller-dashboard', active: false },
		{ id: '02', label: 'Order Management', route: 'OrderList', active: false },
		{ id: '03', label: 'Orders', route: `orderRequest?id=${parsed.id}`, active: false },
		{
			id: '04',
			label: orderedList?.extension_attributes?.supplier_order_increment_id,
			active: true,
		},
	];

	const [showReasonForRejectionModal, setShowReasonForRejectionModal] = useState(false);
	const toggleReasonForRejectionModal = () => {
		setShowReasonForRejectionModal(!showReasonForRejectionModal);
	};
	const [action, setAction] = useState('');
	const [showPopup, setShowModal] = useState(false);
	const [disableReason, setDisableReason] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [modalText, setmodalText] = useState('');
	const [firstCheckBox, setFirstCheckBox] = useState(true);
	const [secondCheckBox, setSecondCheckBox] = useState(false);
	useEffect(() => {
		if (parsed.id) {
			setLoading(true);
			http
				.GET({
					url: urls.GetSearchUrl({
						url: 'orders?',
						fields: [{ field_name: 'entity_id', field_value: parsed.id }],
					}),
				})
				.then(function (d) {
					if (d.data?.items?.length > 0) {
						let datas = d.data?.items[0];
						setOrderedList(datas);
						setLoading(false);
						window.scroll({ top: 0, left: 0, behavior: 'smooth' });
					}
				});
		}
	}, [location]);
	const acceptOrder = (status) => {
		if (status === OrderStatuses.Confirmed) {
			setAction(OrderStatuses.Confirmed);
			showConfirmationModal('Are you sure you want to Accept this Order?');
		} else if (status === OrderStatuses.Shipped) {
			setAction(OrderStatuses.Shipped);
			showConfirmationModal('Are you sure wants to change the status of this order to Shipped?');
		} else if (status === OrderStatuses.Rejected) {
			setAction(OrderStatuses.Rejected);
			toggleReasonForRejectionModal();
		}
	};
	function showConfirmationModal(text) {
		setmodalText(text);
		setShowModal(!showPopup);
	}
	const onSubmitReason = (reasonForRejection, status) => {
		setLoading(true);
		let payload;
		if (action === OrderStatuses.Shipped) {
			let item = [] as any;

			if (orderedList) {
				orderedList.items &&
					orderedList.items.length > 0 &&
					orderedList.items.map((data) => {
						let itemDetails = {
							order_item_id: 0,
							qty: 0,
						};
						itemDetails.order_item_id = data.item_id;
						itemDetails.qty = data.qty_ordered;
						item.push(itemDetails);
					});
			}
			payload = {
				items: item,
				notify: true,
				tracks: [],
			};
			const obj: Partial<Ipost> = {
				data: payload,
				params: {},
				url: `order/${parsed.id}/ship`,
				message: `Order(s) shipped successfully`,
			};
			try {
				api.post(obj).then(function (data) {
					if (data !== undefined) {
						localStorage.setItem('refetchOnOrderReload', 'true');
						history.goBack();
						window.scroll({ top: 0, left: 0, behavior: 'smooth' });
						setLoading(false);
					}

				});
			} catch (e) {
				setLoading(false);
			}
		} else {
			if (action === OrderStatuses.Rejected) {
				payload = {
					entity_id: parsed.id,
					state: OrderStatuses.Cancelled,
					status: action,
					status_histories: [
						{
							comment: `${reasonForRejection != ''
								? (firstCheckBox ? 'Product unavailable:' : 'Delivery unavailable:') +
								reasonForRejection
								: ''
								}`,
							entity_name: 'order',
							is_customer_notified: 1,
							is_visible_on_front: 1,
							parent_id: parsed.id,
							status: action,
						},
					],
					extension_attributes: {
						receiver_email: orderedList.customer_email,
					},
				};
			} else if (action === OrderStatuses.Confirmed) {
				payload = {
					entity_id: parsed.id,
					state: OrderStatuses.Processing,
					status: action,
				};
			}
			const obj: Partial<Ipost> = {
				data: { entity: payload },
				params: {},
				url: `orders/create`,
				message: `Order(s) ${action == OrderStatuses.Rejected ? 'rejected' : 'accepted'
					} successfully`,
			};
			try {
				setLoading(true);
				api.put(obj).then((data) => {
					if (data !== undefined) {
						localStorage.setItem('refetchOnOrderReload', 'true');
						history.goBack();
						window.scroll({ top: 0, left: 0, behavior: 'smooth' });
						setOrderedList(orderedList);
						setLoading(false);
					}

				});
			} catch (e) { }
		}
		if (action == OrderStatuses.Rejected) {
			setShowReasonForRejectionModal(!showReasonForRejectionModal);
		} else {
			setShowModal(!showPopup);
		}
	};
	async function closePopup() {
		await setShowModal(!showPopup);
	}

	const cancel = () => {
		setShowReasonForRejectionModal(!showReasonForRejectionModal);
		setFirstCheckBox(true);
		setSecondCheckBox(false);
		rejectionReason.reason = '';
		setRejectionReason(rejectionReason);
	};
	const setReasonForRejection = (comment, event) => {
		if (comment === 'delivery') {
			if (event.target.checked) {
				setFirstCheckBox(false);
				setSecondCheckBox(true);
			} else {
				setFirstCheckBox(true);
				setSecondCheckBox(false);
			}
		} else if (comment === 'product') {
			if (event.target.checked) {
				setFirstCheckBox(true);
				setSecondCheckBox(false);
			} else {
				setFirstCheckBox(false);
				setSecondCheckBox(true);
			}
		}
	};
	const onClickRejectedBtn = (statusHistory) => {
		setDisableReason(false);
		if (statusHistory) {
			rejectionReason.reason = statusHistory.split(':')[1];
			let reason = statusHistory.split(':')[0];
			if (reason == 'Product unavailable') {
				setFirstCheckBox(true);
				setSecondCheckBox(false);
			} else {
				setFirstCheckBox(false);
				setSecondCheckBox(true);
			}
			setDisableReason(true);
			setRejectionReason(rejectionReason);
		}
		toggleReasonForRejectionModal();
	};
	const bindHeader = (status, status_histories) => {
		let comment = '';
		if (status == OrderStatuses.Pending) {
			return (
				<ButtonWrapper>
					{utilityFunctions.isGranted(dcUserPermissionList.OrderManagementUpdate) && (
						<Button variant="editIcon" onClick={() => acceptOrder(OrderStatuses.Confirmed)}>
							<icons.Tick />
							<div>Accept</div>
						</Button>
					)}
					{utilityFunctions.isGranted(dcUserPermissionList.OrderManagementUpdate) && (
						<Button variant="redOutline" onClick={() => acceptOrder(OrderStatuses.Rejected)}>
							<icons.CloseIcon />
							<div>Reject</div>
						</Button>
					)}
				</ButtonWrapper>
			);
		} else if (status == OrderStatuses.Rejected) {
			if (status_histories && status_histories[0].comment) {
				comment = status_histories[0].comment.split(':')[0];
			}
			return (
				<ButtonWrapperR>
					{utilityFunctions.isGranted(dcUserPermissionList.OrderManagementUpdate) && (
						<Button
							variant="redOutline"
							onClick={() => onClickRejectedBtn(status_histories[0].comment)}
						>
							<div>Rejected</div>
							<div>{comment}</div>
						</Button>
					)}
				</ButtonWrapperR>
			);
		} else if (status == OrderStatuses.Confirmed) {
			return (
				<ButtonWrapperR>
					<Button variant="secondary" disabled={true}>
						<div>Confirmed</div>
					</Button>
					<Button variant="primary" onClick={() => acceptOrder(OrderStatuses.Shipped)}>
						<div>Ship It</div>
					</Button>
				</ButtonWrapperR>
			);
		} else if (status == OrderStatuses.Shipped) {
			return (
				<ButtonWrapperR>
					<Button variant="secondary" disabled={true}>
						<div>Shipped</div>
					</Button>
				</ButtonWrapperR>
			);
		}
	};
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	};
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={() => onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={() => onclickHambrgBtn()}></Sidebar>
			{isLoading == true ? <Loader></Loader> : null}
			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbHolder>
					<BreadcrumbNav Heading="Order Details" breadcrumbList={breadcrumbNav} />
					{bindHeader(orderedList.status, orderedList.status_histories)}
				</BreadcrumbHolder>
				<OrderRequestInfo orderRequestInfo={orderedList} />
				<ProductDetails></ProductDetails>
				<ProductOrderList>
					{orderedList && orderedList.items && orderedList.items.length > 0 ? (
						orderedList.items.map(({ name, price, qty_ordered, extension_attributes }, index) => {
							return (
								<Flex as="article" bg="white" sx={{ alignItems: 'center' }} key={index}>
									<Flex
										alignItems="center"
										as="picture"
										maxWidth={['100%']}
										sx={{ overflow: 'hidden' }}
									>
										<Image src={extension_attributes && (extension_attributes.image_details !== ''
											? extension_attributes.image_details
											: no_image)} />
									</Flex>
									<Flex as="section">
										<Box as="slot">
											<Text as="h2" fontSize={16} fontWeight="normal" color="text.black">
												Product <strong>{name}</strong>
											</Text>
										</Box>
										<Box className="noOfItem">
											<Text as="h2" fontSize={16} fontWeight="normal" color="text.black">
												No. of items <strong>{qty_ordered}</strong>
											</Text>
										</Box>
										<Box>
											<Text as="h2" fontSize={16} fontWeight="normal" color="text.black">
												Price <strong>${parseFloat(price ? price : 0).toFixed(2)}</strong>
											</Text>
										</Box>
									</Flex>
								</Flex>
							);
						})
					) : (
						<div className="noProductFound">No Products Found</div>
					)}
				</ProductOrderList>
				<SweetAlert
					show={showPopup}
					confirmBtnText="Confirm"
					onConfirm={() => onSubmitReason('', 'confirmed')}
					showCancel={true}
					onCancel={closePopup}
					title={'Confirmation Box'}
				//  dependencies={[this.state.firstName, this.state.lastName]}
				>
					{() => (
						<form>
							<hr />
							{modalText}
							<hr />
						</form>
					)}
				</SweetAlert>
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
			<Modal
				width="500px"
				showModal={showReasonForRejectionModal}
				setShowModal={setShowReasonForRejectionModal}
			>
				<ReasonForRejectionModal
					disableReason={disableReason}
					value={rejectionReason}
					setReasonForRejection={setReasonForRejection}
					onSubmitReason={onSubmitReason}
					firstCheckBox={firstCheckBox}
					secondCheckBox={secondCheckBox}
					cancel={() => {
						cancel();
					}}
				/>
			</Modal>
		</>
	);
}

export default orderRequest;
