import moment from 'moment';
import React, { ReactElement } from 'react';
import 'react-tippy/dist/tippy.css';
import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import helper from 'utils/helper.functions';

const StyledCartListView = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	column-gap: 20px;
	row-gap: 20px;
	margin-bottom: 18px;
`;
const OrderRequestInfoHolder = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	background: ${({ theme }) => theme.colors.bg.white};
	border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
	box-sizing: border-box;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 16px;
	overflow: hidden;
	transition: box-shadow 100ms ease-in, transform 100ms ease-in;
	align-items: flex-start;
	@media (max-width: 768px) {
		flex-direction: column;
	}
	@media (max-width: 360px) {
		margin-top: 15px;
	}
	section {
		height: 100%;
		padding: 25px;
		position: relative;
		display: flex;
		flex-direction: row;
		@media (max-width: 768px) {
			padding: 20px;
		}
		@media (max-width: 480px) {
			padding: 15px;
		}
	}
	slot {
		grid-template-columns: repeat(6, 1fr);
		align-items: flex-start;
		flex-direction: column;
		justify-content: space-between;
		position: relative;
		display: grid;
		width: 100%;
		@media (max-width: 1300px) {
			grid-template-columns: repeat(3, 1fr);
			grid-row-gap: 15px;
		}
		@media (max-width: 760px) {
			grid-template-columns: repeat(2, 1fr);
			grid-row-gap: 15px;
		}
		@media (max-width: 640px) {
			grid-template-columns: repeat(1, 1fr);
			grid-row-gap: 15px;
		}

		h1,
		h2,
		h3 {
			margin-bottom: 0;
			font-weight: normal;
			color: ${({ theme }) => theme.colors.text.black};
			margin-right: 15px;
			strong {
				font-family: 'OpenSansBold';
				font-size: 18px;
				line-height: 26px;
				float: left;
				width: 100%;
				word-break: break-word;
			}
		}
		.totalProduct {
			max-width: 110px;
			text-align: right;
			white-space: nowrap;
		}
	}
`;

// type OrderRequestInfoProps = {
// 	orderRequestInfo: Array<list>;
// };
// type list = {
// 	id?: string | number;
// 	orderId?: string;
// 	date?: string;
// 	venue?: string;
// 	location?: string;
// 	phone?: string;
// };

function OrderRequestInfo(props): ReactElement {
	let deliverySlot = props.orderRequestInfo?.extension_attributes?.delivery_slot
		? props?.orderRequestInfo?.extension_attributes?.delivery_slot
		: '';
	deliverySlot = deliverySlot.split(' ');
	let deliverySlotDay = null as any;
	let deliverySlotDate = null as any;
	if (deliverySlot.length === 2) {
		deliverySlotDate = moment(deliverySlot[1], 'DD-MM-YYYY');
		deliverySlotDate = helper.formatDate(deliverySlotDate.toDate());
		deliverySlotDay = deliverySlot[0];
	}
	return (
		<StyledCartListView>
			{props.orderRequestInfo && props.orderRequestInfo ? (
				<OrderRequestInfoHolder key={props.orderRequestInfo.entity_id}>
					<Flex as="section" flexDirection="column" width="100%" maxWidth={['100%']}>
						<Box as="slot">
							<Text as="h1" fontSize={17} color="text.black">
								Order ID
								<strong>
									{' '}
									{props.orderRequestInfo.increment_id}
								</strong>
							</Text>
							<Text as="h2" fontSize={17} color="text.black">
								Date<strong> {helper.formatDate(props.orderRequestInfo.created_at)}</strong>
							</Text>
							<Text as="h3" fontSize={17} color="text.black">
								Venue<strong> {props.orderRequestInfo.extension_attributes?.venue_name}</strong>
							</Text>
							<Text className="totalProduct" as="h3" fontSize={17} color="text.black">
								Total Products<strong> {props.orderRequestInfo.total_item_count}</strong>
							</Text>
							<Text as="h3" fontSize={17} color="text.black">
								Order Total
								<strong>{`$${props.orderRequestInfo.subtotal
									? parseFloat(props.orderRequestInfo.subtotal).toFixed(2)
									: ''
									}`}</strong>
							</Text>
							<Text as="h2" fontSize={17} color="text.black">
								Delivery Date<strong> {deliverySlot && deliverySlot.length === 2
									? `${deliverySlotDay} ${deliverySlotDate}`
									: 'NIL'}</strong>
							</Text>
						</Box>
					</Flex>
				</OrderRequestInfoHolder>
			) : null}
		</StyledCartListView>
	);
}

export { OrderRequestInfo };
