import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Text, Box, Flex } from 'rebass/styled-components';
import 'react-tippy/dist/tippy.css';
import { Search, Button, SortBy, Loader } from 'ui/components';
import styled from 'styled-components';
import icons, * as Icon from 'ui/icons';
import { useHistory } from 'react-router-dom';
import AppContext from 'utils/appContext';
import urlObj from 'models/url.obj';
import urls from 'utils/create.url';
import Iget from 'models/Iget';
import hooks from 'utils/requestCtx/reactQueryHooks';
import { UserBody } from 'admin/dashboardVenue/styles';
import Skeleton from 'react-loading-skeleton';
import helperFunctions from 'utils/helper.functions';
import pageConstants from 'utils/pageConstants';
import utilityFunctions from 'utils/utility.functions';
import httpService from 'utils/http.service';
import { venueUserPermissionList } from 'models/enum-constants/common.constants';
import constants from 'utils/constants';
import api from 'utils/requestCtx/api.service';
import { UseGlobalState } from 'models/globalStateAccess';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import SweetAlert from 'react-bootstrap-sweetalert';

const StyledOrderListView = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	column-gap: 20px;
	row-gap: 20px;
	article {
		flex-direction: row;
		background: ${({ theme }) => theme.colors.bg.white};
		border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
		box-sizing: border-box;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 16px;
		overflow: hidden;
		transition: box-shadow 100ms ease-in, transform 100ms ease-in;
		align-items: flex-start;
		cursor: pointer;
		&:hover {
			transform: translateY(-5px);
			box-shadow: 0 20px 20px #eee;
			border: 1px solid ${({ theme }) => theme.baseColors.baseYellowLight};
		}
		span {
			max-width: 120px;
			flex: 0 0 120px;
			height: 100%;
			min-height: 130px;
			background-repeat: no-repeat;
			background-size: cover;
			@media (max-width: 768px) {
				max-width: 126px;
				flex: 0 0 126px;
			}
			@media (max-width: 480px) {
				max-width: 100px;
				flex: 0 0 100px;
			}
		}
		section {
			display: grid !important;
			grid-template-columns: repeat(7, 1fr) !important;
			height: 100%;
			padding: 25px;
			align-items: center;
			column-gap: 10px;
			h2 {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			div {
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				display: block;
			}
			@media (max-width: 1300px) {
				padding: 20px;
				grid-template-columns: repeat(3, 1fr) !important;
				row-gap: 20px;
			}
			@media (max-width: 768px) {
				row-gap: 15px;
				grid-template-columns: repeat(2, 1fr) !important;
			}
			@media (max-width: 480px) {
				padding: 15px;
				.CreatedUser,
				.status {
					grid-area: auto / 3 / auto / 1;
				}
				
			}
			@media (max-width: 340px) {
				grid-template-columns: repeat(1, 1fr) !important;
			}
			.btn-block {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				@media (max-width: 1300px) {
					justify-content: flex-start;
				}
				span {
					height: auto;
					flex: 0 0 auto;
					max-width: auto;
					min-width: auto;
					min-height: auto;
				}
				button {
					display: flex;
					align-items: center;
					margin-left: 15px;
					text-transform: capitalize;
					white-space: nowrap;
					height: 50px;
					@media (max-width: 1300px) {
						margin-left: 0;
					}
					@media (max-width: 480px) {
						margin-left: 15px;
						font-size: 13px;
						padding-right: 10px;
						padding-left: 10px;
						white-space: nowrap;
					}
					span {
						max-width: 100% !important;
						display: flex;
						align-items: center;
						svg {
							height: 14px;
							margin-right: 5px;
							path {
								fill: ${({ theme }) => theme.colors.bg.white};
							}
						}
					}
					&.success,
					&.danger {
						margin: 0;
						max-width: 50px;
						padding: 0;
						flex: 0 0 50px;
						width: 50px;
						svg {
							margin: 0 !important;
						}
					}
				}
				icon {
					display: inline-block;
				}
				div {
					word-break: break-word;
				}
			}
			.statusRejected,
			.statusApproved {
				color: ${({ theme }) => theme.baseColors.white};
				padding: 5px 15px;
				border-radius: 50px;
				float: left;
				font-size: 13px !important;
				font-family: 'OpenSansSemiBold' !important;
			}
			.statusRejected {
				background: ${({ theme }) => theme.colors.state.error};
			}
			.statusApproved {
				background: ${({ theme }) => theme.colors.state.success};
			}
			.totalProducts {
				max-width: 82px;
				text-align: right;
				white-space: nowrap;
			}
		}
	}
`;
const PageSearchHolder = styled.div`
	width: 100%;
	padding-top: 15px;
	padding-bottom: 10px;
	display: flex;
	justify-content: space-between;
	div {
		width: 650px;
		@media screen and (max-width: 1200px) {
			width: 60%;
		}
		@media screen and (max-width: 860px) {
			width: 100%;
		}
		+ div {
			width: 254px;
			@media screen and (max-width: 860px) {
				width: 100%;
				margin-top: 10px;
			}
		}
	}
	@media screen and (max-width: 860px) {
		flex-direction: column;
	}
	@media screen and (max-width: 767px) {
		width: 100%;
	}
`;

function PendingOrdersListView(): ReactElement {
	let history = useHistory();
	const ctx = useContext(AppContext).productSearchObj;
	const [searchText, setSearchText] = useState('');
	const [url, setSearchUrl] = useState('');
	const [orderList, setOrderList] = useState([] as any);
	const [mainList, setMainList] = useState([] as any);
	const [venueUserId] = useState<any>(localStorage.getItem('userId'));
	const [venueId, setVenueId] = useState<any>(localStorage.getItem('selectedVenue'));
	const [inProgress, setInProgress] = useState(false);
	const [state, dispatch] = UseGlobalState();
	const [modalText, setmodalText] = useState('');
	const [cartId, setCartId] = useState('');
	const [showPopup, setShowPopup] = useState(false);
	const [updateData, setUpdateData] = useState<any>();
	const statusArray = ['rejected', 'pending_approval', 'expired'];

	let urlObj: Partial<urlObj> = {
		url: 'experion/pending-order/search?',
		pageSize: 2000,
		filterDeleted: true,
		ctx: ctx,
		fields: [
			{
				field_name: 'venue_id',
				field_value: localStorage.getItem('selectedVenue')!,
			},
			{
				field_name: 'venue_user_id',
				field_value: localStorage.getItem('userId')!,
			},
		],
	};

	const getKey = () => {
		return `pendingorders${ctx.SortField}${ctx.SortDirection}`;
	};

	const getSortData = (val) => {
		let obj = { field: '', direction: '' };
		switch (val) {
			case 'Low - High Price':
				obj.field = 'cart_total';
				obj.direction = 'asc';
				break;
			case 'High - Low Price':
				obj.field = 'cart_total';
				obj.direction = 'desc';
				break;
			default:
				break;
		}
		return obj;
	};

	const obj: Partial<Iget> = {
		url: urls.GetSearchUrl(urlObj),
		enabled: true,
		refetchOnWindowFocus: false,
		resultFilteringRequired: true,
		key: getKey(),
	};
	const qry = hooks.useQuery(obj);

	const handleSort = (val) => {
		let sortData = getSortData(val);
		ctx.SortDirection = sortData.direction;
		ctx.SortField = sortData.field;
		setSearchUrl(urls.GetSearchUrl(urlObj));
	};

	//api to get total amount
	const url1 = `/carts/${cartId}/totals`;
	const obj6: Partial<Iget> = {
		url: url1,
		enabled: false, //manual fetch
		key: 'carttotalKey1',
		resultFilteringRequired: false,
	};
	var qryTotal = hooks.useQuery(obj6);

	function pendingOrderActionConfirm(e, orderId, type, products) {
		e.stopPropagation();
		if (type === 1 || type === 2) {
			const data = {
				orderId: orderId,
				type: type,
				products: products,
			};
			setUpdateData(data as any);
			const msg =
				type == 1
					? 'Are you sure you want to approve the order?'
					: 'Are you sure you want to reject the order?';
			showConfirmationModal(msg);
		} else {
			pendingOrderAction(orderId, type, products);
		}
	}

	async function pendingOrderAction(orderId, type, products) {
		setInProgress(true);
		if (type === 1 || type === 2) {
			const status = type === 1 ? 'approved' : 'rejected';
			const data = {
				orderGroupId: orderId,
				userId: localStorage.getItem('userId'),
				status: status,
				venue_id: localStorage.getItem('selectedVenue'),
			};
			httpService
				.PUT({ url: `experion/order/update`, data: data })
				.then(function (data) {
					{
						updateListData(orderId, type);
						setInProgress(false);
					}
				})
				.catch((e) => {
					setInProgress(false);
				});
		} else {
			let id: any;
			let CUSTOMER_TOKEN = localStorage.getItem('customerToken');
			id = await utilityFunctions.getCartId(CUSTOMER_TOKEN);
			if (id !== undefined) {
				setCartId(id);
				const data = JSON.parse(products);
				let items = [] as any;
				data?.order_details?.forEach((v, i) => {
					items.push({ product_id: v.product_data.product_id, qty: v.product_data.qty_ordered });
				});
				let addToCartJson = {
					products: {
						orderGroupId: parseInt(orderId),
						quote_id: parseInt(id),
						items: items,
						venue_user_id: parseInt(venueUserId),
						venue_id: parseInt(venueId),
					},
				};
				api
					.post({
						data: addToCartJson,
						url: constants.multiCartURL(),
						key: 'pendingOrderCartAdd',
						message: 'Successfully moved to cart',
					})
					.then(function (data) {
						fetchTotal();
						updateListData(orderId, type);
						setInProgress(false);
					})
					.catch((e) => {
						setInProgress(false);
					});
			} else {
				setCartId('');
				setInProgress(false);
			}
		}
	}

	function updateListData(orderId, type) {
		const orders = mainList;
		var list = [];
		if (type === 1 || type === 2) {
			const status = type === 1 ? 'pending' : 'rejected';
			const data = [JSON.stringify({ status: status })];
			list = orders.map((obj) => (obj.order_group_id === orderId ? { ...obj, status: data } : obj));
		} else {
			list = orders.map((obj) =>
				obj.order_group_id === orderId ? { ...obj, is_moved_to_cart: 1 } : obj
			);
		}
		setOrderList(list);
		setMainList(list);
	}

	const fetchTotal = () => {
		qryTotal.refetch().then((data) => {
			if (data) {
				updateCartTotal(data);
			}
		});
	};

	function updateCartTotal(data) {
		if (data) {
			let datas = data.items?.filter((x) => x.is_deleted != true);
			if (datas?.length > 0) {
				dispatch({ cartTotal: helperFunctions.formatAmount(data.grand_total) });
				dispatch({ cartItemCount: datas?.length });
				// setTotalCount(data.subtotal);
			} else {
				dispatch({ cartItemCount: '0' });
				dispatch({ cartTotal: '' });
				// setTotalCount(0);
			}
		} else {
			dispatch({ cartItemCount: '0' });
			dispatch({ cartTotal: '' });
			// setTotalCount(0);
		}
	}

	const setFilteredData = (val) => {
		setSearchText(val);
		let datas = mainList;
		const orders = datas;
		if (val && val.length > 0) {
			var list = orders.filter((v) => {
				let statusList = getStatusList(v.status);
				let orderStatus = utilityFunctions.getOrderStatus(statusList.map((x) => x.status));
				let statusName = helperFunctions.toPascalCase(
					statusArray.includes(orderStatus) ? orderStatus?.replace('_', ' ') : 'Approved'
				);
				return (
					statusName?.toLowerCase().includes(val.toLowerCase()) ||
					v.name?.toLowerCase().includes(val.toLowerCase()) ||
					String('$' + parseFloat(v.cart_total).toFixed(2))
						.toLowerCase()
						.includes(val.toLowerCase()) ||
					String(v.no_of_items).toLowerCase().includes(val.toLowerCase()) ||
					String('#' + v.order_group_increment_id)
						?.toLowerCase()
						.includes(val.toLowerCase()) ||
					helperFunctions.formatDate(v.created_at).toLowerCase().includes(val.toLowerCase())
				);
			});
			setOrderList(list);
		} else {
			setOrderList(datas);
		}
	};

	function getStatusList(status) {
		let data = [] as any;
		if (status.length > 0) {
			if (status.length > 1) {
				let items = JSON.parse(JSON.stringify(status));
				items.map((v, i) => {
					data.push(JSON.parse(v));
				});
			} else {
				data.push(JSON.parse(status));
			}
		}
		return data;
	}

	function showConfirmationModal(text) {
		setmodalText(text);
		setShowPopup(!showPopup);
	}

	const onSubmit = () => {
		setShowPopup(!showPopup);
		pendingOrderAction(updateData?.orderId, updateData?.type, updateData?.products);
	};

	async function closePopup() {
		await setShowPopup(!showPopup);
	}

	useEffect(() => {
		setOrderList(qry.data);
		setMainList(qry.data);
	}, [qry.data]);

	return (
		<StyledOrderListView>
			<PageSearchHolder>
				<Search onChange={(val) => setFilteredData(val)}></Search>
				<SortBy onSort={handleSort} removeNameSort={true}></SortBy>
			</PageSearchHolder>
			{!qry.isFetching && orderList?.length > 0 ? (
				orderList.map(
					(
						{
							order_group_id,
							order_group_increment_id,
							name,
							cart_total,
							no_of_items,
							created_at,
							status,
							venue_user_id,
							products,
							is_moved_to_cart,
						},
						i
					) => {
						let statusList = getStatusList(status);
						let orderStatus = utilityFunctions.getOrderStatus(statusList.map((x) => x.status));
						let statusName = helperFunctions.toPascalCase(
							statusArray.includes(orderStatus) ? orderStatus?.replace('_', ' ') : 'Approved'
						);
						return (
							<Flex
								as="article"
								onClick={() => history.push(`/store/pendingOrderDetails?id=${order_group_id}`)}
								bg="white"
								sx={{ alignItems: 'center' }}
								key={Math.random()}
							>
								<Flex as="section" flexWrap="wrap" width="100%" maxWidth={['100%']}>
									<Box>
										<Text fontSize={16} color="text.black">
											Order ID
										</Text>
										<Text fontSize={18} fontFamily="OpenSansBold" color="text.black">
											{'#' + order_group_increment_id}
										</Text>
									</Box>
									<Box className="CreatedUser">
										<Text as="h1" fontSize={16} color="text.black">
											Created User
										</Text>
										<Text as="h2" fontSize={18} fontFamily="OpenSansBold" color="text.black">
											{name}
										</Text>
									</Box>
									<Box>
										<Text as="h1" fontSize={16} color="text.black">
											Amount
										</Text>
										<Text as="h2" fontSize={18} fontFamily="OpenSansBold" color="text.black">
											{helperFunctions.formatAmount(cart_total)}
										</Text>
									</Box>
									<Box>
										<Text as="h1" fontSize={16} color="text.black">
											Created On
										</Text>
										<Text as="h2" fontSize={18} fontFamily="OpenSansBold" color="text.black">
											{helperFunctions.formatDate(created_at)}
										</Text>
									</Box>
									<Box className="totalProducts">
										<Text as="h1" fontSize={16} color="text.black">
											Total Items
										</Text>
										<Text as="h2" fontSize={18} fontFamily="OpenSansBold" color="text.black">
											{no_of_items}
										</Text>
									</Box>
									<Box className="status">
										<Text as="h1" fontSize={16} color="text.black">
											Status
										</Text>
										{/* Add class Approved for ".statusApproved" Reject for ".statusRejected" */}
										<Text
											as="h2"
											fontSize={18}
											fontFamily="OpenSansBold"
											color="text.black"
											className={`${
												utilityFunctions.isGranted(venueUserPermissionList.OrderApproval)
													? statusName == 'Approved'
														? 'statusApproved'
														: statusName == 'Rejected'
														? 'statusRejected'
														: ''
													: ''
											}`}
										>
											{statusName}
										</Text>
									</Box>
									<Box className="btn-block">
										{orderStatus == 'pending_approval' &&
											utilityFunctions.isGranted(venueUserPermissionList.OrderApproval) && (
												<>
													<Tooltip
														arrow
														title="Reject"
														animation="perspective"
														position="top"
														trigger="mouseenter"
														style={{ marginRight: '10px' }}
													>
														<Button
															variant="danger"
															className="danger"
															onClick={(e) => {
																e.stopPropagation();
																pendingOrderActionConfirm(e, order_group_id, 2, products);
															}}
														>
															<icons.Close_ICon />
														</Button>
													</Tooltip>
													<Tooltip
														arrow
														title="Approve"
														animation="perspective"
														position="top"
														trigger="mouseenter"
													>
														<Button
															variant="success"
															className="success"
															onClick={(e) => {
																e.stopPropagation();
																pendingOrderActionConfirm(e, order_group_id, 1, products);
															}}
														>
															<icons.Tick />
														</Button>
													</Tooltip>
												</>
											)}
										{orderStatus == 'rejected' &&
											!utilityFunctions.isGranted(venueUserPermissionList.OrderApproval) &&
											parseInt(venueUserId) === parseInt(venue_user_id) &&
											!is_moved_to_cart && (
												<Button
													onClick={(e) => {
														pendingOrderActionConfirm(e, order_group_id, 3, products);
													}}
												>
													Move to Cart
												</Button>
											)}
									</Box>
								</Flex>
							</Flex>
						);
					}
				)
			) : !qry.isFetching ? (
				<UserBody>
					{/* <Icon.searchIcon title="No Data Found" /> */}
					<Text fontSize={18} fontWeight="bold" color="#5C5E57" textAlign="center">
						No Orders Found
					</Text>
				</UserBody>
			) : (
				new Array(2).fill(null).map((_, i) => {
					return <Skeleton key={i} width="1200px" height="50px" />;
				})
			)}
			{inProgress ? <Loader></Loader> : null}
			<SweetAlert
				show={showPopup}
				confirmBtnText="Confirm"
				onConfirm={onSubmit}
				showCancel={true}
				onCancel={closePopup}
				title={'Confirmation Box'}
			>
				{() => <form>{modalText}</form>}
			</SweetAlert>
		</StyledOrderListView>
	);
}

export { PendingOrdersListView };
