import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import 'react-tippy/dist/tippy.css';
import { Box, Button, Flex, Text } from 'rebass/styled-components';
import styled, { css } from 'styled-components';
import * as Icon from 'ui/icons';
import helperFunctions from 'utils/helper.functions';
import pageConstants from 'utils/pageConstants';

const StyledCartListView = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	row-gap: 5px;
	width: 100%;
`;

const NotificationsFullViewHolder = styled.div<NotificationsFullViewHolderProps>`
	width: 100%;
	display: flex;
	flex-direction: row;
	background: ${({ theme }) => theme.colors.bg.white};
	border: 1px solid ${({ theme }) => theme.colors.bg.white};

	box-sizing: border-box;
	border-radius: 16px;
	overflow: hidden;
	transition: box-shadow 100ms ease-in, transform 100ms ease-in;
	align-items: center;
	cursor: pointer;
	padding: 10px;
	@media (max-width: 480px) {
		padding: 10px 0;
	}
	button {
		&.readIndication {
			background: ${({ theme }) => theme.baseColors.primary}!important;
			padding: 0;
			transition: none;
			width: 18px;
			height: 18px;
			border: 4px solid ${({ theme }) => theme.colors.bg.lightYellow06}!important;
			border-radius: 100%;
			margin-right: 5px;
			flex: 0 0 18px;
			max-width: 18px;
		}
		&.unreadIndication {
			background: ${({ theme }) => theme.colors.bg.white};
			padding: 0;
			transition: none;
			width: 18px;
			height: 18px;
			border: 4px solid ${({ theme }) => theme.colors.bg.white};
			border-radius: 100%;
			margin-right: 5px;
			flex: 0 0 18px;
			max-width: 18px;
		}
		&.notificationClear {
			background: transparent;
			padding: 0;
			transition: none;
			width: 18px;
			height: 18px;
			border: transparent;
			border-radius: 100%;
			margin-right: 10px;
			flex: 0 0 18px;
			max-width: 18px;
			display: none;
			svg {
				width: 12px;
				height: auto;
				path {
					fill: ${({ theme }) => theme.baseColors.red};
				}
			}
		}
	}
	&:hover {
		background: ${({ theme }) => theme.newColors.lightgray};
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07);

		picture {
			background: ${({ theme }) => theme.colors.bg.white};
		}
		button {
			&.notificationClear {
				display: block !important;
			}
			&.unreadIndication {
				border: 4px solid ${({ theme }) => theme.colors.bg.lightYellow06}!important;
			}
		}
	}

	@media (max-width: 480px) {
		align-items: flex-start;
	}
	picture {
		max-width: 40px;
		flex: 0 0 40px;
		height: 40px;
		border-radius: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: ${({ theme }) => theme.colors.bg.lightYellow06};
		border: 1px solid ${({ theme }) => theme.colors.bg.white};
		margin-right: 15px;
		svg {
			width: 20px;
			height: 25px;
		}
	}
	section {
		height: 100%;
		position: relative;
		display: flex;
		flex-direction: row;
		flex-direction: column;
		align-items: flex-start;
	}
	slot {
		flex: 1;
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		justify-content: space-between;
		position: relative;
		justify-content: center;
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-weight: normal;
			color: ${({ theme }) => theme.colors.text.black};
		}
		h1 {
			margin-bottom: 3px;
		}
		h2 {
			strong {
				font-family: 'OpenSansMedium';
			}
		}
		h3 {
			margin-top: 4px;
			color: #8993a4;
		}

		a {
			font-family: 'OpenSansBold';
			cursor: pointer;
			margin-top: 4px;
			&:hover {
				color: ${({ theme }) => theme.baseColors.primary};
			}
		}
		+ slot {
			display: flex;
			align-items: center;
			flex-direction: row;
			justify-content: flex-start;
			@media (max-width: 768px) {
				justify-content: flex-start;
			}
		}
	}
	${({ active }) =>
		!active &&
		css`
			opacity: 0.3;
		`}
`;

type NotificationsFullViewHolderProps = {
	active?: boolean;
};

function NotificationsFullView(props): ReactElement {
	const history = useHistory();
	const onNotificationClick = (item, event) => {
		event.stopPropagation();
		if (item && item.sku && item.sku !== '') {
			const skuList = item.sku.split(',');
			if (skuList.length > 1) {
				history.push({
					pathname: '/home/product-list',
					search: `?search=` + '&type=Products' + '&notificationId=' + item.id,
				});
			} else {
				history.push('/home/product-details?id=' + item.sku);
			}
		}
	};

	const getDateTime = (dateTime) => {
		dateTime = helperFunctions.formatDateTime(dateTime);
		dateTime = dateTime + ' UTC';
		const theDate = new Date(Date.parse(dateTime));
		const localTime = theDate.toLocaleString();
		return `${helperFunctions.formatDate(localTime)}  ${helperFunctions.formatTime(localTime)}`;
	};
	return (
		<StyledCartListView>
			{props.notificationsFullView
				? props.notificationsFullView.map((item) => (
						<NotificationsFullViewHolder
							onClick={(event) => {
								onNotificationClick(item, event);
							}}
							key={helperFunctions.getRandomInt(pageConstants.maxValue)}
							active={true}
						>
							<Flex
								alignItems="center"
								as="picture"
								maxWidth={['100%']}
								sx={{
									overflow: 'hidden',
									backgroundColor: '#f5f5f5 !important',
									border: '1px solid #a0a0a0 !important',
								}}
							>
								{/* {item.notificationType === 'alert' ? <Icon.bellIcon width="23px" /> : null}
								{item.notificationType === 'email' ? <Icon.emailIcon width="23px" /> : null} */}
								<Icon.bellIcon width="23px" />
							</Flex>
							<Flex as="section" flexDirection="column" width="100%" maxWidth={['100%']}>
								<Box as="slot">
									{/* <Text as="h1" fontSize={16} fontFamily="OpenSansBold" color="text.black">
										{item.notificationHeading}
									</Text> */}

									<Text as="h2" fontSize={14} fontFamily="OpenSansMedium" color="text.black">
										{/* Shipment on order <strong>ID {item.orderID}</strong> has been confirmed */}
										<strong>{item.description}</strong>
									</Text>
									{/* <Link as="a" fontSize={14}>
										{item.goToOrderDetails}
									</Link> */}
								</Box>
								<Box as="slot">
									<Text as="h3" fontSize={14} fontFamily="OpenSansMedium" color="text.black">
										{getDateTime(item.date_time)}
										{/* {`${helperFunctions.formatDate(item.date_time)}  ${helperFunctions.formatTime(item.date_time)}`} */}
									</Text>
									{/* <Text as="h3" fontSize={14} fontFamily="OpenSansMedium" color="text.black">
									{` ${helperFunctions.formatTime(item.date_time)}`}
									</Text> */}
								</Box>
							</Flex>

							{/* NotificationCLear */}
							<Button
								className="notificationClear"
								onClick={(e) => {
									props.deleteNotification(item.id);
									e.stopPropagation();
								}}
							>
								<Icon.CloseIcon />
							</Button>
							{/* Notification Read */}
							{item.mark_read == '0' && (
								<Button
									className="readIndication"
									onClick={(e) => {
										props.markAsRead(item.id);
										e.stopPropagation();
									}}
								>
									<span></span>
								</Button>
							)}
							{/* Notification Unread */}
							{item.mark_read == '1' && (
								<Button
									className="unreadIndication"
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
									}}
								>
									<span></span>
								</Button>
							)}
						</NotificationsFullViewHolder>
				  ))
				: null}
		</StyledCartListView>
	);
}

export { NotificationsFullView };
