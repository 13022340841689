import { Input } from '@rebass/forms';
import React, { ReactElement, useEffect, useState } from 'react';
import { Flex, Text } from 'rebass/styled-components';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { Button, Loader, RadioButton } from 'ui/components';
import * as Icon from 'ui/icons';
import { AddToFavorites, AddToFavoritesContainer } from './styles';


export default function AddToParkedOrderModal(props): ReactElement {
	const [addParkedOrder, setAddParkedOrder] = useState(0);
	const handleOnChange = (parkorder_id, is_locked, locked_by, name) => {
		setAddParkedOrder(parseInt(parkorder_id));
		props.saveProductToParkedOrder(parseInt(parkorder_id),parseInt(is_locked),parseInt(locked_by), name);
	};
	return (
		<AddToFavorites>
			<Text as="h1" color="text.textHeading" fontSize={22} fontFamily="OpenSansBold">
				Add to Parked Orders
			</Text>
			<Text as="p" color="text.black06" fontFamily="OpenSansMedium" fontSize={[16]}>
				Select a parked order list to add the product
			</Text>

			<AddToFavoritesContainer>
				<SimpleBar>
					{props.parkedOrderList === undefined ? <Loader></Loader> : null}
					{props.parkedOrderList && props.parkedOrderList.length > 0 ?
						props.parkedOrderList.map((item) => (
							
							<RadioButton
								id={item.parkorder_id}
								name="paymentMethod"
								onChange={(e) => {
									e.stopPropagation(); 
									handleOnChange(item.parkorder_id, item.is_locked, item.locked_by, item.name)}
								}
								hasActiveText
							>
								<Flex
									alignItems="center"
									width="100%"
									pl={[1, 2]}
									sx={{ transform: 'translateY(-3px)' }}
								>
									<Text as="h1" fontFamily="OpenSansSemiBold" flex="1" px={2}>
										{item.name} 
									</Text>
									{addParkedOrder === item.parkorder_id ? <Icon.Tick /> : ``}
								</Flex>
							</RadioButton>
						)) : 
						<Flex
							alignItems="center"
							width="100%"
							pl={[1, 2]}
							sx={{ transform: 'translateY(-3px)' }}
						>
							<Text as="h1" fontFamily="OpenSansSemiBold" flex="1" px={2}>
								No Records Found
							</Text>
						</Flex>
						}
				</SimpleBar>
			</AddToFavoritesContainer>
		</AddToFavorites>
	);
}
