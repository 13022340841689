import React, { ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Header, Footer, BreadcrumbNav, Loader } from 'ui/components';
import { Sidebar, DataGrid } from 'ui/patterns';
import { MainContent, RightContentHolder, ContentHolder, BreadcrumbHolder } from './styles';
import Iget from 'models/Iget';
import hooks from 'utils/requestCtx/reactQueryHooks';
import constants from 'utils/constants';
function SupplierReviewAndRating(): ReactElement {
	const location = useLocation() as any;
	const parsed = queryString.parse(location.search);
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const breadcrumbNav = [
		{ id: '01', label: 'Dashboard ', route: '/admin/seller-dashboard', active: false },
		{ id: '02', label: 'Reviews & Ratings ', route: '/admin/seller-ratings', active: false },
		{ id: '03', label: parsed.venue, active: true },
	];
	const OnlyHeading = parsed.venue;
	const gridDataHead = [
		{ label: 'User Name' },
		{ label: 'Rating' },
		{ label: 'Review' },
		{ label: 'Date' },
	];
	const [gridData, updateGridData] = useState<any>([]);
	let CUSTOMER_TOKEN = localStorage.getItem('customerToken');
	const obj: Partial<Iget> = {
		params: {},
		url: constants.getSupplierReviewAndRatings() + parsed.venue_id,
		enabled: true,
		key: 'supplierReviewRatingVenue',
		resultFilteringRequired: true,
		headers: { Authorization: CUSTOMER_TOKEN },
	};
	const qry = hooks.useQuery(obj);
	useEffect(() => {
		qry.data && updateGridData(qry.data.reviews);
	}, [location, qry.data]);
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	}
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={()=>onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={()=>onclickHambrgBtn()}></Sidebar>
			{qry.isLoading ? <Loader></Loader> : null}
			<MainContent>
				{/* Top Breadcrumb */}
				<BreadcrumbHolder>
					<BreadcrumbNav Heading={OnlyHeading} breadcrumbList={breadcrumbNav} />
				</BreadcrumbHolder>
				<ContentHolder>
					{/* Right Product Detail Session */}
					<RightContentHolder>
						<DataGrid gridData={gridData} gridDataHead={gridDataHead} venueName={OnlyHeading} />
					</RightContentHolder>
				</ContentHolder>
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}
export default SupplierReviewAndRating;
