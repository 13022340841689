import ReasonForRejectionModal from 'admin/orderRequest/reasonForRejection';
import { dcUserPermissionList, OrderStatuses } from 'models/enum-constants/common.constants';
import Iget from 'models/Iget';
import Ipost from 'models/Ipost';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory } from 'react-router-dom';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { Text, Box } from 'rebass/styled-components';
import { Button, Search, TableList, Loader } from 'ui/components';
import icons from 'ui/icons';
import { Modal } from 'ui/patterns';
import urls from 'utils/create.url';
import helper from 'utils/helper.functions';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import utilityFunctions from 'utils/utility.functions';
import { PageSearchHolder, PendingOrderHolder } from './styles';

function PendingOrder(): ReactElement {
	localStorage.removeItem('refetchOnOrderReload');
	const [pendingOrders, setPendingOrders] = useState<any>([]);
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [showPopup, setShowModal] = useState(false);
	const [modalText, setmodalText] = useState('');
	const [action, setAction] = useState('');
	const [id, setId] = useState(0);
	const [firstCheckBox, setFirstCheckBox] = useState(true);
	const [secondCheckBox, setSecondCheckBox] = useState(false);
	const [showReasonForRejectionModal, setShowReasonForRejectionModal] = useState(false);
	const toggleReasonForRejectionModal = () => {
		setShowReasonForRejectionModal(!showReasonForRejectionModal);
	};
	const url1 = urls.GetSearchUrl({
		url: `orders?`,
		pageSize: 10,
		fields: [
			{
				field_name: 'dcid',
				field_value: localStorage.getItem('selectedLocation')!,
			},
			{
				field_name: 'status',
				field_value: `pending_approval`,
				field_conditionType: 'neq',
			},
			{
				field_name: 'status',
				field_value: `rejected`,
				field_conditionType: 'neq',
			},
			{
				field_name: 'status',
				field_value: `expired`,
				field_conditionType: 'neq',
			},
			{
				field_name: 'status',
				field_value: `approved`,
				field_conditionType: 'neq',
			},
			{
				field_name: 'status',
				field_value: `holded`,
				field_conditionType: 'neq',
			},
			{
				field_name: 'status',
				field_value: `pending`,
				field_conditionType: 'eq',
			},
		],
	});

	const obj: Partial<Iget> = {
		params: {},
		url: url1,
		enabled: true,
		key: 'pendingOrders_2',
	};
	var qry = hooks.useQuery(obj);
	useEffect(() => {
		qry.data && qry.data.items && setPendingOrders(qry.data.items);
	}, [qry.data && qry.data.items]);
	const setFilteredData = (val) => {
		const sampleList = qry.data.items;
		if (val && val.length > 0) {
			var list = sampleList.filter(
				(v) =>
					v.entity_id.toString().includes(val) ||
					v.total_qty_ordered.toString().includes(val) ||
					v.subtotal.toString().includes(val) ||
					v.extension_attributes.venue_name.toLowerCase().includes(val.toLowerCase()) ||
					helper.formatDate(v.created_at).toLowerCase().includes(val.toLowerCase())
			);
			setPendingOrders(list);
		} else {
			setPendingOrders(qry.data.items);
		}
	};
	const orderAcceptRejectSubmit = (action, id) => {
		if (action == OrderStatuses.Confirmed) {
			setAction(action);
			showConfirmationModal(action, 'Are you sure you want to Accept this Order?', id);
		}
	};
	function showConfirmationModal(action, text, id) {
		setId(id);
		setmodalText(text);
		setShowModal(!showPopup);
	}

	const onSubmitReason = (reasonForRejection, action) => {
		setLoading(true);
		let payload;
		if (action == OrderStatuses.Rejected) {
			payload = {
				entity_id: id,
				state: OrderStatuses.Cancelled,
				status: action,
				status_histories: [
					{
						comment: `${
							reasonForRejection != ''
								? (firstCheckBox ? 'Product unavailable:' : 'Delivery unavailable:') +
								  reasonForRejection
								: ''
						}`,
						entity_name: 'order',
						is_customer_notified: 1,
						is_visible_on_front: 1,
						parent_id: id,
						status: action,
					},
				],
				extension_attributes: {
					receiver_email: pendingOrders.filter((x) => x.entity_id == id)[0].customer_email,
				},
			};
		} else {
			payload = {
				entity_id: id,
				state: OrderStatuses.Processing,
				status: action,
			};
		}
		const obj: Partial<Ipost> = {
			data: { entity: payload },
			params: {},
			url: `orders/create`,
			message: `Order(s) ${
				action == OrderStatuses.Rejected ? 'rejected' : 'accepted'
			} successfully`,
		};
		try {
			setLoading(true);
			api.put(obj).then((data) => {
				if (data !== undefined) {
					qry.refetch().then(function (data) {
						if (data && data?.items?.length > 0) setPendingOrders(data.items);
						setLoading(false);
					});
					window.scroll({ top: 0, left: 0, behavior: 'smooth' });
				}
			}).catch(function (data) {
				setLoading(false);
			});;
		} catch (e) {}

		if (action === OrderStatuses.Rejected)
			setShowReasonForRejectionModal(!showReasonForRejectionModal);
		else setShowModal(!showPopup);
	};
	async function closePopup() {
		await setShowModal(!showPopup);
	}
	const setReasonForRejection = (comment, event) => {
		if (comment === 'delivery') {
			if (event.target.checked) {
				setFirstCheckBox(false);
				setSecondCheckBox(true);
			} else {
				setFirstCheckBox(true);
				setSecondCheckBox(false);
			}
		} else if (comment === 'product') {
			if (event.target.checked) {
				setFirstCheckBox(true);
				setSecondCheckBox(false);
			} else {
				setFirstCheckBox(false);
				setSecondCheckBox(true);
			}
		}
	};
	const deleteOrder = (id) => {
		setId(id);
		toggleReasonForRejectionModal();
	};
	const cancel = () => {
		setShowReasonForRejectionModal(!showReasonForRejectionModal);
		setFirstCheckBox(true);
		setSecondCheckBox(false);
	};
	return (
		<PendingOrderHolder>
			{qry.isLoading || loading == true ? <Loader></Loader> : null}
			<PageSearchHolder>
				<Box as="section">
					<Search
						onChange={(val) => setFilteredData(val)}
						type={'pendingOrderList'}
						placeholder="Search for Order Id, Venue Name, Quantity ..."
					></Search>
				</Box>
				{/* <Box as="small">
					<Button flex={[1, 1, 'initial']} variant="redOutline">
						<icons.DeleteIcon />
						<div>Delete Selected</div>
					</Button>
				</Box>
				<StatusFilter></StatusFilter> */}
			</PageSearchHolder>
			<TableList>
				<table>
					<thead>
						<tr>
							{/* <th className="checked">
								<Label>
									<Checkbox id="remember" name="remember" />
								</Label>
							</th> */}
							<th>Order Id</th>
							<th>Store Name</th>
							<th>Date</th>
							<th>No. of Items</th>
							<th>Delivery Date</th>
							<th>Order Total</th>
							<th className="action">Action</th>
						</tr>
					</thead>

					<tbody>
						{pendingOrders &&
						pendingOrders.length > 0 &&
						pendingOrders.filter((x) => x.status === OrderStatuses.Pending)?.slice(0, 10)?.length >
							0 ? (
							pendingOrders
								.filter((x) => x.status === OrderStatuses.Pending)
								?.slice(0, 10)
								.map(
									(
										{ entity_id, total_qty_ordered, subtotal, created_at, extension_attributes },
										index
									) => {
										let deliverySlot = extension_attributes?.delivery_slot
											? extension_attributes?.delivery_slot
											: '';
										deliverySlot = deliverySlot.split(' ');
										let deliverySlotDay = null as any;
										let deliverySlotDate = null as any;
										if (deliverySlot.length === 2) {
											deliverySlotDate = moment(deliverySlot[1], 'DD-MM-YYYY');
											deliverySlotDate = helper.formatDate(deliverySlotDate.toDate());
											deliverySlotDay = deliverySlot[0];
										}
										return (
											<tr
												key={entity_id}
												onClick={() => history.push(`orderRequest?id=${entity_id}`)}
											>
												{/* <td className="checked">
											<Label>
												<Checkbox id="remember" name="remember" />
											</Label>
										</td> */}
												<td data-label="Order Id">
													<Text as="h1" fontSize={15} fontFamily="OpenSansBlack" color="text.black">
														<span>{extension_attributes.supplier_order_increment_id}</span>
													</Text>
												</td>
												<td data-label="Venue Name">
													<Text
														as="h1"
														fontSize={15}
														fontFamily="OpenSansMedium"
														color="text.black"
													>
														{extension_attributes.venue_name}
													</Text>
												</td>
												<td data-label="Date">
													<Text
														as="h1"
														fontSize={15}
														fontFamily="OpenSansMedium"
														color="text.black"
													>
														{helper.formatDate(created_at)}
													</Text>
												</td>
												<td data-label="NO. OF ITEMS">
													<Text
														as="h1"
														fontSize={15}
														fontFamily="OpenSansMedium"
														color="text.black"
													>
														{total_qty_ordered}
													</Text>
												</td>
												<td data-label="Delivery Date">
													<Text
														as="h1"
														fontSize={15}
														fontFamily="OpenSansMedium"
														color="text.black"
													>
														{deliverySlot && deliverySlot.length === 2
															? `${deliverySlotDay} ${deliverySlotDate}`
															: 'NIL'}
													</Text>
												</td>
												<td data-label="Order Total">
													<Text
														as="h1"
														fontSize={15}
														fontFamily="OpenSansMedium"
														color="text.black"
													>
														${parseFloat(subtotal).toFixed(2)}
													</Text>
												</td>
												<td
													data-label="Action"
													onClick={(e) => {
														e.stopPropagation();
													}}
													className="action"
												>
													<Text>
														{utilityFunctions.isGranted(
															dcUserPermissionList.OrderManagementUpdate
														) && (
															<Button
																onClick={() =>
																	orderAcceptRejectSubmit(OrderStatuses.Confirmed, entity_id)
																}
																variant="editIcon"
															>
																<icons.Tick />
															</Button>
														)}
														{utilityFunctions.isGranted(
															dcUserPermissionList.OrderManagementUpdate
														) && (
															<Button onClick={() => deleteOrder(entity_id)} variant="deleteIcon">
																<icons.CloseIcon />
															</Button>
														)}
													</Text>
												</td>
											</tr>
										);
									}
								)
						) : (
							<tr>
								<td>No orders found</td>
							</tr>
						)}
					</tbody>
				</table>
			</TableList>
			<SweetAlert
				show={showPopup}
				confirmBtnText="Confirm"
				onConfirm={() => onSubmitReason('', OrderStatuses.Confirmed)}
				showCancel={true}
				onCancel={closePopup}
				title={'Confirmation Box'}
				//  dependencies={[this.state.firstName, this.state.lastName]}
			>
				{() => (
					<form>
						<hr />
						{modalText}
						<hr />
					</form>
				)}
			</SweetAlert>
			<Modal
				width="500px"
				showModal={showReasonForRejectionModal}
				setShowModal={setShowReasonForRejectionModal}
			>
				<ReasonForRejectionModal
					setReasonForRejection={setReasonForRejection}
					firstCheckBox={firstCheckBox}
					secondCheckBox={secondCheckBox}
					onSubmitReason={onSubmitReason}
					cancel={() => {
						cancel();
					}}
				/>
			</Modal>
		</PendingOrderHolder>
	);
}

export { PendingOrder };
