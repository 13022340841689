import rolePermissions from 'models/rolePermission.model';
import React, { ReactElement } from 'react';
import { Box } from 'rebass/styled-components';
import BindNode from './bind.nodes';
function RolePermissionHeirarchy({ data, handleOnChange,hasChange }): ReactElement {
	return (
		<>
			{data
				?.filter((x) => x.parent_id == 0)
				.map((l0: rolePermissions) => {
					let level1 = data?.filter((y) => y.parent_id == l0.id);
					return (
						<Box as="ul" key={l0.id}>
							<Box as="li">
								<BindNode obj={l0} count={level1.length} handleOnChange={handleOnChange}></BindNode>
								{level1 &&
									level1.length > 0 &&
									level1.map((l1: rolePermissions) => {
										let level2 = data?.filter((p) => p.parent_id == l1.id);
										return (
											<Box as="ul" key={l1.id}>
												<Box as="li">
													<BindNode
														obj={l1}
														count={level2.length}
														handleOnChange={handleOnChange}
													></BindNode>
													{level2 &&
														level2.length > 0 &&
														level2.map((l2: rolePermissions) => {
															let level3 = data?.filter((q) => q.parent_id == l2.id);
															return (
																<Box as="ul" key={l2.id}>
																	<Box as="li">
																		<BindNode
																			obj={l2}
																			count={level3.length}
																			handleOnChange={handleOnChange}
																		></BindNode>
																	</Box>
																</Box>
															);
														})}
												</Box>
											</Box>
										);
									})}
							</Box>
						</Box>
					);
				})}
		</>
	);
}

export default RolePermissionHeirarchy;
