import React, { ReactElement, ReactNode, useState } from 'react';
import styled, { css } from 'styled-components';

type TabsWrapperProps = {
	orderTabs?: boolean;
	merchantTabs?: boolean;
};

const TabsWrapper = styled.div<TabsWrapperProps>`
	display: grid;
	height: 100%;
	grid-template-rows: auto 1fr;
	width: 100%;
	float: left;

	& > [role='tablist'] {
		display: flex;
		justify-content: center;
		border-bottom: solid 1px ${({ theme }) => theme.colors.border.grayMedium};
		padding: 0 10px;
		& > * + * {
			margin-left: 15px;
		}
	}
	& > [role='tablist'] > button {
		margin-bottom: -1px;
		padding: 14px 18px 8px 18px;
		min-width: 210px;
		color: ${({ theme }) => theme.colors.text.tabHeading};
		font-family: 'OpenSansMedium';
		font-size: 18px;
		line-height: 18px;
		border-radius: 15px 15px 0 0;
		background: ${({ theme }) => theme.colors.bg.menu};
		border: solid 1px ${({ theme }) => theme.colors.border.grayMedium};
		cursor: pointer;
		text-transform: uppercase;
		z-index: 1;
		@media screen and (max-width: 420px) {
			padding: 14px 6px 8px 6px;
			font-size: 13px;
			width: 100%;
			min-width: auto;
		}
		&:hover {
			background: ${({ theme }) => theme.colors.text.tabHeading};
			color: ${({ theme }) => theme.colors.text.white};
		}
	}

	& > [role='tablist'] > button[aria-selected='true'] {
		background: ${({ theme }) => theme.colors.bg.white};
		color: ${({ theme }) => theme.colors.text.tabHeading};

		border: solid 1px ${({ theme }) => theme.colors.border.grayMedium};
		border-bottom: solid 1px ${({ theme }) => theme.colors.border.white};
		box-shadow: none;
		z-index: 1;
		font-family: 'OpenSansBold';
		&:hover {
			color: ${({ theme }) => theme.colors.text.white}!;
		}
	}
	& > [role='tablist'] > button:focus {
		outline: 0;
		--bs-color: rgba(0, 0, 0, 0.4);
	}

	& > [role='tabpanel'] {
		padding: 15px 0 0 0;
		opacity: 1;
		overflow: visible;
		@media (max-width: 1300px) {
			overflow-x: auto;
		}
	}
	& > [role='tabpanel'][hidden] {
		opacity: 0;
		transition: all 0.3s ease-in;
	}

	${({ orderTabs }) =>
		orderTabs &&
		css`
			margin-top: -51px;
		`}

	${({ merchantTabs }) =>
		merchantTabs &&
		css`
			margin-top: 0px;

			@media screen and (max-width: 768px) {
				margin-top: 10px;
			}

			& > [role='tablist'] {
				@media (min-width: 769px) {
					border-bottom: solid 2px ${({ theme }) => theme.colors.border.default};
				}

				& > button {
					margin-bottom: -2px;
					min-width: 150px;

					@media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
						min-width: 200px;
					}
				}
			}

			& > [role='tabpanel'] {
				padding-top: 25px;
			}
		`}
`;

type TabsProps = {
	tabs: Array<tabcontentitem>;
	tabDescription?: string;
	orderTabs?: boolean;
	merchantTabs?: boolean;
};

type tabcontentitem = {
	id: string;
	label: string | ReactNode;
	content: string | ReactNode;
	defaultSelected?: any;
};

function Tabs({ tabs, tabDescription, orderTabs, merchantTabs }: TabsProps): ReactElement {
	const [selectedTab, setSelectedTab] = useState(tabs[0].id);
	function handleOnClick(e: any) {
		setSelectedTab(e.target.id);
	}

	return (
		<TabsWrapper orderTabs={orderTabs} merchantTabs={merchantTabs}>
			<div role="tablist" aria-label={tabDescription}>
				{tabs.map(({ id, label }) => (
					<button
						key={id}
						role="tab"
						aria-selected={selectedTab === id ? true : false}
						onClick={handleOnClick}
						id={id}
					>
						{label}
					</button>
				))}
			</div>
			{tabs.map(({ id, content }) => (
				<div
					key={id}
					role="tabpanel"
					aria-labelledby={id}
					hidden={selectedTab === id ? false : true}
				>
					{content}
				</div>
			))}
		</TabsWrapper>
	);
}

export { Tabs };
