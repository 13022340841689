import { Input, Label } from '@rebass/forms';
import React, { ReactElement } from 'react';
import { Box, Text } from 'rebass/styled-components';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import styled from 'styled-components';
import { Button, Stack } from 'ui/components';
import helperFunctions from 'utils/helper.functions';
import pageConstants from 'utils/pageConstants';
const AccessList = styled.div`
	padding: 10px 0 40px;
	.simplebar-wrapper {
		max-height: calc(100vh - 300px);
	}
	.simplebar-horizontal {
		display: none;
	}
	h3 {
		margin-bottom: 14px;
	}
	ul {
		margin: 0;

		.input {
			position: absolute;
			clip: rect(0, 0, 0, 0);
			&:checked ~ ul {
				display: block;
			}
			&:checked ~ label.TreeLabel {
				&:before {
					content: '–';
				}
			}
		}
		.input ~ ul {
			display: none;
		}
		li {
			line-height: 1.2;
			position: relative;
			display: inline-block;
			width: 100%;
			margin-top: 15px;
		}
		ul {
			li {
				padding: 0 0 0 44px;
				@media (max-width: 480px) {
					padding: 0 0 0 15px;
				}
			}
		}
		> li {
			&:last-child {
				padding-bottom: 0;
				&:before {
					display: none;
				}
			}
		}
	}
	.TreeLabel {
		position: relative;
		display: flex;
		align-items: center;
		background: ${({ theme }) => theme.colors.bg.menu};
		border: 1px solid ${({ theme }) => theme.colors.border.yellowDark};
		box-sizing: border-box;
		border-radius: 6px;
		min-height: 40px;
		padding: 10px;

		label {
			margin-left: auto;
			width: auto;
		}
		h1 {
			color: ${({ theme }) => theme.colors.text.SubHeading};
			font-family: 'OpenSansBold';
			font-size: 14px;
			margin: 0 10px;
		}
		h2 {
			color: ${({ theme }) => theme.colors.text.SubHeading};
			font-family: 'OpenSansSemiBold';
			font-size: 14px;
			margin: 0 10px;
		}
	}
	.TreeLabelSub {
		position: relative;
		display: flex;
		align-items: center;
		background: ${({ theme }) => theme.colors.bg.menu};
		border: 1px solid ${({ theme }) => theme.colors.border.yellowDark};
		box-sizing: border-box;
		border-radius: 6px;
		min-height: 40px;
		padding: 10px;

		label {
			margin-left: auto;
			width: auto;
		}
		h1 {
			color: ${({ theme }) => theme.colors.text.SubHeading};
			font-family: 'OpenSansBold';
			font-size: 14px;
			margin: 0 6px 0 10px;
		}
		h2 {
			color: ${({ theme }) => theme.colors.text.SubHeading};
			font-family: 'OpenSansSemiBold';
			font-size: 14px;
			margin: 0 10px;
		}
	}
	label.TreeLabel {
		cursor: pointer;
		&:hover {
			color: #666;
		}
		&:before {
			width: 24px;
			height: 24px;
			background: ${({ theme }) => theme.colors.bg.white};
			border: 1px solid ${({ theme }) => theme.colors.border.yellowDark};
			color: ${({ theme }) => theme.colors.border.yellowDark};
			position: relative;
			left: 0;
			z-index: 1;
			float: left;
			border-radius: 4px;
			content: '+';
			text-align: center;
			font-size: 24px;

			line-height: 0.8em;
			font-family: 'OpenSansBold';
		}
		&:after {
			border-bottom: 0;
		}
	}

	.tree_custom {
		display: block;
		background: #eee;
		padding: 1em;
		border-radius: 0.3em;
	}
`;
const Badge = styled.div`
	background: ${({ theme }) => theme.baseColors.white};
	font-size: 12px;
	line-height: 12px;
	font-family: 'OpenSansBold';
	color: ${({ theme }) => theme.colors.text.SubHeading};
	margin: 0;
	width: 27px;
	height: 16px;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 12px;
`;
const AddToFavorites = styled.div`
	display: flex;
	height: auto;
	position: relative;
	flex-direction: column;
	background: ${({ theme }) => theme.colors.bg.white};
	padding: 0 10px 12px;
`;

export default function BulkUploadModal({
	errorList,
	toggleBulkUploadModal
}): ReactElement {
	return (
		<AddToFavorites>
			<Text as="h1" color="text.textHeading" fontSize={18} fontFamily="OpenSansSemiBold">
				Error List
			</Text>
			<AccessList>
				<SimpleBar>
					<Box width={[1]}>
						{errorList
							.map((v) => {
								let id = helperFunctions.getRandomInt(pageConstants.maxValue);
								return <Box as="ul" key={helperFunctions.getRandomInt(
									pageConstants.maxValue
								)}>
									<Box as="li">
										<Input className="input" type="checkbox" id={`${id}`} />
										<Label htmlFor={`${id}`} className="TreeLabel">
											<Text as="h1">{v.name}</Text>
											<Badge style={{color:'red'}}>{v.errors.length}</Badge>
										</Label>
										{v.errors.map((v) => {
										return	<Box as="ul" key={helperFunctions.getRandomInt(
											pageConstants.maxValue
										)}>
												<Box as="li">
													<Label className="TreeLabelSub">
														<Text as="h2">{v}</Text>
													</Label>
												</Box>
											</Box>;
										})}
									</Box>
								</Box>;
							})}
					</Box>
				</SimpleBar>
			</AccessList>

			<Stack direction="x" gap={3} justifyContent="flex-end">
				<Button onClick={() => toggleBulkUploadModal()} flex={[1, 1, 'initial']}>
					Ok
				</Button>
			</Stack>
		</AddToFavorites>
	);
}
