import styled from 'styled-components';
const StyledProductMainDetails = styled.div`
	width: 100%;
	padding: 36px 0 0 0;
	margin: 30px 0 0 0;
	float: left;
	border-top: 1px solid ${({ theme }) => theme.colors.border.grayLightBorder01};
	@media (max-width: 860px) {
		width: calc(100% + 30px);
		padding: 30px 15px;
		margin: 30px 0 0 -15px;
	}
`;
const ViewAllHeadingBlock = styled.div`
	h1 {
		font-family: 'OpenSansBold';
		text-transform: uppercase;
		color: ${({ theme }) => theme.colors.text.black};
		line-height: normal;
	}
`;
const ProductMainDetailsHolder = styled.div`
	margin-top: 20px;
	@media (max-width: 860px) {
		div {
			max-height: max-content;
		}
	}
	article {
		margin: 0 0 10px 0;
		padding: 0;
		border-bottom: none;
		flex-direction: column;
		justify-content: flex-start;
		+ div {
			margin-top: 0;
		}
		div {
			display: flex;
			align-items: center;
			margin-bottom: 10px;
			flex: 1;
			width: 100%;
			&:last-child {
				margin-bottom: 0;
			}
			h2 {
				font-weight: normal;
				margin: 0;
				flex: 0 0 150px;
				margin-right: 20px;
				font-family: 'OpenSansMedium';
				position: relative;
				@media (max-width: 480px) {
					flex: 0 0 150px;
					margin-right: 20px;
				}
				&:after {
					content: ':';
					position: absolute;
					top: 0;
					right: 0;
				}
			}
			h3 {
				margin: 0;
				font-family: 'OpenSansBold';
				word-break: break-word;
				white-space: normal;
			}
			@media (max-width: 860px) {
				display: flex;
				h3 {
					margin-top: 0;
				}
				h2,
				h3 {
					font-size: 17px;
				}
			}
		}
	}
`;
export { StyledProductMainDetails, ViewAllHeadingBlock, ProductMainDetailsHolder };
