import React, { ReactNode } from 'react';
import { Text } from 'rebass/styled-components';
import styled from 'styled-components';

const QuantityWrapper = styled.div`
	position: relative;
	padding: 15px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #fff;
	border-radius: ${({ theme }) => theme.radii.softer};
	background: ${({ theme }) => theme.colors.primary};

	&::before {
		content: 'Free';
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);
		padding: 0.25em 0.5em;
		display: inline-block;
		font-family: 'OpenSansBold';
		font-size: 14px;
		line-height: 1;
		color: #fff;
		background: #ecb136;
		border-radius: 20px;
	}
`;

type ProductQuantity = {
	quantity?: string;
	icon?: ReactNode;
};

function ProductQuantity({ quantity, icon }: ProductQuantity) {
	return (
		<QuantityWrapper>
			{icon}
			<Text fontFamily="OpenSansBlack" lineHeight="1" mt={2} mb={1}>
				x{quantity}
			</Text>
			<Text color="rgba(255, 255, 255, 0.5)" lineHeight="1" fontSize="14px">
				Available
			</Text>
		</QuantityWrapper>
	);
}

export { ProductQuantity };
