import { UserBody } from 'admin/dashboardVenue/styles';
import { venueUserPermissionList } from 'models/enum-constants/common.constants';
import Iget from 'models/Iget';
import Ipost from 'models/Ipost';
import urlObj from 'models/url.obj';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';
import 'react-tippy/dist/tippy.css';
import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { Button, Loader, Search, SortBy } from 'ui/components';
import * as Icon from 'ui/icons';
import AppContext from 'utils/appContext';
import env from 'utils/constants';
import urls from 'utils/create.url';
import helperFunctions from 'utils/helper.functions';
import pageConstants from 'utils/pageConstants';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import utilityFunctions from 'utils/utility.functions';
import { Modal } from '../modal';
import NewFavoritesModal from '../recentOrderDetailsEditView/newFavorites';
const StyledOrderListView = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	column-gap: 20px;
	row-gap: 20px;
	article {
		flex-direction: row;
		background: ${({ theme }) => theme.colors.bg.white};
		border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
		box-sizing: border-box;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 16px;
		overflow: hidden;
		transition: box-shadow 100ms ease-in, transform 100ms ease-in;
		align-items: flex-start;
		height: auto;
		cursor: pointer;
		&:hover {
			transform: translateY(-5px);
			box-shadow: 0 20px 20px #eee;
			border: 1px solid ${({ theme }) => theme.baseColors.baseYellowLight};
		}
		&.disabled {
			opacity: 0.3;
			pointer-events: none;
		}
		span {
			max-width: 218px;
			flex: 0 0 218px;
			height: 224px;
			background-repeat: no-repeat;
			background-size: cover;
			@media (max-width: 1300px) {
				height: 100%;
			}
			@media (max-width: 768px) {
				max-width: 126px;
				flex: 0 0 126px;
			}
			@media (max-width: 480px) {
				max-width: 100px;
				flex: 0 0 100px;
			}
		}
		section {
			display: grid !important;
			grid-template-columns: repeat(6, 1fr) !important;
			height: 100%;
			padding: 25px;
			align-items: center;
			column-gap: 20px;
			div {
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				display: block;
			}
			@media (max-width: 1440px) {
				padding: 20px;
				grid-template-columns: repeat(4, 1fr) !important;
				row-gap: 20px;
			}
			@media (max-width: 960px) {
				padding: 20px;
				grid-template-columns: repeat(3, 1fr) !important;
				row-gap: 20px;
			}
			@media (max-width: 768px) {
				grid-template-columns: repeat(2, 1fr) !important;
			}
			@media (max-width: 480px) {
				grid-template-columns: repeat(1, 1fr) !important;
				slot {
					grid-column: 1 !important;
				}
			}
			h1 {
				span {
					max-width: fit-content;
					flex: 0 0 auto;
					height: auto;
					path {
						fill: ${({ theme }) => theme.baseColors.primary};
					}
				}
				button {
					padding: 0;
					background: transparent !important;
					cursor: pointer;
					margin: 0 0 0 10px;
					outline: none !important;
					box-shadow: none !important;
					border: none !important;
				}
			}
			.btn-block {
				display: flex;
				align-items: center;
				@media (max-width: 768px) {
					justify-content: flex-start;
				}
				span {
					height: auto;
					flex: 0 0 auto;
					max-width: auto;
					min-width: auto;
				}
				button {
					display: flex;
					align-items: center;
					text-transform: capitalize;
					margin-right: 15px;
					white-space: nowrap;
					@media (max-width: 480px) {
						font-size: 13px;
						padding-right: 10px;
						padding-left: 10px;
						white-space: nowrap;
						margin-right: 15px;
					}
					span {
						max-width: 100% !important;
					}
				}
				icon {
					display: inline-block;
				}
				div {
					word-break: break-word;
				}
			}
			slot {
				display: flex;
				flex-direction: column;
				h1 + div {
					word-break: break-word;
				}
				button {
					background: transparent !important;
				}
			}
			.totalProducts {
				max-width: 108px;
				text-align: right;
				white-space: nowrap;
				text-overflow: unset;
			}
		}
	}
`;

const PageSearchHolder = styled.div`
	width: 100%;
	padding-top: 15px;
	padding-bottom: 10px;
	display: flex;
	justify-content: space-between;
	div {
		width: 650px;
		@media screen and (max-width: 1200px) {
			width: 60%;
		}
		@media screen and (max-width: 860px) {
			width: 100%;
		}
		+ div {
			width: 254px;
			@media screen and (max-width: 860px) {
				width: 100%;
				margin-top: 10px;
			}
		}
	}
	@media screen and (max-width: 860px) {
		flex-direction: column;
	}
	@media screen and (max-width: 767px) {
		width: 100%;
	}
`;

function FavouriteOrderListView(): ReactElement {
	const [showNewFavoritesModal, setShowNewFavoritesModal] = useState(false);
	let history = useHistory();
	const ctx = useContext(AppContext).productSearchObj;
	const cartCtx = useContext(AppContext).cartObj;
	const [searchText, setSearchText] = useState('');
	const [url, setSearchUrl] = useState('');
	const [orderList, setOrderList] = useState([] as any);
	const [postObj, setObj] = useState({} as any);
	const [modalText, setmodalText] = useState('');
	const [showPopup, setShowModal] = useState(false);
	const [action, setAction] = useState('');
	const [favName, setFavName] = useState('');
	const [favId, setFavId] = useState(0);
	const [orderId, setOrderId] = useState('');
	const [inProgress, setInProgress] = useState(false);
	let urlObj: Partial<urlObj> = {
		url: urls.GetSearchUrl({
			url: 'experion/productmanagement/favorite/search?',
			fields: [
				{
					field_name: 'venue_user_id',
					field_value: localStorage.getItem('userId')!,
				},
			],
		}),
		pageSize: 2000,
		filterDeleted: true,
		ctx: ctx,
		fields: [
			{
				field_name: 'venue_id',
				field_value: localStorage.getItem('selectedVenue')!,
			},
			{
				field_name: 'venue_user_id',
				field_value: localStorage.getItem('userId')!,
			},
		],
	};

	const getSortData = (val) => {
		let obj = { field: '', direction: '' };
		switch (val) {
			case 'A - Z Order':
				obj.field = 'favorite_name';
				obj.direction = 'asc';
				break;
			case 'Z - A Order':
				obj.field = 'favorite_name';
				obj.direction = 'desc';
				break;
			default:
				break;
		}
		return obj;
	};

	const getKey = () => {
		return `favorite${ctx.SortField}${ctx.SortDirection}`;
	};

	const obj: Partial<Iget> = {
		url: urls.GetSearchUrl(urlObj),
		enabled: true,
		refetchOnWindowFocus: false,
		resultFilteringRequired: true,
		key: getKey(),
	};
	const qry = hooks.useQuery(obj);

	const handleSort = (val) => {
		let sortData = getSortData(val);
		ctx.SortDirection = sortData.direction;
		ctx.SortField = sortData.field;
		setSearchUrl(urls.GetSearchUrl(urlObj));
	};

	const setFilteredData = (val) => {
		setSearchText(val);
		let datas = qry.data;
		const orders = datas;
		if (val && val.length > 0) {
			var list = orders.filter((v) => {
				let suppliers = [] as any;
				v.favorite_items?.map((x) => {
					suppliers.push(x.supplier_name);
				});
				let supplierNames = suppliers?.join(',');
				return (
					supplierNames.toLowerCase().includes(val.toLowerCase()) ||
					v.favorite_name.toLowerCase().includes(val.toLowerCase()) ||
					String(v.favorite_items?.length).toLowerCase().includes(searchText.toLowerCase()) ||
					String('$' + parseFloat(v.total_cost).toFixed(2))
						.toLowerCase()
						.includes(val.toLowerCase())
				);
			});
			setOrderList(list);
		} else {
			setOrderList(datas);
		}
	};

	useEffect(() => {
		setOrderList(qry.data);
	}, [qry.data]);

	function confirm(e, action, id, qty = 0) {
		e.stopPropagation();
		setAction(action);
		switch (action) {
			case 'del':
				showConfirmationModal('Delete favorite?', id, qty);
				break;
			case 'placeOrder':
				showConfirmationModal('Move to cart ?', id, qty);
				break;
			default:
				break;
		}
	}
	function showConfirmationModal(text, id, qty) {
		setOrderId(id);
		setmodalText(text);
		setShowModal(!showPopup);
	}
	async function closePopup() {
		await setShowModal(!showPopup);
	}
	function onSubmit() {
		if (action == 'del') deleteOrder();
		else if (action == 'placeOrder') placeOrder();
	}

	function deleteOrder(hasMessage = true) {
		let obj1: Partial<Ipost> = {};
		obj1.message = !hasMessage ? ' ' : 'Order deleted successfully';
		obj1.url = `experion/productmanagement/deletefavoriteorder/${orderId}`;
		api.delete(obj1).then(function (d) {
			if (typeof d != 'undefined') {
				qry.refetch().then(function (data) {
					setOrderList(data);
				});
			}
		});
		if (hasMessage) setShowModal(!showPopup);
	}

	async function placeOrder() {
		let data = orderList?.find((x) => x.id == orderId);
		setShowModal(!showPopup);
		if (typeof data != 'undefined') {
			setInProgress(true);
			let items = [] as any;
			data?.favorite_items?.forEach((v, i) => {
				items.push({ product_id: v.product_id, qty: v.qty });
			});
			setObj(items);
			let id: any;
			let CUSTOMER_TOKEN = localStorage.getItem('customerToken');
			id = await utilityFunctions.getCartId(CUSTOMER_TOKEN);
			const payload = {
				products: {
					quote_id: parseInt(id),
					items: items,
				},
			};
			api
				.post({
					data: payload,
					url: env.multiCartURL(),
					key: 'placeOrder',
					message: 'Available products will be moved to cart!',
				})
				.then(function (data) {
					setInProgress(false);
					if (data == true) {
						cartCtx.stepperdCountUpdated = false;
						history.push('/store/cart');
					}
				});
		}
	}

	function editFavoriteName(e, name, id) {
		e.stopPropagation();
		setFavName(getFavName(name));
		setFavId(id);
		setShowNewFavoritesModal(!showNewFavoritesModal);
	}

	function updateList(name) {
		let fav = orderList.find((x) => x.id == favId);
		fav.favorite_name = `${getBaseName(fav.favorite_name)}${name}`;
		setOrderList(orderList);
	}

	function getBaseName(name) {
		if (name.includes('/')) {
			let n = name.lastIndexOf('/');
			return name.substring(0, n + 1);
		}
		return '';
	}

	function getFavName(name) {
		if (name.includes('/')) {
			let n = name.lastIndexOf('/');
			return name.substring(n + 1);
		}
		return name;
	}
	return (
		<StyledOrderListView>
			<PageSearchHolder>
				<Search onChange={(val) => setFilteredData(val)}></Search>
				<SortBy onSort={handleSort} removePriceSort={true}></SortBy>
			</PageSearchHolder>
			{!qry.isFetching && orderList?.length > 0 ? (
				orderList.map(({ id, favorite_name, total_cost, created_at, favorite_items }, i) => {
					let productCount = favorite_items?.length;
					let Suppliers = [] as any;
					favorite_items?.map((x) => {
						if (!Suppliers.includes(x.supplier_name)) Suppliers.push(x.supplier_name);
					});
					let supplierCount = Suppliers?.length;
					let SuppliersToDisplay = Suppliers.slice(0, 2).join(',').replace(/,\s*$/, '');

					return (
						<Flex
							onClick={() => history.push(`/store/favouriteOrderDetails?id=${id}`)}
							as="article"
							bg="white"
							sx={{ alignItems: 'center' }}
							key={helperFunctions.getRandomInt(pageConstants.maxValue)}
							// className="disabled"
						>
							{/* <Flex
							alignItems="center"
							as="span"
							maxWidth={['100%']}
							sx={{ overflow: 'hidden' }}
						></Flex> */}
							<Flex key={i} as="section" flexDirection="column" width="100%" maxWidth={['100%']}>
								<Box as="slot">
									<Text as="h1" fontSize={16} fontWeight="normal" color="text.black">
										Name
										{utilityFunctions.isGranted(venueUserPermissionList.FavoriteOrderEdit) && (
											<Button
												variant="ghost"
												onClick={(e) => editFavoriteName(e, favorite_name, id)}
											>
												<Icon.textEditIcon />
											</Button>
										)}
									</Text>
									<Text fontSize={18} fontWeight="bold" color="text.black">
										{favorite_name}
									</Text>
								</Box>
								{/* <Box>
								<Text fontSize={16} fontWeight="normal" color="text.black">
									Order ID
								</Text>
								<Text fontSize={18} fontWeight="bold" color="text.black">
									{123456}
								</Text>
							</Box> */}
								<Box className="totalProducts">
									<Text fontSize={16} fontWeight="normal" color="text.black">
										Total Products
									</Text>
									<Text fontSize={18} fontWeight="bold" color="text.black">
										{productCount}
									</Text>
								</Box>
								<Box>
									<Text fontSize={16} fontWeight="normal" color="text.black">
										Created
									</Text>
									<Text fontSize={18} fontWeight="bold" color="text.black">
										{helperFunctions.formatDate(created_at)}
									</Text>
								</Box>
								<Box>
									<Text fontSize={17} fontWeight="normal" color="text.black">
										Supplier
									</Text>
									<Text fontSize={20} fontWeight="bold" color="text.black">
										{SuppliersToDisplay}
										{supplierCount > 2 && <strong> & {supplierCount - 2} more</strong>}
									</Text>
								</Box>
								<Box>
									<Text fontSize={17} fontWeight="normal" color="text.black">
										Amount
									</Text>
									<Text fontSize={20} fontWeight="bold" color="text.black">
										{helperFunctions.formatAmount(total_cost)}
									</Text>
								</Box>
								<div className="btn-block">
									{utilityFunctions.isGranted(venueUserPermissionList.ManageProductsinCart) && (
										<Button
											disabled={productCount == 0}
											onClick={(e) => confirm(e, 'placeOrder', id, productCount)}
										>
											<Text as="h1" fontFamily="OpenSansBold" fontSize={18} color="text.white">
												Move To Cart
											</Text>
										</Button>
									)}
									{utilityFunctions.isGranted(venueUserPermissionList.FavoriteOrderDelete) && (
										<Button variant="ghost" onClick={(e) => confirm(e, 'del', id)}>
											<Icon.DeleteIcon />
										</Button>
									)}
								</div>
							</Flex>
						</Flex>
					);
				})
			) : !qry.isFetching ? (
				<UserBody>
					{/* <Icon.searchIcon title="No Data Found" /> */}
					<Text fontSize={18} fontWeight="bold" color="#5C5E57" textAlign="center">
						No Orders Found
					</Text>
				</UserBody>
			) : (
				new Array(2).fill(null).map((_, i) => {
					return <Skeleton key={i} width="1200px" height="50px" />;
				})
			)}

			<Modal
				width="500px"
				showModal={showNewFavoritesModal}
				setShowModal={setShowNewFavoritesModal}
			>
				<NewFavoritesModal
					updateList={updateList}
					showModal={showNewFavoritesModal}
					setShowModal={setShowNewFavoritesModal}
					favName={favName}
					favId={favId}
				/>
			</Modal>
			{inProgress && <Loader></Loader>}
			<SweetAlert
				show={showPopup}
				confirmBtnText="Confirm"
				onConfirm={onSubmit}
				showCancel={true}
				onCancel={closePopup}
				title={'Confirmation Box'}
			>
				{() => (
					<form>
						<hr />
						{modalText}
						<hr />
					</form>
				)}
			</SweetAlert>
		</StyledOrderListView>
	);
}

export { FavouriteOrderListView };
