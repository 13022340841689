import React, { ReactElement, useState } from 'react';
import { Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { Button, Stack } from 'ui/components';
import { FormInput } from 'ui/patterns';


const AddToFavorites = styled.div`
	display: flex;
	height: auto;
	position: relative;
	flex-direction: column;
	background: ${({ theme }) => theme.colors.bg.white};
	padding: 0 10px 12px;
	@media (max-width: 960px) {
		padding: 0;
	}
	h1 {
		margin: 0 0 41px 0;
		width: 100%;
		font-weight: normal;
		text-transform: uppercase;
	}
	div {
		margin-bottom: 25px;
		&:last-child {
			margin-bottom: 0;
			margin-top: 25px;
		}
	}
	button {
		padding: 0;
		width: 100%;
		min-height: 50px;
		text-transform: capitalize;
		font-size: 18px;
		margin: 0;
		font-family: 'OpenSansBold';
		margin-left: 20px;
		@media (max-width: 480px) {
			margin-left: 10px;
			font-size: 16px;
		}
		&:first-child {
			margin-left: 0;
		}
	}
`;

type Option = {
	cancel: () => void;
};
export default function AddNewAttributeModal({ cancel }: Option): ReactElement {
	const [addNewAttribute, setAddNewAttribute] = useState('');

	const handleOnChange = (e: any) => {
		setAddNewAttribute(e.target.id);
	};
	return (
		<AddToFavorites>
			<Text as="h1" color="text.textHeading" fontSize={18} fontFamily="OpenSansSemiBold">
				New Attribute
			</Text>
			<FormInput label="DC Name" />
			<FormInput label="DC Name" />

			<Stack direction="x" gap={3} justifyContent="flex-end">
				<Button flex={[1, 1, 'initial']} variant="secondary">
					Clear All
				</Button>
				<Button flex={[1, 1, 'initial']} variant="secondary">
					Delete
				</Button>
				<Button flex={[1, 1, 'initial']}>Save</Button>
			</Stack>
		</AddToFavorites>
	);
}
