import styled from 'styled-components';

const MainContent = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
	padding: 0 50px 30px;
	@media (max-width: 960px) {
		padding: 0 15px 15px;
	}
	table {
		thead,
		tbody {
			tr {
				td,
				th {
					h1 {
						max-width: 100% !important;
					}

					&:nth-child(3),
					&:nth-child(5) {
						flex: 0 0 135px;
						max-width: 135px;
					}
					&:nth-child(4) {
						flex: 0 0 160px;
						max-width: 160px;
					}
				}
			}
		}
		tbody{
			margin-bottom: 0!important;
		}
	}
`;
const ContentHolder = styled.div`
	height: 100%;
	padding: 0 50px;
	padding-bottom: 30px;
	width: 100%;

	@media (max-width: 768px) {
		padding: 0 15px;
	}
`;
const CardsAndNotificationHolder = styled.div`
	width: 100%;
	display: flex;
	@media (max-width: 860px) {
		flex-direction: column;
	}
`;
const TabContentHolder = styled.div`
	width: 100%;
	display: flex;
	margin-top: 50px;
`;
const BreadcrumbHolder = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media (max-width: 360px) {
		flex-direction: column;
		align-items: end;
		& > div {
			padding-bottom: 15px;
		}
	}
`;
const ButtonWrapper = styled.div`
	display: flex;
	@media (max-width: 480px) {
		padding-bottom: 0 !important;
	}
	button {
		display: flex !important;
		font-size: 18px;
		text-transform: capitalize;
		height: 50px;
		padding-top: 0;
		padding-bottom: 0;
		margin-left: 10px;
		@media (max-width: 767px) {
			width: 40px;
			height: 40px;
			padding: 0;
			align-items: center;
			justify-content: Center;
			div {
				display: none;
			}
			.pr-icon {
				margin-right: 0 !important;
			}
		}
		&:hover {
			svg {
				path {
					fill: ${({ theme }) => theme.colors.text.white};
				}
			}
		}
		span {
			display: flex;
			align-items: center;
		}
		+ button {
			margin-left: 15px;
		}
		.pr-icon {
			margin-right: 15px;
		}
	}
	a {
		text-decoration: none;
	}
	.custom-file-input::-webkit-file-upload-button {
		visibility: hidden;
	}
	.app-c-file-upload {
		position: relative;
		cursor: pointer;
		span {
			width: 100%;
			height: 100%;
		}
		input[type='file'] {
			position: absolute;
			left: 0;
			top: 0;
			opacity: 0;
			width: 100%;
			height: 100%;
		}
		.pr-button-label {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			div {
				display: flex;
			}
			h2 {
				width: calc(100% + 40px);
				font-size: 13px;
				font-family: 'OpenSansRegular';
				padding: 3px 0;
				margin: 0 -20px;
				max-width: 180px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}
	}
`;
export {
	MainContent,
	ContentHolder,
	CardsAndNotificationHolder,
	TabContentHolder,
	BreadcrumbHolder,
	ButtonWrapper,
};
