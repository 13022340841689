import { venueUserPermissionList } from 'models/enum-constants/common.constants';
import Iget from 'models/Iget';
import Ipost from 'models/Ipost';
import { NodataFound } from 'pages/ListingTIleView/styles';
import queryString from 'query-string';
import React, { ReactElement, useEffect, useState, useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Button, Image, Text } from 'rebass/styled-components';
import { Breadcrumb, Footer, Header, Loader } from 'ui/components';
import * as Icon from 'ui/icons';
import nodataImage from 'ui/images/Nodata.svg';
import constants from 'utils/constants';
import {
	AttributeMainDetails,
	Modal,
	OtherSuppliers,
	ProductAllergens,
	ProductDescription,
	ProductMainDetails,
	ProductMainHeading,
	ProductMainImage,
	ProductSubstitute,
	Sidebar,
	Stepper,
} from 'ui/patterns';
import urls from 'utils/create.url';
import http from 'utils/http.service';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import utilityFunctions from 'utils/utility.functions';
import AddToFavoritesModal from './addToFavorites';
import RemoveFromFavoritesModal from './removeFromFavorites';
import { UseGlobalState } from 'models/globalStateAccess';
import {
	BreadcrumbHolder,
	ContentHolder,
	ItemQuantityHolder,
	LefContentHolder,
	MainContent,
	OtherSuppliersHolderMobile,
	OtherSuppliersHolderWeb,
	ProductSubstituteBlock,
	RightContentHolder,
} from './styles';
import helperFunctions from 'utils/helper.functions';
import appContext from 'utils/appContext';
import AddToParkedOrderModal from './addToParkedOrder';
import notify from 'utils/toast.service';

function ProductDetails(): ReactElement {
	let ctx = useContext(appContext).cartObj;
	const [showAddToFavoritesModal, setShowAddToFavoritesModal] = useState(false);
	const [showRemoveFavoritesModal, setShowRemoveFavoritesModal] = useState(false);
	var [favoriteList, setFavoriteList] = useState<any>([]);
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const toggleAddToFavoritesModal = () => {
		fetch();
		setIsExist(false);
		setShowAddToFavoritesModal(!showAddToFavoritesModal);
	};
	const toggleRemoveFromFavoritesModal = () => {
		removeFetch();
		setShowRemoveFavoritesModal(!showRemoveFavoritesModal);
	};
	const [selectedFavourite, setSelectedFavourite] = useState('');
	const [validationData, SetValidationData] = useState('');
	var [pdtDetails, setPdtDetails] = useState<any>();
	const [stepperCount, setStepperCount] = useState('0');
	const key = 'cartKey';
	const [mutatePost] = hooks.useMutation(key);
	const location = useLocation();
	const parsed = queryString.parse(location.search); // get url param
	var [collectionName, setCollection] = useState<any>();
	const [venueUserId] = useState<any>(localStorage.getItem('userId'));
	const [venueId] = useState<any>(localStorage.getItem('selectedVenue'));
	const [userName, setUserName] = useState<any>('');
	const [isExist, setIsExist] = useState(false);
	const [isInvalid, setIsInvalid] = useState(false);
	var [assignedFavList, setAssignedFavList] = useState<any>([]);
	const [creditStatus, setCreditStatus] = useState('');
	var [categoryList, setCategoryList] = useState<any>('');
	const [attributeList, setAttributeList] = useState<any>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [totalAmt, setTotalCount] = useState(0);
	const saveFavKey = 'saveFavKey';
	var cartId = localStorage.getItem('cartId');
	const [state, dispatch] = UseGlobalState();
	const [showFavIcon, setShowFavIcon] = useState(true);
	var history = useHistory();
	const [showAddToParkedOrderModal, setShowAddToParkedOrderModal] = useState(false);
	const toggleAddToParkedOrderModal = () => {
		fetchParkedOrders();
	}

	var [parkedOrderList, setParkedOrderList] = useState<any>([]);


	// get all parkedorders api call
	const objPkd: Partial<Iget> = {
		params: {},
		url: 'experion-parkorder/get/' + venueId + '/' + venueUserId,
		enabled: false, //manual fetch
		key: 'ParkedordersKey',
		resultFilteringRequired: false,
	};
	var qryParkedOrders = hooks.useQuery(objPkd);

	const fetchParkedOrders = () => {
		setIsLoading(true);
		qryParkedOrders.refetch().then((data) => {
			if (data && data !== undefined) {
				parkedOrderList = [];
				data.forEach((item) => {
					if (item.is_delete === "0") {
						parkedOrderList.push(item);
						setParkedOrderList(() => [...parkedOrderList]);
					}
				});
				setIsLoading(false);
			} else {
				setIsLoading(false);
			}
			setShowAddToParkedOrderModal(!showAddToParkedOrderModal)
		});
	};

	// prod details api call
	const squ = parsed.id;
	const userKey = 'pdtDetailsKey' + squ;
	const url = 'products/' + squ;
	const obj: Partial<Iget> = {
		params: {},
		url: url,
		enabled: false,
		key: userKey,
		resultFilteringRequired: false,
	};
	var qry = hooks.useQuery(obj);
	useEffect(() => {
		qry.refetch();
	}, [parsed.id]);
	// remove fav Icon
	const removeFavUrl = constants.removeFavIcon() + venueId + '/' + venueUserId + '/' + pdtDetails?.id;
	const favObj: Partial<Iget> = {
		params: {},
		url: removeFavUrl,
		headers: { Authorization: localStorage.getItem('customerToken') },
		enabled: false,
		key: 'favIcon',
		resultFilteringRequired: false,
	};
	var favQry = hooks.useQuery(favObj);
	useEffect(() => {
		if (pdtDetails?.id) {
			setIsLoading(true);
			favQry.refetch().then(function (data) {
				if (data) {
					setShowFavIcon(true)
				}
				else
					setShowFavIcon(false)
				setIsLoading(false);
			})

		}
	}, [pdtDetails?.id])
	// get all favorites api call
	const obj1: Partial<Iget> = {
		params: {},
		url: urls.GetSearchUrl({
			url: 'experion/productmanagement/favorite/search?',
			fields: [
				{
					field_name: 'venue_user_id',
					field_value: venueUserId,
				},
				{ field_name: 'venue_id', field_value: localStorage.getItem('selectedVenue')! },
			],
		}),
		enabled: false, //manual fetch
		key: 'favKey',
		resultFilteringRequired: false,
	};
	var qryFav = hooks.useQuery(obj1);

	// get all favorites assigned to product call
	const obj2: Partial<Iget> = {
		params: {},
		url: 'experion/productmanagement/getfavoritelist/' + `${pdtDetails?.id}` + '/' + venueUserId,
		enabled: false, //manual fetch
		key: 'favRemoveKey',
		resultFilteringRequired: false,
	};
	var qryRemoveFav = hooks.useQuery(obj2);

	//api to get total amount
	const url1 = '/carts/' + cartId + '/totals';

	const obj6: Partial<Iget> = {
		params: {},
		url: url1,
		enabled: false, //manual fetch
		key: 'carttotalKey1',
		resultFilteringRequired: false,
	};
	var qryTotal = hooks.useQuery(obj6);

	useEffect(() => {
		qryTotal.refetch().then(function (data) {
			updateCartTotal(data);
		});
	}, []);

	useEffect(() => {
		if (qryTotal.data && !qryTotal.isFetching) updateCartTotal(qryTotal.data);
	}, [qryTotal.data]);

	const backListener = history.listen((location) => {
		if (location.pathname === '/store/cart') {
			ctx.stepperdCountUpdated = false;
		}
	});

	function updateCartTotal(data) {
		if (data) {
			let datas = data.items?.filter((x) => x.is_deleted != true);
			if (datas?.length > 0) {
				dispatch({ cartTotal: helperFunctions.formatAmount(data.grand_total) });
				dispatch({ cartItemCount: datas?.length });
				setTotalCount(data.subtotal);
			} else {
				dispatch({ cartItemCount: '0' });
				dispatch({ cartTotal: '' });
				setTotalCount(0);
			}
		} else {
			dispatch({ cartItemCount: '0' });
			dispatch({ cartTotal: '' });
			setTotalCount(0);
		}
	}

	useEffect(() => {
		if (qry.data) {
			api.get({ url: `experion/attributes/get/${qry.data?.id}` }).then(function (data) {
				setAttributeList(data);
			});
			setPdtDetails(qry.data);
			// prod view report api call
			productViewReports(qry.data.id);

			if (qry.data?.extension_attributes?.credit_status.length === 0) {
				setCreditStatus('go');
			} else {
				qry.data?.extension_attributes?.credit_status.forEach((obj) => {
					if (parseInt(venueId) === obj) {
						setCreditStatus('stop');
					}
				});
			}
			setStepperCount(qry.data?.extension_attributes.stock_item.min_sale_qty);
			if (qry.data?.custom_attributes !== undefined) {
				qry.data?.custom_attributes?.forEach((data) => {
					if (data.attribute_code === 'category_ids') {
						setCategoryList(data.value.join().toString());
					}
				});
			}
		}
	}, [qry.data]);

	const productViewReports = (id) => {
		api
			.post({
				data: { "productId": id },
				url: `experion/product/view`,
				key: userKey,
				message: ' ',
			})
			.then(function (data) {
				if (data == true) {

				}
			});
	}

	const callbackFunction = (childData) => {
		setSelectedFavourite(childData);
	};

	async function togglePopup() {
		await setShowAddToFavoritesModal(!showAddToFavoritesModal);
	}

	async function toggleRemovePopup() {
		await setShowRemoveFavoritesModal(!showRemoveFavoritesModal);
	}

	const fetch = () => {
		qryFav.refetch().then((data) => {
			if (data) {
				favoriteList = [];
				data.items.forEach((item) => {
					if (item.is_delete === false) {
						favoriteList.push(item);
						setFavoriteList(() => [...favoriteList]);
					}
				});
				// setError({ ...error, venueNameError: 'Venue Name already exists' });
			} else {
				// setError({ ...error, venueNameError: '' });
			}
		});
	};
	const removeFetch = () => {
		qryRemoveFav.refetch().then((data) => {
			if (data) {
				assignedFavList = [];
				data.forEach((item) => {
					if (item.is_delete === '0') {
						assignedFavList.push(item);
					}
				});
				setAssignedFavList(assignedFavList);
				// setError({ ...error, venueNameError: 'Venue Name already exists' });
			} else {
				// setError({ ...error, venueNameError: '' });
			}
		});
	};
	const updateStepperCount = (item) => {
		setStepperCount(item);
	};

	const fetchTotal = () => {
		qryTotal.refetch().then((data) => {
			if (data) {
				if (data?.items?.length > 0) setTotalCount(data?.grand_total);
				dispatch({ cartItemCount: data?.items.length });
			} else {
			}
		});
	};
	async function addItemsToCart() {
		ctx.stepperdCountUpdated = false;
		ctx.prodStepperCountUpdated = !ctx.prodStepperCountUpdated;
		setIsLoading(true);
		let id: any;
		let CUSTOMER_TOKEN = localStorage.getItem('customerToken');
		id = await utilityFunctions.getCartId(CUSTOMER_TOKEN);

		if (id !== undefined) {
			if (pdtDetails) {
				let addToCartJson = {
					cartItem: {
						sku: pdtDetails.sku,
						qty: stepperCount,
						quote_id: id,
					},
				};

				const obj: Partial<Ipost> = {
					data: addToCartJson,
					params: {},
					headers: { Authorization: `${CUSTOMER_TOKEN}` },
					url: 'carts/mine/items',
					message: 'Your cart has been updated!',
				};

				try {
					await api
						.post(obj)
						.then(function (data) {
							if (data !== undefined) {
								setIsLoading(false);
								fetchTotal();
							} else {
								setIsLoading(false);
							}
						})
						.catch(function (data) {
							setIsLoading(false);
						});
				} catch (e) {
					setIsLoading(false);
				}
			}
		} else {
			setIsLoading(false);
		}
	}

	function handleChange(e: any) {
		ctx.prodStepperCountUpdated = !ctx.prodStepperCountUpdated;
		if (
			parseInt(e.target.value) >=
			parseInt(pdtDetails?.extension_attributes.stock_item.min_sale_qty) &&
			parseInt(e.target.value) > 0 &&
			parseInt(e.target.value) <= parseInt(pdtDetails?.extension_attributes.stock_item.max_sale_qty)
		) {
			setStepperCount(e.target.value);
			SetValidationData('');
			setIsInvalid(false);
		} else if (
			parseInt(e.target.value) < parseInt(pdtDetails?.extension_attributes.stock_item.min_sale_qty)
		) {
			SetValidationData(
				'Min quantity is ' + pdtDetails?.extension_attributes.stock_item.min_sale_qty
			);
			setStepperCount(e.target.value);
			setIsInvalid(true);
		} else if (
			parseInt(e.target.value) > parseInt(pdtDetails?.extension_attributes.stock_item.max_sale_qty)
		) {
			SetValidationData(
				'Max quantity is ' + pdtDetails?.extension_attributes.stock_item.max_sale_qty
			);
			setStepperCount(e.target.value);
			setIsInvalid(true);
		} else if (e.target.value === '' || e.target.value === undefined) {
			SetValidationData(
				'Min quantity is ' + pdtDetails?.extension_attributes.stock_item.min_sale_qty
			);
			setStepperCount(e.target.value);
			setIsInvalid(true);
		}
	}

	const decreaseCount = () => {
		ctx.prodStepperCountUpdated = !ctx.prodStepperCountUpdated;
		if (parseInt(stepperCount) > 0) {
			if (
				parseInt(stepperCount) - 1 >= pdtDetails?.extension_attributes.stock_item.min_sale_qty &&
				parseInt(stepperCount) - 1 <= pdtDetails?.extension_attributes.stock_item.max_sale_qty
			) {
				setStepperCount((parseInt(stepperCount) - 1).toString());
				SetValidationData('');
				setIsInvalid(false);
			} else if (
				parseInt(stepperCount) - 1 <=
				pdtDetails?.extension_attributes.stock_item.min_sale_qty
			) {
				setStepperCount((parseInt(stepperCount) - 1).toString());
				SetValidationData(
					'Min quantity is ' + pdtDetails?.extension_attributes.stock_item.min_sale_qty
				);
				setIsInvalid(true);
			} else if (
				parseInt(stepperCount) - 1 >=
				pdtDetails?.extension_attributes.stock_item.max_sale_qty
			) {
				setStepperCount((parseInt(stepperCount) - 1).toString());
				SetValidationData(
					'Max quantity is ' + pdtDetails?.extension_attributes.stock_item.max_sale_qty
				);
				setIsInvalid(true);
			}
		}
	};
	const increaseCount = () => {
		ctx.prodStepperCountUpdated = !ctx.prodStepperCountUpdated;
		if (
			parseInt(stepperCount) + 1 <= pdtDetails?.extension_attributes.stock_item.max_sale_qty &&
			parseInt(stepperCount) + 1 >= pdtDetails?.extension_attributes.stock_item.min_sale_qty
		) {
			setStepperCount((parseInt(stepperCount) + 1).toString());
			SetValidationData('');
			setIsInvalid(false);
		} else if (
			parseInt(stepperCount) + 1 >=
			pdtDetails?.extension_attributes.stock_item.max_sale_qty
		) {
			//show validation
			SetValidationData(
				'Max quantity is ' + pdtDetails?.extension_attributes.stock_item.max_sale_qty
			);
			setIsInvalid(true);
			setStepperCount((parseInt(stepperCount) + 1).toString());
		} else if (
			parseInt(stepperCount) + 1 <=
			pdtDetails?.extension_attributes.stock_item.min_sale_qty
		) {
			//show validation
			SetValidationData(
				'Min quantity is ' + pdtDetails?.extension_attributes.stock_item.min_sale_qty
			);
			setIsInvalid(true);
			setStepperCount((parseInt(stepperCount) + 1).toString());
		}
	};

	function saveToCollection() {
		if (collectionName != undefined) {
			http
				.GET({ url: `/experion/productmanagement/favorite/${collectionName}/${venueUserId}` })
				.then(function (data) {
					//validate newly added favourite api call
					setIsExist(data.data);
					if (data.data === false) {
						if (!isExist) {
							let favJson = {
								favorite: {
									venue_user_id: venueUserId,
									favorite_name: collectionName,
									product_id: pdtDetails.id,
									created_by: userName,
									updated_by: userName,
									is_active: true,
									venue_id: venueId,
								},
							};

							const obj: Partial<Ipost> = {
								data: favJson,
								params: {},
								url: '/experion/productmanagement/favorite/create',
								message: 'saved to collection!',
							};
							try {
								mutatePost(obj).then((data) => {
									if (data !== undefined) {
										fetch();
									}
								});
							} catch (e) { }
						}
					}
				});
		}
	}

	useEffect(() => {
		setCollection(collectionName);
	}, [collectionName]);

	const validateNewCollection = (event) => {
		if (event.currentTarget.value.length > 0) {
			setCollection(event.currentTarget.value);
			setUserName(localStorage.getItem('userName'));
		}
	};

	const saveProductToFav = (value) => {
		let favJson = {
			favoriteOrder: {
				id: pdtDetails.id,
				venue_user_id: venueUserId,
				favorite_name: collectionName,
				created_by: userName,
				updated_by: userName,
				is_active: true,
				venue_id: venueId,
				is_delete: false,
				favorite_items: [
					{
						product_id: pdtDetails.id,
						qty: stepperCount,
						is_active: true,
						is_delete: false,
					},
				],
			},
		};

		const obj: Partial<Ipost> = {
			data: favJson,
			params: {},
			url: '/experion/productmanagement/favorite/updatefavorite/' + value,
			message: 'Added to favorites',
		};
		try {
			api.put(obj).then((data) => {
				if (data !== undefined) {
					toggleAddToFavoritesModal();
					setShowFavIcon(true);
				}
			});
		} catch (e) { }
	};

	const removeProductFromFav = (value) => {
		const obj: Partial<Ipost> = {
			params: {},
			url: 'experion/productmanagement/deletefavoritelistproduct/' + value + '/' + pdtDetails.id,
			message: 'Removed from favorites',
		};
		try {
			api.delete(obj).then((data) => {
				if (data !== undefined) {
					toggleRemoveFromFavoritesModal();
					favQry.refetch().then(function (data) {
						if (data) {
							setShowFavIcon(true)
						}
						else
							setShowFavIcon(false)
					})
				}
			});
		} catch (e) { }
	};

	let onVacation = false;

	const saveProductToParkedOrder = (parked_order_id, is_locked, locked_by, ParkedOrderName) => {

		let parkedJson = {
			userId: venueUserId,
			parkOrderItem: {
				qty: pdtDetails.extension_attributes.stock_item.min_sale_qty,
				price: pdtDetails.price,
				parked_order_id: parked_order_id,
				product_id: pdtDetails.id,
				sku: pdtDetails.sku,
			}
		};

		const obj: Partial<Ipost> = {
			data: parkedJson,
			params: {},
			url: '/experion-parkorder/parkorderitem',
			message: ' ',

		};
		try {
			api.post(obj).then((data) => {
				if (data !== undefined) {
					toggleAddToParkedOrderModal();
					notify(pdtDetails.name + ' added to ' + ParkedOrderName).error();
				}

			});
		} catch (e) { }
	};
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	}
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={() => onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={() => onclickHambrgBtn()}></Sidebar>
			{qry.data === undefined || isLoading ? <Loader></Loader> : ''}
			{(qry.data && qry.data?.extension_attributes?.is_delete == 0 && (
				<MainContent>
					{qry.data && qry.data === undefined ? (
						<Skeleton width="300px" height="30px"></Skeleton>
					) : (
						<>
							{/* Top Breadcrumb */}
							<BreadcrumbHolder>
								<Breadcrumb
									Heading="Product Details"
									HomePage="Home"
									previousPage="Products"
									previousPageSecond={
										qry.data && qry.data.extension_attributes.productcatagory_name[0]
									}
									currentPage={qry.data?.name}
									type="product-details"
								></Breadcrumb>
							</BreadcrumbHolder>
							<ContentHolder>
								{/* Left Image Session  */}
								<LefContentHolder>
									{/* Product Images */}
									<ProductMainImage productDetails={pdtDetails}></ProductMainImage>

									<OtherSuppliersHolderWeb>
										{/* Product Other Suppliers */}
										<OtherSuppliers
											categoryList={categoryList}
											productDetails={pdtDetails}
										></OtherSuppliers>
									</OtherSuppliersHolderWeb>
								</LefContentHolder>

								{/* Right Product Detail Session */}
								<RightContentHolder>
									{/* Product Main Heading */}
									<ProductMainHeading
										updateStepperCount={updateStepperCount}
										productDetails={pdtDetails}
										validationData={SetValidationData}
									></ProductMainHeading>
									<ItemQuantityHolder>
										{/* Stepper */}
										<Box className="Stepper">
											<Stepper
												stepperCount={stepperCount}
												active={creditStatus === 'stop' ? false : true}
												creditStatus={[]}
												validationData={validationData}
												index={null}
												decreaseCount={decreaseCount}
												handleChange={handleChange}
												increaseCount={increaseCount}
												isCartUpdated={true}
												stopEvent={''}
											/>
										</Box>
										{utilityFunctions.isGranted(venueUserPermissionList.ManageProductsinCart) && (
											<Button
												backgroundColor="#64A2F5"
												onClick={addItemsToCart}
												disabled={isInvalid || creditStatus === 'stop' || onVacation}
											>
												<Icon.cartIcon title="cartIcon" />
												<Text as="h1" fontFamily="OpenSansBold" fontSize={18} color="text.white">
													Add To Cart
												</Text>
											</Button>
										)}

										{/* Add "active" class for button active state */}
										{utilityFunctions.isGranted(venueUserPermissionList.FavoriteOrderAdd) && !showFavIcon && (
											<Button
												variant="outline"
												className="Favorites"
												onClick={toggleAddToFavoritesModal}
											>
												<Icon.FavoritesIcon title="FavoritesIcon" />
											</Button>
										)}
										{utilityFunctions.isGranted(venueUserPermissionList.FavoriteOrderDelete) && showFavIcon && (

											<Button
												variant="outline"
												className="FavoritesRemove"
												onClick={toggleRemoveFromFavoritesModal}
											>
												<Icon.removeFavIcon title="FavoritesRemoveIcon" />
											</Button>
										)}
										<Button
											variant="outline"
											className="Favorites"
											onClick={toggleAddToParkedOrderModal}
										>
											<Icon.parkedOrderIcon title="FavoritesIcon" />
										</Button>
									</ItemQuantityHolder>

									{/* Product Details */}
									<ProductMainDetails productDetails={pdtDetails}></ProductMainDetails>

									{/* Product Description */}
									<ProductDescription productDetails={pdtDetails}></ProductDescription>

									{/* Product Allergens */}
									{/* <ProductAllergens productAllegens={pdtDetails}></ProductAllergens> */}
									<OtherSuppliersHolderMobile>
										<OtherSuppliers
											categoryList={categoryList}
											productDetails={pdtDetails}
										></OtherSuppliers>
									</OtherSuppliersHolderMobile>
									{/* Product Review */}
									{/* <ProductReview></ProductReview> */}

									{/* Attribute Details */}
									<AttributeMainDetails data={attributeList}></AttributeMainDetails>
								</RightContentHolder>
							</ContentHolder>
							<ProductSubstituteBlock>
								{/* Product Substitute */}
								<ProductSubstitute substitution={pdtDetails?.product_links}></ProductSubstitute>
							</ProductSubstituteBlock>
						</>
					)}
				</MainContent>
			)) ||
				(qry.data && qry.data?.extension_attributes?.is_delete == 1 ? (
					<NodataFound>
						<Box>
							<Image
								src={nodataImage}
								sx={{
									width: 130,
									height: 130,
								}}
							/>
							<Text as="h1" fontFamily="OpenSansBold" fontSize={18} color="text.black">
								No details found
							</Text>
						</Box>
					</NodataFound>
				) : (
					''
				))}

			{/* Footer Session */}
			<Footer></Footer>

			<Modal
				width="600px"
				showModal={showAddToFavoritesModal}
				setShowModal={setShowAddToFavoritesModal}
			>
				<AddToFavoritesModal
					isExist={isExist}
					validateNewCollection={validateNewCollection}
					saveToCollection={saveToCollection}
					favoriteList={favoriteList}
					parentCallback={callbackFunction}
					togglePopup={togglePopup}
					saveProductToFav={saveProductToFav}
				/>
			</Modal>
			<Modal
				width="600px"
				showModal={showRemoveFavoritesModal}
				setShowModal={setShowRemoveFavoritesModal}
			>
				<RemoveFromFavoritesModal
					favoriteRemoveList={assignedFavList}
					togglePopup={toggleRemovePopup}
					removeProductFromFav={removeProductFromFav}
				/>
			</Modal>
			<Modal
				width="600px"
				showModal={showAddToParkedOrderModal}
				setShowModal={setShowAddToParkedOrderModal}
			>
				<AddToParkedOrderModal
					parkedOrderList={parkedOrderList}
					togglePopup={toggleRemovePopup}
					saveProductToParkedOrder={saveProductToParkedOrder}
				/>
			</Modal>
		</>
	);
}

export default ProductDetails;
