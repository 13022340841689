import { Input, Label } from '@rebass/forms';
import rolePermissions from 'models/rolePermission.model';
import React, { ReactElement } from 'react';
import { Text } from 'rebass/styled-components';
import { RadioButton } from 'ui/components';
import * as Icon from 'ui/icons';
import { Badge, RadioBtnHolder } from './styles';

function BindNode({ obj, count, handleOnChange }): ReactElement {
	let data: rolePermissions = obj;
	return (
		<>
			<Input className= 'input' type="checkbox" id={`chk_${data.id}`} />
			<Label className={`${count > 0 ? 'TreeLabel' : 'TreeLabelSub'} ${data.parent_active ? 'active' : ''}`} htmlFor={`chk_${data.id}`}>
				<Text as="h1">{data.permission_name}</Text>
				{count > 0 && <Badge>{count}</Badge>}
				<RadioBtnHolder>
					<RadioButton className = {`${data.is_granted ? 'active_tick':''}`} id={String(data.id)} checked={data.is_granted} onChange={handleOnChange}>
						{data.is_granted ? <Icon.Tick /> : ''}
					</RadioButton>
				</RadioBtnHolder>
			</Label>
		</>
	);
}

export default BindNode;
