import { Select } from '@rebass/forms';
import { condition } from 'models/enum-constants/common.constants';
import Iget from 'models/Iget';
import Ipost from 'models/Ipost';
import VenueRole from 'models/pageObjects/venue.roles';
import queryString from 'query-string';
import React, { ReactElement, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Text } from 'rebass/styled-components';
import { BreadcrumbNav, Button, Footer, Grid, Header, Stack } from 'ui/components';
import * as Icon from 'ui/icons';
import { Sidebar } from 'ui/patterns';
import urls from 'utils/create.url';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import { AddUserHolder, FooterBtnBlock, MainContent, ProfileDetails } from './styles';
function AddExistingUserVenue(): ReactElement {
	const location = useLocation() as any;
	const history = useHistory();
	const parsed = queryString.parse(location.search);
	const [showPopup, setShowModal] = useState(false);
	const [action, setAction] = useState('');
	const [modalText, setmodalText] = useState('');
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const venueUserUrl = urls.GetSearchUrl({
		url: 'experion/venueuser/search?',
		pageSize: 10,
		filterDeleted: true,
		fields: [
			{
				field_name: 'hg_id',
				field_value: localStorage.getItem('hospitalityId')!,
			},
			{
				field_name: 'magento_user_id',
				field_value: localStorage.getItem('hgAdminId')!,
				field_conditionType: condition.neq,
			},
		],
	});

	const venueObj: Partial<Iget> = {
		params: {},
		url: venueUserUrl,
		enabled: true,
		key: 'userKey',
		resultFilteringRequired: true,
	};

	const venueQry = hooks.useQuery(venueObj);

	const url = urls.GetSearchUrl({
		url: 'experion/venueuserrole/search?',
		filterDeleted: true,
		ctx: { currentPage: 1 },
		fields: [
			{
				field_name: 'hg_admin_Id',
				field_value: localStorage.getItem('hospitalityId')!,
			},
		],
	});

	const roleObj: Partial<Iget> = {
		params: {},
		url: url,
		enabled: true,
		key: 'rolesList',
		resultFilteringRequired: true,
	};
	const roleQry = hooks.useQuery(roleObj);
	useEffect(() => {
		roleQry.data && setRoleList([...roleQry.data]);
	}, [roleQry.data]);

	const [assignedUser, setAssignedUser] = useState<VenueRole>({
		venue: { key: 0, value: '' },
		role: { key: 0, value: '' },
	});
	const breadcrumbNav = [
		{ id: '01', label: 'Home', route: '/home/dashboard', active: false },
		{ id: '02', label: 'Dashboard', route: '/admin/user-dashboard', active: false },
		{ id: '03', label: 'Add Existing User', active: true },
	];
	const [isLoading, setIsLoading] = useState(false);
	const [venueUserList, updateUserList] = useState([] as any);
	const [roleList, setRoleList] = useState([] as any);
	const [error, setError] = useState({
		venueUserError: '',
		userRoleError: '',
	});
	const [userDetails, setUserDetails] = useState({
		first_name: '',
		last_name: '',
		phone: '',
		venue_user_email: '',
		id: 0,
		venue_user_role_mapping: [] as any,
		is_active: true,
		hg_id: localStorage.getItem('hospitalityId'),
	});

	useEffect(() => {
		const userList = [] as any;
		if (venueQry.data) {
			venueQry.data.map((d) => {
				if (d.venue_user_role_mapping && d.venue_user_role_mapping.length > 0) {
					d.venue_user_role_mapping.map((item) => {
						if (item.venue_id != parsed.id) {
							userList.push(d);
						}
					});
				}
			});
			updateUserList([...userList]);
		}
	}, [parsed.id, venueQry.data]);

	const cancel = () => {
		history.push({
			pathname: '/admin/dashboard-store',
			search: '?id=' + parsed.id,
		});
	};

	const onSelectDropdwn = (e, name) => {
		const data: VenueRole = JSON.parse(JSON.stringify(assignedUser));
		switch (name) {
			case 'venueUser': {
				if (!e.target.value) {
					setError({ ...error, venueUserError: 'Store user cannot be blank' });
				} else {
					setError({ ...error, venueUserError: '' });
				}

				if (e.target.value != '') {
					data.venue.key = parseInt(e.target.value);
					setUserDetails(venueUserList[venueUserList.findIndex((x) => x.id == e.target.value)]);
				}
				break;
			}
			case 'userRole': {
				if (!e.target.value) {
					setError({ ...error, userRoleError: 'User role cannot be blank' });
				} else {
					setError({ ...error, userRoleError: '' });
				}
				if (data) {
					data.role.value = e.target.value;
					if (e.target.value != '')
						data.role.key = parseInt(
							roleList[roleList.findIndex((x) => x.role_name == e.target.value)].id
						);
				}
				break;
			}
		}
		setAssignedUser(JSON.parse(JSON.stringify(data)));
	};

	const saveUser = async () => {
		setIsLoading(true);
		const user = {
			id: assignedUser.venue.key,
			venue_user_role_mapping: [
				{
					venue_id: parseInt(parsed.id),
					role_id: assignedUser.role.key,
					is_active: true,
				},
			],
			first_name: userDetails.first_name,
			last_name: userDetails.last_name,
			venue_user_email: userDetails.venue_user_email,
			magento_user_id: 0,
			phone: userDetails.phone,
			is_active: true,
			hg_id: userDetails.hg_id,
		};

		const obj: Partial<Ipost> = {
			data: { venueUser: user },
			params: {},
			url: `experion/venueuser/update/${user.id}`,
			message: `User updated  successfully`,
		};
		try {
			await api.put(obj).then(function (data) {
				if (typeof data != 'undefined')
					history.push({
						pathname: '/admin/dashboard-store',
						search: '?id=' + parsed.id,
					});
			});
			setIsLoading(false);
		} catch (e) {
			setIsLoading(false);
		}
	};

	//popup

	function confirmPopup(action) {
		switch (action) {
			case 'cancel':
				showConfirmationModal(action, 'Are you sure you want to cancel ?');
				break;
			case 'Save':
				saveUser();
				break;
			default:
				break;
		}
	}
	async function closePopup() {
		await setShowModal(!showPopup);
	}
	function showConfirmationModal(action, text) {
		setAction(action);
		setmodalText(text);
		setShowModal(!showPopup);
	}
	async function onSubmit() {
		if (action === 'cancel') {
			cancel();
		} else if (action == 'Save') {
			saveUser();
		}
		setShowModal(!showPopup);
	}
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	};
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={() => onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={() => onclickHambrgBtn()}></Sidebar>
			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbNav Heading="Add Existing User" breadcrumbList={breadcrumbNav} />
				{/* Add User Details */}
				<AddUserHolder>
					<ProfileDetails>
						<Text as="h1" fontSize={18} fontFamily="OpenSansBold" color="text.SubHeading">
							Assign User & Role{' '}
						</Text>
						<Box as="form">
							<Grid gap={4}>
								<Stack categorySelect gap={0}>
									<div>
										<Select onChange={(e) => onSelectDropdwn(e, 'venueUser')}>
											<option value="">-Select User-</option>
											{venueUserList &&
												venueUserList.map((option, index) => (
													<option key={index} value={option.id}>
														{option.first_name}
													</option>
												))}
										</Select>
										<Icon.downArrow />
									</div>
									<span style={{ fontSize: 12, color: 'red' }}>{error.venueUserError}</span>
								</Stack>
								<Stack categorySelect gap={0}>
									<div>
										<Select onChange={(e) => onSelectDropdwn(e, 'userRole')}>
											<option value="">-Select Role-</option>
											{roleList &&
												roleList.map((option, index) => (
													<option key={index}>{option.role_name}</option>
												))}
										</Select>
										<Icon.downArrow />
									</div>
									<span style={{ fontSize: 12, color: 'red' }}>{error.userRoleError}</span>
								</Stack>
							</Grid>
						</Box>
					</ProfileDetails>

					{/* Footer Btn Block */}
					<FooterBtnBlock>
						<Stack direction="x" gap={3} justifyContent={['center', 'flex-end']}>
							<Button
								flex={[1, 1, 'initial']}
								variant="secondary"
								onClick={() => confirmPopup('cancel')}
							>
								Cancel
							</Button>
							<Button
								flex={[1, 1, 'initial']}
								onClick={() => confirmPopup('Save')}
								disabled={
									!assignedUser.venue.key ||
									!assignedUser.role.key ||
									error.userRoleError != '' ||
									error.venueUserError != '' ||
									isLoading == true
								}
							>
								Save
							</Button>
						</Stack>
					</FooterBtnBlock>
				</AddUserHolder>
				<SweetAlert
					show={showPopup}
					confirmBtnText="Confirm"
					onConfirm={onSubmit}
					showCancel={true}
					onCancel={closePopup}
					title={'Confirmation Box'}
				>
					{() => <form>{modalText}</form>}
				</SweetAlert>
			</MainContent>
			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default AddExistingUserVenue;
