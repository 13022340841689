import styled from 'styled-components';

const AddToFavorites = styled.div`
	display: flex;
	height: auto;
	position: relative;
	flex-direction: column;
	background: ${({ theme }) => theme.colors.bg.white};
	padding: 0 10px;
	@media (max-width: 960px) {
		padding: 0;
	}
	h1,
	p {
		margin: 0;
		width: 100%;
		font-weight: normal;
	}
	button {
		padding: 0;
		min-width: 100px;
		height: 48px;
		text-transform: capitalize;
		font-size: 18px;
	}
	button {
		padding: 0 20px;
	}
	.parkedOrderLimit{
		padding-bottom: 10px;
		margin-top: -20px;
	}
`;
const ParkedOrderLimitReached = styled.div`
	width: 100%;
	margin-bottom: 46px;
	margin-top: 20px;
	@media (max-width: 960px) {
		min-height: calc(100vh - 350px);
	}
	label {
		width: 100%;
		border-bottom: 1px solid ${({ theme }) => theme.colors.border.grayLightBorder01};
		padding: 22px 0 20px !important;
		&:before{
			opacity:0!important;
			position:absolute!important;
		}
	}
	
	h1 {
		margin: -3px 0 0 5px;
		font-size: 20px;
		line-height: 26px;
		font-weight: normal;
		font-family: 'OpenSansRegular';
		padding: 0 !important;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	input[type='checkbox']:checked + label h1 {
		font-family: 'OpenSansBold';
		color: rgba(125, 55, 111, 1);
		&:after {
			content: '';
			background: url(${require('../../../images/tick.svg')});
			width: 15px;
			height: 12px;
			width: 15px;
			height: 12px;
			flex: 0 0 15px;
			max-width: 15px;
			display: block;
			position: absolute;
			top: 7px;
			right: 0;
		}
	}

	input[type='checkbox'] {
		position: absolute;
		margin: 0 !important;
		padding: 0 !important;
		opacity: 0;
		height: 0;
		width: 0;
		pointer-events: none;
		font-size: 14px;

		& + label {
			display: inline-flex;
			align-items: flex-start;
			line-height: var(--checkbox-radio-line-height);
			user-select: none;
			cursor: pointer;
			color: #2f363d;
			&:before{
				opacity:0!important;
				position:absolute!important;
			}
		}

		& + label::before {
			content: '';
			display: inline-block;
			position: relative;
			flex-shrink: 0;
			width: var(--checkbox-radio-size);
			height: var(--checkbox-radio-size);
			background-color: var(--checkbox-bg);
			border-width: var(--checkbox-radio-border-width);
			border-color: var(--checkbox-border);
			border-style: solid;
			background-repeat: no-repeat;
			background-position: center;
			margin-right: var(--checkbox-radio-gap);
			transition: transform 0.2s, border 0.2s;
		}

		&:not(:checked):not(:focus):not(:disabled) + label:hover::before {
			border-color: ${({ theme }) => theme.colors.border.yellowDark};
		}

		& + label::before {
			border-radius: var(--checkbox-radius);
		}

		&:checked + label::before {
			background-color: var(--checkbox-marker-color);
			box-shadow: none;
			border-color: var(--checkbox-marker-color);
			transition: transform 0.2s;
		}

		&:active:not(:disabled) + label::before {
			transform: scale(0.8);
			transition: transform 0.2s;
		}

		&:checked:active + label::before {
			transform: none;
			transition: none;
		}

		&:checked + label::before {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpolyline points='1 6.5 4 9.5 11 2.5' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E");
			background-size: var(--checkbox-marker-size);
		}

		&:checked:active + label::before,
		&:focus + label::before {
			border-color: ${({ theme }) => theme.colors.border.yellowDark};
			box-shadow: 0 0 0 3px hsla(var(--primary-color-hsl), 30%);
		}

		&:disabled + label {
			opacity: 0.5;
			cursor: not-allowed;
		}
	}
	.scroll-block {
		max-height: calc(100vh - 350px);
	}
`;

export { AddToFavorites, ParkedOrderLimitReached };
