import { UserBody } from 'admin/dashboardVenue/styles';
import { venueUserPermissionList } from 'models/enum-constants/common.constants';
import Iget from 'models/Iget';
import Ipost from 'models/Ipost';
import React, { ReactElement, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory } from 'react-router-dom';
import { Box, Text } from 'rebass/styled-components';
import {
	BreadcrumbNav,
	Button,
	Footer,
	Header,
	Loader,
	PageSearchHolder,
	Search,
	TableList,
} from 'ui/components';
import icons from 'ui/icons';
import { Sidebar } from 'ui/patterns';
import urls from 'utils/create.url';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import utilityFunctions from 'utils/utility.functions';
import { BreadcrumbHolder, ButtonWrapper, MainContent, PageSearchHolderBlock } from './styles';

function UserRoleManagement(): ReactElement {
	const history = useHistory();
	const breadcrumbNav = [
		{ id: '01', label: 'Dashboard', route: '/admin/user-dashboard', active: false },

		{ id: '02', label: 'User Role Management', active: true },
	];
	const params = {};
	const [roleList, updateRoleList] = useState<any>([]);
	const [id, setId] = useState(0);
	const [modalText, setmodalText] = useState('');
	const [showPopup, setShowModal] = useState(false);
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const obj1: Partial<Ipost> = {
		params: {},
		url: '',
	};
	const roleKey = 'rolesList';
	const url = urls.GetSearchUrl({
		url: 'experion/venueuserrole/search?',
		ctx: { currentPage: 1 },
		fields: [{ field_name: 'hg_admin_Id', field_value: localStorage.getItem('hospitalityId')! }],
	});

	const obj: Partial<Iget> = {
		params: params,
		url: url,
		enabled: true,
		key: roleKey,
		resultFilteringRequired: true,
	};
	const qry = hooks.useQuery(obj);
	useEffect(() => {
		qry.data && updateRoleList([...qry.data]);
	}, [qry.data]);

	const setFilteredData = (val) => {
		const sampleList = qry.data;
		if (val && val.length > 0) {
			const list = sampleList.filter(
				(v) =>
					v.role_name.toLowerCase().includes(val.toLowerCase()) ||
					v.description.toLowerCase().includes(val.toLowerCase())
			);
			updateRoleList(list);
		} else {
			updateRoleList(qry.data);
		}
	};
	const addUsers = () => {
		history.push('user-role');
	};
	const navigateToEdit = (id) => {
		history.push({
			pathname: 'user-role',
			search: '?id=' + id,
		});
	};
	function confirm(id) {
		showConfirmationModal('Delete role?', id);
	}
	function showConfirmationModal(text, id) {
		setId(id);
		setmodalText(text);
		setShowModal(!showPopup);
	}
	function deleteRole() {
		obj1.url = `experion/venueuserrole/delete/${id}`;
		obj1.data = { id: id };
		obj1.message = 'Role deleted successfully';
		api.put(obj1).then(function (d) {
			if (typeof d != 'undefined') {
				qry.refetch().then(function (data) {
					updateRoleList(data);
				});
			}
		});
		setShowModal(!showPopup);
	}
	async function closePopup() {
		await setShowModal(!showPopup);
	}
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	};
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={() => onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={() => onclickHambrgBtn()}></Sidebar>
			{qry.data == null ? <Loader></Loader> : null}

			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbHolder>
					<BreadcrumbNav Heading="User Role Management " breadcrumbList={breadcrumbNav} />
					{utilityFunctions.isGranted(venueUserPermissionList.UserRoleManagementAdd) && (
						<ButtonWrapper>
							<Button flex={[1, 1, 'initial']} onClick={addUsers}>
								<icons.PlusIcon />
								<div>Add new Role</div>
							</Button>
						</ButtonWrapper>
					)}
				</BreadcrumbHolder>
				<TableList>
					<PageSearchHolderBlock>
						<PageSearchHolder>
							<Box as="section">
								<Search
									onChange={(val) => setFilteredData(val)}
									type={'roleList'}
									placeholder="Search for Role, Description..."
								></Search>
							</Box>
							{/* <Box as="small">
								<Button flex={[1, 1, 'initial']} variant="redOutline">
									<icons.DeleteIcon />
									<div>Delete Selected</div>
								</Button>
							</Box> */}
						</PageSearchHolder>
					</PageSearchHolderBlock>
					<table>
						<thead>
							<tr>
								<th>Role</th>
								<th>Description</th>
								<th className="action">Action</th>
							</tr>
						</thead>

						<tbody>
							{(roleList &&
								roleList.length > 0 &&
								roleList
									.filter((x) => x.is_delete !== true)
									.map(({ role_name, description, id }, index) => (
										<tr key={index}>
											<td data-label="Role">
												<Text fontSize={15} fontFamily="OpenSansBlack" color="text.black">
													<span>{role_name}</span>
												</Text>
											</td>
											<td data-label="Description">
												<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
													{description}
												</Text>
											</td>

											<td className="action" data-label="Action">
												<Text>
													{utilityFunctions.isGranted(
														venueUserPermissionList.UserRoleManagementDelete
													) && (
														<Button
															variant="deleteIcon"
															disabled={!id ? true : false}
															onClick={() => confirm(id)}
														>
															<icons.DeleteIcon />
														</Button>
													)}
													{utilityFunctions.isGranted(
														venueUserPermissionList.UserRoleManagementEdit
													) && (
														<Button variant="editIcon" onClick={() => navigateToEdit(id)}>
															<icons.textEditIcon />
														</Button>
													)}
												</Text>
											</td>
										</tr>
									))) ||
								(!qry.isFetching && (
									<UserBody>
										<Button>
											<icons.userRoll title="User Icon" />
										</Button>
										<Text fontSize={18} fontWeight="bold" color="#5C5E57" textAlign="center">
											No User role Found
										</Text>
									</UserBody>
								))}
						</tbody>
					</table>
				</TableList>
			</MainContent>
			<SweetAlert
				show={showPopup}
				confirmBtnText="Confirm"
				onConfirm={deleteRole}
				showCancel={true}
				onCancel={closePopup}
				title={'Confirmation Box'}
				//  dependencies={[this.state.firstName, this.state.lastName]}
			>
				{() => (
					<form>
						<hr />
						{modalText}
						<hr />
					</form>
				)}
			</SweetAlert>
			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default UserRoleManagement;
