import urlObj from 'models/url.obj';
import queryString from 'query-string';
import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useLocation } from 'react-router-dom';
import { Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { BreadcrumbNav, Button, Footer, Header, Loader } from 'ui/components';
import { OrdersDetailsListView, ParkedOrderDetailsEditView, Sidebar } from 'ui/patterns';
import urls from 'utils/create.url';
import { default as helper, default as helperFunctions } from 'utils/helper.functions';
import http from 'utils/http.service';
import pageConstants from 'utils/pageConstants';
import SweetAlert from 'react-bootstrap-sweetalert';
import Ipost from 'models/Ipost';
import api from 'utils/requestCtx/api.service';
import { error } from 'console';
const MainContent = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
	padding: 0 50px;
	@media (max-width: 960px) {
		padding: 0 15px;
	}
`;
const OrderDetailsBlock = styled.div`
	margin-bottom: 50px;
	h4 {
		margin: 40px 0 10px;
	}
	h5 {
		margin-bottom: 10px;
	}
`;
function ParkedOrderDetails(): ReactElement {
	let location = useLocation();
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const [orders, setOrders] = useState([] as any);
	const [products, setProducts] = useState([] as any);
	const [hasData, setHasData] = useState(false);
	const parsed = queryString.parse(location.search);
	const [productsBySuppliers, setSupplierGroup] = useState([] as any);
	const [editedProducts, setEditedProducts] = useState([] as any);
	const [count, setProductCount] = useState(0);
	const [stepperCount, setStepperCount] = useState(new Array(products?.length));
	const [validationData, SetValidationData] = useState(new Array(products?.length));
	const [modalText, setmodalText] = useState('');
	const [showPopup, setShowModal] = useState(false);
	const [action, setAction] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [showCounter, setShowCounter] = useState(false);
	const [disableSave, setDisableSave] = useState(false);
	const [productCount, setCount] = useState(0);
	const [amount, setAmount] = useState(0);
	const breadcrumbNav = [
		{ id: '01', route: '/home/dashboard', label: 'Home', active: false },
		{ id: '02', route: '/store/orders', label: 'Parked Orders', active: false },
		{ id: '03', label: getName(), active: true },
	];

	function getName() {
		if (orders?.length > 0) return orders[0].name;
	}
	let productObj = {
		url: 'experion-parkorder/parkorder/search?',
		pageSize: 2000,
		filterDeleted: true,
		fields: [
			{ field_name: 'venue_user_id', field_value: localStorage.getItem('userId')! },
			{ field_name: 'venue_id', field_value: localStorage.getItem('selectedVenue')! },
		],
		fieldsOpt:[
			{ field_name: 'is_shared', field_value: "1" },
		],
	};
	let productItemObj: Partial<urlObj> = {
		url: 'experion-parkorder/parkorderitem/search?',
		pageSize: 2000,
		fields: [{
			field_name: 'parked_order_id',
			field_value: parsed?.id
		}],
	};
	useEffect(() => {
		if (parsed.id) {
			http.GET({ url: urls.GetSearchUrl(productObj) }).then(function (d) {
				setOrders(d.data?.items?.filter((x) => x.parkorder_id == parsed.id));
				setCount(d.data?.items?.filter((x) => x.parkorder_id == parsed.id)[0]?.item_count);
				setAmount(d.data?.items?.filter((x) => x.parkorder_id == parsed.id)[0]?.price);
			});
			http.GET({ url: urls.GetSearchUrl(productItemObj) }).then(function (d) {
				let datas = d?.data?.items;
				if (datas) {
					setProductCount(datas.length);
					setProducts(datas);
					let groupBySupplierName = helper.GroupBy(datas, (x) => x.seller_name);
					setSupplierGroup(groupBySupplierName);
				}
				setHasData(true);
			});
		}
	}, [location]);
	function changeParkOrderDetails(name) {
		switch (name) {
			case 'close':
				showConfirmationModal(name, 'Discard the changes?');
				break;
			case 'save':
				showConfirmationModal(name, 'Save the changes?');
				break;
		}
	}
	function showConfirmationModal(action, text) {
		setAction(action);
		setmodalText(text);
		setShowModal(!showPopup);
	}
	useEffect(() => {
		let stepperCountList = new Array(products?.length);
		products?.forEach((data, index) => {
			stepperCount[data.parkorderitem_id] = data.qty;
			stepperCountList[data.parkorderitem_id] = data.qty;
			setStepperCount(stepperCount);
		});

	}, [products]);
	const stopEvent = (event) => {
		event.stopPropagation();
		event.preventDefault();
	};
	async function decreaseCount(item, event) {
		setIsLoading(true);
		let amount = 0;
		let counter = 0;
		event.stopPropagation();
		event.preventDefault();
		let count = (parseInt(item.qty) - 1).toString();
		if (parseInt(count) <= 0) {
			validationData[item.parkorderitem_id] = 'Min No. of items is 1';
			SetValidationData(validationData);
		}
		else {
			validationData[item.parkorderitem_id] = '';
			SetValidationData(validationData);
		}
		if (validationData.includes('Min No. of items is 1'))
			setDisableSave(true);
		else
			setDisableSave(false);
		if (parseInt(count) >= 0) {
			stepperCount[item.parkorderitem_id] = count;
			setStepperCount(stepperCount);
			productsBySuppliers?.forEach((data) => {
				data?.value.forEach((dat, i) => {
					if (dat.parkorderitem_id == item.parkorderitem_id) {
						dat.qty = count;
						let isExist = editedProducts.find((item) => item.product_id == dat.product_id)
						if (isExist) {
							editedProducts.map((innerData) => {
								if (innerData.product_id == dat.product_id) {
									innerData.qty = dat.qty;
									innerData.price = dat.price;
									innerData.is_delete = dat.is_delete;
								}
							});
						}
						else {
							let newData = {
								id: dat.parkorderitem_id,
								product_id: dat.product_id,
								qty: dat.qty,
								price: dat.price,
								is_delete: 0
							}
							editedProducts.push(newData);
						}
						setEditedProducts(editedProducts);
					}
					amount = amount + (dat.qty * dat.price);
					setAmount(amount);
				});
			});
			setSupplierGroup([...productsBySuppliers]);
		}
		setIsLoading(false);
	};
	async function increaseCount(item, event) {
		setIsLoading(true);
		let amount = 0;
		// let id: any;
		event.stopPropagation();
		event.preventDefault();
		let count = (parseInt(item.qty) + 1).toString();
		if (parseInt(count) <= 0) {
			validationData[item.parkorderitem_id] = 'Min No. of items is 1';
			SetValidationData(validationData);
		}
		else {
			validationData[item.parkorderitem_id] = '';
			SetValidationData(validationData);
		}
		if (validationData.includes('Min No. of items is 1'))
			setDisableSave(true);
		else
			setDisableSave(false);
		if (parseInt(count) >= 0) {
			stepperCount[item.parkorderitem_id] = count;
			setStepperCount(stepperCount);
			productsBySuppliers?.forEach((data) => {
				data?.value.forEach((dat, i) => {
					if (dat.parkorderitem_id == item.parkorderitem_id) {
						dat.qty = count;
						let isExist = editedProducts.find((item) => item.product_id == dat.product_id)
						if (isExist) {
							editedProducts.map((innerData) => {
								if (innerData.product_id == dat.product_id) {
									innerData.qty = dat.qty;
									innerData.price = dat.price;
									innerData.is_delete = 0;
								}
							});
						}
						else {
							let newData = {
								id: dat.parkorderitem_id,
								product_id: dat.product_id,
								qty: dat.qty,
								price: dat.price,
								is_delete: 0
							}
							editedProducts.push(newData);
						}
						setEditedProducts(editedProducts);
					}
					amount = amount + (dat.qty * dat.price);
					setAmount(amount);
				});
			});
			setSupplierGroup([...productsBySuppliers]);
		}
		setIsLoading(false);
	};
	function handleChange(item, event) {
		event.stopPropagation();
		event.preventDefault();
		let amount = 0;
		let eventValue = event.target.value;
		if (eventValue === '' || eventValue === '0' || eventValue === undefined) {
			stepperCount[item.parkorderitem_id] = eventValue;
			let count = eventValue;
			setStepperCount(stepperCount);
			validationData[item.parkorderitem_id] = 'Min No. of items is 1';
			SetValidationData(validationData);
			//disableCheckoutButton(true);
		} else {
			stepperCount[item.parkorderitem_id] = eventValue;
			let count = eventValue;
			setStepperCount(stepperCount);
			validationData[item.parkorderitem_id] = '';
			SetValidationData(validationData);
		}
		if (validationData.includes('Min No. of items is 1'))
			setDisableSave(true);
		else
			setDisableSave(false);
		productsBySuppliers?.forEach((data) => {
			data?.value.forEach((dat, i) => {
				if (dat.parkorderitem_id == item.parkorderitem_id) {
					dat.qty = eventValue;
					let isExist = editedProducts.find((item) => item.product_id == dat.product_id)
					if (isExist) {
						editedProducts.map((innerData) => {
							if (innerData.product_id == parseInt(dat.product_id)) {
								innerData.qty = parseInt(dat.qty);
								innerData.price = parseInt(dat.price);
								innerData.is_delete = 0;
							}
						});
					}
					else {
						let newData = {
							id: parseInt(dat.parkorderitem_id),
							product_id: parseInt(dat.product_id),
							qty: parseInt(dat.qty),
							price: parseInt(dat.price),
							is_delete: 0
						}
						editedProducts.push(newData);
					}
					setEditedProducts(editedProducts);
				}
				amount = amount + (dat.qty * dat.price);
				setAmount(amount);
			});
		});
		setSupplierGroup([...productsBySuppliers]);
	}
	function setLock(isLock) {
		setIsLoading(true);
		const payload = {
			parkOrderId: parseInt(parsed?.id),
			isLock: isLock,
			venueUserId: parseInt(localStorage.getItem('userId')!)
		}
		const obj: Partial<Ipost> = {
			data: payload,
			params: {},
			url: `experion-parkorder/setlock`,
			message: ' ',
		};
		try {
			api.post(obj).then(function (data) {
				setIsLoading(false);
				if (typeof data != 'undefined') {
					setShowCounter(!showCounter);
				}
				return data;
				// setIsShared(true);
			});
		} catch (e) {
			setIsLoading(false);
		}
	}
	async function onSubmit() {
		if (action === 'close') {
			setIsLoading(true);
			setLock(0);
			setShowModal(!showPopup)
		} else if (action === 'save') {
			setIsLoading(true);
			const payload = {
				parkOrderId: parseInt(parsed.id!),
				userId: parseInt(localStorage.getItem('userId')!),
				items: editedProducts
			}
			const obj: Partial<Ipost> = {
				data: payload,
				params: {},
				url: 'experion-parkorder/update',
				message: `Parked order updated successfully`,
			};
			try {
				await api.put(obj).then(function (data) {
					setIsLoading(false);
					if (data) {
						http.GET({ url: urls.GetSearchUrl(productObj) }).then(function (d) {
							setOrders(d.data?.items?.filter((x) => x.parkorder_id == parsed.id));
							setCount(d.data?.items?.filter((x) => x.parkorder_id == parsed.id)[0]?.item_count);
							setAmount(d.data?.items?.filter((x) => x.parkorder_id == parsed.id)[0]?.price);
						});
						http.GET({ url: urls.GetSearchUrl(productItemObj) }).then(function (d) {
							let datas = d?.data?.items;
							if (datas) {
								setProductCount(datas.length);
								setProducts(datas);
								let groupBySupplierName = helper.GroupBy(datas, (x) => x.seller_name);
								setSupplierGroup(groupBySupplierName);
							}
							setHasData(true);
							setIsLoading(false);
						}).catch(function (err) {
						});;
						setShowCounter(!showCounter);
					}
				});

			} catch (e) {
				setIsLoading(false);
			}
			setShowModal(!showPopup);
		}

	}
	async function closePopup() {
		await setShowModal(!showPopup);
	}
	// const EnableCounter = () => {
	// 	setShowCounter(!showCounter);
	// }
	const removeparkOrder = (id) => {
		let amount = 0;
		let counter = 0;
		let supplierCount = 0;
		let index;
		productsBySuppliers?.forEach((data) => {
			data?.value.forEach((dat, i) => {
				if (dat.parkorderitem_id == id) {
					let isExist = editedProducts.find((item) => item.product_id == dat.product_id)
					if (isExist) {
						editedProducts.map((innerData) => {
							if (innerData.product_id == dat.product_id) {
								innerData.qty = dat.qty;
								innerData.price = dat.price;
								innerData.is_delete = 1;
							}
						});
					}
					else {
						let newData = {
							id: dat.parkorderitem_id,
							product_id: dat.product_id,
							qty: dat.qty,
							price: dat.price,
							is_delete: 1
						}
						editedProducts.push(newData);
					}
					data.value = data?.value?.filter(x => x.parkorderitem_id !== id);
					setEditedProducts(editedProducts);
					if (data?.value.length === 0)
						index = productsBySuppliers.indexOf(data);
				}
				else {
					counter = counter + 1;
					amount = amount + (dat.qty * dat.price);
				}
				setAmount(amount);
				setCount(counter);
			});
		});
		if (index > -1)
			productsBySuppliers.splice(index, 1);
		setSupplierGroup([...productsBySuppliers]);
	}
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	}
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={()=>onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={()=>onclickHambrgBtn()}></Sidebar>
			{isLoading == true ? <Loader></Loader> : null}
			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbNav
					Heading="Parked Order Details"
					breadcrumbList={breadcrumbNav}
					RouteLink="/store/orders"
				/>

				{(hasData && (
					<OrderDetailsBlock>
						<ParkedOrderDetailsEditView
							orders={orders}
							products={products}
							supplierCount={productsBySuppliers?.length}
							changeParkOrderDetails={changeParkOrderDetails}
							setLock={setLock}
							showCounter={showCounter}
							amount={amount}
							productCount={productCount}
							disableSave={disableSave}
						></ParkedOrderDetailsEditView>
						{productsBySuppliers &&
							productsBySuppliers.map((data, i) => {
								return (
									<Fragment key={helperFunctions.getRandomInt(pageConstants.maxValue)}>
										<Text
											as="h5"
											key={i}
											mt={5}
											fontSize="20px"
											lineHeight="26px"
											color="text.black"
										>
											{data.key} <strong>{data.value?.length}</strong>
										</Text>
										<OrdersDetailsListView
											ordersDetailsListView={data.value}
											stopEvent={stopEvent}
											decreaseCount={decreaseCount}
											increaseCount={increaseCount}
											handleChange={handleChange}
											validationData={validationData}
											stepperCount={stepperCount}
											showCounter={showCounter}
											removeparkOrder={removeparkOrder}
										//index={i}
										></OrdersDetailsListView>
									</Fragment>

								);
							})}
					</OrderDetailsBlock>
				)) ||
					new Array(10).fill(null).map((_, i) => {
						return <Skeleton key={i} width="1400px" height="150px" />;
					}) ||
					new Array(10).fill(null).map((_, i) => {
						return <Skeleton key={i} width="1400px" height="50px" />;
					})}

				<SweetAlert
					show={showPopup}
					confirmBtnText="Confirm"
					onConfirm={onSubmit}
					showCancel={true}
					onCancel={closePopup}
					title={'Confirmation Box'}
				>
					{() => <form>{modalText}</form>}
				</SweetAlert>
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default ParkedOrderDetails;
