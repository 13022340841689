import { UserBody } from 'admin/dashboardVenue/styles';
import { venueUserPermissionList } from 'models/enum-constants/common.constants';
import Iget from 'models/Iget';
import Ipost from 'models/Ipost';
import urlObj from 'models/url.obj';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';
import 'react-tippy/dist/tippy.css';
import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { Button, Loader, Search, SortBy } from 'ui/components';
import * as Icon from 'ui/icons';
import AppContext from 'utils/appContext';
import env from 'utils/constants';
import urls from 'utils/create.url';
import helper from 'utils/helper.functions';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import utilityFunctions from 'utils/utility.functions';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import notify from 'utils/toast.service';

const StyledOrderListView = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	column-gap: 20px;
	row-gap: 20px;
	article {
		flex-direction: row;
		background: ${({ theme }) => theme.colors.bg.white};
		border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
		box-sizing: border-box;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 16px;
		overflow: hidden;
		transition: box-shadow 100ms ease-in, transform 100ms ease-in;
		align-items: flex-start;
		cursor: pointer;
		&:hover {
			transform: translateY(-5px);
			box-shadow: 0 20px 20px #eee;
			border: 1px solid ${({ theme }) => theme.baseColors.baseYellowLight};
		}
		&.disabled {
			opacity: 0.3;
			pointer-events: none;
		}
		span {
			max-width: 120px;
			flex: 0 0 120px;
			height: 100%;
			min-height: 130px;
			background-repeat: no-repeat;
			background-size: cover;
			@media (max-width: 768px) {
				max-width: 126px;
				flex: 0 0 126px;
			}
			@media (max-width: 480px) {
				max-width: 100px;
				flex: 0 0 100px;
			}
		}
		section {
			display: grid !important;
			grid-template-columns: repeat(5, 1fr) !important;
			height: 100%;
			padding: 25px;
			align-items: center;
			column-gap: 10px;
			div {
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				display: block;
			}

			@media (max-width: 1300px) {
				padding: 20px;
				grid-template-columns: repeat(3, 1fr) !important;
				row-gap: 20px;
			}
			@media (max-width: 768px) {
				row-gap: 15px;
				grid-template-columns: repeat(2, 1fr) !important;
			}
			@media (max-width: 480px) {
				padding: 15px;
				grid-template-columns: repeat(1, 1fr) !important;
			}
			.btn-block {
				display: flex;
				align-items: center;
				justify-content: flex-end;

				@media (max-width: 1300px) {
					justify-content: flex-start;
				}
				span {
					height: auto;
					flex: 0 0 auto;
					max-width: auto;
					min-width: auto;
					min-height: auto;
				}
				button {
					display: flex;
					align-items: center;
					margin-left: 5px;
					text-transform: capitalize;
					white-space: nowrap;
					height: 50px;
					@media (max-width: 768px) {
						margin-left: 0;
						margin-right: 5px;
					}
					@media (max-width: 480px) {
						margin-right: 15px;
						font-size: 13px;
						padding-right: 10px;
						padding-left: 10px;
						white-space: nowrap;
					}
					span {
						max-width: 100% !important;
					}
				}
				icon {
					display: inline-block;
				}
				div {
					word-break: break-word;
				}
			}
			.totalProducts {
				max-width: 106px;
				text-align: right;
				white-space: nowrap;
			}
		}
	}
`;
const PageSearchHolder = styled.div`
	width: 100%;
	padding-top: 15px;
	padding-bottom: 10px;
	display: flex;
	justify-content: space-between;
	div {
		width: 650px;
		@media screen and (max-width: 1200px) {
			width: 60%;
		}
		@media screen and (max-width: 860px) {
			width: 100%;
		}
		+ div {
			width: 254px;
			@media screen and (max-width: 860px) {
				width: 100%;
				margin-top: 10px;
			}
		}
	}
	@media screen and (max-width: 860px) {
		flex-direction: column;
	}
	@media screen and (max-width: 767px) {
		width: 100%;
	}
`;
function OrderListView(): ReactElement {
	let history = useHistory();
	const ctx = useContext(AppContext).productSearchObj;
	const cartCtx = useContext(AppContext).cartObj;
	const [url, setSearchUrl] = useState('');
	const [orderList, setOrderList] = useState([] as any);
	const [postObj, setObj] = useState([] as any);
	const [modalText, setmodalText] = useState('');
	const [showPopup, setShowModal] = useState(false);
	const [action, setAction] = useState('');
	const [searchText, setSearchText] = useState('');
	const [orderId, setOrderId] = useState('');
	const [inProgress, setInProgress] = useState(false);
	const [venueUserId] = useState<any>(localStorage.getItem('userId'));
	const [venueId] = useState<any>(localStorage.getItem('selectedVenue'));

	let urlObj: Partial<urlObj> = {
		url: 'experion-parkorder/parkorder/search?',
		pageSize: 2000,
		filterDeleted: true,
		ctx: ctx,
		fields: [
			{ field_name: 'venue_user_id', field_value: localStorage.getItem('userId')! },
			{ field_name: 'venue_id', field_value: localStorage.getItem('selectedVenue')! },
		],
		fieldsOpt: [{ field_name: 'is_shared', field_value: '1' }],
	};
	const getKey = () => {
		return `parked${ctx.SortField}${ctx.SortDirection}`;
	};

	const obj: Partial<Iget> = {
		url: urls.GetSearchUrl(urlObj),
		enabled: true,
		refetchOnWindowFocus: false,
		resultFilteringRequired: true,
		key: getKey(),
	};
	// const obj: Partial<Iget> = {
	// 	params: {},
	// 	url: `experion-parkorder/get/${localStorage.getItem('selectedVenue')!}/${localStorage.getItem(
	// 		'userId'
	// 	)!}`,
	// 	enabled: true,
	// 	key: getKey(),
	// 	resultFilteringRequired: false,
	// };
	const qry = hooks.useQuery(obj);

	const handleSort = (val) => {
		let sortData = utilityFunctions.getSortData(val);
		ctx.SortDirection = sortData.direction;
		ctx.SortField = sortData.field;
		setSearchUrl(urls.GetSearchUrl(urlObj));
	};

	const setFilteredData = (val) => {
		setSearchText(val);
		const orders = qry.data;
		if (val && val.length > 0) {
			var list = orders.filter(
				(v) =>
					v.name.toLowerCase().includes(val.toLowerCase()) ||
					String('$' + parseFloat(v.price).toFixed(2))
						.toLowerCase()
						.includes(val.toLowerCase()) ||
					String(v.item_count).toLowerCase().includes(val.toLowerCase())
			);
			setOrderList(list);
		} else {
			setOrderList(qry.data);
		}
	};

	useEffect(() => {
		if (qry.data) {
			//let data = qry.data.filter(x=>x.venue_user_id == venueUserId || (x.venue_user_id != venueUserId && x.is_shared == 1))
			setOrderList(qry.data);
		}
	}, [qry.data]);

	function confirm(e, action, id, qty = 0) {
		e.stopPropagation();
		setAction(action);
		switch (action) {
			case 'del':
				showConfirmationModal('Delete parked order?', id, qty);
				break;
			case 'move':
				showConfirmationModal('Sure you want to move the products to cart ?', id, qty);
				break;
			default:
				break;
		}
	}

	async function showConfirmationModal(text, id, qty) {
		setOrderId(id);
		setmodalText(text);
		setShowModal(!showPopup);
	}
	async function closePopup() {
		await setShowModal(!showPopup);
	}
	function onSubmit() {
		if (action == 'del') deleteParkedOrder();
		else if (action == 'move') moveToCart();
	}

	function deleteParkedOrder(hasMessage = true) {
		let obj1: Partial<Ipost> = {};
		obj1.message = !hasMessage ? ' ' : 'Order deleted successfully';
		obj1.url = `experion-parkorder/parkorder/${orderId}`;
		api.delete(obj1).then(function (d) {
			if (typeof d != 'undefined') {
				qry.refetch().then(function (data) {
					setOrderList(data);
				});
			}
		});
		if (hasMessage) setShowModal(!showPopup);
	}

	async function moveToCart() {
		setInProgress(true);
		setShowModal(!showPopup);
		let items = [] as any;
		await api
			.get({
				url: urls.GetSearchUrl({
					url: 'experion-parkorder/parkorderitem/search?',
					pageSize: 1,
					fields: [{ field_name: 'parked_order_id', field_value: orderId }],
				}),
			})
			.then(function (data) {
				data?.items?.forEach((v: any) => {
					items.push({ product_id: v.product_id, qty: v.qty });
				});
			});
		setObj(items);
		if (items.length > 0) {
			let id: any;
			let CUSTOMER_TOKEN = localStorage.getItem('customerToken');
			id = await utilityFunctions.getCartId(CUSTOMER_TOKEN);
			if (id !== undefined) {
				const payload = {
					products: {
						quote_id: id,
						items: items,
						venue_user_id: venueUserId,
						venue_id: venueId,
						parked_id: orderId,
					},
				};

				const obj: Partial<Ipost> = {
					data: payload,
					url: env.multiCartURL(),
					key: 'parkedToCart',
					message: 'Available products will be moved to cart!',
					headers: { Authorization: localStorage.getItem('customerToken') },
				};

				try {
					await api
						.post(obj)
						.then(function (data) {
							if (data == true) {
								setInProgress(false);
								cartCtx.stepperdCountUpdated = false;
								deleteParkedOrder(false);
								history.push('/store/cart');
							} else {
								setInProgress(false);
							}
						})
						.catch(function (data) {
							setInProgress(false);
						});
				} catch (e) {
					setInProgress(false);
				}
			}
		} else {
			setInProgress(false);
			notify('No products are available to move to the cart !!').error();
		}
	}

	return (
		<StyledOrderListView>
			<PageSearchHolder>
				<Search onChange={(val) => setFilteredData(val)}></Search>
				<SortBy onSort={handleSort}></SortBy>
			</PageSearchHolder>
			{orderList?.length > 0 ? (
				orderList.map(
					({ parkorder_id, name, price, created_at, item_count, is_shared, venue_user_id }) => (
						<Flex
							onClick={() => history.push(`/store/parkedOrderDetails?id=${parkorder_id}`)}
							as="article"
							bg="white"
							sx={{ alignItems: 'center' }}
							key={parkorder_id}
							// className="disabled"
						>
							{/* <Flex
								alignItems="center"
								as="span"
								maxWidth={['100%']}
								sx={{ overflow: 'hidden' }}
							></Flex> */}
							<Flex as="section" flexWrap="wrap" width="100%" maxWidth={['100%']}>
								<Box>
									<Text fontSize={16} color="text.black">
										Parked Order Name
									</Text>
									<Text fontSize={18} fontFamily="OpenSansBold" color="text.black">
										{name}
									</Text>
								</Box>
								<Box>
									<Text as="h1" fontSize={16} color="text.black">
										Amount
									</Text>
									<Text as="h2" fontSize={18} fontFamily="OpenSansBold" color="text.black">
										{helper.formatAmount(price)}
									</Text>
								</Box>
								<Box>
									<Text as="h1" fontSize={16} color="text.black">
										Created On
									</Text>
									<Text as="h2" fontSize={18} fontFamily="OpenSansBold" color="text.black">
										{helper.formatDate(created_at)}
									</Text>
								</Box>
								<Box className="totalProducts">
									<Text as="h1" fontSize={16} color="text.black">
										Total Products
									</Text>
									<Text as="h2" fontSize={18} fontFamily="OpenSansBold" color="text.black">
										{item_count}
									</Text>
								</Box>
								<Box>
									<div className="btn-block">
										{utilityFunctions.isGranted(venueUserPermissionList.ManageProductsinCart) && (
											<Button onClick={(e) => confirm(e, 'move', parkorder_id, item_count)}>
												Move to Cart
											</Button>
										)}

										{(utilityFunctions.isGranted(venueUserPermissionList.ParkedOrdersDelete) ||
											utilityFunctions.isGranted(venueUserPermissionList.ParkedOrdersAdmin)) &&
											venue_user_id == venueUserId && (
												<Button variant="ghost" onClick={(e) => confirm(e, 'del', parkorder_id)}>
													<Icon.DeleteIcon />
												</Button>
											)}
										{is_shared == 1 && venue_user_id !== venueUserId && (
											<Tooltip
												arrow
												title="Shared"
												animation="perspective"
												position="top"
												trigger="mouseenter"
												style={{ padding: '0 24px', cursor: 'default' }}
											>
												<Icon.sharePersonIcon />
											</Tooltip>
										)}
									</div>
								</Box>
							</Flex>
						</Flex>
					)
				)
			) : !qry.isFetching ? (
				<UserBody>
					{/* <Icon.searchIcon title="No Data Found" /> */}
					<Text fontSize={18} fontWeight="bold" color="#5C5E57" textAlign="center">
						No Orders Found
					</Text>
				</UserBody>
			) : (
				new Array(2).fill(null).map((_, i) => {
					return <Skeleton key={i} width="1200px" height="50px" />;
				})
			)}
			{inProgress && <Loader></Loader>}
			<SweetAlert
				show={showPopup}
				confirmBtnText="Confirm"
				onConfirm={onSubmit}
				showCancel={true}
				onCancel={closePopup}
				title={'Confirmation Box'}
			>
				{() => (
					<form>
						<hr />
						{modalText}
						<hr />
					</form>
				)}
			</SweetAlert>
		</StyledOrderListView>
	);
}

export { OrderListView };
