const config = {
	//get configuration
	getConfig: (params, url, headers = {}) => {
		return {
			url: url,
			params: params,
			headers: headers,
		};
	},
	//config for post request
	postConfig: (data, params, url,headers = {}) => {
		return {
			url: url,
			params: params,
			headers: headers,
			data: data,
		};
	}
};

export default config;
