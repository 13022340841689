import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

import 'ui/styles/index.css';

import App from './App';

import * as serviceWorker from './serviceWorker';
import helperFunctions from 'utils/helper.functions';
import pageConstants from 'utils/pageConstants';

ReactDOM.render(
	<I18nextProvider  key={helperFunctions.getRandomInt(pageConstants.maxValue)} i18n={i18n}>
	<App />
	</I18nextProvider>, 
document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers:
// https://github.com/facebook/create-react-app/blob/master/packages/cra-template/template/README.md
serviceWorker.register();
