import styled from 'styled-components';

const MainContent = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
	padding: 0 50px;
	@media (max-width: 960px) {
		padding: 0 15px;
	}
`;
const ContentHolder = styled.div`
	height: 100%;
	padding: 0 50px;
	padding-bottom: 30px;
	width: 100%;

	@media (max-width: 768px) {
		padding: 0 15px;
	}
`;
const CardsAndNotificationHolder = styled.div`
	width: 100%;
	display: flex;
	@media (max-width: 860px) {
		flex-direction: column;
	}
`;
const TabContentHolder = styled.div`
	width: 100%;
	display: flex;
	margin-top: 50px;
`;
const AccordionBlock = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	position: relative;
`;
const UserBlock = styled.div`
	display: flex;
	flex-direction: column;
	margin: 45px 0px 0px;
	table {
		margin-top: 10px;
		@media (max-width: 1300px) {
			width: 100%;
		}
		thead,
		tbody {
			tr {
				td,
				th {
					@media screen and (max-width: 767px) {
						flex: 0 0 100% !important;
						max-width: 100% !important;
						margin-bottom: 15px;
					}
				}
			}
		}
	}
`;
const UserHeader = styled.div`
	display: flex;
	align-items: center;
	padding-bottom: 16px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	button {
		margin-left: auto;
		min-width: 100px;
		outline: none;
		cursor: pointer;
	}
`;
const UserBody = styled.div`
	width: 100%;
	height: 100%;
	margin: 50px auto;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	svg {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	button {
		margin: 0 auto;
		background: none;
		outline: none;
		border: none;
	}
`;
const BreadcrumbWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media (max-width: 860px) {
		flex-direction: column;
		align-items: flex-start;
		& > div {
			padding-bottom: 20px;
		}
	}
`;
const RightHolder = styled.div`
	display: flex;
	align-items: center;
	label {
		margin-top: 5px;
	}
	button {
		width: 34px;
		height: 34px;
		margin-left: 18px;
		flex: 0 0 34px;
		padding: 0;
		svg {
			width: 15px;
			height: 17px;
		}
	}
`;
export {
	MainContent,
	ContentHolder,
	CardsAndNotificationHolder,
	TabContentHolder,
	AccordionBlock,
	UserBlock,
	UserHeader,
	UserBody,
	BreadcrumbWrapper,
	RightHolder,
};
