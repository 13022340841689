import { UserBody } from 'admin/dashboardVenue/styles';
import { dcUserPermissionList } from 'models/enum-constants/common.constants';
import Iget from 'models/Iget';
import Ipost from 'models/Ipost';
import React, { ReactElement, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory } from 'react-router-dom';
import { Box, Text } from 'rebass/styled-components';
import {
	BreadcrumbNav,
	Footer,
	Header,
	Loader,
	PageSearchHolder,
	Search,
	TableList,
	Toggle,
} from 'ui/components';
import { Sidebar } from 'ui/patterns';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import utilityFunctions from 'utils/utility.functions';
import { BreadcrumbHolder, MainContent } from './styles';

function VenueCreditManagement(): ReactElement {
	const breadcrumbNav = [
		{ id: '01', label: 'Dashboard', route: 'seller-dashboard', active: false },
		{ id: '03', label: 'Store Credit Status', active: true },
	];
	const [showPopup, setShowModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	var [productList, updateProductList] = useState<any>([]);
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const userKey = 'pdtManagement';
	const dcId = localStorage.getItem('selectedLocation');
	const userId = localStorage.getItem('userId');
	const obj1: Partial<Ipost> = {
		params: {},
		data: {},
		url: '',
	};
	const [checked, setChecked] = useState(false);
	const [action, setAction] = useState('');
	const [id, setId] = useState(0);
	const [modalText, setmodalText] = useState('');
	const url = `experion/productmanagement/venuecreditstatus/${dcId}`;
	const obj: Partial<Iget> = {
		params: {},
		url: dcId == null ? '' : url,
		enabled: true,
		key: userKey,
		resultFilteringRequired: false,
	};
	var qry = hooks.useQuery(obj);
	useEffect(() => {
		qry.data && updateProductList([...qry.data]);
	}, [qry.data]);
	function onSubmit() {
		setIsLoading(true);
		if (action == 'upd') changeStatus();
	}
	function changeStatus() {
		var item = {
			dc_user_id: parseInt(userId + ''),
			venue_id: parseInt(id + ''),
			status: checked,
		};
		obj1.url = `experion/productmanagement/venuecreditstatus/create`;
		obj1.data = { venueCreditStatus: item };
		obj1.message = 'Venue updated successfully';
		api.post(obj1).then(function () {
			setIsLoading(false);
		});
		setShowModal(!showPopup);
	}
	async function closePopup() {
		if (action == 'upd') {
			productList[productList.findIndex((x) => x.venue_id == id)].status =
				productList[productList.findIndex((x) => x.venue_id == id)].status == 1 ? 0 : 1;
			updateProductList(productList);
			setChecked(!checked);
		}
		await setShowModal(!showPopup);
	}
	function showConfirmationModal(action, text, id, checked) {
		if (action == 'upd') setChecked(checked);
		setAction(action);
		setId(id);
		setmodalText(text);
		setShowModal(!showPopup);
	}
	function confirm(action, id, e = null as any) {
		let checked = 0;
		if (e != null) {
			// checked = e.currentTarget.checked;
			if (e.currentTarget.checked) {
				checked = 1;
			} else {
				checked = 0;
			}
			if (action == 'upd') {
				productList[productList.findIndex((x) => x.venue_id == id)].status = checked;
			}
			updateProductList(productList);
		}
		switch (action) {
			case 'upd':
				showConfirmationModal(action, `${checked == 1 ? 'Start' : 'Stop'} Venue?`, id, checked);
				break;
			default:
				break;
		}
	}
	const setFilteredData = (val) => {
		const sampleList = qry.data;
		if (val && val.length > 0) {
			var list = sampleList.filter(
				(v) =>
					v.venue_name.toLowerCase().includes(val.toLowerCase()) ||
					v.venue_description.toLowerCase().includes(val.toLowerCase()) ||
					v.contact_name.toLowerCase().includes(val.toLowerCase()) ||
					v.contact_person_phone.toLowerCase().includes(val.toLowerCase()) ||
					v.phone.includes(val.toLowerCase()) ||
					v.email.toLowerCase().includes(val.toLowerCase())
			);
			updateProductList(list);
		} else {
			updateProductList(qry.data);
		}
	};
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	};
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={() => onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={() => onclickHambrgBtn()}></Sidebar>
			{qry.isLoading || isLoading == true ? <Loader></Loader> : null}
			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbHolder>
					<BreadcrumbNav Heading="Store Credit Status" breadcrumbList={breadcrumbNav} />
				</BreadcrumbHolder>
				<PageSearchHolder>
					<Box as="section">
						<Search
							onChange={(val) => setFilteredData(val)}
							type={'creditTracker'}
							placeholder="Search for Stores..."
						></Search>
					</Box>
					{/* <Box as="small">
						<Button flex={[1, 1, 'initial']} variant="redOutline">
							<icons.DeleteIcon />
							<div>Delete Selected</div>
						</Button>
					</Box> */}
				</PageSearchHolder>
				<TableList>
					<table>
						<thead>
							<tr>
								{/* <th className="checked">
									<Label>
										<Checkbox id="remember" name="remember" />
									</Label>
								</th> */}
								<th>Store Name</th>
								<th>Description</th>
								<th>Email</th>
								<th>Phone</th>
								<th>Contact Person</th>
								<th>Contact Phone</th>
								<th className="status">Status</th>
							</tr>
						</thead>

						<tbody>
							{productList.length > 0 ? (
								(productList && productList).map(
									(
										{
											venue_id,
											venue_name,
											venue_description,
											email,
											phone,
											contact_name,
											contact_person_phone,
											status,
										},
										index
									) => (
										<tr key={index} className={status == 0 ? 'row-highlighted' : ''}>
											{/* <td className="checked">
												<Label>
													<Checkbox id="remember" name="remember" />
												</Label>
											</td> */}
											<td data-label="Venue name">
												<Text as="h1" fontSize={15} fontFamily="OpenSansBlack" color="text.black">
													<span>{venue_name}</span>
												</Text>
											</td>
											<td data-label="Description">
												<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
													{venue_description}
												</Text>
											</td>
											<td data-label="Email">
												<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
													{email}
												</Text>
											</td>
											<td data-label="Phone">
												<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
													{phone}
												</Text>
											</td>
											<td data-label="Contact Name">
												<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
													{contact_name}
												</Text>
											</td>
											<td data-label="Contact Person Phone">
												<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
													{contact_person_phone}
												</Text>
											</td>
											<td className="status" data-label="Status">
												<Toggle
													disabled={
														!utilityFunctions.isGranted(dcUserPermissionList.CreditStatusView)
													}
													checked={status == 0 ? false : true}
													onChange={(e) => confirm('upd', venue_id, e)}
												/>
											</td>
										</tr>
									)
								)
							) : (
								<tr>
									<UserBody>
										{/* <Button>
		<Icon.noUser title="User Icon" />
	</Button> */}
										<Text fontSize={18} fontWeight="bold" color="#5C5E57" textAlign="center">
											No Stores Found
										</Text>
									</UserBody>
								</tr>
							)}
						</tbody>
					</table>
				</TableList>
				<SweetAlert
					show={showPopup}
					confirmBtnText="Confirm"
					onConfirm={onSubmit}
					showCancel={true}
					onCancel={closePopup}
					title={'Confirmation Box'}
				>
					{() => (
						<form>
							<hr />
							{modalText}
							<hr />
						</form>
					)}
				</SweetAlert>
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default VenueCreditManagement;
