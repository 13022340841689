import { UserBody } from 'admin/userDetails/styles';
import Iget from 'models/Iget';
import Ipost from 'models/Ipost';
import React, { ReactElement, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory } from 'react-router-dom';
import { Box, Text } from 'rebass/styled-components';
import {
	BreadcrumbNav, Button, Footer, Header,





	Loader, PageSearchHolder, Search, TableList, Toggle
} from 'ui/components';
import icons from 'ui/icons';
import { Sidebar } from 'ui/patterns';
import urls from 'utils/create.url';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import { BreadcrumbHolder, ButtonWrapper, MainContent } from './styles';


function DCList(): ReactElement {
	const history = useHistory();
	const breadcrumbNav = [
		{ id: '01', label: 'Dashboard', route: '/admin/seller-dashboard', active: false },
		{ id: '02', label: 'DC Management', active: true },
	];
	var [dcList, updateDcList] = useState<any>([]);
	const userKey = 'dcKey';
	const [checked, setChecked] = useState(false);
	const [action, setAction] = useState('');
	const [id, setId] = useState(0);
	const [modalText, setmodalText] = useState('');
	const [showPopup, setShowModal] = useState(false);
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	// var [idList, updateIdList] = useState<any>([]);
	// var [enableButton, updateEnableButton] = useState<any>(false);
	const url = urls.GetSearchUrl({
		url: 'experion/supplycompanyaccount/dc/search?',
		fields: [{
			field_name: 'supply_id',
			field_value: localStorage.getItem("supplierId")!
		}],
		filterDeleted: true,
		ctx: { currentPage: 1 },
	});
	const obj: Partial<Iget> = {
		params: {},
		url: url,
		enabled: true,
		key: userKey,
		resultFilteringRequired: true,
	};
	const obj1: Partial<Ipost> = {
		params: {},
		url: '',
	};
	var qry = hooks.useQuery(obj);
	useEffect(() => {
		qry.data && updateDcList([...qry.data]);
	}, [qry.data]);
	function confirm(action, id, e = null as any) {
		let checked = false;
		if (e != null) {
			checked = e.currentTarget.checked;
			dcList[dcList.findIndex((x) => x.dc_id == id)].is_active = checked;
			updateDcList(dcList);
		}
		switch (action) {
			case 'del':
				showConfirmationModal(action, 'Delete Distribution Center?', id, checked);
				break;
			case 'upd':
				showConfirmationModal(
					action,
					`${checked == true ? 'A' : 'Dea'}ctivate Distribution Center?`,
					id,
					checked
				);
				break;
			default:
				break;
		}
	}
	function onSubmit() {
		if (action == 'del') deleteDC();
		else if (action == 'upd') changeStatus();
	}
	function deleteDC() {
		obj1.url = `experion/supplycompanyaccount/deletedc/${id}`;
		obj1.data = { Id: [id] };
		obj1.message = 'Distribution Center deleted successfully';
		api.delete(obj1).then(function (d) {
			if (typeof d != 'undefined') {
				qry.refetch().then(function (data) {
					updateDcList(data);
				})
			}
		});
		setShowModal(!showPopup);
	}

	function changeStatus() {
		obj1.url = `experion/supplycompanyaccount/updatestatus/${id}/${checked}`;
		obj1.message = 'Distribution Center status updated successfully';
		api.put(obj1).then(function (data) {
			if (typeof data == 'undefined') {
				dcList[dcList.findIndex((x) => x.dc_id == id)].is_active = !checked;
				updateDcList(dcList);
				setChecked(!checked);
			}
		});
		setShowModal(!showPopup);
	}
	async function closePopup() {
		if (action == 'upd') {
			dcList[dcList.findIndex((x) => x.dc_id == id)].is_active = !checked;
			updateDcList(dcList);
			setChecked(!checked);
		}
		await setShowModal(!showPopup);
	}
	function showConfirmationModal(action, text, id, checked) {
		if (action == 'upd') setChecked(checked);
		setAction(action);
		setId(id);
		setmodalText(text);
		setShowModal(!showPopup);
	}
	// const selectCheckBox = (id, event) => {
	// 	if (event.target.checked) {
	// 		idList.push(id);
	// 		updateIdList(idList);
	// 	} else {
	// 		idList.splice(idList.indexOf(id), 1);
	// 		updateIdList(idList);
	// 	}
	// 	if (idList.length >= 1) {
	// 		updateEnableButton(true);
	// 	} else {
	// 		updateEnableButton(false);
	// 	}
	// };
	// const selectAllCheckbox = (event) => {
	// 	idList = [];
	// 	updateIdList(idList);
	// 	if (event.target.checked) {
	// 		updateEnableButton(true);
	// 		dcList.map((data) => {
	// 			idList.push(data.id);
	// 		});
	// 		updateIdList(idList);
	// 	} else {
	// 		idList = [];
	// 		updateIdList(idList);
	// 		updateEnableButton(false);
	// 	}
	// 	let checked = event.target.checked;
	// 	dcList = dcList.map((item) => ({ ...item, isChecked: checked }));
	// 	updateDcList(dcList);
	// };
	// const deleteDcItem = (id) => {
	// 	obj1.url = `experion-supplycompanyaccount/deletedc/`;
	// 	obj1.data = { Id: [id] };
	// 	obj1.message = 'Distribution Center deleted successfully';
	// 	api.post(obj1);
	// };
	const createNewDc = () => {
		history.push('addDCList');
	};
	const navigateToEdit = (id) => {
		history.push({
			pathname: 'editDCList',
			search: '?id=' + id,
		});
	};
	const setFilteredData = (val) => {
		const sampleList = qry.data;
		if (val && val.length > 0) {
			var list = sampleList.filter(
				(v) =>
					v.name.toLowerCase().includes(val.toLowerCase()) ||
					v.description.toLowerCase().includes(val.toLowerCase()) ||
					v.email.toLowerCase().includes(val.toLowerCase()) ||
					v.address.toLowerCase().includes(val.toLowerCase()) ||
					v.phone.toString().includes(val)
			);
			updateDcList(list);
		} else {
			updateDcList(qry.data);
		}
	};
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	}
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={() => onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={() => onclickHambrgBtn()}></Sidebar>
			{qry.data == null ? <Loader></Loader> : null}
			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbHolder>
					<BreadcrumbNav Heading="Distribution Centers " breadcrumbList={breadcrumbNav} />
					<ButtonWrapper>
						<Button onClick={createNewDc} flex={[1, 1, 'initial']}>
							<icons.PlusIcon />
							<div>New DC</div>
						</Button>
					</ButtonWrapper>
				</BreadcrumbHolder>

				<PageSearchHolder>
					<Box as="section">
						<Search
							onChange={(val) => setFilteredData(val)}
							type={'dcList'}
							placeholder="Search for DC, Email, Phone ..."
						></Search>
					</Box>
					{/* <Box as="small">
						<Button 
						onClick={() => deleteDcItem(idList)}
						disabled={!enableButton}
						flex={[1, 1, 'initial']} variant="redOutline">
							<icons.DeleteIcon />
							<div>Deleted Selected</div>
						</Button>
					</Box> */}
				</PageSearchHolder>
				<TableList>
					<table>
						<thead>
							<tr>
								{/* <th className="checked">
									<Label>
										<Checkbox id="remember" name="remember" onChange={selectAllCheckbox}/>
									</Label>
								</th> */}
								<th>DC Name</th>
								<th>Description</th>
								<th>Address</th>
								<th>Email</th>
								<th>Phone</th>
								<th className="status">Status</th>
								<th className="action">Action</th>
							</tr>
						</thead>

						<tbody>
							{dcList && dcList.length > 0 ? (
								dcList
									.filter((x) => x.is_delete !== true)
									.map(
										(
											{ name, email, phone, dc_id, is_active, address, isChecked, description },
											index
										) => (
											<tr key={index} className={!is_active ? "row-highlighted" : ""}>
												{/* <td className="checked">
												<Label>
													<Checkbox id="remember"
															onChange={(event) => selectCheckBox(id, event)}
															checked={isChecked}
															name="remember" />
												</Label>
											</td> */}
												<td data-label="DC Name">
													<Text
														as="h1"
														fontSize={15}
														fontFamily="OpenSansBlack"
														color="text.black"
													>
														<span>{name}</span>
													</Text>
												</td>
												<td data-label="Description">
													<Text
														as="h1"
														fontSize={15}
														fontFamily="OpenSansMedium"
														color="text.black"
													>
														{description}
													</Text>
												</td>
												<td data-label="Address">
													<Text
														as="h1"
														fontSize={15}
														fontFamily="OpenSansMedium"
														color="text.black"
													>
														{address}
													</Text>
												</td>
												<td data-label="Email">
													<Text
														as="h1"
														fontSize={15}
														fontFamily="OpenSansMedium"
														color="text.black"
													>
														{email}
													</Text>
												</td>
												<td data-label="Phone">
													<Text
														as="h1"
														fontSize={15}
														fontFamily="OpenSansMedium"
														color="text.black"
													>
														{phone}
													</Text>
												</td>
												<td className="status" data-label="Status">
													<Toggle
														//disabled={!dc_id ? true : false}
														checked={is_active}
														onChange={(e) => confirm('upd', dc_id, e)}
													/>
												</td>
												<td className="action" data-label="Action">
													<Text>
														<Button
															variant="deleteIcon"
															disabled={!dc_id ? true : false}
															onClick={() => confirm('del', dc_id)}
														>
															<icons.DeleteIcon />
														</Button>
														<Button onClick={() => navigateToEdit(dc_id)} variant="editIcon">
															<icons.textEditIcon />
														</Button>
													</Text>
												</td>
											</tr>
										)
									)
							) : (
								<UserBody>
									{/* <Button>
										<Icon.noUser title="User Icon" />
									</Button> */}
									<Text fontSize={18} fontWeight="bold" color="#5C5E57" textAlign="center">
										No Distribution Centers Found
									</Text>
								</UserBody>
							)}
						</tbody>
					</table>
				</TableList>
				<SweetAlert
					show={showPopup}
					confirmBtnText="Confirm"
					onConfirm={onSubmit}
					showCancel={true}
					onCancel={closePopup}
					title={'Confirmation Box'}
				//  dependencies={[this.state.firstName, this.state.lastName]}
				>
					{() => (
						<form>
							<hr />
							{modalText}
							<hr />
						</form>
					)}
				</SweetAlert>
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default DCList;
