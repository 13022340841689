import styled, { css } from 'styled-components';
import { SpaceProps, space, LayoutProps, layout } from 'styled-system';
type StyledAriaCardProps = SpaceProps &
	LayoutProps & {
		category: 'home' | 'family' | 'office' | 'friends';
		deactivated?: boolean;
	};

const StyledAriaCard = styled.div<StyledAriaCardProps>`
	${space}
	${layout}

	--bg: ${({ category }) => {
		if (category === 'home') return '#ecb136';
		if (category === 'family') return '#696C3C';
		if (category === 'office') return '#1B1919';
		if (category === 'friends') return '#D79B9B';
	}};
	min-height: 200px;
	color: #fff;
	border-radius: ${({ theme }) => theme.radii.rounded};
	background: var(--bg);
	background: linear-gradient(
			205deg,
			rgba(255, 255, 255, 0.1) 0%,
			rgba(255, 255, 255, 0.08) 65%,
			rgba(255, 255, 255, 0) 65%,
			rgba(255, 255, 255, 0) 100%
		),
		var(--bg);
	background-size: 200%;
	box-shadow: ${({ theme }) => theme.shadows.medium};

	${({ deactivated }) =>
		deactivated &&
		css`
			/* opacity: 0.8; */
			--bg: #c4c4c4;
		`}

	& .btn-cardoptions {
		position: relative;
		top: -2px;
		padding: 0;
		appearance: none;
		background: none;
		border: none;
		cursor: pointer;
		line-height: 1;
		border-radius: 12px;
		/* margin-right: -12px; */
		margin-left: 2px;

		&:focus {
			outline: none;
			background-color: rgba(255, 255, 255, 0.5);
		}
	}
`;

type UserAssetsProps = SpaceProps & LayoutProps;

const UserAssets = styled.div<UserAssetsProps>`
	${space}
	${layout}

	display: flex;

	& > * {
		flex: 1;
		padding-top: ${({ theme }) => theme.space[3]};
		padding-bottom: ${({ theme }) => theme.space[3]};
		padding-right: ${({ theme }) => theme.space[3]};

		@media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
			padding-top: ${({ theme }) => theme.space[2]};
			padding-bottom: ${({ theme }) => theme.space[2]};
			padding-right: ${({ theme }) => theme.space[2]};
		}
	}

	& > * + * {
		padding-left: ${({ theme }) => theme.space[3]};
		border-left: solid 1px rgba(255, 255, 255, 0.3);

		@media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
			padding-left: ${({ theme }) => theme.space[2]};
		}
	}
`;

const PrimaryLabel = styled.span`
	position: relative;
	top: 1px;
	text-transform: uppercase;
	display: inline-flex;
	align-items: center;
	margin-left: ${({ theme }) => theme.space[2]};
	padding: 6px;
	font-size: 10px;
	font-family: 'OpenSansBlack';
	line-height: 1;
	color: #000;
	background-color: #fff;
	border-radius: 4px;
`;

export { StyledAriaCard, UserAssets, PrimaryLabel };
