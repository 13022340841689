import styled from 'styled-components';

const MainContent = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
	padding: 0 50px;
	@media (max-width: 960px) {
		padding: 0 15px;
	}
	table {
		height: calc(100vh - 470px);
		@media (max-width: 1300px) {
			width: 100%;
		}
		@media (max-width: 768px) {
			height: auto;
		}
		@media (max-width: 640px) {
			min-height: 100px;
		}
		thead,
		tbody {
			tr {
				td,
				th {
					width: 100%;
					max-width: 100%;
					flex: 1;
					text-align: right;
					justify-content: flex-end;
					overflow: visible;
					margin-right: 0;

					input {
						border: 1px solid ${({ theme }) => theme.colors.border.grayLightBorder};
						margin: 10px 0 0;
						width: 130px;
						min-height: 34px;
						text-align: right;
						margin-left: auto;
						&:focus {
							outline: none;
							border: 1px solid ${({ theme }) => theme.baseColors.primary};

						}
						&:disabled {
							background: ${({ theme }) => theme.colors.border.grayLightBorder};
						}
					}

					&:nth-child(3),
					&:nth-child(4) {
						input {
							min-width: 100px;
							width: 100px;
						}
					}
					@media (max-width: 768px) {
						margin-bottom: 15px;
						margin-left: 0;
						text-align: right !important;
						&:before {
							text-align: right !important;
							font-family: 'OpenSansRegular' !important;
						}
						h1 {
							max-width: 100%;
						}
					}
				}
			}
		}
		thead {
			tr {
				th {
					button {
						min-width: 80px;
						margin: 10px auto 0 !important;
					}
					&:nth-child(1) {
						text-align: center !important;
					}
				}
			}

			@media (max-width: 768px) {
				display: block;
				tr {
					display: flex;
					padding: 0;
					margin: 0 0px 20px -10px;
					th {
						&:nth-child(3),
						&:nth-child(4) {
							display: none !important;
						}
						text-align: left;
						&:nth-child(1) {
							max-width: 50% !important;
							flex: 0 0 50% !important;
							input {
								width: 100%;
								min-width: 100%;
							}
							.SearchAutoSuggestionSKUHolder {
								width: 100%;
								min-width: 100%;
							}
						}
						&:nth-child(2),
						&:nth-child(5) {
							max-width: 25% !important;
							flex: 0 0 25% !important;
							input {
								min-width: 100%;
								width: 100%;
							}
						}
					}
				}
			}
			@media (max-width: 480px) {
				display: block;
				padding-top: 20px;
				tr {
					display: flex;
					flex-wrap: wrap;
					align-items: flex-start !important;
					padding: 0;
					th {
						text-align: left;
						&:nth-child(3),
						&:nth-child(4) {
							display: none !important;
						}
						&:nth-child(1) {
							max-width: 100% !important;
							flex: 0 0 100% !important;
							input {
								width: 100%;
								min-width: 100%;
							}
							.SearchAutoSuggestionSKUHolder {
								width: 100%;
								min-width: 100%;
							}
						}
						&:nth-child(2),
						&:nth-child(5) {
							max-width: 50% !important;
							flex: 0 0 50% !important;
							width: 50% !important;
							input {
								min-width: 100%;
								width: 100%;
							}
						}
						&:nth-child(5) {
							div {
								justify-content: center;
								button{
									margin-left: auto!important;
    								margin-right: 0!important;
								}
							}
						}
					}
				}
			}
		}
		tbody {
			td {
				&:nth-child(1) {
					text-align: right !important;
				}
				h1 {
					font-family: 'OpenSansBold';
				}
				&.action {
					button {
						margin-left: auto !important;
						margin-right: auto !important;
					}
				}
			}
		}
	}
`;
const ContentHolder = styled.div`
	height: 100%;
	padding: 0 50px;
	padding-bottom: 30px;
	width: 100%;
	@media (max-width: 768px) {
		padding: 0 15px;
	}
`;
const CardsAndNotificationHolder = styled.div`
	width: 100%;
	display: flex;
	@media (max-width: 860px) {
		flex-direction: column;
	}
`;
const TabContentHolder = styled.div`
	width: 100%;
	display: flex;
	margin-top: 50px;
`;
const BreadcrumbHolder = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media (max-width: 360px) {
		flex-direction: column;
		align-items: end;
		& > div {
			padding-bottom: 15px;
		}
	}
	div {
		width: 100%;
		div {
			width: auto;
		}
		button {
			display: none;
		}
	}
`;
const ButtonWrapper = styled.div`
	display: flex;
	width: 100%;
	justify-content: flex-end;
	@media (max-width: 480px) {
		padding-bottom: 0 !important;
	}
	.app-c-csv-uploaded {
		label {
			font-size: 12px;
			margin-bottom: 3px;
		}
		p {
			font-size: 16px;
		}
		button {
			margin: 0;
			padding: 0;
			border: none !important;
			background: transparent !important;
			outline: none !important;
			box-shadow: none !important;
			&:hover {
				opacity: 0.6;
			}
		}
		.pr-icon {
			margin-right: 0;
			margin-left: 15px;
			svg {
				path {
					fill: ${({ theme }) => theme.colors.text.white};
				}
			}
		}
	}
	button {
		display: flex !important;
		font-size: 18px;
		text-transform: capitalize;
		height: 50px;
		padding-top: 0;
		padding-bottom: 0;
		margin-left: 15px;
		white-space: nowrap;
		@media (max-width: 1200px) {
			font-size: 14px;
		}
		@media (max-width: 980px) {
			width: 40px;
			height: 40px;
			padding: 0;
			align-items: center;
			justify-content: Center;
			div,
			label {
				display: none;
			}
			.pr-icon {
				margin-right: 0 !important;
			}
		}
		&:hover {
			svg {
				path {
					fill: ${({ theme }) => theme.colors.text.white};
				}
			}
		}
		span {
			display: flex;
			align-items: center;
		}
		+ button {
			margin-left: 15px;
			border: 1px solid ${({ theme }) => theme.baseColors.primary};
		}
		.pr-icon {
			margin-right: 15px;
		}
	}
	.app-c-file-upload {
		position: relative;
		cursor: pointer;
		label {
			white-space: nowrap;
		}
		span {
			width: 100%;
			height: 100%;
		}
		input[type='file'] {
			position: absolute;
			left: 0;
			top: 0;
			opacity: 0;
			width: 100%;
			height: 100%;
		}
		.pr-button-label {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			div {
				display: flex;
			}
			h2 {
				width: calc(100% + 40px);
				font-size: 13px;
				font-family: 'OpenSansRegular';
				padding: 3px 0;
				margin: 0 -20px;
				max-width: 180px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}
	}
`;
export {
	MainContent,
	ContentHolder,
	CardsAndNotificationHolder,
	TabContentHolder,
	BreadcrumbHolder,
	ButtonWrapper,
};
