import moment from 'moment';
import React, { ReactElement } from 'react';
import Countdown from 'react-countdown';
import { Box, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import helper from 'utils/helper.functions';

const StyledSupplierMainHeading = styled.div`
	width: 100%;
	h1 {
		line-height: normal;
		margin-bottom: 12px;
	}
	h2 {
		margin-top: 35px;
		float: left;
		width: 100%;
	}
	h3 {
		margin-top: 21px;
		float: left;
		width: 100%;
		br {
			display: none;
			+ strong {
				font-family: 'OpenSansBold';
				color: ${({ theme }) => theme.colors.text.black};
			}
		}
		strong {
			font-family: 'OpenSansBold';
			color: ${({ theme }) => theme.colors.text.error01};
		}
	}
	@media (max-width: 860px) {
		text-align: center;
		h1 {
			font-size: 26px;
		}
		h2,
		h3,
		h4 {
			font-size: 17px;
		}
		h2 {
			strong {
				font-size: 17px;
				margin-top: 7px;
			}
		}
		h3 {
			margin-top: 21px;
		}
		h4 {
			margin-top: 15px;
			br {
				display: block;
			}
		}
	}
`;
// const RatingHolder = styled.div`
// 	width: 100%;
// 	@media (max-width: 860px) {
// 		display: flex;
// 		align-items: center;
// 		justify-content: center;
// 		div {
// 			margin: 0 auto !important;
// 		}
// 	}
// `;
// const recentOrderList = [
// 	{
// 		id: 1,
// 		productHeading: 'Cold Brew Bags',
// 		address: 'Hospital Road,Witherlea Blenheim 7201, New Zealand',
// 		deliveredDate: '6th Dec',
// 		deliveredTime: '02:00:00',
// 		TenPiecesRate: '150',
// 		TwentyPiecesRate: '300',
// 		FiftyPiecesRate: '500',
// 	},
// ];
function SupplierMainHeading(props): ReactElement {
	var deliveryMonth: any;
	if ((props?.processTime?.next_avaliable_time && props?.order_process_time?.next_avaliable_time !== "")) {
		deliveryMonth = helper.formatDate(props?.processTime?.next_avaliable_time);
	}
	const getCountDown = (order_process_time) => {
		//count down timer functionality
		if (order_process_time && order_process_time !== undefined && order_process_time?.next_avaliable_time !== "") {
			const countDownDate = moment(order_process_time?.next_avaliable_time, "DD-MM-YYYY HH:mm:ss").valueOf();
			const now = moment(order_process_time?.current_time, "DD-MM-YYYY HH:mm:ss").valueOf();
			const distance = countDownDate - now;
			return distance;
		} else {
			return 0;
		}
	};
	return (
		<StyledSupplierMainHeading>
			{props.SupplierInfo && (
				<Box m="0" key="">
					<Text
						as="h1"
						fontSize="36px"
						fontFamily="OpenSansBold"
						color="text.black"
						className="truncate"
					>
						{props.SupplierInfo.name}
					</Text>
					{/* <RatingHolder>
						<Rating></Rating>
					</RatingHolder> */}

					<Text as="h2" fontSize="20px" fontFamily="OpenSansRegular" color="text.black03">
						{props.SupplierInfo.SupplierAddress}
					</Text>
					{deliveryMonth &&
						<Text as="h3" fontSize="20px" fontFamily="OpenSansRegular" color="text.black03">
							Order in{' '}
							<strong>
								<Countdown date={Date.now() + getCountDown(props?.processTime)} />
							</strong>
							<br></br> to get it processed on{' '}
							<strong>
								{deliveryMonth}
							</strong>
						</Text>
					}
				</Box>
			)}
		</StyledSupplierMainHeading>
	);
}

export { SupplierMainHeading };
