import React, { ReactElement } from 'react';
import { Text, Box } from 'rebass/styled-components';
import styled from 'styled-components';
import { RatingXl } from 'ui/components';
const StyledSupplierMainHeadingRating = styled.div`
	width: 100%;
	h1 {
		line-height: normal;
		margin-bottom: 21px;
		margin-top:60px;
		font-size:24px;
	}
	h2 {
		margin-top: 35px;
		float: left;
		width: 100%;
	}
	h3 {
		margin-top: 21px;
		float: left;
		width: 100%;
		br {
			display: none;
			+ strong {
				font-family: 'OpenSansBold';
				color: ${({ theme }) => theme.colors.text.black};
			}
		}
		strong {
			font-family: 'OpenSansBold';
			color: ${({ theme }) => theme.colors.text.error};
		}
	}
	@media (max-width: 860px) {
		text-align: center;
		h1 {
			font-size: 26px;
		}
		h2,
		h3,
		h4 {
			font-size: 17px;
		}
		h2 {
			strong {
				font-size: 17px;
				margin-top: 7px;
			}
		}
		h3 {
			margin-top: 21px;
		}
		h4 {
			margin-top: 15px;
			br {
				display: block;
			}
		}
	}
`;
const RatingHolder = styled.div`
	width: 100%;
	@media (max-width: 860px) {
		display: flex;
		align-items: center;
		justify-content: center;
		div {
			margin: 0 auto !important;
			width: 100%;
		}
	}
	.review-count {
		display: inline;
		float: none;
		line-height: 22px;
		@media (max-width: 860px) {
			margin: 0;
		}
	}
	.rating {
		@media (max-width: 860px) {
			float: none!important;
		}
	}
`;
function SupplierReviewRating(props): ReactElement {
	return (
		<StyledSupplierMainHeadingRating>
			<Box m="0">
				<Text
					as="h1"
					fontFamily="OpenSansBold"
					color="text.black"
				>
					Supplier Rating
					</Text>
				<RatingHolder onChange={props.getSupplierRating} >
					<RatingXl reviewId={props?.reviewId} value={props.supplierRating ? props.supplierRating : ''}></RatingXl>
				</RatingHolder>
			</Box>
		</StyledSupplierMainHeadingRating>
	);
}

export { SupplierReviewRating };
