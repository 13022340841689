import { userTypes } from 'models/enum-constants/common.constants';
import React, { ReactElement, useState } from 'react';
import { Flex, Text } from 'rebass/styled-components';
import { RadioButton } from 'ui/components';
import * as Icon from 'ui/icons';
import utilityFunctions from 'utils/utility.functions';
import { AddToFavorites, AddToFavoritesContainer } from '../../ui/patterns/productGridView/addToFavorites/styles';


export default function LocationSelector(props): ReactElement {
    var venueUserId = localStorage.getItem('userId');
    var userType = localStorage.getItem('userType');
    // var locationOptions: HVenueDetails[];
    var locationOptions = [] as any;
    locationOptions = props.qry;
    const [addSelectedVenue, setAddSelectedVenue] = useState('');


    const handleOnChange = async(e: any) => {
       let id = e.target.id;
        if (venueUserId) {
            setAddSelectedVenue(id);
            localStorage.setItem('selectedLocation', id);
            await utilityFunctions.setPermissions(id,false);
            props.parentCallback(id);
            props.togglePopup();
        }
        
        // else {
        //     setAddLocation(e.target.id);
        // }
    };
    // const saveLocation = () => {
    //     localStorage.setItem('selectedLocation', addLocation);
    //     props.parentCallback(addLocation);
    //     props.togglePopup();
    // }
    // const saveSelectedVenue = () => {
    //     localStorage.setItem('selectedLocation', addSelectedVenue);
    //     props.parentCallback(addSelectedVenue);
    //     props.togglePopup();
    // }
    // const closePopup = () => {
    //     props.togglePopup();
    // }

    return (
        <AddToFavorites>
            <Text as="h1" color="text.textHeading" fontSize={22} fontFamily="OpenSansBold">
                Choose Your Location            
            </Text>
            <AddToFavoritesContainer>
                {locationOptions && userType == userTypes.supplierAdmin && locationOptions
                .filter((x) => x.is_delete != true && x.is_active == true)
                .map(({ dc_id, name }) => (
                    <RadioButton id={dc_id} key={dc_id} name="paymentMethod" onChange={handleOnChange} hasActiveText>
                        <Flex alignItems="center" width="100%" pl={[1, 2]} sx={{ transform: 'translateY(-3px)' }}>
                            <Text as="h1" fontFamily="OpenSansSemiBold" flex="1" px={2}>
                                {name}
                            </Text>
                            {(addSelectedVenue === dc_id.toString()) ? <Icon.Tick /> : ``}
                        </Flex>
                    </RadioButton>
                ))}

                {locationOptions && userType == userTypes.supplierUser && locationOptions
                .filter((x) => x.is_delete != true && x.is_active == true)
                .map(({ dc_id, dc_name }) => (
                    <RadioButton id={dc_id} key={dc_id} name="paymentMethod" onChange={handleOnChange} hasActiveText>
                        <Flex alignItems="center" width="100%" pl={[1, 2]} sx={{ transform: 'translateY(-3px)' }}>
                            <Text as="h1" fontFamily="OpenSansSemiBold" flex="1" px={2}>
                                {dc_name}
                            </Text>
                            {(addSelectedVenue === dc_id.toString()) ? <Icon.Tick /> : ``}
                        </Flex>
                    </RadioButton>
                ))}
            </AddToFavoritesContainer>
            {/* <Stack direction="x" gap={3} justifyContent="center">
                <Button flex={[1, 1, 'initial']} variant="secondary" onClick={closePopup}>
                    Cancel
				</Button>
                <Button onClick={saveSelectedVenue} flex={[1, 1, 'initial']}>Save</Button>
            </Stack> */}
        </AddToFavorites>
    );
}
