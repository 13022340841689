/**
 * Library used for handling HTTP request : axios
 * GIT: https://github.com/axios/axios
 * NPM: https://www.npmjs.com/package/axios
 */

import Axios, { AxiosPromise, AxiosRequestConfig } from 'axios';

import env, { APP_TOKEN } from './constants';
import constants from './constants';

// default SERVICE_URL will go here.
const API_URL = env.baseURL();
const API_BULK_URL = env.bulkUrl();
// const CUSTOMER_TOKEN = localStorage.getItem('customerToken');

const axiosConfig: AxiosRequestConfig = {
	baseURL: API_URL,
	headers: {
		'Content-Type': 'application/json'
	},
};

const axiosBulkConfig: AxiosRequestConfig = {
	baseURL: API_BULK_URL,
	headers: {
		'Content-Type': 'application/json'
	},
};

function getApiConfig({ headers = {} as any}) {
	let config = axiosConfig;
	return getMainConfig(headers,config);
}

function getBulkApiConfig({ headers = {} as any}) {
	let config = axiosBulkConfig;
	return getMainConfig(headers,config);
}
function getMainConfig(headers,config){
	const mainConfig: AxiosRequestConfig = {
		...config,
		headers: {
			...axiosConfig.headers,
			...headers,
			Authorization: `Bearer ${typeof (headers.Authorization) !== 'undefined' ? headers.Authorization : APP_TOKEN}`,//getAccessToken()
		},
	};
	 return mainConfig;
}

const ApiCall = (ajaxParams: any): AxiosPromise => Axios(ajaxParams);

export function delay(time: number, value: number | string = 0): Promise<number | string> {
	return new Promise((resolve) => setTimeout(resolve.bind(null, value), time));
}

function GET({ url = '', params = {}, headers = {} }: AxiosRequestConfig): AxiosPromise {
	const config = getApiConfig({ headers });

	const ajaxParams = {
		...config,
		url,
		params,
		method: 'GET',
	};

	return ApiCall(ajaxParams);
}

function POST({
	url = '',
	params = {},
	data = {},
	headers = {},
}: AxiosRequestConfig): AxiosPromise {
	const config = getApiConfig({ headers });

	const ajaxParams = {
		...config,
		url,
		data,
		params,
		method: 'POST',
	};

	return ApiCall(ajaxParams);
}

function BULKPOST({
	url = '',
	params = {},
	data = {},
	headers = {},
}: AxiosRequestConfig): AxiosPromise {
	const config = getBulkApiConfig({ headers });
	
	const ajaxParams = {
		...config,
		url,
		data,
		params,
		method: 'POST',
	};

	return ApiCall(ajaxParams);
}

function GRAPHQLPOST({
	query = '',
	headers = {},
}): AxiosPromise {
	return Axios.post(
		constants.graphqlURL(), {
		query: query
	}
	);
}

function PUT({ url = '', params = {}, data = {}, headers = {} }: AxiosRequestConfig): AxiosPromise {
	const config = getApiConfig({ headers });

	const ajaxParams = {
		...config,
		url,
		data,
		params,
		method: 'PUT',
	};

	return ApiCall(ajaxParams);
}

function PATCH({
	url = '',
	params = {},
	data = {},
	headers = {},
}: AxiosRequestConfig): AxiosPromise {
	const config = getApiConfig({ headers });

	const ajaxParams = {
		...config,
		url,
		data,
		params,
		method: 'PATCH',
	};

	return ApiCall(ajaxParams);
}

function DELETE({
	url = '',
	params = {},
	data = {},
	headers = {},
}: AxiosRequestConfig): AxiosPromise {
	const config = getApiConfig({ headers });

	const ajaxParams = {
		...config,
		url,
		data,
		params,
		method: 'DELETE',
	};

	return ApiCall(ajaxParams);
}

export default {
	GET,
	POST,
	BULKPOST,
	PUT,
	PATCH,
	DELETE,
	GRAPHQLPOST
};
