import styled from 'styled-components';
const StyledCart = styled.div`
	display: flex;
	align-items: center;
	margin: 12px 0 0 0;
	svg {
		path {
			fill: ${({ theme }) => theme.baseColors.white};
		}
	}
	.amount {
		color: ${({ theme }) => theme.baseColors.white};
		margin-left: 23px;
		font-family: 'OpenSansBold';
		font-size: 18px;
		line-height: 23px;
		margin-top: -7px;
	}
	button {
		background: transparent !important;
		border: none !important;
		outline: none !important;
		box-shadow: none !important;
	}
`;
export { StyledCart };
