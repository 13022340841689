import React, { ReactElement } from 'react';
import { FormLabel, StyledFormFeedback, Textarea } from '../../components';
import { FormGroup } from '../index';

type RefReturn =
	| string
	| ((instance: HTMLInputElement | null) => void)
	| React.RefObject<HTMLInputElement>
	| null
	| undefined;

export type FormTextareaProps = {
	label?: string | any;
	name?: string;
	value?: string;
	onTextChange?: (text: string) => void;
	placeholder?: string;
	disabled?: boolean;
	readonly?: boolean;
	validation?: 'error' | 'success' | 'warning';
	feedback?: string;
	required?: boolean;
	rows?: number;
	cols?: number;
	validationMessage?: string;
	register?: ({ required }: { required?: boolean | string }) => RefReturn;
	maxLength?:number;
};

const FormTextarea = ({
	label,
	name,
	value,
	onTextChange,
	placeholder,
	disabled,
	readonly,
	validation,
	feedback,
	required,
	rows,
	cols,
	validationMessage,
	register,
	maxLength
}: FormTextareaProps): ReactElement => {
	// const id = `input-${label.replace(' ', '').toLowerCase()}`;
	return (
		<FormGroup>
			<FormLabel htmlFor={name} required={required} disabled={disabled}>
				{label}
			</FormLabel>
			<Textarea
				placeholder={placeholder}
				value={value}
				id={name}
				disabled={disabled}
				validation={validation}
				register={register}
				rows={rows}
				cols={cols}
				readonly={readonly}
				maxLength={maxLength}
				onTextChange={onTextChange}
			/>
			{feedback ? (
				<StyledFormFeedback validation={validation} disabled={disabled}>
					{feedback}
				</StyledFormFeedback>
			) : null}
			<span style={{ fontSize: 12, color: 'red' }}>{validationMessage}</span>
		</FormGroup>
	);
};

export { FormTextarea };
