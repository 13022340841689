import React, { ReactElement, useState } from 'react';
import { Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { Button, Stack } from 'ui/components';

const AddToFavorites = styled.div`
	display: flex;
	height: auto;
	position: relative;
	flex-direction: column;
	background: ${({ theme }) => theme.colors.bg.white};
	padding: 0 10px 12px;
	@media (max-width: 960px) {
		padding: 0;
	}
	h1 {
		margin: 0 0 41px 0;
		width: 100%;
		font-weight: normal;
		text-transform: uppercase;
	}
	button {
		padding: 0;
		min-width: 100%;
		min-height: 50px;
		text-transform: capitalize;
		font-size: 18px;
		margin: 0;
		font-family: 'OpenSansBold';
		& + button {
			margin-top: 28px;
		}
	}
`;

export default function AssignUsersModal(prop): ReactElement {
	// const [assignUsers, setAssignUsers] = useState('');

	// const handleOnChange = (e: any) => {
	// 	setAssignUsers(e.target.id);
	// };
	return (
		<AddToFavorites>
			<Text as="h1" color="text.textHeading" fontSize={18} fontFamily="OpenSansSemiBold">
				Assign Users
			</Text>

			<Stack direction="x" gap={3} justifyContent="center" flexDirection="column">
				<Button flex={[1, 1, 'initial']} onClick={()=>prop.addExistingUsers && prop.addExistingUsers('existing')}>Add from existing Users</Button>
				<Button flex={[1, 1, 'initial']} variant="secondary" onClick={()=>prop.addExistingUsers && prop.addExistingUsers('new')}>
					Add New User
				</Button>
			</Stack>
		</AddToFavorites>
	);
}
