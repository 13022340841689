import { UseGlobalState } from 'models/globalStateAccess';
import Iget from 'models/Iget';
import React, { ReactElement, useEffect, useState } from 'react';
import 'react-tippy/dist/tippy.css';
import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import helperFunctions from 'utils/helper.functions';
import hooks from 'utils/requestCtx/reactQueryHooks';

const StyledCheckoutVenueDetails = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	column-gap: 20px;
	row-gap: 20px;
	margin-top: 16px;
	margin-bottom: 16px;
	.order-details__wrap {
		flex-direction: row;
		background: ${({ theme }) => theme.colors.bg.white};
		border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
		box-sizing: border-box;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 16px;
		overflow: hidden;
		transition: box-shadow 100ms ease-in, transform 100ms ease-in;
		align-items: flex-start;
		height: 100%;
		padding: 25px;
		span {
			max-width: 117px;
			flex: 0 0 117px;
			height: 140px;
			background-repeat: no-repeat;
			background-size: contain;
			@media (max-width: 1152px) {
				height: 242px;
			}
			@media (max-width: 768px) {
				max-width: 126px;
				flex: 0 0 126px;
				height: 100%;
				background-position: center center;
			}
			@media (max-width: 360px) {
				max-width: 80px;
				flex: 0 0 80px;
			}
		}
		section {
			display: grid;
			grid-template-columns: 1fr 3fr !important;
			height: 100%;
			column-gap: 30px;
			align-items: start;
			@media (max-width: 1224px) {
				grid-template-columns: repeat(1, 1fr) !important;
				label {
					width: 100%;
					float: left;
					margin: -6px 0 10px 0;
					line-height: normal;
				}
			}
			@media (max-width: 480px) {
				padding: 15px;
			}
		}
		&.selected {
			background: ${({ theme }) => theme.newColors.lightgray};
		}
	}
`;
// const checkoutVenuDetails = [
// 	{
// 		id: 1,
// 		venueName: 'Hinds Burgh',
// 		address: '24 Avenue, Laker’s St, Hindsburgh, New Zealand 2103',
// 	},
// ];

function CheckoutVenueDetails(props): ReactElement {
	const [venueDetails, setVenueDetails] = useState<any>({});
	// const [cartDetails, setCartDetails] = useState<any>([]);
	const [state, dispatch] = UseGlobalState();
	
	var id = localStorage.getItem('selectedVenue');
	const venueKey = 'venueDetails';
	const venueUrl = `experion/venue/get/${id}`;
	const item: Partial<Iget> = {
		params: {},
		url: venueUrl,
		enabled: true,
		key: venueKey,
	};
	const venueEditDetails = hooks.useQuery(item);

	useEffect(() => {
		setVenueDetails(venueEditDetails.data);
		dispatch({venueDetails: venueEditDetails.data});
		//FIXME: REMOVE
		props.setVenueDetails(venueEditDetails.data);
	}, [venueEditDetails.data])

	return (
		<StyledCheckoutVenueDetails>
				<Flex className="order-details__wrap" bg="white" sx={{ alignItems: 'center' }} key={id}>
					<Flex as="section" flexWrap="wrap" width="100%" maxWidth={['100%']}>
						<Box>
							<Text mb={1} fontSize={18} color="text.black">
								Store Name
							</Text>
							<Text as="label" fontSize={20} fontFamily="OpenSansBold" color="text.black">
								{venueDetails?.name}
							</Text>
						</Box>
						<Box>
							<Text mb={1} fontSize={18} color="text.black">
								Address
							</Text>
							<Text as="label" fontSize={20} fontFamily="OpenSansBold" color="text.black">
								{venueDetails?.city}, 
								{
									venueDetails?.state?.length <= 4 ? 
										helperFunctions.getRegionName(venueDetails.country, venueDetails.state).name 
										: 
										venueDetails.state 
								}, 
								{
									venueDetails?.country?.length <= 2 ? 
										helperFunctions.getCountryName(venueDetails.country) 
										: 
										venueDetails.country
								} 
							</Text>
						</Box>
					</Flex>
				</Flex>
		</StyledCheckoutVenueDetails>
	);
}

export { CheckoutVenueDetails };
