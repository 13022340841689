import styled, { css } from 'styled-components';
import { layout, LayoutProps, space, SpaceProps } from 'styled-system';

type SubHeadingProps = SpaceProps &
	LayoutProps & {
		tabSubHeading?: boolean;
	};
const SubHeading = styled.div<SubHeadingProps>`
	${space}
	${layout}
	margin-top: 50px;
	float: left;
	width: 100%;
	.pr-icon {
		margin-right: 7px;
	}
	div {
		text-transform: uppercase;
		text-align: left;
		font-size: 18px;
		line-height: 22px;
		font-family: 'OpenSansBold';
		display: grid;
		grid-template-columns: auto 1fr auto auto;
		grid-gap: var(--metric-keyline-gap);
		align-items: center;
		width: 100%;
		color: ${({ theme }) => theme.colors.text.black};

		&::after {
			content: '';
			height: 1px;
			background: ${({ theme }) => theme.colors.border.grayMedium};
			margin-left: 9px;
		}
	}
	@media (max-width: 768px) {
		text-align: center;
		margin-top: 40px;
		div {
			text-align: center;
			display: flex;
			justify-content: center;
			&::after {
				display: none;
			}
		}
	}
	@media (max-width: 480px) {
		div {
			font-size: 15px;
		}
	}
	${({ tabSubHeading }) =>
		tabSubHeading &&
		css`
			margin-top: 84px;
			div {
				&::after {
					content: '';
					height: 2px;
				}
			}
		`}
`;

export { SubHeading };
