import styled from 'styled-components';

const MainContent = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
	padding: 0 50px 30px;
	@media (max-width: 960px) {
		padding: 0 15px 15px;
	}
	table {
		thead,
		tbody {
			margin-bottom: 0;
			tr {
				td,
				th {
					&:nth-child(0) {
						flex: 0 0 450px;
						max-width: 450px;
						@media screen and (max-width: 767px) {
							flex: 0 0 100% !important;
							max-width: 100% !important;
							margin-bottom: 15px;
						}
					}
				}
			}
		}
		tbody{
			margin-bottom: 0!important;
		}
	}
`;
const ContentHolder = styled.div`
	height: 100%;
	padding: 0 50px;
	padding-bottom: 30px;
	width: 100%;

	@media (max-width: 768px) {
		padding: 0 15px;
	}
`;
const BreadcrumbHolder = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media (max-width: 360px) {
		flex-direction: column;
		align-items: end;
		& > div {
			padding-bottom: 15px;
		}
	}
`;
const ButtonWrapper = styled.div`
	display: flex;
	@media (max-width: 480px) {
		padding-bottom: 0 !important;
	}
	button {
		display: flex !important;
		font-size: 18px;
		text-transform: capitalize;
		height: 50px;
		padding-top: 0;
		padding-bottom: 0;
		width: 190px;
		@media (max-width: 767px) {
			width: 40px;
			height: 40px;
			padding: 0;
			align-items: center;
			justify-content: Center;
			div {
				display: none;
			}
			.pr-icon {
				margin-right: 0 !important;
			}
		}
		&:hover {
			svg {
				path {
					fill: ${({ theme }) => theme.colors.text.white};
				}
			}
		}
		span {
			display: flex;
			align-items: center;
		}
		+ button {
			margin-left: 15px;
			border: 1px solid ${({ theme }) => theme.baseColors.primary};
		}
		.pr-icon {
			margin-right: 15px;
		}
	}
`;
export { MainContent, ContentHolder, BreadcrumbHolder, ButtonWrapper };
