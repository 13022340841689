import { venueUserPermissionList } from 'models/enum-constants/common.constants';
import Iget from 'models/Iget';
import Ipost from 'models/Ipost';
import IProduct from 'models/IProduct';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import { Box, Button, Flex, Image, Text } from 'rebass/styled-components';
import icons from 'ui/icons';
import no_image from 'ui/images/no_image.png';
import { Modal } from 'ui/patterns';
import urls from 'utils/create.url';
import helperFunctions from 'utils/helper.functions';
import http from 'utils/http.service';
import pageConstants from 'utils/pageConstants';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import utilityFunctions from 'utils/utility.functions';
import Dairy from '../../images/allergens/diary.svg';
import Egg from '../../images/allergens/egg.svg';
import Fish from '../../images/allergens/fish.svg';
import Gluten from '../../images/allergens/gluten.svg';
import Lupin from '../../images/allergens/lupin.svg';
import Milk from '../../images/allergens/milk.svg';
import Peanuts from '../../images/allergens/peanuts.svg';
import SesameSeeds from '../../images/allergens/sesame-seeds.svg';
import Shellfish from '../../images/allergens/shellfish.svg';
import Soy from '../../images/allergens/soy.svg';
import TreeNut from '../../images/allergens/treenut.svg';
import AddToFavoritesModal from './addToFavorites';
import { IconHolder, StyledProductListView } from './styles';

function ProductListView({ isFetching, data }: IProduct): ReactElement {
	const history = useHistory();

	const [showAddToFavoritesModal, setShowAddToFavoritesModal] = useState(false);
	const [selectedFavourite, setSelectedFavourite] = useState('');
	const [venueUserId] = useState<any>(localStorage.getItem('userId'));
	var [collectionName, setCollection] = useState<any>();
	var [favoriteList, setFavoriteList] = useState<any>([]);
	const [userName, setUserName] = useState<any>('');
	const [venueId] = useState<any>(localStorage.getItem('selectedVenue'));
	const [selectedId, setSelectedId] = useState(0);
	const [isExist, setIsExist] = useState(false);
	const key = 'FavoriteKey';
	const [mutatePost] = hooks.useMutation(key);
	const [minValue, setMinValue] = useState(0);

	const toggleAddToFavoritesModal = (event, id, minValue) => {
		event.stopPropagation();
		fetch();
		setIsExist(false);
		setSelectedId(id);
		setMinValue(minValue);
		setShowAddToFavoritesModal(!showAddToFavoritesModal);
	};
	const callbackFunction = (childData) => {
		setSelectedFavourite(childData);
	};
	// get all favorites api call
	const obj1: Partial<Iget> = {
		params: {},
		url: urls.GetSearchUrl({
			url: 'experion/productmanagement/favorite/search?',
			fields: [
				{ field_name: 'venue_user_id', field_value: venueUserId },
				{ field_name: 'venue_id', field_value: localStorage.getItem('selectedVenue')! },
			],
		}),
		enabled: false, //manual fetch
		key: 'favKey',
		resultFilteringRequired: false,
	};
	var qryFav = hooks.useQuery(obj1);

	// async function togglePopup() {
	// 	await setshowFavouriteNotFoundModal(!showFavouriteNotFoundModal);
	// }
	const fetch = () => {
		qryFav.refetch().then((data) => {
			if (data) {
				favoriteList = [];
				data.items.forEach((item) => {
					if (item.is_delete === false) {
						favoriteList.push(item);
						setFavoriteList((_) => [...favoriteList]);
					}
				});
				// setError({ ...error, venueNameError: 'Venue Name already exists' });
			} else {
				// setError({ ...error, venueNameError: '' });
			}
		});
	};
	useEffect(() => {
		setCollection(collectionName);
	}, [collectionName]);

	const validateNewCollection = (event) => {
		event.stopPropagation();
		if (event.currentTarget.value.length > 0) {
			setCollection(event.currentTarget.value);

			setUserName(localStorage.getItem('userName'));
		}
	};

	const formatString = (val) => {
		let temp = val;
		temp = temp.replace(/\s+/g, '');
		temp = temp.toLowerCase();
		switch (temp) {
			case 'gluten':
				return Gluten;
				break;
			case 'egg':
				return Egg;
				break;
			case 'dairy':
				return Dairy;
				break;
			case 'shellfish':
				return Shellfish;
				break;
			case 'fish':
				return Fish;
				break;
			case 'lupin':
				return Lupin;
				break;
			case 'milk':
				return Milk;
				break;
			case 'peanuts':
				return Peanuts;
				break;
			case 'treenuts':
				return TreeNut;
				break;
			case 'sesameseeds':
				return SesameSeeds;
				break;
			case 'soy':
				return Soy;
				break;
			default:
				break;
		}
	};

	function saveToCollection(event) {
		event.stopPropagation();
		if (collectionName != undefined) {
			http
				.GET({ url: `/experion/productmanagement/favorite/${collectionName}/${venueUserId}` })
				.then(function (data) {
					//validate newly added favourite api call
					setIsExist(data.data);
					if (data.data === false) {
						if (!isExist) {
							let favJson = {
								favorite: {
									venue_user_id: venueUserId,
									favorite_name: collectionName,
									product_id: selectedId,
									created_by: userName,
									updated_by: userName,
									is_active: true,
									venue_id: venueId,
								},
							};

							const obj: Partial<Ipost> = {
								data: favJson,
								params: {},
								url: '/experion/productmanagement/favorite/create',
								message: 'saved to collection!',
							};
							try {
								mutatePost(obj).then((data) => {
									if (data !== undefined) {
										fetch();
									}
								});
							} catch (e) { }
						}
					}
				});
		}
	}

	async function togglePopup() {
		await setShowAddToFavoritesModal(!showAddToFavoritesModal);
	}

	const saveProductToFav = (value) => {
		let favJson = {
			favoriteOrder: {
				id: selectedId,
				venue_user_id: venueUserId,
				favorite_name: collectionName,
				created_by: userName,
				updated_by: userName,
				is_active: true,
				venue_id: venueId,
				is_delete: false,
				favorite_items: [
					{
						product_id: selectedId,
						qty: minValue,
						is_active: true,
						is_delete: false,
					},
				],
			},
		};

		const obj: Partial<Ipost> = {
			data: favJson,
			params: {},
			url: '/experion/productmanagement/favorite/updatefavorite/' + value,
			message: 'Added to favorites',
		};
		try {
			api.put(obj).then((data) => {
				if (data !== undefined) {
					togglePopup();
				}
			});
		} catch (e) { }
	};
	return (
		<StyledProductListView>
			{!isFetching &&
				data ?
				data?.items &&
				data.items?.map((item, index) => {
					let img = '';
					if (item.media_gallery_entries && item.media_gallery_entries.length > 0)
						img = `${item.media_gallery_entries[0].file}`;
					else img = no_image; // img = `${env.imageURL()}${item.media_gallery_entries[0].file}`;
					let creditStatus = '';
					if (item?.extension_attributes?.credit_status.length === 0) {
						creditStatus = 'go';
					} else {
						item?.extension_attributes?.credit_status.forEach((obj) => {
							if (parseInt(venueId) === obj) {
								creditStatus = 'stop';
							}
						});
					}
					let ClassName = '';
					if (parseInt(item.extension_attributes.qty) === 0) {
						ClassName = 'row-highlighted';
					} else if (creditStatus == 'stop') {
						ClassName = 'un-available';
					}
					let onVacation = 0;
					let EndOfVacation = '';
					if (onVacation === 1) {
						EndOfVacation = '18 Apr 2021';
					}
					let showSpclPrice = false;
					let date = moment().format('YYYY-MM-DD');
					let fromDate =
						item.extension_attributes?.multi_seller_product_special_from_date !== ''
							? moment(item.extension_attributes?.multi_seller_product_special_from_date).format(
								'YYYY-MM-DD'
							)
							: '';
					let toDate =
						item.extension_attributes?.multi_seller_product_special_to_date !== ''
							? moment(item.extension_attributes?.multi_seller_product_special_to_date).format(
								'YYYY-MM-DD'
							)
							: '';
					if (fromDate !== '' && toDate !== '') {
						if (
							moment(date).isSameOrAfter(fromDate, 'date') &&
							moment(date).isSameOrBefore(toDate, 'date')
						) {
							showSpclPrice = true;
						} else {
							showSpclPrice = false;
						}
					} else if (fromDate !== '' && toDate === '') {
						if (moment(date).isSameOrAfter(fromDate, 'date')) {
							showSpclPrice = true;
						} else {
							showSpclPrice = false;
						}
					} else {
						showSpclPrice = false;
					}
					return (
						<Flex
							className={ClassName}
							style={{ cursor: 'pointer' }}
							as="article"
							bg="white"
							sx={{ alignItems: 'center' }}
							key={index}
							onClick={() => {
								history.push({
									pathname: '/home/product-details',
									search: '?id=' + item.sku,
								});
							}}
						>
							<Flex alignItems="center" as="span" maxWidth={['100%']} sx={{ overflow: 'hidden' }}>
								<Box
									as="picture"
									style={{
										backgroundImage: 'url(' + img + ')',
										backgroundColor: 'white'
									}}
								></Box>
								{onVacation === 1 ? (
									<Flex as="div" className="temp-unavailable" flexDirection="column">
										<Box>Temporarily unavailable till</Box>
										<Box className="date"> {EndOfVacation} </Box>
									</Flex>
								) : null}
								{utilityFunctions.isGranted(venueUserPermissionList.FavoriteOrderAdd) && (
									<Button
										onClick={(event) => {
											event.stopPropagation();
											toggleAddToFavoritesModal(
												event,
												item.id,
												item.extension_attributes.min_order_qty
											)
										}
										}
									>
										<icons.FavoritesIcon />
										<Text>Add to Favorites</Text>
									</Button>
								)}
							</Flex>
							<Flex as="section" flexDirection="column" width="100%" maxWidth={['100%']}>
								<Box as="slot">
									<Box>
										<Text
											as="h1"
											fontFamily="OpenSansBold"
											fontSize="20px"
											lineHeight="26px"
											color="text.black"
											className="truncate"
										>
											{item.name}
										</Text>
										<Text
											as="h2"
											fontSize="17px"
											fontFamily="OpenSansRegular"
											className="truncate"
											lineHeight="22px"
										>
											{item.extension_attributes.ddg_brand}
										</Text>
									</Box>

									<Box>
										<Text
											as="h3"
											fontSize="20px"
											fontFamily="OpenSansBlack"
											color="text.black"
											className="truncate"
											lineHeight="26px"
											textAlign="right"
										>
											{showSpclPrice && parseFloat(
												item.extension_attributes?.multi_seller_product_special_price
											) > 0
												? '$' + parseFloat(
													item.extension_attributes?.multi_seller_product_special_price
												).toFixed(2)
												: '$' + parseFloat(item.price).toFixed(2)}
										</Text>
										<Text
											as="h4"
											fontSize="14px"
											fontFamily="OpenSansRegular"
											color="text.black"
											className="truncate"
											lineHeight="18px"
											textAlign="right"
										>
											{item.extension_attributes?.uomname &&
												item.extension_attributes?.uomname.length > 0
												? item.extension_attributes?.uomname
												: 'per Pack'}
										</Text>
									</Box>
								</Box>
								{/* <Box as="slot">
									<Box>
										<Text
											as="h5"
											fontFamily="OpenSansRegular"
											fontSize="16px"
											lineHeight="21px"
											color="text.black"
											className="truncate"
										>
											Minimum Order :{' '}
											<strong>{!item.min_order_qty ? 0 : item.min_order_qty}</strong> Pack
										</Text>
									</Box>
								</Box> */}
								<Box as="slot">
									<Box>
										{/* <Text
											as="label"
											fontSize="17px"
											fontFamily="OpenSansSemiBold"
											lineHeight="22px"
											color="text.black"
											className="truncate"
										>
											{item.extension_attributes.product_categories.map((val, index) => {
												return index == item.extension_attributes.product_categories.length - 1
													? ` ${val}`
													: ` ${val} ,`;
											})}
										</Text> */}

										<Text
											as="h6"
											fontSize="17px"
											fontFamily="OpenSansRegular"
											className="truncate"
											lineHeight="22px"
											style={{ textDecoration: 'none', cursor: 'default' }}
										>
											{item.extension_attributes.brand_name}
										</Text>
										<Text
											as="label"
											fontSize="17px"
											fontFamily="OpenSansRegular"
											className="truncate"
											lineHeight="22px"
											onClick={(e) => {
												e.stopPropagation();
												history.push({
													pathname: '/suppliers/seller-details',
													search:
														'?sku=' +
														item.sku +
														'&id=' +
														item.extension_attributes.supplier_id +
														'&cat=' +
														item.extension_attributes.product_categories[0] +
														'&pdt=' +
														item.name,
												});
											}}
										>
											{item.extension_attributes.supplier_name}
										</Text>
									</Box>
								</Box>
								<IconHolder onClick={(e) => e.stopPropagation()}>
									{item.extension_attributes.allergen_names.map((v, i) => {
										if (i <= 3) {
											return (
												<Tooltip
													key={helperFunctions.getRandomInt(pageConstants.maxValue)}
													arrow
													title={v}
													animation="perspective"
													position="top"
													trigger="mouseenter"
												>
													{/* <icons.GoodServiceIcon /> */}
													<div className="img-block">
														<Image src={formatString(v)} />
													</div>
												</Tooltip>
											);
										}
									})}

									<Tooltip
										key={helperFunctions.getRandomInt(pageConstants.maxValue)}
										arrow
										animation="perspective"
										trigger="click"
										position="top"
										html={
											<div className="tooltip-body">
												{item.extension_attributes.allergen_names.map((v, i) => {
													if (i > 3) {
														return (
															<span key={helperFunctions.getRandomInt(pageConstants.maxValue)}>
																{/* <icons.GoodServiceIcon /> */}
																<div className="img-block">
																	<Image
																		src={formatString(v)}
																		sx={{
																			width: 30,
																			height: 30,
																			border: 0,
																		}}
																	/>
																</div>
																{<div>{v}</div>}
															</span>
														);
													}
												})}
											</div>
										}
									>
										{item.extension_attributes.allergen_names?.length > 4 && (
											<span key={helperFunctions.getRandomInt(pageConstants.maxValue)}>
												{item.allergens} <div>More</div>
											</span>
										)}
									</Tooltip>
								</IconHolder>
							</Flex>
						</Flex>
					);
				}) :
				new Array(12).fill(null).map((_, i) => {
					return <Skeleton key={i} width="920px" height="200px" />;
				})}
			<Modal
				width="600px"
				showModal={showAddToFavoritesModal}
				setShowModal={setShowAddToFavoritesModal}
			>
				<AddToFavoritesModal
					isExist={isExist}
					validateNewCollection={validateNewCollection}
					saveToCollection={(event) => saveToCollection(event)}
					favoriteList={favoriteList}
					parentCallback={callbackFunction}
					togglePopup={togglePopup}
					saveProductToFav={saveProductToFav}
				/>
			</Modal>
		</StyledProductListView>
	);
}

export { ProductListView };
