import styled from 'styled-components';

const Nav = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	z-index: 999;
	@media (max-width: 960px) {
		margin: 20px 0 !important;
		justify-content: center;
		padding: 0 15px;
		&.ProductManangement {
			justify-content: center;
			margin: 0 !important;
			a {
				margin-bottom: 15px !important;
			}
		}
	}
	@media (max-width: 480px) {
		&.ProductManangement {
			flex-wrap: wrap;

			a {
				width: 50%;
				margin-left: 0;
				margin: 5px 0 15px 0;
			}
		}
	}
	&.venueManagement {
		@media (max-width: 480px) {
			label {
				white-space: nowrap;
				font-size: 14px;
			}
		}
		@media (max-width: 340px) {
			label {
				white-space: normal;
			}
		}
	}

	a {
		color: ${({ theme }) => theme.colors.text.black01};
		font-size: 16px;
		line-height: 21px;
		text-decoration: none;
		margin-left: 47px;
		position: relative;
		cursor: pointer;
		background: transparent !important;
		@media (max-width: 1200px) {
			margin-left: 25px;
		}
		@media (max-width: 1074px) {
			font-size: 15px;
		}
		@media (max-width: 1000px) {
			font-size: 15px;
			margin-left: 30px;
		}
		@media (max-width: 960px) {
			font-size: 16px;
			line-height: 21px;
		}
		@media (max-width: 480px) {
			font-size: 14px;
			margin-left: 22px;
		}
		label {
			cursor: pointer;
		}
		&:first-child {
			margin-left: 0;
		}
		&.active {
			label {
				font-family: 'OpenSansBold';
				color: ${({ theme }) => theme.baseColors.primary};
			}
		}
		&:hover {
			label {
				color: ${({ theme }) => theme.baseColors.primary};
			}
		}

		&.orderNav {
			padding-right: 20px;
			display: flex;
			align-items: center;
			& > div {
				top: 0;
				position: absolute;
				width: 100%;
			}
			button {
				background: transparent;
				border: none;
				width: 100%;
				min-height: 25px;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				position: absolute;
				outline: none;
				cursor: pointer;
				@media (max-width: 480px) {
					min-height: 23px;
				}
			}
			.dropdownMenu {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				button {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					border: none;
					background: transparent;
					outline: none;
					cursor: pointer;
					& + div {
						margin-top: 53px;
						border-radius: 12px;
						left: auto;
						right: -30px;
						min-width: 200px;
						z-index: 1;
						padding: 15px;
						overflow: visible;
						box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.39);
						@media (max-width: 960px) {
							right: -5px;
							margin-top: 44px;
						}
						@media (max-width: 340px) {
							right: -25px;
							margin-top: 64px;
						}
						button {
							position: relative;
							min-height: auto;
							padding: 10px 10px;
							margin: 0;
							&::before {
								left: 0;
								right: 0;
							}
							&:hover {
								background: ${({ theme }) => theme.baseColors.primary};
								color: ${({ theme }) => theme.colors.text.white};
								text-decoration: none;
							}
						}
						&::before {
							content: '';
							width: 47px;
							height: 16px;
							position: absolute;
							top: -16px;
							left: auto;
							right: 14px;
							background: url(${require('../../images/dropdownTopArrow.png')});
						}
					}
				}
			}
		}
	}
	.menuContent {
		position: absolute;
		background: #ffffff;
		box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.39);
		border-radius: 12px;
		min-height: 220px;
		width: 400px;
		left: -154px;
		top: 53px;
		font-family: 'OpenSansBold';
		cursor: default;
		transition: all 0.25s ease-in-out;
		z-index: 1;
		&::before {
			content: '';
			width: 47px;
			height: 16px;
			position: absolute;
			top: -16px;
			left: 185px;
			background: url(${require('../../images/dropdownTopArrow.png')});
		}
		@media (max-width: 960px) {
			top: 41px;
		}
		@media (max-width: 592px) {
			left: auto;
			right: -150px;
			top: 36px;
			&::before {
				left: auto;
				right: 180px;
			}
		}
		@media (max-width: 480px) {
			width: 350px;
			left: auto;
			right: -130px;
			top: 36px;
			&::before {
				left: auto;
				right: 145px;
			}
		}
		@media (max-width: 380px) {
			width: 310px;
			left: auto;
			right: -120px;
			top: 36px;
			&::before {
				left: auto;
				right: 135px;
			}
		}
	}
`;
export { Nav };
