import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

const StyledDropdownButton = styled.summary`
	vertical-align: middle;
	cursor: pointer;
	user-select: none;
	appearance: none;
	text-decoration: none;
	color: var(--text-color-normal);

	&::-webkit-details-marker {
		display: none;
	}

	.dropdown-profile & {
		padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[3]};
		border: 0;
		border-radius: ${({ theme }) => theme.radii.hard};
		box-shadow: none;
		background: none;
		cursor: pointer;
		transition: background 220ms ease-in-out;

		&:hover {
			background-color: var(--color-neutral-contrast-02);

			html[data-color-mode='dark'] & {
				background-color: var(--color-neutral-contrast-03);
			}
		}

		&:focus {
			outline: none;
			box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
		}
	}
`;

type DropdownButtonProps = {
	children?: string | ReactNode;
	role?: string;
	onClick?: () => void;
	tabIndex?: number;
};

function DropdownButton({ children, role, onClick, tabIndex }: DropdownButtonProps): ReactElement {
	return (
		<StyledDropdownButton tabIndex={tabIndex} as="summary" onClick={onClick} role={role}>
			{children}
		</StyledDropdownButton>
	);
}

export default DropdownButton;
