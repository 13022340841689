import { condition } from 'models/enum-constants/common.constants';
import Iget from 'models/Iget';
import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { FlexboxProps, LayoutProps, SpaceProps } from 'styled-system';
import icons from 'ui/icons';
import urls from 'utils/create.url';
import hooks from 'utils/requestCtx/reactQueryHooks';

const VenueCardsWrap = styled.div`
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		column-gap: 25px;
		row-gap: 25px;
		width: 100%;
		margin-right:25px;
		@media (max-width: 1280px) {
			grid-template-columns: repeat(1, 1fr);
			row-gap: 0;
		}
		@media (max-width: 860px) {
			margin-bottom:32px;
		}
		article{
			background-color: ${({ theme }) => theme.baseColors.primaryDark};
			border-radius: 12px;
			padding:0 30px;
			display:flex;
			align-items:center;
			min-height:125px;
			@media (max-width: 860px) {
				padding:0 25px;
			}
			@media (max-width: 1280px) {
				border-radius: 0;
				min-height:85px;
				padding:25px;
				border-bottom:1px solid ${({ theme }) => theme.colors.bg.userInfoHeader};

				&:first-child{
					border-radius: 12px 12px 0 0 ;
				}
				&:last-child{
					border-radius: 0 0 12px 12px ;
					border:none;
				}
				h1,h2{
					br{
						display:none;
					}
				}
			}
			@media (max-width: 960px) {
						&:first-child{
					border-radius: 8px 8px 0 0 ;
				}
				&:last-child{
					border-radius: 0 0 8px 8px ;
					border:none;
				}
			}
			h1,h2{
				font-size: 16px;
				line-height: 21px;
				font-family:'OpenSansSemiBold';
				text-transform: uppercase;
				color: ${({ theme }) => theme.colors.text.white};
				display:flex;
				flex-direction:column;
				strong{
					font-size: 28px;
					line-height: 36px;
					font-family:'OpenSansBold';

				}
			}
			h2{
				margin-left:15px;

			}
			button{
				width:50px;
				height:50px;
				background-color: ${({ theme }) => theme.colors.bg.yellow};
				border-radius: 12px;
				cursor: pointer;
				outline:none;
				border:none;
				margin-left:auto;
				display:flex;
				align-items:center;
				justify-content:center;
				svg{
					width:16px;
					height:16px;
				}
				&:hover{
					background-color: ${({ theme }) => theme.colors.text.textHeading};

				}
				@media (max-width: 860px) {
					width:46px;
					height:46px;
				}
			}
		}
	}
`;
type styledSystemProps = SpaceProps & LayoutProps & FlexboxProps;
type SortInfoProps = styledSystemProps & {
	productCount?: string;
	userCount?: string;
};

function SupplierCardHolder({ productCount, userCount }: SortInfoProps): ReactElement {
	// const supply_id = localStorage.getItem('supplierId');
	//let supplierId = parseInt(supply_id ? supply_id : '0')
	const userKey = 'dcUserList';
	const url =
		urls.GetSearchUrl({
			ctx: { currentPage: 1 },
			url: "experion/supplycompanyaccount/dcuser/search?",
			fields : [{field_name: 'sc_id',
			field_value: localStorage.getItem("supplierId")!},
			{
				field_name: 'dc_user_id',
				field_value: localStorage.getItem("SuAdminId")!,
				field_conditionType : condition.neq
			}
		],
			filterDeleted: true,
		});
	const obj: Partial<Iget> = {
		params: {},
		url: url,
		enabled: true,
		key: userKey,
		resultFilteringRequired: true,
	};
	var userList = hooks.useQuery(obj);
	const supplierId = localStorage.getItem('supplierId');
	const url1 =
		`experion-productmanagement/getproducts/${supplierId}`
	const obj1: Partial<Iget> = {
		params: {},
		url: url1,
		enabled: true,
		key: 'pdtManagement',
		resultFilteringRequired: false,
	};
	var pdtManagement = hooks.useQuery(obj1);
	const history = useHistory();
	const navigation = (name) => {
		switch (name) {
			case 'dcList':
				history.push('DCList');
				break;
			case 'userList':
				history.push('userList');
				break;
			case 'addUser':
				history.push('new-dc-user');
				break;
			case 'productManagement':
				history.push('productManagement');
				break;
			case 'addProduct':
				history.push('add-products');
				break;
			default:
				break;
		}

	}
	return (
		<VenueCardsWrap>
			<Flex as="article">
				<Text onClick={() => navigation('productManagement')} as="h1" fontFamily="OpenSansBold" fontSize={18} lineHeight="23px" color="text.black">
					product management<strong>{pdtManagement.data?.length}</strong>
				</Text>
				<Button onClick={() => navigation('addProduct')}>
					<icons.PlusIcon />
				</Button>
			</Flex>
			<Flex as="article">
				<Text onClick={() => navigation('userList')} as="h1" fontFamily="OpenSansBold" fontSize={18} lineHeight="23px" color="text.black">
					user<br></br> management<strong>{userList.data?.length}</strong>
				</Text>
				<Button onClick={() => navigation('addUser')}>
					<icons.PlusIcon />
				</Button>
			</Flex>
			<Flex as="article">
				<icons.inventoryIcon />
				<Text as="h2" fontFamily="OpenSansBold" fontSize={18} lineHeight="23px" color="text.black">
					Update <br></br>
					inventory and <br></br>
					price <br></br>
				</Text>
			</Flex>
			<Flex as="article" onClick={()=>history.push('OrderList')}>
				<icons.orderManagementIcon />
				<Text as="h2" fontFamily="OpenSansBold" fontSize={18} lineHeight="23px" color="text.black">
					order <br></br>
					management
				</Text>
			</Flex>
			<Flex as="article" onClick={() => navigation('dcList')}>
				<icons.dcManagementIcon />
				<Text as="h2" fontFamily="OpenSansBold" fontSize={18} lineHeight="23px" color="text.black">
					DC <br></br>
					Management
				</Text>
			</Flex>
			<Flex as="article">
				<icons.attributeConfirmationIcon />
				<Text as="h2" fontFamily="OpenSansBold" fontSize={18} lineHeight="23px" color="text.black">
					Attribute <br></br>
					Confirmation
				</Text>
			</Flex>
		</VenueCardsWrap>
	);
}

export { SupplierCardHolder };
