import React, { ReactElement, useState } from 'react';
import { Box, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { Button, Stack } from 'ui/components';
import { FormInput } from 'ui/patterns';

const CancelOrderMessage = styled.div`
	display: flex;
	height: auto;
	position: relative;
	flex-direction: column;
	background: ${({ theme }) => theme.colors.bg.white};
	padding: 0 10px 12px;
	@media (max-width: 960px) {
		padding: 0;
	}
	h1 {
		margin: 0 0 30px 0;
		width: 100%;
		font-weight: normal;
		text-transform: uppercase;
	}
	label {
		font-size: 16px;
		line-height: 21px;
		font-family: 'OpenSansSemiBold';
		color: ${({ theme }) => theme.colors.text.SubHeading};
		margin-bottom: 8px;
	}
	button {
		padding: 0;
		min-width: 100px;
		min-height: 50px;
		text-transform: capitalize;
		font-size: 18px;
		margin: 0 0 0 20px;
		font-family: 'OpenSansBold';
	}
	.app-c-form-group {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
	}
	.app-c-select-dropdown {
		position: relative;
		svg {
			position: absolute;
			top: 20px;
			right: 14px;
		}
	}
`;

export default function CancelOrderMessageModal({ showModal, setShowModal,
cancelOrder }): ReactElement {
	const [text, setText] = useState('');
	const [disableCreate, setDisableCreate] = useState(true);
	
	const handleOnChange = (e: any) => {
		if (e.target.value.length > 0) {
			setDisableCreate(false);
			setText(e.target.value);
		}
		else
		setDisableCreate(true);
	};

	return (
		<CancelOrderMessage>
			<Text as="h1" color="text.textHeading" fontSize={18} fontFamily="OpenSansSemiBold">
				Cancel order
			</Text>
			<Box mb={45}>
				<Stack categorySelect gap={0} mt={0}>
					<FormInput label="Enter the reason for cancel the order" onChange={handleOnChange}/>
				</Stack>
			</Box>

			<Stack direction="x" gap={3} justifyContent="center">
				<Button flex={[1, 1, 'initial']} variant="secondary"
				onClick={() => { setShowModal(!showModal) }}>
					Cancel
				</Button>
				<Button onClick={()=> {setShowModal(!showModal);cancelOrder(text)}} disabled={disableCreate} flex={[1, 1, 'initial']}>Ok</Button>
			</Stack>
		</CancelOrderMessage>
	);
}
