import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';
import { BreadcrumbNav, Footer, Header } from 'ui/components';
import {
	CheckoutConfirm, Sidebar
} from 'ui/patterns';


const MainContent = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
	padding: 0 50px 50px;
	@media (max-width: 960px) {
		padding: 0 15px;
	}
`;
const BreadcrumbHolder = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media (max-width: 480px) {
		& > div {
			padding-bottom: 15px;
		}
	}
`;
const CheckoutHolder = styled.div`
	display: flex;
	@media (max-width: 960px) {
		flex-direction: column;
	}
`;

function OrderConfirm(): ReactElement {
	const breadcrumbNav = [
		{ id: '01', label: 'Home',route :"/home/dashboard", active: false },
		{ id: '04', label: 'Order Confirmed', active: true },
	];
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);	
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	}
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={()=>onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={()=>onclickHambrgBtn()}></Sidebar>

			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbHolder>
					<BreadcrumbNav Heading="Order Confirmation" breadcrumbList={breadcrumbNav} />
				</BreadcrumbHolder>

				<CheckoutHolder>
					<CheckoutConfirm ></CheckoutConfirm>
				</CheckoutHolder>
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default OrderConfirm;
