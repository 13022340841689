import { math } from 'polished';
import React, { ReactElement } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';

const StyledPageHeader = styled.div`
	margin-left: auto;
	float: left;
	.rating {
		border: none;
		float: left;
		> input {
			display: none;
			cursor: pointer;
			&:checked {
				~ {
					label {
						&:before {
							cursor: pointer;
							background: url(${require('../../images/starYellow.svg')});
						}
						&:hover {
							~ {
								label {
									&:before {
										cursor: pointer;
										background: url(${require('../../images/starYellow.svg')});
										opacity: 0.4;
									}
								}
							}
						}
					}
				}
				+ {
					label {
						cursor: pointer;
						&:hover {
							&:before {
								cursor: pointer;
								background: url(${require('../../images/starYellow.svg')});
								opacity: 0.7;
							}
						}
					}
				}
			}
		}
		> label {
			&:before {
				margin: 0 5px;
				font-size: 1.25em;
				display: inline-block;
				content: '';
				width: 20px;
				height: 19px;
				cursor: pointer;
				background: url(${require('../../images/star.svg')});
			}
			float: right;
			height: 19px;
			&:hover {
				~ {
					input {
						&:checked {
							~ {
								label {
									&:before {
										background: url(${require('../../images/starYellow.svg')});
										opacity: 0.7;
									}
								}
							}
						}
					}
				}
			}
		}
		> .half {
			&:before {
				content: '';
				position: absolute;
				background: url(${require('../../images/starHalf.svg')});
			}
		}
		&:not(:checked) {
			> label {
				&:hover {
					&:before {
						background: url(${require('../../images/starYellow.svg')});
						opacity: 0.7;
					}
					~ {
						label {
							&:before {
								background: url(${require('../../images/starYellow.svg')});
							}
						}
					}
				}
			}
		}
	}
`;
function Rating(props): ReactElement {
	const [rating, setRating] = useState(0);
	useEffect(() => {
		if (props?.value) {
			if ((props.value % 1) > 0.5) {
				setRating(Math.floor(props.value) + 0.5);
			}
			else {
				setRating(Math.floor(props.value));
			}
		}
	},[props?.value])

	return (
		<StyledPageHeader>
			<div className="rating">
				<input type="radio" id="star5" name="rating" readOnly value="5" checked={rating == 5} />
				<label className="full" htmlFor="star5" title="Awesome - 5 stars"></label>
				<input type="radio" id="star4half" name="rating" readOnly value="4.5" checked={rating == 4.5} />
				<label className="half" htmlFor="star4half" title="Pretty good - 4.5 stars"></label>
				<input type="radio" id="star4" name="rating" readOnly value="4" checked={rating == 4} />
				<label className="full" htmlFor="star4" title="Pretty good - 4 stars"></label>
				<input type="radio" id="star3half" name="rating" readOnly value="3.5" checked={rating == 3.5} />
				<label className="half" htmlFor="star3half" title="Meh - 3.5 stars"></label>
				<input type="radio" id="star3" name="rating" readOnly value="3" checked={rating == 3} />
				<label className="full" htmlFor="star3" title="Meh - 3 stars"></label>
				<input type="radio" id="star2half" name="rating" readOnly value="2.5" checked={rating == 2.5} />
				<label className="half" htmlFor="star2half" title="Kinda bad - 2.5 stars"></label>
				<input type="radio" id="star2" name="rating" readOnly value="2" checked={rating == 2} />
				<label className="full" htmlFor="star2" title="Kinda bad - 2 stars"></label>
				<input type="radio" id="star1half" name="rating" readOnly value="1.5" checked={rating == 1.5} />
				<label className="half" htmlFor="star1half" title="Meh - 1.5 stars"></label>
				<input type="radio" id="star1" name="rating" readOnly value="1" checked={rating == 1} />
				<label className="full" htmlFor="star1" title="Sucks big time - 1 star"></label>
				<input type="radio" id="starhalf" name="rating" readOnly value="0.5" checked={rating == 0.5} />
				<label className="half" htmlFor="starhalf" title="Sucks big time - 0.5 stars"></label>
			</div>
		</StyledPageHeader>
	);
}

export { Rating };
