import React, { ReactElement } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { StyledTodaysOffer } from './styles';

const todaysOfferList = [
	{
		id: 1,
		image: require('ui/images/offer-01.png'),
		Offer: '20%',
		offerRate: '$250',
		quantity: '25',
		rate: '100',
		discount: '120',
		offPercentage: '20',
	},
	{
		id: 2,
		image: require('ui/images/offer-02.png'),
		Offer: '20%',
		offerRate: '$250',
		quantity: '25',
		rate: '100',
		discount: '120',
		offPercentage: '20',
	},
	{
		id: 3,
		image: require('ui/images/offer-03.png'),
		Offer: '20%',
		offerRate: '$250',
		quantity: '25',
		rate: '100',
		discount: '120',
		offPercentage: '20',
	},
	{
		id: 4,
		image: require('ui/images/offer-04.png'),
		Offer: '20%',
		offerRate: '$250',
		quantity: '25',
		rate: '100',
		discount: '120',
		offPercentage: '20',
	},
];
function TodaysOffer(): ReactElement {
	return (
		<StyledTodaysOffer>
			{todaysOfferList.map(
				({ id, image, Offer, offerRate, quantity, discount, rate, offPercentage }) => (
					<Flex as="article" bg="white" sx={{ alignItems: 'center' }} key={id}>
						<Flex alignItems="center" as="section" maxWidth={['100%']}>
							<Box flex={0}>
								<Text
									as="h1"
									fontFamily="OpenSansBold"
									fontSize="27px"
									lineHeight="35px"
									color="text.black"
									className="truncate"
								>
									{Offer}Offer
								</Text>
								<Text
									as="h2"
									fontSize="17px"
									lineHeight="22px"
									color="text.black"
									className="truncate"
								>
									Purchase above <br></br>
									<strong>{offerRate}</strong> or more
								</Text>
							</Box>
							<Box flex={0}>
								<Text
									as="h3"
									fontSize="17px"
									lineHeight="22px"
									color="text.black"
									className="truncate"
								>
									<strong>{quantity}</strong> Qty
								</Text>
							</Box>
							<Flex alignItems="baseline">
								<Text
									as="h4"
									fontSize="20px"
									fontFamily="OpenSansBlack"
									color="text.black"
									className="truncate"
									lineHeight="26px"
								>
									${rate}
								</Text>
								<Text
									as="h5"
									fontSize="16px"
									fontFamily="OpenSansBold"
									color="text.black"
									className="truncate"
									lineHeight="26px"
								>
									${discount}
								</Text>
								<Text
									as="h6"
									fontSize="16px"
									fontFamily="OpenSansBold"
									color="text.blackLight"
									className="truncate"
									lineHeight="26px"
								>
									( {offPercentage} % Off )
								</Text>
							</Flex>
						</Flex>
						<Flex
							alignItems="center"
							as="span"
							maxWidth={['100%']}
							sx={{ overflow: 'hidden' }}
							style={{
								backgroundImage: 'url(' + image + ')',
							}}
						></Flex>
					</Flex>
				)
			)}
		</StyledTodaysOffer>
	);
}

export { TodaysOffer };
