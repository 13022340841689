import styled from 'styled-components';

const ReviewContent = styled.div`
	padding: 0 10px 10px; 
	h1{
		font-weight: 600;
		font-size: 18px;
		text-transform: uppercase;
		color: #1F261F;
		line-height:23px;
		margin-bottom: 20px;
	}
	.address{
		padding-bottom:0;
		font-size: 16px;
		// border-bottom: 1px solid #A8A9AD;
		.full-name{
			font-weight:bold;
		}
	}
	.review{
		padding-top:24px;
		padding-bottom:11px;
		font-size: 17px;
		line-height: 22px;
	}
	.action-done{
		margin: 30px auto 0;
        display: block;
		min-width:140px;
	}
`;
export {
	ReviewContent, 
};
