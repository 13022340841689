import React, { ReactElement } from 'react';
import { SpaceProps, LayoutProps, FlexboxProps } from 'styled-system';
import { StyledProductMainDetails, ViewAllHeadingBlock, ProductMainDetailsHolder } from './styles';
import { Text, Box, Flex } from 'rebass/styled-components';
import { Stack } from 'ui/components';

type styledSystemProps = SpaceProps & LayoutProps & FlexboxProps;

type StyledProductMainDetailsProps = styledSystemProps & {
	totalAmount?: string;
};
const rewardsBalanceList = [
	{
		id: 1,
		details: 'Coffee',
	},
	{
		id: 2,
		details: 'Tea',
	},
	{
		id: 3,
		details: 'Burgers',
	},
];
function ProductListOfferings({
	...styledSystemProps
}: StyledProductMainDetailsProps): ReactElement {
	return (
		<StyledProductMainDetails {...styledSystemProps}>
			<ViewAllHeadingBlock>
				<Text as="h1" fontSize="18px">
					Details
				</Text>
			</ViewAllHeadingBlock>
			<ProductMainDetailsHolder>
				<Box>
					<Text className="product-title">Product Offered</Text>
				</Box>
				<Box>
					<Stack direction="x" gap={3}>
						{rewardsBalanceList.map(({ details, id }) => (
							<Flex as="article" bg="white" p={[3, 4]} sx={{ alignItems: 'center' }} key={id}>
								<Box flex={1}>
									<Text as="h3" fontSize="18px" color="text.black01" className="truncate">
										{details}
									</Text>
								</Box>
							</Flex>
						))}
						{/* after 3 then show this inside the loop*/}
						<Flex as="article" bg="white" p={[3, 4]} sx={{ alignItems: 'center' }}>
							<Box flex={1}>
								<Text
									as="span"
									fontSize="18px"
									color="text.black01"
									className="truncate more-products"
								>
									28 Others
								</Text>
							</Box>
						</Flex>
					</Stack>
				</Box>
			</ProductMainDetailsHolder>
		</StyledProductMainDetails>
	);
}

export { ProductListOfferings };
