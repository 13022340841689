import { Input } from '@rebass/forms';
import Iget from 'models/Iget';
import React, { ReactElement, useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import { Box, Flex, Link, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import helper from 'utils/helper.functions';
import hooks from 'utils/requestCtx/reactQueryHooks';
import constants from 'utils/constants';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import icons from 'ui/icons';
import moment from 'moment';
import helperFunctions from 'utils/helper.functions';
import { useHistory } from 'react-router-dom';
import { Loader } from 'ui/components/Loader';
const StyledCheckoutProductList = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	column-gap: 20px;
	row-gap: 20px;
	.order-details__wrap {
		flex-direction: column;
		background: ${({ theme }) => theme.colors.bg.white};
		border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
		box-sizing: border-box;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 16px;
		overflow: hidden;
		transition: box-shadow 100ms ease-in, transform 100ms ease-in;
		align-items: flex-start;
		height: auto;
		section {
			display: flex;
			flex-direction: column;
			padding: 25px;
			flex-wrap: nowrap;

			h1 {
				display: flex;
				margin-bottom: 18px;
				@media (max-width: 1224px) {
					flex-direction: column;
				}
				@media (max-width: 960px) {
					flex-direction: row;
				}
				@media (max-width: 480px) {
					flex-direction: column;
				}
				div {
					max-width: 100px;
					width: 100px;
					@media (max-width: 768px) {
						max-width: 120px;
						width: 120px;
					}
				}
				strong {
					font-family: 'OpenSansBold';
					font-size: 18px;
				}
			}
			h2 {
				float: left;
				width: 100%;
				br {
					display: none;
					+ strong {
						font-family: 'OpenSansBold';
						color: ${({ theme }) => theme.colors.text.black};
					}
				}
				strong {
					font-family: 'OpenSansBold';
					color: ${({ theme }) => theme.colors.text.error01};
				}
			}
			article {
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				white-space: nowrap;
				@media (max-width: 1152px) {
					flex-wrap: wrap;
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-start;
				}
				div {
					max-width: fit-content;
					width: auto;
					padding-right: 10px;
					@media (max-width: 768px) {
						max-width: 120px;
						width: 120px;
					}
				}
				h5 {
					width: calc(100% - 57%);
					word-break: break-all;
					white-space: normal;
					margin-bottom: 10px;
					@media (max-width: 1152px) {
						width: 100%;
					}
				}
			}
		}
	}
`;
const AddComments = styled.div`
	float: left;
	margin-top: 22px;
	width: 100%;
	@media (max-width: 1500px) {
		width: 100%;
	}
	h1 {
		font-family: 'OpenSansSemiBold';
		margin-bottom: 5px !important;
	}
	input {
		border-radius: 6px;
		border-right: 0;
		height: 54px;
		border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
		outline: none;
		font-family: 'OpenSansMedium';
		color: ${({ theme }) => theme.colors.text.black};
		padding: 15px;
	}
	button {
		border-radius: 0 6px 6px 0;
		flex: 0 0 50px;
		max-width: 50px;
		height: 54px;
	}
`;
const DeliveryCostBlock = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media (max-width: 1152px) {
		flex-wrap: wrap;
		h3 {
			flex: 1;
		}
	}
	h3 {
		white-space: nowrap;
		display: flex;
		width: 100;
		justify-content: flex-end;
		height: 34px;
		align-items: center;
		flex: initial;
		svg {
			height: 18px;
			width: 28px;
			margin-left: 5px;
		}
	}
`;
// type checkoutProductListProps = {
// 	checkoutProductList: Array<list>;
// };
// type list = {
// 	id?: string | number;
// 	supplierName?: string;
// 	price?: string;
// 	MOV?: string;
// 	qty?: string;
// 	deliveredTime?: string;
// 	deliveredDate?: string;
// };

function CheckoutProductList(props): ReactElement {
	const history = useHistory();
	const [cartDetails, setCartDetails] = useState<any>();
	const [comments, setComments] = useState<any>([]);
	const [commentObj, setCommentObj] = useState<any>({ supplier_id: 0, comment: '' });
	var cartId = localStorage.getItem('cartId');
	var [groupBySupplierName, setGroupBySupplierName] = useState<any>([{ key: '', value: [] }]);
	var deliveryMonth: any;
	const CUSTOMER_TOKEN = localStorage.getItem('customerToken');
	const [deliveryDate, setDeliveryDate] = useState<any>(localStorage.getItem('deliverySlot'));

	const url1 = constants.getCartItems(); // get cart items api call
	const obj1: Partial<Iget> = {
		params: {},
		url: url1,
		enabled: true,
		key: 'cartKey',
		resultFilteringRequired: false,
		headers: { Authorization: CUSTOMER_TOKEN },
	};
	var qry = hooks.useQuery(obj1);

	useEffect(() => {
		if (qry.data !== undefined) {
			setCartDetails(qry.data);
			props.getTotalCountOfItems(
				qry.data?.filter((x) => x.extension_attributes.is_delete == false)?.length
			);
			groupBySupplierName = helper.GroupBy(
				qry.data.filter((x) => x.extension_attributes?.is_delete == false),
				(x) => x.extension_attributes.supplier_name
			);
			for (let value of Object.values(qry.data)) {
				console.log(value); // John, then 30
			}
			setGroupBySupplierName(groupBySupplierName);
		} else {
			history.push('/home/dashboard');
		}
	}, [qry.data]);

	const getCountDown = (order_process_time) => {
		//count down timer functionality
		if (
			order_process_time &&
			order_process_time !== undefined &&
			order_process_time?.next_avaliable_time !== ''
		) {
			const countDownDate = moment(
				order_process_time?.next_avaliable_time,
				'DD-MM-YYYY HH:mm:ss'
			).valueOf();
			const now = moment(order_process_time?.current_time, 'DD-MM-YYYY HH:mm:ss').valueOf();
			const distance = countDownDate - now;
			return distance;
		} else {
			return 0;
		}
	};

	const addComments = (supplierId, event) => {
		if (event.target.value) {
			setCommentObj({ supplier_id: supplierId, comment: event.target.value });
			comments.push({ supplier_id: supplierId, comment: event.target.value });
			setComments(comments);
			props.setOrderComments(comments);
		}
	};

	const getPriceQty = (value) => {
		if (value !== []) {
			let sum = 0;
			value.forEach((data) => {
				sum = sum + data.qty * data.price;
			});
			var data: any;
			data = sum;
			// parseFloat(data).toFixed(2)
			return data;
		}
	};

	return (
		<StyledCheckoutProductList>
			{qry.isFetching||qry.isLoading ? <Loader></Loader> : null}
			{groupBySupplierName?.length > 0
				? groupBySupplierName.map((item) => {
						var deliveryMonth: any;
						let deliverydate =
							item.value[0]?.extension_attributes?.order_process_time?.next_avaliable_time;
						if (deliverydate && deliverydate !== '') {
							deliveryMonth = helperFunctions.formatDate(
								moment(deliverydate, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD hh:mm:ss')
							);
						}
						return (
							<Flex
								className="order-details__wrap selected"
								bg="white"
								sx={{ alignItems: 'center' }}
								key={`${item.item_id}${Math.floor(Math.random()*1000)}`}
							>
								<Flex
									as="section"
									flexWrap="wrap"
									flexDirection="row"
									width="100%"
									maxWidth={['100%']}
								>
									<Box as="article">
										<Text as="h5" fontSize={17} color="text.black">
											<strong>{item.key}</strong>
										</Text>
										{/* TODO: Remove if delivery slots are required
										 <Text as="h1" fontSize={17} color="text.black">
											<div>Mov</div>
											<strong>
												${parseFloat(item.value[0]?.extension_attributes.mov).toFixed(2)}
											</strong>
										</Text> */}
										{/* <Text as="h1" fontSize={17} color="text.black">
											<div>Delivery Date</div>
											<strong>
												{deliveryDate &&
												deliveryDate
													.filter((obj) => {
														obj.supplier_id ===;
													})
													.map((opt) => opt.slot)} 
												{deliveryDate &&
													JSON.parse(deliveryDate)
														.filter(
															(x) =>
																x.supplier_id === item.value[0]?.extension_attributes?.supplier_id
														)
														.map((option) => {
															const day = moment(option.slot, 'DD-MM-YYYY').format('dddd');
															let deliverySlotDate = moment(option.slot, 'DD-MM-YYYY') as any;
															deliverySlotDate = helperFunctions.formatDate(
																deliverySlotDate.toDate()
															);
															return day + ' ' + deliverySlotDate;
														})}
											</strong>
										</Text> */}
									</Box>

									<Text as="h1" fontSize={17} color="text.black">
										<div>Total </div>
										<strong>${parseFloat(getPriceQty(item.value)).toFixed(2)}</strong>
									</Text>

									<Text as="h1" fontSize={17} color="text.black">
										<div>Quantity</div>
										<strong>{item.value.reduce((a, v) => (a = a + v.qty), 0)}</strong>
									</Text>
									<DeliveryCostBlock>
										{deliveryMonth && (
											<Text
												as="h2"
												fontSize="17px"
												fontFamily="OpenSansRegular"
												color="text.black03"
											>
												Order in{' '}
												<strong>
													<Countdown
														date={
															Date.now() +
															getCountDown(item.value[0]?.extension_attributes?.order_process_time)
														}
													/>
												</strong>
												<br></br> to get it processed on <strong>{deliveryMonth}</strong>
											</Text>
										)}
										{item && item?.value[0]?.extension_attributes?.delivery_cost ? (
											<Text as="h3" fontSize={16} fontWeight="normal" color="black">
												Delivery Cost Applicable
												<icons.moneyTruck />
											</Text>
										) : null}
									</DeliveryCostBlock>
									<AddComments>
										<Text as="h1" fontSize={17} color="text.black">
											Comments
										</Text>
										<Flex>
											<Input
												name="comments"
												type="text"
												placeholder="Add Comments"
												onBlur={(event) =>
													addComments(item.value[0]?.extension_attributes.supplier_id, event)
												}
											/>
											{/* <Button onClick={addComments}>
									<icons.PlusIcon />
								</Button> */}
										</Flex>
									</AddComments>
								</Flex>
							</Flex>
						);
				  })
				: ''}
		</StyledCheckoutProductList>
	);
}

export { CheckoutProductList };
