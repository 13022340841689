import React, { ReactElement, ReactNode } from 'react';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import styled from 'styled-components';
import { Button } from 'ui/components';
import * as Icon from 'ui/icons';

const StyledDropdownButton = styled.summary``;

type DropdownButtonProps = {
	children?: string | ReactNode;
	role?: string;
	onClick?: () => void;
	tabIndex?: number;
};

function DropdownButton({ children, role, onClick, tabIndex }: DropdownButtonProps): ReactElement {
	return (
		<StyledDropdownButton tabIndex={tabIndex} as="summary" onClick={onClick} role={role}>
			<Tooltip
				arrow
				title="Parked Order"
				animation="perspective"
				position="bottom"
				trigger="mouseenter"
			>
				<Button
					iconBefore={<Icon.parkedOrderIcon title="cartIcon" />}
					variant="cartButton"
				></Button>
			</Tooltip>
		</StyledDropdownButton>
	);
}

export default DropdownButton;
