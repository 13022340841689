import styled from 'styled-components';
import { getHSL } from '../../ui/utils/getHSL';
const MainContent = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
	padding: 30px 50px;
	@media (max-width: 960px) {
		padding: 30px 15px 50px;
	}
	@media (max-width: 767px) {
		padding: 30px 15px 0;
	}
`;
const BreadcrumbHolder = styled.div`
	width: 100%;
	float: left;
`;

const ContentHolder = styled.div`
	display: flex; 
	@media (max-width: 860px) {
		flex-direction: column;
		padding-top: 15px;
	}
`;
const LefContentHolder = styled.div`
	flex: 0 0 600px;
	max-width: 600px;
	margin-right: 46px;
	overflow-x: hidden;
	@media (max-width: 1360px) {
		flex: 0 0 400px;
		max-width: 400px;
		margin-right: 46px;
	}
	@media (max-width: 1152px) {
		flex: 0 0 350px;
		max-width: 350px;
		margin-right: 46px;
	}
	@media (max-width: 860px) {
		flex: 0 0 100%;
		max-width: 100%;
		margin-right: 0;
		overflow-x: visible;
		margin-bottom: 20px;
	}
`;
const RightContentHolder = styled.div`
	flex-grow: 1;
	@media (max-width: 860px) {
		margin-top: 20px;
	}
`;
const OtherSuppliersHolderWeb = styled.div`
	width: 100%;
	float: left;
	@media (max-width: 860px) {
		display: none;
	}
`;
const OtherSuppliersHolderMobile = styled.div`
	width: 100%;
	float: left;
	display: none;
	@media (max-width: 860px) {
		display: block;
	}
`;
const ProductAllergens = styled.div`
	width: 100%;
	float: left;
`;

const ItemQuantityHolder = styled.div`
	width: 100%;
	float: left;
	display: flex;
	margin: 40px 0 0 0;
	@media (max-width: 860px) {
		margin: 30px 0 0 0;
	}
	button {
		--h: ${({ theme, color }) => getHSL('h', color || theme.baseColors.primary)};
		--s: ${({ theme, color }) => getHSL('s', color || theme.baseColors.primary)};
		--l: ${({ theme, color }) => getHSL('l', color || theme.baseColors.primary)};
		--a: 100%;
		height: 60px;
		border-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 0 0 26px;
		padding: 0 35px;
		cursor: pointer;
		outline: none !important;
		transition: background 0.8s;
		@media (max-width: 1224px) {
			height: 48px;
			padding: 0;
			flex: 0 0 48px;
			margin: 0 0 0 20px;
			h1 {
				display: none;
			}
		}
		@media (max-width: 480px) {
			margin: 0 0 0 17px;
		}

		svg {
			path {
				fill: ${({ theme }) => theme.baseColors.white};
			}
		}
		h1 {
			margin-left: 17px;
		}
		&:hover,
		&:focus {
			background: hsl(var(--h), var(--s), calc(var(--l) - 10%));
		}
	}
	.Favorites {
		--h: ${({ theme, color }) => getHSL('h', color || theme.baseColors.primary)};
		--s: ${({ theme, color }) => getHSL('s', color || theme.baseColors.primary)};
		--l: ${({ theme, color }) => getHSL('l', color || theme.baseColors.primary)};
		--a: 100%;
		border: solid 1px hsl(var(--h), var(--s), var(--l));
		padding: 0 !important;
		width: 60px;
		max-width: 60px;
		flex: 0 0 60px;
		background: transparent;
		svg {
			width: 20px;
			height: 20px;
			path {
				fill: ${({ theme }) => theme.baseColors.primary};
			}
		}
		&:hover,
		&:focus {
			background: hsl(var(--h), var(--s), var(--l));
			svg {
				width: 20px;
				height: 20px;
				path {
					fill: ${({ theme }) => theme.baseColors.white};
				}
			}
		}
		&.active {
			background: hsl(var(--h), var(--s), var(--l));
			svg {
				width: 20px;
				height: 20px;
				path {
					fill: ${({ theme }) => theme.baseColors.white};
				}
			}
		}
	}
	.secondary {
		color: ${({ theme }) => theme.baseColors.primary};
		border: 1px solid ${({ theme }) => theme.baseColors.primary};
		background: ${({ theme }) => theme.colors.bg.white};
		font-family: 'OpenSansBold';
		flex: 0 0 auto;
		padding: 0 20px;
		&:hover,
		&:focus,
		&:active {
			background: ${({ theme }) => theme.baseColors.primary};
			color: ${({ theme }) => theme.colors.text.white};
		}
	}
`;
const ProductSubstituteBlock = styled.div`
	width: 100%;
	padding: 0;
	@media (max-width: 860px) {
		margin-bottom: 50px;
	}
	@media (max-width: 767px) {
		margin-bottom: 0;
	}
`;
export {
	MainContent,
	BreadcrumbHolder,
	LefContentHolder,
	RightContentHolder,
	ContentHolder,
	OtherSuppliersHolderWeb,
	ItemQuantityHolder,
	ProductAllergens,
	OtherSuppliersHolderMobile,
	ProductSubstituteBlock,
};
