import { venueUserPermissionList } from 'models/enum-constants/common.constants';
import { UseGlobalState } from 'models/globalStateAccess';
import Iget from 'models/Iget';
import Ipost from 'models/Ipost';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';
import { Box, Image, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { BreadcrumbNav, Button, Footer, Header, Loader, Stack } from 'ui/components';
import nodataImage from 'ui/images/Nodata.svg';
import { CartListView, Modal, Sidebar } from 'ui/patterns';
import appContext from 'utils/appContext';
import { default as helper, default as helperFunctions } from 'utils/helper.functions';
import pageConstants from 'utils/pageConstants';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import utilityFunctions from 'utils/utility.functions';
import NewParkedOrderModal from './newParkedOrder';
import { CartListHolder, FooterBtnBlock, MainContent, TotalCostBlock } from './styles';
import SweetAlert from 'react-bootstrap-sweetalert';
import constants from 'utils/constants';

const NodataFound = styled.div`
	width: 100%;
	display: flex;
	display: flex;
	align-items: center;
	justify-content: center;
	height: calc(100vh - 494px);
	grid-column: 3/1;
	img {
		margin: 0;
	}
	h1 {
		text-align: center;
		margin-top: 15px;
	}
`;
function Cart(): ReactElement {
	let ctx = useContext(appContext).cartObj;
	var [cartDetails, setCartDetails] = useState<any>();
	const [showModal, setShowModal] = useState(false);
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const [disableCheckOut, setDisableCheckOut] = useState(false);
	var [group, setGroup] = useState<any>({});
	const [venueDetails, setVenueDetails] = useState<any>({});
	const [isLoading, setIsLoading] = useState(false);

	var [groupBySupplierName, setGroupBySupplierName] = useState<any>([{ key: '', value: [] }]);
	const [venueUserId, setVenueUserId] = useState<any>(localStorage.getItem('userId'));
	const [venueId, setVenueId] = useState<any>(localStorage.getItem('selectedVenue'));
	var cartId = localStorage.getItem('cartId');
	var history = useHistory();
	const [state, dispatch] = UseGlobalState();
	const key = 'shippingkey';
	const [mutatePost] = hooks.useMutation(key);
	const [restrictParkedOrder, disableParkedOrder] = useState(false);
	const [modalText, setmodalText] = useState('');
	const [showPopup, setShowPopup] = useState(false);
	const [action, setAction] = useState('');
	const CUSTOMER_TOKEN = localStorage.getItem('customerToken');

	if (cartId == null) {
		utilityFunctions.getCartId(CUSTOMER_TOKEN);
		cartId = localStorage.getItem('cartId');
		if (cartId == null) {
			history.push('/login');
		}
	}

	var qry;
	const url = constants.getCartItems();
	const obj: Partial<Iget> = {
		params: {},
		url: url,
		enabled: false,
		key: 'cartKey',
		resultFilteringRequired: false,
		headers: { Authorization: CUSTOMER_TOKEN },
	};
	qry = hooks.useQuery(obj);
	var id = localStorage.getItem('selectedVenue');
	const venueKey = 'venueDetails';
	const venueUrl = `experion/venue/get/${id}`;
	const item: Partial<Iget> = {
		params: {},
		url: venueUrl,
		enabled: true,
		key: venueKey,
	};
	const venueEditDetails = hooks.useQuery(item);

	var qryTotal;
	const url1 = '/carts/' + cartId + '/totals';
	const obj2: Partial<Iget> = {
		params: {},
		url: url1,
		enabled: true,
		key: 'cartTotalKey2',
		resultFilteringRequired: false,
	};
	qryTotal = hooks.useQuery(obj2);

	useEffect(() => {
		obj2.key = `cartTotalKey2${helperFunctions.getRandomInt(pageConstants.maxValue)}`;
		qry.refetch().then(function (data) {
			updateCartDetails(data);
		});
	}, []);

	useEffect(() => {
		setVenueDetails(venueEditDetails.data);
	}, [venueEditDetails.data]);

	useEffect(() => {
		if (qry.data) {
			updateCartDetails(qry.data);
		}
	}, [qry.data]);

	function updateCartDetails(data) {
		setCartDetails(data);
		if (data != undefined) {
			if (data.items != undefined) {
				if (data?.items?.filter((x) => x.extension_attributes.is_delete == false)?.length === 0) {
					setDisableCheckOut(true);
					groupBySupplierName = [];
					setGroupBySupplierName(groupBySupplierName);
					ctx.cartItemCount = [];
				} else {
					group = {};
					setGroup(group);
					data?.items
						?.filter((x) => x.extension_attributes.is_delete == false)
						.forEach((item) => {
							// if (item.extension_attributes?.credit_status.length === 0) {
							// 	setCreditStatus('go');
							// } else {
							// 	item.extension_attributes?.credit_status.forEach((obj) => {
							// 		if (parseInt(venueId) === obj) {
							// 			setCreditStatus('stop');
							// 		}
							// 	})
							// }
							if (item.extension_attributes.is_in_stock === false) {
								setDisableCheckOut(true);
							}
						});

					groupBySupplierName = helper.GroupBy(
						data?.items?.filter((x) => x.extension_attributes.is_delete == false),
						(x) => x.extension_attributes.supplier_name
					);

					setGroupBySupplierName(groupBySupplierName);
					ctx.cartItemCount = new Array(groupBySupplierName?.length);
				}
				setIsLoading(false);
			} else {
				setIsLoading(false);
				history.goBack();
			}
		} else {
			setIsLoading(true);
		}
	}

	const callbackFn = (data) => {
		setCartDetails((prevObj1) => [...data]);

		if (groupBySupplierName.length > 0) {
			const listData = groupBySupplierName.filter((x) => x.value.length > 0);

			setGroupBySupplierName(listData);
		}
	};

	const disableCheckoutButton = (value) => {
		setDisableCheckOut(value);
	};

	const breadcrumbNav = [
		{ id: '01', label: 'Home', route: '/home/dashboard', active: false },
		{ id: '04', label: 'Cart Management', active: true },
	];

	const [showNewParkedOrderModal, setShowNewParkedOrderModal] = useState(false);
	const toggleNewParkedOrderModal = () => {
		setShowNewParkedOrderModal(!showNewParkedOrderModal);
	};

	const updateTotalAmount = () => {
		qryTotal.refetch().then((data) => {
			if (data) {
				if (data?.items?.length > 0)
					dispatch({ cartTotal: helperFunctions.formatAmount(data?.grand_total) });
			} else {
			}
		});
	};

	useEffect(() => {
		updateTotal(qryTotal.data);
	}, [qryTotal.data]);

	function updateTotal(data) {
		let datas = data?.items?.filter((x) => x.is_deleted != true);
		if (datas?.length > 0) {
			dispatch({ cartItemCount: datas.length });
			dispatch({ cartTotal: helperFunctions.formatAmount(data?.grand_total) });
		} else {
			dispatch({ cartItemCount: '0' });
			dispatch({ cartTotal: '' });
			setDisableCheckOut(true);
		}
	}

	const onMarkAsParked = () => {
		let payload = {
			cartId: cartId,
			venueUserId: venueUserId,
			// "parkOrderName": newParkedOrder,
			venueId: venueId,
		};

		const obj1: Partial<Ipost> = {
			data: payload,
			params: {},
			url: '/experion-parkorder/create',
			message: 'Order has been saved as Parked Order',
		};
		try {
			mutatePost(obj1).then((data) => {
				if (data !== undefined) {
					// setCartDetails(prevObj1 => ([]));
					clearCart();
				}
			});
		} catch (e) {}
	};

	function updateCartData() {
		qryTotal.refetch();
	}

	const callShippingInformation = (data) => {
		//get all shipping details
		let placeOrderJson = {
			addressInformation: {
				shipping_address: {
					region: venueDetails?.state,
					region_id: 43,
					region_code: 'NZ',
					country_id: 'NZ',
					street: [venueDetails?.address],
					postcode: venueDetails?.zip_code,
					city: venueDetails?.city,
					firstname: 'Jane',
					lastname: 'Doe',
					email: localStorage.getItem('email'),
					telephone: localStorage.getItem('phone_no'),
				},
				billing_address: {
					region: venueDetails?.state,
					region_id: 43,
					region_code: 'NZ',
					country_id: 'NZ',
					street: [venueDetails?.address],
					postcode: venueDetails?.zip_code,
					city: venueDetails?.city,
					firstname: 'Jane',
					lastname: 'Doe',
					email: localStorage.getItem('email'),
					telephone: localStorage.getItem('phone_no'),
				},
				shipping_carrier_code: data[0].carrier_code,
				shipping_method_code: data[0].method_code,
			},
		};

		const obj1: Partial<Ipost> = {
			data: placeOrderJson,
			params: {},
			url: '/carts/' + cartId + '/shipping-information',
			message: ' ',
		};
		try {
			mutatePost(obj1).then((data) => {
				if (data !== undefined) {
					let orderCostLimit: any = localStorage.getItem('orderCostLimit');
					let totalCart = parseFloat(state.cartTotal.replace(/[^0-9\.-]+/g, ''));
					if (
						totalCart > parseInt(orderCostLimit) &&
						utilityFunctions.isGranted(venueUserPermissionList.OrderApproval) === false
					) {
						setIsLoading(false);
						showConfirmationModal(
							'Order Cost Limit exceeded. Click confirm to send this order for approval.'
						);
					} else {
						history.push('/admin/checkout');
					}
				}
			});
		} catch (e) {
			setIsLoading(false);
		}
	};

	const placeOrder = () => {
		setIsLoading(true);
		let placeOrderJson = {
			paymentMethod: {
				method: 'cashondelivery',
				extension_attributes: {
					venue_id: parseInt(venueId),
					venue_user_id: parseInt(venueUserId),
					comments: [],
				},
			},
			billing_address: {
				email: localStorage.getItem('email'),
				region: venueDetails?.state,
				region_id: 43,
				region_code: 'NZ',
				country_id: 'NZ',
				street: [venueDetails?.address],
				postcode: venueDetails?.zip_code,
				city: venueDetails?.city,
				telephone: localStorage.getItem('phone_no'),
				firstname: 'Jane',
				lastname: 'Doe',
			},
		};

		const obj: Partial<Ipost> = {
			data: placeOrderJson,
			params: {},
			url: constants.paymentURL(),
			message: 'Your order has been sent for approval',
			headers: { Authorization: CUSTOMER_TOKEN },
		};
		try {
			mutatePost(obj).then((data) => {
				if (data !== undefined) {
					let orderCostLimit: any = localStorage.getItem('orderCostLimit');
					let totalCart = parseFloat(state.cartTotal.replace(/[^0-9\.-]+/g, ''));
					if (
						totalCart > parseInt(orderCostLimit) &&
						utilityFunctions.isGranted(venueUserPermissionList.OrderApproval) === false
					) {
						setGroupBySupplierName([]);
						// clearCart();
					} else {
						localStorage.setItem('orderId', data);
						history.push('/admin/confirm');
					}
					updateTotalAmount();
					setIsLoading(false);
				}
			});
		} catch (e) {
			setIsLoading(false);
		}
	};

	const onCheckoutClick = (action) => {
		setAction(action);
		estimateShippingMethod();
	};

	const estimateShippingMethod = () => {
		setIsLoading(true);
		// payment method to pass payment method and get shipping carrier and method code
		let placeOrderJson = {
			address: {
				region: venueDetails?.state,
				region_id: 43,
				region_code: 'NZ',
				country_id: 'NZ',
				street: [venueDetails?.address],
				postcode: venueDetails?.zip_code,
				city: venueDetails?.city,
				firstname: 'Jane',
				lastname: 'Doe',
				customer_id: localStorage.getItem('userId'),
				email: localStorage.getItem('email'),
				telephone: localStorage.getItem('phone_no'),
				same_as_billing: 1,
			},
		};

		const obj1: Partial<Ipost> = {
			data: placeOrderJson,
			params: {},
			url: '/carts/' + cartId + '/estimate-shipping-methods',
			message: ' ',
		};
		try {
			mutatePost(obj1).then((data) => {
				if (data !== undefined) {
					callShippingInformation(data);
				}
			});
		} catch (e) {
			setIsLoading(false);
		}
	};
	const getTotalSuppAmt = (value) => {
		let sum = 0;
		value.forEach((x) => {
			sum = sum + x.qty * x.price;
		});
		return sum;
	};
	const clearCart = () => {
		const obj: Partial<Ipost> = {
			data: { cartId: localStorage.getItem('cartId') },
			params: {},
			url: '/clear-cart',
			message: ' ',
		};
		try {
			api.post(obj).then((data) => {
				if (data !== undefined) {
					qry.refetch();
					qryTotal.refetch().then(function (data) {
						updateTotal(data);
						history.push('/home/dashboard');
					});
				}
			});
		} catch (e) {}
	};
	async function closePopup() {
		await setShowPopup(!showPopup);
	}
	const onSubmit = () => {
		if (action === 'clear') {
			clearCart();
		} else if (action === 'orderApproval') {
			placeOrder();
		}
		setShowPopup(!showPopup);
	};
	const clearCartOrders = (action) => {
		setAction(action);
		showConfirmationModal('Are you sure want to clear the cart?');
	};
	function showConfirmationModal(text) {
		setmodalText(text);
		setShowPopup(!showPopup);
	}
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	};
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={() => onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={() => onclickHambrgBtn()}></Sidebar>
			{qry.status == 'loading' || isLoading === true ? <Loader></Loader> : null}
			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbNav Heading="Cart " breadcrumbList={breadcrumbNav} />
				{groupBySupplierName &&
				groupBySupplierName.length > 0 &&
				groupBySupplierName[0].value.length > 0 ? (
					groupBySupplierName.map((data, i) => (
						<CartListHolder key={helperFunctions.getRandomInt(pageConstants.maxValue)}>
							<Text as="h1" fontSize="20px" lineHeight="26px" color="text.black">
								{data.key}
								{data.value?.length <= 1 ? (
									<strong>
										{' '}
										({data.value?.length} Product) (
										{helperFunctions.formatAmount(getTotalSuppAmt(data.value))})
									</strong>
								) : (
									<strong>
										{' '}
										({data.value.length} Products)({' '}
										{helperFunctions.formatAmount(getTotalSuppAmt(data.value))})
									</strong>
								)}
							</Text>
							<CartListView
								getTotalSuppAmt={getTotalSuppAmt}
								disableCheckoutButton={disableCheckoutButton}
								callbackFn={callbackFn}
								updateTotalAmount={updateTotalAmount}
								cartOrderList={data.value}
								supplierIndex={i}
								updateCartData={updateCartData}
								disableParkedOrder={disableParkedOrder}
							/>
						</CartListHolder>
					))
				) : !isLoading && !qry.isFetching ? (
					<NodataFound>
						<Box>
							<Image
								src={nodataImage}
								sx={{
									width: 130,
									height: 130,
								}}
							/>
							<Text as="h1" fontFamily="OpenSansBold" fontSize={18} color="text.black">
								Cart is Empty
							</Text>
						</Box>
					</NodataFound>
				) : (
					''
				)}
				{state.cartTotal != '' && (
					<TotalCostBlock>
						{(!qryTotal.isFetching && !qry.isFetching && (
							<Text
								as="h1"
								fontSize="20px"
								fontFamily="OpenSansRegular"
								className="truncate"
								lineHeight="22px"
								color="text.black"
							>
								Total Cost <strong>{state.cartTotal}</strong>
							</Text>
						)) || <Skeleton count={2} width="100px" height="20px"></Skeleton>}
					</TotalCostBlock>
				)}
				{/* Footer Btn Block */}
				<FooterBtnBlock>
					<Stack direction="x" gap={3} justifyContent={['center', 'flex-end']}>
						{/* <MarkAsParkedOrderEditBtn>
							<Box>
								<Text
									as="h1"
									fontSize="15px"
									fontFamily="OpenSansMedium"
									className="truncate"
									color="text.primary"
								>
									Parked Order Saved
								</Text>
								<strong>Coffee Orders</strong>
							</Box>
							<Button>
								<icons.textEditIcon />
							</Button>
						</MarkAsParkedOrderEditBtn> */}

						{(utilityFunctions.isGranted(venueUserPermissionList.ParkedOrdersAdd) ||
							utilityFunctions.isGranted(venueUserPermissionList.ParkedOrdersAdmin)) &&
							state.cartTotal != '' && (
								<Button
									flex={[1, 1, 'initial']}
									variant="secondary"
									disabled={restrictParkedOrder}
									onClick={onMarkAsParked}
								>
									Save As Parked Order
								</Button>
							)}

						<Button
							onClick={() => {
								clearCartOrders('clear');
							}}
							disabled={state.cartTotal == ''}
							variant="secondary"
							flex={[1, 1, 'initial']}
						>
							Clear
						</Button>
						<Button
							onClick={() => {
								onCheckoutClick('orderApproval');
							}}
							disabled={disableCheckOut}
							flex={[1, 1, 'initial']}
						>
							Checkout
						</Button>
					</Stack>
				</FooterBtnBlock>
				<SweetAlert
					show={showPopup}
					confirmBtnText="Confirm"
					onConfirm={onSubmit}
					showCancel={true}
					onCancel={closePopup}
					title={'Confirmation Box'}
				>
					{() => <form>{modalText}</form>}
				</SweetAlert>
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
			<Modal
				width="600px"
				showModal={showNewParkedOrderModal}
				setShowModal={setShowNewParkedOrderModal}
			>
				<NewParkedOrderModal
					cancel={() => setShowModal(false)}
					togglePopup={toggleNewParkedOrderModal}
				/>
			</Modal>
		</>
	);
}

export default Cart;
