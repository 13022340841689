import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { FlexProps, flex, SpaceProps, space, LayoutProps, layout } from 'styled-system';
import { Text } from 'rebass/styled-components';
import { Button } from 'ui/components';
import { useHistory } from 'react-router-dom';

type ProductDescriptionWrapProps = FlexProps & SpaceProps & LayoutProps;

const ProductDescriptionWrap = styled.div<ProductDescriptionWrapProps>`
	${flex}
	${space}
	${layout}
	display: flex;
	float: left;
	width: 100%;
	padding-top: 38px;
	border-top: 1px solid #dadada;
	flex-direction: column;

	.review-btn {
		max-width: fit-content;
		margin-top: 30px;
		.pr-button-label {
			max-width: auto;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}
	@media (max-width: 860px) {
		margin-top: 0;
	}
	h1 {
		text-transform: uppercase;
	}
	p {
		margin-top: 15px;
	}
	@media (max-width: 860px) {
		h1,
		p {
			font-size: 17px;
		}
	}
`;

type styledsystemProps = FlexProps & SpaceProps & LayoutProps;

type ProductDescriptionProps = styledsystemProps & {
	description?: string;
};

function ProductListDescription(props): ReactElement {
	const history = useHistory();
	return (
		<ProductDescriptionWrap >
			<Text
				as="h1"
				fontFamily="OpenSansBold"
				fontSize={18}
				lineHeight="23px"
				color="text.black"
				className="truncate"
			>
				Description
			</Text>
			<Text
				as="p"
				fontFamily="OpenSansRegular"
				fontSize={18}
				lineHeight="23px"
				color="text.black03"
			>
				{props?.supplierInfo?.supplierDescription}
			</Text>
			<Button className="review-btn"
				title={props?.supplierInfo?.name}
				disabled={props?.currentUserReview?.available === true}
				onClick={() => {
					history.push({
						pathname: '/admin/sellers-review',
						search: '?id=' + props?.supplierId,
					})
				}}>Write a review</Button>
		</ProductDescriptionWrap>
	);
}

export { ProductListDescription };
