import React, { ReactElement, useState } from 'react';
import { Flex } from 'rebass/styled-components';
import { Footer, Header } from 'ui/components';
import { Filter, Pagination, ProductToolbar, Sidebar, SortInfo } from 'ui/patterns';
import {
	FilterBlock,
	MainContent,
	ProductListViewBlock,
	TilesContentBlock,
	TileViewHolder,
} from './styles';

function ListingListView(): ReactElement {
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	}
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={()=>onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={()=>onclickHambrgBtn()}></Sidebar>

			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<ProductToolbar></ProductToolbar>
				<TileViewHolder>
					{/* Filter Session */}
					<FilterBlock>
						<Filter></Filter>
					</FilterBlock>
					<TilesContentBlock>
						{/* Sort Result Info */}
						<SortInfo noOfResult="55" noOfResultItem="Coffee"></SortInfo>
						{/* Product Grid View */}
						<ProductListViewBlock>{/* <ProductListView></ProductListView> */}</ProductListViewBlock>
						<Flex>
							<Pagination showing="09" showingOf="100" />
						</Flex>
					</TilesContentBlock>
				</TileViewHolder>
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default ListingListView;
