import styled from 'styled-components';
const StyledAccordionVenue = styled.div`
	button {
		width: 22px;
		height: 22px;
		border-radius: 6px;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	h1 {
		text-transform: uppercase;
		line-height: normal;
	}
	a,
	p {
		text-decoration: none;
		text-decoration: none;
		line-height: 18px;
		position: relative;
		margin-right: 5px;
		padding-right: 11px;
		&:before {
			content: '/';
			position: absolute;
			font-size: 14px;
		}
		&:hover {
			color: ${({ theme }) => theme.baseColors.baseYellow};
		}
	}
	a {
		color: ${({ theme }) => theme.colors.text.blackLight};
		font-family: 'OpenSansMedium';

		&:before {
			font-family: 'OpenSansMedium';
			right: 0;
		}
	}
	p {
		color: ${({ theme }) => theme.baseColors.baseYellow};
		font-family: 'OpenSansBold';
		&:before {
			color: ${({ theme }) => theme.baseColors.black};
			font-family: 'OpenSansMedium';
			left: 0;
			display: none;
		}
	}
	.is-open {
		.pr-button-label {
			svg {
				trasform: rotate(180deg);
			}
		}
	}
`;

const AccordionPanel = styled.div`
	display: flex;
	flex-direction: column;
	padding: 25px;
	margin-bottom: 12px;
	border: 1px solid #bdbcb9;
	border-radius: 12px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

	.Collapsible__trigger {
		width: 100%;
		display: flex;
		font-weight: 600;
		&.is-closed,
		&.is-open {
			position: relative;
			&:after {
				content: '';
				box-sizing: border-box;
				height: 10px;
				width: 10px;
				border-style: solid;
				border-color: #000;
				right: 0;
				position: absolute;
				transition: all 0.5s ease;
				cursor: pointer;
			}
		}
		&.is-closed {
			&:after {
				border-width: 0px 2px 2px 0px;
				-webkit-transform: rotate(45deg);
				-ms-transform: rotate(45deg);
				transform: rotate(45deg);
				-webkit-transition: border-width 150ms ease-in-out;
				transition: border-width 150ms ease-in-out;
			}
		}
		&.is-open {
			&:after {
				border-width: 0px 2px 2px 0px;
				-webkit-transform: rotate(225deg);
				-ms-transform: rotate(225deg);
				transform: rotate(225deg);
				-webkit-transition: border-width 150ms ease-in-out;
				transition: border-width 150ms ease-in-out;
			}
		}
	}
`;

const VenueDetails = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	margin: 21px 0;
	margin-bottom: 5px;
	position: relative;
	section {
		div {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
	button {
		background: none;
		outline: none;
		border: none;
		position: absolute;
		top: 12px;
		right: 15px;
		&:hover,
		&:focus {
			background: none;
			outline: none;
			border: none;
		}
		svg {
			width: 12px;
			height: 12px;
		}
	}
	.status-button-label {
		color: #ecb136;
		font-weight: normal;
		font-size: 13px;
		min-width: 87px;
		background: #fdf9f0;
		border: 1px solid #ecb136;
		border-radius: 6px;
		padding: 3px 28px;
	}
`;
export { StyledAccordionVenue, AccordionPanel, VenueDetails };
