import React, { ReactElement } from 'react';
import { Box, Text } from 'rebass/styled-components';
import styled from 'styled-components';
const StyledSuppliersMainHeading = styled.div`
	width: 100%;
	h1 {
		line-height: normal;
	}
	h2 {
		strong {
			font-family: 'OpenSansBold';
			font-size: 30px;
			margin-right: 7px;
			margin-top: 7px;
		}
	}
	h3 {
		margin-top: 42px;
		strong {
			font-family: 'OpenSansBold';
		}
	}
	h4 {
		margin-top: 7px;
		br {
			display: none;
		}
		strong {
			font-family: 'OpenSansBold';
			color: ${({ theme }) => theme.colors.text.error01};
			+ strong {
				font-family: 'OpenSansBold';
				color: ${({ theme }) => theme.colors.text.black};
			}
		}
	}
	@media (max-width: 860px) {
		text-align: center;
		h1 {
			font-size: 26px;
		}
		h2,
		h3,
		h4 {
			font-size: 17px;
		}
		h2 {
			strong {
				font-size: 17px;
				margin-top: 7px;
			}
		}
		h3 {
			margin-top: 21px;
		}
		h4 {
			margin-top: 15px;
			br {
				display: block;
			}
		}
	}
`;
// const ItemQuantityHolder = styled.div`
// 	width: 100%;
// 	margin-top: 40px;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	border: 1px solid ${({ theme }) => theme.colors.border.YellowBorder};
// 	border-radius: 8px;
// 	overflow: hidden;
// 	@media (max-width: 860px) {
// 		flex-direction: column;
// 		margin-top: 33px;
// 	}
// 	h5 {
// 		margin: 0 0 4px 0;
// 		padding: 0;
// 		line-height: 21px;
// 	}
// 	h6 {
// 		line-height: 26px;
// 		font-family: 'OpenSansRegular';
// 		strong {
// 			font-family: 'OpenSansBold';
// 		}
// 	}
// 	div {
// 		padding: 0;
// 		flex-grow: 1;
// 		display: flex;
// 		align-items: center;
// 		justify-content: center;
// 		flex-direction: column;
// 		height: 80px;
// 		border-right: 1px solid ${({ theme }) => theme.colors.border.YellowBorder};
// 		@media (max-width: 860px) {
// 			width: 100%;
// 			border-right: none;
// 			border-bottom: 1px solid ${({ theme }) => theme.colors.border.YellowBorder};
// 			flex-direction: row;
// 			justify-content: space-between;
// 			padding: 0;
// 			height: auto;
// 			label {
// 				div {
// 					padding: 20px;
// 				}
// 			}
// 		}
// 		&:last-child {
// 			border: none;
// 		}
// 	}
// 	input {
// 		display: none;
// 	}
// 	label {
// 		width: 100%;
// 		padding: 0 !important;
// 		margin-bottom: 0;
// 		&::before {
// 			display: none !important;
// 		}
// 	}
// `;

function SuppliersMainHeading(props): ReactElement {
	return (
		<StyledSuppliersMainHeading>
				{props.supplierDetails && (
				<Box m="0" key="">
					<Text
						as="h1"
						fontSize="36px"
						fontFamily="OpenSansBold"
						color="text.black"
						className="truncate"
					>
						{props?.supplierDetails?.company_name}
					</Text>
					{/* <RatingHolder>
						<Rating></Rating>
					</RatingHolder> */}

					<Text as="h2" fontSize="20px" fontFamily="OpenSansRegular" color="text.black03">
						{props?.supplierDetails?.street_address}
					</Text>
					{/* <Text as="h3" fontSize="20px" fontFamily="OpenSansRegular" color="text.black03">
						Order in{' '}
						<strong>
							<Countdown date={Date.now() + getCountDown(props.SupplierInfo.countdownTimerDatetime)} />
						</strong>
						<br></br> to get it processed on{' '}
						<strong>
							{deliveryMonth}
						</strong>
					</Text> */}
				</Box>
			)}
		</StyledSuppliersMainHeading>
	);
}

export { SuppliersMainHeading };
