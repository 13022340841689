import styled from 'styled-components';
const BreadcrumbHolder = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	button {
		display: none;
	}
	@media (max-width: 480px) {
		& > div {
			padding-bottom: 15px;
		}
	}
`;
const MainContent = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
	padding: 0 50px 50px;
	@media (max-width: 960px) {
		padding: 0 15px;
	}
	section {
		&.section {
			row-gap: 20px;
			column-gap: 20px;
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			background: #f9f9f9;
			padding: 20px;
			border-radius: 15px;
			@media (max-width: 860px) {
				grid-template-columns: repeat(1, 1fr);
				.reportBlock {
			grid-column: 1 / 1!important;
			}
		}

		.reportBlock {
			grid-column: 3 / 1;
			div {
				width: 300px;
				select {
					background: ${({ theme }) => theme.baseColors.white};
				}
			}
			h1 {
				text-align: center;
				font-family: 'OpenSansSemiBold';
				color: ${({ theme }) => theme.colors.text.SubHeading};
				font-size: 22px;
				margin: 20px 0 0 0;
			}
		}
		article {
			width: 100%;
			background: ${({ theme }) => theme.colors.bg.white};
			border: 1px solid ${({ theme }) => theme.colors.border.grayMedium03};
			box-sizing: border-box;
			box-shadow: 0 1px 15px 1px rgb(62 57 107 / 7%);
			border-radius: 16px;
			padding: 0;
			h2 {
				font-family: 'OpenSansSemiBold';
				color: ${({ theme }) => theme.colors.text.SubHeading};
				font-size: 1.12rem;
				padding: 25px 25px 10px;
				+ div {
					margin-top: 0;
					padding: 0 5px 10px;
				}
			}
			.tableList {
				padding: 0 !important;
				table {
					tbody {
						tr {
							border: none;
							border-top: 1px solid ${({ theme }) => theme.colors.border.grayMedium03};
							box-shadow: none;
							border-radius: 0px;
						}
					}
					tbody {
						margin-bottom: 0;
					}

					th,
					td {
						width: auto;
						max-width: 100%;
						flex: 1;
						&:nth-child(2) {
							text-align: center;
							width: 130px;
							max-width: 130px;
							flex: 0 0 130px;
						}
					}
				}
			}
		}
	}
`;

const TabContentHolder = styled.div`
	width: 100%;
	display: flex;
	margin-top: 50px;
`;

export { MainContent, BreadcrumbHolder, TabContentHolder };
