import styled from 'styled-components';

const MainContent = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
	padding: 0 50px;
	@media (max-width: 960px) {
		padding: 0 15px;
	}
`;
const PriceDetailsBlock = styled.div`
	padding: 0 0 0 20px;
	flex: 0 0 300px;
	max-width: 300px;
	flex-direction: column;
	display: flex;
	margin-bottom: 30px;
	button {
		min-width: 100%;
		text-transform: capitalize;
		padding: 0 20px;
		height: 60px;
		flex: 0 0 auto;
		margin: 0 !important;
		div {
			flex-wrap: wrap;
			row-gap: 20px;
		}
	}
	div {
		display: flex;
		flex-direction: column;
		row-gap: 20px;
	}
	@media (max-width: 768px) {
		flex: 0 0 100%;
		max-width: 100%;
		width: 100%;
		order: -1;
		margin: 0 0 0 0;
		padding: 0 0 20px 0;
	}
`;
const TotalCostBlock = styled.div`
	display: grid;
	// background: rgba(240, 233, 218, 0.26);
	background: #f5f5f5;
	padding: 16px 20px 15px;
	width: 100%;
	border: 1px solid ${({ theme }) => theme.newColors.gray};
	border-radius: 12px;
	row-gap: 10px !important;
	margin-bottom: 20px;
	h1 {
		margin-bottom: 0px;
		font-size: 18px !important;
	}
	h2,
	h3 {
		display: flex;
		font-size: 16px !important;
		padding-top: 10px;

		span {
			flex: 1;
		}
		strong {
			font-family: 'OpenSansSemiBold';
		}
	}
	h3 {
		border-top: 1px solid ${({ theme }) => theme.newColors.gray};
		display: flex;
		align-items: center;
		margin-top: 10px;
		strong {
			font-family: 'OpenSansBold';
			font-size: 20px;
		}
		div {
			row-gap: 0px !important;
		}
	}
	+ div {
		display: grid;
		width: 100%;
		grid-template-columns: repeat(1, 1fr);
		column-gap: 10px;
		button {
			width: 100%;
			flex: 0 0 100%;
			max-width: 100%;
		}
		@media (max-width: 768px) {
			grid-template-columns: repeat(2, 1fr);
			button {
				grid-column: 3 / 1;
				+ button{
					grid-column: auto;

				}
			}
		}
	
	}
`;
const CartPageWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	@media (max-width: 768px) {
		flex-direction: column;
	}
`;
export { MainContent, PriceDetailsBlock, TotalCostBlock, CartPageWrapper };
