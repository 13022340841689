import styled from 'styled-components';

const UserListHolder = styled.div`
	table {
		width: 100%;
	}
	.d-flex {
		display: flex;
		align-items: center;
	}
	table {
		tbody {
			@media (max-width: 768px){
				margin-bottom: 0!important;
			}
			tr {
				td {
					.img-block {
						width: 30px;
						height: 30px;
						margin-right: 10px;
						img {
							width: 100%;
							height: 100%;
						}
					}
				}
			}
		}
		thead,
		tbody {
			tr {
				th,
				td {
					
					&:nth-child(4),
					&:nth-child(6) {
						flex: 0 0 135px;
						max-width: 135px;
						@media screen and (max-width: 767px) {
							flex: 0 0 33.333% !important;
							max-width: 33.333% !important;
							margin-bottom: 15px;
						}
						@media screen and (max-width: 480px) {
							flex: 0 0 50% !important;
							max-width: 50% !important;
							h1{
								max-width: max-content;
							}
						}
					}
				}
			}
		}
	}
`;
const PageSearchHolder = styled.div`
	width: 100%;
	padding-top: 20px;
	padding-bottom: 25px;
	display: flex;
	justify-content: space-between;
	position: relative;
	z-index: 996;
	@media screen and (max-width: 960px) {
		flex-direction: column;
		align-items: center;
	}
	div {
		width: 580px;
		@media screen and (max-width: 960px) {
			width: 100%;
		}
	}
	button {
		div {
			width: auto;
		}
		@media screen and (max-width: 960px) {
			width: auto;
			margin-top: 25px;
			svg {
				margin-right: 0;
			}
		}
	}
	@media screen and (max-width: 767px) {
		width: 100%;
	}
`;
export { UserListHolder, PageSearchHolder };
