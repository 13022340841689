import React, { ReactElement, useState } from 'react';
import { Sidebar, PendingOrder, AllOrders } from 'ui/patterns';
import { Box, Flex, Heading, Text } from 'rebass/styled-components';
import { BreadcrumbNav, Footer, Header, TabsView, TableList, Stack } from 'ui/components';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { MainContent, BreadcrumbHolder, TabContentHolder } from './styles';
import LineChart from 'ui/components/chart/lineChart';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Select } from '@rebass/forms';
import * as Icon from 'ui/icons';
import icons from 'ui/icons';
const breadcrumbNav = [{ id: '01', label: 'Home', active: true }];
function ReportDashboard(): ReactElement {
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	}
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={()=>onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={()=>onclickHambrgBtn()}></Sidebar>
			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbHolder>
					<BreadcrumbNav Heading="Dashboard" breadcrumbList={breadcrumbNav} />
				</BreadcrumbHolder>
				<Flex as="section" className="section" flexWrap="wrap">
					<Box className="reportBlock">
						<Stack categorySelect gap={0}>
							<div>
								<label>Date Range</label>
								<Select id="country" name="country" defaultValue="Last 30 days">
									<option>-Select-</option>
									<option>1</option>
									<option>2</option>
								</Select>
								<Icon.downArrow />
							</div>
						</Stack>
						<Text as="h1">Reports showing for the date from 23 Apr 2021 - 30 Apr 2021</Text>
					</Box>

					<Box as="article" p={3} width={[1, 1, 1 / 2]}>
						<Heading>Revenue Report</Heading>
						<LineChart />
					</Box>
					<Box as="article" p={3} width={[1, 1, 1 / 2]}>
						<Heading>Revenue Report</Heading>

						<LineChart />
					</Box>
					<Box as="article" p={3} width={[1, 1, 1 / 2]}>
						<Heading>Product Views</Heading>
						<TableList className="tableList">
							<table>
								<thead>
									<tr>
										<th>Product</th>
										<th>Views</th>
									</tr>
								</thead>

								<tbody>
									<tr>
										<td data-label="Order Id">
											<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
												<span>Instant Coffee Powder</span>
											</Text>
										</td>
										<td data-label="Date">
											<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
												500
											</Text>
										</td>
									</tr>
									<tr>
										<td data-label="Order Id">
											<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
												<span>Instant Coffee Powder</span>
											</Text>
										</td>
										<td data-label="Date">
											<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
												500
											</Text>
										</td>
									</tr>
									<tr>
										<td data-label="Order Id">
											<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
												<span>Instant Coffee Powder</span>
											</Text>
										</td>
										<td data-label="Date">
											<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
												500
											</Text>
										</td>
									</tr>
								</tbody>
							</table>
						</TableList>
					</Box>
					<Box as="article" p={3} width={[1, 1, 1 / 2]}>
						<Heading>Product Sales</Heading>
						<TableList className="tableList">
							<table>
								<thead>
									<tr>
										<th>Product</th>
										<th>Unit Sold</th>
									</tr>
								</thead>

								<tbody>
									<tr>
										<td data-label="Order Id">
											<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
												<span>Instant Coffee Powder</span>
											</Text>
										</td>
										<td data-label="Date">
											<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
												500
											</Text>
										</td>
									</tr>
									<tr>
										<td data-label="Order Id">
											<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
												<span>Instant Coffee Powder</span>
											</Text>
										</td>
										<td data-label="Date">
											<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
												500
											</Text>
										</td>
									</tr>
									<tr>
										<td data-label="Order Id">
											<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
												<span>Instant Coffee Powder</span>
											</Text>
										</td>
										<td data-label="Date">
											<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
												500
											</Text>
										</td>
									</tr>
								</tbody>
							</table>
						</TableList>
					</Box>
				</Flex>
				<TabContentHolder>
					<TabsView>
						<Tabs>
							<TabList>
								<Tab>Pending Orders</Tab>
								<Tab>All Orders</Tab>
							</TabList>

							<TabPanel>
								<PendingOrder></PendingOrder>
							</TabPanel>
							<TabPanel>
								<AllOrders></AllOrders>
							</TabPanel>
						</Tabs>
					</TabsView>
				</TabContentHolder>
			</MainContent>
			<Footer></Footer>
		</>
	);
}
export default ReportDashboard;
