import styled from 'styled-components';

const UserListHolder = styled.div`
	table {
		width: 100%!important;
		margin-top: 20px;
		thead,
		tbody {
			tr {
				th,
				td {
					&:nth-child(1) {
						position: relative !important;
						@media screen and (max-width: 768px) {
							position: relative !important;
						}
					}
				}
			}
		}
	}
`;
export { UserListHolder };
