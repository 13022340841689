import styled from 'styled-components';

const StyledToolbar = styled.div`
	display: flex;
	align-items: center;
	padding: 30px 50px;
	justify-content: space-between;
	background: ${({ theme }) => theme.colors.bg.white};
	@media (max-width: 960px) {
		padding: 30px 15px;
	}
	@media (max-width: 768px) {
		flex-direction: column;
	}
`;
const StyledBreadcrumb = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	z-index: 99;
	button {
		display: none;
	}
	h1 {
		margin-left: 0;
	}
	div + button {
		@media (max-width: 768px) {
			display: flex;
			margin-left: auto;
		}
	}
	div {
		/* a + a + a {
			display: none;
		} */
		p {
			margin-left: 0;
			padding-left: 0;
		}
	}
	@media (max-width: 768px) {
		width: 100%;
	}
`;
const BrandSorting = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	z-index: 19;
	div > button > span > span > svg {
		path,
		rect {
			fill: ${({ theme }) => theme.colors.text.black02};
		}
	}
	div + div {
		display: flex;
	}
	button {
		display: none;
		+ button {
			display: flex;
		}
	}
	@media (max-width: 960px) {
		button {
			display: flex;
		}
	}
	@media (max-width: 768px) {
		button {
			display: none;
			+ button {
				display: flex;
			}
		}
		width: 100%;
		justify-content: space-between;
		margin-top: 25px;
	}
`;
const SortBuy = styled.div`
	border: 1px solid rgba(189, 188, 185, 1);
	box-sizing: border-box;
	border-radius: 6px;
	display: flex;
	align-items: center;
	min-width: 254px;
	min-height: 40px;
	@media (max-width: 480px) {
		min-width: auto;
		flex-grow: 1;
	}
	.pr-icon {
		margin: 0 8px 0 12px;
		svg {
			margin-right: 0;
		}
	}
	div {
		flex-grow: 1;
		Select {
			border: none;
			outline: none;
			box-shadow: none;
			font-size: 14px;
			line-height: 18px;
			font-family: 'OpenSansMedium';
			color: ${({ theme }) => theme.colors.text.black02};
			margin-right: -34px;
		}
	}
	svg {
		margin-right: 10px;
		margin-left: 0;
		path {
			fill: ${({ theme }) => theme.colors.text.black02};
		}
	}
`;
export { StyledToolbar, StyledBreadcrumb, BrandSorting, SortBuy };
