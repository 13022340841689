import styled from 'styled-components';
import { color, FlexProps, layout, LayoutProps, space, SpaceProps } from 'styled-system';

type TabsViewProps = LayoutProps &
	SpaceProps &
	FlexProps & {
		userInfoWrapper?: boolean;
		userInfoHolder?: boolean;
		userReward?: boolean;
		userRewardHolder?: boolean;
		merchUserInfo?: boolean;
	};
const TabsView = styled.div<TabsViewProps>`
	${layout}
	${space}
	${color}
	width:100%;
	float: left;
	.react-tabs__tab-list {
		display: flex;
		justify-content: center;
		border-bottom: solid 1px ${({ theme }) => theme.colors.bg.menu};
		padding: 0 10px;
		& > * + * {
			margin-left: 15px;
		}
		li {
			margin-bottom: -1px;
			padding: 14px 18px 8px 18px;
			min-width: 210px;
			color: ${({ theme }) => theme.colors.text.black02};
			font-family: 'OpenSansMedium';
			font-size: 18px;
			line-height: 18px;
			border-radius: 15px 15px 0 0;
			background: ${({ theme }) => theme.colors.bg.grayLight};
			border: solid 1px ${({ theme }) => theme.colors.bg.menu};
			cursor: pointer;
			text-transform: uppercase;
			z-index: 1;
			text-align: center;
			outline: none !important;
			svg {
				width: 25px;
				height: 22px;
				margin-bottom: 0;
				margin-top: -5px;
			}
			@media screen and (max-width: 860px) {
				padding: 14px 6px 8px 6px;
				font-size: 13px;
				width: 100%;
				min-width: auto;
				span {
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
			&:hover {
				background: ${({ theme }) => theme.colors.text.black02};
				color: ${({ theme }) => theme.colors.text.white};
			}
			&.react-tabs__tab--selected {
				background: ${({ theme }) => theme.colors.bg.white};
				color: ${({ theme }) => theme.colors.text.black02};
				border: solid 2px ${({ theme }) => theme.colors.bg.grayLight};
				border-bottom: solid 1px ${({ theme }) => theme.colors.border.white};
				box-shadow: none;
				z-index: 1;
				font-family: 'OpenSansBold';
				&:hover {
					color: ${({ theme }) => theme.colors.text.white}!;
				}
			}
		}
	}

	.react-tabs__tab-panel--selected {
		padding: 15px 0 0 0;
		opacity: 1;
		overflow: visible;
		@media (max-width: 1300px) {
			overflow-x: auto;
		}
	}
	@media (max-width: 1200px) {
		&.tablistBlock {
			li{
				min-width: 150px;
				font-size: 15px;
			}
		}
	}
	@media (max-width: 768px) {
		&.tablistBlock {
			width: 100%;
			.react-tabs__tab-list {
				flex-wrap: wrap;
				border: none;
				row-gap: 10px;
				column-gap: 10px;
				display: grid;
				grid-template-columns: repeat(2, 1fr) !important;
				li {
					width: 100%;
					max-width: 100%;
					flex: 0 0 100%;
					margin: 0 !important;
					border-radius: 15px;
					padding: 14px;
					&.react-tabs__tab--selected {
						background: ${({ theme }) => theme.baseColors.primary};
						color: ${({ theme }) => theme.colors.white};
						border: solid 1px ${({ theme }) => theme.colors.bg.menu}!important;
						box-shadow: none;
						z-index: 1;
						font-family: 'OpenSansBold';
						&:hover {
							color: ${({ theme }) => theme.colors.text.white}!;
						}
					}
				}
			}
		}
	}
	@media (max-width: 480px) {
		&.tablistBlock {
			.react-tabs__tab-list {
				grid-template-columns: repeat(1, 1fr) !important;
			}
		}
	}
`;

export { TabsView };
