import React, { ReactElement, useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { BreadcrumbNav, Button, Footer, Header } from 'ui/components';
import icons from 'ui/icons';
import {
	CheckoutPriceDetails,
	CheckoutProductList,
	CheckoutShippingOptions,
	CheckoutVenueDetails,
	Sidebar,
} from 'ui/patterns';
import appContext from 'utils/appContext';
import { Label, Radio } from '@rebass/forms';
import { Box, Flex, Text } from 'rebass/styled-components';
import SimpleBar from 'simplebar-react';
import constants from 'utils/constants';
import helperFunctions from 'utils/helper.functions';
import hooks from 'utils/requestCtx/reactQueryHooks';
import Ipost from 'models/Ipost';
import { UseGlobalState } from 'models/globalStateAccess';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import PaypalButtonWrapper from 'ui/components/paypalButton';
import { PAYPAL_CLIENT_ID, PAYPAL_SECRET, APP_TOKEN } from '../../utils/constants';

const StyledCartListView = styled.div`
	width: 100%;
	table {
		width: 100%;
		thead,
		tbody {
			tr {
				display: flex;
				align-items: center;

				td,
				th {
					text-align: left;
					width: 100%;
					padding: 0;
					background: ${({ theme }) => theme.colors.bg.white};
					white-space: nowrap;
					text-overflow: ellipsis;
					margin-right: auto;
					width: 220px;
					max-width: 220px;
					flex: 0 0 220px;
					padding: 0 10px;
					@media (max-width: 1440px) {
						width: 200px;
						max-width: 200px;
						flex: 0 0 200px;
					}
					h1,
					h2 {
						overflow: hidden;
						text-overflow: ellipsis;
						display: inline-block;
						white-space: nowrap;
						max-width: 100%;
						span {
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
						}
					}
					h1 {
						strong {
							font-size: 16px;
						}
						&:hover {
							color: ${({ theme }) => theme.baseColors.primary};
						}
					}
					&:nth-child(1) {
						display: flex;
						align-items: center;
						flex: 300px;
						max-width: 300px;
						width: 300px;
						@media (max-width: 1440px) {
							flex: 200px;
							max-width: 200px;
							width: 200px;
						}
						div {
							width: 100%;
							display: flex;
							align-items: center;
						}
						img {
							flex: 0 0 55px;
							max-width: 55px;
							margin-right: 15px;
							height: 55px;
							background-color: #faf4ea;
							object-fit: contain;
							border-radius: 5px;
							overflow: hidden;
						}
						h1 {
							cursor: pointer;
							span {
								word-break: break-word;
							}
						}
						h3 {
							margin-top: 2px;
							@media (max-width: 640px) {
								margin-bottom: 5px;
							}
						}
					}

					&:nth-child(4) {
						text-align: center;
						flex: 170px;
						max-width: 170px;
						width: 170px;
						justify-content: center;
						div {
							margin: auto;
						}
					}
					&:nth-child(2) {
						width: 200px;
						flex: 200px;
						max-width: 200px;
						@media (max-width: 1300px) {
							width: 150px;
							flex: 150px;
							max-width: 150px;
						}
					}

					&:nth-child(5) {
						text-align: right;
						width: 120px;
						flex: 120px;
						max-width: 120px;
					}
					&:nth-child(3) {
						width: 150px;
						flex: 150px;
						max-width: 150px;
						text-align: right;
					}
					&.action {
						width: 70px;
						max-width: 70px;
						flex: 0 070px;
						text-align: center;
						margin-right: 0;
						justify-content: center;
						background: transparent;
						@media screen and (max-width: 768px) {
							width: 33.33%;
							max-width: 33.33%;
							flex: 0 0 33.33%;
							text-align: left;
							justify-content: flex-start;
							label {
								margin-top: 8px;
							}
							button {
								margin-left: 0;
								margin-right: 10px;
							}
						}
						div {
							display: flex;
							align-items: center;
							justify-content: center;
							@media screen and (max-width: 768px) {
								justify-content: start;
								button {
									margin-left: 0;
									margin-right: 10px;
								}
							}
						}
						button {
							width: 34px;
							height: 34px;
							max-width: 34p;
							flex: 0 0 34px;
							border-radius: 8px;
							padding: 0;
							background: transparent;
							&:focus,
							&:hover,
							&:active {
								background: ${({ theme }) => theme.colors.bg.error}!important;
								svg {
									path {
										fill: ${({ theme }) => theme.colors.bg.white};
									}
								}
							}
							svg {
								width: 15px;
								height: 17px;
							}
						}
					}
				}
			}
		}
		thead {
			@media (max-width: 768px) {
				display: none;
			}
			background: #f5f5f5;
			tr {
				border: 1px solid transparent;
				box-sizing: border-box;
				border-radius: 8px;
				padding: 15px 16px 15px !important;
				th {
					background: #f5f5f5 !important;
					font-size: 14px;
					line-height: 18px;
					font-family: 'OpenSansBold';
					text-transform: uppercase;
				}
			}
		}
		tbody {
			display: block;
			min-height: max-content;
			margin-bottom: 0 !important;
			margin-top: -2px;
			@media screen and (max-width: 768px) {
				max-height: 100%;
				min-height: auto;
			}
			tr {
				border-radius: 0 !important;
				margin: 0 0 0 0 !important;
				margin-bottom: 0 !important;
				box-shadow: none !important;
				border-top: none !important;
				padding: 7px 14px !important;
				position: relative;
				border: 1px solid #f5f5f5;
				@media (max-width: 768px) {
					border: 1px solid ${({ theme }) => theme.colors.border.userListTD}!important;
					box-sizing: border-box !important;
					box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07) !important;
					margin-bottom: 15px !important;
					border-radius: 8px !important;
					flex-wrap: wrap;
					align-items: flex-start;
					padding: 14px 14px 7px !important;
				}
				td {
					@media screen and (max-width: 768px) {
						position: relative !important;
						padding-left: 0 !important;
						text-align: left !important;
						white-space: pre-wrap !important;
						overflow-wrap: break-word !important;
						display: flex !important;
						flex-direction: column !important;
						width: 33.3333%;
						flex: 0 0 33.3333%;
						max-width: 33.3333%;
						margin-bottom: 15px;
						&::before {
							content: attr(data-label);
							position: relative;
							display: block;
							font-family: 'OpenSansRegular';
							text-transform: uppercase;
							width: 100%;
							left: 0;
							font-size: 14px;
							line-height: 16px;
							white-space: pre-wrap;
							overflow-wrap: break-word;
							text-align: left;
							margin-bottom: 4px;
						}
						&.checked {
							position: absolute;
							top: 7px;
							right: 18px;
							width: 24px;
							z-index: 1;
							margin-bottom: 0;
						}
						h1 {
							font-family: 'OpenSansBold';
						}
					}
					slot {
						max-width: 110px;
						flex: 0 0 110px;
						font-size: 13px;
						line-height: 17px;
						display: flex;
						align-items: center;
						justify-content: center;
						margin: 0 auto;
						@media screen and (max-width: 768px) {
							flex: 0;
							margin: 0 auto 10px 0;
							width: 100px;
							min-height: 30px;
						}
					}
					@media (max-width: 768px) {
						&:nth-child(1),
						&:nth-child(2),
						&:nth-child(3),
						&:nth-child(4),
						&:nth-child(5),
						&.action {
							width: 33.333% !important;
							flex: 0 0 33.333% !important;
							max-width: 33.333% !important;
							button {
								margin-left: 0px !important;
								margin-right: 0 !important;
							}
						}
					}
					@media (max-width: 480px) {
						&:before {
							display: none;
						}
						&:nth-child(1) {
							width: 100% !important;
							flex: 0 0 100% !important;
							max-width: 100% !important;
							order: -1;
							h1 {
								padding-bottom: 30px;
								span {
									white-space: normal;
									font-size: 16px;
								}
							}
							div {
								img {
									flex: 0 0 60px;
									max-width: 60px;
									width: 60px;
									height: 60px;
									margin-right: 10px;
								}
								align-items: flex-start;
							}
						}
						&:nth-child(2) {
							width: auto !important;
							min-width: 100% !important;
							flex: 1 !important;
							max-width: fit-content !important;
							margin-top: -20px;
							background: transparent;
							h1 {
								font-size: 14px;
								padding-left: 70px !important;
								max-width: 230px;
							}
						}
						&:nth-child(3) {
							order: -1;
							width: auto !important;
							flex: 1 !important;
							max-width: fit-content !important;
							padding: initial;
							padding-left: 70px !important;
							margin-top: -50px;
							background: transparent;
							h1 {
								font-family: 'OpenSansMedium';
								color: ${({ theme }) => theme.colors.text.muted};
							}
						}
						&:nth-child(4) {
							flex: 150px !important;
							max-width: 150px !important;
							width: 150px !important;
							margin-left: 70px !important;
							background: transparent;
							button {
								width: 36px;
								flex: 0 0 36px;
								max-width: 36px;
							}
							input {
								font-size: 15px;
							}
						}
						&:nth-child(5) {
							width: 84px !important;
							flex: 0 0 84px !important;
							max-width: 84px !important;
							margin: -49px 0 0 0 !important;
							position: absolute !important;
							bottom: 33px;
							left: 0;
							text-align: center;

							h1 {
								text-align: center;
							}
						}
						&.action {
							position: absolute !important;
							flex: 44px !important;
							max-width: 44px !important;
							right: 10px;
							bottom: 29px;
							margin: 0;

							span {
								margin-bottom: 2px;
								span {
									margin-bottom: 0;
								}
							}
						}
					}
					@media (max-width: 340px) {
						&:nth-child(4) {
							flex: 130px;
							max-width: 130px;
							width: 130px;
							margin-left: 70px !important;
							background: transparent;
							button {
								width: 36px;
								flex: 0 0 36px;
								max-width: 36px;
							}
							input {
								font-size: 15px;
							}
						}
						&.action {
							button {
								margin-left: 10px !important;
								margin-right: 0 !important;
							}
						}
					}
				}
			}
		}
	}
`;

const PaymentBlock = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	column-gap: 20px;
	row-gap: 20px;
	width: 40%;
	margin-top: 15px;
	margin-right: 100px;
	.order-details__wrap {
		flex-direction: column;
		background: ${({ theme }) => theme.colors.bg.white};
		border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
		box-sizing: border-box;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 16px;
		overflow: hidden;
		transition: box-shadow 100ms ease-in, transform 100ms ease-in;
		align-items: flex-start;
		height: auto;
		section {
			display: flex;
			flex-direction: column;
			padding: 25px;
			flex-wrap: nowrap;

			h1 {
				display: flex;
				margin-bottom: 18px;
				@media (max-width: 1224px) {
					flex-direction: column;
				}
				@media (max-width: 960px) {
					flex-direction: row;
				}
				@media (max-width: 480px) {
					flex-direction: column;
				}
				div {
					max-width: 100px;
					width: 100px;
					@media (max-width: 768px) {
						max-width: 120px;
						width: 120px;
					}
				}
				strong {
					font-family: 'OpenSansBold';
					font-size: 18px;
				}
			}
			h2 {
				float: left;
				width: 100%;
				br {
					display: none;
					+ strong {
						font-family: 'OpenSansBold';
						color: ${({ theme }) => theme.colors.text.black};
					}
				}
				strong {
					font-family: 'OpenSansBold';
					color: ${({ theme }) => theme.colors.text.error01};
				}
			}
			article {
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				white-space: nowrap;
				@media (max-width: 1152px) {
					flex-wrap: wrap;
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-start;
				}
				div {
					max-width: fit-content;
					width: auto;
					padding-right: 10px;
					@media (max-width: 768px) {
						max-width: 120px;
						width: 120px;
					}
				}
				h5 {
					width: calc(100% - 57%);
					word-break: break-all;
					white-space: normal;
					@media (max-width: 1152px) {
						width: 100%;
					}
				}
			}
		}
	}
`;

const TotalCostBlock = styled.div`
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	text-align: right;
	padding: 15px 20px;
	background: #f5f5f5;
	flex-direction: row;
	row-gap: 15px;
	margin-top: 10px;
	@media (max-width: 768px) {
		margin-top: 0;
		flex-direction: column;
	}
	h1,
	h2 {
		strong {
			font-size: 20px;
			font-family: 'OpenSansSemiBold';
			margin-left: 10px;
			float: right;
			text-align: left;
			@media (max-width: 768px) {
				width: 100px;
			}
		}
	}
	h2 {
		padding-left: 20px;
		strong {
			font-family: 'OpenSansBold';
		}
	}
`;

const PaymentMainBlock = styled.div`
	display: flex;
	align-items: center;
`;

const MainContent = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
	padding: 0 50px 50px;
	@media (max-width: 960px) {
		padding: 0 15px;
	}
`;
const BreadcrumbHolder = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media (max-width: 480px) {
		& > div {
			padding-bottom: 15px;
		}
	}
`;
const ButtonWrapper = styled.div`
	display: flex;
	@media (max-width: 480px) {
		padding-bottom: 0 !important;
	}
	button {
		display: flex !important;
		font-size: 18px;
		text-transform: capitalize;
		height: 50px;
		padding-top: 0;
		padding-bottom: 0;
		svg {
			width: 16px;
			height: 16px;
		}
		@media (max-width: 767px) {
			width: 40px;
			height: 40px;
			padding: 0;
			align-items: center;
			justify-content: Center;
			div {
				display: none;
			}
			.pr-icon {
				margin-right: 0 !important;
			}
		}
		&:hover {
			svg {
				path {
					fill: ${({ theme }) => theme.colors.text.white};
				}
			}
		}
		span {
			display: flex;
			align-items: center;
		}
		+ button {
			margin-left: 15px;
			border: 1px solid ${({ theme }) => theme.baseColors.primary};
		}
		.pr-icon {
			margin-right: 15px;
		}
	}
`;
const CheckoutListHolder = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;
const CheckoutHolder = styled.div`
	display: flex;
	align-items: flex-start;
	margin-bottom: 30px;
	@media (max-width: 960px) {
		flex-direction: column;
	}
`;
// const CheckoutPriceDetailsHolder = styled.div`
// 	margin-left: 27px;
// 	width: 480px;
// 	max-width: 480px;
// 	@media (max-width: 960px) {
// 		order: -1;
// 		width: 100%;
// 		max-width: 100%;
// 		margin: 20px 0 20px;
// 	}
// `;
// const CheckoutPriceDetailsHolderBlock = styled.div`
// 	display: flex;
// 	flex-direction: column;

// 	padding-bottom: 20px;
// 	button {
// 		width: 100%;
// 		margin-top: 20px;
// 		font-size: 18px;
// 	}
// `;

function Checkout(): ReactElement {
	const ctx = useContext(appContext).cartObj;
	const history = useHistory();
	const [state, dispatch] = UseGlobalState();
	const [mutatePost] = hooks.useMutation('placeOrderKey');
	const [totalItemCount, setTotalItemCount] = useState(0);
	const [comments, setPlaceOrderComments] = useState<any>([]);
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const [shippingMethod, setShippingMethod] = useState<any>([]);
	const [paymentMethod, setPaymentMethod] = useState<any>([]);
	const [venueDetails, setVenueDetails] = useState<any>([]);
	const [progress, setProgress] = useState('checkout');
	const [selectedOption, setSelectedOption] = useState<any>();
	const currency = 'NZD';
	const breadcrumbNav = [
		{ id: '01', label: 'Home', route: '/home/dashboard', active: false },
		{ id: '02', label: 'Cart', route: '/store/cart', active: false },
		{ id: '04', label: progress == 'checkout' ? 'Checkout' : 'Payment', active: true },
	];
	const backListener = history.listen((location) => {
		if (location.pathname === '/store/cart') {
			ctx.stepperdCountUpdated = false;
		}
	});

	const getTotalCountOfItems = (totalCount) => {
		setTotalItemCount(totalCount);
	};

	const setOrderComments = (data) => {
		setPlaceOrderComments(data);
	};

	const goToCart = () => {
		ctx.stepperdCountUpdated = false;
		history.push('/store/cart');
	};

	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	};

	const changePaymentMethod = (event) => {
		setSelectedOption(event.target.value);
		dispatch({ selectedOption: event.target.value });
	};

	const onSubmit = () => {
		const userId = localStorage.getItem('userId');
		const adminId = localStorage.getItem('hgAdminId');

		const country =
			venueDetails?.country?.length > 2
				? helperFunctions.getCountryId(venueDetails.country)
				: venueDetails.country;
		const region =
			venueDetails?.state?.length > 4
				? helperFunctions.getRegionId(venueDetails.country, venueDetails.state)
				: helperFunctions.getRegionName(country, venueDetails.state);

		setPaymentOption(userId, country, region);
	};

	const setPaymentOption = (userId, country, region) => {
		const payloadJSON = {
			cartId: localStorage.getItem('cartId'),
			paymentMethod: {
				method: state.selectedOption,
			},
		};
		const obj: Partial<Ipost> = {
			data: payloadJSON,
			params: {},
			url: constants.setPaymentMethod(),
			message: ' ',
			headers: { Authorization: localStorage.getItem('customerToken') },
		};
		try {
			mutatePost(obj).then((data) => {
				if (data) {
					makePayment(userId, country, region);
				}
			});
		} catch (e) {
			console.error(e);
		}
	};

	const makePayment = (userId, country, region) => {
		const shippingAddressId = localStorage.getItem('defaultAddressId') || '';
		const payloadJSON = {
			paymentMethod: {
				method: selectedOption,
				extension_attributes: {
					venue_id: venueDetails?.id,
					venue_user_id: parseInt(userId || '0'),
					comments: [
						{
							supplier_id: comments?.supplierId,
							comment: comments?.comment,
						},
					],
				},
			},
			billingAddress: {
				// id: parseInt(shippingAddressId),
				// region_id: region?.id,
				region_code: region?.code,
				country_id: country,
				street: [venueDetails?.address],
				telephone: venueDetails?.phone,
				postcode: venueDetails?.zip_code,
				city: venueDetails?.city,
				firstname: venueDetails?.contact_name.split(' ')[0],
				lastname: venueDetails?.contact_name.split(' ')[2]
					? venueDetails?.contact_name.split(' ')[2]
					: venueDetails?.contact_name.split(' ')[1]
						? venueDetails?.contact_name.split(' ')[1]
						: '',
				middlename:
					venueDetails?.contact_name.split(' ')[1] &&
						venueDetails?.contact_name.split(' ')[2] == undefined
						? venueDetails?.contact_name.split(' ')[1]
						: '',
				customer_id: userId,
				email: venueDetails?.venue_email,
				same_as_billing: 0,
				// customer_address_id: 1,
				save_in_address_book: 0,
			},
		};

		const obj: Partial<Ipost> = {
			data: payloadJSON,
			params: {},
			url: constants.paymentURL(),
			message: ' ',
			headers: { Authorization: localStorage.getItem('customerToken') },
		};
		try {
			mutatePost(obj).then((data) => {
				if (data !== undefined) {
					localStorage.setItem('orderId', data);
					localStorage.removeItem('deliverySlot');
					dispatch({ cartItemCount: '0' });
					dispatch({ cartTotal: '' });
					history.push('/admin/confirm');
				}
			});
		} catch (e) {
			console.error(e);
		}
	};

	const renderButton = () => {
		console.error(selectedOption && selectedOption == 'paypal_express');
		return selectedOption && selectedOption == 'paypal_express' ? (
			<PayPalScriptProvider
				options={{
					clientId: PAYPAL_CLIENT_ID!,
					components: 'buttons',
					currency: currency,
					intent: 'authorize',
				}}
			>
				<PaypalButtonWrapper currency={currency} showSpinner={false} />
			</PayPalScriptProvider>
		) : (
			<Button
				style={{ width: '25%', height: '60px' }}
				disabled={selectedOption ? false : true}
				onClick={onSubmit}
			>
				Pay
			</Button>
		);
	};

	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={() => onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={() => onclickHambrgBtn()}></Sidebar>

			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbHolder>
					<BreadcrumbNav
						Heading={progress == 'checkout' ? 'Checkout' : 'Payment'}
						breadcrumbList={breadcrumbNav}
					/>
					<ButtonWrapper>
						<Button flex={[1, 1, 'initial']} onClick={goToCart}>
							<icons.LeftArrowIcon />
							<div>Go Back to Cart</div>
						</Button>
					</ButtonWrapper>
				</BreadcrumbHolder>

				{progress == 'checkout' ? (
					<CheckoutHolder>
						<CheckoutListHolder>
							<CheckoutProductList
								setOrderComments={setOrderComments}
								getTotalCountOfItems={getTotalCountOfItems}
							/>
							<CheckoutShippingOptions setShippingMethod={setShippingMethod} />
							<CheckoutVenueDetails setVenueDetails={setVenueDetails} />
						</CheckoutListHolder>
						<CheckoutPriceDetails
							comments={comments}
							totalItemCount={totalItemCount}
							shippingMethod={shippingMethod}
							setProgress={setProgress}
							setPaymentMethod={setPaymentMethod}
						/>
					</CheckoutHolder>
				) : (
					<>
						<StyledCartListView>
							<SimpleBar className="AccordionPanel">
								<table>
									<thead>
										<tr>
											<th>Product name</th>
											<th>Price</th>
											<th>Quantity</th>
											<th className="action"></th>
										</tr>
									</thead>
									<tbody>
										{paymentMethod &&
											paymentMethod.totals &&
											paymentMethod.totals.items.map((obj) => (
												<tr key={obj.item_id}>
													<td data-label="Product name">
														<Text
															as="h1"
															fontSize={15}
															fontFamily="OpenSansMedium"
															color="text.black"
														>
															{obj.name}
														</Text>
													</td>
													<td data-label="Price">
														<Text
															as="h1"
															fontSize={15}
															fontFamily="OpenSansMedium"
															color="text.black"
														>
															{obj.price}
														</Text>
													</td>
													<td data-label="Qty">
														<Text
															as="h1"
															fontSize={15}
															fontFamily="OpenSansMedium"
															color="text.black"
														>
															{obj.qty}
														</Text>
													</td>
												</tr>
											))}
									</tbody>
								</table>
							</SimpleBar>
							<TotalCostBlock>
								<Text
									as="h2"
									fontSize="15px"
									fontFamily="OpenSansMedium"
									className="truncate"
									lineHeight="22px"
									color="text.black"
								>
									Grand Total
									<strong>
										$
										{isNaN(paymentMethod && paymentMethod.totals.grand_total)
											? 0.0
											: parseFloat(paymentMethod && paymentMethod.totals.grand_total).toFixed(2)}
									</strong>
								</Text>
							</TotalCostBlock>
						</StyledCartListView>
						<PaymentMainBlock>
							<PaymentBlock>
								<Flex
									className="order-details__wrap selected"
									bg="white"
									sx={{ alignItems: 'center' }}
									p={4}
								>
									<Text as="h5" fontSize={17} color="text.black" m={3}>
										<strong>Payment Methods</strong>
									</Text>
									{paymentMethod && paymentMethod.payment_methods.length > 0 ? (
										paymentMethod.payment_methods.map((item) => {
											return (
												<Box
													as="article"
													key={`${item.code}${Math.floor(Math.random() * 1000)}`}
													p={3}
												>
													<Label>
														<Radio
															name="payment-method"
															id={item.code}
															value={item.code}
															onChange={changePaymentMethod}
															checked={selectedOption == item.code}
														/>
														{item.title}
													</Label>
												</Box>
											);
										})
									) : (
										<Box as="article">
											<Text as="h1" fontSize={17} color="text.black">
												<div>No options available </div>
											</Text>
										</Box>
									)}
								</Flex>
							</PaymentBlock>
							{renderButton()}
						</PaymentMainBlock>
					</>
				)}
			</MainContent>

			{/* Footer Session */}
			<Footer />
		</>
	);
}

export default Checkout;
