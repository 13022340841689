import { OrderStatuses } from 'models/enum-constants/common.constants';
import queryString from 'query-string';
import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { BreadcrumbNav, Button, Footer, Header, Loader } from 'ui/components';
import icons from 'ui/icons';
import {
	RecentOrderDetailsEditView,
	Sidebar,
	Modal,
	OrderGridSupplierView,
	AllOrdersDetailsListView,
	AllOrderDetailsEditView,
} from 'ui/patterns';
import { RecentOrdersDetailsListView } from 'ui/patterns/recentOrdersDetailsListView';
import urls from 'utils/create.url';
import helperFunctions from 'utils/helper.functions';
import http from 'utils/http.service';
import pageConstants from 'utils/pageConstants';
import notify from 'utils/toast.service';
import utilityFunctions from 'utils/utility.functions';
import api from 'utils/requestCtx/api.service';
import env from 'utils/constants';
import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
import { UseGlobalState } from 'models/globalStateAccess';
import Iget from 'models/Iget';
import hooks from 'utils/requestCtx/reactQueryHooks';
import Ipost from 'models/Ipost';

const PageHolder = styled.div`
	position: relative;
	z-index: 2;
	background: #fff;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
`;
const MainContent1 = styled.div`
	text-align: center;
	/* CLIENT-SPECIFIC STYLES */
	body,
	table,
	td,
	a {
		-webkit-text-size-adjust: 100%;
		-ms-text-size-adjust: 100%;
	} /* Prevent WebKit and Windows mobile changing default text sizes */
	table,
	td {
		mso-table-lspace: 0;
		mso-table-rspace: 0;
	} /* Remove spacing between tables in Outlook 2007 and up */
	img {
		-ms-interpolation-mode: bicubic;
	} /* Allow smoother rendering of resized image in Internet Explorer */

	/* RESET STYLES */
	img {
		border: 0;
		height: auto;
		line-height: 100%;
		outline: none;
		text-decoration: none;
	}
	table {
		border-collapse: collapse !important;
	}
	body {
		height: 100% !important;
		margin: 0 !important;
		padding: 0 !important;
		width: 100% !important;
		background: #fff !important;
	}
	/* iOS BLUE LINKS */
	a[x-apple-data-detectors] {
		color: inherit !important;
		text-decoration: none !important;
		font-size: inherit !important;
		font-family: inherit !important;
		font-weight: inherit !important;
		line-height: inherit !important;
	}

	/* MOBILE STYLES */

	/* ANDROID CENTER FIX */
	div[style*='margin: 16px 0;'] {
		margin: 0 !important;
	}

	/* Outlook 2016 Height Fix */
	table,
	tr,
	td {
		border-collapse: collapse;
	}
	tr {
		font-size: 0;
		line-height: 0;
		border-collapse: collapse;
	}

	/* media query */
	@media screen and (max-width: 800px) {
		.m-heading {
			font-size: 14px !important;
		}
		.m-col-width {
			width: auto !important;
			max-width: 100% !important;
			min-width: 100% !important;
		}
		.paddingshrink {
			padding: 10px !important;
		}
		.m-venue {
			font-size: 15px !important;
		}
		.m-created {
			font-size: 12px !important;
		}
	}
`;
const MainContent = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
	padding: 0 50px;
	@media (max-width: 960px) {
		padding: 0 15px;
	}
`;
const OrderDetailsBlock = styled.div`
	margin-bottom: 50px;
	.orderAgain {
		@media (max-width: 600px) {
			div {
				display: block;
				font-size: 11px;
			}
		}
	}
	h4 {
		margin: 40px 0 10px;
	}
	h5 {
		margin-bottom: 10px;
	}
	.action-download-order {
		button + button {
			margin-left: 8px;
		}
	}
	header {
		justify-content: flex-end;
		align-items: center;
		margin-top: 30px;
		h4 {
			margin-top: 0;
			margin-bottom: 0;
		}
		button {
			height: 50px;
			text-transform: capitalize;
			span {
				display: flex;
				white-space: nowrap;
				align-items: center;
			}
			svg {
				margin-right: 10px;
				path {
					fill: ${({ theme }) => theme.baseColors.primary};
				}
			}
			&:hover,
			&:focus {
				svg {
					path {
						fill: ${({ theme }) => theme.baseColors.white};
					}
				}
			}
			@media (max-width: 640px) {
				div {
					display: none;
				}
				svg {
					margin-right: 0;
				}
			}
		}
	}
	.headingBtnBLock {
		h5 {
			strong {
				font-size: 16px;
				font-family: 'OpenSansMedium';
				padding-left: 3px;
			}
		}
		@media (max-width: 768px) {
			h5 {
				font-size: 18px;
				span {
					font-size: 18px;
				}
			}
		}
		@media (max-width: 600px) {
			flex-wrap: wrap;
			header {
				margin-left: auto;
				margin-top: 10px !important;
			}
			h5 {
				font-size: 18px;

				span {
					font-size: 18px;
					float: left;
				}
			}
		}
	}
`;
function AllOrderDetails(): ReactElement {
	const location = useLocation();
	const [order, setOrder] = useState([] as any);
	const [hasData, setHasData] = useState(false);
	const parsed = queryString.parse(location.search);
	const [productsBySuppliers, setSupplierGroup] = useState([] as any);
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);

	const breadcrumbNav = [
		{ id: '01', route: '/home/dashboard', label: 'Home', active: false },
		{ id: '02', route: '/store/orders?tab=all-orders', label: 'All Orders', active: false },
		{ id: '03', label: '#' + order?.order_group_increment_id, active: true },
	];

	useEffect(() => {
		if (parsed.id) {
			http
				.GET({
					url: urls.GetSearchUrl({
						url: 'experion/order/search?',
						fields: [
							{
								field_name: 'venue_id',
								field_value: localStorage.getItem('selectedVenue')!,
							},
							{
								field_name: 'venue_user_id',
								field_value: localStorage.getItem('userId')!,
								field_conditionType: 'neq',
							},
							{
								field_name: 'order_group_id',
								field_value: parsed.id,
								field_conditionType: 'eq',
							},
							{ field_name: 'for_approval', field_value: '0,2', field_conditionType: 'in' },
						],
						pageSize: 2000,
						filterDeleted: true,
					}),
				})
				.then(function (d) {
					setOrder(d.data?.items?.find((x) => x.order_group_id == parsed.id));
				});

			http
				.GET({
					url: urls.GetSearchUrl({
						url: 'orders?',
						fields: [
							{
								field_name: 'group_order_id',
								field_value: parsed.id,
							},
						],
					}),
				})
				.then(function (d) {
					if (d.data?.items?.length > 0) {
						const datas = d.data?.items;
						setSupplierGroup(datas);
					}
					setHasData(true);
				});
		}
	}, [location, parsed.id]);

	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	};
	return (
		<>
			<PageHolder>
				{/* Main Header */}
				<Header toggleSideBar={() => onclickHambrgBtn()}></Header>
				{/* Side Menu Bar */}
				<Sidebar hide={hideSideBar} toggleSideBar={() => onclickHambrgBtn()}></Sidebar>
				<MainContent>
					{/* Breadcrumb and Sorting Session */}
					<BreadcrumbNav Heading="All Orders" breadcrumbList={breadcrumbNav} />
					{(hasData && (
						<OrderDetailsBlock>
							<AllOrderDetailsEditView
								supplierCount={productsBySuppliers?.length}
								data={order}
							></AllOrderDetailsEditView>

							{productsBySuppliers &&
								productsBySuppliers.map((data, i) => {
									let deliverySlot = data.extension_attributes?.delivery_slot
										? data.extension_attributes?.delivery_slot
										: '';
									deliverySlot = deliverySlot.split(' ');
									let deliverySlotDay = null as any;
									let deliverySlotDate = null as any;
									if (deliverySlot.length === 2) {
										deliverySlotDate = moment(deliverySlot[1], 'DD-MM-YYYY');
										deliverySlotDate = helperFunctions.formatDate(deliverySlotDate.toDate());
										deliverySlotDay = deliverySlot[0];
									}
									let rejectionReason = '' as any;
									if (
										data.status == OrderStatuses.Rejected &&
										data.status_histories &&
										data.status_histories.length > 0
									) {
										rejectionReason = data.status_histories[0].comment?.split(':')[0];
									}
									return (
										<Fragment key={helperFunctions.getRandomInt(pageConstants.maxValue)}>
											<Flex
												flexDirection="row"
												alignItems="center"
												justifyContent="space-between"
												marginTop="30px"
												marginBottom="10px"
												className="headingBtnBLock"
											>
												{deliverySlot && deliverySlot.length === 2 ? (
													<Text
														as="h5"
														key={i}
														fontSize="20px"
														lineHeight="26px"
														color="text.black"
														style={{ marginBottom: '0px' }}
													>
														{data.extension_attributes.supplier_name}
														<strong>{` ${data.items?.length}`}</strong>
														{` ( `}
														<strong>{`#${data.extension_attributes.supplier_order_increment_id}`}</strong>
														{` - ${helperFunctions.toPascalCase(
															data.status?.replace('_', ' ') +
																(rejectionReason != '' && rejectionReason != undefined
																	? ': ' + rejectionReason
																	: '')
														)} ) `}
														<span>
															Delivery Date:
															<strong>{`${deliverySlotDay} ${deliverySlotDate}`}</strong>
														</span>
													</Text>
												) : (
													<Text
														as="h5"
														key={i}
														fontSize="20px"
														lineHeight="26px"
														color="text.black"
														style={{ marginBottom: '0px' }}
													>
														{data.extension_attributes.supplier_name}
														<strong>{` ${data.items?.length}`}</strong>
														{` ( `}
														<strong>{`#${data.extension_attributes.supplier_order_increment_id}`}</strong>
														{` - ${helperFunctions.toPascalCase(
															data.status?.replace('_', ' ') +
																(rejectionReason != '' && rejectionReason != undefined
																	? ': ' + rejectionReason
																	: '')
														)} )`}
													</Text>
												)}
											</Flex>

											<AllOrdersDetailsListView
												ordersDetailsListView={data.items}
											></AllOrdersDetailsListView>
										</Fragment>
									);
								})}
						</OrderDetailsBlock>
					)) || <Loader></Loader>}
				</MainContent>

				{/* Footer Session */}
				<Footer></Footer>
				{/* order again grid */}
			</PageHolder>
		</>
	);
}

export default AllOrderDetails;
