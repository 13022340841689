import React, { ReactElement, useEffect, useState } from 'react';
import { Text, Image, Box } from 'rebass/styled-components';
import 'react-tippy/dist/tippy.css';
import { Button, TableList } from 'ui/components';
import styled from 'styled-components';
import { StepperCart } from 'ui/components';
import Ipost from 'models/Ipost';
import api from 'utils/requestCtx/api.service';
import * as Icon from 'ui/icons';
import hooks from 'utils/requestCtx/reactQueryHooks';
import constants from 'utils/constants';
import Iget from 'models/Iget';
import SimpleBar from 'simplebar-react';
import { useHistory } from 'react-router-dom';

const StyledCartListView = styled.div`
	width: 100%;
	table {
		width: 100%;
		thead,
		tbody {
			tr {
				display: flex;
				align-items: center;

				td,
				th {
					text-align: left;
					width: 100%;
					padding: 0;
					background: ${({ theme }) => theme.colors.bg.white};
					white-space: nowrap;
					text-overflow: ellipsis;
					margin-right: auto;
					width: 220px;
					max-width: 220px;
					flex: 0 0 220px;
					padding: 0 10px;
					@media (max-width: 1440px) {
						width: 200px;
						max-width: 200px;
						flex: 0 0 200px;
					}
					h1,
					h2 {
						overflow: hidden;
						text-overflow: ellipsis;
						display: inline-block;
						white-space: nowrap;
						max-width: 100%;
						span {
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
						}
					}
					h1 {
						strong {
							font-size: 16px;
						}
						&:hover {
								color: ${({ theme }) => theme.baseColors.primary};
							}
					}
					&:nth-child(1) {
						display: flex;
						align-items: center;
						flex: 300px;
						max-width: 300px;
						width: 300px;
						@media (max-width: 1440px) {
							flex: 200px;
							max-width: 200px;
							width: 200px;
						}
						div {
							width: 100%;
							display: flex;
							align-items: center;
						}
						img {
							flex: 0 0 55px;
							max-width: 55px;
							margin-right: 15px;
							height: 55px;
							background-color: #faf4ea;
							object-fit: contain;
							border-radius: 5px;
							overflow: hidden;
						}
						h1 {
							cursor: pointer;
							span {
								word-break: break-word;
							}
						}
						h3 {
							margin-top: 2px;
							@media (max-width: 640px) {
								margin-bottom: 5px;
							}
						}
					}

					&:nth-child(4) {
						text-align: center;
						flex: 170px;
						max-width: 170px;
						width: 170px;
						justify-content: center;
						div {
							margin: auto;
						}
					}
					&:nth-child(2) {
						width: 200px;
						flex: 200px;
						max-width: 200px;
						@media (max-width: 1300px) {
							width: 150px;
							flex: 150px;
							max-width: 150px;
						}
					}

					&:nth-child(5) {
						text-align: right;
						width: 120px;
						flex: 120px;
						max-width: 120px;
					}
					&:nth-child(3) {
						width: 150px;
						flex: 150px;
						max-width: 150px;
						text-align: right;
					}
					&.action {
						width: 70px;
						max-width: 70px;
						flex: 0 070px;
						text-align: center;
						margin-right: 0;
						justify-content: center;
						background: transparent;
						@media screen and (max-width: 768px) {
							width: 33.33%;
							max-width: 33.33%;
							flex: 0 0 33.33%;
							text-align: left;
							justify-content: flex-start;
							label {
								margin-top: 8px;
							}
							button {
								margin-left: 0;
								margin-right: 10px;
							}
						}
						div {
							display: flex;
							align-items: center;
							justify-content: center;
							@media screen and (max-width: 768px) {
								justify-content: start;
								button {
									margin-left: 0;
									margin-right: 10px;
								}
							}
						}
						button {
							width: 34px;
							height: 34px;
							max-width: 34p;
							flex: 0 0 34px;
							border-radius: 8px;
							padding: 0;
							background: transparent;
							&:focus,
							&:hover,
							&:active {
								background: ${({ theme }) => theme.colors.bg.error}!important;
								svg {
									path {
										fill: ${({ theme }) => theme.colors.bg.white};
									}
								}
							}
							svg {
								width: 15px;
								height: 17px;
							}
						}
					}
				}
			}
		}
		thead {
			@media (max-width: 768px) {
				display: none;
			}
			background: #f5f5f5;
			tr {
				border: 1px solid transparent;
				box-sizing: border-box;
				border-radius: 8px;
				padding: 15px 16px 15px !important;
				th {
					background: #f5f5f5 !important;
					font-size: 14px;
					line-height: 18px;
					font-family: 'OpenSansBold';
					text-transform: uppercase;
				}
			}
		}
		tbody {
			display: block;
			min-height: max-content;
			margin-bottom: 0 !important;
			margin-top: -2px;
			@media screen and (max-width: 768px) {
				max-height: 100%;
				min-height: auto;
			}
			tr {
				border-radius: 0 !important;
				margin: 0 0 0 0 !important;
				margin-bottom: 0 !important;
				box-shadow: none !important;
				border-top: none !important;
				padding: 7px 14px !important;
				position: relative;
				border: 1px solid #f5f5f5;
				@media (max-width: 768px) {
					border: 1px solid ${({ theme }) => theme.colors.border.userListTD}!important;
					box-sizing: border-box !important;
					box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07) !important;
					margin-bottom: 15px !important;
					border-radius: 8px !important;
					flex-wrap: wrap;
					align-items: flex-start;
					padding: 14px 14px 7px !important;
				}
				td {
					@media screen and (max-width: 768px) {
						position: relative !important;
						padding-left: 0 !important;
						text-align: left !important;
						white-space: pre-wrap !important;
						overflow-wrap: break-word !important;
						display: flex !important;
						flex-direction: column !important;
						width: 33.3333%;
						flex: 0 0 33.3333%;
						max-width: 33.3333%;
						margin-bottom: 15px;
						&::before {
							content: attr(data-label);
							position: relative;
							display: block;
							font-family: 'OpenSansRegular';
							text-transform: uppercase;
							width: 100%;
							left: 0;
							font-size: 14px;
							line-height: 16px;
							white-space: pre-wrap;
							overflow-wrap: break-word;
							text-align: left;
							margin-bottom: 4px;
						}
						&.checked {
							position: absolute;
							top: 7px;
							right: 18px;
							width: 24px;
							z-index: 1;
							margin-bottom: 0;
						}
						h1 {
							font-family: 'OpenSansBold';
							
						}
					}
					slot {
						max-width: 110px;
						flex: 0 0 110px;
						font-size: 13px;
						line-height: 17px;
						display: flex;
						align-items: center;
						justify-content: center;
						margin: 0 auto;
						@media screen and (max-width: 768px) {
							flex: 0;
							margin: 0 auto 10px 0;
							width: 100px;
							min-height: 30px;
						}
					}
					@media (max-width: 768px) {
						&:nth-child(1),
						&:nth-child(2),
						&:nth-child(3),
						&:nth-child(4),
						&:nth-child(5),
						&.action {
							width: 33.333% !important;
							flex: 0 0 33.333% !important;
							max-width: 33.333% !important;
							button {
								margin-left: 0px !important;
								margin-right: 0 !important;
							}
						}
					}
					@media (max-width: 480px) {
						&:before {
							display: none;
						}
						&:nth-child(1) {
							width: 100% !important;
							flex: 0 0 100% !important;
							max-width: 100% !important;
							order: -1;
							h1 {
								padding-bottom: 30px;
								span {
									white-space: normal;
									font-size: 16px;
								}
							}
							div {
								img {
									flex: 0 0 60px;
									max-width: 60px;
									width: 60px;
									height: 60px;
									margin-right: 10px;
								}
								align-items: flex-start;
							}
						}
						&:nth-child(2) {
							width: auto !important;
							min-width: 100% !important;
							flex: 1 !important;
							max-width: fit-content !important;
							margin-top: -20px;
							background: transparent;
							h1 {
								font-size: 14px;
								padding-left: 70px !important;
								max-width: 230px;
							}
						}
						&:nth-child(3) {
							order: -1;
							width: auto !important;
							flex: 1 !important;
							max-width: fit-content !important;
							padding: initial;
							padding-left: 70px !important;
							margin-top: -50px;
							background: transparent;
							h1 {
								font-family: 'OpenSansMedium';
								color: ${({ theme }) => theme.colors.text.muted};
							}
						}
						&:nth-child(4) {
							flex: 150px !important;
							max-width: 150px !important;
							width: 150px !important;
							margin-left: 70px !important;
							background: transparent;
							button {
								width: 36px;
								flex: 0 0 36px;
								max-width: 36px;
							}
							input {
								font-size: 15px;
							}
						}
						&:nth-child(5) {
							width: 84px !important;
							flex: 0 0 84px !important;
							max-width: 84px !important;
							margin: -49px 0 0 0 !important;
							position: absolute !important;
							bottom: 33px;
							left: 0;
							text-align: center;

							h1 {
								text-align: center;
							}
						}
						&.action {
							position: absolute !important;
							flex: 44px !important;
							max-width: 44px !important;
							right: 10px;
							bottom: 29px;
							margin: 0;

							span {
								margin-bottom: 2px;
								span {
									margin-bottom: 0;
								}
							}
						}
					}
					@media (max-width: 340px) {
						&:nth-child(4) {
							flex: 130px;
							max-width: 130px;
							width: 130px;
							margin-left: 70px !important;
							background: transparent;
							button {
								width: 36px;
								flex: 0 0 36px;
								max-width: 36px;
							}
							input {
								font-size: 15px;
							}
						}
						&.action {
							button {
								margin-left: 10px !important;
								margin-right: 0 !important;
							}
						}
					}
				}
			}
		}
	}
`;
const TotalCostBlock = styled.div`
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	text-align: right;
	padding: 15px 20px;
	background: #f5f5f5;
	flex-direction: row;
	row-gap: 15px;
	margin-top: 10px;
	@media (max-width: 768px) {
		margin-top: 0;
		flex-direction: column;
	}
	h1,
	h2 {
		strong {
			font-size: 20px;
			font-family: 'OpenSansSemiBold';
			margin-left: 10px;
			float: right;
			text-align: left;
			@media (max-width: 768px) {
				width: 100px;
			}
		}
	}
	h2 {
		padding-left: 20px;
		strong {
			font-family: 'OpenSansBold';
		}
	}
`;

function CartListItems(props): ReactElement {
	const [totalAmtPerSupplier, setTotalSupplierCount] = useState<any>(0);
	const [totalTaxPerSupplier, setTotalTaxPerSupplier] = useState<any>(0);
	var history = useHistory();

	useEffect(() => {
		let totalAmtPerSupplier = 0;
		let totalTaxPerSupplier = 0;
		props?.cartProducts.forEach((data) => {
			totalAmtPerSupplier = totalAmtPerSupplier + data.price * data.qty;
			totalTaxPerSupplier = totalTaxPerSupplier + data.extension_attributes?.tax_amount;
		});
		setTotalSupplierCount(totalAmtPerSupplier);
		setTotalTaxPerSupplier(totalTaxPerSupplier);
	}, [props?.cartProducts]);

	async function callHandleChange(event, item, index, suppIndex) {
		props.handleChange(event, item, index, suppIndex);
	}

	async function callDecreaseCount(item, index, event, suppIndex) {
		props.decreaseCount(item, index, event, suppIndex);
	}
	async function callIncreaseCount(item, index, event, suppIndex) {
		props.increaseCount(item, index, event, suppIndex);
	}
	const navigateToDetails = (item, event) => {
		event.stopPropagation();
		event.preventDefault();
		if (props?.creditStatus[props.suppIndex] !== 'stop')
			history.push('/home/product-details?id=' + item.sku);
	};
	return (
		<StyledCartListView>
			<SimpleBar className="AccordionPanel">
				<table>
					<thead>
						<tr>
							<th>Product name</th>
							<th>SKU</th>
							<th>Price</th>
							<th>Quantity</th>
							<th>Total</th>
							<th className="action"></th>
						</tr>
					</thead>
					<tbody>
						{props?.cartProducts &&
							props?.cartProducts.map((obj, index) => (
								<tr key={obj.item_id}>
									<td data-label="Product name">
										<Box>
											<Image
												src={obj.extension_attributes?.thumbnail_image}
												sx={{
													width: 55,
													height: 55,
													borderRadius: 8,
												}}
											/>
											<Text
												onClick={(event) => {
													navigateToDetails(obj, event);
												}}
												as="h1"
												fontSize={15}
												fontFamily="OpenSansBold"
												color="text.black"
											>
												<span>{obj.name}</span>
												{!obj?.extension_attributes?.is_in_stock ? (
													<Text
														as="h3"
														fontSize="14px"
														fontFamily="OpenSansBold"
														color="text.error"
														textAlign="left"
													>
														Out of Stock !
													</Text>
												) : (
													''
												)}
											</Text>
										</Box>
									</td>
									<td data-label="SKU">
										<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
											{obj.sku}
										</Text>
									</td>
									<td data-label="Price">
										<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
											${obj.price} {obj.extension_attributes?.uom_name !== '' ? '/' : ''}
											{obj.extension_attributes?.uom_name}
										</Text>
									</td>
									<td data-label="Qty Pending">
										<StepperCart
											decreaseCount={(event) =>
												callDecreaseCount(obj, index, event, props.suppIndex)
											}
											handleChange={(event) => callHandleChange(event, obj, index, props.suppIndex)}
											increaseCount={(event) =>
												callIncreaseCount(obj, index, event, props.suppIndex)
											}
											supplierName={obj.extension_attributes?.supplier_name}
											index={index}
											groupBySupplierName={props.groupBySupplierName}
											suppIndex={props.suppIndex}
											validationData={props.validationData}
											disableStepper={props.disableStepper}
										/>
									</td>
									<td data-label="Total">
										<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
											${obj.price * obj.qty}
										</Text>
									</td>

									<td className="action" data-label="Action">
										<div className="btn-block">
											<Button variant="ghost" onClick={(event) => props.confirmDelete(event, obj)}>
												<Icon.DeleteIcon />
											</Button>
										</div>
									</td>
								</tr>
							))}
					</tbody>
				</table>
			</SimpleBar>
			<TotalCostBlock>
				<Text
					as="h1"
					fontSize="15px"
					fontFamily="OpenSansMedium"
					className="truncate"
					lineHeight="22px"
					color="text.black"
				>
					Tax<strong>${
						isNaN(totalTaxPerSupplier) ?
							0.00:
							parseFloat(totalTaxPerSupplier).toFixed(2)
					}</strong>
				</Text>
				<Text
					as="h2"
					fontSize="15px"
					fontFamily="OpenSansMedium"
					className="truncate"
					lineHeight="22px"
					color="text.black"
				>
					Order Total<strong>${
						isNaN(totalAmtPerSupplier) ?
						0.00:
						parseFloat(totalAmtPerSupplier).toFixed(2)
					}</strong>
				</Text>
			</TotalCostBlock>
		</StyledCartListView>
	);
}

export { CartListItems };
