import { permissionGroup, userTypes } from 'models/enum-constants/common.constants';
import { UseGlobalState } from 'models/globalStateAccess';
import Iget from 'models/Iget';
import React, { ReactElement, useEffect, useState, useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';
import { updateObjectLiteral } from 'typescript';
import { Button, Logo } from 'ui/components';
import {
	Cart,
	HeaderMenu,
	HeaderSearch,
	HeaderTopNav,
	Location,
	NotificationIndicator,
	ParkedOrderDropdown,
	ProfileDropdown,
	NotificationInfo,
} from 'ui/patterns';
import helperFunctions from 'utils/helper.functions';
import hooks from 'utils/requestCtx/reactQueryHooks';
import utilityFunctions from 'utils/utility.functions';
import * as Icon from '../../icons';
import LogoFile from '../../images/logo_exp.png';
import {
	HeaderCartLocation,
	HeaderLeft,
	HeaderMobileView,
	HeaderRight,
	HeaderRightNav,
	HeaderSearchWebView,
	StickyHeader,
	StyledHeader,
} from './styles';
import appContext from 'utils/appContext';

var token: any;

// type Headerprops = {
// 	onClick?: (dropdownVal: string, searchValue: string) => void;
// 	locationSelected?: string;
// };
function Header(prop): ReactElement {
	console.log = console.warn = () => {};
	let ctx = useContext(appContext).cartObj;
	var history = useHistory();
	token = localStorage.getItem('customerToken');
	var cartId = localStorage.getItem('cartId');
	var autoFetch = true;
	const userKey = 'miniCart';
	// const [miniCartBadge, setMiniCartBadge] = useState('0');
	// const [display, setDisplay] = useState(false);
	const [state, dispatch] = UseGlobalState();
	let userAccessType = localStorage.getItem('userType');
	const userType =
		userAccessType == userTypes.venueAdmin || userAccessType == userTypes.venueUser
			? userTypes.HospitalityGroup
			: userTypes.Supplier;

	// const getVariables = (dropdownval, searchVal) => {
	// 	prop.onClick && prop.onClick(dropdownval, searchVal);
	// };

	//Invalid Token Handling
	if (token == null) {
		history.push('/login');
		autoFetch = false;
	}

	//User Type Handling - Cart API should be called only for Hospitality Group
	if (userType != userTypes.HospitalityGroup) {
		autoFetch = false;
	}

	if (cartId == null && userType == userTypes.HospitalityGroup) {
		autoFetch = false;
		let CUSTOMER_TOKEN = localStorage.getItem('customerToken');
		utilityFunctions.getCartId(CUSTOMER_TOKEN);
		cartId = localStorage.getItem('cartId');

		if (cartId == null) {
			history.push('/login');
			autoFetch = false;
		} else {
			autoFetch = true;
		}
	}

	const url = 'carts/' + cartId + '/totals';
	const obj: Partial<Iget> = {
		params: {},
		url: url,
		enabled: false,
		key: userKey,
		resultFilteringRequired: false,
	};
	var qry = hooks.useQuery(obj);

	useEffect(() => {
		if (autoFetch) {
			qry.refetch().then(function (data) {
				updateCart(data);
			});
		}
	}, []);

	// useEffect(() => {
	// 	if(qry.data){
	// 		updateCart(qry.data);
	// 	}
	// }, [qry.data]);

	async function goToCart() {
		const CUSTOMER_TOKEN = localStorage.getItem('customerToken');
		ctx.stepperdCountUpdated = false;
		let id: any;
		id = await utilityFunctions.getCartId(CUSTOMER_TOKEN);
		if (id !== undefined) {
			history.push('/store/cart');
		}
	}

	function updateCart(data) {
		if (typeof data != 'undefined') {
			let datas = data.items?.filter((x) => x.is_deleted != true);
			if (datas?.length > 0) {
				dispatch({ cartTotal: helperFunctions.formatAmount(data.grand_total) });
				dispatch({ cartItemCount: datas?.length });
			} else {
				dispatch({ cartItemCount: '0' });
				dispatch({ cartTotal: '' });
			}
		} else {
			dispatch({ cartItemCount: '0' });
			dispatch({ cartTotal: '' });
		}
	}

	return (
		<>
			<StickyHeader>
				<StyledHeader>
					{/* Header Hamburger Menu Logo and Page Search */}
					<HeaderLeft>
						<Button
							iconBefore={<Icon.hamburgerMenu title="hamburgerMenu" />}
							onClick={() => {
								prop.toggleSideBar && prop.toggleSideBar();
							}}
							variant="hamburgerMenu"
						></Button>
						{userType == userTypes.HospitalityGroup && (
							<Logo route="/home/dashboard" src={LogoFile}></Logo>
						)}
						{userType == userTypes.Supplier && (
							<Logo route="/admin/seller-dashboard" src={LogoFile}></Logo>
						)}
						<>
							{userType == userTypes.HospitalityGroup && (
								<HeaderSearchWebView>
									{/* Page Search */}
									<HeaderSearch
										updateDropDown={prop.updateDropDown}
										queryString={prop.queryString}
										itemSearch={prop.itemSearchValue}
									></HeaderSearch>
								</HeaderSearchWebView>
							)}
						</>
					</HeaderLeft>

					{/* Header Nav and Avatar */}
					<HeaderRight>
						<HeaderRightNav>
							<HeaderTopNav></HeaderTopNav>
						</HeaderRightNav>

						{userType == userTypes.HospitalityGroup &&
							utilityFunctions.isAnyGranted(permissionGroup.ParkedOrder) && (
								<ParkedOrderDropdown></ParkedOrderDropdown>
							)}
						{userType == userTypes.HospitalityGroup && <NotificationInfo></NotificationInfo>}
						{userType == userTypes.Supplier && <NotificationIndicator />}
						<ProfileDropdown></ProfileDropdown>
					</HeaderRight>
				</StyledHeader>

				{/* Mobile View -- Location Page Search ANd Navigation */}
				<HeaderMobileView>
					{/* <Location location="Hillsborough, Auckland" /> */}
					{userType == userTypes.HospitalityGroup && (
						<HeaderSearch
							updateDropDown={prop.updateDropDown}
							queryString={prop.queryString}
							itemSearch={prop.itemSearchValue ? prop.itemSearchValue : ''}
						></HeaderSearch>
					)}
					<HeaderTopNav></HeaderTopNav>
				</HeaderMobileView>

				<HeaderCartLocation>
					{/* Location */}
					<Location location={prop?.locationSelected} />

					{/* Sub Menu */}

					<HeaderMenu
						userAccessType={userAccessType}
						userType={userType}
						className="hiddenMenu"
						queryString={prop.queryString}
						closeHumburgur={() => {}}
					></HeaderMenu>

					{/* Add TO Cart */}
					{
						userType == userTypes.HospitalityGroup &&
							// (miniCartBadge != '0' && miniCartBadge != '' ?
							((!qry.isFetching && (
								<a href="#!" onClick={goToCart} style={{ textDecorationLine: 'none' }}>
									<Cart count={state.cartItemCount} amount={state.cartTotal} />
								</a>
							)) || <Skeleton width="100px" height="20px" count={1}></Skeleton>)
						// : (
						// 	<a href="#!" onClick={goToCart} style={{ textDecorationLine: 'none' }}>
						// 		<Cart count={state.cartItemCount} amount={state.cartTotal} />
						// 	</a>
						// )
						// )
					}
				</HeaderCartLocation>
			</StickyHeader>
			{/* <Modal
				width="600px"
				showModal={showParkedOrderLimitReachedModal}
				setShowModal={setShowParkedOrderLimitReachedModal}
			>
				<ParkedOrderLimitReached />
			</Modal> */}
		</>
	);
}

export { Header };
