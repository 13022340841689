import React, { ReactElement, SyntheticEvent, useEffect, useRef, useState } from 'react';
import RSC from 'react-scrollbars-custom';
import { Box, Button, Flex, Link, Text } from 'rebass/styled-components';
import { TabsView } from 'ui/components';
import useOnClickOutside from 'use-onclickoutside';
import * as Icon from 'ui/icons';
import { StyledNotifications, BadgeCount } from './styles';
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { WatchListNotification, WatchListView } from 'ui/patterns';
import Iget from 'models/Iget';
import hooks from 'utils/requestCtx/reactQueryHooks';
import { userTypes } from 'models/enum-constants/common.constants';
import constants from 'utils/constants';
function NotificationInfo({ autoClose = true }): ReactElement {
	const [open, setOpen] = useState(false);
	const [watchListCount, setWatchListCount] = useState(0);
	const [notificationCount, setNotificationCount] = useState(0);
	const [watchFullViewList, setWatchFullViewList] = useState([] as any);
	const [notificationViewList, setNotificationViewList] = useState([] as any);
	const handleOnClickOutside = () => {
		setOpen(false);
	};
	const wrapperRef = useRef<HTMLDivElement>(null);
	useOnClickOutside(wrapperRef, handleOnClickOutside);
	const handleToggle = (e: SyntheticEvent<HTMLDivElement>) => {
		e.stopPropagation();
		setOpen(autoClose === true ? !open : true);
	};
	let user_Type = localStorage.getItem('userType');
	let venue = localStorage.getItem('selectedVenue');
	let location = localStorage.getItem('selectedLocation');
	let userId = localStorage.getItem('userId');
	let loc =
		user_Type == userTypes.venueAdmin || user_Type == userTypes.venueUser ? venue : location;
	const url =
		loc && typeof userId != 'undefined' && userId != ''
			? constants.productWatchNotification() + loc + '/' + userId
			: '';
	const CUSTOMER_TOKEN = localStorage.getItem('customerToken');
	function getUserType() {
		let userType = '';
		switch (user_Type) {
			case userTypes.venueAdmin:
				userType = 'hg_admin';
				break;
			case userTypes.venueUser:
				userType = 'venue_user';
				break;
			case userTypes.supplierAdmin:
				userType = 'supply_admin';
				break;
			case userTypes.supplierUser:
				userType = 'dc_user';
				break;
			default:
				break;
		}
		return userType;
	}
	const notificationUrl =
		loc &&
			typeof userId != 'undefined' &&
			userId != '' &&
			typeof user_Type != 'undefined' &&
			user_Type != ''
			? `experion/notifications/${loc}/${getUserType()}/${userId}`
			: '';
	const notificationObj: Partial<Iget> = {
		params: {},
		url: notificationUrl,
		enabled: true,
		key: 'Notification',
		resultFilteringRequired: false,
	};
	var notificationListQry = hooks.useQuery(notificationObj);
	useEffect(() => {
		setNotificationViewList(notificationListQry.data);
		const count = notificationListQry?.data
			? notificationListQry.data?.filter((x) => x.mark_read == '0')?.length
			: 0;
		setNotificationCount(count);
	}, [notificationListQry.data]);
	const watchListObj: Partial<Iget> = {
		params: {},
		url: url,
		enabled: true,
		key: 'WatchNotification',
		resultFilteringRequired: false,
		headers: { Authorization: CUSTOMER_TOKEN },
	};
	var watchListQry = hooks.useQuery(watchListObj);
	useEffect(() => {
		setWatchFullViewList(watchListQry.data);
		const count = watchListQry?.data
			? watchListQry.data?.filter((x) => x.mark_read == '0')?.length
			: 0;
		setWatchListCount(count);
	}, [watchListQry.data]);

	const updateNotificationLists = (event) => {
		handleToggle(event);
		if (!open) {
			notificationListQry.refetch();
			watchListQry.refetch();
		}
		setOpen(!open);
	};
	return (
		<StyledNotifications ref={
			wrapperRef
		} onClick={updateNotificationLists}>
			<Accordion allowZeroExpanded>
				<AccordionItem dangerouslySetExpanded={open} >
					<AccordionItemHeading>
						<AccordionItemButton className={(((notificationCount > 0) || (watchListCount > 0)) ? 'notificationBadge' : '')}>
							<Icon.notificationIcon />
						</AccordionItemButton>
					</AccordionItemHeading>

					<AccordionItemPanel>
						<Tabs onSelect={(index, last, e) => e.stopPropagation()}>
							<TabsView>
								<TabList>
									<Tab>
										Notifications
										{notificationCount > 0 ? <BadgeCount>{notificationCount}</BadgeCount> : null}
									</Tab>
									<Tab>
										Watch List
										{watchListCount > 0 ? <BadgeCount>{watchListCount}</BadgeCount> : null}
									</Tab>
								</TabList>
								<TabPanel>
									<WatchListNotification
										notificationList={notificationViewList}
										updateUnReadCount={setNotificationCount}
										updateNotificationList={setNotificationViewList}
									/>
								</TabPanel>
								<TabPanel>
									<WatchListView
										notificationList={watchFullViewList}
										updateUnReadCount={setWatchListCount}
										updateNotificationList={setWatchFullViewList}
									/>
								</TabPanel>
							</TabsView>
						</Tabs>
					</AccordionItemPanel>

				</AccordionItem>
			</Accordion>
		</StyledNotifications>
	);
}

export { NotificationInfo };
