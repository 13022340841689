import React, { ReactElement, useEffect, useState } from 'react';
import { Text, Box, Flex, Link, Image } from 'rebass/styled-components';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import { CartListItems } from 'ui/patterns';
import icons from 'ui/icons';
import styled, { css } from 'styled-components';
import { Accordion, AccordionItem, AccordionButton, AccordionPanel } from '@reach/accordion';
import '@reach/accordion/styles.css';
import { Select } from '@rebass/forms';
import Iget from 'models/Iget';
import Ipost from 'models/Ipost';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import constants from 'utils/constants';
import helper from 'utils/helper.functions';
import { useHistory } from 'react-router-dom';
import helperFunctions from 'utils/helper.functions';
import Countdown from 'react-countdown';
import SweetAlert from 'react-bootstrap-sweetalert';
import utilityFunctions from 'utils/utility.functions';
import nodataImage from 'ui/images/Nodata.svg';
import { Loader } from 'ui/components';
import { Button } from 'ui/components';
import moment from 'moment';

const StyledProductCartListView = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	column-gap: 20px;
	row-gap: 20px;
	width: 100%;
	padding-bottom: 30px;
	@media (max-width: 1440px) {
		margin-bottom: 30px !important;
	}
	@media (max-width: 1200px) {
		grid-template-columns: repeat(1, 1fr);
	}
	/* .simplebar-wrapper {
		max-height: calc(100vh - 490px);
		min-height: calc(100vh - 490px);
		.simplebar-content-wrapper {
			height: 100% !important;
		}
		@media (max-width: 1440px) {
			max-height: calc(100vh - 90px);
			min-height: auto;
			margin-bottom: 30px !important;
		}
		@media (max-width: 768px) {
			max-height: max-content;
			min-height: auto;
		}
	} */
	.accordion {
		row-gap: 20px;
		border: none;
		display: grid;
		border: none;
	}
`;

const NodataFound = styled.div`
	width: 100%;
	display: flex;
	display: flex;
	align-items: center;
	justify-content: center;
	height: calc(100vh - 494px);
	grid-column: 3/1;
	img {
		margin: 0;
	}
	h1 {
		text-align: center;
		margin-top: 15px;
	}
`;
const ProductCartList = styled.div<ProductCartListProps>`
	[data-reach-accordion-panel] {
		transition: max-height 0.35s ease;
		max-height: 0;
		overflow: hidden;
		display: none;
	}
	[data-reach-accordion-item][data-state='open'] {
		.accordionButton {
			transform: rotate(-180deg);
			background: ${({ theme }) => theme.baseColors.primary};

			svg {
				path {
					fill: ${({ theme }) => theme.baseColors.white};
				}
			}
		}
	}
	[data-reach-accordion-panel][data-state='open'] {
		transition: max-height 0.35s ease;
		max-height: max-content;
		display: flex;
		flex-wrap: wrap;
		border-top: 1px solid ${({ theme }) => theme.newColors.gray};
		padding: 25px;
		width: 100%;
		@media (max-width: 480px) {
			padding: 15px;
		}
		@media (max-width: 340px) {
			padding: 5px;
		}
		[data-simplebar] {
			width: 100%;
		}
	}
	[data-reach-accordion-panel][data-state='collapsed'] {
		transition: max-height 0.35s ease;
		max-height: 0;
		overflow: hidden;
		display: block;
	}
	.CartAccordionItem {
		width: 100%;
		background: ${({ theme }) => theme.colors.bg.white};
		border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
		box-sizing: border-box;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 16px;
		transition: box-shadow 100ms ease-in, transform 100ms ease-in;
	}
	header {
		justify-content: space-between;
		width: 100%;
		padding: 15px 70px 15px 25px;
		position: relative;
		display: grid;
		grid-template-columns: repeat(4, 1fr) !important;
		column-gap: 25px;
		row-gap: 25px;
		@media (max-width: 1440px) {
			align-items: flex-start;
		}
		@media (max-width: 1200px) {
			row-gap: 15px;
			grid-template-columns: repeat(3, 1fr) !important;
			align-items: flex-start;
		}
		@media (max-width: 1152px) {
			grid-template-columns: repeat(2, 1fr) !important;
		}
		@media (max-width: 768px) {
			column-gap: 15px;
			row-gap: 5px;
		}
		@media (max-width: 480px) {
			padding: 15px 60px 15px 25px;
		}
		@media (max-width: 340px) {
			grid-template-columns: repeat(1, 1fr) !important;
		}
		section {
			width: auto;
		}
		.supplerName {
			width: 350px;
			p {
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				display: block;
			}
			@media (max-width: 1540px) {
				width: 250px;
			}
			@media (max-width: 1152px) {
				width: 100%;
			}
			@media (max-width: 768px) {
				width: 320px;
			}
			@media (max-width: 640px) {
				width: auto;
			}
			@media (max-width: 480px) {
				grid-column: 3/1;
			}
			h1 {
				display: flex;
			}
			[data-tooltipped] {
				margin: 4px 0 0 4px;
				cursor: pointer;
				svg {
					height: 18px;
					width: 28px;
				}
			}
		}
		.deliveryDate {
			display: flex;
			width: 350px;
			align-items: flex-start;
			h1 {
				white-space: nowrap;
				display: flex;
				flex: 0 0 150px;
				max-width: 150px;
				width: 150px;
				height: 34px;
				align-items: center;
				justify-content: flex-end;
				padding-right: 10px;
				@media (max-width: 1440px) {
					justify-content: flex-start;
					padding-right: 0;
				}
			}
			[data-tooltipped] {
				margin: 4px 4px 0 0;
				cursor: pointer;
				svg {
					height: 18px;
					width: 28px;
				}
			}
			@media (max-width: 1440px) {
				width: 200px;
				flex-direction: column;
				align-items: flex-start;
				h1 {
					flex: 100%;
					width: 100%;
					max-width: 100%;
				}
			}
			@media (max-width: 1152px) {
				width: 100%;
			}
			@media (max-width: 480px) {
				grid-column: 3/1;
				width: 100%;
			}
		}
	}
	.AccordionPanel {
		.simplebar-wrapper {
			max-height: max-content !important;
			min-height: auto !important;
		}
	}
	.orderTotal {
		width: 100%;
		text-align: right;
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		h1 {
			padding-right: 5px;
		}
		@media (max-width: 1440px) {
			flex-direction: column;
			align-items: flex-end;
		}
	}
	.mov {
		display: flex;
		align-items: center;
		[data-tooltipped] {
			cursor: pointer;
		}
		h1 {
			margin-right: 5px;
		}
		svg {
			width: 14px;
			height: 14px;
		}
	}
	.accordionButton {
		background: transparent;
		padding: 0;
		width: 32px;
		height: 32px;
		border: none;
		max-width: 32px;
		flex: 0 0 32px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		border-radius: 5px;
		position: absolute;
		right: 17px;
		top: 17px;
		transition-duration: 0.8s;
		transition-property: transform;
		border: 1px solid ${({ theme }) => theme.baseColors.primary};
		@media (max-width: 768px) {
			top: 15px;
		}
		svg {
			width: 14px;
			height: 12px;
			path {
				fill: ${({ theme }) => theme.baseColors.primary};
			}
		}
		&:hover {
			background: ${({ theme }) => theme.baseColors.primary};

			svg {
				path {
					fill: ${({ theme }) => theme.baseColors.white};
				}
			}
		}
		.icon-chevron-up {
			display: none;
		}
	}
`;

const SelectHolder = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	div {
		width: 100%;
	}
	select {
		padding: 0;
		font-size: 14px;
		width: 100%;
		height: 34px;
		border: 1px solid ${({ theme }) => theme.colors.border.default};
		border-radius: 4px;
		padding: 0 10px;
		outline: none !important;
		box-shadow: none !important;
	}
	i {
		font-size: 6px;
		margin-left: 5px;
		margin-top: 3px;
	}
`;
type ProductCartListProps = {
	active?: boolean;
};
type ProductCartListViewProps = {
	productCartList: Array<list>;
};
type list = {
	id?: string | number;
	image?: string;
	supplerName?: string;
	mov?: string;
	orderTotal?: string;
	deliveredTime?: string;
	deliveredDate?: string;
};

function ProductCartListView(props): ReactElement {
	let getAppConfig = JSON.parse(localStorage.getItem('appconfig')!);
	const CUSTOMER_TOKEN = localStorage.getItem('customerToken');
	var [groupBySupplierName, setGroupBySupplierName] = useState<any>([{ key: '', value: [] }]);
	var [group, setGroup] = useState<any>({});
	const [isLoading, setIsLoading] = useState(false);
	const [cartDetails, setCartDetails] = useState<any>([]);
	const [validationData, SetValidationData] = useState(new Array() as any);
	var [deliverySlots, setDeliverySlots] = useState<any>([]);
	var cartId = localStorage.getItem('cartId');
	var deliveryMonth: any;
	var history = useHistory();
	const [venueId, setVenueId] = useState<any>(localStorage.getItem('selectedVenue'));
	const [deliverySlotList, setDeliverySlotList] = useState([] as any);
	const [deleteItem, setDeleteItem] = useState<any>();
	const [showPopup, setShowModal] = useState(false);
	const [disableStepper, setDisableStepper] = useState(false);
	const [creditStatus, setCreditStatus] = useState(new Array());

	var sum = 0 as any;
	const url1 = constants.getCartItems(); // get cart items api call
	const obj1: Partial<Iget> = {
		params: {},
		url: url1,
		enabled: true,
		key: 'cartKey',
		resultFilteringRequired: false,
		headers: { Authorization: CUSTOMER_TOKEN },
	};
	var qry = hooks.useQuery(obj1);

	useEffect(() => {
		let unmounted = false;
		setIsLoading(true);
		if (qry.data !== undefined) {
			setCartDetails(qry.data);
			updateCartDetails(qry.data);
			setIsLoading(false);
		} else {
			setGroupBySupplierName([]);
			setIsLoading(false);
		}
		return () => { unmounted = true };
	}, [qry.data]);

	useEffect(() => {
		if (props?.IsCartUpdated)
			setGroupBySupplierName([]);
	}, [props?.IsCartUpdated]);

	async function addItemsToCart(sku, count, item_id, event) {
		setIsLoading(true);
		setDisableStepper(true);
		let id: any;
		id = await utilityFunctions.getCartId(CUSTOMER_TOKEN);
		if (id !== undefined) {
			let addToCartJson = {
				cartItem: {
					sku: sku,
					qty: count,
					quote_id: cartId,
				},
			};

			const obj4: Partial<Ipost> = {
				data: addToCartJson,
				params: {},
				url: '/carts/' + cartId + '/items/' + item_id,
				message: 'Your cart has been updated!',
			};
			try {
				await api
					.put(obj4)
					.then(function (data) {
						if (data) {
							props.updateTotalAmount && props.updateTotalAmount();
							qry.refetch().then((data) => { });
							setIsLoading(false);
							setDisableStepper(false);
						}
					})
					.catch(function (data) {
						setDisableStepper(false);
						setIsLoading(false);
					});
				setIsLoading(false);
				setDisableStepper(false);
			} catch (e) {
				setIsLoading(false);
				setDisableStepper(false);
			}
		}
	}

	function updateCartDetails(data) {
		if (data != undefined) {
			if (data?.filter((x) => x.extension_attributes.is_delete == false)?.length === 0) {
				props.callBackFunction(true, 'cartTotal');
				groupBySupplierName = [];
				setGroupBySupplierName(groupBySupplierName);
			} else {
				group = {};
				setGroup(group);
				data
					?.filter((x) => x.extension_attributes.is_delete == false)
					.forEach((item, index) => {
						if (item.extension_attributes?.credit_status.length === 0) {
							creditStatus[index] = 'go';
							setCreditStatus(creditStatus);
						} else {
							item.extension_attributes?.credit_status.forEach((obj) => {
								if (parseInt(venueId) === obj) {
									creditStatus[index] = 'stop';
									props.callBackFunction(true, 'credit');
									setCreditStatus(creditStatus);
								}
							});
						}
						if (item.extension_attributes.is_in_stock === false) {
							props.callBackFunction(true, 'stock');
						}
					});

				groupBySupplierName = helper.GroupBy(
					data?.filter((x) => x.extension_attributes.is_delete == false),
					(x) => x.extension_attributes.supplier_name
				);
				if (groupBySupplierName.length > 0) {
					let localData: any;
					localData = localStorage.getItem('deliverySlot');
					if (localData !== null) {
						if (JSON.parse(localData).length !== groupBySupplierName?.length) {

							props.callBackFunction(true, 'slot');
						} else {
							props.callBackFunction(false, 'slot');
						}
					} else {
						props.callBackFunction(true, 'slot');
					}
					groupBySupplierName.forEach((eachGrp) => {
						let itemsProcessed = 0;
						sum = 0;
						eachGrp.value.forEach((eachItem) => {
							itemsProcessed++;
							sum = sum + eachItem.qty * eachItem.price;
							if (itemsProcessed === eachGrp.value.length) {
								if (parseInt(sum) < parseInt(eachItem.extension_attributes?.mov)) {
									eachGrp['isMovMet'] = false;
									props.callBackFunction(true, 'mov');
								} else {
									eachGrp['isMovMet'] = true;
								}
							}
						});
					});
					var newArray = groupBySupplierName.filter(function (el) {
						return el.isMovMet == false;
					});
					if (newArray.length === 0) {
						props.callBackFunction(false, 'mov');
					}
					setGroupBySupplierName(groupBySupplierName);
				}
			}
			setIsLoading(false);
		} else {
			setIsLoading(false);
		}
	}
	const increaseCount = (item, index, event, suppIndex) => {
		event.stopPropagation();
		event.preventDefault();
		item.qty = item.qty + 1;
		setGroupBySupplierName(groupBySupplierName);
		if (parseInt(item.qty) >= 0) {
			let validation = { index: 0, SuppIndex: 0, value: '' };
			let validationData = [] as any;
			if (
				parseInt(item.qty) >= parseInt(item?.extension_attributes.min_order_qty) &&
				parseInt(item.qty) <= parseInt(item?.extension_attributes.max_order_qty)
			) {
				validation.index = index;
				validation.SuppIndex = suppIndex;
				validation.value = '';
				validationData.push(validation);
				SetValidationData(validationData);
				addItemsToCart(item.sku, item.qty, item.item_id, event);
				props.callBackFunction(false, 'qtyIssue');
			} else if (parseInt(item.qty) < parseInt(item?.extension_attributes.min_order_qty)) {
				validation.index = index;
				validation.SuppIndex = suppIndex;
				validation.value = 'Min quantity is ' + item?.extension_attributes.min_order_qty;
				validationData.push(validation);
				SetValidationData(validationData);
				props.callBackFunction(true, 'qtyIssue');
			} else if (parseInt(item.qty) > parseInt(item?.extension_attributes.max_order_qty)) {
				if (parseInt(item?.extension_attributes.max_order_qty) === 0) {
					validation.index = index;
					validation.SuppIndex = suppIndex;
					validation.value = '';
					validationData.push(validation);
					SetValidationData(validationData);
					addItemsToCart(item.sku, item.qty, item.item_id, event);
					props.callBackFunction(false, 'qtyIssue');
				} else {
					validation.index = index;
					validation.SuppIndex = suppIndex;
					validation.value = 'Max quantity is ' + item?.extension_attributes.max_order_qty;
					validationData.push(validation);
					SetValidationData(validationData);
					props.callBackFunction(true, 'qtyIssue');
				}
			}
		}
	};

	const decreaseCount = (item, index, event, suppIndex) => {
		event.stopPropagation();
		event.preventDefault();
		item.qty = item.qty - 1;
		if (parseInt(item.qty) >= 0) {
			let validation = { index: 0, SuppIndex: 0, value: '' };
			let validationData = [] as any;
			if (
				parseInt(item.qty) >= parseInt(item?.extension_attributes.min_order_qty) &&
				parseInt(item.qty) <= parseInt(item?.extension_attributes.max_order_qty)
			) {
				addItemsToCart(item.sku, item.qty, item.item_id, event);
				validation.index = index;
				validation.SuppIndex = suppIndex;
				validation.value = '';
				validationData.push(validation);
				SetValidationData(validationData);
				props.callBackFunction(false, 'qtyIssue');
			} else if (parseInt(item.qty) < parseInt(item?.extension_attributes.min_order_qty)) {
				validation.index = index;
				validation.SuppIndex = suppIndex;
				validation.value = 'Min quantity is ' + item?.extension_attributes.min_order_qty;
				validationData.push(validation);
				SetValidationData(validationData);
				props.callBackFunction(true, 'qtyIssue');
			} else if (parseInt(item.qty) > parseInt(item?.extension_attributes.max_order_qty)) {
				if (parseInt(item?.extension_attributes.max_order_qty) === 0) {
					validation.index = index;
					validation.SuppIndex = suppIndex;
					validation.value = '';
					validationData.push(validation);
					SetValidationData(validationData);
					addItemsToCart(item.sku, item.qty, item.item_id, event);
					props.callBackFunction(false, 'qtyIssue');
				} else {
					validation.index = index;
					validation.SuppIndex = suppIndex;
					validation.value = 'Max quantity is ' + item?.extension_attributes.max_order_qty;
					validationData.push(validation);
					SetValidationData(validationData);
					props.callBackFunction(true, 'qtyIssue');
				}
			}
		}
	};
	const handleChange = (event, item, index, suppIndex) => {
		event.stopPropagation();
		event.preventDefault();
		let eventValue = event.target.value;
		item.qty = parseInt(eventValue);
		let validation = { index: 0, SuppIndex: 0, value: '' };
		let validationData = [] as any;
		if (eventValue === '' || eventValue === '0' || eventValue === undefined) {
			validation.index = index;
			validation.SuppIndex = suppIndex;
			validation.value = 'Min quantity is ' + item?.extension_attributes.min_order_qty;
			validationData.push(validation);
			SetValidationData(validationData);
			props.callBackFunction(true, 'qtyIssue');
		} else {
			if (
				parseInt(eventValue) >= parseInt(item?.extension_attributes.min_order_qty) &&
				parseInt(eventValue) <= parseInt(item?.extension_attributes.max_order_qty)
			) {
				addItemsToCart(item.sku, item.qty, item.item_id, event);
				validation.index = index;
				validation.SuppIndex = suppIndex;
				validation.value = '';
				validationData.push(validation);
				SetValidationData(validationData);
				props.callBackFunction(false, 'qtyIssue');
			} else if (parseInt(eventValue) < parseInt(item?.extension_attributes.min_order_qty)) {
				validation.index = index;
				validation.SuppIndex = suppIndex;
				validation.value = 'Min quantity is ' + item?.extension_attributes.min_order_qty;
				validationData.push(validation);
				SetValidationData(validationData);
				props.callBackFunction(true, 'qtyIssue');
			} else if (parseInt(eventValue) > parseInt(item?.extension_attributes.max_order_qty)) {
				if (parseInt(item?.extension_attributes.max_order_qty) === 0) {
					validation.index = index;
					validation.SuppIndex = suppIndex;
					validation.value = '';
					validationData.push(validation);
					SetValidationData(validationData);
					addItemsToCart(item.sku, item.qty, item.item_id, event);
					props.callBackFunction(false, 'qtyIssue');
				} else {
					validation.index = index;
					validation.SuppIndex = suppIndex;
					validation.value = 'Max quantity is ' + item?.extension_attributes.max_order_qty;
					validationData.push(validation);
					SetValidationData(validationData);
					props.callBackFunction(true, 'qtyIssue');
				}
			}
		}
	};
	const getTotalSuppAmt = (value) => {
		let sum = 0;
		value.forEach((x) => {
			if (x.qty)
				sum = sum + x.qty * x.price;
		});
		return sum;
	};

	const getCountDown = (order_process_time) => {
		//count down timer functionality cart
		if (
			order_process_time &&
			order_process_time !== undefined &&
			order_process_time?.next_avaliable_time !== ''
		) {
			const countDownDate = moment(
				order_process_time?.next_avaliable_time,
				'DD-MM-YYYY HH:mm:ss'
			).valueOf();
			const now = moment(order_process_time?.current_time, 'DD-MM-YYYY HH:mm:ss').valueOf();
			const distance = countDownDate - now;
			return distance;
		} else {
			return 0;
		}
	};

	const getDropDownValues = (supplierId, event) => {
		if (supplierId !== undefined) {
			let isExists = deliverySlots.some(function (el) {
				return el.key === supplierId;
			});
			if (isExists === false) {
				(event as any)?.currentTarget?.blur();
				setIsLoading(true);
				api
					.get({
						url: `/experion/deliverySlotAvilable/get/?supplierId=` + supplierId + `&venueId=` + venueId,
					})
					.then(function (data) {
						let deliverySlot = { value: [] };
						deliverySlot['key'] = supplierId;
						deliverySlot.value = data;
						deliverySlots.push(deliverySlot);
						setDeliverySlots(JSON.parse(JSON.stringify(deliverySlots)));
						setIsLoading(false);
					});
			}
		}
	};

	const selectDeliverySlot = (event, suppId) => {
		let deliverySlot = { supplier_id: 0, slot: '' };
		deliverySlot.slot = event.target.value;
		deliverySlot.supplier_id = suppId;
		const filteredArray = deliverySlotList.filter((data) => data.supplier_id !== suppId);
		if (deliverySlot.slot !== 'Select') {
			filteredArray.push(deliverySlot);
		}
		setDeliverySlotList(filteredArray);
		if (filteredArray.length !== groupBySupplierName?.length) {
			props.callBackFunction(true, 'slot');
		} else {
			props.callBackFunction(false, 'slot');
		}
		// setDeliveryFilteredArray(JSON.stringify(filteredArray));
		localStorage.setItem('deliverySlot', JSON.stringify(filteredArray));
	};

	const removeItem = (event) => {
		event?.stopPropagation();
		let cartId: any;
		setIsLoading(true);
		setShowModal(!showPopup);
		cartId = localStorage.getItem('cartId');
		const obj1: Partial<Ipost> = {
			params: {},
			url: '/carts/' + cartId + '/items/' + deleteItem.item_id,
			data: {},
			message: 'Item deleted successfully',
		};

		api.delete(obj1).then(function (res) {
			if (res === true) {
				try {
					setIsLoading(false);
					cartDetails.splice(cartDetails.indexOf(deleteItem), 1);
					updateCartDetails(cartDetails);
					props.updateTotalAmount();
					const checkSupplierData = groupBySupplierName.filter(x=>x.value[0]?.extension_attributes?.supplier_id ===deleteItem.extension_attributes.supplier_id).length === 0;
					if(checkSupplierData){
						selectDeliverySlot({target:{value:'Select'}},deleteItem.extension_attributes.supplier_id);
					}
				} catch (ex) {
					setIsLoading(false);
				}
			}
		});
	};

	const closePopup = () => {
		setShowModal(!showPopup);
	};

	const confirmDelete = (event, item) => {
		event.stopPropagation();
		setDeleteItem(item);
		setShowModal(!showPopup);
	};
	return (
		<StyledProductCartListView>
			{isLoading === true || qry.isLoading ? <Loader></Loader> : null}
			<Accordion className="accordion" collapsible defaultIndex={0}>
				{groupBySupplierName?.length > 0 ? (
					groupBySupplierName.map((item, suppIndex) => {
						var deliveryMonth: any;
						let deliverydate =
							item?.value[0]?.extension_attributes?.order_process_time?.next_avaliable_time;
						if (deliverydate && deliverydate !== '') {
							deliveryMonth = helperFunctions.formatDate(
								moment(deliverydate, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD hh:mm:ss')
							);
						}
						return (
							<ProductCartList key={suppIndex}>
								<AccordionItem className="CartAccordionItem" key={item.id}>
									<Flex as="header">
										<Box as="section" className="supplerName">
											<Text as="p" fontSize={20} fontWeight="bold" color="black">
												{item.key}
											</Text>
											{deliveryMonth && (
												<Text
													as="h2"
													fontSize="13px"
													fontFamily="OpenSansSemiBold"
													color="text.error01"
												>
													Order in{' '}
													<strong>
														<Countdown
															date={
																Date.now() +
																getCountDown(
																	item?.value[0]?.extension_attributes?.order_process_time
																)
															}
														/>
													</strong>{' '}
													to get it processed on <strong>{deliveryMonth}</strong>
												</Text>
											)}

											{creditStatus[suppIndex] === 'stop' ? (
												<Text
													as="h3"
													fontSize="15px"
													fontFamily="OpenSansBold"
													color="text.error"
													textAlign="left"
												>
													Cannot place order from {item.extension_attributes.supplier_name}
												</Text>
											) : (
												''
											)}
										</Box>
										<Box as="section">
											{item.isMovMet === false ? (
												<>
													<Flex>
														<Box className="mov">
															<Text as="h1" fontSize={16} fontWeight="normal" color="black">
																MOV
															</Text>
														</Box>
														<Text fontSize={20} fontWeight="bold" color="black" mr={2}>
															${parseFloat(item?.value[0]?.extension_attributes?.mov)?.toFixed(2)}
														</Text>
													</Flex>

													<Text
														as="h2"
														fontSize="13px"
														fontFamily="OpenSansSemiBold"
														color="text.error01"
													>
														MOV not met
													</Text>
												</>
											) : (
												''
											)}
										</Box>
										<Box as="section">
											<Box className="orderTotal">
												<Text as="h1" fontSize={16} fontWeight="normal" color="black">
													Order Total
												</Text>
												<Text as="p" fontSize={20} fontWeight="bold" color="black">
													${getTotalSuppAmt(item.value)?.toFixed(2)}
												</Text>
											</Box>
										</Box>
										{/* <Box as="section" className="deliveryDate">
											<Text as="h1" fontSize={16} fontWeight="normal" color="black">
												{item && item?.value[0]?.extension_attributes?.delivery_cost ? (
													<Tooltip
														arrow
														animation="perspective"
														position="top"
														// trigger="click"
														html={
															<div className="tooltip-body tooltip-content">
																<span>
																	<Link
																href="https://www.google.com/"
																color="black"
																sx={{
																	fontSize: '14px',
																	textDecoration: 'underline',

																	':hover': {
																		color: '#7D376F',
																	},
																}}
															>
																	Delivery Cost Applicable
																	</Link>
																</span>
															</div>
														}
													>
														<Link
															target="_blank"
															href={
																getAppConfig &&
																	getAppConfig?.extension_attributes &&
																	getAppConfig.extension_attributes?.deliverycost
																	? getAppConfig.extension_attributes.deliverycost
																	: null
															}
															color="black"
															sx={{
																fontSize: '14px',
																textDecoration: 'underline',

																':hover': {
																	color: '#7D376F',
																},
															}}
														>
															<icons.moneyTruck />
														</Link>
													</Tooltip>
												) : null}
												Delivery Date:
											</Text>
											<SelectHolder>
												<Select
													id={item?.value[0]?.extension_attributes?.supplier_id}
													name={item?.value[0]?.extension_attributes?.supplier_id}
													onChange={(e) =>
														selectDeliverySlot(e, item?.value[0]?.extension_attributes?.supplier_id)
													}
													onClick={(event) =>
														getDropDownValues(
															item?.value[0]?.extension_attributes?.supplier_id,
															event
														)
													}
												>
													<option>Select</option>

													{
													deliverySlots && deliverySlots?.filter(x=>x.key === item?.value[0]?.extension_attributes?.supplier_id).length > 0
														? deliverySlots.filter(x=>x.key === item?.value[0]?.extension_attributes?.supplier_id)[0].value &&
															deliverySlots.filter(x=>x.key === item?.value[0]?.extension_attributes?.supplier_id)[0].value.map((option, index) => (
															<option value={option.date} key={index}>
																{option.day + ' ' + option.date}
															</option>
														))
														: []}
												</Select>
												<i className="icon-chevron-down"></i>
											</SelectHolder>
										</Box> */}

										<AccordionButton className="accordionButton">
											<icons.dropdownArrowIcon />
										</AccordionButton>
									</Flex>
									<AccordionPanel>
										{/* Cart Item Listing */}
										<CartListItems
											increaseCount={increaseCount}
											decreaseCount={decreaseCount}
											handleChange={handleChange}
											cartProducts={item.value}
											supplierName={item.key}
											suppIndex={suppIndex}
											groupBySupplierName={groupBySupplierName}
											validationData={validationData}
											confirmDelete={confirmDelete}
											disableStepper={disableStepper}
											creditStatus={creditStatus}
										/>
									</AccordionPanel>
								</AccordionItem>
							</ProductCartList>
						);
					})
				) : (!isLoading && !qry.isFetching) ? (
					<NodataFound>
						<Box>
							<Image
								src={nodataImage}
								sx={{
									width: 130,
									height: 130,
								}}
							/>
							<Text as="h1" fontFamily="OpenSansBold" fontSize={18} color="text.black">
								Cart is Empty
							</Text>
						</Box>
					</NodataFound>
				) : (
					''
				)}
			</Accordion>
			<SweetAlert
				show={showPopup}
				confirmBtnText="Confirm"
				onConfirm={(event) => removeItem(event)}
				showCancel={true}
				onCancel={closePopup}
				title={'Confirmation Box'}
			//  dependencies={[this.state.firstName, this.state.lastName]}
			>
				{() => <form>{'Remove item?'}</form>}
			</SweetAlert>
		</StyledProductCartListView>
	);
}

export { ProductCartListView };
