import { Select } from '@rebass/forms';
import React, { ReactElement, useState } from 'react';
import { Box, Button, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import * as Icon from 'ui/icons';
import { DropdownFilter } from 'ui/patterns';

const TagList = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 0;
	h1 {
		text-transform: uppercase;
		line-height: 17px;
	}
	button {
		width: 22px;
		height: 22px;
		border-radius: 6px;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 10px;
		cursor: pointer;
		outline: none !important;
		&:hover {
			background: ${({ theme }) => theme.colors.bg.yellow};
		}
	}
`;

const Tag = styled.div`
	border: 1px solid ${({ theme }) => theme.colors.border.grayMedium04};
	border-radius: 6px;
	display: flex;
	margin-right: 14px;
	align-items: center;
	margin-bottom: 5px;
	@media (max-width: 960px) {
		margin-right: 10px;
	}
	button {
		background: transparent !important;
		border: none;
		flex: 0 0 22px;
		max-width: 22px;
		margin: 2px;
		border-radius: 4px;
		height: 22px;
		svg {
			path {
				fill: ${({ theme }) => theme.colors.text.black};
			}
		}
		&:hover,
		&:focus {
			background: ${({ theme }) => theme.colors.bg.error}!important;
			svg {
				path {
					fill: ${({ theme }) => theme.baseColors.white};
				}
			}
		}
	}
	h2 {
		text-transform: uppercase;
		font-size: 14px;
		line-height: 18px;
		font-family: 'OpenSansSemiBold';
		padding: 6px 11px 6px 10px;
		margin: 0;
		color: ${({ theme }) => theme.colors.border.grayMedium04};
		white-space: nowrap;
	}
`;
const FilterDropdown = styled.div`
	border-radius: 6px;
	display: flex;
	margin-right: 14px;
	align-items: center;
	width: 102px;
	height: 32px;
	font-size: 14px;
	line-height: 18px;
	color: ${({ theme }) => theme.baseColors.white};
	background: ${({ theme }) => theme.baseColors.primary};
	padding: 0 0 0 8px;
	.pr-icon {
		margin-left: auto;
		margin-right: 10px;
		svg {
			path {
				fill: ${({ theme }) => theme.baseColors.white};
			}
		}
	}
`;
const StyledFilterByDropdown = styled.div`
	position: absolute;
	.dropdownMenu {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		button {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: none;
			background: transparent;
			outline: none;
			cursor: pointer;
			& + div {
				margin-top: 60px;
				border-radius: 12px;
				left: 0;
				right: auto;
				min-width: 230px;
				z-index: 1;
				padding: 15px;
				overflow: visible;
				box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.39);

				@media (max-width: 960px) {
					right: -10px;
				}
				button {
					position: relative;
					min-height: auto;
					padding: 10px 10px;
					margin: 0;
					&::before {
						left: 0;
						right: 0;
					}
					&:hover {
						background: ${({ theme }) => theme.baseColors.primary};
						color: ${({ theme }) => theme.colors.text.white};
						text-decoration: none;
					}
				}
				&::before {
					content: '';
					width: 47px;
					height: 16px;
					position: absolute;
					top: -16px;
					left: 60px;
					right: 14px;
					background: url(${require('../../images/dropdownTopArrow.png')});
				}
			}
		}
	}
	&.navbar-nav {
		@media (max-width: 960px) {
			display: none;
		}
	}
`;

const TagListContent = styled.div`
	display: flex;
	margin-left: 117px;
	flex-wrap: wrap;
`;
const SortBuy = styled.div`
	border: 1px solid rgba(189,188,185,1);
	box-sizing: border-box;
	border-radius: 6px;
	display: flex;
	align-items: center;
	min-width: 254px;
	min-height: 40px;
	@media (max-width: 860px) {
		min-width: 100%;
		flex-grow: 1;
		margin-top: 20px;
	}
	.pr-icon {
		margin: 0 8px 0 12px;
		svg {
			margin-right: 0;
		}
	}
	div {
		flex-grow: 1;
		Select {
			border: none;
			outline: none;
			box-shadow: none;
			font-size: 14px;
			line-height: 18px;
			color: ${({ theme }) => theme.baseColors.primary};
			margin-right:-34px;

		}
	}
	svg {
		margin-right: 10px;
		margin-left: 0;
		path {
			fill: ${({ theme }) => theme.baseColors.primary};
		}
	}
`;
const FilterSession = styled.div`
	width: 100%;
	float: left;
	display: flex;
	align-items: flex-end;
	margin-bottom: 20px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.border.grayLightBorder01};
	padding-bottom: 15px;
	justify-content: space-between;
	@media (max-width: 860px) {
		flex-direction: column;
		align-items: flex-start;
	}
`;
type FilterProps = {
	filterList: Array<list>;
};
type list = {
	id?: string | number;
	label?: string | number;
	active?: boolean;
};

function Filter({ filterList }: FilterProps): ReactElement {
	const categoriesOptions = [
		{
			id: 1,
			label: 'James & Co',
			active: true,
		},
		{
			id: 2,
			label: 'Grounded',
			active: true,
		},
		{
			id: 3,
			label: 'Grinded',
			active: false,
		},
	];
	const [showOptions, setShowOptions] = useState(false);
	const optionsTrigger = () => (
		<button className="btn-cardoptions" onClick={() => setShowOptions(!showOptions)}></button>
	);
	return (
		<FilterSession>
			<TagList>
				<StyledFilterByDropdown>
					<FilterDropdown>
						Filter By <Icon.downArrow />
					</FilterDropdown>
					<DropdownFilter
						trigger={optionsTrigger()}
						options={categoriesOptions}
						showOptions={showOptions}
						setShowOptions={setShowOptions}
						top="0"
						place="covered-right"
					/>
				</StyledFilterByDropdown>
				<TagListContent>
					{filterList
						? filterList.map((item) => (
								<Box key={item.id}>
									<Tag>
										<Text as="h2"> {item.label}</Text>
										<Button>
											<Icon.CloseIcon />
										</Button>
									</Tag>
								</Box>
						  ))
						: null}
				</TagListContent>
			</TagList>
			<SortBuy>
				<Icon.SortIcon />
				<Select
					id="country"
					name="country"
					defaultValue="United States"
					className="PageSearchCategory"
				>
					<option>Latest</option>
					<option>Low - High Price</option>
					<option>High - Low Price</option>
					<option>A - Z Order</option>
					<option>Z - A Order</option>
				</Select>
			</SortBuy>
		</FilterSession>
	);
}

export { Filter };
