import styled from 'styled-components';

const StyledFilter = styled.div`
	width: 100%;
`;
const FilterHeading = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 62px;
	padding: 30px 18px 16px 50px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.border.grayLightBorder01};
	@media (max-width: 960px) {
		padding: 0;
		border: none;
		justify-content: center;
		height: auto;
	}
	h1,
	a {
		font-size: 16px;
		line-height: 17px;
	}
	h1 {
		text-transform: uppercase;
		font-family: 'OpenSansBold';
		color: ${({ theme }) => theme.colors.text.black02};
		@media (max-width: 960px) {
			font-size: 20px;
			margin-bottom: 10px;
		}
	}
	a {
		font-family: 'OpenSansMedium';
		color: ${({ theme }) => theme.colors.text.black02};
		text-decoration: none;
		transition: ease-in all 200ms;
		@media (max-width: 960px) {
			display: none;
		}

		&:hover {
			background: ${({ theme }) => theme.baseColors.baseYellow};
			padding: 3px 10px;
			color: ${({ theme }) => theme.baseColors.white};
			border-radius: 5px;
		}
	}
`;

const FilterCategoryHeading = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	padding: 22px 0 21px;
	margin-bottom: 0 !important;
	h2 {
		font-size: 16px;
		line-height: 17px;
		text-transform: uppercase;
		font-family: 'OpenSansBold';
		color: ${({ theme }) => theme.colors.text.black02};
		position: absolute;
	}
	input {
		padding: 5px;
	}
	.search-form {
		display: inline-block;
		position: relative;
		width: 100%;
		margin: 0;
	}
	.search-field {
		height: auto;
		position: absolute;
		right: 0;
		-webkit-transition: all 0.5s ease-in-out;
		transition: all 0.5s ease-in-out;
		background: ${({ theme }) => theme.colors.bg.lightYellow};
		border: none;
		border-bottom: 1px solid ${({ theme }) => theme.colors.border.grayLightBorder01}!important;
		box-sizing: border-box;
		color: ${({ theme }) => theme.colors.text.black02};
		cursor: pointer;
		font-size: 16px;
		font-weight: 300;
		opacity: 0;
		padding-right: 50px;
		width: 0;
		z-index: 3;
		font-family: 'OpenSansBold';
		top: -9px;
		&:focus,
		&:active,
		&.active {
			cursor: text;
			opacity: 1;
			outline: none;
			width: 100%;
			z-index: 1;
			&:hover {
				~ {
					.search-submit {
						.fa-search {
							color: #ffffff;
						}
					}
				}
			}
		}
		&:hover {
			~ {
				.search-submit {
					.fa-search {
						color: white;
					}
				}
			}
		}
	}
	.search-submit {
		height: auto;
		-webkit-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
		background: transparent !important;
		border: none;
		float: right;
		position: relative;
		width: auto;
		z-index: 2;
		padding: 0 20px 0 0;
		cursor: pointer;
		outline: none !important;
		box-shadow: none !important;
		&:hover {
			.fa-search {
				color: white;
			}
		}
		svg {
			path {
				fill: ${({ theme }) => theme.colors.primary};
			}
		}
	}
	.fa-search {
		-webkit-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
		color: #ffffff;
		font-size: 2.25rem;
	}
`;
const FilterCategory = styled.div`
	width: calc(100% - 50px);
	border-bottom: 1px solid ${({ theme }) => theme.colors.border.grayLightBorder01};
	float: left;
	margin-left: 50px;
	padding-bottom: 6px;
	@media (max-width: 960px) {
		margin-left: 0;
		width: 100%;
	}
	h3 {
		margin-top: -2px;
	}
	div {
		width: 100%;
		margin-bottom: 14px;
		label {
			&::before {
				width: 21px !important;
				height: 21px !important;
				border: 1px solid ${({ theme }) => theme.colors.bg.lightYellow04};

				border-radius: 4px !important;
				margin-top: 2px;
			}
		}
	}
	a {
		font-family: 'OpenSansMedium';
		color: ${({ theme }) => theme.colors.text.black02};
		text-decoration: none;
		transition: ease-in all 200ms;
		font-family: 'OpenSansBold';
		margin-right: auto;
		&:hover {
			color: ${({ theme }) => theme.baseColors.baseYellow};
		}
	}
`;
const Price = styled.div`
	width: calc(100% - 50px);
	float: left;
	margin-left: 50px;
	padding-bottom: 6px;
	@media (max-width: 960px) {
		width: 100%;
		margin-left: 0;
	}
	h2 {
		position: relative;
	}
	div {
		width: 100%;
		margin-bottom: 14px;
		label {
			&::before {
				width: 21px !important;
				height: 21px !important;
				border: 1px solid ${({ theme }) => theme.colors.bg.lightYellow04};
				border-radius: 4px;
			}
		}
	}
	a {
		font-family: 'OpenSansMedium';
		color: ${({ theme }) => theme.colors.text.black02};
		text-decoration: none;
		transition: ease-in all 200ms;
		font-family: 'OpenSansBold';
		margin-right: auto;
		&:hover {
			color: ${({ theme }) => theme.baseColors.baseYellow};
		}
	}
`;
const PriceWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	div {
		width: auto;
		@media (max-width: 960px) {
			width: 100%;
		}
	}
`;
const PriceHolder = styled.div`
	display: flex;
	justify-content: center;
	p {
		display: flex;
		align-items: center;
		margin: 0 12px;
		flex: 0 0 16px;
	}
	div {
		display: flex;
		margin: 0;
		justify-content: center;
		align-items: center;
		flex-grow: 1;
		width: auto;
		position: relative;
		+ div {
			flex-grow: 0;
		}
		small {
			position: absolute;
			left: 7px;
			top: 6px;
		}

		input {
			border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
			height: 35px;
			border-radius: 6px;
			outline: none;
			background: ${({ theme }) => theme.colors.bg.white};
			padding-left: 20px;
			font-size: 14px;
			font-family: 'OpenSansMedium';
		}
	}
`;
const PriceBtnHolder = styled.div`
	width: 100%;
	padding: 0 20px 0 10px;
	button {
		margin: 0;
		height: 35px;
		padding: 0;
		min-width: 80px;
		font-size: 14px;
		line-height: 18px;
		text-transform: uppercase;
		cursor: pointer;
	}
	@media (max-width: 960px) {
		display: none;
	}
`;
const FilterCategoryHolder = styled.div`
	width: 100%;
	@media (max-width: 960px) {
		overflow-y: auto;
		max-height: calc(100vh - 250px);
	}
`;
const ButtonHolderMobileView = styled.div`
	display: none;
	border-top: 1px solid ${({ theme }) => theme.colors.border.grayLightBorder01};

	@media (max-width: 960px) {
		display: flex;
		padding: 15px 0 10px;
		justify-content: center;
		button {
			width: 100px;
			height: 48px;
			+ button {
				margin-left: 20px;
			}
		}
	}
`;

export {
	StyledFilter,
	FilterHeading,
	FilterCategory,
	FilterCategoryHeading,
	Price,
	PriceWrapper,
	PriceHolder,
	PriceBtnHolder,
	FilterCategoryHolder,
	ButtonHolderMobileView,
};
