import React from 'react';
import 'react-tippy/dist/tippy.css';
import { Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { StyledFormInput } from 'ui/components';
import { getHSL } from '../../../ui/utils/getHSL';

const StyledStepperHolder = styled.div`
	width: auto;
	p {
		position: relative;
		margin-top: 0 !important;
		text-align: center;
	}
`;
const StyledStepper = styled.div`
	display: flex;
	border-radius: 8px;
	background: ${({ theme }) => theme.baseColors.baseYellow};
	overflow: hidden;
	flex: 0 0 220px;
	max-width: 220px;
	height: 60px;
	@media (max-width: 1224px) {
		flex: 0 0 160px;
		max-width: 160px;
		height: 46px;
	}

	& > button {
		--h: ${({ theme, color }) => getHSL('h', color || theme.baseColors.baseYellow)};
		--s: ${({ theme, color }) => getHSL('s', color || theme.baseColors.baseYellow)};
		--l: ${({ theme, color }) => getHSL('l', color || theme.baseColors.baseYellow)};
		--a: 100%;
		appearance: none;
		background: none;
		border: none;
		font-size: 24px;
		font-weight: bold;
		border-radius: 0;
		cursor: pointer;
		width: 60px;
		flex: 0 0 60px;
		max-width: 60px;
		padding: 0 !important;
		margin: 0 !important;
		color: ${({ theme }) => theme.baseColors.white};
		@media (max-width: 1224px) {
			width: 48px;
			flex: 0 0 48px;
			max-width: 48px;
		}
		&:hover,
		&:focus {
			background: hsl(var(--h), var(--s), calc(var(--l) - 10%));
		}
	}

	& > button:first-child {
		margin-right: 0;
	}

	& > button:last-child {
		margin-left: 0;
	}

	& > input[type='number'] {
		text-align: center;
		min-width: 36px;
		border-radius: 0;
		border: none;
		flex: 0 0 100px;
		height: calc(100% - 2px);
		margin: 1px 0;
		color: ${({ theme }) => theme.baseColors.baseYellow};
		font-size: 20px;
		font-family: 'OpenSansSemiBold';
		@media (max-width: 1224px) {
			flex: 0 0 64px;
		}
	}
`;

function Stepper(props) {
	const validateEnteredText = (e) => {
		if (e.charCode === 45 || e.charCode === 43 || e.charCode === 46) {
			e.preventDefault();
			return false;
		} else {
			return true;
		}
	};
	return (
		<StyledStepperHolder>
			<StyledStepper
				onClick={(e) => {
					e.stopPropagation();
				}}
			>
				<button
					disabled={
						!props.active ||
						props.isStepperActive ||
						!props.isCartUpdated ||
						props?.creditStatus[props?.index] === 'stop'
					}
					onClick={props?.decreaseCount}
				>
					{' '}
					-{' '}
				</button>
				<StyledFormInput
					type="number"
					onKeyPress={validateEnteredText}
					disabled={!props?.active || props?.creditStatus[props?.index] === 'stop'}
					onChange={props?.handleChange}
					onClick={(event) => {
						if (props.index !== null) {
							props?.stopEvent(event);
						}
					}}
					value={props.index !== null ? props.stepperCount[props?.index] : props.stepperCount}
					px="0.5em"
					py="0.5em"
				/>
				<button
					disabled={
						!props.active ||
						props.isStepperActive ||
						!props.isCartUpdated ||
						props?.creditStatus[props?.index] === 'stop'
					}
					onClick={props?.increaseCount}
				>
					+
				</button>
			</StyledStepper>
			{props.index !== null ? (
				<Text as="p" fontSize="15px" fontFamily="OpenSansRegular" color="text.error">
					{props.validationData[props?.index]}
				</Text>
			) : (
				<Text as="p" fontSize="15px" fontFamily="OpenSansRegular" color="text.error">
					{props.validationData}
				</Text>
			)}
		</StyledStepperHolder>
	);
}

export { Stepper };
