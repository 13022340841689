import React, { ReactElement } from 'react';
import { Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { flex, FlexProps, layout, LayoutProps, space, SpaceProps } from 'styled-system';

type ProductDescriptionWrapProps = FlexProps & SpaceProps & LayoutProps;

const ProductDescriptionWrap = styled.div<ProductDescriptionWrapProps>`
	${flex}
	${space}
	${layout}
	display: flex;
	float: left;
	width: 100%;
	margin-top: 38px;
	flex-direction: column;
	@media (max-width: 860px) {
		margin-top: 0;
	}
	h1 {
		text-transform: uppercase;
	}
	p {
		margin-top: 15px;
		div{
			word-break: break-word;
		}
	}
	@media (max-width: 860px) {
		h1,
		p {
			font-size: 17px;
		}
	}
`;

type styledsystemProps = FlexProps & SpaceProps & LayoutProps;

type ProductDescriptionProps = styledsystemProps & {
	description?: string;
	productDetails?: any;
	type?: string;
};

function ProductDescription({
	...styledsystemProps
}: ProductDescriptionProps): ReactElement {
	const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
	var descriptionValue: string = '';
	if (styledsystemProps.description != undefined && styledsystemProps.description != '') {
		descriptionValue = styledsystemProps.description;
	}
	if (styledsystemProps.productDetails !== undefined) {
		styledsystemProps.productDetails.custom_attributes?.forEach((data) => {
			if (data.attribute_code === "description") {
				descriptionValue = data.value;
			}
		})
	}


	return (
		<ProductDescriptionWrap {...styledsystemProps}>
			{
				descriptionValue && descriptionValue != "" && descriptionValue != null && descriptionValue != undefined && (
				<Text
					as="h1"
					fontFamily="OpenSansBold"
					fontSize={18}
					lineHeight="23px"
					color="text.black"
					className="truncate"
				>
					Description
				</Text>
				)
			}
			
			<Text
				as="p"
				fontFamily="OpenSansRegular"
				fontSize={18}
				lineHeight="23px"
				color="text.black03"
			>
				{renderHTML(descriptionValue)}
			</Text>
		</ProductDescriptionWrap>
	);
}

export { ProductDescription };
