import { Input, Label } from '@rebass/forms';
import React, { ReactElement, useRef } from 'react';
import { Box, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import icons from 'ui/icons';

const StyledFilter = styled.div`
	width: 100%;
	display: flex;
	position: relative;
	padding: 32px 0 5px 0;
	align-items: center;
	justify-content: center;
	@media (max-width: 768px) {
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	h1 {
		display: flex;
		align-items: center;
		padding-right: 25px;
		max-width: 80px;
		flex: 0 0 80px;
		span {
			margin-right: 8px;
		}
		svg {
			height: 13px;
			width: 13px;
		}
	}
	label {
		display: inline-block;
		font-size: 14px;
		line-height: 18px;
		text-align: center;
		text-decoration: none;
		vertical-align: middle;
		cursor: pointer;
		user-select: none;
		background-color: transparent;
		padding: 8px 15px;
		border-radius: 6px;
		position: relative;
		overflow: hidden;
		white-space: nowrap;
		width: auto;
		margin: 0 5px;
		font-family: 'OpenSansMedium';

		transition: color 0.5s ease-in-out, background-color 0.5s ease-in-out,
			border-color 0.5s ease-in-out, box-shadow 0.5s ease-in-out,
			-webkit-box-shadow 0.5s ease-in-out;
		&:after {
			content: '';
			display: block;
			position: absolute;
			left: 50%;
			top: 50%;
			width: 120px;
			height: 120px;
			margin-left: -60px;
			margin-top: -60px;
			background-color: ${({ theme }) => theme.baseColors.primary};
			border-radius: 100%;
			opacity: 0.6;

			transform: scale(0);
		}
		&:not(:active):after {
			animation: ripple 1s ease-out;
		}
		@keyframes ripple {
			0% {
				transform: scale(0);
			}
			20% {
				transform: scale(1);
			}
			100% {
				opacity: 0;
				transform: scale(1);
			}
		}
	}
	sub {
		width: auto;
		display: flex;
		flex-wrap: wrap;
		label {
			margin-bottom: 10px;
		}
		@media (max-width: 768px) {
			align-items: center;
			justify-content: center;
		}
	}

	@media (prefers-reduced-motion: reduce) {
		.btn {
			-webkit-transition: none;
			transition: none;
		}
	}

	label {
		color: ${({ theme }) => theme.colors.text.black04};
		border: 1px solid ${({ theme }) => theme.colors.text.black};
	}
	.clear {
		color: ${({ theme }) => theme.newColors.primary02};
		border: 1px solid ${({ theme }) => theme.newColors.primary02};
	}
	.btn-check:checked + label {
		background-color: ${({ theme }) => theme.baseColors.primary};
		border-color: ${({ theme }) => theme.baseColors.primary};
		color: ${({ theme }) => theme.colors.text.white};
	}
	.btn-check:hover + label {
		background-color: ${({ theme }) => theme.baseColors.primary};
		border-color: ${({ theme }) => theme.baseColors.primary};
		color: ${({ theme }) => theme.colors.text.white};
	}

	.btn-check.clear:checked + label {
		background-color: ${({ theme }) => theme.newColors.primary02};
		border-color: ${({ theme }) => theme.baseColors.primary};
		color: ${({ theme }) => theme.colors.text.white};
	}
	.btn-check.clear:hover + label {
		background-color: ${({ theme }) => theme.newColors.primary02};
		border-color: ${({ theme }) => theme.newColors.primary02};
		color: ${({ theme }) => theme.colors.text.white};
	}

	.btn-check:checked + label:focus {
		-webkit-box-shadow: 0 0 0 0.25rem rgba(125, 55, 111, 0.5);
		box-shadow: 0 0 0 0.25rem rgba(125, 55, 111, 0.5);
	}
	.btn-check {
		position: absolute;
		clip: rect(0, 0, 0, 0);
		pointer-events: none;
		overflow: hidden;
		transform: translate3d(0, 0, 0);
		width: auto;
	}

	.btn-check:checked + label:focus {
		-webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.1);
		box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.1);
	}
`;
function FilterTableList(prop): ReactElement {
	const pendingRef = useRef<HTMLInputElement>(null);
	const CancelledRef = useRef<HTMLInputElement>(null);
	const confirmedRef = useRef<HTMLInputElement>(null);
	const rejectedRef = useRef<HTMLInputElement>(null);
	const disputedRef = useRef<HTMLInputElement>(null);
	const deliveredRef = useRef<HTMLInputElement>(null);
	const shippedRef = useRef<HTMLInputElement>(null);
	const clearRef = useRef<HTMLInputElement>(null);

	const clearFilter = (e) => {
		e.preventDefault();
		if(pendingRef.current?.checked) {
			pendingRef.current.click();
		}
		if(CancelledRef.current?.checked) {
			CancelledRef.current.click();
		}
		if(confirmedRef.current?.checked) {
			confirmedRef.current.click();
		}
		if(rejectedRef.current?.checked) {
			rejectedRef.current.click();
		}
		if(disputedRef.current?.checked) {
			disputedRef.current.click();
		}
		if(deliveredRef.current?.checked) {
			deliveredRef.current.click();
		}
		if(shippedRef.current?.checked) {
			shippedRef.current.click();
		}
	}

	return (
		<StyledFilter>
			<Text as="h1" fontSize={14} fontFamily="OpenSansSemiBold" color="text.black">
				<icons.FilterIcon />
				Filter
			</Text>
			<Box as="sub">
				<Input
					type="checkbox"
					className="btn-check"
					id="Processing"
					autoComplete="off"
					onChange={(e) => {
						prop.onChangeFilter && prop.onChangeFilter('pending', e);
					}}
					ref={pendingRef}
				/>
				<Label htmlFor="Processing">Pending</Label>

				<Input
					type="checkbox"
					className="btn-check"
					id="OutForDelivery"
					autoComplete="off"
					onChange={(e) => {
						prop.onChangeFilter && prop.onChangeFilter('canceled', e);
					}}
					ref={CancelledRef}
				/>
				<Label htmlFor="OutForDelivery">Cancelled</Label>

				<Input
					type="checkbox"
					className="btn-check"
					id="Confirmed"
					autoComplete="off"
					onChange={(e) => {
						prop.onChangeFilter && prop.onChangeFilter('confirmed', e);
					}}
					ref={confirmedRef}
				/>
				<Label htmlFor="Confirmed">Confirmed</Label>

				<Input
					type="checkbox"
					className="btn-check"
					id="Rejected"
					autoComplete="off"
					onChange={(e) => {
						prop.onChangeFilter && prop.onChangeFilter('order_rejected', e);
					}}
					ref={rejectedRef}
				/>
				<Label htmlFor="Rejected">Rejected</Label>

				<Input
					type="checkbox"
					className="btn-check"
					id="Disputed"
					autoComplete="off"
					onChange={(e) => {
						prop.onChangeFilter && prop.onChangeFilter('disputed', e);
					}}
					ref={disputedRef}
				/>
				<Label htmlFor="Disputed">Disputed</Label>

				<Input
					type="checkbox"
					className="btn-check"
					id="Delivered"
					autoComplete="off"
					onChange={(e) => {
						prop.onChangeFilter && prop.onChangeFilter('delivered', e);
					}}
					ref={deliveredRef}
				/>
				<Label htmlFor="Delivered">Delivered</Label>

				<Input
					type="checkbox"
					className="btn-check"
					id="shipped"
					autoComplete="off"
					onChange={(e) => {
						prop.onChangeFilter && prop.onChangeFilter('shipped', e);
					}}
					ref={shippedRef}
				/>
				<Label htmlFor="shipped">Shipped</Label>
				
				<Input
					type="checkbox"
					className="btn-check clear"
					id="clear"
					autoComplete="off"
					onClick={(e) => {
						clearFilter(e);
					}}
					ref={clearRef}
				/>
				<Label className='clear' htmlFor="clear">Clear filter</Label>

				{/* <Input
					type="checkbox"
					className="btn-check"
					id="approved"
					autoComplete="off"
					onChange={(e) => {
						prop.onChangeFilter && prop.onChangeFilter('approved', e);
					}}
				/>
				<Label htmlFor="approved">Approved</Label> */}
			</Box>
		</StyledFilter>
	);
}

export { FilterTableList };
