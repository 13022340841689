import { darken } from 'polished';
import React, { MouseEventHandler, ReactElement, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { flexbox, FlexboxProps, layout, LayoutProps, space, SpaceProps } from 'styled-system';
import { getHSL } from '../../utils/getHSL';

type StyledButtonProps = SpaceProps &
	LayoutProps &
	FlexboxProps & {
		variant?:
			| 'primary'
			| 'secondary'
			| 'deleteIcon'
			| 'editIcon'
			| 'redOutline'
			| 'success'
			| 'warning'
			| 'danger'
			| 'subtle'
			| 'ghost'
			| 'default'
			| 'fbBtn'
			| 'googleBtn'
			| 'modalClose'
			| 'textEdit'
			| 'logOut'
			| 'hamburgerMenu'
			| 'editBtn'
			| 'closeBtn'
			| 'tickBtn'
			| 'cartButton'
			| undefined;
		size?: 'lg' | 'sm';
		style?: React.CSSProperties;
		disabled?: boolean;
		fullWidth?: boolean;
		halfWidth?: boolean;
		loginBtn?: boolean;
		payBtn?: boolean;
		paidBtn?: boolean;
		loginBtnProgress?: boolean;
		shopViewBtn?: boolean;
		active?: boolean;
		userRewardMore?: boolean;
		collapseArrow?: boolean;
		rounded?: boolean;
		isActive?: boolean;
		iconBefore?: any;
		iconAfter?: any;
		changepassword?: boolean;
		backbtn?: boolean;
		cartButton?: boolean;
		color?: string;
		px?: string;
		py?: string;
	};
export const StyledButton = styled.button<StyledButtonProps>`
	${layout}
	${space}
	${flexbox}

  --px: ${({ px }) => px || '1.5em'};
	--py: ${({ py }) => py || '1em'};

	--h: ${({ theme, color }) => getHSL('h', color || theme.baseColors.baseYellow)};
	--s: ${({ theme, color }) => getHSL('s', color || theme.baseColors.baseYellow)};
	--l: ${({ theme, color }) => getHSL('l', color || theme.baseColors.baseYellow)};
	--a: 100%;

	--switch: calc((var(--l) - var(--contrast-threshold)) * -100);

	--color: #fff;
	--whiteColor: ${({ theme }) => theme.colors.text.white};
	--baseYellow: ${({ theme }) => theme.baseColors.baseYellow};

	display: inline-flex;
	align-items: center;
	justify-content: center;
	appearance: none;
	padding: var(--py) var(--px);
	font-size: 1rem;
	line-height: 1;
	text-align: center;
	color: #fff;
	background-image: linear-gradient(to right, #64a2f5, #3e68b9);
	border: none;
	border-radius: ${({ theme }) => theme.radii.softer};
	cursor: pointer;
	width: auto;
	transition: ease-in all 200ms;
	text-transform: uppercase;
	font-family: 'OpenSansMedium' !important;
	transition: background 0.8s;
	font-size: 15px !important;
	font-weight: 600 !important;

	&:hover,
	&:active {
		background-image: linear-gradient(to right, #3e68b9, #64a2f5);
	}

	&:focus {
		outline: none;
		background-image: linear-gradient(to right, #3e68b9, #64a2f5);
		box-shadow: 0 0 0 0.2rem hsla(var(--h), var(--s), var(--l), 30%);
	}

	${({ disabled }) =>
		disabled &&
		css`
			opacity: 0.4;
			cursor: not-allowed;

			&:hover {
				background: hsl(var(--h), var(--s), var(--l));
			}
		`}

	${({ isActive }) =>
		isActive &&
		css`
			background: hsl(var(--h), var(--s), calc(var(--l) - 15%));
		`}

    ${({ fullWidth }) =>
		fullWidth &&
		css`
			width: 100%;
			max-width: 100%;
		`}
		    ${({ halfWidth }) =>
		halfWidth &&
		css`
			width: 50%;
			max-width: 50%;
		`}

	${({ loginBtn }) =>
		loginBtn &&
		css`
			height: 65px;
			border-radius: 10.96px;
			font-size: 20px;
			color: var(--whiteColor);
			text-transform: uppercase;
			background-position: center;
			transition: background 0.8s;

			&:hover {
				background: hsl(var(--h), var(--s), calc(var(--l) - 10%));
				border: 1px solid hsl(var(--h), var(--s), calc(var(--l) - 10%));
				background: hsl(var(--h), var(--s), calc(var(--l) - 10%))
					radial-gradient(circle, transparent 1%, hsl(var(--h), var(--s), calc(var(--l) - 10%)) 1%)
					center/15000%;
			}
			&:active {
				background-color: hsl(var(--h), var(--s), calc(var(--l) - 15%));
				background-size: 100%;
				transition: background 0s;
			}
			@media screen and (min-width: 40em) {
				height: 73px;
				font-size: 24px;
			}
		`}
		${({ payBtn }) =>
		payBtn &&
		css`
			--color: ${({ theme }) => theme.colors.text.white};
			--h: ${({ theme }) => getHSL('h', theme.colors.bg.yellow)};
			--s: ${({ theme }) => getHSL('s', theme.colors.bg.yellow)};
			--l: ${({ theme }) => getHSL('l', theme.colors.bg.yellow)};
			--a: 100%;
			height: 35px;
			border-radius: 6px;
			font-size: 15px;
			color: var(--whiteColor);
			background-position: center;
			transition: background 0.8s;
			font-family: 'OpenSansBold';
			text-transform: capitalize;
			border: 1px solid hsl(var(--h), var(--s), calc(var(--l) - 0%));
			&:hover {
				background: hsl(var(--h), var(--s), calc(var(--l) - 10%));
				border: 1px solid hsl(var(--h), var(--s), calc(var(--l) - 10%));
				background: hsl(var(--h), var(--s), calc(var(--l) - 10%))
					radial-gradient(circle, transparent 1%, hsl(var(--h), var(--s), calc(var(--l) - 10%)) 1%)
					center/15000%;
			}
			&:active {
				background-color: hsl(var(--h), var(--s), calc(var(--l) - 15%));
				background-size: 100%;
				transition: background 0s;
			}
		`}
		${({ paidBtn }) =>
		paidBtn &&
		css`
			--h: ${({ theme }) => getHSL('h', theme.colors.bg.paidBtn)};
			--s: ${({ theme }) => getHSL('s', theme.colors.bg.paidBtn)};
			--l: ${({ theme }) => getHSL('l', theme.colors.bg.paidBtn)};
			--a: 33%;
			height: 35px;
			border-radius: 6px;
			font-size: 15px;
			color: rgba(36, 43, 36, 0.15);
			background-position: center;
			transition: background 0.8s;
			font-family: 'OpenSansBold';
			text-transform: capitalize;
			border: none;
			pointer-events: none;
			&:hover {
				background: hsl(var(--h), var(--s), calc(var(--l) - 10%));
				background: hsl(var(--h), var(--s), calc(var(--l) - 10%))
					radial-gradient(circle, transparent 1%, hsl(var(--h), var(--s), calc(var(--l) - 10%)) 1%)
					center/15000%;
			}
			&:active {
				background-color: hsl(var(--h), var(--s), calc(var(--l) - 15%));
				background-size: 100%;
				transition: background 0s;
			}
		`}
		${({ loginBtnProgress }) =>
		loginBtnProgress &&
		css`
			position: relative;
			overflow: hidden;
			pointer-events: none;

			.progress-value {
				width: 100%;
				height: 100%;
				opacity: 0.7;
				position: absolute;
				top: 0;
				left: 0;
				-webkit-animation: glowing 1300ms infinite;
				-moz-animation: glowing 1300ms infinite;
				-o-animation: glowing 1300ms infinite;
				animation: glowing 1300ms infinite;
				@-webkit-keyframes glowing {
					0% {
						background: hsl(var(--h), var(--s), calc(var(--l) - 10%));
					}
					50% {
						background-color: ${({ theme }) => theme.colors.bg.userInfoHeader};
					}
					100% {
						background: hsl(var(--h), var(--s), calc(var(--l) - 10%));
					}
				}
				@keyframes glowing {
					0% {
						background: hsl(var(--h), var(--s), calc(var(--l) - 10%));
					}
					50% {
						background-color: ${({ theme }) => theme.colors.bg.userInfoHeader};
					}
					100% {
						background: hsl(var(--h), var(--s), calc(var(--l) - 10%));
					}
				}
			}
		`}

		${({ shopViewBtn }) =>
		shopViewBtn &&
		css`
			background: transparent;
			width: 40px;
			height: 40px;
			flex: 0 0 40px;
			margin-left: 16px;
			border-radius: 6px;
			max-width: 40px;
			padding: 0;
			border: 1px solid ${({ theme }) => theme.baseColors.baseYellow};
			svg {
				path {
					fill: ${({ theme }) => theme.colors.primary};
				}
				rect {
					fill: ${({ theme }) => theme.colors.primary};
				}
			}
			&:hover,
			&:active,
			&:focus {
				background: ${({ theme }) => theme.baseColors.primary};
				svg {
					path {
						fill: ${({ theme }) => theme.colors.bg.white};
					}
					rect {
						fill: ${({ theme }) => theme.colors.bg.white};
					}
				}
			}
		`}
		${({ active }) =>
		active &&
		css`
			background: ${({ theme }) => theme.baseColors.baseYellow};

			svg {
				path {
					fill: ${({ theme }) => theme.colors.bg.white};
				}
				rect {
					fill: ${({ theme }) => theme.colors.bg.white};
				}
			}
		`}
    ${({ rounded }) =>
		rounded &&
		css`
			border-radius: 50px;
		`}

  ${({ variant }) =>
		variant === 'primary' &&
		css`
			--color: hsl(0, 0%, var(--switch));
			--h: var(--primary-h);
			--s: var(--primary-s);
			--l: var(--primary-l);
			svg {
				height: 20px;
				path {
					fill: ${({ theme }) => theme.colors.text.white}!important;
				}
			}
		`}

  ${({ variant }) =>
		variant === 'secondary' &&
		css`
			color: ${({ theme }) => theme.baseColors.primary};
			border: 0.5px solid ${({ theme }) => theme.newColors.gray};
			background: ${({ theme }) => theme.newColors.white};
			font-family: 'OpenSansBold';
			svg {
				path {
					fill: ${({ theme }) => theme.baseColors.primary};
				}
			}
			&:hover,
			&:focus,
			&:active {
				background-image: linear-gradient(to right, #3e68b9, #64a2f5) !important;;
				color: ${({ theme }) => theme.colors.text.white};
				svg {
					path {
						fill: ${({ theme }) => theme.colors.text.white};
					}
				}
			}
		`}
		${({ variant }) =>
		variant === 'redOutline' &&
		css`
			color: ${({ theme }) => theme.colors.text.error01};
			border-color: ${({ theme }) => theme.colors.text.error01};
			background: ${({ theme }) => theme.colors.bg.white};
			text-transform: capitalize;

			span {
				display: flex;
			}
			svg {
				width: 15px;
				height: 16px;
				margin-right: 12px;
				margin-top: -2px;
				path {
					fill: ${({ theme }) => theme.colors.text.error01}!important;
				}
			}
			&:hover,
			&:focus,
			&:active {
				background: ${({ theme }) => theme.colors.text.error01};
				color: ${({ theme }) => theme.colors.text.white}!important;
				svg {
					path {
						fill: ${({ theme }) => theme.colors.text.white}!important;
					}
				}
			}
		`}
		${({ variant }) =>
		variant === 'deleteIcon' &&
		css`
			background: transparent;
			border: 1px solid ${({ theme }) => theme.newColors.primary02}!important;
			display: flex;
			align-items: center;
			justify-content: center;
			svg {
				path {
					fill: ${({ theme }) => theme.newColors.primary02}!important;
				}
			}
			&:hover {
				background: ${({ theme }) => theme.newColors.primary02}!important;
				svg {
					path {
						fill: ${({ theme }) => theme.colors.text.white}!important;
					}
				}
			}
		`}

		${({ variant }) =>
		variant === 'editIcon' &&
		css`
			background: hsl(var(--h), var(--s), calc(var(--l) - 10%));
			display: flex;
			align-items: center;
			justify-content: center;
			svg {
				width: 16px;
				height: 16px;
				path {
					fill: ${({ theme }) => theme.colors.white}!important;
				}
			}
			&:hover {
				background: ${({ theme }) => theme.newColors.white}!important;
				border: 1px solid ${({ theme }) => theme.newColors.primary01};
				svg {
					path {
						fill: ${({ theme }) => theme.newColors.primary01}!important;
					}
				}
			}
		`}


${({ variant }) =>
		variant === 'subtle' &&
		css`
			--color: hsl(0, 0%, var(--switch));
			--a: 0%;
		`}

  ${({ variant }) =>
		variant === 'success' &&
		css`
			--color: hsl(0, 0%, var(--switch));
			--h: ${({ theme }) => getHSL('h', theme.colors.state.success)};
			--s: ${({ theme }) => getHSL('s', theme.colors.state.success)};
			--l: ${({ theme }) => getHSL('l', theme.colors.state.success)};
		`}

  ${({ variant }) =>
		variant === 'danger' &&
		css`
			--color: ${({ theme }) => theme.colors.state.default};
			--h: ${({ theme }) => getHSL('h', theme.colors.state.error)};
			--s: ${({ theme }) => getHSL('s', theme.colors.state.error)};
			--l: ${({ theme }) => getHSL('l', theme.colors.state.error)};
		`}
     ${({ variant }) =>
		variant === 'default' &&
		css`
			--color: hsl(0, 0%, var(--switch));
			--h: ${({ theme }) => getHSL('h', theme.colors.state.default)};
			--s: ${({ theme }) => getHSL('s', theme.colors.state.default)};
			--l: ${({ theme }) => getHSL('l', theme.colors.state.default)};
		`}

  ${({ variant }) =>
		variant === 'warning' &&
		css`
			--color: hsl(0, 0%, var(--switch));
			--h: ${({ theme }) => getHSL('h', theme.colors.state.warning)};
			--s: ${({ theme }) => getHSL('s', theme.colors.state.warning)};
			--l: ${({ theme }) => getHSL('l', theme.colors.state.warning)};
		`}

    ${({ variant }) =>
		variant === 'ghost' &&
		css`
			color: ${({ theme }) => theme.colors.primary};
			--h: 0;
			--s: 0%;
			--l: 100%;
			border: 1px solid #000;
			background: ${({ theme }) => theme.colors.text.white}!important;
			&:hover {
				background-image: linear-gradient(to right, #3e68b9, #64a2f5) !important;
				svg {
					path {
						fill: ${({ theme }) => theme.colors.text.white}!important;
					}
				}
			}
			&:focus {
				outline: none;
				background-image: linear-gradient(to right, #3e68b9, #64a2f5) !important;
				box-shadow: 0 0 0 0.2rem hsla(var(--h), var(--s), var(--l), 0%);
			}
		`}
    ${({ variant }) =>
		variant === 'fbBtn' &&
		css`
			--color: ${({ theme }) => theme.colors.text.white};
			--h: ${({ theme }) => getHSL('h', theme.colors.state.fbButton)};
			--s: ${({ theme }) => getHSL('s', theme.colors.state.fbButton)};
			--l: ${({ theme }) => getHSL('l', theme.colors.state.fbButton)};
			--a: 100%;
			justify-content: flex-end;
			height 74px;
			font-size: 2rem;
			padding:0;
			padding-left:  var(--pl);
			position: relative;
			.pr-icon {
				margin-left:10px;
			}
			.pr-button-label{
				width:100%;
				text-align:left;
				padding-left:15px;
				font-size:20px;
			}
			@media (max-width: 640px) {
				height:50px;
				padding:7px;
				.pr-icon {
					margin-left:0;
					svg{
						width:36px!important;
						height:36px!important;
					}
				}
				.pr-button-label{
					padding-left:5px;
					font-size:14px;
				}
			}
			&:hover {
				background: hsl(var(--h), var(--s), calc(var(--l) - -10%));
				.pr-icon {
					-webkit-animation: spin 0.5s linear;
					-moz-animation: spin 0.5s linear;
					animation: spin 0.5s linear;
				}
				@-moz-keyframes spin {
					100% {
						-moz-transform: rotate(360deg);
					}
				}
				@-webkit-keyframes spin {
					100% {
						-webkit-transform: rotate(360deg);
					}
				}
				@keyframes spin {
					100% {
						-webkit-transform: rotate(360deg);
						transform: rotate(360deg);
					}
				}
			}
		`}

	${({ variant }) =>
		variant === 'googleBtn' &&
		css`
			--color: ${({ theme }) => theme.colors.text.white};
			--h: ${({ theme }) => getHSL('h', theme.colors.state.googleButton)};
			--s: ${({ theme }) => getHSL('s', theme.colors.state.googleButton)};
			--l: ${({ theme }) => getHSL('l', theme.colors.state.googleButton)};
			--a: 100%;
			justify-content: flex-end;
			height 72px;
			font-size: 2rem;

			padding: 0;
			padding-left:  var(--pl);

			position: relative;
				.pr-icon {
				margin-left:10px;
			}
			.pr-button-label{
				width:100%;
				text-align:left;
				padding-left:15px;
			}
			@media (max-width: 640px) {
				height:50px;
				padding:7px;
				.pr-icon {
					margin-left:0;
					svg{
						width:36px!important;
						height:36px!important;
					}
				}
				.pr-button-label{
					padding-left:5px;
					font-size:14px;
				}
			}
			&:hover {
				background: hsl(var(--h), var(--s), calc(var(--l) - -10%));
				.pr-icon {
					-webkit-animation: spin 0.5s linear;
					-moz-animation: spin 0.5s linear;
					animation: spin 0.5s linear;
				}
				@-moz-keyframes spin {
					100% {
						-moz-transform: rotate(360deg);
					}
				}
				@-webkit-keyframes spin {
					100% {
						-webkit-transform: rotate(360deg);
					}
				}
				@keyframes spin {
					100% {
						-webkit-transform: rotate(360deg);
						transform: rotate(360deg);
					}
				}
			}
		`}
		${({ variant }) =>
		variant === 'modalClose' &&
		css`
			position: absolute;
			top: 20px;
			right: 20px;
			padding: 5px;
			border-radius: 5px;
			background: transparent;
			border: none;
			svg {
				height: 13px !important;
				width: 13px !important;
			}
			&:hover {
				--h: ${({ theme }) => getHSL('h', theme.colors.bg.error)};
				--s: ${({ theme }) => getHSL('s', theme.colors.bg.error)};
				--l: ${({ theme }) => getHSL('l', theme.colors.bg.error)};
				--a: 100%;
				svg {
					path {
						fill: var(--whiteColor);
					}
				}
			}
		`}
		${({ variant }) =>
		variant === 'textEdit' &&
		css`
			top: 20px;
			padding: 5px;
			border-radius: 5px;
			border: 1px solid ${({ theme }) => theme.colors.bg.lightYellow};
			background: transparent;
			svg {
				height: 14px !important;
				width: 14px !important;
			}
			&:hover {
				--h: var(--primary-h);
				--s: var(--primary-s);
				--l: var(--primary-l);
				svg {
					path {
						fill: var(--whiteColor);
					}
				}
			}
		`}
		${({ variant }) =>
		variant === 'logOut' &&
		css`
			color: transparent !important;
			padding: 5px 10px;
			border-radius: 5px;
			min-height: auto;
			background: transparent;
			border: 1px solid transparent;
			.pr-button-label {
				color: ${({ theme }) => theme.baseColors.baseYellow};
				margin-left: 15px;
				font-family: 'OpenSansMedium';
				@media screen and (max-width: 768px) {
					display: none;
				}
			}
			svg {
				height: 23px !important;
				width: 19px !important;
			}
			&:hover {
				--h: var(--baseYellow);
				--s: var(--baseYellow);
				--l: var(--baseYellow);
				background: ${({ theme }) => theme.baseColors.baseYellow};
				border: 1px solid ${({ theme }) => theme.baseColors.baseYellow};
				svg {
					path {
						stroke: var(--whiteColor);
					}
				}
				.pr-button-label {
					color: var(--whiteColor);
				}
			}
			@media screen and (max-width: 768px) {
				position: absolute;
				right: 0;
				svg {
					height: 32px !important;
					width: 25px !important;
				}
			}
		`}
	
		${({ variant }) =>
		variant === 'cartButton' &&
		css`
			border-color: transparent !important;
			background: transparent !important;
			position: relative;
			padding: 0;
			.badge-count {
				position: absolute;
				top: -13px;
				left: 13px;
			}
		`}



		${({ variant }) =>
		variant === 'hamburgerMenu' &&
		css`
			color: transparent !important;
			padding: 5px 10px;
			border-radius: 5px;
			min-height: auto;
			margin-right: 10px;
			background: transparent;
			border: 1px solid transparent;
			.pr-button-label {
				color: ${({ theme }) => theme.baseColors.baseYellow};
				margin-left: 15px;
				font-family: 'OpenSansMediumMedium';
			}
			svg {
				height: 23px !important;
				width: 19px !important;
				path {
						fill: ${({ theme }) => theme.baseColors.baseYellow}!important;
					}
			}
			&:hover {
				background: ${({ theme }) => theme.baseColors.baseYellow}!important;
				svg {
					path {
						stroke: ${({ theme }) => theme.baseColors.white};
						fill: ${({ theme }) => theme.baseColors.white};
					}
				}
				.pr-button-label {
					color: ${({ theme }) => theme.baseColors.white};
				}
			}
		`}

		${({ variant }) =>
		variant === 'editBtn' &&
		css`
			width: 48px;
			height: 48px;
			background: transparent;
			border: 1px solid ${({ theme }) => theme.baseColors.baseYellow};

			svg {
				height: 23px !important;
				width: 19px !important;
				path {
					fill: ${({ theme }) => theme.baseColors.baseYellow};
				}
			}
			&:hover,
			&:focus,
			&:active {
				background: ${({ theme }) => theme.baseColors.baseYellow};
				border: 1px solid ${({ theme }) => theme.baseColors.baseYellow};
				svg {
					path {
						stroke: var(--whiteColor);
						fill: var(--whiteColor);
					}
				}
				.pr-button-label {
					color: var(--whiteColor);
				}
			}
		`}
		${({ variant }) =>
		variant === 'tickBtn' &&
		css`
			width: 48px;
			height: 48px;
			background: transparent;
			border: 1px solid ${({ theme }) => theme.baseColors.baseYellow};

			svg {
				height: 23px !important;
				width: 19px !important;
				path {
					fill: ${({ theme }) => theme.baseColors.baseYellow};
				}
			}
			&:hover,
			&:focus,
			&:active {
				background: ${({ theme }) => theme.baseColors.baseYellow};
				border: 1px solid ${({ theme }) => theme.baseColors.baseYellow};
				svg {
					path {
						stroke: var(--whiteColor);
						fill: var(--whiteColor);
					}
				}
				.pr-button-label {
					color: var(--whiteColor);
				}
			}
		`}
		${({ variant }) =>
		variant === 'closeBtn' &&
		css`
			width: 48px;
			height: 48px;
			background: transparent;
			border: 1px solid ${({ theme }) => theme.colors.text.error01};

			svg {
				height: 23px !important;
				width: 19px !important;
				path {
					fill: ${({ theme }) => theme.colors.text.error01};
				}
			}
			&:hover,
			&:focus,
			&:active {
				background: ${({ theme }) => theme.colors.text.error01};
				border: 1px solid ${({ theme }) => theme.colors.text.error01};
				svg {
					path {
						stroke: var(--whiteColor);
						fill: var(--whiteColor);
					}
				}
				.pr-button-label {
					color: var(--whiteColor);
				}
			}
		`}




		${({ userRewardMore }) =>
		userRewardMore &&
		css`
			margin: 9px 0 -5px 0;
			float: left;
			border-radius: 5px;
			padding: 0;
			svg {
				height: 17px !important;
				width: 20px !important;
			}
			&:hover {
				svg {
					path {
						stroke: ${({ theme }) => theme.baseColors.baseYellowLight};
						fill: ${({ theme }) => theme.baseColors.baseYellowLight};
					}
				}
			}
			@media (max-width: 768px) {
				display: none;
			}
		`}
		${({ collapseArrow }) =>
		collapseArrow &&
		css`
			margin: 3px 0 0 5px;
			border-radius: 5px;
			padding: 6px;
			line-height: normal;
			display: flex;
			align-items: center;
			svg {
				height: 7px !important;
				width: 12px !important;
				float: left;
			}
			&:hover {
				background: ${({ theme }) => theme.baseColors.baseYellow};
				border: 1px solid ${({ theme }) => theme.baseColors.baseYellow};
				svg {
					path {
						stroke: var(--whiteColor);
						fill: var(--whiteColor);
					}
				}
				.pr-button-label {
					color: var(--whiteColor);
				}
			}
		`}
  ${({ size }) =>
		size === 'lg' &&
		css`
			font-size: 1.5rem;
		`}

  ${({ size }) =>
		size === 'sm' &&
		css`
			font-size: 1rem;
			text-transform: uppercase;
		`}

		${({ changepassword }) =>
		changepassword &&
		css`
			--px: 0em;

			&:hover {
				background: none;
				color: ${({ theme }) => darken(0.2, theme.colors.primary)};
			}

			&:active {
				background: none;
				transform: translate3d(0, 1px, 0);
			}

			& > .pr-icon {
				margin-right: 10px;

				svg {
					width: 1em;
					height: 1em;
				}
			}
		`}

		${({ backbtn }) =>
		backbtn &&
		css`
			--px: 0.2em;
			--py: 0.2em;
			position: absolute;
			top: 15px;
			left: 15px;
			border-color: transparent;
			background: none;
			color: #242b24;

			&:hover,
			&:focus {
				background: none;
				color: ${({ theme }) => darken(0.2, theme.colors.primary)};
				border-color: transparent;
			}

			&:active {
				background: none;
				border-color: transparent;
				transform: translate3d(0, 1px, 0);
			}

			& > .pr-icon {
				margin-right: 10px;
				border-radius: 3px;
				background-color: ${({ theme }) => theme.colors.primary};
				padding: 2px 3px;

				svg {
					width: 1em;
					height: 1em;
					position: relative;
					left: -2px;
					top: -2px;
				}
			}
		`}
`;

type ssProps = SpaceProps & LayoutProps & FlexboxProps;

export type ButtonProps = ssProps & {
	variant?:
		| 'primary'
		| 'secondary'
		| 'editIcon'
		| 'deleteIcon'
		| 'redOutline'
		| 'success'
		| 'warning'
		| 'danger'
		| 'subtle'
		| 'ghost'
		| 'default'
		| 'fbBtn'
		| 'googleBtn'
		| 'modalClose'
		| 'textEdit'
		| 'logOut'
		| 'hamburgerMenu'
		| 'editBtn'
		| 'closeBtn'
		| 'tickBtn'
		| 'cartButton'
		| undefined;
	size?: 'lg' | 'sm';
	style?: React.CSSProperties;
	disabled?: boolean;
	fullWidth?: boolean;
	halfWidth?: boolean;
	payBtn?: boolean;
	paidBtn?: boolean;
	loginBtn?: boolean;
	loginBtnProgress?: boolean;
	shopViewBtn?: boolean;
	active?: boolean;
	userRewardMore?: boolean;
	collapseArrow?: boolean;
	rounded?: boolean;
	isActive?: boolean;
	children?: ReactNode;
	iconAfter?: ReactNode;
	iconBefore?: ReactNode;
	onClick?: MouseEventHandler;
	className?: string;
	type?: 'button' | 'submit' | 'reset';
	title?: string;
	changepassword?: boolean;
	backbtn?: boolean;
	cartButton?: boolean;
	color?: string;
	px?: string;
	py?: string;
};

function Button({
	fullWidth,
	halfWidth,
	loginBtn,
	payBtn,
	paidBtn,
	loginBtnProgress,
	shopViewBtn,
	active,
	userRewardMore,
	collapseArrow,
	rounded,
	disabled,
	style,
	size,
	variant,
	children,
	isActive,
	iconAfter,
	iconBefore,
	onClick,
	className,
	type,
	title,
	changepassword,
	backbtn,
	cartButton,
	color,
	px,
	py,
	...ssProps
}: ButtonProps): ReactElement {
	return (
		<StyledButton
			fullWidth={fullWidth}
			halfWidth={halfWidth}
			loginBtn={loginBtn}
			payBtn={payBtn}
			paidBtn={paidBtn}
			loginBtnProgress={loginBtnProgress}
			shopViewBtn={shopViewBtn}
			active={active}
			userRewardMore={userRewardMore}
			collapseArrow={collapseArrow}
			rounded={rounded}
			disabled={disabled}
			style={style}
			size={size}
			variant={variant}
			isActive={isActive}
			onClick={onClick}
			className={className}
			type={type || 'button'}
			title={title}
			changepassword={changepassword}
			backbtn={backbtn}
			cartButton={cartButton}
			color={color}
			px={px}
			py={py}
			{...ssProps}
		>
			{iconBefore && iconBefore}
			{children && <span className="pr-button-label">{children}</span>}
			{iconAfter && iconAfter}
		</StyledButton>
	);
}

export { Button };
