import React, { ReactNode, useCallback, useEffect, useRef } from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import styled, { css } from 'styled-components';
import { layout, LayoutProps, position, PositionProps, space, SpaceProps } from 'styled-system';
import * as Icon from 'ui/icons';
import useOnClickOutside from 'use-onclickoutside';

type DropdownMenuProps = SpaceProps & LayoutProps & PositionProps;

const DropdownMenu = styled.div<DropdownMenuProps>`
	${space}
	${layout}
	${position}
	position: relative;
`;

type DropdownItemGroupProps = {
	place?:
		| 'right-top'
		| 'right'
		| 'right-bottom'
		| 'left-top'
		| 'left'
		| 'left-bottom'
		| 'covered-left'
		| 'covered-right';
};

const DropdownItemGroup = styled.div<DropdownItemGroupProps>`
	position: absolute;
	background: rgb(255, 255, 255);
	border-radius: 12px;
	box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.39);
	box-sizing: border-box;
	overflow: auto;
	padding: 4px 0px;
	max-height: 317.5px;

	${({ place }) =>
		place === 'left' &&
		css`
			top: 100%;
			right: 100%;
		`}

	${({ place }) =>
		place === 'left-bottom' &&
		css`
			top: 100%;
			right: 0;
		`}

	${({ place }) =>
		place === 'left-top' &&
		css`
			top: 0;
			right: 100%;
			left: auto;
			margin-top: 0;
			margin-right: ${({ theme }) => theme.space[1]};
		`}

  ${({ place }) =>
		place === 'right' &&
		css`
			top: 100%;
			left: 0;
			right: auto;
		`}

  ${({ place }) =>
		place === 'right-top' &&
		css`
			top: 0;
			left: 100%;
			right: auto;
			margin-top: 0;
			margin-left: ${({ theme }) => theme.space[1]};
		`}

	${({ place }) =>
		place === 'covered-left' &&
		css`
			top: -2px;
			right: 0;
			left: auto;
			margin-top: 0;
		`}

	${({ place }) =>
		place === 'covered-right' &&
		css`
			top: -2px;
			left: 0;
			right: auto;
			margin-top: 0;
		`}

	& > * + *:before {
		content: '';
		position: absolute;
		top: 0;
		left: 10px;
		display: block;
		right: 10px;
		height: 1px;
		/* width: 100%; */
		background-color: #f6f4ee;
	}
`;

const ItemLabel = styled.span`
	display: block;
	flex: 1 1 auto;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	line-height: 1.14286;
	padding-bottom: 1px;
	text-align: left;
`;
const DropdownItem = styled.div<DropdownItemProps>`
	position: relative;
	appearance: none;
	border: none;
	align-items: center;
	border-radius: 0px;
	box-sizing: border-box;
	cursor: pointer;
	display: flex;
	flex: 0 0 auto;
	background-color: ${({ theme }) => theme.colors.bg.white};
	color: ${({ theme }) => theme.colors.text.Yellow};
	text-decoration: none;
	padding: 12px 0 11px;
	width: 100%;
	font-size: 14px;
	font-family: 'OpenSansBold';
	transition: all 200ms ease-out;
	svg {
		path {
			fill: ${({ theme }) => theme.colors.text.Yellow};
		}
	}
	span + span {
		display: block !important;
	}
	&:hover {
		color: ${({ theme }) => theme.colors.text.primary};
		text-decoration: none;
	}

	& > .pr-icon {
		margin-right: ${({ theme }) => theme.space[2]};
	}
	${({ active }) =>
		!active &&
		css`
			span {
				color: ${({ theme }) => theme.colors.text.black01};
				font-family: 'OpenSansMedium';

				& + span {
					display: none !important;
				}
			}
			&:hover {
				span {
					color: ${({ theme }) => theme.colors.text.Yellow};
					font-family: 'OpenSansBold';
				}
			}
		`}
`;

type styledSystemProps = SpaceProps & LayoutProps & PositionProps;

type DropdownProps = styledSystemProps & {
	showOptions?: boolean;
	setShowOptions?: any;
	trigger?: ReactNode;
	options?: Array<option>;
	place?:
		| 'right-top'
		| 'right'
		| 'right-bottom'
		| 'left-top'
		| 'left'
		| 'left-bottom'
		| 'covered-left'
		| 'covered-right';
};
type DropdownItemProps = {
	active?: boolean;
};

// type option = { id: number | string; label: string; icon?: ReactNode; active?: boolean };

type option = {
	id?: string | number;
	label?: string | number;
	active?: boolean;
};

function DropdownFilter({
	trigger,
	options,
	showOptions,
	setShowOptions,
	place,
	...styledSystemProps
}: DropdownProps) {
	const handleEscape = useCallback(
		(e) => {
			if (e.code === 'Escape' && showOptions) setShowOptions(false);
		},
		[showOptions, setShowOptions]
	);

	const handleOnClickOutside = () => {
		setShowOptions(false);
	};

	const wrapperRef = useRef<HTMLDivElement>(null);
	useOnClickOutside(wrapperRef, handleOnClickOutside);

	useEffect(() => {
		document.addEventListener('keydown', handleEscape);
		return () => {
			document.removeEventListener('keydown', handleEscape);
		};
	}, [handleEscape]);

	return (
		<>
			<DropdownMenu {...styledSystemProps} ref={wrapperRef} className="dropdownMenu">
				{trigger}
				{showOptions ? (
					<>
						{options ? (
							<DropdownItemGroup role="menu" place={place}>
								<SimpleBar style={{ maxHeight: 200 }}>
									{options.map((option) => (
										<DropdownItem key={option.id} active={option.active}>
											<ItemLabel>{option.label}</ItemLabel>
											<Icon.Tick />
										</DropdownItem>
									))}
								</SimpleBar>
							</DropdownItemGroup>
						) : null}
					</>
				) : null}
			</DropdownMenu>
		</>
	);
}

export { DropdownFilter };
