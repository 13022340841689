import Ipost from 'models/Ipost';
import queryString from 'query-string';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from 'ui/components';
import logo from 'ui/images/logo.svg';
import hooks from 'utils/requestCtx/reactQueryHooks';
import '../../ui/styles/login.css';

import styled from 'styled-components';
import { Flex, Text, Box } from 'rebass/styled-components';
import { Separator } from 'ui/components';
import * as Icon from 'ui/icons';

const LoginWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	position: relative;
	&::before {
		content: '';
		width: 100vw;
		height: 100vh;
		position: absolute;
		top: 0;
		background: ${({ theme }) => theme.colors.bg.orangeLighterGrayish};
		backdrop-filter: blur(54px);
		position: fixed;
	}
	h1 {
		float: left;
		width: 100%;
		text-align: left;
		color: ${({ theme }) => theme.baseColors.primary};
		font-weight: 600;
		font-size: 14px;
	}
`;
const Panel = styled.div`
	min-width: 548px;
	max-width: 548px;
	border-radius: 6px;
	background: ${({ theme }) => theme.colors.bg.orangeLighter};
	position: relative;
	padding: 50px 46px 46px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	@media (max-height: 900px) {
		padding: 30px;
		margin: auto;
		/* margin-top: 30px; */
	}

	@media (max-width: 767px) {
		max-width: 100%;
		min-width: 300px;
		width: 100%;
		border-radius: 0;
		margin-top: 0;
	}
	@media (max-width: 640px) {
		padding: 23px;
		position: fixed;
		overflow-y: auto;
		height: 100vh;
		justify-content: flex-start;
	}
	form {
		width: 100%;
		.loginFields {
			position: relative;
			.pr-icon {
				position: absolute;
				top: 28px;
				right: 27px;
				svg {
					path {
						fill: ${({ theme }) => theme.baseColors.primary};
					}
				}
			}
			.PasswordIcon {
				top: 3px;
			}
		}
		input {
			padding: 10px 22px 10px;
			font-family: 'OpenSansSemiBold';
			font-size: 16px;
			border: 0;
			border: 1px solid ${({ theme }) => theme.colors.bg.orangeLighterGrayish};
			transition: border-color 0.2s ease;
			display: block;
			width: 100%;
			background: ${({ theme }) => theme.baseColors.white};
			color: ${({ theme }) => theme.colors.text.body};
			height: 73px;
			border-radius: 9px;
			padding-right: 60px;

			&:focus {
				outline: none;
				border-color: ${({ theme }) => theme.baseColors.primary};
			}
			+ input {
				margin-top: 30px;
			}
		}
		.forgot {
			width: 100%;
			text-align: right;
			margin: -16px 0 30px;
			float: left;
			a {
				font-size: 16px;
				color: ${({ theme }) => theme.colors.text.black};
				&:hover {
					color: ${({ theme }) => theme.baseColors.primary};
				}
			}
		}
		input:-webkit-autofill,
		input:-webkit-autofill:hover,
		input:-webkit-autofill:focus,
		input:-webkit-autofill:active {
			-webkit-box-shadow: 0 0 0 50px white inset !important;
			font-size: 16px !important;
			font-family: 'OpenSansSemiBold';
		}
		.loginButton_holder {
			button {
				height: 73px;
				font-size: 18px !important;
				font-weight: normal !important;
				font-family: 'OpenSansSemiBold' !important;
				white-space: nowrap;
			}
			@media (max-width: 640px) {
				flex-direction: column;
				button {
					height: 50px;
					font-size: 16px !important;

					margin: 0!important;
					+ button {
						margin-top: 15px!important;
					}
				}
			}
		}
	}
`;

const VerificationComponent = () => {
	//AppConstants on login Initialize
	const history = useHistory();
	const location = useLocation();
	const [details, setDetails] = useState({
		username: '',
		confirmCode: '',
		password: '',
		confirmPassword: '',
	}); //set state for login details
	const [errors, setError] = useState({ error: '' }); //set state for error validation
	const parsed = queryString.parse(location.search);
	const userKey = 'validateKey';
	const [mutatePost] = hooks.useMutation(userKey);

	// function checkPasswordComplexity(pwd) {
	// 	var letter = /[a-zA-Z]/;
	// 	var number = /[0-9]/;
	// 	var valid = number.test(pwd) && letter.test(pwd); //match a letter _and_ a number
	// 	return valid;
	// }

	function validatePassword(pwd) {
		var p = pwd;
		if (p.length < 8) {
			setError({ error: 'Your password must be at least 8 characters' });
			return false;
		}
		if (p.search(/[!@#$%^&*]/i) < 0) {
			setError({ error: 'Your password must contain at least one special character' });
			return false;
		}
		if (p.search(/.*[A-Z]/) < 0) {
			setError({ error: 'Your password must contain at least one uppercase letter' });
			return false;
		}
		if (p.search(/.*[a-z]/) < 0) {
			setError({ error: 'Your password must contain at least one lowercase letter' });
			return false;
		}
		if (p.search(/[0-9]/) < 0) {
			setError({ error: 'Your password must contain at least one digit' });
			return false;
		}
		return true;
	}

	// function validatePasswordRegex(pwd) {
	// 	var minNumberofChars = 8;
	// 	var regularExpression = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9])(?=.*[a-z]).{8}$/;

	// 	if (pwd.length < minNumberofChars) {
	// 		return false;
	// 	}
	// 	if (!regularExpression.test(pwd)) {
	// 		return false;
	// 	}
	// 	return true;
	// }

	// Api call to pass the credentials entered and to validate it
	async function validateCode() {
		if (details && parsed.uid) {
			details.username = parsed.uid;
			if (details.password == details.confirmPassword) {
				if (!validatePassword(details.confirmPassword)) {
					setError({
						error:
							'Password must contain minimum 8 characters, one special character, one number, one uppercase and lowercase characters',
					});
					return false;
				}

				const obj: Partial<Ipost> = {
					data: {
						username: details.username.replace(' ', '+'),
						code: details.confirmCode,
						password: details.password,
					},
					params: {},
					url: 'experion/cognito/forgot-password/confirm',
					message: 'Your Password has been Reset Successfully',
				};
				try {
					await mutatePost(obj).then((data) => {
						if (data !== undefined) {
							history.push('/login');
						} else {
							setError({ error: 'Invalid Confirmation Code' });
						}
					});
				} catch (e) {}
			} else {
				setError({ error: 'Mismatch in Password and Confirm Password' });
			}
		} else {
			setError({ error: 'Invalid Operation. Please try again!' });
			history.push('/login');
		}
	}
	const redirect = () => {
		history.push('/login');
	};
	return (
		<LoginWrapper>
			<Panel>
				<img src={logo} alt="Logo" />
				<Separator>Reset Password</Separator>
				<form autoComplete="new-password">
					<Box className="loginFields" mb={24}>
						<Icon.PasswordIcon />
						<input
							id="confirmCode"
							type="text"
							title="Confirmation Code"
							placeholder="Confirmation Code"
							onChange={(event) => setDetails({ ...details, confirmCode: event.target.value })}
							value={details.confirmCode}
							autoComplete="new-password"
						/>
					</Box>
					<Box className="loginFields" mb={24}>
						<Icon.PasswordIcon />
						<input
							id="originalP"
							type="password"
							title="Password"
							placeholder="Enter Password"
							onChange={(event) => setDetails({ ...details, password: event.target.value })}
							value={details.password}
							autoComplete="new-password"
						/>
					</Box>
					<Box className="loginFields" mb={24}>
						<Icon.PasswordIcon />
						<input
							id="confirmP"
							type="password"
							title="Confirm Password"
							placeholder="Confirm Password"
							onChange={(event) => setDetails({ ...details, confirmPassword: event.target.value })}
							value={details.confirmPassword}
							autoComplete="new-password"
						/>
					</Box>

					<span
						style={{
							color: 'red',
							fontSize: '14px',
							margin: '-20px 0 10px 0',
							width: '100%',
							display: 'inline-block',
						}}
					>
						{errors.error}
					</span>
					<Flex className="loginButton_holder">
						<Button onClick={redirect} variant="secondary" fullWidth>
							Go Back to Login
						</Button>
						<Button type="button" onClick={validateCode} fullWidth style={{ marginLeft: '10px' }}>
							Confirm
						</Button>
					</Flex>

					{/* <a className="forgot" href="#">
						Forgot Username?
					</a> */}
				</form>
			</Panel>
		</LoginWrapper>
	);
};
export default VerificationComponent;
