import React, { ReactElement } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
// import { FlexboxProps, LayoutProps, SpaceProps } from 'styled-system';
import { Stack } from 'ui/components';

const StyledAttributeMainDetails = styled.div`
	width: 100%;
	padding: 0 0 0 0;
	margin: 0 0 40px 0;
	float: left;
	@media (max-width: 860px) {
		width: calc(100% + 30px);
		padding: 30px 15px;
		margin: 30px 0 0 -15px;
	}
`;
const ViewAllHeadingBlock = styled.div`
	h1 {
		font-family: 'OpenSansBold';
		text-transform: uppercase;
		color: ${({ theme }) => theme.colors.text.black};
		line-height: normal;
	}
`;
const AttributeMainDetailsHolder = styled.div`
	margin-top: 20px;
	@media (max-width: 860px) {
		div {
			max-height: max-content;
		}
	}
	article {
		margin: 0 0 10px 0;
		padding: 0;
		border-bottom: none;
		div {
			display: flex;
			align-items: center;
			margin-bottom: 18px;
			&:last-child {
				margin-bottom: 0;
			}
			h2 {
				font-weight: normal;
				margin: 0;
				flex: 0 0 150px;
				margin-right: 20px;
				font-family: 'OpenSansMedium';
				position: relative;
				@media (max-width: 480px) {
					flex: 0 0 150px;
					margin-right: 20px;
				}
				&:after {
					content: ':';
					position: absolute;
					top: 0;
					right: 0;
				}
			}
			h3 {
				margin: 0;
				font-family: 'OpenSansBold';
				word-break: break-word;
				white-space: normal;
			}
			@media (max-width: 860px) {
				display: flex;
				h3 {
					margin-top: 0;
				}
				h2,
				h3 {
					font-size: 17px;
				}
			}
		}
	}
`;
// type styledSystemProps = SpaceProps & LayoutProps & FlexboxProps;
// type StyledAttributeMainDetailsProps = styledSystemProps & {
// 	totalAmount?: string;
// };
function AttributeMainDetails({data}): ReactElement {
	return (
		<StyledAttributeMainDetails>
			{data && data?.length > 0 && <ViewAllHeadingBlock>
				<Text as="h1" fontSize="18px">
					Attribute Details
				</Text>
			</ViewAllHeadingBlock>}
			<AttributeMainDetailsHolder>
				<Box>
					<Stack direction="y" gap={3}>
						{data &&  data?.map(({ label, value },i) => (
							<Flex as="article" bg="white" p={[3, 4]} sx={{ alignItems: 'center' }} key={i}>
								<Box flex={1}>
									<Text
										as="h2"
										fontFamily="OpenSansMedium"
										fontSize={16}
										lineHeight="23px"
										color="text.black03"
										className="truncate"
									>
										{label}
									</Text>
									<Text as="h3" fontSize="18px" color="text.black01" className="truncate">
										{value}
									</Text>
								</Box>
							</Flex>
						))}
					</Stack>
				</Box>
			</AttributeMainDetailsHolder>
		</StyledAttributeMainDetails>
	);
}

export { AttributeMainDetails };
