import React, { ReactElement, useState } from 'react';
import { Text, Box, Flex } from 'rebass/styled-components';
import 'react-tippy/dist/tippy.css';
import { Button, Loader } from 'ui/components';
import icons, * as Icon from 'ui/icons';
import styled from 'styled-components';
import SweetAlert from 'react-bootstrap-sweetalert';
import utilityFunctions from 'utils/utility.functions';
import { venueUserPermissionList } from 'models/enum-constants/common.constants';
import { default as helper, default as helperFunctions } from 'utils/helper.functions';
import { Tooltip } from 'react-tippy';
import pageConstants from 'utils/pageConstants';
import { Modal, OrderGrid } from 'ui/patterns';
import NewFavoritesModal from '../recentOrderDetailsEditView/newFavorites';
import { UseGlobalState } from 'models/globalStateAccess';
import hooks from 'utils/requestCtx/reactQueryHooks';
import Iget from 'models/Iget';
import constants from 'utils/constants';
import api from 'utils/requestCtx/api.service';
import http from 'utils/http.service';
import urls from 'utils/create.url';

const StyledAllOrderDetailsEditView = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	column-gap: 20px;
	row-gap: 20px;
	margin-bottom: 16px;
	.order-details__wrap {
		flex-direction: row;
		background: ${({ theme }) => theme.colors.bg.white} !important;
		border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
		box-sizing: border-box;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 16px;
		overflow: hidden;
		transition: box-shadow 100ms ease-in, transform 100ms ease-in;
		align-items: flex-start;
		height: 100%;
		display: flex;
		span {
			max-width: 117px;
			flex: 0 0 117px;
			height: 100%;
			background-repeat: no-repeat;
			background-size: contain;
			@media (max-width: 890px) {
				max-width: 126px;
				flex: 0 0 126px;
				background-position: center center;
			}
			@media (max-width: 360px) {
				max-width: 80px;
				flex: 0 0 80px;
			}
		}
		section {
			display: grid;
			grid-template-columns: repeat(1, 1fr) !important;
			align-items: center;
			height: 100%;
			padding: 25px;
			row-gap: 20px;

			@media (max-width: 890px) {
				label {
					width: 100%;
					margin: 0;
					line-height: normal;
				}
			}

			@media (max-width: 480px) {
				padding: 15px;
			}
			.order-row {
				display: grid;
				grid-template-columns: repeat(8, 1fr);
				row-gap: 10px;
				column-gap: 10px;
				div {
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					display: block;
				}
				.order-col {
					flex: 0 0 100%;
					min-width: 100%;
					justify-self: auto;
				}
				@media (max-width: 1540px) {
					grid-template-columns: repeat(4, 1fr);
				}
				@media (max-width: 960px) {
					grid-template-columns: repeat(3, 1fr);
				}

				@media (max-width: 1268px) {
					.order-col {
						min-width: 100%;
					}
				}
				@media (max-width: 640px) {
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					.createdBy{
						grid-area: auto / 3 / auto / 1;

					}
				}
				@media (max-width: 480px) {
					grid-template-columns: repeat(2, 1fr);
				}
				@media (max-width: 340px) {
					grid-template-columns: repeat(1, 1fr);
				}
			}
			.order-amount-action {
				label {
					display: block;
				}
			}

			object {
				label {
					display: flex;
					div {
						margin: 4px 0 0 4px;
						cursor: pointer;
					}
					svg {
						width: 16px;
						height: 16px;
					}
				}
			}
			main {
				flex: 0 0 235px;
				max-width: 235px;
				width: 235px;
				.btn-block {
					display: flex;
					align-items: center;
					justify-content: flex-end;
					flex-wrap: wrap;
					width: 100%;
					@media (max-width: 640px) {
						justify-content: flex-start;
						button {
							margin-top: 5px;
							flex: auto !important;
							span {
								white-space: nowrap;
							}
						}
						div {
							width: 100%;
							span {
								max-width: none !important;
							}
						}
					}
					span {
						height: auto;
						flex: 0 0 auto;
						max-width: auto;
						min-width: auto;
					}
					button {
						height: 50px;
						padding: 0 !important;
						width: 50px;
						flex: 0 0 50px;
						margin-left: 10px;
						margin-top: 5px;
						margin-bottom: 5px;
						@media (max-width: 890px) {
							margin-left: 0;
							margin-right: 10px;
						}
						span {
							height: auto;
							min-height: auto;
						}
						svg {
							width: 20px;
							height: 20px;
							path {
								fill: ${({ theme }) => theme.baseColors.primary};
							}
						}
						&:hover,
						&:focus {
							svg {
								width: 20px;
								height: 20px;
								path {
									fill: ${({ theme }) => theme.baseColors.white};
								}
							}
						}
						+ button {
							display: flex;
							align-items: center;
							text-transform: capitalize;
							white-space: nowrap;
							padding: 0 1.5em !important;
							width: auto;

							@media (max-width: 480px) {
								font-size: 13px;
								padding: 0 10px !important;

								white-space: nowrap;
							}
							span {
								max-width: 100% !important;
								min-height: auto;
							}
							+ div button {
								width: auto;
								padding: 0 1.5em !important;
							}
						}
					}

					icon {
						display: inline-block;
					}
					div {
						word-break: break-word;
					}
				}
				@media (max-width: 640px) {
					grid-column: 3/1;
					flex: 0 0 100%;
					max-width: 100%;
					width: 100%;
				}
			}
			.totalProducts {
				div {
					text-align: right !important;
					white-space: nowrap !important;
					flex: 0 0 126px !important;
					max-width: 126px !important;
					justify-content: flex-end !important;
					min-width: 126px !important;
					label {
						justify-content: flex-end !important;
					}
				}
			}
		}
		&.selected {
			background: ${({ theme }) => theme.newColors.lightgray};
		}
	}
`;

function AllOrderDetailsEditView({ data, supplierCount }): ReactElement {
	const [cartId, setCartId] = useState('');
	const [showNewFavoritesModal, setShowNewFavoritesModal] = useState(false);
	const [venueUserId] = useState<any>(localStorage.getItem('userId'));
	const [state, dispatch] = UseGlobalState();
	const [venueId, setVenueId] = useState<any>(localStorage.getItem('selectedVenue'));
	const [postObj, setObj] = useState({} as any);
	const toggleNewFavoritesModal = (id, qty) => {
		setObj({ product_id: id, qty: qty });
		setShowNewFavoritesModal(!showNewFavoritesModal);
	};
	const [showPopup, setShowModal] = useState(false);
	const [orderId, setOrderId] = useState('');
	const [modalText, setmodalText] = useState('');
	const [inProgress, setInProgress] = useState(false);
	let statusList = getStatusList(data.status);
	let orderStatus = utilityFunctions.getOrderStatus(statusList.map((x) => x.status));
	function updateList() {}
	function getStatusList(status) {
		let data = [] as any;
		if (status && status.length == 1) {
			data.push(JSON.parse(status));
		} else if (status && status.length > 1) {
			let items = JSON.parse(JSON.stringify(status));
			items.map((v) => {
				data.push(JSON.parse(v));
			});
		}
		return data;
	}

	//api to get total amount
	const url1 = `/carts/${cartId}/totals`;
	const obj6: Partial<Iget> = {
		url: url1,
		enabled: false, //manual fetch
		key: 'carttotalKey1',
		resultFilteringRequired: false,
	};
	var qryTotal = hooks.useQuery(obj6);

	const getOrderAndMoveTocart = async (orderId) => {
		setInProgress(true);
		let id: any;
		let CUSTOMER_TOKEN = localStorage.getItem('customerToken');
		id = await utilityFunctions.getCartId(CUSTOMER_TOKEN);
		if (id !== undefined) {
			setCartId(id);
			http
				.GET({
					url: urls.GetSearchUrl({
						url: 'orders?',
						fields: [
							{
								field_name: 'group_order_id',
								field_value: orderId,
							},
						],
					}),
				})
				.then(function (d) {
					if (d.data?.items?.length > 0) {
						const datas = d.data?.items;
						moveToCart(orderId, id, datas);
					} else {
						setInProgress(false);
					}
				})
				.catch((e) => {
					setInProgress(false);
				});
		} else {
			setCartId('');
			setInProgress(false);
		}
	};

	const moveToCart = (orderId, cart_id, productsData) => {
		// products
		var obj = { product_id: '', qty: null };
		const item = [] as any;
		productsData.forEach((data) => {
			data.items.forEach((data) => {
				obj = { product_id: data.product_id, qty: data.qty_ordered };
				item.push(obj);
			});
		});
		let addToCartJson = {
			products: {
				orderGroupId: parseInt(orderId),
				quote_id: parseInt(cart_id),
				items: item,
				venue_user_id: parseInt(venueUserId),
				venue_id: parseInt(venueId),
			},
		};
		api
			.post({
				data: addToCartJson,
				url: constants.multiCartURL(),
				key: 'allOrderCartAdd',
				message: 'Successfully moved to cart',
			})
			.then(function (data) {
				fetchTotal();
			})
			.catch((e) => {
				setInProgress(false);
			});
	};

	const fetchTotal = () => {
		qryTotal
			.refetch()
			.then((data) => {
				if (data) {
					updateCartTotal(data);
				}
				setInProgress(false);
			})
			.catch((e) => {
				setInProgress(false);
			});
	};

	function updateCartTotal(data) {
		if (data) {
			let datas = data.items?.filter((x) => x.is_deleted != true);
			if (datas?.length > 0) {
				dispatch({ cartTotal: helperFunctions.formatAmount(data.grand_total) });
				dispatch({ cartItemCount: datas?.length });
				// setTotalCount(data.subtotal);
			} else {
				dispatch({ cartItemCount: '0' });
				dispatch({ cartTotal: '' });
				// setTotalCount(0);
			}
		} else {
			dispatch({ cartItemCount: '0' });
			dispatch({ cartTotal: '' });
			// setTotalCount(0);
		}
	}

	function confirm(e, orderId) {
		e.stopPropagation();
		showConfirmationModal('Move to cart ?', orderId);
	}
	function showConfirmationModal(text, id) {
		setOrderId(id);
		setmodalText(text);
		setShowModal(!showPopup);
	}
	async function closePopup() {
		await setShowModal(!showPopup);
	}
	function onSubmit() {
		getOrderAndMoveTocart(orderId);
		setShowModal(!showPopup);
	}

	return (
		<StyledAllOrderDetailsEditView>
			{inProgress ? <Loader></Loader> : null}
			<Flex className="order-details__wrap selected" bg="white" sx={{ alignItems: 'center' }}>
				<Flex as="section" flexWrap="wrap" width="100%" maxWidth={['100%']}>
					<Box>
						<Box className="order-row">
							<Box className="order-col">
								<Text mb={1} fontSize={18} color="text.black">
									Order ID
								</Text>
								<Text as="label" fontSize={20} fontFamily="OpenSansBold" color="text.black">
									{'#' + data.order_group_increment_id}
								</Text>
							</Box>

							<Box className="order-col createdBy">
								<Text mb={1} fontSize={18} color="text.black">
									Created by
								</Text>
								<Text as="label" fontSize={20} fontFamily="OpenSansBold" color="text.black">
									{data.name}
								</Text>
							</Box>
							<Box
								as="object"
								className="order-col"
								onClick={(e) => {
									e.stopPropagation();
								}}
							>
								<Text as="label" fontSize={16} fontWeight="normal" color="#242B24">
									Status
									<Tooltip
										arrow
										animation="perspective"
										position="right"
										html={
											<div className="tooltip-body tooltip-content statusTooltip">
												{statusList &&
													statusList.map((v) => {
														return (
															<span key={helperFunctions.getRandomInt(pageConstants.maxValue)}>
																<Text
																	as="label"
																	fontSize="17px"
																	fontFamily="OpenSansRegular"
																	color="text.black03"
																>
																	{`${v.supplier_name} :`}
																	<strong>
																		{helperFunctions.toPascalCase(
																			v.status == 'order_rejected'
																				? 'Rejected'
																				: v.status?.replace('_', ' ')
																		)}
																	</strong>
																</Text>
															</span>
														);
													})}
											</div>
										}
									>
										{data.status?.length > 0 && <icons.InfoIconRound />}
									</Tooltip>
								</Text>
								<Text fontSize={18} fontWeight="bold" color="#242B24">
									{helperFunctions.toPascalCase(
										orderStatus == 'order_rejected' ? 'Rejected' : orderStatus?.replace('_', ' ')
									)}
								</Text>
							</Box>
							<Box as="object" className="order-col totalProducts">
								<Box>
									<Text mb={1} fontSize={18} color="text.black">
										Total Products
									</Text>
									<Text as="label" fontSize={20} fontFamily="OpenSansBold" color="text.black">
										{data.no_of_items}
									</Text>
								</Box>
							</Box>
							<Box className="order-col">
								<Text mb={1} fontSize={18} color="text.black">
									Created On
								</Text>
								<Text as="label" fontSize={20} fontFamily="OpenSansBold" color="text.black">
									{helper.formatDate(data.created_at)}
								</Text>
							</Box>
							<Box className="order-col totalProducts" as="object">
								<Box>
									<Text mb={1} fontSize={18} color="text.black">
										Total Suppliers
									</Text>
									<Text as="label" fontSize={20} fontFamily="OpenSansBold" color="text.black">
										{supplierCount}
									</Text>
								</Box>
							</Box>
							<Box as="object" className="order-amount-action">
								<Text mb={1} fontSize={18} color="text.black">
									Amount
								</Text>
								<Text as="label" fontSize={20} fontFamily="OpenSansBold" color="text.black">
									{helper.formatAmount(data.cart_total)}
								</Text>
							</Box>
							<Box as="main">
								<div className="btn-block">
									{utilityFunctions.isGranted(venueUserPermissionList.FavoriteOrderAdd) && (
										<Button
											variant="secondary"
											className="Favorites"
											onClick={() => {
												toggleNewFavoritesModal(data.order_group_id, data.no_of_items);
											}}
										>
											<Icon.FavoritesIcon title="FavoritesIcon" />
										</Button>
									)}

									{utilityFunctions.isGranted(venueUserPermissionList.ManageProductsinCart) && (
										<Button onClick={(e) => confirm(e, data.order_group_id)}>
											<Text as="h1" fontFamily="OpenSansBold" fontSize={18} color="text.white">
												Move To Cart
											</Text>
										</Button>
									)}
								</div>
							</Box>
						</Box>
					</Box>
				</Flex>
			</Flex>
			<Modal
				width="500px"
				showModal={showNewFavoritesModal}
				setShowModal={setShowNewFavoritesModal}
			>
				<NewFavoritesModal
					updateList={updateList}
					showModal={showNewFavoritesModal}
					setShowModal={setShowNewFavoritesModal}
					orderId={postObj.product_id}
				/>
			</Modal>
			<SweetAlert
				show={showPopup}
				confirmBtnText="Confirm"
				onConfirm={onSubmit}
				showCancel={true}
				onCancel={closePopup}
				title={'Confirmation Box'}
			>
				{() => (
					<form>
						<hr />
						{modalText}
						<hr />
					</form>
				)}
			</SweetAlert>
		</StyledAllOrderDetailsEditView>
	);
}

export { AllOrderDetailsEditView };
