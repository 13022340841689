import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Button, FloatLabelInput } from 'ui/components';

const TableWrapper = styled.div`
	overflow: auto;
	background: linear-gradient(white 30%, rgba(255, 255, 255, 0)),
		linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
		radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
		radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
	background-repeat: no-repeat;
	background-color: #fff;
	background-size: 100% 4em, 100% 4em, 100% 1.4em, 100% 1.4em;
	background-attachment: local, local, scroll, scroll;
	border-radius: ${({ theme }) => theme.radii.soft};
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);

	main > & {
		height: 1px;
		flex: 1 1 auto;
	}
`;

const StyledTable = styled.table`
	position: relative;
	width: 100%;

	border-collapse: collapse;
	font-size: 1rem;

	th,
	td {
		padding: 1em;
		vertical-align: text-top;
		text-align: left;
	}

	th {
		--bg: var(--color-neutral-contrast-03);
		padding: 0.5em 1em 1em;
		vertical-align: bottom;
		background-color: #f0e9da;
		color: ${({ theme }) => theme.colors.text.body};
		border-bottom: solid 0.1em ${({ theme }) => theme.colors.border.default};
		position: -webkit-sticky;
		position: sticky;
		top: -1px;
		z-index: 2;
		white-space: nowrap;

		&:first-child {
			border-top-left-radius: ${({ theme }) => theme.radii.soft};
		}

		&:last-child {
			border-top-right-radius: ${({ theme }) => theme.radii.soft};
		}
	}
`;

type TableProps = {
	data: Array<any>;
	columns: Array<any>;
	tableAction?: () => void;
};

function Table({ columns, data, tableAction }: TableProps): ReactElement {
	return (
		<TableWrapper>
			<StyledTable>
				<thead>
					<tr>
						{columns.map((column) => (
							<th key={column.id}>
								{column.label ? <FloatLabelInput label={column.label} /> : null}
							</th>
						))}
						{tableAction ? <th>Action</th> : null}
					</tr>
				</thead>
				<tbody>
					<tr>
						{data.map((item) => (
							<td key={item.id}>{item.label}</td>
						))}
						{tableAction ? (
							<td>
								<Button variant="primary" onClick={tableAction}>
									Proceed to Pay
								</Button>
							</td>
						) : null}
					</tr>
					<tr>
						{data.map((item) => (
							<td key={item.id}>{item.label}</td>
						))}
						{tableAction ? (
							<td>
								<Button variant="primary" onClick={tableAction}>
									Proceed to Pay
								</Button>
							</td>
						) : null}
					</tr>
					<tr>
						{data.map((item) => (
							<td key={item.id}>{item.label}</td>
						))}
						{tableAction ? (
							<td>
								<Button variant="primary" onClick={tableAction}>
									Proceed to Pay
								</Button>
							</td>
						) : null}
					</tr>
					<tr>
						{data.map((item) => (
							<td key={item.id}>{item.label}</td>
						))}
						{tableAction ? (
							<td>
								<Button variant="primary" onClick={tableAction}>
									Proceed to Pay
								</Button>
							</td>
						) : null}
					</tr>
					<tr>
						{data.map((item) => (
							<td key={item.id}>{item.label}</td>
						))}
						{tableAction ? (
							<td>
								<Button variant="primary" onClick={tableAction}>
									Proceed to Pay
								</Button>
							</td>
						) : null}
					</tr>
					<tr>
						{data.map((item) => (
							<td key={item.id}>{item.label}</td>
						))}
						{tableAction ? (
							<td>
								<Button variant="primary" onClick={tableAction}>
									Proceed to Pay
								</Button>
							</td>
						) : null}
					</tr>
					<tr>
						{data.map((item) => (
							<td key={item.id}>{item.label}</td>
						))}
						{tableAction ? (
							<td>
								<Button variant="primary" onClick={tableAction}>
									Proceed to Pay
								</Button>
							</td>
						) : null}
					</tr>
					<tr>
						{data.map((item) => (
							<td key={item.id}>{item.label}</td>
						))}
						{tableAction ? (
							<td>
								<Button variant="primary" onClick={tableAction}>
									Proceed to Pay
								</Button>
							</td>
						) : null}
					</tr>
					<tr>
						{data.map((item) => (
							<td key={item.id}>{item.label}</td>
						))}
						{tableAction ? (
							<td>
								<Button variant="primary" onClick={tableAction}>
									Proceed to Pay
								</Button>
							</td>
						) : null}
					</tr>
					<tr>
						{data.map((item) => (
							<td key={item.id}>{item.label}</td>
						))}
						{tableAction ? (
							<td>
								<Button variant="primary" onClick={tableAction}>
									Proceed to Pay
								</Button>
							</td>
						) : null}
					</tr>
					<tr>
						{data.map((item) => (
							<td key={item.id}>{item.label}</td>
						))}
						{tableAction ? (
							<td>
								<Button variant="primary" onClick={tableAction}>
									Proceed to Pay
								</Button>
							</td>
						) : null}
					</tr>
					<tr>
						{data.map((item) => (
							<td key={item.id}>{item.label}</td>
						))}
						{tableAction ? (
							<td>
								<Button variant="primary" onClick={tableAction}>
									Proceed to Pay
								</Button>
							</td>
						) : null}
					</tr>
					<tr>
						{data.map((item) => (
							<td key={item.id}>{item.label}</td>
						))}
						{tableAction ? (
							<td>
								<Button variant="primary" onClick={tableAction}>
									Proceed to Pay
								</Button>
							</td>
						) : null}
					</tr>
					<tr>
						{data.map((item) => (
							<td key={item.id}>{item.label}</td>
						))}
						{tableAction ? (
							<td>
								<Button variant="primary" onClick={tableAction}>
									Proceed to Pay
								</Button>
							</td>
						) : null}
					</tr>
					<tr>
						{data.map((item) => (
							<td key={item.id}>{item.label}</td>
						))}
						{tableAction ? (
							<td>
								<Button variant="primary" onClick={tableAction}>
									Proceed to Pay
								</Button>
							</td>
						) : null}
					</tr>
					<tr>
						{data.map((item) => (
							<td key={item.id}>{item.label}</td>
						))}
						{tableAction ? (
							<td>
								<Button variant="primary" onClick={tableAction}>
									Proceed to Pay
								</Button>
							</td>
						) : null}
					</tr>
					<tr>
						{data.map((item) => (
							<td key={item.id}>{item.label}</td>
						))}
						{tableAction ? (
							<td>
								<Button variant="primary" onClick={tableAction}>
									Proceed to Pay
								</Button>
							</td>
						) : null}
					</tr>
				</tbody>
			</StyledTable>
		</TableWrapper>
	);
}

export { Table };
