import { venueUserPermissionList } from 'models/enum-constants/common.constants';
import React, { ReactElement, useState } from 'react';
import { Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { Button, Footer, Header, Stack } from 'ui/components';
import icons from 'ui/icons';
import { Sidebar } from 'ui/patterns';
import utilityFunctions from 'utils/utility.functions';


const MainContent = styled.div`
	position: relative;
	padding: 0 50px 50px;
	@media (max-width: 960px) {
		padding: 0 15px;
	}
`;
const OrderPlacedHolder = styled.div`
	display: flex;
	height: auto;
	position: relative;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 90px 50px 50px;
	text-align: center;
	@media (max-width: 767px) {
		padding: 50px 20px 50px;
	}
	h1 {
		margin-top: 32px;
		line-height: 47px;
		@media (max-width: 767px) {
			font-size: 30px;
			line-height: normal;
		}
	}
	h2 {
		margin-top: 8px;
		line-height: 32px;
		@media (max-width: 767px) {
			font-size: 20px;
			line-height: normal;
		}
	}
	p {
		margin-top: 32px;
		line-height: 26px;
		text-align: center;
		@media (max-width: 767px) {
			font-size: 17px;
			line-height: normal;
		}
	}
	div {
		margin-top: 72px;
		button {
			padding: 0;
			min-width: 260px;
			height: 50px;
			text-transform: capitalize;
			font-size: 18px;
			margin: 0;
			font-family: 'OpenSansBold';
			& + button {
				margin-left: 28px;
				@media (max-width: 640px) {
					margin-left: 0;
					margin-top: 20px;
				}
			}
		}
		@media (max-width: 640px) {
			flex-direction: column;
			margin-top: 50px;
		}
	}
`;

function CheckoutPlaceOrder(): ReactElement {
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);	
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	}
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={()=>onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={()=>onclickHambrgBtn()}></Sidebar>

			<MainContent>
				<OrderPlacedHolder>
					<icons.successFullIcon />
					<Text as="h1" fontSize={36} fontFamily="OpenSansBold" color="text.black04">
						Order Successfully Placed
					</Text>
					<Text as="h2" fontSize={24} color="text.black04">
						Order ID <strong>UDHDNW12349</strong>
					</Text>

					<Text as="p" fontSize={20} color="text.black04">
						Congratulations your order has been successfully being placed.<br></br> we have sent the
						confirmation link and update the tracking the details once the order has been confirmed
					</Text>
					{utilityFunctions.isGranted(venueUserPermissionList.FavoriteOrderView) &&
					<Stack direction="x" gap={3} justifyContent="center" flexDirection="row">
						<Button variant="secondary">Mark as Favourite Order</Button>
						<Button>Go back to Shopping</Button>
					</Stack>}
				</OrderPlacedHolder>
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default CheckoutPlaceOrder;
