import styled from 'styled-components';

const StyledProductMainImage = styled.div`
	width: 100%;
`;
const ProductMainImageHolder = styled.div`
	width: 100%;
	margin-bottom: 20px;
	display: flex !important;
	justify-content: center;
	border: 1px solid ${({ theme }) => theme.colors.border.YellowBorder01};
	padding: 5px;
	border-radius: 16px;
	max-height: 420px;
	min-height: 420px;
	@media (max-width: 860px) {
		display: none !important;
	}

	img {
		max-width: 100%;
		border-radius: 16px;
		height: auto;
		max-height: 100%;
		object-fit: contain;
	}
`;
const ProductThumbsImageHolder = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	.slick-slide {
		width: 100%;
		border: 1px solid ${({ theme }) => theme.colors.border.YellowBorder01};
		border-radius: 10px;
		&:hover {
			border: 1px solid ${({ theme }) => theme.colors.border.YellowBorder01};
		}

		&:focus {
			outline: none;
		}
		div {
			display: flex !important;
			justify-content: center;
			height:100%;

		}
	}
	img {
		max-width: 134px;
		border-radius: 10px;
		cursor: pointer;
		float: left;
		max-height: 134px;
		object-fit: contain;
		padding: 5px;
	}
	div {
		outline: none !important;
	}
	.slick-slider {
		width: 100%;
		.slick-prev,
		.slick-next {
			z-index: 9;
			width: 24px;
			height: 24px;
			top: calc(50% - 4px);
		}
		.slick-prev,
		.slick-next {
			@media (max-width: 767px) {
				display: none !important;
			}
			&:before {
				content: '' !important;
				width: 24px;
				height: 24px;
				display: block;
				opacity: 1;
			}
		}
		.slick-prev {
			left: 15px;
			&:before {
				background: url(${require('../../images/leftSlideArrow.svg')});
			}
		}
		.slick-next {
			right: 15px;
			&:before {
				background: url(${require('../../images/rightSlideArrow.svg')});
			}
		}

		.slick-next.slick-disabled:before,
		.slick-prev.slick-disabled:before {
			opacity: 0.25;
		}

		.slick-list {
			@media (max-width: 767px) {
				overflow: hidden;
			}
			@media (max-width: 640px) {
				overflow: hidden;
			}
			.slick-track {
				display: flex;
				padding-right: 0;
				.slick-slide {
					width: 100%;
					margin-left: 20px;
					height: 135px;
					@media (max-width: 640px) {
						margin-left: 0;
						padding: 0 3px;
					}
					&:first-child {
						margin-left: 0;
					}
				}
			}
		}
		.slick-dots {
			li {
				width: 8px;
				height: 8px;
				button {
					width: 8px;
					height: 8px;
					&::before {
						font-size: 8px;
						line-height: 8px;
						width: 8px;
						height: 8px;
						color: ${({ theme }) => theme.colors.border.greyBorderLogo};
						opacity: 1;
					}
				}
				&.slick-active {
					button {
						&::before {
							color: ${({ theme }) => theme.baseColors.baseYellow};
							z-index: 1;
						}
						&::after {
							content: '';
							position: absolute;
							width: 14px;
							height: 14px;
							background: ${({ theme }) => theme.colors.border.greyBorderLogo};
							left: -3px;
							top: -4px;
							border-radius: 50px;
						}
					}
				}
			}
			@media (max-width: 767px) {
				bottom: -20px;
				li {
					width: 8px;
					height: 8px;
					button {
						width: 8px;
						height: 8px;
						&::before {
							font-size: 8px;
							line-height: 8px;
							width: 8px;
							height: 8px;
							color: ${({ theme }) => theme.colors.border.greyBorderLogo};
							opacity: 1;
						}
					}
					&.slick-active {
						button {
							&::before {
								color: ${({ theme }) => theme.baseColors.baseYellow};
								z-index: 1;
							}
							&::after {
								content: '';
								position: absolute;
								width: 13px;
								height: 13px;
								background: ${({ theme }) => theme.colors.border.greyBorderLogo};
								left: -3px;
								top: -3px;
								border-radius: 50px;
							}
						}
					}
				}
			}
		}
	}
`;
export { StyledProductMainImage, ProductMainImageHolder, ProductThumbsImageHolder };
