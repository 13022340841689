import React, { ReactElement } from 'react';
import { Text, Box, Flex } from 'rebass/styled-components';
import 'react-tippy/dist/tippy.css';
import { Search, Button, SortBy } from 'ui/components';
import styled from 'styled-components';
import icons, * as Icon from 'ui/icons';
const StyledOrderListView = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	column-gap: 20px;
	row-gap: 20px;
	article {
		flex-direction: row;
		background: ${({ theme }) => theme.colors.bg.white};
		border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
		box-sizing: border-box;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 16px;
		overflow: hidden;
		transition: box-shadow 100ms ease-in, transform 100ms ease-in;
		align-items: flex-start;
		cursor: pointer;
		&:hover {
			transform: translateY(-5px);
			box-shadow: 0 20px 20px #eee;
			border: 1px solid ${({ theme }) => theme.baseColors.baseYellowLight};
		}
		span {
			max-width: 120px;
			flex: 0 0 120px;
			height: 100%;
			min-height: 130px;
			background-repeat: no-repeat;
			background-size: cover;
			@media (max-width: 768px) {
				max-width: 126px;
				flex: 0 0 126px;
			}
			@media (max-width: 480px) {
				max-width: 100px;
				flex: 0 0 100px;
			}
		}
		section {
			display: grid !important;
			grid-template-columns: repeat(6, 1fr) !important;
			height: 100%;
			padding: 25px;
			align-items: center;

			@media (max-width: 1300px) {
				padding: 20px;
				grid-template-columns: repeat(3, 1fr) !important;
				row-gap: 20px;
			}
			@media (max-width: 768px) {
				row-gap: 15px;
				grid-template-columns: repeat(1, 1fr) !important;
			}
			@media (max-width: 480px) {
				padding: 15px;
			}
			.btn-block {
				display: flex;
				align-items: center;
				justify-content: flex-end;

				@media (max-width: 1300px) {
					justify-content: flex-start;
				}
				span {
					height: auto;
					flex: 0 0 auto;
					max-width: auto;
					min-width: auto;
					min-height: auto;
				}
				button {
					display: flex;
					align-items: center;
					margin-right: 15px;
					text-transform: capitalize;
					white-space: nowrap;
					height: 50px;
					@media (max-width: 480px) {
						margin-left: 15px;
						font-size: 13px;
						padding-right: 10px;
						padding-left: 10px;
						white-space: nowrap;
					}
					span {
						max-width: 100% !important;
						display: flex;
						align-items: center;
						svg {
							height: 14px;
							margin-right: 5px;
							path {
								fill: ${({ theme }) => theme.colors.bg.white};
							}
						}
					}
				}
				icon {
					display: inline-block;
				}
				div {
					word-break: break-word;
				}
			}
		}
	}
`;
const PageSearchHolder = styled.div`
	width: 100%;
	padding-top: 15px;
	padding-bottom: 10px;
	display: flex;
	justify-content: space-between;
	div {
		width: 650px;
		@media screen and (max-width: 1200px) {
			width: 60%;
		}
		@media screen and (max-width: 860px) {
			width: 100%;
		}
		+ div {
			width: 254px;
			@media screen and (max-width: 860px) {
				width: 100%;
				margin-top: 10px;
			}
		}
	}
	@media screen and (max-width: 860px) {
		flex-direction: column;
	}
	@media screen and (max-width: 767px) {
		width: 100%;
	}
`;
const orderListView = [
	{
		id: 1,
		PendingOrderID: '#00000669',
		price: '$130',
		createdOn: '21 June 2020',
		totalItems: '14',
		status: 'Rejected',
	},
];

function PendingOrders(): ReactElement {
	return (
		<StyledOrderListView>
			<PageSearchHolder>
				<Search></Search>
				<SortBy></SortBy>
			</PageSearchHolder>
			{orderListView.map(({ id, PendingOrderID, price, createdOn, totalItems, status }) => (
				<Flex as="article" bg="white" sx={{ alignItems: 'center' }} key={id}>
					<Flex as="section" flexWrap="wrap" width="100%" maxWidth={['100%']}>
						<Box>
							<Text fontSize={16} color="text.black">
								Pending Order ID
							</Text>
							<Text fontSize={18} fontFamily="OpenSansBold" color="text.black">
								{PendingOrderID}
							</Text>
						</Box>
						<Box>
							<Text as="h1" fontSize={16} color="text.black">
								Price
							</Text>
							<Text as="h2" fontSize={18} fontFamily="OpenSansBold" color="text.black">
								{price}
							</Text>
						</Box>
						<Box>
							<Text as="h1" fontSize={16} color="text.black">
								Created On
							</Text>
							<Text as="h2" fontSize={18} fontFamily="OpenSansBold" color="text.black">
								{createdOn}
							</Text>
						</Box>
						<Box>
							<Text as="h1" fontSize={16} color="text.black">
								Total Items
							</Text>
							<Text as="h2" fontSize={18} fontFamily="OpenSansBold" color="text.black">
								{totalItems}
							</Text>
						</Box>
						<Box>
							<Text as="h1" fontSize={16} color="text.black">
								Status
							</Text>
							<Text as="h2" fontSize={18} fontFamily="OpenSansBold" color="text.black">
								{status}
							</Text>
						</Box>
						<Box>
							<div className="btn-block">
								<Button>Move to Cart</Button>
							</div>
						</Box>
					</Flex>
				</Flex>
			))}
		</StyledOrderListView>
	);
}

export { PendingOrders };
