import styled from 'styled-components';
const StyledNotifications = styled.div`
	width: 100%;
	padding: 0 0 0 0;
`;
const NotificationHeadingBlock = styled.div`
	display: flex !important;
	justify-content: space-between;
	padding: 0 25px;
	color: ${({ theme }) => theme.colors.text.black};
	min-height: 60px;
	display: flex;
	align-items: center;
	background: ${({ theme }) => theme.colors.bg.lightYellow02};
	border-radius: 16px 16px 0 0;
	position: relative;
	button {
		display: none;
	}
	@media (max-width: 860px) {
		border-radius: 12px;
		padding: 0 16px;
		a {
			display: none;
		}
		button {
			cursor: pointer;
			background: transparent !important;
			padding: 0 !important;
			outline: none;
			position: absolute;
			width: 100%;
			left: 0;
			height: 60px;
			text-align: right;
			justify-content: flex-end;
			display: flex;
			align-items: center;
			span {
				margin-right: 21px;
			}
		}
	}
	h1 {
		line-height: 18px;
		text-transform: uppercase;
		margin-left: 20px;
		@media (max-width: 860px) {
			margin-left: 10px;
		}
	}
	h1,
	a {
		font-weight: normal;
		font-family: 'OpenSansMedium';
	}
	h1 {
		font-family: 'OpenSansBold';
		text-transform: uppercase;
		display: flex;
		align-items: center;
		div {
			background: ${({ theme }) => theme.colors.primary};
			border-radius: 14px;
			color: ${({ theme }) => theme.colors.text.white};
			height: 23px;
			padding: 0 10px;
			display: flex;
			align-items: center;
			font-size: 14px;
			line-height: 18px;
			font-family: 'OpenSansBold';
			margin-left: 5px;
		}
	}
	a {
		color: ${({ theme }) => theme.baseColors.baseYellow};
		text-decoration: none;
		transition: background 0.8s;
		transition: ease-in all 200ms;

		&:hover {
			background: ${({ theme }) => theme.baseColors.baseYellow};
			padding: 3px 10px;
			color: ${({ theme }) => theme.baseColors.white};
			border-radius: 5px;
		}
	}
`;
const NotificationsDetails = styled.div`
	padding: 16px;
	border-top: 0;
	border: 1px solid ${({ theme }) => theme.colors.border.grayLightBorder01};
	height: calc(100% - 60px);
	border-radius: 0 0 16px 16px;
	overflow: hidden;
	@media (max-width: 860px) {
		display: none;
	}
	.ScrollbarsCustom-Track {
		background: rgba(222, 222, 222, 0.24) !important;
		width: 8px !important;
		.ScrollbarsCustom-Thumb {
			background: ${({ theme }) => theme.baseColors.primary}!important;
		}
	}

	article {
		margin: 0;
		padding: 0;
		border-bottom: none;
		background: ${({ theme }) => theme.colors.bg.lightYellow};
		border-radius: 8px;
		margin: 0 0 10px 0;
		div {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 15px;

			h2 {
				strong {
					font-family: 'OpenSansBlack';
				}
			}
		}
	}
`;
export { StyledNotifications, NotificationHeadingBlock, NotificationsDetails };
