import { Input, Label } from '@rebass/forms';
import fileUploadHandler from 'admin/sKUList/upload.handler';
import { ExportToCsv } from 'export-to-csv';
import { venueUserPermissionList } from 'models/enum-constants/common.constants';
import Ipost from 'models/Ipost';
import React, { ReactElement, useEffect, useState } from 'react';
import { Box, Text } from 'rebass/styled-components';
import {
	BreadcrumbNav,
	Button,
	Footer,
	Header,
	Loader,
	SearchAutoSuggestionSKU,
	TableList,
} from 'ui/components';
import icons from 'ui/icons';
import { Sidebar } from 'ui/patterns';
import urls from 'utils/create.url';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import utilityFunctions from 'utils/utility.functions';
import { TotalCostBlock } from 'venue/cart/styles';
import constants from '../../utils/constants';
import { BreadcrumbHolder, ButtonWrapper, MainContent } from './styles';
import Iget from 'models/Iget';
import { UseGlobalState } from 'models/globalStateAccess';
import helperFunctions from 'utils/helper.functions';

function SKUList(): ReactElement {
	const [qty, setQuantity] = useState<any>('');
	const [price, setPrice] = useState<any>('');
	const [subTotal, setSubTotal] = useState<any>('');
	const [gridItems, AddGridItems] = useState<any>([]);
	const [enteredSku, setEnteredSku] = useState('');
	const [pdtId, setEnteredPdtId] = useState<any>();
	const [isLoading, setIsLoading] = useState(false);
	const [mutatePost] = hooks.useMutation('quickOrderKey');
	const [fileName, setFileName] = useState('');
	const [errorList, setErrors] = useState([] as any);
	const [products, setProducts] = useState([] as any);
	const [isQtyValid, setIsQtyValid] = useState(false);
	const [totalProductCost, setTotalProductCost] = useState('0');
	const [venueUserId, setVenueUserId] = useState<any>(localStorage.getItem('userId'));
	const [venueId, setVenueId] = useState<any>(localStorage.getItem('selectedVenue'));
	const [state, dispatch] = UseGlobalState();
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);

	var template = constants.publicURL() + 'media/templates/quick_order.csv';
	var cartId: any;
	cartId = localStorage.getItem('cartId');

	const breadcrumbNav = [
		{ id: '01', label: 'Home', route: '/home/dashboard', active: false },
		{ id: '03', label: 'Quick Order', active: true },
	];

	//api to get total amount
	const url1 = '/carts/' + cartId + '/totals';

	const obj6: Partial<Iget> = {
		params: {},
		url: url1,
		enabled: false, //manual fetch
		key: 'carttotalKey1',
		resultFilteringRequired: false,
	};
	var qryTotal = hooks.useQuery(obj6);

	const exportOptions = {
		filename: 'export',
		fieldSeparator: ',',
		quoteStrings: '"',
		decimalSeparator: '.',
		showLabels: true,
		showTitle: false,
		title: 'Report',
		useTextFile: false,
		useBom: true,
		useKeysAsHeaders: true,
		// headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
	};

	// const csvExporter = new ExportToCsv(exportOptions);

	function updateCartTotal(data) {
		if (data) {
			let datas = data.items?.filter((x) => x.is_deleted != true);
			if (datas?.length > 0) {
				dispatch({ cartTotal: helperFunctions.formatAmount(data.grand_total) });
				dispatch({ cartItemCount: datas?.length });
				// setTotalCount(data.subtotal);
			} else {
				dispatch({ cartItemCount: '0' });
				dispatch({ cartTotal: '' });
				// setTotalCount(0);
			}
		} else {
			dispatch({ cartItemCount: '0' });
			dispatch({ cartTotal: '' });
			// setTotalCount(0);
		}
	}

	const onExport = () => {
		// csvExporter.generateCsv();
	};

	const getSelectedItemPrice = (price) => {
		setPrice(price);
	};

	const getSelectedItemQuantity = (qty) => {
		setQuantity(qty);
	};
	const getSelectedItemSubtotal = (subtotal) => {
		setSubTotal(subtotal);
	};
	const getSelectedSku = (sku, id) => {
		if (sku === '' || sku === undefined) {
			setIsQtyValid(false);
		} else {
			setIsQtyValid(true);
			setEnteredSku(sku);
			setEnteredPdtId(id);
		}
	};
	const disableAddButton = (value) => {
		setIsQtyValid(!value);
	};

	const searchItem = (item) => {
		setEnteredSku(item);
	};

	const onQuantityChange = (event) => {
		if (event.target.value > 10000) return false;
		if (
			event.target.value == '0' ||
			event.target.value == '' ||
			event.target.value == undefined ||
			enteredSku === ''
		) {
			setIsQtyValid(false);
			setQuantity(event.target.value);
			setSubTotal(price * event.target.value);
		} else {
			setIsQtyValid(true);
			setQuantity(event.target.value);
			setSubTotal(price * event.target.value);
		}
	};

	const addToGrid = () => {
		if (gridItems.length > 0) {
			const exists = gridItems.some((v) => v.sku === enteredSku);
			if (!exists) {
				let dataObj = { sku: '', qty: 0, price: '', subtotal: '', product_id: 0 };
				dataObj = {
					sku: enteredSku,
					qty: qty,
					price: price,
					subtotal: subTotal,
					product_id: pdtId,
				};
				gridItems.push(dataObj);
				AddGridItems(gridItems);
				const result = gridItems.reduce(
					(total, currentValue) => (total = parseInt(total) + parseInt(currentValue.subtotal)),
					0
				);
				setTotalProductCost(result);
				let productObj: any;
				productObj = { product_id: 0, qty: 0 };
				productObj = { product_id: pdtId, qty: parseInt(qty) };
				products.push(productObj);
				setProducts(products);

				setPrice('');
				setQuantity('');
				setSubTotal('');
				setEnteredSku('');
			} else {
				gridItems.forEach((obj3) => {
					if (obj3.sku === enteredSku) {
						obj3.qty = parseInt(obj3.qty) + parseInt(qty);
						obj3.subtotal = parseInt(obj3.qty) * parseInt(obj3.price);
					}
					AddGridItems(gridItems);
					const result = gridItems.reduce(
						(total, currentValue) => (total = parseInt(total) + parseInt(currentValue.subtotal)),
						0
					);
					setTotalProductCost(result);
					products.forEach((obj4) => {
						if (obj4.product_id === obj3.product_id) {
							obj4.qty = parseInt(obj3.qty);
							// productObj = { product_id: data.id, qty: parseInt(item.qty) };
							setProducts(() => [...products]);
							setPrice('');
							setQuantity('');
							setSubTotal('');
							setEnteredSku('');
						}
					});
				});
			}
			// })
		} else {
			let dataObj = { sku: '', qty: 0, price: '', subtotal: '', product_id: 0 };
			dataObj = { sku: enteredSku, qty: qty, price: price, subtotal: subTotal, product_id: pdtId };
			gridItems.push(dataObj);
			AddGridItems(gridItems);
			const result = gridItems.reduce(
				(total, currentValue) => (total = parseInt(total) + parseInt(currentValue.subtotal)),
				0
			);
			setTotalProductCost(result);
			let productObj: any;
			productObj = { product_id: 0, qty: 0 };
			productObj = { product_id: pdtId, qty: parseInt(qty) };
			products.push(productObj);
			setProducts(products);

			setPrice('');
			setQuantity('');
			setSubTotal('');
			setEnteredSku('');
		}
		setIsQtyValid(false);
	};

	const fetchTotal = () => {
		qryTotal.refetch().then((data) => {
			if (data) {
				updateCartTotal(data);
				// if (data?.items?.length > 0)
				// 	dispatch({ cartItemCount: data?.items.length });
			}
		});
	};

	async function AddGridItemsToCart() {
		setIsLoading(true);
		let id: any;
		let CUSTOMER_TOKEN = localStorage.getItem('customerToken');
		id = await utilityFunctions.getCartId(CUSTOMER_TOKEN);
		if (id !== undefined) {
			let addToCartJson = {
				products: {
					quote_id: parseInt(id),
					items: products,
					venue_user_id: parseInt(venueUserId),
					venue_id: parseInt(venueId),
				},
			};

			const obj: Partial<Ipost> = {
				data: addToCartJson,
				url: constants.multiCartURL(),
				message: 'Your cart has been updated!',
			};
			try {
				await mutatePost(obj).then((data) => {
					if (data !== undefined) {
						setIsLoading(false);
						clearGridItems();
						fetchTotal();
					}
					setIsLoading(false);
				});
			} catch (e) {
				setIsLoading(false);
			}
		}
	}

	const fetch = (list, importList) => {
		let url = urls.GetSearchUrl({
			url: '/products?',
			fields: [
				{
					field_name: 'sku',
					field_value: `${list}`,
					field_conditionType: 'in',
				},
				{
					field_name: 'venue_id',
					field_value: localStorage.getItem('selectedVenue')!,
					field_conditionType: 'eq',
				},
			],
		});
		if (list !== undefined) {
			api
				.get({
					url: url,
				})
				.then((data) => {
					if (data.items.length > 0) {
						data.items.forEach((data) => {
							let dataObj = { sku: '', qty: 0, price: '', subtotal: '', product_id: 0 };
							let productObj: any;
							productObj = { product_id: 0, qty: 0 };
							importList.forEach((obj) => {
								if (obj.sku === data.sku) {
									if (gridItems.length > 0) {
										const exists = gridItems.some((v) => v.sku === obj.sku);
										if (!exists) {
											dataObj = {
												sku: data.sku,
												qty: parseInt(obj.qty),
												price: data.price,
												subtotal: (data.price * parseInt(obj.qty)).toString(),
												product_id: data.id,
											};
											gridItems.push(dataObj);
											const result = gridItems.reduce(
												(total, currentValue) =>
													(total = parseInt(total) + parseInt(currentValue.subtotal)),
												0
											);
											setTotalProductCost(result);
											productObj = { product_id: data.id, qty: parseInt(obj.qty) };
											products.push(productObj);
										} else {
											gridItems.forEach((item) => {
												if (item.sku === obj.sku) {
													item.qty = parseInt(item.qty) + parseInt(obj.qty);
													item.subtotal = parseInt(item.qty) * parseInt(item.price);
													const result = gridItems.reduce(
														(total, currentValue) =>
															(total = parseInt(total) + parseInt(currentValue.subtotal)),
														0
													);
													setTotalProductCost(result);
													AddGridItems(() => [...gridItems]);
													products.forEach((item2) => {
														if (item2.product_id === item.product_id) {
															item2.qty = item.qty;
															// productObj = { product_id: data.id, qty: parseInt(item.qty) };
															setProducts(() => [...products]);
														}
													});
												}
											});
										}
									} else {
										dataObj = {
											sku: data.sku,
											qty: parseInt(obj.qty),
											price: data.price,
											subtotal: (data.price * parseInt(obj.qty)).toString(),
											product_id: data.id,
										};
										gridItems.push(dataObj);
										const result = gridItems.reduce(
											(total, currentValue) =>
												(total = parseInt(total) + parseInt(currentValue.subtotal)),
											0
										);
										setTotalProductCost(result);
										productObj = { product_id: data.id, qty: parseInt(obj.qty) };
										products.push(productObj);
									}
									AddGridItems(() => [...gridItems]);
									setProducts(() => [...products]);
								}
							});
						});
					}
				});
		}
	};

	function submitData(errors, data, fileName) {
		setFileName(fileName);
		if (errors.length == 0 && data?.length > 0) {
			// let dataObj = { sku: '', qty: 0, price: '', subtotal: '', product_id: 0 };
			let list = data.map((s) => s.sku);
			fetch(list, data);
		} else if (errors.length > 0) {
			setErrors(errors);
			// setHasChange(true);
		}
		setIsLoading(false);
	}
	const clearGridItems = () => {
		AddGridItems(() => []);
		setProducts(() => []);
		setFileName('');
		setTotalProductCost('0');
	};

	const removeItem = (data) => {
		let index1 = gridItems.findIndex((x) => x.product_id === data.product_id);
		let index2 = products.findIndex((x) => x.product_id === data.product_id);
		gridItems.splice(index1, 1);
		products.splice(index2, 1);
		AddGridItems(() => [...gridItems]);
		setProducts(() => [...products]);
		const result = gridItems.reduce(
			(total, currentValue) => (total = parseInt(total) + parseInt(currentValue.subtotal)),
			0
		);
		setTotalProductCost(result);
		if (gridItems.length === 0) {
			setFileName('');
		}
	};
	const closeFile = () => {
		setFileName('');
	};
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	}
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={() => onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={() => onclickHambrgBtn()}></Sidebar>
			{isLoading == true ? <Loader></Loader> : null}
			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbHolder>
					<BreadcrumbNav Heading="Quick Order" breadcrumbList={breadcrumbNav} />
					<ButtonWrapper>
						{fileName !== '' ? (
							<Button flex={[1, 1, 'initial']} className="app-c-csv-uploaded">
								<Box>
									<Label>CSV Uploaded</Label>
									<p>{fileName}</p>
								</Box>
								<Button onClick={closeFile}>
									<icons.CloseIcon />
								</Button>
							</Button>
						) : (
							''
						)}

						<Button variant="secondary" className="app-c-file-upload">
							<div>
								<icons.bulkUploadIcon />

								<input
									type="file"
									accept=".csv"
									onChange={(e) => {
										setIsLoading(true);
										fileUploadHandler(e, submitData);
									}}
								/>
								<label>Upload CSV</label>
							</div>

							{/* {fileName && <Text as="h2">{fileName}</Text>} */}
						</Button>
						<a href={template} target="_blank" style={{ textDecoration: 'none' }}>
							<Button variant="secondary" flex={[1, 1, 'initial']} onClick={onExport}>
								<icons.downloadIcon />
								<div>Download Sample</div>
							</Button>
						</a>
						<Button variant="secondary" onClick={clearGridItems}>
							<icons.clearAllIcon />
							<div>Clear All</div>
						</Button>
						{utilityFunctions.isGranted(venueUserPermissionList.ManageProductsinCart) && (
							<Button
								variant="primary"
								disabled={gridItems.length === 0}
								onClick={AddGridItemsToCart}
							>
								<icons.cartIcon />

								<div>Move To Cart</div>
							</Button>
						)}
					</ButtonWrapper>
				</BreadcrumbHolder>
				<TableList>
					<table>
						<thead>
							<tr>
								<th>
									SKU
									<Box>
										<SearchAutoSuggestionSKU
											searchItem={searchItem}
											skuValue={enteredSku}
											getSelectedItemPrice={getSelectedItemPrice}
											getSelectedSku={getSelectedSku}
											getSelectedItemSubtotal={getSelectedItemSubtotal}
											getSelectedItemQuantity={getSelectedItemQuantity}
											disableAddButton={disableAddButton}
										></SearchAutoSuggestionSKU>
									</Box>
								</th>
								<th>
									Quantity
									<Box>
										<Input
											id="text"
											name="text"
											type="number"
											value={qty}
											placeholder="Enter Quantity"
											onChange={onQuantityChange}
										/>
									</Box>
								</th>
								<th>
									Price
									<Box>
										<Input
											id="text"
											name="text"
											type="text"
											value={price !== '' ? helperFunctions.formatAmount(price) : ''}
											disabled
											placeholder=""
										/>
									</Box>
								</th>
								<th>
									Subtotal
									<Box>
										<Input
											id="text"
											name="text"
											type="text"
											value={subTotal !== '' ? helperFunctions.formatAmount(subTotal) : ''}
											disabled
											placeholder=""
										/>
									</Box>
								</th>
								<th className="action">
									Action
									<Box>
										<Button disabled={!isQtyValid} onClick={addToGrid}>
											Add
										</Button>
									</Box>
								</th>
							</tr>
						</thead>

						<tbody>
							{gridItems &&
								gridItems.map((data) => (
									<tr key={data.product_id}>
										<td data-label="SKU">
											<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
												<strong>{data.sku}</strong>
											</Text>
										</td>
										<td data-label="QUANTITY">
											<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
												{data.qty}
											</Text>
										</td>
										<td data-label="PRICE">
											<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
												{helperFunctions.formatAmount(data.price)}
											</Text>
										</td>
										<td data-label="SUBTOTAL">
											<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
												{helperFunctions.formatAmount(data.subtotal)}
											</Text>
										</td>
										<td className="action" data-label="Action">
											<Text>
												<Button variant="deleteIcon" onClick={() => removeItem(data)}>
													<icons.DeleteIcon />
												</Button>
											</Text>
										</td>
									</tr>
								))}
						</tbody>
					</table>
					<TotalCostBlock>
						<Text
							as="h1"
							fontSize="20px"
							fontFamily="OpenSansRegular"
							className="truncate"
							lineHeight="22px"
							color="text.black"
						>
							{/* Total Cost <strong>${parseFloat(totalProductCost).toFixed(2)}</strong> */}
							Total Cost <strong>{helperFunctions.formatAmount(totalProductCost)}</strong>
						</Text>
					</TotalCostBlock>
				</TableList>
				<Box></Box>
			</MainContent>
			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default SKUList;
