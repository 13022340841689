import { condition } from 'models/enum-constants/common.constants';
import urlObj from 'models/url.obj';
import moment from 'moment';
import queryString from 'query-string';
function GetSearchUrl(obj: Partial<urlObj>) {
	let index = 0;
	obj.field_conditionType =
		typeof obj.field_conditionType == 'undefined' || obj.field_conditionType == ''
			? condition.eq
			: obj.field_conditionType;
	let url: string = obj.url!;

	if (typeof obj.fields != 'undefined' && obj.fields?.length > 0) {
		if (obj.productSearch === 'true') {
			obj.fields.map((v, i) => {
				if (v.field_name === 'name') {
					let indexOpt1 = 1;
					const field_value =
						v.field_conditionType === condition.like ? `${v.field_value.trim()}%25` : v.field_value;
					url += `&${getOrFilterPdtListing(
						v.field_name,
						field_value,
						typeof v.field_conditionType == 'undefined' ? condition.eq : v.field_conditionType,
						index,
						indexOpt1
					)}`;
					indexOpt1++;
					const field_value2 =
						v.field_conditionType === condition.like
							? `%25 ${v.field_value.trim()}%25`
							: v.field_value;
					url += `&${getOrFilterPdtListing(
						v.field_name,
						field_value2,
						typeof v.field_conditionType == 'undefined' ? condition.eq : v.field_conditionType,
						index,
						indexOpt1
					)}`;
					index++;
					indexOpt1++;
				} else {
					const field_value =
						v.field_conditionType === condition.like ? `%25${v.field_value}%25` : v.field_value;
					url += `&${getAndFilter(
						v.field_name,
						field_value,
						typeof v.field_conditionType == 'undefined' ? condition.eq : v.field_conditionType,
						index
					)}`;
					index++;
				}
			});
		} else {
			obj.fields.map((v, i) => {
				const field_value =
					v.field_conditionType === condition.like ? `%25${v.field_value}%25` : v.field_value;
				url += `&${getAndFilter(
					v.field_name,
					field_value,
					typeof v.field_conditionType == 'undefined' ? condition.eq : v.field_conditionType,
					index
				)}`;
				index++;
			});
		}
	}
	if (typeof obj.fieldsOpt != 'undefined' && obj.fieldsOpt?.length > 0) {
		let indexOpt = 0;
		let equConditionCheck = false;
		obj.fieldsOpt.map((v, i) => {
			if (obj.orderConditionalFilter) {
				if (equConditionCheck && v.field_conditionType === condition.like) {
					index++;
					equConditionCheck = false;
					indexOpt = 0;
				}
				const field_value =
					v.field_conditionType === condition.like ? `%25${v.field_value}%25` : v.field_value;
				url += `&${getAndOrFilterOpt(
					v.field_name,
					field_value,
					typeof v.field_conditionType == 'undefined' ? condition.eq : v.field_conditionType,
					index,
					indexOpt
				)}`;
				if (v.field_conditionType === condition.eq) equConditionCheck = true;
			} else {
				const field_value =
					v.field_conditionType === condition.like ? `%25${v.field_value}%25` : v.field_value;
				url += `&${getOrFilterOpt(
					v.field_name,
					field_value,
					typeof v.field_conditionType == 'undefined' ? condition.eq : v.field_conditionType,
					indexOpt
				)}`;
			}
			indexOpt++;
		});
		if (obj.orderConditionalFilter) index++;
	}
	if (obj.ctx && obj.ctx.Allergens && typeof obj.ctx.Allergens != 'undefined') {
		url += `&${getOrFilter('allergens', obj.ctx.Allergens.split(','), condition.finset, index)}`;
		index++;
	}
	url += getSortFilter(obj.ctx);
	if (obj.filterDeleted == true) {
		url += `&${getAndFilter('is_delete', 0, condition.eq, index)}`;
		index++;
	}
	if (obj.filterInactive == true) {
		url += `&${getAndFilter('is_active', 1, condition.eq, index)}`;
		index++;
	}
	if (obj.showActiveProductsOnly == true) {
		url += `&${getAndFilter('status', 1, condition.eq, index)}`;
		index++;
	}
	if (obj.pageSize) url += `&searchCriteria[pageSize]=${obj.pageSize}`;
	if (obj.ctx && obj.ctx.currentPage && obj.ctx.Type == '')
		url += `&searchCriteria[currentPage]=${obj.ctx.currentPage}`;
	return url;
}

const getAndFilter = (field, val, conditionType, index) => {
	return `searchCriteria[filter_groups][${index}][filters][0][field]=${field}&searchCriteria[filter_groups][${index}][filters][0][value]=${String(
		val
	).trim()}&searchCriteria[filter_groups][${index}][filters][0][condition_type]=${conditionType}`;
};
const getOrFilterOpt = (field, val, conditionType, index) => {
	return `searchCriteria[filter_groups][${index}][filters][1][field]=${field}&searchCriteria[filter_groups][${index}][filters][1][value]=${String(
		val
	).trim()}&searchCriteria[filter_groups][${index}][filters][1][condition_type]=${conditionType}`;
};

const getAndOrFilterOpt = (field, val, conditionType, index, indexOpt) => {
	return `searchCriteria[filter_groups][${index}][filters][${indexOpt}][field]=${field}&searchCriteria[filter_groups][${index}][filters][${indexOpt}][value]=${String(
		val
	).trim()}&searchCriteria[filter_groups][${index}][filters][${indexOpt}][condition_type]=${conditionType}`;
};

const getOrFilterPdtListing = (field, val, conditionType, index, indexOpt) => {
	return `searchCriteria[filter_groups][${index}][filters][${indexOpt}][field]=${field}&searchCriteria[filter_groups][${index}][filters][${indexOpt}][value]=${String(
		val
	).trim()}&searchCriteria[filter_groups][${index}][filters][${indexOpt}][condition_type]=${conditionType}`;
};
const getOrFilter = (filed, arr, conditionType, index) => {
	let qry = '';
	for (let _i = 0; _i < arr.length; _i++) {
		const val = arr[_i];
		qry += `searchCriteria[filter_groups][${index}][filters][${_i}][field]=${filed}&searchCriteria[filter_groups][${index}][filters][${_i}][value]=${val}&searchCriteria[filter_groups][${index}][filters][${_i}][condition_type]=${conditionType}&`;
	}
	return qry.slice(0, -1);
};

function getSortFilter(ctx) {
	if (ctx && ctx.SortDirection) {
		return `&searchCriteria[sortOrders][0][field]=${ctx.SortField}&searchCriteria[sortOrders][0][direction]=${ctx.SortDirection}`;
	} else if (
		(ctx && (typeof ctx.avoidSort == 'undefined' || ctx.avoidSort != true)) ||
		typeof ctx == 'undefined'
	) {
		return `&searchCriteria[sortOrders][0][field]=created_at&searchCriteria[sortOrders][0][direction]=desc`;
	} else return '';
}

/* function to generate graphql query*/
function GetQuery(obj: Partial<urlObj>) {
	return `{products(
    search: "${getSearchQuery(obj)}"
     filter: ${getFilterQuery(obj)}
     sort : ${getSortQuery(obj)}
	 pageSize : ${obj.pageSize}
	 ,venueId: ${localStorage.getItem('selectedVenue')!}
  ){
    aggregations {
      attribute_code
      count
      label
      options {
        label
        value
        count
}
    }
  }}`;
}

/* function to generate graphql query*/
function GetLocationQuery(obj: Partial<urlObj>) {
	return `{productsSearch(
     filter: ${getFilterQuery(obj)}
  ){
	items {
		id
		name
		sku		
	}
	total_count
	page_info {
		page_size
		current_page
	}
  }}`;
}

function getSearchQuery(obj) {
	return obj.searchText ? obj.searchText : '';
}

function getFilterQuery(obj: Partial<urlObj>) {
	let filterQry = '{';
	if (obj.ctx && obj.ctx.Categories && typeof obj.ctx.Categories != 'undefined') {
		const categories = getArray(obj.ctx.Categories);
		filterQry += `category_id: {in: [${categories}]}`;
	}
	if (obj.ctx && obj.ctx.Suppliers && typeof obj.ctx.Suppliers != 'undefined') {
		const suppliers = getArray(obj.ctx.Suppliers);
		filterQry += `supply_company_name: {in: [${suppliers}]}`;
	}
	if (obj.ctx && obj.ctx.Allergens && typeof obj.ctx.Allergens != 'undefined') {
		const allergens = getArray(obj.ctx.Allergens);
		filterQry += `allergens: {in : [${allergens}]}`;
	}
	if (obj.ctx && (obj.ctx.PriceFrom || obj.ctx.PriceTo)) {
		filterQry += `price: {from: "${obj.ctx.PriceFrom! > 0 ? obj.ctx.PriceFrom : ''}",
     to: "${obj.ctx.PriceTo! > 0 ? obj.ctx.PriceTo : ''}"}`;
	}

	if (obj.ctx && obj.ctx.location && typeof obj.ctx.location != 'undefined') {
		const parsed = queryString.parse(window.location.search);
		if (parsed.lat && parsed.long) {
			const radius = parsed.radius;

			const locationData = { lat: parsed.lat, long: parsed.long };
			filterQry += `user_latitude: {in : [${locationData.lat}]} `;
			filterQry += `user_longitude: {in : [${locationData.long}]} `;
			filterQry += `radius: {in : ["${radius}"]}`;
		}
	}

	// if (obj.ctx && obj.ctx.newArrivalDate && typeof obj.ctx.newArrivalDate != 'undefined') {
	// 	const newArrivalDate = getArray(obj.ctx.newArrivalDate);
	// 	filterQry += `created_at: {gteq : ${newArrivalDate}}`;
	// }
	// if (obj.ctx && obj.ctx.skuList && typeof obj.ctx.skuList != 'undefined') {
	// 	const skuList = getArray(obj.ctx.skuList);
	// 	filterQry += `sku: {in : [${skuList}]}`;
	// }
	// if (obj.ctx && obj.ctx.specialProductsView && typeof obj.ctx.specialProductsView != 'undefined') {
	// 	filterQry += `special_price: {gt : ${0}},`;
	// 	filterQry += `special_from_date: {lteq : ${String(moment(new Date()).format('YYYY-MM-DD'))}},`;
	// 	filterQry += `special_to_date: {gteq : ${String(moment(new Date()).format('YYYY-MM-DD'))}}`;
	// }
	filterQry += '}';

	return filterQry;
}

function getArray(val: string) {
	const arr = val.split(',');
	let str = '';
	arr.map((x) => {
		str += `"${x}",`;
	});
	return str.slice(0, -1);
}

function getSortQuery(obj) {
	let sortQry = '{';
	if (obj.ctx && obj.ctx.SortDirection)
		sortQry += `${obj.ctx.SortField}: ${obj.ctx.SortDirection.toUpperCase()}`;
	sortQry += '}';
	return sortQry;
}
export default { GetSearchUrl, GetQuery, GetLocationQuery };
