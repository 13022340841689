import React, { ReactElement } from 'react';
import { Box, Flex, Text, Image } from 'rebass/styled-components';
import styled, { css } from 'styled-components';
import nodataImage from '../../images/Nodata.svg';
import Skeleton from 'react-loading-skeleton';
import no_image from 'ui/images/no_image_list.png';
import { useHistory } from 'react-router-dom';

const StyledSpecialSaleProducts = styled.div`
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	column-gap: 20px;
	row-gap: 20px;
	@media (max-width: 1366px) {
		grid-template-columns: repeat(4, 1fr) !important;
		column-gap: 20px;
	}
	@media (max-width: 1152px) {
		grid-template-columns: repeat(3, 1fr) !important;
		column-gap: 20px;
	}
	@media (max-width: 860px) {
		grid-template-columns: repeat(2, 1fr) !important;
		column-gap: 15px;
	}
	@media (max-width: 640px) {
		grid-template-columns: repeat(1, 1fr) !important;
		column-gap: 15px;
	}
`;
const SpecialSaleProductsBlock = styled.div<SpecialSaleProductsBlockProps>`
	flex-direction: column;
	background: ${({ theme }) => theme.colors.bg.white};
	border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
	box-sizing: border-box;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 16px;
	overflow: hidden;
	transition: box-shadow 100ms ease-in, transform 100ms ease-in;
	transition: all 0.25s ease-in-out;
	cursor: pointer;
	&:hover {
		transform: translateY(-5px);
		box-shadow: 0 20px 20px #eee;
		border: 1px solid ${({ theme }) => theme.baseColors.baseYellowLight};
	}
	span {
		width: 100%;
		height: 200px;
		background-color: #faf4ea;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
	}
	section {
		width: 100%;
		display: flex;
		align-items: center;
		padding: 15px;
		h1,
		h2,
		h3,
		h4 {
			font-weight: normal;
		}

		h2 {
			margin-bottom: 11px;
		}
		h4 {
			position: relative;
			margin: 0 5px 0 13px;
			padding: 0 3px;
			&::after {
				content: '';
				position: absolute;
				border: 1px solid ${({ theme }) => theme.colors.text.error01};
				width: 100%;
				height: 1px;
				top: 12px;
				left: 0;
			}
		}
	}
	${({ active }) =>
		!active &&
		css`
			& ${ListItemLabel} {
				background: #e4b23a;
				border: 1px solid #e4b23a;
				&:before {
					content: 'Processing';
				}
			}
		`}
`;
const ListItemLabel = styled.div`
	width: 87px;
	height: 30px;
	background: #7fa495;
	border: 1px solid #7fa495;
	box-sizing: border-box;
	border-radius: 15px;
	display: flex;
	align-items: center;
	justify-content: center;

	&:before {
		content: 'Delivered';
		font-size: 13px;
		line-height: 17px;
		font-family: 'OpenSansSemiBold';
		color: #ffffff;
	}
`;
const NodataFound = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 250px;
	grid-column: 6 / 1;
	img {
		margin: 0;
		height: 120px;
	}
	h1 {
		text-align: center;
		margin-top: 15px;
	}
`;
type SpecialSaleProductsBlockProps = {
	active?: boolean;
};

function SpecialSaleProducts({ specialSaleProducts }): ReactElement {
	const history = useHistory();
	return (
		<StyledSpecialSaleProducts>
			{specialSaleProducts && specialSaleProducts.length > 0 ? (
				specialSaleProducts.map((item) => {
					let img = '';
					if (item.media_gallery_entries && item.media_gallery_entries.length > 0)
						img = `${item.media_gallery_entries[0].file}`;
					else img = no_image;
					return (
						<SpecialSaleProductsBlock
							key={item.id}
							onClick={(e) => {
								e.stopPropagation();
								history.push({
									pathname: '/home/product-details',
									search: '?id=' + item.sku,
								});
							}}
						>
							<Flex
								alignItems="center"
								as="span"
								maxWidth={['100%']}
								sx={{ overflow: 'hidden' }}
								style={{
									backgroundImage: 'url(' + img + ')',
								}}
							></Flex>
							<Flex alignItems="center" as="section" maxWidth={['100%']}>
								<Box flex={1}>
									<Text
										as="h1"
										fontFamily="OpenSansBold"
										fontSize="18px"
										lineHeight="23px"
										color="text.black"
										className="truncate"
									>
										{item.name}
									</Text>
									<Text
										as="h2"
										fontSize="16px"
										lineHeight="21px"
										color="text.black"
										fontFamily="OpenSansRegular"
										className="truncate"
									>
										{item.extension_attributes?.supplier_name}
									</Text>
									<Flex alignItems="baseline">
										<Text
											as="h3"
											fontSize="20px"
											// fontFamily="OpenSansBlack"
											color="text.black"
											className="truncate"
											lineHeight="26px"
										>
											<strong>
												$
												{parseFloat(
													item.extension_attributes?.multi_seller_product_special_price
												).toFixed(2)}
											</strong>{' '}
											{item.extension_attributes?.uomname}
										</Text>
										{/* <Text
											as="h4"
											fontSize="16px"
											fontFamily="OpenSansBold"
											color="text.black"
											className="truncate"
											lineHeight="26px"
										>
											${item.Discount}
										</Text> */}
										{/* <Text
											as="h5"
											fontSize="16px"
											fontFamily="OpenSansBold"
											color="text.black"
											className="truncate"
											lineHeight="26px"
										>
											( {item.OffPercentage} % Off )
										</Text> */}
									</Flex>
								</Box>
							</Flex>
						</SpecialSaleProductsBlock>
					);
				})
			) : specialSaleProducts && specialSaleProducts.length == 0 ? (
				<NodataFound>
					<Box>
						<Image
							style={{ margin: 'auto' }}
							src={nodataImage}
							sx={{
								width: 130,
								height: 130,
							}}
						/>
						<Text as="h1" fontFamily="OpenSansBold" fontSize={18} color="text.black">
							No products found!
						</Text>
					</Box>
				</NodataFound>
			) : (
				new Array(5).fill(null).map((_, i) => {
					return <Skeleton key={i} width="200px" height="200px" />;
				})
			)}
		</StyledSpecialSaleProducts>
	);
}

export { SpecialSaleProducts };
