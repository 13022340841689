import Iget from 'models/Iget';
import queryString from 'query-string';
import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Text } from 'rebass/styled-components';
import { BreadcrumbNav, Button, Footer, Header, Loader } from 'ui/components';
import { DeliveryConfirmationsOrderInfo, DeliveryOrderInfo, Sidebar } from 'ui/patterns';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import {
	BreadcrumbNavHolder,
	MainContent,
	ParametersSpecifiedHolder,
	TotalCostBlock,
	ButtonWrapper,
} from './styles';

function DeliveryConfirmationsInner(): ReactElement {
	const location = useLocation();
	const parsed = queryString.parse(location.search);
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const [deliveryConfirmationsOrderInfo, setDeliveryConfirmationsOrderInfo] = useState<any>({});
	const [showInfo, setShowInfo] = useState(true);
	var venueUserId: any;
	venueUserId = localStorage.getItem('userId');

	const history = useHistory();
	const breadcrumbNav = [
		{ id: '01', label: 'Home', route: '/home/dashboard', active: false },
		{ id: '02', label: 'Dashboard', route: '/admin/user-dashboard', active: false },
		{
			id: '03',
			label: 'Delivery Confirmation',
			route: '/admin/deliveryConfirmations',
			active: false,
		},
		{
			id: '04',
			label:
				'Order ID ' +
				`${deliveryConfirmationsOrderInfo?.extension_attributes?.order_group_increment_id}`,
			active: true,
		},
	];

	const url1 = '/orders/' + parsed.id;
	const obj1: Partial<Iget> = {
		params: {},
		url: url1,
		enabled: true,
		key: 'orderFav',
		resultFilteringRequired: false,
	};
	var qry = hooks.useQuery(obj1);

	// useEffect(() => {
	// 	if (parsed.id) {
	// 		http.GET({
	// 			url: urls.GetSearchUrl({
	// 				url: 'orders?',
	// 				field_name: 'group_order_id', field_value: parsed.id
	// 			})
	// 		})
	// 			.then(function (d) {
	// 				if (d.data?.items?.length > 0) {
	// 					setDeliveryConfirmationsOrderInfo(d.data?.items);
	// 				}
	// 			})
	// 	};
	// }, [location]);

	useEffect(() => {
		setDeliveryConfirmationsOrderInfo(qry.data);
	}, [qry.data]);

	const updateStatus = () => {
		let setData = [] as any;
		qry.data?.items?.forEach((data) => {
			let dataObj = {
				product_id: data.product_id,
				ordered_qty: data.qty_ordered,
				delivered_qty: data.qty_ordered,
				comment: '',
			};
			setData.push(dataObj);
		});

		const payload = {
			delivery: {
				order_id: parseInt(parsed.id),
				dc_id: deliveryConfirmationsOrderInfo.extension_attributes.dc_id,
				venue_user_id: parseInt(venueUserId),
				delivery_product: setData,
				is_delivery_in_full: true,
				is_signed: true,
				is_active: true,
				product_id: 0,
				ordered_qty: 0,
				delivered_qty: 0,
				comment: '',
			},
		};
		api
			.post({
				data: payload,
				url: '/experion/deliveryconfirmation/create',
				key: 'placeOrder',
				message: 'Order Delivery Confirmed',
			})
			.then(function (data) {
				if (data) {
					history.push('/admin/deliveryConfirmations');
				}
			});
	};
	const itemIssueTrack = () => {
		history.push('/admin/deliveryConfirmationEnterProblem?id=' + parsed.id);
	};
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	}
	const navigateToReview = (supplier_id, review_id) => {
		if (review_id && Number(review_id) > 0)
			history.push('/admin/sellers-review?id=' + supplier_id + '&reviewId=' + review_id + '&type=delivery' + '&deliveryId=' + parsed.id)
		else
			history.push('/admin/sellers-review?id=' + supplier_id + '&type=delivery' + '&deliveryId=' + parsed.id)
	}
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={() => onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={() => onclickHambrgBtn()}></Sidebar>
			{qry.isLoading || qry.isFetching ? <Loader></Loader> : null}
			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbNavHolder>
					<BreadcrumbNav
						Heading={
							'Order ID ' +
							deliveryConfirmationsOrderInfo?.extension_attributes?.order_group_increment_id
						}
						breadcrumbList={breadcrumbNav}
					/>
					<ButtonWrapper>
						<Button flex={[1, 1, 'initial']} onClick={() => navigateToReview(deliveryConfirmationsOrderInfo?.extension_attributes?.supplier_id, deliveryConfirmationsOrderInfo?.extension_attributes?.review_id)}>
							<div>Write a review</div>
						</Button>
					</ButtonWrapper>
					{/* <SortBuy>
						<Icon.SortIcon />
						<Select
							id="country"
							name="country"
							defaultValue="United States"
							className="PageSearchCategory"
						>
							<option>Popularity</option>
							<option>Low - High Price</option>
							<option>High - Low Price</option>
							<option>A - Z Order</option>
							<option>Z - A Order</option>
						</Select>
					</SortBuy> */}
				</BreadcrumbNavHolder>
				<DeliveryConfirmationsOrderInfo
					deliveryConfirmationsOrderInfo={deliveryConfirmationsOrderInfo}
				/>
				{showInfo === true ? (
					<ParametersSpecifiedHolder>
						<Text as="h1" fontSize={17} color="text.black" fontFamily="OpenSansMedium">
							Was the delivered according to the delivery requirements and parameters specified ?
						</Text>
						<Button onClick={updateStatus}>Yes</Button>
						<Button onClick={itemIssueTrack} variant="secondary">
							No
						</Button>
					</ParametersSpecifiedHolder>
				) : null}

				<DeliveryOrderInfo deliveryOrderInfoList={deliveryConfirmationsOrderInfo} />
				{/* <AddCommentsHolder>
					<Input
						id="email"
						name="email"
						type="email"
						placeholder="Add Comments"
						autoCorrect="off"
					/>
					<Button variant="secondary">Cancel</Button>

					<Button>Submit</Button>
				</AddCommentsHolder> */}
				<TotalCostBlock>
					<Text
						as="h1"
						fontSize="20px"
						fontFamily="OpenSansRegular"
						className="truncate"
						lineHeight="22px"
						color="text.black"
					>
						Total Cost <strong>${parseFloat(deliveryConfirmationsOrderInfo?.grand_total ? deliveryConfirmationsOrderInfo?.grand_total : 0).toFixed(2)}</strong>
					</Text>
				</TotalCostBlock>
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default DeliveryConfirmationsInner;
