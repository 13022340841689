import styled from 'styled-components';

const MainContent = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
	padding: 0 50px;
	@media (max-width: 960px) {
		padding: 0 15px;
	}
`;
const ContentHolder = styled.div`
	height: 100%;
	padding: 0 50px;
	padding-bottom: 30px;
	width: 100%;

	@media (max-width: 768px) {
		padding: 0 15px;
	}
`;
const FooterBtnBlock = styled.div`
	padding: 0 0 58px;
	button {
		min-width: 100px;
		text-transform: capitalize;
		padding: 0;
		height: 48px;
		flex: 0 0 100px;
	}
	@media screen and (max-width: 1300px) {
		padding: 50px 0 58px;
	}
	@media (max-width: 768px) {
		padding: 30px 0px;
		button {
			flex: 1;
			min-width: fit-content;
		}
	}
`;
const ProductImageHolder = styled.div`
	width: 100%;
	display: flex;
	margin: 23px 0 73px 0;
	h2 {
		margin-bottom: 20px;
	}
	@media (max-width: 860px) {
		flex-direction: column;
	}
`;
const TabContentHolder = styled.div`
	width: 100%;
	display: flex;
	margin-top: 50px;
`;
const BreadcrumbHolder = styled.div`

`;
const AllergenNameSection = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 53px 0px 40px;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	@media (max-width: 767px) {
		padding: 40px 0px 0px;
		margin-top: -36px;
	}
`;

const AddProduct = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 53px 0px 0px;
	@media (max-width: 767px) {
		padding: 0 0px 0px;


	}
`;

export {
	MainContent,
	ContentHolder,
	ProductImageHolder,
	TabContentHolder,
	BreadcrumbHolder,
	AllergenNameSection,
	AddProduct,
	FooterBtnBlock,
};
