 
import React, { ReactElement } from 'react'; 
import { Box, Button, Flex, Text } from 'rebass/styled-components';
import { ReviewContent } from "./styles";
function ReviewVenue({data}): ReactElement {
 
	return (
		<ReviewContent>
		  <Box as="h1">
			  Review
		  </Box>
		  <Box className="address">
				<Text as="div" className="full-name">
				{data.venue_user_name}
				</Text>
				<Text as="div"> 
				{data.venue_name} 
				</Text>
		  </Box>
		  <Box className="review">
		  		{data.review}
		  </Box>
		  {/* <Button className="action-done"
									flex={[1, 1, 'initial']}
									variant="secondary" 
								>
									Done
								</Button> */}
		</ReviewContent>
	);
}

export default ReviewVenue;
