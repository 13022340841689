import React, { ReactElement, ReactNode } from 'react';
import { Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { flex, FlexProps, layout, LayoutProps, space, SpaceProps } from 'styled-system';
import { Button } from 'ui/components';
import { Stepper } from 'ui/patterns';

type ProductControlWrapProps = FlexProps & SpaceProps & LayoutProps;

const ProductControlWrap = styled.div<ProductControlWrapProps>`
	${flex}
	${space}
	${layout}
	display: flex;

	/* @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
		max-width: 220px;
	} */
`;

const ProductDetail = styled.div`
	padding: ${({ theme }) => theme.space[3]} ${({ theme }) => theme.space[4]};
	position: relative;
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #ecb136;
	border-right: dashed 1px #fff;
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;

	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: -12px;
		transform: translateY(-50%);
		width: 24px;
		height: 24px;
		border-radius: 50%;
		background-color: #fff;
	}
`;

const ProductCount = styled.div`
	padding: ${({ theme }) => theme.space[3]} ${({ theme }) => theme.space[4]};
	position: relative;
	flex: 3;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
	background: #f7f4ed;
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;

	&::before {
		content: '';
		position: absolute;
		top: 50%;
		right: -12px;
		transform: translateY(-50%);
		width: 24px;
		height: 24px;
		border-radius: 50%;
		background-color: #fff;
	}

	& > * + * {
		margin-top: 10px;
	}
`;

type styledsystemProps = FlexProps & SpaceProps & LayoutProps;

type ProductControlProps = styledsystemProps & {
	product?: string;
	icon?: ReactNode;
	buttonLabel?: string;
};

function ProductControl({
	product,
	icon,
	buttonLabel,
	...styledsystemProps
}: ProductControlProps): ReactElement {
	return (
		<ProductControlWrap {...styledsystemProps}>
			<ProductDetail>
				{icon}
				<Text color="#fff" fontFamily="OpenSansBold" fontSize="14px">
					{product}
				</Text>
			</ProductDetail>
			<ProductCount>
				<Stepper />
				<Button color="#ECB136" py="0.8em">
					{buttonLabel}
				</Button>
			</ProductCount>
		</ProductControlWrap>
	);
}

export { ProductControl };
