import React, { ReactElement, ReactNode } from 'react';
import { Text } from 'rebass/styled-components';
import styled from 'styled-components';

const StyledPageHeader = styled.div`
	--px: ${({ theme }) => theme.space[3]};
	--py: ${({ theme }) => theme.space[1]};
	padding: var(--py) var(--px);
	display: flex;
	background: ${({ theme }) => theme.colors.white};
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
`;

const Count = styled.small`
	display: inline-block;
	margin-left: ${({ theme }) => theme.space[0]};
	padding: ${({ theme }) => theme.space[0]} ${({ theme }) => theme.space[1]};
	background-color: ${({ theme }) => theme.colors.context.danger.medium};
	color: ${({ theme }) => theme.colors.context.danger.light};
	border-radius: ${({ theme }) => theme.radii.round};
	line-height: 1;
`;

type PageHeaderProps = {
	children?: ReactNode;
	title?: string;
	count?: number;
};

function PageHeader({ title, count, children }: PageHeaderProps): ReactElement {
	return (
		<StyledPageHeader>
			<Text as="h4" fontWeight="medium" lineHeight="1">
				{title} <Count>{count}</Count>
			</Text>
			{children}
		</StyledPageHeader>
	);
}

export { PageHeader };
