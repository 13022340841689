import styled from 'styled-components';
const StyledTodaysOffer = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	column-gap: 20px;
	row-gap: 20px;
	@media (max-width: 1700px) {
		grid-template-columns: repeat(3, 1fr) !important;
		column-gap: 20px;
	}
	@media (max-width: 1300px) {
		grid-template-columns: repeat(2, 1fr) !important;
		column-gap: 20px;
	}
	@media (max-width: 900px) {
		grid-template-columns: repeat(1, 1fr) !important;
		column-gap: 15px;
	}
	article {
		flex-direction: row;
		background: ${({ theme }) => theme.colors.bg.white};
		border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
		box-sizing: border-box;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 16px;
		overflow: hidden;
		transition: box-shadow 100ms ease-in, transform 100ms ease-in;
		cursor: pointer;
		&:hover {
			transform: translateY(-5px);
			box-shadow: 0 20px 20px #eee;
			border: 1px solid ${({ theme }) => theme.baseColors.baseYellowLight};
		}
		span {
			max-width: 177px;
			flex: 0 0 177px;
			height: 230px;
			background-repeat: no-repeat;
			background-size: cover;
			@media (max-width: 430px) {
				max-width: 120px;
				flex: 0 0 120px;
			}
			@media (max-width: 360px) {
				max-width: 80px;
				flex: 0 0 80px;
			}
		}
		section {
			width: 100%;
			display: flex;
			align-items: flex-start;
			padding: 15px;
			flex-direction: column;
			height: 100%;
			justify-content: space-between;
			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				font-weight: normal;
			}
			h2 {
				font-family: 'OpenSansSemiBold';
				margin: 4px 0 0 0;
				color: ${({ theme }) => theme.colors.text.blackLight};
				strong {
					font-family: 'OpenSansBlack';
					color: ${({ theme }) => theme.colors.text.black};
				}
			}
			h3 {
				font-family: 'OpenSansMedium';
				margin: 0;
				strong {
					font-family: 'OpenSansBold';
				}
			}
			h5 {
				position: relative;
				margin: 0 5px 0 13px;
				padding: 0 3px;
				&::after {
					content: '';
					position: absolute;
					border: 1px solid ${({ theme }) => theme.colors.text.error01};
					width: 100%;
					height: 1px;
					top: 12px;
					left: 0;
				}
			}
		}
	}
`;
export { StyledTodaysOffer };
