import { ReactNode } from 'react';
import styled from 'styled-components';
import { flexbox, FlexboxProps, layout, LayoutProps, space, SpaceProps } from 'styled-system';

type GridProps = LayoutProps &
	FlexboxProps &
	SpaceProps & {
		children?: ReactNode;
		className?: string;
		gap: number;
		minColWidth?: string;
		maxContent?: boolean;
	};

const Grid = styled.div<GridProps>`
	${layout}
	${flexbox}
	${space}
    align-items: baseline;
	/* display: grid;
	grid-gap: ${({ gap, theme }) => theme.space[gap]};
	grid-template-columns: repeat(
		auto-fit,
		minmax(
			min(${({ minColWidth }) => minColWidth}, 100%),
			${({ maxContent }) => (maxContent ? 'max-content' : '1fr')}
		)
	); */
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	column-gap: 30px;
	row-gap: 1em;
	@media (max-width: 1600px) {
		grid-template-columns: repeat(3, 1fr) !important;
	}
	@media (max-width: 1366px) {
		grid-template-columns: repeat(2, 1fr) !important;
		column-gap: 20px;
	}
	@media (max-width: 640px) {
		grid-template-columns: repeat(1, 1fr) !important;
		column-gap: 15px;
	}
`;

export { Grid };
