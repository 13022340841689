import React, { ReactElement, useState } from 'react';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';
import { Breadcrumb, Button, Footer, Header } from 'ui/components';
import icons, * as Icon from 'ui/icons';
import {
	ProductAllergens, ProductDescription, ProductMainDetails,


	QuantityAndPricing, Sidebar,
	SuppliersMainHeading
} from 'ui/patterns';
import images from '../../ui/images/PMIS-01.png';


const MainContent = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
	padding: 30px 50px 100px;
	@media (max-width: 960px) {
		padding: 30px 15px 0;
	}
`;
const BreadcrumbHolder = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media (max-width: 360px) {
		flex-direction: column;
		align-items: end;
		& > div {
			padding-bottom: 15px;
		}
	}
	div {
		button {
			display: none;
		}
	}
`;

const ContentHolder = styled.div`
	display: flex;
	padding-top: 30px;
	@media (max-width: 860px) {
		flex-direction: column;
		padding-top: 15px;
	}
`;
const LefContentHolder = styled.div`
	flex: 0 0 500px;
	max-width: 500px;
	margin-right: 142px;
	@media (max-width: 1440px) {
		flex: 0 0 400px;
		max-width: 400px;
		margin-right: 46px;
	}
	@media (max-width: 1152px) {
		flex: 0 0 350px;
		max-width: 350px;
		margin-right: 46px;
	}
	@media (max-width: 860px) {
		flex: 0 0 100%;
		max-width: 100%;
		margin-right: 0;
	}
`;
const RightContentHolder = styled.div`
	flex-grow: 1;
	@media (max-width: 860px) {
		margin-top: 30px;
		div {
			text-align: left !important;
		}
	}
`;
const ImageHolder = styled.div`
	row-gap: 25px;
	column-gap: 25px;
	display: grid;
	grid-template-columns: repeat(3, 1fr) !important;
	@media (max-width: 480px) {
		row-gap: 18px;
		column-gap: 18px;
	}
	div {
		width: 100%;
		border-radius: 16px;
		overflow: hidden;
		position: relative;
		img {
			width: 100%;
		}
	}
`;
const PlayIconOverlay = styled.div`
	position: absolute !important;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	background: linear-gradient(0deg, rgba(34, 18, 31, 0.48), rgba(34, 18, 31, 0.48)), url(image.png);
`;
const ButtonWrapper = styled.div`
	display: flex;
	@media (max-width: 480px) {
		padding-bottom: 0 !important;
	}
	button {
		display: flex !important;
		font-size: 18px;
		text-transform: capitalize;
		height: 50px;
		padding-top: 0;
		padding-bottom: 0;
		@media (max-width: 767px) {
			width: 40px;
			height: 40px;
			padding: 0;
			align-items: center;
			justify-content: Center;
			div {
				display: none;
			}
			.pr-icon {
				margin-right: 0 !important;
			}
		}
		span {
			display: flex;
			align-items: center;
		}
		.pr-icon {
			margin-right: 15px;
		}
	}
`;

function DetailPageOfSupplier(): ReactElement {
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	}
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={()=>onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={()=>onclickHambrgBtn()}></Sidebar>

			<MainContent>
				{/* Top Breadcrumb */}
				<BreadcrumbHolder>
					<Breadcrumb
						Heading="Cold Brew Bags"
						HomePage="Home"
						previousPage="Products"
						previousPageSecond="Coffee"
						currentPage="Cold Brew Bags"
					></Breadcrumb>
					<ButtonWrapper>
						<Button flex={[1, 1, 'initial']}>
							<icons.Edit />
							<div>Edit</div>
						</Button>
					</ButtonWrapper>
				</BreadcrumbHolder>
				<ContentHolder>
					{/* Left Image Session  */}
					<LefContentHolder>
						<ImageHolder>
							<Box>
								<img src={images} alt="Images" style={{ maxWidth: '100%' }} />
								<PlayIconOverlay>
									<Icon.playIcon />
								</PlayIconOverlay>
							</Box>

							<Box>
								<img src={images} alt="Images" style={{ maxWidth: '100%' }} />
							</Box>
							<Box>
								<img src={images} alt="Images" style={{ maxWidth: '100%' }} />
							</Box>
							<Box>
								<img src={images} alt="Images" style={{ maxWidth: '100%' }} />
							</Box>
							<Box>
								<img src={images} alt="Images" style={{ maxWidth: '100%' }} />
							</Box>
						</ImageHolder>
					</LefContentHolder>

					{/* Right Product Detail Session */}
					<RightContentHolder>
						{/* Product Main Heading */}
						<SuppliersMainHeading></SuppliersMainHeading>
						{/* Product Details */}
						<ProductMainDetails></ProductMainDetails>
						{/* Product Description */}
						<ProductDescription description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, "></ProductDescription>
						<QuantityAndPricing></QuantityAndPricing>
						{/* Product Allergens */}
						<ProductAllergens></ProductAllergens>
					</RightContentHolder>
				</ContentHolder>
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default DetailPageOfSupplier;
