import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { flexbox, FlexboxProps, layout, LayoutProps, space, SpaceProps } from 'styled-system';

type StyledClusterProps = LayoutProps &
	FlexboxProps &
	SpaceProps & {
		gap: number;
		clusterWidth?: string;
	};

const StyledCluster = styled.div<StyledClusterProps>`
	--space: ${({ theme, gap }) => theme.space[gap]};
	overflow: hidden;
	width: ${({ clusterWidth }) => clusterWidth || 'auto'};

	& > * {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: center;
		margin: calc(var(--space) / 2 * -1);
	}

	& > * > * {
		margin: calc(var(--space) / 2);
	}

	${flexbox};
	${layout};
	${space};
`;

const ClusterItemsWrap = styled.div<styledSystemProps>`
	${flexbox};
	${layout};
	${space};
`;

type styledSystemProps = SpaceProps & LayoutProps & FlexboxProps;

type ClusterProps = styledSystemProps & {
	gap: number;
	children?: ReactNode;
	clusterWidth?: string;
};

function Cluster({ gap, children, clusterWidth, ...styledSystemProps }: ClusterProps) {
	return (
		<StyledCluster gap={gap} clusterWidth={clusterWidth}>
			<ClusterItemsWrap {...styledSystemProps}>{children}</ClusterItemsWrap>
		</StyledCluster>
	);
}

export { Cluster };
