import { Select } from '@rebass/forms';
import Iget from 'models/Iget';
import Ipost from 'models/Ipost';
import VenueRole from 'models/pageObjects/venue.roles';
import queryString from 'query-string';
import React, { ReactElement, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Text } from 'rebass/styled-components';
import { BreadcrumbNav, Button, Footer, Grid, Header, Stack } from 'ui/components';
import * as Icon from 'ui/icons';
import { FormInput, Sidebar } from 'ui/patterns';
import urls from 'utils/create.url';
import helperFunctions from 'utils/helper.functions';
import http from 'utils/http.service';
import pageConstants from 'utils/pageConstants';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import {
	AddUserHolder,
	AssignVenueHolder,
	FooterBtnBlock,
	MainContent,
	ProfileDetails,
} from './styles';
function AddNewVenueUser(): ReactElement {
	const pageSize = 500;
	const location = useLocation() as any;
	const history = useHistory();
	const parsed = queryString.parse(location.search);
	const [showPopup, setShowModal] = useState(false);
	const [action, setAction] = useState('');
	const [modalText, setmodalText] = useState('');
	const [userPhn, checkUserPhn] = useState<any>();
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const userPhnValidateUrl = `experion/venueuser/phonenumberexists/get/${userPhn}`;
	const roleUrl = urls.GetSearchUrl({
		url: 'experion/venueuserrole/search?',
		pageSize: pageSize,
		filterDeleted: true,
		filterInactive: true,
		fields: [
			{
				field_name: 'hg_admin_Id',
				field_value: localStorage.getItem('hospitalityId')!,
			},
		],
	});
	const venueUrl = urls.GetSearchUrl({
		url: 'experion/venue/search?',
		pageSize: pageSize,
		fields: [
			{
				field_name: 'hosp_id',
				field_value: localStorage.getItem('hospitalityId')!,
			},
		],
		filterDeleted: true,
	});
	const venueObj: Partial<Iget> = {
		params: {},
		url: venueUrl,
		enabled: true,
		key: 'venue',
		resultFilteringRequired: true,
	};
	const venueListQry = hooks.useQuery(venueObj);

	const roleObj: Partial<Iget> = {
		params: {},
		url: roleUrl,
		enabled: true,
		key: 'role',
		resultFilteringRequired: true,
	};

	const roleQry = hooks.useQuery(roleObj);
	useEffect(() => {
		roleQry.data && setRoleList([...roleQry.data]);
	}, [roleQry.data]);
	useEffect(() => {
		venueListQry.data && setvenues([...venueListQry.data]);
	}, [venueListQry.data]);
	const [assignedUser, setAssignedUser] = useState<VenueRole>({
		venue: { key: 0, value: '' },
		role: { key: 0, value: '' },
	});
	const breadcrumbNav = [
		{ id: '01', label: 'Home', route: '/home/dashboard', active: false },
		{ id: '02', label: 'Dashboard', route: '/admin/user-dashboard', active: false },
		{ id: '03', label: 'Add New User', active: true },
	];
	const [isLoading, setIsLoading] = useState(false);
	const [venues, setvenues] = useState([] as any);
	const [roleList, setRoleList] = useState([] as any);
	const [userSaveText, setUserSaveText] = useState('Add');
	const [error, setError] = useState({
		userFirstNameError: '',
		userLastNameError: '',
		userPhnError: '',
		userEmailError: '',
		userRoleError: '',
	});
	const [userDetails, setUserDetails] = useState({
		first_name: '',
		last_name: '',
		phone: '',
		venue_user_email: '',
		id: 0,
		venue_user_role_mapping: [] as any,
		is_active: true,
		hg_id: localStorage.getItem('hospitalityId'),
	});
	const validateUniquePhnNum = () => {
		if (error.userPhnError === '') {
			http.GET({ url: userPhnValidateUrl }).then(function (data) {
				if (data.data) {
					setError({ ...error, userPhnError: 'Phone already exists' });
				} else {
					handleChange(userPhn, 'phone');
				}
			});
		}
	};

	const cancel = () => {
		history.push({
			pathname: '/admin/dashboard-store',
			search: '?id=' + parsed.id,
		});
	};
	const clear = () => {
		setUserDetails({
			first_name: '',
			last_name: '',
			phone: '',
			venue_user_email: '',
			id: 0,
			venue_user_role_mapping: [],
			is_active: true,
			hg_id: localStorage.getItem('hospitalityId'),
		});
		setError({
			userEmailError: '',
			userFirstNameError: '',
			userLastNameError: '',
			userPhnError: '',
			userRoleError: '',
		});
		setAssignedUser({
			venue: { key: 0, value: '' },
			role: { key: 0, value: '' },
		});
		window.scroll({ top: 0, left: 0, behavior: 'smooth' });
	};

	const handleChange = (val, name) => {
		const item = userDetails;
		//const data = assignedUser;
		switch (name) {
			case 'firstName': {
				if (!val) {
					setError({ ...error, userFirstNameError: 'First name cannot be blank' });
				} else {
					setError({ ...error, userFirstNameError: '' });
				}
				item.first_name = val;
				break;
			}
			case 'lastName': {
				if (!val) {
					setError({ ...error, userLastNameError: 'Last name cannot be blank' });
				} else {
					setError({ ...error, userLastNameError: '' });
				}
				item.last_name = val;
				break;
			}
			case 'phone': {
				if (!val) {
					setError({ ...error, userPhnError: 'Phone cannot be blank' });
				} else {
					const regex = pageConstants.phoneRegex;
					if (!regex.test(val)) {
						setError({ ...error, userPhnError: 'Invalid Phone' });
					} else {
						checkUserPhn(val);
						setError({ ...error, userPhnError: '' });
					}
				}
				item.phone = val;
				break;
			}
			case 'userEmail': {
				if (!val) {
					setError({ ...error, userEmailError: 'Email cannot be blank' });
				} else {
					const validEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(val);
					if (validEmail) {
						setError({ ...error, userEmailError: '' });
					} else {
						setError({ ...error, userEmailError: 'Please enter Valid Email' });
					}
				}
				item.venue_user_email = val;
				break;
			}
			case 'userRole': {
				item.venue_user_role_mapping = [];
				const data: VenueRole = JSON.parse(JSON.stringify(assignedUser));
				if (data) {
					data.role.value = val.target.value;
					data.venue.key = parseInt(parsed.id);
					if (val.target.value != '') {
						data.role.key = parseInt(
							roleList[roleList.findIndex((x) => x.role_name == val.target.value)].id
						);
						setError({ ...error, userLastNameError: '' });
						if (data.venue.key) {
							data.venue.value = venues[venues.findIndex((x) => x.id == data.venue.key)].name;
						}
					} else {
						setError({ ...error, userRoleError: 'Role cannot be blank' });
					}
					item.venue_user_role_mapping.push({
						venue_id: data.venue.key,
						role_id: data.role.key,
						venue_name: data.venue.value,
						role_name: data.role.value,
					});

					setAssignedUser(data);
				}

				break;
			}
		}
		setUserDetails({
			...item,
		});
	};

	const saveUser = async () => {
		setIsLoading(true);
		userDetails.phone = userDetails.phone.replace(/\+/g, '').replace(/ /g, '');
		userDetails.phone = helperFunctions.formatPhone(userDetails.phone);
		let user = {};
		const isSave = userSaveText == 'Add' ? true : false;
		if (isSave) {
			user = Object.keys(userDetails).reduce((object, key) => {
				if (key !== 'id') {
					object[key] = userDetails[key];
				}
				return object;
			}, {});
		} else user = userDetails;

		const obj: Partial<Ipost> = {
			data: { venueUser: user },
			params: {},
			url: `experion/venueuser/create`,
			message: `User saved successfully`,
		};
		try {
			await api.post(obj).then(function (data) {
				if (typeof data != 'undefined')
					history.push({
						pathname: '/admin/dashboard-store',
						search: '?id=' + parsed.id,
					});
			});
			setIsLoading(false);
		} catch (e) {
			setIsLoading(false);
		}
	};

	//popup

	function confirmPopup(action) {
		switch (action) {
			case 'cancel':
				showConfirmationModal(action, 'Are you sure you want to cancel ?');
				break;
			case 'clear':
				showConfirmationModal(action, 'Are you sure you want to clear all data ?');
				break;
			case 'Add':
				saveUser();
				break;
			default:
				break;
		}
	}
	async function closePopup() {
		await setShowModal(!showPopup);
	}
	function showConfirmationModal(action, text) {
		setAction(action);
		setmodalText(text);
		setShowModal(!showPopup);
	}
	async function onSubmit() {
		if (action === 'clear') {
			clear();
		} else if (action === 'cancel') {
			cancel();
		}
		setShowModal(!showPopup);
	}
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	};
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={() => onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={() => onclickHambrgBtn()}></Sidebar>
			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbNav Heading="Add New User" breadcrumbList={breadcrumbNav} />
				{/* Add User Details */}
				<AddUserHolder>
					<ProfileDetails>
						<Text as="h1" fontSize={18} fontFamily="OpenSansBold" color="text.SubHeading">
							basic details
						</Text>
						<Box as="form">
							<Grid gap={0}>
								<FormInput
									label="First Name"
									autoComplete="off"
									validationMessage={error.userFirstNameError}
									validation={error.userFirstNameError ? 'error' : undefined}
									required={true}
									value={userDetails.first_name}
									name="firstName"
									onTextChange={(e) => handleChange(e, 'firstName')}
									maxLength={50}
									type="text"
								/>
								<FormInput
									label="Last Name"
									autoComplete="off"
									validationMessage={error.userLastNameError}
									validation={error.userLastNameError ? 'error' : undefined}
									required={true}
									value={userDetails.last_name}
									name="lastName"
									onTextChange={(e) => handleChange(e, 'lastName')}
									maxLength={50}
									type="text"
								/>
								<FormInput
									label="Phone"
									autoComplete="off"
									validationMessage={error.userPhnError}
									validation={error.userPhnError ? 'error' : undefined}
									required={true}
									value={userDetails.phone}
									name="phone"
									onTextChange={(e) => handleChange(e, 'phone')}
									maxLength={12}
									type="tel"
									onBlur={validateUniquePhnNum}
								/>
								<FormInput
									label="Email Address"
									autoComplete="off"
									validationMessage={error.userEmailError}
									validation={error.userEmailError ? 'error' : undefined}
									required={true}
									value={userDetails.venue_user_email}
									name="userEmail"
									onTextChange={(e) => handleChange(e, 'userEmail')}
									maxLength={50}
									type="text"
								/>
							</Grid>
						</Box>
					</ProfileDetails>
					<AssignVenueHolder>
						<Text as="h1" fontSize={18} fontFamily="OpenSansBold" color="text.SubHeading">
							Assign Role{' '}
						</Text>
						<Box as="form">
							<Grid gap={2}>
								<Stack categorySelect gap={0}>
									<div>
										<Select
											onChange={(e) => handleChange(e, 'userRole')}
											value={assignedUser.role.value}
										>
											<option value="">-Select Role-</option>
											{roleList &&
												roleList.map((option, index) => (
													<option key={index}>{option.role_name}</option>
												))}
										</Select>
										<Icon.downArrow />
									</div>
									<span style={{ fontSize: 12, color: 'red' }}>{error.userRoleError}</span>
								</Stack>
							</Grid>
						</Box>
					</AssignVenueHolder>

					{/* Footer Btn Block */}
					<FooterBtnBlock>
						<Stack direction="x" gap={3} justifyContent={['center', 'flex-end']}>
							{userSaveText == 'Add' && (
								<Button
									flex={[1, 1, 'initial']}
									variant="secondary"
									onClick={() => confirmPopup('clear')}
								>
									Clear
								</Button>
							)}
							<Button
								flex={[1, 1, 'initial']}
								variant="secondary"
								onClick={() => confirmPopup('cancel')}
							>
								Cancel
							</Button>
							<Button
								flex={[1, 1, 'initial']}
								onClick={() => confirmPopup(userSaveText)}
								disabled={
									userDetails.first_name == '' ||
									userDetails.last_name == '' ||
									userDetails.venue_user_email == '' ||
									userDetails.phone == '' ||
									!assignedUser.role.key ||
									error.userFirstNameError != '' ||
									error.userLastNameError != '' ||
									error.userPhnError != '' ||
									error.userEmailError != '' ||
									error.userRoleError != '' ||
									isLoading == true
								}
							>
								{userSaveText}
							</Button>
						</Stack>
					</FooterBtnBlock>
				</AddUserHolder>
				<SweetAlert
					show={showPopup}
					confirmBtnText="Confirm"
					onConfirm={onSubmit}
					showCancel={true}
					onCancel={closePopup}
					title={'Confirmation Box'}
				>
					{() => <form>{modalText}</form>}
				</SweetAlert>
			</MainContent>
			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default AddNewVenueUser;
