import { Input, Select } from '@rebass/forms';
import React, { useState } from 'react';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { Box, Flex, Text } from 'rebass/styled-components';
import 'simplebar/dist/simplebar.min.css';
import styled from 'styled-components';
import { Button } from 'ui/components';
import icons from 'ui/icons';
import notify from 'utils/toast.service';
const UserListHolder = styled.div`
	width: 660px;
	margin-top: 25px;
	@media (max-width: 767px) {
		width: 100%;
	}
	button {
		padding: 5px 10px;
		font-size: 14px;
		cursor: pointer !important;
		span {
			display: flex;
			.pr-icon {
				margin-right: 10px;
			}
		}
	}
	form {
		flex-direction: column;
		margin-top: 20px;
		font-family: 'OpenSansMedium';
		slot,
		section {
			display: flex;
			margin-bottom: 15px;
			div {
				margin-top: 0;
				min-width: 125px;
				@media (max-width: 480px) {
					min-width: 95px;
				}
			}
		}
		section {
			background: ${({ theme }) => theme.colors.bg.lightYellow};
			border-radius: 8px;
			padding: 10px;
		}
		button {
			margin-left: 25px;
			max-width: auto;
			flex: 0 0 auto;
			height: 42px;
			font-size: 14px;
			span {
				display: flex;
				.pr-icon {
					margin-right: 10px;
					svg {
						width: 14px;
						height: 14px;
					}
				}
			}
			div {
				min-width: fit-content;
			}
			@media (max-width: 640px) {
				margin-left: 10px;
				span {
					.pr-icon {
						margin-right: 0;
					}
				}
				div {
					display: none;
				}
			}
			@media (max-width: 480px) {
				margin-left: 10px;
			}
		}
		select,
		input {
			width: auto;
			border: 1px solid ${({ theme }) => theme.colors.border.default};
			outline: none;
			background: ${({ theme }) => theme.colors.bg.white};
		}
		select {
			border-right: 0;
			width: 100%;
			border-radius: 6px 0 0 6px;
		}
		input {
			border-radius: 6px;
			width: 100% !important;
			margin-right: 10px;
			text-align: center;
			+ div + input {
				border-radius: 0 6px 6px 0;
				margin-right: 0;
			}
			@media (max-width: 480px) {
				width: 88px !important;
			}
		}
	}
	section {
		align-items: center;
		justify-content: space-between;
		margin: 5px 0 0 0 !important;
		input,
		select {
			opacity: 0.7;
			cursor: not-allowed;
		}
		button {
			margin-left: 25px;
			max-width: 42px;
			flex: 0 0 42px;
			height: 42px;
			svg {
				height: 20px;
			}
		}
	}
`;

function PricingTable(prop) {
	// const [isChanged, setIsChanged] = useState(false);
	const [qtyVal, setQtyVal] = useState('');
	const [dropDown, setDropDown] = useState('fixed');
	const [price, setPrice] = useState('');
	const [tierPrices, setTierPrices] = useState({
		qty: '',
		value: '',
		drpDown: 'fixed',
	});

	const tierPricingOnchange = (data, e) => {
		if (data == 'qty') {
			setQtyVal(e.target.value);
			tierPrices.qty = e.target.value;
		} else if (data == 'price') {
			setPrice(e.target.value);
			tierPrices.value = e.target.value;
		} else if (data == 'fxd') {
			if (e.target.value) {
				setDropDown(e.target.value);
				tierPrices.drpDown = e.target.value;
			}
		}
		setTierPrices(tierPrices);
	};
	const onClickAdd = () => {
		if (qtyVal != '' && price != '' && dropDown != '') {
			prop.addTierPricing && prop.addTierPricing(tierPrices);
			setQtyVal('');
			setPrice('');
			setDropDown('fixed');
			setTierPrices({
				qty: '',
				value: '',
				drpDown: 'fixed',
			});
		} else {
			notify('please enter values').error();
		}
	};
	return (
		<UserListHolder>
			<Flex flexWrap="wrap" as="section">
				<Text as="h1" fontSize={16} fontFamily="OpenSansBold" color="text.black">
					Pricing
				</Text>
			</Flex>
			<Flex as="form">
				<Box as="slot">
					<Input
						id="quantity"
						name="quantity"
						placeholder="Quantity"
						type="number"
						value={qtyVal}
						onChange={(e) => tierPricingOnchange('qty', e)}
					/>
					<Select
						id="location"
						name="location"
						value={dropDown}
						onChange={(e) => tierPricingOnchange('fxd', e)}
					>
						<option value="fixed">Fixed</option>
						<option value="discount">Discount</option>
					</Select>
					<Input
						id="price"
						name="name"
						placeholder={tierPrices.drpDown == 'discount' ? 'Discount(%)' : 'Price per item($)'}
						type="number"
						value={price}
						onChange={(e) => tierPricingOnchange('price', e)}
					/>
					<Button variant="editIcon" onClick={onClickAdd}>
						<icons.PlusIcon />
						<Box> ADD</Box>
					</Button>
				</Box>
				{prop.productDetails &&
					prop.productDetails.tier_prices.length > 0 &&
					prop.productDetails.tier_prices.map((item, index) => {
						return (
							<Box as="section" key={index}>
								<Input
									disabled={true}
									id="quantity"
									value={item.qty}
									name="quantity"
									type="number"
									placeholder="Quantity"
								/>
								<Select
									disabled={true}
									id="location"
									value={item.value ? 'fixed' : 'discount'}
									name="location"
								>
									<option value="fixed">Fixed</option>
									<option value="discount">Discount</option>
								</Select>
								<Input
									disabled={true}
									id="name"
									name="name"
									type="number"
									value={item.value != 0 ? item.value : item.extension_attributes?.percentage_value}
								/>

								<Button variant="deleteIcon" onClick={() => prop.deleteTierPricing(index)}>
									<icons.DeleteIcon />
								</Button>
							</Box>
						);
					})}
			</Flex>
		</UserListHolder>
	);
}

export { PricingTable };
