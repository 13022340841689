import { Select } from '@rebass/forms';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import * as Icon from 'ui/icons';

const StyledOrderFilter = styled.div`
	border: 1px solid rgba(189,188,185,1);
	box-sizing: border-box;
	border-radius: 6px;
	display: flex;
	align-items: center;
	min-width: 254px;
	min-height: 40px;
	@media (max-width: 480px) {
		min-width: auto;
		flex-grow: 1;
	}
	.pr-icon {
		margin: 0 8px 0 12px;
		svg {
			margin-right: 0;
		}
	}
	div {
		flex-grow: 1;
		Select {
			border: none;
			outline: none;
			box-shadow: none;
			font-size: 14px;
			line-height: 18px;
			color: ${({ theme }) => theme.baseColors.primary};
			margin-right: -34px;
		}
	}
	svg {
		margin-right: 10px;
		margin-left: 0;
		path {
			fill: ${({ theme }) => theme.baseColors.primary};
		}
	}
`;
function RecentOrderFilter(prop): ReactElement {
	return (
		<StyledOrderFilter>
			<Icon.FilterIcon />
			<Select
				onChange={(e) => {
					prop.onSort(e.target.value);
				}}
				className="PageSearchCategory"
			>
				<option value="All">All Orders</option>
				<option value="canceled">Canceled</option>
				<option value="confirmed">Confirmed</option>
				<option value="delivered">Delivered</option>
				<option value="disputed">Disputed</option>
				<option value="pending">Pending</option>
				{/* <option value="processing">Processing</option> */}
				<option value="order_rejected">Rejected</option>
				<option value="shipped">Shipped</option>
			</Select>
		</StyledOrderFilter>
	);
}

export { RecentOrderFilter };
