import React, { ReactElement, ReactNode } from 'react';
import { Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import { flex, FlexProps, space, SpaceProps } from 'styled-system';
import { PageTitle } from '../../components';
import * as Icon from '../../icons';
import { PageActions } from '../index';

type StyledPageHeaderProps = SpaceProps & FlexProps;

const StyledPageHeader = styled.div<StyledPageHeaderProps>`
	${space}
	${flex}

  --bg: var(--color-neutral-contrast-07);

	display: flex;
	border-top: solid 1px transparent;
	background: var(--bg);
	min-height: 45px;

	html[data-color-mode='dark'] & {
		--bg: var(--color-neutral-contrast-01);
		border-top: solid 1px var(--color-neutral-contrast-02);
	}

	& .pr-page-utils {
		> *,
		> .pr-page-actions > * + * {
			--border-color: var(--color-neutral-contrast-06);
			border-left: solid 1px var(--border-color);

			html[data-color-mode='dark'] & {
				--border-color: var(--color-neutral-contrast-02);
			}
		}
	}

	& .addons {
		.button-group {
			margin: ${({ theme }) => theme.space[2]};

			button {
				line-height: 0.8;

				&:focus {
					box-shadow: none;
					border-color: transparent;
				}
			}
		}
	}

	& .pr-page-search {
		position: relative;

		& .pr-icon {
			position: absolute;
			top: 50%;
			left: ${({ theme }) => theme.space[3]};
			transform: translateY(-45%);
			z-index: 100;
			pointer-events: none;

			svg {
				width: 1.1em;
				height: 1.1em;

				path {
					fill: #fff !important;
				}
			}
		}
	}
`;

const BackNavButton = styled.button`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	appearance: none;
	border: none;
	background: hsla(0, 0%, 100%, 10%);
	border-radius: none;
	color: #fff;
	margin-right: ${({ theme }) => theme.space[3]};
	margin-left: calc(${({ theme }) => theme.space[3]} * -1);
	cursor: pointer;

	&:hover {
		background: hsla(0, 0%, 100%, 10%);
	}

	&:focus {
		outline: none;
		background: hsla(0, 0%, 100%, 50%);
	}
`;

type PageHeaderProps = {
	title: string;
	subTitle?: string | null;
	actions?: Array<action>;
	hasSearch?: boolean;
	searchValue?: string;
	handleChange?: (value: string) => void;
	addons?: ReactElement;
	hasBackNav?: boolean;
	backNavAction?: () => void;
	backNavTooltip?: string;
};

type action = {
	action: string;
	icon: ReactNode;
	handleButtonClick?: () => void;
};

function PageHeader({
	title,
	subTitle,
	actions,
	addons,
	hasBackNav,
	backNavAction,
	backNavTooltip,
}: PageHeaderProps): ReactElement {
	return (
		<StyledPageHeader px={3}>
			{hasBackNav ? (
				<BackNavButton onClick={backNavAction} title={backNavTooltip}>
					<Icon.ChevronLeft title={backNavTooltip} />
				</BackNavButton>
			) : null}
			<PageTitle title={title} />
			{subTitle ? (
				<span
					style={{
						display: 'inline-block',
						padding: '6px 8px',
						borderRadius: '100px',
						background: 'rgba(255, 255, 255, 0.3)',
						fontSize: '10px',
						marginLeft: '10px',
						fontWeight: 600,
						alignSelf: 'center',
						color: 'white',
						lineHeight: 1,
					}}
				>
					{subTitle}
				</span>
			) : null}
			<Flex ml="auto" className="pr-page-utils">
				{addons ? addons : null}
				{actions ? <PageActions actions={actions} /> : null}
			</Flex>
		</StyledPageHeader>
	);
}

export { PageHeader };
