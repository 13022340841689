import { dcUserPermissionList, userTypes } from 'models/enum-constants/common.constants';
import Ipost from 'models/Ipost';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { queryCache } from 'react-query';
import { useHistory } from "react-router-dom";
import { Avatar } from 'ui/components';
import CustomerNotFound from 'ui/components/header/parkedOrderLimitReached';
// import AvatarLoggedIn from 'ui/images/defaultAvatar.png';
import { default as AvatarDefault, default as AvatarLoggedIn } from 'ui/images/avatar.svg';
import { Dropdown, Modal } from 'ui/patterns';
import appContext from 'utils/appContext';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import utilityFunctions from 'utils/utility.functions';
import { StyledProfileDropdown } from './styles';

function ProfileDropdown(): ReactElement {
	let userAccessType = localStorage.getItem('userType');
	const setUserType = localStorage.getItem('userType');
	const [showParkedOrderLimitModal, setshowParkedOrderLimitModal] = useState(false);
	var userType = (localStorage.getItem('userType') == userTypes.venueAdmin || localStorage.getItem('userType') == userTypes.venueUser ? "h" : "s");
	const history = useHistory();
	const [modalText, setmodalText] = useState('');
	const [showPopup, setShowModal] = useState(false);
	const [action, setAction] = useState('');
	const key = "logOutParking";
	const [mutatePost] = hooks.useMutation(key);
	let ctx = useContext(appContext).userObj;
	const hospAdminProfileOptions = [
		{
			id: 2,
			name: 'Dashboard',
		},
		{
			id: 3,
			name: 'Profile Details',
		},
		{
			id: 4,
			name: 'Customer Details',
		},
		{
			id: 6,
			name: 'Log Out',
		},
		// {
		// 	id: 5,
		// 	name: 'Refer ARIA Company',
		// },
	];

	const hospUserProfileOptions = [
		{
			id: 2,
			name: 'Dashboard',
		},
		{
			id: 3,
			name: 'Profile Details',
		},
		{
			id: 6,
			name: 'Log Out',
		},
		// {
		// 	id: 5,
		// 	name: 'Refer ARIA Company',
		// },
	];

	const supplyAdminProfileOptions = [
		// {
		// 	id: 2,
		// 	name: 'Dashboard',
		// },
		{
			id: 3,
			name: 'Profile Details',
		},
		{
			id: 4,
			name: 'Seller Details',
		},
		// {
		// 	id: 7,
		// 	name: 'Seller Settings',
		// },
		{
			id: 8,
			name: 'DC Configuration',
		},
		{
			id: 6,
			name: 'Log Out',
		},
		// {
		// 	id: 5,
		// 	name: 'Refer ARIA Company',
		// },
	];

	const supplyUserProfileOptions = [
		// {
		// 	id: 2,
		// 	name: 'Dashboard',
		// },
		{
			id: 3,
			name: 'Profile Details',
		},
		{
			id: 6,
			name: 'Log Out',
		},
		// {
		// 	id: 5,
		// 	name: 'Refer ARIA Company',
		// },

	];
	if (utilityFunctions.isGranted(dcUserPermissionList.Vacation)) {
		let item = {
			id: 8,
			name: 'DC Configuration'
		}
		supplyUserProfileOptions.splice(1, 0, item);
	}
	const togglePOLPopup = () => {
		//e.stopPropagation();
		setshowParkedOrderLimitModal(!showParkedOrderLimitModal);
	};

	const callbackPOLFunction = (_) => {
		//setSelectedFavourite(childData);
	};

	const clearCart = () => {
		const obj: Partial<Ipost> = {
			data: { cartId: localStorage.getItem('cartId') },
			params: {},
			url: "/clear-cart",
			message: ' ',
		};
		try {
			api.post(obj).then((data) => {
				if (data !== undefined) {
					localStorage.clear();
					ctx.venueId = null;
					queryCache.clear();
					history.push('/login');
				}
			});
		} catch (e) { }
	}
	const onMarkAsParked = () => {
		let payload = {
			"cartId": localStorage.getItem('cartId'),
			"venueUserId": localStorage.getItem('userId'),
			"venueId": localStorage.getItem('selectedVenue')
		};
		const obj1: Partial<Ipost> = {
			data: payload,
			params: {},
			url: '/experion-parkorder/create',
			message: ' '
		};
		try {
			mutatePost(obj1).then((data) => {
				if (data !== undefined) {
					clearCart();
				}
			});
		} catch (e) { }
	}

	async function onSubmit() {
		if (action === 'plogout') { //Parkorder Logout
			let poLimitCheck: any;
			poLimitCheck = await utilityFunctions.getVenueParkedOrderLimit();

			let poCountCheck: any;
			poCountCheck = await utilityFunctions.getVenueParkedOrderCount();

			if (poLimitCheck < poCountCheck + 1) {
				let venueSelected: any = localStorage.getItem('selectedVenue');
				localStorage.setItem('switchedVenue', venueSelected);
				setShowModal(!showPopup);
				togglePOLPopup();
			} else {
				onMarkAsParked();
				setShowModal(!showPopup);
			}
		} else if (action === 'nlogout') { //Normal Logout
			localStorage.clear();
			ctx.venueId = null;
			queryCache.clear();
			history.push('/login');
		}
	}

	async function closePopup() {
		await setShowModal(!showPopup);
		if ((setUserType == userTypes.venueUser || setUserType == userTypes.venueAdmin)) {
			let cartCheck = await utilityFunctions.getCartData();
			if (cartCheck) {
				if (action === 'plogout') {
					clearCart();
				}
			}
		}
	}

	function showConfirmationModal(action, text) {
		setShowModal(!showPopup);
		setAction(action);
		setmodalText(text);
	}

	const logOutSession = async () => {
		if ((setUserType == userTypes.venueUser || setUserType == userTypes.venueAdmin)) {
			let cartData: any;
			cartData = await utilityFunctions.getCartData();
			if (cartData) {
				showConfirmationModal('plogout', 'Do you want to park the orders in cart before logging out?');
			} else {
				showConfirmationModal('nlogout', 'Are you sure you want to log out?');
			}
		} else {
			showConfirmationModal('nlogout', 'Are you sure you want to log out?');
		}
	}
	const [showOptions, setShowOptions] = useState(false);
	const optionsTrigger = () => (
		<button className="btn-cardoptions" onClick={() => setShowOptions(!showOptions)}></button>
	);

	const onProfileDetailsClick = (event) => {
		if (localStorage.getItem('customerToken') !== null) {
			if (event.id === 6) {
				logOutSession();
			} else if (event.id === 2 && (setUserType == userTypes.venueUser || setUserType == userTypes.venueAdmin)) {
				history.push('/admin/user-dashboard');
			} else if (event.id === 2 && (setUserType == userTypes.supplierUser || setUserType == userTypes.supplierAdmin || setUserType == "du" || setUserType == "da")) {
				history.push('/admin/seller-dashboard');
			} else if (event.id === 3 && (setUserType == userTypes.venueUser || setUserType == userTypes.venueAdmin)) {
				history.push('/admin/userProfileDetails');
			} else if (event.id === 3 && (setUserType == userTypes.supplierUser || setUserType == userTypes.supplierAdmin || setUserType == "du" || setUserType == "da")) {
				history.push('/admin/sellerProfileDetails');
			} else if (event.id === 4 && (setUserType == userTypes.venueUser || setUserType == userTypes.venueAdmin)) {
				history.push('/admin/customerGroupDetails');
			} else if (event.id === 4 && (setUserType == userTypes.supplierUser || setUserType == userTypes.supplierAdmin || setUserType == "du" || setUserType == "da")) {
				history.push('/admin/sellerCompanyProfileManagement');
			} else if (event.id === 7 && (setUserType == userTypes.supplierUser || setUserType == userTypes.supplierAdmin || setUserType == "du" || setUserType == "da")) {
				history.push('/admin/sellerCompanySettings');
			} else if (event.id === 8 && (setUserType == userTypes.supplierUser || setUserType == userTypes.supplierAdmin || setUserType == "du" || setUserType == "da")) {
				history.push('/admin/dcConfig');
			} else if (event.id === 5) {
				history.push('/admin/referExperion');
			}
		} else {
			localStorage.clear();
			ctx.venueId = null;
			queryCache.clear();
			history.push('/login');
		}
	}
	return (
		<>
			<StyledProfileDropdown>
				{localStorage.getItem('customerToken') !== null ? <Avatar src={AvatarLoggedIn} className="pr-header-avatar" /> : <Avatar src={AvatarDefault} className="pr-header-avatar" />}
				<Dropdown
					trigger={optionsTrigger()}
					options={userType == "h" ? (userAccessType == userTypes.venueAdmin ? hospAdminProfileOptions : hospUserProfileOptions) : (userAccessType == userTypes.supplierAdmin ? supplyAdminProfileOptions : supplyUserProfileOptions)}
					showOptions={showOptions}
					setShowOptions={setShowOptions}
					top="0"
					place="covered-right"
					handleClick={onProfileDetailsClick}
				/>
			</StyledProfileDropdown>


			<Modal
				width="600px"
				showModal={showParkedOrderLimitModal}
				setShowModal={setshowParkedOrderLimitModal}
			>
				<CustomerNotFound parentCallback={callbackPOLFunction} togglePopup={togglePOLPopup} />
			</Modal>

			<SweetAlert
				show={showPopup}
				confirmBtnText="Confirm"
				onConfirm={onSubmit}
				showCancel={true}
				onCancel={closePopup}
				cancelBtnText="Cancel"
				title={'Confirmation Box'}
			>
				{() => <form>{modalText}</form>}
			</SweetAlert>
		</>
	);
}

export { ProfileDropdown };

