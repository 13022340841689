import React, { ReactElement } from 'react';
import styled from 'styled-components';

const StyledPageHeader = styled.div`
	margin-left: auto;
	float: left;
	.rating {
		border: none;
		float: left;
		> input {
			display: none;
			cursor: pointer;
			&:checked {
				~ {
					label {
						&:before {
							cursor: pointer;
							background: url(${require('../../images/starYellow-xl.svg')});
						}
						&:hover {
							~ {
								label {
									&:before {
										cursor: pointer;
										background: url(${require('../../images/starYellow-xl.svg')});
										opacity: 0.4;
									}
								}
							}
						}
					}
				}
				+ {
					label {
						cursor: pointer;
						&:hover {
							&:before {
								cursor: pointer;
								background: url(${require('../../images/starYellow-xl.svg')});
								opacity: 0.7;
							}
						}
					}
				}
			}
		}
		> label {
			&:before {
				margin: 0 5px;
				font-size: 1.25em;
				display: inline-block;
				content: '';
				width: 35px;
				height: 33px;
				cursor: pointer;
				background: url(${require('../../images/star-xl.svg')});
			}
			float: right;
			height: 33px;
			&:hover {
				~ {
					input {
						&:checked {
							~ {
								label {
									&:before {
										background: url(${require('../../images/starYellow-xl.svg')});
										opacity: 0.7;
									}
								}
							}
						}
					}
				}
			}
		}
		> .half {
			&:before {
				content: '';
				position: absolute;
				width: calc(33px / 2);
				background: url(${require('../../images/starHalf-xl.svg')});
			}
		}
		&:not(:checked) {
			> label {
				&:hover {
					&:before {
						background: url(${require('../../images/starYellow-xl.svg')});
						opacity: 0.7;
					}
					~ {
						label {
							&:before {
								background: url(${require('../../images/starYellow-xl.svg')});
							}
						}
					}
				}
			}
		}
	}
`;
function RatingXl(props): ReactElement {
	return (
		<StyledPageHeader>
			<div className="rating">
				<input type="radio" id="starxl5" name="ratingxl" value="5" checked={props.value && props.value == 5 ? true : false} />
				<label className="full" htmlFor="starxl5" title="Awesome - 5 stars"></label>
				<input type="radio" id="starxl4half" name="ratingxl" value="4.5" checked={props.value && props.value == 4.5 ? true : false} />
				<label className="half" htmlFor="starxl4half" title="Pretty good - 4.5 stars"></label>
				<input type="radio" id="starxl4" name="ratingxl" value="4" checked={props.value && props.value == 4 ? true : false} />
				<label className="full" htmlFor="starxl4" title="Pretty good - 4 stars"></label>
				<input type="radio" id="starxl3half" name="ratingxl" value="3.5" checked={props.value && props.value == 3.5 ? true : false} />
				<label className="half" htmlFor="starxl3half" title="Meh - 3.5 stars"></label>
				<input type="radio" id="starxl3" name="ratingxl" value="3" checked={props.value && props.value == 3 ? true : false} />
				<label className="full" htmlFor="starxl3" title="Meh - 3 stars"></label>
				<input type="radio" id="starxl2half" name="ratingxl" value="2.5" checked={props.value && props.value == 2.5 ? true : false} />
				<label className="half" htmlFor="starxl2half" title="Kinda bad - 2.5 stars"></label>
				<input type="radio" id="starxl2" name="ratingxl" value="2" checked={props.value && props.value == 2 ? true : false} />
				<label className="full" htmlFor="starxl2" title="Kinda bad - 2 stars"></label>
				<input type="radio" id="starxl1half" name="ratingxl" value="1.5" checked={props.value && props.value == 1.5 ? true : false} />
				<label className="half" htmlFor="starxl1half" title="Meh - 1.5 stars"></label>
				<input type="radio" id="starxl1" name="ratingxl" value="1" checked={props.value && props.value == 1 ? true : false} />
				<label className="full" htmlFor="starxl1" title="Sucks big time - 1 star"></label>
				<input type="radio" id="starxlHalf" name="ratingxl" value="0.5" checked={props.value && props.value == 0.5 ? true : false} />
				<label className="half" htmlFor="starxlHalf" title="Sucks big time - 0.5 stars"></label>
			</div>
		</StyledPageHeader>
	);
}

export { RatingXl };
