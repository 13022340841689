import React, { ReactElement } from 'react';
import styled from 'styled-components';

type PageAnchorProps = {
	active?: boolean;
	disabled?: boolean;
};

const PageAnchor = styled.a<PageAnchorProps>`
	position: relative;
	display: block;
	padding: 0.5rem 0.75rem;
	margin-left: -1px;
	line-height: 1.25;
	text-decoration: none;
	color: ${({ active, disabled, theme }) => {
		if (disabled) return `#eee`;
		return active ? theme.colors.white : theme.colors.primary;
	}};
	background-color: ${({ active, theme }) => (active ? theme.primary : `#eee`)};
	border: solid 1px ${({ theme }) => theme.colors.border.default};
	cursor: ${({ disabled }) => {
		if (disabled) return 'not-allowed';
	}};
`;

const StyledPagination = styled.nav`
	& > ul {
		display: flex;
		padding-left: 0;
		list-style: none;
		border-radius: 0.25rem;
		margin: 0;

		& > li > ${PageAnchor} {
			cursor: pointer;
		}

		& > li:first-child ${PageAnchor} {
			border-top-left-radius: ${({ theme }) => theme.radii.softer};
			border-bottom-left-radius: ${({ theme }) => theme.radii.softer};
		}

		& > li:last-child ${PageAnchor} {
			border-top-right-radius: ${({ theme }) => theme.radii.softer};
			border-bottom-right-radius: ${({ theme }) => theme.radii.softer};
		}
	}
`;

function Pagination(): ReactElement {
	return (
		<StyledPagination>
			<ul>
				<li>
					<PageAnchor>Previous</PageAnchor>
				</li>
				<li>
					<PageAnchor>1</PageAnchor>
				</li>
				<li>
					<PageAnchor>2</PageAnchor>
				</li>
				<li>
					<PageAnchor>3</PageAnchor>
				</li>
				<li>
					<PageAnchor>4</PageAnchor>
				</li>
				<li>
					<PageAnchor>5</PageAnchor>
				</li>
				<li>
					<PageAnchor>Next</PageAnchor>
				</li>
			</ul>
		</StyledPagination>
	);
}

export { Pagination };
