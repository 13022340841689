import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Flex, Text } from 'rebass/styled-components';
import styled, { css } from 'styled-components';
import * as Icon from 'ui/icons';

const ListWrapper = styled.div`
	button {
		width: 22px;
		height: 22px;
		border-radius: 6px;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 10px;
		cursor: pointer;
		outline: none !important;
		background-image: linear-gradient(to right, ${({ theme }) => theme.newColors.secondary01}, ${({ theme }) => theme.newColors.secondary02});
		&:hover {
			background-image: linear-gradient(to right, ${({ theme }) => theme.newColors.secondary02}, ${({ theme }) => theme.newColors.secondary01});
		}
	}
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	overflow-x: auto;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 30px 0;
	&.subHeadingPad {
		padding: 30px 0 6px;
	}
	h1 {
		text-transform: uppercase;
		line-height: 17px;
	}
`;
type ListItemProps = {
	active?: boolean;
};
const ListItem = styled.div<ListItemProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	a {
		text-decoration: none;
		text-decoration: none;
		line-height: 18px;
		position: relative;
		margin-right: 5px;
		padding-right: 11px;
		color: ${({ theme }) => theme.baseColors.baseYellow};
		font-family: 'OpenSansBold';
		&:before {
			display: none;
		}
	}
	${({ active }) =>
		!active &&
		css`
			color: green;

			& > ${Link} {
				color: ${({ theme }) => theme.colors.text.blackLight};
				cursor: pointer;
				pointer-events: auto;
				position: relative;
				font-family: 'OpenSansMedium';
				&:hover {
					color: ${({ theme }) => theme.colors.bg.baseYellow};
				}
				&:before {
					content: '/';
					position: absolute;
					font-size: 14px;
					display: block !important;
					right: 0;
				}
			}
		`}
`;

const Link = styled.a`
	font-size: 14px;
	color: ${({ theme }) => theme.colors.text.blackLight};
	font-family: 'OpenSansMedium';
	margin-top: 8px;
`;
type BreadcrumbNavProps = {
	breadcrumbList: Array<list>;
	Heading?: string;
	RouteLink?: string;
	SubHeading?: string;
	OnlyHeading?: string;
};

type list = {
	id?: string | number;
	label?: string | number;
	route?: string;
	active?: boolean;
};

function BreadcrumbNav({
	Heading,
	breadcrumbList,
	SubHeading,
	OnlyHeading,
	RouteLink,
}: BreadcrumbNavProps): ReactElement {
	const history = useHistory();
	const navigateBack = RouteLink;
	const handleRoute = (val, RouteLink) => {
		if (navigateBack != null && navigateBack != '' && navigateBack != undefined) {
			history.push(navigateBack);
		} else {
			history.goBack();
		}
	};
	return (
		<ListWrapper className={SubHeading ? 'subHeadingPad' : ''}>
			{OnlyHeading ? (
				<Text as="h1" fontSize={18} fontFamily="OpenSansBold" color="text.SubHeading">
					{OnlyHeading}
				</Text>
			) : (
				<Flex alignItems="center">
					<Button onClick={(e) => handleRoute(e, navigateBack)}>
						<Icon.LeftArrowIcon title="LeftArrowIcon" />
					</Button>
					<Text as="h1" fontSize={18} fontFamily="OpenSansBold" color="text.black04">
						{Heading}
					</Text>
				</Flex>
			)}
			<Flex flexWrap="wrap" className="breadcrumbItems">
				{breadcrumbList
					? breadcrumbList.map((item) => (
							<ListItem key={item.id} active={item.active}>
								{item.route != undefined ? (
									<Link onClick={() => history.push(String(item.route))}>{item.label}</Link>
								) : (
									<Link>{item.label}</Link>
								)}
							</ListItem>
					  ))
					: null}
			</Flex>
			<Flex alignItems="center">
				{SubHeading ? (
					<Text
						as="h1"
						fontSize={18}
						marginTop={3}
						fontFamily="OpenSansBold"
						color="text.SubHeading"
					>
						{SubHeading}
					</Text>
				) : null}
			</Flex>
		</ListWrapper>
	);
}

export { BreadcrumbNav };
