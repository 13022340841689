import { UserBody } from 'admin/dashboardVenue/styles';
import ReasonForRejectionModal from 'admin/orderRequest/reasonForRejection';
import {
	dcUserPermissionList,
	OrderStatuses,
	pageNavigators,
} from 'models/enum-constants/common.constants';
import Iget from 'models/Iget';
import Ipost from 'models/Ipost';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory } from 'react-router-dom';
import { Box, Flex, Text } from 'rebass/styled-components';
import { BreadcrumbNav, Button, Footer, Header, Loader, Search, TableList } from 'ui/components';
import icons from 'ui/icons';
import { bindPagination, FilterTableList, Modal, Pagination, Sidebar } from 'ui/patterns';
import urls from 'utils/create.url';
import helper from 'utils/helper.functions';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import utilityFunctions from 'utils/utility.functions';
import { BreadcrumbHolder, MainContent, PageSearchHolder } from './styles';
import { queryCache } from 'react-query';
import moment from 'moment';
import { Input } from '@rebass/forms';

function OrderList(): ReactElement {
	const history = useHistory();
	const ref = useRef(null);
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const breadcrumbNav = [
		{ id: '01', label: 'Dashboard', route: '/admin/seller-dashboard', active: false },
		{ id: '02', label: 'Order Management', active: true },
	];
	var [orderList, updateOrderList] = useState<any>([]);
	const selectedDC =
		localStorage.getItem('selectedLocation') == null ||
			localStorage.getItem('selectedLocation') == undefined ||
			localStorage.getItem('selectedLocation') == ''
			? ''
			: localStorage.getItem('selectedLocation');
	const [modalText, setmodalText] = useState('');
	const [showPopup, setShowModal] = useState(false);
	const [entityID, setEntityId] = useState();
	const [isLoading, setLoading] = useState(false);
	const [action, setAction] = useState('');
	const [showReasonForRejectionModal, setShowReasonForRejectionModal] = useState(false);
	const toggleReasonForRejectionModal = () => {
		setShowReasonForRejectionModal(!showReasonForRejectionModal);
	};
	const [page, setPage] = React.useState(1);
	let showingPageCount = 5;
	const itemsPerPage = 10;
	var [pageList, setPageList] = React.useState([...Array(showingPageCount)].map((_, i) => i + 1));
	const [firstCheckBox, setFirstCheckBox] = useState(true);
	const [secondCheckBox, setSecondCheckBox] = useState(false);
	const [filterList, setFilterList] = useState<any>([]);
	const [keyList, udateKeyList] = useState<any>([]);
	const [searchTerm, setSearchTerm] = useState('');
	const initialURLObj = {
		url: `orders?`,
		orderConditionalFilter: true,
		pageSize: itemsPerPage,
		ctx: { currentPage: page, Type: '' },
		fieldsOpt: [] as any,
		fields: [
			{
				field_name: 'dcid',
				field_value: selectedDC!,
			},
			{
				field_name: 'status',
				field_value: `pending_approval`,
				field_conditionType: 'neq',
			},
			{
				field_name: 'status',
				field_value: `rejected`,
				field_conditionType: 'neq',
			},
			{
				field_name: 'status',
				field_value: `expired`,
				field_conditionType: 'neq',
			},
			{
				field_name: 'status',
				field_value: `approved`,
				field_conditionType: 'neq',
			},
			{
				field_name: 'status',
				field_value: `holded`,
				field_conditionType: 'neq',
			},
		],
	};
	const [urlObj, setURLObj] = React.useState(initialURLObj);
	function updateAPIkey(urlObj) {
		let key = 'ordersManagent' as string;
		key += urlObj && urlObj?.ctx && urlObj.ctx?.currentPage ? urlObj.ctx.currentPage : '';
		filterList.sort((a, b) => a.localeCompare(b));
		filterList.forEach((data) => {
			key += data;
		});
		key += searchTerm && searchTerm.length > 0 ? searchTerm : '';
		if (keyList.indexOf(key) === -1) keyList.push(key);
		return key;
	}
	const [url, setURL] = React.useState(urls.GetSearchUrl(urlObj));
	const [key, setKey] = React.useState(updateAPIkey(urlObj));
	const obj: Partial<Iget> = {
		params: {},
		url: url,
		enabled: true,
		caching: true,
		key: key,
	};
	var qry = hooks.useQuery(obj);
	if (JSON.parse(JSON.stringify(localStorage.getItem('refetchOnOrderReload')))) {
		localStorage.removeItem('refetchOnOrderReload');
		queryCache.clear();
	}
	useEffect(() => {
		qry.data && qry.data.items && updateOrderList(qry.data.items);
	}, [qry.data && qry.data.items]);

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			setFilteredData(searchTerm);
		}, 1000);

		return () => clearTimeout(delayDebounceFn);
	}, [searchTerm]);

	const setFilteredData = (val) => {
		setLoading(true);
		(ref.current as any)?.blur();
		let urlUpdate = initialURLObj;
		filterList.map((data) => {
			const fieldData = {
				field_name: 'status',
				field_value: data,
				field_conditionType: 'eq',
			};
			urlUpdate.fieldsOpt.push(fieldData);
		});
		const searchData = val;
		if (val && val.length > 0) {
			const fieldData1 = {
				field_name: 'supplier_order_inc_id',
				field_value: searchData,
				field_conditionType: 'like',
			};
			urlUpdate.fieldsOpt.push(fieldData1);
			const fieldData2 = {
				field_name: 'total_qty_ordered',
				field_value: searchData,
				field_conditionType: 'like',
			};
			urlUpdate.fieldsOpt.push(fieldData2);
			const fieldData3 = {
				field_name: 'subtotal',
				field_value: searchData,
				field_conditionType: 'like',
			};
			urlUpdate.fieldsOpt.push(fieldData3);

			const fieldData4 = {
				field_name: 'venue_name',
				field_value: searchData,
				field_conditionType: 'like',
			};
			urlUpdate.fieldsOpt.push(fieldData4);
			// const fieldData5 = {
			// 	field_name: 'main_table.created_at',
			// 	field_value: searchData,
			// 	field_conditionType: 'eq'
			// };
			// urlUpdate.fieldsOpt.push(fieldData5);
		}
		setURLObj(urlUpdate);
		if (page !== 1) {
			setPage(1);
		} else {
			setURL(urls.GetSearchUrl(urlUpdate));
			window.scroll({ top: 0, left: 0, behavior: 'smooth' });
			setKey(updateAPIkey(urlUpdate));
		}
		setLoading(false);
	};
	const deleteOrder = (status, id) => {
		setEntityId(id);
		setAction(status);
		toggleReasonForRejectionModal();
	};
	function showConfirmationModal(action, text, id) {
		setEntityId(id);
		setmodalText(text);
		setShowModal(!showPopup);
	}
	const orderAcceptRejectSubmit = (action, id, e) => {
		if (action == OrderStatuses.Confirmed) {
			setAction(action);
			showConfirmationModal(action, 'Are you sure you want to Accept this Order?', id);
		} else if (action === OrderStatuses.Shipped) {
			e.stopPropagation();
			setAction(action);
			showConfirmationModal(
				action,
				'Are you sure wants to change the status of this order to Shipped?',
				id
			);
		}
	};
	const bindAction = (status, entity_id) => {
		switch (status) {
			case OrderStatuses.Pending:
				return (
					<Text>
						{utilityFunctions.isGranted(dcUserPermissionList.OrderManagementUpdate) && (
							<Button
								variant="editIcon"
								onClick={(e) => orderAcceptRejectSubmit('confirmed', entity_id, e)}
							>
								<icons.Tick />
							</Button>
						)}
						{utilityFunctions.isGranted(dcUserPermissionList.OrderManagementUpdate) && (
							<Button
								variant="deleteIcon"
								onClick={() => deleteOrder(OrderStatuses.Rejected, entity_id)}
							>
								<icons.CloseIcon />
							</Button>
						)}
					</Text>
				);
			case OrderStatuses.Rejected:
				return (
					<Box
						as="slot"
						sx={{
							display: 'inline-block',
							color: 'white',
							bg: 'red',
							px: 2,
							py: 0.9,
							borderRadius: 9999,
							height: 30,
						}}
					>
						Rejected
					</Box>
				);
			case OrderStatuses.Cancelled:
				return (
					<Box
						as="slot"
						sx={{
							display: 'inline-block',
							color: 'white',
							bg: 'processingBadge',
							px: 2,
							py: 0.9,
							borderRadius: 9999,
							height: 30,
						}}
					>
						Cancelled
					</Box>
				);
			case OrderStatuses.Confirmed:
				return (
					<Box
						as="slot"
						sx={{
							display: 'inline-block',
							color: 'white',
							bg: 'confirmedBadge',
							px: 2,
							py: 0.9,
							borderRadius: 9999,
							height: 30,
						}}
					>
						Confirmed
					</Box>
				);
			case OrderStatuses.Disputed:
				return (
					<Box
						as="slot"
						sx={{
							display: 'inline-block',
							color: 'white',
							bg: 'deliveredWithIssue',
							px: 2,
							py: 0.9,
							borderRadius: 9999,
							height: 30,
						}}
					>
						Disputed
					</Box>
				);
			case OrderStatuses.Delivered:
				return (
					<Box
						as="slot"
						sx={{
							display: 'inline-block',
							color: 'white',
							bg: 'delivered',
							px: 2,
							py: 0.9,
							borderRadius: 9999,
							height: 30,
						}}
					>
						Delivered
					</Box>
				);
			case OrderStatuses.Shipped:
				return (
					<Box
						as="slot"
						sx={{
							display: 'inline-block',
							color: 'white',
							bg: 'shipped',
							px: 2,
							py: 0.9,
							borderRadius: 9999,
							height: 30,
						}}
					>
						Shipped
					</Box>
				);

			default:
				break;
		}
	};
	const onSubmitReason = (reasonForRejection, status) => {
		setLoading(true);
		if (action == OrderStatuses.Rejected) {
			const payload = {
				entity_id: entityID,
				state: OrderStatuses.Cancelled,
				status: action,
				status_histories: [
					{
						comment: `${reasonForRejection != ''
							? (firstCheckBox ? 'Product unavailable:' : 'Delivery unavailable:') +
							reasonForRejection
							: ''
							}`,
						entity_name: 'order',
						is_customer_notified: 1,
						is_visible_on_front: 1,
						parent_id: entityID,
						status: action,
					},
				],
				extension_attributes: {
					receiver_email: orderList.filter((x) => x.entity_id == entityID)[0].customer_email,
				},
			};
			const obj: Partial<Ipost> = {
				data: { entity: payload },
				params: {},
				url: `orders/create`,
				message: `Order(s) rejected successfully`,
			};
			try {
				api.put(obj).then((data) => {
					if (data !== undefined) {
						window.scroll({ top: 0, left: 0, behavior: 'smooth' });
					}
					if (keyList && keyList.length > 0) {
						keyList.forEach((key) => {
							queryCache.removeQueries(key, { exact: true });
						});
					}
					keyList.splice(0, keyList.length);
					keyList.push(key);
					setLoading(false);
				});
			} catch (e) {
				setLoading(false);
			}
			setShowReasonForRejectionModal(!showReasonForRejectionModal);
		} else if (action == OrderStatuses.Confirmed) {
			const payload = {
				entity_id: entityID,
				state: OrderStatuses.Processing,
				status: action,
			};
			const obj: Partial<Ipost> = {
				data: { entity: payload },
				params: {},
				url: `orders/create`,
				message: `Order(s) accepted successfully`,
			};
			try {
				setLoading(true);
				api.put(obj).then((data) => {
					if (data !== undefined) {
						qry.refetch().then(function (data) {
							if (data && data?.items?.length > 0) updateOrderList(data.items);
							setLoading(false);
						});
						if (keyList && keyList.length > 0) {
							keyList.forEach((key) => {
								queryCache.removeQueries(key, { exact: true });
							});
						}
						keyList.splice(0, keyList.length);
						keyList.push(key);
						window.scroll({ top: 0, left: 0, behavior: 'smooth' });
					}
				});
			} catch (e) {
				setLoading(false);
			}
			setShowModal(!showPopup);
		} else {
			let itemSelected = orderList.filter((x) => x.entity_id == entityID);
			let item = [] as any;

			if (itemSelected) {
				itemSelected[0].items &&
					itemSelected[0].items.length > 0 &&
					itemSelected[0].items.map((data) => {
						let itemDetails = {
							order_item_id: 0,
							qty: 0,
						};
						itemDetails.order_item_id = data.item_id;
						itemDetails.qty = data.qty_ordered;
						item.push(itemDetails);
					});
			}
			const payload = {
				items: item,
				notify: true,
				tracks: [],
			};
			const obj: Partial<Ipost> = {
				data: payload,
				params: {},
				url: `order/${entityID}/ship`,
				message: `Order(s) shipped successfully`,
			};
			try {
				api.post(obj).then(function (data) {
					if (data !== undefined) {
						window.scroll({ top: 0, left: 0, behavior: 'smooth' });
					}
					if (keyList && keyList.length > 0) {
						keyList.forEach((key) => {
							queryCache.removeQueries(key, { exact: true });
						});
					}
					keyList.splice(0, keyList.length);
					keyList.push(key);
					setLoading(false);
				});
			} catch (e) {
				setLoading(false);
			}
			setShowModal(!showPopup);
		}
	};
	async function closePopup() {
		await setShowModal(!showPopup);
	}
	const setReasonForRejection = (comment, event) => {
		if (comment === 'delivery') {
			if (event.target.checked) {
				setFirstCheckBox(false);
				setSecondCheckBox(true);
			} else {
				setFirstCheckBox(true);
				setSecondCheckBox(false);
			}
		} else if (comment === 'product') {
			if (event.target.checked) {
				setFirstCheckBox(true);
				setSecondCheckBox(false);
			} else {
				setFirstCheckBox(false);
				setSecondCheckBox(true);
			}
		}
	};
	const cancel = () => {
		setShowReasonForRejectionModal(!showReasonForRejectionModal);
		setFirstCheckBox(true);
		setSecondCheckBox(false);
	};
	const onChangeFilter = (status, e) => {
		switch (status) {
			case OrderStatuses.Pending:
				{
					setStatus(e, status);
				}
				break;
			case OrderStatuses.Confirmed:
				{
					setStatus(e, status);
				}
				break;
			case OrderStatuses.Rejected:
				{
					setStatus(e, status);
				}
				break;
			case OrderStatuses.Cancelled:
				{
					setStatus(e, status);
				}
				break;
			case OrderStatuses.Disputed:
				{
					setStatus(e, status);
				}
				break;
			case OrderStatuses.Delivered:
				{
					setStatus(e, status);
				}
				break;
			case OrderStatuses.Shipped:
				{
					setStatus(e, status);
				}
				break;
			default:
				break;
			// case OrderStatuses.Approved: {
			// 	setStatus(e, status)
			// }
			// 	break;
			// case OrderStatuses.Onhold: {
			// 	setStatus(e, status)
			// }
			// 	break;
		}
	};
	const setStatus = (e, status) => {
		if (e.target.checked) {
			if (!filterList.includes(status)) {
				filterList.push(status);
			}
		} else if (filterList.includes(status)) {
			let index = filterList.indexOf(status);
			filterList.splice(index, 1);
		}
		setFilteredData(searchTerm);
	};

	var totalPages = Math.ceil((qry.data && qry.data.items && qry.data.total_count) / itemsPerPage);
	const count = qry.data && qry.data.items && (qry.data.total_count ?? 0);

	const handlePagination = (action) => {
		if (action == pageNavigators.prev) setPageData(page - 1);
		else if (action == pageNavigators.next) setPageData(page + 1);
		if (action == pageNavigators.fastPrev) setPageData(1);
		//pageList[0] - showingPageCount
		else if (action == pageNavigators.fastNext) setPageData(totalPages); //pageList.slice(-1).pop()! + 1
	};

	const bindPages = () => {
		return bindPagination(getPages(page, false).pages, page, setPage);
	};

	const setPageData = (page) => {
		if (page <= totalPages && page > 0) {
			setPage(() => page);
			setPageList(getPages(page).pages);
		}
	};

	function getPages(currentPage: number = 1, repagination: boolean = true) {
		let pageCount = totalPages;

		if (repagination) {
			// will only enter this block for prev & next
			//for checking if in last section of pagination
			let isLastSection = pageCount - currentPage < showingPageCount ? true : false;
			if (!pageList.includes(currentPage))
				//if current pagination list doesnt contains current page,
				// then the list will get updated with new values.ie pagination numbers will change
				setPageArray(currentPage, pageCount, isLastSection);
		} else if (pageCount < showingPageCount)
			// if total page count less than numberOfPages, then page count
			//will be max page
			pageList = Array.from(Array(pageCount + 1 - 1).keys()).map((i) => 1 + i);
		// return object with all pager properties required by the view
		return {
			pages: pageList,
		};
	}

	const setPageArray = (currentPage, pageCount, isLastSection = false) => {
		//if page is greatr than list items, then new start will be last list item + 1
		let val =
			currentPage > pageList[0] ? pageList.slice(-1).pop()! : pageList[0] - (showingPageCount + 1);
		let startPage = val + 1;
		// if last section, then end page will be pagecount
		let endPage = isLastSection && pageList[0] < currentPage ? pageCount : val + showingPageCount;
		// pagelist updating with new values
		pageList = Array.from(Array(endPage + 1 - startPage).keys()).map((i) => startPage + i);
	};
	useEffect(() => {
		if (page != qry?.data?.search_criteria?.current_page && !qry.isLoading) {
			let urlUpdate = urlObj;
			urlUpdate.ctx.currentPage = page;
			setURLObj(urlUpdate);
			setURL(urls.GetSearchUrl(urlUpdate));
			window.scroll({ top: 0, left: 0, behavior: 'smooth' });
			setKey(updateAPIkey(urlUpdate));
		}
	}, [[page]]);

	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	};
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={() => onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={() => onclickHambrgBtn()}></Sidebar>
			{qry.isLoading || isLoading ? <Loader></Loader> : null}
			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbHolder>
					<BreadcrumbNav Heading="Orders" breadcrumbList={breadcrumbNav} />
					{/* <ButtonWrapper>
						<Button variant="editIcon">
							<icons.Tick />
							<div>Accept</div>
						</Button>
						<Button variant="redOutline">
							<icons.CloseIcon />
							<div>Reject</div>
						</Button>
					</ButtonWrapper> */}
				</BreadcrumbHolder>

				<PageSearchHolder>
					<Box as="section">
						<icons.searchIcon />

						<Input
							ref={ref}
							onChange={(e) => setSearchTerm(e.currentTarget.value)}
							type={'orderList'}
							placeholder="Search for Order Id, Store Name, Quantity ..."
						/>
					</Box>
					<Box as="section">
						<FilterTableList onChangeFilter={onChangeFilter}></FilterTableList>
					</Box>
				</PageSearchHolder>
				<TableList>
					<table>
						<thead>
							<tr>
								{/* <th className="checked">
									<Label>
										<Checkbox id="remember" name="remember" />
									</Label>
								</th> */}
								<th>Order Id</th>
								<th>Store Name</th>
								<th>Date</th>
								<th>No. of Items</th>
								<th>Delivery Date</th>
								<th>Order Total</th>
								<th className="action">Action</th>
							</tr>
						</thead>

						<tbody>
							{orderList && orderList.length > 0 ? (
								orderList.map(
									({
										entity_id,
										increment_id,
										total_qty_ordered,
										subtotal,
										created_at,
										extension_attributes,
										status,
									}) => {
										let deliverySlot = extension_attributes?.delivery_slot
											? extension_attributes?.delivery_slot
											: '';
										deliverySlot = deliverySlot.split(' ');
										let deliverySlotDay = null as any;
										let deliverySlotDate = null as any;
										if (deliverySlot.length === 2) {
											deliverySlotDate = moment(deliverySlot[1], 'DD-MM-YYYY');
											deliverySlotDate = helper.formatDate(deliverySlotDate.toDate());
											deliverySlotDay = deliverySlot[0];
										}
										return (
											<tr
												key={entity_id}
												onClick={() => history.push(`orderRequest?id=${entity_id}`)}
											>
												{/* {status === 'pending' ?
												<td className="checked">
													<Label>
														<Checkbox id="remember" name="remember" />
													</Label>
												</td> :
												<td className="checked">
												</td>} */}
												{/* <td className="checked">
											</td> */}
												<td data-label="Order Id">
													<Text as="h1" fontSize={15} fontFamily="OpenSansBlack" color="text.black">
														<span>{increment_id}</span>
													</Text>
												</td>
												<td data-label="Venue Name">
													<Text
														as="h1"
														fontSize={15}
														fontFamily="OpenSansMedium"
														color="text.black"
													>
														{extension_attributes.venue_name}
													</Text>
												</td>
												<td data-label="Date">
													<Text
														as="h1"
														fontSize={15}
														fontFamily="OpenSansMedium"
														color="text.black"
													>
														{helper.formatDate(created_at)}
													</Text>
												</td>
												<td data-label="No. of Items">
													<Text
														as="h1"
														fontSize={15}
														fontFamily="OpenSansMedium"
														color="text.black"
													>
														{total_qty_ordered}
													</Text>
												</td>
												<td data-label="Delivery Date">
													<Text
														as="h1"
														fontSize={15}
														fontFamily="OpenSansMedium"
														color="text.black"
													>
														{deliverySlot && deliverySlot.length === 2
															? `${deliverySlotDay} ${deliverySlotDate}`
															: 'NIL'}
													</Text>
												</td>
												<td data-label="Order Total">
													<Text
														as="h1"
														fontSize={15}
														fontFamily="OpenSansMedium"
														color="text.black"
													>
														${parseFloat(subtotal ? subtotal : 0).toFixed(2)}
													</Text>
												</td>

												<td
													className="action"
													onClick={(e) => {
														e.stopPropagation();
													}}
													data-label="Action"
												>
													{status === OrderStatuses.Confirmed && (
														<Button
															variant="secondary"
															onClick={(e) =>
																orderAcceptRejectSubmit(OrderStatuses.Shipped, entity_id, e)
															}
														>
															Ship It
														</Button>
													)}
													{bindAction(status, entity_id)}
												</td>
											</tr>
										);
									}
								)
							) : (
								<UserBody>
									{/* <Icon.searchIcon title="No Data Found" /> */}
									<Text fontSize={18} fontWeight="bold" color="#5C5E57" textAlign="center">
										No Orders Found
									</Text>
								</UserBody>
							)}
						</tbody>
					</table>
				</TableList>
				{orderList && orderList.length > 0 && (
					<Flex>
						{/* commenting this due to some error in code , uncomment for pagination option */}
						<Pagination
							handlePagination={handlePagination}
							bindPagination={bindPages}
							page={page}
							total={orderList && count}
							itemsPerPage={itemsPerPage}
						/>
					</Flex>
				)}
			</MainContent>
			<SweetAlert
				show={showPopup}
				confirmBtnText="Confirm"
				onConfirm={() => onSubmitReason('', action)}
				showCancel={true}
				onCancel={closePopup}
				title={'Confirmation Box'}
			>
				{() => <form>{modalText}</form>}
			</SweetAlert>
			{/* Footer Session */}
			<Footer></Footer>
			<Modal
				width="500px"
				showModal={showReasonForRejectionModal}
				setShowModal={setShowReasonForRejectionModal}
			>
				<ReasonForRejectionModal
					setReasonForRejection={setReasonForRejection}
					firstCheckBox={firstCheckBox}
					secondCheckBox={secondCheckBox}
					onSubmitReason={onSubmitReason}
					cancel={() => {
						cancel();
					}}
				/>
			</Modal>
		</>
	);
}

export default OrderList;
