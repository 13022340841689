import React, { ReactElement } from 'react';
import { Text } from 'rebass/styled-components';
import { Button, TableList } from 'ui/components';
import icons from 'ui/icons';
import { UserListHolder } from './styles';

function AssignDistributionList(prop): ReactElement {
	if (prop.dcRoles && prop.dcRoles.length > 0) {
		return (
			<UserListHolder>
				<TableList>
					<table>
						<thead>
							<tr>
								<th>Distribution Center</th>
								<th>Role</th>
								<th className="action">Action</th>
							</tr>
						</thead>

						<tbody>
							{prop.dcRoles &&
								prop.dcRoles.map((item, i) => {
									return (
										<tr key={i}>
											<td data-label="DC Name">
												<Text as="h2" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
													{item && item.dcName.value}
												</Text>
											</td>
											<td data-label="Role">
												<Text as="h2" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
													{item && item.role.value}
												</Text>
											</td>

											<td data-label="Action" className="action">
												<Text>
													<Button variant="deleteIcon"
														onClick={() => {
															prop.deleteDCRole(item.dcName, item.role);
														}}>
														<icons.DeleteIcon />
													</Button>
													{/* <Button variant="editIcon">
														<icons.textEditIcon />
													</Button> */}
												</Text>
											</td>
										</tr>
									);
								})}
						</tbody>
					</table>
				</TableList>
			</UserListHolder>
		);
	} else {
		return <></>;
	}
}

export { AssignDistributionList };
