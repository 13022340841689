import React, { ReactElement, useState } from 'react';
import { Box, Button, Flex, Text } from 'rebass/styled-components';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import styled, { css } from 'styled-components';
import * as Icon from '../../icons';
const StyledSearch = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	span {
		position: absolute;
		z-index: 1;
		left: 22px;
	}
`;
const SearchAutoSuggestionHolder = styled.div`
	width: 100px;
	position: relative;
	div {
		width: auto;
	}
`;
const PageSearch = styled.input`
	background: ${({ theme }) => theme.colors.bg.white};
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	width: 100%;
	height: 60px;
	border-radius: 8px;
	border: none;
	outline: none;
	box-shadow: none;
	padding: 0 20px 0 50px;
	font-family: 'OpenSansMedium';
	font-size: 16px;
	border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
	@media screen and (max-width: 767px) {
		height: 50px;
	}
	&::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: ${({ theme }) => theme.colors.text.body};
		color: rgba(98, 98, 98, 0.4);
		font-size: 14px;
	}
	&::-moz-placeholder {
		/* Firefox 19+ */
		color: ${({ theme }) => theme.colors.text.body};
		color: rgba(98, 98, 98, 0.4);
		font-size: 14px;
	}
	&:-ms-input-placeholder {
		/* IE 10+ */
		color: ${({ theme }) => theme.colors.text.body};
		color: rgba(98, 98, 98, 0.4);
		font-size: 14px;
	}
	&:-moz-placeholder {
		/* Firefox 18- */
		color: ${({ theme }) => theme.colors.text.body};
		color: rgba(98, 98, 98, 0.4);
		font-size: 14px;
	}
`;
const SimpleBarClose = styled.div`
	background-color: ${({ theme }) => theme.baseColors.primary};
	color: white;
	padding: 2px 10px;
	position: absolute;
	float: right;
	right: 16px;
	cursor: pointer;
	text-align: center;
	z-index: 1;
	text-decoration: none;
	display: inline-block;
	font-size: 14px;
	border-radius: 4px;
	top: 16px;
`;
const SearchAutoSuggestionDetails = styled.div<SearchAutoSuggestionDetailsProps>`
	padding: 58px 16px 0 16px;
	border-top: 0;
	border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
	/* height: calc(100% - 60px); */
	border-radius: 0 0 16px 16px;
	overflow: hidden;
	display: none;
	position: absolute;
	width: calc(100% - 54px) !important;
	margin: -1px 27px 0;
	z-index: 1;
	background: ${({ theme }) => theme.baseColors.white}!important;
	@media screen and (max-width: 960px) {
		width: 100% !important;
		margin: -6px 0 0 0;
	}
	.ScrollbarsCustom-Track {
		background: rgba(222, 222, 222, 0.24) !important;
		width: 8px !important;
		.ScrollbarsCustom-Thumb {
			background: ${({ theme }) => theme.baseColors.primary}!important;
		}
	}

	article {
		margin: 0;
		padding: 15px;
		border-bottom: none;
		background: ${({ theme }) => theme.colors.bg.lightYellow};
		border-radius: 8px;
		margin: 0 0 10px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		div {
			h1 {
				margin-bottom: 2px;
			}
		}
		button {
			cursor: pointer;
			width: 34px;
			height: 34px;
			max-width: 34px;
			flex:0 0 34px;
			border-radius: 8px;
			background: ${({ theme }) => theme.colors.bg.white};
			border: 1px solid ${({ theme }) => theme.baseColors.primary};
			padding: 0;
			outline: none;
			margin:0!important;
			span {
				width: 34px;
				height: 34px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			svg {
				path {
					fill: ${({ theme }) => theme.colors.text.black};
				}
			}
			&:hover {
				background: ${({ theme }) => theme.baseColors.primary};
				svg {
					path {
						fill: ${({ theme }) => theme.baseColors.white};
					}
				}
			}
		}
	}
	${({ show }) =>
		!show &&
		css`
			display: none;
		`}
	${({ show }) =>
		show &&
		css`
			display: block;
		`}
`;
type SearchAutoSuggestionDetailsProps = {
	hide?: boolean;
	show?: boolean;
};
type TProps = {
	hide?: boolean;
	show?: boolean;
	setFilteredData?: any;
	subProductList?: any;
	id?: string;
	addSubstitutionProducts?: any;
};
const rewardsBalanceList = [
	{
		id: 1,
		orderItem: 'Roasted coffee',
		orderID: 'SKU 21732141',
	},
	{
		id: 2,
		orderItem: 'Roasted Tea',
		orderID: 'SKU 21732141',
	},
	{
		id: 3,
		orderItem: 'Roasted coffee',
		orderID: 'SKU 21732141',
	},
	{
		id: 4,
		orderItem: 'Roasted Tea',
		orderID: 'SKU 21732141',
	},
];

function SearchAutoSuggestion({
	show,
	setFilteredData,
	subProductList,
	id,
	addSubstitutionProducts,
}: TProps): ReactElement {
	const [showHideAutoSuggest, setShowHideAutoSuggest] = useState(false);
	const onSelectSubStituteProduct = function (setValue?) {
		setShowHideAutoSuggest(setValue);
	};
	const setValue = (e: React.FormEvent<HTMLInputElement>) => {
		setFilteredData && setFilteredData(e.currentTarget.value);
	};
	return (
		<>
			{show ? (
				<SearchAutoSuggestionHolder>
					<StyledSearch>
						<Icon.searchIcon />
						<PageSearch
							onChange={(e) => setValue(e)}
							type="search"
							placeholder="Search for product"
							onFocus={() => onSelectSubStituteProduct(true)}
						></PageSearch>
					</StyledSearch>
					<SearchAutoSuggestionDetails show={showHideAutoSuggest}>
						<SimpleBarClose onClick={() => onSelectSubStituteProduct(false)}>Close</SimpleBarClose>
						<SimpleBar style={{ maxHeight: 244 }}>
							{subProductList && subProductList.length > 0 ?
								(subProductList
									.filter((x) => x.sku != id)
									.map(({ name, sku, id,supplier_sku }) => (
										<Flex as="article" bg="white" p={[3, 4]} sx={{ alignItems: 'center' }} key={id}>
											<Box>
												<Text
													as="h1"
													fontFamily="OpenSansBold"
													fontSize={16}
													lineHeight="21px"
													color="text.black03"
													className="truncate"
												>
													{name}
												</Text>
												<Text
													as="h2"
													fontFamily="OpenSansRegular"
													fontSize={14}
													lineHeight="18px"
													color="text.black03"
													className="truncate"
												>
													{supplier_sku}
												</Text>
											</Box>
											<Button
												onClick={() => {
													addSubstitutionProducts(sku);
													onSelectSubStituteProduct(false);
												}}
											>
												<Icon.PlusIcon />
											</Button>
										</Flex>
									))) : (<Text
										fontSize={18} fontWeight="bold" color="#5C5E57" textAlign="center"
									>
										No products found
									</Text>)}
						</SimpleBar>
					</SearchAutoSuggestionDetails>
				</SearchAutoSuggestionHolder>
			) : (
				''
			)}
		</>
	);
}
export { SearchAutoSuggestion };
