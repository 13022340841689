import { Select } from '@rebass/forms';
import Iget from 'models/Iget';
import IgetEx from 'models/IgetEx';
import Ipost from 'models/Ipost';
import queryString from 'query-string';
import React, { ReactElement, useEffect, useState } from 'react';
import Autocomplete from 'react-autocomplete';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Text, Flex } from 'rebass/styled-components';
import { BreadcrumbNav, Button, Footer, Grid, Header, Loader, Stack, Toggle } from 'ui/components';
import * as Icon from 'ui/icons';
import { FormInput, FormTextarea, Sidebar } from 'ui/patterns';
import helperFunctions from 'utils/helper.functions';
import httpService from 'utils/http.service';
import pageConstants from 'utils/pageConstants';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import constants from '../../utils/constants';
import {
	AddUserHolder,
	AssignVenueHolder,
	AutocompleteHolder,
	FooterBtnBlock,
	MainContent,
	ProfileDetails,
} from './styles';

function AddNewVenue(prop): ReactElement {
	const history = useHistory();
	const location = useLocation();
	const [showPopup, setShowModal] = useState(false);
	const [action, setAction] = useState('');
	const [editParam, setEditParam] = useState(0);
	const [statusChecked, setStatusChecked] = useState(true);
	const [disableBtnSave, setDisableBtnSave] = useState(true);
	const parsed = queryString.parse(location.search);
	const hospId = localStorage.getItem('hospitalityId');
	const counties = JSON.parse(localStorage.getItem('Countries') || '{}');
	const [venueName, checkVenueName] = useState<any>();
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const validate = 'validateName';
	const [modalText, setmodalText] = useState('');
	const [state, setStateByCountry] = useState<any>();
	let venueNameUrl = `experion/venuenameexists/get/${venueName}/${hospId}`;
	const [isLoading, setIsLoading] = useState(false);
	const params = {
		pages: '1',
	};
	const validationObj: Partial<Iget> = {
		params: params,
		url: venueNameUrl,
		enabled: false,
		key: validate,
	};
	const qryVenue = hooks.useQuery(validationObj);
	let hospGrpId = 0;
	hospGrpId = parseInt(hospId ? hospId : '23');

	const parseQueryString = function () {
		// window.scroll({ top: 0, left: 0, behavior: 'smooth' });
		if (parsed.id) {
			setIsLoading(true);
			const id = parseInt(parsed.id);
			setDisableBtnSave(false);
			setEditParam(id);
			venueEditDetails
				.refetch()
				.then(function (data) {
					setValues(data);
					getStateByCountry(data.country);
					setStatusChecked(data.is_active);
					setIsLoading(false);
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	};

	//Auto complete for Address Begins

	const [autoAddress, setAutoAddress] = useState('');
	const [autoAddress404, setAutoAddress404] = useState(false);
	let [autoAddressList, setAutoAddressList] = useState<any>([]);
	const addInfo = 'autoAddress';
	const addDetails = 'autoDetails';
	const acHeaders = {
		'x-api-key': constants.placesAPIKey(),
		'x-client-id': constants.placesClientId(),
	};
	const autoparams = {
		count: '10',
		country: pageConstants.defaultCountry,
	};
	const addobj: Partial<IgetEx> = {
		headers: acHeaders,
		url: constants.placesAPIURL() + `key=`,
		key: addInfo,
		params: autoparams,
		enabled: false,
	};
	const addQry = hooks.useQuery(addobj);

	const addRobj: Partial<IgetEx> = {
		headers: acHeaders,
		url: constants.placesAPIURL() + `id=`,
		key: addDetails,
		params: autoparams,
		enabled: false,
	};

	useEffect(() => {
		addQry.data && setAutoAddressList([...addQry.data]);
	}, [addQry.data]);

	useEffect(() => {
		setAutoAddress404(false);
		setValues({
			id: values.id,
			name: values.name,
			hosp_id: values.hosp_id,
			venue_email: values.venue_email,
			description: values.description,
			address: values.address,
			zip_code: '',
			phone: values.phone,
			latitude: 0,
			longitude: 0,
			created_by: values.created_by,
			updated_by: values.updated_by,
			is_active: values.is_active,
			is_delete: values.is_delete,
			contact_name: values.contact_name,
			city: '',
			state: '',
			country: '',
			number_of_parked_orders: values.number_of_parked_orders,
			contact_person_phone: values.contact_person_phone,
			order_cost_limit: values.order_cost_limit,
		});
		autoAddressList = [];
		setAutoAddressList(autoAddressList);
		getAddressSuggestion(autoAddress);
	}, [autoAddress]);

	const addressOnSelect = function (item?) {
		let addressId = 0;
		if (autoAddressList.length > 0 && autoAddress.length > 4) {
			autoAddressList &&
				autoAddressList
					.filter((x) => x.FullAddress == item)
					.map((option) => (addressId = option.Id));
			values.address = item;
			setValues(values);
			if (addressId != 0) {
				addRobj.url = constants.placesAPIURL() + `id=${addressId}`;
				api.getEx(addRobj).then(function (res) {
					setDisableBtnSave(false);

					setValues({
						id: values.id,
						name: values.name,
						hosp_id: values.hosp_id,
						venue_email: values.venue_email,
						description: values.description,
						address: values.address,
						zip_code: res.Postcode,
						phone: values.phone,
						latitude: res.Latitude,
						longitude: res.Longitude,
						created_by: values.created_by,
						updated_by: values.updated_by,
						is_active: values.is_active,
						is_delete: values.is_delete,
						contact_name: values.contact_name,
						city: res.City,
						state: res.Suburb,
						country: res.Country,
						number_of_parked_orders: values.number_of_parked_orders,
						contact_person_phone: values.contact_person_phone,
						order_cost_limit: values.order_cost_limit,
					});
				});
			}
		}
	};

	// const addressSuggestion = function (item?) {
	// 	// return [{ label: 'apple' },{ label: 'banana' },{ label: 'pear' }];
	// };

	const getAddressSuggestion = async function (value?) {
		if (autoAddress != '' && autoAddress.length > 4) {
			addobj.url = constants.placesAPIURL() + `key=${autoAddress}`;
			await api.getEx(addobj).then(function (res) {
				if (res == undefined) {
					setAutoAddress404(true);
				} else {
					setAutoAddress404(false);
				}
				res && setAutoAddressList([...res]);
			});
		} else {
			setAutoAddressList([]);
		}
	};

	//Auto complete for Address Ends

	useEffect(() => {
		parseQueryString();
	}, [location]);
	const editVenuKey = 'editVenue';
	const EditVenueUrl = `experion/venue/get/${parsed.id}`;
	const item: Partial<Iget> = {
		params: params,
		url: EditVenueUrl,
		enabled: false,
		key: editVenuKey,
	};
	const venueEditDetails = hooks.useQuery(item);
	const [values, setValues] = useState({
		id: editParam ? editParam : 0,
		name: '',
		hosp_id: hospGrpId,
		venue_email: '',
		description: '',
		address: '',
		zip_code: '',
		phone: '',
		latitude: 0,
		longitude: 0,
		created_by: 'admin',
		updated_by: 'admin',
		is_active: true,
		is_delete: false,
		contact_name: '',
		city: '',
		state: '',
		country: 'select',
		number_of_parked_orders: 0,
		contact_person_phone: '',
		order_cost_limit: 0,
	});

	const userInfo = 'addVenue';
	// const url = 'directory/countries';
	// const obj: Partial<Iget> = {
	// 	params: params,
	// 	url: url,
	// 	enabled: true,
	// 	key: userInfo,
	// };
	// const qry = hooks.useQuery(obj);
	// const countries = qry.data;

	const breadcrumbNav = [
		{ id: '01', label: 'Home', route: 'user-dashboard', active: false },
		{
			id: '02',
			label: editParam ? 'Edit Store' : 'Add New Store',
			route: editParam ? `edit-venue?id=${parsed.id}` : 'add-new-venue',
			active: true,
		},
	];

	const [error, setError] = useState({
		venueNameError: '',
		emailAddressError: '',
		phoneNumberError: '',
		parkedOrdersError: '',
		contactNameError: '',
		contactPhoneError: '',
		streetAddressError: '',
		zipCodeError: '',
		cityError: '',
		stateError: '',
		countryError: '',
		orderCostLimitError: '',
	});
	const [mutatePost] = hooks.useMutation(userInfo);
	const handleChange = (val, name) => {
		const item = values;
		switch (name) {
			case 'venueName': {
				if (!val) {
					error.venueNameError = 'Store Name cannot be blank';
				} else {
					error.venueNameError = '';
				}
				setError(error);
				item.name = val;
				checkVenueName(val);
				break;
			}
			case 'emailAddress': {
				if (!val) {
					setError({ ...error, emailAddressError: 'Email Address cannot be blank' });
				} else {
					const validEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(val);
					if (validEmail) {
						error.emailAddressError = '';
					} else {
						error.emailAddressError = 'Please enter Valid Email Address';
					}
				}
				setError(error);
				item.venue_email = val;
				break;
			}
			case 'phoneNumber': {
				if (!val) {
					error.phoneNumberError = 'Phone cannot be blank';
				} else {
					const regex = pageConstants.phoneRegex;
					if (!regex.test(val)) {
						error.phoneNumberError = 'Invalid Phone';
					} else {
						error.phoneNumberError = '';
					}
				}
				setError(error);
				item.phone = val;
				break;
			}
			case 'NoOfparkedOrders':
				if (!val) {
					error.parkedOrdersError = 'Parked Orders cannot be empty';
				} else {
					error.parkedOrdersError = '';
				}
				setError(error);
				item.number_of_parked_orders = parseInt(val);
				break;
			case 'description':
				item.description = val;
				break;
			case 'contactName':
				if (!val) {
					error.contactNameError = 'Contact Name cannot be blank';
				} else {
					error.contactNameError = '';
				}
				setError(error);
				item.contact_name = val;
				break;
			case 'contactPhone':
				if (!val) {
					error.contactPhoneError = `Contact Person's Phone cannot be blank`;
				} else {
					const regex = pageConstants.phoneRegex;
					if (!regex.test(val)) {
						error.contactPhoneError = 'Invalid Phone';
					} else {
						error.contactPhoneError = '';
					}
				}
				setError(error);
				item.contact_person_phone = val;
				break;
			case 'streetAddress':
				if (!val) {
					error.streetAddressError = 'Street Address cannot be blank';
				} else {
					error.streetAddressError = '';
				}
				setError(error);
				item.address = val;
				break;
			case 'zipCode':
				if (!val) {
					error.zipCodeError = 'Zip Code cannot be blank';
				} else {
					error.zipCodeError = '';
				}
				setError(error);
				item.zip_code = val;
				break;
			case 'city':
				if (!val) {
					error.cityError = 'City cannot be blank';
				} else {
					error.cityError = '';
				}
				setError(error);
				item.city = val;
				break;
			case 'state':
				if (!val) {
					error.stateError = 'State cannot be blank';
				} else {
					error.stateError = '';
				}
				setError(error);
				item.state = val;
				break;
			case 'country':
				getStateByCountry(val.target.value);
				if (val.target.value == '-Select-') {
					error.countryError = 'Country cannot be blank';
				} else {
					error.countryError = '';
				}
				setError(error);
				item.country = val.target.value;
				item.state = '';
				break;
			case 'orderCostLimit':
				if (!val) {
					error.orderCostLimitError = 'Order Cost Limit cannot be blank';
				} else {
					error.orderCostLimitError = '';
				}
				setError(error);
				item.order_cost_limit = parseInt(val);
				localStorage.setItem('orderCostLimit', val);
				break;
			default:
				break;
		}
		setValues({
			...item,
		});
		//venueDetails.postVenue = item;
		if (
			values &&
			values.name &&
			values.venue_email &&
			values.phone &&
			values.number_of_parked_orders &&
			values.contact_name &&
			values.contact_person_phone &&
			values.address &&
			values.zip_code &&
			values.city &&
			values.state &&
			values.country != '-Select-' &&
			values.order_cost_limit &&
			error &&
			!error.venueNameError &&
			!error.emailAddressError &&
			!error.phoneNumberError &&
			!error.parkedOrdersError &&
			!error.contactNameError &&
			!error.contactPhoneError &&
			!error.streetAddressError &&
			!error.cityError &&
			!error.stateError &&
			!error.countryError &&
			!error.orderCostLimitError
		) {
			setDisableBtnSave(false);
		} else {
			setDisableBtnSave(true);
		}
	};
	async function submitData() {
		if (values && !editParam) {
			const payload = {
				address: values.address,
				city: values.city,
				contact_name: values.contact_name,
				contact_person_phone: helperFunctions.formatPhone(values.contact_person_phone),
				country: values.country,
				created_by: values.created_by,
				description: values.description,
				hosp_id: values.hosp_id,
				is_active: statusChecked,
				is_delete: values.is_delete,
				latitude: values.latitude,
				longitude: values.longitude,
				name: values.name.trim(),
				number_of_parked_orders: values.number_of_parked_orders,
				phone: helperFunctions.formatPhone(values.phone),
				state: values.state,
				updated_by: values.updated_by,
				venue_email: values.venue_email,
				zip_code: values.zip_code,
				order_cost_limit: values.order_cost_limit,
			};

			const obj: Partial<Ipost> = {
				data: { venue: payload },
				params: {},
				url: 'experion/venue/create',
				message: 'Store created successfully',
			};
			try {
				await mutatePost(obj).then((data) => {
					if (data !== undefined) {
						history.push('user-dashboard');
					} else {
						console.log('Error saving data');
					}
				});
			} catch (e) { }
		} else if (values && editParam) {
			const updatePayload = values;
			updatePayload.phone = helperFunctions.formatPhone(updatePayload.phone);
			updatePayload.name = updatePayload.name.trim();
			updatePayload.contact_person_phone = helperFunctions.formatPhone(
				updatePayload.contact_person_phone
			);
			const obj: Partial<Ipost> = {
				data: { venue: updatePayload },
				params: {},
				url: `experion/venue/update/${editParam}`,
				message: 'Store updated successfully',
			};
			try {
				api.put(obj).then((data) => {
					if (data !== undefined) {
						history.push('user-dashboard');
					}
				});
			} catch (e) { }
		}
	}
	const cancelVenueDetails = () => {
		history.push('user-dashboard');
	};
	const clearVenueDetails = () => {
		setAutoAddress('');
		setValues({
			id: editParam ? editParam : 0,
			name: '',
			hosp_id: hospGrpId,
			venue_email: '',
			description: '',
			address: '',
			zip_code: '',
			phone: '',
			latitude: 0,
			longitude: 0,
			created_by: '',
			updated_by: '',
			is_active: true,
			is_delete: false,
			contact_name: '',
			city: '',
			state: '',
			country: '',
			number_of_parked_orders: 0,
			contact_person_phone: '',
			order_cost_limit: 0,
		});
		setError({
			venueNameError: '',
			emailAddressError: '',
			phoneNumberError: '',
			parkedOrdersError: '',
			contactNameError: '',
			contactPhoneError: '',
			streetAddressError: '',
			zipCodeError: '',
			cityError: '',
			stateError: '',
			countryError: '',
			orderCostLimitError: '',
		});
		setStatusChecked(true);
		window.scroll({ top: 0, left: 0, behavior: 'smooth' });
	};

	const getStateByCountry = async (Id) => {
		// const code = countries.find((item) => item.full_name_english === Id);
		const stateUrl = `directory/countries/${Id}`;
		await httpService.GET({ url: stateUrl }).then((data) => {
			if (data.data) {
				setStateByCountry(data.data?.available_regions);
			} else {
				setError({ ...error });
			}
		});
	};

	const validateVenueName = async (name) => {
		if (parsed.id) {
			venueNameUrl = `experion/venuenameexists/get/${name}/${hospId}?id=${parsed.id}`;
		} else venueNameUrl = `experion/venuenameexists/get/${name}/${hospId}`;
		let isDuplicate = false;
		await httpService.GET({ url: venueNameUrl }).then((data) => {
			if (data.data) {
				isDuplicate = true;
				setError({ ...error, venueNameError: 'Store Name already exists' });
			} else {
				setError({ ...error, venueNameError: '' });
			}
		});
		return isDuplicate;
	};

	const obj1: Partial<Ipost> = {
		params: {},
		url: '',
	};
	const toggleBtnChange = (e) => {
		const checkedProp = e.currentTarget.checked;
		if (parsed.id) {
			obj1.url = `experion/venue/updatestatus/${parsed.id}/${checkedProp}`;
			obj1.message = 'Store status updated successfully';
			setStatusChecked(checkedProp);
			api.put(obj1).then(function (data) {
				if (data == undefined) {
					values.is_active = !checkedProp;
					setValues(values);
					setStatusChecked(!checkedProp);
				} else {
					values.is_active = checkedProp;
					setValues(values);
				}
			});
		} else {
			values.is_active = checkedProp;
			setStatusChecked(checkedProp);
			setValues(values);
		}
	};

	//popup

	async function confirmPopup(action) {
		switch (action) {
			case 'cancel':
				showConfirmationModal(action, 'Are you sure you want to cancel ?');
				break;
			case 'clear':
				showConfirmationModal(action, 'Are you sure you want to clear all data ?');
				break;
			case 'Save':
				setIsLoading(true);
				const is_Duplicate = await validateVenueName(values.name);
				setIsLoading(false);
				if (is_Duplicate) {
					window.scroll({ top: 0, left: 0, behavior: 'smooth' });
					return false;
				} else showConfirmationModal(action, 'Are you sure you want to update the store details');
				break;
			case 'Add':
				setIsLoading(true);
				const is_Duplicate1 = await validateVenueName(values.name);
				setIsLoading(false);
				if (is_Duplicate1) {
					window.scroll({ top: 0, left: 0, behavior: 'smooth' });
					return false;
				} else submitData();
				break;
			default:
				break;
		}
	}
	async function closePopup() {
		await setShowModal(!showPopup);
	}
	function showConfirmationModal(action, text) {
		setAction(action);
		setmodalText(text);
		setShowModal(!showPopup);
	}
	async function onSubmit() {
		if (action === 'clear') {
			clearVenueDetails();
		} else if (action === 'cancel') {
			cancelVenueDetails();
		} else if (action == 'Save') {
			submitData();
		}
		setShowModal(!showPopup);
	}
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	};
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={() => onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={() => onclickHambrgBtn()}></Sidebar>
			{isLoading ? <Loader></Loader> : null}
			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbNav
					Heading={editParam ? 'Edit Store' : 'Add New Store'}
					breadcrumbList={breadcrumbNav}
					RouteLink="user-dashboard"
				/>
				{/* Add User Details */}

				<AddUserHolder>
					<ProfileDetails>
						<Text as="h1" fontSize={18} fontFamily="OpenSansBold" color="text.SubHeading">
							Basic Information
						</Text>
						<Box as="form">
							<Grid gap={25} className="Grid">
								<FormInput
									autoFocus={true}
									validationMessage={error.venueNameError}
									validation={error.venueNameError ? 'error' : undefined}
									value={values && values.name}
									label="Store Name"
									name="venueName"
									required={true}
									type="text"
									onTextChange={(e) => handleChange(e, 'venueName')}
									autofocus={true}
									maxLength={20}
								//TODO: Remove if venue name validation is required
								// onBlur={(e) => {
								// 	validateVenueName(e.target.value);
								// }}
								/>
								<FormInput
									validationMessage={error.emailAddressError}
									validation={error.emailAddressError ? 'error' : undefined}
									value={values && values.venue_email}
									label="Email Address"
									required={true}
									name="emailAddress"
									type="email"
									onTextChange={(e) => handleChange(e, 'emailAddress')}
									maxLength={200}
								/>
								<FormInput
									validationMessage={error.phoneNumberError}
									validation={error.phoneNumberError ? 'error' : undefined}
									value={values && (values.phone ? values.phone.toString() : '')}
									label="Phone"
									name="phoneNumber"
									required={true}
									onTextChange={(e) => handleChange(e, 'phoneNumber')}
									maxLength={12}
									type="tel"
								/>
								<FormInput
									validationMessage={error.parkedOrdersError}
									validation={error.parkedOrdersError ? 'error' : undefined}
									value={
										values &&
										(values.number_of_parked_orders
											? values.number_of_parked_orders.toString()
											: '')
									}
									label="Number of Parked Orders"
									required={true}
									name="NoOfparkedOrders"
									onTextChange={(e) => handleChange(e, 'NoOfparkedOrders')}
									maxLength={10}
									type="number"
								/>
								{/* TODO: Add validation to ensure name is in firstname lastname format */}
								<FormInput
									validationMessage={error.contactNameError}
									validation={error.contactNameError ? 'error' : undefined}
									value={values && values.contact_name}
									label="Contact  Name"
									name="contactName"
									required={true}
									onTextChange={(e) => handleChange(e, 'contactName')}
									maxLength={20}
								/>
								<FormInput
									validationMessage={error.contactPhoneError}
									validation={error.contactPhoneError ? 'error' : undefined}
									value={
										values &&
										(values.contact_person_phone ? values.contact_person_phone.toString() : '')
									}
									label="Contact Person's Phone"
									required={true}
									name="contactPhone"
									onTextChange={(e) => handleChange(e, 'contactPhone')}
									maxLength={12}
									type="tel"
								/>
								{/* </Grid> */}
								{/* <Box mt={25}>
								<FormTextarea
									value={values && values.description}
									label="Description"
									name="description"
									onTextChange={(e) => handleChange(e, 'description')}
									maxLength={100}
								/>
							</Box> */}
								<Box className="amount">
									<p>{helperFunctions.getCurrencySymbol()}</p>
									<FormInput
										validationMessage={error.orderCostLimitError}
										validation={error.orderCostLimitError ? 'error' : undefined}
										value={
											values && (values.order_cost_limit ? values.order_cost_limit.toString() : '')
										}
										label="Order Cost Limit"
										required={true}
										name="orderCostLimit"
										onTextChange={(e) => handleChange(e, 'orderCostLimit')}
										maxLength={12}
										type="number"
									/>
								</Box>
								<Box
									className="status"
									sx={{
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<div>
										<label style={{ marginBottom: '0' }}>Status</label>
										<Toggle
											onChange={(e) => toggleBtnChange(e)}
											checked={values && statusChecked}
										/>
									</div>
								</Box>
							</Grid>
						</Box>
					</ProfileDetails>
					<AssignVenueHolder>
						<Text as="h1" fontSize={18} fontFamily="OpenSansBold" color="text.SubHeading">
							Legal address
						</Text>

						<Box as="form" mt={25}>
							<Box mb={25}>
								{/* https://www.js-tutorials.com/react-js/autocomplete-example-using-react/ */}
								<div>
									<label>
										Street Address <sup style={{ fontSize: 16, color: 'red' }}>*</sup>
									</label>
									<AutocompleteHolder>
										<Autocomplete
											name="address"
											getItemValue={(item) => item.FullAddress}
											items={autoAddressList}
											renderItem={(item, isHighlighted) => (
												<div
													key={item.Id}
													style={{ background: isHighlighted ? 'lightgray' : 'white' }}
												>
													{item.FullAddress}
												</div>
											)}
											value={autoAddress != '' ? autoAddress : values && values.address}
											// value={autoAddress}
											onChange={(e) => {
												//TODO: Remove if address lookup is required
												// setAutoAddress(e.target.value);
												handleChange(e.target.value, 'streetAddress');
											}}
											onSelect={(val) => {
												setAutoAddress(val);
												addressOnSelect(val);
											}}
										/>
										{autoAddressList.length == 0 &&
											autoAddress != '' &&
											autoAddress.length > 4 &&
											!autoAddress404 ? (
											<div className="icon-container">
												<i className="loader"></i>
											</div>
										) : (
											''
										)}
									</AutocompleteHolder>
								</div>
								<span style={{ fontSize: 12, color: 'red' }}>{error.streetAddressError}</span>
							</Box>
							{/* <Box mb={25}>
								<FormInput
									validationMessage={error.streetAddressError}
									validation={error.streetAddressError ? 'error' : undefined}
									value={values && values.address}
									required={true}
									label="Street address"
									name="streetAddress"
									onTextChange={(e) => handleChange(e, 'streetAddress')}
									maxLength={200}
								/>
							</Box> */}
							<Grid gap={4}>
								<FormInput
									validationMessage={error.zipCodeError}
									validation={error.zipCodeError ? 'error' : undefined}
									value={values && values.zip_code}
									label="Zip Code"
									required={true}
									name="zipCode"
									onTextChange={(e) => handleChange(e, 'zipCode')}
									maxLength={10}
									type="text"
								/>
								<FormInput
									validationMessage={error.cityError}
									validation={error.cityError ? 'error' : undefined}
									value={values && values.city}
									label="City"
									required={true}
									name="city"
									onTextChange={(e) => handleChange(e, 'city')}
									maxLength={20}
								/>
								{/* <FormInput
									validationMessage={error.stateError}
									validation={error.stateError ? 'error' : undefined}
									value={values && values.state}
									label="State"
									required={true}
									name="state"
									onTextChange={(e) => handleChange(e, 'state')}
									maxLength={20}
								/> */}
								<Stack categorySelect validation={error.countryError ? 'error' : undefined} gap={0}>
									<div>
										<label>
											Country <sup>*</sup>
										</label>
										<Select
											id="country"
											name="country"
											onChange={(e) => handleChange(e, 'country')}
											value={values && values.country}
										>
											<option>-Select-</option>
											{counties &&
												counties
													.filter((x) => x.id != 'AN')
													.map((option, index) => (
														<option value={option.id} key={index}>
															{option.full_name_english}
														</option>
													))}
										</Select>
										<Icon.downArrow />
									</div>
									<span style={{ fontSize: 12, color: 'red' }}>{error.countryError}</span>
								</Stack>
								{state && state.length > 0 ? (
									<Stack categorySelect validation={error.stateError ? 'error' : undefined} gap={0}>
										<div>
											<label>
												State <sup>*</sup>
											</label>
											<Select
												id="state"
												name="state"
												onChange={(e) => handleChange(e.target.value, 'state')}
												value={values && values.state}
											>
												<option>-Select-</option>
												{state &&
													state.map((item, index) => (
														<option key={index} value={item.code}>
															{item.name}
														</option>
													))}
											</Select>
											<Icon.downArrow />
										</div>
										<span style={{ fontSize: 12, color: 'red' }}>{error.stateError}</span>
									</Stack>
								) : (
									<FormInput
										id="state"
										validationMessage={error.stateError}
										validation={error.stateError ? 'error' : undefined}
										value={values && values.state}
										label="State"
										required={true}
										name="state"
										onTextChange={(e) => handleChange(e, 'state')}
										maxLength={20}
									/>
								)}
								{/* <AutocompleteHolder>
									<Autocomplete
										name="state"
										getItemValue={(item) => item.name}
										items={state && state.length > 0 ? state : []}
										renderItem={(item, isHighlighted) => (
											<div
												key={item.id}
												style={{ background: isHighlighted ? 'lightgray' : 'white' }}
											>
												{item.name}
											</div>
										)}
										value={values && values.state}
										onChange={(e) => {
											handleChange(e.target.value, 'state');
										}}
									/>
								</AutocompleteHolder> */}
								{/* <FormInput
									validationMessage={error.stateError}
									validation={error.stateError ? 'error' : undefined}
									value={values && values.state}
									label="State"
									required={true}
									name="state"
									onTextChange={(e) => handleChange(e, 'state')}
									maxLength={20}
								/> */}
							</Grid>
						</Box>
					</AssignVenueHolder>

					{/* Footer Btn Block */}
					<FooterBtnBlock>
						<Stack direction="x" gap={3} justifyContent={['center', 'flex-end']}>
							{editParam ? null : (
								<Button
									onClick={() => confirmPopup('clear')}
									flex={[1, 1, 'initial']}
									variant="secondary"
								>
									Clear
								</Button>
							)}
							<Button
								onClick={() => confirmPopup('cancel')}
								flex={[1, 1, 'initial']}
								variant="secondary"
							>
								Cancel
							</Button>
							<Button
								disabled={disableBtnSave}
								onClick={() => confirmPopup(editParam ? 'Save' : 'Add')}
								flex={[1, 1, 'initial']}
							>
								{editParam ? 'Save' : 'Add'}
							</Button>
						</Stack>
					</FooterBtnBlock>
				</AddUserHolder>
				<SweetAlert
					show={showPopup}
					confirmBtnText="Confirm"
					onConfirm={onSubmit}
					showCancel={true}
					onCancel={closePopup}
					title={'Confirmation Box'}
				>
					{() => <form>{modalText}</form>}
				</SweetAlert>
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default AddNewVenue;
