import Iget from 'models/Iget';
import Ipost from 'models/Ipost';
import VenueRole from 'models/pageObjects/venue.roles';
import queryString from 'query-string';
import React, { ReactElement, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory, useLocation } from 'react-router-dom';
import { Text } from 'rebass/styled-components';
import {
	AccordionUserDetails, BreadcrumbNav,



	Button, Footer, Header,




	TableList, Toggle
} from 'ui/components';
import icons from 'ui/icons';
import { Modal, Sidebar } from 'ui/patterns';
import urls from 'utils/create.url';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import notify from 'utils/toast.service';
import AssignUserRoleModal from './assignUserRole';
import {
	AccordionBlock,


	BreadcrumbWrapper, MainContent,




	RightHolder, UserBlock,
	UserHeader
} from './styles';


function UserDetails(props): ReactElement {
	const breadcrumbNav = [
		{ id: '01', label: 'Home', active: false },
		{ id: '02', label: 'Dashboard', active: false },

		{ id: '03', label: 'Users', active: false },
		{ id: '04', label: 'James Anderson', active: true },
	];

	const [showAssignUserRoleModal, setShowAssignUserRoleModal] = useState(false);
	const toggleAssignUserRoleModal = () => {
		setShowAssignUserRoleModal(!showAssignUserRoleModal);
	};
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const [showPopup, setShowModal] = useState(false);
	const [venueList, setVenueList] = useState([] as any);
	const [userDetails, setUserDetails] = useState<any>();
	const [venueRoles, setVenueRoles] = useState<Array<VenueRole>>([]) as any;
	const [roleList, setRoleList] = useState([] as any);
	var [updateList] = useState([] as any);
	const [modalText, setmodalText] = useState('');
	const [action, setAction] = useState('');
	const [assignedUser, setAssignedUser] = useState<VenueRole>({
		venue: { key: 0, value: '' },
		role: { key: 0, value: '' },
	});
	var [venueIndex, setVenueIndex] = useState<any>();

	const location = useLocation();
	const pageSize = 500;
	var history = useHistory();
	const apiObj: Partial<Ipost> = {
		params: {},
		url: '',
	};

	//get all assigned user mapped roles
	const parsed = queryString.parse(location.search);
	const obj: Partial<Iget> = {
		params: {},
		url: 'experion/venueuser/get/' + parsed.id,
		enabled: true,
		key: 'KeyUserDetails',
		resultFilteringRequired: false
	};
	var qry = hooks.useQuery(obj);

	//get all venues
	const venueUrl = urls.GetSearchUrl({
		url: 'experion/venue/search?',
		pageSize: pageSize,
		fields : [{field_name: 'hosp_id',
		field_value: localStorage.getItem("hospitalityId")!}],
		filterDeleted: true,
	});
	const venueObj: Partial<Iget> = {
		params: {},
		url: venueUrl,
		enabled: true,
		key: 'venue',
		resultFilteringRequired: true,
	};
	const venueQry = hooks.useQuery(venueObj);

	//get all roles
	const roleUrl = urls.GetSearchUrl({ url: 'experion/venueuserrole/search?', pageSize: pageSize,
	fields : [{field_name: 'hg_admin_Id',
	field_value: localStorage.getItem("hospitalityId")!}] });
	const roleObj: Partial<Iget> = {
		params: {},
		url: roleUrl,
		enabled: true,
		key: 'role',
		resultFilteringRequired: true,
	};
	const roleQry = hooks.useQuery(roleObj);


	// map user role mapping to dropdown
	useEffect(() => {
		setUserDetails(qry.data)
		let list = [] as Array<VenueRole>;
		qry.data?.venue_user_role_mapping.map((v) => {
			if (v.is_delete != true && v.is_active == true) {
				list.push({
					venue: {
						key: v.venue_id,
						value: v.venue_name,
					},
					role: {
						key: v.role_id,
						value: v.role_name,
					},
				});
			}
		});
		setVenueRoles(list);
	}, [qry.data])

	useEffect(() => {
		const venues = [] as any;
		venueQry.data &&
			venueQry.data.map((item) => {
				if (
					(venueRoles && venueRoles.findIndex((x) => x.venue.key == item.id) == -1) ||
					!venueRoles ||
					venueRoles.length == 0
				) {
					venues.push({ key: item.id, value: item.name });
				}
			});
		setVenueList(JSON.parse(JSON.stringify(venues)));
	}, [venueQry.data]);

	useEffect(() => {
		const roles = [] as any;
		roleQry.data &&
			roleQry.data.map((item) => {
				// let data = venueQry.data;
				if (
					(venueRoles && venueRoles.findIndex((x) => x.role.key == item.key) == -1) ||
					!venueRoles ||
					venueRoles.length == 0
				) {
					roles.push({ key: item.id, value: item.role_name });
				}
			});
		setRoleList(JSON.parse(JSON.stringify(roles)));
	}, [roleQry.data]);

	const saveUser = async () => {
		if (
			userDetails?.venue_user_role_mapping.findIndex(
				(x) => x.venue_id == assignedUser.venue.key,
			) != 0
		) {
			updateList = JSON.parse(JSON.stringify(userDetails));
			updateList.venue_user_role_mapping = [];
			userDetails.venue_user_role_mapping?.forEach((data) => {
				let items = { venue_id: null, role_id: null, venue_name: '', role_name: '' }
				items.venue_id = data.venue_id
				items.role_id = data.role_id
				items.venue_name = data.venue_name
				items.role_name = data.role_name
				updateList.venue_user_role_mapping.push(items)

			})
			updateList.venue_user_role_mapping.push({
				"venue_id": assignedUser.venue.key,
				"role_id": assignedUser.role.key,
				"venue_name": assignedUser.venue.value,
				"role_name": assignedUser.role.value
			})
			const obj: Partial<Ipost> = {
				data: { venueUser: updateList },
				params: {},
				url: '/experion/venueuser/update/' + userDetails.id,
				message: `User added successfully`,
			};
			try {
				await api.put(obj).then(function (data) {
					if (data) {
						venueList.splice(venueIndex, 1)
						setUserDetails(data)
						toggleAssignUserRoleModal()
					}
					// if (typeof data != 'undefined') history.push('user-dashboard');
				});
				// }
				// setIsLoading(false);
			} catch (e) {
				// setIsLoading(false);
			}
		} else {
			notify('Venue already exists in the list').info();
		}
	}
	const onSelectDropdwn = (event, name) => {
		const data: VenueRole = JSON.parse(JSON.stringify(assignedUser));
		switch (name) {
			case 'venueName': {
				if (data) {
					data.venue.value = event.target.value;
					if (event.target.value !== '') {
						venueIndex = venueList.findIndex((x) => x.value == event.target.value)
						data.venue.key = venueList[venueIndex].key;
						setVenueIndex(venueIndex);
					}
				}
				break;
			}
			case 'userRole': {
				if (data) {
					data.role.value = event.target.value;
					if (event.target.value != '')
						data.role.key = roleList[roleList.findIndex((x) => x.value == event.target.value)].key;
				}
				break;
			}
		}
		setAssignedUser(JSON.parse(JSON.stringify(data)));
	}

	async function onDeleteVenueRole(data) {
		userDetails.venue_user_role_mapping.splice(
			userDetails.venue_user_role_mapping.findIndex((x) => x.id == data.id), 1)
		updateList = JSON.parse(JSON.stringify(userDetails));
		updateList.venue_user_role_mapping = [];
		userDetails.venue_user_role_mapping?.forEach((data) => {
			let items = { venue_id: null, role_id: null, venue_name: '', role_name: '' }
			items.venue_id = data.venue_id
			items.role_id = data.role_id
			items.venue_name = data.venue_name
			items.role_name = data.role_name
			updateList.venue_user_role_mapping.push(items)

		})
		const obj: Partial<Ipost> = {
			data: { venueUser: updateList },
			params: {},
			url: '/experion/venueuser/update/' + userDetails.id,
			message: `User role deleted successfully`,
		};
		try {
			await api.put(obj).then(function (data) {
				if (data) {
					setUserDetails(data)
					setShowModal(!showPopup);
				}
			});
			// }
			// setIsLoading(false);
		} catch (e) {
			// setIsLoading(false);
		}
	};

	const closePopup = () => {
		setShowModal(!showPopup);
	}

	function showConfirmationModal(text, action) {
		setAction(action)
		setmodalText(text);
		setShowModal(!showPopup);
	}

	function onSubmit(selectedId) {
		if (action == 'deleteUser') deleteUser();
		else if (action == 'deleteRole') onDeleteVenueRole(selectedId);

	}
	function deleteUser() {
		apiObj.url = `experion/venueuser/delete/${parsed.id}`;
		apiObj.data = { Id: [parsed.id] };
		apiObj.message = 'User deleted successfully';
		api.put(apiObj).then(() => {
			setShowModal(!showPopup);
		})

	}

	function changeStatus(checked) {
		apiObj.url = `experion/venueuser/updatestatus/${parsed.id}/${checked}`;
		apiObj.message = 'User status updated successfully';
		api.put(apiObj).then(data => {
			if (data === true) {
				qry.refetch().then(() => {
				});
			}
		});
	}

	const navigateToEdit = () => {
		history.push({
			pathname: 'user',
			search: '?id=' + parsed.id,
		});
	};
	const ontoggleChange = (event) => {
		changeStatus(event.target.checked)
	}
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	}
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={()=>onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={()=>onclickHambrgBtn()}></Sidebar>

			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbWrapper>
					<BreadcrumbNav Heading="User Details" breadcrumbList={breadcrumbNav} />
					<RightHolder>
						<Toggle onChange={ontoggleChange} />
						<Button variant="deleteIcon" onClick={() => showConfirmationModal('Delete user?', 'deleteUser')}>
							<icons.DeleteIcon />
						</Button>
						<Button variant="editIcon" onClick={navigateToEdit}>
							<icons.textEditIcon />
						</Button>
					</RightHolder>
				</BreadcrumbWrapper>
				<AccordionBlock>
					<AccordionUserDetails userDetails={userDetails} />
					<UserBlock>
						<UserHeader>
							<Text fontSize={18} fontWeight="bold" color="black" verticalAlign="middle">
								User Permissions
							</Text>
							{/* <Button>
								<Text as="h1" fontFamily="OpenSansBold" fontSize={18} color="text.white">
									Add
								</Text>
							</Button> */}
							<Button onClick={toggleAssignUserRoleModal}>
								<Text as="h1" fontFamily="OpenSansBold" fontSize={18} color="text.white">
									Assign Roles
								</Text>
							</Button>
						</UserHeader>
						<TableList>
							<table>
								<thead>
									<tr>
										<th>Store</th>
										<th>Role</th>
										<th className="action">Action</th>
									</tr>
								</thead>

								<tbody>

									{userDetails && userDetails?.venue_user_role_mapping.map((data) => (
										<tr>
											<td data-label="Venue">
												<Text as="h1" fontSize={15} fontFamily="OpenSansBlack" color="text.black">
													<span>{data.venue_name}</span>
												</Text>
											</td>
											<td data-label="Role">
												<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
													{data.role_name}
												</Text>
											</td>
											<td className="action" data-label="Action">
											{userDetails?.venue_user_role_mapping?.length > 1 && <Text>
													<Button variant="deleteIcon" onClick={() => showConfirmationModal('Delete the venue-role?', 'deleteRole')}>
														<icons.DeleteIcon />
													</Button>
												</Text>}
												<SweetAlert
													show={showPopup}
													confirmBtnText="Confirm"
													onConfirm={() => onSubmit(data.id)}
													showCancel={true}
													onCancel={closePopup}
													title={'Confirmation Box'}
												//  dependencies={[this.state.firstName, this.state.lastName]}
												>
													{() => <form>{modalText}</form>}
												</SweetAlert>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</TableList>
					</UserBlock>
				</AccordionBlock>
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
			<Modal
				width="450px"
				showModal={showAssignUserRoleModal}
				setShowModal={setShowAssignUserRoleModal}
			>
				<AssignUserRoleModal saveUserRole={saveUser} onSelectDropdwn={onSelectDropdwn} venueList={venueList} roleList={roleList} cancel={() => toggleAssignUserRoleModal()} />
			</Modal>
			{/* <Modal
				width="450px"
				showModal={showAssignUserRoleModal}
				setShowModal={setShowAssignUserRoleModal}
			>
				<AssignUserRoleModal cancel={() => setShowModal(false)} />
			</Modal> */}
		</>
	);
}

export default UserDetails;
