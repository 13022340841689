import { venueUserPermissionList } from 'models/enum-constants/common.constants';
import React, { ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { Button } from 'ui/components';
import { Modal } from 'ui/patterns';
import hooks from 'utils/requestCtx/reactQueryHooks';
import utilityFunctions from 'utils/utility.functions';
import NewFavoritesModal from './newFavorites';

const ListWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	overflow-x: auto;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	border-radius: 12px;
	overflow: hidden;
	margin-left: 27px;
	@media (max-width: 960px) {
		order: -1;
		width: 100%;
		max-width: 100%;
		margin: 20px 0 20px;
	}
	.OrderConfirmationSection {
		background: ${({ theme }) => theme.colors.bg.lightYellow06};
		display: flex;
		flex-direction: column;
		padding: 14px 20px;
		width: 100%;
		h1 {
			line-height: 26px;
			margin-bottom: 2px;
		}
	}
	.NextActionList {
		display: flex;
		flex-direction: row;
		background: rgba(240, 233, 218, 0.26);
		padding: 23px 20px;
		width: 100%;
		border: 1px solid ${({ theme }) => theme.colors.bg.lightYellow06};
		border-radius: 0 0 12px 12px;
		justify-content: center;
		column-gap: 10px;
		button {
			flex: 0;
			span {
				white-space: nowrap;
			}
		}
		@media (max-width: 480px) {
			flex-direction: column;
			row-gap: 15px;
		}
	}
`;

type CheckoutConfirmProps = {
	OrderId?: string;
};

function CheckoutConfirm({ OrderId }: CheckoutConfirmProps): ReactElement {
	var history = useHistory();
	const [showNewFavoritesModal, setShowNewFavoritesModal] = useState(false);

	var orderId = localStorage.getItem('orderId');

	const toggleNewFavoritesModal = () => {
		setShowNewFavoritesModal(!showNewFavoritesModal);
	};

	return (
		<ListWrapper>
			<Flex className="OrderConfirmationSection">
				<Text
					as="h1"
					fontSize={30}
					fontFamily="OpenSansBold"
					color="text.black04"
					style={{ textAlign: 'center', paddingTop: '3%' }}
				>
					Order Successfully Placed
				</Text>
				<Text
					as="h2"
					fontSize={20}
					fontFamily="OpenSansBold"
					color="text.black04"
					style={{ textAlign: 'center', paddingTop: '3%' }}
				>
					Order ID: {orderId}
				</Text>
				<Text
					as="p"
					fontSize={20}
					color="text.black04"
					style={{ textAlign: 'center', padding: '3%' }}
				>
					Congratulations. Your order have been successfully being placed. We will sent you the
					confirmation link and the updated tracking details once the order have been confirmed by
					the seller.
				</Text>
			</Flex>
			<Flex className="NextActionList">
				{utilityFunctions.isGranted(venueUserPermissionList.FavoriteOrderAdd) && (
					<Button variant="secondary" flex={[1, 1, 'initial']} onClick={toggleNewFavoritesModal}>
						<div>Mark as Favourite Order</div>
					</Button>
				)}

				<Button
					flex={[1, 1, 'initial']}
					onClick={() => {
						history.push('/home/product-list?search=&type=Products');
					}}
				>
					<div>Go Back to Shopping</div>
				</Button>
			</Flex>
			<Modal
				width="500px"
				showModal={showNewFavoritesModal}
				setShowModal={setShowNewFavoritesModal}
			>
				<NewFavoritesModal toggleNewFavoritesModal={toggleNewFavoritesModal} />
			</Modal>
		</ListWrapper>
	);
}

export { CheckoutConfirm };
