import styled from 'styled-components';

const MainContent = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
	padding: 0 50px;
	@media (max-width: 960px) {
		padding: 0 15px;
	}
`;
const ContentHolder = styled.div`
	height: 100%;
	padding: 0 50px;
	padding-bottom: 30px;
	width: 100%;

	@media (max-width: 768px) {
		padding: 0 15px;
	}
`;
const CartListHolder = styled.div`
	width: 100%;
	float: left;
	min-height: calc(100vh - 444px);
	padding-bottom:50px;
	h1 {
		margin-bottom: 20px;
		strong {
			font-family: 'OpenSansBold';
		}
	}
`;

const FooterBtnBlock = styled.div`
	padding: 0 0 58px;
	@media (max-width: 640px) {
		padding: 11px 0px 0;
	}
	div {
		@media (max-width: 1200px) {
			width: 100%;
		}
	}
	button {
		min-width: 150px;
		text-transform: capitalize;
		padding: 0 20px;
		height: 60px;
		flex: 0 0 auto;
	}
	@media (max-width: 767px) {
		button {
			flex: 0 0 auto;
			min-width: auto;
		}
		div{
			flex-wrap: wrap;
			row-gap:10px ;
			margin-bottom: 50px;
		}
	}
`;
const TotalCostBlock = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	text-align: right;
	padding: 0 0 35px;
	h1 {
		strong {
			font-size: 30px;
		}
	}
`;

const MarkAsParkedOrderEditBtn = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	text-align: right;
	color: ${({ theme }) => theme.baseColors.primary};
	border: 1px solid ${({ theme }) => theme.baseColors.primary};
	background: ${({ theme }) => theme.colors.bg.white};
	border-radius: ${({ theme }) => theme.radii.softer};
	padding: 6px 15px;
	width: auto !important;

	div {
		text-align: left;
		max-width: 200px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		strong {
			font-size: 16px;
			font-family: 'OpenSansBold';
		}
	}
	button {
		padding: 0;
		background: transparent !important;
		border: none;
		min-width: 0;
		height: auto;
		margin-left: 10px;
		svg {
			path {
				fill: ${({ theme }) => theme.baseColors.primary};
			}
		}
	}
`;

export {
	MainContent,
	ContentHolder,
	CartListHolder,
	FooterBtnBlock,
	TotalCostBlock,
	MarkAsParkedOrderEditBtn,
};
