import styled from 'styled-components';

const StyledFooter = styled.footer`
	width: 100%;
	background: ${({ theme }) => theme.colors.text.black02};
	margin-top: auto;
	h1 {
		text-align: center;
		display: flex;
		justify-content: center;
		padding: 35px 0;
		margin: 0 50px;
		font-weight: normal;
		border-top: 1px solid ${({ theme }) => theme.colors.border.white};
		@media (max-width: 768px) {
			margin: 0 15px;
			padding: 25px 0;
			font-size: 14px;
		}
	}
`;
const StyledFooterNav = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	column-gap: 20px;
	row-gap: 20px;
	width: 100%;
	background: ${({ theme }) => theme.colors.text.black02};
	padding: 35px;
	div {
		justify-content: center;
		@media (max-width: 640px) {
			flex-direction: column;
		}
		a {
			margin: 0;
			margin: 0 40px;
			@media (max-width: 960px) {
				margin: 0 20px;
			}
			@media (max-width: 640px) {
				margin: 0 0 20px 0;
				float: left;
				width: auto;
				&:last-child {
					margin: 0 0 0 0;
				}
			}
			&:first-child {
				margin-left: 0;
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}

	title {
		display: block;
		text-transform: uppercase;
		margin-bottom: 10px;
	}
	a {
		text-decoration: none;
		margin-bottom: 5px;
		float: left;
		&:hover {
			text-decoration: underline;
			//todo: change after confirmation
			color: ${({ theme }) => theme.colors.border.gray};
		}
	}
`;
export { StyledFooter, StyledFooterNav };
