import styled, { css } from 'styled-components';

type StyledProfileDropdownProps = {
	hide?: boolean;
	show?: boolean;
};
const StyledProfileDropdown = styled.div<StyledProfileDropdownProps>`
	position: relative;
	.dropdownMenu {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		button {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: none;
			background: transparent;
			outline: none;
			cursor: pointer;
			& + div {
				margin-top: 72px;
				border-radius: 12px;
				left: auto;
				right: -16px;
				min-width: 230px;
				z-index: 1;
				padding: 15px;
				overflow: visible;
				box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.39);

				@media (max-width: 960px) {
					right: -10px;
				}
				button {
					position: relative;
					min-height: auto;
					padding: 10px 10px;
					margin: 0;
					&::before {
						left: 0;
						right: 0;
					}
					&:hover,
					&:focus {
						background: ${({ theme }) => theme.baseColors.primary}!important;
						color: ${({ theme }) => theme.colors.text.white};
						text-decoration: none;
					}
				}
				&::before {
					content: '';
					width: 47px;
					height: 16px;
					position: absolute;
					top: -16px;
					left: auto;
					right: 14px;
					background: url(${require('../../images/dropdownTopArrow.png')});
				}
			}
		}
	}
	a {
		color: ${({ theme }) => theme.colors.text.white};
		font-size: 16px;
		line-height: 21px;
		text-decoration: none;
		margin-left: 50px;
		display: flex;
		align-items: center;
		position: relative;
		min-height: 50px;
		padding-right: 15px;
		padding-left: 10px;
		cursor: pointer;
		font-family: 'OpenSansSemiBold';
		&:hover {
			color: ${({ theme }) => theme.colors.text.textHeading};
		}
		& > div {
			top: 0;
			position: absolute;
			width: 100%;
		}
		button {
			background: transparent;
			border: none;
			width: 100%;
			min-height: 50px;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			position: absolute;
			outline: none;
			cursor: pointer;
		}
		@media (max-width: 1280px) {
			font-size: 14px;
			line-height: 16px;
			margin-left: 15px;
		}
		@media (max-width: 1152px) {
			font-size: 13px;
			margin-left: 10px;
		}
		@media (max-width: 960px) {
			font-size: 16px;
			line-height: 21px;
			margin-left: 0 !important;
		}
		@media (max-width: 480px) {
			font-size: 12px;
			line-height: 16px;
			margin-left: 0;
		}
		&:first-child {
			margin-left: 0;
		}
	}
	&.navbar-nav {
		@media (max-width: 960px) {
			display: none;
		}
	}
	${({ hide }) =>
		hide &&
		css`
			display: none;
		`}
	${({ show }) =>
		show &&
		css`
			display: block;
		`}
`;
export { StyledProfileDropdown };
