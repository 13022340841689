export const APP_TOKEN = process.env.REACT_APP_ACCESS_TOKEN;
export const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;
export const PAYPAL_SECRET = process.env.REACT_APP_PAYPAL_SECRET;
export const REFRESH_TOKEN = 'rfid';

const instances = {
	dev: 'DEV',
	qa: 'QA',
	uat: 'UAT',
	preprod: 'PREPROD',
	prod: 'PROD',
};

const config = {
	[instances.dev]: {
		public_url: 'https://ariadigistore-dev.experionglobal.dev/',
		api: 'https://ariastore-dev.experionglobal.dev/rest/all/V1/',
		bulk_api: 'https://ariastore-dev.experionglobal.dev/rest/default/async/bulk/',
		image_url: 'https://ariastore-dev.experionglobal.dev/pub/media/catalog/product',
		graphql_url: 'https://ariastore-dev.experionglobal.dev/graphql',
		places_url: 'https://apigateway.develop.ariaexp.com/v1/api/address?',
		paypal_url: 'https://api-m.sandbox.paypal.com',
		payment_url:
			'https://ariastore-dev.experionglobal.dev/rest/default/V1/carts/mine/payment-information',
		cart_url: 'https://ariastore-dev.experionglobal.dev/rest/default/V1/carts/mine',
		places_api_key: '2E0407A8-677E-11EB-AE93-0242AC130002',
		places_client_id: 'DigiB2B',
		multi_cart_url: 'https://ariastore-dev.experionglobal.dev/rest/default/V1/cart/multicart',
		send_verification_code:
			'https://ariastore-dev.experionglobal.dev/rest/default/V1/experion/cognito/sendverificationcode',
		edit_supplierReview:
			'https://ariastore-dev.experionglobal.dev/rest/default/V1/experion/supplierreview/update/',
		create_supplierReview:
			'https://ariastore-dev.experionglobal.dev/rest/default/V1/experion/supplierreview/create',
		delete_supplierReview:
			'https://ariastore-dev.experionglobal.dev/rest/default/V1/experion/supplierreview/delete/',
		get_all_supplier_reviews:
			'https://ariastore-dev.experionglobal.dev/rest/default/V1/experion/allsupplierreviews/get/',
		get_All_VenueReview:
			'https://ariastore-dev.experionglobal.dev/rest/default/V1/experion/allvenuereviews/get/',
		verify_Mobile_Number:
			'https://ariastore-dev.experionglobal.dev/rest/default/V1/experion/cognito/verifymobilenumber',
		get_Supplier_Review_Ratings:
			'https://ariastore-dev.experionglobal.dev/rest/default/V1/experion/allsupplierreviewspervenue/get/',
		create_product_watch:
			'https://ariastore-dev.experionglobal.dev/rest/default/V1/experion/productwatch/create',
		product_watch_notification:
			'https://ariastore-dev.experionglobal.dev/rest/default/V1/experion/productwatch/notifications/',
		supplier_watch:
			'https://ariastore-dev.experionglobal.dev/rest/default/V1/aria-supplierwatch/supplierwatch',
		supplier_check_watch:
			'https://ariastore-dev.experionglobal.dev/rest/default/V1/aria-supplierwatch/checkwatch/',
		check_product_watch_added:
			'https://ariastore-dev.experionglobal.dev/rest/default/V1/experion/productwatch/checkwatch/',
		removeProductFromWatchList:
			'https://ariastore-dev.experionglobal.dev/rest/default/V1/experion/productwatch/',
		removeFavIcon:
			'https://ariastore-dev.experionglobal.dev/rest/default/V1/experion/productmanagement/checkfavouriteproduct/',
		generate_report:
			'https://ariastore-dev.experionglobal.dev/rest/default/V1/experion/supplier/report',
		generate_csv: 'https://ariastore-dev.experionglobal.dev/productreport/Export/?',
		cart_get_items: 'https://ariastore-dev.experionglobal.dev/rest/default/V1/carts/mine/get-items',
		set_payment_method:
			'https://magentodemo.experionglobal.dev/rest/default/V1/carts/mine/set-payment-information',
		set_paypal_info: 'https://magentodemo.experionglobal.dev/paypal/express/OnAuthorization/',
	},
	[instances.qa]: {
		public_url: 'https://ariadigistore-qa.experionglobal.dev/',
		api: 'https://ariastore-qa.experionglobal.dev/rest/all/V1/',
		bulk_api: 'https://ariastore-qa.experionglobal.dev/rest/all/async/bulk/',
		image_url: 'https://ariastore-qa.experionglobal.dev/pub/media/catalog/product',
		graphql_url: 'https://ariastore-qa.experionglobal.dev/graphql',
		places_url: 'https://apigateway.qa.ariaexp.com/v1/api/address?',
		paypal_url: 'https://api-m.sandbox.paypal.com',
		payment_url:
			'https://ariastore-qa.experionglobal.dev/rest/default/V1/carts/mine/payment-information',
		cart_url: 'https://ariastore-qa.experionglobal.dev/rest/default/V1/carts/mine',
		places_api_key: '90224006-9E64-4C19-AB0C-6421FAE964F7',
		places_client_id: 'DigiB2B',
		multi_cart_url: 'https://ariastore-qa.experionglobal.dev/rest/default/V1/cart/multicart',
		send_verification_code:
			'https://ariastore-qa.experionglobal.dev/rest/default/V1/experion/cognito/sendverificationcode',
		edit_supplierReview:
			'https://ariastore-qa.experionglobal.dev/rest/default/V1/experion/supplierreview/update/',
		create_supplierReview:
			'https://ariastore-qa.experionglobal.dev/rest/default/V1/experion/supplierreview/create',
		delete_supplierReview:
			'https://ariastore-qa.experionglobal.dev/rest/default/V1/experion/supplierreview/delete/',
		get_all_supplier_reviews:
			'https://ariastore-qa.experionglobal.dev/rest/default/V1/experion/allsupplierreviews/get/',
		get_All_VenueReview:
			'https://ariastore-qa.experionglobal.dev/rest/default/V1/experion/allvenuereviews/get/',
		verify_Mobile_Number: `https://ariastore-qa.experionglobal.dev/rest/default/V1/experion/cognito/verifymobilenumber`,
		get_Supplier_Review_Ratings:
			'https://ariastore-qa.experionglobal.dev/rest/default/V1/experion/allsupplierreviewspervenue/get/',
		create_product_watch:
			'https://ariastore-qa.experionglobal.dev/rest/default/V1/experion/productwatch/create',
		product_watch_notification:
			'https://ariastore-qa.experionglobal.dev/rest/default/V1/experion/productwatch/notifications/',
		supplier_watch:
			'https://ariastore-qa.experionglobal.dev/rest/default/V1/aria-supplierwatch/supplierwatch',
		supplier_check_watch:
			'https://ariastore-qa.experionglobal.dev/rest/default/V1/aria-supplierwatch/checkwatch/',
		check_product_watch_added:
			'https://ariastore-qa.experionglobal.dev/rest/default/V1/experion/productwatch/checkwatch/',
		removeProductFromWatchList:
			'https://ariastore-qa.experionglobal.dev/rest/default/V1/experion/productwatch/',
		removeFavIcon:
			'https://ariastore-qa.experionglobal.dev/rest/default/V1/experion/productmanagement/checkfavouriteproduct/',
		generate_report:
			'https://ariastore-qa.experionglobal.dev/rest/default/V1/experion/supplier/report',
		generate_csv: 'https://ariastore-qa.experionglobal.dev/productreport/Export/?',
		cart_get_items: 'https://ariastore-qa.experionglobal.dev/rest/default/V1/carts/mine/get-items',
		set_payment_method:
			'https://magentodemo.experionglobal.dev/rest/default/V1/carts/mine/set-payment-information',
		set_paypal_info: 'https://magentodemo.experionglobal.dev/paypal/express/OnAuthorization/',
	},
	[instances.uat]: {
		public_url: 'https://magentodemo.experionglobal.dev/',
		api: 'https://magentodemo.experionglobal.dev/rest/default/V1/',
		bulk_api: 'https://magentodemo.experionglobal.dev/rest/all/async/bulk/',
		image_url: 'https://magentodemo.experionglobal.dev/pub/media/catalog/product',
		graphql_url: 'https://magentodemo.experionglobal.dev/graphql',
		places_url: 'https://apigateway.qa.ariaexp.com/v1/api/address?',
		paypal_url: 'https://api-m.sandbox.paypal.com',
		payment_url:
			'https://magentodemo.experionglobal.dev/rest/default/V1/carts/mine/payment-information',
		cart_url: 'https://magentodemo.experionglobal.dev/rest/default/V1/carts/mine',
		places_api_key: '90224006-9E64-4C19-AB0C-6421FAE964F7',
		places_client_id: 'DigiB2B',
		multi_cart_url: 'https://magentodemo.experionglobal.dev/rest/default/V1/cart/multicart',
		send_verification_code:
			'https://magentodemo.experionglobal.dev/rest/default/V1/experion/cognito/sendverificationcode',
		edit_supplierReview:
			'https://magentodemo.experionglobal.dev/rest/default/V1/experion/supplierreview/update/',
		create_supplierReview:
			'https://magentodemo.experionglobal.dev/rest/default/V1/experion/supplierreview/create',
		delete_supplierReview:
			'https://magentodemo.experionglobal.dev/rest/default/V1/experion/supplierreview/delete/',
		get_all_supplier_reviews:
			'https://magentodemo.experionglobal.dev/rest/default/V1/experion/allsupplierreviews/get/',
		get_All_VenueReview:
			'https://magentodemo.experionglobal.dev/rest/default/V1/experion/allvenuereviews/get/',
		verify_Mobile_Number: `https://magentodemo.experionglobal.dev/rest/default/V1/experion/cognito/verifymobilenumber`,
		get_Supplier_Review_Ratings:
			'https://magentodemo.experionglobal.dev/rest/default/V1/experion/allsupplierreviewspervenue/get/',
		create_product_watch:
			'https://magentodemo.experionglobal.dev/rest/default/V1/experion/productwatch/create',
		product_watch_notification:
			'https://magentodemo.experionglobal.dev/rest/default/V1/experion/productwatch/notifications/',
		supplier_watch:
			'https://magentodemo.experionglobal.dev/rest/default/V1/experion-supplierwatch/supplierwatch',
		supplier_check_watch:
			'https://magentodemo.experionglobal.dev/rest/default/V1/experion-supplierwatch/checkwatch/',
		check_product_watch_added:
			'https://magentodemo.experionglobal.dev/rest/default/V1/experion/productwatch/checkwatch/',
		removeProductFromWatchList:
			'https://magentodemo.experionglobal.dev/rest/default/V1/experion/productwatch/',
		removeFavIcon:
			'https://magentodemo.experionglobal.dev/rest/default/V1/experion/productmanagement/checkfavouriteproduct/',
		generate_report:
			'https://magentodemo.experionglobal.dev/rest/default/V1/experion/supplier/report',
		generate_csv: 'https://magentodemo.experionglobal.dev/productreport/Export/?',
		cart_get_items: 'https://magentodemo.experionglobal.dev/rest/default/V1/carts/mine/get-items',
		set_payment_method:
			'https://magentodemo.experionglobal.dev/rest/default/V1/carts/mine/set-payment-information',
		set_paypal_info: 'https://magentodemo.experionglobal.dev/paypal/express/OnAuthorization/',
	},
	[instances.preprod]: {
		public_url: 'https://digistore.preprod.ariaexp.com/',
		api: 'https://store.preprod.ariaexp.com/rest/all/V1/',
		bulk_api: 'https://store.preprod.ariaexp.com/rest/all/async/bulk/',
		image_url: 'https://store.preprod.ariaexp.com/pub/media/catalog/product',
		graphql_url: 'https://store.preprod.ariaexp.com/graphql',
		places_url: 'https://apigateway.qa.ariaexp.com/v1/api/address?',
		paypal_url: 'https://api-m.sandbox.paypal.com',
		payment_url: 'https://store.preprod.ariaexp.com/rest/default/V1/carts/mine/payment-information',
		cart_url: 'https://store.preprod.ariaexp.com/rest/default/V1/carts/mine',
		places_api_key: '90224006-9E64-4C19-AB0C-6421FAE964F7',
		places_client_id: 'DigiB2B',
		multi_cart_url: 'https://store.preprod.ariaexp.com/rest/default/V1/cart/multicart',
		send_verification_code:
			'https://store.preprod.ariaexp.com/rest/default/V1/experion/cognito/sendverificationcode',
		edit_supplierReview:
			'https://store.preprod.ariaexp.com/rest/default/V1/experion/supplierreview/update/',
		create_supplierReview:
			'https://store.preprod.ariaexp.com/rest/default/V1/experion/supplierreview/create',
		delete_supplierReview:
			'https://store.preprod.ariaexp.com/rest/default/V1/experion/supplierreview/delete/',
		get_all_supplier_reviews:
			'https://store.preprod.ariaexp.com/rest/default/V1/experion/allsupplierreviews/get/',
		get_All_VenueReview:
			'https://store.preprod.ariaexp.com/rest/default/V1/experion/allvenuereviews/get/',
		verify_Mobile_Number: `https://store.preprod.ariaexp.com/rest/default/V1/experion/cognito/verifymobilenumber`,
		get_Supplier_Review_Ratings:
			'https://store.preprod.ariaexp.com/rest/default/V1/experion/allsupplierreviewspervenue/get/',
		create_product_watch:
			'https://store.preprod.ariaexp.com/rest/default/V1/experion/productwatch/create',
		product_watch_notification:
			'https://store.preprod.ariaexp.com/rest/default/V1/experion/productwatch/notifications/',
		supplier_watch:
			'https://store.preprod.ariaexp.com/rest/default/V1/aria-supplierwatch/supplierwatch',
		supplier_check_watch:
			'https://store.preprod.ariaexp.com/rest/default/V1/aria-supplierwatch/checkwatch/',
		check_product_watch_added:
			'https://store.preprod.ariaexp.com/rest/default/V1/experion/productwatch/checkwatch/',
		removeProductFromWatchList:
			'https://store.preprod.ariaexp.com/rest/default/V1/experion/productwatch/',
		removeFavIcon:
			'https://store.preprod.ariaexp.com/rest/default/V1/experion/productmanagement/checkfavouriteproduct/',
		generate_report: 'https://store.preprod.ariaexp.com/rest/default/V1/experion/supplier/report',
		generate_csv: 'https://store.preprod.ariaexp.com/productreport/Export/?',
		cart_get_items: 'https://store.preprod.ariaexp.com/rest/default/V1/carts/mine/get-items',
		set_payment_method:
			'https://magentodemo.experionglobal.dev/rest/default/V1/carts/mine/set-payment-information',
		set_paypal_info: 'https://magentodemo.experionglobal.dev/paypal/express/OnAuthorization/',
	},
	[instances.prod]: {
		public_url: 'https://digistore.prod.ariaexp.com/',
		api: 'https://store.prod.ariaexp.com/rest/all/V1/',
		bulk_api: 'https://store.prod.ariaexp.com/rest/all/async/bulk/',
		image_url: 'https://store.prod.ariaexp.com/pub/media/catalog/product',
		graphql_url: 'https://store.prod.ariaexp.com/graphql',
		places_url: 'https://apigateway.qa.ariaexp.com/v1/api/address?',
		paypal_url: 'https://api-m.sandbox.paypal.com',
		payment_url: 'https://store.prod.ariaexp.com/rest/default/V1/carts/mine/payment-information',
		cart_url: 'https://store.prod.ariaexp.com/rest/default/V1/carts/mine',
		places_api_key: '90224006-9E64-4C19-AB0C-6421FAE964F7',
		places_client_id: 'DigiB2B',
		multi_cart_url: 'https://store.prod.ariaexp.com/rest/default/V1/cart/multicart',
		send_verification_code:
			'https://store.prod.ariaexp.com/rest/default/V1/experion/cognito/sendverificationcode',
		edit_supplierReview:
			'https://store.prod.ariaexp.com/rest/default/V1/experion/supplierreview/update/',
		create_supplierReview:
			'https://store.prod.ariaexp.com/rest/default/V1/experion/supplierreview/create',
		delete_supplierReview:
			'https://store.prod.ariaexp.com/rest/default/V1/experion/supplierreview/delete/',
		get_all_supplier_reviews:
			'https://store.prod.ariaexp.com/rest/default/V1/experion/allsupplierreviews/get/',
		get_All_VenueReview:
			'https://store.prod.ariaexp.com/rest/default/V1/experion/allvenuereviews/get/',
		verify_Mobile_Number: `https://store.prod.ariaexp.com/rest/default/V1/experion/cognito/verifymobilenumber`,
		get_Supplier_Review_Ratings:
			'https://store.prod.ariaexp.com/rest/default/V1/experion/allsupplierreviewspervenue/get/',
		create_product_watch:
			'https://store.prod.ariaexp.com/rest/default/V1/experion/productwatch/create',
		product_watch_notification:
			'https://store.prod.ariaexp.com/rest/default/V1/experion/productwatch/notifications/',
		supplier_watch:
			'https://store.prod.ariaexp.com/rest/default/V1/aria-supplierwatch/supplierwatch',
		supplier_check_watch:
			'https://store.prod.ariaexp.com/rest/default/V1/aria-supplierwatch/checkwatch/',
		check_product_watch_added:
			'https://store.prod.ariaexp.com/rest/default/V1/experion/productwatch/checkwatch/',
		removeProductFromWatchList:
			'https://store.prod.ariaexp.com/rest/default/V1/experion/productwatch/',
		removeFavIcon:
			'https://store.prod.ariaexp.com/rest/default/V1/experion/productmanagement/checkfavouriteproduct/',
		generate_report: 'https://store.prod.ariaexp.com/rest/default/V1/experion/supplier/report',
		generate_csv: 'https://store.prod.ariaexp.com/productreport/Export/?',
		cart_get_items: 'https://store.prod.ariaexp.com/rest/default/V1/carts/mine/get-items',
		set_payment_method:
			'https://magentodemo.experionglobal.dev/rest/default/V1/carts/mine/set-payment-information',
		set_paypal_info: 'https://magentodemo.experionglobal.dev/paypal/express/OnAuthorization/',
	},
};

/**
 * "REACT_APP_PROJECT_ENV" is set from npm scripts (package.json)
 */
export default {
	currentInstance: process.env.REACT_APP_PROJECT_ENV || instances.uat,
	publicURL(): string {
		return config[this.currentInstance].public_url!;
	},
	baseURL(): string {
		return config[this.currentInstance].api;
	},
	bulkUrl(): string {
		return config[this.currentInstance].bulk_api!;
	},
	graphqlURL(): string {
		return config[this.currentInstance].graphql_url!;
	},
	imageURL(): string {
		return config[this.currentInstance].image_url!;
	},
	placesAPIURL(): string {
		return config[this.currentInstance].places_url!;
	},
	placesAPIKey(): string {
		return config[this.currentInstance].places_api_key!;
	},
	placesClientId(): string {
		return config[this.currentInstance].places_client_id!;
	},
	paymentURL(): string {
		return config[this.currentInstance].payment_url!;
	},
	setPaymentMethod(): string {
		return config[this.currentInstance].set_payment_method!;
	},
	cartURL(): string {
		return config[this.currentInstance].cart_url!;
	},
	multiCartURL(): string {
		return config[this.currentInstance].multi_cart_url!;
	},
	sendVerificationCode(): string {
		return config[this.currentInstance].send_verification_code!;
	},
	editSupplierReview(): string {
		return config[this.currentInstance].edit_supplierReview!;
	},
	createSupplierReview(): string {
		return config[this.currentInstance].create_supplierReview!;
	},
	deleteSupplierReview(): string {
		return config[this.currentInstance].delete_supplierReview!;
	},
	getAllSupplierReview(): string {
		return config[this.currentInstance].get_all_supplier_reviews!;
	},
	getAllVenueReview(): string {
		return config[this.currentInstance].get_All_VenueReview!;
	},
	checkAndVerifyMobileNumber(): string {
		return config[this.currentInstance].verify_Mobile_Number!;
	},
	getSupplierReviewAndRatings(): string {
		return config[this.currentInstance].get_Supplier_Review_Ratings!;
	},
	createProductWatch(): string {
		return config[this.currentInstance].create_product_watch!;
	},
	productWatchNotification(): string {
		return config[this.currentInstance].product_watch_notification!;
	},
	supplierWatch(): string {
		return config[this.currentInstance].supplier_watch!;
	},
	supplierCheckWatch(): string {
		return config[this.currentInstance].supplier_check_watch!;
	},
	checkWatchAProductAdded(): string {
		return config[this.currentInstance].check_product_watch_added;
	},
	removeWatchAProductAdded(): string {
		return config[this.currentInstance].removeProductFromWatchList;
	},
	removeFavIcon(): string {
		return config[this.currentInstance].removeFavIcon;
	},
	generateReport(): string {
		return config[this.currentInstance].generate_report;
	},
	generateCsv(): string {
		return config[this.currentInstance].generate_csv;
	},
	getCartItems(): string {
		return config[this.currentInstance].cart_get_items;
	},
	getPaypalUrl(): string {
		return config[this.currentInstance].paypal_url;
	},
	setPaypalInfo(): string {
		return config[this.currentInstance].set_paypal_info;
	},
};
