import Iget from 'models/Iget';
import React, { ReactElement, useEffect, useState } from 'react';
import { Box } from 'rebass/styled-components';
import { BreadcrumbNav, Footer, Header, Search } from 'ui/components';
import { Sidebar } from 'ui/patterns';
import urls from 'utils/create.url';
import hooks from 'utils/requestCtx/reactQueryHooks';
import { BreadcrumbNavHolder, MainContent, PageSearchHolder } from './styles';
function Notifications(): ReactElement {
	const breadcrumbNav = [
		{ id: '01', label: 'Home', active: false, route: '/home/dashboard' },
		{ id: '02', label: 'Notifications', active: true },
	];
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const url = urls.GetSearchUrl({
		url: 'experion/notifications/details/search?',
		pageSize: 2000,
		fields: [{ field_name: 'participant_id', field_value: localStorage.getItem('selectedVenue')! }],
		ctx: { avoidSort: true },
	});
	const key = 'notification';
	const obj: Partial<Iget> = {
		params: {},
		url: url,
		enabled: true,
		key: key,
		resultFilteringRequired: true,
	};
	var qry = hooks.useQuery(obj);
	useEffect(() => {
		// setNotificationsFullViewList(qry.data);
	}, [qry.data]);

	const setFilteredData = (val) => {
		const sampleList = qry.data;
		if (val && val.length > 0) {
			const list = sampleList.filter((v) =>
				v.description.toLowerCase().includes(val.toLowerCase())
			);
		} else {
		}
	};
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	}
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={()=>onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={()=>onclickHambrgBtn()}></Sidebar>

			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbNavHolder>
					<BreadcrumbNav Heading="Notifications" breadcrumbList={breadcrumbNav} />
				</BreadcrumbNavHolder>
				<PageSearchHolder>
					<Box as="section">
						<Search onChange={(val) => setFilteredData(val)}></Search>
					</Box>
				</PageSearchHolder>
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default Notifications;
