import { transparentize, lighten, desaturate } from 'polished';
import tokenFontSizes from '../utils/designtokens/fontSizes';
import tokenFontWeights from '../utils/designtokens/fontWeights';
import tokenSpace from '../utils/designtokens/spacing';
import tokenColors from '../utils/designtokens/colors';
import tokenShadows from '../utils/designtokens/shadows';
import tokenFontFamily from '../utils/designtokens/fontFamilies';

const fonts = {
	body: "'OpenSansRegular', sans-serif",
	heading: 'OpenSansMedium',
};

const fontFamily = {
	light: tokenFontFamily.light,
	medium: tokenFontFamily.medium,
	bold: tokenFontFamily.bold,
};

const space = [
	0,
	tokenSpace.space4,
	tokenSpace.space8,
	tokenSpace.space12,
	tokenSpace.space16,
	tokenSpace.space20,
	tokenSpace.space24,
	tokenSpace.space32,
	tokenSpace.space40,
	tokenSpace.space48,
	tokenSpace.space64,
	tokenSpace.space80,
	tokenSpace.space96,
	tokenSpace.space112,
	tokenSpace.space128,
];
const radii = {
	round: '100px',
	rounded: '20px',
	soft: '3px',
	softer: '6px',
	sharp: '0px',
};
// const borders = ['1px'];

const zIndices = [-1, 0, 1, 2, 10, 1000, 1020, 1030, 1040, 1050, 1060];

const baseColors = {
	primary: tokenColors.yellow07,
	primaryDark: tokenColors.yellow21,
	secondary: tokenColors.purple,
	baseRed: tokenColors.red,
	baseGreen: tokenColors.green,
	baseYellow: tokenColors.yellow07,
	baseYellowLight: tokenColors.yellow08,
	darkYellow: tokenColors.yellow03,
	baseBlue: tokenColors.blue,
	baseNeutral: tokenColors.gray,
	black: tokenColors.black,
	white: tokenColors.white,
	white90: tokenColors.white90,
	gray: tokenColors.gray,
	purple: tokenColors.purple,
	orange: tokenColors.orange,
	red: tokenColors.red,
	green: tokenColors.green,
	redMedium: tokenColors.red02,

	yellow: [
		tokenColors.yellow,
		tokenColors.yellow01,
		tokenColors.yellow02,
		tokenColors.yellow03,
		tokenColors.yellow04,
		tokenColors.yellow05,
		tokenColors.yellow06,
		tokenColors.yellow07,
		tokenColors.yellow08,
		tokenColors.yellow09,
		tokenColors.yellow10,
		tokenColors.yellow11,
		tokenColors.yellow12,
	],
	blue: [tokenColors.blue, tokenColors.blue01, tokenColors.blue02],
};

const colors = {
	gray: tokenColors.gray,
	black: '#242B24',
	white: baseColors.white,
	primary: 'rgba(125,55,111,1)',
	secondary: baseColors.purple,
	red: baseColors.red,
	darkYellow: tokenColors.yellow03,
	processingBadge: '#ECB136',
	delayedBadge: '#EB5757',
	confirmedBadge: '#7FA495',
	OutForDeliveryBadge: '#D6C78E',
	deliveredWithIssue: '#808080',
	delivered: baseColors.green,
	expired: baseColors.black,
	onhold: '#FFB6C1',
	approved: baseColors.baseYellowLight,
	shipped: baseColors.purple,

	text: {
		body: '#22252B', // Dark gray on light mode and light gray on dark mode
		muted: '#6c757d',
		error: baseColors.red,
		error01: tokenColors.red03,
		warning: baseColors.yellow,
		success: baseColors.green,
		green1: tokenColors.green1,
		white: baseColors.white,
		Yellow: tokenColors.yellow07,
		white90: tokenColors.white90,
		textDark: tokenColors.black03,
		linkText: tokenColors.yellow05,
		textCount: tokenColors.yellow06,
		textHeading: tokenColors.gray01,
		textWelcome: tokenColors.yellow09,
		black: tokenColors.black01,
		black01: tokenColors.black01,
		black02: tokenColors.black08,
		black03: tokenColors.black09,
		black04: tokenColors.black10,
		black05: tokenColors.gray10,
		black06: tokenColors.gray11,
		black07: tokenColors.black11,

		blackLight: tokenColors.black02,
		tabHeading: tokenColors.yellow11,
		textLabel: tokenColors.black06,
		SubHeading: tokenColors.black07,
		darkYellow: tokenColors.yellow14,
	},

	bg: {
		highContrastNeutral: `var(--high-contrast-neutral)`, // Black on light mode and white on dark mode
		lowContrastNeutral: `var(--low-contrast-neutral)`, // Light gray on light mode and dark gray on dark mode
		constantDarkGray: baseColors.gray,
		yellow: tokenColors.yellow12,
		lightYellow: tokenColors.yellow01,
		lightRed: tokenColors.red01,
		lightYellow01: tokenColors.yellow13,
		lightYellow02: tokenColors.yellow15,
		lightYellow03: tokenColors.yellow16,
		lightYellow04: tokenColors.yellow17,
		lightYellow05: tokenColors.yellow20,
		lightYellow06: tokenColors.yellow22,
		darkYellow: tokenColors.yellow04,
		error: baseColors.red,
		userInfoHeader: tokenColors.yellow08,
		white: baseColors.white,
		menu: tokenColors.yellow10,
		blackCard: tokenColors.black04,
		paidBtn: tokenColors.gray05,
		grayLight: tokenColors.gray06,
		orangeLighterGrayish: tokenColors.orangeLighterGrayish,
		orangeLighter: tokenColors.orangeLighter,
	},

	border: {
		default: '#BDBCB9',
		blackfade: transparentize(0.15, tokenColors.black),
		whitefade: transparentize(0.15, tokenColors.white),
		grayLight: lighten(0.03, tokenColors.gray),
		grayMedium: tokenColors.gray02,
		grayMedium01: tokenColors.gray09,
		grayMedium02: tokenColors.yellow18,
		grayMedium03: tokenColors.gray12,
		grayMedium04: tokenColors.gray13,
		gray: tokenColors.gray,
		grayDark: tokenColors.gray,
		grayDarker: tokenColors.gray,
		green: tokenColors.green,
		greenLight: desaturate(0.4, tokenColors.green),
		error: baseColors.red,
		warning: baseColors.yellow,
		success: baseColors.green,
		white: baseColors.white,
		yellowDark: tokenColors.yellow02,
		darkYellow: tokenColors.yellow04,
		greyBorder: tokenColors.gray03,
		greyBorderLogo: tokenColors.gray04,
		userListTD: tokenColors.black05,
		grayLightBorder: tokenColors.gray07,
		grayLightBorder01: tokenColors.gray08,
		YellowBorder: tokenColors.yellow19,
		YellowBorder01: tokenColors.yellow23,
	},

	transparent: {
		black15: transparentize(0.15, tokenColors.black),
		black20: transparentize(0.2, tokenColors.black),
		black30: transparentize(0.3, tokenColors.black),
		black35: transparentize(0.35, tokenColors.black),
		black50: transparentize(0.5, tokenColors.black),
		white15: transparentize(0.15, tokenColors.white),
		white50: transparentize(0.5, tokenColors.white),
		white70: transparentize(0.7, tokenColors.white),
	},

	state: {
		error: tokenColors.red,
		warning: tokenColors.yellow,
		success: tokenColors.green,
		info: tokenColors.blue,
		default: tokenColors.white,
		fbButton: tokenColors.blue01,
		googleButton: tokenColors.blue02,
	},

	focusOutline: {
		error: transparentize(0.15, baseColors.baseRed),
	},
	context: {
		danger: {
			light: '#FFEEF0',
			medium: '#D73A49',
			dark: '#9E1C23',
		},
	},
};

const newColors = {
	primary01: '#000000', //black
	primary02: '#DC143C', //red
	secondary01: '#64A2F5', //light blue
	secondary02: '#3E68B9', //cyan-blue
	secondary03: '#F09951', //orange
	lightgray: '#f5f5f5',
	gray: '#A0A0A0',
	white: '#FFFFFF',
};

const shadows = {
	xLarge: tokenShadows.xLarge,
	large: tokenShadows.large,
	medium: tokenShadows.medium,
	small: tokenShadows.small,
};

const breakpoints = ['40em', '52em', '64em', '80em'];

export default {
	fonts,
	fontFamily,
	space,
	baseColors,
	colors,
	newColors,
	radii,
	shadows,
	zIndices,
	breakpoints,
};
