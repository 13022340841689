import styled from 'styled-components';

const MainContent = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
	padding: 0 50px;
	@media (max-width: 960px) {
		padding: 0 15px;
	}
`;
const ContentHolder = styled.div`
	height: 100%;
	padding: 0 50px;
	padding-bottom: 30px;
	width: 100%;

	@media (max-width: 768px) {
		padding: 0 15px;
	}
`;
const AddUserRoleHolder = styled.div`
	width: 100%;
	float: left;
	min-height: calc(100vh - 444px);
`;

const FooterBtnBlock = styled.div`
	padding: 25px 0 58px;
	@media (max-width: 640px) {
		padding: 20px 50px 28px;
	}
	div {
		@media (max-width: 1200px) {
			width: 100%;
		}
	}
	button {
		min-width: 100px;
		text-transform: capitalize;
		padding: 0;
		height: 48px;
		flex: 0 0 100px;
	}
	@media (max-width: 767px) {
		button {
			flex: 0 0 100px;
		}
	}
`;
const AccessList = styled.div`
	margin-top: 34px;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	padding-top: 26px;
	h3 {
		margin-bottom: 14px;
	}
	ul {
		margin: 0;

		.input {
			position: absolute;
			clip: rect(0, 0, 0, 0);
			&:checked ~ ul {
				display: block;
			}
			&:checked ~ label.TreeLabel {
				&:before {
					content: '–';
				}
			}
		}
		.input ~ ul {
			display: none;
		}
		li {
			line-height: 1.2;
			position: relative;
			display: inline-block;
			width: 100%;
			margin-top: 15px;
		}
		ul {
			li {
				padding: 0 0 0 44px;
				@media (max-width: 480px) {
					padding: 0 0 0 15px;
				}
			}
		}
		> li {
			&:last-child {
				padding-bottom: 0;
				&:before {
					display: none;
				}
			}
		}
	}
	.TreeLabel {
		position: relative;
		display: flex;
		align-items: center;
		background: ${({ theme }) => theme.colors.bg.menu};
		border: 1px solid ${({ theme }) => theme.colors.border.yellowDark};
		box-sizing: border-box;
		border-radius: 6px;
		min-height: 40px;
		padding: 10px;

		label {
			margin-left: auto;
			width: auto;
		}
		h1 {
			color: ${({ theme }) => theme.colors.text.SubHeading};
			font-family: 'OpenSansBold';
			font-size: 14px;
			margin: 0 10px;
		}
		h2 {
			color: ${({ theme }) => theme.colors.text.SubHeading};
			font-family: 'OpenSansSemiBold';
			font-size: 14px;
			margin: 0 10px;
		}
	}
	.TreeLabelSub {
		position: relative;
		display: flex;
		align-items: center;
		background: ${({ theme }) => theme.colors.bg.menu};
		border: 1px solid ${({ theme }) => theme.colors.border.yellowDark};
		box-sizing: border-box;
		border-radius: 6px;
		min-height: 40px;
		padding: 10px;

		label {
			margin-left: auto;
			width: auto;
		}
		h1 {
			color: ${({ theme }) => theme.colors.text.SubHeading};
			font-family: 'OpenSansBold';
			font-size: 14px;
			margin: 0 6px 0 10px;
		}
		h2 {
			color: ${({ theme }) => theme.colors.text.SubHeading};
			font-family: 'OpenSansSemiBold';
			font-size: 14px;
			margin: 0 10px;
		}
	}
	label.TreeLabel {
		cursor: pointer;
		&:hover {
			color: #666;
		}
		&:before {
			width: 24px;
			height: 24px;
			background: ${({ theme }) => theme.colors.bg.white};
			border: 1px solid ${({ theme }) => theme.colors.border.yellowDark};
			color: ${({ theme }) => theme.colors.border.yellowDark};
			position: relative;
			left: 0;
			z-index: 1;
			float: left;
			border-radius: 4px;
			content: '+';
			text-align: center;
			font-size: 24px;

			line-height: 0.8em;
			font-family: 'OpenSansBold';
		}
		&:after {
			border-bottom: 0;
		}
		&.active {
			label {
				&::before {
					background: ${({ theme }) => theme.colors.border.yellowDark};
				}
			}
		}
	}

	.tree_custom {
		display: block;
		background: #eee;
		padding: 1em;
		border-radius: 0.3em;
	}
	.app-l-open-sub-view {
		label.TreeLabel:before {
			content: '–' !important;
		}
		ul {
			display: block !important;
		}
	}
`;
const Badge = styled.div`
	background: ${({ theme }) => theme.baseColors.white};
	font-size: 12px;
	line-height: 12px;
	font-family: 'OpenSansBold';
	color: ${({ theme }) => theme.colors.text.SubHeading};
	margin: 0;
	width: 27px;
	height: 16px;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 12px;
`;
const RadioBtnHolder = styled.div`
	margin-left: auto;
	input[type='radio'] + label::before {
		width: 21px;
		height: 21px;
		box-sizing: border-box;
		border: 1px solid ${({ theme }) => theme.colors.border.yellowDark};
	}
	input[type='radio']:checked + label::before {
		/* border: none; */
		/* background: ${({ theme }) => theme.colors.border.yellowDark}; */
		/* content: url(${require('../../ui/images/check_icon.svg')}); */
	}
	input[type='radio']:checked + label svg path {
		fill: ${({ theme }) => theme.baseColors.white};
	}
	input[type='radio']:checked + label {
		position: relative;
	}
	input[type='radio']:checked + label span {
		position: absolute;
		width: 21px;
		height: 21px;
		background: ${({ theme }) => theme.colors.border.yellowDark};
		border-radius: 50px;
		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			width: 13px;
			height: 10px;
		}
	}
	input.active_tick + label span {
		position: absolute;
		width: 21px;
		height: 21px;
		background: ${({ theme }) => theme.colors.border.yellowDark};
		border-radius: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		svg {
			width: 13px;
			height: 10px;
		}
	}
	input.active_tick + label span svg path {
		fill: ${({ theme }) => theme.baseColors.white};
	}
`;

export {
	MainContent,
	ContentHolder,
	AddUserRoleHolder,
	FooterBtnBlock,
	AccessList,
	Badge,
	RadioBtnHolder,
};
