import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { OrderStatuses, venueUserPermissionList } from 'models/enum-constants/common.constants';
import queryString from 'query-string';
import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useLocation } from 'react-router-dom';
import { Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { BreadcrumbNav, Button, Footer, Header, Loader } from 'ui/components';
import icons from 'ui/icons';
import { RecentOrderDetailsEditView, Sidebar, Modal, OrderGridSupplierView } from 'ui/patterns';
import { RecentOrdersDetailsListView } from 'ui/patterns/recentOrdersDetailsListView';
import urls from 'utils/create.url';
import helperFunctions from 'utils/helper.functions';
import http from 'utils/http.service';
import pageConstants from 'utils/pageConstants';
import notify from 'utils/toast.service';
import utilityFunctions from 'utils/utility.functions';
import api from 'utils/requestCtx/api.service';
import env from 'utils/constants';
import Order from 'admin/order';
import moment from 'moment';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import SweetAlert from 'react-bootstrap-sweetalert';
import { UseGlobalState } from 'models/globalStateAccess';
import Iget from 'models/Iget';
import hooks from 'utils/requestCtx/reactQueryHooks';
import Ipost from 'models/Ipost';

const PageHolder = styled.div`
	position: relative;
	z-index: 2;
	background: #fff;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
`;
const MainContent1 = styled.div`
	text-align: center;
	/* CLIENT-SPECIFIC STYLES */
	body,
	table,
	td,
	a {
		-webkit-text-size-adjust: 100%;
		-ms-text-size-adjust: 100%;
	} /* Prevent WebKit and Windows mobile changing default text sizes */
	table,
	td {
		mso-table-lspace: 0;
		mso-table-rspace: 0;
	} /* Remove spacing between tables in Outlook 2007 and up */
	img {
		-ms-interpolation-mode: bicubic;
	} /* Allow smoother rendering of resized image in Internet Explorer */

	/* RESET STYLES */
	img {
		border: 0;
		height: auto;
		line-height: 100%;
		outline: none;
		text-decoration: none;
	}
	table {
		border-collapse: collapse !important;
	}
	body {
		height: 100% !important;
		margin: 0 !important;
		padding: 0 !important;
		width: 100% !important;
		background: #fff !important;
	}
	/* iOS BLUE LINKS */
	a[x-apple-data-detectors] {
		color: inherit !important;
		text-decoration: none !important;
		font-size: inherit !important;
		font-family: inherit !important;
		font-weight: inherit !important;
		line-height: inherit !important;
	}

	/* MOBILE STYLES */

	/* ANDROID CENTER FIX */
	div[style*='margin: 16px 0;'] {
		margin: 0 !important;
	}

	/* Outlook 2016 Height Fix */
	table,
	tr,
	td {
		border-collapse: collapse;
	}
	tr {
		font-size: 0;
		line-height: 0;
		border-collapse: collapse;
	}

	/* media query */
	@media screen and (max-width: 800px) {
		.m-heading {
			font-size: 14px !important;
		}
		.m-col-width {
			width: auto !important;
			max-width: 100% !important;
			min-width: 100% !important;
		}
		.paddingshrink {
			padding: 10px !important;
		}
		.m-venue {
			font-size: 15px !important;
		}
		.m-created {
			font-size: 12px !important;
		}
	}
`;
const MainContent = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
	padding: 0 50px;
	@media (max-width: 960px) {
		padding: 0 15px;
	}
`;
const OrderDetailsBlock = styled.div`
	margin-bottom: 50px;
	.orderAgain {
		@media (max-width: 600px) {
			div {
				display: block;
				font-size: 11px;
			}
		}
	}
	h4 {
		margin: 40px 0 10px;
	}
	h5 {
		margin-bottom: 10px;
	}
	.action-download-order {
		button + button {
			margin-left: 8px;
		}
	}
	header {
		justify-content: flex-end;
		align-items: center;
		margin-top: 30px;
		h4 {
			margin-top: 0;
			margin-bottom: 0;
		}
		button {
			height: 50px;
			text-transform: capitalize;
			span {
				display: flex;
				white-space: nowrap;
				align-items: center;
			}
			svg {
				margin-right: 10px;
				path {
					fill: ${({ theme }) => theme.baseColors.primary};
				}
			}
			&:hover,
			&:focus {
				svg {
					path {
						fill: ${({ theme }) => theme.baseColors.white};
					}
				}
			}
		}
	}
	.headingBtnBLock {
		h5 {
			flex: 1;
			strong {
				font-size: 16px;
				font-family: 'OpenSansMedium';
				padding-left: 3px;
			}
		}
		@media (max-width: 768px) {
			h5 {
				font-size: 18px;

				span {
					display: inline-block;
					width: 100%;
					font-size: 18px;
				}
			}
		}
		@media (max-width: 600px) {
			flex-wrap: wrap;
			flex-direction: column;
			header {
				margin-left: auto;
				margin-top: 10px !important;
			}
			h5 {
				font-size: 18px;

				span {
					font-size: 18px;
					float: left;
				}
			}
		}
	}
`;
function RecentOrderDetails(): ReactElement {
	const location = useLocation();
	const options = {
		orientation: 'landscape',
		unit: 'in',
		format: [4, 2],
	};
	const [order, setOrder] = useState([] as any);
	const [hasData, setHasData] = useState(false);
	const parsed = queryString.parse(location.search);
	const [productsBySuppliers, setSupplierGroup] = useState([] as any);
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const [count, setProductCount] = useState(0);
	const [eligibleForCancel, setEligibleForCancel] = useState(true);
	const [showOrderGridModal, setShowOrderGridModal] = useState(false);
	const [productGridData, setProductGridData] = useState('');
	const [action, setAction] = useState('');
	const [modalText, setmodalText] = useState('');
	const [showModal, setShowModal] = useState(false);
	const [postObj, setObj] = useState({} as any);
	const [productsStatusData, setProductsStatusData] = useState([]);
	const [visible, setVisible] = useState(false);
	const [showLoader, setShowLoader] = useState(false);
	const [printData, setPrintData] = useState<any>(null);
	const [productsToMoveArr, setProductsToMoveArr] = useState([] as any);
	const [disableBtnMove, setDisableBtnMove] = useState(false);
	const [message, setMessage] = useState('');
	const [venueUserId, setVenueUserId] = useState<any>(localStorage.getItem('userId'));
	const [venueId, setVenueId] = useState<any>(localStorage.getItem('selectedVenue'));
	const [state, dispatch] = UseGlobalState();
	const [isLoading, setIsLoading] = useState(false);

	//File Download
	const canOptions = {
		width: window.innerWidth,
	};
	const printDocument = () => {
		window.scrollTo(0, 0);
		const input = document.getElementById('divToPrint') as HTMLCanvasElement;
		html2canvas(input, canOptions).then((canvas) => {
			const imgData = canvas.toDataURL('image/png');
			const pdf = new jsPDF('l', 'px', [canvas.width, canvas.height + 50]);
			const width = pdf.internal.pageSize.getWidth();
			const height = pdf.internal.pageSize.getHeight();

			const widthRatio = width / canvas.width;
			const heightRatio = height / canvas.height;
			const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

			const canvasWidth = canvas.width * ratio;
			const canvasHeight = canvas.height * ratio;

			let marginX = (width - canvas.width) / 2;
			let marginY = (height - canvas.height) / 2;

			if (marginX == 0) marginX = 50;
			if (marginY == 0) marginY = 50;

			pdf.addImage(imgData, 'PNG', marginX, marginY, canvasWidth, canvasHeight);

			if (
				/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
					navigator.userAgent.toLowerCase()
				)
			) {
				// Mobile Download
				// https://github.com/eligrey/FileSaver.js
				canvas.toBlob(function (blob) {
					saveAs(blob, 'download.png');
				});
			} else {
				pdf.save('download.pdf');
			}
		});
	};

	const breadcrumbNav = [
		{ id: '01', route: '/home/dashboard', label: 'Home', active: false },
		{ id: '02', route: '/store/orders?tab=my-orders', label: 'My Orders', active: false },
		{ id: '03', label: order?.order_group_increment_id, active: true },
	];

	function getStatusList(status, arrayList) {
		const data = [] as any;
		if (status && arrayList.length == 1) {
			data.push(status);
		} else if (status && arrayList.length > 1) {
			const items = JSON.parse(JSON.stringify(status));
			items.map((v) => {
				data.push(JSON.parse(v));
			});
		}
		return data;
	}

	const printDocumentToPDF = (data: any) => {
		const setData = JSON.parse(JSON.stringify(data));
		setPrintData(setData);
		setShowLoader(true);
		window.scrollTo(0, 0);
		var target = document.getElementsByTagName('BODY')[0];
		disableBodyScroll(target);
		setVisible(true);
	};

	useEffect(() => {
		if (visible && printData) {
			const gridColumnSize = 20;
			const length = printData?.items?.length;
			const pdfFileName =
				printData?.extension_attributes?.supplier_order_increment_id +
				'_' +
				printData?.extension_attributes?.supplier_name +
				'_' +
				moment().format('DD MMM YYYY').toString() +
				'_' +
				moment().format('h mm A').toString() +
				'.pdf';
			const numbers = Math.ceil(length / gridColumnSize);
			const input = document.getElementById('printDocument1') as HTMLCanvasElement;
			const canOptions = {
				// width: window.innerWidth,
				// scale: 500/(1366),
				// height: window.outerHeight + window.innerHeight,
				// windowHeight: window.outerHeight + window.innerHeight,
				// scrollY: -window.scrollY
			};
			html2canvas(input, canOptions).then((canvas) => {
				const imgData = canvas.toDataURL('image/png');
				const pdf = new jsPDF('p', 'mm', 'a4');
				const width = pdf.internal.pageSize.getWidth();
				const height = pdf.internal.pageSize.getHeight();

				const widthRatio = width / canvas.width;
				const heightRatio = height / canvas.height;
				const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

				const canvasHeight = canvas.height * ratio;

				let marginX = (width - canvas.width) / 2;
				let marginY = (height - canvas.height) / 2;

				if (marginX == 0) marginX = 50;
				if (marginY == 0) marginY = 50;
				pdf.addImage(imgData, 'PNG', 0, 0, width, canvasHeight);
				if (numbers === 1) {
					setVisible(false);
					setPrintData(null);
					clearAllBodyScrollLocks();
					setShowLoader(false);
					pdf.save(pdfFileName);
				} else {
					let k = 1;
					for (let i = 2; i <= numbers; i++) {
						const id = `printDocument${i}`;
						const input = document.getElementById(id) as HTMLCanvasElement;
						html2canvas(input, canOptions).then((canvas) => {
							k++;
							const widthRatio = width / canvas.width;
							const heightRatio = height / canvas.height;
							const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

							const canvasHeight = canvas.height * ratio;

							const imgData = canvas.toDataURL('image/png');
							pdf.addPage();
							pdf.setPage(k);
							pdf.addImage(imgData, 'PNG', 0, 0, width, canvasHeight);
							if (numbers === k) {
								setVisible(false);
								setPrintData(null);
								clearAllBodyScrollLocks();
								setShowLoader(false);
								pdf.save(pdfFileName);
							}
						});
					}
				}
			});
		}
	}, [visible, printData]);

	useEffect(() => {
		if (parsed.id) {
			http
				.GET({
					url: urls.GetSearchUrl({
						url: 'experion/order/search?',
						fields: [
							{
								field_name: 'venue_id',
								field_value: localStorage.getItem('selectedVenue')!,
							},
							{
								field_name: 'venue_user_id',
								field_value: localStorage.getItem('userId')!,
							},
							{ field_name: 'for_approval', field_value: '0,2', field_conditionType: 'in' },
						],
						pageSize: 2000,
						filterDeleted: true,
					}),
				})
				.then(function (d) {
					setOrder(d.data?.items?.find((x) => x.order_group_id == parsed.id));
				});

			http
				.GET({
					url: urls.GetSearchUrl({
						url: 'orders?',
						fields: [
							{
								field_name: 'group_order_id',
								field_value: parsed.id,
							},
						],
					}),
				})
				.then(function (d) {
					if (d.data?.items?.length > 0) {
						const datas = d.data?.items;
						let count = 0;
						datas?.map(function (item) {
							const arrayList = [] as any;
							arrayList.push(item.status);
							const statusList = getStatusList(item.status, arrayList);
							const orderStatus = utilityFunctions.getOrderStatus(statusList);
							// if (!eligibleForCancel)
							setEligibleForCancel(
								[
									OrderStatuses.Cancelled,
									OrderStatuses.Closed,
									OrderStatuses.Rejected,
									OrderStatuses.Disputed,
									OrderStatuses.Delivered,
									OrderStatuses.Confirmed,
								].includes(orderStatus) ||
									statusList?.length == 0 ||
									item.extension_attributes.enable_cancel_button == false
									? false
									: true
							);
							// let diff = moment.duration(moment(new Date()).diff(moment(item.created_at)));
							// let diffHours = diff.hours() + diff.days() * 24;
							count += item.items?.length;
						});
						setProductCount(count);
						setSupplierGroup(datas);
					}
					setHasData(true);
				});
		}
	}, [location, parsed.id]);

	function cancelOrders(msg) {
		let hasError = false;
		if (productsBySuppliers?.length > 0) {
			productsBySuppliers.map((item) => {
				http
					.PUT({ url: `orders/create`, data: getData(msg, item.entity_id, item.customer_email) })
					.then(function (data) {
						{
							if (typeof data == 'undefined') hasError = true;
							showMsg(hasError);
						}
					})
					.catch(function () {
						hasError = true;
						showMsg(hasError);
					});
			});
		} else {
			notify('No status details found !!').info();
		}
	}
	function showMsg(hasError) {
		if (!hasError) {
			notify('Order cancelled successfully').success();
		} else notify('Order cancellation failed').error();
	}

	function getData(msg, id, mail) {
		return {
			entity: {
				entity_id: id,
				state: 'canceled',
				status: 'canceled',
				status_histories: [
					{
						comment: msg,
						entity_name: 'order',
						is_customer_notified: 1,
						is_visible_on_front: 1,
						parent_id: id,
						status: 'canceled',
					},
				],
				extension_attributes: {
					receiver_email: mail,
				},
			},
		};
	}

	function confirm(action, id, qty) {
		setAction(action);
		switch (action) {
			case 'favorite':
				showConfirmationModal('Delete order?', id, qty);
				break;
			case 'order':
				showConfirmationModal('Order again ?', id, qty);
				break;
			case 'cancelOrder':
				showConfirmationModal('Cancel order?', id, qty);
				break;
			default:
				break;
		}
	}

	function showConfirmationModal(text, id = 0, qty = 0) {
		if (id != 0) setObj({ product_id: id, qty: qty });
		setmodalText(text);
		setShowModal(!showModal);
	}
	async function closePopup() {
		await setShowModal(!showModal);
	}
	function onSubmit() {
		setShowModal(!showModal);
		if (action == 'order') addItemsToCart();
	}

	var cartId: any;
	cartId = localStorage.getItem('cartId');

	async function addItemsToCart() {
		setIsLoading(true);
		let id: any;
		let CUSTOMER_TOKEN = localStorage.getItem('customerToken');
		id = await utilityFunctions.getCartId(CUSTOMER_TOKEN);
		if (id !== undefined) {
			if (productsToMoveArr.length > 0) {
				let payload = {
					products: {
						quote_id: parseInt(id),
						items: productsToMoveArr,
						venue_user_id: parseInt(venueUserId),
						venue_id: parseInt(venueId),
						reorder: 1,
					},
				};
				const obj: Partial<Ipost> = {
					data: payload,
					key: 'recentToCart',
					url: env.multiCartURL(),
					message: 'Your cart has been updated!',
					headers: { Authorization: localStorage.getItem('customerToken') },
				};

				try {
					await api
						.post(obj)
						.then(function (data) {
							if (data !== undefined) {
								setIsLoading(false);
								fetchTotal();
							} else {
								setIsLoading(false);
							}
						})
						.catch(function (data) {
							setIsLoading(false);
						});
				} catch (e) {
					setIsLoading(false);
				}
			}
		} else {
			setIsLoading(false);
		}
	}

	//api to get total amount
	const url1 = '/carts/' + cartId + '/totals';

	const obj6: Partial<Iget> = {
		params: {},
		url: url1,
		enabled: false, //manual fetch
		key: 'carttotalKey1',
		resultFilteringRequired: false,
	};
	var qryTotal = hooks.useQuery(obj6);

	const fetchTotal = () => {
		qryTotal.refetch().then((data) => {
			if (data) {
				updateCartTotal(data);
			}
		});
	};

	function updateCartTotal(data) {
		if (data) {
			let datas = data.items?.filter((x) => x.is_deleted != true);
			if (datas?.length > 0) {
				dispatch({ cartTotal: helperFunctions.formatAmount(data.grand_total) });
				dispatch({ cartItemCount: datas?.length });
				// setTotalCount(data.subtotal);
			} else {
				dispatch({ cartItemCount: '0' });
				dispatch({ cartTotal: '' });
				// setTotalCount(0);
			}
		} else {
			dispatch({ cartItemCount: '0' });
			dispatch({ cartTotal: '' });
			// setTotalCount(0);
		}
	}

	function criteriaCheckSupplier(id) {
		const payload = {
			reorder: {
				quote_id: localStorage.getItem('cartId'),
				venue_id: localStorage.getItem('selectedVenue'),
				order_id: id,
			},
		};
		api
			.post({
				data: payload,
				url: 'experion/order/reorder/',
				key: 'recentToCart',
				message: ' ',
			})
			.then(function (data) {
				if (data != undefined) {
					let cart_status = data[0].cart_status;
					let productsToMoveArr = [] as any;

					const productData = data[0].order_details.map((x) =>
						utilityFunctions.OrderGridProductData(
							x.product_data,
							x.sensitive_data,
							x.non_sensitive_data
						)
					);

					const productDataArray = productData.map((x) =>
						utilityFunctions.productsAvailable(x.product_id, x.qty_ordered, x.sensWarning)
					);
					productDataArray.map((x) => {
						if (x && x.productsObj && Object.keys(x.productsObj).length) {
							productsToMoveArr.push(x.productsObj);
						}
					});

					setProductsToMoveArr(productsToMoveArr);

					if (productsToMoveArr.length > 0) {
						setDisableBtnMove(false);
					} else {
						setDisableBtnMove(true);
					}

					if (cart_status == true) {
						confirm('order', id, 0);
					} else if (cart_status == false) {
						setProductGridData(productData);
						setShowOrderGridModal(!showOrderGridModal);
					}
				}
			});
	}

	const productsAvailable = (product_id, qty_ordered, sensWarning, nonSensWarning) => {
		const productsMovable: string[] = [];
		let items = {} as any;

		if (sensWarning === false) {
			productsMovable.push(product_id);

			items = { product_id: product_id, qty: qty_ordered };
		}

		if (productsMovable.length > 0) {
			setDisableBtnMove(false);
		} else {
			setDisableBtnMove(true);
		}

		const ObjectData = {
			productsMovable,
			productsObj: items,
		};
		return ObjectData;
	};
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	};
	return (
		<>
			<PageHolder>
				{showLoader || isLoading ? <Loader></Loader> : null}
				{/* Main Header */}
				<Header toggleSideBar={() => onclickHambrgBtn()}></Header>
				{/* Side Menu Bar */}
				<Sidebar hide={hideSideBar} toggleSideBar={() => onclickHambrgBtn()}></Sidebar>
				<MainContent>
					{/* Breadcrumb and Sorting Session */}
					<BreadcrumbNav Heading="My Orders" breadcrumbList={breadcrumbNav} />
					{(hasData && (
						<OrderDetailsBlock>
							<div id="divToPrint">
								<RecentOrderDetailsEditView
									supplierCount={productsBySuppliers?.length}
									cancelOrders={cancelOrders}
									data={order}
									eligibleForCancel={eligibleForCancel}
								></RecentOrderDetailsEditView>
								{/* {utilityFunctions.isGranted(venueUserPermissionList.DownloadPDF) && (
								<Flex as="header" className="action-download-order">
									<Button
										data-html2canvas-ignore="true"
										variant="secondary"
										onClick={() => printDocument()}
									>
										<icons.downloadIcon />
										<div>Download</div>
									</Button>
								</Flex>
							)} */}

								{productsBySuppliers &&
									productsBySuppliers.map((data, i) => {
										let deliverySlot = data.extension_attributes?.delivery_slot
											? data.extension_attributes?.delivery_slot
											: '';
										deliverySlot = deliverySlot.split(' ');
										let deliverySlotDay = null as any;
										let deliverySlotDate = null as any;
										if (deliverySlot.length === 2) {
											deliverySlotDate = moment(deliverySlot[1], 'DD-MM-YYYY');
											deliverySlotDate = helperFunctions.formatDate(deliverySlotDate.toDate());
											deliverySlotDay = deliverySlot[0];
										}
										let rejectionReason = '' as any;
										if (
											data.status == OrderStatuses.Rejected &&
											data.status_histories &&
											data.status_histories.length > 0
										) {
											rejectionReason = data.status_histories[0].comment?.split(':')[0];
										}
										return (
											<Fragment key={helperFunctions.getRandomInt(pageConstants.maxValue)}>
												<Flex
													flexDirection="row"
													alignItems="center"
													justifyContent="space-between"
													marginTop="30px"
													marginBottom="20px"
													className="headingBtnBLock"
												>
													{deliverySlot && deliverySlot.length === 2 ? (
														<Text
															as="h5"
															key={i}
															fontSize="20px"
															lineHeight="26px"
															color="text.black"
															style={{ marginBottom: '0px' }}
														>
															{data.extension_attributes.supplier_name}
															<strong>{` ${data.items?.length}`}</strong>
															{` ( `}
															<strong>{`#${data.extension_attributes.supplier_order_increment_id}`}</strong>
															{` - ${helperFunctions.toPascalCase(
																data.status?.replace('_', ' ') +
																	(rejectionReason != '' && rejectionReason != undefined
																		? ': ' + rejectionReason
																		: '')
															)} ) `}
															<span>
																Delivery Date:
																<strong>{`${deliverySlotDay} ${deliverySlotDate}`}</strong>
															</span>
														</Text>
													) : (
														<Text
															as="h5"
															key={i}
															fontSize="20px"
															lineHeight="26px"
															color="text.black"
															style={{ marginBottom: '0px' }}
														>
															{data.extension_attributes.supplier_name}
															<strong>{` ${data.items?.length}`}</strong>
															{` ( `}
															<strong>{`#${data.extension_attributes.supplier_order_increment_id}`}</strong>
															{` - ${helperFunctions.toPascalCase(
																data.status?.replace('_', ' ') +
																	(rejectionReason != '' && rejectionReason != undefined
																		? ': ' + rejectionReason
																		: '')
															)} )`}
														</Text>
													)}

													{utilityFunctions.isGranted(
														venueUserPermissionList.ManageProductsinCart
													) && (
														<Flex
															as="header"
															className="action-download-order"
															style={{ marginTop: '0' }}
														>
															<Button onClick={() => criteriaCheckSupplier(data.entity_id)}>
																Order Again
															</Button>
															<Button variant="secondary" onClick={() => printDocumentToPDF(data)}>
																<icons.downloadIcon />
																<div>Download</div>
															</Button>
														</Flex>
													)}
												</Flex>

												<RecentOrdersDetailsListView
													ordersDetailsListView={data.items}
												></RecentOrdersDetailsListView>
											</Fragment>
										);
									})}
							</div>
						</OrderDetailsBlock>
					)) || (
						// new Array(10).fill(null).map((_, i) => {
						// 	return <Skeleton key={i} width="1400px" height="150px" />;
						// }) ||
						// new Array(10).fill(null).map((_, i) => {
						// 	return <Skeleton key={i} width="1400px" height="50px" />;
						// })
						<Loader></Loader>
					)}
				</MainContent>

				{/* Footer Session */}
				<Footer></Footer>
				{/* order again grid */}
				<SweetAlert
					show={showModal}
					confirmBtnText="Confirm"
					onConfirm={onSubmit}
					showCancel={true}
					onCancel={closePopup}
					title={'Confirmation Box'}
				>
					{() => (
						<form>
							<hr />
							{modalText}
							<hr />
						</form>
					)}
				</SweetAlert>
				<Modal width="800px" showModal={showOrderGridModal} setShowModal={setShowOrderGridModal}>
					<OrderGridSupplierView
						data={productGridData}
						setShowModal={setShowOrderGridModal}
						productsToMoveArr={productsToMoveArr}
						showModal={showOrderGridModal}
						disableBtnMove={disableBtnMove}
						fetchTotal={fetchTotal}
						utilityFunctions={utilityFunctions}
						setIsLoading={setIsLoading}
					/>
				</Modal>
			</PageHolder>
			{visible ? <Order inputData={printData} /> : null}
		</>
	);
}

export default RecentOrderDetails;
