const colors = {
	gray: 'rgba(106, 115, 125, 1)',
	gray01: 'rgba(31, 38, 31, 1)',
	gray02: 'rgba(189, 188, 185, 1)',
	gray03: 'rgba(189, 188, 185, 0.6)',
	gray04: 'rgba(196, 196, 196, 1)',
	gray05: 'rgba(224,222,218,1)',
	gray06: 'rgba(245,244,243,1)',
	gray07: 'rgba(237, 237, 237, 1)',
	gray08: 'rgba(218, 218, 218, 1)',
	gray09: 'rgba(65, 69, 63, 1)',
	gray10: 'rgba(67, 66, 66, 1)',
	gray11: 'rgba(120, 120, 120, 1)',
	gray12: 'rgba(0,0,0,0.15)',
	gray13: '#242B24',
	purple: 'rgba(89, 88, 157, 1)',
	orange: 'rgba(250, 144, 40, 1)',
	green: 'rgba(40, 167, 69, 1)',
	green1: 'rgba(122, 193, 80, 1)',

	white90: 'rgb(255 255 255 / 90%)',
	red: 'rgba(215, 58, 73, 1)',
	red01: 'rgba(255, 238, 238, 1)',
	red02: 'rgba(255, 87, 87, 1)',
	red03: 'rgba(235, 87, 87, 1)',
	yellow: 'rgba(255, 211, 61, 1)',
	yellow01: 'rgba(246,244,238,1)',
	yellow02: 'rgba(217,201,162,1)',
	yellow03: 'rgba(125,55,111,1)',
	yellow04: 'rgba(102,111,53,1)',
	yellow05: 'rgba(134,134,126,1)',
	yellow06: 'rgba(158, 159, 158, 1)',
	// yellow07: 'rgba(125,55,111,1)',
	yellow07: '#000',
	yellow08: 'rgba(135, 137, 99, 1)',
	yellow09: 'rgba(255, 255, 255, 0.7)',
	yellow10: 'rgba(240, 233, 218, 1)',
	yellow11: 'rgba(105,108,60,1)',
	yellow12: 'rgba(236,177,54,1)',
	yellow13: 'rgba(253,249,240,1)',
	yellow14: 'rgba(73,75,41,1)',
	yellow15: 'rgba(240, 233, 218, 1);',
	yellow16: 'rgb(166, 168, 140);',
	yellow17: 'rgba(150, 150, 150, 1);',
	yellow18: 'rgba(168, 169, 173, 1);',
	yellow19: 'rgba(217, 201, 162, 1);',
	yellow20: 'rgba(250,244,234,1);',
	yellow21: 'rgba(81,0,72,1);',
	yellow22: 'rgba(240,233,218,1);',
	yellow23: 'rgba(240,230,215,1);',

	blue: 'rgba(0, 162, 242, 1)',
	blue01: 'rgba(70,88,152,1)',
	blue02: 'rgba(81,142,248,1)',
	black: 'rgba(27, 31, 35, 1)',
	black01: 'rgba(36, 43, 36, 1)',
	black02: 'rgba(92, 94, 87, 1)',
	black03: 'rgba(60, 65, 57, 100%)',
	black04: 'rgba(27,25,25,1)',
	black05: 'rgba(237,235,230,1)',
	black06: 'rgba(34,37,43,1)',
	black07: 'rgba(65,69,63,1)',
	black08: 'rgba(0, 0, 0, 1)',
	black09: 'rgba(26, 25, 25, 1)',
	black10: 'rgba(50, 50, 50, 1)',
	black11: 'rgba(4, 6, 45, 1)',

	white: 'rgba(255, 255, 255, 1)',

	// orangeLighterGrayish: '#dacbb7 ',
	orangeLighterGrayish: '#fff ',
	// orangeLighter: '#FAF3E7',
	orangeLighter: '#f5f5f5',

};

export default colors;
