import ReasonForRejectionModal from 'admin/orderRequest/reasonForRejection/index';
import { dcUserPermissionList, OrderStatuses } from 'models/enum-constants/common.constants';
import Iget from 'models/Iget';
import Ipost from 'models/Ipost';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory } from 'react-router-dom';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { Box, Text } from 'rebass/styled-components';
import { Button, Loader, Search, TableList } from 'ui/components';
import icons from 'ui/icons';
import { Modal } from 'ui/patterns';
import urls from 'utils/create.url';
import helper from 'utils/helper.functions';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import utilityFunctions from 'utils/utility.functions';
import { PageSearchHolder, PendingOrderHolder } from './styles';

function AllOrders(): ReactElement {
	localStorage.removeItem('refetchOnOrderReload');
	const [allOrders, setAllOrders] = useState<any>([]);
	const history = useHistory();
	const [entityID, setEntityId] = useState('');
	const [loading, setLoading] = useState(false);
	const [showPopup, setShowModal] = useState(false);
	const [modalText, setmodalText] = useState('');
	const [firstReasonChecked, setFirstReason] = useState(true);
	const [secReasonChecked, setSecondReason] = useState(false);
	const [action, setAction] = useState('');
	const [showReasonForRejectionModal, setShowReasonForRejectionModal] = useState(false);
	const toggleReasonForRejectionModal = () => {
		setShowReasonForRejectionModal(!showReasonForRejectionModal);
	};
	const url = urls.GetSearchUrl({
		url: `orders?`,
		pageSize: 10,
		fields: [
			{
				field_name: 'dcid',
				field_value: localStorage.getItem('selectedLocation')!,
			},
			{
				field_name: 'status',
				field_value: `pending_approval`,
				field_conditionType: 'neq',
			},
			{
				field_name: 'status',
				field_value: `rejected`,
				field_conditionType: 'neq',
			},
			{
				field_name: 'status',
				field_value: `expired`,
				field_conditionType: 'neq',
			},
			{
				field_name: 'status',
				field_value: `approved`,
				field_conditionType: 'neq',
			},
			{
				field_name: 'status',
				field_value: `holded`,
				field_conditionType: 'neq',
			},
		],
	});
	const obj: Partial<Iget> = {
		params: {},
		url: url,
		enabled: true,
		key: 'allOrders',
	};
	var qry = hooks.useQuery(obj);
	useEffect(() => {
		qry.data && qry.data.items && setAllOrders(qry.data.items);
	}, [qry.data && qry.data.items]);
	const setFilteredData = (val) => {
		const sampleList = qry.data.items;
		if (val && val.length > 0) {
			var list = sampleList.filter(
				(v) =>
					v.entity_id.toString().includes(val) ||
					v.total_qty_ordered.toString().includes(val) ||
					v.subtotal.toString().includes(val) ||
					v.extension_attributes.venue_name.toLowerCase().includes(val.toLowerCase()) ||
					helper.formatDate(v.created_at).toLowerCase().includes(val.toLowerCase()) ||
					v.status.toLowerCase().includes(val.toLowerCase())
			);
			setAllOrders(list);
		} else {
			setAllOrders(qry.data.items);
		}
	};
	const orderAcceptRejectSubmit = (action, id, e) => {
		if (action == OrderStatuses.Confirmed) {
			setEntityId(id);
			setAction(action);
			showConfirmationModal(action, 'Are you sure you want to Accept this Order?', id);
		} else if (action === OrderStatuses.Shipped) {
			e.stopPropagation();
			setEntityId(id);
			setAction(action);
			showConfirmationModal(
				action,
				'Are you sure wants to change the status of this order to Shipped?',
				id
			);
		}
	};
	const changeChekedProp = (action, comment) => {
		if (comment == 'product') {
			if (action) {
				setSecondReason(false);
				setFirstReason(true);
			} else {
				setFirstReason(true);
				setSecondReason(false);
			}
		} else {
			if (action) {
				setFirstReason(false);
				setSecondReason(true);
			} else {
				setFirstReason(false);
				setSecondReason(true);
			}
		}
	};
	function showConfirmationModal(action, text, id) {
		setmodalText(text);
		setShowModal(!showPopup);
	}
	async function closePopup() {
		await setShowModal(!showPopup);
	}
	const cancel = () => {
		setShowReasonForRejectionModal(!showReasonForRejectionModal);
	};
	const onSubmitReason = (reasonForRejection, status) => {
		setLoading(true);
		if (action == OrderStatuses.Rejected) {
			const payload = {
				entity_id: entityID,
				state: OrderStatuses.Cancelled,
				status: action,
				status_histories: [
					{
						comment: `${
							reasonForRejection != ''
								? (firstReasonChecked ? 'Product unavailable:' : 'Delivery unavailable:') +
								  reasonForRejection
								: ''
						}`,
						entity_name: 'order',
						is_customer_notified: 1,
						is_visible_on_front: 1,
						parent_id: entityID,
						status: action,
					},
				],
				extension_attributes: {
					receiver_email: allOrders.filter((x) => x.entity_id == entityID)[0].customer_email,
				},
			};
			const obj: Partial<Ipost> = {
				data: { entity: payload },
				params: {},
				url: `orders/create`,
				message: `Order(s) rejected successfully`,
			};
			try {
				setLoading(true);
				api.put(obj).then((data) => {
					if (data !== undefined) {
						qry.refetch().then(function (data) {
							if (data && data.data) setAllOrders(data.data.items);
							setLoading(false);
						});
						window.scroll({ top: 0, left: 0, behavior: 'smooth' });
					}
				});
			} catch (e) {
				setLoading(false);
			}
			setShowReasonForRejectionModal(!showReasonForRejectionModal);
		} else if (action == OrderStatuses.Confirmed) {
			const payload = {
				entity_id: entityID,
				state: OrderStatuses.Processing,
				status: action,
			};
			const obj: Partial<Ipost> = {
				data: { entity: payload },
				params: {},
				url: `orders/create`,
				message: `Order(s) accepted successfully`,
			};
			try {
				setLoading(true);
				api.put(obj).then((data) => {
					if (data !== undefined) {
						qry.refetch().then(function (data) {
							if (data && data?.items?.length > 0) setAllOrders(data.items);
							setLoading(false);
						});
						window.scroll({ top: 0, left: 0, behavior: 'smooth' });
					}
				});
			} catch (e) {
				setLoading(false);
			}
			setShowModal(!showPopup);
		} else {
			let itemSelected = allOrders.filter((x) => x.entity_id == entityID);
			let item = [] as any;

			if (itemSelected) {
				itemSelected[0].items &&
					itemSelected[0].items.length > 0 &&
					itemSelected[0].items.map((data) => {
						let itemDetails = {
							order_item_id: 0,
							qty: 0,
						};
						itemDetails.order_item_id = data.item_id;
						itemDetails.qty = data.qty_ordered;
						item.push(itemDetails);
					});
			}
			const payload = {
				items: item,
				notify: true,
				tracks: [],
			};
			const obj: Partial<Ipost> = {
				data: payload,
				params: {},
				url: `order/${entityID}/ship`,
				message: `Order(s) shipped successfully`,
			};
			try {
				setLoading(true);
				api.post(obj).then(function (data) {
					if (data !== undefined) {
						window.scroll({ top: 0, left: 0, behavior: 'smooth' });
					}
					qry.refetch().then(function (data) {
						if (data && data?.items?.length > 0) setAllOrders(data.items);
					});
					setLoading(false);
				});
			} catch (e) {}
			setShowModal(!showPopup);
		}
	};
	const deleteOrder = (status, id) => {
		setEntityId(id);
		setAction(status);
		toggleReasonForRejectionModal();
	};
	const bindAction = (status, entity_id) => {
		switch (status) {
			case OrderStatuses.Pending:
				return (
					<Text>
						{utilityFunctions.isGranted(dcUserPermissionList.OrderManagementUpdate) && (
							<Button
								variant="editIcon"
								onClick={(e) => orderAcceptRejectSubmit(OrderStatuses.Confirmed, entity_id, e)}
							>
								<icons.Tick />
							</Button>
						)}
						{utilityFunctions.isGranted(dcUserPermissionList.OrderManagementUpdate) && (
							<Button
								variant="deleteIcon"
								onClick={() => deleteOrder(OrderStatuses.Rejected, entity_id)}
							>
								<icons.CloseIcon />
							</Button>
						)}
					</Text>
				);
			case OrderStatuses.Rejected:
				return (
					<Box
						as="slot"
						sx={{
							display: 'inline-block',
							color: 'white',
							bg: 'red',
							px: 2,
							py: 0.9,
							borderRadius: 9999,
							height: 30,
						}}
					>
						Rejected
					</Box>
				);
			case OrderStatuses.Cancelled:
				return (
					<Box
						as="slot"
						sx={{
							display: 'inline-block',
							color: 'white',
							bg: 'processingBadge',
							px: 2,
							py: 0.9,
							borderRadius: 9999,
							height: 30,
						}}
					>
						Cancelled
					</Box>
				);
			case OrderStatuses.Confirmed:
				return (
					<Box
						as="slot"
						sx={{
							display: 'inline-block',
							color: 'white',
							bg: 'confirmedBadge',
							px: 2,
							py: 0.9,
							borderRadius: 9999,
							height: 30,
						}}
					>
						Confirmed
					</Box>
				);
			case OrderStatuses.Disputed:
				return (
					<Box
						as="slot"
						sx={{
							display: 'inline-block',
							color: 'white',
							bg: 'deliveredWithIssue',
							px: 2,
							py: 0.9,
							borderRadius: 9999,
							height: 30,
						}}
					>
						Disputed
					</Box>
				);
			case OrderStatuses.Delivered:
				return (
					<Box
						as="slot"
						sx={{
							display: 'inline-block',
							color: 'white',
							bg: 'delivered',
							px: 2,
							py: 0.9,
							borderRadius: 9999,
							height: 30,
						}}
					>
						Delivered
					</Box>
				);
			case OrderStatuses.Shipped:
				return (
					<Box
						as="slot"
						sx={{
							display: 'inline-block',
							color: 'white',
							bg: 'shipped',
							px: 2,
							py: 0.9,
							borderRadius: 9999,
							height: 30,
						}}
					>
						Shipped
					</Box>
				);
			// case OrderStatuses.Approved:
			//     return (
			//         <Box
			//             as="slot"
			//             sx={{
			//                 display: 'inline-block',
			//                 color: 'white',
			//                 bg: 'approved',
			//                 px: 2,
			//                 py: 0.9,
			//                 borderRadius: 9999,
			//                 height: 30,
			//             }}
			//         >
			//             Approved
			//         </Box>
			//     );
			// case OrderStatuses.Onhold:
			//     return (
			//         <Box
			//             as="slot"
			//             sx={{
			//                 display: 'inline-block',
			//                 color: 'white',
			//                 bg: 'onhold',
			//                 px: 2,
			//                 py: 0.9,
			//                 borderRadius: 9999,
			//                 height: 30,
			//             }}
			//         >
			//             Holded
			//         </Box>
			//     );
			default:
				break;
		}
	};
	return (
		<PendingOrderHolder>
			{qry.isLoading || loading == true ? <Loader></Loader> : null}
			<PageSearchHolder>
				<Box as="section">
					<Search
						onChange={(val) => setFilteredData(val)}
						type={'pendingOrderList'}
						placeholder="Search for Order Id, Venue Name, Quantity ..."
					></Search>
				</Box>
				{/* <Box as="small">
					<Button flex={[1, 1, 'initial']} variant="redOutline">
						<icons.DeleteIcon />
						<div>Delete Selected</div>
					</Button>
				</Box>
				<StatusFilter></StatusFilter> */}
			</PageSearchHolder>
			<TableList>
				<table>
					<thead>
						<tr>
							{/* <th className="checked">
								<Label>
									<Checkbox id="remember" name="remember" />
								</Label>
							</th> */}
							<th>Order Id</th>
							<th>Store Name</th>
							<th>Date</th>
							<th>No. of Items</th>
							<th>Delivery Date</th>
							<th>Order Total</th>
							<th className="action">Action</th>
						</tr>
					</thead>

					<tbody>
						{allOrders && allOrders.length > 0 ? (
							allOrders
								.slice(0, 10)
								.map(
									({
										entity_id,
										total_qty_ordered,
										subtotal,
										created_at,
										status,
										extension_attributes,
									}) => {
										let deliverySlot = extension_attributes?.delivery_slot
											? extension_attributes?.delivery_slot
											: '';
										deliverySlot = deliverySlot.split(' ');
										let deliverySlotDay = null as any;
										let deliverySlotDate = null as any;
										if (deliverySlot.length === 2) {
											deliverySlotDate = moment(deliverySlot[1], 'DD-MM-YYYY');
											deliverySlotDate = helper.formatDate(deliverySlotDate.toDate());
											deliverySlotDay = deliverySlot[0];
										}
										return (
											<tr
												key={entity_id}
												onClick={() => history.push(`orderRequest?id=${entity_id}`)}
											>
												{/* <td className="checked">
											<Label>
												<Checkbox id="remember" name="remember" />
											</Label>
										</td> */}
												<td data-label="Order Id">
													<Text as="h1" fontSize={15} fontFamily="OpenSansBlack" color="text.black">
														<span>{extension_attributes.supplier_order_increment_id}</span>
													</Text>
												</td>
												<td data-label="Venue Name">
													<Text
														as="h1"
														fontSize={15}
														fontFamily="OpenSansMedium"
														color="text.black"
													>
														{extension_attributes.venue_name}
													</Text>
												</td>
												<td data-label="Date">
													<Text
														as="h1"
														fontSize={15}
														fontFamily="OpenSansMedium"
														color="text.black"
													>
														{helper.formatDate(created_at)}
													</Text>
												</td>
												<td data-label="No. of Items">
													<Text
														as="h1"
														fontSize={15}
														fontFamily="OpenSansMedium"
														color="text.black"
													>
														{total_qty_ordered}
													</Text>
												</td>
												<td data-label="Delivery Date">
													<Text
														as="h1"
														fontSize={15}
														fontFamily="OpenSansMedium"
														color="text.black"
													>
														{deliverySlot && deliverySlot.length === 2
															? `${deliverySlotDay} ${deliverySlotDate}`
															: 'NIL'}
													</Text>
												</td>
												<td data-label="Order Total">
													<Text
														as="h1"
														fontSize={15}
														fontFamily="OpenSansMedium"
														color="text.black"
													>
														${parseFloat(subtotal ? subtotal : 0).toFixed(2)}
													</Text>
												</td>
												<td
													data-label="Action"
													onClick={(e) => {
														e.stopPropagation();
													}}
													className="action"
												>
														{status === OrderStatuses.Confirmed && (
														<Button
															variant="secondary"
															onClick={(e) =>
																orderAcceptRejectSubmit(OrderStatuses.Shipped, entity_id, e)
															}
														>
															Ship It
														</Button>
													)}
													{bindAction(status, entity_id)}
												
												</td>
											</tr>
										);
									}
								)
						) : (
							<tr>
								<td>No orders found</td>
							</tr>
						)}
					</tbody>
				</table>
			</TableList>
			<SweetAlert
				show={showPopup}
				confirmBtnText="Confirm"
				onConfirm={() => onSubmitReason('', action)}
				showCancel={true}
				onCancel={closePopup}
				title={'Confirmation Box'}
				//  dependencies={[this.state.firstName, this.state.lastName]}
			>
				{() => (
					<form>
						<hr />
						{modalText}
						<hr />
					</form>
				)}
			</SweetAlert>
			<Modal
				width="500px"
				showModal={showReasonForRejectionModal}
				setShowModal={setShowReasonForRejectionModal}
			>
				<ReasonForRejectionModal
					changeChekedProp={changeChekedProp}
					setFirst={firstReasonChecked}
					setSec={secReasonChecked}
					onSubmitReason={() => onSubmitReason}
					cancel={() => {
						cancel();
					}}
				/>
			</Modal>
		</PendingOrderHolder>
	);
}

export { AllOrders };
