import Iget from 'models/Iget';
import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { BreadcrumbNav, Footer, Header } from 'ui/components';
import { Sidebar } from 'ui/patterns';
import urls from 'utils/create.url';
import helperFunctions from 'utils/helper.functions';
import pageConstants from 'utils/pageConstants';
import hooks from 'utils/requestCtx/reactQueryHooks';
const MainContent = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
	padding: 0 50px 50px;
	@media (max-width: 960px) {
		padding: 0 15px;
	}
`;
const BreadcrumbHolder = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media (max-width: 480px) {
		& > div {
			padding-bottom: 15px;
		}
	}
`;
const TermsAndConditionHolder = styled.div`
	display: flex;
	flex-direction: column;
	width: 80%;
	min-height: 150px;
	@media (max-width: 1024px) {
		width: 100%;
	}
	h1{
		text-transform:capitalize;
	}
	h2{
		padding-left:20px;
	}
	ul {
		li {
			position: relative;
			padding-left: 25px;
			&:after {
				content: '';
				width: 10px;
				height: 10px;
				position: absolute;
				left: 0;
				top: 7px;
				border: 3px solid ${({ theme }) => theme.baseColors.baseYellow};
				border-radius: 50px;
			}
		}
	}
`;
function FAQ(): ReactElement {
	const [data, setData] = useState([] as any);
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	let urlObj = {
		url: 'experion/faq/search?',
		pageSize: 2000,
		filterDeleted: true,
	};

	const obj: Partial<Iget> = {
		url: urls.GetSearchUrl(urlObj),
		enabled: true,
		refetchOnWindowFocus: false,
		resultFilteringRequired: true,
		key: 'faq',
		caching: true,
	};
	const qry = hooks.useQuery(obj);
	useEffect(() => {
		setData(qry.data);
	}, [qry.data]);
	const breadcrumbNav = [
		{ id: '04', label: 'FAQ', active: true },
	];
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	}
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={()=>onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={()=>onclickHambrgBtn()}></Sidebar>

			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbHolder>
					<BreadcrumbNav Heading="Frequently asked questions" breadcrumbList={breadcrumbNav} />
				</BreadcrumbHolder>
				{(!qry.isFetching && (
					<TermsAndConditionHolder>
						{data?.map((q, i) => {
							return (
								<Fragment key={helperFunctions.getRandomInt(pageConstants.maxValue)}>
									<Text as="h1" fontSize="17px" mb={1} fontFamily="OpenSansSemiBold" color="text.black">
										{`${i + 1}. ${q.question}`}
									</Text>

									<Text
									as="h2"
										fontSize="16px"
										mb={5}
										fontFamily="OpenSansMedium"
										color="text.SubHeading"
									>
										{` ${q.answer}`}
									</Text>
								</Fragment>
							);
						})}
					</TermsAndConditionHolder>
				)) || (
					<>
						<Skeleton
							key={helperFunctions.getRandomInt(pageConstants.maxValue)}
							count={20}
							width="200px"
						/>
						<Skeleton key={helperFunctions.getRandomInt(pageConstants.maxValue)} count={20} />
					</>
				)}
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default FAQ;
