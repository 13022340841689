import { Select } from '@rebass/forms';
import Iget from 'models/Iget';
import Ipost from 'models/Ipost';
import DCRoles from 'models/pageObjects/dc.Roles';
import queryString from 'query-string';
import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Text } from 'rebass/styled-components';
import { BreadcrumbNav, Button, Footer, Grid, Header, Loader, Stack } from 'ui/components';
import * as Icon from 'ui/icons';
import { AssignDistributionList, FormInput, Sidebar } from 'ui/patterns';
import urls from 'utils/create.url';
import helperFunctions from 'utils/helper.functions';
import http from 'utils/http.service';
import pageConstants from 'utils/pageConstants';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import notify from 'utils/toast.service';
import {
	AddUserHolder,

	AssignVenueHolder, FooterBtnBlock, MainContent,


	ProfileDetails
} from './styles';
function NewUsers(): ReactElement {
	const pageSize = 500;
	const location = useLocation() as any;
	const history = useHistory();
	const parsed = queryString.parse(location.search);
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const dcUrl = urls.GetSearchUrl({
		url: 'experion/supplycompanyaccount/dc/search?',
		pageSize: pageSize,
		filterDeleted: true,
		filterInactive: true,
		fields: [{
			field_name: 'supply_id',
			field_value: localStorage.getItem('supplierId')!
		}]
	});
	const [dcRoleChange, setDCRoleChange] = useState(false);
	const roleUrl = urls.GetSearchUrl({
		url: 'experion/supplycompanyaccount/dcUserrole/search?',
		pageSize: pageSize,
		filterDeleted: true,
		fields: [{
			field_name: 'supply_id',
			field_value: localStorage.getItem("supplierId")!
		}]
	});
	const dcObj: Partial<Iget> = {
		params: {},
		url: dcUrl,
		enabled: true,
		key: 'dc',
		resultFilteringRequired: true,
	};

	const dcQry = hooks.useQuery(dcObj);
	const roleObj: Partial<Iget> = {
		params: {},
		url: roleUrl,
		enabled: true,
		key: 'dcrole',
		resultFilteringRequired: true,
	};

	const roleQry = hooks.useQuery(roleObj);
	const [assignedUser, setAssignedUser] = useState<DCRoles>({
		dcName: { key: 0, value: '' },
		role: { key: 0, value: '' },
	});
	const [dcRoles, setDCRoles] = useState<Array<DCRoles>>([]) as any;
	const [userSaveText, setUserSaveText] = useState('Add');
	const breadcrumbNav = [
		{ id: '01', label: 'Dashboard', route: '/admin/seller-dashboard', active: false },
		{ id: '02', label: 'User Management', route: '/admin/userList', active: false },
		{ id: '03', label: (userSaveText == 'Save') ? 'Edit User' : 'Add Users', route: (userSaveText == 'Save') ? `edit-dc-user?id=${parsed.id}` : 'new-dc-user', active: true },
	];
	const [isLoading, setIsLoading] = useState(false);
	const [dcList, setDCList] = useState([] as any);
	const [roleList, setRoleList] = useState([] as any);
	const [userPhn, checkUserPhn] = useState<any>();
	const userPhnValidateUrl = `experion/supplycompanyaccount/DCUserPhoneExists/${userPhn}`;
	const [error, setError] = useState({
		userFirstNameError: '',
		userLastNameError: '',
		userPhnError: '',
		userEmailError: '',
		dcNameError: '',
		userRoleError: '',
	});
	const [userDetails, setUserDetails] = useState({
		first_name: '',
		last_name: '',
		phone: '',
		dc_user_email: '',
		id: 0,
		dc_userrole_mapping: [],
		is_active: true,
		sc_id: ""
	});
	const validationObj: Partial<Iget> = {
		params: {},
		url: userPhnValidateUrl,
		enabled: false,
		key: 'PhnValidation',
	};
	const qryValidatePhn = hooks.useQuery(validationObj);
	const validateUniquePhnNum = () => {
		if (error.userPhnError === '') {
			qryValidatePhn.refetch().then((data) => {
				if (data) {
					setError({ ...error, userPhnError: 'Phone already exists' });
				} else {
					//setError({ ...error, userPhnError: '' });
					handleChange(userPhn, 'phone');
				}
			});
		}
	};
	useEffect(() => {
		if (typeof location.search != 'undefined' && parsed.id) {
			setUserSaveText('Save');
			http.GET({ url: `experion/supplycompanyaccount/get/${parsed.id}` }).then(function (d) {
				let data = d.data;
				setUserDetails({
					first_name: data.first_name,
					id: data.id,
					is_active: data.is_active,
					last_name: data.last_name,
					phone: data.phone,
					dc_user_email: data.dc_user_email,
					dc_userrole_mapping: data.dc_userrole_mapping,
					sc_id: localStorage.getItem("supplierId")!
				});
				let list = [] as Array<DCRoles>;
				data.dc_userrole_mapping.map((v) => {
					if (v.is_delete != true && v.is_active == true) {
						list.push({
							dcName: {
								key: v.dc_id,
								value: v.dc_name,
							},
							role: {
								key: v.role_id,
								value: v.role_name,
							},
						});
					}
				});
				setDCRoles(list);
				setDCRoleChange(true);
			});
		}
	}, [location]);

	// useEffect(()=>{
	// 	if(dcRoleChange == true && dcRoles.length > 0 &&
	// 		dcQry.data && roleQry.data){
	// 	let venues = venueQry.data;
	// 	let roles = roleQry.data;
	// 	dcRoles.map((v:VenueRole,i)=>{
	// 		venues.splice(venues.findIndex(x=>x.name == v.venue.value),1);
	// 		roles.splice(roles.findIndex(x=>x.name == v.role.value),1);
	// 	})
	// 	setDCList(venues);
	// 	setRoleList(roles);
	// 	}
	// },[dcRoleChange])

	useEffect(() => {
		const dcs = [] as any;
		dcQry.data &&
			dcQry.data.map((item) => {
				if (
					(dcRoles && dcRoles.findIndex((x) => x.dcName.key == item.id) == -1) ||
					!dcRoles ||
					dcRoles.length == 0
				) {
					dcs.push({ key: item.dc_id, value: item.name });
				}
			});
		setDCList(JSON.parse(JSON.stringify(dcs)));
	}, [dcQry.data]);

	useEffect(() => {
		const roles = [] as any;
		roleQry.data &&
			roleQry.data.map((item) => {
				// let data = dcQry.data;
				if (
					(dcRoles && dcRoles.findIndex((x) => x.role.key == item.key) == -1) ||
					!dcRoles ||
					dcRoles.length == 0
				) {
					roles.push({ key: item.id, value: item.role_name });
				}
			});
		setRoleList(JSON.parse(JSON.stringify(roles)));
	}, [roleQry.data]);

	const onDeleteDCRole = (dcName, role) => {
		dcRoles.splice(
			dcRoles.findIndex((x) => x.role.key == role.key && x.dcName.key === dcName.key),
			1
		);
		assignedUser.role = assignedUser.dcName = { key: 0, value: '' };
		if (dcList.findIndex((x) => x.value == dcName.value) == -1) {
			dcList.push(dcName);
			setDCList(dcList);
		}

		if (roleList.findIndex((x) => x.value == role.value) == -1) {
			roleList.push(role);
			setRoleList(roleList);
		}

		setDCRoles(JSON.parse(JSON.stringify(dcRoles)));
	};

	const cancel = () => {
		history.push('userList');
	};
	const clear = () => {
		setUserDetails({
			first_name: '',
			last_name: '',
			phone: '',
			dc_user_email: '',
			id: 0,
			dc_userrole_mapping: [],
			is_active: true,
			sc_id: localStorage.getItem("supplierId")!
		});
		setError({
			userFirstNameError: '',
			userLastNameError: '',
			userPhnError: '',
			userEmailError: '',
			dcNameError: '',
			userRoleError: ''
		});
		setAssignedUser({
			dcName: { key: 0, value: '' },
			role: { key: 0, value: '' }
		});
		setDCRoles([]);
		const dcs = [] as any;
		dcQry.data &&
			dcQry.data.map((item) => {
				dcs.push({ key: item.dc_id, value: item.name });
			});
		setDCList(JSON.parse(JSON.stringify(dcs)));
		roleQry.data &&
			roleQry.data.map((item) => {
				roleList.push({ key: item.id, value: item.role_name });
			});
		setRoleList(JSON.parse(JSON.stringify(roleList)));
	};

	const onSelectDropdwn = (e, name) => {
		const data: DCRoles = JSON.parse(JSON.stringify(assignedUser));
		switch (name) {
			case 'dcName': {
				if (e.target.value == '') {
					setError({ ...error, dcNameError: 'DC name cannot be blank' });
				} else {
					setError({ ...error, dcNameError: '' });
				}
				if (data) {
					data.dcName.value = e.target.value;
					if (e.target.value != '')
						data.dcName.key = dcList[dcList.findIndex((x) => x.value == e.target.value)].key;
				}
				break;
			}
			case 'userRole': {
				if (e.target.value == '') {
					setError({ ...error, userRoleError: 'User role cannot be blank' });
				} else {
					setError({ ...error, userRoleError: '' });
				}
				if (data) {
					data.role.value = e.target.value;
					if (e.target.value != '')
						data.role.key = roleList[roleList.findIndex((x) => x.value == e.target.value)].key;
				}
				break;
			}
		}
		setAssignedUser(JSON.parse(JSON.stringify(data)));
	};
	const handleChange = (val, name) => {
		const item = userDetails;
		//const data = assignedUser;
		switch (name) {
			case 'firstName': {
				if (!val) {
					setError({ ...error, userFirstNameError: 'First name cannot be blank' });
				} else {
					setError({ ...error, userFirstNameError: '' });
				}
				item.first_name = val;
				break;
			}
			case 'lastName': {
				if (!val) {
					setError({ ...error, userLastNameError: 'Last name cannot be blank' });
				} else {
					setError({ ...error, userLastNameError: '' });
				}
				item.last_name = val;
				break;
			}
			case 'phone': {
				if (!val) {
					setError({ ...error, userPhnError: 'Phone cannot be blank' });
				} else {
					const regex = pageConstants.phoneRegex;
					if (!regex.test(val)) {
						setError({ ...error, userPhnError: 'Invalid Phone' });
					} else {
						checkUserPhn(val);
						setError({ ...error, userPhnError: '' });
					}
				}
				item.phone = val;
				break;
			}
			case 'userEmail': {
				if (!val) {
					setError({ ...error, userEmailError: 'Email cannot be blank' });
				} else {
					const validEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(val);
					if (validEmail) {
						setError({ ...error, userEmailError: '' });
					} else {
						setError({ ...error, userEmailError: 'Please enter Valid Email' });
					}
				}
				item.dc_user_email = val;
				break;
			}
		}
		setUserDetails({
			...item,
		});
	};

	const assignUserRole = () => {
		if (
			dcRoles.findIndex(
				(x) => x.dcName.key == assignedUser.dcName.key && x.role.key == assignedUser.role.key
			) != 0
		) {
			const itemList = JSON.parse(JSON.stringify(dcRoles));
			const user = JSON.parse(JSON.stringify(assignedUser));
			if (
				user &&
				itemList &&
				itemList.map((x) => x.dcName.value).indexOf(user.dcName.value) == -1
			) {
				dcList.splice(
					dcList.findIndex((x) => x.value == user.dcName.value),
					1
				);
				// roleList.splice(
				// 	roleList.findIndex((x) => x.value == user.role.value),
				// 	1
				// );
				setDCList(dcList);
				// setRoleList(roleList);
				setDCRoles((arr) => [...arr, user]);
				assignedUser.role = assignedUser.dcName = { key: 0, value: '' };
			}
		} else {
			notify('DC role already exists in the list').info();
		}
	};

	const saveUser = async () => {
		setIsLoading(true);
		const list = [] as any;

		dcRoles.map((v, i) => {
			list.push({ dc_id: v.dcName.key as never, role_id: v.role.key as never, is_active: true });
		});
		userDetails.dc_userrole_mapping = list;
		userDetails.phone = userDetails.phone.replace(/\+/g, '').replace(/ /g, '');
		userDetails.phone = helperFunctions.formatPhone(userDetails.phone);
		userDetails.sc_id = localStorage.getItem("supplierId")!;
		let user = {};
		const isSave = userSaveText == 'Add' ? true : false;
		if (isSave) {
			user = Object.keys(userDetails).reduce((object, key) => {
				if (key !== 'id') {
					object[key] = userDetails[key];
				}
				return object;
			}, {});
		} else user = userDetails;

		const obj: Partial<Ipost> = {
			data: { dcUser: user },
			params: {},
			url: isSave
				? 'experion/supplycompanyaccount/dcUser/create'
				: `experion/supplycompanyaccount/update/${userDetails.id}`,
			message: `User saved successfully`,
		};
		try {
			if (isSave) {
				await api.post(obj).then(function (data) {
					if (typeof data != 'undefined') history.push('userList');
				});
			} else {
				await api.put(obj).then(function (data) {
					if (typeof data != 'undefined') history.push('userList');
				});
			}
			setIsLoading(false);
		} catch (e) {
			setIsLoading(false);
		}
	};
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	}
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={() => onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={() => onclickHambrgBtn()}></Sidebar>
			{!dcRoleChange && parsed?.id && <Loader></Loader>}{' '}
			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbNav
					Heading={userSaveText === 'Save' ? 'Edit User' : 'Add New Users'}
					breadcrumbList={breadcrumbNav}
				/>
				{/* Add User Details */}
				<AddUserHolder>
					<ProfileDetails>
						<Text as="h1" fontSize={18} fontFamily="OpenSansBold" color="text.SubHeading">
							basic details
						</Text>
						<Box as="form">
							<Grid gap={0}>
								<FormInput
									label="First Name"
									value={userDetails && userDetails.first_name}
									validationMessage={error.userFirstNameError}
									validation={error.userFirstNameError ? 'error' : undefined}
									required={true}
									name="firstName"
									onTextChange={(e) => handleChange(e, 'firstName')}
									maxLength={20}
									type="text"
								/>
								<FormInput
									label="Last Name"
									value={userDetails && userDetails.last_name}
									validationMessage={error.userLastNameError}
									validation={error.userLastNameError ? 'error' : undefined}
									required={true}
									name="lastName"
									onTextChange={(e) => handleChange(e, 'lastName')}
									maxLength={20}
									type="text"
								/>
								<FormInput
									label="Phone"
									disabled={userSaveText == 'Save'}
									value={userDetails && userDetails.phone}
									validationMessage={error.userPhnError}
									validation={error.userPhnError ? 'error' : undefined}
									required={true}
									name="phone"
									onTextChange={(e) => handleChange(e, 'phone')}
									maxLength={12}
									type="text"
									onBlur={validateUniquePhnNum}
								/>
								<FormInput
									label="Email Address"
									value={userDetails && userDetails.dc_user_email}
									validationMessage={error.userEmailError}
									validation={error.userEmailError ? 'error' : undefined}
									required={true}
									name="userEmail"
									onTextChange={(e) => handleChange(e, 'userEmail')}
									maxLength={200}
									type="text"
								/>
							</Grid>
						</Box>
					</ProfileDetails>
					<AssignVenueHolder>
						<Text as="h1" fontSize={18} fontFamily="OpenSansBold" color="text.SubHeading">
							Distribution center & role
						</Text>
						<Box as="form">
							<Grid gap={4}>
								<Stack categorySelect gap={0}>
									<div>
										<label>
											Distribution Center <sup>*</sup>
										</label>
										<Select
											id="dcName"
											name="dcName"
											onChange={(e) => onSelectDropdwn(e, 'dcName')}
											value={assignedUser && assignedUser.dcName.value}
										>
											<option value="">-Select Distribution Center-</option>
											{dcList &&
												dcList.map((option, index) => <option key={index}>{option.value}</option>)}
										</Select>
										<Icon.downArrow />
									</div>
									<span style={{ fontSize: 12, color: 'red' }}>{error.dcNameError}</span>
								</Stack>
								<Stack categorySelect gap={0}>
									<div>
										<label>
											Role <sup>*</sup>
										</label>
										<Select
											id="role"
											name="role"
											onChange={(e) => onSelectDropdwn(e, 'userRole')}
											value={assignedUser && assignedUser.role.value}
										>
											<option value="">-Select Role-</option>
											{roleList &&
												roleList.map((option, index) => (
													<option key={index}>{option.value}</option>
												))}
										</Select>
										<Icon.downArrow />
									</div>
									<span style={{ fontSize: 12, color: 'red' }}>{error.userRoleError}</span>
								</Stack>
							</Grid>
							<Button
								onClick={assignUserRole}
								disabled={!assignedUser.dcName.value || !assignedUser.role.value}
								flex={[1, 1, 'initial']}
							>
								Assign
							</Button>
						</Box>

						{/* Assign Value List */}
						<AssignDistributionList
							deleteDCRole={onDeleteDCRole}
							dcRoles={dcRoles}
						></AssignDistributionList>
					</AssignVenueHolder>

					{/* Footer Btn Block */}
					<FooterBtnBlock>
						<Stack direction="x" gap={3} justifyContent={['center', 'flex-end']}>
							{userSaveText == 'Add' && (<Button onClick={clear} flex={[1, 1, 'initial']} variant="secondary">
								Clear
							</Button>
							)}
							<Button onClick={cancel} flex={[1, 1, 'initial']} variant="secondary">
								Cancel
							</Button>
							<Button
								onClick={saveUser}
								disabled={
									userDetails.first_name == '' ||
									userDetails.last_name == '' ||
									userDetails.dc_user_email == '' ||
									userDetails.phone == '' ||
									error.userFirstNameError != '' ||
									error.userLastNameError != '' ||
									error.userPhnError != '' ||
									error.userEmailError != '' ||
									error.userRoleError != '' ||
									error.dcNameError != '' ||
									(dcRoles && dcRoles.length == 0) ||
									isLoading == true
								}
								flex={[1, 1, 'initial']}
							>
								{userSaveText}
							</Button>
						</Stack>
					</FooterBtnBlock>
				</AddUserHolder>
			</MainContent>
			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default NewUsers;
