import { Column } from 'react-table';
import styled from 'styled-components';
import { getHSL } from '../../ui/utils/getHSL';

const MainContent = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
	padding: 0 50px 50px;
	@media (max-width: 960px) {
		padding: 0 15px;
	}
`;
const UserListHolder = styled.div`
	button {
		margin-left: auto;
		display: Flex;
		margin-top: 20px;
		width: auto;
		white-space: nowrap;
		flex: 0;
		span {
			display: flex;
			span {
				margin-right: 10px;
			}
		}
		@media screen and (max-width: 640px) {
			width: 50px;
			max-width: 50px;
			flex: 0 0 50px;
			padding: 0;
			height: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			span {
				margin-right: 0 !important;
			}
			div {
				display: none;
			}
		}
	}
	table {
		width: 100%;
		border: 1px solid ${({ theme }) => theme.colors.border.userListTD};
		margin-top: 20px;
		@media screen and (max-width: 768px) {
			border: none;
		}

		h1 {
			strong {
				cursor: pointer;
				&:hover {
					color: ${({ theme }) => theme.baseColors.primary};
				}
			}
		}
		thead {
			border: none;
			border-spacing: 0;
			display: block;
			tr {
				border-radius: 0;
				margin-bottom: -2px;
				padding: 0;
				border: 0;
				background: #fbfbfb;

				th {
					border-right: 1px solid ${({ theme }) => theme.colors.border.userListTD};
					padding: 16px;
					width: 100%;
					max-width: 100%;
					flex: auto;
					position: relative;
					background: #fbfbfb;
					&:last-child {
						border-right: none;
					}
					input {
						--px: 0.75em;
						--py: 0.75em;
						--a: 80%;
						--h: ${({ theme }) => getHSL('h', theme.colors.border.default)};
						--s: ${({ theme }) => getHSL('s', theme.colors.border.default)};
						--l: ${({ theme }) => getHSL('l', theme.colors.border.default)};
						--input-bg: #fff;
						height: 40px;
						padding: var(--py) var(--px);
						font-family: 'OpenSansSemiBold';
						font-size: 14px;
						font-weight: 400;
						line-height: 1.5;
						color: ${({ theme }) => theme.colors.text.body};
						background-color: var(--input-bg);
						background-clip: padding-box;
						border: 1px solid ${({ theme }) => theme.colors.border.default};
						border-radius: ${({ theme }) => theme.radii.softer};
						transition: all 200ms ease-in-out;
						-webkit-appearance: none;
						appearance: none;
						margin-top: 7px;
						width: 100%;
						&:focus {
							background-color: #fff;
							border-color: ${({ theme }) => theme.colors.primary};
							outline: 0;
							box-shadow: 0 0 0 0.2rem hsla(var(--h), var(--s), var(--l), 30%);
						}
					}
					span {
						position: absolute;
						right: 6px;
						top: 9px;
						svg {
							width: 20px;
							height: 16px;
						}
					}
				}
				@media screen and (max-width: 480px) {
					display: flex;
					flex-wrap: wrap;
					th {
						width: 50%;
						input,
						input::-webkit-input-placeholder {
							font-size: 12px;
							line-height: 3;
						}
					}
				}
			}
		}
		tbody {
			margin-bottom: 0;
			border-spacing: 0;
			.simplebar-wrapper {
				max-height: 400px;
			}
			tr {
				box-shadow: none;
				border-radius: 0;
				margin-bottom: 0;
				border-top: 0;
				padding: 0;
				border: 0;
				@media screen and (max-width: 768px) {
					border: 1px solid ${({ theme }) => theme.colors.border.userListTD}!important;
					box-sizing: border-box !important;
					box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07) !important;
					border-radius: 8px !important;
					padding: 14px 16px !important;
					margin-bottom: 15px !important;
				}
				td {
					border: 0;
					border-right: 1px solid ${({ theme }) => theme.colors.border.userListTD};
					border-top: 1px solid ${({ theme }) => theme.colors.border.userListTD};
					width: 100%;
					max-width: 100%;
					flex: auto;
					padding: 16px;
					align-items: center;
					display: flex;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					&:last-child {
						border-right: none;
					}
					h1 {
						overflow: hidden;
						text-overflow: ellipsis;
						display: inline-block;
						white-space: nowrap;
						display: flex;
						@media screen and (max-width: 768px) {
							white-space: normal;
							max-width: 100%;
						}
						div {
							overflow: hidden;
							text-overflow: ellipsis;
							display: inline-block;
							white-space: nowrap;
							float: left;
							@media screen and (max-width: 768px) {
								white-space: normal;
								max-width: 100%;
							}
							+ div {
								width: 20px;
								flex: 0 0 20px;
								max-width: 20px;
								@media screen and (max-width: 768px) {
									display: none !important;
								}
							}
						}
						svg {
							height: 16px;
							width: 16px;
							margin-left: 5px;
							margin-top: 4px;
							path {
								fill: ${({ theme }) => theme.baseColors.primary};
							}
						}
					}
					@media screen and (max-width: 768px) {
						border: none !important;
						padding: 0 10px 0 0 !important;
						text-align: left !important;
						align-items: flex-start;
						margin-right: 0;

						h1 {
							div {
								text-align: left !important;
							}
						}
					}
					h1 {
						width: 100%;
					}
				}
			}
		}
	}
	@media (max-width: 768px) {
		table {
			width: 100%;
			margin-top: 20px;
		}
	}
`;
const BreadcrumbHolder = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media (max-width: 480px) {
		& > div {
			padding-bottom: 15px;
		}
	}
`;
const FilterHolder = styled.div`
	display: flex;
	flex-direction: column;
	padding: 25px;
	margin-bottom: 12px;
	border: 1px solid #bdbcb9;
	border-radius: 12px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	form {
		div {
			grid-template-columns: repeat(5, 1fr);
			@media (max-width: 1600px) {
				grid-template-columns: repeat(5, 1fr) !important;
			}
			@media (max-width: 1366px) {
				grid-template-columns: repeat(4, 1fr) !important;
			}
			@media (max-width: 1200px) {
				grid-template-columns: repeat(3, 1fr) !important;
			}
			@media (max-width: 860px) {
				grid-template-columns: repeat(1, 1fr) !important;
			}
			div {
				grid-column: auto;
			}
		}
		.btnBlock {
			margin-top: auto;

			button {
				flex: 1 !important;
				white-space: nowrap;
			}
			@media (max-width: 480px) {
				grid-template-columns: repeat(1, 1fr) !important;
				flex-direction: column;
				button {
					width: 100%;
					margin-left: 0;
					+ button {
						margin-top: 10px;
					}
				}
			}
		}
		section {
			display: flex;
			min-width: 400px;
			column-gap: 15px;
			@media (max-width: 1200px) {
				min-width: 320px;
			}
			@media (max-width: 860px) {
				min-width: 100%;
			}
		}
	}
`;
const ReportNameBlock = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 20px;
	button {
		margin-top: 0px !important;
	}
	+ div {
		max-height: fit-content;
		margin-bottom: 30px;
	}
`;
export { MainContent, UserListHolder, BreadcrumbHolder, FilterHolder, ReportNameBlock };
