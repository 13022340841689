import styled from 'styled-components';
import { flexbox, FlexboxProps, space, SpaceProps } from 'styled-system';

type InfoLabelProps = FlexboxProps &
	SpaceProps & {
		label?: string;
		variant?: 'success' | 'warning' | 'danger';
	};

const InfoLabel = styled.span<InfoLabelProps>`
	${flexbox};
	${space};
	display: inline-flex;
	align-items: center;
	padding: 0.3em 0.5em;
	text-transform: uppercase;
	font-family: 'OpenSansBold';
	font-size: 12px;
	line-height: 1;
	color: #fff;
	border-radius: 3px;
	background: ${({ variant }) => {
		if (variant === 'success') return '#3CB35D';
		if (variant === 'warning') return '#FF7E35';
		if (variant === 'danger') return '#be2222';
	}};
`;

export { InfoLabel };
