import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { FormLabel } from '../../components';

const StyledToggleLabel = styled(FormLabel)`
	display: block;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
	padding-right: 4em;
	position: relative;
	border-radius: ${({ theme }) => theme.radii.rounded};
	transition: background 200ms ease-in-out;
	& .pr-toggle-label {
		color: ${({ theme }) => theme.colors.text.body};
	}
	& .pr-toggle-ui {
		position: relative;
	}
	& .pr-toggle-ui:before,
	& .pr-toggle-ui:after {
		border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
		content: '';
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		transition: background 200ms ease-in-out;
	}
	& .pr-toggle-ui:after {
		background: ${({ theme }) => theme.colors.border.grayMedium};
		border-radius: 100px;
		height: 18px;
		left: 6px;
		transition: left 0.1825s cubic-bezier(0.65, 0.05, 0.36, 1);
		width: 18px;
	}
	& .pr-toggle-ui:before {
		background: var(--color-neutral-contrast-02);
		border-radius: 1.75em;
		height: 26px;
		left: 0;
		transition: all 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
		width: 50px;
	}

	& input {
		height: 100%;
		left: 0;
		opacity: 0.0001;
		position: absolute;
		top: 0;
		width: 100%;
	}

	& input:not([role='button']) {
		pointer-events: none;
	}
	& input:focus + .pr-toggle-ui:before {
		outline: none;
		transition: all 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
	}
	& input:checked + .pr-toggle-ui:after {
		left: 27px;
		transition: left 0.1825s cubic-bezier(0.65, 0.05, 0.36, 1);
		background: ${({ theme }) => theme.newColors.primary01};
		border: none;
	}
	& input:checked + .pr-toggle-ui:before {
		border: 1px solid ${({ theme }) => theme.newColors.primary01};
	}
	& input:focus + .pr-toggle-ui:before {
		outline: none;
		transition: all 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
	}
	& input:readonly + .pr-toggle-ui:after {
		left: 27px;
		transition: left 0.1825s cubic-bezier(0.65, 0.05, 0.36, 1);
		background: ${({ theme }) => theme.newColors.primary01};
		border: none;
	}
	& input:readonly + .pr-toggle-ui:before {
		border: 1px solid ${({ theme }) => theme.newColors.primary01};
	}
	@media screen and (-ms-high-contrast: active) {
		& .pr-toggle-ui:after {
			background-color: windowText;
			transition: all 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
		}
	}
`;

export type ToggleProps = {
	label?: string;
	required?: boolean;
	htmlFor?: string;
	register?: ({ required }: { required?: boolean }) => RefReturn;
	name?: string;
	disabled?: boolean;
	onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
	checked?: boolean;
	readOnly?: boolean;
};

type RefReturn =
	| string
	| ((instance: HTMLInputElement | null) => void)
	| React.RefObject<HTMLInputElement>
	| null
	| undefined;

function Toggle({
	required,
	// htmlFor,
	label,
	register,
	name,
	disabled,
	onChange,
	checked,
	readOnly,
}: ToggleProps): ReactElement {
	return (
		<StyledToggleLabel className="check-switch" htmlFor={name}>
			<input
				ref={register && register({ required })}
				id={name}
				name={name}
				data-check-switch=""
				role="switch"
				type="checkbox"
				disabled={disabled}
				onChange={(e)=> onChange && onChange(e)}
				checked={checked}
				readOnly={readOnly}
			/>
			<span className="pr-toggle-ui" aria-hidden="true"></span>
		</StyledToggleLabel>
	);
}

export { Toggle };
