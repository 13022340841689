import { UserBody } from 'admin/dashboardVenue/styles';
import { userTypes } from 'models/enum-constants/common.constants';
import Iget from 'models/Iget';
import React, { ReactElement, ReactNode, SyntheticEvent, useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Box, Link, Text } from 'rebass/styled-components';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import styled from 'styled-components';
import * as Icon from 'ui/icons';
import { NotificationsFullView } from 'ui/patterns';
import useOnClickOutside from 'use-onclickoutside';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import DropdownButton from './DropdownButton';
import DropdownMenu from './DropdownMenu';

const StyledNotificationIndicator = styled.div`
	display: flex;
	align-items: center;
	margin: 0 46px 0 0;
	svg {
		width: 25px;
		height: 30px;
	}
	button {
		padding: 0;
		outline: none !important;
		box-shadow: none !important;
	}
	.badge-count {
		width: 10px;
		height: 10px !important;
		top: 4px;
		left: auto;
		border-width: 1px;
		right: 1px;
	}
	.menuContent {
		width: 550px !important;
		padding: 18px !important;
		display: flex !important;
		flex-direction: column !important;
		top: 60px !important;
		min-height: max-content !important;
		&:before {
			right: 85px !important;
			left: auto !important;
		}
		.simplebar-wrapper {
			max-height: calc(100vh - 200px);
		}
		@media (max-width: 640px) {
			width: calc(100vw - 34px) !important;
		}
	}
`;
const ViewAllHeadingBlock = styled.div`
	display: flex !important;
	justify-content: space-between;
	color: ${({ theme }) => theme.colors.text.black};
	padding: 0 10px 10px;
	align-items: center;
	min-height: 35px;
	h1,
	h2 {
		font-family: 'OpenSansBold';
		text-transform: uppercase;
		display: flex !important;
		align-items: center;
		position: relative;
		span {
			margin-right: 6px;
			svg {
				width: 25px;
				height: 22px;
			}
		}
	}
	h1,
	h2 a {
		font-weight: normal;
	}
	a {
		color: ${({ theme }) => theme.baseColors.primary};
		text-decoration: none;
		font-family: 'OpenSansMedium';
		transition: ease-in all 200ms;

		&:hover {
			background: ${({ theme }) => theme.baseColors.primary};
			padding: 3px 10px;
			color: ${({ theme }) => theme.baseColors.white};
			border-radius: 5px;
		}
	}
	h2 {
		width: 100%;
		margin-top: 20px;
		&:after,
		&:before {
			content: '';
			position: absolute;
			z-index: -1;
		}
		&:after {
			width: 125px;
			height: 10px;
			background: ${({ theme }) => theme.baseColors.white};
		}
		&:before {
			width: 100%;
			height: 1px;
			background: rgba(0, 0, 0, 0.15);
		}
	}
`;
export type NotificationIndicatorProps = {
	trigger?: string | ReactNode;
	menuList?: Array<item>;
	menuContent?: ReactNode;
	autoClose?: boolean;
	position?: 'right-top' | 'right' | 'left-top' | 'left';
	ref?: React.RefObject<HTMLDivElement>;
	onClick?: (e: any) => void;
};
type item = {
	id?: number;
	value: string;
	action?: () => void;
	icon?: ReactNode;
	groupEnd?: boolean;
};

function NotificationIndicator({
	autoClose = true,
	position,
}: NotificationIndicatorProps): ReactElement {
	let user_Type = localStorage.getItem('userType');
	let venue = localStorage.getItem('selectedVenue');
	let location = localStorage.getItem('selectedLocation');
	let userId = localStorage.getItem('userId');
	let loc =
		user_Type == userTypes.venueAdmin || user_Type == userTypes.venueUser ? venue : location;
	const [notificationsFullViewList, setNotificationsFullViewList] = useState([] as any);
	const url =
		loc &&
		typeof userId != 'undefined' &&
		userId != '' &&
		typeof user_Type != 'undefined' &&
		user_Type != ''
			? `experion/notifications/${loc}/${getUserType()}/${userId}`
			: '';
	const key = 'notification';
	const obj: Partial<Iget> = {
		params: {},
		url: url,
		enabled: true,
		key: key,
		resultFilteringRequired: false,
	};
	var qry = hooks.useQuery(obj);
	useEffect(() => {
		setNotificationsFullViewList(qry.data);
	}, [qry.data]);

	const [open, setOpen] = useState(false);
	const handleOnClickOutside = () => {
		setOpen(false);
	};

	const wrapperRef = useRef<HTMLDivElement>(null);
	useOnClickOutside(wrapperRef, handleOnClickOutside);
	const handleToggle = (e: SyntheticEvent<HTMLDivElement>) => {
		e.stopPropagation();
		setOpen(autoClose === true ? !open : true);
	};

	function getUserType() {
		let userType = '';
		switch (user_Type) {
			case userTypes.venueAdmin:
				userType = 'hg_admin';
				break;
			case userTypes.venueUser:
				userType = 'venue_user';
				break;
			case userTypes.supplierAdmin:
				userType = 'supply_admin';
				break;
			case userTypes.supplierUser:
				userType = 'dc_user';
				break;
			default:
				break;
		}
		return userType;
	}

	function markAllAsRead(e) {
		e.preventDefault();
		let userType = getUserType();
		api
			.put({
				url: `experion/notifications/markAsReadAll/${loc}/${userType}/${localStorage.getItem(
					'userId'
				)!}`,
				message: 'All notifications marked as read',
			})
			.then(function (data) {
				if (data) {
					notificationsFullViewList?.map((x) => {
						x.mark_read = '1';
					});
					setNotificationsFullViewList(JSON.parse(JSON.stringify(notificationsFullViewList)));
				}
			});
	}

	function markAsRead(id) {
		api
			.put({
				url: `experion/notifications/markAsRead/${id}`,
				message: 'Notification marked as read',
				data: constructData(),
			})
			.then(function (data) {
				if (data) {
					let item = notificationsFullViewList.find((x) => x.id == id);
					if (item) {
						item!.mark_read = '1';
						setNotificationsFullViewList(JSON.parse(JSON.stringify(notificationsFullViewList)));
					}
				}
			});
	}

	function constructData() {
		return {
			participants: {
				mark_read: '1',
			},
		};
	}

	function deleteNotification(id) {
		api
			.delete({
				url: `experion/notifications/delete/${id}`,
				message: 'Notification deleted successfully',
			})
			.then(function (data) {
				if (data) qry.refetch();
			});
	}

	return (
		<StyledNotificationIndicator ref={wrapperRef} onClick={handleToggle}>
			<DropdownButton
				hasNotification={
					notificationsFullViewList &&
					notificationsFullViewList?.filter((x) => x.mark_read == '0')?.length > 0
						? true
						: false
				}
			/>
			{open && (
				<DropdownMenu position={position}>
					<Box className="menuContent">
						{notificationsFullViewList &&
							notificationsFullViewList?.filter((x) => x.mark_read == '0')?.length > 0 && (
								<ViewAllHeadingBlock>
									<Text as="h1" fontSize="14px"></Text>
									<Link
										variant="nav"
										href=""
										onClick={(e) => {
											markAllAsRead(e);
										}}
										fontSize="12px"
									>
										Mark all as read
									</Link>
								</ViewAllHeadingBlock>
							)}
						<SimpleBar>
							{(!qry.isFetching && notificationsFullViewList?.length > 0 && (
								<NotificationsFullView
									notificationsFullView={notificationsFullViewList}
									markAsRead={markAsRead}
									deleteNotification={deleteNotification}
								/>
							)) ||
								(!qry.isFetching && notificationsFullViewList?.length == 0 ? (
									<UserBody className="no-data">
										<Icon.searchIcon title="No Data Found" />
										<Text fontSize={18} fontWeight="bold" color="#5C5E57" textAlign="center">
											No notifications found
										</Text>
									</UserBody>
								) : (
									new Array(12).fill(null).map((_, i) => {
										return <Skeleton key={i} width="1200px" height="50px" />;
									})
								))}
						</SimpleBar>
					</Box>
				</DropdownMenu>
			)}
		</StyledNotificationIndicator>
	);
}

export { NotificationIndicator };
