import { userTypes } from 'models/enum-constants/common.constants';
import Iget from 'models/Iget';
import React, { ReactElement, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import { BreadcrumbNav, Footer, Header } from 'ui/components';
import { Sidebar } from 'ui/patterns';
import urls from 'utils/create.url';
import helperFunctions from 'utils/helper.functions';
import pageConstants from 'utils/pageConstants';
import hooks from 'utils/requestCtx/reactQueryHooks';
const MainContent = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
	padding: 0 50px 50px;
	@media (max-width: 960px) {
		padding: 0 15px;
	}
`;
const BreadcrumbHolder = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media (max-width: 480px) {
		& > div {
			padding-bottom: 15px;
		}
	}
`;
const TermsAndConditionHolder = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	@media (max-width: 1024px) {
		width: 100%;
	}
	ul {
		li {
			position: relative;
			padding-left: 25px;
			&:after {
				content: '';
				width: 10px;
				height: 10px;
				position: absolute;
				left: 0;
				top: 7px;
				border: 3px solid ${({ theme }) => theme.baseColors.baseYellow};
				border-radius: 50px;
			}
		}
		& + div {
			margin-top: 1.4285714285714284rem;
			margin-bottom: 1.4285714285714284rem;
		}
	}
	h1 {
		margin-bottom: 0.2857142857142857rem;
		font-size: 20px;
		font-family: 'OpenSansBold';
		color: ${({ theme }) => theme.colors.text.Yellow};
		& + div {
			margin-bottom: 1.4285714285714284rem;
		}
	}
	h2 {
		margin-bottom: 0.2857142857142857rem;
		font-size: 20px;
		font-family: 'OpenSansBold';
		color: ${({ theme }) => theme.colors.text.Yellow};
		& + div {
			margin-bottom: 1.4285714285714284rem;
		}
	}
`;
function TermsAndCondition(): ReactElement {
	let userType = localStorage.getItem('userType');
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const [tnc, setTnc] = useState({} as any);
	let code = '';
	if (userType == userTypes.venueUser || userType == userTypes.venueAdmin) {
		code = 'hospitality-terms-condition';
	} else if (userType == userTypes.supplierUser || userType == userTypes.supplierAdmin) {
		code = 'seller-terms-condition';
	}
	const url = urls.GetSearchUrl({
		url: 'cmsBlock/search?',
		pageSize: 2000,
		fields: [{
			field_name: 'identifier',
			field_value: code
		}],
		ctx: { avoidSort: true },
	});
	const key = 't&c';
	const obj: Partial<Iget> = {
		params: {},
		url: url,
		enabled: true,
		key: key,
		resultFilteringRequired: true,
	};
	const breadcrumbNav = [
		{ id: '04', label: 'Terms & Condition', active: true },
	];
	var qry = hooks.useQuery(obj);
	useEffect(() => {
		if (qry.data?.length > 0) setTnc(qry.data[0]);
	}, [qry.data]);

	function rawHtml() {
		var rawMarkup = tnc.content;
		return { __html: rawMarkup };
	}
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	}
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={() => onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={() => onclickHambrgBtn()}></Sidebar>

			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbHolder>
					<BreadcrumbNav Heading="Terms & Condition" breadcrumbList={breadcrumbNav} />
				</BreadcrumbHolder>

				{(!qry.isFetching && (
					<TermsAndConditionHolder dangerouslySetInnerHTML={rawHtml()}></TermsAndConditionHolder>
				)) ||
					<><Skeleton key={helperFunctions.getRandomInt(pageConstants.maxValue)} count={20} width='200px' />
						<Skeleton key={helperFunctions.getRandomInt(pageConstants.maxValue)} count={20} /></>}
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default TermsAndCondition;
