import styled from 'styled-components';

const MainContent = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
	padding: 0 50px;
	@media (max-width: 960px) {
		padding: 0 15px;
	}
	table {
		@media (max-width: 1300px) {
			width: 120vw;
		}
		@media (max-width: 768px) {
			width: 100%;
		}
		thead,
		tbody {
			tr {
				td,
				th {
					&:nth-child(1),
					&:nth-child(3) {
						width: 115px !important;
						max-width: 115px !important;
						flex: 0 0 115px !important;
						text-align: left !important;
						@media (max-width: 768px) {
							width: 33.33% !important;
							max-width: 33.33% !important;
							flex: 0 033.33% !important;
						}
						@media (max-width: 480px) {
							width: 50% !important;
							max-width: 50% !important;
							flex: 0 0 50% !important;
						}
					}
					&:nth-child(5),
					&:nth-child(6) {
						width: 170px !important;
						max-width: 170px !important;
						flex: 0 0 170px !important;
						text-align: left !important;
						@media (max-width: 768px) {
							width: 33.33% !important;
							max-width: 33.33% !important;
							flex: 0 033.33% !important;
						}
						@media (max-width: 480px) {
							width: 50% !important;
							max-width: 50% !important;
							flex: 0 0 50% !important;
						}
					}

					&:nth-child(4) {
						width: 115px !important;
						max-width: 115px !important;
						flex: 0 0 115px !important;
						text-align: right !important;
						@media (max-width: 768px) {
							width: 33.33% !important;
							max-width: 33.33% !important;
							flex: 0 033.33% !important;
							margin-right: 0 !important;
							padding-right: 20% !important;
						}
						@media (max-width: 480px) {
							width: 50% !important;
							max-width: 50% !important;
							flex: 0 0 50% !important;
							padding-right: 52px !important;
						}
						@media (max-width: 340px) {
							padding-right: 34px !important;
						}
					}
					&:nth-child(5) {
						@media (max-width: 1300px) {
							width: 220px !important;
							max-width: 220px !important;
							flex: 0 0 220px !important;
							padding-left: 3% !important;
						}
						@media (max-width: 780px) {
							padding-left: 0 !important;
							width: 33.33% !important;
							max-width: 33.33% !important;
							flex: 0 033.33% !important;
						}
						@media (max-width: 480px) {
							width: 50% !important;
							max-width: 50% !important;
							flex: 0 0 50% !important;
						}
					}
					&.action {
						width: 220px;
						max-width: 220px;
						flex: 0 0 220px;
						display: flex;
						align-items: center;
						button {
							width: auto;
							text-transform: capitalize;
							padding: 0 15px;
							margin: 0;
							span {
								font-size: 14px !important;
							}
						}
					}
				}
				td {
					.css-knfhfe {
						margin-top: -7px;
					}
				}
			}
		}
		tbody {
			tr {
				&:hover {
					cursor: pointer;
					background: ${({ theme }) => theme.colors.bg.lightYellow05};
					td {
						background: ${({ theme }) => theme.colors.bg.lightYellow05};
					}
				}
				td.action {
					button {
						width: auto;
						margin-right: 8px;
					}
					slot {
						margin-left: auto;
						margin-right: 0;
						@media screen and (max-width: 768px) {
							margin-right: auto;
							margin-left: 0;
							max-width: 100px;
							flex: 0 0 100px;
						}
						+ button {
							margin-right: 8px;
						}
					}
					div {
						button {
							padding: 0;
							max-width: 34px;
							flex: 0 0 34px;
							width: 34px;
						}
					}
					@media (max-width: 768px) {
						justify-content: flex-start !important;
						align-items: flex-start !important;
						flex-direction: row !important;
						flex-wrap: wrap;
					}
					@media screen and (max-width: 480px) {
						width: 100% !important;
						flex: 0 0 100% !important;
						max-width: 100% !important;
					}
				}
			}
		}
	}
`;
const ContentHolder = styled.div`
	height: 100%;
	padding: 0 50px;
	padding-bottom: 30px;
	width: 100%;

	@media (max-width: 768px) {
		padding: 0 15px;
	}
`;
const BreadcrumbHolder = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	/* @media (max-width: 480px) {
		flex-direction: column;
		align-items: end;
		& > div {
			padding-bottom: 15px;
		}
	} */
`;
const ButtonWrapper = styled.div`
	display: flex;
	@media (max-width: 480px) {
		padding-bottom: 0 !important;
	}
	button {
		display: flex !important;
		font-size: 18px;
		text-transform: capitalize;
		height: 50px;
		padding-top: 0;
		padding-bottom: 0;
		width: auto;
		@media (max-width: 767px) {
			width: 40px;
			height: 40px;
			padding: 0;
			align-items: center;
			justify-content: Center;
			div {
				display: none;
			}
		}
		&:hover {
			svg {
				path {
					fill: ${({ theme }) => theme.colors.text.white};
				}
			}
		}
		span {
			display: flex;
			align-items: center;
		}
		+ button {
			margin-left: 15px;
		}
		.pr-icon {
			svg {
				margin-right: 10px !important;
				@media (max-width: 767px) {
					margin-right: 0px !important;
				}
			}
		}
	}
`;
const PageSearchHolder = styled.div`
	display: inline-flex;
	flex-wrap: wrap;
	section {
		width: 824px;
		margin: auto;
		display: flex;
		align-items: center;
		position: relative;
		span {
			position: absolute;
			z-index: 1;
			left: 22px;
		}
		& + section {
			width: 100%;
			display: flex;
			justify-content: center;
			span {
				position: relative;
				left: 0;
			}
		}
		input {
			background: ${({ theme }) => theme.colors.bg.white};
			display: flex;
			align-items: center;
			justify-content: space-between;
			position: relative;
			width: 100%;
			height: 50px;
			border-radius: 8px;
			border: none;
			outline: none;
			box-shadow: none;
			padding: 0 20px 0 50px;
			font-family: 'OpenSansMedium';
			font-size: 16px;
			border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
			@media screen and (max-width: 767px) {
				height: 50px;
			}
			&::-webkit-input-placeholder {
				/* Chrome/Opera/Safari */
				color: ${({ theme }) => theme.colors.text.body};
				color: rgba(98, 98, 98, 0.4);
				font-size: 14px;
			}
			&::-moz-placeholder {
				/* Firefox 19+ */
				color: ${({ theme }) => theme.colors.text.body};
				color: rgba(98, 98, 98, 0.4);
				font-size: 14px;
			}
			&:-ms-input-placeholder {
				/* IE 10+ */
				color: ${({ theme }) => theme.colors.text.body};
				color: rgba(98, 98, 98, 0.4);
				font-size: 14px;
			}
			&:-moz-placeholder {
				/* Firefox 18- */
				color: ${({ theme }) => theme.colors.text.body};
				color: rgba(98, 98, 98, 0.4);
				font-size: 14px;
			}
		}
	}
`;

export { MainContent, ContentHolder, BreadcrumbHolder, ButtonWrapper, PageSearchHolder };
