import styled from 'styled-components';

const UserListHolder = styled.div`
	table {
		width: 100%!important;
		.d-flex {
			h1 {
				display: flex;
				align-items: center;
			}
		}
		thead,
		tbody {
			min-height: auto !important;
			tr {
				.img-block {
					width: 45px;
					height: 45px;
					margin-right: 18px;
					img {
						width: 100%;
						height: 100%;
					}
				}
				td,
				th {
					&.action {
						justify-content: center !important;
						@media screen and (max-width: 480px) {
							flex: 0 0 100% !important;
							max-width: 100% !important;
						}
					}
					&:nth-child(2){
						display:flex;
						h1{
							display:flex;
							align-items:center;

						}
					}
				}
			}
		}
	}
`;
const PageSearchHolder = styled.div`
	width: 100%;
	padding-top: 20px;
	padding-bottom: 25px;
	display: flex;
	justify-content: space-between;
	@media screen and (max-width: 960px) {
		flex-direction: column;
		align-items: center;
	}
	div {
		width: 650px;
		@media screen and (max-width: 960px) {
			width: 100%;
		}
	}
	form {
		button {
			@media screen and (max-width: 960px) {
				margin-top: 0;
			}
		}
	}
	button {
		@media screen and (max-width: 960px) {
			margin-top: 25px;
		}
		& div {
			width: auto;
		}
	}
	@media screen and (max-width: 767px) {
		width: 100%;
	}
`;
const StyledDropdown = styled.div`
	form {
		display: flex;
		button {
			margin-left: 10px;

			span {
				display: flex;
			}
			.pr-icon {
				margin-right: 16px;
			}
			svg {
				path {
					fill: ${({ theme }) => theme.colors.text.black04};
				}
			}
			&:hover {
				svg {
					path {
						fill: ${({ theme }) => theme.colors.text.white};
					}
				}
			}
			@media screen and (max-width: 960px) {
				div {
					display: none;
				}
				.pr-icon {
					margin-right: 0;
				}
			}
		}
	}
`;
export { UserListHolder, PageSearchHolder, StyledDropdown };
