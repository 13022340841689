import React, { ReactElement } from 'react';
import { queryCache } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Flex, Link, Text } from 'rebass/styled-components';
import { FlexboxProps, LayoutProps, SpaceProps } from 'styled-system';
import * as Icon from 'ui/icons';
import helper from 'utils/helper.functions';
import { Button } from '../button';
import { StyledBreadcrumb } from './styles';

type styledSystemProps = SpaceProps & LayoutProps & FlexboxProps;

type BreadcrumbProps = styledSystemProps & {
	Heading?: string;
	currentPage?: string;
	previousPage?: string;
	previousPageSecond?: string;
	previousPageThird?: string;
	type?: string;
	HomePage?: string;
	sku?: string;
	deliveryId?: string;
};

const getUrl = (type, page, sku, index, deliveryId) => {	
	let url = '';
	if (page == 'Home') url = '/home/dashboard';
	else if (page == 'Products') url = '/home/product-list?search=&type=';
	else if (page == 'Dashboard') url = '/admin/user-dashboard';
	else if (page == 'Delivery Confirmation Details') url = `/admin/deliveryConfirmationsInner?id=${deliveryId}`;
	else if (typeof page != 'undefined') {
		switch (type) {
			case 'supplier':
				if (index == '1')
					url = `/home/product-details?id=${sku}`;
				else
					url = getSupplierUrl(page);
				break;
			case 'product-details':
				let categories = queryCache.getQueryData('catKey') as any;
				if (typeof categories != 'undefined') {
					let obj = categories.find(
						(x) => x.name.toLowerCase().trim() == page.toLowerCase().trim()
					);
					url = obj ? `/home/product-list?search=&cat=${obj.id}` : '';
				}
				break;
			default:
				break;
		}
	}
	return url;
};

const getSupplierUrl = (page,) => {
	let url = '';
	let categories = queryCache.getQueryData('catKey') as any;
	if (typeof categories != 'undefined') {
		let obj = categories.find(
			(x) => x.name.toLowerCase().trim() == page.toLowerCase().trim()
		);
		url = obj ? `/home/product-list?search=&cat=${obj.id}` : '';
	}

	return url;
};

function Breadcrumb({
	Heading,
	currentPage,
	previousPage,
	previousPageSecond,
	previousPageThird,
	HomePage,
	type,
	sku,
	deliveryId,
	...styledSystemProps
}: BreadcrumbProps): ReactElement {
	const history = useHistory();
	const homePageUrl = getUrl(type, HomePage, sku, 0, 0);
	const prevPageUrl = getUrl(type, previousPage, sku, 0, 0);
	const prevPageSecondUrl = getUrl(type, previousPageSecond, sku, 0, deliveryId);
	const prevPageThirdUrl = getUrl(type, previousPageThird, sku, 1, 0);
	const navigateBack = '';
	const handleRoute = (val, RouteLink) => {
		if (navigateBack != null && navigateBack != '' && navigateBack != undefined) {
			history.push(navigateBack);
		} else {
			history.goBack();
		}
	};
	const navigateSupplier = () => {
		if (type == 'supplier') history.goBack()
		else{
			history.push(prevPageUrl);
		}
	}
	return (
		<StyledBreadcrumb {...styledSystemProps}>
			<Flex mb={9.1}>
				<Button onClick={(e) => handleRoute(e, navigateBack)}>
					<Icon.LeftArrowIcon title="LeftArrowIcon" />
				</Button>
				<Text as="h1" ml={9.1} fontSize={18} fontFamily="OpenSansBold" color="text.black04">
					{Heading}
				</Text>
			</Flex>
			<Flex flexWrap="wrap" className="breadcrumbItems">
				<Link fontSize={14} href={homePageUrl}>
					{HomePage}
				</Link>
				<Link fontSize={14} onClick={navigateSupplier}>
					{previousPage}
				</Link>
				{/* <Link fontSize={14} href={prevPageSecondUrl}>
					{helper.toPascalCase(previousPageSecond)}
				</Link> */}

				{previousPageSecond ? (
					<Link fontSize={14} href={prevPageSecondUrl}>
						{helper.toPascalCase(previousPageSecond)}
					</Link>
				) : null}
				{previousPageThird ? (
					<Link fontSize={14} href={prevPageThirdUrl}>
						{helper.toPascalCase(previousPageThird)}
					</Link>
				) : null}
				{/* <Text as="p" fontSize={14}>
					{helper.toPascalCase(currentPage)}
				</Text> */}
				{currentPage ? (
					<Text as="p" fontSize={14}>
						{helper.toPascalCase(currentPage)}
					</Text>
				) : null}
			</Flex>
		</StyledBreadcrumb>
	);
}

export { Breadcrumb };
