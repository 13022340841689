import React from 'react';
import { Link } from 'react-router-dom';
import { Text } from 'rebass/styled-components';
import styled from 'styled-components';
const LoginWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	position: relative;
	&::before {
		content: '';
		width: 100vw;
		height: 100vh;
		position: absolute;
		top: 0;
		background: #391634;
		position: fixed;
	}
	h1 {
		float: left;
		width: 100%;
		text-align: center;
		color: ${({ theme }) => theme.baseColors.primary};
		font-size: 60px;
	}
	h2 {
		font-size: 18px;
		margin: -10px 0 20px 0;
	}
`;
const Panel = styled.div`
	min-width: 548px;
	max-width: 548px;
	border-radius: 6px;
	background: #ebdecc;
	position: relative;
	padding: 30px 46px 46px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	a {
		background: ${({ theme }) => theme.baseColors.primary};
		color: ${({ theme }) => theme.baseColors.white};
		padding: 10px 30px;
		border-radius: 10px;
		text-decoration: none;
		&:hover {
			opacity: 0.8;
		}
	}
`;
class UnAuthorized extends React.Component {
	render() {
		return (
			<LoginWrapper>
				<Panel>
					<Text as="h1" fontFamily="OpenSansBlack">
						401
					</Text>
					<Text as="h2" fontFamily="OpenSansMedium">
						Page not found
					</Text>
					<Link to="/home/dashboard">Go to Home </Link>
				</Panel>
			</LoginWrapper>
		);
	}
}
export default UnAuthorized;
