import styled from 'styled-components';
const StyledProductReview = styled.div`
	float: left;
	width: 100%;
	margin-top: 40px;
	flex-direction: column;
	padding-top: 40px;
	border-top: 1px solid ${({ theme }) => theme.colors.border.grayLightBorder01};

	@media (max-width: 860px) {
		margin-top: 30px;
		padding-top: 30px;
		width: calc(100% + 30px);
		padding: 30px 0 0;
		margin: 0 0 0 -15px;
		border: 0 !important;
	}
`;
const ViewAllHeadingBlock = styled.div`
	display: flex !important;
	justify-content: space-between;
	padding: 0;
	color: ${({ theme }) => theme.colors.text.black};
	min-height: 30px;
	display: flex;
	align-items: center;
	h1,
	a {
		font-weight: normal;
		font-family: 'OpenSansMedium';
	}
	h1 {
		font-family: 'OpenSansBold';
		text-transform: uppercase;
	}
	a {
		color: ${({ theme }) => theme.baseColors.baseYellow};
		text-decoration: none;
		transition: background 0.8s;
		transition: ease-in all 200ms;

		&:hover {
			background: ${({ theme }) => theme.baseColors.baseYellow};
			padding: 3px 10px;
			color: ${({ theme }) => theme.baseColors.white};
			border-radius: 5px;
		}
	}
	@media (max-width: 860px) {
		padding: 0 15px;
	}
`;
const ProductReviewDetails = styled.div`
	padding: 30px 0 0;
	div {
		& + div {
			border-top: 1px solid ${({ theme }) => theme.colors.border.grayLightBorder01};
			padding-top: 30px;
			@media (max-width: 860px) {
				padding-top: 0 !important;
			}
		}
	}
	@media (max-width: 860px) {
		padding: 0 0 0 0;

		div {
			max-height: max-content;
		}
	}
	article {
		margin: 0 0 30px 0;
		padding: 0 0 30px 0;
		border-bottom: 1px solid ${({ theme }) => theme.colors.border.grayLightBorder01};
		flex-direction: column;
		align-items: flex-start;
		&:last-child {
			border-bottom: 0;
			margin-bottom: 0;
		}
		@media (max-width: 860px) {
			padding: 20px 15px 30px !important;
			margin-bottom: 0;
		}
		div {
			width: 100%;
			border: none;
		}
		h3 {
			margin-top: 8px;
		}
		hgroup {
			width: 100%;
			display: flex;
			justify-content: space-between;
			margin-top: 20px;
			@media (max-width: 640px) {
				flex-direction: column;
				justify-content: flex-start;
			}
			h4 {
				strong {
					color: ${({ theme }) => theme.colors.text.green1};
					margin-left: 5px;
					font-weight: normal;
					font-family: 'OpenSansMedium';
				}
			}
			h5 {
				margin-top: 10px;
				font-weight: normal;
				strong {
					color: ${({ theme }) => theme.colors.text.black03};
					margin-left: 5px;
					font-weight: normal;
					font-family: 'OpenSansRegular';
					border-left: 1px solid ${({ theme }) => theme.colors.text.black03};
					margin-left: 10px;
					padding-left: 8px;
				}
			}
			button {
				background: transparent;
				padding: 0;
				border: none;
				display: flex;
				align-items: center;
				margin-left: 30px;
				outline: none;
				cursor: pointer;
				@media (max-width: 640px) {
					margin-left: 0;
					margin-right: 30px;
				}
				h6 {
					margin-left: 14px;
					line-height: 18px;
				}
				&:active,
				&:focus {
					svg {
						path {
							fill: ${({ theme }) => theme.colors.text.green1};
						}
					}
					h6 {
						color: ${({ theme }) => theme.colors.text.green1};
					}
				}
				& + button {
					&:active,
					&:focus {
						svg {
							path {
								fill: ${({ theme }) => theme.colors.text.error};
							}
						}
						h6 {
							color: ${({ theme }) => theme.colors.text.error};
						}
					}
				}
			}
			div + div {
				display: flex;
				align-items: flex-end;
				justify-content: flex-end;
				@media (max-width: 640px) {
					justify-content: flex-start;
					margin-top: 35px;
				}
			}
		}
	}
`;
const RatingBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 0 0 60px;
	max-width: 60px;
	height: 22px;
	background: linear-gradient(135deg, #fec163 0%, #dea513 100%);
	border-radius: 100px;
	font-size: 12px;
	line-height: 16px;
	font-family: 'OpenSansBold';
	color: ${({ theme }) => theme.baseColors.white};
	margin-right: 12px;
`;
const RatingBoxBad = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 0 0 60px;
	max-width: 60px;
	height: 22px;
	background: ${({ theme }) => theme.colors.text.error01};
	border-radius: 100px;
	font-size: 12px;
	line-height: 16px;
	font-family: 'OpenSansBold';
	color: ${({ theme }) => theme.baseColors.white};
	margin-right: 12px;
`;
export { StyledProductReview, ViewAllHeadingBlock, ProductReviewDetails, RatingBox, RatingBoxBad };
