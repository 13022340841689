import { Input, Label } from '@rebass/forms';
import React, { ReactElement, useState } from 'react';
import { Box, Heading } from 'rebass/styled-components';
import styled from 'styled-components';
import icons from 'ui/icons';
import no_image from 'ui/images/no_image.png';

const LefContentHolder = styled.div`
	flex: 0 0 500px;
	max-width: 500px;
	margin-right: 142px;
	@media (max-width: 1360px) {
		flex: 0 0 400px;
		max-width: 400px;
		margin-right: 46px;
	}
	@media (max-width: 1152px) {
		flex: 0 0 350px;
		max-width: 350px;
		margin-right: 46px;
	}
	@media (max-width: 860px) {
		flex: 0 0 100%;
		max-width: 100%;
		margin-right: 0;
		margin-bottom: 30px;
	}
`;
const TrashIconOverlay = styled.div`
	position: absolute !important;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	background: linear-gradient(0deg, rgba(34, 18, 31, 0.28), rgba(34, 18, 31, 0.28)), url(image.png);
	opacity: 0;
	&:hover {
		opacity: 1;
		.pr-icon {
			width: 30px;
			height: 30px;
			background: ${({ theme }) => theme.baseColors.red};
			cursor: pointer;
			border-radius: 5px;
			display: flex;
			align-items: center;
			justify-content: center;
			&:hover {
				background: ${({ theme }) => theme.baseColors.red};
			}
			svg {
				width: 16px;
				height: 16px;
				path {
					fill: ${({ theme }) => theme.baseColors.white};
				}
			}
		}
	}
`;
const ImageHolder = styled.div`
	row-gap: 25px;
	column-gap: 25px;
	display: grid;
	grid-template-columns: repeat(3, 1fr) !important;
	@media (max-width: 500px) {
		grid-template-columns: repeat(2, 1fr) !important;
	}
	@media (max-width: 380px) {
		grid-template-columns: repeat(1, 1fr) !important;
	}
	@media (max-width: 480px) {
		row-gap: 18px;
		column-gap: 18px;
	}
	div {
		width: 100%;
		border-radius: 16px;
		overflow: hidden;
		position: relative;
		border: 1px solid rgba(240, 230, 215, 1);
		img {
			width: 100%;
			max-height: 135px;
			object-fit: contain;
			padding: 5px;
			min-height: 135px;
		}
	}
	form {
		width: 100%;
		height: 135px;
		position: relative;
		overflow: hidden;
		margin: 0;
		border: 1px dashed #000000;
		border-radius: 14px;
		flex-shrink: 0;

		.app-l-ad__upload {
			min-height: 135px;
			height: 100%;
			position: relative;
			cursor: pointer;
		}
		label {
			display: block;
			padding: 5px;
			margin: 0;
			height: 100%;
			position: relative;
			cursor: pointer;

			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			input {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: none;
				opacity: 0;
			}

			&:before {
				content: '';
				font-family: 'OpenSansBold';
				font-size: 12px;
				color: black;
			}
		}

		span {
			svg {
				width: 25px;
				height: 25px;
				path {
					fill: black;
				}
			}
		}
	}
`;
function AddImageHolder(props): ReactElement {
	var [uploadFile, setUploadFile] = useState<any>([]);
	var [imageFile, setImageFile] = useState<any>([]);
	var [imgFileList, setimgFileList] = useState<any>([]);

	// setImageFile(props.imageData);
	// On file select (from the pop up)
	const onFileChange = (event) => {
		var reader = new FileReader();
		var base64data;
		//imgFileList = [];
		// uploadFile && uploadFile.map((data) => {
		// 	imgFileList.push({ selectedFile: data.selectedFile });
		// });

		// imageFile && imageFile.map((data) => {
		// 	imgFileList.push({ selectedFile: data });
		// });
		event.target.files[0] && reader.readAsDataURL(event.target.files[0]);
		reader.onloadend = function () {
			base64data = reader.result;
			imgFileList.push({ selectedFile: base64data });
			setUploadFile(imgFileList);
			props.onChangeValue && props.onChangeValue(imgFileList, 'media_gallery_entries');
		};
	};

	const deleteImage = (data) => {
		if (uploadFile.length > 0) {
			uploadFile.splice(
				uploadFile.findIndex((x) => x.id == data.id),
				1
			);
			setUploadFile(uploadFile);
			props.onChangeValue && props.onChangeValue(uploadFile, 'media_gallery_entries');
		}

		if (props.imageData.length > 0) {
			props.imageData.splice(
				props.imageData.findIndex((x) => x.id == data.id),
				1
			);
			props.onChangeValue && props.onChangeValue(props.imageData, 'media_gallery_entries');
		}
	};

	// On file upload (click the upload button)
	const onFileUpload = () => {
		// Create an object of formData
		const formData = new FormData();
		// Update the formData object
		formData.append('myFile', uploadFile.selectedFile, uploadFile.selectedFile.name);
		// Details of the uploaded file
		console.log(uploadFile.selectedFile);
		// Request made to the backend api
		// Send formData object
		// axios.post("api/uploadfile", formData);
	};

	// File content to be displayed after
	// file upload is complete
	const fileData = () => {
		if (uploadFile) {
			return (
				uploadFile &&
				uploadFile.map((data, index) => (
					<Box key={index}>
						<Label>
							<img src={data.selectedFile} style={{ maxWidth: '100%', height: '100%' }} />
							<TrashIconOverlay onClick={() => deleteImage(data)}>
								<icons.DeleteIcon />
							</TrashIconOverlay>
						</Label>
					</Box>
				))
			);
		} else {
			return (
				<div>
					<br />
					<h4>Choose before Pressing the Upload button</h4>
				</div>
			);
		}
	};

	return (
		<LefContentHolder>
			<Heading
				fontSize="18px"
				color="text.black"
				fontFamily="OpenSansBold"
				sx={{ textTransform: 'uppercase' }}
			>
				Images
			</Heading>
			<ImageHolder>
				<Box as="form" p={0} fontSize={4} width={[1, 1, 1 / 2]}>
					<div className="app-l-ad__upload">
						<Label htmlFor="file">
							<Input id="file" name="file" accept="image/*" type="file" onChange={onFileChange} />
							<icons.PlusIcon />
						</Label>
					</div>
				</Box>
				{uploadFile && fileData()}
				{props.imageData &&
					props.imageData.map((data, index) =>
						data.file ? (
							<Box key={index}>
								<Label>
									<img src={data.file} style={{ maxWidth: '100%', height: '100%' }} />
									<TrashIconOverlay onClick={() => deleteImage(data)}>
										<icons.DeleteIcon />
									</TrashIconOverlay>
								</Label>
							</Box>
						) : (
							''
						)
					)}
				{/* <Box>
					<Label>
						<img src={BgImg} alt="Images" style={{ maxWidth: '100%' }} />
						<TrashIconOverlay>
							<icons.DeleteIcon />
						</TrashIconOverlay>
					</Label>
				</Box> */}
				{/* <Box>
					<img src={BgImg} alt="Images" style={{ maxWidth: '100%' }} />
				</Box>
				<Box>
					<img src={BgImg} alt="Images" style={{ maxWidth: '100%' }} />
				</Box>
				<Box>
					<img src={BgImg} alt="Images" style={{ maxWidth: '100%' }} />
				</Box>
				<Box>
					<img src={BgImg} alt="Images" style={{ maxWidth: '100%' }} />
				</Box> */}
			</ImageHolder>
		</LefContentHolder>
	);
}

export { AddImageHolder };
