import styled from 'styled-components';
const StyledNotifications = styled.div`
	width: auto;
	padding: 0 0 0 0;
	.accordion {
		border: none;
		margin: -3px 46px 0px 0px;
		@media (max-width: 480px) {
			margin: -3px 26px 0px 0px;

		}
		.accordion__button {
			padding: 0;
			border: none;
			background: transparent;
			box-shadow: none;
			svg {
				width: 25px;
				height: 30px;
			}
			&:before {
				display: none;
			}
		}
		.accordion__panel {
			width: 550px;

			position: absolute;
			right: 0;
			top: 60px;
			padding: 0;
			background: ${({ theme }) => theme.baseColors.white};
			box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.39);
			border-radius: 12px;
			transition: all 0.25s ease-in-out;
			z-index: 1;
			padding: 20px;
			&::before {
				content: '';
				width: 47px;
				height: 16px;
				position: absolute;
				top: -16px;
				right: 83px;
				background: url(${require('../../images/dropdownTopArrow.png')});
				@media (max-width: 480px) {
					right: 55px;
				}
			}
			@media (max-width: 480px) {
				width: 330px;
			}
			@media (max-width: 340px) {
				width: 300px;
				ul{
					li{
						font-size: 12px;
					}
				}
			}
		}
		.react-tabs__tab-list {
			margin: 0 -20px;
			li {
				font-size: 15px;
				font-family: 'OpenSansSemiBold';
				display: flex;
				justify-content: center;
			}
		}
		.accordion__heading {
			cursor: pointer;
		}
		.notificationBadge {
			position: relative;
			&:after {
				content: '';
				position: absolute;
				width: 12px;
				height: 12px !important;
				top: 3px;
				left: auto;
				border-width: 1px;
				right: 0px;
				background: #eb5757;
				border: 2px solid #fff;
				border-radius: 50px;
			}
		}
		svg {
			width: 25px;
			height: 30px;
		}
	}
`;

const BadgeCount = styled.div`
	width: 22px;
	height: 22px;
	max-width: 22px;
	background: ${({ theme }) => theme.baseColors.red};
	color: ${({ theme }) => theme.baseColors.white};
	font-size: 10px;
	border-radius: 50px;
	margin-left: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: -3px;
`;

export { StyledNotifications, BadgeCount };
