import { userTypes } from 'models/enum-constants/common.constants';
import Iget from 'models/Iget';
import Ipost from 'models/Ipost';
import React, { ReactElement, useState, useEffect, useContext } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Box, Text } from 'rebass/styled-components';
import { FlexboxProps, LayoutProps, SpaceProps } from 'styled-system';
import CustomerNotFound from 'ui/components/header/parkedOrderLimitReached';
import * as Icon from 'ui/icons';
import { Dropdown, Modal } from 'ui/patterns';
import appContext from 'utils/appContext';
import urls from 'utils/create.url';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import utilityFunctions from 'utils/utility.functions';
import { StyledLocation } from './styles';

type styledSystemProps = SpaceProps & LayoutProps & FlexboxProps;

type LocationProps = styledSystemProps & {
	location: string;
};

function Location({ location, ...styledSystemProps }: LocationProps): ReactElement {
	var userId = localStorage.getItem('userId');
	var userType = localStorage.getItem('userType');
	const [modalText, setmodalText] = useState('');
	const [showPopup, setShowModal] = useState(false);
	const [action, setAction] = useState('');
	const [modalId, setmodalId] = useState('');
	const [showParkedOrderLimitModal, setshowParkedOrderLimitModal] = useState(false);
	const key = 'switchParking';
	const [mutatePost] = hooks.useMutation(key);
	// let locationOptions: HVenueDetails[];
	let ctx = useContext(appContext).userObj;
	let locationOptions = [] as any;
	let ddOptions = [] as any;
	const params = {
		pages: '1',
	};
	const venueUrl = `experion/venueuser/get/${userId}`;
	const venueAdminUrl = urls.GetSearchUrl({
		url: 'experion/venue/search?',
		pageSize: 500,
		fields: [
			{
				field_name: 'hosp_id',
				field_value: localStorage.getItem('hospitalityId')!,
			},
		],
		filterDeleted: true,
		filterInactive: true,
	});
	const dcUrl = `experion/supplycompanyaccount/get/${userId}`;
	const dcAdminUrl = urls.GetSearchUrl({
		url: 'experion/supplycompanyaccount/dc/search?',
		pageSize: 500,
		fields: [
			{
				field_name: 'supply_id',
				field_value: localStorage.getItem('supplierId')!,
			},
		],
		filterDeleted: true,
		filterInactive: true,
	});

	function apiURLSelector() {
		if (userType == userTypes.venueAdmin) {
			return venueAdminUrl;
		} else if (userType == userTypes.venueUser) {
			return venueUrl;
		} else if (userType == userTypes.supplierAdmin) {
			return dcAdminUrl;
		} else if (userType == userTypes.supplierUser) {
			return dcUrl;
		}
	}

	const userKey = 'locationKey' + userType;
	const obj: Partial<Iget> = {
		params: params,
		url: apiURLSelector(),
		enabled: true,
		key: userKey,
		resultFilteringRequired: true,
	};
	const qry = hooks.useQuery(obj);
	locationOptions = qry.data;
	var venueName;
	var storedLocation =
		userId && (userType == userTypes.venueUser || userType == userTypes.venueAdmin)
			? localStorage.selectedVenue
			: localStorage.selectedLocation;
	var venue = storedLocation;
	if (userId && locationOptions != undefined && locationOptions != null) {
		ddOptions = [];
		locationOptions.forEach((x) => {
			if (x.is_delete != true && x.is_active == true) {
				if (userType == userTypes.venueUser) {
					if (x.venue_id && x.venue_id.toString() === venue) {
						venueName = x.venue_name;
						localStorage.setItem('orderCostLimit', x.order_cost_limit);
					}
					ddOptions.push({ id: x.venue_id, name: x.venue_name });
				} else if (userType == userTypes.venueAdmin) {
					if (x.id && x.id.toString() === venue) {
						venueName = x.name;
						localStorage.setItem('orderCostLimit', x.order_cost_limit);
					}
					ddOptions.push({ id: x.id, name: x.name });
				} else if (userType == userTypes.supplierUser) {
					if (x.dc_id && x.dc_id.toString() === venue) {
						venueName = x.dc_name;
					}
					ddOptions.push({ id: x.dc_id, name: x.dc_name });
				} else if (userType == userTypes.supplierAdmin) {
					if (x.dc_id && x.dc_id.toString() === venue) {
						venueName = x.name;
					}
					ddOptions.push({ id: x.dc_id, name: x.name });
				}
			}
		});
	}

	
	if (userId && locationOptions != undefined && locationOptions != null && locationOptions.length === 1) {
		if (locationOptions[0].is_delete != true && locationOptions[0].is_active == true) {
			if (userType == userTypes.venueUser) {
				if (locationOptions[0].venue_id && locationOptions[0].venue_id.toString() === venue) {
					venueName = locationOptions[0].venue_name;
					localStorage.setItem('orderCostLimit', locationOptions[0].order_cost_limit);
				}
				localStorage.setItem('selectedVenue', locationOptions[0].venue_id);
				ctx.venueId = locationOptions[0].venue_id;
			} else if (userType == userTypes.venueAdmin) {
				if (locationOptions[0].id && locationOptions[0].id.toString() === venue) {
					venueName = locationOptions[0].name;
					localStorage.setItem('orderCostLimit', locationOptions[0].order_cost_limit);
				}
				localStorage.setItem('selectedVenue', locationOptions[0].id);
				ctx.venueId = locationOptions[0].id;
			} else if (userType == userTypes.supplierUser) {
				if (locationOptions[0].dc_id && locationOptions[0].dc_id.toString() === venue) {
					venueName = locationOptions[0].dc_name;
				}
				localStorage.setItem('selectedLocation', locationOptions[0].dc_id);
			} else if (userType == userTypes.supplierAdmin) {
				if (locationOptions[0].dc_id && locationOptions[0].dc_id.toString() === venue) {
					venueName = locationOptions[0].name;
				}
				localStorage.setItem('selectedLocation', locationOptions[0].dc_id);
			}
		}
	}
	
	
	async function onSubmit() {
		if (action === 'change') {
			if (userId && (userType == userTypes.venueUser || userType == userTypes.venueAdmin)) {
				let poLimitCheck: any;
				poLimitCheck = await utilityFunctions.getVenueParkedOrderLimit();
				let poCountCheck: any;
				poCountCheck = await utilityFunctions.getVenueParkedOrderCount();

				let cartCheck = await utilityFunctions.getCartData();
				if (cartCheck) {
					let sVenue = localStorage.getItem('selectedVenue');
					localStorage.setItem('switchedVenue', sVenue!);
					if (poLimitCheck < poCountCheck + 1) {
						setShowModal(!showPopup);
						togglePOLPopup();
						return false;
					} else {
						await onMarkAsParked();
						setShowModal(!showPopup);
					}
				}

				storedLocation = modalId;
				localStorage.setItem('selectedVenue', storedLocation);
				ctx.venueId = locationOptions[0].id;
				await utilityFunctions.setPermissions(storedLocation);
				setShowModal(!showPopup);
			} else {
				storedLocation = modalId;
				localStorage.setItem('selectedLocation', storedLocation);
				await utilityFunctions.setPermissions(storedLocation, false);
				setShowModal(!showPopup);
			}
			setShowOptions(!showOptions);
			window.location.href = window.location.href.replace('#!', '');
		}
	}

	async function closePopup() {
		let cartCheck = await utilityFunctions.getCartData();
		if (cartCheck) {
			storedLocation = modalId;
			localStorage.setItem('selectedVenue', storedLocation);
			ctx.venueId = locationOptions[0].id;
			await utilityFunctions.setPermissions(storedLocation);
			setShowModal(!showPopup);

			clearCart().then(() => {
				setShowOptions(!showOptions);
				window.location.href = window.location.href.replace('#!', '');
			});
		}
		await setShowModal(!showPopup);
		// if((userType == userTypes.venueUser || userType == userTypes.venueAdmin)){
		// 	let cartCheck = await helperFunctions.getCartData();
		// 	if(cartCheck){
		// 		clearCart();
		// 	}
		// }
	}

	async function clearCart() {
		const obj: Partial<Ipost> = {
			data: { cartId: localStorage.getItem('cartId') },
			params: {},
			url: '/clear-cart',
			message: ' ',
		};
		try {
			await api.post(obj).then((data) => {
				if (data !== undefined) {
					//Do Nothing
				}
			});
		} catch (e) {}
	}
	const onMarkAsParked = () => {
		var pCartId = localStorage.getItem('cartId');
		var pUserId = localStorage.getItem('userId');
		var pSwitchedVenue = localStorage.getItem('switchedVenue');

		let payload = {
			cartId: parseInt(pCartId + ''),
			venueUserId: parseInt(pUserId + ''),
			venueId: parseInt(pSwitchedVenue + ''),
		};
		const obj1: Partial<Ipost> = {
			data: payload,
			params: {},
			url: '/experion-parkorder/create',
			message: ' ',
		};
		try {
			mutatePost(obj1).then((data) => {
				if (data !== undefined) {
					clearCart();
				}
			});
		} catch (e) {}
	};

	const togglePOLPopup = () => {
		//e.stopPropagation();
		setshowParkedOrderLimitModal(!showParkedOrderLimitModal);
	};

	const callbackPOLFunction = (_) => {
		//setSelectedFavourite(childData);
	};

	async function showConfirmationModal(action, text, event) {
		if (userId && (userType == userTypes.venueUser || userType == userTypes.venueAdmin)) {
			setmodalId(event.id);
			setAction(action);
			setmodalText(text);
			setShowModal(!showPopup);
		} else {
			setmodalId(event.id);
			setAction(action);
			setmodalText(text);
			setShowModal(!showPopup);
		}
	}

	const [showOptions, setShowOptions] = useState(false);

	const optionsTrigger = () => (
		<button className="btn-locationOptions" onClick={() => setShowOptions(!showOptions)}>
			<Icon.locationIcon width="2em" className="locationIcon" />
			<Box>
				{/* <Text as="label" className="amount" fontSize={12}>
					{
						(userType == userTypes.venueUser || userType == userTypes.venueAdmin) ? "Venue" : "DC"
					}
				</Text> */}
				<Text as="p" className="amount" fontSize={14} fontFamily="OpenSansMedium">
					{userId
						? venueName
						: ddOptions && storedLocation > 0
						? ddOptions[storedLocation - 1].label
						: ''}
				</Text>
			</Box>
			<Icon.Options width="2em" />
		</button>
	);

	const onLocationSelection = async (event) => {
		if (userId) {
			if (userType == userTypes.venueUser || userType == userTypes.venueAdmin) {
				if (localStorage.getItem('selectedVenue') == event.id) {
					setShowOptions(!showOptions);
					return false;
				} else {
					let cartData: any;
					cartData = await utilityFunctions.getCartData();
					if (cartData) {
						showConfirmationModal(
							'change',
							'Do you want to park the order and switch the Store?',
							event
						);
					} else {
						showConfirmationModal('change', 'Are you sure you want to switch the Store?', event);
					}
				}
			} else {
				if (localStorage.getItem('selectedLocation') == event.id) {
					setShowOptions(!showOptions);
					return false;
				} else {
					showConfirmationModal('change', 'Are you sure you want to switch the Distribution Center?', event);
				}
			}
		}
	};
	
	
	return (
		<>
			<StyledLocation {...styledSystemProps} className="location">
				<Dropdown
					trigger={optionsTrigger()}
					options={ddOptions}
					showOptions={showOptions}
					setShowOptions={setShowOptions}
					top="0"
					place="covered-right"
					handleClick={onLocationSelection}
				/>
			</StyledLocation>

			<Modal
				width="600px"
				showModal={showParkedOrderLimitModal}
				setShowModal={setshowParkedOrderLimitModal}
			>
				<CustomerNotFound parentCallback={callbackPOLFunction} togglePopup={togglePOLPopup} />
			</Modal>

			<SweetAlert
				show={showPopup}
				confirmBtnText="Confirm"
				onConfirm={onSubmit}
				showCancel={true}
				onCancel={closePopup}
				title={'Confirmation Box'}
			>
				{() => <form>{modalText}</form>}
			</SweetAlert>
		</>
	);
}

export { Location };
