import { Input, Label, Radio } from '@rebass/forms';
import React from 'react';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { Box, Flex, Text } from 'rebass/styled-components';
import 'simplebar/dist/simplebar.min.css';
import styled from 'styled-components';
import { Button } from 'ui/components';
import icons from 'ui/icons';
const UserListHolder = styled.div`
	width: 425px;
	margin-top: 35px;
	@media (max-width: 640px) {
		width: 100%;
	}
	h1 {
		margin-bottom: 10px !important;
	}
	button {
		padding: 5px 10px;
		font-size: 14px;
		cursor: pointer !important;
		span {
			display: flex;
			.pr-icon {
				margin-right: 10px;
			}
		}
	}
	form {
		flex-direction: column;
		margin-top: 20px;
		font-family: 'OpenSansMedium';
		width: 100% !important;
		slot,
		section {
			display: flex;
			margin-bottom: 15px;
			div {
				margin-top: 0;
				min-width: 130px;
			}
		}
		section {
			background: ${({ theme }) => theme.colors.bg.lightYellow};
			border-radius: 8px;
			padding: 10px;
			margin-bottom: 10px !important;
		}
		button {
			margin-left: 25px !important;
			max-width: 42px !important;
			flex: 0 0 42px !important;
			height: 42px !important;
			span {
				display: flex;
				.pr-icon {
					margin-right: 0;
				}
			}
			@media (max-width: 640px) {
				margin: 0 0 0 10px !important;
			}
		}
		select,
		input {
			width: auto;
			border: 1px solid ${({ theme }) => theme.colors.border.default};
			outline: none;
			background: ${({ theme }) => theme.colors.bg.white};
		}
		select {
			border-right: 0;
			width: 100%;
			border-radius: 6px 0 0 6px;
		}
		input {
			border-radius: 6px;
			width: 100% !important;
			text-align: center;
			+ div + input {
				border-radius: 0 6px 6px 0;
				margin-right: 0;
			}
		}
	}
	section {
		align-items: center;
		justify-content: space-between;
		margin: 0 !important;
		input,
		select {
			opacity: 0.7;
			cursor: not-allowed;
		}
		input + div {
			min-width: auto;
			width: 20px;
			margin-left: 23px;
		}
		button {
			margin-left: 25px;
			max-width: 42px;
			flex: 0 0 42px;
			height: 42px;
			svg {
				height: 20px;
			}
		}
		label {
			font-size: 16px;
			line-height: 21px;
			font-family: 'OpenSansSemiBold';
			color: ${({ theme }) => theme.colors.text.SubHeading};
			width: auto;
			input,
			svg {
				width: auto !important;
				background: transparent !important;
			}
			svg {
				color: ${({ theme }) => theme.baseColors.primary};
			}
			svg + svg {
				color: ${({ theme }) => theme.colors.text.SubHeading};
			}
			div {
				margin: -3px 0 0 0 !important;
			}
		}
	}
`;

function AttributeEntryTypes(prop) {
	return (
		<UserListHolder>
			<Flex flexWrap="wrap" as="section">
				<Text as="h1" fontSize={16} fontFamily="OpenSansBold" color="text.black">
					Attributes Entry
				</Text>
			</Flex>
			<Flex as="form">
				{prop.action == 'Add' && (
					<Box as="slot">
						<Input
							disabled={prop.action == 'Save'}
							autoComplete="off"
							value={prop.data.attribute}
							onChange={(e) => prop.handleChange(e.target.value, 'attribute')}
							maxLength={50}
							type="text"
						/>
						<Button
							disabled={prop.attribute == '' || prop.action == 'Save'}
							onClick={(e) => {
								prop.addAttributeSet(e);
							}}
						>
							<icons.PlusIcon />
						</Button>
					</Box>
				)}
				{prop.attributeList &&
					prop.attributeList?.map((v, i) => {
						return (
							<Box as="section" key={i}>
								<Input
									value={v.label}
									onChange={(e) => {
										e.preventDefault();
									}}
								/>
								<Box>
									<Label>
										<Radio
											id={i}
											name="color"
											disabled={prop.action == 'Save'}
											onClick={() => {
												prop.setDefault(v.label);
											}}
											checked={v.is_default}
										/>
									</Label>
								</Box>
								<Button
									variant="deleteIcon"
									disabled={prop.action == 'Save'}
									onClick={() => {
										prop.deleteAttribute(v.label);
									}}
								>
									<icons.DeleteIcon />
								</Button>
							</Box>
						);
					})}
			</Flex>
		</UserListHolder>
	);
}

export { AttributeEntryTypes };
