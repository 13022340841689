import styled from 'styled-components';
const StyledLocation = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	z-index: 99;
	margin-right: 10px;

	.btn-locationOptions {
		display: flex;
		background: transparent;
		border: none;
		box-shadow: none;
		align-items: center;
		outline: none;
		cursor: pointer;
		padding: 0;
		svg {
			path {
				fill: ${({ theme }) => theme.baseColors.white};
			}
		}
		div {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			color: ${({ theme }) => theme.baseColors.white};
			padding: 0 16px 0 10px;
			label,
			p {
				margin: 0;
				padding: 0;
				line-height: normal;
				text-align: left;
			}
			p {
				@media (max-width: 480px) {
					font-size: 13px;
				}
			}
		}
		& + div {
			margin-top: 37px;
			border-radius: 0 0 10px 10px;
			left: 0;
			right: 0;
			min-width: 150px;
			z-index: 1;
			button {
				position: relative;
				min-height: auto;
				padding: 10px 10px;
				margin: 0;
				&::before {
					left: 0;
					right: 0;
				}
			}
		}
	}
`;
export { StyledLocation };
