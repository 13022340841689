import { venueUserPermissionList } from 'models/enum-constants/common.constants';
import Ipost from 'models/Ipost';
import React, { ReactElement, useState, useContext, useEffect } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory, useLocation } from 'react-router-dom';
import 'react-tippy/dist/tippy.css';
import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { Button, Loader } from 'ui/components';
import * as Icon from 'ui/icons';
import env from 'utils/constants';
import helper from 'utils/helper.functions';
import api from 'utils/requestCtx/api.service';
import utilityFunctions from 'utils/utility.functions';
import AppContext from 'utils/appContext';
import { Tooltip } from 'react-tippy';
import Iget from 'models/Iget';
import hooks from 'utils/requestCtx/reactQueryHooks';
import queryString from 'query-string';
import notify from 'utils/toast.service';

const StyledParkedOrderDetailsEditView = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	column-gap: 20px;
	row-gap: 20px;
	margin-bottom: 16px;
	.order-details__wrap {
		flex-direction: row;
		background: ${({ theme }) => theme.colors.bg.white};
		border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
		box-sizing: border-box;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 16px;
		overflow: hidden;
		transition: box-shadow 100ms ease-in, transform 100ms ease-in;
		align-items: flex-start;
		height: 100%;
		span {
			max-width: 117px;
			flex: 0 0 117px;
			height: 100%;
			background-repeat: no-repeat;
			background-size: contain;

			@media (max-width: 768px) {
				max-width: 126px;
				flex: 0 0 126px;
				background-position: center center;
			}
			@media (max-width: 360px) {
				max-width: 80px;
				flex: 0 0 80px;
			}
		}
		section {
			display: grid;
			grid-template-columns: repeat(6, 1fr) !important;
			align-items: center;
			height: 100%;
			padding: 20px 25px;
			column-gap: 10px;
			label {
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				display: block;
			}
			@media (max-width: 1552px) {
				grid-template-columns: repeat(3, 1fr) !important;
				row-gap: 20px;
				main {
					justify-self: start;
				}
			}
			@media (max-width: 960px) {
				grid-template-columns: repeat(2, 1fr) !important;
				row-gap: 20px;
				label {
					width: 100%;
					float: left;
					margin: -6px 0 0 0;
					line-height: normal;
				}
			}
			@media (max-width: 640px) {
				grid-template-columns: repeat(2, 1fr);
				main {
					grid-column: 2/1;
				}
				.parkedOrderName {
					grid-area: auto / 3 / auto / 1;
				}
			}
			@media (max-width: 340px) {
				grid-template-columns: repeat(1, 1fr);
			}
			.btn-block {
				display: flex;
				align-items: center;
				justify-content: flex-end;

				@media (max-width: 1300px) {
					justify-content: flex-start;
				}
				span {
					height: auto;
					flex: 0 0 auto;
					max-width: auto;
					min-width: auto;
					min-height: auto;
				}
				button {
					display: flex;
					align-items: center;
					margin-right: 15px;
					text-transform: capitalize;
					white-space: nowrap;
					height: 50px;
					@media (max-width: 480px) {
						font-size: 13px;
						padding-right: 10px;
						padding-left: 10px;
						white-space: nowrap;
					}
					span {
						max-width: 100% !important;
					}
				}
				icon {
					display: inline-block;
				}
				div {
					word-break: break-word;
				}
			}
			.totalProducts,
			.totalSuppliers {
				max-width: 120px;
				text-align: right;
				white-space: nowrap;
			}
			.totalSuppliers {
				max-width: 125px;
			}
		}
		&.selected {
			background: ${({ theme }) => theme.newColors.lightgray};
		}
	}
`;

function ParkedOrderDetailsEditView(props): ReactElement {
	const ctx = useContext(AppContext).cartObj;
	const [postObj, setObj] = useState([] as any);
	const [modalText, setmodalText] = useState('');
	const [showPopup, setShowModal] = useState(false);
	const [action, setAction] = useState('');
	const [orderId, setOrderId] = useState('');
	const [inProgress, setInProgress] = useState(false);
	const userId = localStorage.getItem('userId')!;
	const [venueUserId] = useState<any>(localStorage.getItem('userId'));
	const [venueId] = useState<any>(localStorage.getItem('selectedVenue'));
	const location = useLocation();
	const parsed = queryString.parse(location.search);
	const [orders, setOrders] = useState([] as any);
	const [isLoading, setIsLoading] = useState(false);
	let history = useHistory();
	useEffect(() => {
		if (props?.orders) {
			setOrders(props.orders);
		}
	}, [props?.orders]);
	function confirm(e, action, id, qty = 0) {
		e.stopPropagation();
		setAction(action);
		switch (action) {
			case 'del':
				showConfirmationModal('Delete parked order?', id, qty);
				break;
			case 'move':
				showConfirmationModal('Sure you want to move the products to cart ?', id, qty);
				break;
			default:
				break;
		}
	}
	function showConfirmationModal(text, id, qty) {
		setOrderId(id);
		setmodalText(text);
		setShowModal(!showPopup);
	}
	async function closePopup() {
		await setShowModal(!showPopup);
	}
	function onSubmit() {
		if (action == 'del') deleteParkedOrder();
		else if (action == 'move') moveToCart();
		else if (action == 'share') parkedOrderShare(1, parsed.id);
	}

	function deleteParkedOrder(hasMessage = true) {
		let obj1: Partial<Ipost> = {};
		obj1.message = !hasMessage ? ' ' : 'Order deleted successfully';
		obj1.url = `experion-parkorder/parkorder/${orderId}`;
		api.delete(obj1).then(function (d) {
			if (typeof d != 'undefined') {
				history.push('/store/orders?tab=parked-orders');
				//window.location.href = window.location.href;
			}
		});
		if (hasMessage) setShowModal(!showPopup);
	}

	async function moveToCart() {
		setInProgress(true);
		setShowModal(!showPopup);
		let items = [] as any;
		props?.products?.forEach((v) => {
			items.push({ product_id: v.product_id, qty: v.qty });
		});
		setObj(items);
		if (items.length > 0) {
			let id: any;
			let CUSTOMER_TOKEN = localStorage.getItem('customerToken');
			id = await utilityFunctions.getCartId(CUSTOMER_TOKEN);
			if (id !== undefined) {
				const payload = {
					products: {
						quote_id: id,
						items: items,
						venue_user_id: venueUserId,
						venue_id: venueId,
						parked_id: orderId,
					},
				};
				const obj: Partial<Ipost> = {
					data: payload,
					url: env.multiCartURL(),
					key: 'parkedToCart',
					message: 'Available products will be moved to cart!',
					headers: { Authorization: localStorage.getItem('customerToken') },
				};

				try {
					await api
						.post(obj)
						.then(function (data) {
							if (data == true) {
								setInProgress(false);
								ctx.stepperdCountUpdated = false;
								deleteParkedOrder(false);
								history.push('/store/cart');
							} else {
								setInProgress(false);
							}
						})
						.catch(function (data) {
							setInProgress(false);
						});
				} catch (e) {
					setInProgress(false);
				}
			}
		} else {
			setInProgress(false);
			notify('No products are available to move to the cart !!').error();
		}
	}

	const obj: Partial<Iget> = {
		url: `experion-parkorder/checkEdit/${parsed?.id}/${userId}`,
		key: 'checkEdit',
		enabled: false,
	};
	const qry = hooks.useQuery(obj);
	function parkedOrderEdit() {
		setIsLoading(true);
		qry
			.refetch()
			.then((data) => {
				if (data) {
					props?.setLock(1);
				}
				setIsLoading(false);
			})
			.catch((error) => {
				setIsLoading(false);
			});
	}
	const onClickShare = (isShared, parkedOrderId) => {
		if (isShared === 0) {
			let id = 0;
			let qty = 0;
			setAction('share');
			showConfirmationModal(
				'Parked Order cannot be updated when it is shared with other users. Sure you want to share?',
				id,
				qty
			);
		} else parkedOrderShare(0, parkedOrderId);
	};
	async function parkedOrderShare(isShared, parkedOrderId) {
		setIsLoading(true);
		const payload = {
			parkOrderId: parseInt(parkedOrderId),
			isShared: isShared,
			venueUserId: parseInt(userId),
		};
		const obj: Partial<Ipost> = {
			data: payload,
			params: {},
			url: `experion-parkorder/share`,
			message: `${isShared == 0 ? 'You has stopped sharing your parked order' : ' '}`,
		};
		try {
			await api.post(obj).then(function (data) {
				if (isShared == 1) {
					setShowModal(!showPopup);
				}
				if (typeof data != 'undefined') {
					if (props?.orders[0]) {
						props.orders[0].is_shared = isShared;
						setOrders(props.orders);
						setIsLoading(false);
					}
				}
				// setIsShared(true);
			});
		} catch (e) {
			setIsLoading(false);
		}
	}
	return (
		<StyledParkedOrderDetailsEditView>
			{isLoading === true ? <Loader></Loader> : null}
			{orders?.map((data, i) => (
				<Flex
					className="order-details__wrap selected"
					bg="white"
					sx={{ alignItems: 'center' }}
					key={i}
				>
					{/* <Flex
						alignItems="center"
						as="span"
						maxWidth={['100%']}
						sx={{ overflow: 'hidden' }}
					></Flex> */}
					<Flex as="section" flexWrap="wrap" width="100%" maxWidth={['100%']}>
						<Box className="parkedOrderName">
							<Text mb={1} fontSize={18} color="text.black">
								Parked Order Name
							</Text>
							<Text as="label" fontSize={20} fontFamily="OpenSansBold" color="text.black">
								{data.name}
							</Text>
						</Box>
						<Box>
							<Text mb={1} fontSize={18} color="text.black">
								Amount
							</Text>
							<Text as="label" fontSize={20} fontFamily="OpenSansBold" color="text.black">
								{helper.formatAmount(props?.amount)}
							</Text>
						</Box>
						<Box>
							<Text mb={1} fontSize={18} color="text.black">
								Created On
							</Text>
							<Text as="label" fontSize={20} fontFamily="OpenSansBold" color="text.black">
								{helper.formatDate(data.created_at)}
							</Text>
						</Box>
						<Box className="totalProducts">
							<Text mb={1} fontSize={18} color="text.black">
								Total Products
							</Text>
							<Text as="label" fontSize={20} fontFamily="OpenSansBold" color="text.black">
								{props?.productCount}
							</Text>
						</Box>
						<Box className="totalSuppliers">
							<Text mb={1} fontSize={18} color="text.black">
								Total Suppliers
							</Text>
							<Text as="label" fontSize={20} fontFamily="OpenSansBold" color="text.black">
								{props?.supplierCount}
							</Text>
						</Box>
						<Box as="main">
							<div className="btn-block">
								{utilityFunctions.isGranted(venueUserPermissionList.ManageProductsinCart) &&
									props?.showCounter == false && (
										<Button onClick={(e) => confirm(e, 'move', data.parkorder_id, data.item_count)}>
											Move to Cart
										</Button>
									)}
								{/* {(utilityFunctions.isGranted(venueUserPermissionList.ParkedOrdersDelete) ||
									utilityFunctions.isGranted(venueUserPermissionList.ParkedOrdersAdmin)) && (
									<Button variant="ghost" onClick={(e) => confirm(e, 'del', data.parkorder_id)}>
										<Icon.DeleteIcon />
									</Button>
								)} */}
								{data.venue_user_id === userId ? (
									<Tooltip
										arrow
										title={data.is_shared === 1 ? 'Shared' : 'Not shared'}
										animation="perspective"
										position="top"
										trigger="mouseenter"
									>
										{/* Add "active" class for button active state */}
										<Button
											active={data.is_shared === 1 ? true : false}
											variant="ghost"
											onClick={() => onClickShare(data.is_shared, data.parkorder_id)}
										>
											<Icon.shareIcon />
										</Button>
									</Tooltip>
								) : (
									<>
										{props?.showCounter == false ? (
											<Button
												variant="ghost"
												disabled={parseInt(props?.productCount) == 0}
												onClick={parkedOrderEdit}
											>
												<Icon.editIcon />
											</Button>
										) : (
											<>
												<Button
													variant="ghost"
													onClick={() =>
														props.changeParkOrderDetails && props.changeParkOrderDetails('close')
													}
												>
													<Icon.Close_ICon />
												</Button>
												<Button
													variant="ghost"
													disabled={props?.disableSave}
													onClick={() =>
														props.changeParkOrderDetails && props.changeParkOrderDetails('save')
													}
												>
													<Icon.Tick_Icon />
												</Button>
											</>
										)}
									</>
								)}
							</div>
						</Box>
					</Flex>
				</Flex>
			))}
			{inProgress && <Loader></Loader>}
			<SweetAlert
				show={showPopup}
				confirmBtnText="Confirm"
				onConfirm={onSubmit}
				showCancel={true}
				onCancel={closePopup}
				title={'Confirmation Box'}
			>
				{() => (
					<form>
						<hr />
						{modalText}
						<hr />
					</form>
				)}
			</SweetAlert>
		</StyledParkedOrderDetailsEditView>
	);
}

export { ParkedOrderDetailsEditView };
