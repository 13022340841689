import Iget from 'models/Iget';
import queryString from 'query-string';
import React, { ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Breadcrumb, Footer, Header, Loader } from 'ui/components';
import {
	OrderProcessingTime,
	ProductListDescription,
	ProductMainDetails,
	Sidebar,
	SupplierListHeading,
	SupplierListReview,
} from 'ui/patterns';
import httpService from 'utils/http.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import constants from 'utils/constants';
import {
	BreadcrumbHolder,
	ContentHolder,
	MainContent,
	RightContentHolder,
	ProductDetailsHolder,
} from './styles';

function DetailPageOFSupplier(): ReactElement {
	const location = useLocation();
	const parsed = queryString.parse(location.search);
	const [loading, setIsLoading] = useState(false);
	const [supplierDetails, setSupplierDetails] = useState<any>();
	const [supplierInfo, setSupplierInfo] = useState<any>();
	const [processTime, setProcessingTime] = useState<any>();
	const [currentUserReview, setUserReview] = useState<any>([]);
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const EditDcUrl = `products/${parsed.sku}`;
	const params = {
		pages: '1',
	};
	const item: Partial<Iget> = {
		params: params,
		url: EditDcUrl,
		enabled: false,
		key: 'supplierDetails',
	};

	const suppliersInfo = hooks.useQuery(item);
	const url = constants.getAllSupplierReview() + parsed.id
	const obj: Partial<Iget> = {
		params: {},
		url: url,
		enabled: false,
		key: 'suppReviewKey',
		resultFilteringRequired: false,
		headers: { Authorization: localStorage.getItem('customerToken') },
	};
	var qry = hooks.useQuery(obj);
	const parseQueryString = function () {
		if (parsed.sku) {
			setIsLoading(true);
			suppliersInfo.refetch().then(function (data) {
				if (data) {					
					setSupplierDetails(data);
					if (data.extension_attributes?.supplier_details.length > 0) {
						let item = JSON.parse(data?.extension_attributes.supplier_details[0]);
						setSupplierInfo(item);
						setProcessingTime(data?.extension_attributes?.order_process_time);
					}
					getSUpplerEmail(data.extension_attributes?.supplier_id);
				}
				setIsLoading(false);
			});
		}
	};
	const getSUpplerEmail = (companyId) => {
		const url = `experion/supplycompanyaccount/getsupplycompany/${companyId}`;
		httpService.GET({ url: url }).then(function (data) {
			if (data && data.data) {
				window.scroll({ top: 0, left: 0, behavior: 'smooth' });
			}
		});
	};
	useEffect(() => {
		parseQueryString();
	}, [location]);

	const getAllReviewDetails = (data) => {
		setUserReview(data);
	};
	const fetchData = () => {
		qry.refetch().then((data) => {
			setUserReview(JSON.parse(JSON.stringify(data[0])));
		});
	}
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	}
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={()=>onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar}
				toggleSideBar={()=>onclickHambrgBtn()}></Sidebar>
			{loading == true ? <Loader></Loader> : null}
			<MainContent>
				{/* Top Breadcrumb */}
				<BreadcrumbHolder>
					<Breadcrumb
						Heading="Supplier Details"
						HomePage="Home"
						previousPage="Products"
						sku={parsed.sku}
						previousPageSecond={parsed.cat}
						previousPageThird={parsed.pdt}
						currentPage={supplierInfo && supplierInfo.name}
						type={'supplier'}
					></Breadcrumb>
				</BreadcrumbHolder>
				<ContentHolder>
					{/* Right Product Detail Session */}
					<RightContentHolder>
						{/* Product Main Heading */}
						<SupplierListHeading
							currentUserReview={currentUserReview}
							supplierInfo={supplierInfo}
							processTime={processTime}
							supplierId={supplierDetails?.extension_attributes?.supplier_id}
						></SupplierListHeading>

						{/* Product Details */}
						<ProductDetailsHolder>
							<ProductMainDetails
								type='supplierInfo'
								supplierInfo={supplierInfo}
								productDetails={supplierDetails}
							></ProductMainDetails>
						</ProductDetailsHolder>

						{/* Supplier Description */}
						<ProductListDescription
							supplierInfo={supplierInfo}
							currentUserReview={currentUserReview}
							supplierId={supplierDetails?.extension_attributes?.supplier_id}
						></ProductListDescription>

						{/* Supplier Review */}
						<SupplierListReview
							fetchData={fetchData}
							getAllReviewDetails={getAllReviewDetails}
							supplierInfo={supplierInfo}
							supplierId={supplierDetails?.extension_attributes?.supplier_id}
						></SupplierListReview>
					</RightContentHolder>
				</ContentHolder>
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default DetailPageOFSupplier;
