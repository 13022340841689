import React, { ReactElement } from 'react';
import { Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { flexbox, FlexboxProps, layout, LayoutProps, space, SpaceProps } from 'styled-system';
import * as Icon from 'ui/icons';

type StyledContactInfoProps = SpaceProps & LayoutProps & FlexboxProps;

const StyledContactInfo = styled.div<StyledContactInfoProps>`
	${space}
	${layout}
	${flexbox}
	--s1: 1rem;
	--measure: 40em;
	/* display: flex;
	flex-wrap: wrap; */
	background-color: ${({ theme }) => theme.colors.primary};
	border-radius: 10px;

	padding: 10px;

	@media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
		padding: 0px;
	}

	& > * {
		display: flex;
		flex-wrap: wrap;
		margin: calc((var(--s1) / 2) * -1);
	}

	& > * > * {
		flex-grow: 1;
		flex-basis: calc((var(--measure) - (100% - var(--s1))) * 999);
		margin: calc(var(--s1) / 2);
	}

	& > * > :nth-last-child(n + 4),
	& > * > :nth-last-child(n + 4) ~ * {
		flex-basis: 100%;
	}

	& > * > .email-wrap {
		flex-grow: 2;

		& .email {
			word-break: break-all;
			/* max-width: 30ch; */
			/* @media (min-width: 1300px) {
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
				max-width: 60ch;
			} */
		}

		& .pr-icon {
			/* position: relative;
			top: 5px;
			float: left; */
			flex-shrink: 0;
		}
	}

	& > * > :nth-child(1) {
		white-space: nowrap;
	}

	& > * > * {
		/* padding: 10px; */
		display: flex;
		align-items: center;
		font-size: 16px;
		font-family: 'OpenSansMedium';
		color: #f1f3f7;

		@media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
			padding: 15px;
			justify-content: center;
		}
	}

	@media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
		& > * > * + * {
			border-left: solid 1px #878963;
		}
	}

	& .pr-icon {
		margin-right: 10px;
	}
`;

type styledSystemProps = SpaceProps & LayoutProps & FlexboxProps;

type ContactInfoProps = styledSystemProps & {
	email?: string;
	phone?: string;
	cards?: string;
};

function ContactInfo({
	email,
	phone,
	cards,
	...styledSystemProps
}: ContactInfoProps): ReactElement {
	return (
		<StyledContactInfo {...styledSystemProps}>
			<div>
				<Text>
					<Icon.PhoneColored width="20px" /> {phone}
				</Text>
				<div className="email-wrap">
					<Flex minWidth="0" alignItems="center">
						<Icon.MailColored width="20px" />
						<Text className="email">{email}</Text>
					</Flex>
				</div>
				<Text>
					<Icon.CardColored width="20px" /> {cards} Cards
				</Text>
			</div>
		</StyledContactInfo>
	);
}

export { ContactInfo };
