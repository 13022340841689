import React, { ReactNode, useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { LayoutProps, PositionProps, SpaceProps } from 'styled-system';
import useOnClickOutside from 'use-onclickoutside';
import { DropdownItem, DropdownItemGroup, DropdownMenu, ItemLabel } from './styles';
type styledSystemProps = SpaceProps & LayoutProps & PositionProps;

type DropdownProps = styledSystemProps & {
	showOptions?: boolean;
	setShowOptions?: any;
	trigger?: ReactNode;
	options?: Array<option>;
	place?:
		| 'right-top'
		| 'right'
		| 'right-bottom'
		| 'left-top'
		| 'left'
		| 'left-bottom'
		| 'covered-left'
		| 'covered-right';
	handleClick?: (x: option) => void;
	optionLabel?:string;
};

type option = { id: number | string; name : string; icon?: ReactNode };

function Dropdown({
	trigger,
	options,
	showOptions,
	setShowOptions,
	place,
	handleClick = () => '',
	optionLabel,
	...styledSystemProps
}: DropdownProps) {
	const history = useHistory();
	const handleEscape = useCallback(
		(e) => {
			if (e.code === 'Escape' && showOptions) setShowOptions(false);
		},
		[showOptions, setShowOptions]
	);

	const handleOnClickOutside = () => {
		setShowOptions(false);
	};
	// const onSelectCategory = (option) => {
	// 	if (option && option.id) {
	// 		history.push({
	// 			pathname: '/home/product-list',
	// 			search: '?search=' + '&cat=' + option.id,
	// 		});
	// 		setShowOptions(false);
	// 	}
	// };
	const wrapperRef = useRef<HTMLDivElement>(null);
	useOnClickOutside(wrapperRef, handleOnClickOutside);

	useEffect(() => {
		document.addEventListener('keydown', handleEscape);
		return () => {
			document.removeEventListener('keydown', handleEscape);
		};
	}, [handleEscape]);

	return (
		<>
			<DropdownMenu {...styledSystemProps} ref={wrapperRef} className="dropdownMenu">
				{trigger}
				{showOptions ? (
					<>
						{options ? (
							<DropdownItemGroup role="menu" place={place}>
								<SimpleBar style={{ maxHeight: 200 }}>
									{options.filter(function( element ) {return element !== undefined;}).map((option) => (
										<DropdownItem
											key={option?.id}
											onClick={() => {
												handleClick(option);
											}}
										>
											<ItemLabel>{option?.name}</ItemLabel>
										</DropdownItem>
									))}
								</SimpleBar>
							</DropdownItemGroup>
						) : null}
					</>
				) : null}
			</DropdownMenu>
		</>
	);
}

export { Dropdown };
