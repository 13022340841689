import React, { ReactElement, ReactNode, SyntheticEvent, useRef, useState } from 'react';
import styled from 'styled-components';
import useOnClickOutside from 'use-onclickoutside';
// import useClickOutside from 'hooks/useClickoutside';
import DropdownButton from './DropdownButton';
import DropdownMenu from './DropdownMenu';

const StyledDropdown = styled.div`
	position: relative;
	display: inline-block;

	&:focus {
		outline: none;
		background-color: ${({ theme }) => theme.colors.gray[1]};
	}

	/* Column selection dropdown */
	&.dd-col-selection {
		summary {
			background: none;
			border: none;
			padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[1]};
			line-height: 1.2;
			box-shadow: none;
			display: flex;
			align-items: center;

			&:focus,
			&:active {
				outline: none;
				background: ${({ theme }) => theme.colors.bg.grayDark};
				color: #fff;
			}
		}
	}

	/* List item options dropdown */
	&.dd-listitem-options {
		summary {
			padding: 0;

			button {
				padding: ${({ theme }) => theme.space[1]};
			}
		}
	}
`;

export type DropdownProps = {
	trigger?: string | ReactNode;
	menuList?: Array<item>;
	menuContent?: ReactNode;
	className?: any;
	autoclose?: boolean;
	position?: 'right-top' | 'right' | 'left-top' | 'left';
	ref?: React.RefObject<HTMLDivElement>;
	onClick?: (e: any) => void;
};

type item = {
	id?: number;
	value: string;
	action?: () => void;
	icon?: ReactNode;
	groupEnd?: boolean;
};

function Dropdown({
	trigger,
	menuList,
	menuContent,
	className,
	autoclose = true,
	position,
	ref,
	onClick,
}: DropdownProps): ReactElement {
	const [open, setOpen] = useState(false);
	const handleOnClickOutside = () => {
		setOpen(false);
	};
	const wrapperRef = useRef<HTMLDivElement>(null);
	useOnClickOutside(wrapperRef, handleOnClickOutside);
	const handleToggle = (e: SyntheticEvent<HTMLDivElement>) => {
		e.stopPropagation();
		setOpen(autoclose === true ? !open : true);
	};

	return (
		<StyledDropdown ref={wrapperRef} onClick={handleToggle} className={className}>
			<DropdownButton>{trigger}</DropdownButton>
			{open && (
				<DropdownMenu position={position}>
					{menuList ? (
						<ul data-testid="header-profile-list" className="dropdownMenuList">
							{menuList.map((item, index) => (
								<li key={index}>
									<button
										data-testid={`button${item.id}`}
										type="button"
										onClick={() => {
											setOpen(false);
											item.action && item.action();
										}}
									>
										{item.icon ? item.icon : null}
										<span className="pr-button-label">{item.value}</span>
									</button>
									{item.groupEnd ? <div className="dd-divider"></div> : null}
								</li>
							))}
						</ul>
					) : (
						null || menuContent
					)}
				</DropdownMenu>
			)}
		</StyledDropdown>
	);
}

export { DropdownButton, DropdownMenu, StyledDropdown };
