import React, { ReactElement, useState } from 'react';
import { Text, Box, Flex } from 'rebass/styled-components';
import 'react-tippy/dist/tippy.css';
import { Button } from 'ui/components';
import icons, * as Icon from 'ui/icons';
import styled from 'styled-components';
import { Modal } from 'ui/patterns';
import { default as helper, default as helperFunctions } from 'utils/helper.functions';
import NewFavoritesModal from './newFavorites';
import utilityFunctions from 'utils/utility.functions';
import { venueUserPermissionList } from 'models/enum-constants/common.constants';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import SweetAlert from 'react-bootstrap-sweetalert';

const StyledPendingOrderDetailsEditView = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	column-gap: 20px;
	row-gap: 20px;
	margin-bottom: 16px;
	.order-details__wrap {
		flex-direction: row;
		background: ${({ theme }) => theme.colors.bg.white};
		border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
		box-sizing: border-box;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 16px;
		overflow: hidden;
		transition: box-shadow 100ms ease-in, transform 100ms ease-in;
		align-items: flex-start;
		height: 100%;
		display: flex;
		span {
			max-width: 117px;
			flex: 0 0 117px;
			height: 100%;
			background-repeat: no-repeat;
			background-size: contain;
			@media (max-width: 890px) {
				max-width: 126px;
				flex: 0 0 126px;
				background-position: center center;
			}
			@media (max-width: 360px) {
				max-width: 80px;
				flex: 0 0 80px;
			}
		}
		section {
			display: grid;
			grid-template-columns: repeat(1, 1fr) !important;
			align-items: flex-start;
			height: 100%;
			padding: 25px;

			@media (max-width: 1268px) {
				padding: 20px;
				grid-template-columns: repeat(1, 1fr) !important;
			}
			@media (max-width: 890px) {
				padding: 20px;
				row-gap: 0;
				grid-template-columns: repeat(1, 1fr) !important;
				label {
					margin: -6px 0 10px 0;
					line-height: normal;
				}
			}
			.statusRejected,
			.statusApproved {
				color: ${({ theme }) => theme.baseColors.white};
				padding: 5px 15px;
				border-radius: 50px;
				float: left;
				font-size: 13px !important;
				font-family: 'OpenSansSemiBold' !important;
			}
			.statusRejected {
				background: ${({ theme }) => theme.colors.state.error};
			}
			.statusApproved {
				background: ${({ theme }) => theme.colors.state.success};
			}
			@media (max-width: 480px) {
				padding: 15px;
			}

			object {
				justify-self: center;
				.statusApproved,
				.statusRejected {
					width: auto;
				}
				@media (max-width: 1152px) {
					justify-self: start;
				}
			}
			main {
				@media (max-width: 1152px) {
					div {
						justify-content: flex-start !important;
					}
				}
			}
			.pending-order-item {
				display: grid;
				grid-template-columns: repeat(7, 1fr);
				row-gap: 20px;
				column-gap: 10px;
				div {
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					display: block;
				}
				.order-col {
					flex: 0 1 270px;
					min-width: 270px;
					justify-self: auto;
					@media (max-width: 1380px) {
						flex: 0 0 100%;
						min-width: 100%;
					}
				}
				& + .pending-order-item {
					margin-top: 20px;
				}
				@media (max-width: 1800px) {
					grid-template-columns: repeat(5, 1fr);
				}
				@media (max-width: 1380px) {
					grid-template-columns: repeat(4, 1fr);
				}
				@media (max-width: 767px) {
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					.order-col {
						flex: 0 1 auto;
					}
				}
				@media (max-width: 340px) {
					grid-template-columns: repeat(4, 1fr);
				}
			}
			.pending-order-btn-block {
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				@media (max-width: 767px) {
					align-items: flex-start;
				}
				label {
					margin: 0;
				}
				.btn-block {
					display: flex;
					align-items: center;
					justify-content: flex-end;
					flex-wrap: wrap;
					@media (max-width: 890px) {
						justify-content: flex-start;
						flex-wrap: wrap;
						button {
							margin-top: 5px;
						}
					}
					@media (max-width: 640px) {
						margin-top: 20px;
					}
					span {
						height: auto;
						flex: 0 0 auto;
						max-width: auto;
						min-width: auto;
					}
					button {
						height: 50px;
						margin: 0;
						display: flex;
						margin-left: 10px;
						&.success,
						&.danger {
							margin: 0;
							max-width: 50px;
							padding: 0;
							flex: 0 0 50px;
							width: 50px;
							svg {
								margin: 0 !important;
							}
						}
						@media (max-width: 767px) {
							margin-right: 10px;
							margin-left: 0;
						}

						span {
							svg {
								height: 14px;
								path {
									fill: ${({ theme }) => theme.colors.bg.white};
								}
							}
						}
					}
					icon {
						display: inline-block;
					}
					div {
						word-break: break-word;
					}
				}
			}
			.totalProducts,
			.totalSuppliers {
				max-width: 110px !important;
				min-width: 110px !important;
				flex: 0 0 110px !important;
				text-align: right;
				white-space: nowrap;
			}
			.totalProducts {
				max-width: 100px !important;
				min-width: 100px !important;
				flex: 0 0 100px !important;
			}
		}
		&.selected {
			background: #f0e9da;
		}
	}
`;

function PendingOrderDetailsEditView({
	pendingOrderAction,
	status,
	data,
	supplierCount,
	orderStatus,
}): ReactElement {
	const [showNewFavoritesModal, setShowNewFavoritesModal] = useState(false);
	const [venueUserId] = useState<any>(localStorage.getItem('userId'));
	const [modalText, setmodalText] = useState('');
	const [showPopup, setShowPopup] = useState(false);
	const [updateData, setUpdateData] = useState<any>();

	function pendingOrderActionConfirm(e, choice) {
		e.stopPropagation();
		if (choice === 1 || choice === 2) {
			setUpdateData(choice);
			const msg =
				choice == 1
					? 'Are you sure you want to approve the order?'
					: 'Are you sure you want to reject the order?';
			showConfirmationModal(msg);
		} else {
			pendingOrderAction(choice);
		}
	}

	function showConfirmationModal(text) {
		setmodalText(text);
		setShowPopup(!showPopup);
	}

	const onSubmit = () => {
		setShowPopup(!showPopup);
		pendingOrderAction(updateData);
	};

	async function closePopup() {
		await setShowPopup(!showPopup);
	}

	return (
		<StyledPendingOrderDetailsEditView>
			<Flex className="order-details__wrap selected" bg="white" sx={{ alignItems: 'center' }}>
				<Flex as="section" flexWrap="wrap" width="100%" maxWidth={['100%']}>
					<Box className="pending-order-item">
						<Box className="order-col">
							<Text mb={1} fontSize={16} color="text.black">
								Order ID
							</Text>
							<Text as="label" fontSize={18} fontFamily="OpenSansBold" color="text.black">
								{'#' + data.order_group_increment_id}
							</Text>
						</Box>
						<Box className="order-col">
							<Text mb={1} fontSize={16} color="text.black">
								Created On
							</Text>
							<Text as="label" fontSize={18} fontFamily="OpenSansBold" color="text.black">
								{helper.formatDate(data.created_at)}
							</Text>
						</Box>
						<Box className="order-col">
							<Text mb={1} fontSize={16} color="text.black">
								Created User
							</Text>
							<Text as="label" fontSize={18} fontFamily="OpenSansBold" color="text.black">
								{data.name}
							</Text>
						</Box>

						<Box className="order-col statusCol">
							<Text mb={1} fontSize={16} color="text.black">
								Status
							</Text>
							{/* Add class Approved for ".statusApproved" Reject for ".statusRejected" */}
							<Text
								as="label"
								fontSize={18}
								fontFamily="OpenSansBold"
								color="text.black"
								className={`${
									utilityFunctions.isGranted(venueUserPermissionList.OrderApproval)
										? status == 'Approved'
											? 'statusApproved'
											: status == 'Rejected'
											? 'statusRejected'
											: ''
										: ''
								}`}
							>
								{status}
							</Text>
						</Box>
						<Box className="order-col totalProducts">
							<Text mb={1} fontSize={16} color="text.black">
								Total Product
							</Text>
							<Text as="label" fontSize={18} fontFamily="OpenSansBold" color="text.black">
								{data.no_of_items}
							</Text>
						</Box>
						<Box className="order-col totalSuppliers">
							<Text mb={1} fontSize={16} color="text.black">
								Total Suppliers
							</Text>
							<Text as="label" fontSize={18} fontFamily="OpenSansBold" color="text.black">
								{supplierCount}
							</Text>
						</Box>
						<Box className="order-col">
							<Text mb={1} fontSize={16} color="text.black">
								Amount
							</Text>
							<Text as="label" fontSize={18} fontFamily="OpenSansBold" color="text.black">
								{helper.formatAmount(data.cart_total)}
							</Text>
						</Box>
					</Box>
					<Box className="pending-order-btn-block">
						<Box as="main">
							{/* Multiple button condition */}
							<div className="btn-block">
								{orderStatus == 'pending_approval' &&
									utilityFunctions.isGranted(venueUserPermissionList.OrderApproval) && (
										<>
											<Tooltip
												arrow
												title="Reject"
												animation="perspective"
												position="top"
												trigger="mouseenter"
												style={{ marginRight: '10px' }}
											>
												<Button
													variant="danger"
													className="danger"
													onClick={(e) => {
														pendingOrderActionConfirm(e, 2);
													}}
												>
													<icons.Close_ICon />
												</Button>
											</Tooltip>
											<Tooltip
												arrow
												title="Approve"
												animation="perspective"
												position="top"
												trigger="mouseenter"
											>
												<Button
													variant="success"
													className="success"
													onClick={(e) => {
														pendingOrderActionConfirm(e, 1);
													}}
												>
													<icons.Tick />
												</Button>
											</Tooltip>
										</>
									)}
								{orderStatus == 'rejected' &&
									!utilityFunctions.isGranted(venueUserPermissionList.OrderApproval) &&
									parseInt(venueUserId) === parseInt(data.venue_user_id) &&
									!data.is_moved_to_cart && (
										<Button
											onClick={(e) => {
												pendingOrderActionConfirm(e, 3);
											}}
										>
											Move to Cart
										</Button>
									)}
							</div>
						</Box>
					</Box>
				</Flex>
			</Flex>
			<SweetAlert
				show={showPopup}
				confirmBtnText="Confirm"
				onConfirm={onSubmit}
				showCancel={true}
				onCancel={closePopup}
				title={'Confirmation Box'}
			>
				{() => <form>{modalText}</form>}
			</SweetAlert>
		</StyledPendingOrderDetailsEditView>
	);
}

export { PendingOrderDetailsEditView };
