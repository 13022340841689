import React, { ReactElement, useState } from 'react';
import 'react-tippy/dist/tippy.css';
import * as Icon from 'ui/icons';
import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { Tooltip } from 'react-tippy';
import { Button } from 'ui/components/button';
import api from 'utils/requestCtx/api.service';
import env from 'utils/constants';
import helperFunctions from 'utils/helper.functions';
import Ipost from 'models/Ipost';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
const StyledFavoriteOrdersDetailsListView = styled.div`
	h1 {
		font-weight: 600;
		font-size: 18px;
		text-transform: uppercase;
		color: #1f261f;
		line-height: 23px;
		margin-bottom: 43px;
	}

	.action-order-again {
		justify-content: space-between;
		align-items: center;
		margin-top: 35px;

		.action-order-title {
			display: flex;
			flex-direction: column;
		}
		button {
			text-transform: uppercase;
			& + button {
				margin-left: 36px;
			}
		}
		.required {
			font-weight: normal;
			font-size: 16px;
			line-height: 21px;
			color: #eb5757;
			font-style: italic;
		}
		@media (max-width: 640px) {
			flex-direction: column;
			button {
				margin: 0 !important;
				flex: 1;
				span {
					white-space: nowrap;
				}
				+ button{
					margin-left: 10px!important;
				}
			}
		}
	}
	.grid-data {
		&.scroll-enable {
			max-height: 500px;
			overflow-y: auto;
			margin-right: -15px;
		}
		.grid-header {
			align-items: center;
			> div {
				padding-left: 20px;
				font-weight: bold;
				font-size: 14px;
				line-height: 18px;
				text-transform: uppercase;
				color: #242b24;
				min-height: 50px;
				flex: 0 1 100%;
				align-items: center;
				display: flex;
				position: relative;
				white-space: nowrap;
			}
		}
		.grid-body {
			background: #ffffff;
			border: 1px solid #edebe6;
			box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07);
			border-radius: 8px;
			transition: box-shadow 100ms ease-in, transform 100ms ease-in;
			cursor: pointer;
			position: relative;
			&:hover {
				box-shadow: 0 20px 20px #eee;
				border: 1px solid rgba(135, 137, 99, 1);
			}
			.onModal {
				position: relative;
				right: -22px;
			}
			.pr-icon {
				position: relative;
				svg {
					position: relative;
					width: 19px;
					top: -1px;
				}
			}
			& + .grid-body {
				margin-top: 16px;
			}
			&.out-of-class {
				border: 1px solid #eb5757;
				text-decoration-line: line-through;
				color: rgba(36, 43, 36, 0.33);
				&.line-through {
					text-decoration-line: line-through;
				}
				&:hover {
					box-shadow: 0 20px 20px #eee;
					border: 1px solid #eb5757;
				}
			}
		}
		.list {
			width: 100%;
			display: flex;
			min-height: 70px;
			align-items: center;
			.total-price {
				align-items: center;
			}
			> div {
				padding-left: 20px;
				flex: 0 1 100%;
				display: flex;
				position: relative;
				&.title {
					font-weight: bold;
					font-size: 15px;
					line-height: 20px;
				}
			}
		}
	}
`;

const gridDataHead = [
	{ label: 'Product name ' },
	{ label: 'Price' },
	{ label: 'New Price' },
	{ label: 'Quantity' },
	{ label: 'Total' },
];

function OrderGridSupplierView({
	data,
	setShowModal,
	productsToMoveArr,
	showModal,
	disableBtnMove,
	fetchTotal,
	utilityFunctions,
	setIsLoading,
}): ReactElement {
	async function addItemsToCart(productsToMoveArr) {
		setShowModal(!showModal);
		setIsLoading(true);
		let id: any;
		let CUSTOMER_TOKEN = localStorage.getItem('customerToken');
		id = await utilityFunctions.getCartId(CUSTOMER_TOKEN);
		if (id !== undefined) {
			if (productsToMoveArr.length > 0) {
				let payload = {
					products: {
						quote_id: parseInt(id),
						items: productsToMoveArr,
						reorder: 1,
					},
				};
				const obj: Partial<Ipost> = {
					data: payload,
					key: 'recentToCart',
					url: env.multiCartURL(),
					message: 'Your cart has been updated!',
					headers: { Authorization: localStorage.getItem('customerToken') },
				};

				try {
					await api
						.post(obj)
						.then(function (data) {
							if (data == true) {
								setIsLoading(false);
								fetchTotal();
							} else {
								setIsLoading(false);
							}
						})
						.catch(function (data) {
							setIsLoading(false);
						});
				} catch (e) {
					setIsLoading(false);
				}
			}
		} else {
			setIsLoading(false);
		}
	}

	const warning = (msg) => {
		const message = msg.map((x) => <div className="out-of-stock">{x}</div>);

		return message;
	};

	return (
		<StyledFavoriteOrdersDetailsListView>
			<Box as="h1">Order Again</Box>
			<SimpleBar>
			<Box as="article" className="grid-data">
				<Flex className="grid-header">
					{gridDataHead
						? gridDataHead.map((item) => {
								return (
									<Box>
										<Text>{item.label}</Text>
									</Box>
								);
						  })
						: null}
				</Flex>
			</Box>
			<Box as="article" className="grid-data scroll-enable">
				{data
					? data.map((item) => {
							return (
								// add class .out-of-class below in grid-body if it is out of stock
								<Flex className={item.sensWarning == true ? 'grid-body out-of-class' : 'grid-body'}>
									<Box className="list">
										<Text className="title" as="div">
											<Text as="div" className="truncate">
												{item.product_name}
											</Text>
										</Text>
										<Text>{helperFunctions.formatAmount(item.old_price)}</Text>
										<Text>{helperFunctions.formatAmount(item.new_price)}</Text>
										<Text>{parseFloat(item.qty_ordered)}</Text>
										<Text className="total-price">
											<Box>{helperFunctions.formatAmount(item.current_total)}</Box>
											{item.sensWarning == true || item.nonSensWarning == true ? (
												<Tooltip
													className="onModal"
													title="warning"
													animation="perspective"
													arrow
													position="top"
													html={warning(item.message)}
												>
													<Icon.caution title="addIcon" />
												</Tooltip>
											) : null}
										</Text>
									</Box>
								</Flex>
							);
					  })
					: null}
			</Box>
			</SimpleBar>
			<Flex className="action-order-again">
				<Flex className="action-order-title">
					{disableBtnMove == false ? (
						<Box className="required">*Quantities can be changed from cart</Box>
					) : null}
					{disableBtnMove == false ? (
						<Box className="required">*Available Products will be moved to cart</Box>
					) : null}
				</Flex>
				<Flex>
					<Button
						className="action-done"
						flex={[1, 1, 'initial']}
						variant="secondary"
						onClick={() => setShowModal(!showModal)}
					>
						Cancel
					</Button>
					<Button
						className="action-done"
						flex={[1, 1, 'initial']}
						variant="primary"
						disabled={disableBtnMove}
						onClick={(e) => {
							e.stopPropagation();
							addItemsToCart(productsToMoveArr);
						}}
					>
						Move to Cart
					</Button>
				</Flex>
			</Flex>
		</StyledFavoriteOrdersDetailsListView>
	);
}

export { OrderGridSupplierView };
