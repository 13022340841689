import moment from 'moment';
import React, { ReactElement } from 'react';
import 'react-tippy/dist/tippy.css';
import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import helper from 'utils/helper.functions';

const StyledCartListView = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	column-gap: 20px;
	row-gap: 20px;
	margin-bottom: 18px;
	@media (max-width: 860px) {
		margin-top: 18px;
	}
`;
const DeliveryConfirmationsOrderInfoHolder = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	background: ${({ theme }) => theme.colors.bg.white};
	border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
	box-sizing: border-box;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 16px;
	overflow: hidden;
	transition: box-shadow 100ms ease-in, transform 100ms ease-in;
	align-items: flex-start;
	@media (max-width: 860px) {
		margin-top: 15px !important;
	}
	@media (max-width: 768px) {
		flex-direction: column;
	}
	@media (max-width: 640px) {
		margin-top: 0px !important;
	}
	section {
		height: 100%;
		padding: 25px;
		position: relative;
		display: flex;
		flex-direction: row;
		@media (max-width: 768px) {
			padding: 20px;
		}
		@media (max-width: 480px) {
			padding: 15px;
		}
	}
	slot {
		grid-template-columns: repeat(6, 1fr);
		align-items: flex-start;
		flex-direction: column;
		justify-content: space-between;
		position: relative;
		display: grid;
		width: 100%;
		column-gap: 20px;
		row-gap: 20px;
		@media (max-width: 1200px) {
			grid-template-columns: repeat(4, 1fr);
		}
		@media (max-width: 1024px) {
			grid-template-columns: repeat(3, 1fr);
		}
		@media (max-width: 740px) {
			grid-template-columns: repeat(2, 1fr);
		}
		@media (max-width: 340px) {
			grid-template-columns: repeat(1, 1fr);
		}
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin-bottom: 0;
			font-weight: normal;
			color: ${({ theme }) => theme.colors.text.black};
			strong {
				font-family: 'OpenSansBold';
				font-size: 20px;
				line-height: 26px;
				float: left;
				width: 100%;
				word-break: break-word;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				display: block;
			}
		}
		h5 {
			width: 95px;
			text-align: right;
		}
		@media (max-width: 480px) {
			h3,
			h6 {
				grid-area: auto / 3 / auto / 1;
			}
			h6 {
				margin-bottom: -5px;
			}
		}
		@media (max-width: 340px) {
			h3,
			h6,
			h1 {
				grid-area: auto / 3 / auto / 1;
			}
		}
	}
`;

// type DeliveryConfirmationsOrderInfoProps = {
// 	deliveryConfirmationsOrderInfo: Array<list>;
// };
// type list = {
// 	id?: string | number;
// 	orderId?: string;
// 	createdOn?: string;
// 	deliveredOn?: string;
// };

function DeliveryConfirmationsOrderInfo({ deliveryConfirmationsOrderInfo }): ReactElement {
	let deliverySlot = deliveryConfirmationsOrderInfo?.extension_attributes?.delivery_slot
		? deliveryConfirmationsOrderInfo?.extension_attributes?.delivery_slot
		: '';
	deliverySlot = deliverySlot.split(' ');
	let deliverySlotDay = null as any;
	let deliverySlotDate = null as any;
	if (deliverySlot.length === 2) {
		deliverySlotDate = moment(deliverySlot[1], 'DD-MM-YYYY');
		deliverySlotDate = helper.formatDate(deliverySlotDate.toDate());
		deliverySlotDay = deliverySlot[0];
	}
	return (
		<StyledCartListView>
			{deliveryConfirmationsOrderInfo ? (
				<DeliveryConfirmationsOrderInfoHolder key={Math.random()}>
					<Flex as="section" flexDirection="column" width="100%" maxWidth={['100%']}>
						<Box as="slot">
							<Text as="h1" fontSize={17} color="text.black">
								Order ID
								<strong>
									{' '}
									{deliveryConfirmationsOrderInfo?.extension_attributes?.order_group_increment_id}
								</strong>
							</Text>
							<Text as="h2" fontSize={17} color="text.black">
								Created On
								<strong> {helper.formatDate(deliveryConfirmationsOrderInfo.created_at)}</strong>
							</Text>
							<Text as="h3" fontSize={17} color="text.black">
								Supplier Name
								<strong>
									{' '}
									{deliveryConfirmationsOrderInfo.extension_attributes?.supplier_name}
								</strong>
							</Text>
							<Text as="h4" fontSize={17} color="text.black">
								Status
								<strong>
									{' '}
									{deliveryConfirmationsOrderInfo.status?.charAt(0).toUpperCase() +
										deliveryConfirmationsOrderInfo.status?.slice(1)}
								</strong>
							</Text>
							<Text as="h5" fontSize={17} color="text.black">
								No of Items<strong> {deliveryConfirmationsOrderInfo.total_item_count}</strong>
							</Text>
							<Text as="h6" fontSize={17} color="text.black">
								Delivery Date <strong>{helper.formatDate(deliveryConfirmationsOrderInfo.updated_at)}</strong>
							</Text>
						</Box>
					</Flex>
				</DeliveryConfirmationsOrderInfoHolder>
			) : null}
		</StyledCartListView>
	);
}

export { DeliveryConfirmationsOrderInfo };
