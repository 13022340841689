import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

const StyledSeparator = styled.div`
	display: flex;
	padding-left: 0;
	list-style: none;
	margin: 42px 0;
	justify-content: center;
	position: relative;
	width: 70%;
	line-height: normal;
	height: 1px;
	&::before {
		content: '';
		position: absolute;
		border-bottom: 2px solid transparent;
		border-image: linear-gradient(0.25turn, #F09951, #DC143C, rgba(56,2,155,0));
		border-image-slice: 1;
		width: 100%;
	}
`;

const Title = styled.p`
	font-size: 16px;
	font-weight: 500;
	position: absolute;
	top: -12px;
	padding: 0 6px;
	position: relative;
	color: ${({ theme }) => theme.baseColors.primary};
	background: ${({ theme }) => theme.colors.bg.orangeLighter};
	height: 20px;
  font-family: 'OpenSansBold';
`;

interface SeparatorProps {
	children: ReactNode;
}

function Separator({ children }: SeparatorProps): ReactElement {
	return <StyledSeparator>{<Title>{children || 'Or'}</Title>}</StyledSeparator>;
}

export { Separator };
