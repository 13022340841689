import { userTypes } from 'models/enum-constants/common.constants';
import Ipost from 'models/Ipost';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'ui/components';
import logo from 'ui/images/logo_exp.png';
import constants from 'utils/constants';
import httpService from 'utils/http.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import notify from 'utils/toast.service';
import utilityFunctions from 'utils/utility.functions';
import '../../ui/styles/login.css';
import styled from 'styled-components';
import { Flex, Text, Box } from 'rebass/styled-components';
import { Separator } from 'ui/components';
import * as Icon from 'ui/icons';

const LoginWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	position: relative;
	&::before {
		content: '';
		width: 100vw;
		height: 100vh;
		position: absolute;
		top: 0;
		background: ${({ theme }) => theme.newColors.white};
		backdrop-filter: blur(54px);
		position: fixed;
	}
	h1 {
		float: left;
		width: 100%;
		text-align: left;
		color: ${({ theme }) => theme.baseColors.primary};
		font-weight: 600;
		font-size: 14px;
	}
`;
const Panel = styled.div`
	min-width: 548px;
	max-width: 548px;
	border-radius: 6px;
	background: ${({ theme }) => theme.colors.bg.orangeLighter};
	border: 0.5px solid #000;
	position: relative;
	padding: 50px 46px 46px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	@media (max-height: 900px) {
		padding: 30px;
		margin: auto;
		/* margin-top: 30px; */
	}

	@media (max-width: 767px) {
		max-width: 100%;
		min-width: 300px;
		width: 100%;
		border-radius: 0;
		margin-top: 0;
	}
	@media (max-width: 640px) {
		padding: 23px;
		position: fixed;
		overflow-y: auto;
		height: 100vh;
		justify-content: flex-start;
	}
	img {
		width: 150px;
	}
	form {
		width: 100%;
		.loginFields {
			position: relative;
			.pr-icon {
				position: absolute;
				top: 25px;
				right: 27px;
				svg {
					path {
						fill: ${({ theme }) => theme.baseColors.primary};
					}
				}
			}
			.PasswordIcon {
				top: 3px;
			}
		}
		input {
			padding: 10px 22px 10px;
			font-family: 'OpenSansSemiBold';
			font-size: 16px;
			border: 0;
			border: 1px solid ${({ theme }) => theme.colors.bg.orangeLighterGrayish};
			transition: border-color 0.2s ease;
			display: block;
			width: 100%;
			background: ${({ theme }) => theme.baseColors.white};
			color: ${({ theme }) => theme.colors.text.body};
			height: 73px;
			border-radius: 9px;
			padding-right: 60px;

			&:focus {
				outline: none;
				border-color: ${({ theme }) => theme.baseColors.primary};
			}
			+ input {
				margin-top: 30px;
			}
		}
		.forgot {
			width: 100%;
			text-align: right;
			margin: -16px 0 30px;
			float: left;
			a {
				font-size: 16px;
				color: ${({ theme }) => theme.colors.text.black};
				&:hover {
					color: ${({ theme }) => theme.baseColors.primary};
				}
			}
		}
		input:-webkit-autofill,
		input:-webkit-autofill:hover,
		input:-webkit-autofill:focus,
		input:-webkit-autofill:active {
			-webkit-box-shadow: 0 0 0 50px white inset !important;
			font-size: 16px !important;
			font-family: 'OpenSansSemiBold';
		}
		.loginButton {
			height: 73px;
			font-size: 18px !important;
			font-weight: normal !important;
			font-family: 'OpenSansSemiBold' !important;
		}
	}
	.password_block {
		button {
			position: absolute;
			top: 22px !important;
			right: 21px !important;
			cursor: pointer;
			background: transparent;
			border: none;
			padding: 0;
			box-shadow: none;
			outline: none;
			.pr-icon {
				position: relative;
				right: 0 !important;
				top: 0 !important;
				position: relative !important;
				&:hover {
					opacity: 0.5;
				}
			}
			&.iconActive {
				top: 27px !important;
			}
		}
	}
`;

const LoginComponent = () => {
	//AppConstants on login Initialize
	const history = useHistory();
	const [details, setDetails] = useState({ userName: '', password: '' }); //set state for login details
	const [errors, setError] = useState({ error: '' }); //set state for error validation
	// const [customerToken, setCustomerToken] = useState({ error: '' });
	const userKey = 'loginKey';
	const [mutatePost] = hooks.useMutation(userKey);
	const [isWatchIconActive, setWatchIconActive] = useState(false);

	let activeToken: any;
	let userType: any;

	function getConfigData() {
		const urlAPI = constants.baseURL() + 'store/storeConfigs';
		httpService.GET({ url: urlAPI }).then(function (data) {
			if (data) {
				localStorage.setItem('appconfig', JSON.stringify(data.data[0]));
			}
		});
	}

	async function getCustomerToken(customerToken, is_mobile_verified) {
		const password_reset = localStorage.getItem('is_password_set');
		// if (is_mobile_verified == '0') {
		// 	createOtp();
		// } else if (is_mobile_verified == '1' && password_reset == '0') {
		// 	ResetPassword();
		// } else {
		let id: any;
		id = await utilityFunctions.getCartId(customerToken);
		activeToken = localStorage.getItem('customerToken');
		userType = localStorage.getItem('userType');
		if (activeToken !== null && activeToken !== '') {
			if (userType == userTypes.venueUser || userType == userTypes.venueAdmin) {
				// if (is_mobile_verified == '1' && password_reset == '1') {
				history.push('/home/dashboard');
				// }
			} else if (userType == userTypes.supplierUser || userType == userTypes.supplierAdmin) {
				// if (is_mobile_verified == '1' && password_reset == '1') {
				history.push('/admin/seller-dashboard');
				// }
			}
		}
		// }
	}
	async function ResetPassword() {
		if (details) {
			const obj: Partial<Ipost> = {
				data: { username: details.userName },
				params: {},
				url: 'integration/cognito/forgot-password/code',
				message: 'Verification Code has been send successfully to your email',
			};
			try {
				await mutatePost(obj).then((data) => {
					if (data == true) {
						history.push({
							pathname: 'verification',
							search: '?uid=' + details.userName,
						});
					} else {
						// setError({ error: 'Invalid Username' })
					}
				});
			} catch (e) { }
		}
	}
	async function createOtp() {
		const obj: Partial<Ipost> = {
			url: constants.sendVerificationCode(),
			message: `A six-digit verification code has been sent to ${details.userName}`,
			headers: { Authorization: localStorage.getItem('customerToken') },
		};
		try {
			await mutatePost(obj).then((data) => {
				if (data !== undefined) {
					history.push(`mobile-verification?id=${details.userName}`);
					//history.push('/login');
				}
			});
		} catch (e) { }
	}

	const getCountry = async () => {
		const stateUrl = `directory/countries`;
		await httpService.GET({ url: stateUrl }).then((data) => {
			if (data.data) {
				localStorage.setItem('Countries', JSON.stringify(data.data));
			} else {
				setError({ ...errors });
			}
		});
	};

	const getAddressId = async () => {
		const userType = localStorage.getItem('userType');
		const userId = localStorage.getItem('userId');
		const adminId = localStorage.getItem('hgAdminId');
		const currentUserId = userType == userTypes.venueAdmin ? adminId : userId;
		const addressUrl = `customers/${currentUserId}/shippingAddress`;
		await httpService.GET({ url: addressUrl }).then((response) => {
			if (response.data) {
				localStorage.setItem('defaultAddressId', response.data.id);
			} else {
				setError({ ...errors });
			}
		});
	}

	// Api call to pass the credentials entered and to validate it
	async function validateLogin() {
		if (details) {
			getConfigData();
			const obj: Partial<Ipost> = {
				data: { username: details.userName, password: details.password },
				params: {},
				url: 'experion/customer/token',
				message: ' ',
			};
			try {
				await mutatePost(obj).then((data) => {
					if (data !== undefined) {
						localStorage.setItem('is_password_set', data[0].is_password_set);
						localStorage.setItem('customerToken', data[0].token);
						localStorage.setItem('userName', data[0].username);
						localStorage.setItem('userId', data[0].user_id);
						// if (data[0].is_password_set == '1' && data[0].is_mobile_verified == '1') {
						notify('Successfully Logged In').success();
						getCountry();

						// localStorage.setItem('token', data);
						localStorage.setItem('email', data[0].email);
						localStorage.setItem('phone_no', data[0].phone_no);

						if (data[0].user_type == 'venue_user') {
							localStorage.setItem('userType', userTypes.venueUser);
							localStorage.setItem('hospitalityId', data[0].company_id);
						} else if (data[0].user_type == 'hg_admin') {
							localStorage.setItem('userType', userTypes.venueAdmin);
							localStorage.setItem('hgAdminId', data[0].admin_id);
							localStorage.setItem('hospitalityId', data[0].company_id);
						} else if (data[0].user_type == 'dc_user') {
							localStorage.setItem('userType', userTypes.supplierUser);
							localStorage.setItem('storeId', data[0].company_id);
							localStorage.setItem('supplierId', data[0].company_id);
						} else {
							localStorage.setItem('userType', userTypes.supplierAdmin);
							localStorage.setItem('SuAdminId', data[0].admin_id);
							localStorage.setItem('storeId', data[0].company_id);
							localStorage.setItem('supplierId', data[0].company_id);
						}
						// }
						getAddressId();
						getCustomerToken(data[0].token, data[0].is_mobile_verified);
					}
				});
			} catch (e) { }
		}
	}
	return (
		<LoginWrapper>
			<Panel>
				<img src={logo} alt="Logo" />
				<Separator>Login</Separator>
				<form>
					<Box className="loginFields" mb={24}>
						<Icon.MobileIcon />

						<input
							type="text"
							title="Enter Email Address"
							placeholder="Enter Email Address"
							autoComplete="off"
							onChange={(event) => setDetails({ ...details, userName: event.target.value })}
							value={details.userName}
						/>
					</Box>
					<Box className="loginFields password_block" mb={24}>
						{isWatchIconActive === false ? (
							<Button onClick={() => setWatchIconActive(true)}>
								<Icon.watchListIcon />
							</Button>
						) : (
							<Button onClick={() => setWatchIconActive(false)} className="iconActive">
								<Icon.watchListIconActive />
							</Button>
						)}
						<input
							type={isWatchIconActive === true ? 'text' : 'password'}
							title="Enter Password"
							placeholder="Enter Password"
							onChange={(event) => setDetails({ ...details, password: event.target.value })}
							value={details.password}
							autoComplete="off"
						/>
					</Box>
					<span style={{ color: 'red', fontSize: '14px' }}>{errors.error}</span>
					<Box className="forgot">
						<a href="/forgot-password">Forgot Password</a>
					</Box>
					<Button className="loginButton" fullWidth type="button" onClick={validateLogin}>
						Login
					</Button>
					{/* <a className="forgot" href="#">
						Forgot Username?
					</a> */}
				</form>
			</Panel>
		</LoginWrapper>
	);
};
export default LoginComponent;
