import moment from 'moment';
import pageConstants from "utils/pageConstants";

function toPascalCase(string) {
    string = string && string.trim();
    if (string) {
        const selectedString = string.replace(/\s+/g,' ').trim();
        const words = selectedString.split(" ");
        for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        }
        return words.join(" ");
    }
    return "";
}
function GroupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    let array = Array.from(map, ([key, value]) => ({ key, value }));
    return array;
}
function returnPage(pathname) {
    switch (pathname) {
        case '/admin/venues-list':
            return 'Venue Management'
            break
        case '/admin/users-list':
            return 'Venue User Management'
            break
        case '/admin/user-role-management':
            return 'Role Management'
            break
    }
}

const formatDate = (value) => {
    try{
        var appconfig = JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('appconfig'))));
        var dateFormat = appconfig.extension_attributes.date_format;
        if(dateFormat){
            dateFormat = dateFormat.replace("d","DD");
            dateFormat = dateFormat.replace("m","MM");
            dateFormat = dateFormat.replace("y","YYYY");
            dateFormat = dateFormat.replace(",","/").replace(",","/");
        }else{
            dateFormat = pageConstants.dateFormat;
        }
        
        var date = moment(value).format(dateFormat).toString();
        return date;
    }catch(ex){
        var date = moment(value).format(pageConstants.dateFormat).toString();
        return date;
    }
}

const returnDateFormat = () => {
    try{
        var appconfig = JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('appconfig'))));
        var dateFormat = appconfig.extension_attributes.date_format;
        if(dateFormat){
            dateFormat = dateFormat.replace("d","dd");
            dateFormat = dateFormat.replace("m","MM");
            dateFormat = dateFormat.replace("y","yyyy");
            dateFormat = dateFormat.replace(",","/").replace(",","/");
        }else{
            dateFormat = pageConstants.dateFormat;
        }
        return dateFormat;
    }catch(ex){
    }
}

//date to dd-mm-yyyy format
const changeDateFormat = (value) => {
    var date = moment(value).format(pageConstants.defaultDateFormat);
    return date;
}
const formatTime= (value) => {
    var time = moment(value).format(pageConstants.defaultTime).toString();
    return time;
};
const formatDateTime= (value) => {
    var dateTime = moment(value).format(pageConstants.dateTimeFormat).toString();
    return dateTime;
};
const formatAmount = (amt) => {
    return `$${parseFloat(amt).toFixed(2)}`;
}

function getRandomInt(max) {
    let randomNo = Math.floor(Math.random() * Math.floor(max));
    return randomNo;
}

function formatPhone(phone) {
    if (phone.includes("+")) {
        return phone;
    } else {
        let phone_number = "+" + phone;
        return phone_number;
    }
}

const getCurrencySymbol = () => {
    return `$`;
}

function getCountryId(countryName) {
    const countries:any = JSON.parse(localStorage.getItem('Countries') || '{}');
    const result = countries.filter(c => c.full_name_english == countryName);
    return result[0]?.id;
}

function getRegionId(countryName, regionName) {
    const countries:any = JSON.parse(localStorage.getItem('Countries') || '{}');
    const country = countries.filter(c => c.id == countryName);
    const region = country[0].available_regions.filter(r => r.code == regionName);
    return region[0];
}

function getCountryName(countryId) {
    const countries:any = JSON.parse(localStorage.getItem('Countries') || '{}');
    const result = countries.filter(c => c.id == countryId);
    return result[0]?.full_name_english;

}

function getRegionName(countryId, regionId) {
    const countries:any = JSON.parse(localStorage.getItem('Countries') || '{}');
    const country = countries.filter(c => c.id == countryId);
    const region = country[0].available_regions.filter(r => r.code == regionId);
    return region[0];
}

export default {
    GroupBy, toPascalCase, formatDate, changeDateFormat, formatAmount,
    getRandomInt, formatPhone,returnPage,formatTime,formatDateTime,
    returnDateFormat, getCurrencySymbol, getCountryId, getRegionId,
    getCountryName, getRegionName
};
