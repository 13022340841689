import React, { ReactElement, useState } from 'react';

import {
	Header,
	Footer,
	BreadcrumbNav,
	Grid,
	Button,
	Stack,
	Toggle,
	Checkbox,
	FormLabel,
} from 'ui/components';
import { Sidebar, FormInput, FormGroup } from 'ui/patterns';
import { Box, Flex, Text } from 'rebass/styled-components';

import { MainContent, FooterBtnBlock, AddUserHolder, ProfileDetails } from './styles';
import { Label } from '@rebass/forms';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as Icon from 'ui/icons';

function AddDCList(): ReactElement {
	const breadcrumbNav = [];
	const [startDate, setStartDate] = useState(new Date());
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const SubHeading = 'Edit DC';
		const onclickHambrgBtn = () => {
			toggleHideSideBar(!hideSideBar);
		}
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={()=>onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={()=>onclickHambrgBtn()}></Sidebar>

			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbNav Heading="Back" breadcrumbList={breadcrumbNav} SubHeading={SubHeading} />
				{/* Add User Details */}
				<AddUserHolder>
					<ProfileDetails>
						<Box as="form">
							<Grid gap={0}>
								<FormInput label="DC Name" />
								<FormInput label="Description" />
								<FormInput label="Delivery location" />
								<FormInput label="Address" />
								<FormInput label="Email" />
								<FormInput label="Phone" />
								<FormInput label="Contact name" />
								<FormInput label="Contact Person’s Phone" />
								<FormInput label="City" />
								<FormInput label="State" />
								<FormInput label="Zipcode" />
								<FormInput label="Country" />
							</Grid>
							<Grid gap={0} className="inline-checkbox">
								<Flex>
									<Flex>
										<Text as="h1" fontSize={14} fontFamily="OpenSansBold">
											DC Active Status
										</Text>
									</Flex>
									<Flex>
										<Toggle />
									</Flex>
								</Flex>
							</Grid>
							<Grid gap={0} className="vacation-details">
								<Flex>
									<Flex>
										<Text as="h1" fontSize={18} fontFamily="OpenSansBold">
											Vacation Details
											<Text as="span" className="requiredText">
												*Applicable from 00:00 hr (Midnight) to 00:00 hr (Midnight)
											</Text>
										</Text>
									</Flex>
								</Flex>
							</Grid>
							<Grid gap={0}>
								<Flex>
									<Flex>
										<Label>
											<Checkbox name="remember" />
											Vacation Status
										</Label>
									</Flex>
								</Flex>
							</Grid>
							<Grid gap={0}>
								<Flex>
									<Box className="datepicker-c">
										<FormGroup datePicker>
											<FormLabel>Date From</FormLabel>
											<DatePicker
												selected={startDate}
												onChange={(date: any) => setStartDate(date)}
												dateFormat="dd MMMM yyy"
											/>
											<Icon.DateIconInput />
										</FormGroup>
									</Box>
									<Box className="datepicker-c">
										<FormGroup datePicker>
											<FormLabel>Date From</FormLabel>
											<DatePicker
												selected={startDate}
												onChange={(date: any) => setStartDate(date)}
												dateFormat="dd MMMM yyy"
											/>
											<Icon.DateIconInput />
										</FormGroup>
									</Box>
								</Flex>
								<Flex>
									<Flex className="full-width">
										<FormInput label="Message for Customers" />
									</Flex>
								</Flex>
							</Grid>
						</Box>
					</ProfileDetails>

					{/* Footer Btn Block */}
					<FooterBtnBlock>
						<Stack direction="x" gap={3} justifyContent={['center', 'flex-end']}>
							<Button flex={[1, 1, 'initial']} variant="secondary">
								Cancel
							</Button>
							<Button flex={[1, 1, 'initial']}>save</Button>
						</Stack>
					</FooterBtnBlock>
				</AddUserHolder>
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default AddDCList;
