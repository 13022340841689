import { permissionGroup, userTypes } from 'models/enum-constants/common.constants';
import React, { ReactElement } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { FlexboxProps, LayoutProps, SpaceProps } from 'styled-system';
import icons from 'ui/icons';
import utilityFunctions from 'utils/utility.functions';
const VenueCardsWrap = styled.div`
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		column-gap: 25px;
		row-gap: 25px;
		width: 100%;
		@media (max-width: 1266px) {
			grid-template-columns: repeat(2, 1fr);
		}
		@media (max-width: 768px) {
			grid-template-columns: repeat(1, 1fr);
			row-gap: 0;
		}
		@media (max-width: 860px) {
			margin-bottom:32px;
		}
		article{
			background-color: ${({ theme }) => theme.newColors.secondary02};
			border-radius: 12px;
			padding:0 30px;
			display:flex;
			align-items:center;
			min-height:125px;
			cursor: pointer;
			@media (max-width: 860px) {
				padding:0 25px;
			}
			@media (max-width: 768px) {
				border-radius: 0;
				min-height:85px;
				border-bottom:1px solid ${({ theme }) => theme.colors.bg.userInfoHeader};

				&:first-child{
					border-radius: 12px 12px 0 0 ;
				}
				&:last-child{
					border-radius: 0 0 12px 12px ;
					border:none;
				}
			}
			@media (max-width: 768px) {
						&:first-child{
					border-radius: 8px 8px 0 0 ;
				}
				&:last-child{
					border-radius: 0 0 8px 8px ;
					border:none;
				}
			}
			h1{
				font-size: 16px;
				line-height: 21px;
				font-family:'OpenSansSemiBold';
				text-transform: uppercase;
				color: ${({ theme }) => theme.colors.text.white};
				margin-left:35px;
				display:flex;
				flex-direction:column;
				strong{
					font-size: 28px;
					line-height: 36px;
					font-family:'OpenSansBold';

				}
			}
			button{
				width:50px;
				height:50px;
				background-color: ${({ theme }) => theme.newColors.secondary03};
				border-radius: 12px;
				cursor: pointer;
				outline:none;
				border:none;
				margin-left:auto;
				display:flex;
				align-items:center;
				justify-content:center;
				svg{
					width:16px;
					height:16px;
				}
				&:hover{
					background-color: ${({ theme }) => theme.newColors.primary01};

				}
				@media (max-width: 860px) {
					width:46px;
					height:46px;
				}
			}
		}
	}
`;
type styledSystemProps = SpaceProps & LayoutProps & FlexboxProps;
type SortInfoProps = styledSystemProps & {
	venueCount?: number;
	userCount?: number;
};

function VenueCardHolder({ venueCount, userCount }: SortInfoProps): ReactElement {
	const history = useHistory();
	let userType = localStorage.getItem('userType');
	return (
		<VenueCardsWrap>
			{
				userType==userTypes.venueAdmin && (
				<Flex as="article">
					<icons.venueIcon />
					<Text onClick={() => history.push('/admin/stores-list')} as="h1" fontFamily="OpenSansBold" fontSize={18} lineHeight="23px" color="text.black">
						Store<strong>{venueCount}</strong>
					</Text>
					{
						userType==userTypes.venueAdmin && 
						(<Button onClick={() => history.push('/admin/add-new-store')}>
							<icons.PlusIcon />
						</Button>)
					}
				</Flex>
			)}
			{
				userType==userTypes.venueAdmin && (
				<Flex as="article">
					<icons.userIcon />
					<Text onClick={() => history.push('/admin/users-list')} as="h1" fontFamily="OpenSansBold" fontSize={18} lineHeight="23px" color="text.black">
						User<strong>{userCount}</strong>
					</Text>
					{
						userType==userTypes.venueAdmin && 
						(<Button  onClick={() => history.push('/admin/user')}> 
							<icons.PlusIcon />
						</Button>)
					}
				</Flex>
			)}
			{
				userType==userTypes.venueAdmin && 
				utilityFunctions.isAnyGranted(permissionGroup.UserRole) &&
				(
					<Flex as="article" onClick={() => history.push('/admin/user-role-management')}>
						<icons.userRoll />
						<Text as="h1" fontFamily="OpenSansBold" fontSize={18} lineHeight="23px" color="text.black">
							User Role <br></br>
							Management
						</Text>
					</Flex>
			)}
			<Flex as="article" onClick={() => history.push('/admin/deliveryConfirmations')} >
				<icons.deliveryIcon />
				<Text as="h1" fontFamily="OpenSansBold" fontSize={18} lineHeight="23px" color="text.black">
					Delivery <br></br>
					Confirmation
				</Text>
			</Flex>
		</VenueCardsWrap>
	);
}

export { VenueCardHolder };
