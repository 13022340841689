import { HTMLAttributes, RefObject } from 'react';
import styled, { css } from 'styled-components';
import { layout, LayoutProps, space, SpaceProps } from 'styled-system';
import { getHSL } from '../../utils/getHSL';


type InputProps = HTMLAttributes<HTMLInputElement> &
	SpaceProps &
	LayoutProps & {
		variant?: 'otpField';
		label?: string;
		value?: string | number;
		type?: 'email' | 'number' | 'password' | 'search' | 'text' | 'tel' | 'url' | 'date';
		placeholder?: string;
		disabled?: boolean;
		readonly?: boolean;
		validation?: string;
		feedback?: string;
		inline?: boolean;
		ref?: RefObject<HTMLInputElement>;
		px?: string;
		py?: string;
	};
const StyledFormInput = styled.input<InputProps>`
	${layout};
	${space};

	--px: ${({ px }) => px || '0.75em'};
	--py: ${({ py }) => py || '0.75em'};
	--a: 80%;
	--h: ${({ theme }) => getHSL('h', theme.colors.border.default)};
	--s: ${({ theme }) => getHSL('s', theme.colors.border.default)};
	--l: ${({ theme }) => getHSL('l', theme.colors.border.default)};
	--input-bg: #fff;
	display: block;
	width: 100%;
	height: calc(calc(var(--py) * 2) + calc(var(--px) * 2) + 2px);
	padding: var(--py) var(--px);
	font-family: 'OpenSansSemiBold';
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: ${({ theme }) => theme.colors.text.body};
	background-color: var(--input-bg);
	background-clip: padding-box;
	border: 1px solid ${({ theme }) => theme.colors.border.default};
	border-radius: ${({ theme }) => theme.radii.softer};
	transition: all 200ms ease-in-out;
	-webkit-appearance: none;
	appearance: none;

	&:focus {
		background-color: #fff;
		border-color: ${({ theme }) => theme.colors.primary};
		outline: 0;
		box-shadow: 0 0 0 0.2rem hsla(var(--h), var(--s), var(--l), 30%);
	}

	${({ disabled }) =>
		disabled &&
		css`
			opacity: 0.5;
			cursor: not-allowed;
		`}

	${({ readonly }) =>
		readonly &&
		css`
			--input-bg: rgba(196, 196, 196, 0.19);
		`}

	${({ validation }) =>
		validation === 'error' &&
		css`
			input {
			}
			border-color: ${({ theme }) => theme.colors.border.error};
			--h: ${({ theme }) => getHSL('h', theme.colors.state.error)};
			--s: ${({ theme }) => getHSL('s', theme.colors.state.error)};
			--l: ${({ theme }) => getHSL('l', theme.colors.state.error)};

			&:focus {
				border-color: ${({ theme }) => theme.colors.border.error};
			}
		`}

    ${({ validation }) =>
		validation === 'success' &&
		css`
			border-color: ${({ theme }) => theme.colors.border.success};
			--h: ${({ theme }) => getHSL('h', theme.colors.state.success)};
			--s: ${({ theme }) => getHSL('s', theme.colors.state.success)};
			--l: ${({ theme }) => getHSL('l', theme.colors.state.success)};

			&:focus {
				border-color: ${({ theme }) => theme.colors.border.success};
			}
		`}

    ${({ validation }) =>
		validation === 'warning' &&
		css`
			border-color: ${({ theme }) => theme.colors.border.warning};
			--h: ${({ theme }) => getHSL('h', theme.colors.state.warning)};
			--s: ${({ theme }) => getHSL('s', theme.colors.state.warning)};
			--l: ${({ theme }) => getHSL('l', theme.colors.state.warning)};

			&:focus {
				border-color: ${({ theme }) => theme.colors.border.warning};
			}
		`}
		${({ variant }) =>
		variant === 'otpField' &&
		css`
			width: 66px;
			height: 73px;
			border-radius: 9px;
			border: 1px solid ${({ theme }) => theme.colors.border.yellowDark};
			text-align: center;
			font-size: 24px;
			@media (max-width: 640px) {
				width: 100%;
				height: 65px;
			}
		`}

  ${({ inline }) =>
		inline &&
		css`
			width: auto;
		`}
`;
export type InputProp = {
	variant?: 'otpField';
	label?: string;
	value?: string;
	type?: 'email' | 'number' | 'password' | 'search' | 'text' | 'tel' | 'url' | 'date';
	placeholder?: string;
	disabled?: boolean;
	readonly?: boolean;
	validation?: string;
	feedback?: string;
	inline?: boolean;
	ref?: RefObject<HTMLInputElement>;
};
export { StyledFormInput };
