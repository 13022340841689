import Ipost from 'models/Ipost';
import React, { ReactElement, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Box } from 'rebass/styled-components';
import { BreadcrumbNav, Button, Footer, Grid, Header, Stack } from 'ui/components';
import { FormInput, Sidebar } from 'ui/patterns';
import pageConstants from 'utils/pageConstants';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import {
	AddUserHolder,

	BreadcrumbHolder, FooterBtnBlock, MainContent,


	ProfileDetails
} from './styles';



function ReferARIA(): ReactElement {
	// const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
	// const toggleChangePasswordModal = () => {
	// 	setShowChangePasswordModal(!showChangePasswordModal);
	// };
	// const [showCPModal, setShowCPModal] = useState(false);

	var userId = localStorage.getItem('userId');
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	// const apiKey = 'userProfileDet';
	const [disableBtnSave, setDisableBtnSave] = useState(true);
	const [modalText, setmodalText] = useState('');
	const [showPopup, setShowModal] = useState(false);
	const [action, setAction] = useState('');
	// const [mutatePost, mutationStates] = hooks.useMutation(apiKey);

	const [userDetails, updateUserDetails] = useState({
		user_email: '',
		phone: '',
	});

	const [error, setError] = useState({
		emailAddressError: '',
		phoneNumberError: '',
	});

	const breadcrumbNav = [
		// { id: '01', label: 'Home', route: '/home/dashboard', active: false },
		{ id: '02', label: 'Refer B2B Company', active: true },
	];

	const onChangeValue = (val, name) => {
		const item = userDetails;
		switch (name) {
			case 'email': {
				if (!val) {
					setError({ ...error, emailAddressError: 'Email Address cannot be blank' });
				} else {
					const validEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(val);
					if (validEmail) {
						error.emailAddressError = '';
					} else {
						error.emailAddressError = 'Please enter Valid Email Address';
					}
				}
				setError(error);
				item.user_email = val;
				break;
			}
			case 'phone': {
				if (!val) {
					error.phoneNumberError = 'Phone cannot be blank';
				} else {
					const regex = pageConstants.phoneRegex;
					if (!regex.test(val)) {
						error.phoneNumberError = 'Invalid Phone';
					} else {
						error.phoneNumberError = '';
					}
				}
				setError(error);
				item.phone = (val);
				break;
			}
			default:
				break;
		}
		updateUserDetails({
			...item,
		});
		if (
			userDetails &&
			userDetails.user_email &&
			// userDetails.phone &&
			error &&
			!error.emailAddressError
			// !error.phoneNumberError
		) {
			setDisableBtnSave(false);
		} else {
			setDisableBtnSave(true);
		}
	};

	const resetData = () => {
		clearDetails('clear');
	};

	const submitData = (action) => {
		switch (action) {
			case 'save':
				showConfirmationModal(action, 'Send Reference Email to the entered email address?');
				break;
			case 'clear':
				if (
					userDetails.user_email != '' ||
					userDetails.phone !== ''
				) {
					showConfirmationModal(action, 'Discard the changes?');
				}
				break;
			default:
				break;
		}
	};

	function showConfirmationModal(action, text) {
		setAction(action);
		setmodalText(text);
		setShowModal(!showPopup);
	}

	async function onSubmit() {
		if (action === 'save') {
			saveData();
		} else if (action === 'clear') {
			clearDetails('clear');
		}
		setShowModal(!showPopup);
	}

	async function closePopup() {
		await setShowModal(!showPopup);
	}

	async function saveData() {
		if (userDetails) {
			var payload = {
				"user_id": parseInt(userId + ""),
				"receiver_email": userDetails.user_email
				// phone: userDetails.phone,
			};
			const obj: Partial<Ipost> = {
				data: { refer: payload },
				params: {},
				url: "experion/venue/referexperion",
				message: 'Reference mail send successfully',
			};
			try {
				api.post(obj).then(() => {
					// if (data !== undefined) {
					// 	history.push('/home/dashboard');
					// }
				});
			} catch (e) { }
			setShowModal(!showPopup);
		}
	}

	const clearDetails = (param) => {
		if (param === 'clear') {
			updateUserDetails({
				user_email: '',
				phone: '',
			});
			setError({
				emailAddressError: '',
				phoneNumberError: '',
			});
			setDisableBtnSave(true);
		}
	};
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	}
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={() => onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={() => onclickHambrgBtn()}></Sidebar>
			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbHolder>
					<BreadcrumbNav Heading="Refer B2B Company" breadcrumbList={breadcrumbNav} />
				</BreadcrumbHolder>
				{/* Add User Details */}

				<AddUserHolder>
					<ProfileDetails style={{ marginBottom: '0px' }}>
						{/* <Text as="h1" fontSize={18} fontFamily="OpenSansBold" color="text.SubHeading">
							Refer ARIA Company
						</Text> */}
						<Box as="form">
							<Grid gap={25}>

								<FormInput
									label="Email"
									autoComplete="off"
									validationMessage={error.emailAddressError}
									validation={error.emailAddressError ? 'error' : undefined}
									value={userDetails && userDetails.user_email}
									required={true}
									name="email"
									onTextChange={(e) => onChangeValue(e, 'email')}
									maxLength={50}
									type="text"
								/>

								{/* <FormInput
									label="Phone Number"
									autoComplete="off"
									validationMessage={error.phoneNumberError}
									validation={error.phoneNumberError ? 'error' : undefined}
									value={userDetails && (userDetails.phone ? userDetails.phone.toString() : '')}
									required={true}
									name="phone"
									onTextChange={(e) => onChangeValue(e, 'phone')}
									maxLength={12}
									type="tel"
								/> */}

							</Grid>
						</Box>
					</ProfileDetails>

					{/* Footer Btn Block */}
					<FooterBtnBlock>
						<Stack direction="x" gap={3} justifyContent={['center', 'flex-end']}>
							<Button onClick={() => resetData()} flex={[1, 1, 'initial']} variant="secondary">
								Clear
							</Button>
							<Button
								disabled={disableBtnSave}
								onClick={() => submitData('save')}
								flex={[1, 1, 'initial']}
							>
								Send
							</Button>
						</Stack>
					</FooterBtnBlock>
				</AddUserHolder>
				<SweetAlert
					show={showPopup}
					confirmBtnText="Confirm"
					onConfirm={onSubmit}
					showCancel={true}
					onCancel={closePopup}
					title={'Confirmation Box'}
				>
					{() => <form>{modalText}</form>}
				</SweetAlert>
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default ReferARIA;
