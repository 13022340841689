import React, { ReactElement, useState } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import styled, { css } from 'styled-components';
import urls from 'utils/create.url';
import api from 'utils/requestCtx/api.service';

const StyledSearch = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	span {
		position: absolute;
		z-index: 1;
		left: 22px;
	}
`;
const SearchAutoSuggestionSKUHolder = styled.div`
	width: 100%;
	min-width: 100%;
	margin: 0 auto;
	position: relative;
	input {
		text-align: center !important;
	}
	div {
		width: auto;
	}
`;
const PageSearch = styled.input`
	border: 1px solid ${({ theme }) => theme.colors.border.grayLightBorder};
	margin: 10px auto 0;
	width: 100% !important;

	&::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: ${({ theme }) => theme.colors.text.body};
		color: rgba(98, 98, 98, 0.4);
		font-size: 14px;
	}
	&::-moz-placeholder {
		/* Firefox 19+ */
		color: ${({ theme }) => theme.colors.text.body};
		color: rgba(98, 98, 98, 0.4);
		font-size: 14px;
	}
	&:-ms-input-placeholder {
		/* IE 10+ */
		color: ${({ theme }) => theme.colors.text.body};
		color: rgba(98, 98, 98, 0.4);
		font-size: 14px;
	}
	&:-moz-placeholder {
		/* Firefox 18- */
		color: ${({ theme }) => theme.colors.text.body};
		color: rgba(98, 98, 98, 0.4);
		font-size: 14px;
	}
`;
const SearchAutoSuggestionSKUDetails = styled.div<SearchAutoSuggestionSKUDetailsProps>`
	padding: 16px;
	border-top: 0;
	border: 1px solid ${({ theme }) => theme.colors.border.grayLightBorder};
	border-radius: 0 0 16px 16px;
	position: absolute;
	width: 100% !important;
	margin: -1px 0 0;
	z-index: 996;
	background: ${({ theme }) => theme.baseColors.white}!important;
	@media screen and (max-width: 960px) {
		width: 100% !important;
		margin: -6px 0 0 0;
	}
	@media screen and (max-width: 767px) {
		padding: 8px;
	}
	article {
		margin: 0;
		padding: 15px;
		border-bottom: none;
		background: ${({ theme }) => theme.colors.bg.lightYellow};
		border-radius: 8px;
		margin: 0 0 10px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		@media screen and (max-width: 767px) {
			padding: 8px;
		}
		h1 {
			max-width: 100% !important;
		}
		div {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-direction: column;

			h1 {
				margin-bottom: 2px;
				strong {
					margin-right: 5px;
				}
			}
		}
	}
	${({ hide }) =>
		hide &&
		css`
			display: none;
		`}
	${({ show }) =>
		show &&
		css`
			display: block;
		`}
`;
type SearchAutoSuggestionSKUDetailsProps = {
	hide?: boolean;
	show?: boolean;
};

function SearchAutoSuggestionSKU(props): ReactElement {
	const [openDropDown, setOpenDropDown] = useState(false);
	const [searchValues, setSearchValues] = useState<any>([]);

	const setSearchSku = (event) => {
		if (event.target.value === '' || event.target.value === undefined) {
			props.disableAddButton(true);
			props.searchItem(event.target.value);
			props.getSelectedSku('');
			props.getSelectedItemPrice('');
			props.getSelectedItemQuantity('');
			props.getSelectedItemSubtotal('');
			setOpenDropDown(false);
		} else {
			props.disableAddButton(true);
			setOpenDropDown(true);
			props.searchItem(event.target.value);
			fetch(event.target.value);
		}
	};

	const fetch = (sku) => {
		let url = urls.GetSearchUrl({
			url: 'products?',
			fields: [
				{
					field_name: 'sku',
					field_value: `${sku}`,
					field_conditionType: 'like',
				},
				{
					field_name: 'venue_id',
					field_value: localStorage.getItem('selectedVenue')!,
					field_conditionType: 'eq',
				},
			],
		});
		if (sku !== undefined) {
			api
				.get({
					url: url,
				})
				.then((data) => {
					if (data.items.length > 0) {
						setSearchValues(data.items);
					} else {
					}
				});
		}
	};
	const onProductClick = (item) => {
		props.disableAddButton(false);
		props.getSelectedSku(item.sku, item.id);
		props.getSelectedItemPrice(item.price);
		props.getSelectedItemQuantity(item.extension_attributes.min_order_qty);
		props.getSelectedItemSubtotal(item.price * item.extension_attributes.min_order_qty);
		setOpenDropDown(false);
	};

	return (
		<SearchAutoSuggestionSKUHolder className="SearchAutoSuggestionSKUHolder">
			<StyledSearch>
				{/* {props.skuValue !== '' ?  */}
				<PageSearch
					type="search"
					value={props.skuValue}
					onChange={setSearchSku}
					placeholder="Search for product"
				></PageSearch>
			</StyledSearch>
			<SearchAutoSuggestionSKUDetails hide={!openDropDown}>
				<SimpleBar style={{ maxHeight: 244 }}>
					{searchValues &&
						searchValues.filter((data) => data?.extension_attributes?.is_delete !== 1)
						?.map((data) => (
							<Flex
								as="article"
								bg="white"
								p={[3, 4]}
								sx={{ alignItems: 'center' }}
								key={data.id}
								onClick={() => {
									onProductClick(data);
								}}
							>
								<Box>
									<Text
										as="h1"
										fontFamily="OpenSansMedium"
										fontSize={16}
										lineHeight="21px"
										color="text.black03"
										className="truncate"
									>
										<strong>{data.sku}</strong>
										{data.name}
									</Text>
								</Box>
							</Flex>
						))}
				</SimpleBar>
			</SearchAutoSuggestionSKUDetails>
		</SearchAutoSuggestionSKUHolder>
	);
}
export { SearchAutoSuggestionSKU };
