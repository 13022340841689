import { Input } from '@rebass/forms';
import Iget from 'models/Iget';
import queryString from 'query-string';
import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Text } from 'rebass/styled-components';
import { BreadcrumbNav, Button, Footer, Header, Loader } from 'ui/components';
import { DeliveryConfirmationsOrderInfo, DeliveryOrderProblem, Sidebar } from 'ui/patterns';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import notify from 'utils/toast.service';
import {
	TotalCostBlock
} from '../../admin/deliveryConfirmationsInner/styles';
import { AddCommentsHolder, BreadcrumbNavHolder, MainContent } from './styles';

function DeliveryConfirmationsInner(): ReactElement {
	const location = useLocation();
	const [disableSubmit, setdisableSubmit] = useState([] as any);
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const parsed = queryString.parse(location.search);
	var venueUserId: any;
	venueUserId = localStorage.getItem('userId');
	const [deliveryConfirmationsOrderInfo, setDeliveryConfirmationsOrderInfo] = useState<any>({});
	const [enteredComment, setEnteredComment] = useState<any>('');
	const [textBoxValue, setTextBoxValue] = useState<any>([]);
	const [loading, setLoading] = useState(false);
	const history = useHistory();

	const breadcrumbNav = [
		{ id: '01', label: 'Home', route: '/home/dashboard', active: false },
		{ id: '02', label: 'Dashboard', route: '/admin/user-dashboard', active: false },
		{ id: '03', label: 'Delivery Confirmation', route: "/admin/deliveryConfirmations", active: false },
		{ id: '04', label: 'Order ID ' + `${deliveryConfirmationsOrderInfo?.extension_attributes?.order_group_increment_id}`, active: true },
	];
	const url1 = '/orders/' + parsed.id
	const obj1: Partial<Iget> = {
		params: {},
		url: url1,
		enabled: true,
		key: 'orderFav',
		resultFilteringRequired: false,
	};
	var qry = hooks.useQuery(obj1);

	useEffect(() => {
		setDeliveryConfirmationsOrderInfo(qry.data)
	}, [qry.data])

	const setEnteredCommentToSave = (event) => {
		setEnteredComment(event.target.value);
	}
	const setTextBoxValueFn = (event, disableSub) => {
		// event.forEach(data => {
		// 	data.comment = enteredComment;
		// })
		setdisableSubmit(disableSub);
		setTextBoxValue(prevObj1 => ([...event]));
	}

	const submitIssue = () => {
		if (disableSubmit.length > 0) {
			notify(`Received quantity must be less than requested quantity and cannot be less than zero or a decimal number`).error();
		} else {
			setLoading(true);

			let setData = [] as any;
			qry.data?.items?.forEach((data) => {
				let dataObj = {
					product_id: data.product_id,
					ordered_qty: data.qty_ordered,
					delivered_qty: data.qty_ordered,
					comment: '',
				};
				setData.push(dataObj);
			});
			console.log(qry);

			const payload = {
				'delivery': {
					"order_id": parseInt(parsed.id),
					"dc_id": deliveryConfirmationsOrderInfo.extension_attributes.dc_id,
					"venue_user_id": parseInt(venueUserId),
					"delivery_product": setData,
					"is_delivery_in_full": false,
					"is_signed": true,
					"is_active": true,
					"product_id": 0,
					"ordered_qty": 0,
					"delivered_qty": 0,
					"comment": enteredComment,
				},
			};
			api.post({
				data: payload,
				url: '/experion/deliveryconfirmation/create',
				key: 'placeOrder',
				message: 'Order Delivery Confirmed',
			})
				.then(function (data) {
					setLoading(false);
					if (data) {
						history.push('/admin/deliveryConfirmations');
					}
				}).catch((e) => {
					setLoading(false);
				});
		}

	}
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	}
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={() => onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={() => onclickHambrgBtn()}></Sidebar>
			{(qry.isLoading || loading) ? < Loader ></Loader> : null}
			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbNavHolder>
					<BreadcrumbNav Heading={'Order ID ' + deliveryConfirmationsOrderInfo?.extension_attributes?.order_group_increment_id} breadcrumbList={breadcrumbNav} />
					{/* <SortBuy>
						<Icon.SortIcon />
						<Select
							id="country"
							name="country"
							defaultValue="United States"
							className="PageSearchCategory"
						>
							<option>Popularity</option>
							<option>Low - High Price</option>
							<option>High - Low Price</option>
							<option>A - Z Order</option>
							<option>Z - A Order</option>
						</Select>
					</SortBuy> */}
				</BreadcrumbNavHolder>
				<DeliveryConfirmationsOrderInfo
					deliveryConfirmationsOrderInfo={deliveryConfirmationsOrderInfo}
				/>

				<DeliveryOrderProblem deliveryOrderProblemList={deliveryConfirmationsOrderInfo} setTextBoxValueFn={setTextBoxValueFn} />
				<TotalCostBlock>
					<Text
						as="h1"
						fontSize="20px"
						fontFamily="OpenSansRegular"
						className="truncate"
						lineHeight="22px"
						color="text.black"
					>
						Total Cost <strong>${deliveryConfirmationsOrderInfo?.grand_total}</strong>
					</Text>
				</TotalCostBlock>
				<AddCommentsHolder>
					<Input onChange={setEnteredCommentToSave}
						id="email"
						name="email"
						type="email"
						placeholder="Add Comments"
						autoCorrect="off"
					/>
					<Button onClick={() => { history.goBack() }} variant="secondary">Cancel</Button>

					<Button onClick={submitIssue}>Submit</Button>
				</AddCommentsHolder>

			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default DeliveryConfirmationsInner;
