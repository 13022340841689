import React, { ReactElement, useEffect, useState } from 'react';
import { Flex, Link, Text } from 'rebass/styled-components';
import { StyledFooter, StyledFooterNav } from './styles';

function Footer(): ReactElement {
	const [year, setYear] = useState(new Date().getFullYear());
	useEffect(() => {
		setYear(new Date().getFullYear());
	}, []);

	return (
		<StyledFooter>
			<StyledFooterNav>
				<Flex flexDirection="row">
					<Link fontSize="16" color="border.white" variant="nav" href="/admin/faq">
						FAQ
					</Link>
					<Link fontSize="16" color="border.white" variant="nav" href="/admin/termsAndCondition">
						Terms & Conditions
					</Link>
					<Link fontSize="16" color="border.white" variant="nav" href="/admin/privacyPolicy">
						Privacy Policy
					</Link>
					<Link fontSize="16" color="border.white" variant="nav" href="/admin/referExperion">
						Refer B2B marketplace
					</Link>

					<Link fontSize="16" color="border.white" variant="nav" href="/admin/askHelp">
						Help
					</Link>
				</Flex>
			</StyledFooterNav>
			<Text
				as="h1"
				fontFamily="OpenSansMedium"
				fontSize="16px"
				lineHeight="21px"
				color="border.white"
			>
				{`© ${year} Experion, All Rights Reserved`}
			</Text>
		</StyledFooter>
	);
}

export { Footer };
