import { getHSL } from '../../utils/getHSL';

import styled from 'styled-components';
const StyledProductSubstitute = styled.div`
	display: flex;
	flex-direction: column;
	float: left;
	width: 100%;
	@media (max-width: 1366px) {
		grid-template-columns: repeat(3, 1fr) !important;
		column-gap: 20px;
	}
	@media (max-width: 1300px) {
		grid-template-columns: repeat(2, 1fr) !important;
		column-gap: 20px;
		margin-bottom: 50px;
	}
	@media (max-width: 960px) {
		grid-template-columns: repeat(3, 1fr) !important;
		column-gap: 20px;
	}
	@media (max-width: 860px) {
		grid-template-columns: repeat(2, 1fr) !important;
		column-gap: 15px;
		margin-bottom: 0;
	}
	@media (max-width: 640px) {
		grid-template-columns: repeat(1, 1fr) !important;
		column-gap: 15px;
	}
	div {
		outline: none !important;
	}
	article {
		flex-direction: column;
		background: ${({ theme }) => theme.colors.bg.white};
		border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
		box-sizing: border-box;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 16px;
		overflow: hidden;
		transition: box-shadow 100ms ease-in, transform 100ms ease-in;
		transition: all 0.25s ease-in-out;
		outline: none !important;
		cursor: pointer;

		&.row-highlighted {
			pointer-events: none;
			box-shadow: none;
			position: relative;
			border: 1px solid rgb(255, 0, 0, 0.1);
			span,
			section,
			div {
				opacity: 0.35;
			}
			&:after {
				content: 'Out of Stock';
				position: absolute;
				color: ${({ theme }) => theme.newColors.white};
				right: 0;
				top: 0;
				font-family: 'OpenSansSemiBold';
				font-size: 16px;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				background: rgb(160 160 160 / 50%);
			}
		}
		span {
			width: 100%;
			height: 200px;
			position: relative;
			transition: opacity 0.35s;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #faf4ea;

			div {
				position: relative;
				width: calc(100% - 10px);
				height: calc(100% - 10px);
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center;
				margin: 5px;
			}
			&:after {
				content: '';
				opacity: 0;
				transition: opacity 0.35s;
			}
			button {
				--h: ${({ theme, color }) => getHSL('h', color || theme.baseColors.baseYellow)};
				--s: ${({ theme, color }) => getHSL('s', color || theme.baseColors.baseYellow)};
				--l: ${({ theme, color }) => getHSL('l', color || theme.baseColors.baseYellow)};
				--a: 100%;
				width: 146px;
				height: 35px;
				opacity: 0;
				z-index: 1;
				color: ${({ theme }) => theme.colors.text.white};
				background: hsla(var(--h), var(--s), var(--l), var(--a));
				border: solid 1px hsl(var(--h), var(--s), var(--l));
				outline: none;
				border: none;
				position: absolute;
				transition: opacity 0.3s, ease-in-out 0.3s;
				bottom: 0;
				font-size: 14px;
				font-family: 'OpenSansSemiBold';
				cursor: pointer;
				display: flex;
				white-space: nowrap;
				align-items: center;
				justify-content: center;

				&:hover {
					background: hsl(var(--h), var(--s), calc(var(--l) - 10%));
					border: 1px solid hsl(var(--h), var(--s), calc(var(--l) - -5%));
				}
				span {
					height: auto;
					width: auto;
					margin-right: 8px;
					-webkit-animation: beat 0.25s infinite alternate;
					-moz-animation: beat 0.25s infinite alternate;
					-ms-animation: beat 0.25s infinite alternate;
					-o-animation: beat 0.25s infinite alternate;
					animation: beat 0.25s infinite alternate;

					-webkit-transform-origin: center;
					-moz-transform-origin: center;
					-o-transform-origin: center;
					-ms-transform-origin: center;
					transform-origin: center;

					&:after {
						display: none;
					}
					@keyframes beat {
						to {
							-webkit-transform: scale(1.3);
							-moz-transform: scale(1.3);
							-o-transform: scale(1.3);
							-ms-transform: scale(1.3);
							transform: scale(1.3);
						}
					}
				}
			}
		}

		section {
			width: 100%;
			display: flex;
			align-items: flex-start;
			flex-direction: column;
			padding: 15px;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			display: block;
			h1,
			h2,
			h3,
			h4 {
				font-weight: normal;
			}
			h2 {
				font-family: 'OpenSansMedium';
				margin: 3px 0 0 0;
			}
			h4 {
				font-family: 'OpenSansMedium';
				strong {
					font-family: 'OpenSansBlack';
					font-size: 20px;
					color: ${({ theme }) => theme.colors.text.black};
					margin-right: 5px;
				}
			}
		}
	}
	.slick-slider {
		width: 100%;
		.slick-prev,
		.slick-next {
			z-index: 9;
			width: 24px;
			height: 24px;
			top: calc(50% - 4px);
		}
		.slick-prev,
		.slick-next {
			@media (max-width: 767px) {
				display: none !important;
			}
			&:before {
				content: '' !important;
				width: 24px;
				height: 24px;
				display: block;
				opacity: 1;
			}
		}
		.slick-prev {
			left: 15px;
			&:before {
				background: url(${require('../../images/leftSlideArrow.svg')});
			}
		}
		.slick-next {
			right: 20px;
			&:before {
				background: url(${require('../../images/rightSlideArrow.svg')});
			}
		}

		.slick-next.slick-disabled:before,
		.slick-prev.slick-disabled:before {
			opacity: 0.25;
		}

		.slick-list {
			@media (max-width: 767px) {
				overflow: hidden;
			}
			@media (max-width: 640px) {
				overflow: hidden;
			}
			.slick-track {
				display: flex;
				padding-right: 0;
				.slick-slide {
					width: 100%;
					margin-left: 20px;
					@media (max-width: 640px) {
						margin-left: 0;
						padding: 0 3px;
					}
					&:first-child {
						margin-left: 0;
					}
				}
			}
		}
		.slick-dots {
			li {
				width: 8px;
				height: 8px;
				button {
					width: 8px;
					height: 8px;
					&::before {
						font-size: 8px;
						line-height: 8px;
						width: 8px;
						height: 8px;
						color: ${({ theme }) => theme.colors.border.greyBorderLogo};
						opacity: 1;
					}
				}
				&.slick-active {
					button {
						&::before {
							color: ${({ theme }) => theme.baseColors.baseYellow};
							z-index: 1;
						}
						&::after {
							content: '';
							position: absolute;
							width: 14px;
							height: 14px;
							background: ${({ theme }) => theme.colors.border.greyBorderLogo};
							left: -3px;
							top: -4px;
							border-radius: 50px;
						}
					}
				}
			}
			@media (max-width: 767px) {
				bottom: -20px;
				li {
					width: 8px;
					height: 8px;
					button {
						width: 8px;
						height: 8px;
						&::before {
							font-size: 8px;
							line-height: 8px;
							width: 8px;
							height: 8px;
							color: ${({ theme }) => theme.colors.border.greyBorderLogo};
							opacity: 1;
						}
					}
					&.slick-active {
						button {
							&::before {
								color: ${({ theme }) => theme.baseColors.baseYellow};
								z-index: 1;
							}
							&::after {
								content: '';
								position: absolute;
								width: 13px;
								height: 13px;
								background: ${({ theme }) => theme.colors.border.greyBorderLogo};
								left: -3px;
								top: -3px;
								border-radius: 50px;
							}
						}
					}
				}
			}
		}
	}
`;
const ViewAllHeadingBlock = styled.div`
	display: flex !important;
	justify-content: space-between;
	margin: 0 0 40px;
	color: ${({ theme }) => theme.colors.text.black};
	min-height: 61px;
	padding-top: 30px;
	display: flex;
	align-items: center;
	border-top: 1px solid ${({ theme }) => theme.colors.border.grayLightBorder01};
	@media (max-width: 860px) {
		padding-top: 40px;
	}
	h1,
	a {
		font-weight: normal;
		font-family: 'OpenSansMedium';
	}
	h1 {
		font-family: 'OpenSansBold';
		text-transform: uppercase;
	}
	a {
		color: ${({ theme }) => theme.baseColors.baseYellow};
		text-decoration: none;
		transition: background 0.8s;
		transition: ease-in all 200ms;

		&:hover {
			background: ${({ theme }) => theme.baseColors.baseYellow};
			padding: 3px 10px;
			color: ${({ theme }) => theme.baseColors.white};
			border-radius: 5px;
		}
	}
`;
export { StyledProductSubstitute, ViewAllHeadingBlock };
