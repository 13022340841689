import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

const StyledDropdownMenu = styled.div<DropdownMenuProps>``;

type DropdownMenuProps = {
	children?: string | ReactNode;
	position?: 'right-top' | 'right' | 'left-top' | 'left';
};

function DropdownMenu({ children, position }: DropdownMenuProps): ReactElement {
	return <StyledDropdownMenu position={position}>{children}</StyledDropdownMenu>;
}

export default DropdownMenu;
