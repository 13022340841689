import React, { ReactNode } from 'react';
import { Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { layout, LayoutProps, space, SpaceProps } from 'styled-system';
import { Stack } from 'ui/components';

type StyledKeylineProps = LayoutProps &
	SpaceProps & {
		contentAlign?: 'left' | 'center' | 'right';
	};

const StyledKeyline = styled.div<StyledKeylineProps>`
	${layout}
	${space}
	--metric-keyline-gap: 1rem;
	font-family: 'OpenSansBold';
	text-transform: uppercase;
	text-align: center;
	line-height: 1.2;
	display: grid;
	grid-template-columns: ${({ contentAlign }) => {
		if (contentAlign === 'left') return 'auto auto 1fr';
		if (contentAlign === 'center') return '1fr auto 1fr';
		if (contentAlign === 'right') return '1fr auto auto';
	}};
	grid-gap: var(--metric-keyline-gap);
	align-items: center;
	width: 100%;
	color: ${({ theme }) => theme.colors.black};
	&::before,
	&::after {
		content: '';
		height: 1px;
		background: #bdbcb9;
		/* transform: translateY(-0.1em); */
	}
	& > * {
		max-width: 35ch;
		${({ contentAlign }) => {
			if (contentAlign === 'left') return 'margin-left: calc(var(--metric-keyline-gap) * -1)';
			if (contentAlign === 'center') return null;
			if (contentAlign === 'right') return 'margin-right: calc(var(--metric-keyline-gap) * -1)';
		}};
	}
	&.border-none {
		&::after {
			display: none !important;
		}
	}
`;

type styledSystemProps = SpaceProps & LayoutProps;

type KeyLineProps = styledSystemProps & {
	label: string;
	icon?: ReactNode;
	contentAlign?: 'left' | 'center' | 'right';
	className?: string;
};

function Keyline({ className, icon, label, contentAlign, ...styledSystemProps }: KeyLineProps) {
	return (
		<StyledKeyline {...styledSystemProps} contentAlign={contentAlign} className={className}>
			<Stack direction="x" gap={2} alignItems="center">
				{icon ? icon : null}{' '}
				<Text fontSize={['16px', '18px']} fontFamily="OpenSansBold" lineHeight="1">
					{label}
				</Text>
			</Stack>
		</StyledKeyline>
	);
}

export { Keyline };
