import { Checkbox, Label } from '@rebass/forms';
import { ExportToCsv } from 'export-to-csv';
import Iget from 'models/Iget';
import Ipost from 'models/Ipost';
import React, { ReactElement, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory } from 'react-router-dom';
import { Box, Text } from 'rebass/styled-components';
import {
	BreadcrumbNav,
	Button,
	Footer,
	Header,
	PageSearchHolder,
	Search,
	TableList,
	Toggle,
} from 'ui/components';
import icons from 'ui/icons';
import { Sidebar } from 'ui/patterns';
import urls from 'utils/create.url';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import notify from 'utils/toast.service';
import { BreadcrumbHolder, ButtonWrapper, MainContent, UserListHolder } from './styles';

function FullVenuesList(): ReactElement {
	const breadcrumbNav = [
		{ id: '01', label: 'Home', route: '/home/dashboard', active: false },
		{ id: '02', label: 'Dashboard', route: '/admin/user-dashboard', active: false },
		{ id: '03', label: 'Stores', active: true },
	];
	var [venueList, updateVenueList] = useState<any>([]);
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const userKey = 'venueListKey';
	const [showPopup, setShowModal] = useState(false);
	const [checked, setChecked] = useState(false);
	const [modalText, setmodalText] = useState('');
	const [action, setAction] = useState('');
	const [id, setId] = useState(0);
	const history = useHistory();
	var [idList, updateIdList] = useState<any>([]);
	var [enableButton, updateEnableButton] = useState<any>(false);

	const url = urls.GetSearchUrl({
		url: 'experion/venue/search?',
		fields: [{ field_name: 'hosp_id', field_value: localStorage.getItem('hospitalityId')! }],
		pageSize: 2000,
		filterDeleted: true,
	});
	const obj: Partial<Iget> = {
		params: {},
		url: url,
		enabled: true,
		key: userKey,
		resultFilteringRequired: true,
	};
	const obj1: Partial<Ipost> = {
		params: {},
		url: '',
	};
	const exportOptions = {
		filename: 'export',
		fieldSeparator: ',',
		quoteStrings: '"',
		decimalSeparator: '.',
		showLabels: true,
		showTitle: false,
		title: 'Report',
		useTextFile: false,
		useBom: true,
		useKeysAsHeaders: true,
		// headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
	};

	const csvExporter = new ExportToCsv(exportOptions);
	const onExport = () => {
		csvExporter.generateCsv(venueList);
	};

	var qry = hooks.useQuery(obj);

	const routeAdd = () => {
		history.push('/admin/add-new-store');
	};
	// if (searchValue == '') {
	// 	venueList = [];
	// }
	// venueList = React.useMemo(
	// 	() => [
	// 		{
	// 		  "id": 41,
	// 		  "name": "Onehunga Cafe",
	// 		  "hosp_id": 23,
	// 		  "venue_email": "test@gmail.com",
	// 		  "description": "Onehunga Cafe",
	// 		  "address": "Onehunga Cafe",
	// 		  "zip_code": "1930",
	// 		  "phone": 1234567890,
	// 		  "latitude": 50.8858,
	// 		  "longitude": 4.4671,
	// 		  "created_by": "test",
	// 		  "updated_by": "test",
	// 		  "created_at": "2021-01-19 08:15:43",
	// 		  "updated_at": "2021-01-19 12:22:42",
	// 		  "is_active": true,
	// 		  "is_delete": null,
	// 		  "contact_name": "Test",
	// 		  "city": "",
	// 		  "state": "",
	// 		  "country": "BE",
	// 		  "number_of_parked_orders": 1,
	// 		  "contact_person_phone": 0,
	// 		},
	// 		{
	// 		  "id": 42,
	// 		  "name": "big bazar",
	// 		  "hosp_id": 23,
	// 		  "venue_email": "mmmeenu@gmail.com",
	// 		  "description": "qwewe",
	// 		  "address": "1wasdaqqq",
	// 		  "zip_code": "1222",
	// 		  "phone": 12,
	// 		  "latitude": 0,
	// 		  "longitude": 0,
	// 		  "created_by": "admin",
	// 		  "updated_by": "admin",
	// 		  "created_at": "2020-12-12 00:00:00",
	// 		  "updated_at": "2021-01-19 10:20:27",
	// 		  "is_active": true,
	// 		  "is_delete": true,
	// 		  "contact_name": "neenu somy",
	// 		  "city": "222",
	// 		  "state": "222",
	// 		  "country": "Australia",
	// 		  "number_of_parked_orders": 122,
	// 		  "contact_person_phone": 23,
	// 		}
	// 	  ],
	// 	[]
	// );

	// venueList =
	// 	(venueList?.length !== 0 && typeof venueList !== 'undefined') || searchValue != ''
	// 		? venueList
	// 		: qry.data;

	async function closePopup() {
		if (action == 'upd') {
			venueList[venueList.findIndex((x) => x.id == id)].is_active = !checked;
			updateVenueList(venueList);
			setChecked(!checked);
		}
		await setShowModal(!showPopup);
	}
	function onSubmit() {
		if (action == 'del' || action == 'bulkdel') deleteVenue();
		else if (action == 'upd') changeStatus();
	}
	function deleteVenue() {
		if (idList?.length > 0) {
			obj1.url = `experion/venue/delete`;
			obj1.data = { Id: typeof idList != 'undefined' && idList.length > 0 ? idList : [id] };
			obj1.message = `Store${typeof idList != 'undefined' && idList.length > 1 ? '(s)' : ''
				} deleted successfully`;
			api.post(obj1).then(function (d) {
				if (typeof d != 'undefined') {
					let datalist = [];
					if (typeof idList != 'undefined' && idList.length > 0) {
						datalist = venueList.filter((x) => !idList.includes(x.id));
						updateVenueList(datalist);
					} else {
						updateVenueList((venueList = venueList.filter((x) => x.id !== id)));
					}
				}
				updateIdList([]);
				setId(0);
			});
			setShowModal(!showPopup);
		}
		else {
			notify('Please select the store(s).').error()
			updateEnableButton(false);
		}
	}

	function changeStatus() {
		obj1.url = `experion/venue/updatestatus/${id}/${checked}`;
		obj1.message = 'Store status updated successfully';
		api.put(obj1).then(function (data) {
			if (data == undefined) {
				venueList[venueList.findIndex((x) => x.id == id)].is_active = !checked;
				updateVenueList(venueList);
				setChecked(!checked);
			}
		});
		setShowModal(!showPopup);
	}

	function confirm(action, id, e = null as any) {
		let checked = false;
		if (e != null) {
			checked = e.currentTarget.checked;
			venueList[venueList.findIndex((x) => x.id == id)].is_active = checked;
			updateVenueList(venueList);
		}
		switch (action) {
			case 'del':
				showConfirmationModal(action, 'Delete store?', id, checked);
				break;
			case 'bulkdel':
				if (idList.length > 0) {
					showConfirmationModal(action, 'Delete store(s)?', id, checked);
				} else {
					notify('Please select the store(s).').error()
				}
				break;
			case 'upd':
				showConfirmationModal(
					action,
					`${checked == true ? 'A' : 'Dea'}ctivate store?`,
					id,
					checked
				);
				break;
			default:
				break;
		}
	}

	function showConfirmationModal(action, text, id, checked) {
		if (action == 'upd') setChecked(checked);
		if (action != 'bulkdel') setId(id);
		setAction(action);
		setmodalText(text);
		setShowModal(!showPopup);
	}

	// useEffect(() => {
	// 	updateVenueList(venueList);
	// }, [venueList]);
	useEffect(() => {
		qry.data && updateVenueList([...qry.data]);
	}, [qry.data]);
	// event to get data from search ( child ) component
	const handleSearchFill = (data) => {
		updateVenueList(data);
	};

	//on grid row name click
	const onNameClick = (id) => {
		history.push({
			pathname: '/admin/dashboard-store',
			search: '?id=' + id,
		});
	};

	const selectAllCheckbox = (event) => {
		idList = [];
		updateIdList(idList);
		if (event.target.checked) {
			updateEnableButton(true);
			venueList.map((data) => {
				idList.push(data.id);
			});
			updateIdList(idList);
		} else {
			idList = [];
			updateIdList(idList);
			updateEnableButton(false);
		}
		let checked = event.target.checked;
		venueList = venueList.map((item) => ({ ...item, isChecked: checked }));
		updateVenueList(venueList);
	};

	const navigateToEdit = (id) => {
		history.push({
			pathname: 'edit-store',
			search: '?id=' + id,
		});
	};

	const selectCheckBox = (id, event) => {
		if (event.target.checked) {
			idList.push(id);
			updateIdList(idList);
		} else {
			idList.splice(idList.indexOf(id), 1);
			updateIdList(idList);
		}
		if (idList.length >= 1) {
			updateEnableButton(true);
		} else {
			updateEnableButton(false);
		}
	};

	const tableColumns = React.useMemo(
		() => [
			{
				Header: 'name',
				accessor: 'name', // accessor is the "key" in the data
			},
			{
				Header: 'description',
				accessor: 'description',
			},
			{
				Header: 'address',
				accessor: 'address',
			},
			{
				Header: 'Email',
				accessor: 'venue_email',
			},
			{
				Header: 'Phone',
				accessor: 'phone',
			},
			{
				Header: 'PO Limit',
				accessor: 'number_of_parked_orders',
			},
		],
		[]
	);
	const setFilteredData = (val) => {
		const sampleList = qry.data;
		if (val && val.length > 0) {
			var venueList = sampleList.filter(
				(v) =>
					v.name.toLowerCase().includes(val.toLowerCase()) ||
					(v.description && v.description.toLowerCase().includes(val.toLowerCase())) ||
					v.venue_email.toLowerCase().includes(val.toLowerCase()) ||
					v.address.toLowerCase().includes(val.toLowerCase()) ||
					v.phone.toString().includes(val) ||
					v.number_of_parked_orders.toString().includes(val)
			);
			updateVenueList(venueList);
		} else {
			updateVenueList(qry.data);
		}
	};
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	};
	window.scroll({ top: 0, left: 0, behavior: 'smooth' });
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={() => onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={() => onclickHambrgBtn()}></Sidebar>
			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbHolder>
					<BreadcrumbNav
						Heading="Stores"
						breadcrumbList={breadcrumbNav}
						RouteLink="user-dashboard"
					/>
					<ButtonWrapper>
						<Button onClick={() => routeAdd()} flex={[1, 1, 'initial']}>
							<icons.PlusIcon />

							<div>Add New Store</div>
						</Button>

						<Button onClick={() => onExport()} flex={[1, 1, 'initial']}>
							<icons.exportIcon />
							<div>Export CSV</div>
						</Button>
						{/* <Button flex={[1, 1, 'initial']} variant="secondary">
							<icons.downloadIcon />
							<div>Download</div>
						</Button> */}
					</ButtonWrapper>
				</BreadcrumbHolder>
				{/* <VenuesFullList columns={tableColumns} data={venueList}></VenuesFullList> */}
				<UserListHolder>
					<PageSearchHolder>
						<Box as="section">
							<Search
								onChange={(val) => setFilteredData(val)}
								type={'venuesList'}
								placeholder="Search for Store, Email, Phone ..."
							></Search>
						</Box>
						<Box as="small">
							<Button
								flex={[1, 1, 'initial']}
								variant="redOutline"
								onClick={() => confirm('bulkdel', idList)}
								disabled={!enableButton}
							>
								<icons.DeleteIcon />
								<div>Delete Selected</div>
							</Button>
						</Box>
					</PageSearchHolder>
					<TableList>
						<table>
							<thead>
								<tr>
									<th className="checked">
										{/* <Label>
											<Checkbox id="remember" name="remember" onChange={selectAllCheckbox} />
										</Label> */}
									</th>
									<th>Store</th>
									{/* <th>Description</th> */}
									<th>Address</th>
									<th>Email</th>
									<th>Phone</th>
									<th>Parked Orders Limit</th>
									<th className="status">Status</th>
									<th className="action">Action</th>
								</tr>
							</thead>

							<tbody>
								{/* Add (row-highlighted) class for row highlighting */}

								{venueList &&
									venueList.map(
										({
											name,
											venue_email,
											phone,
											id,
											is_active,
											description,
											address,
											number_of_parked_orders,
											isChecked,
										}) => (
											<tr key={id} className={!is_active ? 'row-highlighted' : ''}>
												<td className="checked">
													<Label>
														<Checkbox
															id="remember"
															onChange={(event) => selectCheckBox(id, event)}
															checked={isChecked}
															name="remember"
														/>
													</Label>
												</td>
												<td data-label="Venue Name">
													<Text
														as="h1"
														onClick={() => onNameClick(id)}
														fontSize={15}
														fontFamily="OpenSansMedium"
														color="text.black"
													>
														<strong>
															<span>{name}</span>
														</strong>
													</Text>
												</td>
												{/* <td data-label="description">
													<Text
														as="h1"
														fontSize={15}
														fontFamily="OpenSansMedium"
														color="text.black"
													>
														{description}
													</Text>
												</td> */}
												<td data-label="Address">
													<Text
														as="h1"
														fontSize={15}
														fontFamily="OpenSansMedium"
														color="text.black"
													>
														{address}
													</Text>
												</td>
												<td data-label="Email">
													<Text
														as="h1"
														fontSize={15}
														fontFamily="OpenSansMedium"
														color="text.black"
													>
														{venue_email}
													</Text>
												</td>
												<td data-label="Phone">
													<Text
														as="h1"
														fontSize={15}
														fontFamily="OpenSansMedium"
														color="text.black"
													>
														{phone}
													</Text>
												</td>
												<td data-label="Parked Orders Limit">
													<Text
														as="h1"
														fontSize={15}
														fontFamily="OpenSansMedium"
														color="text.black"
													>
														{number_of_parked_orders}
													</Text>
												</td>
												{/* <td data-label="noParked">
									<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
										{description}
									</Text>
								</td>
								<td data-label="Address">
									<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
										{address}
									</Text>
								</td>
								<td data-label="Email">
									<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
										{venue_email}
									</Text>
								</td>
								<td data-label="Phone">
									<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
										{phone}
									</Text>
								</td>
								{/* <td data-label="noParked">
									<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
									3
									</Text>
								</td> */}
												<td data-label="Status" className="status">
													<Toggle
														disabled={!id ? true : false}
														checked={is_active}
														onChange={(e) => confirm('upd', id, e)}
													/>
												</td>
												<td data-label="Action" className="action">
													<Text>
														<Button
															variant="deleteIcon"
															disabled={!id ? true : false}
															onClick={() => confirm('del', id)}
														>
															<icons.DeleteIcon />
														</Button>
														<Button onClick={() => navigateToEdit(id)} variant="editIcon">
															<icons.textEditIcon />
														</Button>
													</Text>
												</td>
											</tr>
										)
									)}
							</tbody>
						</table>
					</TableList>
					<SweetAlert
						show={showPopup}
						confirmBtnText="Confirm"
						onConfirm={onSubmit}
						showCancel={true}
						onCancel={closePopup}
						title={'Confirmation Box'}
					//  dependencies={[this.state.firstName, this.state.lastName]}
					>
						{() => <form>{modalText}</form>}
					</SweetAlert>
				</UserListHolder>
			</MainContent>
			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default FullVenuesList;
