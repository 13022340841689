import Iget from 'models/Iget';
import Ipost from 'models/Ipost';
import React, { ReactElement, ReactNode, SyntheticEvent, useEffect, useRef, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory } from 'react-router';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import { Box, Flex, Text } from 'rebass/styled-components';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import styled, { css } from 'styled-components';
import { Button, Loader } from 'ui/components';
import * as Icon from 'ui/icons';
import useOnClickOutside from 'use-onclickoutside';
import urls from 'utils/create.url';
import helper from 'utils/helper.functions';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import notify from 'utils/toast.service';
import DropdownButton from './DropdownButton';
import DropdownMenu from './DropdownMenu';

const QuickOrderBlock = styled.div`
	display: flex;
	align-items: center;
	.QuickOrderIcon {
		margin-left: 40px;
		outline: none;
		border: none;
		button {
			box-shadow: none !important;
		}
		@media (max-width: 480px) {
			margin-left: 10px;
		}
	}
`;
const StyledQuickOrder = styled.div<StyledQuickOrderProps>`
	display: flex;
	align-items: center;
	margin: 0 0px 0 23px;
	position: relative;
	@media (max-width: 480px) {
		margin: 0 0px 0 20px;
	}
	svg {
		height: 30px;
		width: 12px;
	}
	button {
		padding: 0;
		outline: none !important;
		box-shadow: none !important;
	}
	.menuContent {
		width: 350px !important;
		padding: 0 !important;
		display: flex !important;
		flex-direction: column !important;
		top: 53px !important;
		min-height: max-content !important;
		left: auto !important;
		right: 40px;
		@media (max-width: 960px) {
			right: 0;
			top: 44px !important;
		}
		&:before {
			right: 14px;
			left: auto !important;
		}
		.simplebar-wrapper {
			max-height: calc(100vh - 200px);
		}
		@media (max-width: 480px) {
			width: 300px !important;
			top: 59px !important;
		}
	}
	${({ hide }) =>
		hide &&
		css`
			display: none;
		`}
	${({ show }) =>
		show &&
		css`
			display: block;
		`}
`;
const QuickOrderListHolder = styled.div`
	width: 100%;
	overflow: auto;
	padding: 20px;
	.simplebar-wrapper {
		max-height: calc(100vh - 350px);
	}
	@media (max-width: 960px) {
		max-height: 200px;
	}
	h1 {
		font-size: 14px;
		line-height: 26px;
		font-weight: normal;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;

		&:hover {
			color: ${({ theme }) => theme.baseColors.primary};
		}
	}
	h2 {
		font-size: 14px;
		line-height: 26px;
		text-align: center;
		font-family: 'OpenSansMedium';
		color: ${({ theme }) => theme.colors.text.black};
	}
	div {
		padding: 0;
	}

	section {
		border-bottom: 1px solid ${({ theme }) => theme.colors.border.grayLightBorder01};
		padding: 15px 0;
		&:first-child {
			padding-top: 0;
		}
		&:last-child {
			padding-bottom: 0;
			border-bottom: none !important;
		}
		div {
			display: none;
		}
		&.active {
			display: flex !important;
			background: transparent !important;
			div {
				display: flex !important;
			}
			h1 {
				/* font-family: 'OpenSansBold'; */
				color: ${({ theme }) => theme.baseColors.primary};
				font-size: 14px;
			}
		}
	}
	.simplebar-track {
		right: -14px;
	}
	.simplebar-track.simplebar-vertical {
		width: 8px;
	}
`;
const IconHolder = styled.div`
	display: flex;
	align-items: center;
	span {
		svg {
			width: 15px;
			height: 15px;
			path {
				fill: ${({ theme }) => theme.baseColors.primary};
			}
		}
		+ span {
			svg {
				path {
					fill: ${({ theme }) => theme.baseColors.red}!important;
				}
			}
		}
	}
	button {
		background: transparent !important;
		border: none !important;
		margin-left: 10px;

		span {
			margin: 0;
			svg {
				path {
					fill: ${({ theme }) => theme.baseColors.red}!important;
				}
			}
		}
	}
`;
export type QuickOrderProps = {
	trigger?: string | ReactNode;
	menuList?: Array<item>;
	menuContent?: ReactNode;
	autoClose?: boolean;
	position?: 'right-top' | 'right' | 'left-top' | 'left';
	ref?: React.RefObject<HTMLDivElement>;
	onClick?: (e: any) => void;
};
type StyledQuickOrderProps = {
	hide?: boolean;
	show?: boolean;
};
type item = {
	id?: number;
	value: string;
	action?: () => void;
	icon?: ReactNode;
	groupEnd?: boolean;
};

function QuickOrder({ autoClose = true, position }: QuickOrderProps): ReactElement {
	// const [addFavorites, setAddFavorites] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const pathName = window.location.pathname;
	const [page, setPage] = useState('');
	const [modalText, setmodalText] = useState('');
	const [showPopup, setShowModal] = useState(false);
	const [showItem, setShowItem] = useState(true);
	let existInQuickAction = false;
	const history = useHistory();
	// const handleOnChange = (e: any) => {
	// 	setAddFavorites(e.target.id);
	// };
	const [open, setOpen] = useState(false);
	const handleOnClickOutside = () => {
		setOpen(false);
	};
	const wrapperRef = useRef<HTMLDivElement>(null);
	useOnClickOutside(wrapperRef, handleOnClickOutside);
	const handleToggle = (e: SyntheticEvent<HTMLDivElement>) => {
		e.stopPropagation();
		setOpen(autoClose === true ? !open : true);
	};
	const getQuickAccessUrl = urls.GetSearchUrl({
		url: 'experion/quickaccess/search?',
		pageSize: 10,
		filterDeleted: true,
		fields: [
			{
				field_name: 'venue_user_id',
				field_value: localStorage.getItem('userId')!,
			},
			{
				field_name: 'venue_id',
				field_value: localStorage.getItem('selectedVenue')!,
			},
		],
	});
	const qckAccessObj: Partial<Iget> = {
		params: {},
		url: getQuickAccessUrl,
		enabled: true,
		key: 'quickAccess',
		resultFilteringRequired: true,
	};

	const quickAccessQry = hooks.useQuery(qckAccessObj);
	useEffect(() => {
		let show = true;
		if (quickAccessQry.data && quickAccessQry.data.length > 0) {
			let item = quickAccessQry.data?.filter((item) => item.name === page);
			if (item && item.length > 0) {
				show = false;
			}
		}
		setShowItem(show);
	}, [quickAccessQry.data]);
	useEffect(() => {
		let pageName = helper.returnPage(pathName);
		if (pageName && pageName != '') {
			setPage(pageName);
		}
	}, [pathName]);
	const addQuickAccessScreen = () => {
		existInQuickAction = false;
		if (quickAccessQry.data && quickAccessQry.data.length > 0 && quickAccessQry.data.length < 3) {
			quickAccessQry.data.map((item) => {
				if (item.name == page) {
					notify('Already included as a quick access screen').error();
					//setExistInQuickAction(true);
					existInQuickAction = true;
				}
			});
		}
		if (pathName && page != '' && !existInQuickAction) {
			const payload = {
				venue_id: parseInt(localStorage.getItem('selectedVenue')!),
				name: page,
				venue_user_id: parseInt(localStorage.getItem('userId')!),
				referral_url: pathName,
				is_active: true,
			};
			const obj: Partial<Ipost> = {
				data: { quickAccess: payload },
				params: {},
				url: `experion/quickaccess/create`,
				message: `Quick access updated successfully`,
			};
			try {
				api.post(obj).then(function (data) {
					if (data) {
						quickAccessQry.refetch();
					}
				});
				setIsLoading(false);
			} catch (e) {
				setIsLoading(false);
			}
		}
	};
	// function showConfirmationModal(text) {
	// 	setmodalText(text);
	// 	setShowModal(!showPopup);
	// }
	const obj1: Partial<Ipost> = {
		params: {},
		url: '',
	};
	const deleteQuickAccess = (id) => {
		obj1.url = `experion/quickaccess/delete/${id}`;
		obj1.message = 'Quick access screen deleted successfully';
		api.delete(obj1).then((data) => {
			if (data != undefined) {
				quickAccessQry.refetch();
			}
		});
	};
	const onCancel = () => {
		setShowModal(!showPopup);
	};
	return (
		<QuickOrderBlock>
			{isLoading == true ? <Loader></Loader> : null}
			<StyledQuickOrder show ref={wrapperRef} onClick={handleToggle}>
				<DropdownButton />
				{open && (
					<DropdownMenu position={position}>
						<Box className="menuContent">
							<QuickOrderListHolder>
								<SimpleBar>
									{quickAccessQry && quickAccessQry.data && quickAccessQry.data.length > 0 ? (
										quickAccessQry.data.map((item, index) => (
											<Flex
												as="section"
												className="active"
												alignItems="center"
												width="100%"
												pl={[1, 2]}
												key={index}
											>
												<Text
													as="h1"
													fontFamily="OpenSansMedium"
													flex="1"
													px={2}
													pl={0}
													style={{ cursor: 'pointer' }}
													onClick={() => history.push(item.referral_url)}
												>
													{item.name}
												</Text>
												<IconHolder>
													{/* <Icon.Tick /> */}
													<Button disabled={!item.id} onClick={() => deleteQuickAccess(item.id)}>
														<Icon.DeleteIcon />
													</Button>
												</IconHolder>
											</Flex>
										))
									) : (
										<Text as="h2" flex="1">
											No Quick Access Screens
										</Text>
									)}

									{/* <Flex as="section" alignItems="center" width="100%" pl={[1, 2]}>
										<Text as="h1" fontFamily="OpenSansSemiBold" flex="1" px={2}>
											Burger collections
										</Text>
										<IconHolder>
											<Icon.Tick />
											<Button>
												<Icon.DeleteIcon />
											</Button>
										</IconHolder>
									</Flex>
									<Flex as="section" alignItems="center" width="100%" pl={[1, 2]}>
										<Text as="h1" fontFamily="OpenSansSemiBold" flex="1" px={2}>
											Beverage collections
										</Text>
										<IconHolder>
											<Icon.Tick />
											<Button>
												<Icon.DeleteIcon />
											</Button>
										</IconHolder>{' '}
									</Flex> */}
								</SimpleBar>
							</QuickOrderListHolder>
						</Box>
					</DropdownMenu>
				)}
			</StyledQuickOrder>
			{showItem &&
				(page == 'Venue User Management' ||
					page == 'Venue Management' ||
					page == 'Role Management') && (
					<div className="QuickOrderIcon">
						<div className="order_icon" style={{ display: 'none' }}>
							<Tooltip
								arrow
								title="Quick Access"
								animation="perspective"
								position="bottom"
								trigger="mouseenter"
							>
								<Button
									onClick={addQuickAccessScreen}
									iconBefore={<Icon.quickOrderIcon title="orderIcon" />}
									variant="cartButton"
								></Button>
							</Tooltip>
						</div>
						<div className="add_icon">
							<Tooltip
								arrow
								title="Quick Access"
								animation="perspective"
								position="bottom"
								trigger="mouseenter"
							>
								<Button
									onClick={addQuickAccessScreen}
									iconBefore={<Icon.quickOrderAddIcon title="addIcon" />}
									variant="cartButton"
								></Button>
							</Tooltip>
						</div>
						<div className="delete_icon" style={{ display: 'none' }}>
							<Tooltip
								arrow
								title="Quick Access"
								animation="perspective"
								position="bottom"
								trigger="mouseenter"
							>
								<Button
									onClick={addQuickAccessScreen}
									iconBefore={<Icon.quickOrderDeleteIcon title="addIcon" />}
									variant="cartButton"
								></Button>
							</Tooltip>
						</div>
					</div>
				)}
			<SweetAlert
				show={showPopup}
				confirmBtnText="Confirm"
				onConfirm={onCancel}
				showCancel={false}
				title={'Confirmation Box'}
			>
				{() => <form>{modalText}</form>}
			</SweetAlert>
		</QuickOrderBlock>
	);
}

export { QuickOrder };
