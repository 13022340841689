import styled from 'styled-components';
import { getHSL } from 'ui/utils/getHSL';

const MainContent = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
	padding: 0 50px;
	@media (max-width: 960px) {
		padding: 0 15px;
	}
`;
const AddUserHolder = styled.div`
	width: 100%;
	background: ${({ theme }) => theme.colors.bg.white};
	position: relative;
	margin: 0 auto;
	border: 1px solid ${({ theme }) => theme.colors.border.userListTD};
	box-sizing: border-box;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07);
	border-radius: 8px;
	margin-bottom: 50px;
`;
const FooterBtnBlock = styled.div`
	padding: 0 50px 58px;
	button {
		min-width: 100px;
		text-transform: Uppercase;
		padding: 0;
		height: 48px;
		flex: 0 0 100px;
	}
	@media (max-width: 767px) {
		button {
			flex: 0 0 100px;
		}
	}
`;
const ProfileDetails = styled.div`
	margin: 50px 50px 100px;
	@media (max-width: 640px) {
		margin: 15px;
		padding-bottom: 30px;
	}

	h1 {
		text-transform: uppercase;
		margin-bottom: 25px;
		@media (max-width: 640px) {
			margin-bottom: 15px;
		}
	}
	form {
		width: 865px;
		display: grid;
		grid-template-columns: repeat(1, 1fr) !important;
		column-gap: 20px;
		row-gap: 20px;
		@media (max-width: 640px) {
			grid-template-columns: repeat(1, 1fr) !important;
		}
		.categorySelect {
			grid-column: auto;
		}
		div {
			// width: 100%;
			grid-template-columns: repeat(2, 1fr) !important;
			@media (max-width: 640px) {
				grid-template-columns: repeat(1, 1fr) !important;
			}
			label {
				font-size: 16px;
				line-height: 21px;
				font-family: 'OpenSansSemiBold';
				color: ${({ theme }) => theme.colors.text.SubHeading};
				margin-bottom: 8px;
			}
		}
		.vacation-details {
			margin-top: 23px;
			grid-template-columns: repeat(1, 1fr) !important;
			.requiredText {
				font-style: italic;
				font-weight: 600;
				font-size: 12px;
				line-height: 16px;
				margin-left: 8px;
				color: #fc0404;
				@media (max-width: 640px) {
					display: block;
					margin-left: 0;
				}
			}
		}
		.full-width {
			width: 100%;
			> div {
				width: 100%;
			}
		}
		.datepicker-c {
			@media (max-width: 640px) {
				width: 100%;
			}
			& + .datepicker-c {
				margin-left: 18px;
			}
			.pr-icon {
				top: 47px;
			}
		}
		.inline-checkbox {
			margin-top: 23px;
			div {
				width: 100%;
			}
			h1 {
				margin: 0;
			}
		}
		@media (max-width: 1100px) {
			width: 100%;
		}
	}
	section {
		float: left;
		display: flex;
		flex-direction: column;
		min-height: 60px;
		align-items: flex-start;
		label + label {
			height: 50px;
			display: flex;
			padding: 0;
			margin: 0;
		}
	}
`;
const AutocompleteHolder = styled.div`
	padding: 0;
	width: 100%;
	div {
		width: 100%;
		position: relative;
		margin-top: 1.5%;
	}
	.icon-container {
		position: relative;
		top: -40px;
	}
	.loader {
		position: relative;
		float: right;
		right: 1%;
		height: 20px;
		width: 20px;
		display: inline-block;
		animation: around 5.4s infinite;
	}

	@keyframes around {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	.loader::after,
	.loader::before {
		content: '';
		background: white;
		position: absolute;
		display: inline-block;
		width: 100%;
		height: 100%;
		border-width: 2px;
		border-color: #333 #333 transparent transparent;
		border-style: solid;
		border-radius: 20px;
		box-sizing: border-box;
		top: 0;
		left: 0;
		animation: around 0.7s ease-in-out infinite;
	}

	.loader::after {
		animation: around 0.7s ease-in-out 0.1s infinite;
		background: transparent;
	}
	input {
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.5;
		height: 50px;
		width: 100%;
		--px: 0.75em;
		--py: 0.75em;
		--a: 80%;
		--h: ${({ theme }) => getHSL('h', theme.colors.border.default)};
		--s: ${({ theme }) => getHSL('s', theme.colors.border.default)};
		--l: ${({ theme }) => getHSL('l', theme.colors.border.default)};
		--input-bg: #fff;
		display: block;
		width: 100%;
		height: calc(calc(var(--py) * 2) + calc(var(--px) * 2) + 2px);
		padding: var(--py) var(--px);
		font-family: 'OpenSansSemiBold';
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.5;
		color: ${({ theme }) => theme.colors.text.body};
		background-color: var(--input-bg);
		background-clip: padding-box;
		border: 1px solid ${({ theme }) => theme.colors.border.default};
		border-radius: ${({ theme }) => theme.radii.softer};
		transition: all 200ms ease-in-out;
		-webkit-appearance: none;
		appearance: none;

		&:focus {
			background-color: #fff;
			border-color: ${({ theme }) => theme.colors.primary};
			outline: 0;
			box-shadow: 0 0 0 0.2rem hsla(var(--h), var(--s), var(--l), 30%);
		}
		& + div {
			position: absolute !important;
			width: 100% !important;
			top: 50px !important;
			left: 0 !important;
			max-height: 250px !important;
			z-index: 9;
			box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.39) !important;

			div {
				padding: 10px;
				border-bottom: 1px solid rgba(189, 188, 185, 0.5);
				font-family: 'OpenSansSemiBold';
				font-size: 17px;
				cursor: pointer;
				color: ${({ theme }) => theme.colors.text.black07};
				&:hover,
				&:focus,
				&:active {
					background: ${({ theme }) => theme.colors.primary} !important;
					color: ${({ theme }) => theme.colors.text.white};
				}
			}
		}
	}
`;
export { MainContent, FooterBtnBlock, AddUserHolder, ProfileDetails, AutocompleteHolder };
