import { pageNavigators } from 'models/enum-constants/common.constants';
import React, { ReactElement } from 'react';
import { Text } from 'rebass/styled-components';
import styled, { css } from 'styled-components';
import * as Icon from 'ui/icons';
type PaginationItemProps = {
	active?: boolean;
};
const StyledPaginationHolder = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 23px 50px 55px 30px;
	&.other-page{
		padding: 23px 0px 55px 0px;
	}
	@media (max-width: 768px) {
		padding: 23px 50px 0 30px;
		margin-bottom:30px;
		&.other-page{
			padding: 23px 0px 55px 0px;
		}
	}
	@media (max-width: 640px) {
		flex-direction: column;
		padding: 23px 0 0 0;
		&.other-page{
			padding: 23px 0px 55px 0px;
		}
	}

	.PageSearchCategory {
		width: 50px;
		height: 26px;
		font-size: 14px;
		padding: 0 7px 0 7px;
		border-radius: 4px;
		border: 1px solid ${({ theme }) => theme.colors.border.yellowDark};
		margin: 0 0 0 0;
		background: ${({ theme }) => theme.colors.bg.white};
		color: ${({ theme }) => theme.baseColors.baseYellow};

		&:focus {
			outline: none;
			box-shadow: none;
		}
	}
	h1 {
		font-size: 14px;
		line-height: 16px;
		font-family: 'OpenSansRegular';
		font-weight: normal;
		color: ${({ theme }) => theme.colors.text.black07};
		margin: 0 0 0 8px;
		strong {
			color: ${({ theme }) => theme.colors.text.black07};
			font-family: 'OpenSansSemiBold';
		}
	}
`;

const StyledPagination = styled.nav`
	display: inline-flex;
	align-items: center;
	@media (max-width: 640px) {
		margin-top: 15px;
	}
	ul {
		margin: 0;
		margin-left: auto;
		display: flex;
		padding-left: 0;
		list-style: none;
		border-radius: ${({ theme }) => theme.radii.rounded};
	}
`;
const ButtonItem = styled.button`
	background: transparent;
	border: transparent;
	cursor: pointer;
	margin: 0 3px;
	padding: 0.563rem 0.563rem;
	border-radius: 5px;
	outline: none;
	width: 26px;
	height: 26px;

	&:hover {
		background: ${({ theme }) => theme.baseColors.baseYellow};
		color: ${({ theme }) => theme.colors.text.white};
		svg {
			path {
				fill: ${({ theme }) => theme.colors.text.white};
			}
		}
	}
`;
const PaginationItem = styled.a<PaginationItemProps>`
	position: relative;
	display: block;
	padding: 0.313rem 0.583rem;
	font-size: 13px;
	margin: 0 3px;
	line-height: 16px;
	text-decoration: none;
	color: ${({ theme }) => theme.colors.text.grayMedium};
	border-radius: 5px;
	cursor: pointer;
	&:hover {
		background: ${({ theme }) => theme.baseColors.baseYellow};
		color: ${({ theme }) => theme.colors.text.white};
	}
	${({ active }) =>
		active &&
		css`
			background: ${({ theme }) => theme.baseColors.baseYellow};
			color: ${({ theme }) => theme.colors.text.white};
		`}
`;
// const PaginationSelect = styled.div`
// 	position: relative;
// 	div {
// 		svg {
// 			display: none;
// 		}
// 	}
// 	.pr-icon {
// 		position: absolute;
// 		top: 11px;
// 		right: 9px;
// 	}
// `;
const PaginationLeft = styled.div`
	display: flex;
	align-items: center;
	.pagerDropdown{
		border: 1px solid #E6EBEE; 
		border-radius: 4px;
		min-width: 60px;
		font-size: 12px;
	}
`;

//to render pagination numbers
var bindPagination = (elements, currentPage, setPage) => {
	return elements.map((el) => {
		return (
			<PaginationItem style={{caretColor:"transparent"}}
				active={currentPage == el ? true : false}
				key={el}
				onClick={() => {
					setPage(() => el);
				}}
			>
				{el}
			</PaginationItem>
		);
	});
};
///function to get get to count
var getToCount = (props) => {
	return props.itemsPerPage > props.total
			? props.total
			: (props.page - 1) * props.itemsPerPage +
			  ((Math.ceil(props.total / props.itemsPerPage) == props.page) &&
			  (props.total % props.itemsPerPage != 0)
					? props.total % props.itemsPerPage
					: props.itemsPerPage);
};

function Pagination(props): ReactElement {
	return (
		//add other-page class for- StyledPaginationHolder- for pages other than listpage  className="other-page"
		<StyledPaginationHolder>
			<PaginationLeft>
			{/* add dropdown pagination when needed */}
			{/* <Select className="pagerDropdown"
				id='selectPage'
				name='selectPage'
				defaultValue=''>
				 
				<option > 09 
				</option> 
			</Select> */}
				<Text as="h1">
					Showing <strong>{props.page != 1 ? (props.page - 1) * props.itemsPerPage + 1 : 1}</strong>-{' '}
					<strong>{getToCount(props)}</strong> of <strong> {props.total}</strong> results
				</Text>
			</PaginationLeft>

			<StyledPagination>
				<ButtonItem
					onClick={() => {
						props.handlePagination(pageNavigators.fastPrev);
					}}
					disabled={props.page === 1}
				>
					<Icon.paginationLeftDoubleArrowIcon />
				</ButtonItem>
				<ButtonItem
					onClick={() => {
						props.handlePagination(pageNavigators.prev);
					}}
					disabled={props.page == 1}
				>
					<Icon.paginationLeftArrowIcon />
				</ButtonItem>
				{props.bindPagination()}
				<ButtonItem
					onClick={() => {
						props.handlePagination(pageNavigators.next);
					}}
					disabled={props.page == Math.ceil(props.total / props.itemsPerPage)}
				>
					<Icon.paginationRightArrowIcon />
				</ButtonItem>

				<ButtonItem
					onClick={() => {
						props.handlePagination(pageNavigators.fastNext);
					}}
					disabled={props.page == Math.ceil(props.total / props.itemsPerPage)}
				>
					<Icon.paginationRightDoubleArrowIcon />
				</ButtonItem>
			</StyledPagination>
		</StyledPaginationHolder>
	);
}

export { Pagination, bindPagination };
