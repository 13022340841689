import { useEffect } from 'react';
import { PayPalButtons as PayPalButton, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import React from 'react';
import constants, { APP_TOKEN } from 'utils/constants';
import Ipost from 'models/Ipost';
import { UseGlobalState } from 'models/globalStateAccess';
import helperFunctions from 'utils/helper.functions';
import { useHistory } from 'react-router-dom';
import httpService from 'utils/http.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import { generatePaymentToken } from 'utils/paypalTokenGenerator';

// This values are the props in the UI
const amount = '2';
const currency = 'USD';
// const style = {"layout":"vertical"};

// Custom component to wrap the PayPalButtons and handle currency changes
const PaypalButtonWrapper = ({ currency, showSpinner }) => {
	// usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
	// This is the main reason to wrap the PayPalButtons in a new component
	const [{ options, isPending }, dispatchPP] = usePayPalScriptReducer();
	const customertId = localStorage.getItem('hgAdminId');
	const quoteId = localStorage.getItem('cartId');
	const [state, dispatch] = UseGlobalState();
	const history = useHistory();
	const [mutatePost] = hooks.useMutation('placeOrderKey');

	const setPaypalInfo = async (data) => {
		const customer_id = localStorage.getItem('hgAdminId');
		const token = await generatePaymentToken(customer_id);
		const url = `${constants.setPaypalInfo()}?paymentToken=${token}&payerId=${
			data.payerID
		}&quoteId=${quoteId}&id=${data.orderID}&customerId=${customertId}`;
		await httpService.GET({ url: url }).then((response) => {
			if (response.data) {
				console.table(response.data);
			}
		});
	};

	const onSubmit = (data) => {
		const userId = localStorage.getItem('userId');
		const adminId = localStorage.getItem('hgAdminId');

		const country =
			state?.venueDetails?.country?.length > 2
				? helperFunctions.getCountryId(state?.venueDetails.country)
				: state?.venueDetails.country;
		const region =
			state?.venueDetails?.state?.length > 4
				? helperFunctions.getRegionId(state?.venueDetails.country, state?.venueDetails.state)
				: helperFunctions.getRegionName(country, state?.venueDetails.state);

		setPaymentOption(data, userId, country, region);
	};

	const setPaymentOption = (data, userId, country, region) => {
		const payloadJSON = {
			cartId: localStorage.getItem('cartId'),
			paymentMethod: {
				method: state.selectedOption || 'paypal_express',
			},
		};
		const obj: Partial<Ipost> = {
			data: payloadJSON,
			params: {},
			url: constants.setPaymentMethod(),
			message: ' ',
			headers: { Authorization: localStorage.getItem('customerToken') },
		};
		try {
			mutatePost(obj).then((response) => {
				if (response) {
					setPaypalInfo(data);
					// makePayment(userId, country, region);
				}
			});
		} catch (e) {
			console.error(e);
		}
	};

	const makePayment = (userId, country, region) => {
		const shippingAddressId = localStorage.getItem('defaultAddressId') || '';
		const payloadJSON = {
			paymentMethod: {
				method: state.selectedOption,
				extension_attributes: {
					venue_id: state?.venueDetails?.id,
					venue_user_id: parseInt(userId || '0'),
					//FIXME:Remove comment
					// comments: [
					// 	{
					// 		supplier_id: comments?.supplierId,
					// 		comment: comments?.comment
					// 	}
					// ]
				},
			},
			billingAddress: {
				// id: parseInt(shippingAddressId),
				// region_id: region?.id,
				region_code: region?.code,
				country_id: country,
				street: [state?.venueDetails?.address],
				telephone: state?.venueDetails?.phone,
				postcode: state?.venueDetails?.zip_code,
				city: state?.venueDetails?.city,
				firstname: state?.venueDetails?.contact_name.split(' ')[0],
				lastname: state?.venueDetails?.contact_name.split(' ')[2]
					? state?.venueDetails?.contact_name.split(' ')[2]
					: state?.venueDetails?.contact_name.split(' ')[1]
					? state?.venueDetails?.contact_name.split(' ')[1]
					: '',
				middlename:
					state?.venueDetails?.contact_name.split(' ')[1] &&
					state?.venueDetails?.contact_name.split(' ')[2] == undefined
						? state?.venueDetails?.contact_name.split(' ')[1]
						: '',
				customer_id: userId,
				email: state?.venueDetails?.venue_email,
				same_as_billing: 0,
				// customer_address_id: 1,
				save_in_address_book: 0,
			},
		};

		const obj: Partial<Ipost> = {
			data: payloadJSON,
			params: {},
			url: constants.paymentURL(),
			message: ' ',
			headers: { Authorization: localStorage.getItem('customerToken') },
		};
		try {
			mutatePost(obj).then((data) => {
				if (data !== undefined) {
					localStorage.setItem('orderId', data);
					localStorage.removeItem('deliverySlot');
					dispatch({ cartItemCount: '0' });
					dispatch({ cartTotal: '' });
					history.push('/admin/confirm');
				}
			});
		} catch (e) {
			console.error(e);
		}
	};

	useEffect(() => {
		dispatchPP({
			type: 'resetOptions',
			value: {
				...options,
				currency: currency,
			},
		});
	}, [currency, showSpinner]);

	const createOrder = (data, actions) => {
		return actions.order
			.create({
				purchase_units: [
					{
						amount: {
							currency_code: currency,
							value: amount,
						},
					},
				],
			})
			.then((orderID) => {
				return orderID;
			});
	};
	return (
		<>
			{showSpinner && isPending && <div className="spinner" />}
			<PayPalButton
				// style={style}
				disabled={false}
				forceReRender={[amount, currency]}
				fundingSource={undefined}
				createOrder={createOrder}
				// createOrder={(data, actions) => {
				// 	return actions.order
				// 		.create({
				// 			purchase_units: [
				// 				{
				// 					amount: {
				// 						currency_code: currency,
				// 						value: amount,
				// 					},
				// 				},
				// 			],
				// 		})
				// 		.then((orderId) => {
				// 			// Your code here after create the order
				// 			return orderId;
				// 		});
				// }}
				onApprove={function (data, actions) {
					if (actions?.order)
						return actions?.order?.authorize().then(function () {
							// Your code here after capture the order
							onSubmit(data);
						});
					return Promise.resolve();
				}}
			/>
		</>
	);
};

export default PaypalButtonWrapper;
