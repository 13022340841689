import { UserBody } from 'admin/dashboardVenue/styles';
import { condition } from 'models/enum-constants/common.constants';
import Iget from 'models/Iget';
import Ipost from 'models/Ipost';
import React, { ReactElement, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory } from 'react-router-dom';
import { Box, Text } from 'rebass/styled-components';
import { BreadcrumbNav, Button, Footer, Header, Loader, PageSearchHolder, Search, TableList, Toggle } from 'ui/components';
import icons, * as Icon from 'ui/icons';
import { Sidebar } from 'ui/patterns';
import urls from 'utils/create.url';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import notify from 'utils/toast.service';
import { BreadcrumbHolder, ButtonWrapper, MainContent } from './styles';

function UserList(): ReactElement {
	const history = useHistory()
	var [userList, updateUserList] = useState<any>([]);
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const [checked, setChecked] = useState(false);
	const [modalText, setmodalText] = useState('');
	const [action, setAction] = useState('');
	const [id, setId] = useState(0);
	const [showPopup, setShowModal] = useState(false);
	var [idList, updateIdList] = useState<any>([]);
	var [enableButton, updateEnableButton] = useState<any>(false);
	const breadcrumbNav = [
		{ id: '01', label: 'Dashboard', route: '/admin/seller-dashboard', active: false },
		{ id: '02', label: 'User Management', active: true },
	];
	const userKey = 'dcUserList';
	const url =
		urls.GetSearchUrl({
			url: 'experion/supplycompanyaccount/dcuser/search?',
			fields: [
				{
				field_name: 'sc_id',
				field_value: localStorage.getItem("supplierId")!
			},
			{
				field_name: 'dc_user_id',
				field_value: localStorage.getItem("SuAdminId")!,
				field_conditionType : condition.neq
			}
		],
			filterDeleted: true,
			ctx: { currentPage: 1 }
		});
	const obj: Partial<Iget> = {
		params: {},
		url: url,
		enabled: true,
		key: userKey,
		resultFilteringRequired: true,
	};
	var qry = hooks.useQuery(obj);

	useEffect(() => {
		qry.data && updateUserList([...qry.data]);
	}, [qry.data]);
	const checkDc = 'checkDcExist';
	const isDcExistsUrl = `experion/supplycompanyaccount/dcuser/dcexists`;
	const validateDc: Partial<Iget> = {
		params: {},
		url: isDcExistsUrl,
		enabled: false,
		key: checkDc,
	};
	const qryValidateDc = hooks.useQuery(validateDc);
	const navigation = () => {
		qryValidateDc.refetch().then((data) => {
			if (data) {
				history.push('new-dc-user');
			}
			else {
				notify('Cannot add DC users without having at least one DC').error();
			}
		});
	}
	function confirm(action, id, e = null as any) {
		let checked = false;
		if (e != null) {
			checked = e.currentTarget.checked;
			userList[userList.findIndex((x) => x.id == id)].is_active = checked;
			updateUserList(userList);
		}
		switch (action) {
			case 'del':
				showConfirmationModal(action, 'Delete User?', id, checked);
				break;
			case 'upd':
				showConfirmationModal(
					action,
					`${checked == true ? 'A' : 'Dea'}ctivate ?`,
					id,
					checked
				);
				break;
			default:
				break;
		}
	}
	function showConfirmationModal(action, text, id, checked) {
		if (action == 'upd') setChecked(checked);
		setAction(action);
		setId(id);
		setmodalText(text);
		setShowModal(!showPopup);
	}
	// const selectAllCheckbox = (event) => {
	// 	idList = [];
	// 	updateIdList(idList);
	// 	if (event.target.checked) {
	// 		updateEnableButton(true);
	// 		userList.map((data) => {
	// 			idList.push(data.id);
	// 		});
	// 		updateIdList(idList);
	// 	} else {
	// 		idList = [];
	// 		updateIdList(idList);
	// 		updateEnableButton(false);
	// 	}
	// 	let checked = event.target.checked;
	// 	userList = userList.map((item) => ({ ...item, isChecked: checked }));
	// 	updateUserList(userList);
	// };

	const navigateToEdit = (id) => {
		history.push({
			pathname: 'edit-dc-user',
			search: '?id=' + id,
		});
	};

	// const selectCheckBox = (id, event) => {
	// 	if (event.target.checked) {
	// 		idList.push(id);
	// 		updateIdList(idList);
	// 	} else {
	// 		idList.splice(idList.indexOf(id), 1);
	// 		updateIdList(idList);
	// 	}
	// 	if (idList.length >= 1) {
	// 		updateEnableButton(true);
	// 	} else {
	// 		updateEnableButton(false);
	// 	}
	// };
	const obj1: Partial<Ipost> = {
		params: {},
		url: '',
	};
	// const deleteUserList = (id) => {
	// 	obj1.url = `aria-venue/deletevenue/delete`;
	// 	obj1.data = { Id: [id] };
	// 	obj1.message = 'Users deleted successfully';
	// 	api.post(obj1);
	// 	// let checked = false;
	// 	// venueList = venueList.map(item => ({ ...item, isChecked: checked }));
	// 	// updateVenueList(venueList);
	// };
	// const handleSearchFill = (data) => {
	// 	updateUserList(data);
	// };
	function onSubmit() {
		if (action == 'del') deleteDC();
		else if (action == 'upd') changeStatus();
	}
	function deleteDC() {
		obj1.url = `experion/supplycompanyaccount/dcuser/${id}`;
		obj1.data = { Id: [id] };
		obj1.message = 'User deleted successfully';
		api.delete(obj1).then(function (d) {
			if (typeof d != 'undefined') {
				qry.refetch().then(function (data) {
					updateUserList(data);
				})
			}
		});
		setShowModal(!showPopup);
	}

	function changeStatus() {
		obj1.url = `experion/supplycompanyaccount/activedeactive/${id}/${checked}`;
		obj1.message = 'DC User status updated successfully';
		api.put(obj1);
		setShowModal(!showPopup);
	}
	async function closePopup() {
		if (action == 'upd') {
			userList[userList.findIndex(x => x.id == id)].is_active = !checked;
			updateUserList(userList);
			setChecked(!checked);
		}
		await setShowModal(!showPopup);
	}
	const setFilteredData = (val) => {
		const sampleList = qry.data;
		if (val && val.length > 0) {
			var list = sampleList.filter(
				(v) =>
					v.first_name.toLowerCase().includes(val.toLowerCase()) ||
					v.last_name.toLowerCase().includes(val.toLowerCase()) ||
					v.dc_user_email.toLowerCase().includes(val.toLowerCase()) ||
					v.phone.toString().includes(val)
			);
			updateUserList(list);
		}
		else {
			updateUserList(qry.data);
		}
	};

	const onNameClick = (id) => {
		history.push({
			pathname: 'dc-user-Details',
			search: '?id=' + id,
		});
	};
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	}
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={()=>onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={()=>onclickHambrgBtn()}></Sidebar>
			{qry.data == null ? <Loader></Loader> : null}
			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbHolder>
					<BreadcrumbNav Heading="User Management " breadcrumbList={breadcrumbNav} />
					<ButtonWrapper>
						<Button onClick={navigation} flex={[1, 1, 'initial']}>
							<icons.PlusIcon />
							<div>New Users</div>
						</Button>
						 <Button onClick={() => history.push('dc-user-role-management')} flex={[1, 1, 'initial']} variant="secondary">
							<div>User Role Management</div>
							<icons.rightLongArrowIcon />
						</Button>
					</ButtonWrapper>
				</BreadcrumbHolder>

				<PageSearchHolder>
					<Box as="section">
						<Search
							onChange={(val) => setFilteredData(val)}
							type={'dcUserList'}
							placeholder="Search for Name, Email, Phone ...">
						</Search>
					</Box>
					{/* <Box as="small">
						<Button flex={[1, 1, 'initial']} variant="redOutline" onClick={() => deleteUserList(idList)}
							disabled={!enableButton}>
							<icons.DeleteIcon />
							<div>Delete Selected</div>
						</Button>
					</Box> */}
				</PageSearchHolder>
				<TableList>
					<table>
						<thead>
							<tr>
								{/* <th className="checked">
									<Label>
										<Checkbox id="remember" name="remember" onChange={selectAllCheckbox} />
									</Label>
								</th> */}
								<th>First Name</th>
								<th>Last Name</th>
								<th>Email</th>
								<th>Phone</th>
								<th className="status">Status</th>
								<th className="action">Action</th>
							</tr>
						</thead>

						<tbody>
							{userList && userList.length > 0 ?
								(userList.filter((x) => x.is_pdelete !== true)).map(
									({
										id,
										first_name,
										last_name,
										dc_user_email,
										phone,
										dc_user_id,
										is_active,
									}, index) => (
										<tr key={index}>
											{/* <td className="checked">
												<Label>
													<Checkbox id="remember" name="remember" />
												</Label>
											</td> */}
											<td data-label="First Name">
												<Text onClick={() => onNameClick(id)} as="h1" fontSize={15} fontFamily="OpenSansBlack" color="text.black">
													<span>{first_name} </span>
												</Text>
											</td>
											<td data-label="Last Name">
												<Text onClick={() => onNameClick(id)} as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
													<strong>{last_name}</strong>
												</Text>
											</td>
											<td data-label="Email">
												<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
													{dc_user_email}
												</Text>
											</td>
											<td data-label="Phone">
												<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
													{phone}
												</Text>
											</td>

											<td className="status" data-label="Status">
												<Toggle
													disabled={!id ? true : false}
													checked={is_active}
													onChange={(e) => confirm('upd', id, e)} />
											</td>
											<td className="action" data-label="Action">
												<Text>
													<Button variant="deleteIcon"
														disabled={!dc_user_id ? true : false}
														onClick={() => confirm('del', id)}>
														<icons.DeleteIcon />
													</Button>
													<Button onClick={() => navigateToEdit(id)} variant="editIcon">
														<icons.textEditIcon />
													</Button>
												</Text>
											</td>
										</tr>
									)
								) :
								<UserBody>
									<Button>
										<Icon.noUser title="User Icon" />
									</Button>
									<Text fontSize={18} fontWeight="bold" color="#5C5E57" textAlign="center">
										No DC Users Found
									</Text>
								</UserBody>}
						</tbody>
					</table>
				</TableList>
				<SweetAlert
					show={showPopup}
					confirmBtnText="Confirm"
					onConfirm={onSubmit}
					showCancel={true}
					onCancel={closePopup}
					title={'Confirmation Box'}
				//  dependencies={[this.state.firstName, this.state.lastName]}
				>
					{() => (
						<form>
							{modalText}
						</form>
					)}
				</SweetAlert>
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default UserList;
