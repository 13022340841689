import React, { ReactElement, useState } from 'react';
import { Flex, Text } from 'rebass/styled-components';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { Loader, RadioButton } from 'ui/components';
import * as Icon from 'ui/icons';
import { AddToFavorites, AddToFavoritesContainer } from './styles';


export default function RemoveFromFavoritesModal(props): ReactElement {
	const [removedFav, setRemovedFavorites] = useState('');

	const handleOnChange = (item) => {
		setRemovedFavorites(item.favorite_id);
		props.removeProductFromFav(item.favorite_id);
	};
	return (
		<AddToFavorites>
			<Text as="h1" color="text.textHeading" fontSize={22} fontFamily="OpenSansBold">
				Remove from Favorites
			</Text>
			<AddToFavoritesContainer>
				<SimpleBar>
					{props.favoriteRemoveList === undefined ? <Loader></Loader> : null}
					{props.favoriteRemoveList &&
						props.favoriteRemoveList.map((item) => (
							<RadioButton
								id={item.id}
								name="paymentMethod"
								onChange={()=>handleOnChange(item)}
								hasActiveText>
								<Flex
									alignItems="center"
									width="100%"
									pl={[1, 2]}
									sx={{ transform: 'translateY(-3px)' }}
								>
									<Text as="h1" fontFamily="OpenSansSemiBold" flex="1" px={2}>
										{item.favorite_name}
									</Text>
									{removedFav === item.favorite_id ? <Icon.Tick /> : ``}
								</Flex>
							</RadioButton>
						))}
				</SimpleBar>
			</AddToFavoritesContainer>
		</AddToFavorites>
	);
}
