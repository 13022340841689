import { userTypes, venueUserPermissionList } from 'models/enum-constants/common.constants';
import React, { ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BreadcrumbNav, Footer, Header, Loader } from 'ui/components';
import { DeliveryConfirmationsListView, Sidebar } from 'ui/patterns';
import urls from 'utils/create.url';
import http from 'utils/http.service';
import utilityFunctions from 'utils/utility.functions';
import { DCListHolder, MainContent } from './styles';

function DeliveryConfirmations(): ReactElement {
	const [deliveryConfirmationsList, setDeliveryConfirmationsList] = useState<any>([])
	const location = useLocation();
	const [isLoading, setIsLoading] = useState(false);
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	let fields = [{
		field_name: 'status',
		field_value: "shipped",
		field_conditionType: "eq"
	},
	{
		field_name: 'Venueid',
		field_value: localStorage.getItem('selectedVenue')!,
	}
	];
	if(localStorage.getItem('userType') === userTypes.venueUser) {
		fields.push({
			field_name: 'venue_user_id',
			field_value: localStorage.getItem('userId')!,
		});
	}
	useEffect(() => {
		setIsLoading(true);
		http.GET({
			url: urls.GetSearchUrl({
				url: 'orders?',
				field_conditionType: 'in',
				fields: fields
			})
		})
			.then(function (d) {
				if (d.data?.items !== undefined) {
					setIsLoading(false);
					setDeliveryConfirmationsList(d.data?.items)
				}
			}).catch((error) => {
				setIsLoading(false);
			});
	}, [location]);

	const breadcrumbNav = [
		{ id: '01', label: 'Home', route: '/home/dashboard', active: false },
		{ id: '02', label: 'Dashboard', route: '/admin/user-dashboard', active: false },
		{ id: '03', label: 'Delivery Confirmations', active: true },
	];
	// const filter = [
	// 	{ id: '01', label: 'James & Co' },
	// 	{ id: '02', label: 'Grounded' },
	// 	{ id: '03', label: 'Grinded' },
	// ];
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	}
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={()=>onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={()=>onclickHambrgBtn()}></Sidebar>
			{isLoading ? < Loader ></Loader> : null}
			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				{utilityFunctions.isGranted(venueUserPermissionList.DeliveryConfirmation) &&
					<BreadcrumbNav Heading="Delivery Confirmations " breadcrumbList={breadcrumbNav} />}
				{/* <Filter filterList={filter}></Filter> */}
				<DCListHolder>
					<DeliveryConfirmationsListView deliveryConfirmationsList={deliveryConfirmationsList} />
				</DCListHolder>
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default DeliveryConfirmations;
