import { color } from 'styled-system';
// OpenSans Fonts
import { createGlobalStyle } from 'styled-components';
import theme from 'ui/styles/theme';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import '../../../node_modules/slick-carousel/slick/slick.css';
import OpenSansBoldEot from '../fonts/opensans-bold-webfont.eot';
import OpenSansBoldTtf from '../fonts/opensans-bold-webfont.ttf';
import OpenSansBoldWoof from '../fonts/opensans-bold-webfont.woff';
import OpenSansBoldOtf from '../fonts/OpenSans-Bold.otf';
import OpenSansBlackEot from '../fonts/opensans-extrabold-webfont.eot';
import OpenSansBlackTtf from '../fonts/opensans-extrabold-webfont.ttf';
import OpenSansBlackWoof from '../fonts/opensans-extrabold-webfont.woff';
import OpenSansBlackOtf from '../fonts/OpenSans-ExtraBold.otf';
import OpenSansLightEot from '../fonts/opensans-light-webfont.eot';
import OpenSansLightTtf from '../fonts/opensans-light-webfont.ttf';
import OpenSansLightWoof from '../fonts/opensans-light-webfont.woff';
import OpenSansLightOtf from '../fonts/OpenSans-Light.otf';
import OpenSansRegularEot from '../fonts/opensans-regular-webfont.eot';
import OpenSansRegularTtf from '../fonts/opensans-regular-webfont.ttf';
import OpenSansRegularWoof from '../fonts/opensans-regular-webfont.woff';
import OpenSansRegularOtf from '../fonts/OpenSans-Regular.otf';
import OpenSansSemiBoldEot from '../fonts/opensans-semibold-webfont.eot';
import OpenSansSemiBoldTtf from '../fonts/opensans-semibold-webfont.ttf';
import OpenSansSemiBoldWoof from '../fonts/opensans-semibold-webfont.woff';
import OpenSansSemiBoldOtf from '../fonts/OpenSans-Semibold.otf';
import { getHSL } from '../utils/getHSL';

type ThemeType = typeof theme;

const GlobalStyles = createGlobalStyle<{ theme: ThemeType }>`
@font-face {
    font-family: 'OpenSansLight';
    src: local('☺'), local('opensans-light-webfont.eot'),
    url(${OpenSansLightOtf}) format('otf'),
    url(${OpenSansLightEot}) format('eot'),
    url(${OpenSansLightTtf}) format('truetype'),
    url(${OpenSansLightWoof}) format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'OpenSansRegular';
    src: local('☺'), local('opensans-regular-webfont.eot'),
    url(${OpenSansRegularOtf}) format('otf'),
    url(${OpenSansRegularEot}) format('eot'),
    url(${OpenSansRegularTtf}) format('truetype'),
    url(${OpenSansRegularWoof}) format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'OpenSansMedium';
    src: local('☺'), local('opensans-regular-webfont.eot'),
    url(${OpenSansRegularOtf}) format('otf'),
    url(${OpenSansRegularEot}) format('eot'),
    url(${OpenSansRegularTtf}) format('truetype'),
    url(${OpenSansRegularWoof}) format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'OpenSansSemiBold';
    src: local('☺'), local('opensans-semibold-webfont.eot'),
    url(${OpenSansSemiBoldOtf}) format('otf'),
    url(${OpenSansSemiBoldEot}) format('eot'),
    url(${OpenSansSemiBoldTtf}) format('truetype'),
    url(${OpenSansSemiBoldWoof}) format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'OpenSansBold';
    src: local('☺'), local('opensans-bold-webfont.eot'),
    url(${OpenSansBoldOtf}) format('otf'),
    url(${OpenSansBoldEot}) format('eot'),
    url(${OpenSansBoldTtf}) format('truetype'),
    url(${OpenSansBoldWoof}) format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'OpenSansBlack';
    src: local('☺'), local('opensans-extrabold-webfont.eot'),
    url(${OpenSansBlackOtf}) format('otf'),
    url(${OpenSansBlackEot}) format('eot'),
    url(${OpenSansBlackTtf}) format('truetype'),
    url(${OpenSansBlackWoof}) format('woff');
    font-weight: normal;
    font-style: normal;
}




*,
*::before,
*::after {
    box-sizing: border-box;
}

ul[class],
ol[class] {
    padding: 0;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

body {
min-height: 100vh;
scroll-behavior: smooth;
text-rendering: optimizeLegibility;
    line-height: 1.5;
}

ul[class],
ol[class] {
    list-style: none;
}

a:not([class]) {
    text-decoration-skip-ink: auto;
}

img {
max-width: 100%;
    display: block;
}

article > * + * {
    margin-top: 1em;
}

input,
button,
textarea,
select {
    font: inherit;
}

@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}



/* ---------------------------------- */
/* ------------  Config  ------------ */
/* ---------------------------------- */



/* ---------------------------------- */
/* --------   Global Styles  -------- */
/* ---------------------------------- */

html {
    font-size: 16px;
}

body {
    font-family: ${({ theme }) => theme.fonts.body} , system-ui, sans-serif;
}


#root {
    min-height: 100vh;
    scroll-behavior: smooth;
    display: flex;
    flex-direction: column;
}

.sr-only {
    border: 0;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
     /* ---------- Colors --------- */
    :root {
        /* ------- Primary color ------- */
        --primary-h: ${({ theme }) => getHSL('h', theme.colors.primary)};
        --primary-s: ${({ theme }) => getHSL('s', theme.colors.primary)};
        --primary-l: ${({ theme }) => getHSL('l', theme.colors.primary)};
    }
    .linkText{
        color:${({ theme }) => theme.colors.text.textDark};
        text-decoration:none;
        &:hover{
            text-decoration:underline;
        }
    }
   .loginFields{
       position: relative;
       .pr-icon{
           position: absolute;
           top:27px;
           right:27px;
       }
       .PasswordIcon{
            top:3px;
       }
   }
   .slick-dots{
           bottom: -32px;
       li{
           width:10px;
           height:10px;
           button{
               width:10px;
               height:10px;
               padding:0;
               position: relative;
               &:before{
                font-size: 10px;
                line-height: 10px;
                width:10px;
                height:10px;
               }
           }
       }
   }

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scroll-shadow {
    --bgRGB: 255, 255, 255;
	--bg: rgb(var(--bgRGB));
	--bgTrans: rgba(var(--bgRGB), 0);
	--shadow: rgba(0, 0, 0, 0.2);
    background: linear-gradient(var(--bg) 30%, var(--bgTrans)) center top,
		linear-gradient(var(--bgTrans), var(--bg) 70%) center bottom,
		radial-gradient(farthest-side at 50% 0, var(--shadow), rgba(0, 0, 0, 0)) center top,
		radial-gradient(farthest-side at 50% 100%, var(--shadow), rgba(0, 0, 0, 0)) center bottom;
	background-repeat: no-repeat;
	background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
	background-attachment: local, local, scroll, scroll;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.statusTooltip{
    padding:0px!important;
    margin:0;
    span,label{
        padding:0!important;
        strong{
            margin-left: 0;
    padding: 3px 0 0 4px !important;
    font-size: 14px;
    font-family: 'OpenSansRegular';
    font-weight:600;

        }
    }
}
.timePicker{
    width:100%;
    input{
        border: 1px solid #BDBCB9;
        border-radius: 6px;
        min-height:50px;
        font-family: 'OpenSansSemiBold';
        font-size: 1rem;
        color: #22252B;
        padding:0.75em;
        &:focus{
            --h: ${({ theme }) => getHSL('h', theme.colors.border.default)};
            --s: ${({ theme }) => getHSL('s', theme.colors.border.default)};
            --l: ${({ theme }) => getHSL('l', theme.colors.border.default)};
            background-color: #fff;
            border: 1px solid rgba(125,55,111,1);
            outline: 0;
            box-shadow: 0 0 0 0.2rem hsla(var(--h),var(--s),var(--l),30%);
        }
    }
}
.rc-time-picker-clear{
    display:none;
}
.rc-time-picker-panel {
    width: 280px;
    margin-top: 9px;
    .rc-time-picker-clear{
        display:none;
    }
    .rc-time-picker-panel-inner{
        width:100%;
        .rc-time-picker-panel-input-wrap { 
            font-size: 1rem;
        color: #22252B;
        padding:0.65em 0.75em;
            display: flex;
            align-items: center;
            input{
                    font-family: 'OpenSansSemiBold';
                    font-size: 1rem;
        color: #22252B;
                }
        }
        .rc-time-picker-panel-combobox{
            display:flex;
            .rc-time-picker-panel-select{
                flex:1;
               li{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0;
               }
            }
        }
    }
   .rc-time-picker-panel-select{
       + .rc-time-picker-panel-select{
           +.rc-time-picker-panel-select{
               padding:10px;
              flex:0 0 70px;
              li{
                  border-radius:5px;
                  margin-bottom:5px;
                  text-transform:uppercase;
                  font-family: 'OpenSansSemiBold';

                  &:hover{
                    background: #7d376f;
                    color:#fff;   
                  }
              }
               .rc-time-picker-panel-select-option-selected{
                background: #7d376f;
                color:#fff;
               }
           }
       }
   }
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
//Supports Mozilla
input[type=number] {
    -moz-appearance:textfield;
}
.bp3-overlay-content {
    margin: 51px 0 0 -96px;
    @media (max-width:960px) {
        left: 0!important;
    margin: 56px 0 0 24px;
    width: 247px;
    z-index: 9999;
    }
    > &:focus{
        outline: none!important;

    }
    .bp3-menu{
        box-shadow: 0px 4px 24px rgb(0 0 0 / 39%);
		border-radius:0;
        outline: none!important;
                border: none!important;
		li {
			border-bottom: 1px solid rgba(125, 55, 111, 0.12);
            outline: none!important;

            a,span{
                background: transparent!important;
            }
			&:hover {
				background: ${({ theme }) => theme.baseColors.primary};
				color: ${({ theme }) => theme.colors.text.white};
                svg {
                    path {
                        fill: #fff;
                    }
                }
			}
            .bp3-menu-item{
                font-size: 14px;
                padding: 0.6rem 1rem 0.6rem 1rem ;
                outline: none!important;
                box-shadow: none!important;
                border: none!important;
            }
		}
    }
    .bp3-popover.bp3-minimal{
        padding: 0!important;
        margin:5px 0 0 0!important
    }
    .bp3-popover2-arrow{
        display: none;
    }
}
article{
    height: 100%;
    background-size: contain!important;
    picture{
        background-size: contain!important;
        span,div{
            background-size: contain!important;
        }
    }

}
:focus{
    outline: none!important;
}
strong{
    font-family: 'OpenSansBold'!important;
    font-weight: normal!important;
}
`;

export default GlobalStyles;
