import {
	permissionGroup,
	userTypes,
	venueUserPermissionList,
} from 'models/enum-constants/common.constants';
import React, { ReactElement, useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link, Text } from 'rebass/styled-components';
import * as Icon from 'ui/icons';
import { Dropdown, QuickOrder } from 'ui/patterns';
import useOnClickOutside from 'use-onclickoutside';
import AppContext from 'utils/appContext';
import utilityFunctions from 'utils/utility.functions';
import { Nav } from './styles';

function HeaderTopNav(): ReactElement {
	const ctx = useContext(AppContext).productSearchObj;
	const userAccessType = localStorage.getItem('userType');
	const history = useHistory();
	const userType =
		localStorage.getItem('userType') == userTypes.venueAdmin ||
			localStorage.getItem('userType') == userTypes.venueUser
			? userTypes.HospitalityGroup
			: userTypes.Supplier;
	const categoryList = [] as any;
	if (utilityFunctions.isAnyGranted(permissionGroup.ParkedOrder)) {
		categoryList.push({
			id: 1,
			name: 'Parked Orders',
		});
	}
	if (utilityFunctions.isAnyGranted(permissionGroup.RecentOrder)) {
		categoryList.push({
			id: 2,
			name: 'My Orders',
		});
	}

	if (utilityFunctions.isGranted(venueUserPermissionList.FavoriteOrderView)) {
		categoryList.push({
			id: 3,
			name: 'Favourite Orders',
		});
	}

	// Pending order permission.
	if (utilityFunctions.isAnyGranted(permissionGroup.RecentOrder)) {
		categoryList.push({
			id: 4,
			name: 'Pending Orders',
		});
	}

	// All order permission.
	if (utilityFunctions.isGranted(venueUserPermissionList.viewAllOrders)) {
		categoryList.push({
			id: 4,
			name: 'All Orders',
		});
	}

	const categoriesOptions = categoryList;
	// const menuContent = () => (
	// 	<Box className="menuContent">
	// 		<ParkedOrders totalAmount="$0"></ParkedOrders>
	// 	</Box>
	// );
	const [open, setOpen] = useState(false);
	const handleOnClickOutside = () => {
		setOpen(false);
	};
	const wrapperRef = useRef<HTMLDivElement>(null);
	useOnClickOutside(wrapperRef, handleOnClickOutside);
	// const handleToggle = () => {
	// 	setOpen(true);
	// };
	const [showOptions, setShowOptions] = useState(false);

	const optionsTrigger = () => (
		<button onClick={() => setShowOptions(!showOptions)}>
			<Icon.Options width="2em" fill="#fff" />
		</button>
	);

	const onSelect = (event) => {
		ctx.SortDirection = '';
		ctx.SortField = '';
		switch (event.name) {
			case 'Parked Orders':
				history.push('/store/orders?tab=parked-orders');
				break;
			case 'My Orders':
				history.push('/store/orders?tab=my-orders');
				break;
			case 'Favourite Orders':
				history.push('/store/orders?tab=favourite-orders');
				break;
			case 'Pending Orders':
				history.push('/store/orders?tab=pending-orders');
				break;
			case 'All Orders':
				history.push('/store/orders?tab=all-orders');
				break;
		}
		setShowOptions(!showOptions);
	};
	return (
		<>
			{userType == userTypes.HospitalityGroup && (
				<Nav className="venueManagement">
					{utilityFunctions.isGranted(venueUserPermissionList.QuickOrdersView) &&
						utilityFunctions.isGranted(venueUserPermissionList.ManageProductsinCart) && (
							<Link variant="nav" href="/admin/Quick-Order">
								<Text as="label">Quick Order</Text>
							</Link>
						)}

					{/* HACK - Need to Handle Access */}
					{/* {utilityFunctions.isGranted(venueUserPermissionList.ParkedOrdersView) && (
						<Link className="active" variant="nav" ref={wrapperRef} onClick={handleToggle}>
							<Text as="label">Parked Order</Text>
							{open && <div>{menuContent()}</div>}
						</Link>
					)} */}
					{/* Menu active add active class */}
					{/* {utilityFunctions.isAnyGranted(permissionGroup.ParkedOrder) && (
						<Link variant="nav" ref={wrapperRef} onClick={handleToggle}>
							<Text as="label">Parked Order</Text>
							{open && <div>{menuContent()}</div>}
						</Link>
					)} */}
					{/* Add all orders permision also */}
					{categoriesOptions.filter(function (element) {
						return element !== undefined;
					}).length > 0
						? (utilityFunctions.isAnyGranted(permissionGroup.RecentOrder) ||
							utilityFunctions.isAnyGranted(permissionGroup.ParkedOrder) ||
							utilityFunctions.isGranted(venueUserPermissionList.FavoriteOrderView) ||
							utilityFunctions.isGranted(venueUserPermissionList.viewAllOrders)) && (
							<Link variant="nav" className="orderNav">
								<Text as="label">Orders</Text>
								<Dropdown
									trigger={optionsTrigger()}
									options={categoriesOptions}
									showOptions={showOptions}
									setShowOptions={setShowOptions}
									top="0"
									place="covered-right"
									handleClick={onSelect}
								/>
							</Link>
						)
						: ''}
					{(userAccessType == userTypes.venueAdmin ||
						userAccessType == userTypes.supplierAdmin) && <QuickOrder></QuickOrder>}
				</Nav>
			)}
			{/* Supplier Dashboard Menu */}
			{/* {(userType == userTypes.Supplier && userAccessType == userTypes.supplierAdmin) ? ( */}
			<Nav className="ProductManangement">
				{((userType == userTypes.Supplier && userAccessType == userTypes.supplierAdmin) ||
					(userType == userTypes.Supplier &&
						utilityFunctions.isAnyGranted(permissionGroup.Report))) && (
						<Link variant="nav" href="/admin/reports">
							<Text as="label">Reports</Text>
						</Link>
					)}
				{userType == userTypes.Supplier && userAccessType == userTypes.supplierAdmin ? (
					<>
						<Link variant="nav" href="/admin/seller-ratings">
							<Text as="label">Reviews & Ratings</Text>
						</Link>
						<Link variant="nav" href="/admin/productManagement">
							<Text as="label">Product Management</Text>
						</Link>
						<Link variant="nav" href="/admin/userList">
							<Text as="label">User Management</Text>
						</Link>
					</>
				) : (
					''
				)}
			</Nav>
			{/* ) : (
				''
			)} */}
		</>
	);
}

export { HeaderTopNav };
