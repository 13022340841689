import Ipost from 'models/Ipost';
import rolePermissions from 'models/rolePermission.model';
import queryString from 'query-string';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import Skeleton from 'react-loading-skeleton';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Text } from 'rebass/styled-components';
import { BreadcrumbNav, Button, Footer, Header, Loader, Stack } from 'ui/components';
import { FormInput, FormTextarea, Sidebar } from 'ui/patterns';
import AppContext from 'utils/appContext';
import urls from 'utils/create.url';
import https from 'utils/http.service';
import { default as api, default as http } from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import RolePermissionHeirarchy from './role.permission';
import { AccessList, AddUserRoleHolder, FooterBtnBlock, MainContent } from './styles';
function UserRoleManagementAdd(): ReactElement {
	const ctx = useContext(AppContext).userObj;
	const location = useLocation() as any;
	const [showPopup, setShowModal] = useState(false);
	const [action, setAction] = useState('');
	const [modalText, setmodalText] = useState('');
	const hgId = localStorage.getItem('hospitalityId');
	const history = useHistory();
	const [mutatePost] = hooks.useMutation('user-role');
	const parsed = queryString.parse(location.search);
	const [editParam, setEditParam] = useState(0);
	const [loading, setLoading] = useState(false);
	const [rolename, setRoleName] = useState('');
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const breadcrumbNav = [
		{ id: '01', label: 'Dashboard', route: '/home/dashboard', active: false },
		{ id: '02', label: 'User Role Management', route: '/admin/user-role-management', active: false },
		{ id: '04', label: editParam ? 'Edit User Role' : 'Add User Role', active: true },
	];
	const [role, setRole] = useState({
		id: 0,
		hg_admin_id: hgId,
		role_name: '',
		description: '',
		is_active: true,
		is_delete: false,
	});
	const [saveText, setSaveText] = useState('Add');
	const [error, setError] = useState({
		roleError: ''
	});
	const [roleName, validateRole] = useState<any>();
	let userRoleValidateUrl = `experion/venueuserrole/get/${roleName}/${hgId}`;
	const [hasPermissionChange, setPermissionChange] = useState(false);
	const [rolePermissions, setRolePermissions] = useState<rolePermissions[]>(
		[] as rolePermissions[]
	);
	const handleOnChange = (e: any) => {
		handlePermissions(e.target.id, true);
	};

	const clear = () => {
		setRolePermissions(ctx.permissionList);
		setPermissionChange(!hasPermissionChange);
		setRole({
			id: 0,
			hg_admin_id: hgId,
			role_name: '',
			description: '',
			is_active: true,
			is_delete: false,
		});
		setError({
			roleError: ''
		});
		window.scroll({ top: 0, left: 0, behavior: 'smooth' });
	};

	const handlePermissions = (id, toggle) => {
		let data = rolePermissions.find((x) => x.id == parseInt(id));
		if (toggle) data!.is_granted = !data!.is_granted;
		if (data?.parent_id == 0) {
			let childs = rolePermissions.filter((x) => x.parent_id == data?.id);
			if (data.is_granted)
				childs.map((x) => {
					x.is_granted = true;
				});
			else
				childs.map((x) => {
					x.is_granted = false;
				});
			data.parent_active = false;
		}
		if (data?.parent_id != 0) {
			let parent = rolePermissions.find((x) => x.id == data?.parent_id);
			let childrens = rolePermissions.filter((x) => x.parent_id == parent?.id);
			if (!data?.is_granted) {
				let parentActive = false;
				parent!.is_granted = false;
				childrens.map((x) => {
					if (x.is_granted) {
						parentActive = true;
						return;
					}
				});
				if (parentActive) parent!.parent_active = true;
				else parent!.parent_active = false;
			} else {
				let parentIsGranted = true;
				childrens.map((x) => {
					if (!x.is_granted) {
						parentIsGranted = false;
						return;
					}
				});
				if (parentIsGranted) parent!.is_granted = true;
				else parent!.parent_active = true;
			}
		}
		setRolePermissions(rolePermissions);
		setPermissionChange(!hasPermissionChange);
	};

	const parseQueryString = function () {
		if (parsed.id) {
			const id = parseInt(parsed.id);
			setSaveText('Save');
			setEditParam(id);
			http.get({ url: `experion/venue/getVenueUserrole/${parsed.id}` }).then(function (data) {
				if (data) {
					setRole({
						id: data.id,
						hg_admin_id: data.hg_admin_id,
						role_name: data.role_name,
						description: data.description,
						is_active: data.is_active,
						is_delete: data.is_delete,
					});
					setRoleName(data.role_name);
					let permissions = [] as any;
					data.venue_role_permission_mapping.forEach(function (element: rolePermissions) {
						if (
							element.parent_id == 0 &&
							!element.is_granted &&
							data.venue_role_permission_mapping.filter(
								(y: rolePermissions) => y.parent_id == element.id && y.is_granted
							).length > 0
						) {
							element.parent_active = true;
						}
						permissions.push({ ...element, permission_id: element.id });
					});
					ctx.permissionList = JSON.parse(JSON.stringify(permissions));
					permissions && permissions.length > 0 && permissions.sort(sort);
					setRolePermissions(permissions);
					window.scroll({ top: 0, left: 0, behavior: 'smooth' });
				}
			});
		}
	};
	useEffect(() => {
		parseQueryString();
	}, [location]);
	const cancel = () => {
		window.scroll({ top: 0, left: 0, behavior: 'smooth' });
		history.push('user-role-management');
	};

	useEffect(() => {
		if (typeof location?.search == 'undefined' || location?.search == '') {
			http
				.get({
					url: urls.GetSearchUrl({
						url: 'experion/venueuser/userpermissions/search?',
						pageSize: 2000,
						filterInactive: true,
					}),
				})
				.then(function (data) {
					let permissions = [] as any;
					data?.items?.forEach(function (element: rolePermissions) {
						element.is_granted = false;
						permissions.push({ ...element, permission_id: element.id });
					});
					ctx.permissionList = JSON.parse(JSON.stringify(permissions));
					permissions = permissions.sort(sort);
					setRolePermissions(permissions);
				});
		}
	}, []);

	const submitData = async () => {
		if (role && !editParam) {
			const payload = {
				hg_admin_id: role.hg_admin_id, // company id
				role_name: role.role_name.trim(),
				description: role.description,
				is_active: role.is_active,
				is_delete: role.is_delete,
				venue_role_permission_mapping: Object.assign(
					{},
					rolePermissions.map(
						({
							description,
							parent_id,
							permission_name,
							created_at,
							created_by,
							updated_at,
							updated_by,
							role_id,
							id,
							parent_active,
							...keepAttrs
						}) => keepAttrs
					)
				),
			};
			console.log(JSON.stringify(payload));
			console.log(JSON.parse(JSON.stringify(payload)));
			const obj: Partial<Ipost> = {
				data: { venueUserRole: payload },
				params: {},
				url: 'experion/venue/userrole/create',
				message: 'Venue user role created successfully',
			};
			try {
				setLoading(true);
				await mutatePost(obj).then((data) => {
					if (typeof data != 'undefined') {
						window.scroll({ top: 0, left: 0, behavior: 'smooth' });
						history.push('user-role-management');
					}
					setLoading(false);

				});
			} catch (e) { }
		} else if (role && editParam) {
			const payload = {
				id: role.id,
				hg_admin_id: role.hg_admin_id,
				role_name: role.role_name.trim(),
				description: role.description,
				is_active: role.is_active,
				is_delete: role.is_delete,
				venue_role_permission_mapping: Object.assign(
					{},
					rolePermissions.map(
						({
							description,
							parent_id,
							permission_name,
							created_at,
							created_by,
							updated_at,
							updated_by,
							role_id,
							id,
							parent_active,
							...keepAttrs
						}) => keepAttrs
					)
				),
			};
			const obj: Partial<Ipost> = {
				data: { venueUserRole: payload },
				params: {},
				url: `experion/venue/userrole/update/${editParam}`,
				message: 'Venue user role updated successfully',
			};
			try {
				setLoading(true);
				api.put(obj).then((data) => {
					if (data !== undefined) {
						window.scroll({ top: 0, left: 0, behavior: 'smooth' });
						history.push('user-role-management');
					}
					setLoading(false);
				});
			} catch (e) { }
		}
	};

	const handleChange = (val, name) => {
		const item = role;
		switch (name) {
			case 'rolename': {
				if (!val) {
					setError({ ...error, roleError: 'Role name cannot be blank' });
				} else {
					setError({ ...error, roleError: '' });
					validateRole(val);
				}
				item.role_name = val;
				break;
			}
			case 'description': {
				item.description = val;
				break;
			}
		}
		setRole({
			...item,
		});
	};
	const validateUserRole = async (name) => {
		if (parsed.id) {
			userRoleValidateUrl = `experion/venueuserrole/get/${name}/${hgId}/?id=${parsed.id}`
		}
		else
			userRoleValidateUrl = `experion/venueuserrole/get/${name}/${hgId}`
		let isDuplicate = false;
		if ((rolename != '' && rolename !== name) || rolename === '') {
			await https.GET({ url: userRoleValidateUrl }).then((data) => {
				if (data && data.data == true) {
					isDuplicate = true;
					setError({ ...error, roleError: 'Role already exists' });
				} else {
					setError({ ...error, roleError: '' });
				}
			});
		}
		return isDuplicate;

	};

	//popup 

	async function confirmPopup(action) {
		switch (action) {
			case 'cancel': showConfirmationModal(action, 'Are you sure you want to cancel ?');
				break;
			case 'clear': showConfirmationModal(action, 'Are you sure you want to clear all data ?');
				break;
			case 'Save':
				setLoading(true);
				let isDuplicate = await validateUserRole(role.role_name);
				setLoading(false);
				if (isDuplicate) {
					window.scroll({ top: 0, left: 0, behavior: 'smooth' });
					return false;
				}
				else {
					showConfirmationModal(action, 'Are you sure you want to update the user role details ?');
				}
				break;
			case 'Add':
				setLoading(true);
				let isDuplicateRole = await validateUserRole(role.role_name);
				setLoading(false);
				if (isDuplicateRole) {
					window.scroll({ top: 0, left: 0, behavior: 'smooth' });
					return false;
				}
				else {
					submitData();
				}
				break;
			default: break;
		}
	}
	async function closePopup() {
		await setShowModal(!showPopup);
	}
	function showConfirmationModal(action, text) {
		setAction(action);
		setmodalText(text);
		setShowModal(!showPopup);
	}
	async function onSubmit() {
		if (action === 'clear') {
			clear();

		} else if (action === 'cancel') {
			cancel();
		}
		else if (action == 'Save') {
			submitData();
		}
		setShowModal(!showPopup);
	}
	//sort by permission name
	function sort(a, b) {
		if (a.permission_name < b.permission_name) {
			return -1;
		}
		if (a.permission_name > b.permission_name) {
			return 1;
		}
		return 0;
	}
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	}
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={()=>onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={()=>onclickHambrgBtn()}></Sidebar>
			{editParam ? rolePermissions.length == 0 ? <Loader></Loader> : null : null}
			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbNav
					Heading={editParam ? 'Edit User Role' : 'Add User Role'}
					breadcrumbList={breadcrumbNav}
				/>
				<AddUserRoleHolder>
					<Box width={[1, 1 / 1, 1 / 3]}>
						<FormInput
							label="User Role"
							autoComplete="off"
							validationMessage={error.roleError}
							validation={error.roleError ? 'error' : undefined}
							required={true}
							value={role.role_name}
							onTextChange={(e) => handleChange(e, 'rolename')}
							maxLength={30}
							type="text"
							onBlur={(e) => { validateUserRole(e.target.value) }}
						/>
					</Box>
					<Box mt={25} width={[1, 1 / 1, 1 / 2]}>
						<FormTextarea
							label="Description"
							value={role.description}
							onTextChange={(e) => handleChange(e, 'description')}
							maxLength={100}
						/>
					</Box>

					{rolePermissions?.length > 0 ? (
						<AccessList>
							<Text as="h3" fontSize="16px" fontFamily="OpenSansSemiBold" color="text.black03">
								Access Permissions
							</Text>
							<Box width={[1, 1 / 1, 1 / 2]}>
								<RolePermissionHeirarchy
									data={rolePermissions}
									handleOnChange={handleOnChange}
									hasChange={hasPermissionChange}
								></RolePermissionHeirarchy>
							</Box>
						</AccessList>
					) : (
						new Array(14).fill(null).map((_, i) => {
							return <Skeleton key={i} width="500px" height="30px" />;
						})
					)}
				</AddUserRoleHolder>

				{/* Footer Btn Block */}
				<FooterBtnBlock>
					<Stack direction="x" gap={3} justifyContent={['center', 'flex-end']}>
						{saveText == 'Add' && (
							<Button onClick={() => confirmPopup('clear')} flex={[1, 1, 'initial']} variant="secondary">
								Clear
							</Button>
						)}
						<Button flex={[1, 1, 'initial']} variant="secondary" onClick={() => confirmPopup('cancel')}>
							Cancel
						</Button>

						<Button
							flex={[1, 1, 'initial']}
							disabled={
								role.role_name == '' ||
								error.roleError != '' ||
								loading == true
							}
							onClick={() => confirmPopup(editParam ? 'Save' : 'Add')}
						>
							{saveText}
						</Button>
					</Stack>
				</FooterBtnBlock>
				<SweetAlert
					show={showPopup}
					confirmBtnText="Confirm"
					onConfirm={onSubmit}
					showCancel={true}
					onCancel={closePopup}
					title={'Confirmation Box'}
				>
					{() => <form>{modalText}</form>}
				</SweetAlert>
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default UserRoleManagementAdd;
