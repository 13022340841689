import Iget from 'models/Iget';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Flex, Link, Text } from 'rebass/styled-components';
import { Stack } from 'ui/components';
import no_image from 'ui/images/NoImagThumb.png';
import urls from 'utils/create.url';
import hooks from 'utils/requestCtx/reactQueryHooks';
import { OtherSuppliersDetails, StyledOtherSuppliers, ViewAllHeadingBlock } from './styles';
import api from 'utils/requestCtx/api.service';
import appContext from 'utils/appContext';
import Skeleton from 'react-loading-skeleton';
import helperFunctions from 'utils/helper.functions';
import pageConstants from 'utils/pageConstants';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
function OtherSuppliers(props): ReactElement {
	var [otherSupp, setOtherSuppliers] = useState<any>([]);
	var [otherSuppList, setOtherSuppList] = useState<any>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [venueId] = useState<any>(localStorage.getItem('selectedVenue'));
	const history = useHistory();
	var [upc, setUpc] = useState<any>('');
	let ctx = useContext(appContext).cartObj;
	const urlObj = urls.GetSearchUrl({
		ctx: { SortField: 'price', SortDirection: 'asc' },
		url: 'products?',
		fields: [
			{
				field_name: 'venue_id',
				field_value: localStorage.getItem('selectedVenue')!,
				field_conditionType: 'eq',
			},
			{
				field_name: 'upc',
				field_value: `${upc}`,
				field_conditionType: 'eq',
			},
		],
	});
	const suppObj: Partial<Iget> = {
		params: {},
		url: urlObj,
		enabled: false,
		key: 'suppKey',
		resultFilteringRequired: false,
		caching: false,
	};
	// const qry = hooks.useQuery(suppObj);

	const fetch = () => {
		setIsLoading(true);
		api
			.get(suppObj)
			.then(function (dataObjs) {
				if (dataObjs.items.length > 0) {
					let data = JSON.parse(JSON.stringify(dataObjs.items));
					data = data.filter(function (item) {
						let creditStatus = '';
						if (item?.extension_attributes?.credit_status.length > 0) {
							item?.extension_attributes?.credit_status.forEach((obj) => {
								if (parseInt(venueId) === obj) {
									creditStatus = 'stop';
								}
							});
						}
						if (
							parseInt(item.extension_attributes.qty) > 0 &&
							creditStatus !== 'stop' &&
							props?.productDetails &&
							props?.productDetails?.sku !== item.sku
						) {
							return item;
						}
					});
					let sampleData1 = data;
					let sampleData2 = data;
					setOtherSuppList(() => sampleData1);
					setOtherSuppliers(() => sampleData2);
				}
				setIsLoading(false);
			})
			.catch((error) => {
				console.error('Error:', error);
				setIsLoading(false);
			});
	};
	useEffect(() => {
		if (props.productDetails?.custom_attributes !== undefined) {
			props.productDetails?.custom_attributes?.forEach((data) => {
				if (data.attribute_code === 'upc') {
					setUpc(data.value);
				}
			});
		}
	}, [props.productDetails]);

	useEffect(() => {
		if (upc.length > 0 && parseInt(upc) !== 0) {
			fetch();
		}
	}, [upc]);

	useEffect(() => {
		if (otherSuppList !== undefined) {
			setOtherSuppliers(otherSuppList);
		}
	}, [ctx.prodStepperCountUpdated]);

	return (
		<StyledOtherSuppliers>
			<ViewAllHeadingBlock>
				<Text as="h1" fontSize="18px">
					Other Suppliers
				</Text>
				{/* {otherSupp && otherSupp.length > 3 &&
					<Link variant="nav" onClick={() => history.push('/home/product-list?search=&type=')}>
						View All
					</Link>} */}
			</ViewAllHeadingBlock>

			<OtherSuppliersDetails>
				{isLoading ? (
					<Skeleton
						key={helperFunctions.getRandomInt(pageConstants.maxValue)}
						width="600px"
						height="100px"
						count={3}
					/>
				) : null}
				<SimpleBar>
					<Box>
						<Stack as="section" direction="y" gap={3}>
							{otherSupp && otherSupp.length > 0
								? otherSupp.map((item) => {
										return (
											<Flex
												as="article"
												bg="white"
												p={[3, 4]}
												sx={{ alignItems: 'center' }}
												key={item.id}
												onClick={() => {
													history.push({
														pathname: '/home/product-details',
														search: '?id=' + item.sku,
													});
													window.location.reload();
												}}
											>
												<Box as="picture">
													<img
														src={
															item.media_gallery_entries[0] &&
															item.media_gallery_entries[0]?.file !== ''
																? item.media_gallery_entries[0]?.file
																: no_image
														}
														alt="Logo"
														style={{ maxWidth: '100%' }}
													/>
												</Box>
												<Box flex={1}>
													<Text
														as="h2"
														fontFamily="OpenSansMedium"
														fontSize={18}
														lineHeight="23px"
														color="text.black03"
														className="truncate"
													>
														{item.name}
													</Text>
													<Text
														as="h2"
														fontFamily="OpenSansMedium"
														fontSize={18}
														lineHeight="23px"
														color="text.black03"
														className="truncate"
													>
														{item.extension_attributes?.supplier_name}
													</Text>
													<Text as="h3" fontSize="18px" color="text.black01" className="truncate">
														<strong>${parseFloat(item.price).toFixed(2)}</strong>{' '}
														{item.extension_attributes?.uomname?.length > 0
															? item.extension_attributes?.uomname
															: 'per Pack'}
													</Text>
												</Box>
											</Flex>
										);
								  })
								: !isLoading && (
										<Text fontSize={18} fontWeight="bold" color="#5C5E57" textAlign="center" py={5}>
											No Records Found
										</Text>
								  )}
						</Stack>
					</Box>
				</SimpleBar>
			</OtherSuppliersDetails>
		</StyledOtherSuppliers>
	);
}

export { OtherSuppliers };
