import FilterGroupItem from 'models/FilterModel';
import React, { ReactElement } from 'react';
import { Flex, Text } from 'rebass/styled-components';
import { FlexboxProps, LayoutProps, SpaceProps } from 'styled-system';
import * as Icon from 'ui/icons';
import { Button } from '../button';
import { StyledTags } from './styles';


type styledSystemProps = SpaceProps & LayoutProps & FlexboxProps;

type TagsProps = styledSystemProps & {
	tagContent?: string;
	onPress?: (item: FilterGroupItem | null) => void;
	content: FilterGroupItem;
};

function Tags({ tagContent, content, onPress, ...styledSystemProps }: TagsProps): ReactElement {
	return (
		<StyledTags {...styledSystemProps}>
			<Flex>
				<Text as="h2">{tagContent}</Text>
				{onPress && (
					<Button
						onClick={() => {
							onPress(content);
						}}
					>
						<Icon.CloseIcon />
					</Button>
				)}
			</Flex>
		</StyledTags>
	);
}

export { Tags };
