import styled, { css } from 'styled-components';
import { flexbox, FlexboxProps, layout, LayoutProps, space, SpaceProps } from 'styled-system';
import { getHSL } from '../../utils/getHSL';

type StackProps = SpaceProps &
	FlexboxProps &
	LayoutProps & {
		direction?: 'y' | 'x';
		gap: number;
		LoginSocialButtonHolder?: boolean;
		UserInfo?: boolean;
		UserInfoRewards?: boolean;
		favoriteFoodsHolder?: boolean;
		UserInfoVisited?: boolean;
		favoriteVenuesHolder?: boolean;
		allRightReserved?: boolean;
		merchantCustomerCardBlock?: boolean;
		passwordInfo?: boolean;
		passwordInfoHolder?: boolean;
		cardNumber?: boolean;
		categorySelect?: boolean;
		buttonHeader?: boolean;
		UOMSelect?: boolean;
		validation?: 'error' | 'success' | 'warning';
	};

const Stack = styled.div<StackProps>`
	${space};
	${layout};
	display: flex;
	justify-content: flex-start;
	${({ direction, gap }) =>
		direction === 'y' &&
		css`
			flex-direction: column;
			& > * + * {
				margin-top: ${({ theme }) => theme.space[gap]};
			}
		`}

	${({ direction, gap }) =>
		direction === 'x' &&
		css`
			& > * + * {
				margin-left: ${({ theme }) => theme.space[gap]};
			}
		`}
	${flexbox};
	${({ LoginSocialButtonHolder }) =>
		LoginSocialButtonHolder &&
		css`
			margin-top: 52px;
			button {
				margin-right: 16px;
				@media (max-width: 640px) {
					margin-right: 8px;
				}

				& + button {
					margin-left: 16px;
					margin-right: 0;
					@media (max-width: 640px) {
						margin-left: 8px;
					}
				}
			}
		`}

	${({ validation }) =>
		validation === 'error' &&
		css`
			select {
				border-color: ${({ theme }) => theme.colors.border.error}!important;
				&:focus {
					border-color: ${({ theme }) => theme.colors.border.error};
				}
			}
		`}

    ${({ validation }) =>
		validation === 'success' &&
		css`
			select {
				border-color: ${({ theme }) => theme.colors.border.success}!important;
				&:focus {
					border-color: ${({ theme }) => theme.colors.border.success}!important;
				}
				color: ${({ theme }) => theme.colors.border.success}!important;
			}
			svg {
				path {
					fill: ${({ theme }) => theme.colors.border.success}!important;
				}
			}
		`}

    ${({ validation }) =>
		validation === 'warning' &&
		css`
			select {
				border-color: ${({ theme }) => theme.colors.border.warning}!important;
				&:focus {
					border-color: ${({ theme }) => theme.colors.border.warning}!important;
				}
				color: ${({ theme }) => theme.colors.border.warning}!important;
			}
			svg {
				path {
					fill: ${({ theme }) => theme.colors.border.warning}!important;
				}
			}
		`}
	${({ UserInfo }) =>
		UserInfo &&
		css`
			width: 100%;
			display: flex;
			flex-direction: column;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			padding-left: 20px;
			padding-right: 20px;
			height: auto;
			max-height: 110px;
			@media (max-width: 768px) {
				height: auto;
			}
			& > * {
				margin: 0 0 0 0;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
			small,
			h1 {
				line-height: 21px;
			}
		`}
		  ${({ UserInfoRewards }) =>
		UserInfoRewards &&
		css`
			display: flex;
			flex-direction: row;
			@media (max-width: 1152px) {
				width: 100%;
				flex-wrap: wrap;
				justify-content: space-between;
			}
			@media (max-width: 768px) {
				flex-direction: column;
			}
		`}
		  ${({ UserInfoVisited }) =>
		UserInfoVisited &&
		css`
			display: flex;
			flex-direction: row;
			float: left;
			width: 100%;
			@media (max-width: 1366px) {
				width: 100%;
				flex-wrap: wrap;
				justify-content: start;
			}
			@media (max-width: 768px) {
				flex-direction: column;
				border: 1px solid ${({ theme }) => theme.colors.border.greyBorder};
				border-radius: 8px;
			}
		`}
		 ${({ favoriteFoodsHolder }) =>
		favoriteFoodsHolder &&
		css`
			display: flex;
			flex-direction: row;
			float: left;
			width: 100%;
			margin-top: 82px;
			flex-direction: column;
			@media (max-width: 1152px) {
				width: 100%;
			}
			@media (max-width: 768px) {
				padding: 0 14px;
			}
			div {
				outline: none !important;
			}
			.slick-slider {
				.slick-arrow {
					display: none !important;
				}
				.slick-list {
					overflow: visible;
					.slick-track {
						display: flex;
						padding-right: 7px;
						.slick-slide {
							width: 100%;
							margin-left: 13px;
							&:first-child {
								margin-left: 0;
							}
						}
					}
				}
			}
		`}
		 ${({ favoriteVenuesHolder }) =>
		favoriteVenuesHolder &&
		css`
			display: flex;
			flex-direction: row;
			float: left;
			width: 100%;
			margin-top: 30px;
			flex-direction: column;

			@media (max-width: 1152px) {
				width: 100%;
			}
			@media (max-width: 768px) {
				padding: 0 14px;
				margin-top: 0;
			}
			div {
				outline: none !important;
			}
			.slick-slider {
				.slick-arrow {
					display: none !important;
				}
				.slick-list {
					overflow: visible;
					.slick-track {
						display: flex;
						padding-right: 7px;
						.slick-slide {
							width: 100%;
							margin-left: 13px;
							&:first-child {
								margin-left: 0;
							}
						}
					}
				}
			}
		`}
		${({ allRightReserved }) =>
		allRightReserved &&
		css`
			padding: 40px 0 30px;
			width: 100%;
			text-align: center;
			justify-content: center;
			@media (max-width: 768px) {
				display: none;
			}
		`}
		${({ merchantCustomerCardBlock }) =>
		merchantCustomerCardBlock &&
		css`
			background: ${({ theme }) => theme.colors.text.tabHeading};
			padding: 20px;
			border-radius: 20px;
			flex-direction: column;
			@media (max-width: 768px) {
				border-radius: 0;
				padding: 15px;
			}
			div {
				outline: none !important;
			}
			.slick-slider {
				.slick-arrow {
					display: none !important;
				}
				.slick-list {
					overflow: visible;
					.slick-track {
						display: flex;
						padding-right: 7px;
						.slick-slide {
							width: 100%;
							margin-left: 13px;
							&:first-child {
								margin-left: 0;
							}
						}
					}
				}
				.slick-dots {
					bottom: -23px;
				}
			}
		`}
			${({ passwordInfoHolder }) =>
		passwordInfoHolder &&
		css`
			width: 100%;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			& > div {
				width: 100%;
				align-items: center;
			}
			& div + div {
				flex: 0 0 36px;
				max-width: 36px;
				height: 50px;
				margin-left: -6px;
				margin-top: 26px;
			}
		`}
		${({ passwordInfo }) =>
		passwordInfo &&
		css`
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #d9c9a2;
			border-radius: 0 6px 6px 0;
			margin-left: -6px;
			position: relative;
			max-width: 66px;
			flex: 0 0 66px;
		`}
		${({ categorySelect }) =>
		categorySelect &&
		css`
			grid-column: auto / span 2;
			--h: ${({ theme }) => getHSL('h', theme.colors.border.default)};
			--s: ${({ theme }) => getHSL('s', theme.colors.border.default)};
			--l: ${({ theme }) => getHSL('l', theme.colors.border.default)};
			flex-direction: column;
			display: flex;
			@media (max-width: 768px) {
				grid-column: auto / span 1;
			}
			div {
				display: flex;
				width: 100%;
				align-items: center;
				flex-wrap: wrap;
				svg {
					display: none;
				}
				span {
					margin-left: -30px;
					svg {
						display: block;
					}
				}
			}
			label {
				display: inline-block;
				margin-bottom: ${({ theme }) => theme.space[1]};
				color: ${({ theme }) => theme.colors.text.textLabel};
				font-family: 'OpenSansSemiBold';
				line-height: 18px;
				font-size: 14px;
				margin-top:4px;
			}
			select {
				height: 50px;
				border-radius: 6px;
				border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
				&:focus {
					background-color: #fff;
					border-color: ${({ theme }) => theme.colors.primary};
					outline: 0;
					box-shadow: 0 0 0 0.2rem hsla(var(--h), var(--s), var(--l), 30%);
				}
			}
		`}
		${({ cardNumber }) =>
		cardNumber &&
		css`
			input {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				height: 50px !important;
				font-family: 'OpenSansSemiBold';
				font-size: 1rem;
				font-weight: 400;
				line-height: 1.5;
				color: ${({ theme }) => theme.colors.text.body};
				border: 1px solid ${({ theme }) => theme.colors.border.grayMedium}!important;
				border-radius: 6px !important;
				transition: all 200ms ease-in-out;
				margin-top: 0 !important;
				padding: 0.75em !important;
			}
			label {
				font-size: 16px;
				line-height: 21px;
				font-family: 'OpenSansSemiBold';
			}

			.floating-input:placeholder-shown + .floating-label::before {
				bottom: 0px !important;
				padding-left: 3px !important;
			}

			.floating-label::before,
			.floating-input:focus + .floating-label::before {
				bottom: 7px !important;
				padding-left: 7px !important;
				margin-left: 8px !important;
			}
			.floating-input:focus {
				border-color: ${({ theme }) => theme.colors.primary}!important;
			}
			.floating-input:focus + .floating-label::before {
				bottom: 7px !important;
				padding-left: 7px !important;
				margin-left: 8px !important;
			}
		`}
		${({ buttonHeader }) =>
		buttonHeader &&
		css`
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
			padding-bottom: 20px;
			h1 {
				text-transform: uppercase;
			}
			button {
				text-transform: capitalize;
			}
			@media (max-width: 832px) {
				button {
					flex: initial;
				}
			}
			@media (max-width: 768px) {
				margin: 30px 20px;
			}
		`}
		${({ UOMSelect }) =>
		UOMSelect &&
		css`
			@media (max-width: 768px) {
				grid-column: auto / span 1;
			}
			sup {
				color: ${({ theme }) => theme.baseColors.red}!important;
				font-family: 'OpenSansBold';
				font-size: 20px;
				margin: 0 0 0 0;
				display: inline-block;
				position: absolute;
			}
			div {
				display: flex;
				width: 100%;
				align-items: center;
				flex-wrap: wrap;
				svg {
					display: none;
				}
				span {
					margin-left: -30px;
					svg {
						display: block;
					}
				}
			}
			label {
				display: inline-block;
				margin-bottom: ${({ theme }) => theme.space[1]};
				color: ${({ theme }) => theme.colors.text.textLabel};
				font-family: 'OpenSansSemiBold';
				line-height: 18px;
				font-size: 14px;
			}
			select {
				height: 50px;
				border-radius: 6px;
				border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
				&:focus {
					background-color: #fff;
					border-color: ${({ theme }) => theme.colors.primary};
					outline: 0;
					box-shadow: 0 0 0 0.2rem hsla(var(--h), var(--s), var(--l), 30%);
				}
			}
		`}
`;
export { Stack };
