import styled from 'styled-components';
import { getHSL } from '../../../ui/utils/getHSL';

const StyledProductMainHeading = styled.div`
	position: relative;
	width: 100%;
	margin-top: -10px;
	.supplier-msg {
		background: #f0e9da;
		border: 1px solid rgba(189,188,185,1);
		padding: 22px;
		border-radius: 8px;
		margin-top: 24px;
		h4 {
			margin-top: 0;
			margin-bottom: 12px;
		}
		strong {
			text-transform: Uppercase;
			color: #242b24;
		}
		span {
		}
	}
	h1 {
		line-height: normal;
		display: flex;
		align-items: center;
		word-break: break-word;
	}
	h2 {
		strong {
			font-family: 'OpenSansBold';
			font-size: 30px;
			margin-right: 7px;
			margin-top: 7px;
		}
	}
	h3 {
		margin-top: 5px;
		strong {
			font-family: 'OpenSansBold';
			text-decoration: underline;
			cursor: pointer;
			&:hover {
				color: ${({ theme }) => theme.baseColors.primary};
			}
			a {
				text-decoration: underline;
				margin-left: 4px;
				cursor: pointer;
				&:hover {
					color: ${({ theme }) => theme.baseColors.primary};
				}
			}
		}
	}
	h6 {
		margin-top: 42px;
		strong {
			font-family: 'OpenSansBold';
		}
	}
	h4 {
		margin-top: 7px;
		br {
			display: none;
		}
		strong {
			font-family: 'OpenSansBold';
			color: ${({ theme }) => theme.colors.text.error01};
			+ strong {
				font-family: 'OpenSansBold';
				color: ${({ theme }) => theme.colors.text.black};
			}
		}
	}
	@media (max-width: 860px) {
		text-align: center;

		h1 {
			font-size: 26px;
			justify-content: Center;
		}
		h2,
		h3,
		h4 {
			font-size: 17px;
		}
		h2 {
			strong {
				font-size: 17px;
				margin-top: 7px;
			}
		}
		h3 {
			margin-top: 21px;
		}
		h4 {
			margin-top: 15px;
			br {
				display: block;
			}
		}
	}
	.addToWatchList {
		margin-left: 15px;
		display: flex;
		align-items: center;
		margin-top: 5px;

		.Favorites {
			border: solid 1px #b4b4b4;
			padding: 0 !important;
			width: 44px;
			max-width: 44px;
			flex: 0 0 44px;
			height: 44px;
			display: flex;
			justify-content: center;
			background: transparent;
			align-items: center;
			/* &:hover,
			&:focus {
				background: ${({ theme }) => theme.baseColors.primary};
				border: 1px solid ${({ theme }) => theme.baseColors.primary};

				svg {
					path {
						fill: ${({ theme }) => theme.baseColors.white};
					}
				}
			} */
			&.active {
				background: ${({ theme }) => theme.baseColors.primary} !important;
				svg {
					path {
						fill: ${({ theme }) => theme.baseColors.white};
					}
				}
			}
		}
	}
`;
const ItemQuantityHolder = styled.div`
	width: 100%;
	margin-top: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid ${({ theme }) => theme.colors.border.YellowBorder};
	border-radius: 8px;
	overflow: hidden;
	@media (max-width: 860px) {
		flex-direction: column;
		margin-top: 33px;
	}
	h5 {
		margin: 0 0 4px 0;
		padding: 0;
		line-height: 21px;
	}
	h6 {
		line-height: 26px;
		font-family: 'OpenSansRegular';
		margin-top: 0;
		strong {
			font-family: 'OpenSansBold';
		}
	}
	div {
		padding: 0;
		flex-grow: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		height: 80px;
		border-right: 1px solid ${({ theme }) => theme.colors.border.YellowBorder};
		@media (max-width: 860px) {
			width: 100%;
			border-right: none;
			border-bottom: 1px solid ${({ theme }) => theme.colors.border.YellowBorder};
			flex-direction: row;
			justify-content: space-between;
			padding: 0;
			height: auto;
			label {
				div {
					padding: 20px;
				}
			}
		}
		&:last-child {
			border: none;
		}
	}
	input {
		display: none;
	}
	label {
		width: 100%;
		padding: 0 !important;
		margin-bottom: 0;
		&::before {
			display: none !important;
		}
	}
`;
export { StyledProductMainHeading, ItemQuantityHolder };
