import React, { ReactElement, ReactNode, SyntheticEvent } from 'react';
import styled, { css } from 'styled-components';

type StyledCheckRadioProps = {
	inline?: boolean;
	disabled?: boolean;
	label?: string;
	payMethodSelection?: boolean;
	hasActiveBg?: boolean;
};

export const StyledCheckboxWrapper = styled.div<StyledCheckRadioProps>`
	--space-unit: 1em;
	--space-xxxxs: calc(0.125 * var(--space-unit));
	--space-xxxs: calc(0.25 * var(--space-unit));
	--body-line-height: 1.4;
	--radius-md: 0.25em;
	--checkbox-radio-size: 26px;
	--checkbox-radio-gap: 11px;
	--checkbox-radio-border-width: 1px;
	--checkbox-radio-line-height: var(--body-line-height);
	--checkbox-marker-color: ${({ theme }) => theme.colors.primary};
	--checkbox-border: ${({ theme }) => theme.colors.border.yellowDark};
	--checkbox-bg: ${({ theme }) => theme.colors.white};

	--checkbox-marker-size: 12px;
	--checkbox-radius: 6px;

	--color-bg: hsl(0, 0%, 100%);

	position: relative;
	display: flex;
	align-items: center;

	${({ inline }) =>
		inline &&
		css`
			display: inline-block;
		`}

	input[type='checkbox'],
  		input[type='radio'] {
		position: absolute;
		margin: 0 !important;
		padding: 0 !important;
		opacity: 0;
		height: 0;
		width: 0;
		pointer-events: none;
		font-size: 14px;

		& + label {
			display: inline-flex;
			align-items: flex-start;
			line-height: var(--checkbox-radio-line-height);
			user-select: none;
			cursor: pointer;
			color: #2f363d;
		}

		& + label::before {
			content: '';
			display: inline-block;
			position: relative;
			top: ${({ label }) =>
				label === ''
					? '5px'
					: `calc(
        (1em * var(--checkbox-radio-line-height) - var(--checkbox-radio-size)) /
          2
      )`};
			flex-shrink: 0;
			width: var(--checkbox-radio-size);
			height: var(--checkbox-radio-size);
			background-color: var(--checkbox-bg);
			border-width: var(--checkbox-radio-border-width);
			border-color: var(--checkbox-border);
			border-style: solid;
			background-repeat: no-repeat;
			background-position: center;
			margin-right: var(--checkbox-radio-gap);
			transition: transform 0.2s, border 0.2s;
		}

		&:not(:checked):not(:focus):not(:disabled) + label:hover::before {
			border-color: ${({ theme }) => theme.colors.border.yellowDark};
		}

		& + label::before {
			border-radius: var(--checkbox-radius);
		}

		&:checked + label::before {
			background-color: var(--checkbox-marker-color);
			box-shadow: none;
			border-color: var(--checkbox-marker-color);
			transition: transform 0.2s;
		}

		&:active:not(:disabled) + label::before {
			transform: scale(0.8);
			transition: transform 0.2s;
		}

		&:checked:active + label::before {
			transform: none;
			transition: none;
		}

		&:checked + label::before {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpolyline points='1 6.5 4 9.5 11 2.5' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E");
			background-size: var(--checkbox-marker-size);
		}

		&:checked:active + label::before,
		&:focus + label::before {
			border-color: ${({ theme }) => theme.colors.border.yellowDark};
			box-shadow: 0 0 0 3px hsla(var(--primary-color-hsl), 30%);
		}

		&:disabled + label {
			opacity: 0.5;
			cursor: not-allowed;
		}

		/* &:not(:checked):not(:focus):not(:focus) + label:hover::before {
      border-color: var(--upper-low-contrast-neutral);
    } */

		// variation with background color
		${({ payMethodSelection }) =>
			payMethodSelection &&
			css`
				& + label {
					padding: var(--space-xxxxs) var(--space-xxxs);
					border-radius: var(--radius-md);
					transition: background 0.2s;
				}

				& + label:hover {
					background-color: #eee;
				}

				&:active + label,
				&:focus + label {
					background-color: orange;
				}
			`}
	}
`;

type RefReturn =
	| string
	| ((instance: HTMLInputElement | null) => void)
	| React.RefObject<HTMLInputElement>
	| null
	| undefined;

export type CheckboxProps = {
	name?: string;
	label?: string | any;
	value?: string;
	checked?: boolean;
	disabled?: boolean;
	inline?: boolean;
	payMethodSelection?: boolean;
	children?: ReactNode;
	handleChange?: (e: React.FormEvent<HTMLInputElement>) => void;
	register?: () => RefReturn;
};

function Checkbox({
	name,
	label,
	value,
	checked,
	disabled,
	inline,
	children,
	handleChange,
	register,
}: CheckboxProps): ReactElement {
	const id = `cb-${label ? label.replace(/ /g, '').toLowerCase() : null}-${Date.now()}`;

	return (
		<StyledCheckboxWrapper inline={inline} label={label}>
			<input
				name={name}
				ref={register && register()}
				type="checkbox"
				id={id}
				value={value}
				checked={checked}
				disabled={disabled}
				onChange={(e: SyntheticEvent<HTMLInputElement>) => handleChange && handleChange(e)}
			/>
			<label htmlFor={id}>{label || children}</label>
		</StyledCheckboxWrapper>
	);
}

export { Checkbox };
