import { position, color } from 'styled-system';
import styled from 'styled-components';
import { getHSL } from '../../utils/getHSL';

const StyledProductListView = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	column-gap: 20px;
	row-gap: 20px;
	article {
		flex-direction: row;
		background: ${({ theme }) => theme.colors.bg.white};
		border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
		box-sizing: border-box;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 16px;
		overflow: hidden;
		transition: box-shadow 100ms ease-in, transform 100ms ease-in;
		align-items: flex-start;
		&.row-highlighted {
			pointer-events: none;
			box-shadow: none;
			position: relative;
			border: 1px solid rgb(255, 0, 0, 0.1);
			span,
			section,
			div {
				opacity: 0.9;
			}
			&:after {
				content: 'Out of Stock';
				position: absolute;
				color: ${({ theme }) => theme.newColors.white};
				right: 0;
				top: 0;
				font-family: 'OpenSansSemiBold';
				font-size: 19px;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				background: rgb(160 160 160 / 50%);
			}
			picture {
				border: none;
			}
		}
		&.un-available {
			pointer-events: none;
			box-shadow: none;
			position: relative;
			border: 1px solid rgb(255, 0, 0, 0.1);
			span,
			section,
			div {
				opacity: 0.9;
			}
			&:after {
				content: 'Product not available';
				position: absolute;
				color: ${({ theme }) => theme.baseColors.red};
				right: 0;
				top: 0;
				font-family: 'OpenSansSemiBold';
				font-size: 19px;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: flex-end;
				justify-content: center;
				background: rgb(255 0 0 / 3%);
				padding-bottom: 20px;
			}
			picture {
				border: none;
				background-size: cover;
			}
		}
		span {
			max-width: 216px;
			flex: 0 0 216px;
			height: 100%;
			position: relative;
			transition: opacity 0.35s;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			picture {
				width: calc(100% - 0px);
				height: calc(100% - 0px);
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center;
				margin: 0px;
				border-right: 1px solid ${({ theme }) => theme.colors.bg.lightYellow05};
				background-color: #faf4ea;
			}
			.temp-unavailable {
				background: rgba(155, 6, 6, 0.73);
				border-radius: 10px;
				font-size: 14px;
				padding: 9px 14px;
				text-align: center;
				position: absolute;
				bottom: 9px;
				width: 95%;
				color: #fff;
				.date {
					font-weight: 800;
					font-size: 15px;
				}
			}
			@media (max-width: 768px) {
				max-width: 126px;
				flex: 0 0 126px;
				background-position: center center;
			}
			@media (max-width: 360px) {
				max-width: 80px;
				flex: 0 0 80px;
			}
			&:hover {
				transform: translateY(0px);
				box-shadow: 0 20px 20px #eee;
				border: 1px solid ${({ theme }) => theme.baseColors.baseYellowLight};
				&:after {
					content: '';
					width: 100%;
					position: absolute;
					height: 100%;
					top: 0;
					right: 0;
					background: rgba(31, 38, 31, 0.78);
					opacity: 1;
				}
				button {
					bottom: calc(50% - 17px);
					opacity: 1;
				}
			}
			&:after {
				content: '';
				opacity: 0;
				transition: opacity 0.35s;
			}
			button {
				--h: ${({ theme, color }) => getHSL('h', color || theme.baseColors.baseYellow)};
				--s: ${({ theme, color }) => getHSL('s', color || theme.baseColors.baseYellow)};
				--l: ${({ theme, color }) => getHSL('l', color || theme.baseColors.baseYellow)};
				--a: 100%;
				width: auto;
				height: 35px;
				opacity: 0;
				z-index: 1;
				color: ${({ theme }) => theme.colors.text.white};
				background: hsla(var(--h), var(--s), var(--l), var(--a));
				border: solid 1px hsl(var(--h), var(--s), var(--l));
				outline: none;
				border: none;
				position: absolute;
				transition: opacity 0.3s, ease-in-out 0.3s;
				bottom: 0;
				font-size: 14px;
				font-family: 'OpenSansSemiBold';
				cursor: pointer;
				display: flex;
				white-space: nowrap;
				align-items: center;
				justify-content: center;

				&:hover {
					background: hsl(var(--h), var(--s), calc(var(--l) - 10%));
					border: 1px solid hsl(var(--h), var(--s), calc(var(--l) - -5%));
				}
				span {
					height: auto;
					width: auto !important;
					max-width: fit-content;
					flex: 0 !important;
					margin-right: 8px;
					-webkit-animation: beat 0.25s infinite alternate;
					-moz-animation: beat 0.25s infinite alternate;
					-ms-animation: beat 0.25s infinite alternate;
					-o-animation: beat 0.25s infinite alternate;
					animation: beat 0.25s infinite alternate;

					-webkit-transform-origin: center;
					-moz-transform-origin: center;
					-o-transform-origin: center;
					-ms-transform-origin: center;
					transform-origin: center;
					box-shadow: none !important;
					border: none !important;
					&:after {
						display: none;
					}
					@keyframes beat {
						to {
							-webkit-transform: scale(1.3);
							-moz-transform: scale(1.3);
							-o-transform: scale(1.3);
							-ms-transform: scale(1.3);
							transform: scale(1.3);
						}
					}
				}
				@media (max-width: 768px) {
					opacity: 1;
					width: 40px;
					height: 40px;
					padding: 0;
					border-radius: 50px;
					bottom: 15px !important;
					right: 15px;
					transition: none;
					cursor: pointer;
					span {
						animation: none !important;
						margin-right: 0;
						cursor: pointer;

						svg {
							height: 20px;
							width: 20px;
						}
					}
					div {
						display: none;
					}
				}
			}
		}
		section {
			height: 100%;
			padding: 25px;
			@media (max-width: 768px) {
				padding: 20px;
			}
			@media (max-width: 480px) {
				padding: 15px;
			}
		}
		slot {
			width: 100%;
			display: flex;
			align-items: flex-end;
			flex-direction: row;
			justify-content: space-between;
			div + div {
				flex: 0 0 150px;
				max-width: 150px;
				@media (max-width: 768px) {
					flex: auto;
					max-width: fit-content;
					margin: 20px 0 10px;
					p {
						margin-top: 0 !important;
					}
					div {
						margin: 0 !important;
					}
				}
			}
			@media (max-width: 768px) {
				flex-direction: column;
				align-items: end;
				div {
					+ div {
						display: flex;
						align-items: center;
					}
				}
			}
			h1,
			h2,
			h3,
			h4,
			h5,
			h6,
			label {
				font-weight: normal;
			}
			h1 {
				@media (max-width: 768px) {
					width: calc(100vw - 193px);
				}
				@media (max-width: 360px) {
					width: calc(100vw - 140px);
				}
			}
			h2 {
				@media (max-width: 768px) {
					margin-bottom: 12px;
				}
			}
			h4 {
				margin-top: 9px;
				margin-bottom: -24px;
				@media (max-width: 768px) {
					margin: 0 0 0 4px !important;
				}
			}
			+ slot {
				padding: 16px 0 0 0;
				@media (max-width: 768px) {
					padding: 16px 0 10px 0;
				}
				h5 {
					strong {
						font-family: 'OpenSansBold';
					}
				}
				+ slot {
					margin: 20px 0 0 0;
					padding: 0;
					@media (max-width: 768px) {
						margin: 0;
					}
					p {
						display: flex;
						justify-content: flex-end;
						strong {
							font-family: 'OpenSansSemiBold';
							margin-right: 4px;
						}
						small {
							font-size: 16px !important;
							@media (max-width: 768px) {
								display: none !important;
							}
						}
						span {
							display: none;
							@media (max-width: 768px) {
								display: flex !important;
								width: auto;
								height: auto;
								flex: 0 0 0;
								margin-right: 6px;
							}
						}
						@media (max-width: 768px) {
							margin-top: 9px;
							align-items: center;
						}
					}
				}
			}
			label {
				text-decoration: underline;
				cursor: pointer;
				&:hover {
					color: ${({ theme }) => theme.baseColors.primary};
				}
			}
		}
	}
`;
const IconHolder = styled.div`
	display: flex;
	width: 100%;
	padding: 20px 0 0 0;
	margin: auto 0 0 0;
	div {
		margin-right: 10px;
	}
	span {
		height: auto !important;
		background: transparent !important;
		border: none !important;
		box-shadow: none !important;
		width: auto !important;
		display: inline-block !important;
		margin: 0 10px 0 0;
		color: ${({ theme }) => theme.colors.text.black};
		font-family: 'OpenSansBold';
		display: flex !important;
		font-size: 18px;
		max-width: auto;
		flex: 0;
		align-items: center;
		justify-content: flex-start !important;
		div {
			font-family: 'OpenSansSemiBold';
			margin-left: 3px;
			font-size: 15px;
		}
		&:after {
			display: none !important;
		}
		svg {
			width: 30px;
			height: 30px;
		}
	}
	div {
		display: flex !important;
		align-items: center;
	}
`;
const RatingHolder = styled.div`
	display: flex;
	div {
		margin-bottom: -31px;
	}
`;
export { StyledProductListView, IconHolder, RatingHolder };
