export enum pageNavigators {
	prev,
	next,
	fastPrev,
	fastNext,
}
export const condition ={
	eq : 'eq', //Equals
	finset : 'finset', //	A value within a set of values
	from : 'from', //	The beginning of a range. Must be used with to.
	gt : 'gt', //	Greater than
	gteq : 'gteq', //	Greater than or equal
	in : 'in', //	In. The value can contain a comma-separated list of values.
	like : 'like', //	Like. The value can contain the SQL wildcard characters when like is specified.
	lt : 'lt', //	Less than
	lteq : 'lteq', //	Less than or equal
	moreq : 'moreq', //	More or equal
	neq : 'neq', //Not equal
	nfinset: 'nfinset', //	A value that is not within a set of values.
	nin : 'nin', //Not in. The value can contain a comma-separated list of values.
	notnull :'notnull', //	Not null
	null : 'null', //	Null
	to: 'to',
}

export const pages = {
	productList: 'productList',
};

export const types = {
	product: 'Products',
	brand: 'Brands',
	supplier: 'Suppliers',
};

export const OrderStatuses = {
	Processing : 'processing',
	Delivered : 'delivered',
	Cancelled : 'canceled',
	Rejected : 'order_rejected',
	Confirmed : 'confirmed',
	Ordered : 'ordered',
	Shipped : 'shipped',
	Disputed : 'disputed',
	Pending : 'pending',
	Closed :'closed',
	Onhold : 'holded',
	DeliveredWithIssue :'disputed',
	PendingApproval: 'pending_approval',
	Expired:'expired',
	Approved:'approved',
	PendingOrderRejected : 'rejected',
}

export const venueUserPermissionList = {
	ChangePassword : 2,
	UserRoleManagementView: 4,
	UserRoleManagementAdd: 5,
	UserRoleManagementEdit: 6,
	UserRoleManagementDelete: 7,
	RecentOrdersView: 9,
	RecentOrdersCancel : 10,
	RecentOrdersDelete: 11,
	RecentOrdersAdmin : 12,
	DownloadPDF : 13,
	ParkedOrdersView: 15,
	ParkedOrdersAdd: 16,
	ParkedOrdersEdit: 17,
	ParkedOrdersDelete: 18,
	ParkedOrdersAdmin : 19,
	QuickOrdersView: 21,
	QuickOrdersAdd: 22,
	QuickOrdersEdit: 23,
	QuickOrdersDelete: 24,
	QuickAccessButtonsView: 26,
	QuickAccessButtonsAdd: 27,
	QuickAccessButtonsEdit: 28,
	QuickAccessButtonsDelete: 29,
	PlaceOrder : 31,
	ManageOrderAdmin : 32,
	ManageProductsinCart: 34,
	FavoriteOrderView: 36,
	FavoriteOrderAdd: 37,
	FavoriteOrderEdit: 38,
	FavoriteOrderDelete: 39,
	ScheduleOrderfromFavoriteOrder: 40,
	DeliveryConfirmation : 41,
	OrderApproval : 43,
	viewAllOrders: 44
	//new permission will be added here
};

export const dcUserPermissionList = {
	CreditStatusView : 1,
	CreditStatusUpdate : 3,
	OrderManagementView : 5,
	OrderManagementUpdate : 6,
	Vacation : 7,
	SalesReport : 9,
	ViewsReport : 10,
	ProductSoldReport : 11,
	ReportManagement : 8

	//new permission will be added here
};

export const permissionGroup = {
	UserRole : 'user role management',
	RecentOrder : 'recent order',
	ParkedOrder : 'parked order',
	QuickOrder :'quick order',
	QuickAccess : 'quick access',
	FavoriteOrder : 'favorite order',
	Report : 'report'
}

export const userTypes = {
	venueUser : 'vu',
	venueAdmin : 'va',
	supplierUser : 'su',
	supplierAdmin : 'sa',
	HospitalityGroup : 'h',
	Supplier : 's'
}
