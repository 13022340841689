import styled from 'styled-components';

const PendingOrderHolder = styled.div`
	padding-bottom: 30px !important;
	table {
		width: 100% !important;

		thead,
		tbody {
			tr {
				td,
				th {
					width: 200px !important;
					max-width: 200px !important;
					flex: 0 0 200px !important;
					&:nth-child(2) {
						@media (max-width: 480px) {
							width: 100% !important;
							max-width: 100% !important;
							flex: 0 0 100% !important;
							h1 {
								max-width: 290px;
							}
						}
					}
					&:nth-child(4) {
						width: 125px !important;
						max-width: 125px !important;
						flex: 0 0 125px !important;
						text-align: right !important;
						@media (max-width: 768px) {
							width: 33.33% !important;
							max-width: 33.33% !important;
							flex: 0 033.33% !important;
							margin-right: 0 !important;
							padding-right: 20% !important;
						}
						@media (max-width: 480px) {
							width: 50% !important;
							max-width: 50% !important;
							flex: 0 050% !important;
							padding-right: 54px !important;
						}
					}
					&:nth-child(1),
					&:nth-child(3),
					&:nth-child(6) {
						width: 125px !important;
						max-width: 125px !important;
						flex: 0 0 125px !important;
						@media (max-width: 768px) {
							width: 33.33% !important;
							max-width: 33.33% !important;
							flex: 0 033.33% !important;
						}
						@media (max-width: 480px) {
							width: 50% !important;
							max-width: 50% !important;
							flex: 0 050% !important;
						}
					}
					&:nth-child(5) {
						@media (max-width: 1300px) {
							padding-left: 3% !important;
						}
						@media (max-width: 768px) {
							padding-left: 0 !important;
						}
					}
					&.action {
						width: 220px;
						max-width: 220px;
						flex: 0 0 220px;
						display: flex;
						align-items: center;
						button {
							width: auto !important;
							text-transform: capitalize;
							padding: 0 15px !important;
							margin: 0 10px 0 0 !important;
							span {
								font-size: 14px !important;
							}
						}
					}
				}
			}
		}
		tbody {
			margin-bottom: 0 !important;
			tr {
				&:hover {
					cursor: pointer;
					background: ${({ theme }) => theme.colors.bg.lightYellow05};
					td {
						background: ${({ theme }) => theme.colors.bg.lightYellow05};
					}
				}
				td.action {
					slot {
						margin-left: auto;
						margin-right: 0;
						@media screen and (max-width: 480px) {
							margin-right: auto;
							margin-left: 0;
						}
						+ button {
							margin-left: 8px;
							width: auto;
							padding: 0 15px !important;
						}
					}
					div {
						column-gap: 10px;
						button {
							padding: 0;
							max-width: 34px;
							flex: 0 0 34px;
							width: 34px;
						}
					}
					@media screen and (max-width: 480px) {
						width: 100% !important;
						flex: 0 0 100% !important;
						max-width: 100% !important;
					}
				}
			}
		}
	}
	table {
		thead {
			@media (max-width: 768px) {
				display: none;
			}
			tr {
				th {
					&.statusFilter {
						position: relative;
						display: flex;
						align-items: center;
						justify-content: center;
						div {
							position: absolute;
							right: 0;
							button {
								margin-left: 0;
							}
						}
					}
				}
			}
		}
		tbody {
			tr {
				td {
					slot {
						max-width: 110px;
						flex: 0 0 110px;
						font-size: 13px;
						line-height: 17px;
						display: flex;
						align-items: center;
						justify-content: center;
						margin: 0 auto;
						@media screen and (max-width: 767px) {
							flex: 0;
							margin: 0 auto 10px 0;
							width: 100px;
							min-height: 30px;
						}
					}
				}
			}
		}
	}
`;
const PageSearchHolder = styled.div`
	width: 100%;
	padding-top: 20px;
	padding-bottom: 25px;
	display: flex;
	justify-content: space-between;
	section {
		width: 650px;
		@media screen and (max-width: 767px) {
			width: auto;
			flex: 1;
		}
	}
	small {
		button {
			@media screen and (max-width: 960px) {
				width: 60px;
				flex: 0 0 60px;
				max-width: 60px;
				margin-left: 20px;
				height: 50px;
			}

			div {
				width: auto;
				@media screen and (max-width: 960px) {
					display: none;
				}
			}
			@media screen and (max-width: 960px) {
				svg {
					margin-right: 0;
				}
			}
		}
		& + div {
			display: none;
			@media screen and (max-width: 767px) {
				display: Flex;
				margin-left: 20px;
				width: 60px;

				.pr-icon {
					width: 50px;
					height: 50px;
					background: ${({ theme }) => theme.baseColors.primary};
					border-radius: 6px;
					display: flex;
					align-items: center;
					justify-content: center;
					svg {
						path {
							fill: ${({ theme }) => theme.baseColors.white};
						}
					}
				}
				button {
					padding: 0;
					& + div {
						margin-top: 70px !important;
						right: 8px !important;
					}
				}
			}
		}
	}

	@media screen and (max-width: 767px) {
		width: 100%;
	}
`;
const FilterHolder = styled.div`
	display: flex;
`;
export { PendingOrderHolder, PageSearchHolder, FilterHolder };
