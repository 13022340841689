import styled from 'styled-components';
const StyledLineAccordion = styled.div`
	h1 {
		text-transform: uppercase;
		line-height: normal;
	}
	a,
	p {
		text-decoration: none;
		text-decoration: none;
		line-height: 18px;
		position: relative;
		margin-right: 5px;
		padding-right: 11px;
		&:before {
			content: '/';
			position: absolute;
			font-size: 14px;
		}
		&:hover {
			color: ${({ theme }) => theme.baseColors.baseYellow};
		}
	}
	a {
		color: ${({ theme }) => theme.colors.text.blackLight};
		font-family: 'OpenSansMedium';

		&:before {
			font-family: 'OpenSansMedium';
			right: 0;
		}
	}
	p {
		color: ${({ theme }) => theme.baseColors.baseYellow};
		font-family: 'OpenSansBold';
		&:before {
			color: ${({ theme }) => theme.baseColors.black};
			font-family: 'OpenSansMedium';
			left: 0;
			display: none;
		}
	}
	.is-open {
		.pr-button-label {
			svg {
				transform: rotate(180deg);
			}
		}
	}
	.hideMe { display: none; }
	// #2_tag {display: none !important;}

`;

const AccordionPanel = styled.div`
	display: flex;
	flex-direction: column;
	.Collapsible {
		position: relative;
		margin-bottom: 20px;
		&:before {
			content: '';
			height: 1px;
			width: calc(100% - 30px);
			top: 13px;
			right: 30px;
			position: absolute;
			border-top: 1px solid rgba(0, 0, 0, 0.15);
			z-index: -1;
		}
	}
	.Collapsible__contentOuter {
		overflow: visible !important;
	}
	.Collapsible__trigger {
		font-size: 18px;
		font-family: 'OpenSansBold';
		color: ${({ theme }) => theme.colors.text.black04};
		background: ${({ theme }) => theme.colors.bg.white};
		padding-right: 13px;
		cursor: pointer;
		&.is-closed,
		&.is-open {
			&:after {
				content: '+';
				box-sizing: border-box;
				height: 24px;
				border: 1px solid ${({ theme }) => theme.colors.text.black04};
				background: ${({ theme }) => theme.baseColors.white};
				width: 24px;
				border-style: solid;
				border-color: #000;
				top: 2px;
				right: 0;
				position: absolute;
				transition: all 0.5s ease;
				padding: 5px 0 7px 0px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 4px;
				cursor: pointer;
			}
		}

		&.is-open {
			&:after {
				content: '-';
			}
		}
		&.is-closed ~ .Collapsible__contentOuter {
			overflow: hidden !important;
		}
	}
`;

const VenueDetails = styled.div`
	margin: 34px 0 54px;
	position: relative;
	width: 660px;
	@media (max-width: 767px) {
		width: 100%;
	}
	section {
		margin: 0;
		display: grid;
		grid-template-columns: repeat(2, 1fr) !important;
		row-gap: 25px;
		column-gap: 25px;

		@media (max-width: 640px) {
			grid-template-columns: repeat(1, 1fr) !important;
		}
		.statusSelect {
			float: left;
			display: flex;
			flex-direction: column;
			min-height: 60px;
			align-items: flex-start;
			label + label {
				height: 50px;
				display: flex;
				padding: 0;
				margin: 0;
			}
		}
	}
	/* button {
		background: none;
		outline: none;
		border: none;
		position: absolute;
		top: 12px;
		right: 15px;
		&:hover,
		&:focus {
			background: none;
			outline: none;
			border: none;
		}
		svg {
			width: 12px;
			height: 12px;
		}
	} */
	.status-button-label {
		color: #ecb136;
		font-weight: normal;
		font-size: 13px;
		min-width: 87px;
		background: #fdf9f0;
		border: 1px solid #ecb136;
		border-radius: 6px;
		padding: 3px 28px;
	}
	.multiselect-container {
		.search-wrapper {
			min-height: 50px;
			border: 1px solid #bdbcb9;
			border-radius: 6px;
			padding: 8px 5px 5px;
			span {
				background: ${({ theme }) => theme.baseColors.primary};
				border-radius: 5px;
			}
		}
		li {
			&.highlight {
				background: ${({ theme }) => theme.baseColors.primary};
			}
			&:hover {
				background: ${({ theme }) => theme.baseColors.primary};
			}
		}
	}
`;
const DropDownMultiSelect = styled.div`
	width: 100%;
	.dropdown {
		width: 100%;

		a {
			width: 100%;
			display: flex !important;
			color: #ecb136;
			font-weight: normal;
			font-size: 13px;
			border: 1px solid #ecb136;
			border-radius: 6px;
			padding: 3px 28px;
			min-height: 50px;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			&:before {
				display: none;
			}
			&:after {
				position: absolute;
				position: absolute;
				top: 12px;
				right: 13px;
			}
			ul {
				width: 100%;
				max-height: 100px;
				padding-right: 30px;
				overflow: auto;
				::-webkit-scrollbar {
					width: 4px;
					height: 4px;
					border-radius: 50px;
				}

				&::-webkit-scrollbar-track {
					background: white;
				}

				&::-webkit-scrollbar-thumb {
					background: ${({ theme }) => theme.baseColors.primary};
					border-bottom: 1px solid white;
					border-radius: 50px;
				}
				li {
					:first-child{
						span{
							display: none;
						}
					}
					input {
						width: 100%;
					}
					span {
						background: ${({ theme }) => theme.baseColors.primary};
						color: ${({ theme }) => theme.baseColors.white};
						font-size: 13px;
						border-radius: 5px;
						padding: 5px;
						button {
							color: ${({ theme }) => theme.baseColors.white};
						}
					}
				}
			}
		}
		input {
			border: none;
		}
	}
	.dropdown-content {
		width: 100%;
		padding: 12px !important;
		max-height: 300px;
		overflow: auto;
		&::-webkit-scrollbar {
			width: 4px;
			height: 4px;
			border-radius: 50px;
		}

		&::-webkit-scrollbar-track {
			background: white;
		}

		&::-webkit-scrollbar-thumb {
			background: ${({ theme }) => theme.baseColors.primary};
			border-bottom: 1px solid white;
			border-radius: 50px;
		}
		ul {
			width: 100%;
			li {
				background: ${({ theme }) => theme.colors.bg.menu};
				border: 1px solid ${({ theme }) => theme.colors.border.yellowDark};
				border-top: 0;
				padding: 10px;
				&:first-child {
					border-top: 1px solid ${({ theme }) => theme.colors.border.yellowDark};
					padding-left: 10px !important;
				}
				i {
					&:after {
						width: 24px;
						height: 24px;
						background: ${({ theme }) => theme.colors.bg.white};
						border: 1px solid ${({ theme }) => theme.colors.border.yellowDark};
						color: ${({ theme }) => theme.baseColors.primary};
						position: relative;
						left: 0;
						z-index: 1;
						float: left;
						border-radius: 4px;
						text-align: center;
						font-size: 24px;
						line-height: 24px;
						font-family: 'HKGroteskBold';
						font-style: normal;
						font-weight: 900;
					}
				}
				label {
					margin-left: 15px !important;
				}
			}
		}
		.infinite-scroll-component {
			width: 100%;
		}
		span {
			position: relative;
			padding-left: 30px;
			margin: 0;
			min-height: 22px;
			line-height: 22px;
			&:before {
				content: '';
				display: inline-block;
				position: absolute;
				width: 23px;
				height: 23px;
				left: 0px;
				box-sizing: border-box;
				border: 1px solid ${({ theme }) => theme.colors.border.yellowDark};
				border-radius: 50px;
				background: ${({ theme }) => theme.baseColors.white};
				transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
				outline: 0 !important;
				border-radius: 50px;
			}
			&:after {
				display: inline-block;
				position: absolute;
				width: 22px;
				height: 22px;
				left: 0px;
				top: -1px;
				padding-top: 1px;
				font-size: 12px;
				text-align: center;
			}
		}
		input[type='checkbox'] {
			opacity: 0;
			position: absolute;
			&:checked + span::before {
				background: ${({ theme }) => theme.colors.border.yellowDark};
			}
			&:checked + span::after {
				content: '✓';
				color: ${({ theme }) => theme.baseColors.white};
			}
			&:disabled + span {
				opacity: 0.65;
				&:before {
					cursor: not-allowed;
				}
			}
		}
	}
`;
export { StyledLineAccordion, AccordionPanel, VenueDetails, DropDownMultiSelect };
