import styled from 'styled-components';

const AddToFavorites = styled.div`
	display: flex;
	height: auto;
	position: relative;
	flex-direction: column;
	background: ${({ theme }) => theme.colors.bg.white};
	padding: 0 10px;
	@media (max-width: 960px) {
		padding: 0;
	}
	h1,
	p {
		margin: 0;
		width: 100%;
		font-weight: normal;
	}
	button {
		padding: 0;
		min-width: 100px;
		height: 48px;
		text-transform: capitalize;
		font-size: 18px;
	}
`;
const AddToFavoritesContainer = styled.div`
	width: 100%;
	.simplebar-wrapper {
		max-height: calc(100vh - 350px);
	}
	h1 {
		margin: 22px 0;
		font-size: 20px;
		line-height: 26px;
		font-weight: normal;
		font-family: 'OpenSansRegular';
		padding: 0 !important;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	div {
		padding: 0;
	}
	input {
		display: none;
	}
	label {
		width: 100%;
		border-bottom: 1px solid ${({ theme }) => theme.colors.border.grayLightBorder01};
		padding: 0 !important;
		&::before {
			display: none !important;
		}
	}
	.simplebar-content {
		div {
			border-bottom: 1px solid rgba(218, 218, 218, 1);
			&:last-child {
				border-bottom: none;
				label {
					border-bottom: none;
				}
			}
		}
	}
	div {
		&:last-child {
			label {
				border-bottom: none;
			}
		}
	}
`;
const CreateNewFavorites = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	margin-bottom: 8px;
	margin-top: 16px;
	input {
		flex-grow: 1;
		border: 1px solid ${({ theme }) => theme.colors.border.grayMedium02};
		border-radius: 8px 0 0 8px;
		border-right: none;
		height: 46px;
		outline: none;
	}
	button {
		border-radius: 0 8px 8px 0;
		height: 46px;
		padding: 0;
		min-width: 46px;
	}
`;
export { AddToFavorites, AddToFavoritesContainer, CreateNewFavorites };
