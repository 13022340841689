import { userTypes, venueUserPermissionList } from 'models/enum-constants/common.constants';
import { UseGlobalState } from 'models/globalStateAccess';
import Iget from 'models/Iget';
import Ipost from 'models/Ipost';
import React, { ReactElement, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';
import { Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { Button, Loader } from 'ui/components';
import constants from 'utils/constants';
import helperFunctions from 'utils/helper.functions';
import hooks from 'utils/requestCtx/reactQueryHooks';
import utilityFunctions from 'utils/utility.functions';

const ListWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 300px;
	max-width: 300px;
	margin-left: 27px;
	position: sticky;
	top: 28vh;
	@media (max-width: 1200px) {
		width: 380px;
		max-width: 380px;
	}
	@media (max-width: 960px) {
		order: -1;
		width: 100%;
		max-width: 100%;
		margin: 20px 0 20px;
	}
	button {
		width: 100%;
		margin-top: 20px;
		font-size: 18px;
		border-radius: 6px !important;
	}
	.AdditionalNoteSession {
		background: ${({ theme }) => theme.colors.bg.lightYellow06};
		display: flex;
		flex-direction: column;
		padding: 14px 20px;
		width: 100%;
		h1 {
			line-height: 26px;
			margin-bottom: 2px;
		}
	}
	.ItemOrderList {
		display: flex;
		flex-direction: column;
		background: rgba(240, 233, 218, 0.26);
		padding: 16px 20px 15px;
		width: 100%;
		border: 1px solid ${({ theme }) => theme.colors.bg.lightYellow06};
		border-radius: 12px;
		row-gap: 10px !important;
		h1 {
			margin-bottom: 0px;
			font-size: 18px !important;
			padding-top: 10px;
		}
		h2,
		h3 {
			display: flex;
			font-size: 16px !important;
			padding-top: 10px;

			div {
				flex: 1;
			}
			strong {
				font-family: 'OpenSansSemiBold';
			}
		}
		h3 {
			border-top: 1px solid ${({ theme }) => theme.colors.bg.lightYellow06};
			padding-top: 10px;
			display: flex;
			align-items: center;
			margin-top: 10px;

			strong {
				font-family: 'OpenSansBold';
				font-size: 20px;
			}
			div {
				row-gap: 0px !important;
			}
		}
	}
`;
// type CheckoutPriceDetailsProps = {
// 	TotalItems?: string;
// 	TotalAmount?: string;
// };

function CheckoutPriceDetails(props): ReactElement {
	const [totalAmt, setTotalCount] = useState<any>(0);
	const [totalTax, setTotalTax] = useState<any>(0);

	const key = 'placeOrderKey';
	const [mutatePost] = hooks.useMutation(key);
	const history = useHistory();
	const venueId: any = localStorage.getItem('selectedVenue');
	let cartId = localStorage.getItem('cartId');
	const [isLoading, setIsLoading] = useState(false);
	const [state, dispatch] = UseGlobalState();
	const [venueDetails, setVenueDetails] = useState<any>({});

	if (cartId == null) {
		const CUSTOMER_TOKEN = localStorage.getItem('customerToken');
		utilityFunctions.getCartId(CUSTOMER_TOKEN);
		cartId = localStorage.getItem('cartId');

		if (cartId == null) {
			history.push('/login');
		}
	}

	//api to get total amount
	const url1 = '/carts/' + cartId + '/totals';
	const obj1: Partial<Iget> = {
		params: {},
		url: url1,
		enabled: false,
		key: 'carttotalKey3',
		resultFilteringRequired: false,
	};
	const totalQry = hooks.useQuery(obj1);

	useEffect(() => {
		totalQry.refetch();
	}, []);

	useEffect(() => {
		if (totalQry.data) {
			setTotalCount(totalQry.data?.grand_total);
			setTotalTax(totalQry.data?.tax_amount);
			dispatch({ cartTotal: helperFunctions.formatAmount(totalQry.data?.subtotal) });
		}
	}, [dispatch, totalQry.data]);

	const venueKey = 'venueDetails';
	const venueUrl = `experion/venue/get/${venueId}`;
	const item: Partial<Iget> = {
		params: {},
		url: venueUrl,
		enabled: true,
		key: venueKey,
	};
	const venueEditDetails = hooks.useQuery(item);

	useEffect(() => {
		setVenueDetails(venueEditDetails.data);
	}, [venueEditDetails.data]);

	const placeOrder = () => {
		setIsLoading(true);
		//TODO: Remove if delivery slot is required
		// let deliverySlot = localStorage.getItem('deliverySlot')
		// 	? JSON.parse(localStorage.getItem('deliverySlot') as any)
		// 	: [];
		const userId = localStorage.getItem('userId');
		const adminId = localStorage.getItem('hgAdminId');
		const userType = localStorage.getItem('userType');
		const shippingAddressId = localStorage.getItem('defaultAddressId') || '';
		const country =
			venueDetails?.country?.length > 2
				? helperFunctions.getCountryId(venueDetails.country)
				: venueDetails.country;
		const region =
			venueDetails?.state?.length == 4 || venueDetails?.state?.length == 2
				? helperFunctions.getRegionId(venueDetails.country, venueDetails.state)
				: venueDetails.state;
		const payloadJSON = {
			addressInformation: {
				shipping_address: {
					// id: parseInt(shippingAddressId),
					region_id: region?.id,
					region_code: region?.code,
					country_id: country,
					street: [venueDetails?.address],
					telephone: venueDetails?.phone,
					postcode: venueDetails?.zip_code,
					city: venueDetails?.city,
					firstname: venueDetails?.contact_name.split(' ')[0],
					lastname: venueDetails?.contact_name.split(' ')[2]
						? venueDetails?.contact_name.split(' ')[2]
						: venueDetails?.contact_name.split(' ')[1]
							? venueDetails?.contact_name.split(' ')[1]
							: '',
					middlename:
						venueDetails?.contact_name.split(' ')[1] &&
							venueDetails?.contact_name.split(' ')[2] == undefined
							? venueDetails?.contact_name.split(' ')[1]
							: '',
					// customer_id: userType == userTypes.venueAdmin ? adminId : userId,
					email: venueDetails?.venue_email,
					customer_address_id: 0,
					save_in_address_book: 0,
				},
				billing_address: {
					same_as_billing: 1,
					save_in_address_book: 0,
				},
				shipping_method_code: props.shippingMethod[0]?.method_code,
				shipping_carrier_code: props.shippingMethod[0]?.carrier_code,
			},
		};

		const url1 = `${constants.publicURL()}rest/all/V1/carts/${cartId}/shipping-information`;

		const obj: Partial<Ipost> = {
			data: payloadJSON,
			params: {},
			url: url1,
			message: ' ',
		};
		try {
			mutatePost(obj).then((data) => {
				if (data !== undefined) {
					props.setPaymentMethod(data);
					props.setProgress('Payment');
					setIsLoading(false);
				}
			});
		} catch (e) {
			setIsLoading(false);
		}
	};

	return (
		<ListWrapper>
			{isLoading === true ? <Loader></Loader> : null}
			{/* <Flex className="AdditionalNoteSession">
				<Text as="h1" fontSize={20} fontFamily="OpenSansBold" color="text.black04">
					Additional Note
				</Text>
				<Text as="p" fontSize={20} color="text.black04">
					Items ordered are delicate. Please be careful with the packages
				</Text>
			</Flex> */}
			<Flex className="ItemOrderList">
				<Text as="h1" fontSize={20} fontFamily="OpenSansBold" color="text.black04">
					Order Details
				</Text>
				<Text as="h2" fontSize={20}>
					<div>Sub Total</div>
					<strong>${parseFloat(totalAmt).toFixed(2)}</strong>
				</Text>
				<Text as="h2" fontSize={20}>
					<div>Total Tax</div>
					<strong>${parseFloat(totalTax).toFixed(2)}</strong>
				</Text>
				{props.shippingMethod && props.shippingMethod.length != 0 && (
					<Text as="h2" fontSize={20}>
						<div>Shipping</div>
						<strong>${parseFloat(props.shippingMethod[0].price_incl_tax).toFixed(2)}</strong>
					</Text>
				)}
				{(totalQry.isFetched && (
					<Text as="h3" fontSize={20}>
						<div>Total</div>
						{props.shippingMethod && props.shippingMethod.length != 0 ? (
							<strong>
								$
								{parseFloat(totalAmt + totalTax + props.shippingMethod[0].price_incl_tax).toFixed(
									2
								)}
							</strong>
						) : (
							<strong>${parseFloat(totalAmt + totalTax).toFixed(2)}</strong>
						)}
					</Text>
				)) ||
					new Array(2).fill(null).map((_, i) => {
						return <Skeleton key={i} width="20px" height="10px" />;
					})}
				{/* <Text as="h2" fontSize={20}>
					<div>Total Tax</div>
					<strong>${totalAmt.tax_amount}</strong>
				</Text> */}
			</Flex>
			{/* <ButtonWrapper> */}
			{(utilityFunctions.isGranted(venueUserPermissionList.PlaceOrder) ||
				utilityFunctions.isGranted(venueUserPermissionList.ManageOrderAdmin)) && (
					<Button
						flex={[1, 1, 'initial']}
						onClick={placeOrder}
						disabled={props.shippingMethod && props.shippingMethod.length == 0}
					>
						{/* <icons.LeftArrowIcon /> */}
						<div>Proceed to Payment</div>
					</Button>
				)}
			{/* </ButtonWrapper> */}
		</ListWrapper>
	);
}

export { CheckoutPriceDetails };
