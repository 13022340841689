import React, { ReactElement } from 'react';
import styled from 'styled-components';
import * as Icon from '../../icons';

const StyledSearch = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	span {
		position: absolute;
		z-index: 1;
		left: 22px;
	}
`;
const PageSearch = styled.input`
	background: ${({ theme }) => theme.colors.bg.white};
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	width: 100%;
	height: 50px;
	border-radius: 8px;
	border: none;
	outline: none;
	box-shadow: none;
	padding: 0 20px 0 50px;
	font-family: 'OpenSansMedium';
	font-size: 16px;
	border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
	@media screen and (max-width: 767px) {
		height: 50px;
	}
	&::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: ${({ theme }) => theme.colors.text.body};
		color: rgba(98, 98, 98, 0.4);
		font-size: 14px;
	}
	&::-moz-placeholder {
		/* Firefox 19+ */
		color: ${({ theme }) => theme.colors.text.body};
		color: rgba(98, 98, 98, 0.4);
		font-size: 14px;
	}
	&:-ms-input-placeholder {
		/* IE 10+ */
		color: ${({ theme }) => theme.colors.text.body};
		color: rgba(98, 98, 98, 0.4);
		font-size: 14px;
	}
	&:-moz-placeholder {
		/* Firefox 18- */
		color: ${({ theme }) => theme.colors.text.body};
		color: rgba(98, 98, 98, 0.4);
		font-size: 14px;
	}
`;
function Search(props): ReactElement {
	// search value filter method for venue list component


	const setValue = (e: React.FormEvent<HTMLInputElement>) => {
		if (props.type === 'dcList') {
			props.onChange && props.onChange(e.currentTarget.value);
		}
		else if (props.type === 'venueList') {
			props.onChange && props.onChange(e.currentTarget.value);
		}
		else if (props.type === 'venuesList') {
			props.onChange && props.onChange(e.currentTarget.value);
		}
		else if (props.type === 'userList') {
			props.onChange && props.onChange(e.currentTarget.value);
		}
		else if (props.type === 'usersList') {
			props.onChange && props.onChange(e.currentTarget.value);
		}
		else if (props.type === 'dcUserList') {
			props.onChange && props.onChange(e.currentTarget.value);
		}
		else if (props.type === 'roleList') {
			props.onChange && props.onChange(e.currentTarget.value);
		}
		else if (props.type === 'productManagement') {
			props.onChange && props.onChange(e.currentTarget.value);
		}
		else if (props.type === 'orderList') {
			props.onChange && props.onChange(e.currentTarget.value);
		}
		else if (props.type === 'pendingOrderList') {
			props.onChange && props.onChange(e.currentTarget.value);
		}
		else if (props.type === 'creditTracker') {
			props.onChange && props.onChange(e.currentTarget.value);
		}
		else {
			props.onChange && props.onChange(e.currentTarget.value);
		}
	};
	return (
		<StyledSearch>
			<Icon.searchIcon />
			<PageSearch
				onChange={setValue}
				type="search"
				placeholder={props.placeholder? props.placeholder:"Search"}
			></PageSearch>
		</StyledSearch>
	);
}

export { Search };
