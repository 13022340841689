import React, { ReactElement } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { FlexboxProps, LayoutProps, SpaceProps } from 'styled-system';
import { Stack } from 'ui/components';

type styledSystemProps = SpaceProps & LayoutProps & FlexboxProps;
type StyledQuantityAndPricingProps = styledSystemProps & {
	totalAmount?: string;
};
const StyledQuantityAndPricing = styled.div`
	width: 100%;
	padding: 36px 0 0 0;
	margin: 40px 0 0 0;
	float: left;
	border-top: 1px solid ${({ theme }) => theme.colors.border.grayLightBorder01};
	@media (max-width: 860px) {
		width: calc(100% + 30px);
		padding: 30px 15px 0;
		margin: 30px 0 0 -15px;
	}
`;
const ViewAllHeadingBlock = styled.div`
	h1 {
		font-family: 'OpenSansBold';
		text-transform: uppercase;
		color: ${({ theme }) => theme.colors.text.black};
		line-height: normal;
	}
`;
const QuantityAndPricingHolder = styled.div`
	margin-top: 20px;
	@media (max-width: 860px) {
		div {
			max-height: max-content;
		}
	}
	article {
		margin: 0 0 18px 0;
		padding: 0;
		border-bottom: none;
		&:last-child {
			margin: 0 0 0 0;
		}
		div {
			display: flex;
			align-items: center;
			margin-bottom: 18px;
			&:last-child {
				margin-bottom: 0;
			}
			h2 {
				font-weight: normal;
				margin: 0;
				flex: 0 0 220px;
				margin-right: 24px;
				font-family: 'OpenSansMedium';
				position: relative;
				@media (max-width: 480px) {
					flex: 1;
					margin-right: 20px;
				}
			}
			h3 {
				margin: 0;
				font-family: 'OpenSansBold';
			}
			@media (max-width: 860px) {
				display: flex;
				h3 {
					margin-top: 0;
				}
				h2,
				h3 {
					font-size: 17px;
				}
			}
		}
	}
`;
const rewardsBalanceList = [
	{
		id: 1,
		detailsHead: 'Quantity',
		details: '240',
	},
	{
		id: 2,
		detailsHead: 'Unit of measurement',
		details: 'Lbs',
	},
	{
		id: 3,
		detailsHead: 'Quantity confirmed',
		details: '12',
	},
	{
		id: 4,
		detailsHead: 'Quantity Pending',
		details: '15',
	},
	{
		id: 5,
		detailsHead: 'Quantity Sold',
		details: '12',
	},
	{
		id: 6,
		detailsHead: 'Price per pack',
		details: '$15',
	},
	{
		id: 7,
		detailsHead: 'Tax class',
		details: '$1.50',
	},
	{
		id: 8,
		detailsHead: 'Min order quantity',
		details: '10',
	},
	{
		id: 9,
		detailsHead: 'Max order quantity',
		details: '40',
	},
];
function QuantityAndPricing({ ...styledSystemProps }: StyledQuantityAndPricingProps): ReactElement {
	return (
		<StyledQuantityAndPricing {...styledSystemProps}>
			<ViewAllHeadingBlock>
				<Text as="h1" fontSize="18px">
					Quantity and Pricing
				</Text>
			</ViewAllHeadingBlock>
			<QuantityAndPricingHolder>
				<Box>
					<Stack direction="y" gap={3}>
						{rewardsBalanceList.map(({ detailsHead, details, id }) => (
							<Flex as="article" bg="white" p={[3, 4]} sx={{ alignItems: 'center' }} key={id}>
								<Box flex={1}>
									<Text
										as="h2"
										fontFamily="OpenSansMedium"
										fontSize={18}
										lineHeight="23px"
										color="text.black03"
										className="truncate"
									>
										{detailsHead}
									</Text>
									<Text as="h3" fontSize="18px" color="text.black01" className="truncate">
										{details}
									</Text>
								</Box>
							</Flex>
						))}
					</Stack>
				</Box>
			</QuantityAndPricingHolder>
		</StyledQuantityAndPricing>
	);
}

export { QuantityAndPricing };
