import React, { ReactElement } from 'react';
import 'react-tippy/dist/tippy.css';
import { Box, Flex, Text } from 'rebass/styled-components';
import styled, { css } from 'styled-components';

const StyledCartListView = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	column-gap: 20px;
	row-gap: 20px;
	margin-bottom: 50px;
`;

const DeliveryOrderInfoListHolder = styled.div<DeliveryOrderInfoListHolderProps>`
	width: 100%;
	display: flex;
	flex-direction: row;
	background: ${({ theme }) => theme.colors.bg.white};
	border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
	box-sizing: border-box;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 16px;
	overflow: hidden;
	transition: box-shadow 100ms ease-in, transform 100ms ease-in;
	max-height: 100%;
	align-items: flex-start;
	@media (max-width: 768px) {
		flex-direction: column;
	}
	picture {
		max-width: 205px;
		flex: 0 0 216px;
		height: 100%;
		position: relative;
		border-right: 1px solid ${({ theme }) => theme.colors.bg.lightYellow05};
		background-color: #faf4ea;
		div {
			position: absolute;
			width: 100%;
			height: 100%;
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;
			margin: 0;
		}
		@media (max-width: 768px) {
			max-width: 100%;
			flex: 0 0 100%;
			height: 200px;
			min-height: 200px;
			width: 100%;
			max-height: 200px;
		}
	}
	section {
		height: 100%;
		padding: 25px;
		position: relative;
		display: flex;
		flex-direction: row;
		@media (max-width: 768px) {
			padding: 20px;
		}
		@media (max-width: 480px) {
			padding: 15px;
		}
	}
	slot {
		flex: 1;
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		justify-content: space-between;
		position: relative;
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-weight: normal;
			color: ${({ theme }) => theme.colors.text.black};
		}
		h1 {
			margin-bottom: 3px;
		}
		h2 {
			margin: 0 0 12px 0;
		}
		a {
			text-decoration: underline;
			cursor: pointer;
			&:hover {
				color: ${({ theme }) => theme.baseColors.primary};
			}
		}
		h3 {
			margin-top: 5px;
			strong {
				font-family: 'OpenSansBold';
			}
		}
		h4 {
			margin-top: 18px;
			strong {
				font-family: 'OpenSansBold';
			}
		}
		h5 {
			position: absolute;
			right: 0;
			@media (max-width: 480px) {
				bottom: -6px;
			}
		}
		@media (max-width: 480px) {
			div {
				flex-direction: column;
			}
		}
		div {
			margin-bottom: 3px;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			display: block;
			width: 100%;
			padding-right: 20%;
			@media (max-width: 640px) {
				padding-right: 35%;
			}
			@media (max-width: 480px) {
				padding-right: 0;
			}
			h1,
			h2 {
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				display: block;
			}
		}
	}
	${({ active }) =>
		!active &&
		css`
			cursor: default;
			&:hover {
				transform: none;
				background: ${({ theme }) => theme.colors.bg.white};
				border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
				box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
			}

			picture {
				opacity: 0.5;
				pointer-events: none;
			}
			h1,
			h2,
			h3,
			h4,
			h6 {
				opacity: 0.5;
				pointer-events: none;
			}
		`}
`;

type DeliveryOrderInfoListHolderProps = {
	active?: boolean;
};
// type DeliveryOrderInfoProps = {
// 	deliveryOrderInfoList: Array<list>;
// };
// type list = {
// 	id?: string | number;
// 	image?: string;
// 	brandName?: string;
// 	brandItem?: string;
// 	supplierName?: string;
// 	perPackPrice?: string;
// 	quantity?: string;
// 	price?: string;
// 	active?: boolean;
// };

function DeliveryOrderInfo({ deliveryOrderInfoList }): ReactElement {
	return (
		<StyledCartListView>
			{deliveryOrderInfoList &&
				deliveryOrderInfoList.items &&
				deliveryOrderInfoList.items.map((item) => (
					<DeliveryOrderInfoListHolder key={item.item_id} active={true}>
						<Flex alignItems="center" as="picture" maxWidth={['100%']} sx={{ overflow: 'hidden' }}>
							<Box
								style={{
									backgroundImage: 'url(' + item.extension_attributes?.image_details + ')',
								}}
							></Box>
						</Flex>
						<Flex as="section" flexDirection="column" width="100%" maxWidth={['100%']}>
							<Box as="slot">
								<Box>
									<Text as="h1" fontSize={20} fontFamily="OpenSansBold" color="text.black">
										{item.name}
									</Text>
									<Text as="h2" fontSize={17} color="text.black">
										{item.extension_attributes.product_catagory &&
											item.extension_attributes.product_catagory.length > 0 &&
											item.extension_attributes.product_catagory[0]}
									</Text>
								</Box>

								{/* <Link as="a" fontSize={17}>
									{item.supplierName}
								</Link> */}
								<Text as="h3" fontSize={20} color="text.black">
									<strong>${parseFloat(item.price ? item.price : 0).toFixed(2)}</strong>{' '}
									{item.extension_attributes?.uom_name?.length > 0
										? item.extension_attributes?.uom_name
										: 'per Pack'}
								</Text>
								<Text as="h4" fontSize={20} color="text.black">
									Quantity <strong>{item.qty_ordered}</strong>
								</Text>
								<Text as="h5" fontFamily="OpenSansBlack" fontSize={30} color="text.black">
									${parseFloat(item.row_total ? item.row_total : 0).toFixed(2)}
								</Text>
							</Box>
						</Flex>
					</DeliveryOrderInfoListHolder>
				))}
		</StyledCartListView>
	);
}

export { DeliveryOrderInfo };
