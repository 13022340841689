import Iget from 'models/Iget';
import Ipost from 'models/Ipost';
import React, { ReactElement, useEffect, useState } from 'react';
import { Box, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { Button, Stack } from 'ui/components';
import { FormInput } from 'ui/patterns';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import notify from 'utils/toast.service';
import urls from 'utils/create.url'

const NewFavorites = styled.div`
	display: flex;
	height: auto;
	position: relative;
	flex-direction: column;
	background: ${({ theme }) => theme.colors.bg.white};
	padding: 0 10px 12px;
	@media (max-width: 960px) {
		padding: 0;
	}
	h1 {
		margin: 0 0 30px 0;
		width: 100%;
		font-weight: normal;
		text-transform: uppercase;
	}
	label {
		font-size: 16px;
		line-height: 21px;
		font-family: 'OpenSansSemiBold';
		color: ${({ theme }) => theme.colors.text.SubHeading};
		margin-bottom: 8px;
	}
	button {
		padding: 0;
		min-width: 100px;
		min-height: 50px;
		text-transform: capitalize;
		font-size: 18px;
		margin: 0 0 0 20px;
		font-family: 'OpenSansBold';
	}
	.app-c-form-group {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
	}
	.app-c-select-dropdown {
		position: relative;
		svg {
			position: absolute;
			top: 20px;
			right: 14px;
		}
	}
`;

export default function NewFavoritesModal(props): ReactElement {
	const [newFavorites, setNewFavorites] = useState('');
	var [items, setItems] = useState<any>([]);
	const [disableCreate, setDisableCreate] = useState(true);
	var key = 'addFavKey'
	const [mutatePost] = hooks.useMutation(key);
	var orderId = localStorage.getItem('orderId');
	const orderid = parseInt(orderId as any);
	var id: any;
	id = localStorage.getItem('userId');
	var venueUserID = parseInt(id);
	var v_id: any;
	v_id = localStorage.getItem('selectedVenue');
	var venueId = parseInt(v_id);

	const obj1: Partial<Iget> = {
		params: {},
		url: urls.GetSearchUrl({
			url: 'orders?',
			fields: [{
				field_name: 'group_order_id',
				field_value: orderid?.toString() as any
			}]
		}),
		enabled: true,
		key: 'orderFav',
		resultFilteringRequired: false,
	};
	var qry = hooks.useQuery(obj1);

	useEffect(() => {
		var obj = { product_id: '', qty: null, is_active: false, is_delete: false };
		const item = [] as any;
		qry.data && qry.data.items && qry.data.items.length > 0 && qry.data.items.forEach(data => {
			data.items.forEach((data) => {
				obj = { product_id: data.product_id, qty: data.qty_ordered, is_active: true, is_delete: false };
				item.push(obj);
			});
		});
		setItems(item);
	}, [qry.data]);

	const handleOnChange = (e: any) => {
		if (e.target.value.length > 0) {
			setDisableCreate(false);
			setNewFavorites(e.target.value);
		}
	};

	const createFavorite = async () => {
		let isExists = false;
		await api
			.get({ url: `/experion/productmanagement/favorite/${newFavorites}/${venueUserID}` })
			.then(function (data) {
				if (typeof data != 'undefined' && data == true)
					isExists = true;
			});
		if (!isExists) {
			let markFavJson = {
				"favoriteOrder": {
					"venue_user_id": venueUserID,
					"favorite_name": newFavorites,
					"venue_id": venueId,
					"favorite_items": items
				}
			}

			const obj: Partial<Ipost> = {
				data: markFavJson,
				params: {},
				url: '/experion/productmanagement/favoriteorder/create',
				message: 'Order has been added as favorite',
			};
			try {
				mutatePost(obj).then((data) => {
					if (data !== undefined) {
						props.toggleNewFavoritesModal();
					}
				});
			} catch (e) { }
		}
		else {
			notify('Favorite name exists').error();
		}
	}
	return (
		<NewFavorites>
			<Text as="h1" color="text.textHeading" fontSize={18} fontFamily="OpenSansSemiBold">
				New Favorite
			</Text>
			<Box mb={45}>
				<Stack categorySelect gap={0} mt={0}>
					<FormInput onChange={handleOnChange} label="Create New Favorite" />
				</Stack>
			</Box>

			<Stack direction="x" gap={3} justifyContent="center">
				<Button flex={[1, 1, 'initial']} variant="secondary" onClick={() => { props.toggleNewFavoritesModal() }}>
					Cancel
				</Button>
				<Button onClick={createFavorite} disabled={disableCreate} flex={[1, 1, 'initial']}>Create</Button>
			</Stack>
		</NewFavorites>
	);
}

