import cloneDeep from 'lodash/cloneDeep';
import { pageNavigators, pages } from 'models/enum-constants/common.constants';
import FilterGroupItem from 'models/FilterModel';
import Iget from 'models/Iget';
import IgqlPost from 'models/IgqlPost';
import urlObj from 'models/url.obj';
import { ProductListViewBlock } from 'pages/ListingListView/styles';
import queryString from 'query-string';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { queryCache } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Flex, Image, Text } from 'rebass/styled-components';
import { Footer, Header } from 'ui/components';
import {
	bindPagination,
	Filter,
	Pagination,
	ProductGridView,
	ProductListView,
	ProductToolbar,
	Sidebar,
	SortInfo,
} from 'ui/patterns';
import AppContext from 'utils/appContext';
import urls from 'utils/create.url';
import pageConstants from 'utils/pageConstants';
import hooks from 'utils/requestCtx/reactQueryHooks';
import utilityFunctions from 'utils/utility.functions';
import nodataImage from '../../ui/images/Nodata.svg';
import {
	FilterBlock,
	MainContent,
	NodataFound,
	ProductGridViewBlock,
	TilesContentBlock,
	TileViewHolder,
} from './styles';
const key = 'products';
function ListingTIleView(): ReactElement {
	const location = useLocation();
	const loc = location.search;
	const queryStringValues = queryString.parse(loc);

	const ctx = useContext(AppContext).productSearchObj;
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const [page, setPage] = React.useState(1);
	const [urlChange, seturlChange] = React.useState(Math.random());
	var [selectedSupplyGroup, setSelectedSupplyGroup] = useState<Array<FilterGroupItem>>([]);
	const [selectedCategoryGroup, setCategoryItem] = useState<Array<FilterGroupItem>>([]);
	const [selectedAllergensGroup, setAllergensItem] = useState<Array<FilterGroupItem>>([]);
	const [allFilters, setAllFilters] = useState<Array<FilterGroupItem>>([]);
	const [layeredFilters, setLayeredFilters] = useState<any>({});
	const [dropdownVal, setDropdownVal] = useState('');
	const [searchValue, setSearchValue] = useState(
		queryStringValues.search ? queryStringValues.search : ''
	);
	const [supplierQry, setSupplierQuery] = useState('');
	const [isLayeredUpdated, setIsLayeredUpdated] = useState(false);
	const [categoryVal, setCatValue] = useState([]);
	const [selectedSupplier, setSelectedSupplier] = useState('');
	const [notificationDetails, setNotificationDetails] = useState<any>([]);
	const [listProductData, setListProductData] = useState<any>([]);
	const productView = localStorage.getItem('ProductViewType')
		? localStorage.getItem('ProductViewType')
		: 'tile';
	const [viewType, setViewType] = useState(productView);
	const [url, setSearchUrl] = useState('');
	let showingPageCount = 5;
	const itemsPerPage = 40;

	ctx.SearchText = queryStringValues.search ? queryStringValues.search : '';
	ctx.newArrivalDate = queryStringValues.newArrivalDate ? queryStringValues.newArrivalDate : '';
	ctx.specialProductsView = queryStringValues.specialProductsView;
	ctx.Type = queryStringValues.type == '' ? 'Products' : queryStringValues.type;
	ctx.Categories = ctx.Categories != '' ? ctx.Categories : queryStringValues.cat;
	ctx.Categories = ctx.Categories != '' ? ctx.Categories : queryStringValues.cat;

	ctx.radius = ctx.radius ? ctx.radius : '0-100';
	ctx.location = {
		lat: queryStringValues.lat,
		long: queryStringValues.long,
		place: ctx.location.place ? ctx.location.place : '',
	};

	const parsedItems = queryString.parse(location.search);
	const urlObj: Partial<urlObj> = {
		pageName: pages.productList.toString(),
		searchText: queryStringValues.search,
		type: dropdownVal,
		page: page,
		ctx: ctx,
		pageSize: itemsPerPage,
		url: 'products?',
		productSearch: queryStringValues.search !== '' ? 'true' : 'false',
		// showActiveProductsOnly: true,
	};

	// for location state
	useEffect(() => {
		parseQueryString(window.location.search);
	}, [window.location.search]);

	useEffect(() => {
		if (
			parsedItems?.notificationId &&
			parsedItems.notificationId !== undefined &&
			parsedItems.notificationId !== ''
		)
			notificationQry.refetch();
	}, [parsedItems.notificationId]);

	useEffect(() => {
		if (
			(parsedItems?.cat && parsedItems.cat !== undefined && parsedItems.cat !== '') ||
			(parsedItems?.newArrivalDate &&
				parsedItems.newArrivalDate !== undefined &&
				parsedItems.newArrivalDate !== '') ||
			(parsedItems?.specialProductsView &&
				parsedItems.specialProductsView !== undefined &&
				parsedItems.specialProductsView !== '') ||
			(parsedItems?.supplierCompanyName &&
				parsedItems.supplierCompanyName !== undefined &&
				parsedItems.supplierCompanyName !== '')
		) {
			setSupplierQuery('');
			ctx.PriceFrom = 0;
			ctx.PriceTo = 0;
			ctx.Allergens = '';
			setAllergensItem([]);
			ctx.Suppliers = '';
			setSelectedSupplyGroup([]);
			parseQueryString();
		}
	}, [
		parsedItems.newArrivalDate,
		parsedItems.specialProductsView,
		parsedItems.cat,
		parsedItems.supplierCompanyName,
	]);

	useEffect(() => {
		let idArr = [];

		if (layeredFilters?.data?.productsSearch?.items?.length > 0) {
			idArr = layeredFilters?.data?.productsSearch?.items.map((item) => item.id);
			ctx.product_id = idArr;
		}

		if (
			parsedItems.supplierCompanyName !== undefined &&
			// isLayeredUpdated === false &&
			parsedItems.supplierCompanyName !== supplierQry
		) {
			let list = [] as any;
			if (layeredFilters?.data?.products?.aggregations?.length > 0) {
				// setSupplierQuery(parsedItems.supplierCompanyName);
				let aggregations = layeredFilters.data.products.aggregations;
				let suppliers = aggregations.find((x) => x.attribute_code == 'supply_company_name');
				if (suppliers && suppliers.options) {
					suppliers.options.map((x) => {
						if (parsedItems.supplierCompanyName === x.value && x.value != 0) {
							list.push({ id: x.value, name: x.label, key: 'supply', isChecked: false });
						}
					});
				}
				let newArray = [] as any;
				newArray = list;
				// selectedSupplyGroup = newArray;
				// selectedSupplyGroup.forEach((v) => (newArray.includes(v) ? null : newArray.push(v)));
				setSelectedSupplyGroup(newArray);
				// updateCatData(newArray, 'add', 'supply');
				setIsLayeredUpdated(true);
			}
		}
	}, [parsedItems.supplierCompanyName, layeredFilters]);

	const obj: Partial<Iget> = {
		params: {},
		url: url,
		enabled: false,
		key: key,
		refetchOnWindowFocus: false,
		caching: true,
		pageNumber: page,
		urlObj: urlObj,
	};
	const objNot: Partial<Iget> = {
		// get notification by notification id
		params: {},
		url: '/experion/notifications/' + `${parsedItems.notificationId}`,
		enabled:
			parsedItems.notificationId === undefined || parsedItems.notificationId === '' ? false : true,
		key: 'notificationKey',
		resultFilteringRequired: false,
	};
	const notificationQry = hooks.useQuery(objNot);

	useEffect(() => {
		if (notificationQry.data !== undefined) {
			ctx.Suppliers = '';
			setSelectedSupplyGroup([]);
			setNotificationDetails(notificationQry.data);
		}
	}, [notificationQry.data]);

	useEffect(() => {
		if (notificationDetails?.sku) parseQueryString();
	}, [notificationDetails]);

	const layerdFilterObj: Partial<IgqlPost> = {
		enabled: true,
		key: 'layered-filter',
		caching: true,
		query: ``,
	};
	if (queryStringValues.lat) layerdFilterObj.query = ctx.query = urls.GetLocationQuery(urlObj);
	const layerdFilterQry = hooks.useGraphQlQuery(layerdFilterObj);

	useEffect(() => {
		if (typeof layerdFilterQry.data != 'undefined') {
			setLayeredFilters(layerdFilterQry.data);
		}
	}, [layerdFilterQry.data]);
	useEffect(() => {
		console.log(layerdFilterQry.data);
	}, [layerdFilterQry]);

	const parseQueryString = function (item?) {
		const parsed = item ? queryString.parse(item) : queryString.parse(location.search);

		if (parsed) {
			setAllFilters([]);
			setCategoryItem([]);
			let type = (ctx.Type = parsed.type ? parsed.type : 'Products');
			setSearchValue(parsed.search);
			setDropdownVal(type);
			setCatValue(parsed.cat);
			ctx.Categories = parsed.cat;
			ctx.SearchText = urlObj.searchText = parsed.search;
			ctx.radius = parsed.radius;
			if (parsed.lat && parsed.long) {
				ctx.location = {
					lat: parsed.lat,
					long: parsed.long,
					place: ctx.location.place,
				};
			} else {
				ctx.location = '';
				ctx.radius = '0-100';
			}

			ctx.newArrivalDate = parsed.newArrivalDate ? parsed.newArrivalDate : '';
			ctx.specialProductsView = parsed.specialProductsView;
			ctx.Suppliers = ctx.Suppliers != '' ? ctx.Suppliers : parsed.supplierCompanyName;

			if (
				parsed?.notificationId &&
				parsed.notificationId !== undefined &&
				parsed.notificationId != '' &&
				notificationDetails?.sku
			) {
				ctx.skuList = `${notificationDetails.sku}`;
			} else if (!parsed?.notificationId) {
				ctx.skuList = '';
				setNotificationDetails(null);
			}
			setSearchUrl(urls.GetSearchUrl(utilityFunctions.getFields(urlObj)));

			if (parsed.lat && parsed.long) {
				layerdFilterObj.query = ctx.query = urls.GetLocationQuery(urlObj);
			} else layerdFilterObj.query = ctx.query = urls.GetQuery(urlObj);
			setPage(1);
		}
		// totalPages = 0;
		// if (
		// 	(parsedItems &&
		// 	parsedItems.notificationId !== undefined &&
		// 	parsedItems.notificationId != '') ||
		// 	(parsedItems?.supplierCompanyName &&
		// 		parsedItems.supplierCompanyName !== undefined &&
		// 		parsedItems.supplierCompanyName !== '') ||
		// 	(parsedItems?.newArrivalDate &&
		// 		parsedItems.newArrivalDate !== undefined &&
		// 		parsedItems.newArrivalDate !== '') ||
		// 	(parsedItems?.specialProductsView &&
		// 		parsedItems.specialProductsView !== undefined &&
		// 		parsedItems.specialProductsView !== '')
		// ) {
		// 	totalPages = 0;
		// 	seturlChange(Math.random());
		// }
		if (Object.keys(parsed).length === 0) history.push('/home/dashboard');
		toggleHideSideBar(true);
	};
	var [pageList, setPageList] = React.useState([...Array(showingPageCount)].map((_, i) => i + 1));
	const history = useHistory();
	React.useEffect(() => {
		if (page != totalPages || queryStringValues.lat) {
			setTimeout(() => {
				queryCache.prefetchQuery(
					[key, { page: page }],
					() => {
						if (typeof queryCache.getQueryData(key + (page + 1)) == 'undefined') {
							obj.pageNumber = page + 1;
							obj.url = urls.GetSearchUrl(
								utilityFunctions.getFields({
									pageName: pages.productList.toString(),
									searchText: searchValue,
									type: dropdownVal,
									page: obj.pageNumber,
									pageSize: itemsPerPage,
									ctx: ctx,
									url: 'products?',
								})
							);
							if (
								ctx.SearchUrl &&
								ctx.SearchUrl == obj.url &&
								ctx.currentPage == page &&
								!ctx.location.lat
							)
								return;
							ctx.SearchUrl = obj.url!;
							ctx.currentPage = page;
							return hooks.getData(obj, ctx);
						}
					},
					{
						retry: false,
					}
				);
			}, 1500);
		}
		window.scroll({ top: 0, left: 0, behavior: 'smooth' });
	}, [obj, page, urlChange]);

	useEffect(() => {
		qry.refetch();
	}, [url, page]);

	function getPages(currentPage: number = 1, repagination: boolean = true) {
		let pageCount = totalPages;

		if (repagination) {
			// will only enter this block for prev & next
			//for checking if in last section of pagination
			let isLastSection = pageCount - currentPage < showingPageCount ? true : false;
			if (!pageList.includes(currentPage))
				//if current pagination list doesnt contains current page,
				// then the list will get updated with new values.ie pagination numbers will change
				setPageArray(currentPage, pageCount, isLastSection);
		} else if (pageCount < showingPageCount)
			// if total page count less than numberOfPages, then page count
			//will be max page
			pageList = Array.from(Array(pageCount + 1 - 1).keys()).map((i) => 1 + i);
		// return object with all pager properties required by the view
		return {
			pages: pageList,
		};
	}

	const setPageArray = (currentPage, pageCount, isLastSection = false) => {
		//if page is greatr than list items, then new start will be last list item + 1
		let val =
			currentPage > pageList[0] ? pageList.slice(-1).pop()! : pageList[0] - (showingPageCount + 1);
		let startPage = val + 1;
		// if last section, then end page will be pagecount
		let endPage = isLastSection && pageList[0] < currentPage ? pageCount : val + showingPageCount;
		// pagelist updating with new values
		pageList = Array.from(Array(endPage + 1 - startPage).keys()).map((i) => startPage + i);
	};

	const bindPages = () => {
		return bindPagination(getPages(page, false).pages, page, setPage);
	};

	const handlePagination = (action) => {
		if (action == pageNavigators.prev) setPageData(page - 1);
		else if (action == pageNavigators.next) setPageData(page + 1);
		if (action == pageNavigators.fastPrev) setPageData(1);
		//pageList[0] - showingPageCount
		else if (action == pageNavigators.fastNext) setPageData(totalPages); //pageList.slice(-1).pop()! + 1
	};
	const qry = hooks.usePaginatedQuery(obj);
	var totalPages = Math.ceil(
		(qry.resolvedData && qry.resolvedData.items && qry.resolvedData.total_count) / itemsPerPage
	);
	// const count = qry.resolvedData && qry.resolvedData.items && (qry.resolvedData.total_count ?? 0);
	const count =
		qry.resolvedData?.items &&
		(qry.resolvedData.items ? qry.resolvedData.items.filter((item) => item.status != 2).length : 0);

	const handleToggle = (val) => {
		setViewType(val);
		bindProductList(val);
	};

	const handleSort = (val) => {
		setPage(1);
		let sortData = utilityFunctions.getSortData(val);
		ctx.SortDirection = sortData.direction;
		ctx.SortField = sortData.field;
		// ctx.SortField = ctx.specialProductsView && sortData.field == 'price' ? 'special_price' : sortData.field;
		setSearchUrl(urls.GetSearchUrl(utilityFunctions.getFields(urlObj)));
		layerdFilterObj.query = ctx.query = urls.GetQuery(urlObj);
	};
	const setPageData = (page) => {
		if (page <= totalPages && page > 0) {
			setPage(() => page);
			setPageList(getPages(page).pages);
		}
	};

	useEffect(() => {
		let filteredData = qry.resolvedData;
		//TODO: Remove once products endpoint is fixed to remove deleted products
		//Filtering items that have been deleted by supplier
		if (filteredData) {
			let filteredItems = filteredData.items;
			filteredItems = filteredItems.filter((item) => item.status != 2);
			filteredData.items = filteredItems;
		}
		setListProductData(filteredData);
	}, [qry.resolvedData]);

	const bindProductList = (viewType) => {
		localStorage.setItem('ProductViewType', viewType);
		if (viewType == 'tile') {
			return (
				<ProductGridViewBlock>
					<ProductGridView data={listProductData} isFetching={qry.isFetching}></ProductGridView>
				</ProductGridViewBlock>
			);
		} else {
			return (
				<ProductListViewBlock>
					<ProductListView data={listProductData} isFetching={qry.isFetching}></ProductListView>
				</ProductListViewBlock>
			);
		}
	};
	const isCat = (item) => {
		if (item.key == pageConstants.categoryGroup) {
			setSelectedFilter(item, selectedCategoryGroup, setCategoryItem, true);
		}
	};
	const onPriceChange = (_) => {
		setPage(1);
		setSearchUrl(urls.GetSearchUrl(utilityFunctions.getFields(urlObj)));
		layerdFilterObj.query = ctx.query = urls.GetQuery(urlObj);
	};
	function getWindowDimensions() {
		const { innerWidth: width, innerHeight: height } = window;
		return {
			width,
			height,
		};
	}
	const onSelectCheckbox = (item, checked) => {
		setSupplierQuery(parsedItems.supplierCompanyName);
		if (checked) {
			if (item.key == pageConstants.supplyGroup) {
				setSelectedFilter(item, selectedSupplyGroup, setSelectedSupplyGroup);
			} else if (item.key == pageConstants.categoryGroup) {
				setSelectedFilter(item, selectedCategoryGroup, setCategoryItem);
			} else {
				setSelectedFilter(item, selectedAllergensGroup, setAllergensItem);
			}
		} else {
			if (item.key == pageConstants.supplyGroup) {
				removeSelectedFilter(item, selectedSupplyGroup, setSelectedSupplyGroup);
			} else if (item.key == pageConstants.categoryGroup) {
				if (item.id == categoryVal) {
					setUrl();
				}
				removeSelectedFilter(item, selectedCategoryGroup, setCategoryItem);
			} else {
				removeSelectedFilter(item, selectedAllergensGroup, setAllergensItem);
			}
		}
		setPage(1);
	};
	const setUrl = () => {
		if (queryStringValues.search) {
			history.push({
				pathname: '/home/product-list',
				search: '?search=' + queryStringValues.search + '&cat=',
			});
		} else {
			history.push({
				pathname: '/home/product-list',
				search: '?search=' + '' + '&cat=',
			});
		}
		parseQueryString(history.location.search);
	};

	const updateCatData = (itemSelected, action, key, isDeleteForMobile = false) => {
		let dimensions = getWindowDimensions();
		if (dimensions.width >= 960 || isDeleteForMobile) {
			let ids = getConcatenatedString(itemSelected);
			switch (key) {
				case pageConstants.categoryGroup:
					if (action == 'add') {
						ctx.Categories = ids != '' ? ids : ctx.Categories;
					} else {
						ctx.Categories = ids;
					}
					break;
				case pageConstants.supplyGroup:
					ctx.Suppliers = ids;
					break;
				case pageConstants.allergensGroup:
					ctx.Allergens = ids;
					break;
				default:
					break;
			}
			setSearchUrl(urls.GetSearchUrl(utilityFunctions.getFields(urlObj)));
			layerdFilterObj.query = ctx.query = urls.GetQuery(urlObj);
		}
	};

	const getConcatenatedString = (list) => {
		return Array.prototype.map
			.call(list, function (item) {
				return item.id;
			})
			.join(',');
	};

	const setSelectedFilter = (
		item: FilterGroupItem,
		group: FilterGroupItem[],
		setData,
		isSingleCat = false
	) => {
		let itemSelected = [] as any;
		if (group.filter((x) => x.id == item.id).length == 0) {
			if (!isSingleCat) itemSelected = group;
			if (getWindowDimensions().width <= 960) item.temp = true;
			itemSelected.push(item);
			setData([...itemSelected]);
		} else if (item.tempDeleted == true) {
			group[group.findIndex((obj) => obj.id == item.id)].tempDeleted = false;
			setData([...group]);
		}
		updateCatData([...itemSelected], 'add', item.key);
	};
	const removeSelectedFilter = (
		item: FilterGroupItem,
		group,
		removeItem,
		isDeleteForMobile = false
	) => {
		if (parsedItems.supplierCompanyName && item.id === parsedItems.supplierCompanyName) {
			history.push('/home/product-list?search=&type=Suppliers');
			setSupplierQuery('');
		}
		let itemDeselected: Array<FilterGroupItem> = [];
		if (getWindowDimensions().width >= 960 || isDeleteForMobile) {
			itemDeselected = group.filter((data) => data.name !== item.name);
		} else {
			group
				.filter((data) => data.name === item.name)
				.forEach((element: FilterGroupItem) => {
					element.tempDeleted = true;
					element.isChecked = false;
				});
			itemDeselected = group;
		}
		removeItem([...itemDeselected]);
		updateCatData([...itemDeselected], 'remove', item.key, isDeleteForMobile);
	};
	const removeSearch = (item) => {
		if (item.key == pageConstants.supplyGroup) {
			removeSelectedFilter(item, selectedSupplyGroup, setSelectedSupplyGroup, true);
		} else if (item.key == pageConstants.categoryGroup) {
			if (item.id == categoryVal) {
				setUrl();
			}
			removeSelectedFilter(item, selectedCategoryGroup, setCategoryItem, true);
		} else {
			removeSelectedFilter(item, selectedAllergensGroup, setAllergensItem, true);
		}
		setPage(1);
	};
	useEffect(() => {
		const arr1 = cloneDeep(selectedSupplyGroup);
		const arr2 = cloneDeep(selectedCategoryGroup);
		const arr3 = cloneDeep(selectedAllergensGroup);
		Array.prototype.push.apply(arr1, arr2);
		Array.prototype.push.apply(arr1, arr3);
		setAllFilters([...arr1]);
	}, [selectedSupplyGroup, selectedCategoryGroup, selectedAllergensGroup]);

	const clearAllFilter = () => {
		// let d = ctx;
		setAllFilters([]);
		setSelectedSupplyGroup([]);
		setCategoryItem([]);
		setAllergensItem([]);
		setPage(1);
		clearContext();
		setUrl();
		// setSearchUrl(GetSearchUrl(urlObj));
	};
	const clearContext = () => {
		ctx.Categories = '';
		ctx.PriceFrom = 0;
		ctx.PriceTo = 0;
		ctx.SortDirection = '';
		ctx.SortField = '';
		ctx.currentPage = 1;
		ctx.Allergens = '';
		ctx.Suppliers = '';
		ctx.query = '';
	};

	/*for mobile  */
	const applyFilter = () => {
		resetTemp(selectedCategoryGroup);
		resetTemp(selectedSupplyGroup);
		resetTemp(selectedAllergensGroup);
		setCategoryItem(selectedCategoryGroup.filter((x) => x.tempDeleted != true));
		setAllergensItem(selectedAllergensGroup.filter((x) => x.tempDeleted != true));
		setSelectedSupplyGroup(selectedSupplyGroup.filter((x) => x.tempDeleted != true));
		ctx.Categories = getConcatenatedString(
			selectedCategoryGroup.filter((x) => x.tempDeleted != true)
		);
		ctx.Allergens = getConcatenatedString(
			selectedAllergensGroup.filter((x) => x.tempDeleted != true)
		);
		ctx.Suppliers = getConcatenatedString(selectedSupplyGroup.filter((x) => x.tempDeleted != true));
		setSearchUrl(urls.GetSearchUrl(utilityFunctions.getFields(urlObj)));
		layerdFilterObj.query = ctx.query = urls.GetQuery(urlObj);
	};

	const cancelFilter = () => {
		setFilterArray(selectedCategoryGroup);
		setFilterArray(selectedSupplyGroup);
		setFilterArray(selectedAllergensGroup);
		setCategoryItem(selectedCategoryGroup.filter((x) => x.temp != true));
		setSelectedSupplyGroup(selectedSupplyGroup.filter((x) => x.temp != true));
		setAllergensItem(selectedAllergensGroup.filter((x) => x.temp != true));
	};
	//for mobile temp selection
	const resetTemp = (list) => {
		list
			.filter((x) => x.temp == true)
			.forEach((v) => {
				v.temp = false;
			});
	};

	const setFilterArray = (list) => {
		list
			.filter((x) => x.tempDeleted == true)
			.forEach((el) => {
				el.tempDeleted = false;
				el.isChecked = true;
			});
	};
	/*mobile pseudo code ends */
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	};
	return (
		<>
			{/* Main Header */}
			<Header
				updateDropDown={dropdownVal}
				queryString={parseQueryString}
				itemSearchValue={searchValue}
				clearAllFilter={clearAllFilter}
				toggleSideBar={() => onclickHambrgBtn()}
			></Header>
			{/* Side Menu Bar */}
			<Sidebar
				queryString={parseQueryString}
				hide={hideSideBar}
				toggleSideBar={() => onclickHambrgBtn()}
			></Sidebar>

			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<ProductToolbar
					currentTab={searchValue}
					prevTab={dropdownVal}
					onToggle={handleToggle}
					onSort={handleSort}
					selectedSupplyGroup={selectedSupplyGroup}
					selectedCategoryGroup={selectedCategoryGroup}
					selectedAllergensGroup={selectedAllergensGroup}
					onChange={onSelectCheckbox}
					onPriceChange={onPriceChange}
					clearAll={clearAllFilter}
					selectedCategoryId={categoryVal}
					isCatSelected={isCat}
					applyFilter={applyFilter}
					cancelFilter={cancelFilter}
					layeredFilters={layeredFilters}
				></ProductToolbar>
				<TileViewHolder>
					{/* Filter Session */}
					<FilterBlock>
						<Filter
							selectedSupplyGroup={selectedSupplyGroup}
							selectedCategoryGroup={selectedCategoryGroup}
							selectedAllergensGroup={selectedAllergensGroup}
							onChange={onSelectCheckbox}
							onPriceChange={onPriceChange}
							clearAll={clearAllFilter}
							selectedCategoryId={categoryVal}
							isCatSelected={isCat}
							layeredFilters={layeredFilters}
							selectedSupplier={selectedSupplier}
						></Filter>
					</FilterBlock>
					<TilesContentBlock>
						{/* Sort Result Info */}
						<SortInfo
							noOfResult={count > 0 ? String(count) : '0'}
							noOfResultItem={searchValue}
							selectedFilters={allFilters}
							removeItem={removeSearch}
							isFetching={qry.isFetching}
						></SortInfo>
						{/* Binding product list */}
						{!qry.isFetching && qry.resolvedData && qry.resolvedData.items == undefined && (
							<NodataFound>
								<Box>
									<Image
										src={nodataImage}
										sx={{
											width: 130,
											height: 130,
										}}
									/>
									<Text as="h1" fontFamily="OpenSansBold" fontSize={18} color="text.black">
										No data found
									</Text>
								</Box>
							</NodataFound>
						)}
						{bindProductList(viewType)}
						{qry.resolvedData && qry.resolvedData.items?.length > 0 && (
							<Flex>
								<Pagination
									handlePagination={handlePagination}
									bindPagination={bindPages}
									page={page}
									total={qry.resolvedData && count}
									itemsPerPage={itemsPerPage}
								/>
							</Flex>
						)}
					</TilesContentBlock>
				</TileViewHolder>
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default ListingTIleView;
