import Ipost from 'models/Ipost';
import moment from 'moment';
import queryString from 'query-string';
import React, { ReactElement, useEffect, useState } from 'react';
import { Text, Box, Flex } from 'rebass/styled-components';
import { FormInput } from 'ui/patterns';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-multi-date-picker';
import { DatePanel } from 'react-multi-date-picker/plugins';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Checkbox, FormLabel, RadioButton } from 'ui/components';
import * as Icon from 'ui/icons';
import { FormGroup } from 'ui/patterns';
import api from 'utils/requestCtx/api.service';

const SetSchedule = styled.div`
	display: flex;
	height: auto;
	position: relative;
	flex-direction: column;
	background: ${({ theme }) => theme.colors.bg.white};
	padding: 0 10px 12px;
	@media (max-width: 960px) {
		padding: 0;
	}
	h1 {
		margin: 0 0 30px 0;
		width: 100%;
		font-weight: normal;
		text-transform: uppercase;
	}
	label {
		font-size: 16px;
		line-height: 21px;
		font-family: 'OpenSansSemiBold';
		color: ${({ theme }) => theme.colors.text.SubHeading};
		margin-bottom: 8px;
	}
	footer {
		display: flex;
		justify-content: center;
		margin-top: 50px;
		button {
			padding: 0 20px;
			min-width: 100px;
			min-height: 50px;
			text-transform: capitalize;
			font-size: 18px;
			margin: 0 0 0 20px;
			font-family: 'OpenSansBold';
		}
	}
	.pr-icon {
		margin-top: 7px;
	}
`;
const ItemMonthHolder = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid ${({ theme }) => theme.colors.border.YellowBorder};
	border-radius: 8px;
	overflow: hidden;
	margin-top: 5px;
	h5 {
		padding: 0;
		line-height: 21px;
	}
	div {
		padding: 0;
		flex-grow: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		height: 40px;
		border-right: 1px solid ${({ theme }) => theme.colors.border.YellowBorder};
		&:last-child {
			border: none;
		}
	}
	input {
		display: none;
	}
	input[type='radio']:checked + label {
		background: ${({ theme }) => theme.baseColors.primary};
		h5 {
			color: ${({ theme }) => theme.baseColors.white};
			font-family: 'OpenSansBold';
		}
	}
	label {
		width: 100%;
		padding: 0 !important;
		margin-bottom: 0;
		&::before {
			display: none !important;
		}
	}
`;
const ItemWeekHolder = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 8px;
	overflow: hidden;
	margin-top: 30px;
	margin-bottom: 20px;

	h5 {
		padding: 0;
		line-height: 21px;
	}
	div {
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 40px;
		flex: 0 0 40px;
		border-radius: 50px;
		overflow: hidden;
		height: 40px;
		background: rgba(124, 54, 110, 0.05);
		@media (max-width: 380px) {
			width: 30px;
			flex: 0 0 30px;
			height: 30px;
		}
	}
	input {
		display: none;
	}
	input[type='radio']:checked + label {
		background: ${({ theme }) => theme.baseColors.primary};
		h5 {
			color: ${({ theme }) => theme.baseColors.white};
			font-family: 'OpenSansBold';
		}
	}
	input[type='checkbox']:checked + label {
		background: ${({ theme }) => theme.baseColors.primary};
		width: 100%;
		height: 100%;
		color: ${({ theme }) => theme.baseColors.white};
		display: flex;
		align-items: center;
		justify-content: center;
		h5 {
			color: ${({ theme }) => theme.baseColors.white};
			font-family: 'OpenSansBold';
		}
	}
	label {
		width: 100%;
		padding: 0 !important;
		margin-bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: 'OpenSansBold';
		&::before {
			display: none !important;
		}
	}
`;
export default function NewScheduleModal(props): ReactElement {
	const [newSchedule, setSchedule] = useState('01');
	const [addFavorites] = useState<any>('01');
	const [enableDaily, setEnableDaily] = useState<boolean>(((props.scheduledDetails.items && props.scheduledDetails?.items?.length > 0 && props.scheduledDetails?.items[0]?.subscr_type === 'daily') && (props.scheduledDetails?.items[0]?.is_delete !== true)) ? true : false);
	const [enableMonthly, setEnableMonthly] = useState<boolean>(((props.scheduledDetails.items && props.scheduledDetails?.items?.length > 0 && props.scheduledDetails?.items[0]?.subscr_type === 'monthly') && (props.scheduledDetails?.items[0]?.is_delete !== true)) ? true : false);
	const [enableWeekly, setEnableWeekly] = useState<boolean>(((props.scheduledDetails.items && props.scheduledDetails?.items?.length > 0 && props.scheduledDetails?.items[0]?.subscr_type === 'weekly') && (props.scheduledDetails?.items[0]?.is_delete !== true)) ? true : false);
	const [skipDates, setSkipDates] = useState<any>('');
	const location = useLocation();
	const [venueUserId] = useState<any>(localStorage.getItem('userId'));
	const [venueId] = useState<any>(localStorage.getItem('selectedVenue'));
	const [userName] = useState<any>(localStorage.getItem('userName'));
	const [startDate, setStartDate] = useState<any>(new Date());
	const [endDate, setEndDate] = useState<any>(new Date());
	const [disableCreate, setDisableCreate] = useState(true);
	const [subscType, setSubscrType] = useState('');
	const [subsDate, setSubscrDate] = useState<any>('');
	const [isAlreadyScheduled, setIsAlreadyScheduled] = useState(true);
	const [isStartDateChanged, setIsStartDateChanged] = useState(false);
	const [isEndDateChanged, setIsEndDateChanged] = useState(false);
	const [selectedSkipDates, setSelectedSkipDates] = useState<any>('');

	const parsed = queryString.parse(location.search);

	useEffect(() => {
		if ((props.scheduledDetails?.items?.length > 0) && (props.scheduledDetails?.items[0].is_delete !== true) && (props.is_schedule === true)) {
			setIsAlreadyScheduled(true);
			props.scheduledDetails.items.forEach((data) => {
				setStartDate(moment(data.start_date).format('DD-MM-YYYY'));
				setEndDate(moment(data.end_date).format('DD-MM-YYYY'));
				if (data.skip_date !== undefined) {
					let array = data.skip_date.split(',')
					setSelectedSkipDates(array);
				}

				if (data.subscr_type === 'daily') {
					setEnableDaily(true);
				} else if (data.subscr_type === 'weekly') {
					setEnableWeekly(true);
					setSubscrDate(data.subscr_date);
				} else if (data.subscr_type === 'monthly') {
					setSubscrDate(data.subscr_date);
					setEnableMonthly(true);
				}
			});
		} else {
			setEnableDaily(true);
			setEnableMonthly(false);
			setEnableWeekly(false);
			setIsAlreadyScheduled(false);
		}
	}, [props.scheduledDetails]);

	const handleOnChange = (e: any) => {
		setSchedule(e.target.id);
		if (e.target.id === '01') {
			setDisableCreate(false);
			setSubscrType('daily');
			setEnableDaily(true);
			setEnableMonthly(false);
			setEnableWeekly(false);
		} else if (e.target.id === '02') {
			setSubscrType('weekly');
			setEnableWeekly(true);
			setEnableDaily(false);
			setEnableMonthly(false);
		} else if (e.target.id === '03') {
			setDisableCreate(false);
			setSubscrType('monthly');
			setSubscrDate('1');
			setEnableMonthly(true);
			setEnableDaily(false);
			setEnableWeekly(false);
		}
	};

	const createSchedule = () => {
		let startObj: any;
		let endObj: any;
		if (isStartDateChanged) {
			startObj = new Date(startDate);
			startObj = moment(startObj).format('YYYY-MM-DD');
		} else if (!isStartDateChanged && isAlreadyScheduled) {
			startObj = startDate;
		} else {
			startObj = moment(startDate).format('YYYY-MM-DD');
		}

		if (isEndDateChanged) {
			endObj = new Date(endDate);
			endObj = moment(endObj).format('YYYY-MM-DD');
		}
		else if (!isEndDateChanged && isAlreadyScheduled) {
			endObj = endDate;
		}
		else {
			endObj = moment(endDate).format('YYYY-MM-DD');

		}

		if ((startObj !== 'Invalid date') && (endObj !== 'Invalid date')) {
			const ScheduleOrder = {
				fav_id: parsed.id,
				subscr_name: userName,
				subscr_type: subscType,
				start_date: startObj,
				end_date: endObj,
				subscr_date: subsDate,
				skip_date: skipDates,
				is_active: 1,
				venue_user_id: venueUserId,
				venue_id: venueId,
			};
			const obj: Partial<Ipost> = {
				data: { ScheduleOrder },
				params: {},
				url: '/experion/scheduleorder/create',
				message: 'Order has been scheduled ',
			};
			try {
				api.post(obj).then((data) => {
					if (data !== undefined) {
						props.refetchProducts();
						props.togglePopup();
					}
				});
			} catch (e) { }
		} else {
			setDisableCreate(true);
		}
	};
	const selectSkipDates = (e) => {
		setDisableCreate(false);
		let skipDateArray = [] as any;
		let startObj: any;
		e.forEach((obj) => {
			startObj = new Date(obj);
			startObj = moment(startObj).format('DD-MM-YYYY');
			skipDateArray.push(startObj);
		})
		let skipData = skipDateArray.join(',')
		setSkipDates(skipData.toString());
	};
	const onDaySelection = (event) => {
		if (enableWeekly) {
			setDisableCreate(false);
			setSubscrDate(event.target.value);
		}
	};
	const cancelSchedule = () => {
		let obj1: Partial<Ipost> = {
			params: {},
			url: '',
		};
		obj1.url = `/experion/scheduleorder/delete/` + props.scheduledDetails?.items[0]?.id;
		obj1.message = 'Schedule has been cancelled';
		api.delete(obj1).then(function (d) {
			if (typeof d != 'undefined') {
				props.refetchProducts();
				props.togglePopup();
				// window.location.reload();
			}
		});
	};

	return (
		<SetSchedule>
			<Text as="h1" color="text.textHeading" fontSize={18} fontFamily="OpenSansSemiBold">
				Set Schedule
			</Text>
			<Box mb={4}>
				<FormGroup datePicker>
					<FormLabel>Add Start Date</FormLabel>
					<DatePicker
						format="DD-MM-YYYY"
						minDate={startDate}
						value={startDate}
						onChange={(dateObject) => { setStartDate(dateObject); setDisableCreate(false); setIsStartDateChanged(true); }}
					/>
					{/* <DatePicker
						selected={startDate}
						onChange={(date: any) => setStartDate(date)}
						dateFormat="dd MMMM yyy"
					/> */}
					<Icon.DateIconInput />
				</FormGroup>
			</Box>
			<Box mb={4}>
				<FormGroup datePicker>
					<FormLabel>Add End Date</FormLabel>
					<DatePicker
						format="DD-MM-YYYY"
						minDate={startDate}
						value={endDate}
						onChange={(dateObject) => { setEndDate(dateObject); setDisableCreate(false); setIsEndDateChanged(true); }}
					/>
					{/* <DatePicker
						selected={startDate}
						onChange={(date: any) => setStartDate(date)}
						dateFormat="dd MMMM yyy"
					/> */}
					<Icon.DateIconInput />
				</FormGroup>
			</Box>
			<Box>
				<Text fontSize="16px" lineHeight="21px" fontFamily="OpenSansSemiBold" color="text.black">
					Repeat
				</Text>
				<ItemMonthHolder>
					<RadioButton
						id="01"
						name="paymentMethod"
						onChange={handleOnChange}
						checked={enableDaily == true ? true : false}
						hasActiveBackground
					>
						<Flex alignItems="center" width="100%" pl={[1, 2]}>
							<Text as="h5" fontSize="16px" fontFamily="OpenSansBold" color="text.blackLight">
								Daily
							</Text>
							{addFavorites === '01'}
						</Flex>
					</RadioButton>
					<RadioButton
						id="02"
						name="paymentMethod"
						onChange={handleOnChange}
						checked={enableWeekly == true ? true : false}
						hasActiveBackground
					>
						<Flex alignItems="center" width="100%" pl={[1, 2]}>
							<Text as="h5" fontSize="16px" fontFamily="OpenSansBold" color="text.blackLight">
								Weekly
							</Text>
							{addFavorites === '02'}
						</Flex>
					</RadioButton>
					<RadioButton
						id="03"
						name="paymentMethod"
						onChange={handleOnChange}
						checked={enableMonthly == true ? true : false}
						hasActiveBackground
					>
						<Flex alignItems="center" width="100%" pl={[1, 2]}>
							<Text as="h5" fontSize="16px" fontFamily="OpenSansBold" color="text.blackLight">
								Monthly
							</Text>
							{addFavorites === '03'}
						</Flex>
					</RadioButton>
				</ItemMonthHolder>
				{enableWeekly ? <ItemWeekHolder>
					<Checkbox label="M" key='1' value='Monday' checked={subsDate === 'Monday' ? true : false} handleChange={onDaySelection}></Checkbox>
					<Checkbox label="T" key='2' value='Tuesday' checked={subsDate === 'Tuesday' ? true : false} handleChange={onDaySelection}></Checkbox>
					<Checkbox label="W" key='3' value='Wednesday' checked={subsDate === 'Wednesday' ? true : false} handleChange={onDaySelection}></Checkbox>
					<Checkbox label="Th" key='4' value='Thursday' checked={subsDate === 'Thursday' ? true : false} handleChange={onDaySelection}></Checkbox>
					<Checkbox label="F" key='5' value='Friday' checked={subsDate === 'Friday' ? true : false} handleChange={onDaySelection}></Checkbox>
					<Checkbox label="S" key='6' value='Saturday' checked={subsDate === 'Saturday' ? true : false} handleChange={onDaySelection}></Checkbox>
					<Checkbox label="Su" key='7' value='Sunday' checked={subsDate === 'Sunday' ? true : false} handleChange={onDaySelection}></Checkbox>
				</ItemWeekHolder> : ''}

				{enableMonthly ? <Box mt={25}>
					<FormInput
						type="number"
						// value={values && values.description}
						label="Monthly Date"
						name="Monthly Date"
						onTextChange={(e) => {
							const value = parseInt(e);
							if (!value || (value >= 1 && value <= 31))
								setSubscrDate(e); setDisableCreate(false);
						}}
						maxLength={2}
						value={enableMonthly ? subsDate : ''}
					/>
				</Box>
					: ''}
				<Box mt={4}>
					<Text
						fontSize="16px"
						lineHeight="21px"
						fontFamily="OpenSansSemiBold"
						color="text.black"
					></Text>
					<FormGroup datePicker>
						<FormLabel>Skip Dates </FormLabel>
						<DatePicker format='DD-MM-YYYY' minDate={startDate} maxDate={endDate} placeholder='Choose skip dates' value={selectedSkipDates ? selectedSkipDates : ''} onChange={(e) => selectSkipDates(e)} multiple plugins={[<DatePanel />]} />
						<Icon.DateIconInput />
					</FormGroup>
					{/* <FormGroup datePicker>
						<DatePicker format='DD-MM-YYYY' placeholder='Choose skip dates' onChange={selectSkipDates} multiple plugins={[<DatePanel />]} />
						<Icon.DateIconInput />
					</FormGroup> */}
				</Box>
			</Box>

			<Box as="footer">
				<Button onClick={() => props.togglePopup()} flex={[1, 1, 'initial']} variant="secondary">
					Cancel
				</Button>
				{isAlreadyScheduled ? <Button onClick={cancelSchedule} flex={[1, 1, 'initial']}>
					Cancel Schedule
				</Button> : ""}
				{!isAlreadyScheduled ? <Button onClick={createSchedule} disabled={disableCreate} flex={[1, 1, 'initial']}>
					Create
				</Button> : <Button onClick={createSchedule} disabled={disableCreate} flex={[1, 1, 'initial']}>
						Save
				</Button>}
			</Box>
		</SetSchedule>
	);
}
