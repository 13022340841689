import { dcUserPermissionList, permissionGroup, userTypes } from 'models/enum-constants/common.constants';
import Iget from 'models/Iget';
import LocationSelector from 'pages/auth/locationSelection';
import RedirectUser from 'pages/auth/RedirectUser';
import React, { ReactElement, useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import {
	BreadcrumbNav,
	Footer,
	Header,
	TabsView,
	TableList,
	Stack,
	Loader,
	FormLabel,
} from 'ui/components';
import { FormGroup, Modal, PendingOrder, Sidebar } from 'ui/patterns';
import { AllOrders } from 'ui/patterns/allOrders';
import urls from 'utils/create.url';
import hooks from 'utils/requestCtx/reactQueryHooks';
import utilityFunctions from 'utils/utility.functions';
import { BreadcrumbHolder, MainContent, TabContentHolder, Dashboard } from './styles';
import { Box, Flex, Heading, Text } from 'rebass/styled-components';
import LineChart from 'ui/components/chart/lineChart';
import { Select } from '@rebass/forms';
import * as Icon from 'ui/icons';
import Ipost from 'models/Ipost';
import api from 'utils/requestCtx/api.service';
import moment from 'moment';
import { useLocation, useHistory } from 'react-router-dom';
import RevenueReportLineChart from 'ui/components/RevenueLineChart/revenueReportLineChart';
import DatePicker from 'react-multi-date-picker';

function SupplierDashboard(): ReactElement {
	let userId = localStorage.getItem('userId');
	var userType = localStorage.getItem('userType');
	const dateFormat = 'DD/MM/YYYY';
	const [showDCNotFoundModal, setShowDCNotFoundModal] = useState(false);
	const [showCustomerNotFoundModal, setShowCustomerNotFoundModal] = useState(false);
	const [selectedLocation, setSelectedLocation] = useState('');
	const [reportData, setReportData] = useState({ data: [], period: '' } as any);
	const location = useLocation();
	const [isLoading, setIsLoading] = useState(false);
	const [dateInterval, setDateInterval] = useState({
		from: moment().format('MM/DD/YYYY'),
		to: moment().format('MM/DD/YYYY'),
	} as any);
	const [dateRange, setDateRange] = useState(`Today` as any);
	const [startDate, setStartDate] = useState<any>(new Date());
	const [endDate, setEndDate] = useState<any>(new Date());
	const [period, setPeriod] = useState<any>('day');
	const supplierId = localStorage.getItem('supplierId');
	const dcId = localStorage.getItem('selectedLocation');
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const [productViewData, setProductViewData] = useState([] as any);
	const [productSoldData, setProductSoldData] = useState([] as any);

	const history = useHistory();
	const dcUrl = `experion/supplycompanyaccount/get/${userId}`;
	const dcAdminUrl = urls.GetSearchUrl({
		url: 'experion/supplycompanyaccount/dc/search?',
		pageSize: 500,
		fields: [
			{
				field_name: 'supply_id',
				field_value: localStorage.getItem('supplierId')!,
			},
		],
		filterDeleted: true,
		filterInactive: true,
	});

	const dropdownValues = [
		{ id: 1, value: 'Today' },
		{ id: 2, value: 'Yesterday' },
		{ id: 3, value: 'Last 7 Days' },
		{ id: 4, value: 'Last 30 Days' },
		{ id: 5, value: 'Previous Month' },
		{ id: 6, value: 'Previous Year' },
		{ id: 7, value: 'Week to Date' },
		{ id: 8, value: 'Month to Date' },
		{ id: 9, value: 'Year to Date' },
		{ id: 10, value: 'Custom' },
	];

	function apiURLSelector() {
		if (userType == userTypes.supplierAdmin) {
			return dcAdminUrl;
		} else if (userType == userTypes.supplierUser) {
			return dcUrl;
		} else {
			return '';
		}
	}

	const locationSelector = 'DcSelection';
	const obj: Partial<Iget> = {
		params: {},
		url: apiURLSelector(),
		enabled: true,
		key: locationSelector,
		resultFilteringRequired: true,
	};

	var qry = hooks.useQuery(obj);
	useEffect(() => {
		let locationSelected = localStorage.getItem('selectedLocation');
		if (locationSelected === null) {
			let isMounted = true;
			togglePopup();
			return () => {
				isMounted = false;
			};
		} else {
			setSelectedLocation(locationSelected);
		}
	}, []);

	useEffect(() => {
		if (qry.data && qry.data.length == 1) {
			const setPermissions = async () => {
				await utilityFunctions
					.setPermissions(qry.data[0].dc_id, false)
					.then(function () {
						setLocation();
					})
					.catch(function () {
						setLocation();
					});
			};
			setPermissions();
		} else {
			let locationSelected = localStorage.getItem('selectedLocation');
			if (qry.data && qry.data.length > 1 && (locationSelected == null || locationSelected == '')) {
				setShowDCNotFoundModal(false);
				togglePopup();
			} else if (
				!qry.isLoading &&
				qry.data &&
				qry.data.length == 0 &&
				localStorage.getItem('userType') == userTypes.supplierAdmin
			) {
				toggleRedirectPopup();
			} else if (qry.data && locationSelected != null && locationSelected != '') {
				setSelectedLocation(locationSelected);
			}
		}
	}, [qry.data]);

	function setLocation() {
		if (qry.data[0].is_delete != true && qry.data[0].is_active == true) {
			if (userType == userTypes.supplierAdmin) {
				if (userId && localStorage.getItem('selectedLocation') != qry.data[0].dc_id) {
					let id = qry.data[0].dc_id;
					localStorage.setItem('selectedLocation', id);
					reloadPage();
				}
				if (localStorage.getItem('selectedLocation')) setSelectedLocation(localStorage.getItem('selectedLocation')!);
			} else if (userType == userTypes.supplierUser) {
				if (userId && localStorage.getItem('selectedLocation') != qry.data[0].dc_id) {
					let id = qry.data[0].dc_id;
					localStorage.setItem('selectedLocation', id);
					reloadPage();
				}
				if (localStorage.getItem('selectedLocation')) setSelectedLocation(localStorage.getItem('selectedLocation')!);
			}
		}
	}

	const reloadPage = () => {
		window.location.href = window.location.href.replace('#!', '');
	};

	const callbackFunction = (childData) => {
		setSelectedLocation(childData);
		window.location.href = window.location.href.replace('#!', '');
	};
	async function togglePopup() {
		if (qry.data && qry.data.length > 1) {
			//let userType = localStorage.getItem('userType');
			//if(userType != userTypes.supplierAdmin) {
			await setShowCustomerNotFoundModal(!showCustomerNotFoundModal);
			//}
		}
	}

	async function toggleRedirectPopup() {
		await setShowDCNotFoundModal(!showDCNotFoundModal);
	}

	const breadcrumbNav = [
		// { id: '01', label: 'Home', active: false },
		{ id: '03', label: 'Dashboard', active: true },
	];

	useEffect(() => {
		if (selectedLocation != null && selectedLocation !== undefined && selectedLocation != "") {
			setIsLoading(true); // initial call for todays report
			const obj: Partial<Ipost> = {
				data: {
					from: moment().format('MM/DD/YYYY'),
					to: moment().format('MM/DD/YYYY'),
					type: 'dashboard',
					supplierId: supplierId,
					dcId: dcId,
					dcuserId: userId,
					period: 'day',
				},
				params: {},
				url: `/experion/supplier/report`,
				message: ' ',
			};
			try {
				api.post(obj).then(function (data) {
					if (typeof data != 'undefined' && data.length > 0) {
						setReportData({ data: data[0].order_data, period: JSON.parse(JSON.stringify(obj.data.period)) } as any);
						setProductViewData(data[0].view_data.slice(0, 5));
						setProductSoldData(data[0].sold_data.slice(0, 5));
						window.dispatchEvent(new Event('resize'));
					}
				});
				setIsLoading(false);
			} catch (e) {
				setIsLoading(false);
			}
		}
		setIsLoading(false);
	}, [location, selectedLocation]);

	useEffect(() => {
		if (dateRange === 'Custom') {
			onDropDownChange({ target: { value: 'Custom' } });
		}
	}, [startDate, endDate]);

	const updatePeriod = (data) => {
		const dayObject = {
			year: 365,
			monthcase: 30,
		};
		var periodData = '';
		var fromDate = new Date(Date.parse(data.from)) as any;
		var toDate = new Date(Date.parse(data.to)) as any;
		var differenceMs = Math.abs(toDate - fromDate);
		var dayMs = 1000 * 60 * 60 * 24;
		var days = Math.round(differenceMs / dayMs);
		if (days > dayObject.year) { periodData = 'year'; setPeriod(periodData); }
		else if (days > dayObject.monthcase) { periodData = 'month'; setPeriod(periodData); }
		else { periodData = 'day'; setPeriod(periodData); }
		return periodData;
	};

	async function onDropDownChange(event) {
		setDateRange(event.target.value);
		if (event.target.value !== 'Custom') {
			setStartDate(new Date());
			setEndDate(new Date());
		}
		let getDate = utilityFunctions.getFromToDates(event.target.value);
		setIsLoading(true);
		if (getDate.from === '' || getDate.to === '') {
			if (event.target.value === 'Today') {
				getDate.from = moment().format('MM/DD/YYYY');
				getDate.to = moment().format('MM/DD/YYYY');
			} else if (event.target.value === 'Custom') {
				var sdate = JSON.parse(JSON.stringify(startDate.toString()));
				var edate = JSON.parse(JSON.stringify(endDate.toString()));
				sdate = moment(sdate, 'DD/MM/YYYY');
				getDate.from = sdate._isValid
					? moment(sdate).format('MM/DD/YYYY')
					: moment().format('MM/DD/YYYY');
				edate = moment(edate, 'DD/MM/YYYY');
				getDate.to = edate._isValid
					? moment(edate).format('MM/DD/YYYY')
					: moment().format('MM/DD/YYYY');
			}
		}
		setDateInterval(getDate as any);
		const periodData = updatePeriod(getDate);
		const obj: Partial<Ipost> = {
			data: {
				from: getDate.from,
				to: getDate.to,
				type: 'dashboard',
				supplierId: supplierId,
				dcId: dcId,
				dcuserId: userId,
				period: event.target.value === 'Custom' ? periodData : getDate.period,
			},
			params: {},
			url: `/experion/supplier/report`,
			message: ' ',
		};
		try {
			await api.post(obj).then(function (data) {
				if (typeof data != 'undefined') {
					setReportData({ data: data[0].order_data, period: JSON.parse(JSON.stringify(obj.data.period)) } as any);
					setProductViewData(data[0].view_data.slice(0, 5));
					setProductSoldData(data[0].sold_data.slice(0, 5));
					window.dispatchEvent(new Event('resize'));
				}
			});
			setIsLoading(false);
		} catch (e) {
			setIsLoading(false);
		}
	}
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	}
	return (
		<>
			<Modal
				width="600px"
				backdrop="static"
				showModal={showCustomerNotFoundModal}
				setShowModal={setShowCustomerNotFoundModal}
			>
				<LocationSelector
					qry={qry.data}
					parentCallback={callbackFunction}
					togglePopup={togglePopup}
				/>
			</Modal>
			<Modal
				backdrop="static"
				width="600px"
				showModal={showDCNotFoundModal}
				setShowModal={setShowDCNotFoundModal}
			>
				<RedirectUser
					qry={qry.data}
					parentCallback={callbackFunction}
					togglePopup={toggleRedirectPopup}
				/>
			</Modal>
			{/* Main Header */}
			<Header toggleSideBar={() => onclickHambrgBtn()} locationSelected={userId ? selectedLocation : ''}> </Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={() => onclickHambrgBtn()}></Sidebar>
			{isLoading ? <Loader></Loader> : null}
			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbHolder>
					<BreadcrumbNav Heading="Dashboard" breadcrumbList={breadcrumbNav} />
				</BreadcrumbHolder>
				{/* <CardsAndNotificationHolder>
					<SupplierCardHolder productCount="250" userCount="250"></SupplierCardHolder>
					<Notifications count="20"></Notifications>
				</CardsAndNotificationHolder> */}
				{/* {utilityFunctions.isAnyGranted(permissionGroup.Report) && (
					<Dashboard>
						<Box className="reportBlock">
							<Stack categorySelect gap={0}>
								<div>
									<label>Date Range</label>
									<Select
										id="country"
										name="country"
										defaultValue="Last 30 days"
										onChange={onDropDownChange}
									>
										{/* <option value=''>-Select-</option> */}
				{/* {dropdownValues &&
											dropdownValues?.map((option) => {
												return (
													<option value={option.value} key={option.id}>
														{option.value}
													</option>
												);
											})}
									</Select>
									<Icon.downArrow />
								</div>
							</Stack>
							{dateRange == 'Custom' && (
								<Box as="section">
									<FormGroup datePicker>
										<FormLabel>From</FormLabel>
										<DatePicker
											format={dateFormat}
											maxDate={endDate}
											value={startDate}
											onChange={(dateObject) => {
												setStartDate(dateObject);
											}}
										/>
										<Icon.DateIconInput />
									</FormGroup>
									<FormGroup datePicker>
										<FormLabel>To</FormLabel>
										<DatePicker
											format={dateFormat}
											minDate={startDate}
											value={endDate}
											onChange={(dateObject) => {
												setEndDate(dateObject);
											}}
										/>
										<Icon.DateIconInput />
									</FormGroup>
								</Box>
							)}
							<Text as="h1">
								{moment(dateInterval?.from).format('DD MMM YYYY').toString()} -{' '}
								{moment(dateInterval?.to).format('DD MMM YYYY').toString()}
							</Text>
						</Box>
						<Flex as="section" className="section" flexWrap="wrap">
							{utilityFunctions.isGranted(dcUserPermissionList.SalesReport) &&
								<>
									<Box as="article" p={3} width={[1, 1, 1 / 2]}>
										<Heading
											onClick={() => {
												history.push({
													pathname: '/admin/reports',
													search:
														`?type=sales&period=${period}&from=` +
														moment(dateInterval?.from).format('MM/DD/YYYY') +
														'&to=' +
														moment(dateInterval?.to).format('MM/DD/YYYY') +
														`&range=${dateRange}`
												});
											}}
										>
											Revenue Report
										</Heading>
										<RevenueReportLineChart reportData={reportData?.data} period={reportData?.period} />
									</Box>
									<Box as="article" p={3} width={[1, 1, 1 / 2]}>
										<Heading
											onClick={() => {
												history.push({
													pathname: '/admin/reports',
													search:
														`?type=sales&period=${period}&from=` +
														moment(dateInterval?.from).format('MM/DD/YYYY') +
														'&to=' +
														moment(dateInterval?.to).format('MM/DD/YYYY') +
														`&range=${dateRange}`
												});
											}}
										>
											Orders Report
										</Heading>

										<LineChart reportData={reportData?.data} period={reportData?.period} />
									</Box>
								</>}
							{utilityFunctions.isGranted(dcUserPermissionList.ViewsReport) &&
								<Box as="article" p={3} width={[1, 1, 1 / 2]}>
									<Heading
										onClick={() => {
											history.push({
												pathname: '/admin/reports',
												search:
													`?type=view&period=${period}&from=` +
													moment(dateInterval?.from).format('MM/DD/YYYY') +
													'&to=' +
													moment(dateInterval?.to).format('MM/DD/YYYY') +
													`&range=${dateRange}`
											});
										}}
									>
										Product Views
									</Heading>
									<TableList className="tableList">
										<table>
											<thead>
												<tr>
													<th>Product</th>
													<th>Views</th>
												</tr>
											</thead>

											<tbody>
												{productViewData && productViewData.length > 0
													? productViewData.map((item, i) => {
														return (
															<tr key={i}>
																<td data-label="Product">
																	<Text
																		as="h1"
																		fontSize={15}
																		fontFamily="OpenSansMedium"
																		color="text.black"
																	>
																		<span>{item.product_name}</span>
																	</Text>
																</td>
																<td data-label="Views">
																	<Text
																		as="h1"
																		fontSize={15}
																		fontFamily="OpenSansMedium"
																		color="text.black"
																	>
																		{parseFloat(item.views_num)}
																	</Text>
																</td>
															</tr>
														);
													})
													: <tr><td colSpan={2} className="no-records">No Records Found</td></tr>}
											</tbody>
										</table>
									</TableList>
								</Box>
							}
							{utilityFunctions.isGranted(dcUserPermissionList.ProductSoldReport) &&
								<Box as="article" p={3} width={[1, 1, 1 / 2]}>
									<Heading
										onClick={() => {
											history.push({
												pathname: '/admin/reports',
												search:
													`?type=sold&period=${period}&from=` +
													moment(dateInterval?.from).format('MM/DD/YYYY') +
													'&to=' +
													moment(dateInterval?.to).format('MM/DD/YYYY') +
													`&range=${dateRange}`
											});
										}}
									>
										Product Sales
									</Heading>
									<TableList className="tableList">
										<table>
											<thead>
												<tr>
													<th>Product</th>
													<th>Unit Sold</th>
												</tr>
											</thead>

											<tbody>
												{productSoldData && productSoldData.length > 0
													? productSoldData.map((item, i) => {
														return (
															<tr key={i}>
																<td data-label="Product">
																	<Text
																		as="h1"
																		fontSize={15}
																		fontFamily="OpenSansMedium"
																		color="text.black"
																	>
																		<span>{item.order_items_name}</span>
																	</Text>
																</td>
																<td data-label="Unit Sold">
																	<Text
																		as="h1"
																		fontSize={15}
																		fontFamily="OpenSansMedium"
																		color="text.black"
																	>
																		{parseFloat(item.ordered_qty)}
																	</Text>
																</td>
															</tr>
														);
													})
													: <tr><td colSpan={2} className="no-records">No Records Found</td></tr>}
											</tbody>
										</table>
									</TableList>
								</Box>
							}
						</Flex>
					</Dashboard> */}
				{/* )} */}
				<TabContentHolder>
					<TabsView>
						<Tabs>
							{(utilityFunctions.isGranted(dcUserPermissionList.OrderManagementView) ||
								utilityFunctions.isGranted(dcUserPermissionList.OrderManagementUpdate)) && (
									<TabList>
										<Tab>Pending Orders</Tab>
										<Tab>All Orders</Tab>
									</TabList>
								)}
							{(utilityFunctions.isGranted(dcUserPermissionList.OrderManagementView) ||
								utilityFunctions.isGranted(dcUserPermissionList.OrderManagementUpdate)) && (
									<TabPanel>
										<PendingOrder></PendingOrder>
									</TabPanel>
								)}
							{(utilityFunctions.isGranted(dcUserPermissionList.OrderManagementView) ||
								utilityFunctions.isGranted(dcUserPermissionList.OrderManagementUpdate)) && (
									<TabPanel>
										<AllOrders></AllOrders>
									</TabPanel>
								)}
						</Tabs>
					</TabsView>
				</TabContentHolder>
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default SupplierDashboard;
