import styled from 'styled-components';
import { getHSL } from '../../ui/utils/getHSL';
const MainContent = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
	padding: 30px 50px;
	@media (max-width: 960px) {
		padding: 30px 15px 50px;
	}
	@media (max-width: 767px) {
		padding: 30px 15px 0;
	}

`;
const BreadcrumbHolder = styled.div`
	width: 100%;
	float: left;
	@media (max-width: 860px) {
		div {
			padding: 0;
		}
	}
`;

const ContentHolder = styled.div`
	display: flex;
	@media (max-width: 860px) {
		flex-direction: column;
		padding-top: 15px;
	}
`;
const RightContentHolder = styled.div`
	flex-grow: 1;
	@media (max-width: 480px) {
		article {
			width: 500px;
			max-width: 500px;
			flex: 0 0 500px !important;
		}
	}
`;

export { MainContent, BreadcrumbHolder, RightContentHolder, ContentHolder };
