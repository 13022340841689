import AddAttribute from 'admin/addAttribute';
import AddDCList from 'admin/addDCList';
import AddExistingUserVenue from 'admin/addExistingUserVenue';
import AddNewVenue from 'admin/addNewVenue';
import AddNewVenueUser from 'admin/addNewVenueUser';
import AddProducts from 'admin/addProducts';
import AddUsers from 'admin/addUsers';
import AskHelp from 'admin/askHelp';
import AttributeManagement from 'admin/attributeManagement';
import Checkout from 'admin/checkout';
import CheckoutPlaceOrder from 'admin/checkoutPlaceOrder';
import OrderConfirm from 'admin/confirm';
import DashboardVenue from 'admin/dashboardVenue';
import DCUserDetails from 'admin/dc-userdetails';
import DCList from 'admin/DCList';
import DCUserRoleManagement from 'admin/dcUserRoleManagement';
import DCUserRoleManagementAdd from 'admin/dcuserRoleManagementAdd';
import DeliveryConfirmationEnterProblem from 'admin/deliveryConfirmationEnterProblem';
import DeliveryConfirmations from 'admin/deliveryConfirmations';
import DeliveryConfirmationsInner from 'admin/deliveryConfirmationsInner';
import DetailPageOfSupplier from 'admin/detailPageOfSupplier';
import FAQ from 'admin/faq';
import HospitalityGroupDetails from 'admin/hospitalityGroupDetails';
import NewUsers from 'admin/newUsers';
import Notifications from 'admin/notifications';
import OrderList from 'admin/orderList';
import OrderRequest from 'admin/orderRequest';
import PrivacyPolicy from 'admin/privacyPolicy';
import ProductManagement from 'admin/productManagement';
import ReferARIA from 'admin/referARIA';
import SKUList from 'admin/sKUList';
import SupplerCompanySettings from 'admin/supplerCompanySettings';
import SupplierCompanyProfileManagement from 'admin/supplierCompanyProfileManagement';
import SupplierDashboard from 'admin/supplierDashboard';
import SupplierProfileDetails from 'admin/supplierProfileDetails';
import TermsAndCondition from 'admin/termsAndCondition';
import UserDashboard from 'admin/userDashboard';
import UserDetails from 'admin/userDetails';
import UserList from 'admin/userList';
import UserProfileDetails from 'admin/userProfileDetails';
import UserRoleManagement from 'admin/userRoleManagement';
import UserRoleManagementAdd from 'admin/userRoleManagementAdd';
import FullUsersList from 'admin/usersList';
import VenueCreditManagement from 'admin/venueCreditTracker';
import VenueDetailsNewUsers from 'admin/venueDetailsNewUsers';
import FullVenuesList from 'admin/venuesList';
import {
	dcUserPermissionList,
	permissionGroup,
	venueUserPermissionList,
} from 'models/enum-constants/common.constants';
import ForgotComponent from 'pages/auth/forgot-password';
import UnAuthorized from 'pages/auth/unAuthorized';
import VerificationComponent from 'pages/auth/verification';
import OtpScreen from 'pages/auth/otpScreen';

import Dashboard from 'pages/Dashboard';
import Home from 'pages/Home';
import ListingListView from 'pages/ListingListView';
import ListingTIleView from 'pages/ListingTIleView';
import ProductDetails from 'pages/ProductDetails';
import React, { ReactElement } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import DetailPageOFSupplier from 'suppliers/detailPageOFSupplier';
import utilityFunctions from 'utils/utility.functions';
import Cart from 'venue/cart';
import FavouriteOrderDetails from 'venue/favouriteOrderDetails';
import Orders from 'venue/orders';
import ParkedOrderDetails from 'venue/parkedOrderDetails';
import RecentOrderDetails from 'venue/recentOrderDetails';
import LoginComponent from '../pages/auth/login';
import EditDCList from 'admin/editDCList';
import DcConfig from 'admin/dcConfig';
import DetailPageOFSuppliers from 'suppliers/supplierDetails';
import SupplierReview from 'suppliers/supplierReview';
import SupplierReviewAndRating from 'suppliers/supplierReviewRating';
import SupplierReviewRatingVenue from 'suppliers/supplierReviewRatingVenue';
import Order from 'admin/order';
import ProductSales from 'admin/productSales';
import SalesReport from 'admin/salesReport';
import ProductSalesView from 'admin/productSalesView';
import ReportDashboard from 'admin/reportDashboard';
import PendingOrderDetails from 'venue/pendingOrderDetails';
import ProductCart from 'venue/productCart';
import AllOrderDetails from 'venue/allOrderDetails';
function AppRouter(): ReactElement {
	return (
		<Switch>
			<Route exact path="/">
				<Home />
			</Route>
			{/* B2B Product Shop */}
			<Route exact path="/home/dashboard">
				<Dashboard />
			</Route>
			<Route exact path="/home/product-list">
				<ListingTIleView />
			</Route>
			<Route exact path="/home/listing-tile-view">
				<ListingTIleView />
			</Route>
			<Route exact path="/home/listing-list-view">
				<ListingListView />
			</Route>
			<Route exact path="/home/product-details">
				<ProductDetails />
			</Route>
			<Route exact path="/store/favouriteOrderDetails">
				{(utilityFunctions.isGranted(venueUserPermissionList.FavoriteOrderView) && (
					<FavouriteOrderDetails />
				)) || <UnAuthorized />}
			</Route>
			<Route exact path="/admin/checkout">
				{(utilityFunctions.isGranted(venueUserPermissionList.ManageProductsinCart) && (
					<Checkout />
				)) || <UnAuthorized />}
			</Route>
			<Route exact path="/admin/confirm">
				<OrderConfirm />
			</Route>

			{/* Venue Admin Panel */}
			<Route exact path="/admin/user-dashboard">
				<UserDashboard />
			</Route>
			<Route exact path="/admin/edit-store">
				<AddNewVenue />
			</Route>
			<Route exact path="/admin/add-users">
				<AddUsers />
			</Route>
			<Route exact path="/admin/dashboard-store">
				<DashboardVenue />
			</Route>
			<Route exact path="/admin/add-new-store">
				<AddNewVenue />
			</Route>
			<Route exact path="/admin/store-details-new-users">
				<VenueDetailsNewUsers />
			</Route>
			<Route exact path="/admin/stores-list">
				<FullVenuesList />
			</Route>
			<Route exact path="/admin/user">
				<AddUsers />
			</Route>
			<Route exact path="/admin/users-list">
				<FullUsersList />
			</Route>
			<Route exact path="/admin/user-role-management">
				{(utilityFunctions.isAnyGranted(permissionGroup.UserRole) && <UserRoleManagement />) || (
					<UnAuthorized />
				)}
			</Route>
			<Route exact path="/admin/user-role">
				{(utilityFunctions.isGranted(venueUserPermissionList.UserRoleManagementAdd) && (
					<UserRoleManagementAdd />
				)) || <UnAuthorized />}
			</Route>
			<Route exact path="/store/orders">
				<Orders />
			</Route>
			<Route exact path="/admin/user-role-management-add">
				{(utilityFunctions.isGranted(venueUserPermissionList.UserRoleManagementAdd) && (
					<UserRoleManagementAdd />
				)) || <UnAuthorized />}
			</Route>
			<Route exact path="/admin/dc-user-role">
				<DCUserRoleManagementAdd />
			</Route>
			<Route exact path="/admin/checkoutPlaceOrder">
				{(utilityFunctions.isGranted(venueUserPermissionList.PlaceOrder) && (
					<CheckoutPlaceOrder />
				)) || <UnAuthorized />}
			</Route>
			<Route exact path="/store/cart">
				{(utilityFunctions.isGranted(venueUserPermissionList.ManageProductsinCart) && ( 
					<ProductCart />
				)) || <UnAuthorized />}
			</Route>
			<Route exact path="/store/parkedOrderDetails">
				{(utilityFunctions.isAnyGranted(permissionGroup.ParkedOrder) && <ParkedOrderDetails />) || (
					<UnAuthorized />
				)}
			</Route>
			<Route exact path="/store/myOrderDetails">
				{(utilityFunctions.isAnyGranted(permissionGroup.RecentOrder) && <RecentOrderDetails />) || (
					<UnAuthorized />
				)}
			</Route>

			<Route exact path="/store/addExistingUser">
				<AddExistingUserVenue />
			</Route>
			<Route exact path="/store/addNewStoreUser">
				<AddNewVenueUser />
			</Route>

			{/* Supplier Admin Panel */}
			<Route exact path="/admin/seller-dashboard">
				<SupplierDashboard />
			</Route>
			<Route exact path="/admin/dashboard-dcUser">
				<DashboardVenue />
			</Route>
			<Route exact path="/admin/DCList">
				<DCList />
			</Route>
			<Route exact path="/admin/addDCList">
				<AddDCList />
			</Route>
			<Route exact path="/admin/editDCList">
				<AddDCList />
			</Route>
			<Route exact path="/admin/editDCListUi">
				<EditDCList />
			</Route>
			<Route exact path="/admin/dcConfig">
				<DcConfig />
			</Route>
			<Route exact path="/admin/sellers-details">
				<DetailPageOFSuppliers />
			</Route>
			<Route exact path="/admin/seller-ratings">
				<SupplierReviewAndRating />
			</Route>
			<Route exact path="/admin/seller-rating-by-store">
				<SupplierReviewRatingVenue />
			</Route>
			<Route exact path="/admin/sellers-review">
				<SupplierReview />
			</Route>
			<Route exact path="/admin/detailPageOfSupplier">
				<DetailPageOfSupplier />
			</Route>
			<Route exact path="/admin/productManagement">
				<ProductManagement />
			</Route>
			<Route exact path="/admin/newUsers">
				<NewUsers />
			</Route>
			<Route exact path="/admin/add-products">
				<AddProducts />
			</Route>
			<Route exact path="/admin/new-dc-user">
				<NewUsers />
			</Route>
			<Route exact path="/admin/edit-dc-user">
				<NewUsers />
			</Route>
			<Route exact path="/admin/userList">
				<UserList />
			</Route>
			<Route exact path="/admin/dc-user-Details">
				<DCUserDetails />
			</Route>
			<Route exact path="/admin/user-details">
				<UserDetails />
			</Route>
			<Route exact path="/admin/dc-user-role-management">
				<DCUserRoleManagement />
			</Route>

			<Route exact path="/suppliers/seller-details">
				<DetailPageOFSupplier />
			</Route>
			<Route exact path="/admin/Quick-Order">
				{(utilityFunctions.isGranted(venueUserPermissionList.QuickOrdersView) &&
					utilityFunctions.isGranted(venueUserPermissionList.ManageProductsinCart) && (
						<SKUList />
					)) || <UnAuthorized />}
			</Route>
			<Route exact path="/admin/customerGroupDetails">
				<HospitalityGroupDetails />
			</Route>
			<Route exact path="/admin/userProfileDetails">
				<UserProfileDetails />
			</Route>
			<Route exact path="/admin/sellerProfileDetails">
				<SupplierProfileDetails />
			</Route>
			<Route exact path="/admin/termsAndCondition">
				<TermsAndCondition />
			</Route>
			<Route exact path="/admin/privacyPolicy">
				<PrivacyPolicy />
			</Route>
			<Route exact path="/admin/faq">
				<FAQ />
			</Route>
			<Route exact path="/admin/deliveryConfirmations">
				{(utilityFunctions.isGranted(venueUserPermissionList.DeliveryConfirmation) && (
					<DeliveryConfirmations />
				)) || <UnAuthorized />}
			</Route>
			<Route exact path="/admin/deliveryConfirmationsInner">
				{(utilityFunctions.isGranted(venueUserPermissionList.DeliveryConfirmation) && (
					<DeliveryConfirmationsInner />
				)) || <UnAuthorized />}
			</Route>
			<Route exact path="/admin/deliveryConfirmationEnterProblem">
				{(utilityFunctions.isGranted(venueUserPermissionList.DeliveryConfirmation) && (
					<DeliveryConfirmationEnterProblem />
				)) || <UnAuthorized />}
			</Route>
			<Route exact path="/admin/notifications">
				<Notifications />
			</Route>
			<Route exact path="/admin/OrderList">
				{((utilityFunctions.isGranted(dcUserPermissionList.OrderManagementView) ||
					utilityFunctions.isGranted(dcUserPermissionList.OrderManagementUpdate)) && (
					<OrderList />
				)) || <UnAuthorized />}
			</Route>
			{/* <Route exact path="/admin/orderAccepted">
				<OrderAccepted />
			</Route> */}
			<Route exact path="/admin/orderRequest">
				{((utilityFunctions.isGranted(dcUserPermissionList.OrderManagementView) ||
					utilityFunctions.isGranted(dcUserPermissionList.OrderManagementUpdate)) && (
					<OrderRequest />
				)) || <UnAuthorized />}
			</Route>
			{/* <Route exact path="/admin/orderRejected">
				<OrderRejected />
			</Route> */}
			<Route exact path="/admin/attributeManagement">
				<AttributeManagement />
			</Route>
			<Route exact path="/admin/sellerCompanySettings">
				<SupplerCompanySettings />
			</Route>
			<Route exact path="/admin/sellerCompanyProfileManagement">
				<SupplierCompanyProfileManagement />
			</Route>
			<Route exact path="/admin/storeCreditStatus">
				{((utilityFunctions.isGranted(dcUserPermissionList.CreditStatusView) ||
					utilityFunctions.isGranted(dcUserPermissionList.CreditStatusUpdate)) && (
					<VenueCreditManagement />
				)) || <UnAuthorized />}
			</Route>

			<Route exact path="/admin/referExperion">
				<ReferARIA />
			</Route>
			<Route exact path="/admin/askHelp">
				<AskHelp />
			</Route>
			<Route exact path="/admin">
				<AskHelp />
			</Route>
			<Route exact path="/admin/attribute">
				<AddAttribute />
			</Route>

			<Route exact path="/login" component={LoginComponent} />
			<Route exact path="/forgot-password" component={ForgotComponent} />
			<Route exact path="/verification" component={VerificationComponent} />
			<Route exact path="/mobile-verification" component={OtpScreen} />
			{/* <Route exact path="/admin/productSales">
				<ProductSales />
			</Route> */}
			{/* <Route exact path="/admin/salesReport">
				<SalesReport />
			</Route> */}
			<Route exact path="/admin/reports">
				<ProductSalesView />
			</Route>
			<Route exact path="/admin/reportDashboard">
				<ReportDashboard />
			</Route>
			<Route exact path="/store/pendingOrderDetails">
				<PendingOrderDetails />
			</Route>
			<Route exact path="/venue/productCart">
				<Cart />
			</Route>
			<Route exact path="/store/allOrderDetails">
				<AllOrderDetails />
			</Route>
			{/* Load "Home" for all paths other than '/' also. Remove and add routes as needed */}

			<Route>
				{/* <Redirect to="/" /> */}
				<Redirect from="*" to="/login" />
			</Route>
		</Switch>
	);
}

export default AppRouter;
