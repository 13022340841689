import { Checkbox, Label } from '@rebass/forms';
import { condition } from 'models/enum-constants/common.constants';
import Iget from 'models/Iget';
import Ipost from 'models/Ipost';
import React, { ReactElement, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory } from 'react-router-dom';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { Box, Text } from 'rebass/styled-components';
import { Button, Search, TableList, Toggle } from 'ui/components';
import icons from 'ui/icons';
import urls from 'utils/create.url';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import notify from 'utils/toast.service';
import { PageSearchHolder, UserListHolder } from './styles';

function UsersList(props): ReactElement {
	const userKey = 'userKey';
	const history = useHistory();
	var [userList, updateUserList] = useState<any>([]);
	var [userQList, updateUserQList] = useState<any>([]);

	var [idList, updateIdList] = useState<any>([]);
	var [enableButton, updateEnableButton] = useState<any>(false);
	const [checked, setChecked] = useState(false);
	const [action, setAction] = useState('');
	const [id, setId] = useState(0);
	const [modalText, setmodalText] = useState('');
	const [showPopup, setShowModal] = useState(false);

	const setFilteredData = (val) => {
		const sampleList = userQList;
		if (val && val.length > 0) {
			var userList = sampleList.filter(
				(v) =>
					v.first_name.toLowerCase().includes(val.toLowerCase()) ||
					v.last_name.toLowerCase().includes(val.toLowerCase()) ||
					v.venue_user_email.toLowerCase().includes(val.toLowerCase()) ||
					v.phone.toString().includes(val)
			);
			updateUserList(userList);
		}
		else {
			updateUserList(userQList);
		}
	}

	// const urlObj: Partial<Ipost> = {
	// 	params: {},
	// 	url: '',
	// };

	const url =
		urls.GetSearchUrl({
			url: 'experion/venueuser/search?',
			pageSize: 10,
			fields: [{
				field_name: 'hg_id',
				field_value: localStorage.getItem("hospitalityId")!
			},
			{
				field_name: 'magento_user_id',
				field_value: localStorage.getItem("hgAdminId")!,
				field_conditionType: condition.neq
			}
			],
			filterDeleted: true,
		});

	const obj: Partial<Iget> = {
		params: {},
		url: url,
		enabled: true,
		key: userKey,
		resultFilteringRequired: true,
	};
	var qry = hooks.useQuery(obj);
	useEffect(() => {
		// const ac = new AbortController();
		//props.getUserCount(qry.data?.length);
		qry.data && updateUserList([...qry.data]);
		qry.data && updateUserQList([...qry.data]);
		//return () => ac.abort(); // Abort both fetches on unmount
	}, [qry.data]);

	const apiObj: Partial<Ipost> = {
		params: {},
		url: '',
	};

	//Page Actions
	const selectCheckBox = (id, event) => {
		if (event.target.checked) {
			idList.push(id);
		} else {
			idList.splice(idList.indexOf(id), 1);
		}
		updateIdList(idList);
		if (idList.length >= 1) {
			updateEnableButton(true);
		} else {
			updateEnableButton(false);
		}
	};

	const onNameClick = (id) => {
		history.push({
			pathname: 'user-details',
			search: '?id=' + id,
		});
	};


	function deleteUserItem() {
		apiObj.url = `experion/venueuser/bulkdelete`;
		apiObj.data = { id: (typeof idList != 'undefined' && idList.length > 0) ? idList : [id] };
		apiObj.message = `User${(typeof idList != 'undefined' && idList.length > 1) ? '(s)' : ''} deleted successfully`;
		api.post(apiObj).then(function (d) {
			if (typeof d != 'undefined') {
				let datalist = [];
				if (typeof idList != 'undefined' && idList.length > 0) {
					datalist = userList.filter((x) => !idList.includes(x.id));
				}
				else {
					datalist = userList.filter((x) => x.id !== id);
				}
				updateUserList(datalist);
				updateUserQList(datalist);
				props.getUserCount(datalist.length);
			}
			updateIdList([]);
			setId(0);
		});
		setShowModal(!showPopup);
	}

	const viewAllUsers = () => {
		history.push('users-list');
	};

	async function closePopup() {
		if (action == 'upd') {
			userList[userList.findIndex((x) => x.id == id)].is_active = !checked;
			updateUserList(userList);
			updateUserQList(userList);
			setChecked(!checked);
		}
		await setShowModal(!showPopup);
	}
	function onSubmit() {
		if (action == 'del') deleteUser();
		else if (action == 'upd') changeStatus();
		else if (action == 'bulkdel') deleteUserItem();
	}
	function deleteUser() {
		apiObj.url = `experion/venueuser/delete/${id}`;
		apiObj.data = { Id: [id] };
		apiObj.message = 'User deleted successfully';
		api.put(apiObj).then(function (d) {
			if (typeof d != 'undefined') {
				idList.splice(idList.indexOf(id), 1);
				updateIdList(idList);
				updateUserList((userList = userList.filter((x) => x.id !== id)));
				updateUserQList((userList = userList.filter((x) => x.id !== id)));
				props.getUserCount(0 - apiObj.data.Id.length);
			}
		});
		setShowModal(!showPopup);
	}

	function changeStatus() {
		apiObj.url = `experion/venueuser/updatestatus/${id}/${checked}`;
		apiObj.message = 'User status updated successfully';
		api.put(apiObj);
		setShowModal(!showPopup);
	}

	const navigateToEdit = (id) => {
		history.push({
			pathname: 'user',
			search: '?id=' + id,
		});
	};

	function confirm(action, id, e = null as any) {
		let checked = false;
		if (e != null) {
			checked = e.currentTarget.checked;
			userList[userList.findIndex((x) => x.id == id)].is_active = checked;
			updateUserList(userList);
			updateUserQList(userList);
		}
		switch (action) {
			case 'del':
				showConfirmationModal(action, 'Delete user?', id, checked);
				break;
			case 'bulkdel':
				if (idList?.length > 0) {
					showConfirmationModal(action, 'Delete users?', id, checked);
				}
				else {
					notify('Please select the user(s).').error();
				}
				break;
			case 'upd':
				showConfirmationModal(
					action,
					`${checked == true ? 'A' : 'Dea'}ctivate user?`,
					id,
					checked
				);
				break;
			default:
				break;
		}
	}

	function showConfirmationModal(action, text, id, checked) {
		if (action == 'upd') setChecked(checked);
		setAction(action);
		if (action != 'bulkdel') setId(id);
		setmodalText(text);
		setShowModal(!showPopup);
	}

	return (
		<UserListHolder>
			<PageSearchHolder>
				<Search onChange={(val) => setFilteredData(val)} type={'userList'}
					placeholder="Search for User Details ..."></Search>
				<Box>
					<Button flex={[1, 1, 'initial']} variant="redOutline"
						onClick={() => confirm('bulkdel', idList)}
						disabled={!enableButton}>
						<icons.DeleteIcon />
						<div>Delete Selected</div>
					</Button>
					<Button onClick={viewAllUsers} flex={[1, 1, 'initial']} variant="primary">
						<icons.viewIcon />

						<div>view All</div>
					</Button>
				</Box>
			</PageSearchHolder>
			<TableList>
				<table>
					<thead>
						<tr>
							{/* <th className="checked">
								<Label>
									<Checkbox id="remember" name="remember" />
								</Label>
							</th> */}
							<th className="checked"></th>
							<th>First Name</th>
							<th>Last Name</th>
							<th>Email</th>
							<th>Phone</th>
							{/* <th>Venue Name</th>
							<th>Location</th> */}
							<th className="status">Status</th>
							<th className="action">Action</th>
						</tr>
					</thead>

					<tbody>
						{/* Add (row-highlighted) class for row highlighting */}

						{userList &&
							userList.map(
								({
									first_name,
									last_name,
									venue_user_email,
									phone,
									id,
									is_active,
									isChecked,
								}) => (
									<tr key={id} className="">
										<td className="checked">
											<Label>
												<Checkbox
													id="remember"
													onChange={(event) => selectCheckBox(id, event)}
													checked={isChecked}
													name="remember"
												/>
											</Label>
										</td>
										<td data-label="First Name">
											<Text
												as="h1"
												onClick={() => onNameClick(id)}
												fontSize={15}
												fontFamily="OpenSansMedium"
												color="text.black"
											>
												<strong><span>{first_name}</span></strong>
											</Text>
										</td>
										<td data-label="Last Name">
											<Text
												as="h1"
												onClick={() => onNameClick(id)}
												fontSize={15}
												fontFamily="OpenSansMedium"
												color="text.black"
											>
												<strong><span>{last_name}</span></strong>
											</Text>
										</td>
										<td data-label="Email">
											<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
												{venue_user_email}
											</Text>
										</td>
										<td data-label="Phone">
											<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
												{phone}
											</Text>
										</td>
										{/* <td data-label="Venue Name">
											<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
												{venue_id}
											</Text>
										</td>
										<td data-label="Location">
											<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
												{id}
											</Text>
										</td> */}
										<td data-label="Status" className="status">
											<Toggle
												disabled={!id ? true : false}
												checked={is_active}
												onChange={(e) => confirm('upd', id, e)}
											/>
										</td>
										<td data-label="Action" className="action">
											<Text>
												<Button
													variant="deleteIcon"
													disabled={!id ? true : false}
													onClick={() => confirm('del', id)}
												>
													<icons.DeleteIcon />
												</Button>
												<Button onClick={() => navigateToEdit(id)} variant="editIcon">
													<icons.textEditIcon />
												</Button>
											</Text>
										</td>
									</tr>
								)
							)}
					</tbody>
				</table>
			</TableList>
			<SweetAlert
				show={showPopup}
				confirmBtnText="Confirm"
				onConfirm={onSubmit}
				showCancel={true}
				onCancel={closePopup}
				title={'Confirmation Box'}
			//  dependencies={[this.state.firstName, this.state.lastName]}
			>
				{() => (
					<form>
						{modalText}
					</form>
				)}
			</SweetAlert>
		</UserListHolder>
	);
}

export { UsersList };
