import { Input } from '@rebass/forms';
import React, { ReactElement, useState } from 'react';
import 'react-tippy/dist/tippy.css';
import { Box, Flex, Text } from 'rebass/styled-components';
import styled, { css } from 'styled-components';
import { Button } from 'ui/components';
import no_image from 'ui/images/no_image.png';
import notify from 'utils/toast.service';

const StyledCartListView = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	column-gap: 20px;
	row-gap: 20px;
	margin-bottom: 50px;
`;

const DeliveryOrderProblemListHolder = styled.div<DeliveryOrderProblemListHolderProps>`
	width: 100%;
	display: flex;
	flex-direction: row;
	background: ${({ theme }) => theme.colors.bg.white};
	border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
	box-sizing: border-box;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 16px;
	overflow: hidden;
	transition: box-shadow 100ms ease-in, transform 100ms ease-in;
	align-items: flex-start;
	max-height: 100%;
	@media (max-width: 768px) {
		flex-direction: column;
	}

	picture {
		max-width: 216px;
		flex: 0 0 216px;
		height: 100%;
		border-right: 1px solid ${({ theme }) => theme.colors.bg.lightYellow05};
		background-color: #faf4ea;
		div {
			position: relative;
			width: calc(100% - 10px);
			height: calc(100% - 10px);
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;
			margin: 5px;
		}
		@media (max-width: 768px) {
			max-width: 100%;
			flex: 0 0 100%;
			height: 200px;
			min-height: 200px;
			width: 100%;
			max-height: 200px;
		}
	}
	section {
		height: 100%;
		padding: 25px;
		position: relative;
		display: flex;
		flex-direction: row;
		@media (max-width: 768px) {
			padding: 20px;
		}
		@media (max-width: 480px) {
			padding: 15px!important;
		}
		section {
			padding: 20px 0 0 0;
			white-space: nowrap;
			align-items: center;
			flex-wrap: wrap;
			@media (max-width: 480px) {
				padding:5px 0 0 0!important;
			}
			input {
				width: 90px;
				height: 48px;
				border-radius: 6px;
				border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
				margin-left: 24px;
				padding: 14px;
				outline: none;
				font-family: 'OpenSansBold';
				@media (max-width: 580px) {
					margin-left: 0;
				}
				&:disabled {
					border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
					background: #edebe6;
				}
				&:required:invalid {
					border: 1px solid ${({ theme }) => theme.colors.border.error};
				}
				&:invalid {
					background-color: #ffdddd;
				}
				&.required {
					border: 1px solid ${({ theme }) => theme.colors.border.error};
					background-color: #ffdddd;
				}
			}
			button {
				height: 48px;
				margin-left: 16px;
				cursor: pointer;
			}
			h6 {
				@media (max-width: 580px) {
					width: 100%;
					margin-bottom: 0;
				}
			}
		}
	}
	slot {
		flex: 1;
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		justify-content: space-between;
		position: relative;
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-weight: normal;
			color: ${({ theme }) => theme.colors.text.black};
		}
		h1 {
			margin-bottom: 3px;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			margin-bottom: 3px;
		}
		div {
			margin-bottom: 3px;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			display: block;
			width: 100%;
			padding-right: 20%;
			@media (max-width: 640px) {
				padding-right: 35%;
			}
			@media (max-width: 480px) {
				padding-right: 35%;
			}
		}
		h2 {
			margin: 0 0 12px 0;
		}
		a {
			text-decoration: underline;
			cursor: pointer;
			&:hover {
				color: ${({ theme }) => theme.baseColors.primary};
			}
		}
		h3 {
			margin-top: 5px;
			strong {
				font-family: 'OpenSansBold';
			}
		}
		h4 {
			margin-top: 18px;
			strong {
				font-family: 'OpenSansBold';
			}
		}
		h5 {
			position: absolute;
			right: 0;
		}
		@media (max-width: 480px) {
			div {
				flex-direction: column;
			}
		}
		.mt20 {
			margin-top: 20px;
			text-transform: capitalize;
		}
	}
	${({ active }) =>
		!active &&
		css`
			cursor: default;
			&:hover {
				transform: none;
				background: ${({ theme }) => theme.colors.bg.white};
				border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
				box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
			}

			picture {
				opacity: 0.5;
				pointer-events: none;
			}
			h1,
			h2,
			h3,
			h4,
			h6 {
				opacity: 0.5;
				pointer-events: none;
			}
		`}
`;

type DeliveryOrderProblemListHolderProps = {
	active?: boolean;
};
// type DeliveryOrderProblemProps = {
// 	deliveryOrderProblemList: Array<list>;
// };
// type list = {
// 	id?: string | number;
// 	image?: string;
// 	brandName?: string;
// 	brandItem?: string;
// 	supplierName?: string;
// 	perPackPrice?: string;
// 	quantity?: string;
// 	price?: string;
// 	active?: boolean;
// };

function DeliveryOrderProblem(props): ReactElement {
	// const [quantity, setQuantity] = useState();
	const [isButtonClicked, setButtonClicked] = useState([] as any);
	const [updateData, setupdateData] = useState([] as any);
	const [disableSubmit, setdisableSubmit] = useState([] as any);
	// const setQuantityReceived = (event) => {
	// 	setQuantity(event.target.value);
	// }
	const onTodoChange = (event, item) => {
		const disableSub = disableSubmit.filter((data) => data !== item.item_id);
		if (item.qty_ordered < Number(event.target.value) || Number(event.target.value) < 0 || Math.floor(Number(event.target.value)) !== Number(event.target.value)) {
			disableSub.push(item.item_id);
		}
		const filteredArray = updateData.filter((data) => data.product_id !== item.product_id);
		item['textBoxValue'] = event.target.value;
		if (event.target.value !== "") {
			let dataObj = {
				product_id: item.product_id,
				ordered_qty: item.qty_ordered,
				delivered_qty: Number(event.target.value),
				comment: '',
			};
			filteredArray.push(dataObj);
		}
		setupdateData(filteredArray);
		setdisableSubmit(JSON.parse(JSON.stringify(disableSub)));
		props.setTextBoxValueFn(filteredArray, disableSub);
	};

	const updateFieldEdit = (data, e) => {
		e.stopPropagation();
		if (!isButtonClicked.includes(data.item_id)) {
			isButtonClicked.push(data.item_id);
			setButtonClicked(JSON.parse(JSON.stringify(isButtonClicked)));
		}
	};

	return (
		<StyledCartListView>
			{props.deliveryOrderProblemList &&
				props.deliveryOrderProblemList.items &&
				props.deliveryOrderProblemList.items.map((item,i) => (
					<DeliveryOrderProblemListHolder key={i} active={true}>
						<Flex alignItems="center" as="picture" maxWidth={['100%']} sx={{ overflow: 'hidden' }}>
							<Box
								style={{
									backgroundImage:
										'url(' +
										(item.extension_attributes.image_details &&
										item.extension_attributes.image_details !== ''
											? item.extension_attributes.image_details
											: no_image) +
										')',
								}}
							></Box>
						</Flex>
						<Flex as="section" flexDirection="column" width="100%" maxWidth={['100%']}>
							<Box as="slot">
								<Box>
									<Text as="h1" fontSize={20} fontFamily="OpenSansBold" color="text.black">
										{item.name}
									</Text>
									<Text as="h2" fontSize={17} color="text.black">
										{item.extension_attributes.product_catagory &&
											item.extension_attributes.product_catagory.length > 0 &&
											item.extension_attributes.product_catagory[0]}
									</Text>
								</Box>
								<Text as="h3" fontSize={20} color="text.black">
									<strong>${item.price}</strong>
								</Text>
								<Text as="h4" fontSize={20} color="text.black">
									Quantity Requested <strong>{item.qty_ordered}</strong>
								</Text>
								<Text as="h5" fontFamily="OpenSansBlack" fontSize={30} color="text.black">
									${item.row_total}
								</Text>
								<Button
									className="mt20"
									onClick={(e) => {
										updateFieldEdit(item, e);
									}}
								>
									Wrong Quantity Received?
								</Button>
								{isButtonClicked.includes(item.item_id) ? (
									<Flex as="section">
										<Text as="h6" fontSize={17} color="text.black">
											Quantity received
										</Text>
										<Input
											id="text"
											name="text"
											type="number"
											min="0"
											max={item.qty_ordered}
											value={item.textBoxValue || ''}
											onChange={(e) => onTodoChange(e, item)}
											placeholder=""
										/>
										{/* <Button variant="editBtn" onClick={() => { setEditClicked(true) }}>
										<Icon.textEditIcon />
									</Button> */}
									</Flex>
								) : (
									''
								)}
							</Box>
						</Flex>
					</DeliveryOrderProblemListHolder>
				))}
		</StyledCartListView>
	);
}

export { DeliveryOrderProblem };
