import { Checkbox, Label } from '@rebass/forms';
import { ExportToCsv } from 'export-to-csv';
import { condition } from 'models/enum-constants/common.constants';
import Iget from 'models/Iget';
import Ipost from 'models/Ipost';
import React, { ReactElement, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory } from 'react-router-dom';
import { Box, Text } from 'rebass/styled-components';
import {
	BreadcrumbNav,
	Button,
	Footer,
	Header,
	PageSearchHolder,
	Search,
	TableList,
	Toggle,
} from 'ui/components';
import icons from 'ui/icons';
import { Sidebar } from 'ui/patterns';
import urls from 'utils/create.url';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import notify from 'utils/toast.service';
import { BreadcrumbHolder, ButtonWrapper, MainContent, UserListHolder } from './styles';

function FullUsersList(): ReactElement {
	const breadcrumbNav = [
		{ id: '01', label: 'Home', route: '/home/dashboard', active: false },
		{ id: '02', label: 'Dashboard', route: '/admin/user-dashboard', active: false },
		{ id: '03', label: 'Users', active: true },
	];
	var [userList, updateUserList] = useState<any>([]);
	const userKey = 'userListKey';
	const [showPopup, setShowModal] = useState(false);
	const [checked, setChecked] = useState(false);
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const [modalText, setmodalText] = useState('');
	const [action, setAction] = useState('');
	const [id, setId] = useState(0);
	const history = useHistory();
	var [idList, updateIdList] = useState<any>([]);
	var [enableButton, updateEnableButton] = useState<any>(false);
	const url = urls.GetSearchUrl({
		url: 'experion/venueuser/search?',
		pageSize: 2000,
		fields: [
			{
				field_name: 'hg_id',
				field_value: localStorage.getItem('hospitalityId')!,
			},
			{
				field_name: 'magento_user_id',
				field_value: localStorage.getItem('hgAdminId')!,
				field_conditionType: condition.neq,
			},
		],
		filterDeleted: true,
	});
	const obj: Partial<Iget> = {
		params: {},
		url: url,
		enabled: true,
		key: userKey,
		resultFilteringRequired: true,
	};
	const obj1: Partial<Ipost> = {
		params: {},
		url: '',
	};

	// const tableData = React.useMemo(
	// 	() => [
	// 		{
	// 			col1: 'Albert',
	// 			col2: 'king.lawson@example.com',
	// 			col3: '(784) 555-0103',
	// 			col4: 'North Warren',
	// 			col5: 'Sun Falls, Maryland',
	// 			col6: 'toggle',
	// 			col7: 'action',
	// 		},
	// 		{
	// 			col1: 'James',
	// 			col2: 'kenzi.lawson@example.com',
	// 			col3: '(480) 555-0103',
	// 			col4: 'Wade Warren',
	// 			col5: 'Great Falls, Maryland',
	// 			col6: 'toggle',
	// 			col7: 'action',
	// 		},
	// 	],
	// 	[]
	// );
	const exportOptions = {
		filename: 'export',
		fieldSeparator: ',',
		quoteStrings: '"',
		decimalSeparator: '.',
		showLabels: true,
		showTitle: false,
		title: 'Report',
		useTextFile: false,
		useBom: true,
		useKeysAsHeaders: true,
		// headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
	};

	const csvExporter = new ExportToCsv(exportOptions);
	const onExport = () => {
		let users = [] as any;
		userList?.forEach((data) => {
			users.push({
				First_Name: data.first_name,
				Last_Name: data.last_name,
				Email: data.venue_user_email,
				Phone: data.phone,
				Status: data.is_active == true ? 'Active' : 'Disabled',
			});
		});
		csvExporter.generateCsv(users);
	};

	var qry = hooks.useQuery(obj);

	const routeAdd = () => {
		history.push('/admin/user');
	};

	// if (searchValue == '') {
	// 	userList = [];
	// }

	useEffect(() => {
		qry.data && updateUserList([...qry.data]);
	}, [qry.data]);

	// userList =
	// 	(userList?.length !== 0 && typeof userList !== 'undefined') || searchValue != ''
	// 		? userList
	// 		: qry.data;

	async function closePopup() {
		if (action == 'upd') {
			userList[userList.findIndex((x) => x.id == id)].is_active = !checked;
			updateUserList(userList);
			setChecked(!checked);
		}
		await setShowModal(!showPopup);
	}
	function onSubmit() {
		if (action == 'del' || action == 'bulkdel') deleteUserItem();
		else if (action == 'upd') changeStatus();
	}

	function changeStatus() {
		obj1.url = `experion/venueuser/updatestatus/${id}/${checked}`;
		obj1.message = 'User status updated successfully';
		api.put(obj1);
		setShowModal(!showPopup);
	}

	function confirm(action, id, e = null as any) {
		let checked = false;
		if (e != null) {
			checked = e.currentTarget.checked;
			userList[userList.findIndex((x) => x.id == id)].is_active = checked;
			updateUserList(userList);
		}
		switch (action) {
			case 'del':
				showConfirmationModal(action, 'Delete User?', id, checked);
				break;
			case 'bulkdel':
				if (idList.length > 0) {
					showConfirmationModal(action, 'Delete users?', id, checked);
				}
				else {
					notify('Please select the user(s).').error();
				}
				break;
				break;
			case 'upd':
				showConfirmationModal(action, `${checked == true ? 'A' : 'Dea'}ctivate User?`, id, checked);
				break;
			default:
				break;
		}
	}

	function showConfirmationModal(action, text, id, checked) {
		if (action == 'upd') setChecked(checked);
		setAction(action);
		if (action != 'bulkdel') setId(id);
		setmodalText(text);
		setShowModal(!showPopup);
	}

	// event to get data from search ( child ) component
	const handleSearchFill = (data) => {
		updateUserList(data);
	};

	//on grid row name click
	const onNameClick = (id) => {
		history.push({
			pathname: 'user',
			search: '?id=' + id,
		});
	};

	const selectAllCheckbox = (event) => {
		idList = [];
		updateIdList(idList);
		if (event.target.checked) {
			updateEnableButton(true);
			userList.map((data) => {
				idList.push(data.id);
			});
			updateIdList(idList);
		} else {
			idList = [];
			updateIdList(idList);
			updateEnableButton(false);
		}
		let checked = event.target.checked;
		userList = userList.map((item) => ({ ...item, isChecked: checked }));
		updateUserList(userList);
	};

	const navigateToEdit = (id) => {
		history.push({
			pathname: 'user',
			search: '?id=' + id,
		});
	};

	const setFilteredData = (val) => {
		const sampleList = qry.data;
		if (val && val.length > 0) {
			var userList = sampleList.filter(
				(v) =>
					v.first_name.toLowerCase().includes(val.toLowerCase()) ||
					//(v.description && v.description.toLowerCase().includes(val.toLowerCase())) ||
					v.last_name.toLowerCase().includes(val.toLowerCase()) ||
					v.venue_user_email.toLowerCase().includes(val.toLowerCase()) ||
					v.phone.toString().includes(val)
			);
			updateUserList(userList);
		} else {
			updateUserList(qry.data);
		}
	};

	const selectCheckBox = (id, event) => {
		if (event.target.checked) {
			idList.push(id);
			updateIdList(idList);
		} else {
			idList.splice(idList.indexOf(id), 1);
			updateIdList(idList);
		}
		if (idList.length >= 1) {
			updateEnableButton(true);
		} else {
			updateEnableButton(false);
		}
	};

	function deleteUserItem() {
		obj1.url = `experion/venueuser/bulkdelete`;
		obj1.data = { id: typeof idList != 'undefined' && idList.length > 0 ? idList : [id] };
		obj1.message = `User${
			typeof idList != 'undefined' && idList.length > 1 ? '(s)' : ''
		} deleted successfully`;
		api.post(obj1).then(function (d) {
			if (typeof d != 'undefined') {
				let datalist = [];
				if (typeof idList != 'undefined' && idList.length > 0) {
					datalist = userList.filter((x) => !idList.includes(x.id));
					updateUserList(datalist);
				} else {
					datalist = userList.filter((x) => x.id !== id);
					updateUserList(datalist);
				}
			}
			updateIdList([]);
			setId(0);
		});
		setShowModal(!showPopup);
	}
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	};
	window.scroll({ top: 0, left: 0, behavior: 'smooth' });
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={() => onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={() => onclickHambrgBtn()}></Sidebar>

			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbHolder>
					<BreadcrumbNav
						Heading="Users"
						breadcrumbList={breadcrumbNav}
						RouteLink="user-dashboard"
					/>
					<ButtonWrapper>
						<Button onClick={() => routeAdd()} flex={[1, 1, 'initial']}>
						<icons.PlusIcon />
							Add New User
						</Button>

						<Button onClick={() => onExport()} flex={[1, 1, 'initial']}>
							<icons.exportIcon />
							<div>Export</div>
						</Button>
						{/* <Button flex={[1, 1, 'initial']} variant="secondary">
							<icons.downloadIcon />
							<div>Download</div>
						</Button> */}
					</ButtonWrapper>
				</BreadcrumbHolder>
				{/* <UsersFullList columns={tableColumns} data={tableData}></UsersFullList> */}
				<UserListHolder>
					<PageSearchHolder>
						<Box as="section">
							<Search
								onChange={(val) => setFilteredData(val)}
								type={'usersList'}
								placeholder="Search for User Details ..."
							></Search>

							{/* <Search
								searchValue={setSearchValue}
								userList={qry.data}
								searchList={handleSearchFill}
							></Search> */}
						</Box>
						<Box as="small">
							<Button
								flex={[1, 1, 'initial']}
								variant="redOutline"
								onClick={() => confirm('bulkdel', idList)}
								disabled={!enableButton}
							>
								<icons.DeleteIcon />
								<div>Delete Selected</div>
							</Button>
						</Box>
					</PageSearchHolder>
					<TableList>
						<table>
							<thead>
								<tr>
									<th className="checked">
										{/* <Label>
											<Checkbox id="remember" name="remember" />
										</Label> */}
									</th>
									<th>First Name</th>
									<th>Last Name</th>
									<th>Email</th>
									<th>Phone</th>
									{/* <th>Venue Name</th>
									<th>Location</th> */}
									<th className="status">Status</th>
									<th className="action">Action</th>
								</tr>
							</thead>

							<tbody>
								{/* Add (row-highlighted) class for row highlighting */}

								{userList &&
									userList.map(
										({
											first_name,
											last_name,
											venue_user_email,
											phone,
											id,
											is_active,
											role_id,
											venue_id,
											number_of_parked_orders,
											isChecked,
										}) => (
											<tr key={id} className={!is_active ? 'row-highlighted' : ''}>
												<td className="checked">
													<Label>
														<Checkbox
															id="remember"
															onChange={(event) => selectCheckBox(id, event)}
															checked={isChecked}
															name="remember"
														/>
													</Label>
												</td>
												<td data-label="User Name">
													<Text
														as="h1"
														onClick={() => onNameClick(id)}
														fontSize={15}
														fontFamily="OpenSansMedium"
														color="text.black"
													>
														<strong>
															<span>{first_name}</span>
														</strong>
													</Text>
												</td>
												<td data-label="User Name">
													<Text
														as="h1"
														onClick={() => onNameClick(id)}
														fontSize={15}
														fontFamily="OpenSansMedium"
														color="text.black"
													>
														<strong>
															<span>{last_name}</span>
														</strong>
													</Text>
												</td>
												<td data-label="Email">
													<Text
														as="h1"
														fontSize={15}
														fontFamily="OpenSansMedium"
														color="text.black"
													>
														{venue_user_email}
													</Text>
												</td>
												<td data-label="Phone">
													<Text
														as="h1"
														fontSize={15}
														fontFamily="OpenSansMedium"
														color="text.black"
													>
														{phone}
													</Text>
												</td>
												{/* <td data-label="Venue Name">
													<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
														{venue_id}
													</Text>
												</td>
												<td data-label="Location">
													<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
														{id}
													</Text>
												</td> */}
												<td data-label="Status" className="status">
													<Toggle
														disabled={!id ? true : false}
														checked={is_active}
														onChange={(e) => confirm('upd', id, e)}
													/>
												</td>
												<td data-label="Action" className="action">
													<Text>
														<Button
															variant="deleteIcon"
															disabled={!id ? true : false}
															onClick={() => confirm('del', id)}
														>
															<icons.DeleteIcon />
														</Button>
														<Button onClick={() => navigateToEdit(id)} variant="editIcon">
															<icons.textEditIcon />
														</Button>
													</Text>
												</td>
											</tr>
										)
									)}
							</tbody>
						</table>
					</TableList>
					<SweetAlert
						show={showPopup}
						confirmBtnText="Confirm"
						onConfirm={onSubmit}
						showCancel={true}
						onCancel={closePopup}
						title={'Confirmation Box'}
						//  dependencies={[this.state.firstName, this.state.lastName]}
					>
						{() => <form>{modalText}</form>}
					</SweetAlert>
				</UserListHolder>
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default FullUsersList;
