import { Select } from '@rebass/forms';
import { userTypes } from 'models/enum-constants/common.constants';
import Iget from 'models/Iget';
import Ipost from 'models/Ipost';
import React, { ReactElement, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Box, Text } from 'rebass/styled-components';
import { BreadcrumbNav, Button, Footer, Grid, Header, Loader, Stack } from 'ui/components';
import * as Icon from 'ui/icons';
import { FormInput, Modal, Sidebar } from 'ui/patterns';
import constants from 'utils/constants';
import helperFunctions from 'utils/helper.functions';
import pageConstants from 'utils/pageConstants';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import ChangePasswordModal from './changePassword';
import {
	AddUserHolder,

	BreadcrumbHolder, FooterBtnBlock, MainContent,


	ProfileDetails
} from './styles';



function UserProfileDetails(): ReactElement {
	var userId = localStorage.getItem('userId');
	var adminId = localStorage.getItem('hgAdminId');
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const apiKey = 'userProfileDet';
	const [disableBtnSave, setDisableBtnSave] = useState(true);
	const [modalText, setmodalText] = useState('');
	const [showPopup, setShowModal] = useState(false);
	const [action, setAction] = useState('');
	const [mutatePost, mutationStates] = hooks.useMutation(apiKey);

	const [userDetails, updateUserDetails] = useState({
		id: userId,
		first_name: '',
		last_name: '',
		venue_user_email: '',
		phone: '',
		gender: '',
	});

	const [adminDetails, updateAdminDetails] = useState({
		id: adminId,
		firstname: '',
		lastname: '',
		email: '',
		phone: '',
		gender: '',
	});

	const [error, setError] = useState({
		firstNameError: '',
		lastNameError: '',
		emailAddressError: '',
		phoneNumberError: '',
		// genderError: '',
	});

	// var is_password_set = localStorage.getItem('is_password_set');
	const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
	const toggleChangePasswordModal = () => {
		setShowChangePasswordModal(!showChangePasswordModal);
	};

	useEffect(() => {
		// if(is_password_set == "0"){
		// 	setShowChangePasswordModal(true);
		// }
	}, []);

	const breadcrumbNav = [
		{ id: '01', label: 'Home', route: '/home/dashboard', active: false },
		{ id: '02', label: 'User Profile Details', active: true },
	];

	const onChangeValue = (val, name) => {
		const item = userDetails;
		switch (name) {
			case 'first_name': {
				if (!val) {
					error.firstNameError = 'First Name cannot be blank';
				} else {
					error.firstNameError = '';
				}
				setError(error);
				item.first_name = val;
				break;
			}
			case 'last_name': {
				if (!val) {
					error.lastNameError = 'Last Name cannot be blank';
				} else {
					error.lastNameError = '';
				}
				setError(error);
				item.last_name = val;
				break;
			}
			case 'email': {
				if (!val) {
					setError({ ...error, emailAddressError: 'Email Address cannot be blank' });
				} else {
					const validEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(val);
					if (validEmail) {
						error.emailAddressError = '';
					} else {
						error.emailAddressError = 'Please enter Valid Email Address';
					}
				}
				setError(error);
				item.venue_user_email = val;
				break;
			}
			case 'phone': {
				if (!val) {
					error.phoneNumberError = 'Phone cannot be blank';
				} else {
					const regex = pageConstants.phoneRegex;
					if (!regex.test(val)) {
						error.phoneNumberError = 'Invalid Phone';
					} else {
						error.phoneNumberError = '';
					}
				}
				setError(error);
				item.phone = (val);
				break;
			}
			// case 'gender':
			// 	if (val.target.value == '-Select-') {
			// 		error.genderError = 'Gender cannot be blank';
			// 	} else {
			// 		error.genderError = '';
			// 	}
			// 	setError(error);
			// 	item.gender = val.target.value;
			// 	break;
			default:
				break;
		}
		updateUserDetails({
			...item,
		});
		if (
			userDetails &&
			userDetails.first_name &&
			userDetails.last_name &&
			// userDetails.venue_user_email &&
			// userDetails.phone &&
			// userDetails.gender != '-Select-' &&
			error &&
			!error.firstNameError &&
			!error.lastNameError
			// !error.emailAddressError &&
			// !error.phoneNumberError &&
			// !error.genderError
		) {
			setDisableBtnSave(false);
		} else {
			setDisableBtnSave(true);
		}
	};

	const params = {
		pages: '1',
	};

	const dataObj: Partial<Iget> = {
		params: params,
		url: (localStorage.getItem('userType') == userTypes.venueAdmin ? constants.baseURL() + `customers/${adminId}` : `experion/venueuser/get/${userId}`),
		enabled: true,
		key: apiKey,
	};

	var qryData = hooks.useQuery(dataObj);

	useEffect(() => {
		if (localStorage.getItem('userType') == userTypes.venueAdmin) {
			qryData.data && updateAdminDetails(qryData.data);
			if (qryData.data) {
				var mobile_no = "";
				qryData.data && qryData.data.custom_attributes.map((x) => {
					if (x.attribute_code == "mobile_number") {
						mobile_no = helperFunctions.formatPhone(x.value);
					}
				});

				updateUserDetails({
					id: adminId,
					first_name: qryData.data.firstname,
					last_name: qryData.data.lastname,
					venue_user_email: qryData.data.email,
					phone: mobile_no,
					gender: qryData.data.gender
				})
			}
		} else {
			qryData.data && updateUserDetails(qryData.data);
		}
	}, [qryData.data]);

	const resetData = () => {
		qryData.refetch().then(function (data) {
			if (data) {
				updateUserDetails(data.data);
			}
		});
	};

	const submitData = (action) => {
		switch (action) {
			case 'save':
				showConfirmationModal(action, 'Save the changes?');
				break;
			case 'clear':
				if (
					userDetails.first_name != '' ||
					userDetails.last_name != '' ||
					userDetails.venue_user_email != '' ||
					userDetails.phone !== '' ||
					userDetails.gender != ('-Select-' && 'select')
				) {
					showConfirmationModal(action, 'Discard the changes?');
				}
				break;
			default:
				break;
		}
	};

	function showConfirmationModal(action, text) {
		setAction(action);
		setmodalText(text);
		setShowModal(!showPopup);
	}

	async function onSubmit() {
		if (action === 'save') {
			saveData();
		} else if (action === 'clear') {
			clearDetails('clear');
		}
		setShowModal(!showPopup);
	}

	async function closePopup() {
		await setShowModal(!showPopup);
	}

	async function saveData() {
		if (userDetails) {
			if (localStorage.getItem('userType') == userTypes.venueAdmin) {
				var adminPayload = {
					firstname: userDetails.first_name,
					lastname: userDetails.last_name,
					email: userDetails.venue_user_email,
					gender: userDetails.gender,
					// custom_attributes: [
					// 	{
					// 		attribute_code: 'mobile_number',
					// 		value: helperFunctions.formatPhone(userDetails.phone)
					// 	},
					// ]
				};
				const adminObj: Partial<Ipost> = {
					data: { customer: adminPayload },
					params: {},
					url: constants.baseURL() + `customers/${adminId}`,
					message: 'Profile updated successfully',
				};
				try {
					api.put(adminObj).then((data) => {
					});
				} catch (e) { }
				setShowModal(!showPopup);
			} else {
				var payload = {
					first_name: userDetails.first_name,
					last_name: userDetails.last_name,
					// venue_user_email: userDetails.venue_user_email,
					// phone: helperFunctions.formatPhone(userDetails.phone),
					gender: userDetails.gender,
				};
				const obj: Partial<Ipost> = {
					data: { venueUserEditProfile: payload },
					params: {},
					url: `experion/venueusereditprofile/update/${userId}`,
					message: 'Profile updated successfully',
				};
				try {
					api.put(obj).then((data) => {
					});
				} catch (e) { }
				setShowModal(!showPopup);
			}
		}
	}

	const clearDetails = (param) => {
		if (param === 'clear') {
			updateUserDetails({
				id: userId,
				first_name: '',
				last_name: '',
				venue_user_email: '',
				phone: '',
				gender: 'select',
			});
			setError({
				firstNameError: '',
				lastNameError: '',
				emailAddressError: '',
				phoneNumberError: ''
			});
		}
	};
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	}
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={() => onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={() => onclickHambrgBtn()}></Sidebar>
			{qryData.isLoading ? <Loader></Loader> : null}
			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbHolder>
					<BreadcrumbNav Heading="User Profile Details" breadcrumbList={breadcrumbNav} />
				</BreadcrumbHolder>
				{/* Add User Details */}

				<AddUserHolder>
					<ProfileDetails style={{ marginBottom: '0px' }}>
						<Text as="h1" fontSize={18} fontFamily="OpenSansBold" color="text.SubHeading">
							User Profile Details
						</Text>
						<Box as="form">
							<Grid gap={25}>
								<FormInput
									autoComplete="off"
									label="First Name"
									validationMessage={error.firstNameError}
									validation={error.firstNameError ? 'error' : undefined}
									value={userDetails && userDetails.first_name}
									required={true}
									onTextChange={(e) => onChangeValue(e, 'first_name')}
									maxLength={50}
									type="text"
								/>

								<FormInput
									autoComplete="off"
									label="Last Name"
									validationMessage={error.lastNameError}
									validation={error.lastNameError ? 'error' : undefined}
									value={userDetails && userDetails.last_name}
									required={true}
									onTextChange={(e) => onChangeValue(e, 'last_name')}
									maxLength={50}
									type="text"
								/>

								<FormInput
									label="Email"
									autoComplete="off" disabled={true}
									validationMessage={error.emailAddressError}
									validation={error.emailAddressError ? 'error' : undefined}
									value={userDetails && userDetails.venue_user_email}
									required={true}
									name="email"
									onTextChange={(e) => onChangeValue(e, 'email')}
									maxLength={50}
									type="text"
								/>
								<FormInput
									label="Phone Number"
									autoComplete="off" disabled={true}
									validationMessage={error.phoneNumberError}
									validation={error.phoneNumberError ? 'error' : undefined}
									value={userDetails && (userDetails.phone ? userDetails.phone.toString() : '')}
									required={true}
									name="phone"
									onTextChange={(e) => onChangeValue(e, 'phone')}
									maxLength={12}
									type="tel"
								/>

								{/* <FormInput label="Gender" /> */}
								{/* <Grid gap={1}>
									<Stack
										categorySelect
										gap={0}
										validation={error.genderError ? 'error' : undefined}
									>
										<div>
											<label>Gender</label>
											<Select
												id="genderselected"
												name="genderselected"
												onChange={(e) => onChangeValue(e, 'gender')}
												value={userDetails && userDetails.gender}
											>
												<option>-Select-</option>
												<option value="1" key="Male">
													Male
												</option>
												<option value="2" key="Female">
													Female
												</option>
												<option value="0" key="Other">
													Other
												</option>
											</Select>
											<Icon.downArrow />
										</div>
										<span style={{ fontSize: 12, color: 'red' }}>{error.genderError}</span>
									</Stack>
								</Grid> */}
							</Grid>
						</Box>
					</ProfileDetails>

					{/* Footer Btn Block */}
					<FooterBtnBlock>
						<Stack direction="x" gap={3} justifyContent={['center', 'flex-end']}>
							<Button onClick={toggleChangePasswordModal} variant="secondary">
								Change password
							</Button>
							{/* <Button onClick={() => resetData()} flex={[1, 1, 'initial']} variant="secondary">
								Cancel
							</Button> */}
							<Button
								disabled={disableBtnSave}
								onClick={() => submitData('save')}
								flex={[1, 1, 'initial']}
							>
								Save
							</Button>
						</Stack>
					</FooterBtnBlock>
				</AddUserHolder>
				<SweetAlert
					show={showPopup}
					confirmBtnText="Confirm"
					onConfirm={onSubmit}
					showCancel={true}
					onCancel={closePopup}
					title={'Confirmation Box'}
				>
					{() => <form>{modalText}</form>}
				</SweetAlert>
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
			<Modal
				width="450px"
				showModal={showChangePasswordModal}
				setShowModal={setShowChangePasswordModal}
			>
				<ChangePasswordModal cancel={() => { toggleChangePasswordModal() }} />
			</Modal>
		</>
	);
}

export default UserProfileDetails;
