import styled, { css } from 'styled-components';

interface StyledMainContentProps {
	contentCentered?: boolean;
	contentStacked?: boolean;
	flexDirection?: string;
	spacing?: boolean;
}

const MainContent = styled.main<StyledMainContentProps>`
	flex: 1 0 auto;
	display: flex;

	${(props) =>
		props.contentCentered &&
		css`
			justify-content: center;
			align-items: center;
		`};

	${({ flexDirection }) =>
		flexDirection === 'row' &&
		css`
			flex-direction: row;
		`};

	${({ flexDirection }) =>
		flexDirection === 'column' &&
		css`
			flex-direction: column;
		`};
	${(props) =>
		props.spacing &&
		css`
			padding: 50px;
		`};
`;

export { MainContent };
