import styled from 'styled-components';
const StyledOrderListView = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	column-gap: 20px;
	row-gap: 20px;
	margin-bottom: 16px;
	.order-details__wrap {
		flex-direction: row;
		background: ${({ theme }) => theme.colors.bg.white};
		border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
		box-sizing: border-box;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 16px;
		overflow: hidden;
		transition: box-shadow 100ms ease-in, transform 100ms ease-in;
		align-items: flex-start;
		cursor: pointer;
		height: 100%;
		&:hover {
			transform: translateY(-5px);
			box-shadow: 0 20px 20px #eee;
			border: 1px solid ${({ theme }) => theme.baseColors.baseYellowLight};
		}
		span {
			max-width: 117px;
			flex: 0 0 117px;
			height: 140px;
			background-repeat: no-repeat;
			background-size: contain;
			@media (max-width: 1152px) {
				height: 242px;
			}
			@media (max-width: 768px) {
				max-width: 126px;
				flex: 0 0 126px;
				height: 100%;
				background-position: center center;
			}
			@media (max-width: 360px) {
				max-width: 80px;
				flex: 0 0 80px;
			}
		}
		section {
			display: grid;
			grid-template-columns: repeat(4, 1fr) !important;
			align-items: center;
			height: 100%;
			padding: 0px 25px;
			@media (max-width: 1152px) {
				padding: 20px;
				grid-template-columns: repeat(3, 1fr) !important;
			}
			@media (max-width: 768px) {
				padding: 20px;
				grid-template-columns: repeat(1, 1fr) !important;
				label {
					width: 100%;
					float: left;
					margin: -6px 0 10px 0;
					line-height: normal;
				}
			}
			@media (max-width: 480px) {
				padding: 15px;
			}
			.btn-block {
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				justify-content: flex-end;
				@media (max-width: 767px) {
					width: 100%;
					align-items: flex-start;
					justify-content: flex-start;
				}
				span {
					height: auto;
					flex: 0 0 auto;
					max-width: auto;
					min-width: auto;
				}

				slot {
					word-break: break-word;
					justify-content: flex-end;
					align-items: center;
					padding-bottom: 14px;
					button {
						min-width: auto;
						border: none;
						background: transparent;
						padding: 0;
						outline: none;
						box-shadow: none;
						svg {
							margin-left: 12px;
							path {
								fill: ${({ theme }) => theme.baseColors.primary};
							}
						}
					}
					@media (max-width: 768px) {
						justify-content: flex-start;
						button {
							order: -1;
							margin-right: 14px;
							svg {
								margin-left: 0;
							}
						}
						h1,
						h2 {
							text-align: left;
						}
						h1 {
							margin: 0px 0 0 0;
						}
					}

					h1 {
						margin: -5px 0 0 0;
						white-space: nowrap;
					}
				}
				button {
					text-transform: capitalize;
					height: 48px;
					white-space: nowrap;
				}
			}
			object {
				justify-self: center;
				@media (max-width: 768px) {
					justify-self: start;
				}
			}
			main {
				@media (max-width: 1152px) {
					grid-column: 5 / 1;
					grid-row: auto / auto;
				}
				@media (max-width: 768px) {
					grid-column: 1 / 1;
					grid-row: auto / auto;
					div {
						justify-self: start;
					}
				}
			}
		}
		&.selected {
			background: #f0e9da;
		}
	}
`;

const RatingHolder = styled.div`
	display: flex;
`;
export { StyledOrderListView, RatingHolder };
