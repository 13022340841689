import ValidationJson from 'admin/productManagement/bulkUpload/validation.data';
import { queryCache } from 'react-query';
import notify from 'utils/toast.service';
import * as XLSX from 'xlsx';
var errorObj = [] as any;
let fileName = '';
  // handle file upload
   function handleFileUpload (e,callback) {
     errorObj = [];
    const file = e.target.files[0];
    fileName = file.name;
    e.target.value = ''
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt!.target!.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws);
      let list = processData(data);
     callback(errorObj,list,fileName);// call back to function with errors and data
    };
    reader.readAsBinaryString(file);
  }

  
 // process CSV data
 const processData = dataString => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
    
    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
      if (headers && row.length == headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] == '"')
              d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"')
              d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }
        // remove the blank rows
        if (Object.values(obj).filter(x => x).length > 0) {
          list.push(obj as never);
        }
      }
    }
    let isEqual = arraysEqual(headers);
    if(isEqual)
       validateData(list);
    else
    notify(`Unexpected File Headers. 
    Please revert to template file headers!`).error();
    return isEqual ? list : [];
  }

  function arraysEqual(headers) {
    let  headersToCompare = ValidationJson.header;
    if (headers === headersToCompare) return true;
    if (headers == null || headersToCompare == null) return false;
    if (headers.length !== headersToCompare.length) return false;
    for (var i = 0; i < headers.length; ++i) {
      if (headers[i] !== headersToCompare[i]) return false;
    }
    return true;
  }
    
  function validateData(list){
    for (var i = 0; i < list.length; i++) {  
        isValidData(list[i]);
    }   
  }
  
  function isValidData(data) {
    var obj = [] as any;
    ValidationJson.MandatoryFields.forEach(function (item) {
        if (!data[item!]) {
            obj.push(`${item?.replace('_',' ')} is required`);
        }
    });

    ValidationJson.MaxLengthFields.forEach(function (item) {
        var msg = '';
        var size = 0;
        if (data.hasOwnProperty(item)) {
            size = (item == 'UOM')  ? 10 : 50;
            msg = lengthCheck(data[item], item, size);
            if (msg)
                obj.push(msg);
        }
    });

    ValidationJson.DropDownItems.forEach(function (item) {
      switch(item)
      {
        case 'Product_Category' :
          if (data.hasOwnProperty(item) && data[item] != '') {
              let category= getCategory(data[item]);
              if(category?.length > 0)
              data[item] = category;
              else
              obj.push(`${item?.replace('_',' ')} is invalid`);
          }
          break;
          case 'UOM' :  if (data.hasOwnProperty(item) && data[item] != '') {
            let uom= getUOM(data[item]);
            if(uom > 0)
            data[item] = uom;
            else
            obj.push(`${item?.replace('_',' ')} is invalid`);
        }
      }

    });

    ValidationJson.ValidText.forEach(function (item) {
        if (data.hasOwnProperty(item)) {
            var msg = validateFields(data[item], item, 'validText');
            if (msg)
                obj.push(msg);
        }

    });

    ValidationJson.NumberOnly.forEach(function (item) {
        if (data.hasOwnProperty(item)) {
            var msg = validateFields(data[item], item, 'numberOnly');
            if (msg)
                obj.push(msg);
        }
    });

    if(obj.length > 0)
    errorObj.push({
        name:  data['Product_Name'] == '' ?
         `Unknown Product`: data['Product_Name'],
        errors: obj
    });
}

function lengthCheck(val, header, size) {
    return (val != null && val.length > size) ? header?.replace('_',' ') + ' length max. ' + size + ' char' : '';
}

function validateFields(val, header, type) {
    var msg = '';
    if (!checker(val, header, type))        
        msg = header?.replace('_',' ') + ' is not valid';
    return msg;
}

function checker(val, header, type) {
    let format = '' as any;
    let isvalid = true;
    switch (type) {
        case 'validText':
            format = /[!@#$%^&*()+\=\[\]{};':"\\|,<>\/?]+/;
            if (regex(format, val))
                isvalid = false;
            break;
        case 'numberOnly':
            format = /^[0-9.$,]+$/;
            if (!regex(format, val))
                isvalid = false;
            break;
        default: break;
    }
    return isvalid;
}

function regex(format, val) {
    return format.test(val) ? true : false;
}

function getCategory(data){
    let categoryData = '';
    let categories = queryCache.getQueryData('catKey') as any;
    if(typeof  categories != 'undefined'){
     let  list = [] as any;
    list = data.split(',');
    list.map((v)=>{
        let obj = categories.find(x=> x.name.toLowerCase().trim() == v.toLowerCase().trim());
        if(typeof obj != 'undefined')
        categoryData += `${obj.id},`;
    })
    }
   return categoryData.replace(/,\s*$/, "");
}

function getUOM(data){
  let uomData = queryCache.getQueryData('uomData') as any;
  if(typeof  uomData != 'undefined'){
      let obj = uomData.options?.find(x=> x.label.toLowerCase().trim() ==
       data.toLowerCase().trim());
      return typeof obj != 'undefined' ? obj.value : 0;
  }
}

  export default handleFileUpload;
