import styled, { css } from 'styled-components';
import { layout, LayoutProps, space, SpaceProps } from 'styled-system';

type DividerProps = LayoutProps &
	SpaceProps & {
		direction: 'x' | 'y';
		thickness?: string | '1px';
	};

const Divider = styled.div<DividerProps>`
	${layout}
	${space}
    background: ${({ theme }) => theme.colors.border.default};
	${({ direction, thickness }) =>
		direction === 'x' &&
		css`
			width: 100%;
			height: ${thickness || '1px'};
		`}

	${({ direction, thickness }) =>
		direction === 'y' &&
		css`
			height: auto;
			min-height: 2.5em;
			width: ${thickness || '1px'};
		`}
`;

export { Divider };
