/*This file is to handle common utility  functions*/
import {
	condition,
	dcUserPermissionList,
	OrderStatuses,
	permissionGroup,
	types,
	userTypes,
	venueUserPermissionList,
} from 'models/enum-constants/common.constants';
import Iget from 'models/Iget';
import Ipost from 'models/Ipost';
import urlObj from 'models/url.obj';
import moment from 'moment';
import urls from 'utils/create.url';
import api from 'utils/requestCtx/api.service';
import constants from './constants';
/*permissions */
function isGranted(id) {
	if (!isAdmin()) {
		let permissions = JSON.parse(localStorage.getItem('permissions')!);
		if (typeof permissions != 'undefined' && permissions != null)
			return permissions?.find((x) => x.id == id)?.is_granted;
		else return false;
	} else return true;
}

function isAdmin() {
	let userType = localStorage.getItem('userType');
	return userType == userTypes.supplierAdmin || userType == userTypes.venueAdmin ? true : false;
}

///to check any of group permission is true
function _isAnyGranted(idList) {
	let permissions = JSON.parse(localStorage.getItem('permissions')!);
	if (typeof permissions != 'undefined' && permissions != null) {
		let isGranted = false as any;
		let permissionList = permissions?.filter((x) => idList.includes(x.id));
		if (permissionList) {
			permissionList.forEach((v) => {
				if (v.is_granted == true) {
					isGranted = true;
					return;
				}
			});
		}
		return isGranted;
	}
}

async function setPermissions(id, isVenueUser = true) {
	//by default venue user is true, for
	// dc user then pass isVenueUser as false
	let roleId = 0;
	if (isVenueUser) {
		await getVenueUserInfo().then(function (d) {
			roleId = d?.venue_user_role_mapping?.find((x) => x.venue_id == id)?.role_id;
		});
		if (roleId > 0) {
			await getVenueUserRoleInfo(roleId).then(function (data) {
				if (data) {
					let permissions = data.venue_role_permission_mapping;
					localStorage.setItem('permissions', JSON.stringify(permissions));
				}
			});
		}
	} else {
		await getSupplierUserInfo().then(function (d) {
			roleId = d?.dc_userrole_mapping?.find((x) => x.dc_id == id)?.role_id;
		});
		if (roleId > 0) {
			await getSupplierUserRoleInfo(roleId).then(function (data) {
				if (data) {
					let permissions = data.dc_role_permission_mapping;
					localStorage.setItem('permissions', JSON.stringify(permissions));
				}
			});
		}
	}
}

async function getVenueUserInfo() {
	return await api.get({ url: `experion/venueuser/get/${localStorage.getItem('userId')}` });
}

async function getVenueUserRoleInfo(roleId) {
	return await api.get({ url: `experion/venue/getVenueUserrole/${roleId}` });
}

async function getSupplierUserInfo() {
	return await api.get({
		url: `experion/supplycompanyaccount/get/${localStorage.getItem('userId')}`,
	});
}

async function getSupplierUserRoleInfo(roleId) {
	return await api.get({ url: `experion/supplycompanyaccount/getdcUserrole/${roleId}` });
}

function isAnyGranted(type) {
	let _isGranted = false;
	if (!isAdmin()) {
		switch (type) {
			case permissionGroup.UserRole:
				_isGranted = _isAnyGranted([
					venueUserPermissionList.UserRoleManagementView,
					venueUserPermissionList.UserRoleManagementEdit,
					venueUserPermissionList.UserRoleManagementAdd,
					venueUserPermissionList.UserRoleManagementDelete,
				]);
				break;
			case permissionGroup.RecentOrder:
				_isGranted = _isAnyGranted([
					venueUserPermissionList.RecentOrdersView,
					venueUserPermissionList.RecentOrdersDelete,
					venueUserPermissionList.RecentOrdersCancel,
					venueUserPermissionList.RecentOrdersAdmin,
				]);
				break;
			case permissionGroup.QuickOrder:
				_isGranted = _isAnyGranted([
					venueUserPermissionList.QuickOrdersAdd,
					venueUserPermissionList.QuickOrdersDelete,
					venueUserPermissionList.QuickOrdersEdit,
					venueUserPermissionList.QuickOrdersView,
				]);
				break;
			case permissionGroup.QuickAccess:
				_isGranted = _isAnyGranted([
					venueUserPermissionList.QuickAccessButtonsAdd,
					venueUserPermissionList.QuickAccessButtonsDelete,
					venueUserPermissionList.QuickAccessButtonsEdit,
					venueUserPermissionList.QuickAccessButtonsView,
				]);
				break;
			case permissionGroup.ParkedOrder:
				_isGranted = _isAnyGranted([
					venueUserPermissionList.ParkedOrdersAdmin,
					venueUserPermissionList.ParkedOrdersAdd,
					venueUserPermissionList.ParkedOrdersDelete,
					venueUserPermissionList.ParkedOrdersEdit,
					venueUserPermissionList.ParkedOrdersView,
				]);
				break;
			case permissionGroup.Report:
				_isGranted = _isAnyGranted([
					dcUserPermissionList.ReportManagement,
					dcUserPermissionList.SalesReport,
					dcUserPermissionList.ViewsReport,
					dcUserPermissionList.ProductSoldReport,
				]);
				break;
			case permissionGroup.FavoriteOrder:
				_isGranted = _isAnyGranted([
					venueUserPermissionList.FavoriteOrderAdd,
					venueUserPermissionList.FavoriteOrderDelete,
					venueUserPermissionList.FavoriteOrderEdit,
					venueUserPermissionList.FavoriteOrderView,
					venueUserPermissionList.ScheduleOrderfromFavoriteOrder,
				]);
				break;
			default:
				break;
		}
	} else {
		_isGranted = true;
	}
	return _isGranted;
}

/*permissions end here */

function getFields(obj: Partial<urlObj>) {
	obj.fields = [];
	obj.fields!.push({ field_name: 'venue_id', field_value: localStorage.getItem('selectedVenue')! });
	if (obj.searchText) {
		let type = _getSearchType(obj);
		obj.fields!.push({
			field_name: type,
			field_value: `${obj.searchText}`,
			field_conditionType: condition.like,
		});
	}
	if (obj.ctx && obj.ctx.Categories && typeof obj.ctx.Categories != 'undefined')
		obj.fields!.push({
			field_name: 'category_id',
			field_value: obj.ctx.Categories,
			field_conditionType: condition.in,
		});
	if (obj.ctx && obj.ctx.Suppliers && typeof obj.ctx.Suppliers != 'undefined')
		obj.fields!.push({
			field_name: 'supply_company_name',
			field_value: obj.ctx.Suppliers,
			field_conditionType: condition.in,
		});
	if (obj.ctx && obj.ctx.PriceFrom)
		obj.fields!.push({
			field_name: 'price',
			field_value: String(obj.ctx.PriceFrom),
			field_conditionType: condition.gteq,
		});
	if (obj.ctx && obj.ctx.PriceTo)
		obj.fields!.push({
			field_name: 'price',
			field_value: String(obj.ctx.PriceTo),
			field_conditionType: condition.lteq,
		});
	if (obj.ctx && obj.ctx.skuList)
		obj.fields!.push({
			field_name: 'sku',
			field_value: String(obj.ctx.skuList),
			field_conditionType: condition.in,
		});
	if (obj.ctx && obj.ctx.newArrivalDate && obj.ctx.newArrivalDate.length > 0)
		obj.fields!.push({
			field_name: 'created_at',
			field_value: String(obj.ctx.newArrivalDate),
			field_conditionType: condition.gteq,
		});
	if (obj.ctx && obj.ctx.specialProductsView) {
		obj.fields!.push({
			field_name: 'special_price',
			field_value: String(0),
			field_conditionType: condition.gt,
		});
		obj.fields!.push({
			field_name: 'special_from_date',
			field_value: String(moment(new Date()).format('YYYY-MM-DD')),
			field_conditionType: condition.lteq,
		});
		obj.fields!.push({
			field_name: 'special_to_date',
			field_value: String(moment(new Date()).format('YYYY-MM-DD')),
			field_conditionType: condition.gteq,
		});
	}
	if (obj.ctx && obj.ctx.product_id?.length > 0) {
		obj.fields!.push({
			field_name: 'entity_id',
			field_value: obj.ctx.product_id.toString(),
			field_conditionType: condition.in,
		});
	}
	return obj;
}

function _getSearchType(obj) {
	let searchFilter = 'name';
	switch (obj.ctx!.Type) {
		case types.product:
			searchFilter = 'name';
			break;
		case types.brand:
			searchFilter = 'brand';
			break;
		case types.supplier:
			searchFilter = 'supplier_name';
			break;
		default:
			break;
	}
	return searchFilter;
}

async function getCartId(CUSTOMER_TOKEN) {
	let cartID: any;
	const obj: Partial<Ipost> = {
		data: {},
		params: {},
		url: constants.cartURL(),
		message: ' ',
		headers: { Authorization: CUSTOMER_TOKEN },
	};
	try {
		await api.post(obj).then((data) => {
			if (data !== undefined) {
				cartID = data;
				localStorage.setItem('cartId', cartID);
			}
		});
	} catch (e) {}
	return cartID;
}

function getOrderStatus(arr) {
	let status = '';
	if (arr.indexOf(OrderStatuses.Processing) > -1 || arr.indexOf(OrderStatuses.Ordered) > -1)
		status = OrderStatuses.Processing;
	else if (arr.indexOf(OrderStatuses.Pending) > -1) status = OrderStatuses.Pending;
	else if (arr.indexOf(OrderStatuses.Shipped) > -1) status = OrderStatuses.Shipped;
	else if (arr.indexOf(OrderStatuses.Delivered) > -1) status = OrderStatuses.Delivered;
	else if (arr.indexOf(OrderStatuses.Rejected) > -1) status = OrderStatuses.Rejected;
	let cancelCount = 0,
		rejectedCount = 0,
		deliveredCount = 0,
		confirmedCount = 0,
		disputedCount = 0,
		pendingApproval = 0,
		expired = 0,
		approved = 0,
		pendingOrderRejected = 0;
	status == '' &&
		arr.forEach((element) => {
			if (element.toLowerCase() == OrderStatuses.Cancelled) cancelCount++;
			else if (element.toLowerCase() == OrderStatuses.Rejected) rejectedCount++;
			else if (element.toLowerCase() == OrderStatuses.Delivered) deliveredCount++;
			else if (element.toLowerCase() == OrderStatuses.Confirmed) confirmedCount++;
			else if (element.toLowerCase() == OrderStatuses.Disputed) disputedCount++;
			else if (element.toLowerCase() == OrderStatuses.PendingApproval) pendingApproval++;
			else if (element.toLowerCase() == OrderStatuses.Expired) expired++;
			else if (element.toLowerCase() == OrderStatuses.Approved) approved++;
			else if (element.toLowerCase() == OrderStatuses.PendingOrderRejected) pendingOrderRejected++;
		});
	if (status == '' && arr.length > 0) {
		if (cancelCount == arr.length) status = OrderStatuses.Cancelled;
		else if (rejectedCount == arr.length) status = OrderStatuses.Rejected;
		else if (deliveredCount == arr.length) status = OrderStatuses.Delivered;
		else if (confirmedCount == arr.length) status = OrderStatuses.Confirmed;
		else if (disputedCount == arr.length) status = OrderStatuses.Disputed;
		else if (pendingApproval == arr.length) status = OrderStatuses.PendingApproval;
		else if (expired == arr.length) status = OrderStatuses.Expired;
		else if (approved == arr.length) status = OrderStatuses.Approved;
		else if (pendingOrderRejected == arr.length) status = OrderStatuses.PendingOrderRejected;
	}
	return status;
}

const getSortData = (val) => {
	let obj = { field: '', direction: '' };
	switch (val) {
		case 'Low - High Price':
			obj.field = 'price';
			obj.direction = 'asc';
			break;
		case 'High - Low Price':
			obj.field = 'price';
			obj.direction = 'desc';
			break;
		case 'A - Z Order':
			obj.field = 'name';
			obj.direction = 'asc';
			break;
		case 'Z - A Order':
			obj.field = 'name';
			obj.direction = 'desc';
			break;
		default:
			break;
	}
	return obj;
};

async function getCartData() {
	let returnData: any;
	let cartID = localStorage.getItem('cartId');
	if (cartID != null && cartID != '' && cartID != undefined) {
		let cartUrl = 'carts/' + cartID + '/totals';
		const obj: Partial<Iget> = {
			params: {},
			url: cartUrl,
			key: 'cartData',
			resultFilteringRequired: false,
		};
		try {
			await api.get(obj).then((data) => {
				if (data !== undefined) {
					if (data?.items.length > 0) returnData = true;
					else returnData = false;
				}
			});
		} catch (e) {}
	}
	return returnData;
}

async function getVenueParkedOrderLimit() {
	let returnData: any;
	let venueId = localStorage.getItem('selectedVenue');
	let venueUrl = `experion/venue/get/${venueId}`;
	const obj: Partial<Iget> = {
		params: {},
		url: venueUrl,
		key: 'venueParkedOrderLimit',
		resultFilteringRequired: false,
	};
	try {
		await api.get(obj).then((data) => {
			if (data !== undefined) {
				if (data?.number_of_parked_orders) returnData = data?.number_of_parked_orders;
			}
		});
	} catch (e) {}
	return returnData;
}

async function getVenueParkedOrderCount() {
	let urlObj: Partial<urlObj> = {
		url: 'experion-parkorder/parkorder/search?',
		pageSize: 2000,
		filterDeleted: true,
		fields: [
			{
				field_name: 'venue_id',
				field_value: localStorage.getItem('selectedVenue')!,
			},
			{
				field_name: 'venue_user_id',
				field_value: localStorage.getItem('userId')!,
			},
		],
	};

	let returnData: any;

	const obj: Partial<Iget> = {
		params: {},
		url: urls.GetSearchUrl(urlObj),
		key: 'venueParkedOrderCount',
		resultFilteringRequired: false,
	};
	try {
		await api.get(obj).then((data) => {
			if (data !== undefined) {
				if (data?.total_count) returnData = data?.total_count;
			}
		});
	} catch (e) {}
	return returnData;
}

function getFromToDates(val) {
	let obj = { from: '', to: '', period: '' };

	if (val === 'Today') {
		let today = new Date();
		let dd = String(today.getDate()).padStart(2, '0');
		let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		let yyyy = today.getFullYear();

		let from = mm + '/' + dd + '/' + yyyy;
		obj = { from: from, to: from, period: 'day' };
	}

	if (val === 'Yesterday') {
		let days = 1;
		let date = new Date();
		let start = new Date(date.getTime() - days * 24 * 60 * 60 * 1000);
		let from_dd = String(start.getDate()).padStart(2, '0');
		let from_mm = String(start.getMonth() + 1).padStart(2, '0'); //January is 0!
		let from_yyyy = start.getFullYear();

		let from = from_mm + '/' + from_dd + '/' + from_yyyy;
		obj = { from: from, to: from, period: 'day' };
	}
	if (val === 'Last 7 Days') {
		let d = 7;
		let days = d - 1;
		let date = new Date();
		let start = new Date(date.getTime() - days * 24 * 60 * 60 * 1000);
		let from_dd = String(start.getDate()).padStart(2, '0');
		let from_mm = String(start.getMonth() + 1).padStart(2, '0'); //January is 0!
		let from_yyyy = start.getFullYear();

		let from = from_mm + '/' + from_dd + '/' + from_yyyy;

		let end = new Date();
		let to_dd = String(end.getDate()).padStart(2, '0');
		let to_mm = String(end.getMonth() + 1).padStart(2, '0'); //January is 0!
		let to_yyyy = end.getFullYear();

		let to = to_mm + '/' + to_dd + '/' + to_yyyy;

		obj = { from: from, to: to, period: 'day' };
	}

	if (val === 'Last 30 Days') {
		let d = 30;
		let days = d - 1;
		let date = new Date();
		let start = new Date(date.getTime() - days * 24 * 60 * 60 * 1000);
		let from_dd = String(start.getDate()).padStart(2, '0');
		let from_mm = String(start.getMonth() + 1).padStart(2, '0'); //January is 0!
		let from_yyyy = start.getFullYear();

		let from = from_mm + '/' + from_dd + '/' + from_yyyy;

		let end = new Date();
		let to_dd = String(end.getDate()).padStart(2, '0');
		let to_mm = String(end.getMonth() + 1).padStart(2, '0'); //January is 0!
		let to_yyyy = end.getFullYear();

		let to = to_mm + '/' + to_dd + '/' + to_yyyy;

		obj = { from: from, to: to, period: 'day' };
	}

	if (val === 'Previous Month') {
		let start = new Date(); // current date
		start.setDate(1); // going to 1st of the month
		start.setHours(-1); // going to last hour before this date even started.
		start.setDate(1); // going to 1st of the prev month

		let from_dd = String(start.getDate()).padStart(2, '0');
		let from_mm = String(start.getMonth() + 1).padStart(2, '0'); //January is 0!
		let from_yyyy = start.getFullYear();

		let from = from_mm + '/' + from_dd + '/' + from_yyyy;

		let end = new Date(); // current date
		end.setDate(1); // going to 1st of the month
		end.setHours(-1); // going to last hour before this date even started.
		let to_dd = String(end.getDate()).padStart(2, '0');
		let to_mm = String(end.getMonth() + 1).padStart(2, '0'); //January is 0!
		let to_yyyy = end.getFullYear();

		let to = to_mm + '/' + to_dd + '/' + to_yyyy;

		obj = { from: from, to: to, period: 'day' };
	}

	if (val === 'Previous Year') {
		let start = new Date(); // current date
		start.setMonth(0); // going to 1st month of the year
		start.setDate(1); // going to 1st day of the month
		start.setHours(-1); // going to last hour before this date even started.
		start.setMonth(0); // going to 1st month of the prev year
		start.setDate(1); // going to 1st day of the month

		let from_dd = String(start.getDate()).padStart(2, '0');
		let from_mm = String(start.getMonth() + 1).padStart(2, '0'); //January is 0!
		let from_yyyy = start.getFullYear();

		let from = from_mm + '/' + from_dd + '/' + from_yyyy;

		let end = new Date(); // current date
		end.setMonth(0); // going to 1st month of the year
		end.setDate(1); // going to 1st day of the month
		end.setHours(-1); // going to last hour before this date even started.
		let to_dd = String(end.getDate()).padStart(2, '0');
		let to_mm = String(end.getMonth() + 1).padStart(2, '0'); //January is 0!
		let to_yyyy = end.getFullYear();

		let to = to_mm + '/' + to_dd + '/' + to_yyyy;

		obj = { from: from, to: to, period: 'month' };
	}

	if (val === 'Week to Date') {
		let d = new Date();
		let day = d.getDay(),
			diff = d.getDate() - day;
		let start = new Date(d.setDate(diff));

		let from_dd = String(start.getDate()).padStart(2, '0');
		let from_mm = String(start.getMonth() + 1).padStart(2, '0'); //January is 0!
		let from_yyyy = start.getFullYear();

		let from = from_mm + '/' + from_dd + '/' + from_yyyy;

		let end = new Date(); // current date
		let to_dd = String(end.getDate()).padStart(2, '0');
		let to_mm = String(end.getMonth() + 1).padStart(2, '0'); //January is 0!
		let to_yyyy = end.getFullYear();

		let to = to_mm + '/' + to_dd + '/' + to_yyyy;

		obj = { from: from, to: to, period: 'day' };
	}

	if (val === 'Month to Date') {
		let start = new Date(); // current date
		start.setDate(1); // going to 1st day of the month
		let from_dd = String(start.getDate()).padStart(2, '0');
		let from_mm = String(start.getMonth() + 1).padStart(2, '0'); //January is 0!
		let from_yyyy = start.getFullYear();

		let from = from_mm + '/' + from_dd + '/' + from_yyyy;

		let end = new Date(); // current date
		let to_dd = String(end.getDate()).padStart(2, '0');
		let to_mm = String(end.getMonth() + 1).padStart(2, '0'); //January is 0!
		let to_yyyy = end.getFullYear();

		let to = to_mm + '/' + to_dd + '/' + to_yyyy;

		obj = { from: from, to: to, period: 'day' };
	}
	if (val === 'Year to Date') {
		let start = new Date(); // current date
		start.setMonth(0); // going to 1st month of the year
		start.setDate(1); // going to 1st day of the month
		let from_dd = String(start.getDate()).padStart(2, '0');
		let from_mm = String(start.getMonth() + 1).padStart(2, '0'); //January is 0!
		let from_yyyy = start.getFullYear();

		let from = from_mm + '/' + from_dd + '/' + from_yyyy;

		let end = new Date(); // current date
		let to_dd = String(end.getDate()).padStart(2, '0');
		let to_mm = String(end.getMonth() + 1).padStart(2, '0'); //January is 0!
		let to_yyyy = end.getFullYear();

		let to = to_mm + '/' + to_dd + '/' + to_yyyy;

		obj = { from: from, to: to, period: 'month' };
	}

	return obj;
}

function OrderGridProductData(product_data, sensitive_data, non_sensitive_data) {
	let sensWarning = false;
	let nonSensWarning = false;

	const { credit_status, product_available, stock_status, supplier_vacation } =
		sensitive_data || {};
	const msg: string[] = [];
	if (credit_status.status === false) {
		msg.push(credit_status.message);
	}
	if (product_available.status === false) {
		msg.push(product_available.message);
	}
	if (stock_status.status === false) {
		msg.push(stock_status.message);
	}
	if (supplier_vacation.status === false) {
		msg.push(supplier_vacation.message);
	}
	const { min_qty, max_qty, price_variation, mov } = non_sensitive_data || {};

	if (min_qty.status === false) {
		msg.push(min_qty.message);
	}
	if (max_qty.status === false) {
		msg.push(max_qty.message);
	}
	if (price_variation.status === false) {
		msg.push(price_variation.message);
	}
	if (mov.status === false) {
		msg.push(mov.message);
	}
	if (
		credit_status.status === false ||
		product_available.status === false ||
		stock_status.status === false ||
		supplier_vacation.status === false
	) {
		sensWarning = !sensWarning;
	}
	if (
		min_qty.status === false ||
		max_qty.status === false ||
		price_variation.status === false ||
		mov.status === false
	) {
		nonSensWarning = !nonSensWarning;
	}

	// const warningMessage = msg.map((s) => warning(s));

	const ObjectData = Object.assign(product_data, {
		message: msg,
		sensWarning: sensWarning,
		nonSensWarning: nonSensWarning,
	});
	return ObjectData;
}

function productsAvailable(product_id, qty_ordered, sensWarning) {
	let items = {} as any;

	if (sensWarning === false) {
		items = { product_id: product_id, qty: qty_ordered };
	}

	const ObjectData = {
		productsObj: items,
	};
	return ObjectData;
}

export default {
	isGranted,
	setPermissions,
	isAnyGranted,
	getFields,
	getCartId,
	getOrderStatus,
	getSortData,
	getCartData,
	getVenueParkedOrderLimit,
	getVenueParkedOrderCount,
	getFromToDates,
	OrderGridProductData,
	productsAvailable,
};
