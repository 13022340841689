import React, { ReactElement } from 'react';
import 'react-tippy/dist/tippy.css';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Button } from 'ui/components';
import * as Icon from 'ui/icons';
import { StyledOrderListView } from './styles';

const orderDetailsEditView = [
	{
		id: 1,
		image: require('ui/images/R1.png'),
		orderId: 'A123213D123WD',
		date: '21 June 2020, Wednesday 07:30 AM',
		amount: '$300',
	},
];

function OrderDetailsEditView(): ReactElement {
	return (
		<StyledOrderListView>
			{orderDetailsEditView.map(({ id, image, orderId, date, amount }) => (
				<Flex
					className="order-details__wrap selected"
					bg="white"
					sx={{ alignItems: 'center' }}
					key={id}
				>
					<Flex
						alignItems="center"
						as="span"
						maxWidth={['100%']}
						sx={{ overflow: 'hidden' }}
						style={{
							backgroundImage: 'url(' + image + ')',
						}}
					></Flex>
					<Flex as="section" flexWrap="wrap" width="100%" maxWidth={['100%']}>
						<Box>
							<Text mb={1} fontSize={18} color="text.black">
								Order Id
							</Text>
							<Text as="label" fontSize={20} fontFamily="OpenSansBold" color="text.black">
								{orderId}
							</Text>
						</Box>
						<Box>
							<Text mb={1} fontSize={18} color="text.black">
								Date
							</Text>
							<Text as="label" fontSize={20} fontFamily="OpenSansBold" color="text.black">
								{date}
							</Text>
						</Box>
						<Box as="object">
							<Text mb={1} fontSize={18} color="text.black">
								Amount
							</Text>
							<Text as="label" fontSize={20} fontFamily="OpenSansBold" color="text.black">
								{amount}
							</Text>
						</Box>
						<Box as="main">
							<div className="btn-block">
								<Flex as="slot" width="100%" maxWidth={['100%']}>
									<Box>
										<Text as="h2" fontSize={15} color="text.black" textAlign="right">
											This Order has been favorited
										</Text>
										<Text
											as="h1"
											fontSize={16}
											fontFamily="OpenSansBold"
											color="text.black"
											textAlign="right"
										>
											Coffee Orders
										</Text>
									</Box>
									<Button>
										<Icon.textEditIcon />
									</Button>
								</Flex>
								<Button>Order Again</Button>
							</div>
						</Box>
					</Flex>
				</Flex>
			))}
		</StyledOrderListView>
	);
}

export { OrderDetailsEditView };
