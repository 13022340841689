import { getHSL } from '../../utils/getHSL';

import styled from 'styled-components';
const StyledProductGridView = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	column-gap: 20px;
	row-gap: 20px;
	@media (max-width: 1566px) {
		grid-template-columns: repeat(3, 1fr) !important;
		column-gap: 20px;
	}
	@media (max-width: 1300px) {
		grid-template-columns: repeat(2, 1fr) !important;
		column-gap: 20px;
	}
	@media (max-width: 1152px) {
		grid-template-columns: repeat(1, 1fr) !important;
		column-gap: 20px;
	}
	@media (max-width: 960px) {
		grid-template-columns: repeat(2, 1fr) !important;
		column-gap: 20px;
	}
	@media (max-width: 640px) {
		grid-template-columns: repeat(1, 1fr) !important;
		column-gap: 15px;
	}
	article {
		flex-direction: column;
		background: ${({ theme }) => theme.colors.bg.white};
		border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
		box-sizing: border-box;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 16px;
		overflow: hidden;
		transition: box-shadow 100ms ease-in, transform 100ms ease-in;
		transition: all 0.25s ease-in-out;
		&.row-highlighted {
			pointer-events: none;
			box-shadow: none;
			position: relative;
			border: 1px solid rgb(255, 0, 0, 0.1);
			span,
			section,
			div {
				opacity: 0.9;
			}
			&:after {
				content: 'Out of Stock';
				position: absolute;
				color: ${({ theme }) => theme.newColors.white};
				right: 0;
				top: 0;
				font-family: 'OpenSansSemiBold';
				font-size: 19px;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				background: rgb(160 160 160 / 50%);
				padding-bottom: 20px;
			}
			picture {
				border: none;
			}
		}
		&.un-available {
			pointer-events: none;
			box-shadow: none;
			position: relative;
			border: 1px solid rgb(255, 0, 0, 0.1);
			span,
			section,
			div {
				opacity: 0.9;
			}
			&:after {
				content: 'Product not available';
				position: absolute;
				color: ${({ theme }) => theme.baseColors.red};
				right: 0;
				top: 0;
				font-family: 'OpenSansSemiBold';
				font-size: 19px;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				background: rgb(255 0 0 / 3%);
				padding-bottom: 20px;
			}
			picture {
				border: none;
				background-size: cover;
			}
		}
		span {
			width: 100%;
			height: 200px;
			position: relative;
			transition: opacity 0.35s;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			overflow: visible;
			picture {
				width: calc(100% - 0px);
				height: calc(100% - 0px);
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center;
				margin: 0px;
				border-bottom: 1px solid ${({ theme }) => theme.colors.bg.lightYellow05};
				background-color: #faf4ea;
			}
			.temp-unavailable {
				background: rgba(155, 6, 6, 0.73);
				border-radius: 10px;
				font-size: 14px;
				padding: 9px 14px;
				text-align: center;
				position: absolute;
				bottom: 9px;
				width: 95%;
				color: #fff;
				.date {
					font-weight: 800;
					font-size: 15px;
				}
			}
			&:hover {
				transform: translateY(0px);
				border: 1px solid ${({ theme }) => theme.baseColors.baseYellowLight};
				&:after {
					content: '';
					width: 100%;
					position: absolute;
					height: 100%;
					top: 0;
					right: 0;
					background: rgba(31, 38, 31, 0.78);
					opacity: 1;
				}
				button {
					bottom: calc(50% - 17px);
					opacity: 1;
				}
			}
			@media (max-width: 768px) {
				cursor: default;
				border: none !important;
				box-shadow: none !important;
				&:after {
					display: none !important;
				}
			}
			&:after {
				content: '';
				opacity: 0;
				transition: opacity 0.35s;
			}
			button {
				--h: ${({ theme, color }) => getHSL('h', color || theme.baseColors.baseYellow)};
				--s: ${({ theme, color }) => getHSL('s', color || theme.baseColors.baseYellow)};
				--l: ${({ theme, color }) => getHSL('l', color || theme.baseColors.baseYellow)};
				--a: 100%;
				width: auto;
				height: 35px;
				opacity: 0;
				z-index: 1;
				color: ${({ theme }) => theme.colors.text.white};
				background: hsla(var(--h), var(--s), var(--l), var(--a));
				border: solid 1px hsl(var(--h), var(--s), var(--l));
				outline: none;
				border: none;
				position: absolute;
				transition: opacity 0.3s, ease-in-out 0.3s;
				bottom: 0;
				font-size: 14px;
				font-family: 'OpenSansSemiBold';
				cursor: pointer;
				display: flex;
				white-space: nowrap;
				align-items: center;
				justify-content: center;

				&:hover {
					background: hsl(var(--h), var(--s), calc(var(--l) - 10%));
					border: 1px solid hsl(var(--h), var(--s), calc(var(--l) - -5%));
				}

				span {
					height: auto;
					width: auto;
					margin-right: 8px;
					-webkit-animation: beat 0.25s infinite alternate;
					-moz-animation: beat 0.25s infinite alternate;
					-ms-animation: beat 0.25s infinite alternate;
					-o-animation: beat 0.25s infinite alternate;
					animation: beat 0.25s infinite alternate;

					-webkit-transform-origin: center;
					-moz-transform-origin: center;
					-o-transform-origin: center;
					-ms-transform-origin: center;
					transform-origin: center;
					box-shadow: none !important;
					border: none !important;
					&:after {
						display: none;
					}
					@keyframes beat {
						to {
							-webkit-transform: scale(1.3);
							-moz-transform: scale(1.3);
							-o-transform: scale(1.3);
							-ms-transform: scale(1.3);
							transform: scale(1.3);
						}
					}
				}
				@media (max-width: 768px) {
					opacity: 1;
					width: 40px;
					height: 40px;
					padding: 0;
					border-radius: 50px;
					bottom: -16px !important;
					right: 15px;
					transition: none;
					cursor: pointer;
					span {
						animation: none !important;
						margin-right: 0;
						cursor: pointer;

						svg {
							height: 20px;
							width: 20px;
						}
					}
					div {
						display: none;
					}
				}
			}
		}
		section {
			width: 100%;
			display: flex;
			align-items: flex-end;
			padding: 15px;
			h1,
			h2,
			h3,
			h4 {
				font-weight: normal;
			}
			h2 {
				font-family: 'OpenSansMedium';
				margin: 3px 0 0 0;
				display: flex;
				justify-content: space-between;
				padding-right: 5px;
				a {
					text-decoration: underline;
					cursor: pointer;
					&:hover {
						color: ${({ theme }) => theme.baseColors.baseYellow};
					}
				}
			}
			h3 {
				margin: 10px 0 0 0;
			}
			h4 {
				font-family: 'OpenSansMedium';
				margin: 0 0 -3px 0;
				strong {
					font-family: 'OpenSansBlack';
					font-size: 18px;
					color: ${({ theme }) => theme.colors.text.black};
					margin-right: 5px;
				}
			}
		}
	}
`;
const IconHolder = styled.div`
	display: flex;
	width: 100%;
	padding: 0 15px 15px;
	margin: auto 0 0 0;
	div {
		margin-right: 10px;
		div {
			margin-right: 0;
		}
	}
	span {
		height: auto !important;
		background: transparent !important;
		border: none !important;
		box-shadow: none !important;
		width: auto !important;
		display: inline-block !important;
		margin: 0 0 0 0;
		color: ${({ theme }) => theme.colors.text.black};
		font-family: 'OpenSansBold';
		display: flex !important;
		font-size: 18px;
		div {
			font-family: 'OpenSansSemiBold';
			margin: 0;
			font-size: 15px;
		}
		&:after {
			display: none !important;
		}
		svg {
			width: 30px;
			height: 30px;
		}
	}
`;
export { StyledProductGridView, IconHolder };
