import { Checkbox, Label } from '@rebass/forms';
import Iget from 'models/Iget';
import Ipost from 'models/Ipost';
import React, { ReactElement, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory } from 'react-router-dom';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { Box, Text } from 'rebass/styled-components';
import { Button, Search, TableList, Toggle } from 'ui/components';
import icons from 'ui/icons';
import urls from 'utils/create.url';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import notify from 'utils/toast.service';
import { PageSearchHolder, UserListHolder } from './styles';
function VenueList(props): ReactElement {
	var [venueList, updateVenueList] = useState<any>([]);
	var [venueQList, updateVenueQList] = useState<any>([]);
	const [showPopup, setShowModal] = useState(false);
	const [checked, setChecked] = useState(false);
	const [modalText, setmodalText] = useState('');
	const [action, setAction] = useState('');
	const [id, setId] = useState(0);
	const history = useHistory();
	const userKey = 'venueKey';
	var [idList, updateIdList] = useState<any>([]);
	var [enableButton, updateEnableButton] = useState<any>(false);
	const [checkedAll, setCheckedAll] = useState(false);
	const url = urls.GetSearchUrl({
		url: 'experion/venue/search?',
		pageSize: 10,
		fields: [{
			field_name: 'hosp_id',
			field_value: localStorage.getItem("hospitalityId")!
		}],
		filterDeleted: true,
	});
	const obj: Partial<Iget> = {
		params: {},
		url: url,
		enabled: true,
		key: userKey,
		resultFilteringRequired: true,
	};
	const obj1: Partial<Ipost> = {
		params: {},
		url: '',
	};

	var qry = hooks.useQuery(obj);

	const viewAllVenues = () => {
		history.push('stores-list');
	};

	async function closePopup() {
		if (action == 'upd') {
			venueList[venueList.findIndex((x) => x.id == id)].is_active = !checked;
			updateVenueList(venueList);
			updateVenueQList(venueList);
			setChecked(!checked);
		}
		await setShowModal(!showPopup);
	}
	function onSubmit() {
		if (action == 'del' || action == 'bulkdel') deleteVenue();
		else if (action == 'upd') changeStatus();
	}
	function deleteVenue() {
		if (idList?.length > 0) {
			obj1.url = `experion/venue/delete`;
			obj1.data = { Id: typeof idList != 'undefined' && idList.length > 0 ? idList : [id] };
			obj1.message = `Store${typeof idList != 'undefined' && idList.length > 1 ? '(s)' : ''
				} deleted successfully`;
			api.post(obj1).then(function (d) {
				if (typeof d != 'undefined') {
					let datalist = [];
					if (typeof idList != 'undefined' && idList.length > 0) {
						datalist = venueList.filter((x) => !idList.includes(x.id));
						updateVenueList(datalist);
						updateVenueQList(datalist);
					} else {
						updateVenueList((venueList = venueList.filter((x) => x.id !== id)));
						updateVenueQList((venueList = venueList.filter((x) => x.id !== id)));
					}
					props.getVenueCount(datalist.length);
				}
				updateIdList([]);
				setId(0);
			});
			setShowModal(!showPopup);
		}
		else {
			notify('Please select the store(s).').error();
			updateEnableButton(false);
		}
	}

	function changeStatus() {
		obj1.url = `experion/venue/updatestatus/${id}/${checked}`;
		obj1.message = 'Store status updated successfully';
		api.put(obj1).then(function (data) {
			if (data == undefined) {
				venueList[venueList.findIndex((x) => x.id == id)].is_active = !checked;
				updateVenueList(venueList);
				updateVenueQList(venueList);
				setChecked(!checked);
			}
		});
		setShowModal(!showPopup);
	}

	function confirm(action, id, e = null as any) {
		let checked = false;
		if (e != null) {
			checked = e.currentTarget.checked;
			venueList[venueList.findIndex((x) => x.id == id)].is_active = checked;
			updateVenueList(venueList);
			updateVenueQList(venueList);
		}
		switch (action) {
			case 'del':
				showConfirmationModal(action, 'Delete store?', id, checked);
				break;
			case 'bulkdel':
				if (idList.length > 0) {
					showConfirmationModal(action, 'Delete store(s)?', id, checked);
				} else {
					notify('Please select the store(s).').error()
				}
				break;
			case 'upd':
				showConfirmationModal(
					action,
					`${checked == true ? 'A' : 'Dea'}ctivate store?`,
					id,
					checked
				);
				break;
			default:
				break;
		}
	}

	function showConfirmationModal(action, text, id, checked) {
		if (action == 'upd') setChecked(checked);
		setAction(action);
		if (action != 'bulkdel') setId(id);
		setmodalText(text);
		setShowModal(!showPopup);
	}

	useEffect(() => {
		//const ac = new AbortController();
		props.getVenueCount(qry.data?.length);
		qry.data && updateVenueList([...qry.data]);
		qry.data && updateVenueQList([...qry.data]);
		//return () => ac.abort(); // Abort both fetches on unmount
	}, [qry.data]);

	// useEffect(() => {
	// 	updateVenueList(venueList);
	// }, [venueList]);

	// event to get data from search ( child ) component
	// const handleSearchFill = (data) => {
	// 	updateVenueList(data);
	// };

	//on grid row name click
	const onNameClick = (id) => {
		history.push({
			pathname: 'dashboard-store',
			search: '?id=' + id,
		});
	};

	// const selectAllCheckbox = (event) => {
	// 	setCheckedAll(event.target.checked);
	// 	idList = [];
	// 	if (event.target.checked) {
	// 		updateEnableButton(true);
	// 		venueList.map((data) => {
	// 			idList.push(data.id);
	// 		});
	// 		updateIdList(idList);
	// 	} else {
	// 		idList = [];
	// 		updateIdList(idList);
	// 		updateEnableButton(false);
	// 	}
	// 	let checked = event.target.checked;
	// 	venueList = venueList.map((item) => ({ ...item, isChecked: checked }));
	// 	updateVenueList(venueList);
	// 	updateVenueQList(venueList);
	// };

	const navigateToEdit = (id) => {
		history.push({
			pathname: 'edit-store',
			search: '?id=' + id,
		});
	};

	const selectCheckBox = (id, event) => {
		if (event.target.checked) {
			idList.push(id);
			updateIdList(idList);
		} else {
			idList.splice(idList.indexOf(id), 1);
			updateIdList(idList);
		}
		if (idList.length >= 1) {
			updateEnableButton(true);
		} else {
			updateEnableButton(false);
		}

		// let checked = event.target.checked;
		// for (var i=0; i<venueList.length ; i++){
		// 	if(venueList[i].id == id){
		// 		venueList[i].isChecked = checked;
		// 		updateVenueList(venueList);
		// 	}
		// }
	};

	const setFilteredData = (val) => {
		const sampleList = venueQList;
		if (val && val.length > 0) {
			var venueList = sampleList.filter(
				(v) =>
					v.name.toLowerCase().includes(val.toLowerCase()) ||
					(v.description && v.description.toLowerCase().includes(val.toLowerCase())) ||
					v.venue_email.toLowerCase().includes(val.toLowerCase()) ||
					v.address.toLowerCase().includes(val.toLowerCase()) ||
					v.phone.toString().includes(val) ||
					v.number_of_parked_orders.toString().includes(val)
			);
			updateVenueList(venueList);
		} else {
			updateVenueList(venueQList);
		}
	};

	return (
		<UserListHolder>
			<PageSearchHolder>
				<Search
					onChange={(val) => setFilteredData(val)}
					type={'venuesList'}
					placeholder="Search for Store, Email, Phone ..."
				></Search>
				<Box>
					<Button
						flex={[1, 1, 'initial']}
						variant="redOutline"
						onClick={() => confirm('bulkdel', id)}
						disabled={!enableButton}
					>
						<icons.DeleteIcon />
						<div>Delete Selected</div>
					</Button>
					<Button onClick={viewAllVenues} flex={[1, 1, 'initial']} variant="primary">
						<icons.viewIcon />

						<div>view All</div>
					</Button>
				</Box>
			</PageSearchHolder>
			<TableList>
				<table>
					<thead>
						<tr>
							{/* <th className="checked">
								<Label>
									<Checkbox  name="remember"  onChange={(event) => selectAllCheckbox(event)} checked={checkedAll} />
								</Label>
							</th> */}
							<th className="checked"></th>
							<th>Store</th>
							{/* <th>Description</th> */}
							<th>Address</th>
							<th>Email</th>
							<th>Phone</th>
							<th>Parked Orders Limit</th>
							<th className="status">Status</th>
							<th className="action">Action</th>
						</tr>
					</thead>
					<tbody>
						{/* Add (row-highlighted) class for row highlighting */}

						{venueList &&
							venueList.map(
								({
									name,
									venue_email,
									phone,
									id,
									is_active,
									description,
									address,
									number_of_parked_orders,
									isChecked
								}) => (
									<tr key={id} className="">
										<td className="checked">
											<Label>
												<Checkbox
													onChange={(event) => selectCheckBox(id, event)}
													checked={isChecked}
													name="remember"
												/>
											</Label>
										</td>
										<td data-label="Venue Name">
											<Text
												as="h1"
												onClick={() => onNameClick(id)}
												fontSize={15}
												fontFamily="OpenSansMedium"
												color="text.black"
											>
												<strong>
													<span>{name}</span>
												</strong>
											</Text>
										</td>
										{/* <td data-label="description">
											<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
												{description}
											</Text>
										</td> */}
										<td data-label="Address">
											<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
												{address}
											</Text>
										</td>
										<td data-label="Email">
											<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
												{venue_email}
											</Text>
										</td>
										<td data-label="Phone">
											<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
												{phone}
											</Text>
										</td>
										<td data-label="Parked Orders Limit">
											<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
												{number_of_parked_orders}
											</Text>
										</td>
										{/* <td data-label="noParked">
									<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
										{description}
									</Text>
								</td>
								<td data-label="Address">
									<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
										{address}
									</Text>
								</td>
								<td data-label="Email">
									<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
										{venue_email}
									</Text>
								</td>
								<td data-label="Phone">
									<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
										{phone}
									</Text>
								</td>
								{/* <td data-label="noParked">
									<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
									3
									</Text>
								</td> */}
										<td data-label="Status" className="status">
											<Toggle
												disabled={!id ? true : false}
												checked={is_active}
												onChange={(e) => confirm('upd', id, e)}
											/>
										</td>
										<td data-label="Action" className="action">
											<Text>
												<Button
													variant="deleteIcon"
													disabled={!id ? true : false}
													onClick={() => confirm('del', id)}
												>
													<icons.DeleteIcon />
												</Button>
												<Button onClick={() => navigateToEdit(id)} variant="editIcon">
													<icons.textEditIcon />
												</Button>
											</Text>
										</td>
									</tr>
								)
							)}
					</tbody>
				</table>
			</TableList>
			<SweetAlert
				show={showPopup}
				confirmBtnText="Confirm"
				onConfirm={onSubmit}
				showCancel={true}
				onCancel={closePopup}
				title={'Confirmation Box'}
			//  dependencies={[this.state.firstName, this.state.lastName]}
			>
				{() => <form>{modalText}</form>}
			</SweetAlert>
		</UserListHolder>
	);
}

export { VenueList };
