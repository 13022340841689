import src from '*.bmp';
import { UserBody } from 'admin/dashboardVenue/styles';
import fileUploadHandler from 'admin/productManagement/bulkUpload/upload.handler';
import Iget from 'models/Iget';
import Ipost from 'models/Ipost';
import React, { ReactElement, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Link, useHistory } from 'react-router-dom';
import { Box, Image, Text } from 'rebass/styled-components';
import {
	BreadcrumbNav,
	Button,
	Footer,
	Header,
	Loader,
	PageSearchHolder,
	Search,
	TableList,
	Toggle,
} from 'ui/components';
import icons from 'ui/icons';
import no_image from 'ui/images/no_image.png';
import { Modal, Sidebar } from 'ui/patterns';
import constants from 'utils/constants';
import httpService from 'utils/http.service';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import notify from 'utils/toast.service';
import BulkUploadModal from './bulkUpload';
import { BreadcrumbHolder, ButtonWrapper, MainContent } from './styles';

function ProductManagement(): ReactElement {
	const history = useHistory();
	const breadcrumbNav = [
		{ id: '01', label: 'Dashboard', route: '/admin/seller-dashboard', active: false },
		{ id: '03', label: 'Product Management', active: true },
	];
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const [showPopup, setShowModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	var [productList, updateProductList] = useState<any>([]);
	const [fileName, setFileName] = useState('');
	const userKey = 'pdtManagement';
	const supplierId = localStorage.getItem('supplierId');
	const userId = localStorage.getItem('userId');
	const adminId = localStorage.getItem('SuAdminId');
	const obj1: Partial<Ipost> = {
		params: {},
		data: {},
		url: '',
	};
	const [checked, setChecked] = useState(false);
	const [action, setAction] = useState('');
	const [id, setId] = useState(0);
	const [modalText, setmodalText] = useState('');
	const [errorList, setErrors] = useState([] as any);
	const [products, setProducts] = useState([] as any);
	const [hasChange, setHasChange] = useState(false);
	const url = `experion-productmanagement/getproducts/${adminId}`;
	const obj: Partial<Iget> = {
		params: {},
		url: url,
		enabled: true,
		key: userKey,
		resultFilteringRequired: false,
	};
	var qry = hooks.useQuery(obj);
	const uomObj: Partial<Iget> = {
		params: {},
		url: `products/attributes/uom`,
		enabled: true,
		key: 'uomData',
		resultFilteringRequired: false,
		caching: true,
	};
	var uomQry = hooks.useQuery(uomObj);
	useEffect(() => {
		qry.data && updateProductList([...qry.data]);
	}, [qry.data]);
	function onSubmit() {
		setIsLoading(true);
		if (action == 'del') deleteProducts();
		else if (action == 'upd') changeStatus();
	}
	function deleteProducts() {
		obj1.url = constants.publicURL() + `rest/all/V1/experion-productmanagement/deleteproduct/${adminId}/${id}`;
		obj1.message = 'Product deleted successfully';
		api.delete(obj1).then(function (d) {
			setIsLoading(false);
			if (typeof d != 'undefined') {
				qry.refetch().then(function (data) {
					updateProductList(data);
				});
			}
		});
		setShowModal(!showPopup);
	}
	var template = constants.publicURL() + 'templates/bulk_upload.csv';

	function changeStatus() {
		var item = {
			status: checked,
		};
		obj1.url = constants.publicURL() + `/rest/default/V1/products/${id}`;
		obj1.data = { product: item };
		obj1.message = 'Product status updated successfully';
		api.put(obj1).then(function (d) {
			if (d == undefined) {
				productList[productList.findIndex((x) => x.sku == id)].status =
					productList[productList.findIndex((x) => x.sku == id)].status == 2 ? 1 : 2;
				updateProductList(productList);
			}
			setIsLoading(false);
		});
		setShowModal(!showPopup);
	}
	async function closePopup() {
		if (action == 'upd') {
			productList[productList.findIndex((x) => x.sku == id)].status =
				productList[productList.findIndex((x) => x.sku == id)].status == 2 ? 1 : 2;
			updateProductList(productList);
			setChecked(!checked);
		}
		await setShowModal(!showPopup);
	}
	function showConfirmationModal(action, text, id, checked) {
		if (action == 'upd') setChecked(checked);
		setAction(action);
		setId(id);
		setmodalText(text);
		setShowModal(!showPopup);
	}
	function confirm(action, id, e = null as any) {
		let checked = 2;
		if (e != null) {
			// checked = e.currentTarget.checked;
			if (e.currentTarget.checked) {
				checked = 1;
			} else {
				checked = 2;
			}
			if (action == 'upd') {
				productList[productList.findIndex((x) => x.sku == id)].status = checked;
			} else {
				productList[productList.findIndex((x) => x.id == id)].status = checked;
			}
			updateProductList(productList);
		}
		switch (action) {
			case 'del':
				showConfirmationModal(action, 'Are you sure you want to delete this product?', id, checked);
				break;
			case 'upd':
				showConfirmationModal(action, `${checked == 1 ? 'A' : 'Dea'}ctivate Product?`, id, checked);
				break;
			default:
				break;
		}
	}
	const navigateToEdit = (id) => {
		history.push({
			pathname: 'add-products',
			search: '?sku=' + id,
		});
	};
	const setFilteredData = (val) => {
		const sampleList = qry.data;
		if (val && val.length > 0) {
			var list = sampleList.filter(
				(v) =>
					v.name?.toLowerCase().includes(val.toLowerCase()) ||
					v.price?.toLowerCase().includes(val.toLowerCase()) ||
					v.supplier_sku?.toLowerCase().includes(val.toLowerCase())
			);
			updateProductList(list);
		} else {
			updateProductList(qry.data);
		}
	};

	function submitData(errors, data, fileName) {
		setFileName(fileName);
		if (errors.length == 0 && data?.length > 0) {
			setProducts(data);
			setErrors([]);
		} else if (errors.length > 0) {
			setErrors(errors);
		}
		setHasChange(true);
		setIsLoading(false);
	}
	const [showBulkUploadModal, setShowBulkUploadModal] = useState(false);
	const toggleBulkUploadModal = () => {
		setShowBulkUploadModal(!showBulkUploadModal);
	};

	function bulkUploadProducts() {
		if (errorList.length > 0) toggleBulkUploadModal();
		else
			api
				.bulkPost({ url: 'V1/products', data: constructPayload(), message: ' ' })
				.then(function (data) {
					if (typeof data != 'undefined') {
						// httpService.GET({ url: `bulk/${data.bulk_uuid}/detailed-status` }).then(function (d) {
						// 	let res = d.data?.operations_list;
						// 	let errorObj = [] as any;
						// 	let successCnt = 0;
						// 	res?.map((v, i) => {
						// 		let product = JSON.parse(v.serialized_data);
						// 		let productMetaInfo = JSON.parse(product.meta_information).product;
						// 		if (v.status == 3)
						// 			errorObj.push({
						// 				name: productMetaInfo.name == '' ? `Unknown Product` : productMetaInfo.name,
						// 				errors: [v.result_message],
						// 			});
						// 		else
						// 			successCnt++;
						// 	});
						// 	if (errorObj.length > 0) {
						// 		setErrors(errorObj);
						// 		if (successCnt > 0) {
						// 			notify(`${successCnt} products uploaded successfully, ${errorObj.length} failed`).info();
						// 		}
						// 		else
						// 			notify('Some error occured').error();
						// 		toggleBulkUploadModal();
						// 	} else {
						setTimeout(() => {
							qry.refetch().then(function (data) {
								updateProductList([...data]);
							});
						}, 15000);
						notify('Bulk Product Upload Successful').success();
						// 	}
						// });
					}
				});
	}
	function constructPayload() {
		let productPayload = [] as any;
		products.map((data, i) => {
			{
				let substitute_products = data?.Substitute_Products?.split(',');
				let substitute_productList = [] as any;
				if (substitute_products?.length > 0) {
					substitute_products.forEach((val) => {
						if (val != '')
							substitute_productList.push({
								sku: data.supplier_sku,
								link_type: 'substitution',
								linked_product_sku: val,
								linked_product_type: 'simple',
							});
					});
				}
				let categoryList = [] as any;
				let categories = data.Product_Category?.split(',');
				if (categories?.length > 0) {
					categories.forEach((val) => {
						if (val != '')
							categoryList.push({
								position: 0,
								category_id: val,
							});
					});
				}
				let imageList = [] as any;
				let images = data.Product_Image.split(',');
				if (images?.length > 0) {
					images.forEach((val) => {
						if (val != '')
							imageList.push({
								media_type: 'image',
								label: null,
								position: 1,
								disabled: false,
								file: val,
								types: [],
								content: {},
							});
					});
				}
				productPayload.push({
					product: {
						name: data.Product_Name,
						attribute_set_id: 4, //data.Attribute_Set,
						price: parseInt(data.Price),
						status: 1,
						visibility: 1,
						type_id: 'simple',
						media_gallery_entries: imageList,
						product_links: substitute_productList,
						extension_attributes: {
							category_links: categoryList,
							stock_item: {
								qty: data.Inventory,
								is_in_stock: true,
								min_qty: 0,
								min_sale_qty: 1 as any,
								max_sale_qty: data.Inventory,
								use_config_min_sale_qty: 0,
								use_config_max_sale_qty: 0,
								use_config_min_qty: 0,
							},
						},

						custom_attributes: [
							{
								attribute_code: 'seller_id',
								value: adminId, //userId,
							},
							{
								attribute_code: 'uom',
								value: data.UOM,
							},
							{
								attribute_code: 'supplier_sku',
								value: data.supplier_sku,
							},
							{
								attribute_code: 'is_bulk',
								value: '1',
							},
						],
					},
				});
			}
		});
		return JSON.stringify(productPayload);
	}
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	};
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={() => onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={() => onclickHambrgBtn()}></Sidebar>
			{qry.data == null || isLoading == true ? <Loader></Loader> : null}
			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbHolder>
					<BreadcrumbNav Heading="product management" breadcrumbList={breadcrumbNav} />
					<ButtonWrapper>
						<Button flex={[1, 1, 'initial']} onClick={() => history.push('add-products')}>
							<icons.PlusIcon />
							<div>Add New Product</div>
						</Button>
						<Button variant="secondary" className="app-c-file-upload">
							<div>
								<icons.bulkUploadIcon />

								<input
									type="file"
									accept=".csv"
									className="buFileUpload"
									onChange={(e) => {
										setIsLoading(true);
										fileUploadHandler(e, submitData);
									}}
								/>
								<label>Upload File</label>
							</div>

							{fileName && <Text as="h2">{fileName}</Text>}
						</Button>

						{/* <Button
							disabled={products?.length == 0 && errorList.length == 0}
							flex={[1, 1, 'initial']}
							variant="secondary"
							onClick={bulkUploadProducts}
							className="buBulkUpload"
						>
							<icons.bulk_uploadIcon />
							<div>Bulk Upload</div>
						</Button> */}
						<Link to="/template.csv" target="_blank" download style={{ textDecoration: 'none' }}>
							<Button flex={[1, 1, 'initial']} variant="secondary">
								<icons.downloadIcon />
								<div>Sample Template</div>
							</Button>
						</Link>
					</ButtonWrapper>
				</BreadcrumbHolder>
				<PageSearchHolder>
					<Box as="section">
						<Search
							onChange={(val) => setFilteredData(val)}
							type={'productManagement'}
							placeholder="Search for Product Name, SKU, Price ..."
						></Search>
					</Box>
					{/* <Box as="small">
						<Button flex={[1, 1, 'initial']} variant="redOutline">
							<icons.DeleteIcon />
							<div>Delete Selected</div>
						</Button>
					</Box> */}
				</PageSearchHolder>
				<TableList>
					<table>
						<thead>
							<tr>
								{/* <th className="checked">
									<Label>
										<Checkbox id="remember" name="remember" />
									</Label>
								</th> */}
								<th>Product name</th>
								<th>Supplier SKU</th>
								<th>Price</th>
								<th className="status">Status</th>
								<th className="action">Action</th>
							</tr>
						</thead>

						<tbody>
							{productList?.length ? (
								(productList).map(
									(
										{
											id,
											images,
											name,
											price,
											qty,
											qty_confirmed,
											qty_sold,
											sku,
											supplier_sku,
											status,
											type_id,
										},
										index
									) => (
										<tr key={index} className={status == 2 ? 'row-highlighted' : ''}>
											{/* <td className="checked">
												<Label>
													<Checkbox id="remember" name="remember" />
												</Label>
											</td> */}
											<td data-label="Product name">
												<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
													<Image
														src={images == '' ? no_image : images}
														onError={(e) => {
															e.currentTarget.src = no_image;
														}}
														sx={{
															width: 55,
															height: 55,
															borderRadius: 8,
														}}
													/>

													<span>{name}</span>
												</Text>
											</td>
											<td data-label="SKU">
												<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
													{supplier_sku}
												</Text>
											</td>
											<td data-label="Price">
												<Text as="h1" fontSize={15} fontFamily="OpenSansMedium" color="text.black">
													${parseFloat(price ? price : 0).toFixed(2)}
												</Text>
											</td>
											<td className="status" data-label="Status">
												<Toggle
													checked={status == 2 ? false : true}
													onChange={(e) => confirm('upd', sku, e)}
												/>
											</td>
											<td className="action" data-label="Action">
												<Text>
													<Button
														variant="deleteIcon"
														disabled={!id ? true : false}
														onClick={() => confirm('del', id)}
													>
														<icons.DeleteIcon />
													</Button>
													<Button variant="editIcon" onClick={() => navigateToEdit(sku)}>
														<icons.textEditIcon />
													</Button>
												</Text>
											</td>
										</tr>
									)
								)
							) : (
								<tr>
									<td>
										<UserBody>
											{/* <Button>
		<Icon.noUser title="User Icon" />
	</Button> */}
											<Text fontSize={18} fontWeight="bold" color="#5C5E57" textAlign="center">
												No Products Found
											</Text>
										</UserBody>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</TableList>
				<SweetAlert
					show={showPopup}
					confirmBtnText="Confirm"
					onConfirm={onSubmit}
					showCancel={true}
					onCancel={closePopup}
					title={'Confirmation Box'}
				>
					{() => (
						<form>
							<hr />
							{modalText}
							<hr />
						</form>
					)}
				</SweetAlert>
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
			<Modal width="550px" showModal={showBulkUploadModal} setShowModal={setShowBulkUploadModal}>
				<BulkUploadModal errorList={errorList} toggleBulkUploadModal={toggleBulkUploadModal} />
			</Modal>
		</>
	);
}

export default ProductManagement;
