import { UserBody } from 'admin/dashboardVenue/styles';
import { OrderStatuses, venueUserPermissionList } from 'models/enum-constants/common.constants';
import Iget from 'models/Iget';
import urlObj from 'models/url.obj';
import { setgroups } from 'process';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { Button, Search, SortBy, Loader } from 'ui/components';
import icons, * as Icon from 'ui/icons';
import { Modal, RecentOrderFilter, OrderGrid } from 'ui/patterns';
import AppContext from 'utils/appContext';
import env from 'utils/constants';
import urls from 'utils/create.url';
import helperFunctions from 'utils/helper.functions';
import httpService from 'utils/http.service';
import pageConstants from 'utils/pageConstants';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import notify from 'utils/toast.service';
import utilityFunctions from 'utils/utility.functions';
import CancelOrderMessageModal from '../recentOrderDetailsEditView/cancelOrderMessage';
import NewFavoritesModal from '../recentOrderDetailsEditView/newFavorites';
import { UseGlobalState } from 'models/globalStateAccess';
import Ipost from 'models/Ipost';

const StyledOrderListView = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	column-gap: 20px;
	row-gap: 20px;
	article {
		flex-direction: row;
		background: ${({ theme }) => theme.colors.bg.white};
		border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
		box-sizing: border-box;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 16px;
		overflow: hidden;
		transition: box-shadow 100ms ease-in, transform 100ms ease-in;
		align-items: flex-start;
		cursor: pointer;
		&:hover {
			transform: translateY(-5px);
			box-shadow: 0 20px 20px #eee;
			border: 1px solid ${({ theme }) => theme.baseColors.baseYellowLight};
		}
		&.disabled {
			opacity: 0.3;
			pointer-events: none;
		}
		span {
			max-width: 120px;
			flex: 0 0 120px;
			height: 100%;
			background-repeat: no-repeat;
			background-size: cover;
			min-height: 130px;
			@media (max-width: 768px) {
				max-width: 126px;
				flex: 0 0 126px;
			}
			@media (max-width: 480px) {
				max-width: 100px;
				flex: 0 0 100px;
			}
		}
		section {
			display: grid !important;
			grid-template-columns: repeat(6, 1fr) !important;
			height: 100%;
			padding: 20px 30px;
			align-items: center;
			column-gap: 10px;
			div{
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				display: block;
			}
			@media (max-width: 1440px) {
				padding: 20px;
				grid-template-columns: repeat(3, 1fr) !important;
				row-gap: 20px;
			}
			@media (max-width: 768px) {
				grid-template-columns: repeat(2, 1fr) !important;
				h1 {
					margin-top: 14px;
				}
				h2 {
					margin-bottom: 17px;
				}
			}
			@media (max-width: 340px) {
				grid-template-columns: repeat(1, 1fr) !important;
			}

			label {
				display: flex;
				div {
					cursor: pointer;
					margin: 4px 0 0 4px;
					svg {
						width: 16px;
						height: 16px;
					}
					span {
						height: auto;
						min-height: auto;
					}
				}
				strong {
					font-family: 'OpenSansMedium' !important;
				}
			}
			slot {
				width: 365px;
				display: flex;
				@media (max-width: 768px) {
					width: 100%;
				}
				@media (max-width: 480px) {
					grid-column: 3 / 1;
					grid-row: auto / auto;
					div {
						button {
							span {
								white-space: nowrap;
							}
						}
					}
				}
				@media (max-width: 340px) {
					grid-column: 2 / 1;
					grid-row: auto / auto;
				}
			}
			.btn-block {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				@media (max-width: 860px) {
					justify-content: flex-start;
				}
				@media (max-width: 768px) {
					justify-content: flex-start;
					button {
						margin-top: 5px;
					}
				}
				@media (max-width: 640px) {
					button {
						padding: 0 10px !important;
						font-size: 13px !important;
					}
				}
				@media (max-width: 340px) {
					flex-wrap: wrap;
				}
				span {
					height: auto;
					flex: 0 0 auto;
					max-width: auto;
					min-width: auto;
				}
				.Favorites {
					width: 50px;
					flex: 0 0 50px;
				}
				button {
					height: 50px;
					margin-right: 10px;
					margin-top: 5px;
					margin-bottom: 5px;
					@media (max-width: 860px) {
						margin-left: 0;
						margin-right: 10px;
					}
					span {
						height: auto;
						min-height: auto;
					}
					svg {
						width: 20px;
						height: 20px;
						path {
							fill: ${({ theme }) => theme.baseColors.primary};
						}
					}
					&:hover,
					&:focus {
						svg {
							width: 20px;
							height: 20px;
							path {
								fill: ${({ theme }) => theme.baseColors.white};
							}
						}
					}
					+ button {
						display: flex;
						align-items: center;
						text-transform: capitalize;
						white-space: nowrap;
						padding: 0 1.5em !important;
						width: auto;

						@media (max-width: 480px) {
							font-size: 13px;
							padding: 0 10px !important;

							white-space: nowrap;
						}
						span {
							max-width: 100% !important;
							min-height: auto;
						}
						+ div button {
							width: auto;
							padding: 0 1.5em !important;
							@media (max-width: 640px) {
								padding: 0 10px !important;
								font-size: 13px !important;
							}
						}
					}
				}

				icon {
					display: inline-block;
				}
				div {
					word-break: break-word;
				}
			}
			.totalProducts{
				max-width: 110px;
				text-align: right;
				white-space: nowrap;

			}
		}
	}
`;

const PageSearchHolder = styled.div`
	width: 100%;
	padding-top: 15px;
	padding-bottom: 10px;
	display: flex;
	justify-content: space-between;
	div {
		width: 650px;
		@media screen and (max-width: 1300px) {
			width: auto;
			flex: 1;
			margin-right: 20px;
		}
		@media screen and (max-width: 860px) {
			margin-right: 0;
		}

		+ div {
			width: 254px;
			margin-right: 0;
			@media screen and (max-width: 860px) {
				width: 100%;
				margin-top: 10px;
			}
		}
	}
	section {
		display: flex;
		div {
			width: 254px;
			+ div {
				margin-left: 20px;
			}
			@media screen and (max-width: 860px) {
				width: 100%;
				margin-top: 10px;
				div {
					margin-top: 0;
				}
			}
		}
	}
	@media screen and (max-width: 860px) {
		flex-direction: column;
	}
	@media screen and (max-width: 768px) {
		width: 100%;
	}
`;

function RecentOrderListView(): ReactElement {
	const [showNewFavoritesModal, setShowNewFavoritesModal] = useState(false);
	const toggleNewFavoritesModal = (e, id, qty) => {
		e.stopPropagation();
		setObj({ product_id: id, qty: qty });
		setShowNewFavoritesModal(!showNewFavoritesModal);
	};
	const [showModal, setShowModal] = useState(false);
	let history = useHistory();
	const ctx = useContext(AppContext).productSearchObj;
	const cartCtx = useContext(AppContext).cartObj;
	const [url, setSearchUrl] = useState('');
	const [orderList, setOrderList] = useState([] as any);
	const [postObj, setObj] = useState({} as any);
	const [modalText, setmodalText] = useState('');
	const [action, setAction] = useState('');
	const [searchText, setSearchText] = useState('');
	const [filterOption, setFilterOption] = useState('');
	const [showCancelOrderMessageModal, setShowCancelOrderMessageModal] = useState(false);
	const [orderId, setOrderId] = useState(0);
	const [showOrderGridModal, setShowOrderGridModal] = useState(false);
	const [productGridData, setProductGridData] = useState([]);
	const [disableBtnMove, setDisableBtnMove] = useState(false);
	const [productsToMoveArr, setProductsToMoveArr] = useState([] as any);
	const [venueUserId, setVenueUserId] = useState<any>(localStorage.getItem('userId'));
	const [venueId, setVenueId] = useState<any>(localStorage.getItem('selectedVenue'));
	const [state, dispatch] = UseGlobalState();
	const [isLoading, setIsLoading] = useState(false);

	let urlObj: Partial<urlObj> = {
		url: 'experion/order/search?',
		pageSize: 2000,
		filterDeleted: true,
		ctx: ctx,
		fields: [
			{ field_name: 'venue_id', field_value: localStorage.getItem('selectedVenue')! },
			{ field_name: 'venue_user_id', field_value: localStorage.getItem('userId')! },
			{ field_name: 'for_approval', field_value: '0,2', field_conditionType: 'in' },
		],
	};
	const getKey = () => {
		return `recent${ctx.SortField}${ctx.SortDirection}`;
	};

	const obj: Partial<Iget> = {
		url: urls.GetSearchUrl(urlObj),
		enabled: true,
		refetchOnWindowFocus: false,
		resultFilteringRequired: true,
		key: getKey(),
	};
	const recentOrderListQry = hooks.useQuery(obj);

	const handleSort = (val) => {
		let sortData = getSortData(val);
		ctx.SortField = sortData.field;
		ctx.SortDirection = sortData.direction;
		setSearchUrl(urls.GetSearchUrl(urlObj));
	};

	const getSortData = (val) => {
		let obj = { field: '', direction: '' };
		switch (val) {
			case 'Low - High Price':
				obj.field = 'cart_total';
				obj.direction = 'asc';
				break;
			case 'High - Low Price':
				obj.field = 'cart_total';
				obj.direction = 'desc';
				break;
			default:
				break;
		}
		return obj;
	};

	const setFilteredData = (val) => {
		setSearchText(val);
	};

	const setFilterOnStatus = (val) => {
		setFilterOption(val);
	};

	useEffect(() => {
		filterOrderData();
	}, [searchText, filterOption]);

	const filterOrderData = () => {
		if (recentOrderListQry.data) {
			if (
				(searchText && searchText.length > 0) ||
				(filterOption && filterOption.length > 0 && filterOption !== 'All')
			) {
				var list = recentOrderListQry.data;
				if (searchText && searchText.length > 0) {
					list = list.filter(
						(v) => {
							let statusList = getStatusList(v.status);
							let statusName = [] as any;
							statusList && statusList.map((m) => {
								if (m.status == OrderStatuses.Rejected) {
									m.status = 'Rejected';
								} else {
									m.status = m.status?.replace('_', ' ');
								}
								statusName.push(m.status);
							});
							let status = statusName?.join(',');
							return (
								String('#' + v.order_group_increment_id).toLowerCase().includes(searchText.toLowerCase()) ||
								status?.toLowerCase().includes(searchText.toLowerCase()) ||
								String('$' + parseFloat(v.cart_total).toFixed(2)).toLowerCase().includes(searchText.toLowerCase()) ||
								String(v.no_of_items).toLowerCase().includes(searchText.toLowerCase()) ||
								helperFunctions.formatDate(v.created_at).toLowerCase().includes(searchText.toLowerCase()))
						}
					);
				}
				if (filterOption && filterOption.length > 0 && filterOption !== 'All') {
					list = list.filter((v) => {
						let statusList = getStatusList(v.status);
						let orderStatus = utilityFunctions.getOrderStatus(statusList.map((x) => x.status));
						return orderStatus === filterOption;
					});
				}
				setOrderList(list);
			} else {
				setOrderList(recentOrderListQry.data);
			}
		}
	};

	useEffect(() => {
		setOrderList(recentOrderListQry.data);
		filterOrderData();
	}, [recentOrderListQry.data]);

	const [message, setMessage] = useState('');

	function criteriaCheck(id) {
		const payload = {
			reorder: {
				quote_id: localStorage.getItem('cartId'),
				venue_id: localStorage.getItem('selectedVenue'),
				group_order_id: id,
			},
		};
		api
			.post({
				data: payload,
				url: 'experion/grouporder/reorder/',
				key: 'recentToCart',
				message: ' ',
			})
			.then(function (data) {
				if (data != undefined) {
					let cart_status = data[0].cart_status;
					let productsToMoveArr = [] as any;

					const productData = data[0].order_details.map((x) =>
						utilityFunctions.OrderGridProductData(
							x.product_data,
							x.sensitive_data,
							x.non_sensitive_data
						)
					);
					const productDataArray = productData.map((x) =>
						utilityFunctions.productsAvailable(x.product_id, x.qty_ordered, x.sensWarning)
					);
					productDataArray.map((x) => {
						if (x && x.productsObj && Object.keys(x.productsObj).length) {
							productsToMoveArr.push(x.productsObj);
						}
					});

					setProductsToMoveArr(productsToMoveArr);
					if (productsToMoveArr.length > 0) {
						setDisableBtnMove(false);
					} else {
						setDisableBtnMove(true);
					}

					if (cart_status == true) {
						confirm('order', id, 0);
					} else if (cart_status == false) {
						setProductGridData(productData);
						setShowOrderGridModal(!showOrderGridModal);
					}
				}
			});
	}

	function confirm(action, id, qty) {
		setAction(action);
		switch (action) {
			case 'favorite':
				showConfirmationModal('Delete order?', id, qty);
				break;
			case 'order':
				showConfirmationModal('Order again ?', id, qty);
				break;
			case 'cancelOrder':
				showConfirmationModal('Cancel order ?', id, qty);
				break;
			default:
				break;
		}
	}
	function showConfirmationModal(text, id = 0, qty = 0) {
		if (id != 0) setObj({ product_id: id, qty: qty });
		setmodalText(text);
		setShowModal(!showModal);
	}
	async function closePopup() {
		await setShowModal(!showModal);
	}
	function onSubmit() {
		setShowModal(!showModal);
		if (action == 'order') addItemsToCart();
		else if (action == 'cancelOrder') cancelOrder();
	}

	var cartId: any;
	cartId = localStorage.getItem('cartId');

	async function addItemsToCart() {
		setIsLoading(true);
		let id: any;
		let CUSTOMER_TOKEN = localStorage.getItem('customerToken');
		id = await utilityFunctions.getCartId(CUSTOMER_TOKEN);
		if (id !== undefined) {
			if (productsToMoveArr.length > 0) {
				let payload = {
					products: {
						quote_id: parseInt(id),
						items: productsToMoveArr,
						venue_user_id: parseInt(venueUserId),
						venue_id: parseInt(venueId),
						reorder: 1,
					},
				};
				const obj: Partial<Ipost> = {
					data: payload,
					key: 'recentToCart',
					url: env.multiCartURL(),
					message: 'Your cart has been updated!',
					headers: { Authorization: localStorage.getItem('customerToken') },
				};

				try {
					await api
						.post(obj)
						.then(function (data) {
							if (data !== undefined) {
								setIsLoading(false);
								fetchTotal();
							} else {
								setIsLoading(false);
							}
						})
						.catch(function (data) {
							setIsLoading(false);
						});
				} catch (e) {
					setIsLoading(false);
				}
			}
		} else {
			setIsLoading(false);
		}
	}

	//api to get total amount
	const url1 = '/carts/' + cartId + '/totals';

	const obj6: Partial<Iget> = {
		params: {},
		url: url1,
		enabled: false, //manual fetch
		key: 'carttotalKey1',
		resultFilteringRequired: false,
	};
	var qryTotal = hooks.useQuery(obj6);

	const fetchTotal = () => {
		qryTotal.refetch().then((data) => {
			if (data) {
				updateCartTotal(data);
			}
		});
	};

	function updateCartTotal(data) {
		if (data) {
			let datas = data.items?.filter((x) => x.is_deleted != true);
			if (datas?.length > 0) {
				dispatch({ cartTotal: helperFunctions.formatAmount(data.grand_total) });
				dispatch({ cartItemCount: datas?.length });
				// setTotalCount(data.subtotal);
			} else {
				dispatch({ cartItemCount: '0' });
				dispatch({ cartTotal: '' });
				// setTotalCount(0);
			}
		} else {
			dispatch({ cartItemCount: '0' });
			dispatch({ cartTotal: '' });
			// setTotalCount(0);
		}
	}

	function cancelOrderPopup(message) {
		setMessage(message);
		setAction('cancelOrder');
		showConfirmationModal('Cancel the order?');
	}

	function updateList() { }

	function getStatusList(status) {
		let data = [] as any;
		if (status.length > 0) {
			if (status.length > 1) {
				let items = JSON.parse(JSON.stringify(status));
				items.map((v, i) => {
					data.push(JSON.parse(v));
				});
			} else {
				data.push(JSON.parse(status));
			}
		}
		return data;
	}

	async function cancelOrder() {
		let hasError = false;
		let supplierDetails = getStatusList(orderList.find((x) => x.order_group_id == orderId)?.status);
		if (supplierDetails?.length > 0) {
			await supplierDetails?.map((item, i) => {
				httpService
					.PUT({ url: `orders/create`, data: getData(message, item.order_id, item.customer_email) })
					.then(function (data) {
						{
							if (typeof data == 'undefined') hasError = true;
							showMsg(hasError);
						}
					})
					.catch(function (data) {
						hasError = true;
						showMsg(hasError);
					});
			});
		} else {
			notify('No status details found !!').info();
		}
	}

	function showMsg(hasError) {
		if (!hasError) {
			notify('Order cancelled successfully').success();
			recentOrderListQry.refetch();
			// window.location.href = window.location.href.replace("#!","");
		} else notify('Order cancellation failed').error();
	}

	function getData(msg, id, mail) {
		return {
			entity: {
				entity_id: id,
				state: 'canceled',
				status: 'canceled',
				status_histories: [
					{
						comment: msg,
						entity_name: 'order',
						is_customer_notified: 1,
						is_visible_on_front: 1,
						parent_id: id,
						status: 'canceled',
					},
				],
				extension_attributes: {
					receiver_email: mail,
				},
			},
		};
	}

	function showCancelMessagePopup(e, order_group_id) {
		e.stopPropagation();
		setShowCancelOrderMessageModal(!showCancelOrderMessageModal);
		setOrderId(order_group_id);
	}

	return (
		<>
			{isLoading ? <Loader></Loader> : ''}
			<StyledOrderListView>
				<PageSearchHolder>
					<Search onChange={(val) => setFilteredData(val)}></Search>
					<Box as="section">
						<RecentOrderFilter onSort={setFilterOnStatus}></RecentOrderFilter>
						<SortBy onSort={handleSort} removeNameSort={true}></SortBy>
					</Box>
				</PageSearchHolder>
				{!recentOrderListQry.isFetching && orderList?.length > 0 ? (
					orderList.map(
						(
							{
								order_group_id,
								order_group_increment_id,
								no_of_items,
								cart_total,
								status,
								created_at,
							},
							i
						) => {
							let statusList = getStatusList(status);
							let orderStatus = utilityFunctions.getOrderStatus(statusList.map((x) => x.status));
							// let diff = moment.duration(moment(new Date()).diff(moment(created_at)));
							// let diffHours = diff.hours() +(diff.days() * 24);
							let eligibleForCancel = true;
							eligibleForCancel =
								[
									OrderStatuses.Cancelled,
									OrderStatuses.Closed,
									OrderStatuses.Rejected,
									OrderStatuses.Disputed,
									OrderStatuses.Confirmed,
									OrderStatuses.Delivered,
								].includes(orderStatus) || statusList?.length == 0
									? false
									: true;
							return (
								<Flex
									onClick={() => history.push(`/store/myOrderDetails?id=${order_group_id}`)}
									as="article"
									bg="white"
									sx={{ alignItems: 'center' }}
									key={i}
								// className="disabled"
								>
									<Flex as="section" flexWrap="wrap" width="100%" maxWidth={['100%']}>
										<Box>
											<Text fontSize={16} fontWeight="normal" color="#242B24">
												Order ID
											</Text>
											<Text fontSize={18} fontWeight="bold" color="#242B24">
												{'#' + order_group_increment_id}
											</Text>
										</Box>
										<Box className="totalProducts">
											<Text fontSize={16} fontWeight="normal" color="#242B24">
												Total Products
											</Text>
											<Text fontSize={18} fontWeight="bold" color="#242B24">
												{no_of_items}
											</Text>
										</Box>
										<Box>
											<Text fontSize={16} fontWeight="normal" color="#242B24">
												Created
											</Text>
											<Text fontSize={18} fontWeight="bold" color="#242B24">
												{helperFunctions.formatDate(created_at)}
											</Text>
										</Box>
										<Box
											as="object"
											onClick={(e) => {
												e.stopPropagation();
											}}
										>
											<Text as="label" fontSize={16} fontWeight="normal" color="#242B24">
												Status
												<Tooltip
													arrow
													animation="perspective"
													position="right"
													html={
														<div className="tooltip-body tooltip-content statusTooltip">
															{statusList &&
																statusList.map((v) => {
																	if (v.enable_cancel_button == false) eligibleForCancel = false;
																	return (
																		<span
																			key={helperFunctions.getRandomInt(pageConstants.maxValue)}
																		>
																			<Text
																				as="label"
																				fontSize="17px"
																				fontFamily="OpenSansRegular"
																				color="text.black03"
																			>
																				{`${v.supplier_name} :`}
																				<strong>
																					{helperFunctions.toPascalCase(
																						v.status == OrderStatuses.Rejected
																							? 'Rejected'
																							: v.status?.replace('_', ' ')
																					)}
																				</strong>
																			</Text>
																		</span>
																	);
																})}
														</div>
													}
												>
													{status?.length > 0 && <icons.InfoIconRound />}
												</Tooltip>
											</Text>
											<Text fontSize={18} fontWeight="bold" color="#242B24">
												{helperFunctions.toPascalCase(
													orderStatus == 'order_rejected'
														? 'Rejected'
														: orderStatus?.replace('_', ' ')
												)}
											</Text>
										</Box>
										<Box>
											<Text fontSize={16} fontWeight="normal" color="#242B24">
												Amount
											</Text>
											<Text fontSize={18} fontWeight="bold" color="#242B24">
												{helperFunctions.formatAmount(cart_total)}
											</Text>
										</Box>
										<Box
											as="slot"
											onClick={(e) => {
												e.stopPropagation();
											}}
										>
											<div className="btn-block">
												{utilityFunctions.isGranted(venueUserPermissionList.FavoriteOrderAdd) && (
													<Button
														variant="secondary"
														className="Favorites"
														onClick={(e) => {
															toggleNewFavoritesModal(e, order_group_id, no_of_items);
														}}
													>
														<Icon.FavoritesIcon title="FavoritesIcon" />
													</Button>
												)}
												{utilityFunctions.isGranted(
													venueUserPermissionList.ManageProductsinCart
												) && (
														<Button
															onClick={(e) => {
																e.stopPropagation();
																criteriaCheck(order_group_id);
															}}
														>
															Order Again
														</Button>
													)}

												<Tooltip
													arrow
													title={
														!eligibleForCancel
															? orderStatus == OrderStatuses.Pending
																? 'Cut off time exceeded'
																: 'Order has been processed'
															: ''
													}
													animation="perspective"
													position="top"
													trigger="mouseenter"
												>
													{(utilityFunctions.isGranted(
														venueUserPermissionList.RecentOrdersCancel
													) ||
														utilityFunctions.isGranted(
															venueUserPermissionList.RecentOrdersAdmin
														)) && (
															<Button
																disabled={!eligibleForCancel}
																onClick={(e) => {
																	showCancelMessagePopup(e, order_group_id);
																}}
																variant="secondary"
															>
																Cancel Order
															</Button>
														)}
												</Tooltip>
											</div>
										</Box>
									</Flex>
								</Flex>
							);
						}
					)
				) : !recentOrderListQry.isFetching ? (
					<UserBody>
						{/* <Icon.searchIcon title="No Data Found" /> */}
						<Text fontSize={18} fontWeight="bold" color="#5C5E57" textAlign="center">
							No Orders Found
						</Text>
					</UserBody>
				) : (
					new Array(2).fill(null).map((_, i) => {
						return <Skeleton key={i} width="1200px" height="50px" />;
					})
				)}
				<Modal
					width="500px"
					showModal={showNewFavoritesModal}
					setShowModal={setShowNewFavoritesModal}
				>
					<NewFavoritesModal
						updateList={updateList}
						showModal={showNewFavoritesModal}
						setShowModal={setShowNewFavoritesModal}
						orderId={postObj.product_id}
					/>
				</Modal>
				<Modal
					width="500px"
					showModal={showCancelOrderMessageModal}
					setShowModal={setShowCancelOrderMessageModal}
				>
					<CancelOrderMessageModal
						showModal={showCancelOrderMessageModal}
						setShowModal={setShowCancelOrderMessageModal}
						cancelOrder={cancelOrderPopup}
					/>
				</Modal>
				<SweetAlert
					show={showModal}
					confirmBtnText="Confirm"
					onConfirm={onSubmit}
					showCancel={true}
					onCancel={closePopup}
					title={'Confirmation Box'}
				>
					{() => (
						<form>
							<hr />
							{modalText}
							<hr />
						</form>
					)}
				</SweetAlert>
			</StyledOrderListView>
			<Modal width="800px" showModal={showOrderGridModal} setShowModal={setShowOrderGridModal}>
				<OrderGrid
					data={productGridData}
					setShowModal={setShowOrderGridModal}
					productsToMoveArr={productsToMoveArr}
					showModal={showOrderGridModal}
					disableBtnMove={disableBtnMove}
					fetchTotal={fetchTotal}
					utilityFunctions={utilityFunctions}
					setIsLoading={setIsLoading}
				/>
			</Modal>
		</>
	);
}

export { RecentOrderListView };
