import React, { ReactElement } from 'react';
import { Button, Logo } from 'ui/components';
import { HeaderMenu } from 'ui/patterns';
import * as Icon from '../../icons';
import { HeaderMobileView, StyledSidebar, AccordionPanel } from './styles';
import { userTypes } from 'models/enum-constants/common.constants';
import LogoFile from '../../images/logo_exp.png';
import Collapsible from 'react-collapsible';
import VenueDetails from 'models/AddVenue.model';
import { Flex, Box, Text } from 'rebass';

type TProps = {
	hide?: boolean;
	show?: boolean;
	toggleSideBar?: () => void;
	queryString?: () => void;
	selectedItem?: string;
	onChangeSelection?: (option: string) => void;
};

function Sidebar({
	hide,
	toggleSideBar,
	selectedItem,
	onChangeSelection,
	queryString,
}: TProps): ReactElement {
	let userAccessType = localStorage.getItem('userType');
	const closeSideBar = () => {
		toggleSideBar && toggleSideBar();
	}
	const userType =
		userAccessType == userTypes.venueAdmin || userAccessType == userTypes.venueUser
			? userTypes.HospitalityGroup
			: userTypes.Supplier;
	return (
		<StyledSidebar hide={hide} show={!hide}>
			<Button
				onClick={() => {
					toggleSideBar && toggleSideBar();
				}}
				iconBefore={<Icon.ModalClose />}
				variant="modalClose"
			></Button>
			<Logo route="/home/dashboard" src={LogoFile}></Logo>

			<HeaderMobileView>
				<HeaderMenu
					userAccessType={userAccessType}
					userType={userType}
					className="hide"
					queryString={queryString}
					closeHumburgur={closeSideBar}
				></HeaderMenu>
			</HeaderMobileView>
		</StyledSidebar>
	);
}

export { Sidebar, HeaderMobileView };
