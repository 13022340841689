import styled from 'styled-components';

const MainContent = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
	padding: 0 50px 50px;
	@media (max-width: 960px) {
		padding: 0 15px 50px;
	}
`;
const BreadcrumbNavHolder = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	@media (max-width: 860px) {
		div {
			padding-bottom: 0;
		}
	}
`;
const DCListHolder = styled.div`
	width: 100%;
	float: left;
	min-height: calc(100vh - 444px);
	h1 {
		margin-bottom: 20px;
		strong {
			font-family: 'OpenSansBold';
		}
	}
`;
const PageSearchHolder = styled.div`
	width: 100%;
	padding-top: 20px;
	padding-bottom: 25px;
	display: flex;
	justify-content: space-between;
	section {
		width: 650px;
		@media screen and (max-width: 767px) {
			width: auto;
			flex: 1;
		}
	}
	small {
		button {
			@media screen and (max-width: 960px) {
				width: 60px;
				flex: 0 0 60px;
				max-width: 60px;
				margin-left: 20px;
				height: 50px;
			}

			div {
				width: auto;
				@media screen and (max-width: 960px) {
					display: none;
				}
			}
			@media screen and (max-width: 960px) {
				svg {
					margin-right: 0;
				}
			}
		}
	}
`;
const ViewAllHeadingBlock = styled.div`
	display: flex !important;
	justify-content: space-between;
	color: ${({ theme }) => theme.colors.text.black04};
	margin: 20px 0 26px 0;
	align-items: center;
	min-height: 30px;
	h1,
	h2 {
		font-family: 'OpenSansBold';
		text-transform: uppercase;
		display: flex !important;
		align-items: center;
		position: relative;
		span {
			margin-right: 6px;
			svg {
				width: 25px;
				height: 22px;
			}
		}
	}
	h1,
	h2 a {
		font-weight: normal;
	}
	a {
		color: ${({ theme }) => theme.baseColors.baseYellow};
		text-decoration: none;
		font-family: 'OpenSansMedium';
		transition: ease-in all 200ms;

		&:hover {
			background: ${({ theme }) => theme.baseColors.baseYellow};
			padding: 3px 10px;
			color: ${({ theme }) => theme.baseColors.white};
			border-radius: 5px;
		}
	}
	h2 {
		width: 100%;
		margin-top: -20px;
		&:after,
		&:before {
			content: '';
			position: absolute;
			z-index: -1;
		}
		&:after {
			width: 125px;
			height: 10px;
			background: ${({ theme }) => theme.baseColors.white};
		}
		&:before {
			width: 100%;
			height: 1px;
			background: rgba(0, 0, 0, 0.15);
		}
	}
`;
export { MainContent, DCListHolder, BreadcrumbNavHolder, PageSearchHolder, ViewAllHeadingBlock };
