import React, { ReactElement, useEffect, useState } from 'react';
import Slider from 'react-slick';
import { Box } from 'rebass/styled-components';
import no_image from 'ui/images/no_image.png';
import { ProductMainImageHolder, ProductThumbsImageHolder, StyledProductMainImage } from './styles';


function ProductMainImage(props): ReactElement {
	let productThumbsImageView = [] as any;

	if (props.productDetails !== undefined) {
		productThumbsImageView = props.productDetails.media_gallery_entries;
	}
	const [mainImage, SetMainImage] = useState('');

	useEffect(() => {
		if(props.productDetails?.media_gallery_entries.length == 0){
			SetMainImage(no_image)
		}else
			SetMainImage(props.productDetails?.media_gallery_entries[0].file)
	}, [props.productDetails]);

	const settings = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 4,
		initialSlide: 1,
		responsive: [
			{
				breakpoint: 1360,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					initialSlide: 1,
					infinite: false,
					dots: true,
				},
			},
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					initialSlide: 1,
					infinite: false,
					dots: true,
				},
			},
		],
	};
	const onImageClick = (image) => {
		SetMainImage(image);
	};

	return (
		<StyledProductMainImage>
			{/* {ProductMainImageView.map(({ image }) => ( */}
			<ProductMainImageHolder>
				<img src={mainImage} alt="Product Logo" style={{ maxWidth: '100%' }} />
			</ProductMainImageHolder>
			{/* ))} */}
			<ProductThumbsImageHolder>
				<Slider {...settings}>
					{productThumbsImageView.map(({ id, file }) => (
						<Box key={id}>
							<img
								onClick={() => onImageClick(file)}
								src={file}
								alt="Product Logo"
								style={{ maxWidth: '100%' }}
							/>
						</Box>
					))}
				</Slider>
			</ProductThumbsImageHolder>
		</StyledProductMainImage>
	);
}

export { ProductMainImage };
