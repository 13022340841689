import styled, { css } from 'styled-components';
import { color, FlexProps, layout, LayoutProps, space, SpaceProps } from 'styled-system';

type PanelProps = LayoutProps &
	SpaceProps &
	FlexProps & {
		userInfoWrapper?: boolean;
		userInfoHolder?: boolean;
		userReward?: boolean;
		userRewardHolder?: boolean;
		merchUserInfo?: boolean;
	};
const Panel = styled.div<PanelProps>`
	${layout}
	${space}
	${color}
	width:100%;
	border-radius: ${({ theme }) => theme.radii.soft};
	border: solid 1px ${({ theme }) => theme.colors.border.default};
	box-shadow: ${({ theme }) => theme.shadows.medium};
	${({ userInfoWrapper }) =>
		userInfoWrapper &&
		css`
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
			overflow: hidden;
			border-radius: 20px;
			@media (max-width: 768px) {
				border-radius: 0px;
			}
		`}
`;

const PanelHeader = styled.div<PanelProps>`
	${layout}
	${space}
  ${color}

${({ userInfoHolder }) =>
		userInfoHolder &&
		css`
			background: ${({ theme }) => theme.colors.bg.userInfoHeader};
			padding: 18px 24px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: relative;
			@media (max-width: 1360px) {
				flex-direction: column;
				align-items: flex-start;
				& > * {
					width: 100%;
				}
			}
			@media (max-width: 768px) {
				padding: 14px;
			}
			& > * + * {
				@media (max-width: 640px) {
					position: relative;
					padding-bottom: 0;
				}
			}
		`}

		${({ merchUserInfo }) =>
		merchUserInfo &&
		css`
			background: ${({ theme }) => theme.colors.bg.userInfoHeader};
			padding: 18px 24px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: relative;
			@media (max-width: 1360px) {
				flex-direction: column;
				align-items: flex-start;
				& > * {
					width: 100%;
				}
			}
			@media (max-width: 768px) {
				padding: 14px;
			}
			& > * {
				@media (max-width: 640px) {
					position: relative;
					padding-bottom: 82px;
				}
			}
			& > * + * {
				@media (max-width: 640px) {
					position: relative;
					padding-bottom: 0;
				}
			}
		`}
`;

const PanelBody = styled.div<PanelProps>`
	${layout}
	${space}
  ${color}
  ${({ userRewardHolder }) =>
		userRewardHolder &&
		css`
			background: ${({ theme }) => theme.colors.bg.lightYellow};
			padding: 25px;
			float: left;
			width: 100%;
			h2 {
				line-height: 22px;
			}
			@media (max-width: 768px) {
				padding: 20px 14px 14px;
			}

			.selectFiled {
				align-items: center;
				div {
					width: 140px;
					select {
						padding: 0 0 0 15px;
						border: none;
						outline: none;
						color: ${({ theme }) => theme.colors.text.black};
						font-family: 'OpenSansBold';
						font-size: 18px;
						position: relative;
						z-index: 1;
						+ svg {
							display: none;
						}
					}
					+ span {
						margin-left: -15px;
						float: right;
						height: 8px;

						margin-top: 5px;

						svg {
							float: right;
						}
					}
				}
			}
		`}
`;

export { Panel, PanelHeader, PanelBody };
