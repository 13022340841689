import Iget from 'models/Iget';
import IgqlPost from 'models/IgqlPost';
import Ipost from 'models/Ipost';
import productSearchObj from 'models/pageObjects/productlist.ctx';
import { useContext } from 'react';
import { queryCache, useMutation, usePaginatedQuery, useQuery } from 'react-query';
import AppContext from 'utils/appContext';
import urls from 'utils/create.url';
import mutation from 'utils/requestCtx/mutation';
import utilityFunctions from 'utils/utility.functions';
import api from './api.service';
const hooks = {
	//get configuration
	useQuery: (obj: Partial<Iget>) => {
		return useQuery(
			obj.key,
			() => {
				return obj.caching
					? typeof queryCache.getQueryData(obj.key) != 'undefined'
						? queryCache.getQueryData(obj.key)
						: hooks.getData(obj, null as any)
					: hooks.getData(obj, null as any);
			},
			configs(obj)
		);
	},
	useGraphQlQuery: (obj: Partial<IgqlPost>) => {
		let ctx = null as any;
		ctx = ctx ?? useContext(AppContext).productSearchObj;
		const key = getUniqueKey(obj, ctx);
		ctx.radius = '0-1000';
		return useQuery(
			key,
			() => {
				return hooks.getGqlData(obj, ctx);
			},
			configs(obj)
		);
	},
	usePaginatedQuery: (obj: Partial<Iget>) => {
		let ctx = null as any;
		ctx = ctx ?? useContext(AppContext).productSearchObj;
		obj.url = !obj.url ? urls.GetSearchUrl(utilityFunctions.getFields(obj.urlObj!)) : obj.url;
		const key = getUniqueKey(obj, ctx);
		//paginated query call to get result
		return usePaginatedQuery(
			[key],
			() => {
				return typeof queryCache.getQueryData(key) != 'undefined'
					? queryCache.getQueryData(key)
					: hooks.getData(obj, ctx, key);
			},
			configs(obj)
		);
	},
	useMutation: (userKey) => {
		return useMutationFn(userKey);
	},
	getGqlData: async (obj: Partial<IgqlPost>, ctx: productSearchObj) => {
		obj.query = ctx.query;

		if (ctx.query == '' || ctx.Type == '') return;
		const data = await api.gqlPost(obj).then(function (res) {
			if (obj.caching) {
				queryCache.setQueryData(obj.key, res);
			}
			return res;
		});
		return data;
	},
	getData: async (obj: Partial<Iget>, ctx: productSearchObj, key = '') => {
		if (!obj.url) return;
		if (obj.pageNumber) {
			if (key == '') key = getUniqueKey(obj, ctx);
			obj.url += `&searchCriteria[currentPage]=${obj.pageNumber}`;
		}
		
		const data = await api.get(obj).then(function (res) {
			if (obj.caching) {
				queryCache.setQueryData(obj.pageNumber ? key : obj.key, res);
			}
			return res;
		});
		return data;
	},
};

const configs = (obj: Partial<Iget>) => {
	return {
		// if needed we can set initial data here
		//initialData: [{first_name:'user'}],
		enabled: obj.enabled,
		refetchOnWindowFocus: obj.refetchOnWindowFocus,
		retry: 0,
	};
};

function getUniqueKey(obj: Partial<Iget>, ctx: productSearchObj) {
	return `${obj.key}${obj.pageNumber ? obj.pageNumber : ''}${ctx.Type}${
		typeof ctx.SearchText != 'undefined' ? ctx.SearchText : ''
	}${ctx.SortField}${ctx.SortDirection}${
		typeof ctx.Categories != 'undefined' ? ctx.Categories : ''
	}${typeof ctx.Suppliers != 'undefined' ? ctx.Suppliers : ''}${
		typeof ctx.Allergens != 'undefined' ? ctx.Allergens : ''
	}${ctx.PriceFrom > 0 ? ctx.PriceFrom : ''}${ctx.PriceTo > 0 ? ctx.PriceTo : ''}${
		typeof ctx.skuList != 'undefined' ? ctx.skuList : ''
	}${
		ctx.specialProductsView && typeof ctx.specialProductsView != 'undefined'
			? 'specialProducts'
			: ''
	}${typeof ctx.newArrivalDate != 'undefined' ? ctx.newArrivalDate : ''}${
		typeof ctx.location.lat != 'undefined' ? ctx.location.lat : ''
	}`;
}

function useMutationFn(userKey) {
	return useMutation((data: Partial<Ipost>) => api.post(data), {
		// Optimistically update the cache value on mutate, but store
		// the old value and return it so that it's accessible in case of
		// an error
		//ui will update immediately without wait for result from api
		onMutate: (obj) => mutation.mutate(userKey, obj.data),
		// On failure, roll back to the previous value
		onError: (err, values, previousValue) => mutation.error(userKey, err, values, previousValue),
		// After success or failure, refetch the data from api
		onSettled: (data) => mutation.settled(userKey, data),
	});
}

export default hooks;
