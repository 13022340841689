import React, { ReactElement } from 'react';
import { Box, Flex, Image, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { Button } from 'ui/components';
import Dairy from '../../images/allergens/diary.svg';
import Egg from '../../images/allergens/egg.svg';
import Fish from '../../images/allergens/fish.svg';
import Gluten from '../../images/allergens/gluten.svg';
import Lupin from '../../images/allergens/lupin.svg';
import Milk from '../../images/allergens/milk.svg';
import Peanuts from '../../images/allergens/peanuts.svg';
import SesameSeeds from '../../images/allergens/sesame-seeds.svg';
import Shellfish from '../../images/allergens/shellfish.svg';
import Soy from '../../images/allergens/soy.svg';
import TreeNuts from '../../images/allergens/treenut.svg';

const ProductAllergensWrap = styled.div`
	display: flex;
	float: left;
	width: 100%;
	margin-top: 40px;
	flex-direction: column;
	padding-top: 40px;
	border-top: 1px solid ${({ theme }) => theme.colors.border.grayLightBorder01};

	@media (max-width: 860px) {
		margin-top: 30px;
		padding-top: 30px;
		width: calc(100% + 30px);
		padding: 30px 15px;
		margin: 30px 0 0 -15px;
		border-bottom: 1px solid ${({ theme }) => theme.colors.border.grayLightBorder01};
	}
	h1 {
		text-transform: uppercase;
		margin-bottom: 33px;
		@media (max-width: 860px) {
			font-size: 17px;
			margin-bottom: 24px;
		}
	}
	section {
		justify-content: center;
		margin: 50px 0;
		position: relative;
		display: flex;
		button {
			text-transform: capitalize;
			position: relative;
			z-index: 1;
			text-decoration: underline;
		}
		&:after {
			content: '';
			border-bottom: 1px solid ${({ theme }) => theme.colors.border.grayLightBorder01};
			position: absolute;
			width: 100%;
			left: 0;
			top: 25px;
		}
	}
`;
const ProductAllergensHolder = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	column-gap: 20px;
	row-gap: 60px;
	@media (max-width: 1540px) {
		grid-template-columns: repeat(3, 1fr);
	}
	@media (max-width: 1024px) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media (max-width: 860px) {
		row-gap: 30px;
		grid-template-columns: repeat(3, 1fr);
	}
	@media (max-width: 640px) {
		grid-template-columns: repeat(2, 1fr);
		row-gap: 20px;
	}
	article {
		display: flex;
		align-items: center;
		img{
			width:50px;
		}
		h2 {
			margin-left: 15px;
		}
		@media (max-width: 640px) {
			h2 {
				font-size: 14px;
			}
			svg {
				width: 40px;
				height: 40px;
			}
		}
	}
`;

function ProductAllergens(props): ReactElement {
	const formatString = (val) => {
		let temp = val;
		temp = temp.replace(/\s+/g, '');
		temp = temp.toLowerCase();
		switch (temp) {
			case 'gluten':
				return Gluten;
				break;
			case 'egg':
				return Egg;
				break;
			case 'dairy':
				return Dairy;
				break;
			case 'shellfish':
				return Shellfish;
				break;
			case 'fish':
				return Fish;
				break;
			case 'lupin':
				return Lupin;
				break;
			case 'milk':
				return Milk;
				break;
			case 'peanuts':
				return Peanuts;
				break;
			case 'treenuts':
				return TreeNuts;
				break;
			case 'sesameseeds':
				return SesameSeeds;
				break;
			case 'soy':
				return Soy;
				break;
			default:
				break;
		}
	};
	return (
		<ProductAllergensWrap>
			{props.productAllegens?.extension_attributes.allergen_names.length > 0 && (
				<Text
					as="h1"
					fontFamily="OpenSansBold"
					fontSize={18}
					lineHeight="23px"
					color="text.black"
					className="truncate"
				>
					Allergens
				</Text>
			)}

			<ProductAllergensHolder>
				{props.productAllegens?.extension_attributes.allergen_names.map((item) => (
					<Flex as="article" key={Math.random()}>
						<div className="img-block">
							<Image src={formatString(item)} />
						</div>
						<Text
							as="h2"
							fontFamily="OpenSansBold"
							fontSize={18}
							lineHeight="23px"
							color="text.black"
						>
							{item}
						</Text>
					</Flex>
				))}

				{/* <Flex as="article">
					<icons.NoStarchIcon />
					<Text
						as="h2"
						fontFamily="OpenSansBold"
						fontSize={18}
						lineHeight="23px"
						color="text.black"
					>
						No Starch
					</Text>
				</Flex> */}
				{/* <Flex as="article">
					<icons.CornFreeIcon />
					<Text
						as="h2"
						fontFamily="OpenSansBold"
						fontSize={18}
						lineHeight="23px"
						color="text.black"
					>
						Corn Free
					</Text>
				</Flex>
				<Flex as="article">
					<icons.OrganicIcon />
					<Text
						as="h2"
						fontFamily="OpenSansBold"
						fontSize={18}
						lineHeight="23px"
						color="text.black"
					>
						Organic
					</Text>
				</Flex>
				<Flex as="article">
					<icons.GroundedIcon />
					<Text
						as="h2"
						fontFamily="OpenSansBold"
						fontSize={18}
						lineHeight="23px"
						color="text.black"
					>
						Grounded
					</Text>
				</Flex>
				<Flex as="article">
					<icons.VegetarianIcon />
					<Text
						as="h2"
						fontFamily="OpenSansBold"
						fontSize={18}
						lineHeight="23px"
						color="text.black"
					>
						Vegetarian
					</Text>
				</Flex> */}
			</ProductAllergensHolder>
			{props.productAllegens?.extension_attributes.allergen_names.length > 0 && (
				<Box as="section">
					<Button variant="secondary" style={{ textDecoration: 'none' }}>
						Click here to learn about allergens.
					</Button>
				</Box>
			)}
		</ProductAllergensWrap>
	);
}

export { ProductAllergens };
