import { Select } from '@rebass/forms';
import queryString from 'query-string';
import _debounce from 'lodash/debounce';
import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AppContext from 'utils/appContext';
import * as Icon from '../../icons';
import { PageSearch, PageSearchHolder } from './styles';

function HeaderSearch(prop): ReactElement {
	const ctx = useContext(AppContext).productSearchObj;
	const dropdwnList = [
		{
			value: 'product',
			description: 'Products',
		},
		{
			value: 'supplier',
			description: 'Sellers',
		},
		{
			value: 'brandname',
			description: 'Brands',
		},
	];

	const dropdwnListRadius = [
		{
			value: '0-0',
			description: '0KM',
		},
		{
			value: '0-50',
			description: '50KM',
		},
		{
			value: '0-100',
			description: '100KM',
		},
		{
			value: '0-500',
			description: '500KM',
		},
	];

	const history = useHistory();
	const location = useLocation();
	const queryValues = queryString.parse(location.search);
	const [dropdownValue, setDropdownValue] = useState();
	const [searchValue, setSearchValue] = useState();
	const [searchInput, setSearchinput] = useState(prop.itemSearch);
	const [searchLocationInput, setSearchLocationinput] = useState(
		queryValues.place ? queryValues.place : ''
	);
	const [predications, setPredications] = useState<any[]>([]);
	const [show, setShow] = useState(false);
	const [dropdownRadius, setDropdownRadius] = useState('0-100');
	const [locationName, setLocationName] = useState('Location...');
	const item = prop.itemSearch;
	useEffect(() => {
		if (prop.itemSearch) setSearchinput(prop.itemSearch);
	}, [item, prop.itemSearch]);
	useEffect(() => {
		parseQueryString();
	}, []);
	useEffect(() => {
		setDropdownValue(prop.updateDropDown);
	}, [prop.updateDropDown]);
	const parseQueryString = function () {
		const parsed = queryString.parse(location.search);

		if (parsed) {
			let type = parsed.type ? parsed.type : 'Products';
			setSearchValue(parsed.search);
			setDropdownValue(type);
		}
	};
	const handleChange = (e) => {
		ctx.Type = e.target.value;
		setDropdownValue(e.target.value);
		//if (ctx.SearchText != '')
		loadData();
	};
	const handleRadiusChange = (e) => {
		setDropdownRadius(e.target.value);
	};
	const onClickSearch = () => {
		setSearchLocationinput('');
		setDropdownRadius('0-100');
		ctx.location = {};
		ctx.Type = 'Products';
		//if (dropdownValue) {
		loadData();
		//}
	};
	const onClickLocationSearch = () => {
		setSearchinput('');
		const urlParams = new URLSearchParams();

		if (searchLocationInput) {
			urlParams.set('lat', ctx.location.lat);
			urlParams.set('long', ctx.location.long);
			if (dropdownRadius) {
				urlParams.set('radius', dropdownRadius);
			}
			urlParams.set('place', ctx.location.place);
		}
		urlParams.set('type', 'Products');
		history.push({
			pathname: `/home/product-list`,
			search: urlParams.toString(),
		});
	};
	const setSearchParam = (e) => {
		if (e.keyCode === 13) {
			setSearchValue(e.target.value);
			loadData();
		}
	};
	const loadData = () => {
		// if(ctx.SearchText && ctx.SearchText.replace(" ","") != ""){
		history.push({
			pathname: '/home/product-list',
			search:
				'?search=' +
				ctx.SearchText +
				'&type=' +
				(typeof ctx.Type == 'undefined' ? 'Products' : ctx.Type),
		});

		// prop.clearFilter && prop.clearFilter();
		prop.queryString && prop.queryString(history.location.search);
		// }
	};
	const setValue = (e: React.FormEvent<HTMLInputElement>) => {
		ctx.SearchText = e.currentTarget.value;
		setSearchinput(e.currentTarget.value);
	};
	const setLocationValue = (e: React.FormEvent<HTMLInputElement>) => {
		ctx.Type = 'Location';
		setPredications([]);
		setSearchLocationinput(e.currentTarget.value);
		debouncedGetPlacePredictions(e.currentTarget.value);
	};

	const handleAutoCompletePlaceSelected = (placeId) => {
		if (placeId.length > 2) converToAddress(placeId);
	};

	const debouncedGetPlacePredictions = useCallback(
		_debounce(handleAutoCompletePlaceSelected, 100),
		[]
	);

	const key = 'pk.6c9b0cff21b8952da0d103f3624200e2';
	const geoPredictionUrl = 'https://api.locationiq.com/v1/autocomplete';

	const converToAddress = (location) => {
		return fetch(`${geoPredictionUrl}?key=${key}&q=${location}&limit=5&dedupe=1`)
			.then((res) => res.json())
			.then((address) => {
				setPredications(address);
				setShow(true);
				return address;
			})
			.catch((error) => {
				return error;
			});
	};

	const handleLocationSelect = (location) => {
		setShow(false);
		setSearchLocationinput(location.address.name + ', ' + location.address.country);

		ctx.location = {
			lat: location.lat,
			long: location.lon,
			place: location.address.name + ', ' + location.address.country,
		};
	};

	return (
		<>
			<PageSearchHolder>
				{/* <Select
					id="country"
					name="country"
					className="PageSearchCategory"
					value={dropdownValue}
					onChange={handleChange}
				>
					{dropdwnList &&
						dropdwnList.map((item, index) => (
							<option key={index} value={item.description}>
								{item.description}
							</option>
						))}
				</Select> */}
				<PageSearch
					value={searchInput}
					onChange={setValue}
					type="search"
					placeholder="Search"
					onKeyUp={setSearchParam}
					autoComplete="off"
				></PageSearch>
				<span id="1" onClick={onClickSearch}>
					<Icon.searchIcon />
				</span>
			</PageSearchHolder>
			{/* <div> */}
			{/* <PageSearchHolder>
				{show && (
					<div className="PageSearchCategory PageSearchLocation">
						{predications &&
							predications?.length > 0 &&
							predications?.map((prediction, index) => (
								// <li id="listMap" role={'presentation'} onClick={() => {}} key={index}>
								<div className={'PageSearchContainer'}>
									<div
										key={index}
										className={'PageSearchOptions'}
										onClick={() => {
											handleLocationSelect(prediction);
										}}
									>
										{prediction.address.name + ', ' + prediction.address.country}
									</div>
								</div>
								// </li>
							))}
					</div>
				)}
				<PageSearch
					value={searchLocationInput}
					onChange={setLocationValue}
					type="search"
					placeholder="Loction..."
					autoComplete="off"
				></PageSearch>

				<Select
					id="country"
					name="country"
					className="PageSearchCategory"
					value={dropdownRadius}
					onChange={handleRadiusChange}
				>
					{dropdwnListRadius &&
						dropdwnListRadius.map((item, index) => (
							<option key={index} value={item.value}>
								{item.description}
							</option>
						))}
				</Select>
				<span className="PageLocationSearch" id="2" onClick={onClickLocationSearch}>
					<Icon.searchIcon />
				</span>
			</PageSearchHolder> */}
			{/* </div> */}
		</>
	);
}

export { HeaderSearch };
