import React, { ReactElement, useState } from 'react';
import { Header, Footer, Breadcrumb, BreadcrumbNav } from 'ui/components';
import {
	Sidebar,
	SupplierListHeading,
	ProductListOfferings,
	ProductListDescription,
	SupplierListReview,
} from 'ui/patterns';
import { MainContent, BreadcrumbHolder, RightContentHolder, ContentHolder } from './styles';
function DetailPageOFSuppliers(): ReactElement {
	const breadcrumbNav = [
		{ id: '01', label: 'Home ', active: false },
		{ id: '02', label: 'Suppliers ', active: false },
		{ id: '03', label: 'James & Co ', active: true },
	];
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	}
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={()=>onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={()=>onclickHambrgBtn()}></Sidebar>

			<MainContent>
				{/* Top Breadcrumb */}
				<BreadcrumbNav Heading="Back" breadcrumbList={breadcrumbNav} />
				<ContentHolder>
					{/* Right Product Detail Session */}
					<RightContentHolder>
						{/* Supplier Main Heading */}
						<SupplierListHeading></SupplierListHeading>

						{/* Supplier offerings */}
						<ProductListOfferings></ProductListOfferings>

						{/* Supplier Description */}
						<ProductListDescription description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, "></ProductListDescription>

						{/* Supplier Review */}
						<SupplierListReview></SupplierListReview>
					</RightContentHolder>
				</ContentHolder>
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default DetailPageOFSuppliers;
