import React, { useState } from 'react';
import styled from 'styled-components';
import { StyledFormInput, Button } from 'ui/components';
import { getHSL } from '../../utils/getHSL';
import { Text } from 'rebass/styled-components';

const StyledStepperHolder = styled.div`
	width: auto;
	p {
		position: relative;
		margin-top: 0 !important;
		text-align: center;
		@media (max-width: 480px) {
			font-size: 12px !important;
		}
	}
`;
const StyledStepper = styled.div`
	display: flex;
	border-radius: 8px;
	overflow: hidden;
	flex: 1;
	height: 46px;

	& > button {
		--h: ${({ theme, color }) => getHSL('h', color || theme.baseColors.baseYellow)};
		--s: ${({ theme, color }) => getHSL('s', color || theme.baseColors.baseYellow)};
		--l: ${({ theme, color }) => getHSL('l', color || theme.baseColors.baseYellow)};
		--a: 100%;
		appearance: none;
		background: none;
		border: none;
		font-size: 24px;
		font-weight: bold;
		border-radius: 0;
		cursor: pointer;
		width: 46px;
		flex: 0 0 46px;
		max-width: 46px;
		padding: 0 !important;
		margin: 0 !important;
		color: ${({ theme }) => theme.baseColors.white};
		background: ${({ theme }) => theme.baseColors.baseYellow};

		&:hover,
		&:focus {
			background: hsl(var(--h), var(--s), calc(var(--l) - 10%));
			box-shadow: none !important;
		}
		&:disabled {
			opacity: 0.5;
		}
	}

	& > button:first-child {
		margin-right: 0;
	}

	& > button:last-child {
		margin-left: 0;
	}

	& > input[type='number'] {
		text-align: center;
		flex: 1;
		border-radius: 0;
		border: none;
		height: 46px;
		margin: 0;
		color: ${({ theme }) => theme.baseColors.baseYellow};
		border: 1px solid ${({ theme }) => theme.baseColors.baseYellow};
		font-size: 16px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		width: 60px;
		max-width: 60px;
		flex: 0 0 60px;
		&:focus {
			box-shadow: none;
		}
		&:disabled {
			opacity: 0.5;
		}
	}
`;

function StepperCart(props) {
	var filteredProducts = props?.validationData.filter(function (product) {
		return product.SuppIndex === props?.suppIndex && product.index === props?.index;
	});
	return (
		<StyledStepperHolder>
			<StyledStepper>
				<Button onClick={props.decreaseCount} disabled={props.disableStepper}>
					-
				</Button>
				<StyledFormInput
					type="number"
					onChange={props.handleChange}
					// value={count}
					px="0.5em"
					py="0.5em"
					disabled={props.disableStepper}
					value={
						props.supplierName === props.groupBySupplierName[props?.suppIndex].key
							? props.groupBySupplierName[props?.suppIndex].value[props.index].qty
							: ''
					}
				/>
				<Button disabled={props.disableStepper} onClick={props.increaseCount}>
					+
				</Button>
			</StyledStepper>
			<Text as="p" fontSize="15px" fontFamily="OpenSansRegular" color="text.error">
				{filteredProducts.map((data) => data.value)}
			</Text>
		</StyledStepperHolder>
	);
}

export { StepperCart };
