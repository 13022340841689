import React, { useState } from 'react';
import { Button, Loader } from 'ui/components';
import logo from 'ui/images/logo.svg';
import hooks from 'utils/requestCtx/reactQueryHooks';
import '../../ui/styles/login.css';
import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import OtpInput from 'react-otp-input';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Ipost from 'models/Ipost';
import { userTypes } from 'models/enum-constants/common.constants';
import notify from 'utils/toast.service';
import api from 'utils/requestCtx/api.service';
import constants from 'utils/constants';
import { Separator } from 'ui/components';

const LoginWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	position: relative;
	&::before {
		content: '';
		width: 100vw;
		height: 100vh;
		position: absolute;
		top: 0;
		background: ${({ theme }) => theme.colors.bg.orangeLighterGrayish};
		backdrop-filter: blur(54px);
		position: fixed;
	}
	h1 {
		float: left;
		width: 100%;
		text-align: left;
		color: ${({ theme }) => theme.baseColors.primary};
		font-weight: 600;
		font-size: 14px;
	}
`;
const Panel = styled.div`
	min-width: 548px;
	max-width: 548px;
	border-radius: 6px;
	background: ${({ theme }) => theme.colors.bg.orangeLighter};
	position: relative;
	padding: 50px 46px 46px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	@media (max-height: 900px) {
		padding: 30px;
		margin: auto;
		/* margin-top: 30px; */
	}

	@media (max-width: 767px) {
		max-width: 100%;
		min-width: 300px;
		width: 100%;
		border-radius: 0;
		margin-top: 0;
	}
	@media (max-width: 640px) {
		padding: 23px;
		position: fixed;
		overflow-y: auto;
		height: 100vh;
		justify-content: flex-start;
	}
	form {
		width: 100%;
	}
`;
const VerificationHolder = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	h1 {
		width: 100%;
		text-align: center;
		strong {
			width: 100%;
			font-family: 'OpenSansBold' !important;
			font-size: 18px;
		}
	}
	section {
		padding-top: 50px;
		justify-content: center;
		div {
			width: 100%;
			justify-content: center;
			div {
				width: auto;
			}
		}
		input {
			border: 1px solid ${({ theme }) => theme.colors.bg.orangeLighterGrayish};
			transition: border-color 0.2s ease;
			margin: 0;
			border-radius: 10px;
			width: 60px !important;
			height: 60px;
			text-align: Center;
			padding: 0;
			color: ${({ theme }) => theme.colors.text.black};
			font-family: 'OpenSansBold';
			font-size: 22px;
			+ input {
				margin-left: 10px;
			}
			&:focus {
				border: 1px solid ${({ theme }) => theme.baseColors.primary};
				box-shadow: none;
				outline: none;
			}
			@media (max-width: 480px) {
				width: 50px !important;
				height: 50px;
			}
			@media (max-width: 400px) {
				width: 44px !important;
				height: 44px;
			}
		}
	}
	h3 {
		cursor: pointer;
		font-size: 1rem;
		&:hover {
			color: ${({ theme }) => theme.baseColors.primary};
		}
	}
	h4 {
		color: ${({ theme }) => theme.baseColors.gray};
		span {
			margin-left: 10px;
			color: ${({ theme }) => theme.baseColors.primary};
		}
	}
	.forgot {
		font-size: 16px;
		color: ${({ theme }) => theme.colors.text.black};
		&:hover {
			color: ${({ theme }) => theme.baseColors.primary};
		}
	}
`;
const OtpScreen = () => {
	const history = useHistory();
	const location = useLocation() as any;
	const parsed = queryString.parse(location.search);
	const [confirmation, setConfirmation] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState('');
	const userKey = 'verifyMobileOtp';
	const [mutatePost] = hooks.useMutation(userKey);
	const [UserId] = useState<any>(localStorage.getItem('userId'));
	const userName = localStorage.getItem('userName');
	const CUSTOMER_TOKEN = localStorage.getItem('customerToken');
	const password_reset = localStorage.getItem('is_password_set');

	let userType: any;
	const onChangeCode = (val) => {
		setConfirmation(val);
		if (val.length == 6) {
			validateVerificationCode(val);
		}
	};
	const validateVerificationCode = (otp) => {
		setIsLoading(true);
		const obj: Partial<Ipost> = {
			data: {
				verifyMobileNumber: {
					user_id: parseInt(UserId),
					verification_code: parseInt(otp ? otp : ''),
				},
			},
			params: {},
			url: constants.checkAndVerifyMobileNumber(),
			headers: { Authorization: CUSTOMER_TOKEN },
			message: ' ',
		};
		try {
			api.post(obj).then(function (data) {
				setIsLoading(false);
				if (data !== undefined && data == true) {
					notify('Mobile number verified successfully').success();
					if (password_reset == '0') {
						ResetPassword();
					} else {
						history.push('/login');
					}
				} else if (data == false) {
					notify('Invalid verification code').error();
				}
			});
		} catch (e) {
			setIsLoading(false);
		}
	};
	async function ResetPassword() {
		if (userName) {
			const obj: Partial<Ipost> = {
				data: { username: userName },
				params: {},
				url: 'experion/cognito/forgot-password/code',
				message: 'Verification Code has been send successfully to your email',
			};
			try {
				await mutatePost(obj).then((data) => {
					if (data == true) {
						history.push({
							pathname: 'verification',
							search: '?uid=' + userName,
						});
					} else {
						// setError({ error: 'Invalid Username' })
					}
				});
			} catch (e) {}
		}
	}
	async function createOtp() {
		const obj: Partial<Ipost> = {
			url: constants.sendVerificationCode(),
			message: `A six-digit verification code has been sent to ${userName}`,
			headers: { Authorization: localStorage.getItem('customerToken') },
		};
		try {
			await mutatePost(obj).then((data) => {
				if (data !== undefined) {
					history.push({
						pathname: 'mobile-verification',
						search: '?id=' + userName,
					});
				}
			});
		} catch (e) {}
	}
	return (
		<LoginWrapper>
			<Panel>
				{isLoading == true ? <Loader></Loader> : null}

				<img src={logo} alt="Logo" />
				<Separator> Mobile Verification</Separator>
				<form autoComplete="new-password">
					<VerificationHolder>
						<Text as="h1" fontSize={16} fontFamily="OpenSansMedium" color="text.black">
							Verification Code has been sent to<br></br>
							<strong>{parsed && parsed.id}</strong>
						</Text>
						<Flex as="section">
							<OtpInput
								value={confirmation}
								onChange={(otp) => onChangeCode(otp)}
								numInputs={6}
								isInputNum={true}
								separator={<span>-</span>}
							/>
							<span style={{ fontSize: 12, color: 'red' }}>{error}</span>
						</Flex>
						<Flex
							justifyContent="center"
							mt="1.5rem"
							mb="3.5rem"
							flexDirection="column"
							alignItems="center"
						>
							<Text as="h3" onClick={createOtp} fontSize={2} fontWeight="bold">
								Resend
							</Text>
							{/* <Text as="h4" fontSize={2} fontWeight="bold">
								Resend<span>in 00:46</span>
							</Text> */}
						</Flex>

						<Flex>
							{/* <Text as="h3" fontSize={2} fontWeight="bold">
								Go Back to Login
							</Text> */}
							<a className="forgot" href="/login">
								Go Back to Login
							</a>
							{/* <Button type="button" fullWidth style={{ marginLeft: '10px' }}>
								Verify
							</Button> */}
						</Flex>
					</VerificationHolder>
				</form>
			</Panel>
		</LoginWrapper>
	);
};
export default OtpScreen;
