import { Select } from '@rebass/forms';
import React, { ReactElement, useState } from 'react';
import { Breadcrumb, Button } from 'ui/components';
import * as Icon from 'ui/icons';
import { Filter, Modal } from 'ui/patterns';
import { BrandSorting, SortBuy, StyledBreadcrumb, StyledToolbar } from './styles';

function ProductToolbar(prop): ReactElement {
	const [showFilterModal, setShowFilterModal] = useState(false);
	const toggleView = (view) => {
		prop.onToggle(view);
	};
	const sortData = (e) => {
		prop.onSort(e.target.value);
	};
	const toggleFilterModal = () => {
		setShowFilterModal(!showFilterModal);
	};
	return (
		<>
			<StyledToolbar>
				<StyledBreadcrumb>
					<Breadcrumb
						Heading="products"
						HomePage="Home"
						previousPage={prop.prevTab ? prop.prevTab : ''}
						currentPage={prop.currentTab ? prop.currentTab : ''}
					></Breadcrumb>
					<Button shopViewBtn onClick={toggleFilterModal}>
						<Icon.FilterIcon />
					</Button>
				</StyledBreadcrumb>
				<BrandSorting>
					<SortBuy>
						<Icon.SortIcon />
						<Select onChange={sortData}>
							<option>Latest</option>
							<option>Low - High Price</option>
							<option>High - Low Price</option>
							<option>A - Z Order</option>
							<option>Z - A Order</option>
						</Select>
					</SortBuy>
					<div>
						<Button shopViewBtn onClick={toggleFilterModal}>
							<Icon.FilterIcon />
						</Button>
						<Button shopViewBtn onClick={() => toggleView('tile')}>
							<Icon.GridIcon />
						</Button>
						<Button shopViewBtn onClick={() => toggleView('list')}>
							<Icon.ListIcon />
						</Button>
					</div>
				</BrandSorting>
			</StyledToolbar>
			<Modal
				cancelFilter={prop.cancelFilter}
				width="520px"
				showModal={showFilterModal}
				setShowModal={setShowFilterModal}
			>
				<Filter
					selectedSupplyGroup={prop.selectedSupplyGroup}
					selectedCategoryGroup={prop.selectedCategoryGroup}
					selectedAllergensGroup={prop.selectedAllergensGroup}
					layeredFilters={prop.layeredFilters}
					onChange={prop.onChange}
					onPriceChange={prop.onPriceChange}
					clearAll={prop.clearAll}
					selectedCategoryId={prop.selectedCategoryId}
					isCatSelected={prop.isCatSelected}
					applyFilter={prop.applyFilter}
					cancelFilter={prop.cancelFilter}
					showModal={showFilterModal}
					setShowModal={toggleFilterModal}
				/>
			</Modal>
		</>
	);
}

export { ProductToolbar };
