import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import { Header, Footer, BreadcrumbNav, Loader } from 'ui/components';
import { Sidebar, PendingOrderDetailsEditView, PendingOrdersDetailsListView } from 'ui/patterns';
import { Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import http from 'utils/http.service';
import urls from 'utils/create.url';
import utilityFunctions from 'utils/utility.functions';
import { math } from 'polished';
import helperFunctions from 'utils/helper.functions';
import constants from 'utils/constants';
import httpService from 'utils/http.service';
import api from 'utils/requestCtx/api.service';
import { UseGlobalState } from 'models/globalStateAccess';
import hooks from 'utils/requestCtx/reactQueryHooks';
import Iget from 'models/Iget';
import moment from 'moment';

const MainContent = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
	padding: 0 50px;
	@media (max-width: 960px) {
		padding: 0 15px;
	}
`;
const OrderDetailsBlock = styled.div`
	margin-bottom: 50px;
	h4 {
		margin: 40px 0 10px;
	}
	h5 {
		margin-bottom: 10px;
		strong {
			font-size: 16px;
			font-family: 'OpenSansMedium';
			padding-left: 3px;
		}
		@media (max-width: 768px) {
			font-size: 18px;
			span {
				font-size: 18px;
			}
		}
		@media (max-width: 600px) {
			flex-wrap: wrap;
			span {
				display: inline-block;
			}
		}
	}
`;
function PendingOrderDetails(): ReactElement {
	let location = useLocation();
	const parsed = queryString.parse(location.search);
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const history = useHistory();
	const [order, setOrder] = useState([] as any);
	const [hasData, setHasData] = useState(false);
	const [statusShown, setstatusShown] = useState('');
	const [orderStatus, setorderStatus] = useState('');
	const [productsBySuppliers, setSupplierGroup] = useState([] as any);
	const [inProgress, setInProgress] = useState(false);
	const [venueUserId] = useState<any>(localStorage.getItem('userId'));
	const [venueId, setVenueId] = useState<any>(localStorage.getItem('selectedVenue'));
	const [state, dispatch] = UseGlobalState();
	const [cartId, setCartId] = useState('');
	const statusArray = ['rejected', 'pending_approval', 'expired'];

	const breadcrumbNav = [
		{ id: '01', route: '/home/dashboard', label: 'Home', active: false },
		{ id: '02', route: '/store/orders?tab=pending-orders', label: 'Pending Orders', active: false },
		{ id: '03', label: '#' + order?.order_group_increment_id, active: true },
	];

	function getStatusList(status) {
		let data = [] as any;
		if (status.length > 0) {
			if (status.length > 1) {
				let items = JSON.parse(JSON.stringify(status));
				items.map((v, i) => {
					data.push(JSON.parse(v));
				});
			} else {
				data.push(JSON.parse(status));
			}
		}
		return data;
	}

	//api to get total amount
	const url1 = `/carts/${cartId}/totals`;
	const obj6: Partial<Iget> = {
		url: url1,
		enabled: false, //manual fetch
		key: 'carttotalKey1',
		resultFilteringRequired: false,
	};
	var qryTotal = hooks.useQuery(obj6);

	const getPageData = () => {
		http
			.GET({
				url: urls.GetSearchUrl({
					url: 'experion/pending-order/search?',
					fields: [
						{
							field_name: 'venue_id',
							field_value: localStorage.getItem('selectedVenue')!,
						},
						{
							field_name: 'venue_user_id',
							field_value: localStorage.getItem('userId')!,
						},
						{
							field_name: 'order_group_id',
							field_value: `${parsed.id}`,
							field_conditionType: 'eq',
						},
					],
					pageSize: 20,
					filterDeleted: true,
				}),
			})
			.then(function (d) {
				const data = d.data?.items?.find((x) => x.order_group_id == parsed.id);
				setOrder(data);
				const arrayList = [] as any;
				arrayList.push(data?.status);
				let statusList = getStatusList(data?.status);
				let orderStatus = utilityFunctions.getOrderStatus(statusList.map((x) => x.status));
				setorderStatus(orderStatus);
				const status = helperFunctions.toPascalCase(
					statusArray.includes(orderStatus) ? orderStatus?.replace('_', ' ') : 'Approved'
				);
				setstatusShown(status);
			});

		http
			.GET({
				url: urls.GetSearchUrl({
					url: 'orders?',
					fields: [
						{
							field_name: 'group_order_id',
							field_value: parsed.id,
						},
					],
				}),
			})
			.then(function (d) {
				if (d.data?.items?.length > 0) {
					const datas = d.data?.items;
					setSupplierGroup(datas);
				}
				setHasData(true);
			});
	};

	useEffect(() => {
		if (parsed.id) {
			getPageData();
		}
	}, [location, parsed.id]);

	async function pendingOrderAction(type) {
		setInProgress(true);
		if (type === 1 || type === 2) {
			const status = type === 1 ? 'approved' : 'rejected';
			const data = {
				orderGroupId: order.order_group_id,
				userId: localStorage.getItem('userId'),
				status: status,
				venue_id: localStorage.getItem('selectedVenue'),
			};
			httpService
				.PUT({ url: `experion/order/update`, data: data })
				.then(function (data) {
					{
						updateListData(type);
						setInProgress(false);
					}
				})
				.catch((e) => {
					setInProgress(false);
				});
		} else {
			let id: any;
			let CUSTOMER_TOKEN = localStorage.getItem('customerToken');
			id = await utilityFunctions.getCartId(CUSTOMER_TOKEN);
			if (id !== undefined) {
				setCartId(id);
				const data = JSON.parse(order.products);
				let items = [] as any;
				data?.order_details?.forEach((v, i) => {
					items.push({ product_id: v.product_data.product_id, qty: v.product_data.qty_ordered });
				});
				let addToCartJson = {
					products: {
						orderGroupId: parseInt(order.order_group_id),
						quote_id: parseInt(id),
						items: items,
						venue_user_id: parseInt(venueUserId),
						venue_id: parseInt(venueId),
					},
				};
				api
					.post({
						data: addToCartJson,
						url: constants.multiCartURL(),
						key: 'pendingOrderCartAdd',
						message: 'Successfully moved to cart',
					})
					.then(function (data) {
						fetchTotal();
						updateListData(type);
						setInProgress(false);
					})
					.catch((e) => {
						setInProgress(false);
					});
			} else {
				setCartId('');
				setInProgress(false);
			}
		}
	}

	function updateListData(type) {
		const orderData = order;
		var list = [];
		if (type === 1 || type === 2) {
			const status = type === 1 ? 'pending' : 'rejected';
			const data = [JSON.stringify({ status: status })];
			orderData.status = data;
			setorderStatus(status);
			const statusShown = helperFunctions.toPascalCase(
				statusArray.includes(status) ? status?.replace('_', ' ') : 'Approved'
			);
			setstatusShown(statusShown);
		} else {
			orderData.is_moved_to_cart = 1;
		}
		setOrder(orderData);
	}

	const fetchTotal = () => {
		qryTotal.refetch().then((data) => {
			if (data) {
				updateCartTotal(data);
				// if (data?.items?.length > 0)
				//  dispatch({ cartItemCount: data?.items.length });
			}
		});
	};

	function updateCartTotal(data) {
		if (data) {
			let datas = data.items?.filter((x) => x.is_deleted != true);
			if (datas?.length > 0) {
				dispatch({ cartTotal: helperFunctions.formatAmount(data.grand_total) });
				dispatch({ cartItemCount: datas?.length });
				// setTotalCount(data.subtotal);
			} else {
				dispatch({ cartItemCount: '0' });
				dispatch({ cartTotal: '' });
				// setTotalCount(0);
			}
		} else {
			dispatch({ cartItemCount: '0' });
			dispatch({ cartTotal: '' });
			// setTotalCount(0);
		}
	}
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	};
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={() => onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={() => onclickHambrgBtn()}></Sidebar>

			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbNav Heading="Pending Orders" breadcrumbList={breadcrumbNav} />
				{(hasData && (
					<OrderDetailsBlock>
						<PendingOrderDetailsEditView
							status={statusShown}
							orderStatus={orderStatus}
							pendingOrderAction={pendingOrderAction}
							supplierCount={productsBySuppliers?.length}
							data={order}
						></PendingOrderDetailsEditView>

						{productsBySuppliers &&
							productsBySuppliers.map((data, i) => {
								let deliverySlot = data.extension_attributes?.delivery_slot
									? data.extension_attributes?.delivery_slot
									: '';
								deliverySlot = deliverySlot.split(' ');
								let deliverySlotDay = null as any;
								let deliverySlotDate = null as any;
								if (deliverySlot.length === 2) {
									deliverySlotDate = moment(deliverySlot[1], 'DD-MM-YYYY');
									deliverySlotDate = helperFunctions.formatDate(deliverySlotDate.toDate());
									deliverySlotDay = deliverySlot[0];
								}
								return (
									<Fragment key={Math.random()}>
										{deliverySlot && deliverySlot.length === 2 ? (
											<Text as="h5" mt={5} fontSize="20px" lineHeight="26px" color="text.black">
												{data.extension_attributes.supplier_name}
												<strong>{` ${data.items?.length}`}</strong>
												{` ( `}
												<strong>{`#${data.extension_attributes.supplier_order_increment_id}`}</strong>
												{` - ${helperFunctions.toPascalCase(data.status?.replace('_', ' '))} ) `}
												<span>
													Delivery Date:<strong>{`${deliverySlotDay} ${deliverySlotDate}`}</strong>
												</span>
											</Text>
										) : (
											<Text as="h5" mt={5} fontSize="20px" lineHeight="26px" color="text.black">
												{data.extension_attributes.supplier_name}
												<strong>{` ${data.items?.length}`}</strong>
												{` ( `}
												<strong>{`#${data.extension_attributes.supplier_order_increment_id}`}</strong>
												{` - ${helperFunctions.toPascalCase(data.status?.replace('_', ' '))} ) `}
											</Text>
										)}
										<PendingOrdersDetailsListView
											pendingOrdersDetailsListView={data.items}
										></PendingOrdersDetailsListView>
									</Fragment>
								);
							})}
					</OrderDetailsBlock>
				)) || <Loader></Loader>}
			</MainContent>
			{inProgress ? <Loader></Loader> : null}
			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default PendingOrderDetails;
