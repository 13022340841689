import React, { ReactElement } from 'react';
import './styles.css';


function Loader(): ReactElement {
	return (
		<div className="page-default-loader-holder">
			<div className="page-default-loader"></div>
		</div>
	);
}
export { Loader };
