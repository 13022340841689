import React, { ReactElement, ReactNode, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Text, Box, Flex, Link, Button } from 'rebass/styled-components';
import 'react-tippy/dist/tippy.css';
import * as Icon from 'ui/icons';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { userTypes } from 'models/enum-constants/common.constants';
import Iget from 'models/Iget';
import hooks from 'utils/requestCtx/reactQueryHooks';
import useOnClickOutside from 'use-onclickoutside';
import api from 'utils/requestCtx/api.service';
import { UserBody } from 'admin/dashboardVenue/styles';
import Skeleton from 'react-loading-skeleton';
import { NotificationsFullView } from 'ui/patterns';
import constants from 'utils/constants';

const StyledCartListView = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	row-gap: 5px;
	width: 100%;
	.simplebar-wrapper {
		max-height: calc(100vh - 250px);
	}
	.simplebar-track.simplebar-vertical {
		width: 8px !important;
	}
`;

const NotificationsFullViewHolder = styled.div<NotificationsFullViewHolderProps>`
	width: 100%;
	display: flex;
	flex-direction: row;
	background: ${({ theme }) => theme.colors.bg.white};
	border: 1px solid ${({ theme }) => theme.colors.bg.white};

	box-sizing: border-box;
	border-radius: 16px;
	overflow: hidden;
	transition: box-shadow 100ms ease-in, transform 100ms ease-in;
	align-items: center;
	cursor: pointer;
	padding: 10px;
	button {
		&.readIndication {
			background: ${({ theme }) => theme.baseColors.primary}!important;
			padding: 0;
			transition: none;
			width: 18px;
			height: 18px;
			border: 4px solid ${({ theme }) => theme.colors.bg.lightYellow06}!important;
			border-radius: 100%;
			margin-right: 5px;
			flex: 0 0 18px;
			max-width: 18px;
		}
		&.unreadIndication {
			background: ${({ theme }) => theme.colors.bg.white};
			padding: 0;
			transition: none;
			width: 18px;
			height: 18px;
			border: 4px solid ${({ theme }) => theme.colors.bg.white};
			border-radius: 100%;
			margin-right: 5px;
			flex: 0 0 18px;
			max-width: 18px;
		}
		&.notificationClear {
			background: transparent;
			padding: 0;
			transition: none;
			width: 18px;
			height: 18px;
			border: transparent;
			border-radius: 100%;
			margin-right: 10px;
			flex: 0 0 18px;
			max-width: 18px;
			display: none;
			svg {
				width: 12px;
				height: auto;
				path {
					fill: ${({ theme }) => theme.baseColors.red};
				}
			}
		}
	}
	&:hover {
		background: ${({ theme }) => theme.colors.bg.lightYellow06};

		picture {
			background: ${({ theme }) => theme.colors.bg.white};
		}
		button {
			&.notificationClear {
				display: block !important;
			}
			&.unreadIndication {
				border: 4px solid ${({ theme }) => theme.colors.bg.lightYellow06}!important;
			}
		}
	}

	@media (max-width: 480px) {
		flex-direction: column;
	}
	picture {
		max-width: 40px;
		flex: 0 0 40px;
		height: 40px;
		border-radius: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: ${({ theme }) => theme.colors.bg.lightYellow06};
		border: 1px solid ${({ theme }) => theme.colors.bg.white};
		margin-right: 15px;
		svg {
			width: 20px;
			height: 25px;
		}
	}
	section {
		height: 100%;
		position: relative;
		display: flex;
		flex-direction: row;
		flex-direction: column;
		align-items: flex-start;
	}
	slot {
		flex: 1;
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		justify-content: space-between;
		position: relative;
		justify-content: center;
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-weight: normal;
			color: ${({ theme }) => theme.colors.text.black};
		}
		h1 {
			margin-bottom: 3px;
		}
		h2 {
			strong {
				font-family: 'OpenSansMedium';
			}
		}
		h3 {
			margin-top: 4px;
			color: #8993a4;
		}

		a {
			font-family: 'OpenSansBold';
			cursor: pointer;
			margin-top: 4px;
			&:hover {
				color: ${({ theme }) => theme.baseColors.primary};
			}
		}
		+ slot {
			display: flex;
			align-items: center;
			flex-direction: row;
			justify-content: flex-start;
			@media (max-width: 768px) {
				justify-content: flex-start;
			}
		}
	}
	${({ active }) =>
		!active &&
		css`
			opacity: 0.3;
		`}
`;
const ViewAllHeadingBlock = styled.div`
	display: flex !important;
	justify-content: space-between;
	color: ${({ theme }) => theme.colors.text.black04};
	padding: 0 10px 10px;
	align-items: center;
	min-height: 35px;
	h1,
	h2 {
		font-family: 'OpenSansBold';
		text-transform: uppercase;
		display: flex !important;
		align-items: center;
		position: relative;
		span {
			margin-right: 6px;
			svg {
				width: 25px;
				height: 22px;
			}
		}
	}
	h1,
	h2 a {
		font-weight: normal;
	}
	a {
		color: ${({ theme }) => theme.baseColors.baseYellow};
		text-decoration: none;
		font-family: 'OpenSansMedium';
		transition: ease-in all 200ms;

		&:hover {
			background: ${({ theme }) => theme.baseColors.baseYellow};
			padding: 3px 10px;
			color: ${({ theme }) => theme.baseColors.white};
			border-radius: 5px;
		}
	}
	h2 {
		width: 100%;
		margin-top: 20px;
		&:after,
		&:before {
			content: '';
			position: absolute;
			z-index: -1;
		}
		&:after {
			width: 125px;
			height: 10px;
			background: ${({ theme }) => theme.baseColors.white};
		}
		&:before {
			width: 100%;
			height: 1px;
			background: rgba(0, 0, 0, 0.15);
		}
	}
`;
type NotificationsFullViewHolderProps = {
	active?: boolean;
};
export type WatchListNotificationViewProps = {
	trigger?: string | ReactNode;
	menuList?: Array<item>;
	menuContent?: ReactNode;
	autoClose?: boolean;
	position?: 'right-top' | 'right' | 'left-top' | 'left';
	ref?: React.RefObject<HTMLDivElement>;
	onClick?: (e: any) => void;
	updateUnReadCount?: any;
	notificationList?: any;
	updateNotificationList?: any;
};
type item = {
	id?: number;
	value: string;
	action?: () => void;
	icon?: ReactNode;
	groupEnd?: boolean;
};

function WatchListView({
	autoClose = true,
	position,
	updateUnReadCount,
	notificationList,
	updateNotificationList
}: WatchListNotificationViewProps): ReactElement {
	const notificationCount = 25;
	const [lenghtUpdater, setLengthUpdater] = useState(0);
	const [scrollEvent, setScrollEvent] = useState(Math.random());
	let user_Type = localStorage.getItem('userType');
	let venue = localStorage.getItem('selectedVenue');
	let location = localStorage.getItem('selectedLocation');
	let userId = localStorage.getItem('userId');
	let loc =
		user_Type == userTypes.venueAdmin || user_Type == userTypes.venueUser ? venue : location;
	const [notificationsFullViewList, setNotificationsFullViewList] = useState([] as any);
	const url =
		loc &&
			typeof userId != 'undefined' &&
			userId != ''
			? constants.productWatchNotification() + loc + '/' + userId
			: '';
	const key = 'WatchListViewNotification';
	const CUSTOMER_TOKEN = localStorage.getItem('customerToken');
	const obj: Partial<Iget> = {
		params: {},
		url: url,
		enabled: false,
		key: key,
		resultFilteringRequired: false,
		headers: { Authorization: CUSTOMER_TOKEN }
	};
	var watchListViewQry = hooks.useQuery(obj);
	const handleScroll = useCallback(() => {
		const element = document.getElementsByClassName("simplebar-content-wrapper")[0] as any;
		if (Math.ceil(element?.scrollHeight - element?.scrollTop) !== element?.clientHeight)
			return;
		setScrollEvent(Math.random());
	}, [setScrollEvent]);
	useEffect(() => {
		const oldData = JSON.parse(JSON.stringify(notificationsFullViewList));
		if (oldData && oldData.length > 0) {
			let data = watchListViewQry.data ? watchListViewQry.data?.sort((a, b) => b.id - a.id) : notificationList?.sort((a, b) => b.id - a.id);
			if (data && (data.length > (notificationCount * lenghtUpdater))) {
				data = data.slice(notificationCount * lenghtUpdater, notificationCount * (lenghtUpdater + 1));
				setLengthUpdater(JSON.parse(JSON.stringify(lenghtUpdater)) + 1);
				setNotificationsFullViewList([...oldData, ...data]);
			}
		}
	}, [scrollEvent]);
	useEffect(() => {
		let data = watchListViewQry.data ? watchListViewQry.data?.sort((a, b) => b.id - a.id) : watchListViewQry.data;
		if(data)updateNotificationList(data);
		if (data && data.length > 0) {
			data = data.slice(0, notificationCount);
			setLengthUpdater(1);
			setNotificationsFullViewList(data);
		} else {
			setLengthUpdater(1);
			setNotificationsFullViewList([]);
		}
	}, [watchListViewQry.data]);
	useEffect(() => {
		const element = document.getElementsByClassName("simplebar-content-wrapper")[0] as any;
		if (element) element?.addEventListener('scroll', handleScroll);
		let data = notificationList ? notificationList?.sort((a, b) => b.id - a.id) : notificationList;
		if (data && data.length > 0) {
			data = data.slice(0, notificationCount);
			setLengthUpdater(1);
			setNotificationsFullViewList(data);
		} else {
			setLengthUpdater(1);
			setNotificationsFullViewList([]);
		}
	}, [notificationList]);
	const [open, setOpen] = useState(false);
	const handleOnClickOutside = () => {
		setOpen(false);
	};

	const wrapperRef = useRef<HTMLDivElement>(null);
	useOnClickOutside(wrapperRef, handleOnClickOutside);
	const handleToggle = (e: SyntheticEvent<HTMLDivElement>) => {
		e.stopPropagation();
		setOpen(autoClose === true ? !open : true);
	};

	function getUserType() {
		let userType = '';
		switch (user_Type) {
			case userTypes.venueAdmin:
				userType = 'hg_admin';
				break;
			case userTypes.venueUser:
				userType = 'venue_user';
				break;
			case userTypes.supplierAdmin:
				userType = 'supply_admin';
				break;
			case userTypes.supplierUser:
				userType = 'dc_user';
				break;
			default:
				break;
		}
		return userType;
	}

	function markAllAsRead(e) {
		e.preventDefault();
		let userType = getUserType();
		api
			.put({
				url: `experion/notifications/markAsReadAll/${loc}/${userType}/${localStorage.getItem(
					'userId'
				)!}`,
				message: 'All notifications marked as read',
			})
			.then(function (data) {
				if (data) {
					notificationsFullViewList?.map((x) => {
						x.mark_read = '1';
					});
					setNotificationsFullViewList(JSON.parse(JSON.stringify(notificationsFullViewList)));
					notificationList?.map((x) => {
						x.mark_read = '1';
					});
					updateNotificationList(notificationList);
				}
			});
	}

	function markAsRead(id) {
		api
			.put({
				url: `experion/notifications/markAsRead/${id}`,
				message: 'Notification marked as read',
				data: constructData(),
			})
			.then(function (data) {
				if (data) {
					let item = notificationsFullViewList.find((x) => x.id == id);
					if (item) {
						item!.mark_read = '1';
						setNotificationsFullViewList(JSON.parse(JSON.stringify(notificationsFullViewList)));
						let mainItem = notificationList.find((x) => x.id == id);
						mainItem!.mark_read = '1';
						updateNotificationList(notificationList);
					}
				}
			});
	}

	function constructData() {
		return {
			participants: {
				mark_read: '1',
			},
		};
	}

	function deleteNotification(id) {
		api
			.delete({
				url: `experion/notifications/delete/${id}`,
				message: 'Notification deleted successfully',
			})
			.then(function (data) {
				if (data) watchListViewQry.refetch();
			});
	}
	useEffect(() => {
		let count = 0;
		if (notificationsFullViewList) {
			const data = watchListViewQry.data ? watchListViewQry.data : notificationList;
			count = data?.filter((x) => x.mark_read == '0')?.length
		}
		updateUnReadCount(count);
	}, [notificationsFullViewList]);

	return (
		<StyledCartListView ref={wrapperRef} onClick={handleToggle}>
			<Box style={{ width: '100%' }}>
				{notificationsFullViewList &&
					notificationsFullViewList?.filter((x) => x.mark_read == '0')?.length > 0 && (
						<ViewAllHeadingBlock>
							<Text as="h1" fontSize="14px"></Text>
							<Link
								variant="nav"
								href=""
								onClick={(e) => {
									markAllAsRead(e);
								}}
								fontSize="12px"
							>
								Mark all as read
							</Link>
						</ViewAllHeadingBlock>
					)}
				<SimpleBar>
					{(!watchListViewQry.isFetching && notificationsFullViewList?.length > 0 && (
						<NotificationsFullView
							notificationsFullView={notificationsFullViewList}
							markAsRead={markAsRead}
							deleteNotification={deleteNotification}
						/>
					)) ||
						(!watchListViewQry.isFetching && notificationsFullViewList?.length == 0 ? (
							<UserBody>
								<Icon.searchIcon title="No Data Found" />
								<Text fontSize={18} fontWeight="bold" color="#5C5E57" textAlign="center">
									No notifications found
								</Text>
							</UserBody>
						) : (
							new Array(12).fill(null).map((_, i) => {
								return <Skeleton key={i} width="1200px" height="50px" />;
							})
						))}
				</SimpleBar>
			</Box>
		</StyledCartListView>
	);
}

export { WatchListView };
function wrapperRef(wrapperRef: React.RefObject<HTMLDivElement>, handleOnClickOutside: () => void) {
	throw new Error('Function not implemented.');
}

