import styled from 'styled-components';
import { getHSL } from 'ui/utils/getHSL';

const MainContent = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
	padding: 0 50px;
	@media (max-width: 960px) {
		padding: 0 15px;
	}
`;
const AddUserHolder = styled.div`
	width: 100%;
	position: relative;
	margin: 0 auto;
	margin-bottom: 50px;
	row-gap: 20px;
	display: grid;
	section {
		padding: 25px;
		background: ${({ theme }) => theme.colors.bg.white};
		border: 1px solid ${({ theme }) => theme.colors.border.userListTD};
		box-sizing: border-box;
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07);
		border-radius: 8px;
		.doYouDelivery {
			label {
				margin-bottom: 0;
				margin-left: 20px;
			}
		}
		.skipDatesBlock {
			display: flex;
			align-items: center;
			padding-top: 20px;
			label {
				display: inline-block;
				margin-bottom: ${({ theme }) => theme.space[1]};
				color: ${({ theme }) => theme.colors.text.textLabel};
				font-family: 'OpenSansSemiBold';
				line-height: 18px;
				font-size: 14px;
			}
			.rmdp-input {
				padding-right: 50px;
			}
			h2 {
				padding: 24px 0 0 17px;
			}
		}
		.rmdp-day.rmdp-today span {
			background: ${({ theme }) => theme.baseColors.primary};
		}
	}
`;
const FooterBtnBlock = styled.div`
	padding: 30px 0 0 0;
	button {
		min-width: 100px;
		text-transform: capitalize;
		padding: 0;
		height: 48px;
		flex: 0 0 100px;
	}
	@media (max-width: 767px) {
		button {
			flex: 0 0 100px;
		}
	}
`;
export { MainContent, FooterBtnBlock, AddUserHolder };
