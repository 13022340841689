import { AddDynamicForm } from 'admin/dynamicForm';
import Iget from 'models/Iget';
import Ipost from 'models/Ipost';
import moment from 'moment';
import queryString from 'query-string';
import React, { ReactElement, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory, useLocation } from 'react-router-dom';
import { Heading } from 'rebass/styled-components';
import { BreadcrumbNav, Button, Footer, Header, LineAccordion, Loader, Stack } from 'ui/components';
import { AddImageHolder, AllergenList, ProductList, Sidebar } from 'ui/patterns';
import helper from 'utils/helper.functions';
import httpService from 'utils/http.service';
import pageConstants from 'utils/pageConstants';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import urls from 'utils/create.url';
import notify from 'utils/toast.service';
import {
	AddProduct,
	AllergenNameSection,
	BreadcrumbHolder,
	FooterBtnBlock,
	MainContent,
	ProductImageHolder,
} from './styles';

function AddProducts(): ReactElement {
	const history = useHistory();
	const location = useLocation();
	const [isLoading, setIsLoading] = useState(false);
	const [userSaveText, setUserSaveText] = useState('Add');
	var [idList, updateIdList] = useState<any>([]);
	var [idSubList, updateIdSubList] = useState<any>([]);
	var [enableDeleteButton, setEnableButton] = useState<any>(false);
	var [isProductFetched, setIsProductFetched] = useState<any>(false);
	var [enableSubProductButton, setSubProductButton] = useState<any>(false);
	const [hasAllergensChange, setHasAllergensChange] = useState(false);
	const [modalText, setmodalText] = useState('');
	const [showPopup, setShowModal] = useState(false);
	const [disable, setDisabled] = useState(true);
	const [action, setAction] = useState('');
	const [statusChecked, setStatusChecked] = useState(true);
	const [userDefinedAttributes, setUserDefinedAttributes] = useState([] as any);
	const [categoryInitial, setCategoryInitial] = useState([] as any);
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const breadcrumbNav = [
		{ id: '01', label: 'Dashboard', route: 'seller-dashboard', active: false },
		{ id: '02', label: 'Product Management', route: 'productManagement', active: false },
		{ id: '03', label: userSaveText == 'Save' ? 'Edit Product' : 'Add Product', active: true },
	];
	const parsed = queryString.parse(location.search);
	var [allergensList, setAllergensList] = useState([] as any);
	var [dummyAllergenList, setDummyAllergenList] = useState([] as any);
	var [subProductsList, setsubProductsList] = useState([] as any);
	var [substitutionProducts, setSubstitutionProducts] = useState<any>([]);
	var [dummySubstitutionProducts, setDummySubstitutionProducts] = useState<any>([]);
	var [dropDwnAllergen, setdropDwnAllergen] = useState<any>([]);
	var [dummySubstitutionProducts, setDummySubstitutionProducts] = useState<any>([]);
	const [categoryLevel, setCategoryLevel] = useState([] as any);
	const obj1 = {
		attribute_code: '',
		value: 0,
		name: '',
	};
	const [assignedProperty, setssignedProperty] = useState(obj1);
	const [error, setError] = useState({
		productNameError: '',
		skuError: '',
		typeError: '',
		attributeError: '',
		fromDateError: '',
		minSaleError: '',
		maxSaleError: '',
		upcError: '',
		spclPriceError: '',
	});
	const [AllergenArray, setAllergenArray] = useState(obj1);
	const EditPrdtUrl = `products/${parsed.sku}`;
	var [mediaFiles, setMediaFiles] = useState([] as any);
	const adminId = localStorage.getItem('SuAdminId');
	const [attributeList, setAttributeList] = useState([] as any);
	const getPageData = function (category) {
		const urlSubProds = `experion-productmanagement/getproducts/${adminId}`; //`experion-productmanagement/getproducts/${userId}`;
		httpService.GET({ url: urlSubProds }).then(function (data) {
			var substituteTempData, allergenTempData;
			if (data && data.data) {
				window.scroll({ top: 0, left: 0, behavior: 'smooth' });
				setDummySubstitutionProducts([...data.data]);
				setSubstitutionProducts([...data.data]);
				substituteTempData = data.data;
			}

			const urlAllergen = 'products/attributes/allergens/options';
			httpService.GET({ url: urlAllergen }).then(function (data) {
				if (data && data.data) {
					setDummyAllergenList(data.data);
					setdropDwnAllergen([...data.data]);
					allergenTempData = data.data;
				}

				parseQueryString(substituteTempData, allergenTempData, category);
			});
		});
	};
	//category fetch

	// const item: Partial<Iget> = {
	// 	params: {},
	// 	url: EditPrdtUrl,
	// 	enabled: false,
	// 	key: editPrdtKey,
	// };
	// const productData = hooks.useQuery(item);

	// const substitutionProductMapping = async function (data) { };

	// const allergenMapping = async function (data) { };

	const findparent = (id, array) => {
		var parent = categoryInitial.filter(x => x.id == id);
		if (parent.length > 0 && parent[0].parent_id > 1) {
			array.push(parent[0].parent_id);
			return findparent(parent[0].parent_id, array);
		} else {
			return array;
		}
	}

	const parseQueryString = async function (substituteTempData, allergenTempData, category) {
		if (parsed.sku) {
			// let sku = parseInt(parsed.sku);
			setUserSaveText('Save');
			await httpService.GET({ url: EditPrdtUrl }).then(function (data) {
				if (data && data.data) {
					let updateDate = true;
					let checked = data.data.status == 1 ? true : false;
					setStatusChecked(checked);
					setDisabled(false);
					if (typeof data.data.extension_attributes.category_links == 'undefined') {
						data.data.extension_attributes.category_links = [];
						setDisabled(true);
					}
					else if (data.data.extension_attributes.category_links) {
						data.data.extension_attributes.category_links.map((item) => {
							let data = findparent(Number(item.category_id), []);
							data.push(Number(item.category_id));
							data.map(x => updateNodeInTree(category, x, true));
						});
					}

					if (data.data.product_links != undefined) {
						if (data.data.product_links && data.data.product_links.length > 0) {
							data.data.product_links.map((x) => {
								let subPdt = substituteTempData.find((y) => y.sku == x.linked_product_sku);
								if (subPdt) {
									subProductsList.push(subPdt);
								}
							});
						}
					}

					if (data.data.tier_prices) {
						data.data.tier_prices.map((x) => {
							if (x.extension_attributes && x.extension_attributes.percentage_value) {
								x.value = 0;
							}
						});
					}
					if (data.data.custom_attributes != undefined) {
						api
							.get({ url: `products/attribute-sets/${data.data.attribute_set_id}/attributes` })
							.then(function (attributeList) {
								setAttributeValues(
									attributeList?.filter(
										(x) =>
											x.is_user_defined == true &&
											x.attribute_code != 'brand' &&
											x.attribute_code != 'allergens' &&
											x.attribute_code != 'uom' &&
											x.attribute_code != 'tax_class_id'
									),
									data.data.custom_attributes
								);
							});
						if (data.data.custom_attributes.filter((x) => x.attribute_code == 'allergens' || x.attribute_code == 'special_price')) {
							data.data.custom_attributes.map((x) => {
								if (x.attribute_code == 'allergens') {
									x.value &&
										x.value.split(',').map((step) => {
											let isExist = {
												attribute_code: '',
												value: 0,
												name: '',
											};
											let index = allergenTempData && allergenTempData.find((x) => x.value == step);
											if (index) {
												isExist.name = index.label;
												allergenTempData.splice(
													allergenTempData.findIndex((x) => x.label == isExist.name),
													1
												);
											}
											isExist.attribute_code = 'allergens';
											isExist.value = step;
											allergensList.push(isExist);
											setAllergensList([...allergensList]);
											setdropDwnAllergen(allergenTempData);
											dummyProducts.custom_attributes.push(isExist);
										});
								}
								else if (x.attribute_code === 'special_price') {
									if (x.value) {
										x.value = parseFloat(x.value).toFixed(2);
									}
									updateDate = !(x.value && Number(x.value) > 0);
								}
							});
						}
					}
					updateDate && data.data.custom_attributes.map((x) => {
						if (x.attribute_code == 'special_from_date' || x.attribute_code == 'special_to_date') {
							x.value = new Date;
						}
					});

					setProductDetails(data.data);
					setsubProductsList(JSON.parse(JSON.stringify(subProductsList)));
					setCategoryLevel(JSON.parse(JSON.stringify(category)));
				}
			});
		}
	};
	const categoryKey = 'category';
	const categoryUrl = urls.GetSearchUrl({
		url: 'categories/list?',
		ctx: { currentPage: 1 },
	});
	const catObj1: Partial<Iget> = {
		params: {},
		url: categoryUrl,
		enabled: true,
		key: categoryKey,
		resultFilteringRequired: true,
	};
	var categoryList;
	useEffect(() => {
		httpService.GET({ url: categoryUrl }).then(function (data) {
			if (data && data.data) {
				setCategoryInitial(data.data?.items);
			}
		});
		// categoryList = hooks.useQuery(catObj1) as any;
	}, [location]);
	const [productDetails, setProductDetails] = useState({
		name: '',
		sku: '',
		attribute_set_id: 0,
		price: '',
		status: 1,
		type_id: 'simple',
		media_gallery_entries: [
			{
				media_type: 'image',
				label: null,
				position: 1,
				disabled: false,
				types: [
					"image",
					"small_image",
					"thumbnail"
				],
				content: {
					base64_encoded_data: '',
					type: 'image/jpeg',
					name: '',
				},
			},
		],
		product_links: [
			{
				sku: parsed.sku,
				link_type: 'substitution',
				linked_product_sku: '',
				linked_product_type: 'simple',
				position: 1,
			},
		],
		extension_attributes: {
			category_links: [
				// {
				// 	position: 0,
				// 	category_id: 0,
				// },
			],
			stock_item: {
				qty: 0,
				min_qty: 0,
				is_in_stock: true,
				min_sale_qty: 1 as any,
				max_sale_qty: '' as any,
				use_config_min_sale_qty: 0,
				use_config_max_sale_qty: 0,
				use_config_min_qty: 0,
			},
			other_suppliername: [],
			productcatagory_name: '',
			product_subcatagoryname: [],
			currency_list: '',
		},
		tier_prices: [] as any,
		custom_attributes: [
			{
				attribute_code: 'seller_id',
				value: adminId as any,
			},
			{
				attribute_code: 'is_bulk',
				value: '0',
			},
			{
				attribute_code: 'upc',
				value: '',
			},
			{
				attribute_code: 'supplier_sku',
				value: '',
			},
		],
	});

	useEffect(() => {
		if (categoryInitial && categoryInitial.length > 0) {
			let categoryItems = [] as any;
			categoryInitial.map((innerData) => {
				let item = {
					id: 0,
					parent_id: 0,
					label: '',
					value: 0,
					checked: false,
					expanded: false
				};
				item.id = innerData.id;
				item.parent_id = innerData.parent_id;
				item.label = innerData.name;
				item.value = innerData.id;
				categoryItems.push(item);
			});
			var catItems,
				o = {};
			categoryItems &&
				categoryItems.length > 0 &&
				categoryItems.forEach(function (a) {
					a.children = o[a.id] && o[a.id].children;
					o[a.id] = a;
					if (a.parent_id === 1 && a.id === 21) {
						a["className"] = 'hideMe';
						a.expanded = true;
						catItems = a;

					} else {
						o[a.parent_id] = o[a.parent_id] || {};
						o[a.parent_id].children = o[a.parent_id].children || [];
						o[a.parent_id].children.push(a);
					}
				});
			setCategoryLevel(JSON.parse(JSON.stringify(catItems)));
			getPageData(catItems);
		}
	}, [categoryInitial]);
	function setAttributeValues(attributesList, values) {
		if (attributesList?.length > 0) {
			attributesList?.map((v, i) => {
				let data = values.find((x) => x.attribute_code === v.attribute_code);
				if (data) {
					if (v.frontend_input == 'text') v.value = data.value;
					else if (v.frontend_input == 'select') {
						if (v.options?.length > 0) {
							v.options?.map((x, i) => {
								if (x.value === data.value) v.default_value = x.value;
							});
						}
					}
				}
			});
			setAttributeList(attributesList);
		}
	}

	const formatDate = (value) => {
		var date = moment(value).format(pageConstants.dateFormat).toString();
		return date;
	};


	var [dummyProducts, setDummyProducts] = useState({
		custom_attributes: [
			{
				attribute_code: '',
				value: 0,
				name: '',
			},
		],
	});

	const obj: Partial<Iget> = {
		params: {},
		url: `products/attribute-sets/${productDetails.attribute_set_id}/attributes`,
		enabled: false,
		caching: true,
	};
	const attributeListQry = hooks.useQuery(obj);

	useEffect(() => {
		let attributes = [] as any;
		if (attributeListQry.data) {
			attributeListQry.data.forEach(function (element) {
				if (
					element.is_user_defined == true &&
					element.attribute_code != 'brand' &&
					element.attribute_code != 'allergens' &&
					element.attribute_code != 'uom'
				)
					attributes.push({ ...element, value: '' });
			});
			setAttributeList(JSON.parse(JSON.stringify(attributes)));
		}
	}, [attributeListQry.data]);

	const addTierPricing = (item) => {
		let index = productDetails.tier_prices.findIndex(
			(x) =>
				(x.extension_attributes.percentage_value == item.value || x.value == item.value) &&
				x.qty == item.qty
		);
		if (index != -1 || (item.qty == '' && item.value == '')) {
			notify('duplicate tier price').error();

			return false;
		}
		let data = {
			customer_group_id: 32000,
			qty: 0,
			value: 0,
			extension_attributes: {
				website_id: 0,
				percentage_value: 0,
			},
		};
		//let qtySavd = item.qty.toFixed(2);
		data.qty = parseInt(item.qty);
		if (item.drpDown === 'fixed') {
			data.value = parseFloat(item.value);
		} else {
			data.extension_attributes.percentage_value = parseFloat(item.value);
		}
		productDetails.tier_prices.push(data);
		setProductDetails({ ...productDetails });
	};
	const deleteTierPricing = (index) => {
		if (productDetails && productDetails.tier_prices.length > 0) {
			if (index != -1) {
				productDetails.tier_prices.splice(index, 1);
				setProductDetails({ ...productDetails });
			}
		}
	};

	const onChangeValue = (val, name) => {
		const item = productDetails as any;
		let atrVal = {
			attribute_code: '',
			value: '',
		};
		switch (name) {
			case 'name': {
				if (!val) {
					error.productNameError = 'Product Name cannot be blank';
				} else {
					error.productNameError = '';
				}
				item.name = val;
				setError(error);
				break;
			}
			case 'uom':
				if (val.target.value != '-Select-') {
					let descItem = {
						attribute_code: '',
						value: '',
					};
					let isExist = item.custom_attributes.find((item) => item.attribute_code == name);
					if (isExist) {
						item.custom_attributes &&
							item.custom_attributes.map((x) => {
								if (x.attribute_code == name) {
									x.value = val.target.value;
								}
							});
					} else {
						descItem.attribute_code = name;
						descItem.value = val.target.value;
						item.custom_attributes.push(descItem);
					}
				}
				break;
			case 'tax_class_id': {
				if (val.target.value != '-Select-') {
					let descItem = {
						attribute_code: '',
						value: '',
					};
					let taxClassExist = item.custom_attributes.find((item) => item.attribute_code == name);
					if (taxClassExist) {
						item.custom_attributes &&
							item.custom_attributes.map((x) => {
								if (x.attribute_code == name) {
									x.value = val.target.value;
								}
							});
					} else {
						descItem.attribute_code = name;
						descItem.value = val.target.value;
						item.custom_attributes.push(descItem);
					}
				}
				break;
			}
			case 'description': {
				let descItem = {
					attribute_code: '',
					value: '',
				};
				let isExist = item.custom_attributes.find((item) => item.attribute_code == name);
				if (isExist) {
					item.custom_attributes &&
						item.custom_attributes.map((x) => {
							if (x.attribute_code == name) {
								x.value = val;
							}
						});
				} else {
					descItem.attribute_code = name;
					descItem.value = val;
					item.custom_attributes.push(descItem);
				}
				break;
			}
			case 'supplier_sku':
				if (!val) {
					error.skuError = 'SKU cannot be blank';
				} else {
					error.skuError = '';
				}
				// item.sku = val;
				setError(error);
				let descItem2 = {
					attribute_code: '',
					value: '',
				};
				let isAvailable = item.custom_attributes.find((item) => item.attribute_code == name);
				if (isAvailable) {
					item.custom_attributes &&
						item.custom_attributes.map((x) => {
							if (x.attribute_code == name) {
								x.value = val;
							}
						});
				} else {
					descItem2.attribute_code = name;
					descItem2.value = val;
					item.custom_attributes.push(descItem2);
				}
				break;
			case 'upc':
				if (val.length <= 12) {
					if (val && val.length < 12) {
						error.upcError = 'Enter a valid UPC';
					} else if (val && val.length > 12) {
						error.upcError = 'Enter a valid UPC';
					} else {
						error.upcError = '';
					}
					setError(error);
					let descItem1 = {
						attribute_code: '',
						value: '',
					};
					let isExists = item.custom_attributes.find((item) => item.attribute_code == name);
					if (isExists) {
						item.custom_attributes &&
							item.custom_attributes.map((x) => {
								if (x.attribute_code == name) {
									x.value = val;
								}
							});
					} else {
						descItem1.attribute_code = name;
						descItem1.value = val;
						item.custom_attributes.push(descItem1);
					}
				}
				break;
			case 'price':
				item.price = val;
				let updatedSpclPrice = item.custom_attributes.find((x) => x.attribute_code == 'special_price');
				if (parseInt(updatedSpclPrice?.value) > parseInt(item.price)) {
					error.spclPriceError = 'Special Price should not be greater than Price per unit';
				}
				else {
					error.spclPriceError = '';
				}
				setProductDetails({ ...item });
				break;
			case 'qty':
				if (val != '' && parseInt(val) < item.extension_attributes.stock_item.max_sale_qty) {
					error.maxSaleError = 'Max Order Quantity should not be greater than Inventory';
				}
				if (
					item.extension_attributes.stock_item.max_sale_qty == '' ||
					parseInt(val) >= parseInt(item.extension_attributes.stock_item.max_sale_qty)
				) {
					error.maxSaleError = '';
				}
				item.extension_attributes.stock_item.qty = val;
				break;
			case 'thresholdValue':
				item.extension_attributes.stock_item.min_qty = val;
				break;
			case 'pdtStatus':
				if (val.target.value != '-Select-') {
					if (val.target.value == 1) {
						item.extension_attributes.stock_item.is_in_stock = true;
					} else {
						item.extension_attributes.stock_item.is_in_stock = false;
					}
				}
				break;
			case 'special_from_date':
				let data = formatDate(val);
				let descItem = {
					attribute_code: '',
					value: '',
				};
				let isExist = item.custom_attributes.find((item) => item.attribute_code == name);
				if (isExist) {
					item.custom_attributes &&
						item.custom_attributes.map((x) => {
							if (x.attribute_code == name) {
								x.value = data;
							}
						});
				} else {
					descItem.attribute_code = name;
					descItem.value = data;
					item.custom_attributes.push(descItem);
				}
				setProductDetails({ ...item });
				let isAfter = validateDate();
				if (isAfter) {
					error.fromDateError = 'Date From should not be greater than Date To';
				}
				else {
					error.fromDateError = '';
				}
				break;
			case 'special_to_date':
				let toDate = formatDate(val);
				let itemDate = {
					attribute_code: '',
					value: '',
				};
				let ItemIsExist = item.custom_attributes.find((item) => item.attribute_code == name);
				if (ItemIsExist) {
					item.custom_attributes &&
						item.custom_attributes.map((x) => {
							if (x.attribute_code == name) {
								x.value = toDate;
							}
						});
				} else {
					itemDate.attribute_code = name;
					itemDate.value = toDate;
					item.custom_attributes.push(itemDate);
				}
				setProductDetails({ ...item });
				let dateIsAfter = validateDate();
				if (dateIsAfter) {
					error.fromDateError = 'Date From should not be greater than Date To';
				} else {
					error.fromDateError = '';
				}
				break;
			case 'maxOrder':
				let qtyprice = parseInt(item.extension_attributes.stock_item.qty);
				if (val != '' && parseInt(val) > qtyprice) {
					error.maxSaleError = 'Max Order Quantity should not be greater than Inventory';
				} else error.maxSaleError = '';
				item.extension_attributes.stock_item.max_sale_qty = val;
				break;
			case 'minOrder':
				if (parseInt(val) < 1 || val == '') {
					error.minSaleError = 'Min Order Quantity should not be less than 1';
				} else {
					error.minSaleError = '';
				}
				item.extension_attributes.stock_item.min_sale_qty = val;
				break;
			case 'special_price':
				let splPrice = {
					attribute_code: '',
					value: '',
				};
				if (parseInt(val) > parseInt(item.price)) {
					error.spclPriceError = 'Special Price should not be greater than Price per unit';
				}
				else {
					error.spclPriceError = '';
				}
				let splPriceIsExist = item.custom_attributes.find((item) => item.attribute_code == name);
				if (splPriceIsExist) {
					item.custom_attributes &&
						item.custom_attributes.map((x) => {
							if (x.attribute_code == name) {
								x.value = val;
							}
						});
				} else {
					splPrice.attribute_code = name;
					splPrice.value = val;
					item.custom_attributes.push(splPrice);
				}
				checkAndUpdateFromToDate();
				break;
			case 'attribute':
				if (val.target.value == '-Select-') {
					error.attributeError = 'Please select any Attribute Set';
					item.attribute_set_id = 0;
				} else {
					obj.url = `products/attribute-sets/${val.target.value}/attributes`;
					obj.key = val.target.value;
					attributeListQry.refetch();
					error.attributeError = '';
					item.attribute_set_id = parseInt(val.target.value);
				}
				setUserDefinedAttributes([]);
				setError(error);
				break;
			case 'brand':
				let brand_name = item.custom_attributes.find((item) => item.attribute_code == name);
				if (brand_name) {
					item.custom_attributes &&
						item.custom_attributes.map((x) => {
							if (x.attribute_code == name) {
								x.value = val;
							}
						});
				} else {
					atrVal.attribute_code = name;
					atrVal.value = val;
					item.custom_attributes.push(atrVal);
				}
				break;
			case 'media_gallery_entries':
				mediaFiles = [];
				setMediaFiles(mediaFiles);
				if (val.length > 0) {
					val &&
						val.map((data) => {
							if (data.selectedFile != undefined) {
								var newType: string;
								newType = data.selectedFile.split('base64,')[1];
								if (newType.substring(0, 3) == 'iVB') {
									newType = 'image/png';
								} else {
									newType = 'image/jpeg';
								}
								mediaFiles.push({
									media_type: 'image',
									label: null,
									position: 1,
									disabled: false,
									types: [
										"image",
										"small_image",
										"thumbnail"
									],

									content: {
										base64_encoded_data: data.selectedFile.split('base64,')[1],
										type: newType,
										name: 'uploaded.jpg',
									},
								});
								setMediaFiles(mediaFiles);
							}
						});
				}

				break;
			default:
				break;
		}

		setProductDetails({ ...item });
	};

	function checkAndUpdateFromToDate() {
		let date = formatDate(new Date);
		let itemFromDate = {
			attribute_code: '',
			value: '',
		};
		let isExistFrom = productDetails.custom_attributes.find((item) => item.attribute_code == 'special_from_date');
		if (!isExistFrom) {
			itemFromDate.attribute_code = 'special_from_date';
			itemFromDate.value = date;
			productDetails.custom_attributes.push(itemFromDate);
		}
		let itemToDate = {
			attribute_code: '',
			value: '',
		};
		let isExistTo = productDetails.custom_attributes.find((item) => item.attribute_code == 'special_to_date');
		if (!isExistTo) {
			itemToDate.attribute_code = 'special_to_date';
			itemToDate.value = date;
			productDetails.custom_attributes.push(itemToDate);
		}
	}

	function setCustomAttributes(item, name, val) {
		let isExist = item.find((item) => item.attribute_code == name);
		if (isExist) {
			item &&
				item.map((x) => {
					if (x.attribute_code == name) {
						x.value = val;
					}
				});
		} else {
			let obj = { attribute_code: name, value: val };
			item.push(obj);
		}
	}

	const saveDetails = (action) => {
		switch (action) {
			case 'submit':
				if (parsed.sku) {
					showConfirmationModal(action, 'Save the changes?');
				} else {
					submitData();
				}
				break;
			case 'clear':
				if (
					productDetails.name != '' ||
					productDetails.sku != '' ||
					productDetails.price != '' ||
					productDetails.extension_attributes.stock_item.min_qty != 0
				) {
					showConfirmationModal(action, 'Discard the changes?');
				}
				break;
			default:
				break;
		}
	};
	function showConfirmationModal(action, text) {
		setAction(action);
		setmodalText(text);
		setShowModal(!showPopup);
	}
	async function onSubmit() {
		if (action === 'submit') {
			submitData();
		} else if (action === 'clear') {
			clearFunction();
		}
		setShowModal(!showPopup);
	}
	async function closePopup() {
		await setShowModal(!showPopup);
	}

	const validateDate = () => {
		var fromDate =
			productDetails &&
			productDetails.custom_attributes.find((x) => x.attribute_code == 'special_from_date');
		var toDate =
			productDetails &&
			productDetails.custom_attributes.find((x) => x.attribute_code == 'special_to_date');
		var isafter = moment(fromDate?.value).isAfter(toDate?.value);
		return isafter;
	};
	const submitData = async () => {
		setIsLoading(true);
		let data = JSON.parse(JSON.stringify(productDetails as any));
		mediaFiles.forEach((media) => {
			data.media_gallery_entries.push(media);
		});
		data.type_id = 'simple';
		var priceField = parseFloat(data.price).toFixed(2);
		data.price = parseFloat(priceField);
		data.extension_attributes.other_suppliername = [];
		data.extension_attributes.productcatagory_name = '';
		data.extension_attributes.product_subcatagoryname = [];
		data.extension_attributes.currency_list = '';
		// data.custom_attributes = data.custom_attributes.filter((x) => x.value != '');
		if (!parsed.sku) {
			if (data.media_gallery_entries) {
				data.media_gallery_entries = data.media_gallery_entries.filter(
					(x) => x.content.base64_encoded_data != ''
				);
			}
		}
		if (data.product_links) {
			data.product_links = data.product_links.filter((x) => x.linked_product_sku != '');
		}
		var specialPrice =
			productDetails &&
			productDetails.custom_attributes.find((x) => x.attribute_code == 'special_price');
		if (specialPrice) {
			data.custom_attributes.map((x) => {
				if (x.attribute_code == 'special_price') {
					x.value = Number(x.value);
				}
			});
		}
		var fromDate =
			productDetails &&
			productDetails.custom_attributes.find((x) => x.attribute_code == 'special_from_date');
		if (fromDate) {
			data.custom_attributes.map((x) => {
				if (x.attribute_code == 'special_from_date') {
					x.value = helper.changeDateFormat(x.value);
				}
			});
		}
		if (data.extension_attributes.stock_item.max_sale_qty == '')
			data.extension_attributes.stock_item.max_sale_qty = 0;
		else {
			data.extension_attributes.stock_item.max_sale_qty = parseInt(
				data.extension_attributes.stock_item.max_sale_qty
			);
			data.extension_attributes.stock_item.min_sale_qty = parseInt(
				data.extension_attributes.stock_item.min_sale_qty
			);
		}
		if (data.extension_attributes.stock_item.qty == '')
			data.extension_attributes.stock_item.qty = 0;
		else
			data.extension_attributes.stock_item.qty = parseInt(data.extension_attributes.stock_item.qty);
		var toDate =
			productDetails &&
			productDetails.custom_attributes.find((x) => x.attribute_code == 'special_to_date');
		if (toDate) {
			data.custom_attributes.map((x) => {
				if (x.attribute_code == 'special_to_date') {
					x.value = helper.changeDateFormat(x.value);
				}
			});
		}
		if (userDefinedAttributes?.length > 0) {
			userDefinedAttributes.map((v, i) => {
				let item = data.custom_attributes.find((x) => x.attribute_code == v.attribute_code);
				if (item) {
					item.value = v.value;
				} else {
					data.custom_attributes.push(v);
				}
			});
		}
		var isafter = moment(fromDate?.value).isAfter(toDate?.value);
		if (data.custom_attributes.filter((x) => x.attribute_code == 'category_ids') &&
			data.custom_attributes.filter((x) => x.attribute_code == 'category_ids')?.length > 0) {
			let selectedCat = data.custom_attributes.filter((x) => x.attribute_code == 'category_ids')[0]?.value;
			if (selectedCat?.length > 0) {
				if (data.extension_attributes?.category_links)
					data.extension_attributes.category_links = [] as any;
				selectedCat.map((innerData) => {
					var category_obj1 = {
						position: 0,
						category_id: '',
					} as any;
					category_obj1.category_id = innerData;
					category_obj1.position = category_obj1.position;
					if (data.extension_attributes) {
						data.extension_attributes?.category_links?.push(category_obj1);
					}
				});
			}
		}
		if (!parsed.sku && data.product_links && data.product_links.length > 0) {
			data.product_links.map((x) => {
				x.sku = productDetails.sku;
			});
		}
		// else if ((!parsed.sku) && (data.extension_attributes.stock_item.min_sale_qty > data.extension_attributes.stock_item.max_sale_qty)) {
		// 	setIsLoading(false);
		// 	notify('Max Order Quantity must be greater than Min Order Quantity').error();
		// }
		if (isafter) {
			setIsLoading(false);
			notify('Date From should not be greater than Date To').error();
		} else if (
			!parsed.sku &&
			data.extension_attributes.stock_item.min_sale_qty > data.extension_attributes.stock_item.qty
		) {
			setIsLoading(false);
			notify('Min Order Quantity should not be greater than Inventory').error();
		} else if (
			!parsed.sku &&
			data.extension_attributes.stock_item.max_sale_qty > data.extension_attributes.stock_item.qty
		) {
			setIsLoading(false);
			notify('Max Order Quantity should not be greater than Inventory').error();
		} else {
			const obj: Partial<Ipost> = {
				data: { product: data },
				params: {},
				url: parsed.sku ? `products/${productDetails.sku}` : `products`,
				message: `Product details ${parsed.sku ? 'updated' : 'added'} successfully`,
			};
			try {
				if (parsed.sku) {
					await api.put(obj).then(function (data) {
						if (typeof data != 'undefined') history.push('productManagement');
					});
				} else {
					await api.post(obj).then(function (data) {
						if (typeof data != 'undefined') history.push('productManagement');
					});
				}
				setIsLoading(false);
			} catch (e) {
				setIsLoading(false);
			}
		}
	};
	// Allergens
	const deleteSelectedAllergen = (val) => {
		let item = productDetails;
		allergensList.splice(
			allergensList.findIndex((x) => x.value == val),
			1
		);
		dummyProducts.custom_attributes.splice(
			dummyProducts.custom_attributes.findIndex((x) => x.value == val),
			1
		);
		if (dropDwnAllergen && dropDwnAllergen.findIndex((x) => x.value == val) != 0) {
			let data = dummyAllergenList.find((x) => x.value == val);
			if (data) {
				dropDwnAllergen.push(data);
			}
			setdropDwnAllergen(dropDwnAllergen);
		}
		let pdtAllergen = item.custom_attributes.filter((x) => x.attribute_code == 'allergens');
		if (pdtAllergen && pdtAllergen.length > 0) {
			item.custom_attributes &&
				item.custom_attributes.map((x) => {
					if (x.attribute_code == 'allergens') {
						x.value = removeValue(x.value, val);
					}
				});
		}
		setProductDetails(item);
		allergensList?.map((x) => {
			x.isChecked = false;
		});
		setAllergensList(JSON.parse(JSON.stringify(allergensList)));
		setEnableButton(false);
		updateIdList([]);
	};
	const deleteAllergens = () => {
		// let item = productDetails;
		idList.forEach((element) => {
			deleteSelectedAllergen(element);
		});
		setEnableButton(false);
		updateIdList([]);
	};
	const removeValue = function (list, value) {
		let separator = ',';
		var values = list.split(separator);
		for (var i = 0; i < values.length; i++) {
			if (values[i] == value) {
				values.splice(i, 1);
				return values.join(separator);
			}
		}
		return list;
	};
	const addAllergens = () => {
		let item = productDetails;
		if (assignedProperty.name != '') {
			dummyProducts.custom_attributes.push(AllergenArray);
			const allergenItems = dummyProducts.custom_attributes.filter(
				(x) => x.attribute_code == 'allergens'
			);
			if (allergenItems) {
				setAllergensList([...allergenItems]);
			}
			if (dropDwnAllergen.findIndex((x) => x.label == assignedProperty.name) != 0) {
				const itemList = JSON.parse(JSON.stringify(dummyProducts.custom_attributes));
				const user = JSON.parse(JSON.stringify(assignedProperty));
				if (user && itemList && itemList.map((x) => x.name).indexOf(user.name) != -1) {
					let pdtAllergen = item.custom_attributes.filter((x) => x.attribute_code == 'allergens');
					if (pdtAllergen && pdtAllergen.length > 0) {
						item.custom_attributes &&
							item.custom_attributes.map((x) => {
								if (x.attribute_code == 'allergens') {
									x.value = x.value + ',' + assignedProperty.value;
								}
							});
					} else {
						let dat = {
							attribute_code: '',
							value: '',
						};
						dat.attribute_code = 'allergens';
						dat.value = assignedProperty.value.toString();
						item.custom_attributes.push(dat);
					}
					dropDwnAllergen.splice(
						dropDwnAllergen.findIndex((x) => x.label == user.name),
						1
					);
					assignedProperty.name = '';
					assignedProperty.value = 0;
				}
				setProductDetails(item);
				setdropDwnAllergen(dropDwnAllergen);
			}
			setssignedProperty({
				attribute_code: '',
				value: 0,
				name: '',
			});
			setAllergenArray(obj1);
		}
	};

	const onSelectDropdwn = (e, attribute, list) => {
		if (e.target.value) {
			AllergenArray.attribute_code = attribute;
			AllergenArray.value = e.target.value;
			let index = list.find((x) => x.value == e.target.value);
			if (index) {
				AllergenArray.name = index.label;
			}
			setAllergenArray(AllergenArray);
			// dummyProducts.custom_attributes.push(data);
			setssignedProperty(JSON.parse(JSON.stringify(AllergenArray)));
		}
	};
	const selectAllergenCheckBox = (id, event) => {
		if (event.target.checked) {
			idList.push(id);
			updateIdList(idList);
		} else {
			idList.splice(idList.indexOf(id), 1);
			updateIdList(idList);
		}
		allergensList.find((x) => x.value == id).isChecked = event.target.checked;
		setAllergensList(allergensList);
		setHasAllergensChange(!hasAllergensChange);
		if (idList.length >= 1) {
			setEnableButton(true);
		} else {
			setEnableButton(false);
		}
	};
	const addSubstitutionProducts = (id) => {
		let pdtItem = productDetails;
		let pdtList = subProductsList;
		const list = substitutionProducts.find((x) => x.sku == id);
		if (list) {
			let ids = [] as any;
			pdtList.map((item) => {
				ids.push(item.id);
			});
			if (!ids.includes(list.id)) {
				pdtList.push(list);
				let isExist = {
					sku: parsed.sku,
					link_type: 'substitution',
					linked_product_sku: '',
					linked_product_type: 'simple',
					position: 1,
				};
				if (productDetails.product_links.findIndex((x) => x.linked_product_sku == id) != 0) {
					isExist.linked_product_sku = id;
					pdtItem.product_links.push(isExist);
				}
				setProductDetails(pdtItem);
			} else {
				notify('Product already exists').warn();
			}
		}
		setsubProductsList([...pdtList]);
	};
	const deleteSubstitutionPdt = (id) => {
		let item = productDetails;
		subProductsList.splice(
			subProductsList.findIndex((x) => x.sku == id),
			1
		);
		setsubProductsList(JSON.parse(JSON.stringify(subProductsList)));
		let itemIndex = productDetails.product_links.find((x) => x.linked_product_sku == id);
		if (itemIndex) {
			item.product_links.splice(
				item.product_links.findIndex((x) => x.linked_product_sku == id),
				1
			);
			setProductDetails(item);
		}
	};
	const deleteAllSubProducts = () => {
		let item = productDetails;
		idSubList.forEach((element) => {
			subProductsList.splice(
				subProductsList.findIndex((x) => x.sku == element),
				1
			);
			let itemIndex = productDetails.product_links.find((x) => x.linked_product_sku == element);
			if (itemIndex) {
				item.product_links.splice(
					item.product_links.findIndex((x) => x.linked_product_sku == element),
					1
				);
			}
		});
		setProductDetails(item);
		setsubProductsList(JSON.parse(JSON.stringify(subProductsList)));
		setSubProductButton(false);
		updateIdSubList([]);
	};
	const setFilteredData = (val) => {
		const sampleList = dummySubstitutionProducts;
		if (val && val.length > 0) {
			var list =
				sampleList &&
				sampleList.filter(
					(v) =>
						v.name.toLowerCase().includes(val.toLowerCase()) ||
						v.sku.toLowerCase().includes(val.toLowerCase())
				);
			setSubstitutionProducts(list);
		} else {
			setSubstitutionProducts(dummySubstitutionProducts);
		}
	};
	const selectProductCheckBox = (id, event) => {
		if (event.target.checked) {
			idSubList.push(id);
			updateIdSubList(idSubList);
		} else {
			idSubList.splice(idSubList.indexOf(id), 1);
			updateIdSubList(idSubList);
		}
		if (idSubList.length >= 1) {
			setSubProductButton(true);
		} else {
			setSubProductButton(false);
		}
	};

	function onChangeAttribute(name, val, type) {
		if (type == 'text' || (type == 'select' && val != '')) {
			const item = userDefinedAttributes;
			setCustomAttributes(item, name, val);
			setUserDefinedAttributes(JSON.parse(JSON.stringify(item)));
			let attr = attributeList?.find((x) => x.attribute_code == name);
			if (attr) {
				if (attr.frontend_input == 'text') attr.value = val;
				else if (attr.frontend_input == 'select') attr.default_value = val;
				setAttributeList(JSON.parse(JSON.stringify(attributeList)));
			}
		} else if (type == 'select' && val == '') {
			notify('Please choose a value other than select !!').error();
		}
	}
	const clearFunction = () => {
		setProductDetails({
			name: '',
			sku: '',
			attribute_set_id: 0,
			price: '',
			status: 1,
			type_id: 'simple',
			media_gallery_entries: [
				{
					media_type: 'image',
					label: null,
					position: 1,
					disabled: false,
					types: [
						"image",
						"small_image",
						"thumbnail"
					],

					content: {
						base64_encoded_data: '',
						type: 'image/jpeg',
						name: '',
					},
				},
			],
			product_links: [
				{
					sku: parsed.sku,
					link_type: 'substitution',
					linked_product_sku: '',
					linked_product_type: 'simple',
					position: 1,
				},
			],
			tier_prices: [],
			extension_attributes: {
				category_links: [
					// {
					// 	position: 0,
					// 	category_id: 0,
					// },
				] as any,
				stock_item: {
					qty: 0,
					min_qty: 0,
					is_in_stock: true,
					min_sale_qty: 1,
					max_sale_qty: '' as any,
					use_config_min_sale_qty: 0,
					use_config_max_sale_qty: 0,
					use_config_min_qty: 0,
				},
				other_suppliername: [],
				productcatagory_name: '',
				product_subcatagoryname: [],
				currency_list: '',
			},

			custom_attributes: [
				{
					attribute_code: 'seller_id',
					value: adminId as any, //userId as any,
					//name: ''
				},
			],
		});
		setError({
			productNameError: '',
			skuError: '',
			typeError: '',
			attributeError: '',
			fromDateError: '',
			minSaleError: '',
			maxSaleError: '',
			upcError: '',
			spclPriceError: '',
		});
	};
	const toggleBtnChange = (e) => {
		const checkedProp = e.currentTarget.checked;
		setStatusChecked(checkedProp);
		productDetails.status = checkedProp ? 1 : 2;
		setProductDetails(productDetails);
	};
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	}

	const onChangeCategory = (currentNode, selectedNodes) => {
		const data = updateNodeInTree(categoryLevel, currentNode.id, currentNode.checked)
		getParent(currentNode.parent_id, currentNode.id, currentNode.checked);
	};
	function getParent(id, selectednodeId, checked) {
		updateCategoryIds(selectednodeId, checked);
		let selectedParent = categoryLevel.children.filter(x => x.id === id);
		if (selectedParent?.length > 0) {
			categoryLevel.children.filter(x => x.id === id)[0].checked = true;
			updateCategoryIds(id, null);

			if (categoryLevel.id == selectedParent[0].parent_id) {
				categoryLevel.checked = true;
				updateCategoryIds(selectedParent[0].parent_id, null);
			}
		}
		else if (selectedParent?.length === 0) {
			if (categoryLevel.id == id) {
				categoryLevel.checked = true;
				updateCategoryIds(id, null);
			}
			else {
				for (let i = 0; i < categoryLevel.children.length; i++) {
					let parent = categoryLevel.children[i]?.children
					if (parent?.length > 0) {
						for (let k = 0; k < parent.length; k++) {
							let selectedId = parent[k].id;
							if (selectedId === id) {
								parent[k].checked = true;
								updateCategoryIds(id, null);
								if (categoryLevel.children[i].id === parent[k].parent_id) {
									categoryLevel.children[i].checked = true;
									updateCategoryIds(parent[k].parent_id, null);
								}
							}
						}
					}
				}
			}
		}

		setCategoryLevel(JSON.parse(JSON.stringify(categoryLevel)));
	}
	function updateCategoryIds(id, checked) {
		let item = productDetails;
		if (productDetails.custom_attributes.filter((x) => x.attribute_code == 'category_ids') &&
			productDetails.custom_attributes.filter((x) => x.attribute_code == 'category_ids')?.length > 0) {
			item.custom_attributes &&
				item.custom_attributes.map((x) => {
					if (x.attribute_code == 'category_ids') {
						if (checked === false) {
							let indexVal = x.value.findIndex((x) => x === id.toString());
							if (indexVal != -1) {
								x.value &&
									x.value.splice(
										indexVal,
										1
									);
							}
							if (x.value && x.value?.length > 0)
								setDisabled(false)
							else
								setDisabled(true)
						}
						else {
							let index = x.value.find((x) => x === id.toString());
							if (!index)
								x.value.push(id.toString());
							if (x.value && x.value?.length > 0)
								setDisabled(false)
							else
								setDisabled(true)
						}
					}
				});
		} else {
			let dat = {
				attribute_code: '',
				value: [] as any,
			};
			dat.attribute_code = 'category_ids';
			dat.value.push(id.toString());
			item.custom_attributes.push(dat);
		}
		setProductDetails(item);
	}
	function updateNodeInTree(node, nodeId, checked) {
		if (node.id == nodeId) {
			node.checked = checked;
			if (node.children != null && checked === false) {
				getChild(node)
			}
			return node;
		} else if (node.children != null) {
			let result;
			for (let i = 0; i < node.children.length; i++) {
				result = updateNodeInTree(node.children[i], nodeId, checked);
			}
			return result
		}
		return null;
	}
	function ExpandNodeInTree(node, nodeId, expanded) {
		if (node.id == nodeId) {
			node.expanded = expanded;
			return node;
		} else if (node.children != null) {
			let result;
			for (let i = 0; i < node.children.length; i++) {
				result = ExpandNodeInTree(node.children[i], nodeId, expanded);
			}
			return result
		}
		return null;
	}
	function getChild(node) {
		if (node.children != null) {
			let result;
			for (let i = 0; i < node.children.length; i++) {
				result = node.children[i].checked = false;
				updateCategoryIds(node.children[i].id, node.checked);
				getChild(node.children[i]);
			}
			return result
		}
		return null;
	}
	const onNodeToggle = (currentNode) => {
		ExpandNodeInTree(categoryLevel, currentNode.id, currentNode.expanded);
		console.log('onNodeToggle::', currentNode);
	};

	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={() => onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar}
				toggleSideBar={() => onclickHambrgBtn()}></Sidebar>
			{(parsed.sku && productDetails != null && productDetails.name === '') || isLoading == true ? (
				<Loader></Loader>
			) : null}
			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbHolder>
					<BreadcrumbNav
						Heading={userSaveText == 'Save' ? 'Edit Product' : 'Add Product'}
						breadcrumbList={breadcrumbNav}
					/>
				</BreadcrumbHolder>
				{/* Image and Video Holder */}
				<ProductImageHolder>
					<AddImageHolder
						onChangeValue={onChangeValue}
						imageData={productDetails.media_gallery_entries}
					></AddImageHolder>
					{/* <AddVideoHolder></AddVideoHolder> */}
				</ProductImageHolder>
				{/* Product details and Quantity Pricing */}
				<LineAccordion
					onNodeToggle={onNodeToggle}
					onChangeCategory={onChangeCategory}
					categoryLevel={categoryLevel}
					productDetails={productDetails}
					onChangeValue={onChangeValue}
					error={error}
					addTierPricing={addTierPricing}
					deleteTierPricing={deleteTierPricing}
					toggleBtnChange={toggleBtnChange}
					statusToggle={statusChecked}
					action={parsed.sku ? 'Save' : 'Add'}
				/>
				{/* <AllergenNameSection>
					<Heading fontSize="18px" fontFamily="OpenSansBold" sx={{ textTransform: 'uppercase' }}>
						allergens
					</Heading>
					<AllergenList
						onSelectDropdwn={onSelectDropdwn}
						deleteSelectedAllergen={deleteSelectedAllergen}
						deleteAllergens={deleteAllergens}
						addAllergens={addAllergens}
						allergenList={allergensList}
						assignedProperty={assignedProperty}
						selectAllergenCheckBox={selectAllergenCheckBox}
						enableDeleteButton={enableDeleteButton}
						dropDwnAllergen={dropDwnAllergen}
						hasAllergensChange={hasAllergensChange}
					></AllergenList>
				</AllergenNameSection> */}
				<AddProduct>
					<Heading fontSize="18px" fontFamily="OpenSansBold" sx={{ textTransform: 'uppercase' }}>
						Add substitution products
					</Heading>
					<ProductList
						setFilteredData={(val) => setFilteredData(val)}
						id={parsed.sku}
						deleteSubstitutionPdt={(sku) => deleteSubstitutionPdt(sku)}
						subProductsList={subProductsList}
						addSubstitutionProducts={(id) => addSubstitutionProducts(id)}
						subProductList={substitutionProducts}
						deleteAllSubProducts={deleteAllSubProducts}
						selectProductCheckBox={selectProductCheckBox}
						enableSubProductButton={enableSubProductButton}
					></ProductList>
				</AddProduct>
				{productDetails.attribute_set_id > 0 && (
					<AllergenNameSection>
						<Heading fontSize="18px" fontFamily="OpenSansBold" sx={{ textTransform: 'uppercase' }}>
							attributes
						</Heading>
						<AddDynamicForm data={attributeList} onChangeValue={onChangeAttribute}></AddDynamicForm>
					</AllergenNameSection>
				)}

				<FooterBtnBlock>
					<Stack direction="x" gap={3} justifyContent={['center', 'flex-end']}>
						{parsed.sku ? null : (
							<Button
								onClick={() => saveDetails('clear')}
								flex={[1, 1, 'initial']}
								variant="secondary"
							>
								Clear
							</Button>
						)}
						<Button
							onClick={() => history.push('productManagement')}
							flex={[1, 1, 'initial']}
							variant="secondary"
						>
							Cancel
						</Button>
						<Button
							//onClick={submitData}
							onClick={() => saveDetails('submit')}
							disabled={
								disable ||
								productDetails.name === '' ||
								productDetails.price === '' ||
								productDetails.custom_attributes[3]?.value == '' ||
								productDetails.attribute_set_id == 0 ||
								error.productNameError != '' ||
								error.skuError !== '' ||
								error.typeError != '' ||
								error.fromDateError != '' ||
								error.minSaleError != '' ||
								error.maxSaleError != '' ||
								error.upcError != '' ||
								error.spclPriceError != '' ||
								isLoading == true
							}
							flex={[1, 1, 'initial']}
						>
							{parsed.sku ? 'Save' : 'Add'}
						</Button>
					</Stack>
				</FooterBtnBlock>
			</MainContent>
			<SweetAlert
				show={showPopup}
				confirmBtnText="Confirm"
				onConfirm={onSubmit}
				showCancel={true}
				onCancel={closePopup}
				title={'Confirmation Box'}
			>
				{() => <form>{modalText}</form>}
			</SweetAlert>
			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}

export default AddProducts;
