import { permissionGroup, venueUserPermissionList } from 'models/enum-constants/common.constants';
import Iget from 'models/Iget';
import Ipost from 'models/Ipost';
import urlObj from 'models/url.obj';
import queryString from 'query-string';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Flex, Link, Text } from 'rebass/styled-components';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { FlexboxProps, LayoutProps, SpaceProps } from 'styled-system';
import { Button, Stack, Loader } from 'ui/components';
import * as Icon from 'ui/icons';
import no_image from 'ui/images/no_image_list.png';
import AppContext from 'utils/appContext';
import env from 'utils/constants';
import urls from 'utils/create.url';
import helper from 'utils/helper.functions';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import utilityFunctions from 'utils/utility.functions';
import {
	DeleteParkOrder, ParkedOrdersBlock, StyledParkedOrders,
	TotalAmountBlock,
	ViewAllHeadingBlock
} from './styles';
import notify from 'utils/toast.service';
import http from 'utils/http.service';

type styledSystemProps = SpaceProps & LayoutProps & FlexboxProps;

type StyledParkedOrdersProps = styledSystemProps & {
	totalAmount?: string;
};
function ParkedOrders({
	totalAmount,
	...styledSystemProps
}: StyledParkedOrdersProps): ReactElement {
	const cartCtx = useContext(AppContext).cartObj;
	const ctx = useContext(AppContext).productSearchObj;
	const [products, setProducts] = useState([] as any);
	const [productsTotal, setProductsTotal] = useState(0);
	const [hasData, setHasData] = useState(false);
	const [count, setProductCount] = useState(0);
	const [inProgress, setInProgress] = useState(false);
	const [parkorder_id, setParkorder_id] = useState('');
	const [orderName, setOrderName] = useState('');
	const [productsList, setProductsList] = useState([] as any);

	let urlObj: Partial<urlObj> = {
		url: 'experion-parkorder/parkorder/search?',
		pageSize: 1,
		filterDeleted: true,
		ctx: ctx,
		fields: [
			{ field_name: 'venue_user_id', field_value: localStorage.getItem('userId')! },
			{ field_name: 'venue_id', field_value: localStorage.getItem('selectedVenue')! },
		]
	};

	const obj: Partial<Iget> = {
		url: urls.GetSearchUrl(urlObj),
		enabled: true,
		refetchOnWindowFocus: false,
		resultFilteringRequired: true,
		key: 'parkedOrderObj',
		//caching: true,
	};
	const qryObj = hooks.useQuery(obj);

	useEffect(() => {
		qryObj.data &&
			qryObj.data &&
			qryObj.data.forEach((data) => {
				let totalPrice = 0;
				qryObj.data &&
					qryObj.data.map((item) => (totalPrice = totalPrice + (item.price)));
				setProductsTotal(totalPrice);
			});
	}, [qryObj.data]);

	const objItem: Partial<Iget> = {
		params: {},
		url: urls.GetSearchUrl(urlObj),
		enabled: true,
		key: 'parkedOrderObjItem',
		resultFilteringRequired: false,
	};
	var qryObjItem = hooks.useQuery(objItem);

	useEffect(() => {
		let data = qryObjItem?.data?.items;
        if (data && data.length >0) {
            setParkorder_id(data[0]?.parkorder_id);
            setOrderName(data[0]?.name);
        }
	}, [qryObjItem.data]);

	let history = useHistory();

	function deleteParkedOrder(hasMessage = true) {
		let obj1: Partial<Ipost> = {};
		obj1.message = !hasMessage ? ' ' : 'Parked Order deleted successfully';
		obj1.url = `experion-parkorder/parkorder/${parkorder_id}`;
		api.delete(obj1).then(function (d) {
			if (typeof d != 'undefined') {
				if (hasMessage) {
					window.location.href = window.location.href;
					history.push('/store/orders?tab=parked-orders');
				} else {
					cartCtx.stepperdCountUpdated = false;
					history.push('/store/cart');
				}
			}
		});
	}

	async function moveToCart() {
		let productData = productsList;
		
		let items = [] as any;
		
		productData?.forEach((v: any) => {
			items.push({ product_id: v.product_id, qty: v.qty });
		});
		
		if (items.length > 0) {

		let id: any;
		let CUSTOMER_TOKEN = localStorage.getItem('customerToken');
		id = await utilityFunctions.getCartId(CUSTOMER_TOKEN);
		if (id !== undefined) {

			const payload = {
				products: {
					quote_id: id,
					items: items,
					venue_user_id: localStorage.getItem('userId'),
					venue_id: localStorage.getItem('selectedVenue'),
					parked_id: productData.parkorder_id ,
				},
			};

			const obj: Partial<Ipost> = {
				data: payload,
				url: env.multiCartURL(),
				key: 'parkedToCart',
				message: 'Available products will be moved to cart!',
				headers: { Authorization: localStorage.getItem('customerToken') },
			};
			try {
				await api
					.post(obj)
					.then(function (data) {
						if (data == true) {
							setInProgress(false);
							cartCtx.stepperdCountUpdated = false;
							setProductsTotal(0);
							deleteParkedOrder(false);
							history.push('/store/cart');
						} else {
							setInProgress(false);
						}
					})
					.catch(function (data) {
						setInProgress(false);
					});
			} catch (e) {
				setInProgress(false);
			}
		}
	}else{
		setInProgress(false);
		notify('No products are available to move to the cart !!').error();
	}
	
	}

	let productItemObj: Partial<urlObj> = {
		url: 'experion-parkorder/parkorderitem/search?',
		pageSize: 2000,
		fields: [{
			field_name: 'parked_order_id',
			field_value: parkorder_id,
		}],
	};

	useEffect(() => {
		if (parkorder_id) {
			
			http.GET({ url: urls.GetSearchUrl(productItemObj) }).then(function (d) {
				let datas = d?.data?.items;
				if (datas) {
					setProductCount(datas.length);
					setProducts(datas);
					setProductsList(datas);
				}
				setHasData(true);
			});
		}
	}, [parkorder_id]);

	
		function checkImage1(image, defaultImage){
			if(image!== null || image!==undefined){
				return defaultImage;
				//no image in the API.
			}else{
				return defaultImage;
			}
		}
	return (
		// {inProgress === true ? <Loader></Loader> : null}
		<StyledParkedOrders {...styledSystemProps}>
			<TotalAmountBlock>
				<Box>
					<Text as="h6" fontSize={['16px', '20px']} fontFamily="OpenSansRegular">
						Total Amount
					</Text>
					<Text as="p" fontSize="18px" fontFamily="OpenSansBold">
						{helper.formatAmount(productsTotal)}
					</Text>
				</Box>
				{productsTotal != 0 && (
					<Button onClick={() => moveToCart()}>
						Proceed <Icon.ProceedIcon />
					</Button>
				)}
			</TotalAmountBlock>
			<ViewAllHeadingBlock>
				<Text as="h1" fontSize="16px">
					{orderName}
				</Text>
				{/* {utilityFunctions.isGranted
								(venueUserPermissionList.ParkedOrdersView) && <Link variant="nav" href="/venue/orders?tab=parked-orders">
					View All Parked Orders
				</Link>} */}
				{utilityFunctions.isAnyGranted(permissionGroup.ParkedOrder) && productsTotal != 0 && (
					<Link variant="nav" href="/store/orders?tab=parked-orders">
						View All Parked Orders
					</Link>
				)}
			</ViewAllHeadingBlock>
			<ParkedOrdersBlock>
				<SimpleBar style={{ maxHeight: 'calc(100vh - 450px)' }}>
					<Stack direction="y" gap={3}>
						{hasData &&
							productsList &&
							productsList.map((item) => (
								<Flex
									as="article"
									bg="white"
									p={[3, 4]}
									sx={{ alignItems: 'center' }}
									key={item.id}
								>
									<Flex
										alignItems="center"
										maxWidth={['100px', '126px']}
										sx={{ borderRadius: '16px', overflow: 'hidden' }}
									>
            						<img src={checkImage1(item?.image, no_image)} alt="Product Image"  style={{ maxWidth: '100%' }}/>
	

									</Flex>
									<Box flex={1} ml={[18]}>
										<Text
											as="h2"
											fontFamily="OpenSansBold"
											fontSize={['16px', '18px', '20px']}
											lineHeight="26px"
											color="text.black01"
											className="truncate"
										>
											{item.name}
										</Text>
										<Text as="h3" fontSize="16px" color="text.black01" className="truncate">
										No. of items <strong>{item.qty}</strong> 
										</Text>
										<Text as="h3" fontSize="20px" color="text.black01" className="truncate" mt={2}>
										<strong>{helper.formatAmount(item.price)}</strong> {item.uom_name !== "" ?item.uom_name: 'per Pack'}
										</Text>
									</Box>
								</Flex>
							))}
					</Stack>
				</SimpleBar>
			</ParkedOrdersBlock>
			{(utilityFunctions.isGranted(venueUserPermissionList.ParkedOrdersDelete) ||
				utilityFunctions.isGranted(venueUserPermissionList.ParkedOrdersAdmin)) &&
				products[0] && (
					<DeleteParkOrder>
						<Button onClick={() => deleteParkedOrder()}>
							<Icon.DeleteIcon /> Delete this parked order
						</Button>
					</DeleteParkOrder>
				)}
		</StyledParkedOrders>
	);
}

export { ParkedOrders };
