import { venueUserPermissionList } from 'models/enum-constants/common.constants';
import Iget from 'models/Iget';
import Ipost from 'models/Ipost';
import IProduct from 'models/IProduct';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import { Box, Button, Flex, Image, Text } from 'rebass/styled-components';
import icons from 'ui/icons';
import no_image from 'ui/images/no_image_list.png';
import { Modal } from 'ui/patterns';
import urls from 'utils/create.url';
import helperFunctions from 'utils/helper.functions';
import http from 'utils/http.service';
import pageConstants from 'utils/pageConstants';
import api from 'utils/requestCtx/api.service';
import hooks from 'utils/requestCtx/reactQueryHooks';
import utilityFunctions from 'utils/utility.functions';
import Dairy from '../../images/allergens/diary.svg';
import Egg from '../../images/allergens/egg.svg';
import Fish from '../../images/allergens/fish.svg';
import Gluten from '../../images/allergens/gluten.svg';
import Lupin from '../../images/allergens/lupin.svg';
import Milk from '../../images/allergens/milk.svg';
import Peanuts from '../../images/allergens/peanuts.svg';
import SesameSeeds from '../../images/allergens/sesame-seeds.svg';
import Shellfish from '../../images/allergens/shellfish.svg';
import Soy from '../../images/allergens/soy.svg';
import TreeNut from '../../images/allergens/treenut.svg';
import { default as AddToFavoritesModal, default as CustomerNotFound } from './addToFavorites';
import { IconHolder, StyledProductGridView } from './styles';

function ProductGridView({ isFetching, data }: IProduct): ReactElement {
	const key = 'FavoriteKey';
	const [mutatePost] = hooks.useMutation(key);
	const history = useHistory();
	const [showAddToFavoritesModal, setShowAddToFavoritesModal] = useState(false);
	const [selectedFavourite, setSelectedFavourite] = useState('');
	const [showFavouriteNotFoundModal, setshowFavouriteNotFoundModal] = useState(false);
	const [venueUserId] = useState<any>(localStorage.getItem('userId'));
	var [collectionName, setCollection] = useState<any>();
	var [favoriteList, setFavoriteList] = useState<any>([]);
	const [userName, setUserName] = useState<any>('');
	const [venueId] = useState<any>(localStorage.getItem('selectedVenue'));
	const [selectedId, setSelectedId] = useState(0);
	const [minValue, setMinValue] = useState(0);

	// const toggleCustomerNotFoundModal = (e) => {
	// 	e.stopPropagation();
	// 	setshowFavouriteNotFoundModal(!showFavouriteNotFoundModal);
	// };
	const [isExist, setIsExist] = useState(false);
	const toggleAddToFavoritesModal = (event, id, min_qty) => {
		event.stopPropagation();
		fetch();
		setIsExist(false);
		setSelectedId(id);
		setMinValue(min_qty);
		setShowAddToFavoritesModal(!showAddToFavoritesModal);
	};
	const callbackFunction = (childData) => {
		setSelectedFavourite(childData);
	};
	// get all favorites api call

	const obj1: Partial<Iget> = {
		params: {},
		url: urls.GetSearchUrl({
			url: 'experion/productmanagement/favorite/search?',
			fields: [
				{ field_name: 'venue_user_id', field_value: venueUserId },
				{ field_name: 'venue_id', field_value: localStorage.getItem('selectedVenue')! },
			],
		}),
		enabled: false, //manual fetch
		key: 'favKey',
		resultFilteringRequired: false,
	};
	var qryFav = hooks.useQuery(obj1);

	// async function togglePopup() {
	// 	await setshowFavouriteNotFoundModal(!showFavouriteNotFoundModal);
	// }
	const fetch = () => {
		qryFav.refetch().then((data) => {
			if (data) {
				favoriteList = [];
				data.items?.forEach((item) => {
					if (item.is_delete === false) {
						favoriteList.push(item);
						setFavoriteList(() => [...favoriteList]);
					}
				});
				// setError({ ...error, venueNameError: 'Venue Name already exists' });
			} else {
				// setError({ ...error, venueNameError: '' });
			}
		});
	};
	useEffect(() => {
		setCollection(collectionName);
	}, [collectionName]);

	const validateNewCollection = (event) => {
		event.stopPropagation();
		if (event.currentTarget.value.length > 0) {
			setCollection(event.currentTarget.value);

			setUserName(localStorage.getItem('userName'));
		}
	};
	function saveToCollection(event) {
		event.stopPropagation();
		if (collectionName != undefined) {
			http
				.GET({ url: `/experion/productmanagement/favorite/${collectionName}/${venueUserId}` })
				.then(function (data) {
					//validate newly added favourite api call
					setIsExist(data.data);
					if (data.data === false) {
						if (!isExist) {
							let favJson = {
								favorite: {
									venue_user_id: venueUserId,
									favorite_name: collectionName,
									product_id: selectedId,
									created_by: userName,
									updated_by: userName,
									is_active: true,
									venue_id: venueId,
								},
							};

							const obj: Partial<Ipost> = {
								data: favJson,
								params: {},
								url: '/experion/productmanagement/favorite/create',
								message: 'saved to collection!',
							};
							try {
								mutatePost(obj).then((data) => {
									if (data !== undefined) {
										fetch();
									}
								});
							} catch (e) { }
						}
					}
				});
		}
	}

	async function togglePopup() {
		await setShowAddToFavoritesModal(!showAddToFavoritesModal);
	}

	const saveProductToFav = (value) => {
		let favJson = {
			favoriteOrder: {
				id: selectedId,
				venue_user_id: venueUserId,
				favorite_name: collectionName,
				created_by: userName,
				updated_by: userName,
				is_active: true,
				venue_id: venueId,
				is_delete: false,
				favorite_items: [
					{
						product_id: selectedId,
						qty: minValue,
						is_active: true,
						is_delete: false,
					},
				],
			},
		};

		const obj: Partial<Ipost> = {
			data: favJson,
			params: {},
			url: '/experion/productmanagement/favorite/updatefavorite/' + value,
			message: 'Added to favorites',
		};
		try {
			api.put(obj).then((data) => {
				if (data !== undefined) {
					togglePopup();
				}
			});
		} catch (e) { }
	};

	const formatString = (val) => {
		let temp = val;
		temp = temp.replace(/\s+/g, '');
		temp = temp.toLowerCase();
		switch (temp) {
			case 'gluten':
				return Gluten;
				break;
			case 'egg':
				return Egg;
				break;
			case 'dairy':
				return Dairy;
				break;
			case 'shellfish':
				return Shellfish;
				break;
			case 'fish':
				return Fish;
				break;
			case 'lupin':
				return Lupin;
				break;
			case 'milk':
				return Milk;
				break;
			case 'peanuts':
				return Peanuts;
				break;
			case 'treenuts':
				return TreeNut;
				break;
			case 'sesameseeds':
				return SesameSeeds;
				break;
			case 'soy':
				return Soy;
				break;
			default:
				break;
		}
	};

	return (
		<StyledProductGridView>
			{!isFetching &&
				data ?
				data?.items &&
				data.items?.map((item) => {
					let img = '';
					if (item.media_gallery_entries && item.media_gallery_entries.length > 0)
						img = `${item.media_gallery_entries[0].file}`;
					else img = no_image;
					let creditStatus = '';
					if (item?.extension_attributes?.credit_status.length === 0) {
						creditStatus = 'go';
					} else {
						item?.extension_attributes?.credit_status.forEach((obj) => {
							if (parseInt(venueId) === obj) {
								creditStatus = 'stop';
							}
						});
					}
					let ClassName = '';
					if (parseInt(item.extension_attributes.qty) === 0) {
						ClassName = 'row-highlighted';
					} else if (creditStatus == 'stop') {
						ClassName = 'un-available';
					}
					let vacation = 0;
					let EndOfVacation = '';
					if (vacation === 1) {
						EndOfVacation = '18 Apr 2021';
					}
					let showSpclPrice = false;
					let date = moment().format('YYYY-MM-DD');
					let fromDate =
						item.extension_attributes?.multi_seller_product_special_from_date !== ''
							? moment(item.extension_attributes?.multi_seller_product_special_from_date).format(
								'YYYY-MM-DD'
							)
							: '';
					let toDate =
						item.extension_attributes?.multi_seller_product_special_to_date !== ''
							? moment(item.extension_attributes?.multi_seller_product_special_to_date).format(
								'YYYY-MM-DD'
							)
							: '';
					if (fromDate !== '' && toDate !== '') {
						if (
							moment(date).isSameOrAfter(fromDate, 'date') &&
							moment(date).isSameOrBefore(toDate, 'date')
						) {
							showSpclPrice = true;
						} else {
							showSpclPrice = false;
						}
					} else if (fromDate !== '' && toDate === '') {
						if (moment(date).isSameOrAfter(fromDate, 'date')) {
							showSpclPrice = true;
						} else {
							showSpclPrice = false;
						}
					} else {
						showSpclPrice = false;
					}
					return (
						<Flex
							className={ClassName}
							style={{ cursor: 'pointer' }}
							as="article"
							bg="white"
							sx={{ alignItems: 'center' }}
							key={helperFunctions.getRandomInt(pageConstants.maxValue)}
							onClick={(e) => {
								e.stopPropagation();
								history.push({
									pathname: '/home/product-details',
									search: '?id=' + item.sku,
								});
							}}
						>
							<Flex alignItems="center" as="span" maxWidth={['100%']} sx={{ overflow: 'hidden' }}>
								<Box
									as="picture"
									style={{
										backgroundImage: 'url(' + img + ')',
										backgroundColor: 'white'
									}}
								></Box>
								{vacation === 1 ? (
									<Flex as="div" className="temp-unavailable" flexDirection="column">
										<Box>Temporarily unavailable till</Box>
										<Box className="date"> {EndOfVacation}</Box>
									</Flex>
								) : null}
								{utilityFunctions.isGranted(venueUserPermissionList.FavoriteOrderAdd) && (
									<Button
										onClick={(event) =>
											toggleAddToFavoritesModal(
												event,
												item.id,
												item.extension_attributes.min_order_qty
											)
										}
									>
										<icons.FavoritesIcon />
										<Text>Add to Favorites</Text>
									</Button>
								)}
							</Flex>
							<Flex alignItems="center" as="section" maxWidth={['100%']}>
								<Box flex={1}>
									<Text
										as="h1"
										fontFamily="OpenSansBold"
										fontSize="20px"
										lineHeight="26px"
										color="text.black"
										className="truncate"
									>
										{item.name}
									</Text>
									{/* <Text
										as="h3"
										fontSize="17px"
										fontFamily="OpenSansSemiBold"
										lineHeight="22px"
										color="text.black"
										className="truncate"
									>
										{item.extension_attributes.product_categories[0]}
									</Text> */}
									<Flex
										justifyContent="space-between"
										flexWrap="wrap"
										style={{ marginTop: '10px' }}
									>
										<Box>
											<Text
												as="h2"
												fontSize="17px"
												lineHeight="22px"
												color="text.black"
												className="truncate"
												style={{ display: 'block' }}
											>
												{item.extension_attributes.supplier_name}
											</Text>
										</Box>
										<Box>
											{showSpclPrice && parseFloat(
												item.extension_attributes?.multi_seller_product_special_price
											) > 0 ? (
												<Text as="h4" fontSize="14px" color="text.black05" className="truncate">
													<strong>
														$
														{parseFloat(
															item.extension_attributes?.multi_seller_product_special_price
														).toFixed(2)}
													</strong>{' '}
													{item.extension_attributes?.uomname &&
														item.extension_attributes?.uomname.length > 0
														? item.extension_attributes?.uomname
														: 'per Pack'}
												</Text>
											) : (
												<Text as="h4" fontSize="14px" color="text.black05" className="truncate">
													<strong>${parseFloat(item.price).toFixed(2)}</strong>{' '}
													{item.extension_attributes?.uomname &&
														item.extension_attributes?.uomname.length > 0
														? item.extension_attributes?.uomname
														: 'per Pack'}
												</Text>
											)}
										</Box>
									</Flex>
								</Box>
							</Flex>
							<IconHolder onClick={(e) => e.stopPropagation()}>
								{item.extension_attributes.allergen_names.map((v, i) => {
									if (i <= 3) {
										return (
											<Tooltip
												key={helperFunctions.getRandomInt(pageConstants.maxValue)}
												arrow
												title={v}
												animation="perspective"
												position="top"
												trigger="mouseenter"
											>
												{/* <icons.GoodServiceIcon /> */}
												<div className="img-block">
													<Image src={formatString(v)} />
												</div>
											</Tooltip>
										);
									}
								})}

								<Tooltip
									key={helperFunctions.getRandomInt(pageConstants.maxValue)}
									arrow
									animation="perspective"
									trigger="click"
									position="top"
									style={{
										marginLeft: 'auto',
										marginRight: '0',
										display: 'flex',
										alignItems: 'center',
									}}
									html={
										<div className="tooltip-body">
											{item.extension_attributes.allergen_names.map((v, i) => {
												if (i > 3) {
													return (
														<span key={helperFunctions.getRandomInt(pageConstants.maxValue)}>
															{/* <icons.GoodServiceIcon /> */}
															<div className="img-block">
																<Image
																	src={formatString(v)}
																	sx={{
																		width: 30,
																		height: 30,
																		border: 0,
																	}}
																/>
															</div>
															{<div>{v}</div>}
														</span>
													);
												}
											})}
										</div>
									}
								>
									{item.extension_attributes.allergen_names?.length > 4 && (
										<span key={helperFunctions.getRandomInt(pageConstants.maxValue)}>
											{item.allergens} <div>More</div>
										</span>
									)}
								</Tooltip>
							</IconHolder>
						</Flex>
					);
				}) :
				new Array(12).fill(null).map((_) => {
					return (
						<Skeleton
							key={helperFunctions.getRandomInt(pageConstants.maxValue)}
							width="250px"
							height="250px"
						/>
					);
				})
			}

			<Modal
				width="600px"
				showModal={showFavouriteNotFoundModal}
				setShowModal={setshowFavouriteNotFoundModal}
			>
				<CustomerNotFound parentCallback={callbackFunction} togglePopup={togglePopup} />
			</Modal>
			<Modal
				width="600px"
				showModal={showAddToFavoritesModal}
				setShowModal={setShowAddToFavoritesModal}
			>
				<AddToFavoritesModal
					isExist={isExist}
					validateNewCollection={validateNewCollection}
					saveToCollection={(event) => saveToCollection(event)}
					favoriteList={favoriteList}
					parentCallback={callbackFunction}
					togglePopup={togglePopup}
					saveProductToFav={saveProductToFav}
				/>
			</Modal>
		</StyledProductGridView>
	);
}

export { ProductGridView };
