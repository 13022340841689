import React, { ReactElement, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ReactQueryDevtools } from 'react-query-devtools';
import GlobalStyles from 'ui/styles/globalStyles';
import theme from 'ui/styles/theme';
import 'react-toastify/dist/ReactToastify.css';
import { GlobalStateProvider } from 'models/globalStateHook';
import AppRouter from 'router';
import { AppProvider } from 'utils/appContext';
import { Ictx } from 'models/Ictx';
function App(): ReactElement {
	const userSettings: Ictx = {
		productSearchObj: {
			Categories: '',
			query: '',
			Allergens: '',
			Suppliers: '',
			PriceFrom: 0,
			PriceTo: 0,
			SortDirection: '',
			SearchText: '',
			SearchUrl: '',
			currentPage: 1,
			SortField: '',
			Type: '',
			avoidSort: false,
			skuList: '',
			newArrivalDate: '',
			specialProductsView: false,
			location: '',
			radius: '',
			product_id: [],
		},
		userObj: { permissionList: [], venueId: null },
		cartObj: {
			stepperdCountUpdated: false,
			prodStepperCountUpdated: false,
			cartItemCount: [] as any,
		},
	};

	return (
		<GlobalStateProvider>
			<AppProvider value={userSettings}>
				<ThemeProvider theme={theme}>
					<GlobalStyles />
					{/* <ReactQueryDevtools initialIsOpen={false} position="bottom-left" /> */}
					<ToastContainer draggable={false} />
					<BrowserRouter>
						<AppRouter />
					</BrowserRouter>
				</ThemeProvider>
			</AppProvider>
		</GlobalStateProvider>
	);
}

export default App;
