import styled from 'styled-components';

const MainContent = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
	padding: 0 50px;
	@media (max-width: 960px) {
		padding: 0 15px;
	}
	table {
		thead,
		tbody {
			tr {
				td,
				th {
					margin-right: initial;
					&:nth-child(1) {
						flex: 0 0 250px;
						max-width: 250px;
						h1 {
							display: flex;
							align-items: center;
						}
						@media screen and (max-width: 767px) {
							flex: 0 0 100% !important;
							max-width: 100% !important;
							margin-bottom: 15px;
						}
					}
					&:nth-child(3) {
						margin-left: auto;
						margin-right: initial;
					}
				}
			}
		}
	}
`;

const ProductOrderList = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	row-gap: 20px;
	column-gap: 20px;
	margin-bottom: 100px;
	@media (max-width: 1300px) {
		grid-template-columns: repeat(1, 1fr);
	}

	article {
		flex-direction: row;
		background: ${({ theme }) => theme.colors.bg.white};
		border: 1px solid ${({ theme }) => theme.colors.border.grayMedium};
		box-sizing: border-box;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 16px;
		overflow: hidden;
		transition: box-shadow 100ms ease-in, transform 100ms ease-in;
		align-items: flex-start;
		height: 100%;
		span {
			max-width: 218px;
			flex: 0 0 218px;
			height: 224px;
			background-repeat: no-repeat;
			background-size: cover;
			@media (max-width: 1300px) {
				height: 100%;
			}
			@media (max-width: 768px) {
				max-width: 126px;
				flex: 0 0 126px;
			}
			@media (max-width: 480px) {
				max-width: 100px;
				flex: 0 0 100px;
			}
		}
		picture {
			display: flex;
			width: 85px;
			max-width: 85px;
			flex: 0 0 85px;
			padding: 2px;
			height: 100%;
			border-right: 1px solid ${({ theme }) => theme.colors.bg.lightYellow05};
			background-color: #faf4ea;
			img {
				width: 85px;
				max-width: 85px;
				height: 100%;
				object-fit: contain;
			}
		}
		section {
			height: 100%;
			padding: 25px;
			display: grid !important;
			grid-template-columns: repeat(4, 1fr) !important;
			width: 100%;
			column-gap: 10px;
			@media (max-width: 640px) {
				grid-template-columns: repeat(2, 1fr) !important;
				row-gap: 10px;
			}
			@media (max-width: 480px) {
				grid-template-columns: repeat(1, 1fr) !important;
			}
			h2 {
				strong {
					font-family: 'OpenSansBold';
					font-size: 18px;
					line-height: 26px;
					float: left;
					width: 100%;
				}
			}
			slot {
				grid-column: 3/1;
				display: grid;
				@media (max-width: 480px) {
					grid-column: auto;
				}
				strong{
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}
			.noOfItem {
				max-width: 90px;
				text-align: right;
				white-space: nowrap;
			}
		}
	}
	.noProductFound {
		width: 100%;
		text-align: center;
		font-family: 'OpenSansBold';
		font-size: 18px;
		padding: 50px;
		display: grid;
		grid-column: 2/1;
	}
`;
const ContentHolder = styled.div`
	height: 100%;
	padding: 0 50px;
	padding-bottom: 30px;
	width: 100%;

	@media (max-width: 768px) {
		padding: 0 15px;
	}
`;
const CardsAndNotificationHolder = styled.div`
	width: 100%;
	display: flex;
	@media (max-width: 860px) {
		flex-direction: column;
	}
`;
const TabContentHolder = styled.div`
	width: 100%;
	display: flex;
	margin-top: 50px;
`;
const BreadcrumbHolder = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media (max-width: 360px) {
		flex-direction: column;
		align-items: end;
		& > div {
			padding-bottom: 15px;
		}
	}
	div {
		// button {
		// 	display: none;
		// }
	}
`;
const ButtonWrapper = styled.div`
	display: flex;
	@media (max-width: 480px) {
		padding-bottom: 0 !important;
	}
	button {
		display: flex !important;
		font-size: 18px;
		text-transform: capitalize;
		height: 50px;
		padding-top: 0;
		padding-bottom: 0;
		width: auto;
		@media (max-width: 767px) {
			width: 40px;
			height: 40px;
			padding: 0;
			align-items: center;
			justify-content: Center;
			div {
				display: none;
			}
		}
		&:hover {
			svg {
				path {
					fill: ${({ theme }) => theme.colors.text.white};
				}
			}
		}
		span {
			display: flex;
			align-items: center;
		}
		+ button {
			margin-left: 15px;
		}
		.pr-icon {
			svg {
				margin-right: 10px !important;
				@media (max-width: 767px) {
					margin-right: 0px !important;
				}
			}
		}
	}
`;
const ButtonWrapperR = styled.div`
	display: flex;
	@media (max-width: 480px) {
		padding-bottom: 0 !important;
	}
	button {
		display: flex !important;
		text-transform: capitalize;
		height: 50px;
		padding-top: 0;
		padding-bottom: 0;
		width: auto;
		+ button{
			margin-left: 15px;
		}
		span {
			display: flex !important;
			flex-direction: column;
			div {
				font-family: 'OpenSansBold';
				font-size: 18px;
				& + div {
					font-family: 'OpenSansMedium';
					margin-top: 2px;
					font-size: 14px;
					color: ${({ theme }) => theme.colors.text.black};
				}
			}
		}
		&:hover {
			span {
				div {
					color: ${({ theme }) => theme.colors.text.white};
				}
			}
		}
	}
`;
export {
	MainContent,
	ContentHolder,
	CardsAndNotificationHolder,
	TabContentHolder,
	BreadcrumbHolder,
	ButtonWrapper,
	ButtonWrapperR,
	ProductOrderList,
};
