import styled from 'styled-components';

const MainContent = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
	padding: 0 50px 50px;
	@media (max-width: 960px) {
		padding: 0 15px 50px;
	}
`;
const BreadcrumbNavHolder = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	@media (max-width: 860px) {
		div {
			padding-bottom: 0;
		}
	}
`;
const DCListHolder = styled.div`
	width: 100%;
	float: left;
	min-height: calc(100vh - 444px);
	h1 {
		margin-bottom: 20px;
		strong {
			font-family: 'OpenSansBold';
		}
	}
`;
const SortBuy = styled.div`
	border: 1px solid rgba(189,188,185,1);
	box-sizing: border-box;
	border-radius: 6px;
	display: flex;
	align-items: center;
	min-width: 254px;
	min-height: 40px;
	@media (max-width: 860px) {
		min-width: 100%;
		flex-grow: 1;
		margin-top: 20px;
		margin-bottom: 20px;
	}
	.pr-icon {
		margin: 0 8px 0 12px;
		svg {
			margin-right: 0;
		}
	}
	div {
		flex-grow: 1;
		Select {
			border: none;
			outline: none;
			box-shadow: none;
			font-size: 14px;
			line-height: 18px;
			font-family: 'OpenSansMedium';
			color: ${({ theme }) => theme.baseColors.primary};
			margin-right: -34px;
		}
	}
	svg {
		margin-right: 10px;
		margin-left: 0;
		path {
			fill: ${({ theme }) => theme.baseColors.primary};
		}
	}
`;
const AddCommentsHolder = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 0 0 100%;
	max-width: 100%;
	height: auto;
	background: ${({ theme }) => theme.colors.bg.lightYellow06};
	border-radius: 6px;
	font-size: 12px;
	line-height: 16px;
	font-family: 'OpenSansBold';
	color: ${({ theme }) => theme.baseColors.white};
	padding: 15px !important;
	padding-top: 15px !important;

	input {
		height: 54px;
		border-radius: 6px;
		background: ${({ theme }) => theme.colors.bg.white};
		font-size: 17px;
		color: ${({ theme }) => theme.baseColors.black};
		font-family: 'OpenSansRegular';
		border: none;
		box-shadow: none;
		outline: none;
	}
	button {
		flex: 0 0 130px;
		max-width: 0 0 130x;
		cursor: pointer;
		height: 54px;
		max-height: 54px;
		margin-left: 15px;
		border-radius: 6px;
		font-size: 18px;
		line-height: 23px;
		font-family: 'OpenSansRegular';
	}
	@media (max-width: 767px) {
		flex-direction: column;
		margin-top: 43px;
		border-radius: 0;

		button {
			width: 100%;
			margin-left: 0;
			margin-top: 10px;
		}
	}
`;
export { MainContent, DCListHolder, SortBuy, BreadcrumbNavHolder, AddCommentsHolder };
