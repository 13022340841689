import Ipost from 'models/Ipost';
import React, { ReactElement, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { Button, Stack } from 'ui/components';
import { FormInput } from 'ui/patterns';
import hooks from 'utils/requestCtx/reactQueryHooks';
import { queryCache } from 'react-query';
import appContext from 'utils/appContext';

const AddToFavorites = styled.div`
	display: flex;
	height: auto;
	position: relative;
	flex-direction: column;
	background: ${({ theme }) => theme.colors.bg.white};
	padding: 0 10px 12px;
	@media (max-width: 960px) {
		padding: 0;
	}
	h1 {
		margin: 0 0 41px 0;
		width: 100%;
		font-weight: normal;
		text-transform: uppercase;
	}
	div {
		margin-bottom: 25px;
		&:last-child {
			margin-bottom: 0;
			margin-top: 25px;
		}
	}
	button {
		padding: 0;
		min-width: 100px;
		min-height: 50px;
		text-transform: capitalize;
		font-size: 18px;
		margin: 0;
		font-family: 'OpenSansBold';
		margin-left: 20px;
		@media (max-width: 480px) {
			margin-left: 10px;
			font-size: 16px;
		}
		&:first-child {
			margin-left: 0;
		}
	}
`;
const VerificationMsgSession = styled.div`
	padding: 15px;
	background: ${({ theme }) => theme.colors.bg.lightYellow05};
	border-radius: 10px;
	text-align: center;
	span {
		font-family: 'OpenSansSemiBold';
		color: ${({ theme }) => theme.baseColors.primary};

	}
	a {
		font-size: 14px;
		color: ${({ theme }) => theme.baseColors.primary};
		&:hover {
			opacity: 0.8;
			text-decoration: none !important;
		}
	}
`;
export default function ChangePasswordModal(prop): ReactElement {
	// const [changePassword, setChangePassword] = useState('');

	// const handleOnChange = (e: any) => {
	// 	setChangePassword(e.target.id);
	// };
	let ctx = useContext(appContext).userObj;
	const history = useHistory();
	const [details, setDetails] = useState({
		username: localStorage.getItem('userName'),
		confirmCode: '',
		password: '',
		confirmPassword: '',
	}); //set state for login details
	const [errors, setError] = useState({ error: '' }); //set state for error validation
	const userKey = 'validateKey';
	const [mutatePost] = hooks.useMutation(userKey);
	const [disableBtnSend, setDisableBtnSend] = useState(true);

	// Api call to pass the credentials entered and to validate it
	async function confirmMobile() {
		if (details) {
			const obj: Partial<Ipost> = {
				data: { username: details.username },
				params: {},
				url: 'experion/cognito/forgot-password/code',
				message: 'Verification Code has been send successfully to your email',
			};
			try {
				await mutatePost(obj).then((data) => {
					if (data) {
						setDisableBtnSend(false);
					} else {
						localStorage.clear();
						ctx.venueId = null;
						queryCache.clear();
						history.push('/login');
					}
				});
			} catch (e) {}
		}
	}

	function validatePassword(pwd) {
		var p = pwd;
		if (p.length < 8) {
			setError({ error: 'Your password must be at least 8 characters' });
			return false;
		}
		if (p.search(/[!@#$%^&*]/i) < 0) {
			setError({ error: 'Your password must contain at least one special character' });
			return false;
		}
		if (p.search(/.*[A-Z]/) < 0) {
			setError({ error: 'Your password must contain at least one uppercase letter' });
			return false;
		}
		if (p.search(/.*[a-z]/) < 0) {
			setError({ error: 'Your password must contain at least one lowercase letter' });
			return false;
		}
		if (p.search(/[0-9]/) < 0) {
			setError({ error: 'Your password must contain at least one digit' });
			return false;
		}
		return true;
	}

	// Api call to pass the credentials entered and to validate it
	async function validateCode() {
		var userName = localStorage.getItem('userName');
		if (details && userName) {
			details.username = userName;
			if (details.password == details.confirmPassword) {
				if (!validatePassword(details.confirmPassword)) {
					setError({
						error:
							'Password must contain minimum 8 characters, one special character, one number, one uppercase and lowercase characters',
					});
					return false;
				}

				const obj: Partial<Ipost> = {
					data: {
						username: details.username,
						code: details.confirmCode,
						password: details.password,
					},
					params: {},
					url: 'experion/cognito/forgot-password/confirm',
					message: 'Your Password has been Reset Successfully',
				};
				try {
					await mutatePost(obj).then((data) => {
						if (data !== undefined) {
							history.push('/login');
						} else {
							setError({ error: 'Invalid Confirmation Code' });
						}
					});
				} catch (e) {}
			} else {
				setError({ error: 'Mismatch in Password and Confirm Password' });
			}
		} else {
			setError({ error: 'Invalid Operation. Please try again!' });
			history.push('/login');
		}
	}

	return (
		<AddToFavorites>
			<Text as="h1" color="text.textHeading" fontSize={18} fontFamily="OpenSansSemiBold">
				Change Password
			</Text>
			{/* <Stack direction="x" gap={3} justifyContent="flex-end">
				<Button onClick={confirmMobile} flex={[1, 1, 'initial']} variant="secondary">
					Send Code
				</Button>
			</Stack> */}
			<VerificationMsgSession>
				<span style={{ color: 'green' }}>A verification code has been send to your email. </span>
				<a
					style={{ cursor: 'pointer', textDecoration: 'underline', fontWeight: 'bold' }}
					onClick={confirmMobile}
				>
					Resend Code
				</a>
			</VerificationMsgSession>
			<FormInput
				id="code"
				name="code"
				autoComplete="off"
				label="Please Enter the Verification Code"
				onTextChange={(event) => setDetails({ ...details, confirmCode: event })}
				value={details.confirmCode}
			/>
			<FormInput
				id="password"
				name="password"
				autoComplete="off"
				label="Please Enter New Password"
				type="password"
				onTextChange={(event) => setDetails({ ...details, password: event })}
				value={details.password}
			/>
			<FormInput
				id="confirmpassword"
				name="confirmpassword"
				autoComplete="off"
				label="Re-Enter New Password"
				type="password"
				onTextChange={(event) => setDetails({ ...details, confirmPassword: event })}
				value={details.confirmPassword}
			/>

			<span style={{ color: 'red', fontSize: '14px' }}>{errors.error}</span>
			<Stack direction="x" gap={3} justifyContent="flex-end">
				<Button onClick={() => prop.cancel && prop.cancel()} flex={[1, 1, 'initial']} variant="secondary">
					Cancel
				</Button>
				<Button onClick={validateCode} disabled={disableBtnSend} flex={[1, 1, 'initial']}>
					Change
				</Button>
			</Stack>
		</AddToFavorites>
	);
}
