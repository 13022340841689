import Ipost from 'models/Ipost';
import React, { useState } from 'react';
import { Button } from 'ui/components';
import { useHistory } from 'react-router-dom';
import logo from 'ui/images/logo_exp.png';
import hooks from 'utils/requestCtx/reactQueryHooks';
import '../../ui/styles/login.css';
import styled from 'styled-components';
import { Flex, Text, Box } from 'rebass/styled-components';
import { Separator } from 'ui/components';
import notify from 'utils/toast.service';
import api from 'utils/requestCtx/api.service';
import * as Icon from 'ui/icons';

const LoginWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	position: relative;
	&::before {
		content: '';
		width: 100vw;
		height: 100vh;
		position: absolute;
		top: 0;
		background: ${({ theme }) => theme.colors.bg.orangeLighterGrayish};
		backdrop-filter: blur(54px);
		position: fixed;
	}
	h1 {
		float: left;
		width: 100%;
		text-align: left;
		color: ${({ theme }) => theme.baseColors.primary};
		font-weight: 600;
		font-size: 14px;
	}
`;
const Panel = styled.div`
	min-width: 548px;
	max-width: 548px;
	border-radius: 6px;
	background: ${({ theme }) => theme.colors.bg.orangeLighter};
	position: relative;
	padding: 50px 46px 46px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	@media (max-height: 900px) {
		padding: 30px;
		margin: auto;
		/* margin-top: 30px; */
	}

	@media (max-width: 767px) {
		max-width: 100%;
		min-width: 300px;
		width: 100%;
		border-radius: 0;
		margin-top: 0;
	}
	@media (max-width: 640px) {
		padding: 23px;
		position: fixed;
		overflow-y: auto;
		height: 100vh;
		justify-content: flex-start;
	}
	form {
		width: 100%;
		.loginFields {
			position: relative;
			.pr-icon {
				position: absolute;
				top: 25px;
				right: 27px;
				svg {
					path {
						fill: ${({ theme }) => theme.baseColors.primary};
					}
				}
			}
			.PasswordIcon {
				top: 3px;
			}
		}
		input {
			padding: 10px 22px 10px;
			font-family: 'OpenSansSemiBold';
			font-size: 16px;
			border: 0;
			border: 1px solid ${({ theme }) => theme.colors.bg.orangeLighterGrayish};
			transition: border-color 0.2s ease;
			display: block;
			width: 100%;
			background: ${({ theme }) => theme.baseColors.white};
			color: ${({ theme }) => theme.colors.text.body};
			height: 73px;
			border-radius: 9px;
			padding-right: 60px;

			&:focus {
				outline: none;
				border-color: ${({ theme }) => theme.baseColors.primary};
			}
			+ input {
				margin-top: 30px;
			}
		}
		.forgot {
			width: 100%;
			text-align: right;
			margin: -16px 0 30px;
			float: left;
			a {
				font-size: 16px;
				color: ${({ theme }) => theme.colors.text.black};
				&:hover {
					color: ${({ theme }) => theme.baseColors.primary};
				}
			}
		}
		input:-webkit-autofill,
		input:-webkit-autofill:hover,
		input:-webkit-autofill:focus,
		input:-webkit-autofill:active {
			-webkit-box-shadow: 0 0 0 50px white inset !important;
			font-size: 16px !important;
			font-family: 'OpenSansSemiBold';
		}
		.loginButton {
			height: 73px;
			font-size: 18px !important;
			font-weight: normal !important;
			font-family: 'OpenSansSemiBold' !important;
		}
	}
`;
const ForgotComponent = () => {
	const history = useHistory();
	const [details, setDetails] = useState({ username: '' }); //set state for login details
	const [errors, setError] = useState({ error: '' }); //set state for error validation
	const userKey = 'validateKey';
	const [mutatePost] = hooks.useMutation(userKey);

	// Api call to pass the credentials entered and to validate it
	async function confirmMobile() {
		if (details) {
			const obj: Partial<Ipost> = {
				data: {
					email: details.username,
					template: 'email_reset',
					websiteId: 1
				},
				params: {},
				url: 'customers/password',
				message: 'Verification Code has been send successfully to your email',
			};
			try {
				await api.put({
					url: obj.url, data: obj.data,
					message: obj.message
				}).then((data) => {
					if (data) {
						history.push({
							pathname: 'verification',
							search: '?uid=' + details.username,
						});
					} else {
						notify('Something went wrong! Please try again.').error();
					}
				}
				);
			} catch (e) {
				console.log(e);
			}
		}
	}
	return (
		<LoginWrapper>
			<Panel>
				<img src={logo} alt="Logo" />
				<Separator> Forgot Password</Separator>
				<form>
					<Box className="loginFields" mb={24}>
						<Icon.MobileIcon />

						<input
							type="text"
							title="Enter Email Address"
							placeholder="Enter Email Address"
							onChange={(event) => setDetails({ ...details, username: event.target.value })}
							value={details.username}
						/>
					</Box>

					<span style={{ color: 'red', fontSize: '14px' }}>{errors.error}</span>
					<Box className="forgot">
						<a href="/login">
							<span style={{ float: 'right', textDecoration: 'underline' }}>Go Back to Login</span>
						</a>
					</Box>
					<Button className="loginButton" fullWidth type="button" onClick={confirmMobile}>
						Confirm Email
					</Button>
					{/* <a className="forgot" href="#">
						Forgot Username?
					</a> */}
				</form>
			</Panel>
		</LoginWrapper>
	);
};
export default ForgotComponent;
