import Iget from 'models/Iget';
import React, { ReactElement, useEffect, useState } from 'react';
import { Header, Footer, BreadcrumbNav, Loader } from 'ui/components';
import {
	Sidebar,
	DataGrid
} from 'ui/patterns';
import hooks from 'utils/requestCtx/reactQueryHooks';
import { MainContent, RightContentHolder, ContentHolder } from './styles';
import constants from 'utils/constants';
function SupplierReviewoverall_ratingsVenue(): ReactElement {
	const breadcrumbNav = [
		{ id: '01', label: 'Dashboard ', route: '/admin/seller-dashboard', active: false },
		{ id: '02', label: 'Reviews & Ratings ', active: true },
	];
	const OnlyHeading = 'Reviews & Ratings';
	const gridDataHead = [
		{ label: 'Store ' },
		{ label: 'Overall Ratings' },
	];
	const supplierId = localStorage.getItem('supplierId');
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const [gridData, updateGridData] = useState<any>([]);
	let CUSTOMER_TOKEN = localStorage.getItem('customerToken');
	const obj: Partial<Iget> = {
		params: {},
		url: constants.getAllVenueReview() + `${supplierId}`,
		enabled: true,
		resultFilteringRequired: true,
		headers: { Authorization: CUSTOMER_TOKEN }
	};
	const qry = hooks.useQuery(obj);
	useEffect(() => {
		qry.data && updateGridData([qry.data]);
	}, [qry.data]);
	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	}
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={()=>onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={()=>onclickHambrgBtn()}></Sidebar>
			{qry.isLoading ? <Loader></Loader> : null}
			<MainContent>
				{/* Top Breadcrumb */}
				<BreadcrumbNav Heading={OnlyHeading} breadcrumbList={breadcrumbNav} />
				<ContentHolder>
					{/* Right Product Detail Session */}
					<RightContentHolder>
						{/* <DataGrid gridData={gridData}  gridDataHead={gridDataHead}/> */}
						<DataGrid gridData={gridData} gridDataHead={gridDataHead} />
					</RightContentHolder>
				</ContentHolder>
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
		</>
	);
}
export default SupplierReviewoverall_ratingsVenue;
