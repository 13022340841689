import styled from 'styled-components';

const UserListHolder = styled.div`
	@media (max-width: 1300px) {
		overflow-x: auto;
		table {
			width: 142vw;
		}
	}
	@media (max-width: 960px) {
		table {
			width: 154vw;
		}
	}
	@media (max-width: 768px) {
		table {
			width: 100%;
		}
	}
	table {
		thead,
		tbody {
			tr {
				display: flex;
				justify-content: space-between;
				align-items: center;
				td,
				th {
					text-align: center;
					width: 100%;
					padding: 0;
					background: ${({ theme }) => theme.colors.bg.white};
					white-space: nowrap;

					&:nth-child(1) {
						text-align: left;
						flex: 0 0 300px;
						max-width: 300px;
						@media screen and (max-width: 767px) {
							flex: 0 0 100% !important;
							max-width: 100% !important;
							margin-bottom: 15px;
							h1 {
								margin-bottom: 9px;
							}
						}
					}
					&:nth-child(2) {
						flex: 0 0 100px;
						max-width: 100px;
					}
					&:nth-child(3) {
						flex: 0 0 150px;
						max-width: 150px;
					}
					&:nth-child(4) {
						flex: 0 0 150px;
						max-width: 150px;
					}
					&:nth-child(5) {
						flex: 0 0 200px;
						max-width: 200px;
					}
					&:nth-child(6) {
						flex: 0 0 120px;
						max-width: 120px;
						margin: 0 50px;
						@media screen and (max-width: 1300px) {
							margin: 0 30px;
						}
						@media screen and (max-width: 767px) {
							flex: 0 0 50%;
							max-width: 50%;
							margin: 0;
							div + div {
								width: 125px;
							}
						}
					}
					&:nth-child(7) {
						flex: 0 0 80px;
						max-width: 80px;
						@media screen and (max-width: 767px) {
							flex: 0 0 50%;
							max-width: 50%;
						}
					}

					@media screen and (max-width: 767px) {
						&:nth-child(2),
						&:nth-child(3),
						&:nth-child(4),
						&:nth-child(5) {
							border-bottom: 1px solid ${({ theme }) => theme.colors.border.userListTD}!important;
							padding-bottom: 10px;
							margin-bottom: 10px;
						}
					}
					@media screen and (max-width: 767px) {
						&:nth-child(6),
						&:nth-child(7) {
							.tdBefore {
								padding-bottom: 12px;
							}
						}
					}
					@media screen and (max-width: 380px) {
						&:nth-child(6),
						&:nth-child(7) {
							flex: 0 0 100% !important;
							max-width: 100% !important;
							div {
								margin-bottom: 0 !important;
							}
						}
						&:nth-child(6) {
							margin-bottom: 10px;
							padding-bottom: 10px;
							border-bottom: 1px solid ${({ theme }) => theme.colors.border.userListTD}!important;
						}
					}
				}
			}
		}
		thead {
			tr {
				border: 1px solid transparent;
				box-sizing: border-box;
				border-radius: 8px;
				padding: 30px 16px;
				th {
					font-size: 14px;
					line-height: 18px;
					font-family: 'OpenSansBold';
					text-transform: uppercase;
				}
			}
		}
		tbody {
			display: block;
			    min-height: max-content;
			margin-bottom: 50px;
			/* max-height: calc(100vh - 584px);
			min-height: calc(100vh - 584px);
			overflow: auto; */
			@media screen and (max-width: 767px) {
				max-height: 100%;
				min-height: auto;
			}
			tr {
				border: 1px solid ${({ theme }) => theme.colors.border.userListTD};
				box-sizing: border-box;
				box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07);
				border-radius: 8px;
				padding: 14px 16px;
				margin-bottom: 15px;
				.ar-checkedIn,
				.ar-checkedOut,
				.ar-noResponse {
					border-radius: 6px;
					margin: 0;
					padding: 0;
					font-size: 14px;
					font-family: 'OpenSansMedium';
					min-height: 30px;
					padding-left: 8px;
					outline: none;
					box-shadow: none;
				}
				.ar-checkedIn {
					background: ${({ theme }) => theme.colors.bg.lightYellow01};
					border: 1px solid ${({ theme }) => theme.colors.bg.yellow};
					color: ${({ theme }) => theme.colors.bg.yellow};
					+ svg {
						path {
							fill: ${({ theme }) => theme.colors.bg.yellow};
						}
					}
				}
				.ar-checkedOut {
					background: ${({ theme }) => theme.colors.bg.grayLight};
					border: 1px solid ${({ theme }) => theme.colors.text.black01};
					color: ${({ theme }) => theme.colors.text.black01};
					+ svg {
						path {
							fill: ${({ theme }) => theme.colors.text.black01};
						}
					}
				}
				.ar-noResponse {
					background: ${({ theme }) => theme.colors.bg.lightRed};
					border: 1px solid ${({ theme }) => theme.baseColors.redMedium};
					color: ${({ theme }) => theme.baseColors.redMedium};
					+ svg {
						path {
							fill: ${({ theme }) => theme.baseColors.redMedium};
						}
					}
				}
				.ar-customer-avatar {
					width: 42px;
					height: 42px;
					margin-right: 12px;
					border-radius: 6px;
					flex: 0 0 42px;
					max-width: 42px;
				}
				.customer-badge {
					background: linear-gradient(135deg, #fec163 0%, #dea513 100%);
					font-size: 10px;
					line-height: 12px;
					font-family: 'OpenSansBold';
					color: ${({ theme }) => theme.colors.text.white};
					margin: 2px 0 0 0;
					width: 33px;
					height: 14px;
					text-transform: uppercase;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 4px;
				}
				h2 {
					font-weight: normal;
				}
			}
		}
	}
	@media screen and (max-width: 767px) {
		.responsiveTable {
			padding: 0 14px;
		}
		.responsiveTable table,
		.responsiveTable thead,
		.responsiveTable tbody,
		.responsiveTable th,
		.responsiveTable td,
		.responsiveTable tr {
			display: block;
		}
		.responsiveTable thead tr {
			position: absolute;
			top: -9999px;
			left: -9999px;
			border-bottom: 2px solid #333;
		}
		.responsiveTable tbody tr {
			border: 1px solid ${({ theme }) => theme.colors.border.userListTD};
			padding: 15px 15px 21px;
			display: flex;
			flex-wrap: wrap;
			box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07);
			border-radius: 8px;
			overflow: hidden;
		}
		.responsiveTable td.pivoted {
			position: relative;
			padding-left: 0 !important;
			text-align: left !important;
			white-space: pre-wrap;
			overflow-wrap: break-word;
			display: flex;
			flex-direction: column;
			width: 50%;
			flex: 0 0 50%;
			max-width: 50%;
		}
		.responsiveTable td .tdBefore {
			position: relative;
			display: block;
			font-family: 'OpenSansBold';
			text-transform: uppercase;
			width: 100%;
			left: 0;
			font-size: 14px;
			line-height: 16px;
			white-space: pre-wrap;
			overflow-wrap: break-word;
			text-align: left !important;
			margin-bottom: 4px;
		}
	}
	@media screen and (max-width: 380px) {
		.responsiveTable tbody tr {
			flex-direction: column;
		}
		.responsiveTable td .tdBefore {
			font-size: 12px;
		}
		.responsiveTable td.pivoted {
			width: 100%;
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
`;
export { UserListHolder };
