import React, { ReactElement, useEffect, useState, useContext } from 'react';
import { Header, Footer, Button, BreadcrumbNav, Stack, Loader } from 'ui/components';
import { Sidebar, ProductCartListView } from 'ui/patterns';
import { Text } from 'rebass/styled-components';
import { Modal } from 'ui/patterns';
import { MainContent, PriceDetailsBlock, TotalCostBlock, CartPageWrapper } from './styles';
import NewParkedOrderModal from './newParkedOrder';
import Iget from 'models/Iget';
import hooks from 'utils/requestCtx/reactQueryHooks';
import Ipost from 'models/Ipost';
import utilityFunctions from 'utils/utility.functions';
import { UseGlobalState } from 'models/globalStateAccess';
import { venueUserPermissionList } from 'models/enum-constants/common.constants';
import { useHistory } from 'react-router-dom';
import constants from 'utils/constants';
import helperFunctions from 'utils/helper.functions';
import appContext from 'utils/appContext';
import SweetAlert from 'react-bootstrap-sweetalert';
import api from 'utils/requestCtx/api.service';

function ProductCart(): ReactElement {
	const breadcrumbNav = [
		{ id: '01', label: 'Home', route: '/home/dashboard', active: false },
		{ id: '04', label: 'Cart Management', active: true },
	];
	const [showNewParkedOrderModal, setShowNewParkedOrderModal] = useState(false);
	const [venueDetails, setVenueDetails] = useState<any>({});
	const [showModal, setShowModal] = useState(false);
	const [hideSideBar, toggleHideSideBar] = useState<boolean>(true);
	const [action, setAction] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const key = 'shippingkey';
	const [mutatePost] = hooks.useMutation(key);
	var cartId = localStorage.getItem('cartId');
	const [state, dispatch] = UseGlobalState();
	const [modalText, setmodalText] = useState('');
	const [showPopup, setShowPopup] = useState(false);
	var history = useHistory();
	const [venueUserId, setVenueUserId] = useState<any>(localStorage.getItem('userId'));
	const [venueId, setVenueId] = useState<any>(localStorage.getItem('selectedVenue'));
	const CUSTOMER_TOKEN = localStorage.getItem('customerToken');
	const [totalAmt, setTotalAmt] = useState<any>({});
	const [disableCheckout, setDisableCheckOut] = useState(false);
	const [qtyIssue, setQtyIssue] = useState(false);
	// const [slotEntered, setSlotEntered] = useState(false);
	const [isMovNotMet, setIsMovNotMet] = useState(false);
	const [isOutOfStock, setOutOfStock] = useState(false);
	const [isCreditStatusStop, setIsCreditStatusStop] = useState(false);
	const [isCartupdated, setIsCartUpdated] = useState(false);
	const [productCount, setProductCount] = useState(0);
	let ctx = useContext(appContext).cartObj;

	const toggleNewParkedOrderModal = () => {
		setShowNewParkedOrderModal(!showNewParkedOrderModal);
	};

	var id = localStorage.getItem('selectedVenue');
	const venueKey = 'venueDetails';
	const venueUrl = `experion/venue/get/${id}`;
	const item: Partial<Iget> = {
		params: {},
		url: venueUrl,
		enabled: true,
		key: venueKey,
	};
	const venueEditDetails = hooks.useQuery(item);

	var qryTotal;
	const url1 = '/carts/' + cartId + '/totals';
	const obj2: Partial<Iget> = {
		params: {},
		url: url1,
		enabled: true,
		key: 'cartTotalKey2',
		resultFilteringRequired: false,
	};
	qryTotal = hooks.useQuery(obj2);

	const onclickHambrgBtn = () => {
		toggleHideSideBar(!hideSideBar);
	};
	useEffect(() => {
		setVenueDetails(venueEditDetails.data);
	}, [venueEditDetails.data]);

	const onCheckoutClick = (action) => {
		setAction(action);
		estimateShippingMethod();
	};

	// TODO: Remove if delivery slot is required
	// useEffect(()=>{
	// 	let selectedSlot = localStorage.getItem('deliverySlot')
	// 			? JSON.parse(localStorage.getItem('deliverySlot') as any)
	// 			: [];
	// 		if (selectedSlot.length > 0) {
	// 			if (window.location.href.includes('/venue/cart') == true) {
	// 				localStorage.removeItem('deliverySlot');
	// 				callBackFunction(true, 'slot');
	// 			}
	// 		}
	// },[]);

	const estimateShippingMethod = () => {
		setIsLoading(true);
		// payment method to pass payment method and get shipping carrier and method code
		let placeOrderJson = {
			address: {
				region: venueDetails?.state,
				region_id: 43,
				region_code: 'NZ',
				country_id: 'NZ',
				street: [venueDetails?.address],
				postcode: venueDetails?.zip_code,
				city: venueDetails?.city,
				firstname: 'Jane',
				lastname: 'Doe',
				customer_id: localStorage.getItem('userId'),
				email: localStorage.getItem('email'),
				telephone: localStorage.getItem('phone_no'),
				same_as_billing: 1,
			},
		};

		const obj1: Partial<Ipost> = {
			data: placeOrderJson,
			params: {},
			url: '/carts/' + cartId + '/estimate-shipping-methods',
			message: ' ',
		};
		try {
			mutatePost(obj1).then((data) => {
				if (data !== undefined) {
					callShippingInformation(data);
				}
			});
		} catch (e) {
			setIsLoading(false);
		}
	};

	const callShippingInformation = (data) => {
		//get all shipping details
		let placeOrderJson = {
			addressInformation: {
				shipping_address: {
					region: venueDetails?.state,
					region_id: 43,
					region_code: 'NZ',
					country_id: 'NZ',
					street: [venueDetails?.address],
					postcode: venueDetails?.zip_code,
					city: venueDetails?.city,
					firstname: 'Jane',
					lastname: 'Doe',
					email: localStorage.getItem('email'),
					telephone: localStorage.getItem('phone_no'),
				},
				billing_address: {
					region: venueDetails?.state,
					region_id: 43,
					region_code: 'NZ',
					country_id: 'NZ',
					street: [venueDetails?.address],
					postcode: venueDetails?.zip_code,
					city: venueDetails?.city,
					firstname: 'Jane',
					lastname: 'Doe',
					email: localStorage.getItem('email'),
					telephone: localStorage.getItem('phone_no'),
				},
				shipping_carrier_code: data[0].carrier_code,
				shipping_method_code: data[0].method_code,
			},
		};

		const obj1: Partial<Ipost> = {
			data: placeOrderJson,
			params: {},
			url: '/carts/' + cartId + '/shipping-information',
			message: ' ',
		};
		try {
			mutatePost(obj1).then((data) => {
				if (data !== undefined) {
					let orderCostLimit: any = localStorage.getItem('orderCostLimit');
					let totalCart = parseFloat(state.cartTotal.replace(/[^0-9\.-]+/g, ''));
					if (
						totalCart > parseInt(orderCostLimit) &&
						utilityFunctions.isGranted(venueUserPermissionList.OrderApproval) === false
					) {
						setIsLoading(false);
						showConfirmationModal(
							'Order Cost Limit exceeded. Click confirm to send this order for approval.'
						);
					} else {
						setProductCount(0);
						history.push('/admin/checkout');
					}
				}
			});
		} catch (e) {
			setIsLoading(false);
		}
	};

	const placeOrder = () => {
		setIsLoading(true);
		let deliverySlot = localStorage.getItem('deliverySlot') as any;
		let placeOrderJson = {
			paymentMethod: {
				method: 'cashondelivery',
				extension_attributes: {
					venue_id: parseInt(venueId),
					venue_user_id: parseInt(venueUserId),
					delivery_slot: JSON.parse(deliverySlot),
					comments: [],
				},
			},
			billing_address: {
				email: localStorage.getItem('email'),
				region: venueDetails?.state,
				region_id: 43,
				region_code: 'NZ',
				country_id: 'NZ',
				street: [venueDetails?.address],
				postcode: venueDetails?.zip_code,
				city: venueDetails?.city,
				telephone: localStorage.getItem('phone_no'),
				firstname: 'Jane',
				lastname: 'Doe',
			},
		};

		const obj: Partial<Ipost> = {
			data: placeOrderJson,
			params: {},
			url: constants.paymentURL(),
			message: 'Your order has been sent for approval',
			headers: { Authorization: CUSTOMER_TOKEN },
		};
		try {
			mutatePost(obj).then((data) => {
				if (data !== undefined) {
					let orderCostLimit: any = localStorage.getItem('orderCostLimit');
					// let totalCart = parseFloat(state.cartTotal.replace(/[^0-9\.-]+/g, ''));
					let totalCart = parseInt(totalAmt.grand_total);
					if (
						totalCart > parseInt(orderCostLimit) &&
						utilityFunctions.isGranted(venueUserPermissionList.OrderApproval) === false
					) {
						clearCart();
					} else {
						localStorage.setItem('orderId', data);
						history.push('/admin/confirm');
						setIsLoading(false);
					}
					updateTotalAmount();
					// setIsLoading(false);
				}
			});
		} catch (e) {
			setIsLoading(false);
		}
	};

	const updateTotalAmount = () => {
		qryTotal.refetch().then((data) => {
			if (data) {
				setTotalAmt(data);
				if (data?.items?.length > 0) {
					dispatch({ cartTotal: helperFunctions.formatAmount(data?.grand_total) });
				}
			} else {
			}
		});
	};

	useEffect(() => {
		if (qryTotal.data !== undefined) {
			if (qryTotal.data?.items?.length > 0) {
				setProductCount(qryTotal.data?.items?.length);
			}
			else{
				setProductCount(0);
			}
			setTotalAmt(qryTotal.data);
			updateTotal(qryTotal.data);
		} else {
			setProductCount(0);
		}
	}, [qryTotal.data]);

	const callBackFunction = (value, key) => {
		if (key === 'mov') {
			setIsMovNotMet(value);
		} else if (key === 'credit') {
			setIsCreditStatusStop(value);
		} else if (key === 'stock') {
			setOutOfStock(value);
		} else if (key === 'cartTotal') {
			setDisableCheckOut(value);
		} else if (key === 'qtyIssue') {
			setQtyIssue(value);
		} else if (key === 'slot') {
			// setSlotEntered(value);
		}
	};

	function updateTotal(data) {
		let datas = data?.items?.filter((x) => x.is_deleted != true);
		if (datas?.length > 0) {
			dispatch({ cartItemCount: datas.length });
			dispatch({ cartTotal: helperFunctions.formatAmount(data?.grand_total) });
			setDisableCheckOut(false);
		} else {
			dispatch({ cartItemCount: '0' });
			dispatch({ cartTotal: '' });
			setDisableCheckOut(true);
		}
	}
	async function closePopup() {
		await setShowPopup(!showPopup);
	}
	const onSubmit = () => {
		if (action === 'clear') {
			clearCart();
		} else if (action === 'orderApproval') {
			placeOrder();
		}
		setShowPopup(!showPopup);
	};
	const clearCartOrders = (action) => {
		setAction(action);
		showConfirmationModal('Are you sure want to clear the cart?');
	};
	function showConfirmationModal(text) {
		setmodalText(text);
		setShowPopup(!showPopup);
	}
	async function clearCart() {
		let id: any;
		id = await utilityFunctions.getCartId(CUSTOMER_TOKEN);
		if (id !== undefined) {
			setIsLoading(true);
			const obj: Partial<Ipost> = {
				data: { cartId: localStorage.getItem('cartId') },
				params: {},
				url: '/clear-cart',
				message: ' ',
			};
			try {
				api.post(obj).then((data) => {

					if (data !== undefined) {
						qryTotal.refetch().then(function (data) {
							localStorage.removeItem('deliverySlot');
							setProductCount(0);
							updateTotal(data);
							setIsCartUpdated(true);
							setIsLoading(false);
							// history.push('/home/dashboard');
						});
					}
				});
			} catch (e) {
				setIsLoading(false);
			}
		}
	};

	const onMarkAsParked = () => {
		let payload = {
			cartId: cartId,
			venueUserId: venueUserId,
			// "parkOrderName": newParkedOrder,
			venueId: venueId,
		};

		const obj1: Partial<Ipost> = {
			data: payload,
			params: {},
			url: '/experion-parkorder/create',
			message: 'Order has been saved as Parked Order',
		};
		try {
			mutatePost(obj1).then((data) => {
				if (data !== undefined) {
					clearCart();
				}
			});
		} catch (e) { }
	};
	return (
		<>
			{/* Main Header */}
			<Header toggleSideBar={() => onclickHambrgBtn()}></Header>
			{/* Side Menu Bar */}
			<Sidebar hide={hideSideBar} toggleSideBar={() => onclickHambrgBtn()}></Sidebar>
			{(isLoading === true || (qryTotal && qryTotal.isFetching)) ? <Loader></Loader> : null}
			<MainContent>
				{/* Breadcrumb and Sorting Session */}
				<BreadcrumbNav
					Heading={'Cart (' + productCount + (productCount > 1 ? ' Products)' : ' Product)')}
					breadcrumbList={breadcrumbNav}
				/>

				<CartPageWrapper>
					<ProductCartListView
						updateTotalAmount={updateTotalAmount}
						callBackFunction={callBackFunction}
						IsCartUpdated={isCartupdated}
					/>
					{/* Footer Btn Block */}
					{state.cartTotal !== '' ? (
						<PriceDetailsBlock>
							<TotalCostBlock>
								<Text as="h1" fontSize={20} fontFamily="OpenSansBold" color="text.black04">
									Price Details
								</Text>
								<Text as="h2" fontSize={18}>
									<span>Sub Total</span>
									<strong>${parseFloat(totalAmt.grand_total).toFixed(2)}</strong>
								</Text>
								<Text as="h2" fontSize={18}>
									<span>Total Tax</span>
									<strong>${parseFloat(totalAmt.tax_amount).toFixed(2)}</strong>
								</Text>

								<Text as="h3" fontSize={18}>
									<span>Total Cost</span>
									<strong>
										${parseFloat(totalAmt.grand_total + totalAmt.tax_amount).toFixed(2)}
									</strong>
								</Text>
							</TotalCostBlock>
							<Stack direction="x" gap={0} justifyContent={['center', 'flex-end']}>
								{(utilityFunctions.isGranted(venueUserPermissionList.ParkedOrdersAdd) ||
									utilityFunctions.isGranted(venueUserPermissionList.ParkedOrdersAdmin)) &&
									totalAmt?.grand_total !== 0 && (
										<Button
											flex={[1, 1, 'initial']}
											variant="secondary"
											disabled={state.cartTotal == ''}
											onClick={onMarkAsParked}
										>
											Save As Parked Order
										</Button>
									)}
								<Button
									onClick={() => {
										clearCartOrders('clear');
									}}
									flex={[1, 1, 'initial']}
									variant="secondary"
									disabled={state.cartTotal == ''}
								>
									Clear All
								</Button>
								<Button
									onClick={() => {
										onCheckoutClick('orderApproval');
									}}
									disabled={
										state.cartTotal == '' ||
										isMovNotMet ||
										isOutOfStock ||
										isCreditStatusStop ||
										disableCheckout ||
										qtyIssue 
									}
									flex={[1, 1, 'initial']}
								>
									Checkout
								</Button>
							</Stack>
						</PriceDetailsBlock>
					) : (
						''
					)}
				</CartPageWrapper>

				<SweetAlert
					show={showPopup}
					confirmBtnText="Confirm"
					onConfirm={onSubmit}
					showCancel={true}
					onCancel={closePopup}
					title={'Confirmation Box'}
				>
					{() => <form>{modalText}</form>}
				</SweetAlert>
			</MainContent>

			{/* Footer Session */}
			<Footer></Footer>
			<Modal
				width="600px"
				showModal={showNewParkedOrderModal}
				setShowModal={setShowNewParkedOrderModal}
			>
				<NewParkedOrderModal cancel={() => setShowModal(false)} />
			</Modal>
		</>
	);
}

export default ProductCart;
