import styled from 'styled-components';
import { getHSL } from '../../ui/utils/getHSL';
const MainContent = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
	padding: 30px 50px;
	@media (max-width: 960px) {
		padding: 30px 15px 50px;
	}
	@media (max-width: 767px) {
		padding: 30px 15px 0;
	}
`;
const BreadcrumbHolder = styled.div`
	width: 100%;
	float: left;
`;

const ContentHolder = styled.div`
	display: flex;
	@media (max-width: 860px) {
		flex-direction: column;
		padding-top: 15px;
	}
`;

const RightContentHolder = styled.div`
	flex-grow: 1;
	.review-area {
		width: 100%;
		textarea {
			height: 150px !important;
			max-width: 100%;
			width: 100% !important;
			margin: 0 !important;
		}
		> div {
			margin-top: 30px;
		}
	}
	.point-to-note {
		.required {
			color: #eb5757;
			font-size: 15px;
			margin: 0 3px;
		}
		font-size: 16px;
	}
	.RatingSession {
		margin-top: 20px;
		h1 {
			margin: 0 0 10px 0;
			font-size: 20px;
		}
		.rating {
			margin-left: -10px;
			width: 230px;
			@media (max-width: 860px) {
				margin-left: 0;
			}
		}
	}
`;

const FooterBtnBlock = styled.div`
	padding: 30px 0 20px;
	button {
		min-width: 150px;
		text-transform: Uppercase;
		padding: 0;
		height: 48px;
		flex: 0 0 150px;
	}
	@media (max-width: 767px) {
		button {
			flex: 0 0 100px;
		}
	}
`;
export { MainContent, BreadcrumbHolder, RightContentHolder, ContentHolder, FooterBtnBlock };
